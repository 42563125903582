import React, { useState } from "react";
import "./countries.scss";
import { Country } from "../../../consts/countries";
import { useTranslation } from "react-i18next";
import { CloseIcon } from "../../../components/icons";
import { updateFeatures, useGetAllFeaturesQuery, usePutFeaturesMutation } from "../../../hooks/slices/currentPlanSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {Loader} from "../../../components/loader";
import { ErrorPage } from "../../error";
import { Feature, FeatureName } from "../../../consts/features";
import { FlagIcon } from "../../../components/flags/flags";
import { resetSearch } from "../../../hooks/slices/tenderSearchSlice";
import { CurrentPlanInStore, RaiseCurrentPlan } from "../../../hooks/raiseCurrentPlan";
import { CountriesGrid } from "../../signup/countries/CountriesGrid";
import { FeatureUtil } from "../../../utils/features";
import { showCustomErrorPopup } from "../../../hooks/slices/snaccSlice";
import { SaveButton } from "../../../components/saveButton/SaveButton";
import { AccountSettingsFormSkeleton } from "../accountSettingsFormSkeleton";

export const Countries: React.FC = () => {
  const { data: allFeatures, error: fetchAllFeaturesError, isLoading: featuresIsLoading } = useGetAllFeaturesQuery();
  const currentPlan: CurrentPlanInStore = RaiseCurrentPlan();
  const clientUuid: string | undefined = useAppSelector((state) => state.user.clientUuid);
  if (featuresIsLoading || currentPlan.isLoading) return <AccountSettingsFormSkeleton inputElements={3} />;
  if (fetchAllFeaturesError) return <ErrorPage error={fetchAllFeaturesError} />;
  if (currentPlan.error) return <ErrorPage error={currentPlan.error} />;
  let clientFeatures = currentPlan.currentPlan?.features ? currentPlan.currentPlan.features : undefined;
  let clientFeaturesToRemove = currentPlan.currentPlan?.featuresToRemove
    ? currentPlan.currentPlan.featuresToRemove
    : [];
  let currentPlanStartDate = currentPlan.currentPlan ? currentPlan.currentPlan.startDate : undefined;
  let currentPlanEndDate = currentPlan.currentPlan ? currentPlan.currentPlan.endDate : undefined;
  let allPublisherFeatures = allFeatures
    ? allFeatures.features
      ? allFeatures.features.filter((f) => FeatureUtil.isPublisherFeature(f))
      : undefined
    : undefined;
  if (clientUuid && clientFeatures && currentPlanStartDate && currentPlanEndDate && allPublisherFeatures) {
    return (
      <CountriesWithData
        clientUuid={clientUuid}
        clientFeatures={clientFeatures}
        clientFeaturesToRemove={clientFeaturesToRemove}
        currentPlanStartDate={currentPlanStartDate}
        currentPlanEndDate={currentPlanEndDate}
        allPublisherFeatures={allPublisherFeatures}
      />
    );
  } else return <AccountSettingsFormSkeleton inputElements={3} />;
};

interface CountriesProps {
    clientUuid: string;
    currentPlanStartDate: string;
    currentPlanEndDate: string;
    clientFeatures: Feature[];
    clientFeaturesToRemove: Feature[]; //these are the features that will be removed when the client's plan ends
    allPublisherFeatures: Feature[];
}

export const CountriesWithData: React.FC<CountriesProps> = ({...props}) => {
    const dispatch = useAppDispatch();
    const [putFeatures, {isSuccess: featuresUpdated, isLoading: updatingFeatures, isError: featuresPutIsError, error: featuresPutError}] = usePutFeaturesMutation();
    const countriesLimit = FeatureUtil.getFeatureLimit(props.clientFeatures, FeatureName.COUNTRIES);
    const {t} = useTranslation();
    const originalCountryFeatures = FeatureUtil.sortPublisherFeaturesByTranslation([...props.clientFeatures].filter(f => FeatureUtil.isPublisherFeature(f)), t);
    //original country features includes the features that are slated to remove, since these are still active
    const originalCountryFeaturesToRemove = FeatureUtil.sortPublisherFeaturesByTranslation([...props.clientFeaturesToRemove].filter(f => FeatureUtil.isPublisherFeature(f)), t);
    const [localSelectedCountryFeatures, setLocalSelectedCountryFeatures] = useState<Feature[]>(originalCountryFeatures);
    const [localCountryFeaturesToRemove, setLocalCountryFeaturesToRemove] = useState<Feature[]>(originalCountryFeaturesToRemove);
    const featureWillBeRemoved = (feature: Feature) => {return localCountryFeaturesToRemove.map(f =>f.name).includes(feature.name)};
    const countryFeaturesThatWontBeRemoved = () => localSelectedCountryFeatures.filter(f => !featureWillBeRemoved(f));
    const featuresAreDifferentFromOriginal = () => {
        return originalCountryFeatures.map(f => f.name).sort().join(",") !== localSelectedCountryFeatures.map(f => f.name).sort().join(",")
            || originalCountryFeaturesToRemove.map(f => f.name).sort().join(",") !== localCountryFeaturesToRemove.map(f => f.name).sort().join(",") ;
    }
    const userHasCountriesLeft = () => {
        return localSelectedCountryFeatures.length - localCountryFeaturesToRemove.length !== 0;
    }
    const submit = () => {
        if (featuresAreDifferentFromOriginal()) {
            if (countriesLimit !== undefined && countriesLimit !== null && localSelectedCountryFeatures.length > countriesLimit) {
                let countriesLimitMessageEnd = countriesLimit === 1 ? t("settings.no_countries_left_message_end_singular") : t("settings.no_countries_left_message_end_plural");
                setLocalSelectedCountryFeatures(originalCountryFeatures);
                setLocalCountryFeaturesToRemove(originalCountryFeaturesToRemove);
                dispatch(showCustomErrorPopup(t("settings.no_countries_left_message_start") + " " + countriesLimit + " " + countriesLimitMessageEnd));
            } else {
                let newClientFeatures = [...props.clientFeatures].filter(f => !FeatureUtil.isPublisherFeature(f));
                localSelectedCountryFeatures.forEach(f => newClientFeatures.push(f));
                let newClientFeaturesToRemove = [...props.clientFeaturesToRemove].filter(f => !FeatureUtil.isPublisherFeature(f));
                localCountryFeaturesToRemove.forEach(f => newClientFeaturesToRemove.push(f));
                putFeatures({uuid:props.clientUuid, features: newClientFeatures, featuresToRemove: newClientFeaturesToRemove});
                dispatch(updateFeatures({features: newClientFeatures, featuresToRemove: newClientFeaturesToRemove}));
                dispatch(resetSearch());
            }
        }
    }

    //Scenario's:
    //  (a) Adding a country: adds it to features OR removes it from featuresToRemove
    //  (b) Removing a country: removes it from features (if not yet submitted) | adds it to featuresToRemove (if already paid for)
    const updatePublisherFeatures = (countryFeature: Feature, isSelected: boolean) => {

        let newSelectedFeatures: Feature[] = [];
        let newFeaturesToRemove: Feature[] = [];
        if(!isSelected) {
            if (originalCountryFeatures.map(f => f.name).includes(countryFeature.name)){
                //If user is trying to remove a submitted country feature, add it to featuresToRemove
                newFeaturesToRemove = [...localCountryFeaturesToRemove, countryFeature];
                setLocalCountryFeaturesToRemove(newFeaturesToRemove);
            } else {
                //otherwise just remove it from features
                newSelectedFeatures = localSelectedCountryFeatures.filter(f => f.name !== countryFeature.name);
                setLocalSelectedCountryFeatures(newSelectedFeatures)
            }

        }
        else {
            if (featureWillBeRemoved(countryFeature)){
                //if the feature is slated to remove, just remove it from that list (it is already in the selected features)
                newFeaturesToRemove = localCountryFeaturesToRemove.filter(f => f.name !== countryFeature.name);
                setLocalCountryFeaturesToRemove(newFeaturesToRemove)
            } else {
                //else add it to the new publishers
                newSelectedFeatures = [...localSelectedCountryFeatures, countryFeature];
                setLocalSelectedCountryFeatures(newSelectedFeatures);
            }
        }
    }
    const translatePrice = (publisherFeature: Feature) => {
        return FeatureUtil.getPriceString(publisherFeature, t);
    }
    const translateCountry = (publisherFeature: Feature) => {
        let country = FeatureUtil.getCountryIfPublisherFeature(publisherFeature);
        return country ? t("countries." + country) : "";
    }
    const translatePlanEndDate = t("settings.until") + " " + props.currentPlanEndDate;
    const totalPrice = () => {
        let totalPrice = 0;
        for(const f of localSelectedCountryFeatures){
            if (f.price !== undefined) totalPrice = totalPrice + f.price;
        } return  "€ " + totalPrice;
    }
    if(updatingFeatures) return <Loader/>;
    if(featuresPutError && featuresPutIsError) return <ErrorPage error={featuresPutError}/>
    return <>
        <div>
            <div className='countries-section-alignment'>
                <div className='page-alignment'>
                    <div>
                        <h1>{t("settings.countries")}</h1>
                    </div>
                </div>
                <div className='countries-list-alignment'>
                    {countryFeaturesThatWontBeRemoved().map((f) =>
                        <span key={f.name} >
                            <span>
                                <FlagIcon country={FeatureUtil.getCountryIfPublisherFeature(f)}/>
                                <div>{translateCountry(f)}</div>
                            </span>
                            <div>{translatePrice(f)}</div>
                        </span>
                    )}
                    {localCountryFeaturesToRemove.map((f) =>
                        <span key={f.name} style={{color: 'grey'}} >
                            <span>
                                <FlagIcon country={FeatureUtil.getCountryIfPublisherFeature(f)}/>
                                <div>{translateCountry(f) + " (" + translatePlanEndDate + ")"}</div>
                            </span>
                            <div>{translatePrice(f)}</div>
                        </span>
                    )}
                </div>
                <div className={'countries-total-price-alignment'}>
                    <div>{t("settings.totalPrice") + ": " + totalPrice()}</div>

                </div>
                {featuresAreDifferentFromOriginal() && userHasCountriesLeft() && <SaveButton saving={false} save={submit}/>}
                <CountriesGrid selectedPublisherFeatures={countryFeaturesThatWontBeRemoved()}
                               allPublisherFeatures={props.allPublisherFeatures}
                               updatePublisherFeatures={updatePublisherFeatures}
                               showPrices={true}
                />
            </div>
        </div>
    </>
}

interface CountryProps {
    country: Country;
}

const CountryToggle: React.FC<CountryProps> = ({country}) => {
    const {t} = useTranslation();
    return <>
        <div className='box'>
            <div>
                <span>{t("countries." + country)}</span>
            </div>
            <div>
                {/*<CloseIcon/>*/}
                <CloseIcon/>
            </div>
        </div>
    </>
}
