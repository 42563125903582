import {SearchFilter} from "../../../../consts/SearchFilter";
import {SearchSidebarConfiguration} from "../../GenericSearchPage";
import React from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {updateSearchFilters} from "../../../../hooks/slices/tenderSearchSlice";
import {UserInteraction} from "../../../../hooks/slices/userSlice";
import {RaiseUser} from "../../../../hooks/raiseUser";

interface CheckBoxProps {
    label?: string;
    relevantFilters: SearchFilter[];
    searchSidebarConfiguration: SearchSidebarConfiguration;
    getAggregation: (searchFilter: SearchFilter) => string;
    frozen?: boolean;
    optionsAreExclusive?: boolean;
    addOrRemoveSearchFilter: (element: SearchFilter, currentlySelectedFilters: SearchFilter[]) => SearchFilter[];
}

export const CheckBox: React.FC<CheckBoxProps> = (props) => {
    let {t} = useTranslation();
    const dispatch = useAppDispatch();
    const searchFilters = useAppSelector((state) => state.tenderSearch.searchFilters);
    let userInteractions: UserInteraction[] = RaiseUser().user?.userInteractions ?? [];
    const select = (filter: SearchFilter) => {
        if (!props.frozen) {
            let newSelectedFilters = searchFilters.selectedFilters;
            if (props.optionsAreExclusive) {
                let unchecking = newSelectedFilters.includes(filter);
                newSelectedFilters = newSelectedFilters.filter(f => !props.relevantFilters.includes(f));
                if (!unchecking) newSelectedFilters = [...newSelectedFilters, filter];
            } else {
                newSelectedFilters = props.addOrRemoveSearchFilter(filter, newSelectedFilters);
            }
            dispatch(updateSearchFilters({selectedFilters: newSelectedFilters, userInteractions: userInteractions}));
        }
    }
    const translate = (filter: SearchFilter) => {
        if (filter.startsWith("COUNTRY_")) return t("countries." + filter.replace("COUNTRY_", "")) + props.getAggregation(filter);
        else if (filter.startsWith("LANGUAGE_")) return t("languages." + filter.replace("LANGUAGE_", "")) + props.getAggregation(filter);
        else return t("searchFilters." + filter.valueOf()) + props.getAggregation(filter);
    }
    // for (const hiddenFilter of props.searchSidebarConfiguration.hiddenFilters ? props.searchSidebarConfiguration.hiddenFilters : []) {
    //     if (props.relevantFilters.includes(hiddenFilter)) return <></>
    // }
    let hiddenFilters = props.searchSidebarConfiguration.hiddenFilters ? props.searchSidebarConfiguration.hiddenFilters : [];
    let filtersToDisplay = props.relevantFilters.filter(f => !hiddenFilters.includes(f));
    if (filtersToDisplay.length < 2) return <></>
    return <>
        <div className='checkbox-button-relative' data-cy={"checkbox"}>
            {props.label &&
                <div className='checkbox-label-alignment'>
                    <p>{props.label}</p>
                </div>
            }
            {/*increase first element margin with className={i === 0 ? 'first-bottom-radio-alignment' : ''}*/}
            {filtersToDisplay.map((f, i) => (
                <div key={i}>
                    <input type="checkbox"
                           id={f}
                           name="checkBox" onChange={() => select(f)}
                           checked={searchFilters.selectedFilters.includes(f)}
                           data-cy={"checkbox-input"}
                    />
                    <label htmlFor={f}>{translate(f)}</label>
                </div>
            ))}
        </div>
    </>
}