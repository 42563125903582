import { TenderDetailsProps } from "./TenderDetailsView";
import { TenderDetailsCards } from "./TenderDetailsCards";
import {
    Address,
    Company,
    ContractingAuthority,
    MultilingualField,
    S3FileDocument,
    Tender,
    Value
} from "../../../types/tender";
import React, { useEffect, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { TenderSearchHighlightedAddendum, useGetTenderSearchMutation } from "../../../hooks/slices/tenderSearchSlice";
import { TenderDetailsSidebar } from "../sidebar/TenderDetailsSideBar";
import { LanguageParser } from "../../../consts/languages";
import { useAppDispatch } from "../../../app/hooks";
import { Form } from "../../../components/form/Form";
import { showErrorPopup } from "../../../hooks/slices/snaccSlice";
import { DateUtil } from "../../../utils/date";
import { DeadlineBlueIcon, PublicationDateBlueIcon, SearchIcon } from "../../../components/icons";
import { SearchType } from "../../../consts/searchType";
import { FieldGroup } from "../../../consts/FieldGroup";
import { TenderUtil } from "../../../utils/tenders";
import { usePostDownloadFileMutation } from "../../../hooks/slices/tenderDetailsSlice";
import { Loader } from "../../../components/loader";
import { S3FileUtil } from "../../../utils/s3Files";
import { FlagsForTender } from "../../../components/flags/flags";
import {CompanyUtil} from "../../../utils/company";
import {CompanyLink, CompanyNameWithVat} from "../../company/CompanyLink";
import {MultilingualFieldUtil} from "../../../utils/multilingualfield";
import {SearchPhase} from "../../../consts/searchPhase";

export const TenderDetailsPage: React.FC<TenderDetailsProps> = ({tender, languageIso}) => {

    const dispatch = useAppDispatch();
    const {t, i18n} = useTranslation();

    const [getTenderSearch, {
        data: tenderSearchOutput,
        isLoading,
        isSuccess,
        isError,
        error
    }] = useGetTenderSearchMutation();
    const [addendaSearchQuery, setAddendaSearchQuery] = useState("");
    const changeAddendaSearchQuery: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setAddendaSearchQuery(e.currentTarget.value);
    };
    let initialHighlights: TenderSearchHighlightedAddendum[] = []
    const [addendaHighlights, setAddendaHighlights] = useState(initialHighlights);
    const [highlightSearchPerformed, setHighlightSearchPerformed] = useState(false);

    // This triggers whenever 'tenderSearchOutput' is updated
    useEffect(() => {
        if (tenderSearchOutput !== undefined && isSuccess) {
            let highlights: TenderSearchHighlightedAddendum[] = []
            for (const tender of tenderSearchOutput.tenders) {
                for (const highlight of tender.highlightedAddenda) {
                    highlights.push(highlight);
                }
            }
            setAddendaHighlights(highlights);
            if (addendaSearchQuery !== "") setHighlightSearchPerformed(true);
        } else if (isError && error) {
            dispatch(showErrorPopup(error));
        }
    }, [tenderSearchOutput, isLoading, isSuccess, isError, error]);

    useEffect(() => {
        if (addendaSearchQuery === "") setHighlightSearchPerformed(false);
    }, [addendaSearchQuery])

    const searchAddenda = () => {
        getTenderSearch({
            metadata:{
                searchType: SearchType.DETAILS_PAGE_ADDENDA,
                searchPhase: SearchPhase.HIGHLIGHTS,
                page: 0,
                pageSize: 1,
                addMainQueryHighlights: true,
                highlightSize: 1500,
                maxHighlightedFilesPerTender: 10,
                maxHighlights: 3,
                tenderUuidFilters: [tender.uuid],
            }, mainInput: {
                name: "search_addenda",
                query: addendaSearchQuery,
                fieldGroups: [FieldGroup.ADDENDA],
                languageIsos: [], //translates to default languages
            }, filterInputs: []
        });
    };
    return (
        <>
            <div>
                <div className='tender-details-section'>
                    <div className='container'>
                        <div className='grid'>
                            <div className='grid-items'>
                                <h1>
                                    <span className="flag-section"><FlagsForTender tender={tender} /></span>
                                    <span>{MultilingualFieldUtil.translate(tender.title, languageIso)}</span>
                                </h1>
                                <div className='date-text-grid'>
                                    <div className='date-text-item'>
                                        <div>
                                            <PublicationDateBlueIcon/>
                                        </div>
                                        <div className={'date-text-item-label'}>
                                            <span>{t("publications.publicationDateTitle") + ": "}</span>
                                        </div>
                                    </div>
                                    <div className='date-text-item'>
                                        <div>
                                            <span>{DateUtil.formatDate(tender.publicationDate)}</span>
                                        </div>
                                    </div>
                                </div>
                                {tender.deadline != null && <div className='date-text-grid'>
                                    <div className='date-text-item'>
                                        <div>
                                            <DeadlineBlueIcon/>
                                        </div>
                                        <div className={'date-text-item-label'}>
                                            <span>{t("publications.deadlineTitle") + ": "}</span>
                                        </div>
                                    </div>
                                    <div className='date-text-item'>
                                        <div>
                                            <span>{DateUtil.formatDateTime(tender.deadline)}</span>
                                        </div>
                                    </div>
                                </div>}
                                <TenderOverviewKeyValue label={t("publications.estimatedTotalValueTitle")}
                                                        content={getEstimatedTotalValue(tender)}/>
                                <TenderOverviewKeyValue label={t("publications.isFrameworkTitle")}
                                                        content={isFrameworkAgreement(tender, t)}/>
                                <TenderOverviewKeyValue label={t("publications.isShelteredWorkshopTitle")}
                                                        content={isShelteredWorkshop(tender, t)}/>
                                <TenderOverviewKeyValue label={t("publications.isShelteredProgramTitle")}
                                                        content={isShelteredProgram(tender, t)}/>
                                <TenderOverviewKeyValue label={t("publications.contractTypeTitle")}
                                                        content={t("codes.code_" + getContractType(tender))}/>
                                <TenderOverviewKeyValue label={t("publications.procedureTypeTitle")}
                                                        content={t("codes.code_" + getProcedureType(tender))}/>
                                <ContractingAuthorityOverviewKeyValue contractingAuthority={tender.contractingAuthority} languageIso={languageIso}/>
                                <TenderOverviewKeyValues label={t("publications.subjectCodesTitle")}
                                                         content={getCodeTranslations(tender.mainSubjectCodes, t)}/>
                                <TenderOverviewKeyValues label={t("publications.supplementarySubjectCodesTitle")}
                                                         content={getCodeTranslations(tender.additionalSubjectCodes, t)}/>
                                <TenderOverviewKeyValues label={t("publications.regionCodesTitle")}
                                                         content={getCodeTranslations(tender.regionCodes, t)}/>
                                <TenderOverviewKeyValues label={t("publications.accreditationsTitle")}
                                                         content={getAccreditationTranslations(tender.accreditations, t)}/>
                                <TenderOverviewKeyValue label={t("publications.descriptionTitle")}
                                                        content={MultilingualFieldUtil.translate(tender.description, languageIso)}/>
                                <FilesKeyValue tender={tender} languageIso={languageIso}/>
                            </div>
                            <div className='grid-items'>
                                <>
                                    <TenderDetailsSidebar tender={tender} languageIso={languageIso}/>
                                </>
                            </div>
                        </div>
                        <div className='globally-search-alignment'>
                            {tender.addenda !== null && tender.addenda.length > 0 &&
                                <Form onSubmit={() => {
                                    searchAddenda()
                                }}>
                                    <input type="text" maxLength={255} placeholder={t("publications.searchAddenda")}
                                           onChange={changeAddendaSearchQuery}/>
                                    <div className='icon-alignment'>
                                        <SearchIcon/>
                                    </div>
                                </Form>
                            }
                        </div>
                        {addendaHighlights.length > 0 &&
                            <AddendaHighlightKeyValues highlights={addendaHighlights}/>
                        }
                        {highlightSearchPerformed &&
                            addendaHighlights.length === 0 &&
                            <div className='grid'>
                                <div className='grid-items'>
                                    <div className='text-grid'>
                                        <div className='text-grid-items'>
                                            <label></label>
                                        </div>
                                        <div className='text-grid-items'>
                                            <div><p>{t("publications.noResults")}</p></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <>
                        <TenderDetailsCards tender={tender} languageIso={languageIso}/>
                    </>
                </div>
            </div>
        </>

    );
};

export function getEstimatedTotalValue(tender: Tender) {
    if (tender.priorInformationNotices != null && tender.priorInformationNotices.length > 0 && tender.priorInformationNotices[0].estimatedValue != null) {
        return getValue(tender.priorInformationNotices[0].estimatedValue);
    } else if (tender.contractNotices != null && tender.contractNotices.length > 0 && tender.contractNotices[0].estimatedValue != null) {
        return getValue(tender.contractNotices[0].estimatedValue);
    } else return null;
}

//returning "No" instead of null here makes the element show in either case
function isFrameworkAgreement(tender: Tender, t: TFunction) {
    if (tender.priorInformationNotices != null && tender.priorInformationNotices.length > 0 && tender.priorInformationNotices[0].isFramework != null) {
        return tender.priorInformationNotices[0].isFramework ? t("publications.true") : t("publications.false");
    } else if (tender.contractNotices != null && tender.contractNotices.length > 0 && tender.contractNotices[0].isFramework != null) {
        return tender.contractNotices[0].isFramework ? t("publications.true") : t("publications.false");
    } else return t("publications.false");
}

function isShelteredWorkshop(tender: Tender, t: TFunction) {
    if (tender.priorInformationNotices != null && tender.priorInformationNotices.length > 0 && tender.priorInformationNotices[0].isShelteredWorkshop != null) {
        return tender.priorInformationNotices[0].isShelteredWorkshop ? t("publications.true") : null;
    } else if (tender.contractNotices != null && tender.contractNotices.length > 0 && tender.contractNotices[0].isShelteredWorkshop != null) {
        return tender.contractNotices[0].isShelteredWorkshop ? t("publications.true") : null;
    } else return null;
}

function isShelteredProgram(tender: Tender, t: TFunction) {
    if (tender.priorInformationNotices != null && tender.priorInformationNotices.length > 0 && tender.priorInformationNotices[0].isShelteredProgram != null) {
        return tender.priorInformationNotices[0].isShelteredProgram ? t("publications.true") : null;
    } else if (tender.contractNotices != null && tender.contractNotices.length > 0 && tender.contractNotices[0].isShelteredProgram != null) {
        return tender.contractNotices[0].isShelteredProgram ? t("publications.true") : null;
    } else return null;
}

export function getValue(value: Value) {
    if (value == null) return null;
    if (value.valueExact != null && value.valueExact !== 0) return formatNumber(value.valueExact) + " " + value.currencyIso;
    if (value.valueRange != null && value.valueRange.from !== 0 && value.valueRange.to !== 0) return formatNumber(value.valueRange.from) + " - " + formatNumber(value.valueRange.to) + " " + value.currencyIso;
    if (value.valueText != null) return value.valueText;
    return null;
}

export function formatNumber(num: number) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(num);
}

function getProcedureType(tender: Tender) {
    return tender.procedureType ? tender.procedureType : "UNKNOWN";
}

function getContractType(tender: Tender) {
    return tender.contractType ? tender.contractType : "UNKNOWN";
}

export function translateContractingAuthority(contractingAuthority: ContractingAuthority, languageIso: string, t: TFunction) {
    let ca = contractingAuthority;
    if (ca == null) return "";
    let builder = [];
    if (ca.officialName != null) {
        builder.push(MultilingualFieldUtil.translate(ca.officialName, languageIso));
    }
    if (ca.address != null) builder.push(translateAddress(ca.address, languageIso, true));
    if (ca.contractingAuthorityType !== null && ca.contractingAuthorityType !== "OTHER") builder.push(t("contractingAuthorityTypes." + ca.contractingAuthorityType));
    if (ca.mainActivities != null && ca.mainActivities.length > 0) {
        let translations = [];
        for (const mainActivity of ca.mainActivities) {
            translations.push(t("contractingAuthorityMainActivities." + mainActivity))
        }
        builder.push(translations.join(", "))
    }
    if (ca.contactPoint != null) builder.push(ca.contactPoint)
    return builder.join(" | ");
}

export function translateAddress(address: Address, languageIso: string, includeZip: boolean) {
    let builder = "";
    if (address.zipCode != null && includeZip) builder += address.zipCode + " ";
    if (address.city != null) builder += MultilingualFieldUtil.translate(address.city, languageIso) + ", ";
    if (address.countryIso != null) builder += address.countryIso;
    return builder;
}

export function translateCompany(company: Company, languageIso: string) {
    let parts = []
    parts.push(MultilingualFieldUtil.translate(company.name, languageIso));
    if (company.address != null) parts.push(translateAddress(company.address, languageIso, false));
    if (parts.length === 0) return null;
    else return parts.join(", ");
}

export function getCodeTranslations(codeList: string[] | null, t: TFunction) {
    if (codeList != null && codeList.length > 0) {
        const uniqueCodeList = Array.from(new Set(codeList));//To remove duplicates
        let codeTranslations = []
        for (const code of uniqueCodeList) {
            codeTranslations.push(getCodeTranslation(code, t))
        }
        return codeTranslations
    } else return null;
}

export function getCodeTranslation(code: string, t: TFunction) {
    let translation = t("codes.code_" + code)
    if (translation != null && !translation.startsWith("codes.")) return code + " - " + translation;
    else return code;
}

export function getAccreditationTranslations(codeList: string[] | null, t: TFunction) {
    if (codeList != null && codeList.length > 0) {
        let codeTranslations = []
        for (const code of codeList) {
            let categoryAndClass = code.split("-");
            if (categoryAndClass.length !== 2) continue;
            let categoryTranslation = t("codes.code_" + categoryAndClass[0])
            if (categoryTranslation != null && !categoryTranslation.startsWith("codes.")) {
                codeTranslations.push(t("publications.accreditationCategoryTitle") + " " + categoryAndClass[0] + " - " + categoryTranslation);
            } else codeTranslations.push(t("publications.accreditationCategoryTitle") + " " + categoryAndClass[0]);
            codeTranslations.push(t("publications.accreditationClassTitle") + " " + categoryAndClass[1])
        }
        return codeTranslations
    } else return null;
}

export interface TenderOverviewKeyValueProps {
    label: string;
    content: string | null;
}

export interface TenderOverviewKeyValuesProps {
    label: string;
    content: string[] | null;
    innerHtml?: boolean;
}

/**
 * Todo merge TenderOverviewKeyValue and TenderDetailsKeyValue - <p> -> <span>
 * @param keyValueProps
 * @constructor
 */

const TenderOverviewKeyValue: React.FC<TenderOverviewKeyValueProps> = (keyValueProps) => {
    if (keyValueProps.content === null) return <></>;
    return (
        <>
            <div className='text-grid'>
                <div className='text-grid-items'>
                    <label>{keyValueProps.label + ":"}</label>
                </div>
                <div className='text-grid-items'>
                    <pre>{keyValueProps.content}</pre>
                </div>
            </div>
        </>
    );
};

const ContractingAuthorityOverviewKeyValue: React.FC<{contractingAuthority: ContractingAuthority, languageIso: string}> = ({contractingAuthority, languageIso}) => {
    let {t} = useTranslation();
    let description = translateContractingAuthority(contractingAuthority, languageIso, t);
    let vat = CompanyUtil.findKboVatNumber(contractingAuthority?.vatNumber);
    let ca: CompanyNameWithVat = {name: description, vat: vat, showFollowButton: false};
    return (
        <>
            <div className='text-grid'>
                <div className='text-grid-items'>
                    <label>{t("publications.contractingAuthorityTitle") + ":"}</label>
                </div>
                <div className='text-grid-items'>
                    <pre><CompanyLink c={ca}/></pre>
                </div>
            </div>
        </>
    );
}

export const TenderOverviewKeyValues: React.FC<TenderOverviewKeyValuesProps> = (keyValuesProps) => {
    const [showAll, setShowAll] = useState(false);
    const toggleShowAll = () => setShowAll(!showAll);
    if (keyValuesProps.content === null) return <></>;
    let values = keyValuesProps.content;
    if (!showAll) values = values.slice(0, 3)
    return (
        <>
            <div className='text-grid'>
                <div className='text-grid-items'>
                    <label>{keyValuesProps.label + ":"}</label>
                </div>
                <div className='text-grid-items'>
                    {!keyValuesProps.innerHtml && values.map((value, i) => (
                        <p key={i}>{value}</p>
                    ))}
                    {keyValuesProps.innerHtml && values.map((value, i) => (
                        <p dangerouslySetInnerHTML={{__html: value}} key={i}></p>
                    ))}
                    {keyValuesProps.content.length > 3 &&
                      <p className='cursor-pointer' onClick={toggleShowAll}>...</p>
                    }
                </div>
            </div>
        </>
    );
};

export const FilesKeyValue: React.FC<TenderDetailsProps> = ({tender}) => {
    const {t} = useTranslation();
    const [showAll, setShowAll] = useState(false);
    const toggleShowAll = () => setShowAll(!showAll);
    let files: S3FileDocument[] = tender.addenda != null ? tender.addenda : [];
    if (files.length === 0) {
        let publicationInformations = TenderUtil.getPublicationInformationsSorted(tender);
        let externalAddendumUrls = publicationInformations
            .flatMap(p => p.publicationInformation.files)
            .filter(f => f.type === "EXTERNAL_ADDENDUM_URL");
        if (externalAddendumUrls.length > 0){
            return <ExternalAddendumUrlKeyValue externalAddendumUrls={externalAddendumUrls}/>
        } else return <></>;
    } else {
        files = S3FileUtil.filterDownloadableFiles(files);
        files = S3FileUtil.prioritizeSpecifications(files);
    }
    return (
        <>
            <div className='text-grid'>
                <div className='text-grid-items'>
                    <label>{files.length + " " + t("publications.filesTitle") + ": "}</label>
                </div>
                <div className='text-grid-items'>
                    <FilesSection tenderUuid={tender.uuid} files={files} showAll={showAll}/>
                    {files.length > 3 &&
                        <p className='cursor-pointer' onClick={toggleShowAll}>...</p>
                    }
                </div>
            </div>
        </>
    );
}

interface ExternalAddendumUrlKeyValueProps {
    externalAddendumUrls: S3FileDocument[];
}
const ExternalAddendumUrlKeyValue: React.FC<ExternalAddendumUrlKeyValueProps> = ({externalAddendumUrls}) => {
    const {t} = useTranslation();
    if (externalAddendumUrls.length === 0 || externalAddendumUrls[0].url === undefined) return <></>;
    let externalAddendumUrl = externalAddendumUrls[0].url;
    if(!externalAddendumUrl.startsWith("http")) externalAddendumUrl = "https://" + externalAddendumUrl;
    return <>
        <div className='text-grid'>
            <div className='text-grid-items'>
                <label>{t("publications.externalAddendumUrlTitle") + ": "}</label>
            </div>
            <div className='text-grid-items'>
                <p className='cursor-pointer' key={externalAddendumUrl}>
                    <a className='hover-highlight' href={externalAddendumUrl} target="_blank" rel="noreferrer noopener">{externalAddendumUrl}</a>
                </p>
            </div>
        </div>
    </>
}
interface FilesProps {
    tenderUuid: string;
    files: S3FileDocument[];
    showAll: boolean;
}

export const FilesSection: React.FC<FilesProps> = ({tenderUuid, files, showAll}) => {
    if (!showAll) files = files.slice(0, 3)
    return <>
        {files.map((f, i) => <FileSection tenderUuid={tenderUuid} file={f} key={f.fileName}/>)}
    </>
}

interface FileSectionProps {
    tenderUuid: string;
    file: S3FileDocument;
}
export const FileSection: React.FC<FileSectionProps> = ({tenderUuid, file}) => {
    const dispatch = useAppDispatch();
    const [postDownload, {isSuccess, isLoading, isError, error}] = usePostDownloadFileMutation();
    const fileName = S3FileUtil.extractFileName(file);
    useEffect(() => {
        if(isError && error) dispatch(showErrorPopup(error));
    }, [isError, error])
    return <>
        {isLoading && <Loader/>}
        <p className='cursor-pointer' key={fileName} onClick={() => postDownload({tenderUuid: tenderUuid, file: file})}>
            {fileName}
        </p>
    </>
}

interface AddendaHighlightProps {
    highlights: TenderSearchHighlightedAddendum[]
}

export const AddendaHighlightKeyValues: React.FC<AddendaHighlightProps> = ({highlights}) => {
    const {t} = useTranslation();
    if (highlights.length === 0) return <></>;
    let distinctHighlights = removeHighlightsFromSameFile(highlights); //this should actually be the default behavior but duplicates keep slipping in for some reason
    return (
        <>
            <div className='addenda-results'>
                <div className='addenda-result-item'>
                    {
                        distinctHighlights.map((addendum, i) => (
                            <div key={i}>
                                {/*zip files should be downloaded via the full project*/}
                                {addendum.downloadUrl.endsWith(".zip") &&
                                    <p className={'files-button-disabled bold'}>{addendum.fileName}</p>
                                }
                                {!addendum.downloadUrl.endsWith(".zip") &&
                                    <p className={'files-button bold'}><a
                                        href={addendum.downloadUrl} target="_blank"
                                        title={t("publications.downloadFileTitle")}
                                        rel="noopener noreferrer">{addendum.fileName}</a>
                                    </p>
                                }

                                {addendum.highlights.map((highlight, j) => (
                                    <AddendaHighlightValue highlightString={highlight} key={j}/>
                                ))}
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    );
};

interface AddendaHighlightValueProps {
    highlightString: string;
    key: number;
}

export const AddendaHighlightValue: React.FC<AddendaHighlightValueProps> = ({highlightString, key}) => {
    const [showFullHighlight, setShowFullHighlight] = useState<boolean>(false);
    const getHighlight = () => {
        let highlight = highlightString;
        if (!showFullHighlight) highlight = extractMiddle(highlightString, 300);
        return "..." + highlight.replaceAll("em>", "b>") + "...";
    }
    let toggleHighlight = () => {setShowFullHighlight(!showFullHighlight)}
    return <p className='padding-left'
              style={{cursor: !showFullHighlight ? "zoom-in": "zoom-out"}}
              onClick={toggleHighlight}
              dangerouslySetInnerHTML={{__html: getHighlight()}}
              key={key}></p>
}

function extractMiddle(str: string, window: number): string {
    let center = Math.ceil(str.length / 2);
    let offset = Math.ceil(window / 2);
    return str.substr(center - offset, window);
}


function removeHighlightsFromSameFile(highlights: TenderSearchHighlightedAddendum[]) {
    let files: string[] = [];
    let distinctHighlights: TenderSearchHighlightedAddendum[] = [];
    for (const h of highlights) {
        if (!files.includes(h.fileName)) {
            files.push(h.fileName);
            distinctHighlights.push(h)
        }
    }
    return distinctHighlights;
}