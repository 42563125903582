import { Label, LabelCategory } from "../../../../types/label";
import React from "react";
import { useAppDispatch } from "../../../../app/hooks";
import { RaiseUser } from "../../../../hooks/raiseUser";
import { updateSearchFilters } from "../../../../hooks/slices/tenderSearchSlice";
import { LabelsUtil } from "../../../../utils/labels";

interface LabelsCheckBoxProps {
    selectedLabels: Label[];
    getLabelAggregation: (labelName: string) => string;
}

export const LabelsCheckBox: React.FC<LabelsCheckBoxProps> = (props) => {
    const dispatch = useAppDispatch();
    const userInStore = RaiseUser();
    const labelCategories: LabelCategory[] = userInStore.user && userInStore.user.labelCategories ? userInStore.user.labelCategories : [];
    let userInteractions = RaiseUser().user?.userInteractions ?? [];
    let allLabels: Label[] = LabelsUtil.getAllLabels(labelCategories);
    allLabels = allLabels
        .filter(l => l.count) //not undefined and not 0
        .sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

    const toggleLabel = (label: Label) => {
        if (props.selectedLabels.includes(label)) {
            let newSelectedLabels = props.selectedLabels.filter(l => l.id !== label.id);
            dispatch(updateSearchFilters(({labelFilters: newSelectedLabels, userInteractions: userInteractions})));
        } else {
            let newSelectedLabels = [...props.selectedLabels, label];
            dispatch(updateSearchFilters(({labelFilters: newSelectedLabels, userInteractions: userInteractions})));
        }
    }
    const isChecked = (label: Label) => {
        return props.selectedLabels.map(sl => sl.id).includes(label.id);
    }
    const translate = (labelDto: Label) => {
        let aggregation = props.getLabelAggregation(labelDto.name);
        return labelDto.name + aggregation;
    }

    return <>
        <div className='checkbox-button-relative'>

            <div className='checkbox-label-alignment'>
                <p>Labels</p>
            </div>

            {allLabels.map((l) => (
                <div key={l.id}>
                    <input type="checkbox" id={l.name + "-" + l.id}
                           name="checkbox"
                           onChange={(e) => toggleLabel(l)}
                           checked={isChecked(l)}/>
                    <label htmlFor={l.name + "-" + l.id}>{translate(l)}</label>
                </div>
            ))}
        </div>
    </>
}