import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../../app/store";

const API_URL = process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL : window.API_URL;
console.log(process.env.NODE_ENV, API_URL, window.API_URL);

export const apiSlice = createApi({
    reducerPath: "api",
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL,
        // https://redux-toolkit.js.org/rtk-query/api/fetchBaseQuery#setting-default-headers-on-requests
        prepareHeaders: (headers, { getState }) => {
            const authToken = (getState() as RootState).login.rawToken;

            // If we have a token set in state, let's assume that we should be passing it.
            if (authToken) {
                headers.set("authorization", `Bearer ${authToken}`);
            }

            return headers;
        },
    }),
    // all our endpoints will be injected by various features, so no need to specify them here
    endpoints: (_) => ({}),
    tagTypes: ["Addenda", "Details"],
});
