import {ResourceKey} from "i18next";

export const Error: ResourceKey = {
	oopsSomethingWentWrong: "Oops, something went wrong!",
	error_400: "You've sent a bad request.",
	error_401: "You are not authorized to view this page.",
	error_403: "You don't have permission to access this resource.",
	error_404: "The page you were looking for does not exist.",
	error_500: "We had technical problems during your last operation.",
	error_503: "All of our servers are busy right now.",
};