import {createSlice, PayloadAction, SerializedError} from "@reduxjs/toolkit";
import {PURGE} from "redux-persist";
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {APIError} from "../../utils/error";

/*
  REDUX STORE stuff
*/
export interface SnaccState {
    open: boolean;
    severity: "error" | "warning" | "info" | "success";
    message: string;
}

const initialState: SnaccState = {
    open: false,
    severity: "info",
    message: "",
};

export const snaccSlice = createSlice({
    initialState,
    name: "snacc",
    reducers: {
        updateSnacc(state, action: PayloadAction<SnaccState>) {
            state.open = action.payload.open;
            state.severity = action.payload.severity;
            state.message = action.payload.message;
        }, showErrorPopup(state, action: PayloadAction<FetchBaseQueryError | SerializedError>){
            state.open = true;
            state.severity = "error";
            state.message = APIError(action.payload);
        }, showCustomErrorPopup(state, action: PayloadAction<string>){
            state.open = true;
            state.severity = "error";
            state.message = action.payload;
        }, showCustomInfoPopup(state, action: PayloadAction<string>){
            state.open = true;
            state.severity = "info";
            state.message = action.payload;
        }, showCustomSuccessPopup(state, action: PayloadAction<string>){
            state.open = true;
            state.severity = "success";
            state.message = action.payload;
        }, closeSnacc(state) {
            state.open = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, (state) => {
            state.open = initialState.open;
            state.severity = initialState.severity;
            state.message = initialState.message;
        });
    },
});

export const {updateSnacc, closeSnacc, showErrorPopup, showCustomErrorPopup, showCustomInfoPopup, showCustomSuccessPopup} = snaccSlice.actions;
