import React, { useEffect, useState } from "react";
import "./signup-details.scss";
import { useAppDispatch } from "../../../app/hooks";
import {useNavigate, useSearchParams} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { showCustomInfoPopup, showErrorPopup } from "../../../hooks/slices/snaccSlice";
import { Country } from "../../../consts/countries";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { usePostSignupDetailsMutation } from "../../../hooks/slices/signupSlice";
import { Loader } from "../../../components/loader";
import { t } from "i18next";
import { CompanySuggestion, CompanySuggestionsSource } from "../../../hooks/slices/companySlice";
import { CompanyDropdown } from "../../../components/dropdown";
import { RaiseClient } from "../../../hooks/raiseClient";
import { Client } from "../../../types/client";
import {updateActivityStatus, updateUserInteractions} from "../../../hooks/slices/userSlice";
import { updateClient } from "../../../hooks/slices/clientSlice";
import {SearchParamUtil} from "../../../utils/searchparams";
import { useSignupErrorHandler } from "../SignupErrorHandler";
import { useNavigateToOpportunitiesIfSignupAlreadyFinished } from "../SignupFinishedHandler";
import {Language} from "../../../consts/languages";
import {UserLanguage} from "../../../hooks/raiseUser";
import {MultilingualFieldUtil} from "../../../utils/multilingualfield";

export const SignupDetails: React.FC = () => {
  useNavigateToOpportunitiesIfSignupAlreadyFinished();
  const dispatch = useAppDispatch();
  const clientInStore = RaiseClient();
  const [postSignupDetails, { data: response, isSuccess, isError, error }] = usePostSignupDetailsMutation();
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const onSubmit = (
    companyName: string,
    vatNumber: string,
    country: string | null | undefined,
    website: string,
  ) => {
    setShowLoader(true);
    postSignupDetails({companyName, vatNumber, country, website });
  };
  const navigateOrShowPopup = useSignupErrorHandler();

  useEffect(() => {
    if (isSuccess && response) {
      setShowLoader(false);
      //UPDATES CLIENT
      if(response.client) dispatch(updateClient(response.client));
      //ADDS SHORTLIST AUTOCOMPLETE TO THE STORE
      if (response.shortlist.length > 0) dispatch(updateUserInteractions(response.shortlist));
      //DIFFERENT THIRD SIGNUP PAGES: survey or countries or search
      if (response.client.clientType === "GOVERNMENT") {
        dispatch(updateActivityStatus({ activityStatus: "ACTIVE" }));
        if (searchParams.get("tenderUuid") != null) navigate("/tender/" + searchParams.get("tenderUuid"));
        else navigate("/search");
      } else if (response.companyPageUuid !== undefined && response.companyPageUuid !== ""){
        navigate("/signup-survey/" + response.companyPageUuid + SearchParamUtil.passOnSearchParams(searchParams));
      }  else navigate("/signup-countries" + SearchParamUtil.passOnSearchParams(searchParams));
    } else if (isError && error) {
      setShowLoader(false);
      navigateOrShowPopup(error);
    }
  }, [response, isSuccess, isError, error, dispatch]);

  const countryDropdownElements: CountryDropdownElement[] = [];
  for (const country of Object.values(Country)) {
    countryDropdownElements.push({ value: country, label: t("countries." + country) });
  }
  if (clientInStore.isLoading || clientInStore.client === undefined) return <></>;
  else
    return (
      <>
        {showLoader && <Loader />}
        {/*<FindCompaniesAutoComplete></FindCompaniesAutoComplete>*/}
        <SignupDetailsForm
          client={clientInStore.client}
          onSubmit={onSubmit}
          countryDropdownElements={countryDropdownElements}
        />
      </>
    );
};

interface CountryDropdownElement {
  value: string;
  label: string;
}

interface SignupDetailsFormProps {
  client: Client;
  countryDropdownElements: CountryDropdownElement[];
  onSubmit: (
    companyName: string,
    vatNumber: string,
    country: string | null | undefined,
    website: string,
  ) => void;
}

const SignupDetailsForm: React.FC<SignupDetailsFormProps> = ({ client, onSubmit, countryDropdownElements }) => {
  const { t, i18n } = useTranslation();
  const language: Language = UserLanguage(i18n.language);
  const [companyName, setCompanyName] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [country, setCountry] = React.useState<string>("BE");
  const [website, setWebsite] = useState("");
  const [companySelected, setCompanySelected] = useState(false);
  let governmentClient = client.clientType === "GOVERNMENT";
  let showCompanyAutocomplete = country === "BE" && !governmentClient;
  const autocomplete = (suggestion: CompanySuggestion) => {
    setCompanyName(MultilingualFieldUtil.translate(suggestion.name, language));
    setVatNumber(suggestion.vatNumber);
    setWebsite( "");
    setCompanySelected(true);
  };
  const reset = () => {
    setCompanyName("");
    setVatNumber("");
    setWebsite("");
    setCompanySelected(false);
  };
  const changeCountryAndReset = (country: string) => {
    reset();
    setCountry(country);
  };
  const showNextButton =
    companySelected ||
    (country !== "BE" && companyName.trim() !== "" && vatNumber.trim() !== "") ||
    (governmentClient && companyName.trim() !== "");
  return (
    <div>
      <div className="layout-main-banner">
        <div className="container">
          <div className="intake-second-section">
            <h1>{t("intake.second_page_title")}</h1>
              <div className="input-type-bottom-alignment">
                {!governmentClient && (
                  <CountryAutoComplete
                    countryDropdownElements={countryDropdownElements}
                    country={country}
                    setCountry={changeCountryAndReset}
                  />
                )}
                {showCompanyAutocomplete && (
                  <CompanyDropdown
                    placeholder={t("intake.company_name")}
                    onChange={(s) => setCompanyName(s)}
                    autocomplete={(s) => autocomplete(s)}
                    reset={reset}
                    companySelected={companySelected}
                    source={CompanySuggestionsSource.KBO}
                  />
                )}
                {!showCompanyAutocomplete && (
                  <div className="input">
                    <input
                      type="text"
                      maxLength={255}
                      onChange={(e) => setCompanyName(e.currentTarget.value)}
                      placeholder={t("intake.company_name")}
                      value={companyName}
                      data-testid={"signup-company-name-input"}
                    />
                  </div>
                )}
                <div className="input">
                  <input
                    type="text"
                    maxLength={255}
                    onChange={(e) => setVatNumber(e.currentTarget.value)}
                    placeholder={governmentClient ? t("intake.vat_number_government") : t("intake.vat_number_default")}
                    value={vatNumber}
                    readOnly={companySelected}
                    data-testid={"signup-company-vat-input"}
                  />
                </div>
                <div className="input">
                  <input
                    type="text"
                    maxLength={255}
                    onChange={(e) => setWebsite(e.currentTarget.value)}
                    placeholder={governmentClient ? t("intake.website_government") : t("intake.website_default")}
                    value={website}
                    data-testid={"signup-company-website-input"}
                  />
                </div>
              </div>
              <div className="button-alignment-footer">
                <div className={showNextButton ? "fill-button" : "fill-button disabled"}>
                  <button onClick={() => onSubmit(companyName, vatNumber, country, website)} data-testid={"signup-company-info-submit"}>
                    {t("intake.next")}
                  </button>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface CountryAutoCompleteProps {
  countryDropdownElements: CountryDropdownElement[];
  country: string;
  setCountry: (country: string) => void;
}

const CountryAutoComplete: React.FC<CountryAutoCompleteProps> = ({ country, setCountry, countryDropdownElements }) => {
  return (
    <div className="input">
      <Autocomplete
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            border: "0 none",
          },
          "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
            padding: "0 16px",
          },
          "& .MuiOutlinedInput-root": {
            padding: 0,
          },
        }}
        disablePortal
        autoHighlight
        id="combo-box-demo"
        data-testid={"country-autocomplete"}
        options={countryDropdownElements}
        value={countryDropdownElements.find((element) => element.value === country)}
        renderInput={(params) => <TextField {...params} hiddenLabel placeholder={t("intake.country")} />}
        onChange={(event: any, country: CountryDropdownElement | null) => {
          if (country != null) {
            setCountry(country.value);
          }
        }}
      />
    </div>
  );
};
