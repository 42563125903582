import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {apiSlice} from "./apiSlice";
import {FetchArgs} from "@reduxjs/toolkit/query";
import {Feature} from "../../consts/features";
import {PURGE} from "redux-persist";

/*
  REDUX STORE stuff
*/

export interface CurrentPlanState {
    currentPlan?: PlanDto;
}

const initialState: CurrentPlanState = {
    currentPlan: undefined
}

// id: number;
//     planTemplateId: number;
//     name: string;
//     type: string;
//     startDate: string; //format dd-MM-yyyy
//     endDate: string; //format dd-MM-yyyy
//     features: Feature[];
//     featuresToRemove: Feature[];
//     fallBackPlanTemplateId: number;
//     price: number;
export const currentPlanSlice = createSlice({
    initialState,
    name: "currentPlan",
    reducers: {
        updateCurrentPlan(state, action: PayloadAction<CurrentPlanState>) {
            if (action.payload.currentPlan) state.currentPlan = action.payload.currentPlan;
        }, updateFeatures(state, action: PayloadAction<UpdateFeaturesParams>) {
            if (state.currentPlan) {
                state.currentPlan.features = action.payload.features;
                state.currentPlan.featuresToRemove = action.payload.featuresToRemove;
            }
        }
    }, extraReducers: (builder) => {
        builder.addCase(PURGE, (state) => {
            state.currentPlan = initialState.currentPlan;
        });
    }
});

export const {updateCurrentPlan, updateFeatures} = currentPlanSlice.actions;

/*
  API connection stuff
*/

export interface GetClientParams {
    uuid: string;
}

export interface PutFeaturesParams {
    uuid: string;
    features: Feature[];
    featuresToRemove: Feature[];
}

export interface UpdateFeaturesParams {
    features: Feature[];
    featuresToRemove: Feature[];
}

export interface GetAllFeaturesResponse {
    features: Feature[];
}

export interface PlanDto {
    id: number;
    planTemplateId: number;
    name: string;
    type: string;
    startDate: string; //format dd-MM-yyyy
    endDate: string; //format dd-MM-yyyy
    features: Feature[];
    featuresToRemove: Feature[];
    fallBackPlanTemplateId: number;
    price: number;
}

export interface PlanTemplateDto {
    id: number;
    duration: PlanTemplateDurationDto;
    dutchName: string;
    englishName: string;
    frenchName: string;
    features: Feature[],
    price: number;
    type: string;
}

export interface PlanTemplateDurationDto {
    number: number;
    type: string;
}

export interface GetPlanTemplatesResponse {
    planTemplates: PlanTemplateDto[];
}

export interface GetPlanChangeInfoResponse {
    upgrade: boolean;
}

export interface AddPlanInput {
    extraFeatures: Feature[],
    planTemplateId: number,
    priceInEuro: number
}

export interface CurrentAndNextPlanDto {
    currentPlan: PlanDto;
    nextPlan?: PlanDto;

}

const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        getAllFeatures: build.query<GetAllFeaturesResponse, void>({ //'featuresToRemove' will always be empty here
            query: () => {
                return {
                    url: `/shaggy/plans/features`,
                };
            },
        }),
        getAllPlanTemplates: build.query<GetPlanTemplatesResponse, void>({ //'featuresToRemove' will always be empty here
            query: () => {
                return {
                    url: `/shaggy/plans/templates`,
                };
            },
        }),
        putFeatures: build.mutation<PlanDto, PutFeaturesParams>({
            query: (params: PutFeaturesParams) => {
                let args: FetchArgs = {
                    url: `/shaggy/client/features`,
                    method: "PUT",
                    body: {features: params.features, featuresToRemove: params.featuresToRemove},
                };
                return args;
            },
        }),
        getCurrentPlan: build.query<PlanDto, GetClientParams>({
            query: () => {
                return {
                    url: `/shaggy/client/current_plan`,
                };
            },
        }),
        getCurrentAndNextPlan: build.query<CurrentAndNextPlanDto, void>({
            query: () => {
                return {
                    url: `/shaggy/client/current_and_next_plan`,
                };
            },
        }),
        getAvailablePlanTemplates: build.query<GetPlanTemplatesResponse, void>({
            query: () => {
                return {
                    url: `/shaggy/client/available_plan_templates`,
                };
            },
        }),
        getPlanChangeInfo: build.query<GetPlanChangeInfoResponse, number>({
            query: (planTemplateId: number) => {
                return {
                    url: `/shaggy/client/plan_change_info/` + planTemplateId,
                };
            },
        }),
        postPlan: build.mutation<CurrentAndNextPlanDto, AddPlanInput>({
            query: (addNewPlanInput) => {
                return {
                    url: `/shaggy/client/plans`,
                    method: "POST",
                    body: addNewPlanInput
                }
            }
        }), postRenewTrial: build.mutation<CurrentAndNextPlanDto, void>({
            query: () => {
                return {
                    url: `/shaggy/client/renew-trial`,
                    method: "POST"
                }
            }
        }),
    }),
});

export const {
    useGetAllFeaturesQuery,
    usePutFeaturesMutation,
    useGetCurrentPlanQuery,
    useGetCurrentAndNextPlanQuery,
    useGetAvailablePlanTemplatesQuery,
    useGetAllPlanTemplatesQuery,
    useGetPlanChangeInfoQuery,
    usePostPlanMutation,
    usePostRenewTrialMutation
} = extendedApiSlice;
