import {ResourceKey} from "i18next";

export const Footer: ResourceKey = {
	follow_us: "Volg ons",
	contact_us: "Contacteer ons",
	office_ghent: "Kantoor Gent",
	office_brussels: "Kantoor Brussel",
	auguste_reyerslaan: "Auguste Reyerslaan",
	ghent: "Gent",
    brussels: "Brussel",
	help: "Help",
	helpdesk: "Helpdesk",
	list_of_concepts: "Begrippenlijst",
	blog: "Blog",
	legal: "Juridisch",
	privacy_policy: "Privacy-beleid ",
	terms: "Gebruikersvoorwaarden",
}