export enum Language {

    NL = "NL",
    FR = "FR",
    EN = "EN",
    DE = "DE",
    EL = "EL",
    ES = "ES",
    ET = "ET",
    FI = "FI",
    GA = "GA",
    HR = "HR",
    HU = "HU",
    IT = "IT",
    LT = "LT",
    LV = "LV",
    MT = "MT",
    PL = "PL",
    PT = "PT",
    RO = "RO",
    SK = "SK",
    SL = "SL",
    SV = "SV",
    BG = "BG",
    CS = "CS",
    DA = "DA"
}

export namespace LanguageParser {

    export function toString(language: Language): string {
        return Language[language];
    }

    export function fromString(language: string): Language {
        return (Language as any)[language.toUpperCase()];
    }

    export function isos(): string[]{
        return Object.values(Language);
    }
}