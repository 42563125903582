import {apiSlice} from "./apiSlice";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface EmailPageState {
    email?: string;
    mailFrequency?: EmailFrequency;
    mailIncludesAwards?: boolean;
    additionalAddressees?: string[];
}

const initialState: EmailPageState = {};

export const userEmailPageSlice = createSlice({
    initialState,
    name: "emailPage",
    reducers: {
        updateEmailPage(state, action: PayloadAction<EmailSettings>) {
            state.email = action.payload.email;
            state.mailFrequency = action.payload.mailFrequency;
            state.mailIncludesAwards = action.payload.mailIncludesAwards;
            state.additionalAddressees = action.payload.additionalAddressees;
        },
    },
});


export const {updateEmailPage} = userEmailPageSlice.actions;

export interface EmailSettings {
    email: string;
    mailFrequency: EmailFrequency;
    mailIncludesAwards: boolean;
    additionalAddressees: string[];
}

export enum EmailFrequency {
    DAILY="DAILY",NEVER="NEVER"
}

export interface GetEmailPageParams {
    uuid: string
}

export interface PutEmailPageParams {
    uuid: string,
    body: EmailSettings;
}

export interface UnsubscribeParams {
    uuid: string,
}

const extendedApiSlice = apiSlice.injectEndpoints({

    endpoints: (build) => ({
        getEmailPage: build.query<EmailSettings, GetEmailPageParams>({
            query: (params: GetEmailPageParams) => {
                return {
                    url: `/shaggy/user/email_settings`,

                };
            },
        }),
        putEmailPage: build.mutation<EmailSettings, PutEmailPageParams>({
            query: (params: PutEmailPageParams) => {
                return {
                    url: `shaggy/user/email_settings`,
                    method: 'PUT',
                    body: params.body
                }
            }
        }),
        postUnsubscribe: build.mutation<EmailSettings, UnsubscribeParams>({
            query: (params: UnsubscribeParams) => {
                return {
                    url: `shaggy/user/unsubscribe`,
                    method: 'POST',
                    body: params.uuid
                }
            }
        }),
    }),
});

export const {useGetEmailPageQuery, usePutEmailPageMutation, usePostUnsubscribeMutation} = extendedApiSlice;