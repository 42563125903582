import {FetchArgs} from "@reduxjs/toolkit/dist/query";
import {S3FileDocument, Tender} from "../../types/tender";
import {apiSlice} from "./apiSlice";
import {createSlice} from "@reduxjs/toolkit";
import {SignupCountriesPagePostParams} from "./signupSlice";

/*
  REDUX STORE stuff
*/


/*
  API connection stuff
*/
interface FeedbackParams{
    name: string;
    emailAddress: string;
    company: string;
    message: string;
}


const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        postFeedback: build.mutation<void, FeedbackParams>({
            query: (body: FeedbackParams) => {
                return {
                    url: `/shaggy/feedback`,
                    method: "POST",
                    body: body,
                };
            },
        }),
    })
});

export const {endpoints, usePostFeedbackMutation} = extendedApiSlice;