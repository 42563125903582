import {SearchFilter} from "../../../../consts/SearchFilter";
import React from "react";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {updateSearchFilters} from "../../../../hooks/slices/tenderSearchSlice";
import {RaiseUser} from "../../../../hooks/raiseUser";

interface SearchFilterToggleProps {
    label?: string;
    relevantFilters: SearchFilter[];
    frozen?: boolean;
    addOrRemoveSearchFilter: (element: SearchFilter, currentlySelectedFilters: SearchFilter[]) => SearchFilter[];
}

export const SearchFilterToggle: React.FC<SearchFilterToggleProps> = (props) => {
    const dispatch = useAppDispatch();
    const searchFilters = useAppSelector((state) => state.tenderSearch.searchFilters);
    let userInteractions = RaiseUser().user?.userInteractions ?? [];
    const select = (filters: SearchFilter[]) => {
        if (!props.frozen) {
            let newSelectedFilters = searchFilters.selectedFilters;
            for (const f of filters){
                newSelectedFilters = props.addOrRemoveSearchFilter(f, newSelectedFilters);
            }
            dispatch(updateSearchFilters({selectedFilters: newSelectedFilters, userInteractions: userInteractions}));
        }
    }
    const selected = () => {
        for (const f of props.relevantFilters) {
            if (!searchFilters.selectedFilters.includes(f)) return false;
        } return true;
    }
    return <>
        <div className="checkbox-singleton-alignment">
            <div>
                <span>{props.label}</span>
            </div>
            <div>
                <label className="switch">
                    <input id="checkboxinp" type="checkbox"
                           checked={selected()}
                           onChange={() => select(props.relevantFilters)}></input>
                    <div className="slider round"></div>
                </label>
            </div>
        </div>
    </>
}

interface GenericFilterToggleProps {
    label?: string;
    checked: boolean;
    update: () => void;
}
export const GenericFilterToggle: React.FC<GenericFilterToggleProps> = (props) => {
    return <>
        <div className="checkbox-singleton-alignment">
            <div>
                <span>{props.label}</span>
            </div>
            <div>
                <label className="switch">
                    <input id="checkboxinp" type="checkbox"
                           checked={props.checked}
                           onChange={() => props.update()}></input>
                    <div className="slider round"></div>
                </label>
            </div>
        </div>
    </>
}