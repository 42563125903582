import { SearchSidebarConfiguration } from "../../GenericSearchPage";
import { SearchFilter } from "../../../../consts/SearchFilter";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { Feature } from "../../../../consts/features";
import { RaiseCurrentPlan } from "../../../../hooks/raiseCurrentPlan";
import { Moment } from "moment/moment";
import { SearchFilterUpdateProps, updateSearchFilters } from "../../../../hooks/slices/tenderSearchSlice";
import { DateUtil } from "../../../../utils/date";
import { Dropdown } from "./Dropdown";
import { CustomDateRangePicker } from "./CustomDateRangePicker";
import { SearchFilterUtil } from "../../../../utils/searchFilters";
import {RaiseUser} from "../../../../hooks/raiseUser";
import getDateString = DateUtil.getDateString;
import sortPublicationDateFilters = SearchFilterUtil.sortPublicationDateFilters;

interface PublicationDateRangePickerProps {
  searchSidebarConfiguration: SearchSidebarConfiguration;
  getAggregation: (searchFilter: SearchFilter) => string;
  changeDropdownFilter: (
    newFilter: SearchFilter,
    dropdownFilters: SearchFilter[],
    currentlySelectedFilters: SearchFilter[]
  ) => SearchFilter[];
}

export const PublicationDateRangePicker: React.FC<PublicationDateRangePickerProps> = (props) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const searchFilters = useAppSelector((state) => state.tenderSearch.searchFilters);
    let userInteractions = RaiseUser().user?.userInteractions ?? [];
    const features: Feature[] | undefined = RaiseCurrentPlan().currentPlan?.features;
    let earliestStartDate: Moment | undefined = SearchFilterUtil.getEarliestStartDateForUser(features);
    const [customDatePopupOpen, setCustomDatePopupOpen] = React.useState(false);
    if(earliestStartDate !== undefined) {
        updateSearchFilters({
            startPublicationDate: getDateString(earliestStartDate),
            userInteractions: userInteractions});
    }

    const updateDateSearchFilters = (searchFilters: SearchFilterUpdateProps) => {
        if (searchFilters.selectedFilters) {
            if (searchFilters.selectedFilters.includes(SearchFilter.PUBLICATION_DATE_CUSTOM)) {
                setCustomDatePopupOpen(true);
            }
            dispatch(updateSearchFilters({
                selectedFilters: searchFilters.selectedFilters,
                userInteractions: userInteractions
            }));
        }
    }

    const setCustomStartDate = (startDate :Moment) => {
        if (startDate.isValid() && (earliestStartDate === undefined || startDate.isSameOrAfter(earliestStartDate))) {
            let newSearchFilters = searchFilters.selectedFilters.filter(f => !SearchFilterUtil.isPublicationDateFilter(f))
            dispatch(updateSearchFilters({
                selectedFilters: [...newSearchFilters, SearchFilter.PUBLICATION_DATE_CUSTOM],
                startPublicationDate: DateUtil.getDateString(startDate),
                userInteractions: userInteractions}
            ));
        }
    }
    const setCustomEndDate = (endDate :Moment) => {
        if (endDate.isValid()) {
            let newSearchFilters = searchFilters.selectedFilters.filter(f => !SearchFilterUtil.isPublicationDateFilter(f))
            dispatch(updateSearchFilters({
                selectedFilters: [...newSearchFilters, SearchFilter.PUBLICATION_DATE_CUSTOM],
                endPublicationDate: DateUtil.getDateString(endDate),
                userInteractions: userInteractions}
            ));
        }
    }
    let relevantPublicationDateFilters = SearchFilterUtil.getRelevantPublicationDateFilters(earliestStartDate, props.searchSidebarConfiguration.queryType);
    let selectedDateFilter = relevantPublicationDateFilters.filter(f => searchFilters.selectedFilters.includes(f));
    relevantPublicationDateFilters = sortPublicationDateFilters(relevantPublicationDateFilters, selectedDateFilter.length === 1 ? selectedDateFilter[0] : undefined);
    return <>
        <Dropdown label={t("publications.publicationDateTitle")}
                  relevantFilters={relevantPublicationDateFilters}
                  updateSearchFilters={updateDateSearchFilters}
                  searchSidebarConfiguration={props.searchSidebarConfiguration}
                  getAggregation={props.getAggregation}
                  changeDropdownFilter={props.changeDropdownFilter}
        />
        {customDatePopupOpen &&
            <CustomDateRangePicker
                defaultStart={DateUtil.findDateMoment(searchFilters.startPublicationDate)}
                minStart={earliestStartDate}
                changeStart={setCustomStartDate}
                defaultEnd={DateUtil.findDateMoment(searchFilters.endPublicationDate)}
                changeEnd={setCustomEndDate}
                close={() => {
                    updateDateSearchFilters({selectedFilters: [...searchFilters.selectedFilters], userInteractions: userInteractions});
                    setCustomDatePopupOpen(false);
                }}
            />}
    </>
}
