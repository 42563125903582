import { ResourceKey } from "i18next";

export const DetailsSideBar: ResourceKey = {
	share: "Partager",
	shareThisTender: "Partagez cette tender",
	alsoShareWithMe: "Également partager avec moi",
	isGroupMail: "Envoyer comme courriel de groupe",
	tenderHasBeenSharedWith: "Cette tender a été partagé avec",
	tenderHasAlreadyBeenSharedWith: "Déjà partagé avec",
	viaEmail: "Par e-mail",
	emailAddress: "Adresse(s) e-mail",
	addAMessage: "Ajoutez un message",
	removeLead: "Supprimez présélection",
	makeLead: "Ajoutez au présélection",
	follow: "Suivez",
	unfollow: "Ne pas suivre",
	undeleteOpportunity: "Annulez supprimer opportunité",
	deleteOpportunity: "Supprimez opportunité",
	label: "Label",
	languages: "Langages",
	language: "Langage",
	downloadFullProject: "Téléchargez le projet complet",
	match: "Match",
	addToBesixForce: "Ajoutez au BesixForce",
	goToBesixForce: "BesixForce projet",
	showSimilarTenders: "Offres similaires",
};
