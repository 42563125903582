import React, {useEffect, useState} from 'react'

import './email-page.scss';
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {skipToken} from "@reduxjs/toolkit/query";
import {
    EmailFrequency,
    EmailSettings,
    GetEmailPageParams, updateEmailPage,
    useGetEmailPageQuery,
    usePutEmailPageMutation
} from "../../../hooks/slices/userEmailPageSlice";
import {useTranslation} from "react-i18next";
import {showCustomErrorPopup, showErrorPopup} from "../../../hooks/slices/snaccSlice";
import {Form} from "../../../components/form/Form";
import {APIErrorStatus} from "../../../utils/error";
import {PlusIcon, TrashIcon} from "../../../components/icons";
import {Loader} from "../../../components/loader";
import {ErrorPage} from "../../error";
import {EmailUtil} from "../../../utils/email";
import {ValidatedInput} from "../../../components/validatedInput/validatedInput";
import {AccountSettingsFormSkeleton} from "../accountSettingsFormSkeleton";
import {RaiseCurrentPlan} from "../../../hooks/raiseCurrentPlan";
import {Feature, FeatureName} from "../../../consts/features";
import {FeatureUtil} from "../../../utils/features";
import {SaveButton} from "../../../components/saveButton/SaveButton";
import {t} from "i18next";
import {EmailPageInStore, RaiseEmailPage} from "../../../hooks/raiseEmailAddresses";

export default function EmailPage() {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const userUuid = useAppSelector((state) => state.user.uuid);
    let emailsInStore: EmailPageInStore = RaiseEmailPage();
    const [putEmailPage, {data: putEmailPageResponse, isSuccess: putIsSuccess, isLoading: putIsLoading, isError: putIsError, error: putError}] = usePutEmailPageMutation();
    useEffect(() => {
        if(putIsError && putError) {
            if (APIErrorStatus(putError) === 409) {
                dispatch(showCustomErrorPopup(t("settings.primary_email_address_taken_error")));
            } else dispatch(showErrorPopup(putError));
        }
    }, [putIsError, putError]);
    useEffect(() => {
        if(putIsSuccess && putEmailPageResponse) {
            dispatch(updateEmailPage(putEmailPageResponse));
        }
    }, [putEmailPageResponse]);
    const currentPlan = RaiseCurrentPlan();
    if (currentPlan.error) return <ErrorPage error={currentPlan.error}/>;
    if(emailsInStore.error) return <ErrorPage error={emailsInStore.error}/>;
    else if(emailsInStore.emailPage != null
        && emailsInStore.emailPage.email != null
        && emailsInStore.emailPage.additionalAddressees != null
        && emailsInStore.emailPage.mailFrequency != null
        && emailsInStore.emailPage.mailIncludesAwards != null
        && userUuid
        && currentPlan.currentPlan?.features != null){
        return <>
            <EmailPageForm emailSettings={{
                email: emailsInStore.emailPage.email,
                additionalAddressees: emailsInStore.emailPage.additionalAddressees,
                mailFrequency: emailsInStore.emailPage.mailFrequency,
                mailIncludesAwards: emailsInStore.emailPage.mailIncludesAwards
            }}
                           features={currentPlan.currentPlan?.features}
                           isLoading={putIsLoading}
                           putEmailPage={(settings: EmailSettings) => putEmailPage({uuid: userUuid, body: settings})}
            />
        </>
    } else return <AccountSettingsFormSkeleton inputElements={3}/>;

}

interface EmailPageProps {
    emailSettings: EmailSettings;
    features: Feature[];
    isLoading: boolean;
    putEmailPage: (emailSettings: EmailSettings) => void;
}

const EmailPageForm: React.FC<EmailPageProps> = ({emailSettings, features, putEmailPage, isLoading}) => {
    const {t} = useTranslation();
    const [localEmailSettings, setLocalEmailSettings] = useState<EmailSettings>(emailSettings);
    const onSubmit = () => {
        putEmailPage(localEmailSettings);
    };

    const [newMailAddressIsValid, setNewEmailAddressIsValid] = useState<boolean | undefined>(undefined);
    const [showNewAddressInput, setShowNewAddressInput] = useState<boolean>(false);
    const addAdditionalAddressee = (additionalAddressee: string) => {
        setLocalEmailSettings({
            email: localEmailSettings.email,
            mailFrequency: localEmailSettings.mailFrequency,
            mailIncludesAwards: localEmailSettings.mailIncludesAwards,
            additionalAddressees: [...localEmailSettings.additionalAddressees, additionalAddressee]})
    }
    const removeAdditionalAddressee = (additionalAddressee: string) => {
        const newList = localEmailSettings.additionalAddressees.filter(a => a !== additionalAddressee);
        setLocalEmailSettings({
            email: localEmailSettings.email,
            mailFrequency: localEmailSettings.mailFrequency,
            mailIncludesAwards: localEmailSettings.mailIncludesAwards,
            additionalAddressees: newList})
    }
    const emailAddressIsValid = (emailAddress: string): boolean => {
        return EmailUtil.emailAddressIsValid(emailAddress)
            && emailAddress.toLowerCase() !== localEmailSettings.email.toLowerCase()
            && !localEmailSettings.additionalAddressees.map(a => a.toLowerCase()).includes(emailAddress.toLowerCase());
    }
    const addNewAdditionalAddressee = (additionalAddressee: string) => {
        let valid = emailAddressIsValid(additionalAddressee);
        setNewEmailAddressIsValid(valid);
        if(valid){
            addAdditionalAddressee(additionalAddressee);
            setAddedAdditionalAddressee("");
            setNewEmailAddressIsValid(false);
        }
    }

    function makePrimary(additionalAddressee: string) {
        let newAdditionalAddressees = [...localEmailSettings.additionalAddressees.filter(a => a !== additionalAddressee), localEmailSettings.email];
        setLocalEmailSettings({
            email: additionalAddressee,
            mailFrequency: localEmailSettings.mailFrequency,
            mailIncludesAwards: localEmailSettings.mailIncludesAwards,
            additionalAddressees: newAdditionalAddressees})
    }

    const [addedAdditionalAddressee, setAddedAdditionalAddressee] = useState("");
    const onAddedAdditionalAddresseeChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setNewEmailAddressIsValid(emailAddressIsValid(e.currentTarget.value))
        setAddedAdditionalAddressee(e.currentTarget.value);
    }

    const onEmailFrequencyChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setLocalEmailSettings({
            email: localEmailSettings.email,
            mailFrequency: e.currentTarget.checked ? EmailFrequency.DAILY : EmailFrequency.NEVER,
            mailIncludesAwards: e.currentTarget.checked ? localEmailSettings.mailIncludesAwards : false,
            additionalAddressees: localEmailSettings.additionalAddressees})
    };

    const onIncludeAwardsChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setLocalEmailSettings({
            email: localEmailSettings.email,
            mailFrequency: localEmailSettings.mailFrequency,
            mailIncludesAwards: e.currentTarget.checked,
            additionalAddressees: localEmailSettings.additionalAddressees})
    };

    function emailFrequencyIsDaily(): boolean {
        return localEmailSettings.mailFrequency === EmailFrequency.DAILY;
    }

    return (
        <div>
            <Form>
                <div className='email-page-alignment'>
                    <div className='page-alignment'>
                        <div>
                            <h1>{t("settings.email")}</h1>
                        </div>
                    </div>
                    <div className='checkbox-text-alignment'>
                        <div>
                            <div className='child-product'>
                                <input type="checkbox" id="emailFrequency" onChange={onEmailFrequencyChange}
                                       checked={emailFrequencyIsDaily()}/>
                                <label htmlFor="emailFrequency">{t("settings.daily_mail_frequency")}</label>
                            </div>
                        </div>
                    </div>
                    {emailFrequencyIsDaily() && FeatureUtil.hasFeature(features, FeatureName.UPDATE_MAIL_AWARDS) &&
                        <div className='checkbox-text-alignment'>
                            <div>
                                <div className='child-product'>
                                    <input type="checkbox" id="emailIncludesAwards" onChange={onIncludeAwardsChange}
                                           checked={localEmailSettings.mailIncludesAwards}/>
                                    <label htmlFor="emailIncludesAwards">{t("settings.daily_mail_add_awards")}</label>
                                </div>
                            </div>
                        </div>
                    }
                    <div className='email-add-box'>
                        <label>{t("settings.manage_email_addresses")}</label>
                        <div className='primary-email'>
                            <div>
                                <span>{localEmailSettings.email}</span>
                            </div>
                            <div>
                                <div className='primary'>{t("settings.primary")}</div>
                            </div>
                        </div>
                        {
                            localEmailSettings.additionalAddressees.map((additionalAddressee) => {
                                return <AdditionalAddressee key={additionalAddressee}
                                    email={additionalAddressee}
                                    removeAdditionalAddressee={removeAdditionalAddressee}
                                    makePrimary={makePrimary}/>
                            })
                        }
                        <label style={{cursor: "pointer"}} onClick={() => setShowNewAddressInput(!showNewAddressInput)}>{t("settings.add_email_address")}</label>
                        {showNewAddressInput &&
                            <div className='add-email'>
                                <ValidatedInput isInvalid={newMailAddressIsValid || addedAdditionalAddressee === ""}
                                                placeholder={t("settings.add_email_address")} onChange={onAddedAdditionalAddresseeChange}
                                                value={addedAdditionalAddressee}
                                                testId={"add-email-address"}
                                />
                                {newMailAddressIsValid &&
                                    <span onClick={() => addNewAdditionalAddressee(addedAdditionalAddressee)} title={t("settings.add_email_address")}>
                                    <PlusIcon/>
                                </span>
                                    // <a >{}</a>
                                }
                            </div>
                        }
                        <div className='fill-button'>
                            <SaveButton saving={isLoading} save={onSubmit}/>
                        </div>

                    </div>
                </div>
            </Form>
        </div>
    )
}

interface AdditionalAddresseeProps {
    email: string;
    removeAdditionalAddressee: (email: string) => void;
    makePrimary: (email: string) => void;
}

const AdditionalAddressee: React.FC<AdditionalAddresseeProps> = ({email, removeAdditionalAddressee, makePrimary}) => {

    const {t} = useTranslation();

    return (
        <div className='primary-email'>
            <div className='input'>
                <span>{email}</span>
            </div>
            <div>
                <div onClick={() => makePrimary(email)} className={'make-it-primary'}>{t("settings.make_primary")}</div>
                <div className='remove-button' onClick={() => removeAdditionalAddressee(email)}>
                    <TrashIcon/>
                </div>
            </div>
        </div>
    )


}
