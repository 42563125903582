import React, {useEffect, useState} from "react";
import {SettingSidebar} from "../../components/settingSidebar";
import {Account} from "./account";
import EmailPage from "./emailPage";
import {ChangePassword} from "./changePassword";
import {Plan} from "./plan";
import {SettingTab} from "../../consts/settingTabs";
import {ClientTab} from "./companyData";
import {LabelSettings} from "./labelSettings";
import {SavedSearchesTab} from "./savedSearches";
import {Countries} from "./countries";
import {Masquerade} from "./masquerade";
import {Feedback} from "./feedback";
import {FeatureUtil} from "../../utils/features";
import {FeatureName} from "../../consts/features";
import {RaiseCurrentPlan} from "../../hooks/raiseCurrentPlan";
import {useSearchParams} from "react-router-dom";
import {RaiseUser} from "../../hooks/raiseUser";
import {useTranslation} from "react-i18next";
import {Navigate} from "../../components/navigate/Navigate";

export const AccountSetting: React.FC = () => {
  const {t} = useTranslation();
  const currentPlan = RaiseCurrentPlan();
  const userCanVisitLabelsPage: boolean = FeatureUtil.hasFeature(currentPlan.currentPlan?.features, FeatureName.LABELS);
  const userInStore = RaiseUser();
  const userCanMasquerade: boolean =
    userInStore.user != null && userInStore.user.isAdmin != null && userInStore.user.isAdmin;
  const [searchParams, setSearchParams] = useSearchParams();
  const [tab, setTab] = useState<SettingTab>(searchParams.get("profile") != null ? SettingTab.PROFILES : SettingTab.ACCOUNT);
  useEffect(() => {
    if (searchParams.get("tab") !== null) {
      for (const paramTab of Object.values(SettingTab)) {
        if (searchParams.get("tab") === paramTab) setTab(paramTab);
      }
    }
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.delete("tab");
    setSearchParams(newSearchParams.toString());
  }, [searchParams]);

  const getHelpLink = () => {
    if (userInStore.user?.locale != null) {
      let language = userInStore.user.locale;
      if (language.startsWith("fr")) return "https://fr.tenderwolf.com/helpdesk/starten-met-tenderwolf";
      if (language.startsWith("en")) return "https://en.tenderwolf.com/helpdesk/starten-met-tenderwolf";
    } return "https://tenderwolf.com/helpdesk/starten-met-tenderwolf";
  }

  return (
    <>
      <div>
        <SettingSidebar
          activeTab={tab}
          setTab={setTab}
          userCanVisitLabelsPage={userCanVisitLabelsPage}
          userCanMasquerade={userCanMasquerade}
        />
      </div>
      <div>
        <>{tab === SettingTab.ACCOUNT && <Account />}</>
        <>
          {tab === SettingTab.COMPANY && <ClientTab tabName={t("settings.company")} companyNameEdit={true} addressEdit={true} phoneNumberEdit={true} />}
        </>
        <>
          {tab === SettingTab.INVOICE && (
            <ClientTab tabName={t("settings.invoice")} vatNumberEdit={true} emailAddressEdit={true} invoiceReferenceEdit={true} />
          )}
        </>
        {userCanVisitLabelsPage && <>{tab === SettingTab.LABELS && <LabelSettings />}</>}
        <>{tab === SettingTab.EMAIL && <EmailPage />}</>
        <>{tab === SettingTab.PROFILES && <SavedSearchesTab />}</>
        <>{tab === SettingTab.PASSWORD && <ChangePassword />}</>
        <>{tab === SettingTab.COUNTRIES && <Countries />}</>
        <>{tab === SettingTab.PLAN && <Plan />}</>
        <>{tab === SettingTab.FEEDBACK && <Feedback />}</>
        <>{tab === SettingTab.HELP && <Navigate location={getHelpLink()} external={true}/>}</>
        {userCanMasquerade && <>{tab === SettingTab.MASQUERADE && <Masquerade />}</>}
      </div>
    </>
  );
};
