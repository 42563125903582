import React from "react";
import "./validatedInput.scss";

export interface ValidatedInputProps {
  value: string;
  placeholder: string;
  isInvalid: boolean;
  highlightIfInvalid?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  type?: string;
  maxLength?:number
  testId: string;
}

export const ValidatedInput: React.FC<ValidatedInputProps> = ({ value, placeholder, isInvalid, highlightIfInvalid, onChange , type, maxLength, testId}) => {
  if(highlightIfInvalid === undefined || highlightIfInvalid === null) highlightIfInvalid = true;
  if(type === undefined || type === null) type = "text"
  return <div className='input'>
    <input className={highlightIfInvalid && isInvalid ? 'invalid' : ''}
           type={type} onChange={onChange} maxLength={maxLength ? maxLength : 255}
           value={value} placeholder={placeholder} data-testid={testId}/>
  </div>
}