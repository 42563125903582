import { ResourceKey } from "i18next";

export const CompanyPage: ResourceKey = {

    company_name: "Organisation",
    company_information_section_title: "Informations sur l'entreprise",
    main_competitors_section_title: "Principaux concurrents",
    recent_activity_section_title: "Marchés publics récents",
    recently_won_section_title: "Récemment gagné",
    recently_participated_in_section_title: "A récemment participé à",
    main_buyers_section_title: "Principaux acheteurs",
    tenders_contracted_open_title : "Appels d'offres ouverts",
    tenders_contracted_closed_title : "Appels d'offres récemment lancés",
    main_suppliers_section_title: "Principaux fournisseurs",
    main_activities_section_title: "Types de marchés publics",
    accreditations_title: "Accréditations",
    bid: "offre",
    award: "attribution",
    total_results: "Nombre total de résultats",
    disclaimer: "La page d'entreprise est une fonctionnalité bêta et un premier pas vers une véritable analyse de marché. Envoyez vos commentaires à l'adresse suivante : info@tenderwolf.com",
    unknown_company: "Entreprise inconnue",

};