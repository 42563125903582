import {ResourceKey} from "i18next";

export const Features: ResourceKey = {
	update_mail_limited: "opportunités par semaine",
	update_mail_unlimited: "opportunités illimitées",
	update_mail_daily: "mise à jour quotidienne",
	update_mail_weekly: "mise à jour hebdomadaire",
	country: "pays",
	countries: "pays",
	up_to: "jusqu'à",
	unlimited_countries: "nombre illimité de pays",
	curated_user_profile: "profil de sélection composé",
	curated_user_profiles: "profils de sélection composés",
	curated_user_profiles_unlimited: "profils de sélection composés illimités",
	custom_user_profile: "profil de sélection personnalisé",
	custom_user_profiles: "profils de sélection personnalisés",
	custom_user_profiles_unlimited: "profils de sélection personnalisés illimités",
	advanced_search: "recherche avancée",
	unlimited_advanced_search: "recherche avancée illimitée",
	days_back: "jours en arrière",
	one_year_back: "une année en arrière",
	labels: "étiquettes",
	unlimited_labels: "étiquettes illimitées",
	export_to_excel: "exportation vers excel",
	search_addenda: "recherche dans les addenda",
}