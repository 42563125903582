import React from "react";
import { SearchResponse, TenderSearch } from "../../../../hooks/slices/tenderSearchSlice";
import { SearchConfiguration } from "../../GenericSearchPage";
import { SearchFilterButtons, SearchFilterButtonsSkeleton } from "./SearchFilterButtons";
import { AggregationsInfoBox, AggregationsInfoBoxSkeleton } from "./AggregationsInfoBox";
import { ResultsInfoBox, ResultsInfoBoxSkeleton } from "./ResultsInfoBox";

export interface SearchResultsHeaderProps {
  tenderSearchInput: TenderSearch;
  tenderSearchOutput: SearchResponse;
  searchConfiguration: SearchConfiguration;
  opportunitiesLimit?: number;
}

export const SearchResultsHeader: React.FC<SearchResultsHeaderProps> = (props) => {
    return <>
        <div className={props.opportunitiesLimit ? "disabled" : ""}>
            <SearchFilterButtons {...props} />
            <AggregationsInfoBox {...props} />
        </div>
        <ResultsInfoBox {...props} />
    </>;
};

export const SearchResultsHeaderSkeleton: React.FC<SearchConfiguration> = (props) => {
    return <>
        <SearchFilterButtonsSkeleton/>
        <AggregationsInfoBoxSkeleton {...props} />
        <ResultsInfoBoxSkeleton {...props} />
    </>;
};