import { ResourceKey } from "i18next";

export const Publications: ResourceKey = {
	services: "Services",
	supplies: "Supplies",
	works: "Works",
	publicationSubtitle: "Publication",
	accompanyingDocumentsSubtitle: "Accompanying Documents",
	descriptionTitle: "Description",
	shortContentTitle: "Short content",
	multipleLotsSubtitle: "Lot description",
	singleLotSubtitle: "Description",
	lotTitle: "Lot",
	contractAwardsTitle: "Awards",
	additionalInformationTitle: "Additional Information",
	designContestDetailsTitle: "Details",
	bidTitle: "Bid",
	bidsTitle: "Bids",
	noBidValue: "No public bid value",
	companyTitle: "Company",
	companiesTitle: "Companies",
	participantsTitle: "Participants",
	participantTitle: "Participant",
	winnersTitle: "Winners",
	winnerTitle: "Winner",
	nonWinnersTitle: "Non-winning companies",
	nonWinnerTitle: "Non-winning company",
	noAwardsTitle: "No awards found",
	noAwardsOrDiscontinuationTitle: "No valid offers or discontinued procedure",
	noBidsTitle: "No bids found",
	offersReceivedTitle: "Offers received",
	resultStatusTitle: "Status",
	decisionReasonTitle: "Reason",
	publicationDateTitle: "Publication",
	awardPublicationDateTitle: "Award",
	openingReportPublicationDateTitle: "Opening tenders",
	deadlineTitle: "Deadline",
	dateOfContractNoticeTitle: "Date notice",
	totalValueTitle: "Value",
	estimatedTotalValueTitle: "Estimated total value",
	exactTotalValueTitle: "Total value",
	lowestAndHighestValueTitle: "Lowest and highest value",
	isFrameworkTitle: "Framework Contract",
	isShelteredWorkshopTitle: "Sheltered Workshop",
	isShelteredProgramTitle: "Sheltered Program",
	contractTypeTitle: "Contract Type",
	procedureTypeTitle: "Procedure",
	contractingAuthorityTitle: "Contracting authority",
	contactPoint: "Contact",
	subjectCodesTitle: "Subject codes (CPV)",
	supplementarySubjectCodesTitle: "Supplementary subject codes (CPV)",
	regionCodesTitle: "Region codes (NUTS)",
	accreditationsTitle: "Accreditations",
	accreditationClassTitle: "Class",
	accreditationCategoryTitle: "Category",
	durationTitle: "Estimated duration",
	awardCriteriaTitle: "Award criteria",
	awardCriteriumTitle: "Award criterium",
	awardCriteriumPriceTitle: "Price",
	awardCriteriumQualityTitle: "Quality",
	awardCriteriumDescriptiveTitle: "Description",
	selectionCriteriaTitle: "Selection criteria",
	selectionCriteriumTitle: "Selection criterium",
	selectionCriteriumWeight: "Weight",
	conditionsForParticipationTitle: "Conditions for participation",
	financialConditionsTitle: "Economic and financial capacity",
	financialConditionsInformationSubtitle: "General",
	financialConditionsMinimumSubtitle: "Minimum",
	technicalConditionsTitle: "Technical and professional competence",
	technicalConditionsInformationSubtitle: "General",
	technicalConditionsMinimumSubtitle: "Minimum",
	performanceConditionsTitle: "Performance conditions",
	performanceConditionsSubtitle: "Economic and financial capacity",
	corrigendumChangesTitle: "Changes",
	oldValueTitle: "Old value",
	newValueTitle: "New value",
	lotNumberTitle: "Lot number",
	sectionTitle: "Section",
	labelTitle: "Label",
	corrigendumDateChange: "Date Change",
	corrigendumDateTimeChange: "Time change",
	corrigendumMainSubjectCodeChange: "Subject code change",
	corrigendumAdditionalSubjectCodesChange: "Subject code change",
	corrigendumTextChange: "Text change",
	alreadySelectedTitle: "Already selected",
	cashPrizeTitle: "Cash prize",
	isBindingTitle: "Is binding",
	willBeAwardedTitle: "Will be awarded",
	detailsPaymentTitle: "Details payment",
	juryTitle: "Jury",
	month: "months",
	day: "days",
	true: "yes",
	false: "no",
	filesTitle: "Files",
	externalAddendumUrlTitle: "Tender documents",
  	downloadFileTitle: "Download",
	downloadViaArchiveTitle: "Download via full project",
	languageTitle: "Language",
	languagesTitle: "Languages",
	fields: "Fields",
	title: "Title",
	description: "Description",
	requirements: "Requirements",
	addenda: "Addenda",
	today: "Today",
	yesterday: "Yesterday",
	tomorrow: "Tomorrow",
	lastWeek: "Last week",
	lastMonth: "Last month",
	nextWeek: "Next week",
	nextMonth: "Next month",
	everything: "Everything",
	notYetExpired: "Not yet expired",
	in7Days: "In 7 days",
	in14Days: "In 14 days",
	in1Month: "In 1 month",
	in2Months: "In 2 months",
	municipalDecisionTypeTitle: "Type",
	resolutionTitle: "Resolution",

  publicationRadiusTitle: "European publication",
	yes: "Yes",
	no: "No",
	ok: "Ok",
	euInstitution: "International organisation",
	ministry: "Ministry",
	utilities: "Utilities",
	bodyPublic: "Body governed by public law",
	regionalAuthority: "Regional authority",
	other: "Other",
	regionalAgency: "Regional agency",
	nationalAgency: "National agency",
	publicationType: "Formtype",
	priorInformationNotice: "Prior information notice",
	contractNotice: "Contract notice",
	contractAwardNotice: "Contract award notice",
	awardModification: "Award modification",
	designContest: "Design contest",
	corrigendum: "Corrigendum",
	simplifiedPublication: "Simplified publication",
	openingReport: "Opening report",
	municipalDecision: "Municipal council report",
	countries: "Countries",
	relevance: "relevance",
	date: "date",
	awardDate: "awarded",
	searchLeads: "Shortlist",
	searchSector: "Sector",
	searchCompanies: "Followed companies",
	searchEverything: "Everything",
	showMoreResults: "Show more results",
	showFewerResults: "Show fewer results",
	results: "results",
	noResults: "No results",
  resultsPerPage: "Results per page:",
  sortResultsBy: "Sort by:",
  export: "Export",
	searchTermsFound: "Search terms found",
	interestingKeywords: "Interesting keywords",
	noSearchTermsFound: "none",
	noLabelsFound: "none",
	noInfoRefineSearch: "Too many results to display accurate filtering information",
	searchAddenda: "Search addenda",
	toggleSearchSector: "Search in my sector",
  searchAwardCompanyNames: "Search winners",
  searchBidCompanyNames: "Search participants",
	addLabel: "Add label",
	opportunityRemoved: "This opportunity was removed",
  undo: "Undo",
	officialPublication: "Official publication",
	source: "Source",
	archive: "Archive",
	ongoing: "Ongoing",
	lastChangedOn: "last change on ",
	price: "Price",
	quality: "Quality",
	resultStatusCLOSED: "No winner was chosen and the competition is closed.",
	resultStatusOPEN: "The winner was not yet chosen, but the competition is still ongoing.",
	resultStatusAWARDED: "At least one winner was chosen.",
	resultStatusUNKNOWN: "Unknown",
	decisionReasonALL_REJECTED: "All tenders, requests to participate or projects were withdrawn or found inadmissible",
	decisionReasonCHANGE_NEEDED: "Decision of the buyer, because of a change in needs",
	decisionReasonINSUFFICIENT_FUNDS: "Decision of the buyer, because of insufficient funds",
	decisionReasonNO_TENDERS: "No tenders, requests to participate or projects were received",
	decisionReasonNO_SIGNED: "The highest ranked tenderer(s) refused to sign the contract",
	decisionReasonONE_ADMISSIBLE: "Only one admissible tender, request to participate or project was received",
	decisionReasonOTHER: "Other",
	decisionReasonREVIEW_BODY: "Decision of a review body or another judicial body",
	decisionReasonREVIEW_BUYER: "Decision of the buyer following a tenderer's request to review the award",
	decisionReasonTECHNICAL_PROCEDURAL_ERROR: "Decision of the buyer, not following a tenderer's request to review the award, because of technical or procedural errors",
	decisionReasonUNKNOWN: "Unknown",
	chooseADate: "Choose a start and end date",
	filter: "Filter",
};
