import React from "react";
import { CloseIcon } from "../../../../components/icons";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../app/hooks";
import {resetSortByTenderUuidFilters, SimilarTenderFilter} from "../../../../hooks/slices/tenderSearchSlice";

export const SimilarTenderFilterButton: React.FC<{ similarTender: SimilarTenderFilter }> = ({ similarTender }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  return (
    <>
      <button data-cy={"similar-tender-uuid-filter-button"}>
        <span>{t("searchFilterTags.similarTenderUuid") + ": " + similarTender.tag}</span>
        <span onClick={() => dispatch(resetSortByTenderUuidFilters())} style={{ cursor: "pointer" }}>
          <CloseIcon />
        </span>
      </button>
    </>
  );
};
