import { ResourceKey } from "i18next";

export const Codes: ResourceKey = {
   code_SERVICES:"Diensten",
   code_WORKS:"Werken",
   code_SUPPLIES:"Leveringen",
   // Procedure types
   code_OPEN: "Openbare procedure",
   code_RESTRICTED: "Niet-openbare procedure",
   code_PT_COMPETITIVE_NEGOTIATION: "Mededingingsprocedure met onderhandeling",
   code_PT_COMPETITIVE_DIALOGUE: "Concurrentiegerichte dialoog",
   code_PT_INNOVATION_PARTNERSHIP: "Innovatiepartnerschap",
   code_INVOLVING_NEGOTIATION: "Procedure met onderhandeling",
   code_PT_NEGOTIATED_WITH_PRIOR_CALL: "Onderhandelingsprocedure met voorafgaande oproep tot mededinging",
   code_NEGOTIATED_WITHOUT_PUBLICATION: "Onderhandelingsprocedure zonder voorafgaande oproep tot mededinging",
   code_PT_COMPETITIVE: "Onderhandelingsprocedure zonder voorafgaande bekendmaking (met publicatie in Free Market)",
   code_OTHER: "Andere",
   code_PT_OPEN: "Openbare procedure",
   code_PT_RESTRICTED: "Niet-openbare procedure",
   code_PT_INVOLVING_NEGOTIATION: "Procedure met onderhandelingen",
   code_NEGOTIATED: "Procedure met onderhandelingen",
   code_PT_NEGOTIATED_CHOICE: "Procedure met onderhandelingen",
   code_UNKNOWN: "Onbekend",
   code_INNOVATION: "Innovatiepartnerschap",
   code_NEG_WO_CALL: "Onderhandelingsprocedure zonder voorafgaande oproep tot mededinging",
   code_NEG_W_CALL: "Onderhandelingsprocedure met voorafgaande oproep tot mededinging / mededingingsprocedure met onderhandeling",
   code_COMP_TEND: "Mededingingsprocedure",
   code_OTH_SINGLE: "Andere procedure in één fase",
   code_COMP_DIAL: "Concurrentiegerichte dialoog",
   code_OTH_MULT: "Andere procedure in meerdere fasen",
   //MuncipalDecisionTypes
   code_ANNOUNCEMENT: "Bekendmaking",
   code_AWARD: "Gunning",
   // Accreditations
   code_A:"Baggerwerken",
   code_A1:"Schepen lichten en wrakken opruimen",
   code_B:"Waterbouwkundige werken",
   code_B1:"Waterlopen ruimen",
   code_C:"Wegenbouwkundige werken",
   code_C1:"Gewone rioleringswerken",
   code_C2:"Watervoorziening en leidingen leggen",
   code_C3:"Niet-elektrische verkeerstekens langs verbindingswegen, niet-elektrische veiligheidsinrichtingen, afsluitingen en schermen",
   code_C5:"Bitumineuze verhardingen en bestrijkingen",
   code_C6:"Sterkstroom- en telecommunicatiekabels in sleuven leggen, zonder aaneenkoppeling",
   code_C7:"Horizontale doorpersingen van buizen voor kabels en leidingen",
   code_D:"Bouwwerken",
   code_D1:"Ruwbouwwerken en onder kap brengen van gebouwen",
   code_D4:"Geluids- en warmte-isolatie, lichte scheidingswanden, valse plafonds en blinde vloeren, al dan niet geprefabriceerd",
   code_D5:"Timmerwerk, houten spanten en trappen",
   code_D6:"Marmer- en steenhouwerswerk",
   code_D7:"Smeedwerk",
   code_D8:"Dakbedekkingen in asfaltproducten (of gelijkaardige) en dichtingswerken",
   code_D10:"Tegelwerk",
   code_D11:"Pleister- en raapwerk",
   code_D12:"Bedekkingen die niet in metaal of asfalt zijn",
   code_D13:"Verfwerk",
   code_D14:"Glazenmakerswerk",
   code_D15:"Parketwerk",
   code_D16:"Sanitaire installaties en gasverwarmingsinstallaties met individuele toestellen",
   code_D17:"Centrale verwarming, thermische installaties",
   code_D18:"Ventilatie, luchtverwarming en airconditioning",
   code_D20:"Metalen schrijnwerk",
   code_D21:"Gevels reinigen en opknappen",
   code_D22:"Metalen dakbedekkingen en zinkwerk",
   code_D23:"Restauratie door ambachtslieden",
   code_D24:"Restauratie van monumenten",
   code_D25:"Muur- en vloerbekledingen met uitzondering van marmer-, parket- en tegelwerk",
   code_D29:"Vloerdeklagen en bekleding van industriële vloeren",
   code_E:"Burgerlijke bouwkunde",
   code_E1:"Moerriolen",
   code_E2:"Paalfunderingen, dam- en diepwanden",
   code_E4:"Horizontale doorpersingen van onderdelen van kunstwerken",
   code_F:"Metaalconstructies",
   code_F1:"Montage- en demontagewerken (zonder leveringen)",
   code_F2:"Metalen draagstructuren bouwen",
   code_F3:"Industrieel schilderwerk",
   code_G:"Grondwerken",
   code_G1:"Borings- en sonderingswerken en injecties",
   code_G2:"Draineerwerken",
   code_G3:"Beplantingen",
   code_G4:"Speciale bekledingen voor sportvelden",
   code_G5:"Afbraakwerken",
   code_H:"Spoorwerken",
   code_H1:"Spoorstaven lassen",
   code_H2:"Stroomdraden plaatsen",
   code_K:"Mechanische uitrustingen",
   code_K1:"Uitrustingen van kunstwerken en industriële mechanica",
   code_K2:"Overladings- en hijstoestellen (kranen, rolbruggen…) installeren",
   code_K3:"Oleomechanische uitrustingen",
   code_L:"Hydromechanische uitrustingen",
   code_L1:"Leidingen plaatsen",
   code_L2:"Pomp- en turbinestations uitrusten",
   code_M:"Elektronische uitrustingen",
   code_M1:"Elektronische uitrustingen met industriële of hoge frequentie, voedingsstations inbegrepen",
   code_N:"Transportinstallaties in gebouwen",
   code_N1:"Liften, goederenliften, roltrappen en roltapijten",
   code_N2:"Pneumatisch, mechanisch… vervoer langs kokers en buizen van voorwerpen, documenten of goederen",
   code_P1:"Elektrische installaties in gebouwen, inbegrepen het installeren van stroomaggregaten, alarmtoestellen tegen brand- en diefstal, telecommunicatie in en om gebouwen en voorzieningen voor gemengde telefonie",
   code_P2:"Elektrische en elektromechanische installaties van kunstwerken en nijverheidsinrichtingen, en elektrische buiteninstallaties",
   code_P3:"Installaties van bovengrondse elektriciteitsleidingen",
   code_P4:"Elektrische installaties van haveninrichtingen",
   code_S:"Uitrustingen voor telecommunicatie",
   code_S1:"Openbare telefoon- en telegraafuitrustingen en databeheer",
   code_S2:"Uitrustingen voor afstandsbediening, -controle en -meting",
   code_S3:"Uitrustingen voor radio- en televisieuitzendingen , radar- en antenne-installaties",
   code_S4:"Uitrustingen voor informatieverwerking en procesregeling",
   code_T2:"Bliksemafleiders, ontvangstantennes",
   code_T3:"Koelinstallaties",
   code_T4:"Uitrustingen voor wasserijen en grote keukens",
   code_T6:"Slachthuisinrichtingen",
   code_U:"Installaties voor huisvuilverwerking",
   code_V:"Installaties voor waterzuivering",
   code_03100000:"Land- en tuinbouwproducten",
   code_03110000:"Land- en tuinbouwgewassen en -producten",
   code_03111000:"Zaden",
   code_03111100:"Sojabonen",
   code_03111200:"Pinda's",
   code_03111300:"Zonnebloempitten",
   code_03111400:"Katoenzaad",
   code_03111500:"Sesamzaad",
   code_03111600:"Mosterdzaad",
   code_03111700:"Groentenzaad",
   code_03111800:"Vruchtenzaad",
   code_03111900:"Bloemenzaden",
   code_03112000:"Ruwe tabak",
   code_03113000:"Suikergewassen",
   code_03113100:"Suikerbieten",
   code_03113200:"Suikerriet",
   code_03114000:"Stro en voedergewassen",
   code_03114100:"Stro",
   code_03114200:"Veevoeder",
   code_03115000:"Ruw plantaardig materiaal",
   code_03115100:"Ruw plantaardig materiaal voor de vervaardiging van textiel",
   code_03115110:"Katoen",
   code_03115120:"Jute",
   code_03115130:"Vlas",
   code_03116000:"Natuurlijke rubber, latex en aanverwante producten",
   code_03116100:"Natuurlijke rubber",
   code_03116200:"Natuurlijke latex",
   code_03116300:"Latexproducten",
   code_03117000:"Planten voor specifieke toepassingen",
   code_03117100:"Planten voor bereiding van parfum en geneesmiddelen, insectenbestrijding en dergelijke",
   code_03117110:"Planten gebruikt voor de bereiding van parfum",
   code_03117120:"Planten gebruikt voor de bereiding van geneesmiddelen",
   code_03117130:"Planten gebruikt voor insectenbestrijding",
   code_03117140:"Plantas utilizadas como fungicidas ou para fins semelhantes",
   code_03117200:"Plantenzaden voor specifieke toepassingen",
   code_03120000:"Tuinbouw- en kwekerijproducten",
   code_03121000:"Tuinbouwproducten",
   code_03121100:"Levende planten, bollen, wortels, stekken en enten",
   code_03121200:"Snijbloemen",
   code_03121210:"Bloemstukken",
   code_03130000:"Specerijgewassen en gewassen voor de vervaardiging van dranken",
   code_03131000:"Gewassen bestemd voor de vervaardiging van dranken",
   code_03131100:"Koffiebonen",
   code_03131200:"Theestruiken",
   code_03131300:"Maté",
   code_03131400:"Cacaobonen",
   code_03132000:"Niet-bewerkte specerijen",
   code_03140000:"Dierlijke en aanverwante producten",
   code_03141000:"Stierensperma",
   code_03142000:"Dierlijke producten",
   code_03142100:"Natuurlijke honing",
   code_03142200:"Slakken",
   code_03142300:"Eetbare producten van dierlijke oorsprong",
   code_03142400:"Was",
   code_03142500:"Eieren",
   code_03143000:"Producten van gemengde bedrijven",
   code_03144000:"Landbouwbenodigdheden",
   code_03200000:"Granen, aardappelen, groenten, vruchten en noten",
   code_03210000:"Granen en aardappelen",
   code_03211000:"Granen",
   code_03211100:"Tarwe",
   code_03211110:"Harde tarwe",
   code_03211120:"Zachte tarwe",
   code_03211200:"Maïs",
   code_03211300:"Rijst",
   code_03211400:"Gerst",
   code_03211500:"Rogge",
   code_03211600:"Haver",
   code_03211700:"Mout",
   code_03211900:"Graanproducten",
   code_03212000:"Aardappelen en gedroogde groenten",
   code_03212100:"Aardappelen",
   code_03212200:"Gedroogde peulvruchten en peulen",
   code_03212210:"Gedroogde peulvruchten",
   code_03212211:"Linzen",
   code_03212212:"Kikkererwten",
   code_03212213:"Gedroogde erwten",
   code_03212220:"Peulvruchten",
   code_03220000:"Groenten, vruchten en noten",
   code_03221000:"Groenten",
   code_03221100:"Wortel- en knolgewassen",
   code_03221110:"Wortelgewassen",
   code_03221111:"Rode bieten",
   code_03221112:"Wortelen",
   code_03221113:"Uien",
   code_03221114:"Rapen",
   code_03221120:"Knolgewassen",
   code_03221200:"Vruchtgroenten",
   code_03221210:"Bonen",
   code_03221211:"Tuinbonen",
   code_03221212:"Sperziebonen",
   code_03221213:"Snijbonen",
   code_03221220:"Erwten",
   code_03221221:"Doperwten",
   code_03221222:"Peultjes",
   code_03221230:"Paprika's",
   code_03221240:"Tomaten",
   code_03221250:"Courgettes",
   code_03221260:"Paddestoelen",
   code_03221270:"Komkommers",
   code_03221300:"Bladgroenten",
   code_03221310:"Sla",
   code_03221320:"Slabladeren",
   code_03221330:"Artisjokken",
   code_03221340:"Spinazie",
   code_03221400:"Koolgroenten",
   code_03221410:"Kool",
   code_03221420:"Bloemkool",
   code_03221430:"Broccoli",
   code_03221440:"Spruitjes",
   code_03222000:"Vruchten en noten",
   code_03222100:"Tropische vruchten en noten",
   code_03222110:"Tropisch fruit",
   code_03222111:"Bananen",
   code_03222112:"Ananassen",
   code_03222113:"Mango's",
   code_03222114:"Dadels",
   code_03222115:"Rozijnen",
   code_03222116:"Vijgen",
   code_03222117:"Avocado's",
   code_03222118:"Kiwi's",
   code_03222120:"Kokosnoten",
   code_03222200:"Citrusvruchten",
   code_03222210:"Citroenen",
   code_03222220:"Sinaasappels",
   code_03222230:"Grapefruit",
   code_03222240:"Mandarijntjes",
   code_03222250:"Limoenen",
   code_03222300:"Niet-tropisch fruit",
   code_03222310:"Besvruchten",
   code_03222311:"Krenten",
   code_03222312:"Kruisbessen",
   code_03222313:"Aardbeien",
   code_03222314:"Frambozen",
   code_03222315:"Veenbessen",
   code_03222320:"Appels, peren en kweeperen",
   code_03222321:"Appels",
   code_03222322:"Peren",
   code_03222323:"Kweeperen",
   code_03222330:"Steenvruchten",
   code_03222331:"Abrikozen",
   code_03222332:"Perziken",
   code_03222333:"Kersen",
   code_03222334:"Pruimen",
   code_03222340:"Druiven",
   code_03222341:"Tafeldruiven",
   code_03222342:"Wijndruiven",
   code_03222400:"Olijven",
   code_03300000:"Veeteelt-, jacht- en visserijproducten",
   code_03310000:"Vis, schaal- en schelpdieren en aquatische producten",
   code_03311000:"Vis",
   code_03311100:"Platvissen",
   code_03311110:"Tong",
   code_03311120:"Schol",
   code_03311200:"Kabeljauwachtigen",
   code_03311210:"Kabeljauw",
   code_03311220:"Pollak",
   code_03311230:"Heek",
   code_03311240:"Schelvis",
   code_03311300:"Haring",
   code_03311400:"Tonijn",
   code_03311500:"Wijting",
   code_03311600:"Zeebliek",
   code_03311700:"Zalm",
   code_03312000:"Schaaldieren",
   code_03312100:"Oesters",
   code_03312200:"Schelpdieren",
   code_03312300:"Ongewervelde waterdieren",
   code_03313000:"Aquatische producten",
   code_03313100:"Koralen of soortgelijke producten",
   code_03313200:"Natuurlijke sponsen",
   code_03313300:"Zeewieren",
   code_03313310:"Algen",
   code_03320000:"Groot en kleinvee en andere kleine dieren",
   code_03321000:"Vee",
   code_03321100:"Rundvee",
   code_03321200:"Kalveren",
   code_03322000:"Vee",
   code_03322100:"Schapen",
   code_03322200:"Geiten",
   code_03322300:"Paarden",
   code_03323000:"Varkens",
   code_03324000:"Levend pluimvee",
   code_03325000:"Kleinvee",
   code_03325100:"Konijnen",
   code_03325200:"Hazen",
   code_03330000:"Producten van boerderijdieren",
   code_03331000:"Verse melk van schapen en geiten",
   code_03331100:"Schapenmelk",
   code_03331200:"Geitenmelk",
   code_03332000:"Wol en dierlijk haar",
   code_03332100:"Scheerwol",
   code_03332200:"Dierlijk haar",
   code_03333000:"Verse koemelk",
   code_03340000:"Oormerken voor dieren",
   code_03341000:"Oormerken voor rundvee",
   code_03400000:"Producten van bosbouw en bosexploitatie",
   code_03410000:"Hout",
   code_03411000:"Naaldhout",
   code_03412000:"Tropisch hout",
   code_03413000:"Brandhout",
   code_03414000:"Onbewerkt hout",
   code_03415000:"Zachthout",
   code_03416000:"Houtafval",
   code_03417000:"Afvalhout",
   code_03417100:"Zaagsel",
   code_03418000:"Boomstammen",
   code_03418100:"Hardhout",
   code_03419000:"Timmerhout",
   code_03419100:"Timmerhoutproducten",
   code_03419200:"Mijnhout",
   code_03420000:"Gomsoorten",
   code_03421000:"Balsems",
   code_03422000:"Lak",
   code_03430000:"Kurk",
   code_03431000:"Natuurkurk",
   code_03432000:"Mandenwerk",
   code_03432100:"Vlechtwerk",
   code_03440000:"Bosbouwproducten",
   code_03441000:"Sierplanten, grassen, mossen of korstmossen",
   code_03450000:"Boomkwekerijproducten",
   code_03451000:"Planten",
   code_03451100:"Tuinplanten",
   code_03451200:"Bloembollen",
   code_03451300:"Heesters",
   code_03452000:"Bomen",
   code_03460000:"Pulp",
   code_03461000:"Houtpulp",
   code_03461100:"Houtcellulose",
   code_09000000:"Aardolieproducten, brandstof, elektriciteit en andere energiebronnen",
   code_09100000:"Brandstoffen",
   code_09110000:"Vaste brandstoffen",
   code_09111000:"Steenkool en brandstoffen op basis van steenkool",
   code_09111100:"Steenkool",
   code_09111200:"Brandstoffen op basis van steenkool",
   code_09111210:"Antraciet",
   code_09111220:"Briketten",
   code_09111300:"Fossiele brandstoffen",
   code_09111400:"Brandstoffen op basis van hout",
   code_09112000:"Bruinkool en turf",
   code_09112100:"Bruinkool",
   code_09112200:"Turf",
   code_09113000:"Cokes",
   code_09120000:"Gasvormige brandstoffen",
   code_09121000:"Steenkoolgas, leidinggas of soortgelijke gassen",
   code_09121100:"Steenkoolgas of soortgelijke gassen",
   code_09121200:"Leidinggas",
   code_09122000:"Propaan en butaan",
   code_09122100:"Propaangas",
   code_09122110:"Vloeibaar propaangas",
   code_09122200:"Butaangas",
   code_09122210:"Vloeibaar butaangas",
   code_09123000:"Aardgas",
   code_09130000:"Aardolie en aardoliedistillaten",
   code_09131000:"Luchtvaartkerosine",
   code_09131100:"Reactiemotorbrandstof van het kerosinetype",
   code_09132000:"Benzine",
   code_09132100:"Loodvrije benzine",
   code_09132200:"Gelode benzine",
   code_09132300:"Ethanolhoudende benzine",
   code_09133000:"Vloeibaar petroleumgas (lpg)",
   code_09134000:"Gasolie",
   code_09134100:"Dieselolie",
   code_09134200:"Dieselbrandstof",
   code_09134210:"Dieselbrandstof (0,2)",
   code_09134220:"Dieselbrandstof (EN 590)",
   code_09134230:"Biodiesel",
   code_09134231:"Biodiesel (B20)",
   code_09134232:"Biodiesel (B100)",
   code_09135000:"Stookoliën",
   code_09135100:"Stookolie",
   code_09135110:"Stookoliën met een laag zwavelgehalte",
   code_09200000:"Aardolie, steenkool en olieproducten",
   code_09210000:"Preparaten voor smering",
   code_09211000:"Smeerolie en smeermiddelen",
   code_09211100:"Motorolie",
   code_09211200:"Compressorolie",
   code_09211300:"Turbineolie",
   code_09211400:"Versnellingsbakolie",
   code_09211500:"Olie voor overbrenging",
   code_09211600:"Olie voor gebruik in hydraulische installaties en voor andere doeleinden",
   code_09211610:"Vloeistoffen voor hydraulische installaties",
   code_09211620:"Olie voor het insmeren van vormen",
   code_09211630:"Corrosiewerende olie",
   code_09211640:"Olie voor elektrische isolatie",
   code_09211650:"Remvloeistoffen",
   code_09211700:"White oils en paraffinum liquidum",
   code_09211710:"White oils",
   code_09211720:"Paraffinum liquidum",
   code_09211800:"Aardoliën en -samenstellingen",
   code_09211810:"Lichte oliën",
   code_09211820:"Minerale oliën",
   code_09211900:"Smeeroliën voor tractie",
   code_09220000:"Vaseline, was en lichte oliën",
   code_09221000:"Vaseline en was",
   code_09221100:"Vaseline",
   code_09221200:"Paraffinewas",
   code_09221300:"Slack-wax",
   code_09221400:"Petroleumresiduen",
   code_09222000:"Speciale distillatieproducten",
   code_09222100:"Terpentine",
   code_09230000:"Ruwe aardolie",
   code_09240000:"Olie en steenkoolproducten",
   code_09241000:"Bitumineuze leisteen",
   code_09242000:"Steenkoolproducten",
   code_09242100:"Koololie",
   code_09300000:"Elektriciteit, verwarming, zonne- en kernenergie",
   code_09310000:"Elektriciteit",
   code_09320000:"Stoom, warm water en aanverwante producten",
   code_09321000:"Warm water",
   code_09322000:"Stoom",
   code_09323000:"Stadsverwarming",
   code_09324000:"Afstandsverwarming",
   code_09330000:"Zonne-energie",
   code_09331000:"Zonnepanelen",
   code_09331100:"Zonnecollectoren voor verwarmingsdoeleinden",
   code_09331200:"Fotovoltaïsche zonnecellen",
   code_09332000:"Zonne-energie-installatie",
   code_09340000:"Nucleaire brandstoffen",
   code_09341000:"Uranium",
   code_09342000:"Plutonium",
   code_09343000:"Radioactieve stoffen",
   code_09344000:"Radio-isotopen",
   code_14000000:"Mijnbouw, basismetalen en aanverwante producten",
   code_14200000:"Zand en klei",
   code_14210000:"Grind, zand, steenslag en aggregaten",
   code_14211000:"Zand",
   code_14211100:"Natuurlijk zand",
   code_14212000:"Granulaat, steengruis, steenpoeder, keien, grind, split en steenslag, steenmengels, zand-grindmengsels en andere aggregaten",
   code_14212100:"Keien en grind",
   code_14212110:"Grind",
   code_14212120:"Keien",
   code_14212200:"Aggregaten",
   code_14212210:"Zand-grindmengsel",
   code_14212300:"Split en steenslag",
   code_14212310:"Ballast",
   code_14212320:"Granietsplit",
   code_14212330:"Basaltsplit",
   code_14212400:"Grond",
   code_14212410:"Teelaarde",
   code_14212420:"Ondergrond",
   code_14212430:"Steengruis",
   code_14213000:"Macadam, teermacadam en teerzand",
   code_14213100:"Macadam",
   code_14213200:"Teermacadam",
   code_14213300:"Teerzand",
   code_14220000:"Klei en porseleinaarde",
   code_14221000:"Klei",
   code_14222000:"Porseleinaarde",
   code_14300000:"Mineralen voor de chemische en kunstmestindustrie",
   code_14310000:"Mineralen voor de kunstmestindustrie",
   code_14311000:"Natuurlijk calcium, aluminiumcalciumfosfaat en ruwe natuurlijke kalizouten",
   code_14311100:"Natuurlijk calcium",
   code_14311200:"Aluminiumcalciumfosfaten",
   code_14311300:"Ruwe natuurlijke kalizouten",
   code_14312000:"IJzerpyriet",
   code_14312100:"Ongeroost pyriet",
   code_14320000:"Chemische mineralen",
   code_14400000:"Zout en zuiver natriumchloride",
   code_14410000:"Steenzout",
   code_14420000:"Zeezout",
   code_14430000:"Geëvaporeerd zout en zuiver natriumchloride",
   code_14450000:"Pekel",
   code_14500000:"Nevenproducten van mijnbouw en steengroeven",
   code_14520000:"Edelstenen en halfedelstenen; puimsteen; amaril; natuurlijke slijpmiddelen; andere mineralen en edele metalen",
   code_14521000:"Edelstenen en halfedelstenen",
   code_14521100:"Edelstenen",
   code_14521140:"Stof of poeder van edelstenen",
   code_14521200:"Halfedelstenen",
   code_14521210:"Stof of poeder van halfedelstenen",
   code_14522000:"Industriediamanten; puimsteen; amaril en andere natuurlijke slijpmiddelen",
   code_14522100:"Puimsteen",
   code_14522200:"Industriediamanten",
   code_14522300:"Amaril",
   code_14522400:"Natuurlijke slijpmiddelen",
   code_14523000:"Aanverwante mineralen, edele metalen en bijbehorende producten",
   code_14523100:"Mineralen",
   code_14523200:"Goud",
   code_14523300:"Zilver",
   code_14523400:"Platina",
   code_14600000:"Metaalertsen en legeringen",
   code_14610000:"Metaalertsen",
   code_14611000:"IJzerertsen",
   code_14612000:"Non-ferrometaalertsen",
   code_14612100:"Koperertsen",
   code_14612200:"Nikkelertsen",
   code_14612300:"Aluminiumertsen",
   code_14612400:"Edelmetaalertsen",
   code_14612500:"Loodertsen",
   code_14612600:"Zinkertsen",
   code_14612700:"Tinertsen",
   code_14613000:"Uranium- en thoriumertsen",
   code_14613100:"Uraniumertsen",
   code_14613200:"Thoriumertsen",
   code_14614000:"Diverse ertsen",
   code_14620000:"Legeringen",
   code_14621000:"Ferrolegeringen",
   code_14621100:"Niet-EGKS-ferrolegeringen",
   code_14621110:"Ferromangaan",
   code_14621120:"Ferrochroom",
   code_14621130:"Ferronikkel",
   code_14622000:"Staal",
   code_14630000:"Slakken, gietschuim, ferro-afval en schroot",
   code_14700000:"Basismetalen",
   code_14710000:"IJzer, lood, zink, tin en koper",
   code_14711000:"IJzer",
   code_14711100:"Ruw gietijzer",
   code_14712000:"Lood",
   code_14713000:"Zink",
   code_14714000:"Tin",
   code_14715000:"Koper",
   code_14720000:"Aluminium, nikkel, scandium, titanium en vanadium",
   code_14721000:"Aluminium",
   code_14721100:"Aluminiumoxide",
   code_14722000:"Nikkel",
   code_14723000:"Scandium",
   code_14724000:"Titanium",
   code_14725000:"Vanadium",
   code_14730000:"Chroom, mangaan, kobalt, yttrium en zirkonium",
   code_14731000:"Chroom",
   code_14732000:"Mangaan",
   code_14733000:"Kobalt",
   code_14734000:"Yttrium",
   code_14735000:"Zirkonium",
   code_14740000:"Molybdeen, technetium, ruthenium en rodium",
   code_14741000:"Molybdeen",
   code_14742000:"Technetium",
   code_14743000:"Ruthenium",
   code_14744000:"Rodium",
   code_14750000:"Cadmium, lutetium, hafnium, tantaal en wolfraam",
   code_14751000:"Cadmium",
   code_14752000:"Lutetium",
   code_14753000:"Hafnium",
   code_14754000:"Tantaal",
   code_14755000:"Wolfraam",
   code_14760000:"Iridium, gallium, indium, thallium en barium",
   code_14761000:"Iridium",
   code_14762000:"Gallium",
   code_14763000:"Indium",
   code_14764000:"Thallium",
   code_14765000:"Barium",
   code_14770000:"Cesium, strontium, rubidium en calcium",
   code_14771000:"Cesium",
   code_14772000:"Strontium",
   code_14773000:"Rubidium",
   code_14774000:"Calcium",
   code_14780000:"Kalium, magnesium, natrium en lithium",
   code_14781000:"Kalium",
   code_14782000:"Magnesium",
   code_14783000:"Natrium",
   code_14784000:"Lithium",
   code_14790000:"Niobium, osmium, renium en palladium",
   code_14791000:"Niobium",
   code_14792000:"Osmium",
   code_14793000:"Renium",
   code_14794000:"Palladium",
   code_14800000:"Diverse niet-metaalhoudende minerale producten",
   code_14810000:"Schuurproducten",
   code_14811000:"Molenstenen, slijpstenen en slijpwielen",
   code_14811100:"Molenstenen",
   code_14811200:"Slijpstenen",
   code_14811300:"Slijpwielen",
   code_14812000:"Schuurpoeder of -korrels",
   code_14813000:"Kunstmatig korund",
   code_14814000:"Kunstmatig grafiet",
   code_14820000:"Glas",
   code_14830000:"Fiberglas",
   code_14900000:"Gerecycleerde secundaire grondstoffen",
   code_14910000:"Gerecycleerde secundaire metaalhoudende grondstoffen",
   code_14920000:"Gerecycleerde secundaire niet-metaalhoudende grondstoffen",
   code_14930000:"Metaalhoudende as en residuen",
   code_15000000:"Voeding, dranken, tabak en aanverwante producten",
   code_15100000:"Dierlijke producten, vlees en vleesproducten",
   code_15110000:"Vlees",
   code_15111000:"Vlees van runderen",
   code_15111100:"Rundvlees",
   code_15111200:"Kalfsvlees",
   code_15112000:"Pluimvee",
   code_15112100:"Vers gevogelte",
   code_15112110:"Ganzen",
   code_15112120:"Kalkoenen",
   code_15112130:"Kippen",
   code_15112140:"Eenden",
   code_15112300:"Levers van pluimvee",
   code_15112310:"Foie gras",
   code_15113000:"Varkensvlees",
   code_15114000:"Slachtafval",
   code_15115000:"Lams- en schapenvlees",
   code_15115100:"Lamsvlees",
   code_15115200:"Schapenvlees",
   code_15117000:"Geitenvlees",
   code_15118000:"Paarden-, (muil)ezel- of muildiervlees",
   code_15118100:"Paardenvlees",
   code_15118900:"Vlees van (muil)ezels of muildieren",
   code_15119000:"Divers vlees",
   code_15119100:"Konijnenvlees",
   code_15119200:"Hazenvlees",
   code_15119300:"Wild",
   code_15119400:"Kikkerbillen",
   code_15119500:"Duiven",
   code_15119600:"Visvlees",
   code_15130000:"Vleesproducten",
   code_15131000:"Vleesconserven en -producten",
   code_15131100:"Worstvleesproducten",
   code_15131110:"Worstvlees",
   code_15131120:"Worstproducten",
   code_15131130:"Worsten",
   code_15131134:"Bloedworst en dergelijke",
   code_15131135:"Pluimveeworsten",
   code_15131200:"Gedroogd, gepekeld, gerookt of gekruid vlees",
   code_15131210:"Achterham",
   code_15131220:"Bacon",
   code_15131230:"Salami",
   code_15131300:"Leverproducten",
   code_15131310:"Pâté",
   code_15131320:"Producten van ganzen- of eendenlever",
   code_15131400:"Varkensproducten",
   code_15131410:"Ham",
   code_15131420:"Gehaktballen",
   code_15131490:"Bereide varkensschotels",
   code_15131500:"Pluimveeproducten",
   code_15131600:"Rund- en kalfsvleesproducten",
   code_15131610:"Gehaktballen van rundvlees",
   code_15131620:"Gehakt rundvlees",
   code_15131640:"Biefburgers",
   code_15131700:"Vleesgerechten",
   code_15200000:"Toebereide en geconserveerde vis",
   code_15210000:"Visfilets, vissenlevers, hom en kuit",
   code_15211000:"Visfilets",
   code_15211100:"Verse visfilets",
   code_15212000:"Hom en kuit",
   code_15213000:"Vissenlevers",
   code_15220000:"Ingevroren vis, visfilets en ander visvlees",
   code_15221000:"Ingevroren vis",
   code_15229000:"Ingevroren visproducten",
   code_15230000:"Gedroogde of gezouten vis; gepekelde vis; gerookte vis",
   code_15231000:"Gedroogde vis",
   code_15232000:"Gezouten vis",
   code_15233000:"Gepekelde vis",
   code_15234000:"Gerookte vis",
   code_15235000:"Geconserveerde vis",
   code_15240000:"Ingeblikte en andere bereide of geconserveerde vis",
   code_15241000:"Gepaneerde of ingeblikte vis",
   code_15241100:"Ingeblikte zalm",
   code_15241200:"Bereide of geconserveerde haring",
   code_15241300:"Sardines",
   code_15241400:"Ingeblikte tonijn",
   code_15241500:"Makreel",
   code_15241600:"Ansjovis",
   code_15241700:"Vissticks",
   code_15241800:"Gepaneerde visbereidingen",
   code_15242000:"Bereide visgerechten",
   code_15243000:"Visbereidingen",
   code_15244000:"Kaviaar en visseneieren",
   code_15244100:"Kaviaar",
   code_15244200:"Visseneieren",
   code_15250000:"Zeevruchten",
   code_15251000:"Ingevroren schaaldieren",
   code_15252000:"Bereide of geconserveerde schaaldieren",
   code_15253000:"Schelpdierproducten",
   code_15300000:"Groenten, fruit en aanverwante producten",
   code_15310000:"Aardappelen en aardappelproducten",
   code_15311000:"Ingevroren aardappelen",
   code_15311100:"Friet",
   code_15311200:"Blokjes, schijfjes en andere ingevroren aardappelen",
   code_15312000:"Aardappelproducten",
   code_15312100:"Instantaardappelpuree",
   code_15312200:"Voorgebakken friet",
   code_15312300:"Aardappelchips",
   code_15312310:"Aardappelchips met toegevoegde smaak",
   code_15312400:"Aardappelsnacks",
   code_15312500:"Aardappelkroketten",
   code_15313000:"Verwerkte aardappelen",
   code_15320000:"Vruchten- en groentensappen",
   code_15321000:"Vruchtensappen",
   code_15321100:"Sinaasappelsap",
   code_15321200:"Grapefruitsap",
   code_15321300:"Citroensap",
   code_15321400:"Ananassap",
   code_15321500:"Druivensap",
   code_15321600:"Appelsap",
   code_15321700:"Mixen van ongeconcentreerde sappen",
   code_15321800:"Geconcentreerde sappen",
   code_15322000:"Groentensappen",
   code_15322100:"Tomatensap",
   code_15330000:"Verwerkte groenten en fruit",
   code_15331000:"Verwerkte groenten",
   code_15331100:"Verse of ingevroren groenten",
   code_15331110:"Verwerkte wortelgewassen",
   code_15331120:"Verwerkte knolgewassen",
   code_15331130:"Bonen, erwten, paprika's, tomaten en andere groenten",
   code_15331131:"Verwerkte bonen",
   code_15331132:"Verwerkte erwten",
   code_15331133:"Spliterwten",
   code_15331134:"Verwerkte tomaten",
   code_15331135:"Verwerkte paddestoelen",
   code_15331136:"Verwerkte paprika's",
   code_15331137:"Taugé",
   code_15331138:"Truffels",
   code_15331140:"Bladgroenten en koolgewassen",
   code_15331142:"Verwerkte kool",
   code_15331150:"Verwerkte peulvruchten",
   code_15331170:"Ingevroren groenten",
   code_15331400:"Geconserveerde en/of ingeblikte groenten",
   code_15331410:"Bonen in tomatensaus",
   code_15331411:"Witte bonen in tomatensaus",
   code_15331420:"Geconserveerde tomaten",
   code_15331423:"Ingeblikte tomaten",
   code_15331425:"Tomatenpuree",
   code_15331427:"Geconcentreerde tomatenpuree",
   code_15331428:"Tomatensaus",
   code_15331430:"Ingeblikte champignons",
   code_15331450:"Verwerkte olijven",
   code_15331460:"Ingeblikte groenten",
   code_15331461:"Ingeblikte zuurkool",
   code_15331462:"Ingeblikte erwten",
   code_15331463:"Ingeblikte gedopte bonen",
   code_15331464:"Ingeblikte hele bonen",
   code_15331465:"Ingeblikte asperges",
   code_15331466:"Ingeblikte olijven",
   code_15331470:"Suikermaïs",
   code_15331480:"Tijdelijk geconserveerde groenten",
   code_15331500:"In azijn geconserveerde groenten",
   code_15332000:"Verwerkte vruchten en noten",
   code_15332100:"Verwerkte vruchten",
   code_15332140:"Verwerkte appelen",
   code_15332150:"Verwerkte peren",
   code_15332160:"Verwerkte bananen",
   code_15332170:"Rabarber",
   code_15332180:"Meloenen",
   code_15332200:"Jams en marmelades; vruchtengelei; vruchten- of notenmoes en -pasta",
   code_15332230:"Marmelades",
   code_15332231:"Sinaasappelmarmelade",
   code_15332232:"Citroenmarmelade",
   code_15332240:"Vruchtengelei",
   code_15332250:"Vruchtenpasta's",
   code_15332260:"Notenpasta's",
   code_15332261:"Pindakaas",
   code_15332270:"Vruchtenmoes",
   code_15332290:"Jam",
   code_15332291:"Abrikozenjam",
   code_15332292:"Bramenjam",
   code_15332293:"Zwartebessenjam",
   code_15332294:"Kersenjam",
   code_15332295:"Frambozenjam",
   code_15332296:"Aardbeienjam",
   code_15332300:"Verwerkte noten",
   code_15332310:"Geroosterde of gezouten noten",
   code_15332400:"Geconserveerde vruchten",
   code_15332410:"Gedroogde vruchten",
   code_15332411:"Behandelde krenten",
   code_15332412:"Verwerkte druiven",
   code_15332419:"Sultanarozijnen",
   code_15333000:"Plantaardige bijproducten",
   code_15400000:"Dierlijke of plantaardige oliën en vetten",
   code_15410000:"Ruwe dierlijke of plantaardige oliën en vetten",
   code_15411000:"Dierlijke of plantaardige oliën",
   code_15411100:"Plantaardige oliën",
   code_15411110:"Olijfolie",
   code_15411120:"Sesamolie",
   code_15411130:"Aardnotenolie",
   code_15411140:"Kokosolie",
   code_15411200:"Spijsolie",
   code_15411210:"Frituurolie",
   code_15412000:"Vetten",
   code_15412100:"Dierlijke vetten",
   code_15412200:"Plantaardige vetten",
   code_15413000:"Vaste residuen van plantaardige vetten of oliën",
   code_15413100:"Perskoek",
   code_15420000:"Geraffineerde oliën en vetten",
   code_15421000:"Geraffineerde oliën",
   code_15422000:"Geraffineerde vetten",
   code_15423000:"Gehydrogeneerde of veresterde oliën of vetten",
   code_15424000:"Plantaardige wassen",
   code_15430000:"Spijsvetten",
   code_15431000:"Margarine en dergelijke bereidingen",
   code_15431100:"Margarine",
   code_15431110:"Vloeibare margarine",
   code_15431200:"Margarine met verlaagd vetgehalte en halvarine",
   code_15500000:"Zuivelproducten",
   code_15510000:"Melk en room",
   code_15511000:"Melk",
   code_15511100:"Gepasteuriseerde melk",
   code_15511200:"Gesteriliseerde melk",
   code_15511210:"UHT-melk",
   code_15511300:"Magere melk",
   code_15511400:"Halfvolle melk",
   code_15511500:"Volle melk",
   code_15511600:"Gecondenseerde melk",
   code_15511700:"Melkpoeder",
   code_15512000:"Room",
   code_15512100:"Magere room",
   code_15512200:"Volvette room",
   code_15512300:"Dikke room",
   code_15512900:"Slagroom",
   code_15530000:"Boter",
   code_15540000:"Kaasproducten",
   code_15541000:"Tafelkaas",
   code_15542000:"Verse kaas",
   code_15542100:"Cottage cheese",
   code_15542200:"Zachte kaas",
   code_15542300:"Fetakaas",
   code_15543000:"Geraspte, poeder-, blauwe aderkaas en andere kaas",
   code_15543100:"Blauwe aderkaas",
   code_15543200:"Cheddar",
   code_15543300:"Geraspte kaas",
   code_15543400:"Parmezaanse kaas",
   code_15544000:"Harde kaas",
   code_15545000:"Smeerkaas",
   code_15550000:"Diverse zuivelproducten",
   code_15551000:"Yoghurt en andere gefermenteerde melk",
   code_15551300:"Yoghurt",
   code_15551310:"Yoghurt zonder smaakstoffen",
   code_15551320:"Yoghurt met smaakstoffen",
   code_15551500:"Karnemelk",
   code_15552000:"Caseïne",
   code_15553000:"Lactose of lactosestroop",
   code_15554000:"Wei",
   code_15555000:"Roomijs en aanverwante producten",
   code_15555100:"Roomijs",
   code_15555200:"Sorbet",
   code_15600000:"Graanmalerijproducten, zetmeel en zetmeelproducten",
   code_15610000:"Graanmalerijproducten",
   code_15611000:"Gepelde rijst",
   code_15612000:"Bloem van granen of plantaardige bloem en aanverwante producten",
   code_15612100:"Tarwebloem",
   code_15612110:"Volkorenbloem",
   code_15612120:"Broodbloem",
   code_15612130:"Gewoon bakmeel",
   code_15612150:"Bakmeel",
   code_15612190:"Zelfrijzend bakmeel",
   code_15612200:"Granenbloem",
   code_15612210:"Maïsmeel",
   code_15612220:"Rijstmeel",
   code_15612300:"Plantaardige bloem en meel",
   code_15612400:"Mixen voor de bereiding van bakkerswaren",
   code_15612410:"Cakemixen",
   code_15612420:"Bakmixen",
   code_15612500:"Bakkerijproducten",
   code_15613000:"Graankorrelproducten",
   code_15613100:"Gemalen haver",
   code_15613300:"Ontbijtgranen",
   code_15613310:"Bereide ontbijtgranen",
   code_15613311:"Cornflakes",
   code_15613313:"Muesli of dergelijke",
   code_15613319:"Gepofte tarwe",
   code_15613380:"Havermout",
   code_15614000:"Verwerkte rijst",
   code_15614100:"Langkorrelige rijst",
   code_15614200:"Geslepen rijst",
   code_15614300:"Breukrijst",
   code_15615000:"Zemelen",
   code_15620000:"Zetmeel en zetmeelproducten",
   code_15621000:"Maïsolie",
   code_15622000:"Glucose en glucoseproducten; fructose en fructoseproducten",
   code_15622100:"Glucose en glucoseproducten",
   code_15622110:"Glucose",
   code_15622120:"Glucosestroop",
   code_15622300:"Fructose en fructoseproducten",
   code_15622310:"Fructose",
   code_15622320:"Fructosebereidingen",
   code_15622321:"Fructoseoplossingen",
   code_15622322:"Fructosestroop",
   code_15623000:"Zetmeel",
   code_15624000:"Tapioca",
   code_15625000:"Griesmeel",
   code_15626000:"Custardpoeder",
   code_15700000:"Diervoeders",
   code_15710000:"Bereide voeders voor vee en andere dieren",
   code_15711000:"Visvoer",
   code_15712000:"Droogvoer",
   code_15713000:"Voeders voor huisdieren",
   code_15800000:"Diverse voedingsmiddelen",
   code_15810000:"Broodproducten, vers gebak en cake",
   code_15811000:"Broodproducten",
   code_15811100:"Brood",
   code_15811200:"Broodjes",
   code_15811300:"Croissants",
   code_15811400:"Crumpets",
   code_15811500:"Bereide broodproducten",
   code_15811510:"Sandwiches",
   code_15811511:"Belegde broodjes",
   code_15812000:"Gebak en cake",
   code_15812100:"Gebak",
   code_15812120:"Pasteien en taarten",
   code_15812121:"Pasteien",
   code_15812122:"Taarten",
   code_15812200:"Cakes",
   code_15813000:"Brood, broodjes, croissants e.d.",
   code_15820000:"Beschuit en koekjes; houdbaar gebak en cakes",
   code_15821000:"Geroosterde broodproducten en gebak",
   code_15821100:"Geroosterde broodproducten",
   code_15821110:"Geroosterd brood",
   code_15821130:"Knäckebröd",
   code_15821150:"Beschuit",
   code_15821200:"Zoete koekjes",
   code_15830000:"Suiker en aanverwante producten",
   code_15831000:"Suiker",
   code_15831200:"Witte suiker",
   code_15831300:"Ahornsuiker en esdoornstroop",
   code_15831400:"Melasse",
   code_15831500:"Suikerstroop",
   code_15831600:"Honing",
   code_15832000:"Afval van de suikerindustrie",
   code_15833000:"Suikerproducten",
   code_15833100:"Nagerechten",
   code_15833110:"Taartbodems",
   code_15840000:"Cacao; chocolade en suikerwerk",
   code_15841000:"Cacao",
   code_15841100:"Cacaopasta",
   code_15841200:"Cacaoboter, -vet of -olie",
   code_15841300:"Ongezoet cacaopoeder",
   code_15841400:"Gezoet cacaopoeder",
   code_15842000:"Chocolade en suikerwerk",
   code_15842100:"Chocolade",
   code_15842200:"Chocoladeproducten",
   code_15842210:"Chocoladedrank",
   code_15842220:"Chocoladerepen",
   code_15842300:"Suikerwerk",
   code_15842310:"Snoepgoed",
   code_15842320:"Noga",
   code_15842400:"Gekonfijte vruchten, noten en vruchtenschillen",
   code_15850000:"Pastaproducten",
   code_15851000:"Meelproducten",
   code_15851100:"Ongekookte pasta",
   code_15851200:"Bereide pasta en couscous",
   code_15851210:"Bereide pasta",
   code_15851220:"Gevulde pasta",
   code_15851230:"Lasagne",
   code_15851250:"Couscous",
   code_15851290:"Ingeblikte pastaproducten",
   code_15860000:"Koffie, thee en aanverwante producten",
   code_15861000:"Koffie",
   code_15861100:"Gebrande koffie",
   code_15861200:"Cafeïnevrije koffie",
   code_15862000:"Koffiesurrogaten",
   code_15863000:"Thee",
   code_15863100:"Groene thee",
   code_15863200:"Zwarte thee",
   code_15864000:"Bereidingen van thee of maté",
   code_15864100:"Theezakjes",
   code_15865000:"Kruideninfusies",
   code_15870000:"Specerijen en kruiderijen",
   code_15871000:"Azijn; sauzen; samengestelde kruiderijen; mosterdmeel; bereide mosterd",
   code_15871100:"Azijn en azijnsurrogaten",
   code_15871110:"Azijn of dergelijke",
   code_15871200:"Sauzen, samengestelde specerijen en kruiderijen",
   code_15871210:"Sojasaus",
   code_15871230:"Tomatenketchup",
   code_15871250:"Mosterd",
   code_15871260:"Sauzen",
   code_15871270:"Samengestelde kruiderijen",
   code_15871273:"Mayonaise",
   code_15871274:"Sandwich spreads",
   code_15871279:"Chutney",
   code_15872000:"Kruiden en specerijen",
   code_15872100:"Peper",
   code_15872200:"Specerijen",
   code_15872300:"Kruiden",
   code_15872400:"Zout",
   code_15872500:"Gember",
   code_15880000:"Speciale voedingsproducten",
   code_15881000:"Gehomogeniseerde voedingspreparaten",
   code_15882000:"Dieetproducten",
   code_15884000:"Babyvoeding",
   code_15890000:"Diverse voedingsmiddelen en droge waren",
   code_15891000:"Gebonden en heldere soepen",
   code_15891100:"Vleessoep",
   code_15891200:"Vissoep",
   code_15891300:"Gemengde soep",
   code_15891400:"Soep",
   code_15891410:"Soepmixen",
   code_15891500:"Bouillon",
   code_15891600:"Geconcentreerde bouillon",
   code_15891610:"Bouillonmixen",
   code_15891900:"Groentesoep",
   code_15892000:"Plantensappen, -extracten, digestieve stoffen en bindmiddelen",
   code_15892100:"Plantensappen",
   code_15892200:"Plantenextracten",
   code_15892400:"Bindmiddelen",
   code_15893000:"Droge waren",
   code_15893100:"Voedingsmixen",
   code_15893200:"Dessertmixen",
   code_15893300:"Vleessausmixen",
   code_15894000:"Verwerkte voedingsmiddelen",
   code_15894100:"Veganistische pakketten",
   code_15894200:"Bereide maaltijden",
   code_15894210:"Schoolmaaltijden",
   code_15894220:"Ziekenhuismaaltijden",
   code_15894300:"Bereide gerechten",
   code_15894400:"Snacks",
   code_15894500:"Artikelen voor verkoopautomaten",
   code_15894600:"Sandwichbeleg",
   code_15894700:"Delicatessen",
   code_15895000:"Fastfoodproducten",
   code_15895100:"Hamburgers",
   code_15896000:"Diepvriesproducten",
   code_15897000:"Blikconserven en veldrantsoenen",
   code_15897100:"Veldrantsoenen",
   code_15897200:"Blikconserven",
   code_15897300:"Voedselpakketten",
   code_15898000:"Gist",
   code_15899000:"Bakpoeder",
   code_15900000:"Dranken, tabak en aanverwante producten",
   code_15910000:"Gedistilleerde alcoholische dranken",
   code_15911000:"Sterke dranken",
   code_15911100:"Spiritualiën",
   code_15911200:"Likeuren",
   code_15930000:"Wijnen",
   code_15931000:"Niet-gearomatiseerde wijn",
   code_15931100:"Mousserende wijn",
   code_15931200:"Tafelwijn",
   code_15931300:"Port",
   code_15931400:"Madeira",
   code_15931500:"Druivenmost",
   code_15931600:"Sherry",
   code_15932000:"Wijndroesem",
   code_15940000:"Cider en andere vruchtenwijnen",
   code_15941000:"Cider",
   code_15942000:"Vruchtenwijnen",
   code_15950000:"Niet-gedistilleerde gegiste dranken",
   code_15951000:"Vermout",
   code_15960000:"Moutbier",
   code_15961000:"Bier",
   code_15961100:"Lagerbier",
   code_15962000:"Brouwerij- of stokerijafval",
   code_15980000:"Niet-alcoholische dranken",
   code_15981000:"Mineraalwater",
   code_15981100:"Niet-koolzuurhoudend mineraalwater",
   code_15981200:"Koolzuurhoudend mineraalwater",
   code_15981300:"Water in vaste vorm",
   code_15981310:"IJs",
   code_15981320:"Sneeuw",
   code_15981400:"Mineraalwater met toegevoegde smaakstoffen",
   code_15982000:"Frisdranken",
   code_15982100:"Geconcentreerde vruchtensiroop zonder toevoegingen",
   code_15982200:"Chocolademelk",
   code_15990000:"Tabak, tabakswaren en benodigdheden",
   code_15991000:"Tabakswaren",
   code_15991100:"Sigaren",
   code_15991200:"Cigarillos",
   code_15991300:"Sigaretten",
   code_15992000:"Tabak",
   code_15992100:"Verwerkte tabak",
   code_15993000:"Tabakshandelswaren",
   code_15994000:"Sigarettenpapier en filterpapier",
   code_15994100:"Sigarettenpapier",
   code_15994200:"Filterpapier",
   code_16000000:"Landbouwmachines",
   code_16100000:"Machines voor landbouw of bosbouw, voor voorbereiding of bewerking van de grond",
   code_16110000:"Ploegen en schijveneggen",
   code_16120000:"Eggen, grondbrekers, cultivators, hakploegen of schoffels",
   code_16130000:"Zaai-, plant- of pootmachines",
   code_16140000:"Mestverspreiders",
   code_16141000:"Kunstmeststrooiers",
   code_16150000:"Rollers voor gazons of sportvelden",
   code_16160000:"Diverse tuingereedschappen",
   code_16300000:"Oogstmachines",
   code_16310000:"Maaimachines",
   code_16311000:"Grasmaaiers",
   code_16311100:"Maaimachines voor gazons, parken of sportvelden",
   code_16320000:"Hooimachines",
   code_16330000:"Stro- en veevoederpersen",
   code_16331000:"Opraappersen",
   code_16340000:"Oogst- en dorsmachines",
   code_16400000:"Sproeimachines voor de land- of tuinbouw",
   code_16500000:"Trailers en opleggers voor de landbouw, met eigen laad- of losinstallatie",
   code_16510000:"Landbouwtrailers met eigen laadinstallatie",
   code_16520000:"Landbouwtrailers met eigen losinstallatie",
   code_16530000:"Landbouwopleggers met eigen laadinstallatie",
   code_16540000:"Landbouwopleggers met eigen losinstallatie",
   code_16600000:"Speciale machines voor de landbouw of bosbouw",
   code_16610000:"Machines voor het reinigen, (op maat) sorteren van eieren, vruchten of andere landbouwproducten",
   code_16611000:"Machines voor het reinigen van landbouwproducten",
   code_16611100:"Machines voor het reinigen van eieren",
   code_16611200:"Machines voor het reinigen van vruchten",
   code_16612000:"Machines voor het (op maat) sorteren van landbouwproducten",
   code_16612100:"Machines voor het (op maat) sorteren van eieren",
   code_16612200:"Machines voor het (op maat) sorteren van fruit",
   code_16613000:"Machines voor het reinigen, (op maat) sorteren van zaad, graan of gedroogde groenten",
   code_16620000:"Melkmachines",
   code_16630000:"Machines voor het bereiden van veevoeder",
   code_16640000:"Machines voor de bijenhouderij",
   code_16650000:"Machines voor de pluimveehouderij",
   code_16651000:"Broedmachines en kunstmoeders voor de pluimveehouderij",
   code_16700000:"Tractoren",
   code_16710000:"Motoculteurs",
   code_16720000:"Tweedehandstractoren",
   code_16730000:"Tractiemotoren",
   code_16800000:"Delen van machines voor de landbouw of de bosbouw",
   code_16810000:"Delen van landbouwmachines",
   code_16820000:"Delen van bosbouwmachines",
   code_18000000:"Kleding, schoeisel, bagageartikelen en accessoires",
   code_18100000:"Beroepskleding, speciale werkkleding en toebehoren",
   code_18110000:"Beroepskleding",
   code_18113000:"Industriële kleding",
   code_18114000:"Werkpakken",
   code_18130000:"Speciale werkkleding",
   code_18132000:"Vliegkleding",
   code_18132100:"Vliegeniersjassen",
   code_18132200:"Vliegenierspakken",
   code_18140000:"Accessoires voor werkkleding",
   code_18141000:"Werkhandschoenen",
   code_18142000:"Veiligheidsmaskers",
   code_18143000:"Beschermende uitrusting",
   code_18200000:"Bovenkleding",
   code_18210000:"Jassen",
   code_18211000:"Capes",
   code_18212000:"Schoudermantels",
   code_18213000:"Windjacks",
   code_18220000:"Weerbestendige kleding",
   code_18221000:"Waterdichte kleding",
   code_18221100:"Waterdichte capes",
   code_18221200:"Anoraks",
   code_18221300:"Regenjassen",
   code_18222000:"Bedrijfskleding",
   code_18222100:"Herenkostuums en mantelpakjes",
   code_18222200:"Pakken",
   code_18223000:"Colbertjassen en blazers",
   code_18223100:"Blazers",
   code_18223200:"Colbertjassen",
   code_18224000:"Kleding van beklede of geïmpregneerde textielstof",
   code_18230000:"Diverse bovenkleding",
   code_18231000:"Jurken",
   code_18232000:"Rokken",
   code_18233000:"Shorts",
   code_18234000:"Broeken",
   code_18235000:"Truien, vesten en aanverwante artikelen",
   code_18235100:"Truien",
   code_18235200:"Vesten",
   code_18235300:"Sweaters",
   code_18235400:"Jasjes",
   code_18300000:"Kledingstukken",
   code_18310000:"Ondergoed",
   code_18311000:"Onderjurken",
   code_18312000:"Onderbroeken",
   code_18313000:"Slipjes",
   code_18314000:"Badjassen",
   code_18315000:"Kousen",
   code_18316000:"Panty's",
   code_18317000:"Sokken",
   code_18318000:"Nachtkleding",
   code_18318100:"Nachthemden",
   code_18318200:"Ochtendjassen",
   code_18318300:"Pyjama's",
   code_18318400:"Hemden",
   code_18318500:"Nachtponnen",
   code_18320000:"Beha's, korsetten, jarretels en soortgelijke artikelen",
   code_18321000:"Beha's",
   code_18322000:"Korsetten",
   code_18323000:"Jarretels",
   code_18330000:"T-shirts en overhemden",
   code_18331000:"T-shirts",
   code_18332000:"Overhemden",
   code_18333000:"Poloshirts",
   code_18400000:"Speciale kleding en accessoires",
   code_18410000:"Speciale kleding",
   code_18411000:"Babykleding",
   code_18412000:"Sportkleding",
   code_18412100:"Trainingspakken",
   code_18412200:"Sporthemden",
   code_18412300:"Skipakken",
   code_18412800:"Zwemkleding",
   code_18420000:"Kledingaccessoires",
   code_18421000:"Zakdoeken",
   code_18422000:"Sjaals",
   code_18423000:"Stropdassen",
   code_18424000:"Handschoenen",
   code_18424300:"Wegwerphandschoenen",
   code_18424400:"Wanten",
   code_18424500:"Kaphandschoenen",
   code_18425000:"Ceintuurs",
   code_18425100:"Schouderriemen",
   code_18440000:"Hoeden en hoofddeksels",
   code_18441000:"Hoeden",
   code_18443000:"Hoofddeksels en accessoires voor hoofddeksels",
   code_18443100:"Hoofdbanden",
   code_18443300:"Hoofddeksels",
   code_18443310:"Baretten",
   code_18443320:"Veldpetten",
   code_18443330:"Mutsen",
   code_18443340:"Petten",
   code_18443400:"Kinbanden voor hoofddeksels",
   code_18443500:"Zonnekleppen",
   code_18444000:"Beschermende hoofddeksels",
   code_18444100:"Veiligheidshoofddeksels",
   code_18444110:"Helmen",
   code_18444111:"Valhelmen",
   code_18444112:"Fietshelmen",
   code_18444200:"Veiligheidshelmen",
   code_18450000:"Sluitingen (kleding)",
   code_18451000:"Knopen",
   code_18451100:"Delen van knopen",
   code_18452000:"Veiligheidsspelden",
   code_18453000:"Ritssluitingen",
   code_18500000:"Juwelen, horloges en aanverwante artikelen",
   code_18510000:"Juwelen en aanverwante artikelen",
   code_18511000:"Edelstenen voor juwelen",
   code_18511100:"Diamanten",
   code_18511200:"Robijnen",
   code_18511300:"Smaragden",
   code_18511400:"Opalen",
   code_18511500:"Kwartsen",
   code_18511600:"Toermalijnen",
   code_18512000:"Munten en medailles",
   code_18512100:"Munten",
   code_18512200:"Medailles",
   code_18513000:"Sieraden",
   code_18513100:"Parels",
   code_18513200:"Goudsmeedwerk",
   code_18513300:"Voorwerpen van edele metalen",
   code_18513400:"Voorwerpen van edelstenen of halfedelstenen",
   code_18513500:"Zilversmeedwerk",
   code_18520000:"Persoonlijke uurwerken",
   code_18521000:"Horloges",
   code_18521100:"Glas voor horloges",
   code_18522000:"Polshorloges",
   code_18523000:"Stopwatches",
   code_18530000:"Geschenken en beloningen",
   code_18600000:"Bont en bontartikelen",
   code_18610000:"Bontartikelen",
   code_18611000:"Pelshuiden",
   code_18612000:"Kleding van bont",
   code_18613000:"Producten van namaakbont",
   code_18620000:"Bont",
   code_18800000:"Schoeisel",
   code_18810000:"Schoeisel, met uitzondering van sport- en veiligheidsschoeisel",
   code_18811000:"Waterdicht schoeisel",
   code_18812000:"Schoeisel met rubber of plastic delen",
   code_18812100:"Sandalen met bovendeel van rubber of plastic",
   code_18812200:"Rubberlaarzen",
   code_18812300:"Stadsschoenen met rubber of plastic bovenwerk",
   code_18812400:"Teenslippers",
   code_18813000:"Schoeisel met lederen bovenwerk",
   code_18813100:"Sandalen",
   code_18813200:"Slippers",
   code_18813300:"Stadsschoenen",
   code_18814000:"Schoeisel met textielen bovenwerk",
   code_18815000:"Laarzen",
   code_18815100:"Enkellaarzen",
   code_18815200:"Kuitlaarzen",
   code_18815300:"Knielaarzen",
   code_18815400:"Lieslaarzen",
   code_18816000:"Overschoenen",
   code_18820000:"Sportschoeisel",
   code_18821000:"Skischoeisel",
   code_18821100:"Langlaufschoenen",
   code_18822000:"Trainingsschoenen",
   code_18823000:"Bergschoenen",
   code_18824000:"Voetbalschoenen",
   code_18830000:"Veiligheidsschoeisel",
   code_18831000:"Schoeisel met beschermende metalen neus",
   code_18832000:"Speciaal schoeisel",
   code_18832100:"Vliegeniersschoenen",
   code_18840000:"Delen van schoeisel",
   code_18841000:"Bovendelen van schoeisel",
   code_18842000:"Zolen",
   code_18843000:"Hakken",
   code_18900000:"Bagage, zadeltuig, zakken en tassen",
   code_18910000:"Zadeltuig",
   code_18911000:"Zadels",
   code_18912000:"Rijzwepen",
   code_18913000:"Zwepen",
   code_18920000:"Bagage",
   code_18921000:"Reiskoffers",
   code_18923000:"Aktentassen en portefeuilles",
   code_18923100:"Aktentassen",
   code_18923200:"Portefeuilles",
   code_18924000:"Hutkoffers",
   code_18925000:"Veldfleshouders en holsters",
   code_18925100:"Veldfleshouders",
   code_18925200:"Holsters",
   code_18929000:"Toilettassen",
   code_18930000:"Zakken en tassen",
   code_18931000:"Reistassen",
   code_18931100:"Rugzakken",
   code_18932000:"Sporttassen",
   code_18933000:"Post- of pakketzakken",
   code_18933100:"Postzakken",
   code_18934000:"Plunjezakken",
   code_18935000:"Waszakken",
   code_18936000:"Zakken van textiel",
   code_18937000:"Zakken voor het verpakken van goederen",
   code_18937100:"Tassen voor het verpakken van goederen",
   code_18938000:"Gewatteerde enveloppen",
   code_18939000:"Handtassen",
   code_19000000:"Leder, textielweefsels, kunststof en rubber materialen",
   code_19100000:"Leder",
   code_19110000:"Zeemleer",
   code_19120000:"Leder van runder- en paardachtigen",
   code_19130000:"Leder van schapen, geiten en varkens",
   code_19131000:"Schapenleder of lamsleder",
   code_19132000:"Geitenleder en glacé",
   code_19133000:"Varkensleder",
   code_19140000:"Leder van andere dieren, samengesteld leder en ander leder",
   code_19141000:"Leder van andere dieren",
   code_19142000:"Kunstleder",
   code_19143000:"Imitatieleder",
   code_19144000:"Lakleder",
   code_19160000:"Horlogebandjes",
   code_19170000:"Lederwaren voor gebruik in machines of bij mechanische toepassingen",
   code_19200000:"Textielweefsels en aanverwante artikelen",
   code_19210000:"Geweven stoffen",
   code_19211000:"Synthetische weefsels",
   code_19211100:"Gemengde weefsels",
   code_19212000:"Katoenen weefsels",
   code_19212100:"Tijk",
   code_19212200:"Denim",
   code_19212300:"Canvas",
   code_19212310:"Canvasartikelen",
   code_19212400:"Popeline",
   code_19212500:"Singel",
   code_19212510:"Singelbanden",
   code_19220000:"Wollen stoffen",
   code_19230000:"Linnen stoffen",
   code_19231000:"Linnengoed",
   code_19240000:"Speciale weefsels",
   code_19241000:"Pool",
   code_19242000:"Badstof",
   code_19243000:"Bekledingsstoffen",
   code_19244000:"Gordijnstoffen",
   code_19245000:"Voeringstoffen",
   code_19250000:"Gebreide of gehaakte stoffen",
   code_19251000:"Gebreide stoffen",
   code_19251100:"Poolweefsels",
   code_19252000:"Gehaakte stoffen",
   code_19260000:"Doek",
   code_19270000:"Vliesstoffen",
   code_19280000:"Wol, huiden en vellen van dieren",
   code_19281000:"Wol",
   code_19282000:"Dierlijke vellen",
   code_19283000:"Huiden van vogels, en veren",
   code_19400000:"Textielgaren en -draad",
   code_19410000:"Natuurlijke textielvezels",
   code_19420000:"Synthetische textielvezels",
   code_19430000:"Textielgaren en draad van natuurlijke vezels",
   code_19431000:"Zijdegaren",
   code_19432000:"Wolgaren",
   code_19433000:"Katoengaren",
   code_19434000:"Vlasgaren",
   code_19435000:"Naaidraad en -garen van natuurlijke vezels",
   code_19435100:"Naaigaren",
   code_19435200:"Breigaren",
   code_19436000:"Garen van plantaardige textielvezels",
   code_19440000:"Synthetisch garen of draad",
   code_19441000:"Synthetisch garen",
   code_19442000:"Synthetische draad",
   code_19442100:"Synthetisch naaigaren",
   code_19442200:"Synthetisch breigaren",
   code_19500000:"Rubber en kunststofmaterialen",
   code_19510000:"Producten van rubber",
   code_19511000:"Binnenbanden, loopvlakken en velglinten van rubber",
   code_19511100:"Velglinten",
   code_19511200:"Binnenbanden",
   code_19511300:"Loopvlakken voor banden",
   code_19512000:"Artikelen van niet-gevulcaniseerd rubber",
   code_19513000:"Met rubber beklede textielweefsels",
   code_19513100:"Bandenkoordweefsel",
   code_19513200:"Plakband van met rubber bekleed textiel",
   code_19514000:"Geregenereerd rubber",
   code_19520000:"Producten van plastic",
   code_19521000:"Polystyreen producten",
   code_19521100:"Polystyreen bekleding",
   code_19521200:"Polystyreen platen",
   code_19522000:"Harsen",
   code_19522100:"Epoxyhars",
   code_19522110:"Buizen van epoxyhars",
   code_19600000:"Afval van leer, textiel, rubber en kunststof",
   code_19610000:"Afval van leder",
   code_19620000:"Textielafval",
   code_19630000:"Afval van rubber",
   code_19640000:"Afval- en vuilniszakken van polytheen",
   code_19700000:"Synthetische rubber en vezels",
   code_19710000:"Synthetische rubber",
   code_19720000:"Synthetische vezels",
   code_19721000:"Synthetisch filamentwerk",
   code_19722000:"Garens met een hoge trekvastheid",
   code_19723000:"Eendraads getextureerd garen",
   code_19724000:"Synthetische monofilamenten",
   code_19730000:"Stapelvezels",
   code_19731000:"Kunstmatige stapelvezels",
   code_19732000:"Polypropyleen",
   code_19733000:"Getextureerde kunstmatige garens",
   code_22000000:"Drukwerk en aanverwante producten",
   code_22100000:"Boeken, brochures en folders",
   code_22110000:"Gedrukte boeken",
   code_22111000:"Schoolboeken",
   code_22112000:"Handboeken",
   code_22113000:"Bibliotheekboeken",
   code_22114000:"Woordenboeken, kaarten, partituren en andere boeken",
   code_22114100:"Woordenboeken",
   code_22114200:"Atlassen",
   code_22114300:"Kaarten",
   code_22114310:"Kadasterkaarten",
   code_22114311:"Blauwdrukken",
   code_22114400:"Bladmuziek",
   code_22114500:"Encyclopedieën",
   code_22120000:"Publicaties",
   code_22121000:"Technische publicaties",
   code_22130000:"Telefoonboeken",
   code_22140000:"Folders",
   code_22150000:"Brochures",
   code_22160000:"Boekjes",
   code_22200000:"Kranten, vaktijdschriften, periodieken en geïllustreerde tijdschriften",
   code_22210000:"Kranten",
   code_22211000:"Vaktijdschriften",
   code_22211100:"Staatsbladen",
   code_22212000:"Periodieken",
   code_22212100:"Seriepublicaties",
   code_22213000:"Tijdschriften",
   code_22300000:"Briefkaarten, wenskaarten en ander drukwerk",
   code_22310000:"Briefkaarten",
   code_22312000:"Afbeeldingen",
   code_22313000:"Transfers",
   code_22314000:"Ontwerpen",
   code_22315000:"Foto's",
   code_22320000:"Wenskaarten",
   code_22321000:"Kerstkaarten",
   code_22400000:"Zegels, chequeformulieren, bankbiljetten, aandelen, reclamedrukwerk, catalogi en handboeken",
   code_22410000:"Zegels",
   code_22411000:"Kerstzegels",
   code_22412000:"Postfrisse postzegels",
   code_22413000:"Spaarzegels",
   code_22414000:"Postzegelhouders",
   code_22420000:"Gezegeld papier",
   code_22430000:"Bankbiljetten",
   code_22440000:"Chequeformulieren",
   code_22450000:"Onvervalsbaar drukwerk",
   code_22451000:"Paspoorten",
   code_22452000:"Postwissels",
   code_22453000:"Autobelastingplaatjes",
   code_22454000:"Rijbewijzen",
   code_22455000:"Identiteitskaarten",
   code_22455100:"Identiteitsarmbanden",
   code_22456000:"Vergunningen",
   code_22457000:"Toegangskaartjes",
   code_22458000:"Maatdrukwerk",
   code_22459000:"Tickets",
   code_22459100:"Reclamestickers en -stroken",
   code_22460000:"Materiaal voor reclamedrukwerk, handelscatalogi en handleidingen",
   code_22461000:"Catalogi",
   code_22461100:"Memohouders",
   code_22462000:"Reclamemateriaal",
   code_22470000:"Handleidingen",
   code_22471000:"Computerhandleidingen",
   code_22472000:"Instructiehandleidingen",
   code_22473000:"Technische handleidingen",
   code_22500000:"Drukplaten of -cilinders of andere drukmiddelen",
   code_22510000:"Offsetplaten",
   code_22520000:"Droogetsmateriaal",
   code_22521000:"Reliëfdrukmateriaal",
   code_22600000:"Inkt",
   code_22610000:"Drukinkt",
   code_22611000:"Diepdrukinkt",
   code_22612000:"Oost-Indische inkt",
   code_22800000:"Registers, handelsboeken, ordners, formulieren en overige gedrukte kantoorartikelen, van papier of van karton",
   code_22810000:"Registers van papier of karton",
   code_22813000:"Handelsboeken",
   code_22814000:"Reçublokken",
   code_22815000:"Notitieboekjes",
   code_22816000:"Schrijfblokken",
   code_22816100:"Notitieblokken",
   code_22816200:"Stenoblokken",
   code_22816300:"Post-itblokken",
   code_22817000:"Agenda's of personal organisers",
   code_22819000:"Adresboekjes",
   code_22820000:"Formulieren",
   code_22821000:"Stembiljetten",
   code_22822000:"Commerciële formulieren",
   code_22822100:"Commerciële kettingformulieren",
   code_22822200:"Losse commerciële formulieren",
   code_22830000:"Schriften",
   code_22831000:"Navullingen voor schoolnotitieblokken",
   code_22832000:"Kladpapier",
   code_22840000:"Monsterboeken",
   code_22841000:"Verzamelalbums",
   code_22841100:"Zegelboekjes",
   code_22841200:"Postzegelmappen",
   code_22850000:"Ordners en toebehoren",
   code_22851000:"Ordners",
   code_22852000:"Mappen",
   code_22852100:"Dossiermappen",
   code_22853000:"Archiefmappen",
   code_22900000:"Divers drukwerk",
   code_22990000:"Courantenpapier, handgeschept papier en ander papier en karton, niet gestreken, van de soort gebruikt voor grafische doeleinden",
   code_22991000:"Krantenpapier",
   code_22992000:"Handgeschept papier of karton",
   code_22993000:"Lichtgevoelig, warmtegevoelig of thermografisch papier en karton",
   code_22993100:"Lichtgevoelig papier of karton",
   code_22993200:"Warmtegevoelig papier en karton",
   code_22993300:"Thermografisch papier en karton",
   code_22993400:"Gegolfd papier of golfkarton",
   code_24000000:"Chemische producten",
   code_24100000:"Gassen",
   code_24110000:"Industriële gassen",
   code_24111000:"Waterstof, argon, edelgassen, stikstof en zuurstof",
   code_24111100:"Argon",
   code_24111200:"Edelgassen",
   code_24111300:"Helium",
   code_24111400:"Neon",
   code_24111500:"Medische gassen",
   code_24111600:"Waterstof",
   code_24111700:"Stikstof",
   code_24111800:"Vloeibare stikstof",
   code_24111900:"Zuurstof",
   code_24112000:"Anorganische zuurstofverbindingen",
   code_24112100:"Kooldioxide",
   code_24112200:"Stikstofoxide",
   code_24112300:"Gasvormige anorganische zuurstofverbindingen",
   code_24113000:"Vloeibare lucht en perslucht",
   code_24113100:"Vloeibare lucht",
   code_24113200:"Perslucht",
   code_24200000:"Kleurstoffen en pigmenten",
   code_24210000:"Oxiden, peroxiden en hydroxiden",
   code_24211000:"Zinkoxide en peroxide, titaniumoxide, verfstoffen en pigmenten",
   code_24211100:"Zinkoxide",
   code_24211200:"Zinkperoxide",
   code_24211300:"Titaniumoxide",
   code_24212000:"Chroom-, mangaan-, magnesium-, lood- en koperoxiden en -hydroxiden",
   code_24212100:"Chroomoxide",
   code_24212200:"Mangaanoxide",
   code_24212300:"Loodoxide",
   code_24212400:"Koperoxide",
   code_24212500:"Magnesiumoxide",
   code_24212600:"Hydroxiden voor verf en pigmenten",
   code_24212610:"Chroomhydroxide",
   code_24212620:"Mangaanhydroxide",
   code_24212630:"Loodhydroxide",
   code_24212640:"Koperhydroxide",
   code_24212650:"Magnesiumhydroxide",
   code_24213000:"Zuivere gebluste kalk",
   code_24220000:"Looi-, verfextracten, looistoffen en kleurstoffen",
   code_24221000:"Verfextracten",
   code_24222000:"Looi-extracten",
   code_24223000:"Looistoffen",
   code_24224000:"Kleurstoffen",
   code_24225000:"Looipreparaten",
   code_24300000:"Anorganische en organische basischemicaliën",
   code_24310000:"Anorganische chemische basisproducten",
   code_24311000:"Chemische elementen, anorganische zuren en verbindingen",
   code_24311100:"Metalloïden",
   code_24311110:"Fosfiden",
   code_24311120:"Carbiden",
   code_24311130:"Hydriden",
   code_24311140:"Nitriden",
   code_24311150:"Aziden",
   code_24311160:"Siliciden",
   code_24311170:"Boriden",
   code_24311180:"Geraffineerde zwavel",
   code_24311200:"Halogeen",
   code_24311300:"Alkalimetalen",
   code_24311310:"Kwik",
   code_24311400:"Waterstofchloride, anorganische zuren, silicium- en zwaveldioxide",
   code_24311410:"Anorganische zuren",
   code_24311411:"Zwavelzuur",
   code_24311420:"Fosforzuur",
   code_24311430:"Polyfosforzuur",
   code_24311440:"Waterstofhexafluorosilicaat",
   code_24311450:"Zwaveldioxide",
   code_24311460:"Siliciumdioxide",
   code_24311470:"Waterstofchloride",
   code_24311500:"Hydroxiden als anorganische basische chemicaliën",
   code_24311510:"Metaaloxiden",
   code_24311511:"IJzerzouten en ijzeroxiden",
   code_24311520:"Natriumhydroxide",
   code_24311521:"Bijtende soda",
   code_24311522:"Vloeibare soda",
   code_24311600:"Zwavelverbindingen",
   code_24311700:"Zwavel",
   code_24311800:"Koolstof",
   code_24311900:"Chloor",
   code_24312000:"Verbindingen van metalen met halogenen; hypochlorieten, chloraten en perchloraten",
   code_24312100:"Verbindingen van metalen met halogenen",
   code_24312110:"Natriumhexafluorsilicaat",
   code_24312120:"Chloriden",
   code_24312121:"Aluminiumchloride",
   code_24312122:"Ferrichloride",
   code_24312123:"Polyaluminiumchloride",
   code_24312130:"Aluminiumchloorhydraat",
   code_24312200:"Hypochlorieten en chloraten",
   code_24312210:"Natriumchloride",
   code_24312220:"Natriumhypochloride",
   code_24313000:"Sulfiden, sulfaten; nitraten, fosfaten en carbonaten",
   code_24313100:"Sulfiden, sulfieten en sulfaten",
   code_24313110:"Diverse sulfiden",
   code_24313111:"Waterstofsulfide",
   code_24313112:"Polysulfiden",
   code_24313120:"Sulfaten",
   code_24313121:"Natriumthiosulfaat",
   code_24313122:"Ferrisulfaat",
   code_24313123:"Aluminiumsulfaat",
   code_24313124:"Natriumsulfaat",
   code_24313125:"IJzersulfaat",
   code_24313126:"Kopersulfaat",
   code_24313200:"Fosfinaten, fosfonaten, fosfaten en polyfosfaten",
   code_24313210:"Natriumhexametafosfaat",
   code_24313220:"Fosfaten",
   code_24313300:"Carbonaten",
   code_24313310:"Natriumcarbonaat",
   code_24313320:"Natriumbicarbonaat",
   code_24313400:"Nitraten",
   code_24314000:"Metaalzouten",
   code_24314100:"Kaliumpermanganaat",
   code_24314200:"Oxometaalzuren",
   code_24315000:"Anorganische chemicaliën",
   code_24315100:"Zwaar water en andere isotopen en verbindingen",
   code_24315200:"Cyanide, cyanideoxide, fulminaten, cyanaten, silicaten; boraten, perboraten, zouten van anorganische zuren",
   code_24315210:"Cyaniden",
   code_24315220:"Cyanideoxide",
   code_24315230:"Fulminaten",
   code_24315240:"Cyanaten",
   code_24315300:"Waterstofperoxide",
   code_24315400:"Piëzo-elektrisch kwarts",
   code_24315500:"Verbindingen van zeldzame aardmetalen",
   code_24315600:"Silicaten",
   code_24315610:"Natriumsilicaat",
   code_24315700:"Boraten en perboraten",
   code_24316000:"Gedistilleerd water",
   code_24317000:"Synthetische stenen",
   code_24317100:"Synthetische edelstenen",
   code_24317200:"Synthetische halfedelstenen",
   code_24320000:"Organische basischemicaliën",
   code_24321000:"Koolwaterstoffen",
   code_24321100:"Verzadigde koolwaterstoffen",
   code_24321110:"Acyclische verzadigde koolwaterstoffen",
   code_24321111:"Methaan",
   code_24321112:"Ethyleen",
   code_24321113:"Propeen",
   code_24321114:"Buteen",
   code_24321115:"Acetyleen",
   code_24321120:"Cyclische verzadigde koolwaterstoffen",
   code_24321200:"Onverzadigde koolwaterstoffen",
   code_24321210:"Onverzadigde acyclische koolwaterstoffen",
   code_24321220:"Onverzadigde cyclische koolwaterstoffen",
   code_24321221:"Benzeen",
   code_24321222:"Tolueen",
   code_24321223:"O-xylenen",
   code_24321224:"M-xylenen",
   code_24321225:"Styreen",
   code_24321226:"Ethylbenzeen",
   code_24321300:"Andere chloorderivaten van koolwaterstoffen",
   code_24321310:"Tetrachloorethyleen",
   code_24321320:"Koolstoftetrachloride",
   code_24322000:"Alcoholen, fenolen, fenolalcoholen alsmede halogeen-, gesulfoneerde, nitro- of nitrosoderivaten daarvan; industriële vetalcoholen",
   code_24322100:"Industriële vetalcoholen",
   code_24322200:"Eenwaardige alcoholen",
   code_24322210:"Methanol",
   code_24322220:"Ethanol",
   code_24322300:"Diolen, polyalcoholen en derivaten",
   code_24322310:"Ethyleenglycol",
   code_24322320:"Alcoholderivaten",
   code_24322400:"Fenolen en fenolderivaten",
   code_24322500:"Alcohol",
   code_24322510:"Ethylalcohol",
   code_24323000:"Industriële monocarbonvetzuren",
   code_24323100:"Raffinagevetzuren",
   code_24323200:"Carbonzuren",
   code_24323210:"Azijnzuur",
   code_24323220:"Per-azijnzuur",
   code_24323300:"Onverzadigde monocarbonzuren en samenstellingen daarvan",
   code_24323310:"Esters van methacrylzuur",
   code_24323320:"Acrylzuuresters",
   code_24323400:"Aromatische polycarbonzuren en carbonzuren",
   code_24324000:"Organische verbindingen met stikstofhoudende groepen",
   code_24324100:"Aminoverbindingen",
   code_24324200:"Aminoverbindingen met zuurstofhoudende groepen",
   code_24324300:"Ureïnen",
   code_24324400:"Verbindingen met stikstofhoudende groepen",
   code_24325000:"Verbindingen van organische zwavel",
   code_24326000:"Aldehyde, keton, organische peroxiden, en ethers",
   code_24326100:"Verbindingen met aldehydegroepen",
   code_24326200:"Verbindingen met keton- en chinongroepen",
   code_24326300:"Organische peroxiden",
   code_24326310:"Ethyleenoxide",
   code_24326320:"Ethers",
   code_24327000:"Diverse organische chemicaliën",
   code_24327100:"Plantaardige derivaten voor het verven",
   code_24327200:"Houtskool",
   code_24327300:"Olie en distillatieproducten van steenkoolteer met een hoge temperatuur, pek en pekteer",
   code_24327310:"Steenkoolteer",
   code_24327311:"Creosoot",
   code_24327320:"Pek",
   code_24327330:"Pekteer",
   code_24327400:"Harsproducten",
   code_24327500:"Loog als residu van houtpulpvervaardiging",
   code_24400000:"Meststoffen en stikstofverbindingen",
   code_24410000:"Stikstofhoudende meststoffen",
   code_24411000:"Salpeterzuur en -zouten",
   code_24411100:"Natriumnitraat",
   code_24412000:"Nitreerzuur",
   code_24413000:"Ammoniak",
   code_24413100:"Vloeibare ammoniak",
   code_24413200:"Ammoniumchloride",
   code_24413300:"Ammoniumsulfaat",
   code_24420000:"Fosfaathoudende meststoffen",
   code_24421000:"Minerale fosfaathoudende meststoffen",
   code_24422000:"Chemische fosfaathoudende meststoffen",
   code_24430000:"Meststoffen van dierlijke of plantaardige oorsprong",
   code_24440000:"Diverse meststoffen",
   code_24450000:"Chemische producten voor de landbouw",
   code_24451000:"Pesticiden",
   code_24452000:"Insecticiden",
   code_24453000:"Herbiciden",
   code_24454000:"Plantengroeiregulatoren",
   code_24455000:"Ontsmettingsmiddelen",
   code_24456000:"Verdelgingsmiddelen voor knaagdieren",
   code_24457000:"Fungiciden",
   code_24500000:"Kunststoffen in primaire vorm",
   code_24510000:"Ethyleenpolymeren in primaire vorm",
   code_24520000:"Propyleenpolymeren in primaire vorm",
   code_24530000:"Styreenpolymeren in primaire vorm",
   code_24540000:"Vinylpolymeren in primaire vorm",
   code_24541000:"Vinylacetaatpolymeren in primaire vorm",
   code_24542000:"Acrylpolymeren in primaire vorm",
   code_24550000:"Polyesters in primaire vorm",
   code_24560000:"Polyamiden in primaire vorm",
   code_24570000:"Ureumharsen in primaire vorm",
   code_24580000:"Aminoharsen in primaire vorm",
   code_24590000:"Siliconen in primaire vorm",
   code_24600000:"Explosieven",
   code_24610000:"Bereide explosieven",
   code_24611000:"Drijflading",
   code_24611100:"Voortstuwingsvloeistoffen",
   code_24612000:"Diverse explosieven",
   code_24612100:"Dynamiet",
   code_24612200:"TNT",
   code_24612300:"Nitroglycerine",
   code_24613000:"Lichtkogels, antihagelraketten, mistsignalen en pyrotechnische artikelen",
   code_24613100:"Vogelafschrikkogels",
   code_24613200:"Vuurwerk",
   code_24615000:"Lonten, slaghoedjes, ontstekers en elektrische detonatoren",
   code_24900000:"Fijnchemicaliën en diverse chemische producten",
   code_24910000:"Lijm",
   code_24911000:"Gelatine",
   code_24911200:"Kleefmiddelen",
   code_24920000:"Etherische oliën",
   code_24930000:"Fotochemische producten",
   code_24931000:"Fotografische platen en film",
   code_24931200:"Emulsies voor fotografisch gebruik",
   code_24931210:"Fotografische ontwikkelaars",
   code_24931220:"Fotografische fixeermiddelen",
   code_24931230:"Röntgenontwikkelaars",
   code_24931240:"Röntgenfixeermiddelen",
   code_24931250:"Kweekmedia",
   code_24931260:"Beeldversterkers",
   code_24950000:"Bijzondere chemische preparaten",
   code_24951000:"Vetten en smeermiddelen",
   code_24951100:"Smeermiddelen",
   code_24951110:"Boorsuspensie",
   code_24951120:"Siliconenvet",
   code_24951130:"Boorspoeling",
   code_24951200:"Additieven voor olie",
   code_24951210:"Poeder voor brandblusapparaten",
   code_24951220:"Producten voor brandblusapparaten",
   code_24951230:"Vulling voor brandblusapparaten",
   code_24951300:"Hydraulische vloeistoffen",
   code_24951310:"IJsbestrijdingsmiddelen",
   code_24951311:"Antivriesmiddelen",
   code_24951400:"Chemisch gewijzigde vetten en oliën",
   code_24952000:"Modelleerpasta's",
   code_24952100:"Tandtechnische was",
   code_24953000:"Afwerkingsmiddelen",
   code_24954000:"Actieve kool",
   code_24954100:"Nieuwe actieve kool",
   code_24954200:"Geregenereerde actieve kool",
   code_24955000:"Chemische toiletten",
   code_24956000:"Peptonen en proteïnestoffen",
   code_24957000:"Chemische additieven",
   code_24957100:"Bereide bindmiddelen voor gietvormen of voor gietkernen",
   code_24957200:"Additieven voor cement, mortel of beton",
   code_24958000:"Chemische producten voor de olie- en gasindustrie",
   code_24958100:"Boorgatchemicaliën",
   code_24958200:"Uitvlokmiddelen",
   code_24958300:"Spoelingchemicaliën",
   code_24958400:"Gelampullen voor explosieven",
   code_24959000:"Aerosolen en chemicaliën in schijfjes",
   code_24959100:"Aerosolen",
   code_24959200:"Chemische elementen in schijfjes",
   code_24960000:"Diverse chemische producten",
   code_24961000:"Radiatorvloeistoffen",
   code_24962000:"Chemicaliën voor waterbehandeling",
   code_24963000:"Corrosiewerende middelen",
   code_24964000:"Glycerol",
   code_24965000:"Enzymen",
   code_30000000:"Kantoormachines en gegevensverwerkende apparatuur, kantooruitrusting en -benodigdheden, uitgez. meubilair en softwarepakketten",
   code_30100000:"Kantoormachines, -uitrusting en -benodigdheden, uitgez. computers, printers en meubilair",
   code_30110000:"Tekstverwerkingsmachines",
   code_30111000:"Tekstverwerkers",
   code_30120000:"Fotokopieer- en offsetdrukmachines",
   code_30121000:"Fotokopieer- en thermokopieerapparaten",
   code_30121100:"Fotokopieerapparaten",
   code_30121200:"Fotokopieerapparatuur",
   code_30121300:"Reproductiemachines",
   code_30121400:"Duplicators",
   code_30121410:"Faxschakelapparaten",
   code_30121420:"Digitale zenders",
   code_30121430:"Digitale stencilapparaten",
   code_30122000:"Offsetdrukmachines voor kantoorgebruik",
   code_30122100:"Digitale offsetsystemen",
   code_30122200:"Digitale offsetapparatuur",
   code_30123000:"Kantoor- en bedrijfsmachines",
   code_30123100:"Kaartjesafstempelmachines",
   code_30123200:"Geldautomaten",
   code_30123300:"Stencilmachines",
   code_30123400:"Vouwmachines",
   code_30123500:"Perforatiemachines",
   code_30123600:"Muntmachines",
   code_30123610:"Muntsorteermachines",
   code_30123620:"Munttelmachines",
   code_30123630:"Muntverpakkingsmachines",
   code_30124000:"Delen en toebehoren van kantoormachines",
   code_30124100:"Fusers",
   code_30124110:"Fuser oil",
   code_30124120:"Fuser wiper",
   code_30124130:"Lampen voor fixeereenheid",
   code_30124140:"Reinigingspads voor fixeereenheid",
   code_30124150:"Filters voor fixeereenheid",
   code_30124200:"Fixeereenheidskits",
   code_30124300:"Trommels voor kantoormachines",
   code_30124400:"Nietpatronen",
   code_30124500:"Toebehoren voor scanners",
   code_30124510:"Endosseerapparaten",
   code_30124520:"Documenteninvoerders voor scanners",
   code_30124530:"Transparantieadapters voor scanners",
   code_30125000:"Delen en toebehoren van fotokopieerapparaten",
   code_30125100:"Tonerpatronen",
   code_30125110:"Toner voor laserprinters/faxapparaten",
   code_30125120:"Toner voor fotokopieerapparaten",
   code_30125130:"Toner voor gegevensverwerking en onderzoek- en documentatiecentra",
   code_30130000:"Uitrusting voor postkantoor",
   code_30131000:"Uitrusting voor postkamer",
   code_30131100:"Papier- of envelopvouwmachines",
   code_30131200:"Enveloppeermachines",
   code_30131300:"Adresseermachines",
   code_30131400:"Postmachines",
   code_30131500:"Postopeners",
   code_30131600:"Enveloppensluiters",
   code_30131700:"Poststempelmachines",
   code_30131800:"Zegelmachines",
   code_30132000:"Sorteeruitrusting",
   code_30132100:"Postsorteeruitrusting",
   code_30132200:"Biljettentellers",
   code_30132300:"Sorteerinrichtingen",
   code_30133000:"Verzenduitrusting",
   code_30133100:"Uitrusting voor verzending in bulk",
   code_30140000:"Rekenmachines en boekhoudmachines",
   code_30141000:"Rekenmachines",
   code_30141100:"Zakrekenmachines",
   code_30141200:"Bureaurekenmachines",
   code_30141300:"Rekenmachines met ingebouwde printer",
   code_30141400:"Telmachines",
   code_30142000:"Boekhoudmachines en kasregisters",
   code_30142100:"Boekhoudmachines",
   code_30142200:"Kasregisters",
   code_30144000:"Machines met ingebouwd rekenmechanisme",
   code_30144100:"Frankeermachines",
   code_30144200:"Kaartjesautomaten",
   code_30144300:"Teltoestellen voor voertuigen",
   code_30144400:"Automatische kaartjesverkoop",
   code_30145000:"Delen en toebehoren van rekenmachines",
   code_30145100:"Rekenmachinerollen",
   code_30150000:"Schrijfmachines",
   code_30151000:"Elektronische schrijfmachines",
   code_30152000:"Delen en toebehoren van schrijfmachines",
   code_30160000:"Magneetkaarten",
   code_30161000:"Kredietkaarten",
   code_30162000:"Smartcards",
   code_30163000:"Charge cards",
   code_30163100:"Tankkaarten",
   code_30170000:"Labelmachines",
   code_30171000:"Dateer- of nummermachines",
   code_30172000:"Machines voor het vervaardigen van ID-kaarten",
   code_30173000:"Etiketteermachines",
   code_30174000:"Etikettenprinters",
   code_30175000:"Beletteringsapparatuur",
   code_30176000:"Lettertang",
   code_30177000:"Automatische labelsystemen",
   code_30178000:"Halfautomatische labelsystemen",
   code_30179000:"Labeldispensers",
   code_30180000:"Machines voor het schrijven en endosseren van cheques",
   code_30181000:"Machines voor het endosseren van cheques",
   code_30182000:"Machines voor het schrijven van cheques",
   code_30190000:"Diverse machines, uitrusting en benodigdheden voor kantoor",
   code_30191000:"Kantooruitrusting, uitgez. meubilair",
   code_30191100:"Archiefuitrusting",
   code_30191110:"Kaartcarrouselsystemen",
   code_30191120:"Tijdschriftenstandaards",
   code_30191130:"Klemborden",
   code_30191140:"Toebehoren voor persoonsidentificatie",
   code_30191200:"Overheadprojectoren",
   code_30191400:"Papierversnipperaars",
   code_30192000:"Kantoorbenodigdheden",
   code_30192100:"Vlakgommen",
   code_30192110:"Inktproducten",
   code_30192111:"Stempelkussens",
   code_30192112:"Inktcassettes voor drukmachines",
   code_30192113:"Inktcassettes",
   code_30192121:"Balpennen",
   code_30192122:"Vulpennen",
   code_30192123:"Viltschrijvers",
   code_30192124:"Viltstiften",
   code_30192125:"Markeerstiften",
   code_30192126:"Technische pennen",
   code_30192127:"Penhouders",
   code_30192130:"Potloden",
   code_30192131:"Vulpotloden",
   code_30192132:"Stiften voor vulpotlood",
   code_30192133:"Puntenslijpers",
   code_30192134:"Potloodhouders",
   code_30192150:"Datumstempels",
   code_30192151:"Zegelstempels",
   code_30192152:"Numeroteurs",
   code_30192153:"Tekststempels",
   code_30192154:"Vullingen voor stempelkussens",
   code_30192155:"Stempelhangers voor kantoor",
   code_30192160:"Correctiemateriaal",
   code_30192170:"Prikborden",
   code_30192200:"Meetlinten",
   code_30192300:"Inktlinten",
   code_30192310:"Schrijfmachinelinten",
   code_30192320:"Printerlinten",
   code_30192330:"Linten en trommels voor rekenmachines",
   code_30192340:"Faxlinten",
   code_30192350:"Linten voor kasregister",
   code_30192400:"Reprografiebenodigdheden",
   code_30192500:"Transparanten voor overheadprojector",
   code_30192600:"Tekenborden",
   code_30192700:"Kleine kantoorbenodigdheden",
   code_30192800:"Zelfklevende labels",
   code_30192900:"Correctiemiddelen",
   code_30192910:"Correctiefilm of -band",
   code_30192920:"Correctievloeistof",
   code_30192930:"Correctiepennen",
   code_30192940:"Vullingen voor correctiepennen",
   code_30192950:"Elektrische radeermachines",
   code_30193000:"Organisers en toebehoren",
   code_30193100:"Bureauladeorganisers",
   code_30193200:"Bureauorganisers",
   code_30193300:"Wandsorteerrekjes",
   code_30193400:"Boekensteunen",
   code_30193500:"Documentatierekken",
   code_30193600:"Agenda- of kalenderhouders",
   code_30193700:"Opbergdozen voor dossiers",
   code_30193800:"Berichtenhouders",
   code_30193900:"Concepthouders",
   code_30194000:"Tekenbenodigdheden",
   code_30194100:"Tekenmallen",
   code_30194200:"Dubbelzijdig klevende rondjes, tekenplakband en -film",
   code_30194210:"Dubbelzijdig klevende rondjes of tekenplakband",
   code_30194220:"Tekenfilm",
   code_30194300:"Tekendozen, -sets en -papier",
   code_30194310:"Tekendozen of -sets",
   code_30194320:"Ontwerppapier",
   code_30194400:"Afdekking voor tekentafel",
   code_30194500:"Beletteringshulpmiddelen",
   code_30194600:"Gradenbogen",
   code_30194700:"Sjablonen",
   code_30194800:"Tekenhaken en driehoeken",
   code_30194810:"Tekenhaken",
   code_30194820:"Driehoeken",
   code_30194900:"Afdekhoezen voor werkblad",
   code_30195000:"Borden",
   code_30195100:"Planborden of toebehoren",
   code_30195200:"Elektronische copyboards of toebehoren",
   code_30195300:"Letterborden of toebehoren",
   code_30195400:"Droog uitwisbare borden of toebehoren",
   code_30195500:"Schoolborden of toebehoren",
   code_30195600:"Mededelingenborden of toebehoren",
   code_30195700:"Bordreinigingskits of toebehoren",
   code_30195800:"Ophangrails of houders",
   code_30195900:"Witte schrijfborden en magneetborden",
   code_30195910:"Witte schrijfborden",
   code_30195911:"Toebehoren voor witte schrijfborden",
   code_30195912:"Standaards voor witte schrijfborden",
   code_30195913:"Standaards voor flipovers",
   code_30195920:"Magneetborden",
   code_30195921:"Wissers voor magneetborden",
   code_30196000:"Planningsystemen",
   code_30196100:"Vergaderingsplanners",
   code_30196200:"Afsprakenboeken of navullingen",
   code_30196300:"Ideeënbus",
   code_30197000:"Kleine kantooruitrusting",
   code_30197100:"Nieten, nagels, punaises",
   code_30197110:"Nieten",
   code_30197120:"Kopspijkers",
   code_30197130:"Punaises",
   code_30197200:"Ringmappen en paperclips",
   code_30197210:"Ringmappen",
   code_30197220:"Paperclips",
   code_30197221:"Papercliphouder",
   code_30197300:"Briefopeners, nietapparaten en perforators",
   code_30197310:"Briefopeners",
   code_30197320:"Nietapparaten",
   code_30197321:"Nietjesverwijderaars",
   code_30197330:"Perforators",
   code_30197400:"Zegelbevochtiger",
   code_30197500:"Zegelwas",
   code_30197510:"Toebehoren voor zegelwas",
   code_30197600:"Bewerkt papier en karton",
   code_30197610:"Samengesteld papier en karton",
   code_30197620:"Schrijfpapier",
   code_30197621:"Flipoverblokken",
   code_30197630:"Drukpapier",
   code_30197640:"Contact- of ander kopieerpapier",
   code_30197641:"Thermografisch papier",
   code_30197642:"Fotokopieer- en xerografisch papier",
   code_30197643:"Fotokopieerpapier",
   code_30197644:"Xerografisch papier",
   code_30197645:"Printkarton",
   code_30198000:"Loterijmachines",
   code_30198100:"Trek- en fruitautomaten",
   code_30199000:"Kantoorbenodigdheden van papier en andere artikelen",
   code_30199100:"Carbonpapier, contactpapier, stencilpapier en doorschrijfpapier zonder carbon",
   code_30199110:"Carbonpapier",
   code_30199120:"Contactpapier",
   code_30199130:"Doorschrijfpapier zonder carbon",
   code_30199140:"Stencilpapier",
   code_30199200:"Enveloppen, postbladen en blanco briefkaarten",
   code_30199210:"Postbladen",
   code_30199220:"Blanco briefkaarten",
   code_30199230:"Enveloppen",
   code_30199240:"Correspondentieset",
   code_30199300:"Gegaufreerd of geperforeerd papier",
   code_30199310:"Gegaufreerd of geperforeerd drukpapier",
   code_30199320:"Gegaufreerd of geperforeerd schrijfpapier",
   code_30199330:"Kettingpapier voor printers",
   code_30199340:"Kettingformulieren",
   code_30199400:"Gegomd of zelfklevend papier",
   code_30199410:"Zelfklevend papier",
   code_30199500:"Archiefdozen, brievenbakjes, voorraaddozen en soortgelijke artikelen",
   code_30199600:"Tussenbladen voor kantoorbehoeften",
   code_30199700:"Gedrukt kantoormateriaal, met uitzondering van formulieren",
   code_30199710:"Gedrukte enveloppen",
   code_30199711:"Gedrukte vensterenveloppen",
   code_30199712:"Gedrukte vensterloze enveloppen",
   code_30199713:"Gedrukte omslagen voor röntgenfoto's",
   code_30199720:"Notitiepapier",
   code_30199730:"Visitekaartjes",
   code_30199731:"Visitekaarthouders",
   code_30199740:"Wensstrookjes",
   code_30199750:"Bonnen",
   code_30199760:"Labels",
   code_30199761:"Streepjescodelabels",
   code_30199762:"Bagagelabels",
   code_30199763:"Antidiefstallabels",
   code_30199770:"Maaltijdcheques",
   code_30199780:"Vloeiblokken",
   code_30199790:"Dienstregelingen",
   code_30199791:"Wandplankalenders",
   code_30199792:"Kalenders",
   code_30199793:"Agendahouders",
   code_30200000:"Computeruitrusting en -benodigdheden",
   code_30210000:"Machines voor dataprocessing (hardware)",
   code_30211000:"Mainframecomputer",
   code_30211100:"Supercomputer",
   code_30211200:"Mainframehardware",
   code_30211300:"Computerplatforms",
   code_30211400:"Computerconfiguraties",
   code_30211500:"Centrale verwerkingseenheden (CPU) of processors",
   code_30212000:"Hardware voor minicomputer",
   code_30212100:"Centrale verwerkingseenheden voor minicomputer",
   code_30213000:"Personal computers",
   code_30213100:"Draagbare computers",
   code_30213200:"Tablet-pc's",
   code_30213300:"Desktop computers",
   code_30213400:"Centrale verwerkingseenheden voor pc's",
   code_30213500:"Zakcomputers",
   code_30214000:"Werkstations",
   code_30215000:"Hardware voor microcomputer",
   code_30215100:"Centrale verwerkingseenheden voor microcomputer",
   code_30216000:"Magnetische of optische leeseenheden",
   code_30216100:"Optische leeseenheden",
   code_30216110:"Scanners voor computergebruik",
   code_30216120:"Optische tekenherkenningsuitrusting",
   code_30216130:"Streepjescodelezers",
   code_30216200:"Magneetkaartlezers",
   code_30216300:"Ponskaartlezers",
   code_30220000:"Digitalecartografie-uitrusting",
   code_30221000:"Digitale kadasterkaarten",
   code_30230000:"Computerapparatuur",
   code_30231000:"Computerschermen en -consoles",
   code_30231100:"Computerterminals",
   code_30231200:"Consoles",
   code_30231300:"Beeldschermen",
   code_30231310:"Platte beeldschermen",
   code_30231320:"Touchscreenmonitors",
   code_30232000:"Randapparatuur",
   code_30232100:"Printers en plotters",
   code_30232110:"Laserprinters",
   code_30232120:"Matrixprinters",
   code_30232130:"Grafische kleurenprinters",
   code_30232140:"Plotters",
   code_30232150:"Inkjetprinters",
   code_30232600:"Encoders",
   code_30232700:"Centrale besturingseenheid",
   code_30233000:"Geheugendragers en lezerapparaten",
   code_30233100:"Geheugeneenheden",
   code_30233110:"Magneetkaartgeheugeneenheden",
   code_30233120:"Magneetbandgeheugeneenheden",
   code_30233130:"Magneetschijfgeheugeneenheden",
   code_30233131:"Diskettebesturingseenheden",
   code_30233132:"Hardeschijfeenheden",
   code_30233140:"Direct toegankelijke geheugeneenheden",
   code_30233141:"Redundant Array of Independent Disk (RAID)",
   code_30233150:"Optischeschijfeenheden",
   code_30233151:"Cd-speler en/of -brander",
   code_30233152:"Dvd-speler en/of -brander",
   code_30233153:"Cd/dvd-speler en/of -brander",
   code_30233160:"Tape streamers",
   code_30233161:"Cassettesystemen",
   code_30233170:"Carrouseleenheden",
   code_30233180:"Flashgeheugenopslagmedia",
   code_30233190:"Magneetschijfbesturingseenheid",
   code_30233300:"Smartcardlezers",
   code_30233310:"Vingerafdrukscanners",
   code_30233320:"Gecombineerde vingerafsrukscanner/smartcardlezer",
   code_30234000:"Geheugenmedia",
   code_30234100:"Magneetschijven",
   code_30234200:"Optische schijven",
   code_30234300:"Compact disks (cd's)",
   code_30234400:"Digital versatile discs (dvd's)",
   code_30234500:"Geheugenopslagmedia",
   code_30234600:"Flashgeheugen",
   code_30234700:"Magneetbanden",
   code_30236000:"Diverse computeruitrusting",
   code_30236100:"Geheugenuitbreidingsuitrusting",
   code_30236110:"Willekeurig toegankelijk geheugen (RAM)",
   code_30236111:"Dynamisch willekeurig toegankelijk geheugen (DRAM)",
   code_30236112:"Statisch willekeurig toegankelijk geheugen (SRAM)",
   code_30236113:"Synchroon dynamisch willekeurig toegankelijk geheugen (SDRAM)",
   code_30236114:"Rambus dynamisch willekeurig toegankelijk geheugen (RDRAM)",
   code_30236115:"Synchroon grafisch random access memory (SGRAM)",
   code_30236120:"Read-only memory (ROM)",
   code_30236121:"Programmeerbaar enkel uitleesbaar geheugen (PROM)",
   code_30236122:"Uitwisbaar en programmeerbaar geheugen (EPROM)",
   code_30236123:"Read-onlygeheugen (EEPROM)",
   code_30236200:"Gegevensverwerkingsuitrusting",
   code_30237000:"Onderdelen, toebehoren en benodigdheden voor computers",
   code_30237100:"Computeronderdelen",
   code_30237110:"Netwerkinterfaces",
   code_30237120:"Computerpoorten",
   code_30237121:"Seriële infraroodpoorten",
   code_30237130:"Computerkaarten",
   code_30237131:"Elektronische kaarten",
   code_30237132:"Universele Seriële Bus/USB-interfaces",
   code_30237133:"Adapters en interfaces voor Personal Computer Memory Card International Association (PCMCIA)",
   code_30237134:"Grafische acceleratiekaarten",
   code_30237135:"Netwerkinterfacekaarten",
   code_30237136:"Audiokaarten",
   code_30237140:"Moederborden",
   code_30237200:"Computertoebehoren",
   code_30237210:"Antireflexschermen",
   code_30237220:"Muismatjes",
   code_30237230:"Voorgeheugens",
   code_30237240:"Webcamera",
   code_30237250:"Toebehoren voor computerreiniging",
   code_30237251:"Computerreinigingskits",
   code_30237252:"Hogedrukluchtverstuivers",
   code_30237253:"Stofhoezen voor computerapparatuur",
   code_30237260:"Monitorarmen voor wandmontage",
   code_30237270:"Draagtassen voor laptop",
   code_30237280:"Toebehoren voor stroomvoorziening",
   code_30237290:"Polssteunen voor toetsenbord",
   code_30237295:"Toestenbordhoezen",
   code_30237300:"Computerbenodigdheden",
   code_30237310:"Lettertypecartridges voor printers",
   code_30237320:"Diskettes",
   code_30237330:"DAT-cassettes",
   code_30237340:"DLT-cartridges",
   code_30237350:"Datacartridges",
   code_30237360:"LTO-cartridges",
   code_30237370:"Opnamecassettes",
   code_30237380:"Cd-rom",
   code_30237400:"Toebehoren voor gegevensinvoer",
   code_30237410:"Computermuis",
   code_30237420:"Joysticks",
   code_30237430:"Lichtpennen",
   code_30237440:"Volgbollen",
   code_30237450:"Grafische tabletten",
   code_30237460:"Computertoetsenborden",
   code_30237461:"Programmeerbare toetsenborden",
   code_30237470:"Brailletoetsenborden",
   code_30237475:"Elektrische sensoren",
   code_30237480:"Invoereenheden",
   code_30238000:"Automatiseringsuitrusting voor bibliotheek",
   code_31000000:"Elektrische machines, apparaten, uitrusting en verbruiksartikelen; verlichting",
   code_31100000:"Elektromotoren, elektrische generatoren en elektrische transformatoren",
   code_31110000:"Elektromotoren",
   code_31111000:"Adapters",
   code_31120000:"Generatoren",
   code_31121000:"Generatoraggregaatsets",
   code_31121100:"Generatoraggregaten met motoren met compressieontsteking",
   code_31121110:"Vermogensomzetters",
   code_31121111:"Elektrische draaiomvormers",
   code_31121200:"Generatoraggregaten met motoren met vonkontsteking",
   code_31121300:"Windenergiegeneratoren",
   code_31121310:"Windmolens",
   code_31121320:"Windturbines",
   code_31121330:"Windturbinegeneratoren",
   code_31121331:"Turbinerotoren",
   code_31121340:"Windpark",
   code_31122000:"Generatoraggregaten",
   code_31122100:"Brandstofcellen",
   code_31124000:"Stoomturbinegenerator en bijbehorende apparatuur",
   code_31124100:"Turbinegeneratorsets",
   code_31124200:"Besturingapparatuur voor turbinegenerator",
   code_31126000:"Dynamo's",
   code_31127000:"Noodgenerator",
   code_31128000:"Turbogenerator",
   code_31130000:"Wisselstroomdynamo's",
   code_31131000:"Eenfasemotoren",
   code_31131100:"Aandrijvers",
   code_31131200:"Anodes",
   code_31132000:"Meerfasenmotoren",
   code_31140000:"Koeltorens",
   code_31141000:"Waterkoelers",
   code_31150000:"Voorschakeltoestellen voor ontladingslampen en -buizen",
   code_31151000:"Statische omvormers",
   code_31153000:"Gelijkrichters",
   code_31154000:"Onderbrekingsvrije stroomvoorziening",
   code_31155000:"Wisselrichters",
   code_31156000:"Onderbreekbare stroomvoorziening",
   code_31157000:"Inductoren",
   code_31158000:"Laders",
   code_31158100:"Acculaders",
   code_31158200:"Drukvulling",
   code_31158300:"Turbocompressor",
   code_31160000:"Delen van elektromotoren, elektrische generatoren en elektrische transformatoren",
   code_31161000:"Delen van elektromotoren en elektrische generatoren",
   code_31161100:"Bekrachtigingssystemen",
   code_31161200:"Gaskoelingssystemen",
   code_31161300:"Generatorrotoren",
   code_31161400:"Hoofdwatersystemen",
   code_31161500:"Systemen met afdichtingsolie",
   code_31161600:"Koelwatersystemen voor stators",
   code_31161700:"Delen van stoomgeneratoren",
   code_31161800:"Delen van gasgeneratoren",
   code_31161900:"Spanningsregelsystemen",
   code_31162000:"Delen van transformatoren, inductoren en statische omzetters",
   code_31162100:"Delen van condensors",
   code_31170000:"Transformatoren",
   code_31171000:"Diëlektrische vloeistoftransformatoren",
   code_31172000:"Spanningstransformatoren",
   code_31173000:"Meettransformator",
   code_31174000:"Transformatoren voor stroomvoorziening",
   code_31200000:"Distributie- en regelinstallaties voor elektriciteit",
   code_31210000:"Elektrische apparatuur voor het schakelen of voor het beveiligen van stroomkringen",
   code_31211000:"Panelen en zekeringkasten",
   code_31211100:"Verdeelpanelen",
   code_31211110:"Controlepanelen",
   code_31211200:"Zekeringkasten",
   code_31211300:"Zekeringen",
   code_31211310:"Stroomonderbrekers",
   code_31211320:"Zekeringsblokken",
   code_31211330:"Smeltzekeringen",
   code_31211340:"Zekeringklemmen",
   code_31212000:"Stroomschakelaars",
   code_31212100:"Stroomonderbrekers voor bovengronds gebruik",
   code_31212200:"Schakelingtesters",
   code_31212300:"Magnetische schakelaars",
   code_31212400:"Miniatuurschakelaars",
   code_31213000:"Distributie-uitrusting",
   code_31213100:"Verdeelkasten",
   code_31213200:"Verdeeltransformatoren",
   code_31213300:"Kabelverdeelkast",
   code_31213400:"Distributiesysteem",
   code_31214000:"Schakelapparatuur",
   code_31214100:"Schakelaars",
   code_31214110:"Isolatieschakelaars",
   code_31214120:"Aardschakelaar",
   code_31214130:"Veiligheidsschakelaars",
   code_31214140:"Dimmerschakelaars",
   code_31214150:"Walsschakelaars",
   code_31214160:"Drukschakelaars",
   code_31214170:"Tuimelschakelaars",
   code_31214180:"Schuifschakelaars",
   code_31214190:"Eindschakelaars",
   code_31214200:"Lastschakelaar",
   code_31214300:"Schakelinstallaties voor buiten",
   code_31214400:"Smeltzekeringsschakelaar",
   code_31214500:"Elektrische schakelborden",
   code_31214510:"Verdeelschakelbord",
   code_31214520:"Middenspanningsschakelborden",
   code_31215000:"Spanningsbegrenzers",
   code_31216000:"Overspanningsafleiders",
   code_31216100:"Bliksembeveiligingsuitrusting",
   code_31216200:"Bliksemafleiders",
   code_31217000:"Stootspanningsbegrenzers",
   code_31218000:"Verzamelrails",
   code_31219000:"Beveiligingsdozen",
   code_31220000:"Onderdelen van stroomkringen",
   code_31221000:"Relais",
   code_31221100:"Vermogensrelais",
   code_31221200:"Universele relais",
   code_31221300:"Insteekrelais",
   code_31221400:"Wisselstroomrelais",
   code_31221500:"Kwikrelais",
   code_31221600:"Tijdrelais",
   code_31221700:"Overbelastingsrelais",
   code_31223000:"Fittingen",
   code_31224000:"Verbindingen en contactelementen",
   code_31224100:"Stekkers en contactdozen",
   code_31224200:"Coaxiale aansluitstekkers",
   code_31224300:"Aansluitdozen",
   code_31224400:"Aansluitkabels",
   code_31224500:"Terminals",
   code_31224600:"Dimmers",
   code_31224700:"Aftakkasten",
   code_31224800:"Kabelverbindingssets",
   code_31224810:"Verlengsnoeren",
   code_31230000:"Delen van apparatuur voor de distributie of het regelen van elektriciteit",
   code_31300000:"Geïsoleerde kabel en draad",
   code_31310000:"Hoofdleidingen",
   code_31311000:"Hoofdnetaansluitingen",
   code_31320000:"Stroomverdelingskabels",
   code_31321000:"Elektrische leidingen",
   code_31321100:"Bovengrondse elektriciteitsleidingen",
   code_31321200:"Laagspannings- en middenspanningskabels",
   code_31321210:"Laagspanningskabel",
   code_31321220:"Middenspanningskabels",
   code_31321300:"Hoogspanningskabels",
   code_31321400:"Onderwaterkabel",
   code_31321500:"Onderzeese kabel",
   code_31321600:"Afgeschermde kabel",
   code_31321700:"Signaalkabels",
   code_31330000:"Coaxkabel",
   code_31340000:"Kabeltoebehoren, geïsoleerd",
   code_31341000:"Kabelhaspels, geïsoleerd",
   code_31342000:"Kabelaansluitingen, geïsoleerd",
   code_31343000:"Kabelverbindingen, geïsoleerd",
   code_31344000:"Pakkingsdrukkers voor kabel, geïsoleerd",
   code_31350000:"Geleiders van elektriciteit, voor gegevensverwerking en regeling",
   code_31351000:"Geleiders van elektriciteit, voor toegangscontrolesystemen",
   code_31400000:"Accumulatoren, primaire cellen en primaire elementen",
   code_31410000:"Primaire cellen",
   code_31411000:"Alkalinebatterijen",
   code_31420000:"Primaire batterijen",
   code_31421000:"Loodaccu's",
   code_31422000:"Batterijpacks",
   code_31430000:"Elektrische accumulatoren",
   code_31431000:"Loodzwavelzuuraccu's",
   code_31432000:"Nikkel-cadmiumaccu's",
   code_31433000:"Nikkel-ijzeraccu's",
   code_31434000:"Lithiumaccu's",
   code_31440000:"Accu's/batterijen",
   code_31500000:"Verlichtingsuitrusting en elektrische lampen",
   code_31510000:"Elektrische gloeilampen",
   code_31511000:"Sealed-beam-lampen",
   code_31512000:"Halogeenlampen met wolfraamdraad",
   code_31512100:"Halogeenlampen, lineair",
   code_31512200:"Halogeenlampen met twee contactpennen",
   code_31512300:"Koudlichthalogeenlampen",
   code_31514000:"Ontladingslampen",
   code_31515000:"Uv-lampen",
   code_31516000:"Infrarode lampen",
   code_31517000:"Booglampen",
   code_31518000:"Signalisatielichten",
   code_31518100:"Schijnwerpers",
   code_31518200:"Noodverlichtingsuitrusting",
   code_31518210:"Stormlantaarns",
   code_31518220:"Lichtstaven",
   code_31518300:"Daklampen",
   code_31518500:"Kwiklampen",
   code_31518600:"Zoeklichten",
   code_31519000:"Gloeilampen en neonlampen",
   code_31519100:"Gloeilampen",
   code_31519200:"Neonlampen",
   code_31520000:"Lampen en lamparmaturen",
   code_31521000:"Lampen",
   code_31521100:"Bureaulampen",
   code_31521200:"Staande lampen",
   code_31521300:"Draagbare elektrische lampen",
   code_31521310:"Waarschuwingslichten",
   code_31521320:"Zaklampen",
   code_31521330:"Oplaadbare draagbare elektrische lampen",
   code_31522000:"Kerstboomverlichting",
   code_31523000:"Verlichte borden en naamplaten",
   code_31523100:"Reclameneonlampen",
   code_31523200:"Niet-variabele mededelingsborden",
   code_31523300:"Verlichte naamplaten",
   code_31524000:"Plafond- en wandverlichtingsarmaturen",
   code_31524100:"Plafondverlichtingsarmaturen",
   code_31524110:"Lampen voor operatiekamers",
   code_31524120:"Plafondverlichting",
   code_31524200:"Armaturen voor wandlampen",
   code_31524210:"Wandverlichting",
   code_31527000:"Spots",
   code_31527200:"Buitenverlichting",
   code_31527210:"Lantaarns",
   code_31527260:"Verlichtingssystemen",
   code_31527270:"Perronverlichting",
   code_31527300:"Verlichting voor huishoudelijk gebruik",
   code_31527400:"Onderwaterlampen",
   code_31530000:"Delen van lampen en verlichtingstoestellen",
   code_31531000:"Gloeilampen/peertjes",
   code_31531100:"Verlichtingsbuizen",
   code_31532000:"Delen van lampen en verlichtingsarmaturen",
   code_31532100:"Buislampen",
   code_31532110:"Fluorescerende buislampen",
   code_31532120:"Compacte fluorescerende buislampen",
   code_31532200:"Ringvormige lampen",
   code_31532210:"Fluorescerende ringvormige lampen",
   code_31532300:"Bollampen",
   code_31532310:"Compacte fluorescerende bollampen",
   code_31532400:"Lampfittingen",
   code_31532500:"Lampstarters",
   code_31532510:"Starters voor fluorescentielampen",
   code_31532600:"Lampdimmers",
   code_31532610:"Dimmers voor fluorescentielampen",
   code_31532700:"Lampenkappen",
   code_31532800:"Lamparmen",
   code_31532900:"Fluorescentielampen",
   code_31532910:"Fluorescentiebuizen",
   code_31532920:"Gloeilampen en fluorescentielampen",
   code_31600000:"Elektrische uitrusting",
   code_31610000:"Elektrische uitrusting voor motoren en voertuigen",
   code_31611000:"Kabelsets",
   code_31612000:"Elektrische draadbomen voor motoren",
   code_31612200:"Startmotoren",
   code_31612300:"Elektrische signalisatie-uitrusting voor motoren",
   code_31612310:"Knipperlichten",
   code_31620000:"Apparatuur voor hoorbare of zichtbare signalen",
   code_31625000:"Inbraak- en brandalarminstallaties",
   code_31625100:"Branddetectiesystemen",
   code_31625200:"Brandalarmsystemen",
   code_31625300:"Inbraakalarmsystemen",
   code_31630000:"Magneten",
   code_31640000:"Machines en toestellen met een eigen functie",
   code_31642000:"Elektronische detectieapparatuur",
   code_31642100:"Detectieapparatuur voor metalen buizen",
   code_31642200:"Mijndetectieapparatuur",
   code_31642300:"Detectieapparatuur voor kunststoffen",
   code_31642400:"Detectieapparatuur voor niet-metalen voorwerpen",
   code_31642500:"Detectieapparatuur voor hout",
   code_31643000:"Deeltjesversnellers",
   code_31643100:"Lineaire versnellers",
   code_31644000:"Diverse datarecorders",
   code_31645000:"Flipperkasten",
   code_31650000:"Isolerende fittings",
   code_31651000:"Isolatieband",
   code_31660000:"Koolelektroden",
   code_31670000:"Elektrische delen van machines of toestellen",
   code_31671000:"Glazen omhulsels en kathodestraalbuizen",
   code_31671100:"Glazen omhulsels",
   code_31671200:"Kathodestraalbuizen",
   code_31680000:"Elektrische benodigdheden en toebehoren",
   code_31681000:"Elektrische toebehoren",
   code_31681100:"Elektrische contacten",
   code_31681200:"Elektrische pompen",
   code_31681300:"Stroomkringen",
   code_31681400:"Elektrische componenten",
   code_31681410:"Elektrische materialen",
   code_31681500:"Oplaadtoestellen",
   code_31682000:"Elektriciteitsbenodigdheden",
   code_31682100:"Elektriciteitskasten",
   code_31682110:"Afdekplaten voor elektriciteitskasten",
   code_31682200:"Instrumentenborden",
   code_31682210:"Instrumenten- en besturingsuitrusting",
   code_31682220:"Mengpanelen",
   code_31682230:"Grafische beeldschermpanelen",
   code_31682300:"Middenspanningsuitrusting",
   code_31682310:"Middenspanningsborden",
   code_31682400:"Elektrische uitrusting voor bovengrondse installatie",
   code_31682410:"Luchtkabeldragers",
   code_31682500:"Noodstroomuitrusting",
   code_31682510:"Noodstroomsystemen",
   code_31682520:"Noodstopsystemen",
   code_31682530:"Noodstroombenodigdheden",
   code_31682540:"Uitrusting voor onderstation",
   code_31700000:"Elektronische, elektromechanische en elektrotechnische benodigdheden",
   code_31710000:"Elektronische uitrusting",
   code_31711000:"Elektronische benodigdheden",
   code_31711100:"Elektronische onderdelen",
   code_31711110:"Zendontvangers",
   code_31711120:"Transductoren",
   code_31711130:"Weerstanden",
   code_31711131:"Elektrische weerstanden",
   code_31711140:"Elektroden",
   code_31711150:"Elektrische condensatoren",
   code_31711151:"Vaste condensatoren",
   code_31711152:"Regelbare of instelbare condensatoren",
   code_31711154:"Condensatorbanken",
   code_31711155:"Condensatornetwerken",
   code_31711200:"Elektronische scoreborden",
   code_31711300:"Elektronische tijdcontrolesystemen",
   code_31711310:"Systeem voor aanwezigheidsregistratie",
   code_31711400:"Kleppen en buizen",
   code_31711410:"Kathodestraalbeeldbuizen",
   code_31711411:"Televisiecamerabuizen",
   code_31711420:"Microgolfbuizen en uitrusting",
   code_31711421:"Magnetrons",
   code_31711422:"Uitrusting voor microgolf",
   code_31711423:"Microgolfradio-uitrusting",
   code_31711424:"Klystrons",
   code_31711430:"Elektronenbuizen",
   code_31711440:"Ontvangerbuizen of versterkerbuizen",
   code_31711500:"Delen van elektronische componenten",
   code_31711510:"Delen van elektrische condensatoren",
   code_31711520:"Delen van elektrische weerstanden, reostaten en potentiometers",
   code_31711530:"Delen van elektronenbuizen",
   code_31712000:"Micro-elektronische machines en toestellen en microsystemen",
   code_31712100:"Micro-elektronische machines en toestellen",
   code_31712110:"Elektronisch geïntegreerde schakelingen en microschakelingen",
   code_31712111:"Telefoonkaarten",
   code_31712112:"Simkaarten",
   code_31712113:"Chipkaarten",
   code_31712114:"Geïntegreerde elektronische schakelingen",
   code_31712115:"Microschakelingen",
   code_31712116:"Microprocessors",
   code_31712117:"Pakketten voor geïntegreerde schakelingen",
   code_31712118:"Voetjes of fittingen voor geïntegreerde schakelingen",
   code_31712119:"Afdekkingen voor geïntegreerde schakelingen",
   code_31712200:"Microsystemen",
   code_31712300:"Gedrukte schakelingen",
   code_31712310:"Afgemonteerde printplaten",
   code_31712320:"Niet-afgemonteerde printplaten",
   code_31712330:"Halfgeleiders",
   code_31712331:"Fotovoltaïsche cellen",
   code_31712332:"Thyristors",
   code_31712333:"Diacs",
   code_31712334:"Triacs",
   code_31712335:"Optisch gekoppelde isolatoren",
   code_31712336:"Kristaloscillatoren",
   code_31712340:"Dioden",
   code_31712341:"Lichtdioden",
   code_31712342:"Microgolf- of kleinsignaaldioden",
   code_31712343:"Zenerdioden",
   code_31712344:"Schottkydioden",
   code_31712345:"Tunneldioden",
   code_31712346:"Fotodioden",
   code_31712347:"Vermogens- of zonnedioden",
   code_31712348:"Laserdioden",
   code_31712349:"Radiofrequentiedioden (RF-dioden)",
   code_31712350:"Transistors",
   code_31712351:"Fototransistors",
   code_31712352:"Veldeffecttransistors (FET)",
   code_31712353:"MOS-transistors (MOSFET)",
   code_31712354:"Transistorchips",
   code_31712355:"Bipolaire darlingtontransistors of radiofrequentie (RF)-transistors",
   code_31712356:"Uni-junctietransistors",
   code_31712357:"Bipolaire transistors met geïsoleerde poort (IGBT)",
   code_31712358:"Junctieveldeffecttransistors (JFET)",
   code_31712359:"Bipolaire junctietransistors (BJT)",
   code_31712360:"Gemonteerde piëzo-elektrische kristallen",
   code_31720000:"Elektromechanische uitrusting",
   code_31730000:"Elektrotechnische uitrusting",
   code_31731000:"Elektrotechnische benodigdheden",
   code_31731100:"Modules",
   code_32000000:"Radio-, televisie-, communicatie-, telecommunicatietoestellen en aanverwante apparatuur",
   code_32200000:"Zendapparatuur voor radiotelefonie, radiotelegrafie, radio-omroep en televisie",
   code_32210000:"Zenduitrusting",
   code_32211000:"Productie-uitrusting voor uitzendingen",
   code_32220000:"Televisietransmissie-installaties zonder ontvanger",
   code_32221000:"Radiobakens",
   code_32222000:"Codeermachines voor videosignalen",
   code_32223000:"Videotransmissieapparatuur",
   code_32224000:"Televisietransmissieapparatuur",
   code_32230000:"Radiotransmissieapparatuur met ontvanger",
   code_32231000:"Toestellen voor gesloten televisiecircuits",
   code_32232000:"Uitrusting voor videoconferentie",
   code_32233000:"Radiofrequentieboosterstations",
   code_32234000:"Televisiecamera's voor gesloten televisiecircuits",
   code_32235000:"Bewakingssysteem met gesloten circuit",
   code_32236000:"Radiotelefoons",
   code_32237000:"Walkietalkies",
   code_32240000:"Televisiecamera's",
   code_32250000:"Mobiele telefoons",
   code_32251000:"Autotelefoons",
   code_32251100:"Handsfree set",
   code_32252000:"Gsm-telefoons",
   code_32252100:"Handsfree mobiele telefoons",
   code_32252110:"Handsfree mobiele telefoons (zonder kabel)",
   code_32260000:"Datatransmissie-uitrusting",
   code_32270000:"Digitale zendapparatuur",
   code_32300000:"Televisie- en radio-ontvangers, audio- of video-opnameapparatuur of -reproductietoestellen",
   code_32310000:"Radiotoestellen",
   code_32320000:"Televisie- en audiovisuele uitrusting",
   code_32321000:"Televisieprojectie-uitrusting",
   code_32321100:"Film- en video-uitrusting",
   code_32321200:"Audiovisuele uitrusting",
   code_32321300:"Audiovisuele materialen",
   code_32322000:"Multimedia-uitrusting",
   code_32323000:"Videomonitoren",
   code_32323100:"Kleurenmonitoren",
   code_32323200:"Monochrome videomonitoren",
   code_32323300:"Video-uitrusting",
   code_32323400:"Videoweergave-uitrusting",
   code_32323500:"Videobewakingsuitrusting",
   code_32324000:"Televisieontvangers",
   code_32324100:"Kleurentelevisietoestellen",
   code_32324200:"Zwart-wittelevisietoestellen",
   code_32324300:"Televisie-uitrusting",
   code_32324310:"Satellietantennes",
   code_32324400:"Televisieantennes",
   code_32324500:"Videotuners",
   code_32324600:"Kastjes voor digitale tv",
   code_32330000:"Opname- en weergaveapparatuur voor audio en video",
   code_32331000:"Draaitafels",
   code_32331100:"Platenspelers",
   code_32331200:"Cassettespelers",
   code_32331300:"Apparatuur voor geluidsweergave",
   code_32331500:"Recorders",
   code_32331600:"MP3-speler",
   code_32332000:"Magneetbandrecorders",
   code_32332100:"Dicteerapparaten",
   code_32332200:"Antwoordapparaten",
   code_32332300:"Toestellen voor geluidsopnames",
   code_32333000:"Video-opname- of -weergaveapparatuur",
   code_32333100:"Videorecorders",
   code_32333200:"Videocamcorders",
   code_32333300:"Videoweergaveapparatuur",
   code_32333400:"Videospelers",
   code_32340000:"Microfoons en luidsprekers",
   code_32341000:"Microfoons",
   code_32342000:"Luidsprekers",
   code_32342100:"Koptelefoons",
   code_32342200:"Oortelefoons",
   code_32342300:"Microfoons en luidsprekersets",
   code_32342400:"Akoestische toestellen",
   code_32342410:"Geluidsuitrusting",
   code_32342411:"Minispeakers",
   code_32342412:"Speakers",
   code_32342420:"Mengpaneel voor studio",
   code_32342430:"Spraakcompressiesysteem",
   code_32342440:"Voice-mailsysteem",
   code_32342450:"Stemrecorders",
   code_32343000:"Versterkers",
   code_32343100:"Audiofrequentieversterkers",
   code_32343200:"Megafoons",
   code_32344000:"Ontvangtoestellen voor radiotelefonie of radiotelegrafie",
   code_32344100:"Draagbare ontvangers voor oproepen en boodschappen",
   code_32344110:"Stemregistratiesysteem",
   code_32344200:"Radio-ontvangers",
   code_32344210:"Radio-uitrusting",
   code_32344220:"Semafoonontvangers",
   code_32344230:"Radiostations",
   code_32344240:"Radiotoren",
   code_32344250:"Radio-installaties",
   code_32344260:"Radio- en multiplexuitrusting",
   code_32344270:"Radio- en telefooncontrolesysteem",
   code_32344280:"Draagbare radio's",
   code_32350000:"Delen van audio- en video-uitrusting",
   code_32351000:"Toebehoren van audio- en video-uitrusting",
   code_32351100:"Videomontage-uitrusting",
   code_32351200:"Schermen",
   code_32351300:"Toebehoren voor audioapparatuur",
   code_32351310:"Geluidscassettes",
   code_32352000:"Antennes en reflectoren",
   code_32352100:"Delen van radio- en radaruitrusting",
   code_32352200:"Onderdelen en toebehoren voor radaruitrusting",
   code_32353000:"Geluidsopnamen",
   code_32353100:"Grammofoonplaten",
   code_32353200:"Muziekcassettes",
   code_32354000:"Filmproducten",
   code_32354100:"Film voor radiologie",
   code_32354110:"Röntgenfilm",
   code_32354120:"Blauwediazofilm",
   code_32354200:"Cinematografische film",
   code_32354300:"Fotografische film",
   code_32354400:"Directklaarfilm",
   code_32354500:"Videofilms",
   code_32354600:"Videocassettes",
   code_32354700:"Videobanden",
   code_32354800:"Krimpfolie",
   code_32360000:"Intercomuitrusting",
   code_32400000:"Netwerken",
   code_32410000:"Lokaal netwerk",
   code_32411000:"Token-ringnetwerk",
   code_32412000:"Communicatienetwerk",
   code_32412100:"Telecommunicatienetwerk",
   code_32412110:"Internetnetwerk",
   code_32412120:"Intranetnetwerk",
   code_32413000:"Geïntegreerd netwerk",
   code_32413100:"Netwerkrouters",
   code_32415000:"Ethernetnetwerk",
   code_32416000:"ISDN-netwerk",
   code_32416100:"ISDX-netwerk",
   code_32417000:"Multimedianetwerken",
   code_32418000:"Radionetwerk",
   code_32420000:"Netwerkuitrusting",
   code_32421000:"Netwerkbekabeling",
   code_32422000:"Netwerkcomponenten",
   code_32423000:"Netwerkhubs",
   code_32424000:"Netwerkinfrastructuur",
   code_32425000:"Netwerkbesturingssysteem",
   code_32426000:"Netwerkpublicatiesysteem",
   code_32427000:"Netwerksysteem",
   code_32428000:"Netwerk-upgrade",
   code_32429000:"Uitrusting voor telefoonnetwerk",
   code_32430000:"Wide area netwerk",
   code_32440000:"Telemetrie- en terminaluitrusting",
   code_32441000:"Telemetrie-uitrusting",
   code_32441100:"Telemetrisch bewakingssysteem",
   code_32441200:"Telemetrie- en controle-uitrusting",
   code_32441300:"Telematicasysteem",
   code_32442000:"Terminaluitrusting",
   code_32442100:"Klemmenborden",
   code_32442200:"Aansluitkasten",
   code_32442300:"Terminalemulatoren",
   code_32442400:"Aansluitklemmen",
   code_32500000:"Telecommunicatiebenodigdheden",
   code_32510000:"Systeem voor draadloze telecommunicatie",
   code_32520000:"Telecommunicatiekabels en -uitrusting",
   code_32521000:"Telecommunicatiekabel",
   code_32522000:"Telecommunicatie-uitrusting",
   code_32523000:"Telecommunicatiefaciliteiten",
   code_32524000:"Telecommunicatiesysteem",
   code_32530000:"Uitrusting ten behoeve van satellietcommunicatie",
   code_32531000:"Satellietcommunicatie-uitrusting",
   code_32532000:"Satellietschotels",
   code_32533000:"Satellietgrondstations",
   code_32534000:"Satellietplatforms",
   code_32540000:"Schakelborden",
   code_32541000:"Schakelborduitrusting",
   code_32542000:"Schakelbordpanelen",
   code_32543000:"Interne telefooncentrales",
   code_32544000:"PABX-uitrusting",
   code_32545000:"PABX-systemen",
   code_32546000:"Digitale schakeluitrusting",
   code_32546100:"Digitale schakelborden",
   code_32547000:"Vacuümschakelborden",
   code_32550000:"Telefoonuitrusting",
   code_32551000:"Telefoonkabels en bijbehorende uitrusting",
   code_32551100:"Telefoonaansluitingen",
   code_32551200:"Telefooncentrales",
   code_32551300:"Telefoonhoofdsets",
   code_32551400:"Telefoonnetwerk",
   code_32551500:"Telefoonkabels",
   code_32552000:"Elektrische toestellen voor lijntelefonie of lijntelegrafie",
   code_32552100:"Telefoontoestellen",
   code_32552110:"Draadloze telefoons",
   code_32552120:"Praatpalen",
   code_32552130:"Openbare telefoons",
   code_32552140:"Uitrusting voor munttelefoontoestellen",
   code_32552150:"Telefoons voor visueel gehandicapten",
   code_32552160:"Telefoons voor slechthorenden",
   code_32552200:"Teleprinters",
   code_32552300:"Schakelapparatuur voor telefonie en telegrafie",
   code_32552310:"Digitaletelefooncentrales",
   code_32552320:"Multiplexers",
   code_32552330:"Telefoonschakelapparatuur",
   code_32552400:"Omzettingsapparatuur voor audiosignalen",
   code_32552410:"Modems",
   code_32552420:"Frequentieomvormers",
   code_32552430:"Codeeruitrusting",
   code_32552500:"Teleteksttoestellen",
   code_32552510:"Videotekstterminals",
   code_32552520:"Telexuitrusting",
   code_32552600:"Parlofoons",
   code_32553000:"Delen van elektrische toestellen voor telefonie of telegrafie",
   code_32560000:"Glasvezelmateriaal",
   code_32561000:"Glasvezelverbindingen",
   code_32562000:"Glasvezelkabels",
   code_32562100:"Glasvezelkabels voor informatietransmissie",
   code_32562200:"Optische telecommunicatiekabels",
   code_32562300:"Glasvezelkabels voor datatransmissie",
   code_32570000:"Communicatie-uitrusting",
   code_32571000:"Communicatie-infrastructuur",
   code_32572000:"Communicatiekabels",
   code_32572100:"Communicatiekabel met meervoudige elektrische geleiders",
   code_32572200:"Communicatiekabel met coaxiale geleiders",
   code_32572300:"Communicatiekabel voor bijzondere toepassingen",
   code_32573000:"Communicatiecontrolesysteem",
   code_32580000:"Gegevensuitrusting",
   code_32581000:"Gegevenscommunicatie-uitrusting",
   code_32581100:"Datatransmissiekabel",
   code_32581110:"Datatransmissiekabel met meervoudige elektrische geleiders",
   code_32581120:"Datatransmissiekabel met coaxiale geleiders",
   code_32581130:"Datatransmissiekabel voor bijzondere toepassingen",
   code_32581200:"Faxapparatuur",
   code_32581210:"Toebehoren en delen voor faxapparatuur",
   code_32582000:"Gegevensdragers",
   code_32583000:"Gegevens- en stemmedia",
   code_32584000:"Gegevensdragende media",
   code_33000000:"Medische apparatuur, farmaceutische artikelen en artikelen voor lichaamsverzorging",
   code_33100000:"Medische apparatuur",
   code_33110000:"Beeldvormingsapparatuur voor medisch, tandheelkundig en diergeneeskundig gebruik",
   code_33111000:"Röntgenapparatuur",
   code_33111100:"Röntgentafel",
   code_33111200:"Röntgenwerkstations",
   code_33111300:"Röntgenontwikkelingsapparaten",
   code_33111400:"Röntgenfluoroscopieapparaten",
   code_33111500:"Gebitsröntgen",
   code_33111600:"Instrumenten voor radiografie",
   code_33111610:"Unit voor magnetische resonantie",
   code_33111620:"Gammacamera's",
   code_33111640:"Thermografen",
   code_33111650:"Instrumenten voor mammografie",
   code_33111660:"Botdensitometers",
   code_33111700:"Angiografieruimte",
   code_33111710:"Benodigdheden voor angiografie",
   code_33111720:"Instrumenten voor angiografie",
   code_33111721:"Apparatuur voor digitale angiografie",
   code_33111730:"Benodigdheden voor angioplastie",
   code_33111740:"Instrumenten voor angioplastie",
   code_33111800:"Systeem voor röntgendiagnostiek",
   code_33112000:"Beeldvormingsapparatuur met behulp van echo, ultrageluid en doppler",
   code_33112100:"Ultrasone harttonendetector",
   code_33112200:"Echograaf",
   code_33112300:"Ultrageluidscanners",
   code_33112310:"Kleuren-Doppler",
   code_33112320:"Doppleruitrusting",
   code_33112330:"Echo-encefalografen",
   code_33112340:"Echocardiografen",
   code_33113000:"Kernspinresonantie-uitrusting",
   code_33113100:"Magnetischeresonantiescanners",
   code_33113110:"Kernspinresonantiescanners",
   code_33114000:"Instrumentarium voor spectroscopie",
   code_33115000:"Instrumentarium voor tomografie",
   code_33115100:"Computertomografiescanners",
   code_33115200:"CAT-scanners",
   code_33120000:"Opnamesystemen en onderzoekinstrumenten",
   code_33121000:"Ambulant langetermijnregistratiesysteem",
   code_33121100:"Eeg-apparaten",
   code_33121200:"Scintigrafie-instrumenten",
   code_33121300:"Elektromyografen",
   code_33121400:"Audiometers",
   code_33121500:"Elektrocardiogram",
   code_33122000:"Oogheelkundige apparatuur",
   code_33123000:"Instrumentarium voor hart- en vaatonderzoek",
   code_33123100:"Bloeddrukmeter",
   code_33123200:"Instrumentarium voor elektrocardiografie",
   code_33123210:"Hartbewakingssysteem",
   code_33123220:"Apparatuur voor angiocardiografie",
   code_33123230:"Cardiografen",
   code_33124000:"Instrumentarium en benodigdheden voor diagnostiek en radiodiagnostiek",
   code_33124100:"Diagnostisch instrumentarium",
   code_33124110:"Diagnosesystemen",
   code_33124120:"Ultrageluidsinstrumentarium voor diagnostische doeleinden",
   code_33124130:"Diagnostische benodigdheden",
   code_33124131:"Reageerstrips",
   code_33124200:"Instrumentarium voor radiodiagnostiek",
   code_33124210:"Benodigdheden voor radiodiagnostiek",
   code_33125000:"Instrumentarium voor urologisch onderzoek",
   code_33126000:"Instrumentarium voor stomatologie",
   code_33127000:"Instrumentarium voor immunoanalyse",
   code_33128000:"Medische laser voor andere dan chirurgische doeleinden",
   code_33130000:"Instrumentarium en apparatuur voor tandheelkundig en daaronder vallend gebruik",
   code_33131000:"Handinstrumenten voor tandarts",
   code_33131100:"Instrumentarium voor kaakchirurgie",
   code_33131110:"Tangen, borsteltjes, retractors en bruneerders voor tandheelkundig gebruik",
   code_33131111:"Tangen voor tandheelkundig gebruik",
   code_33131112:"Borsteltjes voor tandheelkundig gebruik",
   code_33131113:"Retractors voor tandheelkundig gebruik",
   code_33131114:"Bruneerders voor tandheelkundig gebruik",
   code_33131120:"Sondes, wortelheffers en excavators voor kaakcryochirurgie",
   code_33131121:"Units voor kaakcryochirurgie",
   code_33131122:"Tandsondes",
   code_33131123:"Wortelheffers",
   code_33131124:"Tandexcavators",
   code_33131130:"Vingerbeschermers en extractietangen voor tandheelkundig gebruik",
   code_33131131:"Vingerbeschermers voor tandheelkundig gebruik",
   code_33131132:"Extractietangen voor tandheelkundig gebruik",
   code_33131140:"Tandspiegeltjes en -ruimers",
   code_33131141:"Tandspiegeltjes",
   code_33131142:"Tandruimers",
   code_33131150:"Apexhaken, scalers en andere tandsteenverwijderaars",
   code_33131151:"Apexhaken",
   code_33131152:"Scalers",
   code_33131153:"Tandsteenverwijderaars",
   code_33131160:"Scharen en messen voor tandheelkundig gebruik",
   code_33131161:"Scharen voor tandheelkundig gebruik",
   code_33131162:"Messen voor tandheelkundig gebruik",
   code_33131170:"Spatels, pincetten en wasmodelleerders voor tandheelkundig gebruik",
   code_33131171:"Spatels voor tandheelkundig gebruik",
   code_33131172:"Pincetten voor tandheelkundig gebruik",
   code_33131173:"Tandtechnische wasmodelleerders",
   code_33131200:"Hechtnaald voor kaakchirurgie",
   code_33131300:"Tandartsinstrumentarium voor eenmalig gebruik",
   code_33131400:"Tandsonde",
   code_33131500:"Tandextractie-instrument",
   code_33131510:"Tandboren",
   code_33131600:"Instrument voor tandvulling",
   code_33132000:"Tandimplantaat",
   code_33133000:"Toebehoren voor tandafdrukken",
   code_33134000:"Klein materiaal voor endodontologie",
   code_33135000:"Orthodontie-instrumenten",
   code_33136000:"Draai- en slijpinstrumentarium",
   code_33137000:"Toebehoren voor tandprofylaxe",
   code_33138000:"Producten voor gebitsprothesen en gebitsregulatie",
   code_33138100:"Kunstgebitten",
   code_33140000:"Medische verbruiksartikelen",
   code_33141000:"Niet-chemische medische gebruiksgoederen voor eenmalig gebruik en verbruiksartikelen voor hematologie",
   code_33141100:"Verband; levering van clips, hechtdraad, ligatuur",
   code_33141110:"Verband",
   code_33141111:"Zelfklevend verband",
   code_33141112:"Hechtpleisters",
   code_33141113:"Bandages",
   code_33141114:"Medicinaal gaas",
   code_33141115:"Medicinale watten",
   code_33141116:"Verpakte kompressen",
   code_33141117:"Watten",
   code_33141118:"Wiscompressen",
   code_33141119:"Compressen",
   code_33141120:"Clips, hechtdraad, ligatuur",
   code_33141121:"Hechtdraad",
   code_33141122:"Hechtkrammen",
   code_33141123:"Afvalbakken voor scherpe voorwerpen",
   code_33141124:"Pads voor scharen en messen",
   code_33141125:"Materiaal voor chirurgische hechtingen",
   code_33141126:"Ligatuur",
   code_33141127:"Resorbeerbare hemostatica",
   code_33141128:"Hechtnaalden",
   code_33141200:"Katheters",
   code_33141210:"Ballonkatheters",
   code_33141220:"Canules",
   code_33141230:"Dilatoria",
   code_33141240:"Cathetertoebehoren",
   code_33141300:"Venapunctie, instrumenten voor bloedafname",
   code_33141310:"Injectiespuiten",
   code_33141320:"Naalden voor medisch gebruik",
   code_33141321:"Naalden voor anesthesie",
   code_33141322:"Arteriële naalden",
   code_33141323:"Biopsienaalden",
   code_33141324:"Dialysenaalden",
   code_33141325:"Fistelnaalden",
   code_33141326:"Naalden voor radiologisch gebruik",
   code_33141327:"Open naalden",
   code_33141328:"Epidurale naalden",
   code_33141329:"Amniocentesenaalden",
   code_33141400:"Draadschaar en bistouri; operatiehandschoenen",
   code_33141410:"Draadschaar en bistouri",
   code_33141411:"Scalpels en mesjes",
   code_33141420:"Operatiehandschoenen",
   code_33141500:"Verbruiksartikelen voor hematologie",
   code_33141510:"Bloedproducten",
   code_33141520:"Plasma-extracten",
   code_33141530:"Bloedstollingsmiddelen",
   code_33141540:"Albumine",
   code_33141550:"Heparine",
   code_33141560:"Menselijke organen",
   code_33141570:"Menselijk bloed",
   code_33141580:"Dierlijk bloed",
   code_33141600:"Opvangbakjes en -zakjes, drainagesets en kits",
   code_33141610:"Opvangzakjes",
   code_33141613:"Bloedzakjes",
   code_33141614:"Plasmazakjes",
   code_33141615:"Urinezakjes",
   code_33141620:"Medische sets",
   code_33141621:"Incontinentieset",
   code_33141622:"Aids-preventiesets",
   code_33141623:"EHBO-trommels",
   code_33141624:"Toedieningssets",
   code_33141625:"Diagnosesets",
   code_33141626:"Doseersets",
   code_33141630:"Bloedplasmafilters",
   code_33141640:"Drains",
   code_33141641:"Sondes",
   code_33141642:"Drainagetoebehoren",
   code_33141700:"Orthopedische benodigdheden",
   code_33141710:"Krukken",
   code_33141720:"Loophulpmiddelen",
   code_33141730:"Orthopedische kragen",
   code_33141740:"Orthopedisch schoeisel",
   code_33141750:"Kunstgewrichten",
   code_33141760:"Spalken",
   code_33141770:"Hulpmiddelen voor botbreuken, pinnen en platen",
   code_33141800:"Verbruiksartikelen voor gebitsverzorging",
   code_33141810:"Materialen voor tandvulling",
   code_33141820:"Tanden",
   code_33141821:"Porseleinen tanden",
   code_33141822:"Tanden van acryl",
   code_33141830:"Cementbasis",
   code_33141840:"Hemostaticum voor tandheelkundig gebruik",
   code_33141850:"Tandhygiëneproducten",
   code_33141900:"Bloedlancetten",
   code_33150000:"Apparatuur voor radiotherapie, mechanotherapie, elektrotherapie en fysiotherapie",
   code_33151000:"Instrumentarium en benodigdheden voor radiotherapie",
   code_33151100:"Instrumentarium voor gammatherapie",
   code_33151200:"Instrumentarium voor röntgentherapie",
   code_33151300:"Spectrografen",
   code_33151400:"Benodigdheden voor radiotherapie",
   code_33152000:"Couveuses",
   code_33153000:"Niersteenvergruizer",
   code_33154000:"Apparatuur voor mechanotherapie",
   code_33155000:"Apparatuur voor fysiotherapie",
   code_33156000:"Materiaal voor psychologische tests",
   code_33157000:"Instrumentarium voor gastherapie en beademing",
   code_33157100:"Maskers voor medicinale gassen",
   code_33157110:"Zuurstofmasker",
   code_33157200:"Zuurstofkits",
   code_33157300:"Zuurstoftenten",
   code_33157400:"Medicinale beademingsapparatuur",
   code_33157500:"Hyperbarische kamers",
   code_33157700:"Gas-wasfles",
   code_33157800:"Unit voor het toedienen van zuurstof",
   code_33157810:"Zuurstoftherapie-unit",
   code_33158000:"Apparatuur voor elektrische, elektromagnetische en mechanische behandeling",
   code_33158100:"Elektromagnetische unit",
   code_33158200:"Instrumentarium voor elektrotherapie",
   code_33158210:"Stimulator",
   code_33158300:"Medische apparatuur met uv-straling",
   code_33158400:"Unit voor mechanotherapie",
   code_33158500:"Medische apparatuur met IR-straling",
   code_33159000:"Systeem voor klinische chemie",
   code_33160000:"Operatietechniek",
   code_33161000:"Unit voor elektrochirurgie",
   code_33162000:"Apparatuur en instrumentarium voor OK",
   code_33162100:"Instrumentarium voor OK",
   code_33162200:"OK-instrumenten",
   code_33163000:"Tent voor medische doeleinden",
   code_33164000:"Apparatuur voor coelioscopie",
   code_33164100:"Colposcoop",
   code_33165000:"Apparatuur voor cryochirurgie en cryotherapie",
   code_33166000:"Apparatuur voor dermatologie",
   code_33167000:"Operatielampen",
   code_33168000:"Apparatuur voor endoscopie en endochirurgie",
   code_33168100:"Endoscopen",
   code_33169000:"Chirurgische instrumenten",
   code_33169100:"Chirurgische laser",
   code_33169200:"Mandjes voor heelkundig gebruik",
   code_33169300:"Bakjes voor heelkundig gebruik",
   code_33169400:"Containers voor heelkundig gebruik",
   code_33169500:"Heelkundige opsporings- en traceringssystemen",
   code_33170000:"Anesthesie en recovery",
   code_33171000:"Instrumentarium voor anesthesie en reanimatie",
   code_33171100:"Instrumenten voor anesthesie",
   code_33171110:"Anesthesiemasker",
   code_33171200:"Instrumentarium voor reanimatie",
   code_33171210:"Reanimatiemasker",
   code_33171300:"Kits of pakketten voor epidurale ingreep",
   code_33172000:"Apparatuur voor anesthesie en reanimatie",
   code_33172100:"Instrumentarium voor anesthesie",
   code_33172200:"Apparatuur voor reanimatie",
   code_33180000:"Functionele ondersteuning",
   code_33181000:"Apparatuur voor nierdialyse",
   code_33181100:"Apparatuur voor hemodialyse",
   code_33181200:"Dialysefilters",
   code_33181300:"Patiëntmonitoren voor hemodialyse",
   code_33181400:"Meerpatiëntendialyseapparatuur",
   code_33181500:"Verbruiksartikelen voor nierbehandeling",
   code_33181510:"Nierdialysevloeistof",
   code_33181520:"Verbruiksartikelen voor nierdialyse",
   code_33182000:"Apparatuur voor hartondersteuning",
   code_33182100:"Defibrillator",
   code_33182200:"Apparatuur voor hartstimulatie",
   code_33182210:"Pacemakers",
   code_33182220:"Hartkleppen",
   code_33182230:"Ventrikels",
   code_33182240:"Onderdelen en toebehoren voor pacemakers",
   code_33182241:"Batterijen voor pacemakers",
   code_33182300:"Apparatuur voor hartchirurgie",
   code_33182400:"Röntgenapparatuur voor hartonderzoek",
   code_33183000:"Orthopedische hulpmiddelen",
   code_33183100:"Orthopedische implantaten",
   code_33183200:"Orthopedische protheses",
   code_33183300:"Apparatuur voor osteosynthese",
   code_33184000:"Kunstmatige lichaamsdelen",
   code_33184100:"Chirurgische implantaten",
   code_33184200:"Vasculaire protheses",
   code_33184300:"Hartprotheses",
   code_33184400:"Borstprotheses",
   code_33184410:"Inwendige borstprotheses",
   code_33184420:"Uitwendige borstprotheses",
   code_33184500:"Coronaire endoprotheses",
   code_33184600:"Kunstogen",
   code_33185000:"Gehoorapparaten",
   code_33185100:"Onderdelen en toebehoren voor gehoorapparaten",
   code_33185200:"Binnenoorimplantaten",
   code_33185300:"KNO-implantaten",
   code_33185400:"Kunststrottenhoofden",
   code_33186000:"Hart-longmachine",
   code_33186100:"Zuurstofapparaat",
   code_33186200:"Verwarming van bloed en vloeistoffen",
   code_33190000:"Diverse medische instrumenten en producten",
   code_33191000:"Instrumentarium voor sterilisatie, desinfectie en reiniging",
   code_33191100:"Sterilisator",
   code_33191110:"Autoclaven",
   code_33192000:"Meubilair voor medische doeleinden",
   code_33192100:"Hospitaalbedden",
   code_33192110:"Orthopedische bedden",
   code_33192120:"Ziekenhuisbedden",
   code_33192130:"Bedden met verstelmotor",
   code_33192140:"Divans voor psychiatrie",
   code_33192150:"Bedden voor therapeutische doeleinden",
   code_33192160:"Brancards",
   code_33192200:"Medische tafels",
   code_33192210:"Onderzoektafels",
   code_33192230:"Operatietafels",
   code_33192300:"Medisch meubilair, met uitzondering van bedden en tafels",
   code_33192310:"Tractie- of ophanguitrusting voor medische bedden",
   code_33192320:"Urinefleshouders",
   code_33192330:"Infuusstatieven",
   code_33192340:"Meubilair voor OK, met uitzondering van tafels",
   code_33192350:"Cel voor medische culturen",
   code_33192400:"Tandartsunits",
   code_33192410:"Tandartsstoelen",
   code_33192500:"Reageerbuisjes",
   code_33192600:"Hefwerktuigen voor de gezondheidszorg",
   code_33193000:"Invalidewagentjes, rolstoelen en bijbehorende apparatuur",
   code_33193100:"Invalidewagentjes en rolstoelen",
   code_33193110:"Invalidewagentjes",
   code_33193120:"Rolstoelen",
   code_33193121:"Gemotoriseerde rolstoelen",
   code_33193200:"Onderdelen en toebehoren voor invalidewagentjes en rolstoelen",
   code_33193210:"Onderdelen en toebehoren voor invalidewagentjes",
   code_33193211:"Motoren voor invalidewagentjes",
   code_33193212:"Stuurmechanismen voor invalidenwagentjes",
   code_33193213:"Besturingsmechanismen voor invalidenwagentjes",
   code_33193214:"Onderstellen voor invalidenwagentjes",
   code_33193220:"Onderdelen en toebehoren voor rolstoelen",
   code_33193221:"Kussens voor rolstoelen",
   code_33193222:"Frames voor rolstoelen",
   code_33193223:"Zittingen voor rolstoelen",
   code_33193224:"Wielen voor rolstoelen",
   code_33193225:"Rolstoelbanden",
   code_33194000:"Apparatuur en instrumentarium voor transfusie en infuus",
   code_33194100:"Apparatuur en instrumentarium voor infuus",
   code_33194110:"Infuuspompen",
   code_33194120:"Infusieartikelen",
   code_33194200:"Apparatuur en instrumentarium voor transfusie",
   code_33194210:"Apparatuur voor bloedtransfusie",
   code_33194220:"Benodigdheden voor bloedtransfusie",
   code_33195000:"Patiëntenbewakingssysteem",
   code_33195100:"Monitoren",
   code_33195110:"Ademhalingsmonitoren",
   code_33195200:"Centraal bewakingsstation",
   code_33196000:"Medische hulpmiddelen",
   code_33196100:"Hulpmiddelen voor ouderen",
   code_33196200:"Hulpmiddelen voor gehandicapten",
   code_33197000:"Medische computeruitrusting",
   code_33198000:"Ziekenhuisartikelen van papier",
   code_33198100:"Papieren kompressen",
   code_33198200:"Papieren sterilisatiezakjes of -wikkels",
   code_33199000:"Kleding voor medisch personeel",
   code_33600000:"Farmaceutische producten",
   code_33610000:"Geneesmiddelen voor maagdarmkanaal en metabolisme",
   code_33611000:"Geneesmiddelen voor storingen i.v.m. zuur",
   code_33612000:"Geneesmiddelen voor functionele maagdarmstoringen",
   code_33613000:"Laxeermiddelen",
   code_33614000:"Middelen tegen diarree en tegen darmontstekingen en -infecties",
   code_33615000:"Geneesmiddelen voor diabetes",
   code_33615100:"Insuline",
   code_33616000:"Vitaminen",
   code_33616100:"Provitaminen",
   code_33617000:"Mineraalsupplementen",
   code_33620000:"Geneesmiddelen voor het bloed, de bloedvormende organen en het hartvaatstelsel",
   code_33621000:"Geneesmiddelen voor bloed en bloedvormende organen",
   code_33621100:"Antithrombogene middelen",
   code_33621200:"Middelen tegen bloedingen",
   code_33621300:"Preparaten tegen bloedarmoede",
   code_33621400:"Bloedvervangende middelen en bloedtransfusie-oplossingen",
   code_33622000:"Geneesmiddelen voor het hartvaatstelsel",
   code_33622100:"Geneesmiddelen voor harttherapie",
   code_33622200:"Middelen tegen hypertensie",
   code_33622300:"Diuretica",
   code_33622400:"Beschermende middelen voor bloedvaten",
   code_33622500:"Middelen tegen hemorroïden, lokaal gebruik",
   code_33622600:"Betablockers",
   code_33622700:"Calciumkanaalblokkerende middelen",
   code_33622800:"Middelen die het renine-angiotensine stelsel beïnvloeden",
   code_33630000:"Geneesmiddelen voor dermatologie, spierstelsel en skelet",
   code_33631000:"Geneesmiddelen voor dermatologie",
   code_33631100:"Schimmelbestrijdingsmiddelen voor dermatologisch gebruik",
   code_33631110:"Salicylzuren",
   code_33631200:"Verwekende en beschermende middelen",
   code_33631300:"Middelen tegen psoriasis",
   code_33631400:"Antibiotica en chemotherapeutica voor dermatologisch gebruik",
   code_33631500:"Corticosteroïden voor dermatologisch gebruik en dermatologische preparaten",
   code_33631600:"Antiseptica en desinfecteermiddelen",
   code_33631700:"Middelen tegen acne",
   code_33632000:"Geneesmiddelen voor het spierstelsel en het skelet",
   code_33632100:"Ontstekingremmende en reumabestrijdende preparaten",
   code_33632200:"Spierrelaxantia",
   code_33632300:"Middelen tegen jicht",
   code_33640000:"Geneesmiddelen voor het urogenitaal stelsel en hormonen",
   code_33641000:"Geneesmiddelen voor het urogenitaal systeem en geslachtshormonen",
   code_33641100:"Infectiewerende middelen en antiseptica voor gynecologisch gebruik",
   code_33641200:"Overige middelen voor gynecologisch gebruik",
   code_33641300:"Geslachtshormonen en modulatoren van het genitaal systeem",
   code_33641400:"Contraceptiva",
   code_33641410:"Orale contraceptiva",
   code_33641420:"Chemische contraceptiva",
   code_33642000:"Sytemische hormoonpreparaten, geslachtshormonen uitgezonderd",
   code_33642100:"Pituitaire hypothalamushormonen e.d.",
   code_33642200:"Corticosteroïden voor systemisch gebruik",
   code_33642300:"Geneesmiddelen voor behandeling van de schildklier",
   code_33650000:"Algemene infectiewerende middelen voor systemisch gebruik, vaccins, antineoplastica en immunomodulerende middelen",
   code_33651000:"Algemene infectiewerende middelen voor systemisch gebruik",
   code_33651100:"Antibacteriële middelen voor systemisch gebruik",
   code_33651200:"Antimycotica voor systemisch gebruik",
   code_33651300:"Antimycobacteriële middelen",
   code_33651400:"Antivirale middelen voor systemisch gebruik",
   code_33651500:"Immuunsera en immunoglobulinen",
   code_33651510:"Antiserums",
   code_33651520:"Immunoglobulinen",
   code_33651600:"Vaccins",
   code_33651610:"DPT-vaccins",
   code_33651620:"Difterie-Tetanusvaccins",
   code_33651630:"BCG-vaccins (gedroogd)",
   code_33651640:"BMR-vaccins",
   code_33651650:"Tyfusvaccins",
   code_33651660:"Griepvaccins",
   code_33651670:"Poliovaccins",
   code_33651680:"Hepatitis B-vaccin",
   code_33651690:"Vaccins voor diergeneeskunde",
   code_33652000:"Antineoplastische en immunomodulerende middelen",
   code_33652100:"Antineoplastische middelen",
   code_33652200:"Geneesmiddelen voor endocriene therapie",
   code_33652300:"Immunosuppresiemiddelen",
   code_33660000:"Geneesmiddelen voor zenuwstelsel en zintuigelijke organen",
   code_33661000:"Geneesmiddelen voor het zenuwstelsel",
   code_33661100:"Anesthetica",
   code_33661200:"Analgetica/pijnstillers",
   code_33661300:"Anti-epileptica",
   code_33661400:"Antiparkinsonmedicijnen",
   code_33661500:"Psycholeptica",
   code_33661600:"Psychoanaleptica",
   code_33661700:"Overige geneesmiddelen voor het zenuwstelsel",
   code_33662000:"Geneesmiddelen voor zintuigelijke organen",
   code_33662100:"Geneesmiddelen voor oogheelkunde",
   code_33670000:"Geneesmiddelen voor het ademhalingssysteem",
   code_33673000:"Geneesmiddelen voor obstructieve aandoeningen van de luchtwegen",
   code_33674000:"Middelen tegen hoest en verkoudheid",
   code_33675000:"Antihistaminen voor systemisch gebruik",
   code_33680000:"Farmaceutische artikelen",
   code_33681000:"Spenen, speenschilden en dergelijke artikelen voor baby's",
   code_33682000:"Rubber tegels",
   code_33683000:"Schokdempmateriaal van rubber",
   code_33690000:"Diverse geneesmiddelen",
   code_33691000:"Antiparasitaire middelen, insecticiden en insectenwerende middelen",
   code_33691100:"Antiprotozaire middelen",
   code_33691200:"Anthelmintica",
   code_33691300:"Ectoparasiticiden, incl. scabiciden, insecticiden en insectenwerende middelen",
   code_33692000:"Medische oplossingen",
   code_33692100:"Infuusoplossingen",
   code_33692200:"Producten voor parenterale voeding",
   code_33692210:"Oplossingen voor parenterale voeding",
   code_33692300:"Enterale voeding",
   code_33692400:"Perfusie-oplossingen",
   code_33692500:"Injecteerbare oplossingen",
   code_33692510:"Intraveneuze vloeistoffen",
   code_33692600:"Galenische oplossingen",
   code_33692700:"Glucoseoplossingen",
   code_33692800:"Dialyseoplossingen",
   code_33693000:"Overige therapeutische middelen",
   code_33693100:"Toxinen",
   code_33693200:"Nicotinevervangers",
   code_33693300:"Behandeling van verslavingen",
   code_33694000:"Diagnostische agentia",
   code_33695000:"Overige niet-therapeutische middelen",
   code_33696000:"Reagentia en contraststoffen",
   code_33696100:"Reagentia voor het bepalen van bloedgroep",
   code_33696200:"Reagentia voor bloedanalyse",
   code_33696300:"Chemische reagentia",
   code_33696400:"Isotopische reagentia",
   code_33696500:"Laboratoriumreagentia",
   code_33696600:"Reagentia voor elektroforese",
   code_33696700:"Reagentia voor urologie",
   code_33696800:"Röntgencontrastmiddelen",
   code_33697000:"Medische preparaten, met uitzondering van verbruiksartikelen voor gebitsverzorging",
   code_33697100:"Enemapreparaten",
   code_33697110:"Botcement",
   code_33698000:"Klinische producten",
   code_33698100:"Microbiologische kweken",
   code_33698200:"Klieren en klierextracten",
   code_33698300:"Peptische stoffen",
   code_33700000:"Producten voor persoonlijke verzorging",
   code_33710000:"Parfums, toiletartikelen en condooms",
   code_33711000:"Parfums en toiletartikelen",
   code_33711100:"Eau de toilette",
   code_33711110:"Deodorants",
   code_33711120:"Transpiratiewerende middelen",
   code_33711130:"Eau de cologne",
   code_33711140:"Geurstoffen",
   code_33711150:"Rozenwater",
   code_33711200:"Make-up middelen",
   code_33711300:"Producten voor manicure of pedicure",
   code_33711400:"Cosmetische producten",
   code_33711410:"Wattenstaafjes",
   code_33711420:"Make-upsets",
   code_33711430:"Wegwerpdoekjes voor persoonlijk gebruik",
   code_33711440:"Lipbalsem",
   code_33711450:"Tatoeages",
   code_33711500:"Huidverzorgingsproducten",
   code_33711510:"Zonbeschermingsmiddelen",
   code_33711520:"Badgel",
   code_33711530:"Douchemutsen",
   code_33711540:"Niet-farmaceutische crèmes of lotions",
   code_33711600:"Haarverzorgingsmiddelen en artikelen",
   code_33711610:"Shampoo",
   code_33711620:"Kammen",
   code_33711630:"Pruiken",
   code_33711640:"Schoonheidssets",
   code_33711700:"Artikelen en producten voor mond- of tandhygiëne",
   code_33711710:"Tandenborstels",
   code_33711720:"Tandpasta",
   code_33711730:"Tandenstokers",
   code_33711740:"Mondwater",
   code_33711750:"Mondverfrissers",
   code_33711760:"Tandzijde",
   code_33711770:"Fopspenen en bijtringen",
   code_33711780:"Gebitsreinigingstabletten",
   code_33711790:"Mondhygiënesets",
   code_33711800:"Scheerproducten",
   code_33711810:"Scheercrèmes",
   code_33711900:"Zeep",
   code_33712000:"Condooms",
   code_33713000:"Voetverzorgingsproducten",
   code_33720000:"Scheerapparaten en sets voor manicure of pedicure",
   code_33721000:"Scheermessen",
   code_33721100:"Scheermesjes",
   code_33721200:"Scheerapparaten",
   code_33722000:"Manicure- of pedicuresets",
   code_33722100:"Manicuresets",
   code_33722110:"Manicure-instrumenten",
   code_33722200:"Pedicuresets",
   code_33722210:"Pedicure-instrumenten",
   code_33722300:"Haarspelden",
   code_33730000:"Oogverzorgingsproducten en contactlenzen",
   code_33731000:"Contactlenzen",
   code_33731100:"Corrigerende lenzen",
   code_33731110:"Intra-oculaire lenzen",
   code_33731120:"Brillenglazen",
   code_33732000:"Contactlensvloeistoffen",
   code_33733000:"Zonnebrillen",
   code_33734000:"Brillen",
   code_33734100:"Brilmonturen",
   code_33734200:"Glas voor brillen",
   code_33735000:"Beschermende brillen",
   code_33735100:"Veiligheidsbrillen",
   code_33735200:"Monturen voor beschermende brillen",
   code_33740000:"Producten voor hand- en nagelverzorging",
   code_33741000:"Handverzorgingsproducten",
   code_33741100:"Handenreiniger",
   code_33741200:"Hand- of bodylotions",
   code_33741300:"Handenontsmettingsmiddel",
   code_33742000:"Nagelverzorgingsproducten",
   code_33742100:"Nagelknippers",
   code_33742200:"Nagellak",
   code_33750000:"Babyverzorgingsproducten",
   code_33751000:"Wegwerpluiers",
   code_33752000:"Verzorgende pads",
   code_33760000:"Toiletpapier, zakdoekjes, handdoeken en servetten",
   code_33761000:"Toiletpapier",
   code_33762000:"Papieren zakdoekjes",
   code_33763000:"Papieren handdoeken",
   code_33764000:"Papieren servetten",
   code_33770000:"Papieren hygiëneartikelen",
   code_33771000:"Papieren producten voor sanitair gebruik",
   code_33771100:"Maandverbanden of tampons",
   code_33771200:"Papieren inlegluiers",
   code_33772000:"Papieren wegwerpproducten",
   code_33790000:"Glaswerk voor laboratoria, voor hygiënisch of farmaceutisch gebruik",
   code_33791000:"Glaswerk voor hygiënisch gebruik",
   code_33792000:"Glaswerk voor farmaceutisch gebruik",
   code_33793000:"Glaswerk voor laboratoria",
   code_33900000:"Uitrusting en benodigdheden voor postmortaal en mortuariumgebruik",
   code_33910000:"Instrumenten en benodigdheden voor pathologie/sectie",
   code_33911000:"Autopsiescharen",
   code_33912000:"Autopsie/sectiekits",
   code_33912100:"Autopsie/sectiepincetten",
   code_33913000:"Kogelsonden voor autopsie",
   code_33914000:"Draad, naalden of snijklemmen voor postmortaal gebruik",
   code_33914100:"Draad voor postmortaal gebruik",
   code_33914200:"Naalden voor postmortaal gebruik",
   code_33914300:"Snijklemmen voor postmortaal gebruik",
   code_33915000:"Holle sondes voor autopsie",
   code_33916000:"Zaagbladen of toebehoren voor autopsie",
   code_33916100:"Autopsiezagen",
   code_33917000:"Sectietafels of pads",
   code_33918000:"Bakken voor postmortale chirurgische instrumenten of toebehoren",
   code_33919000:"Instrumentrollen voor postmortale chirurgische instrumenten of toebehoren",
   code_33920000:"Autopsieapparatuur en -benodigdheden",
   code_33921000:"Botstofcollectoren",
   code_33922000:"Zakken voor lijkenvervoer",
   code_33923000:"Hoofdsteunen, speciale tafels of hangende weegschalen voor autopsie",
   code_33923100:"Hoofdsteunen voor autopsie",
   code_33923200:"Speciale tafels voor autopsie",
   code_33923300:"Hangende weegschalen voor autopsie",
   code_33924000:"Kits voor autopsie bij besmettelijke ziekten",
   code_33925000:"Postmortale identificatielabels of -armbanden",
   code_33926000:"Vacuümafzuiging of slangen voor het opvangen van vloeistoffen bij autopsie",
   code_33927000:"Rectale thermometers voor postmortaal gebruik",
   code_33928000:"Postmortale vingerstrekkers",
   code_33929000:"Weefselopbouwsets (kadaveronderzoek)",
   code_33930000:"Meubelen voor autopsie",
   code_33931000:"Werkplekken of toebehoren voor autopsie",
   code_33932000:"Spoelbakken of toebehoren voor autopsie",
   code_33933000:"Tafels of toebehoren voor autopsie",
   code_33933100:"Autopsietafels",
   code_33934000:"Tafels of toebehoren voor necropsie",
   code_33935000:"Sectietafels of toebehoren voor postmortaal dieronderzoek",
   code_33936000:"Werkplekken of toebehoren voor balseming",
   code_33937000:"Werkplekken voor autopsie met afvoer van lichaamsvloeistoffen, of toebehoren",
   code_33940000:"Uitrusting en benodigdheden voor vervoer en opslag van lijken",
   code_33941000:"Rekken voor lijkenopslag",
   code_33942000:"Lijkenwagentjes",
   code_33943000:"Schaarhefwagentjes voor lijken",
   code_33944000:"Koel- of vriescellen voor lijkenhuis",
   code_33945000:"Inloopkoelcellen voor lijkenhuis",
   code_33946000:"Autopsiekarretjes",
   code_33947000:"Kadaverbakken",
   code_33948000:"Hef- of verplaatsingsapparaten voor kadavers",
   code_33949000:"Containers voor lijkenvervoer",
   code_33950000:"Klinisch-forensische apparatuur en benodigdheden",
   code_33951000:"Materiaal voor postmortale vingerafdruk of andere afdrukken",
   code_33952000:"Ontbindingremmende maskers",
   code_33953000:"Kits of toebehoren voor postmortaal bloedonderzoek",
   code_33954000:"Kits voor het verzamelen van biologisch bewijsmateriaal",
   code_33960000:"Apparatuur en benodigdheden voor balseming",
   code_33961000:"Apparaten voor het inspuiten van holten bij balseming",
   code_33962000:"Slangen voor het ledigen van bloedvaten bij balseming",
   code_33963000:"Balsemvloeistoffen of chemische preparaten",
   code_33964000:"Inspuitslangen voor balseming",
   code_33965000:"Spoelbakken of toebehoren voor balseming",
   code_33966000:"Balsemingspakketten",
   code_33967000:"Injectienaalden voor balseming",
   code_33968000:"Oogkapjes",
   code_33970000:"Apparatuur en benodigdheden voor mortuarium",
   code_33971000:"Sets voor mortuarium",
   code_33972000:"Pakketten voor mortuarium",
   code_33973000:"Draperieën voor mortuarium",
   code_33974000:"Afzuigers voor mortuarium",
   code_33975000:"Verhardende preparaten voor mortuarium",
   code_34000000:"Vervoersmaterieel en bijbehorende producten",
   code_34100000:"Motorvoertuigen",
   code_34110000:"Personenwagens",
   code_34111000:"Auto's: stationcars en sedans",
   code_34111100:"Stationcars",
   code_34111200:"Sedans",
   code_34113000:"Voertuigen met vierwielaandrijving",
   code_34113100:"Jeeps",
   code_34113200:"Terreinvoertuigen",
   code_34113300:"Terreinwagens",
   code_34114000:"Speciale voertuigen",
   code_34114100:"Hulpdienstvoertuigen",
   code_34114110:"Reddingsvoertuigen",
   code_34114120:"Voertuigen voor paramedische doeleinden",
   code_34114121:"Ambulances",
   code_34114122:"Voertuigen voor patiëntenvervoer",
   code_34114200:"Politieauto's",
   code_34114210:"Arrestantenwagens",
   code_34114300:"Voertuigen voor de sociale dienst",
   code_34114400:"Minibusjes",
   code_34115000:"Overige personenwagens",
   code_34115200:"Motorvoertuigen voor personenvervoer van minder dan 10 personen",
   code_34115300:"Tweedehands transportvoertuigen",
   code_34120000:"Motorvoertuigen voor het vervoer van 10 of meer personen",
   code_34121000:"Bussen en touringcars",
   code_34121100:"Bussen voor openbaar vervoer",
   code_34121200:"Gelede bussen",
   code_34121300:"Dubbeldekkers",
   code_34121400:"Bussen met verlaagde vloer",
   code_34121500:"Touringcars",
   code_34130000:"Motorvoertuigen voor goederenvervoer",
   code_34131000:"Kleine vrachtwagentjes",
   code_34132000:"Motorsleden",
   code_34133000:"Gelede vrachtwagens",
   code_34133100:"Tankwagens",
   code_34133110:"Brandstoftankwagens",
   code_34134000:"Diepladers en kipauto's",
   code_34134100:"Diepladers",
   code_34134200:"Kipauto's",
   code_34136000:"Bestelwagens",
   code_34136100:"Lichte bestelwagens",
   code_34136200:"Kleine dichte vrachtwagens",
   code_34137000:"Tweedehandsvoertuigen voor goederenvervoer",
   code_34138000:"Trekkers",
   code_34139000:"Chassis",
   code_34139100:"Chassis met cabines",
   code_34139200:"Chassis met lege opbouw",
   code_34139300:"Volledige chassis",
   code_34140000:"Zware motorvoertuigen",
   code_34142000:"Kranen en containerladers",
   code_34142100:"Vrachtwagens met hoogwerker",
   code_34142200:"Containerladers",
   code_34142300:"Hydraulische kipwagens",
   code_34143000:"Voertuigen voor wegenonderhoud tijdens de winter",
   code_34144000:"Motorvoertuigen voor speciale doeleinden",
   code_34144100:"Mobiele boortorens",
   code_34144200:"Voertuigen voor de hulpdiensten",
   code_34144210:"Brandbluswagens",
   code_34144211:"Autoladders",
   code_34144212:"Tankautospuiten",
   code_34144213:"Brandweerwagens",
   code_34144220:"Takel- en sleepvoertuigen",
   code_34144300:"Mobiele bruggen",
   code_34144400:"Voertuigen voor wegenonderhoud",
   code_34144410:"Kolkreinigers",
   code_34144420:"Zoutstrooiers",
   code_34144430:"Straatveegvoertuigen",
   code_34144431:"Veegzuigvoertuigen",
   code_34144440:"Splitstrooiers",
   code_34144450:"Sproeiwagens",
   code_34144500:"Voertuigen voor werkzaamheden in verband met vast afval en afvalwater",
   code_34144510:"Voertuigen voor werkzaamheden in verband met vast afval",
   code_34144511:"Vuilniswagens",
   code_34144512:"Vuilniscompacteerwagens",
   code_34144520:"Afvalwatertankwagens",
   code_34144700:"Bedrijfswagens",
   code_34144710:"Wielladers",
   code_34144730:"Tankwagens voor vliegtuigbrandstof",
   code_34144740:"Vliegtuigtrekkers",
   code_34144750:"Cargovoertuigen",
   code_34144751:"Portaaltrucks",
   code_34144760:"Bibliobussen",
   code_34144800:"Woonmobielen",
   code_34144900:"Elektrische voertuigen",
   code_34144910:"Elektrische bussen",
   code_34150000:"Simulatoren",
   code_34151000:"Rijsimulatoren",
   code_34152000:"Opleidingssimulatoren",
   code_34200000:"Voertuigcarrosserieën, aanhangwagens of opleggers",
   code_34210000:"Voertuigcarrosserieën",
   code_34211000:"Carrosserieën voor bussen, ambulances en vrachtwagens",
   code_34211100:"Carrosserieën voor bussen",
   code_34211200:"Carrosserieën voor ambulances",
   code_34211300:"Carrosserieën voor vrachtwagens",
   code_34220000:"Aanhangwagens, opleggers en mobiele containers",
   code_34221000:"Mobiele containers voor bijzondere doeleinden",
   code_34221100:"Eerstehulpvoertuigen",
   code_34221200:"Noodhulpwagens",
   code_34221300:"Eerstehulpwagens voor chemische ongevallen",
   code_34223000:"Aanhangwagens en opleggers",
   code_34223100:"Opleggers",
   code_34223200:"Brandstoftankwagens",
   code_34223300:"Aanhangwagens",
   code_34223310:"Aanhangwagens geschikt voor diverse doeleinden",
   code_34223320:"Trailers voor paardenvervoer",
   code_34223330:"Mobiele units op aanhangwagens",
   code_34223340:"Tankeraanhangwagens",
   code_34223350:"Autoladderaanhangwagens",
   code_34223360:"Aanhangwagens voor brandstofvoorziening",
   code_34223370:"Aanhangwagens met kiplaadbak",
   code_34223400:"Caravanachtige aanhangwagens en opleggers",
   code_34224000:"Onderdelen van aanhangwagens, opleggers en andere voertuigen",
   code_34224100:"Delen van aanhangwagens en opleggers",
   code_34224200:"Delen van andere voertuigen",
   code_34300000:"Onderdelen en toebehoren voor voertuigen en motoren",
   code_34310000:"Motoren (voor voertuigen) en onderdelen daarvan",
   code_34311000:"Motoren (voor voertuigen)",
   code_34311100:"Verbrandingsmotoren voor motorvoertuigen en motorfietsen",
   code_34311110:"Vonkontstekingmotoren",
   code_34311120:"Compressie-ontstekingsmotoren",
   code_34312000:"Motoronderdelen",
   code_34312100:"Ventilatorriemen",
   code_34312200:"Bougies",
   code_34312300:"Voertuigradiatoren",
   code_34312400:"Zuigers",
   code_34312500:"Pakkingen",
   code_34312600:"Rubber transportbanden",
   code_34312700:"Rubber drijfriemen",
   code_34320000:"Mechanische onderdelen, met uitzondering van motoren en motoronderdelen",
   code_34321000:"Assen en versnellingsbakken",
   code_34321100:"Assen",
   code_34321200:"Versnellingsbakken",
   code_34322000:"Remmen en remonderdelen",
   code_34322100:"Remuitrusting",
   code_34322200:"Schijfremmen",
   code_34322300:"Remvoeringen",
   code_34322400:"Remblokken",
   code_34322500:"Remschoenen",
   code_34324000:"Wielen, onderdelen en toebehoren",
   code_34324100:"Wielbalanceeruitrusting",
   code_34325000:"Knaldempers en uitlaatpijpen",
   code_34325100:"Knaldempers",
   code_34325200:"Uitlaatpijpen",
   code_34326000:"Autokriks, koppelingen en bijbehorende onderdelen",
   code_34326100:"Koppelingen en onderdelen",
   code_34326200:"Voertuigkrikken",
   code_34327000:"Stuurwielen, stuurkolommen en stuurhuizen",
   code_34327100:"Stuurwielen",
   code_34327200:"Stuurkolommen en stuurhuizen",
   code_34328000:"Testbanken, kits voor het ombouwen van voertuigen en veiligheidsgordels",
   code_34328100:"Proefbanken",
   code_34328200:"Kits voor het ombouwen van voertuigen",
   code_34328300:"Veiligheidsgordels",
   code_34330000:"Onderdelen voor vrachtwagens, bestelwagens en auto's",
   code_34350000:"Banden voor normale en zware voertuigen",
   code_34351000:"Banden voor normale auto's",
   code_34351100:"Banden voor motorvoertuigen",
   code_34352000:"Banden voor zwaar gebruik",
   code_34352100:"Banden voor vrachtwagens",
   code_34352200:"Banden voor autobussen",
   code_34352300:"Banden voor landbouwmachines",
   code_34360000:"Stoelen voor burgerluchtvaartuigen",
   code_34370000:"Stoelen voor motorvoertuigen",
   code_34390000:"Toebehoren voor trekkers",
   code_34400000:"Motorfietsen, fietsen en zijspanwagens",
   code_34410000:"Motorfietsen",
   code_34411000:"Onderdelen en toebehoren voor motorfietsen",
   code_34411100:"Zijspanwagens",
   code_34411110:"Onderdelen en toebehoren voor zijspanwagens",
   code_34411200:"Banden voor motorfietsen",
   code_34420000:"Scooters en fietsen met hulpmotor",
   code_34421000:"Scooters",
   code_34422000:"Fietsen met hulpmotor",
   code_34430000:"Fietsen",
   code_34431000:"Fietsen zonder hulpmotor",
   code_34432000:"Onderdelen en toebehoren voor fietsen",
   code_34432100:"Banden voor fietsen",
   code_34500000:"Schepen en boten",
   code_34510000:"Schepen",
   code_34511100:"Marinepatrouillevaartuigen",
   code_34512000:"Schepen en gelijksoortige vaartuigen voor personen- of goederenvervoer",
   code_34512100:"Veerboten",
   code_34512200:"Cruiseschepen",
   code_34512300:"Bulkcarriers",
   code_34512400:"Vrachtschepen",
   code_34512500:"Goederenschepen",
   code_34512600:"Containerschepen",
   code_34512700:"Rij-op-rij-afschepen",
   code_34512800:"Tankschepen",
   code_34512900:"Autoschepen",
   code_34512950:"Koelschepen",
   code_34513000:"Vissersboten, reddingsboten en andere speciale vaartuigen",
   code_34513100:"Vissersschepen",
   code_34513150:"Fabrieksschepen",
   code_34513200:"Sleepboten",
   code_34513250:"Baggerschepen",
   code_34513300:"Zeewaardige drijfdokken",
   code_34513350:"Duikersschepen",
   code_34513400:"Drijvende kranen",
   code_34513450:"Productievaartuigen",
   code_34513500:"Vaartuigen voor seismisch onderzoek",
   code_34513550:"Onderzoeksvaartuigen",
   code_34513600:"Vervuilingsbestrijdingsvaartuigen",
   code_34513650:"Brandblusvaartuigen",
   code_34513700:"Reddingsvaartuigen",
   code_34513750:"Lichtschepen",
   code_34514000:"Drijvende of diepdrijvende boor- en productieplatforms",
   code_34514100:"Boorschepen",
   code_34514200:"Opvijzelbare platforms",
   code_34514300:"Boorinstallaties op platforms",
   code_34514400:"Drijvende boorplatforms",
   code_34514500:"Drijvende productie-installatie",
   code_34514600:"Halfverzinkbare platforms",
   code_34514700:"Mobiele platforms",
   code_34514800:"Offshoreplatforms",
   code_34514900:"Boorplatforms",
   code_34515000:"Drijvende constructies",
   code_34515100:"Markeringsboeien",
   code_34515200:"Opblaasbare vlotten",
   code_34516000:"Fenders voor de marine",
   code_34520000:"Boten",
   code_34521000:"Boten voor bijzondere doeleinden",
   code_34521100:"Bewakingsboten",
   code_34521200:"Patrouilleboten voor de douane",
   code_34521300:"Patrouilleboten voor de politie",
   code_34521400:"Reddingsboten",
   code_34522000:"Plezier- en sportboten",
   code_34522100:"Zeilboten",
   code_34522150:"Catamaran-zeilboten",
   code_34522200:"Reddingssloepen",
   code_34522250:"Zeilsloepen",
   code_34522300:"Kleine vaartuigen",
   code_34522350:"Sloepen van glasvezel",
   code_34522400:"Sloepen met harde kiel",
   code_34522450:"Opblaasbare vaartuigen",
   code_34522500:"Rubberboten",
   code_34522550:"Kano's",
   code_34522600:"Roeiboten",
   code_34522700:"Pedaalboten",
   code_34600000:"Spoor- en tramlocomotieven, rollend materieel en onderdelen",
   code_34610000:"Spoorlocomotieven en tenders",
   code_34611000:"Locomotieven",
   code_34612000:"Locomotieftenders en kabelwagens",
   code_34612100:"Locomotieftenders",
   code_34612200:"Kabelwagens",
   code_34620000:"Rollend materieel",
   code_34621000:"Rijtuigen voor spooronderhoud of dienst, en goederenwagons",
   code_34621100:"Goederenwagons voor spoorwegen",
   code_34621200:"Spooronderhouds- of dienstvoertuigen",
   code_34622000:"Passagierswagons voor spoor en tram, en trolleybussen",
   code_34622100:"Passagierswagons voor tramspoor",
   code_34622200:"Passagierswagons voor spoorwegen",
   code_34622300:"Trolleybussen",
   code_34622400:"Spoorwagons",
   code_34622500:"Bagagewagons en wagons voor speciale doeleinden",
   code_34630000:"Onderdelen van spoor- of tramlocomotieven of rollend materieel; apparatuur voor spoorverkeersregeling",
   code_34631000:"Onderdelen van locomotieven of rollend materieel",
   code_34631100:"Volwielen",
   code_34631200:"Buffers en trekwerk",
   code_34631300:"Stoelen voor rollend materieel",
   code_34631400:"Wielassen, banden en andere onderdelen van locomotieven of rollend materieel",
   code_34632000:"Apparatuur voor spoorverkeersregeling",
   code_34632100:"Mechanische signalering",
   code_34632200:"Elektrische signalisatie-uitrusting voor spoorwegen",
   code_34632300:"Elektrische installaties voor spoorwegen",
   code_34640000:"Zelfrijdende elementen",
   code_34700000:"Lucht- en ruimtevaartuigen",
   code_34710000:"Helikopters, vliegtuigen, ruimtevaartuigen en andere gemotoriseerde luchtvaartuigen",
   code_34711000:"Helicopters en vliegtuigen",
   code_34711100:"Vliegtuigen",
   code_34711110:"Vleugelvliegtuigen",
   code_34711200:"Op afstand bestuurde luchtvaartuigen",
   code_34711300:"Luchtvaartuigen met piloot",
   code_34711400:"Luchtvaartuigen voor bijzondere doeleinden",
   code_34711500:"Helikopters",
   code_34712000:"Ruimtevaartuigen, satellieten en lanceervoertuigen",
   code_34712100:"Ruimtevaartuigen",
   code_34712200:"Satellieten",
   code_34712300:"Lanceervoertuigen voor ruimtevaartuigen",
   code_34720000:"Luchtballons, luchtschepen en andere niet-gemotoriseerde luchtvaartuigen",
   code_34721000:"Zweefvliegtuigen",
   code_34721100:"Deltavliegers",
   code_34722000:"Heteluchtballons en luchtschepen",
   code_34722100:"Luchtballons",
   code_34722200:"Luchtschepen",
   code_34730000:"Onderdelen voor vliegtuigen, ruimtevaartuigen en helikopters",
   code_34731000:"Onderdelen voor luchtvaartuigen",
   code_34731100:"Vliegtuigmotoren",
   code_34731200:"Turbostraalmotoren",
   code_34731300:"Turboprops",
   code_34731400:"Straalmotoren",
   code_34731500:"Onderdelen voor vliegtuigmotoren",
   code_34731600:"Onderdelen voor straalmotoren of turboprops",
   code_34731700:"Onderdelen voor helikopters",
   code_34731800:"Vliegtuigbanden",
   code_34740000:"Uitrusting voor lucht- en ruimtevaartuigen, opleidingstoestellen, simulators en onderdelen",
   code_34741000:"Uitrusting voor vliegtuigen",
   code_34741100:"Lanceerinstallaties voor vliegtuigen",
   code_34741200:"Katapultinstallaties voor vliegtuigen",
   code_34741300:"Deklandingsinstallaties",
   code_34741400:"Vluchtsimulators",
   code_34741500:"Toestel voor vliegoefeningen op de grond",
   code_34741600:"Zakjes voor luchtziekte",
   code_34900000:"Diverse transportmiddelen en onderdelen",
   code_34910000:"Paarden- of handkarren, andere niet-mechanisch aangedreven voertuigen, bagagekarretjes en diverse onderdelen",
   code_34911000:"Paarden- of handkarren en andere niet mechanisch aangedreven voertuigen",
   code_34911100:"Karretjes",
   code_34912000:"Bagagewagentjes",
   code_34912100:"Wandelwagentjes",
   code_34913000:"Diverse onderdelen",
   code_34913100:"Gebruikte banden",
   code_34913200:"Coverbanden",
   code_34913300:"Fenders",
   code_34913400:"Klokken voor voertuigen",
   code_34913500:"Sloten voor voertuigen",
   code_34913510:"Fietssloten",
   code_34913600:"Scheepsschroeven",
   code_34913700:"Brandweerladders",
   code_34913800:"Ankers",
   code_34920000:"Wegenuitrusting",
   code_34921000:"Uitrusting voor wegenonderhoud",
   code_34921100:"Straatveegmachines",
   code_34921200:"Veegmachines voor start- en landingsbanen",
   code_34922000:"Wegmarkeringsuitrusting",
   code_34922100:"Wegmarkeringen",
   code_34922110:"Glasparels voor wegmarkering",
   code_34923000:"Verkeersregulatieapparatuur",
   code_34924000:"Matrixborden",
   code_34926000:"Parkeerregulatieapparatuur",
   code_34927000:"Tolgelduitrusting",
   code_34927100:"Strooizout",
   code_34928000:"Wegmeubilair",
   code_34928100:"Vangrails",
   code_34928110:"Vangrails langs wegen",
   code_34928120:"Onderdelen van afzettingen",
   code_34928200:"Hekken",
   code_34928210:"Houten palen",
   code_34928220:"Componenten voor omheiningen",
   code_34928230:"Geluidswallen",
   code_34928300:"Middenbermbeveiliging",
   code_34928310:"Veiligheidshek",
   code_34928320:"Borstweringen",
   code_34928330:"Voorzieningen tegen lawines",
   code_34928340:"Sneeuwhekken",
   code_34928400:"Stadsmeubilair",
   code_34928410:"Bermpaaltjes",
   code_34928420:"Waarschuwingslampen langs de weg",
   code_34928430:"Bakens",
   code_34928440:"Bushaltepalen",
   code_34928450:"Verkeerszuiltjes",
   code_34928460:"Verkeerskegels",
   code_34928470:"Signaleringsborden",
   code_34928471:"Materiaal voor signaleringsborden",
   code_34928472:"Palen voor signaleringsborden",
   code_34928480:"Afval- en vuilcontainers en bakken",
   code_34928500:"Straatverlichtingsuitrusting",
   code_34928510:"Straatlichtmasten",
   code_34928520:"Lantaarnpalen",
   code_34928530:"Straatlantaarns",
   code_34929000:"Materiaal voor aanleg van snelwegen",
   code_34930000:"Uitrusting voor de scheepvaart",
   code_34931000:"Uitrusting voor havens",
   code_34931100:"Dokinstallaties",
   code_34931200:"Passagierslandingsbrug voor schepen",
   code_34931300:"Passagierslandingstrap voor schepen",
   code_34931400:"Scheepsbrugsimulatoren",
   code_34931500:"Verkeersregulatieapparatuur voor scheepvaart",
   code_34932000:"Radarsets",
   code_34933000:"Navigatie-uitrusting",
   code_34934000:"Propellerbladen",
   code_34940000:"Spoorweguitrusting",
   code_34941000:"Spoorrails en toebehoren",
   code_34941100:"Stangen/staven",
   code_34941200:"Spoorrails",
   code_34941300:"Tramrails",
   code_34941500:"Pijlerbalken",
   code_34941600:"Dwarslijnen",
   code_34941800:"Spoorwissels",
   code_34942000:"Signalisatie-uitrusting",
   code_34942100:"Seinpalen",
   code_34942200:"Seinposten",
   code_34943000:"Treinenmonitorsysteem",
   code_34944000:"Wisselverwarmingssysteem",
   code_34945000:"Machines voor spoormarkering",
   code_34946000:"Materialen en benodigdheden voor aanleg van spoorlijn",
   code_34946100:"Bouwmateriaal voor aanleg van spoorlijn",
   code_34946110:"Spoorstaven",
   code_34946120:"Spoorwegmaterialen",
   code_34946121:"Lasplaten en voetplaten",
   code_34946122:"Contrarails",
   code_34946200:"Constructie-elementen voor spoorwegen",
   code_34946210:"Stroomrails",
   code_34946220:"Wisseltongen, hartstukken, wisseltrekstangen en kruisstukken",
   code_34946221:"Wisseltongen",
   code_34946222:"Kruiswissels",
   code_34946223:"Wisselstangen",
   code_34946224:"Kruisstukken",
   code_34946230:"Klemplaten, grondplaten en bielzen",
   code_34946231:"Klemplaten",
   code_34946232:"Dwarsplaten en dwarsstangen",
   code_34946240:"Railstoelen en railwiggen",
   code_34947000:"Bielzen en delen van bielzen",
   code_34947100:"Spoorbielsen",
   code_34947200:"Delen van bielzen",
   code_34950000:"Dragend materiaal",
   code_34951000:"Toegangsplatforms",
   code_34951200:"Slibbehandelingsuitrusting",
   code_34951300:"Slibdrooginstallatie",
   code_34952000:"Hydraulische hefplatforms",
   code_34953000:"Opritten",
   code_34953100:"Op- en afritten naar veerboten",
   code_34953300:"Passagiersbruggen",
   code_34954000:"Dienstbruggen",
   code_34955000:"Drijvend dok",
   code_34955100:"Drijvende opslageenheid",
   code_34960000:"Luchthavenuitrusting",
   code_34961000:"Bagageafhandelingssysteem",
   code_34961100:"Bagageafhandelingsuitrusting",
   code_34962000:"Uitrusting voor luchtverkeersleiding",
   code_34962100:"Uitrusting voor controletoren",
   code_34962200:"Luchtverkeersbegeleiding",
   code_34962210:"Simulatie voor luchtverkeersbegeleiding",
   code_34962220:"Luchtverkeersbegeleidingssystemen",
   code_34962230:"Opleiding voor luchtverkeersbegeleiding",
   code_34963000:"Instrument Landing System (ILS)",
   code_34964000:"Doppler VHF Omni direction Range (DVOR)",
   code_34965000:"Distance Measuring Equipment (afstandmeetapparatuur)",
   code_34966000:"Radiorichtingzoeker en non-directional beacon",
   code_34966100:"Radiorichtingzoeker (RDF)",
   code_34966200:"Non-directional beacon (NDB)",
   code_34967000:"Luchthaven-communicatiesysteem (COM)",
   code_34968000:"Luchthavenbewakings- en -verlichtingssysteem",
   code_34968100:"Luchthavenbewakingssysteem (SUR)",
   code_34968200:"Precisie Pad Aanvluchtindicator (PAPI)",
   code_34969000:"Instapslurven en -trappen voor vliegtuigen",
   code_34969100:"Instapslurven voor vliegtuig",
   code_34969200:"Instaptrappen voor vliegtuigen",
   code_34970000:"Verkeersbewakingsuitrusting",
   code_34971000:"Camera-uitrusting voor snelheidsmeting",
   code_34972000:"Systeem voor het meten van de verkeersstroom",
   code_34980000:"Plaatsbewijzen",
   code_34990000:"Controle-, veiligheids-, signalerings- en verlichtingsinstallaties",
   code_34991000:"Lampen voor buitenverlichting",
   code_34992000:"Verlichte en niet-verlichte signaleringsborden",
   code_34992100:"Verlichte verkeersborden",
   code_34992200:"Verkeersborden",
   code_34992300:"Straatnaamborden",
   code_34993000:"Straatverlichting",
   code_34993100:"Tunnelverlichting",
   code_34994000:"Lampen voor scheepsnavigatie en -verlichting",
   code_34994100:"Lampen voor navigatie op rivieren en verlichting",
   code_34995000:"Lampen voor vliegtuignavigatie en -verlichting",
   code_34996000:"Besturings-, beveiligings- of signalisatie-uitrusting voor wegen",
   code_34996100:"Verkeerslichten",
   code_34996200:"Besturings-, beveiligings- of signalisatie-uitrusting voor binnenwateren",
   code_34996300:"Besturings-, beveiligings- of signalisatie-uitrusting voor parkeergelegenheid",
   code_34997000:"Besturings-, beveiligings- of signalisatie-uitrusting voor luchthavens",
   code_34997100:"Vluchtrecorders",
   code_34997200:"Luchthavenverlichting",
   code_34997210:"Lampen voor start- en landingsbanen",
   code_34998000:"Besturings-, beveiligings- of signalisatie-uitrusting voor haveninstallaties",
   code_34999000:"Signaalgeneratoren, antennesignaalverdelers en galvaniseermachines",
   code_34999100:"Signaalgeneratoren",
   code_34999200:"Antennesignaalverdelers",
   code_34999300:"Galvaniseermachines",
   code_34999400:"Maquettes",
   code_34999410:"Vliegtuigmaquettes",
   code_34999420:"Scheepsmaquettes",
   code_35000000:"Uitrusting voor veiligheid, brandweer, politie en leger",
   code_35100000:"Nood- en veiligheidsuitrusting",
   code_35110000:"Uitrusting voor brandbestrijding, redding en veiligheid",
   code_35111000:"Brandblusuitrusting",
   code_35111100:"Ademhalingsapparatuur voor brandbestrijding",
   code_35111200:"Brandweermaterialen",
   code_35111300:"Brandblustoestellen",
   code_35111310:"Schuimsystemen",
   code_35111320:"Draagbare brandblusapparaten",
   code_35111400:"Uitrusting voor ontsnapping bij brand",
   code_35111500:"Brandbestrijdingssysteem",
   code_35111510:"Handgereedschap voor brandbestrijding",
   code_35111520:"Schuimblusmiddel of dergelijke preparaten",
   code_35112000:"Reddings- en hulpverleningsuitrusting",
   code_35112100:"Trainingspoppen voor hulpverlening",
   code_35112200:"Nooddouche",
   code_35112300:"Oogdouche",
   code_35113000:"Veiligheidsuitrusting",
   code_35113100:"Uitrusting voor veiligheid op de werkplaats",
   code_35113110:"Beveiligingssysteem voor kernreactor",
   code_35113200:"Uitrusting voor nucleaire, biologische, chemische en stralingsbescherming",
   code_35113210:"Uitrusting voor nucleaire veiligheid",
   code_35113300:"Veiligheidsinstallaties",
   code_35113400:"Beschermende en veiligheidskleding",
   code_35113410:"Beschermende kleding tegen biologische en chemische stoffen",
   code_35113420:"Beschermende kleding voor kernenergie en straling",
   code_35113430:"Veiligheidsvesten",
   code_35113440:"Reflecterende vesten",
   code_35113450:"Beschermende jassen of poncho's",
   code_35113460:"Beschermende sokken of ondergoed",
   code_35113470:"Beschermende overhemden of broeken",
   code_35113480:"Beschermende polsbanden",
   code_35113490:"Beschermende kiel",
   code_35120000:"Bewakings- en beveiligingssystemen en –apparatuur",
   code_35121000:"Beveiligingsuitrusting",
   code_35121100:"Zoemers",
   code_35121200:"Valsgelddetector",
   code_35121300:"Beveiligingsbenodigdheden",
   code_35121400:"Zakken met veiligheidsvoorzieningen",
   code_35121500:"Verzegelingen",
   code_35121600:"Merktekens",
   code_35121700:"Alarmsystemen",
   code_35121800:"Convexe veiligheidsspiegels",
   code_35121900:"Radardetectoren",
   code_35123000:"Uitrusting voor detectie op terrein",
   code_35123100:"Magneetkaartsysteem",
   code_35123200:"Uitrusting voor registratie van variabele werktijden",
   code_35123300:"Tijdregistratiesysteem",
   code_35123400:"Identificatiebadges",
   code_35123500:"Video-identificatiesystemen",
   code_35124000:"Metaaldetectors",
   code_35125000:"Bewakingssysteem",
   code_35125100:"Sensoren",
   code_35125110:"Biometrische sensoren",
   code_35125200:"Tijdcontrolesysteem of werktijdregistratie-apparaat",
   code_35125300:"Veiligheidscamera's",
   code_35126000:"Uitrusting voor het scannen van streepjescodes",
   code_35200000:"Uitrusting voor de politie",
   code_35210000:"Doelen voor schietbanen",
   code_35220000:"Oproerbestrijdingsuitrusting",
   code_35221000:"Waterkanonnen",
   code_35230000:"Handboeien",
   code_35240000:"Sirenes",
   code_35250000:"Hondenafweermiddelen",
   code_35260000:"Politieborden",
   code_35261000:"Informatieborden",
   code_35261100:"Matrixborden voor mededelingen",
   code_35262000:"Verkeersregulatiesystemen voor wegkruisingen",
   code_35300000:"Wapens, munitie en bijbehorende onderdelen",
   code_35310000:"Diverse wapens",
   code_35311000:"Zwaarden, kapmessen, bajonetten en lansen",
   code_35311100:"Zwaarden",
   code_35311200:"Kapmessen",
   code_35311300:"Bajonetten",
   code_35311400:"Lansen",
   code_35312000:"Gasgeweren",
   code_35320000:"Vuurwapens",
   code_35321000:"Lichte vuurwapens",
   code_35321100:"Handvuurwapens",
   code_35321200:"Geweren",
   code_35321300:"Machinegeweren",
   code_35322000:"Geschut",
   code_35322100:"Luchtdoelgeschut",
   code_35322200:"Motorartillerie",
   code_35322300:"Geschut op aanhangwagens",
   code_35322400:"Mortieren",
   code_35322500:"Houwitser",
   code_35330000:"Munitie",
   code_35331000:"Munitie voor vuurwapens en oorlogvoering",
   code_35331100:"Kogels",
   code_35331200:"Mortiergranaten",
   code_35331300:"Granaten",
   code_35331400:"Landmijnen",
   code_35331500:"Patronen",
   code_35332000:"Munitie voor oorlogvoering te water",
   code_35332100:"Torpedo's",
   code_35332200:"Zeemijnen",
   code_35333000:"Munitie voor oorlogvoering in de lucht",
   code_35333100:"Bommen",
   code_35333200:"Raketten (ruimtevaart)",
   code_35340000:"Delen van vuurwapens en munitie",
   code_35341000:"Delen van lichte vuurwapens",
   code_35341100:"Pijpfittingen van geschutbrons",
   code_35342000:"Delen van raketwerpers",
   code_35343000:"Delen van mortieren",
   code_35400000:"Militaire voertuigen en bijbehorende onderdelen",
   code_35410000:"Militaire gepantserde voertuigen",
   code_35411000:"Gevechtstanks",
   code_35411100:"Zware gevechtstanks",
   code_35411200:"Lichte gevechtstanks",
   code_35412000:"Gepantserde gevechtsvoertuigen",
   code_35412100:"Infanteriegevechtsvoertuigen",
   code_35412200:"Gepantserde manschappenvoertuigen",
   code_35412300:"Gepantserde wapenvoertuigen",
   code_35412400:"Verkennings- en patrouillevoertuigen",
   code_35412500:"Besturings- en verbindingsvoertuigen",
   code_35420000:"Delen van militaire voertuigen",
   code_35421000:"Mechanische onderdelen voor miliaire voertuigen",
   code_35421100:"Motoren en motoronderdelen voor militaire voertuigen",
   code_35422000:"Elektronische en elektrische onderdelen voor militaire voertuigen",
   code_35500000:"Oorlogsschepen en bijbehorende delen",
   code_35510000:"Oorlogsschepen",
   code_35511000:"Oppervlaktegevechtsschip",
   code_35511100:"Vliegdekschepen",
   code_35511200:"Torpedojagers en fregatten",
   code_35511300:"Corvetten en patrouilleboten",
   code_35511400:"Amfibievaartuigen en -schepen",
   code_35512000:"Onderzeeërs",
   code_35512100:"Strategische onderzeeërs, met atoomaandrijving",
   code_35512200:"Gevechtsonderzeeërs, met atoomaandrijving",
   code_35512300:"Gevechtsonderzeeërs, met dieselaandrijving",
   code_35512400:"Onbemande onderzeeërs",
   code_35513000:"Mijnenleggers en hulpschepen",
   code_35513100:"Mijnenvegers",
   code_35513200:"Reserve onderzoeksvaartuig",
   code_35513300:"Reservevaartuig voor informatieverzameling",
   code_35513400:"Reserve hospitaal-/vracht-/tanker-/roroschip",
   code_35520000:"Delen voor oorlogsschepen",
   code_35521000:"Scheepsromp en mechanische onderdelen voor oorlogsschepen",
   code_35521100:"Motoren en motoronderdelen voor oorlogsschepen",
   code_35522000:"Elektrische en elektronische onderdelen voor oorlogsschepen",
   code_35600000:"Militaire lucht- en ruimtevaartuigen en raketten",
   code_35610000:"Militaire vliegtuigen",
   code_35611100:"Gevechtsvliegtuigen",
   code_35611200:"Jachtbommenwerper/vliegtuigen voor grondaanvallen",
   code_35611300:"Bommenwerpers",
   code_35611400:"Militaire transportvliegtuigen",
   code_35611500:"Opleidingsvliegtuigen",
   code_35611600:"Zeeverkenningsvliegtuigen",
   code_35611700:"Tankervliegtuigen",
   code_35611800:"Verkenningsvliegtuigen",
   code_35612100:"Gevechtshelikopters",
   code_35612200:"Antionderzeeëroorlogshelikopters",
   code_35612300:"Ondersteuningshelikopters",
   code_35612400:"Militaire transporthelikopters",
   code_35612500:"Helikopters voor zoek- en reddingsacties",
   code_35613000:"Onbemande luchtvaartuigen",
   code_35613100:"Onbemande gevechtsluchtvaartuigen",
   code_35620000:"Raketten (wapens)",
   code_35621000:"Strategische raketten",
   code_35621100:"Strategische ABM/antiballistische raketten",
   code_35621200:"Ballistische langeafstandsaketten",
   code_35621300:"Vanaf onderzeeërs gelanceerde ballistische raketten",
   code_35621400:"Ballistische middellangeafstandsraketten",
   code_35622000:"Tactische raketten",
   code_35622100:"Lucht-luchtraketten",
   code_35622200:"Lucht-grondraketten",
   code_35622300:"Antischeepsraketten",
   code_35622400:"Antionderzeeërraketten",
   code_35622500:"Tactische ABM/antiballistische raketten",
   code_35622600:"Geleide antitankraketten",
   code_35622700:"Grond-luchtraketten",
   code_35623000:"Kruisraketten",
   code_35623100:"Lucht-/Grond-/Zeegelanceerde kruisraketten",
   code_35630000:"Militaire ruimtevaartuigen",
   code_35631000:"Militaire satellieten",
   code_35631100:"Communicatiesatellieten",
   code_35631200:"Observatiesatellieten",
   code_35631300:"Navigatiesatellieten",
   code_35640000:"Delen voor militaire lucht- en ruimtevaartapparatuur",
   code_35641000:"Structuur en mechanische onderdelen voor militaire lucht- en ruimtevaartapparatuur",
   code_35641100:"Motoren en motoronderdelen voor militaire lucht- en ruimtevaartapparatuur",
   code_35642000:"Elektronische en elektrische onderdelen voor militaire lucht- en ruimtevaartapparatuur",
   code_35700000:"Militaire elektronische systemen",
   code_35710000:"Commando-, controle-, communicatie- en computersystemen",
   code_35711000:"Commando-, controle- en communicatiesystemen",
   code_35712000:"Tactische commando-, controle- en communicatiesystemen",
   code_35720000:"Inlichtingen, bewaking, doelopsporing en verkenning/ISTAR",
   code_35721000:"Elektronisch inlichtingensysteem",
   code_35722000:"Radar",
   code_35723000:"Luchtafweerradar",
   code_35730000:"Elektronische oorlogsvoeringssystemen en tegenmaatregelen",
   code_35740000:"Veldslagsimulatoren",
   code_35800000:"Persoonlijke en ondersteunende uitrusting",
   code_35810000:"Persoonlijke uitrusting",
   code_35811100:"Brandweeruniformen",
   code_35811200:"Politie-uniformen",
   code_35811300:"Militaire uniformen",
   code_35812000:"Gevechtsuniformen",
   code_35812100:"Camouflagejassen",
   code_35812200:"Gevechtspakken",
   code_35812300:"Gevechtsuitrusting",
   code_35813000:"Helmen voor militairen",
   code_35813100:"Helmovertrekken",
   code_35814000:"Gasmaskers",
   code_35815000:"Kogelwerende kleding",
   code_35815100:"Kogelvrije vesten",
   code_35820000:"Ondersteunende uitrusting",
   code_35821000:"Vlaggen",
   code_35821100:"Vlaggenmast",
   code_37000000:"Muziekinstrumenten, sportartikelen, spelletjes, speelgoed, handwerk, kunstartikelen en toebehoren",
   code_37300000:"Muziekinstrumenten en onderdelen daarvan",
   code_37310000:"Muziekinstrumenten",
   code_37311000:"Toetsinstrumenten",
   code_37311100:"Piano's",
   code_37311200:"Accordeons",
   code_37311300:"Orgels",
   code_37311400:"Celesta's",
   code_37312000:"Koperinstrumenten",
   code_37312100:"Trompetten",
   code_37312200:"Trombones",
   code_37312300:"Sousafoons",
   code_37312400:"Saxofoons",
   code_37312500:"Fluitje",
   code_37312600:"Bugels",
   code_37312700:"Saxhoorns",
   code_37312800:"Mellofoons",
   code_37312900:"Alt-, bariton-, vleugel- en Franse hoorns",
   code_37312910:"Althoorns",
   code_37312920:"Baritonhoorns",
   code_37312930:"Vleugelhoorns",
   code_37312940:"Franse hoorns",
   code_37313000:"Snaarinstrumenten",
   code_37313100:"Klavecimbels",
   code_37313200:"Clavichords",
   code_37313300:"Gitaren",
   code_37313400:"Violen",
   code_37313500:"Harpen",
   code_37313600:"Banjo's",
   code_37313700:"Mandolines",
   code_37313800:"Cello's",
   code_37313900:"Bassen",
   code_37314000:"Blaasinstrumenten",
   code_37314100:"Klarinetten",
   code_37314200:"Hobo's",
   code_37314300:"Kornetten en fluiten",
   code_37314310:"Kornetten",
   code_37314320:"Fluiten",
   code_37314400:"Piccolo's",
   code_37314500:"Doedelzakken",
   code_37314600:"Harmonica's",
   code_37314700:"Kazoo's",
   code_37314800:"Engelse hoorns",
   code_37314900:"Ocarina's",
   code_37315000:"Muziekinstrumenten met elektrische versterkers",
   code_37315100:"Synthesizers",
   code_37316000:"Slaginstrumenten",
   code_37316100:"Pauken",
   code_37316200:"Klokken (muziekinstrument)",
   code_37316300:"Tamboerijnen",
   code_37316400:"Castagnetten",
   code_37316500:"Drums (muziekinstrument)",
   code_37316600:"Xylofoons",
   code_37316700:"Vibrafoons",
   code_37320000:"Onderdelen en toebehoren van muziekinstrumenten",
   code_37321000:"Toebehoren van muziekinstrumenten",
   code_37321100:"Metronomen",
   code_37321200:"Rieten mondstukken",
   code_37321300:"Toebehoren voor snaarinstrumenten",
   code_37321400:"Snaren of plectra voor instrumenten",
   code_37321500:"Toebehoren voor slaginstrumenten",
   code_37321600:"Foedralen of kisten of toebehoren voor muziekinstrumenten",
   code_37321700:"Instrumentenstandaards of muziekstandaards",
   code_37322000:"Delen van muziekinstrumenten",
   code_37322100:"Stempinnen",
   code_37322200:"Speeldozen of mechanismen",
   code_37322300:"Mondstukken",
   code_37322400:"Sourdines",
   code_37322500:"Stemschroeven",
   code_37322600:"Dirigeerstokjes",
   code_37322700:"Piccolopolsters",
   code_37400000:"Sportartikelen en -uitrusting",
   code_37410000:"Buitensportuitrusting",
   code_37411000:"Winteruitrusting",
   code_37411100:"Ski- en snowboarduitrusting",
   code_37411110:"Skischoenen",
   code_37411120:"Ski's",
   code_37411130:"Skistokken",
   code_37411140:"Skibindingen",
   code_37411150:"Snowboards",
   code_37411160:"Skikleding",
   code_37411200:"Uitrusting voor schaatsen en ijshockey",
   code_37411210:"Hockeypucks",
   code_37411220:"Schaatsen",
   code_37411230:"Hockeysticks",
   code_37411300:"Poolkleding en -uitrusting",
   code_37412000:"Watersportuitrusting",
   code_37412100:"Waterski's",
   code_37412200:"Duik- en snorkeluitrusting",
   code_37412210:"Trimvesten",
   code_37412220:"Persluchttanks voor duikers",
   code_37412230:"Regelaars voor duikers",
   code_37412240:"Duikinstrumenten of toebehoren",
   code_37412241:"Ademhalingsapparatuur voor duikers",
   code_37412242:"Duikkleding",
   code_37412243:"Onderwaterpakken",
   code_37412250:"Maskers, flippers of snorkels",
   code_37412260:"Natte duikpakken",
   code_37412270:"Droge duikpakken",
   code_37412300:"Surf- en zwemuitrusting en toebehoren",
   code_37412310:"Wakeboards, kneeboards of boogieboards",
   code_37412320:"Uitrusting voor windsurfen",
   code_37412330:"Surfplanken",
   code_37412340:"Zwembrillen of flippers",
   code_37412350:"Uitrusting voor parasailing",
   code_37413000:"Artikelen voor de jacht of hengelsport",
   code_37413100:"Vistuig",
   code_37413110:"Hengelstokken",
   code_37413120:"Vislijn",
   code_37413130:"Hengelmolens",
   code_37413140:"Kunstaas",
   code_37413150:"Visaas",
   code_37413160:"Gewichten of zinklood",
   code_37413200:"Producten voor de jacht",
   code_37413210:"Dierenroepfluitjes",
   code_37413220:"Lokvogels (jacht)",
   code_37413230:"Werpmachines (sport)",
   code_37413240:"Geweerlopen",
   code_37414000:"Kampeerartikelen",
   code_37414100:"Slaapmatjes",
   code_37414200:"IJskisten",
   code_37414300:"Reparatiekits voor tenten",
   code_37414600:"Kooktoestellen voor camping of buitensport",
   code_37414700:"Drankkoelers",
   code_37414800:"Overlevingspakken",
   code_37415000:"Atletiekuitrusting",
   code_37416000:"Vrijetijdsuitrusting",
   code_37420000:"Uitrusting voor gymnastiekzaal",
   code_37421000:"Gymmatten",
   code_37422000:"Gymnastiekrekken of -balken",
   code_37422100:"Gymrekken",
   code_37422200:"Gymbalken",
   code_37423000:"Touwen of ringen of klimtoestellen (gymnastiek)",
   code_37423100:"Touwen (gymnastiek)",
   code_37423200:"Ringen (gymnastiek)",
   code_37423300:"Klimtoestellen (gymnastiek)",
   code_37424000:"Springtoestellen (gymnastiek)",
   code_37425000:"Gymnastiektrampolines",
   code_37426000:"Evenwichttoestellen",
   code_37430000:"Boksuitrusting",
   code_37431000:"Boksringen",
   code_37432000:"Bokszakken",
   code_37433000:"Bokshandschoenen",
   code_37440000:"Fitnessuitrusting",
   code_37441000:"Aerobicstrainingsapparaten",
   code_37441100:"Loopbanden",
   code_37441200:"Steppers",
   code_37441300:"Hometrainers",
   code_37441400:"Roeitrainers",
   code_37441500:"Springtouwen",
   code_37441600:"Oefentrampolines",
   code_37441700:"Gymballen",
   code_37441800:"Aerobics stepapparaten",
   code_37441900:"Crosstrainingsapparaten",
   code_37442000:"Trainingsapparaten met gewichten en veren",
   code_37442100:"Handhalters",
   code_37442200:"Lange halters",
   code_37442300:"Trainingsapparaten voor onder- en bovenlichaam",
   code_37442310:"Trainingsapparaten voor onderlichaam",
   code_37442320:"Trainingsapparaten voor bovenlichaam",
   code_37442400:"Halterbanken of -rekken",
   code_37442500:"Fitnessgewichten",
   code_37442600:"Pilatesapparaten",
   code_37442700:"Gripversterker",
   code_37442800:"Trekveren en buigveren",
   code_37442810:"Trekveren",
   code_37442820:"Buigveren",
   code_37442900:"Multigymapparaten",
   code_37450000:"Uitrusting voor veld- en baansporten",
   code_37451000:"Uitrusting voor veldsporten",
   code_37451100:"Honkballen",
   code_37451110:"Vangschermen of omheiningen voor honkbal",
   code_37451120:"Honkbalhonken",
   code_37451130:"Honkbalknuppels",
   code_37451140:"Slaghulpmiddelen voor honkbal",
   code_37451150:"Honkbalhandschoenen",
   code_37451160:"Beschermende uitrusting voor honkbal of softbal",
   code_37451200:"Veldhockeyuitrusting",
   code_37451210:"Veldhockeyballen",
   code_37451220:"Veldhockeysticks",
   code_37451300:"Ballen voor American football",
   code_37451310:"Blocking sleds voor American football",
   code_37451320:"Kicking tees voor American football",
   code_37451330:"Tackling dummies voor American football",
   code_37451340:"American football vlag-uitrusting",
   code_37451400:"Lacrosseballen",
   code_37451500:"Lacrossesticks",
   code_37451600:"Ballenkanon",
   code_37451700:"Voetballen",
   code_37451710:"Markeerapparatuur voor voetbalveld",
   code_37451720:"Beschermende uitrusting voor voetbal",
   code_37451730:"Trainingshulpmiddelen voor voetbal",
   code_37451800:"Softballen",
   code_37451810:"Softbalknuppels",
   code_37451820:"Softbalhandschoenen",
   code_37451900:"Handbaluitrusting",
   code_37451920:"Schoolsets voor teamhandbal",
   code_37452000:"Uitrusting voor slag- en baansporten",
   code_37452100:"Badmintonuitrusting",
   code_37452110:"Pluimpjes of shuttles voor badminton",
   code_37452120:"Badmintonrackets",
   code_37452200:"Basketballen",
   code_37452210:"Complete spelsets voor basketbal",
   code_37452300:"Beschermende uitrusting voor zaalhockey",
   code_37452400:"Ballen, handgrepen en bespanning voor racketball",
   code_37452410:"Racketball-ballen",
   code_37452420:"Handgrepen voor racketball",
   code_37452430:"Bespanning voor racketball",
   code_37452500:"Rackets voor racketball",
   code_37452600:"Squashuitrusting",
   code_37452610:"Squashballen",
   code_37452620:"Squashrackets",
   code_37452700:"Tennisuitrusting",
   code_37452710:"Tennisballen",
   code_37452720:"Uitrusting voor tennisbaan",
   code_37452730:"Tennisrackets",
   code_37452740:"Trainingshulpmiddelen voor tennis",
   code_37452800:"Tetherballen en palen",
   code_37452810:"Tetherballen",
   code_37452820:"Tetherbalpalen",
   code_37452900:"Volleyballen",
   code_37452910:"Volleybalstandaards",
   code_37452920:"Opbergelement voor volleyballen of netten",
   code_37453000:"Uitrusting voor sintelbaansporten",
   code_37453100:"Werpsperen",
   code_37453200:"Latten voor hoogspringen",
   code_37453300:"Werpdiscussen",
   code_37453400:"Kogels voor kogelstoten",
   code_37453500:"Polsstokken",
   code_37453600:"Horden",
   code_37453700:"Estafettestokjes",
   code_37460000:"Mik- en tafelspelen en uitrusting daarvoor",
   code_37461000:"Tafelspelen en uitrusting daarvoor",
   code_37461100:"Tafels of toebehoren voor luchthockey",
   code_37461200:"Balletjes voor tafelvoetbal",
   code_37461210:"Poppen voor tafelvoetbal",
   code_37461220:"Voetbaltafels",
   code_37461300:"Poolkeuen",
   code_37461400:"Shuffelborduitrusting",
   code_37461500:"Tafeltennistafels",
   code_37461510:"Pingpongballen",
   code_37461520:"Tafeltennisbatjes",
   code_37462000:"Mikspelen en uitrusting daarvoor",
   code_37462100:"Uitrusting voor boogschieten",
   code_37462110:"Armbeschermers voor boogschieten",
   code_37462120:"Pijlen voor boogschieten",
   code_37462130:"Vangelementen voor boogschieten",
   code_37462140:"Boogpezen voor boogschieten",
   code_37462150:"Bogen voor boogschieten",
   code_37462160:"Handschoenen voor boogschieten",
   code_37462170:"Doelstandaards voor boogschieten",
   code_37462180:"Doelen voor boogschieten",
   code_37462200:"Dartpijlen",
   code_37462210:"Dartborden",
   code_37462300:"Doelen voor werpsport",
   code_37462400:"Uitrusting voor kleiduiven schieten",
   code_37470000:"Uitrusting voor golf en bowling",
   code_37471000:"Uitrusting voor golf",
   code_37471100:"Golftassen",
   code_37471200:"Golfballen",
   code_37471300:"Golfclubs",
   code_37471400:"Golftee's",
   code_37471500:"Golfclubhoezen",
   code_37471600:"Golfhandschoenen",
   code_37471700:"Fixeermiddelen voor losgeslagen graszode",
   code_37471800:"Golfscopes",
   code_37471900:"Put-trainer",
   code_37472000:"Bowlinguitrusting",
   code_37480000:"Machines of apparatuur voor vrijetijdsuitrusting",
   code_37481000:"Kunstijsmachines",
   code_37482000:"Billboards voor sportinformatie",
   code_37500000:"Spellen en speelgoed; kermisattracties",
   code_37510000:"Poppen",
   code_37511000:"Poppenhuizen",
   code_37512000:"Delen of toebehoren voor poppen",
   code_37513000:"Marionetten",
   code_37513100:"Poppentheaters",
   code_37520000:"Speelgoed",
   code_37521000:"Speelgoedmuziekinstrumenten",
   code_37522000:"Speelgoed met wielen",
   code_37523000:"Puzzels",
   code_37524000:"Spellen",
   code_37524100:"Educatieve spelen",
   code_37524200:"Bordspelen",
   code_37524300:"Traditionele spelen",
   code_37524400:"Gezelschapsspelen",
   code_37524500:"Strategische spelen",
   code_37524600:"Geheugenspelen",
   code_37524700:"Toebehoren voor spelen",
   code_37524800:"Lottospelen",
   code_37524810:"Loterijbriefjes",
   code_37524900:"Spellensets",
   code_37525000:"Speelgoedballonnen en ballen",
   code_37526000:"Speelgoedemmertjes",
   code_37527000:"Speelgoedtreintjes en -autootjes",
   code_37527100:"Speelgoedtreintjes",
   code_37527200:"Speelgoedautootjes",
   code_37528000:"Speelgoedwapens",
   code_37529000:"Opblaasbaar speelgoed en speelgoedpaardjes e.d.",
   code_37529100:"Opblaasbaar speelgoed",
   code_37529200:"Speelgoedpaardjes e.d.",
   code_37530000:"Artikelen voor pretpark-, bord- of gezelschapsspelen",
   code_37531000:"Speelkaarten",
   code_37532000:"Videospelletjes",
   code_37533000:"Biljarten",
   code_37533100:"Biljartballen",
   code_37533200:"Biljartkrijt",
   code_37533300:"Biljartkeutips",
   code_37533400:"Ballendriehoeken",
   code_37533500:"Biljarttafels",
   code_37534000:"Spellen met munten of fiches",
   code_37535000:"Draaimolens, schommels, schiettenten en andere kermisattracties",
   code_37535100:"Schommels",
   code_37535200:"Speeltuinuitrusting",
   code_37535210:"Schommels voor speelplaatsen",
   code_37535220:"Klimrekken voor speelplaatsen",
   code_37535230:"Draaimolens voor speelplaatsen",
   code_37535240:"Glijbanen voor speelplaatsen",
   code_37535250:"Wippen voor speelplaatsen",
   code_37535260:"Tunnels voor speelplaatsen",
   code_37535270:"Zandbakken voor speelplaatsen",
   code_37535280:"Open tribunes voor speelplaatsen",
   code_37535290:"Uitrusting voor wand- en touwklimmen",
   code_37535291:"Uitrusting voor wandklimmen",
   code_37535292:"Uitrusting voor touwklimmen",
   code_37540000:"Gokmachines",
   code_37800000:"Handwerk- en kunstbenodigdheden",
   code_37810000:"Handwerkbenodigdheden",
   code_37820000:"Kunstbenodigdheden",
   code_37821000:"Schilderspenselen",
   code_37822000:"Tekenpennen",
   code_37822100:"Kleurpotloden",
   code_37822200:"Houtskoolstiften",
   code_37822300:"Krijtjes",
   code_37822400:"Pastelstiften",
   code_37823000:"Vetvrij papier en andere papierwaren",
   code_37823100:"Vetvrij papier",
   code_37823200:"Calqueerpapier",
   code_37823300:"Pergamijn",
   code_37823400:"Doorzichtig of doorschijnend papier",
   code_37823500:"Kunstdrukpapier",
   code_37823600:"Tekenpapier",
   code_37823700:"Cartografisch papier",
   code_37823800:"Meerlagig papier en karton",
   code_37823900:"Kraftliner",
   code_38000000:"Laboratoriuminstrumenten, optische en precisie-instrumenten (uitgezonderd brillen)",
   code_38100000:"Instrumenten voor navigatie en meteorologie",
   code_38110000:"Navigatie-instrumenten",
   code_38111000:"Peilingsuitrusting",
   code_38111100:"Kompassen",
   code_38111110:"Kompastoebehoren",
   code_38112000:"Sextanten",
   code_38112100:"Wereldwijd navigatie- en plaatsbepalingssysteem (gps of dergelijke)",
   code_38113000:"Sonars",
   code_38114000:"Echopeilers",
   code_38115000:"Radarapparatuur",
   code_38115100:"Radarbewakingsuitrusting",
   code_38120000:"Instrumenten voor de meteorologie",
   code_38121000:"Windmeters",
   code_38122000:"Barometers",
   code_38123000:"Apparaten voor het registreren van neerslag of verdamping",
   code_38124000:"Radiopeilapparatuur",
   code_38125000:"Regenmeters",
   code_38126000:"Oppervlakte-observatieapparatuur",
   code_38126100:"Apparatuur voor oppervlakte-observatie van neerslag of verdamping",
   code_38126200:"Apparatuur voor oppervlakte-observatie van zonnestraling",
   code_38126300:"Apparatuur voor oppervlakte-observatie van temperatuur of vochtigheid",
   code_38126400:"Oppervlakte-observatieapparatuur voor wind",
   code_38127000:"Weerstations",
   code_38128000:"Toebehoren voor meteorologische instrumenten",
   code_38200000:"Geologische en geofysische instrumenten",
   code_38210000:"Geologische kompassen",
   code_38220000:"Geologische prospectie-apparatuur",
   code_38221000:"Geografische informatiesystemen (GIS of dergelijke)",
   code_38230000:"Elektromagnetische geofysische instrumenten",
   code_38240000:"Geofysische instrumenten voor zwaartekracht",
   code_38250000:"Geofysische instrumenten voor inductiepolarisatie (IP)",
   code_38260000:"Geofysische instrumenten — magnetometer",
   code_38270000:"Geofysische instrumenten — soortelijke weerstand",
   code_38280000:"Gravimeters",
   code_38290000:"Instrumenten en apparaten voor landmeetkunde, hydrografie, oceanografie en hydrologie",
   code_38291000:"Telemetrieapparatuur",
   code_38292000:"Instrumenten voor de hydrografie",
   code_38293000:"Seismische uitrusting",
   code_38294000:"Theodolieten",
   code_38295000:"Topografische uitrusting",
   code_38296000:"Instrumenten voor de landmeetkunde",
   code_38300000:"Instrumenten voor metingen",
   code_38310000:"Precisieweegschalen",
   code_38311000:"Elektronische weegschalen en toebehoren",
   code_38311100:"Elektronisch-analytische balansen",
   code_38311200:"Elektronisch-technische balansen",
   code_38311210:"Kalibratiegewichten",
   code_38320000:"Tekentafels",
   code_38321000:"Tekenmachines",
   code_38322000:"Pantografen",
   code_38323000:"Rekenlinialen",
   code_38330000:"Handinstrumenten voor lengtemeting",
   code_38331000:"Rasters",
   code_38340000:"Instrumenten voor het meten van hoeveelheden",
   code_38341000:"Apparatuur voor het meten van straling",
   code_38341100:"Elektronenstraalrecorders",
   code_38341200:"Stralingsmeters",
   code_38341300:"Instrumenten voor het meten van elektrische hoeveelheden",
   code_38341310:"Ampèremeters",
   code_38341320:"Voltmeters",
   code_38341400:"Geigertellers",
   code_38341500:"Apparatuur voor het opsporen van besmettingen",
   code_38341600:"Stralingsmonitoren",
   code_38342000:"Oscilloscopen",
   code_38342100:"Oscillografen",
   code_38343000:"Foutencontrole-uitrusting",
   code_38344000:"Apparatuur voor het opsporen van vervuiling",
   code_38400000:"Instrumenten voor het controleren van fysische eigenschappen",
   code_38410000:"Meetinstrumenten",
   code_38411000:"Hydrometers",
   code_38412000:"Thermometers",
   code_38413000:"Pyrometers",
   code_38414000:"Hygrometers",
   code_38415000:"Psychrometers",
   code_38416000:"PH-meters",
   code_38417000:"Thermokoppels",
   code_38418000:"Calorimeters",
   code_38420000:"Instrumenten voor het meten van debiet, peil en druk van vloeistoffen en gassen",
   code_38421000:"Debietmeetuitrusting",
   code_38421100:"Watermeters",
   code_38421110:"Stroommeters",
   code_38422000:"Peilmetingstoestellen",
   code_38423000:"Drukmeters",
   code_38423100:"Drukmeters",
   code_38424000:"Meet- en controle-uitrusting",
   code_38425000:"Apparatuur voor vloeistofmechanica",
   code_38425100:"Manometers",
   code_38425200:"Viscosimeters",
   code_38425300:"Diepte-indicatoren",
   code_38425400:"Apparatuur voor structuurbeoordeling",
   code_38425500:"Apparatuur voor sterktebeoordeling",
   code_38425600:"Pyknometers",
   code_38425700:"Instrumenten voor het meten van oppervlaktespanning",
   code_38425800:"Densitometers",
   code_38426000:"Coulometers",
   code_38427000:"Fluxmeters",
   code_38428000:"Reometers",
   code_38429000:"Rotameters",
   code_38430000:"Detectie- en analyseapparatuur",
   code_38431000:"Detectieapparatuur",
   code_38431100:"Gasdetectieapparatuur",
   code_38431200:"Rookmelders",
   code_38431300:"Foutdetectors",
   code_38432000:"Analyseapparatuur",
   code_38432100:"Gasanalyseapparatuur",
   code_38432200:"Chromatografen",
   code_38432210:"Gaschromatografen",
   code_38432300:"Rookanalyseapparatuur",
   code_38433000:"Spectrometers",
   code_38433100:"Massaspectrometers",
   code_38433200:"Uitrusting voor emissiemeting",
   code_38433210:"Emissiespectrometers",
   code_38433300:"Spectrumanalysatoren",
   code_38434000:"Analysatoren",
   code_38434100:"Expansieanalysatoren",
   code_38434200:"Uitrusting voor geluidsmeting",
   code_38434210:"Sonometers",
   code_38434220:"Apparaten voor geluidssnelheidsanalyse",
   code_38434300:"Uitrusting voor het meten van geluidshinder",
   code_38434310:"Decibelmeter",
   code_38434400:"Vibratieanalysatoren",
   code_38434500:"Biochemische analysatoren",
   code_38434510:"Cytometers",
   code_38434520:"Bloedanalysatoren",
   code_38434530:"Melkanalysatoren",
   code_38434540:"Biomedische uitrusting",
   code_38434550:"Bloedcellentellers",
   code_38434560:"Chemieanalysatoren",
   code_38434570:"Hematologieanalysatoren",
   code_38434580:"Immunoassay-analysatoren",
   code_38435000:"Apparatuur voor het detecteren van vloeistoffen",
   code_38436000:"Schudapparaten en toebehoren",
   code_38436100:"Schudapparaten",
   code_38436110:"Erlenmeyerhouder voor schudapparaten",
   code_38436120:"Erlenmeyerklemmen voor schudapparaten",
   code_38436130:"Houders voor scheitrechters",
   code_38436140:"Plateau voor erlenmeyerklemmen voor schudapparaten",
   code_38436150:"Petrischaalhouder voor schudapparaten",
   code_38436160:"Testbuishouder voor schudapparaten",
   code_38436170:"Kolfadapter voor schudapparaten",
   code_38436200:"Rotatieverdampers",
   code_38436210:"Beschermplaat voor rotatieverdampers",
   code_38436220:"Kooktemperatuursensor voor rotatieverdampers",
   code_38436230:"Drukregelaar voor rotatieverdampers",
   code_38436300:"Schudincubatoren",
   code_38436310:"Verwarmingsplaten",
   code_38436320:"Verwarmingsplaten voor kolven",
   code_38436400:"Magnetische schudapparaten",
   code_38436410:"Warmteregulatoren voor mechanische schudapparaten met verwarmingsplaten",
   code_38436500:"Mechanische roerapparaten",
   code_38436510:"Schoepen voor mechanische roerapparaten",
   code_38436600:"Immersie-homogenisatoren",
   code_38436610:"Dispergeer-apparatuur voor immersie-homogenisatoren",
   code_38436700:"Ultrasone desintegratoren",
   code_38436710:"Sondes voor ultrasone desintegrators",
   code_38436720:"Converters voor ultrasone desintegrators",
   code_38436730:"Continustroom-kamers voor ultrasone desintegrators",
   code_38436800:"Homogenisatoren met roterende bladen",
   code_38437000:"Laboratoriumpipetten en toebehoren",
   code_38437100:"Pipetten",
   code_38437110:"Pipet-tips",
   code_38437120:"Pipettenstandaards",
   code_38500000:"Controle- en testapparatuur",
   code_38510000:"Microscopen",
   code_38511000:"Elektronenmicroscopen",
   code_38511100:"Aftastingselektronenmicroscopen",
   code_38511200:"Transmissie-elektronenmicroscoop",
   code_38512000:"Ionen- en moleculaire microscopen",
   code_38512100:"Ionenmicroscopen",
   code_38512200:"Monoculaire microscopen",
   code_38513000:"Inversie- en metallurgische microscopen",
   code_38513100:"Inversiemicroscopen",
   code_38513200:"Metallurgische microscopen",
   code_38514000:"Donkerveld- en rastersondemicroscopen",
   code_38514100:"Donkerveld-microscopen",
   code_38514200:"Rastersondemicroscopen (SPM)",
   code_38515000:"Fluorescerende en polarisatiemiscroscopen",
   code_38515100:"Polarisatiemicroscopen",
   code_38515200:"Fluorescentiemicroscopen",
   code_38516000:"Binoculaire lichtcompound-microscopen",
   code_38517000:"Akoestische en projectiemicroscopen",
   code_38517100:"Akoestische microscopen",
   code_38517200:"Projectiemicroscopen",
   code_38518000:"Breedveld-, stereo- of microdissectiemicroscopen",
   code_38518100:"Breedveldmicroscopen",
   code_38518200:"Stereo- of microdissectiemicroscopen",
   code_38519000:"Diverse toebehoren voor microscopen",
   code_38519100:"Belichters voor microscopen",
   code_38519200:"Microscoopobjectieven",
   code_38519300:"Foto- of video-opzetstukken voor microscopen",
   code_38519310:"Foto-opzetstukken voor microscopen",
   code_38519320:"Video-opzetstukken voor microscopen",
   code_38519400:"Standaards voor computergestuurde microscoop",
   code_38519500:"Reservelampen voor laboratoriummicroscopen",
   code_38519600:"Oculairs, condensors, collectors, buizen, standaards en afdekhoezen voor microscoop",
   code_38519610:"Oculairs voor microscoop",
   code_38519620:"Condensers voor microscoop",
   code_38519630:"Collectors voor microscoop",
   code_38519640:"Buizen voor microscoop",
   code_38519650:"Standaards voor microscoop",
   code_38519660:"Afdekhoezen voor microscoop",
   code_38520000:"Scanners",
   code_38521000:"Drukscanners",
   code_38522000:"Chromatografische scanners",
   code_38527100:"Dosimeters voor ionisatiekamers",
   code_38527200:"Dosimeters",
   code_38527300:"Secundaire standaard dosimetersystemen",
   code_38527400:"Fantoomdosimeters",
   code_38530000:"Diffractieapparatuur",
   code_38540000:"Machines en toestellen voor testen en meten",
   code_38541000:"Apparatuur voor het testen van de soldeerbaarheid",
   code_38542000:"Servo-hydraulische testapparatuur",
   code_38543000:"Gasdetectie-uitrusting",
   code_38544000:"Drugsopsporingsapparatuur",
   code_38545000:"Gasanalysesets",
   code_38546000:"Explosievendetectiesysteem",
   code_38546100:"Bomdetectoren",
   code_38547000:"Dosimetriesysteem",
   code_38548000:"Instrumenten voor voertuigen",
   code_38550000:"Meters",
   code_38551000:"Energiemeters",
   code_38552000:"Elektronische meters",
   code_38553000:"Magnetometers",
   code_38554000:"Elektriciteitsmeters",
   code_38560000:"Productietellers",
   code_38561000:"Toerentellers",
   code_38561100:"Snelheidsmeters voor voertuigen",
   code_38561110:"Tachometers",
   code_38561120:"Taximeters",
   code_38562000:"Stroboscopen",
   code_38570000:"Instrumenten en apparatuur voor regeling en besturing",
   code_38571000:"Snelheidsbegrenzers",
   code_38580000:"Niet-medische stralingsapparatuur",
   code_38581000:"Bagagescanapparatuur",
   code_38582000:"Röntgencontroleapparatuur",
   code_38600000:"Optische instrumenten",
   code_38620000:"Polariserend materiaal",
   code_38621000:"Glasvezelapparatuur",
   code_38622000:"Spiegels",
   code_38623000:"Optische filters",
   code_38624000:"Optische hulpmiddelen",
   code_38630000:"Astronomische en optische instrumenten",
   code_38631000:"Verrekijkers",
   code_38632000:"Nachtkijkers",
   code_38633000:"Telescopische kijkers",
   code_38634000:"Optische microscopen",
   code_38635000:"Telescopen",
   code_38636000:"Speciale optische instrumenten",
   code_38636100:"Lasers",
   code_38636110:"Industriële lasers",
   code_38640000:"Lcd-toestellen",
   code_38641000:"Periscopen",
   code_38650000:"Foto-uitrusting",
   code_38651000:"Camera's",
   code_38651100:"Cameralenzen",
   code_38651200:"Camerabody's",
   code_38651300:"Camera's voor het vervaardigen van clichés of van drukcilinders",
   code_38651400:"Fototoestellen voor directklaarfotografie",
   code_38651500:"Filmcamera's",
   code_38651600:"Digitale fototoestellen",
   code_38652000:"Filmprojectoren",
   code_38652100:"Projectoren",
   code_38652110:"Diaprojectoren",
   code_38652120:"Videoprojectietoestellen",
   code_38652200:"Vergrotingstoestellen",
   code_38652300:"Reduceertoestellen",
   code_38653000:"Apparatuur voor fotolaboratoria",
   code_38653100:"Flitsers",
   code_38653110:"Flitslampjes",
   code_38653111:"Flitsblokjes",
   code_38653200:"Vergrotingsapparaten voor de fotografie",
   code_38653300:"Apparaten en uitrusting voor het ontwikkelen van films",
   code_38653400:"Projectieschermen",
   code_38654000:"Apparatuur voor microfilm en microfiches",
   code_38654100:"Microfilmuitrusting",
   code_38654110:"Microfilmleesapparaten",
   code_38654200:"Microfiche-uitrusting",
   code_38654210:"Microfichelezers",
   code_38654300:"Microvormuitrusting",
   code_38654310:"Microvormleesapparaten",
   code_38700000:"Tijdregistratietoestellen en soortgelijke apparatuur; parkeermeters",
   code_38710000:"Tijdregistratieapparatuur",
   code_38720000:"Stempelklokken",
   code_38730000:"Parkeermeters",
   code_38731000:"Muntentellers",
   code_38740000:"Timers",
   code_38750000:"Tijdschakelaars",
   code_38800000:"Apparaten voor de bewaking van industriële processen en voor afstandsbediening",
   code_38810000:"Uitrusting voor industriële procesregeling",
   code_38820000:"Afstandsbedieningsuitrusting",
   code_38821000:"Radioafstandsbedieningsapparatuur",
   code_38822000:"Sirenetoestellen met afstandsbediening",
   code_38900000:"Diverse apparatuur voor beoordeling en testen",
   code_38910000:"Apparatuur voor het beoordelen en testen van hygiëne",
   code_38911000:"Kits voor manuele swabtests",
   code_38912000:"Kits voor geautomatiseerde swabtests",
   code_38920000:"Apparatuur voor zaden en diervoeders",
   code_38921000:"Analyseapparaten voor zaadkorrels/graankorrels",
   code_38922000:"Zadentellers",
   code_38923000:"Analyseapparaten voor diervoeders",
   code_38930000:"Instrumenten voor het meten van vochtigheid en vochtgehalte",
   code_38931000:"Temperatuur/vochtigheidstestapparaten",
   code_38932000:"Vochtigheidsmeters",
   code_38940000:"Kernevaluatie-instrumenten",
   code_38941000:"Alfadeeltjestellers",
   code_38942000:"Alfa/betadeeltjestellers",
   code_38943000:"Betadeeltjestellers",
   code_38944000:"Beta/gammadeeltjestellers",
   code_38945000:"Gammadeeltjestellers",
   code_38946000:"KVp-meters",
   code_38947000:"Microanalyseapparaten voor röntgenstralen",
   code_38950000:"Polymerasekettingreactie (PCR)-apparatuur",
   code_38951000:"Real-time polymerasekettingreactie (PCR)-apparatuur",
   code_38960000:"Alcoholslot",
   code_38970000:"Wetenschappelijk-technische simulator voor onderzoek en testen",
   code_39000000:"Meubelen (m.i.v. kantoormeubelen), inrichtingsartikelen, huishoudelijke apparaten (uitgez. verlichting) en schoonmaakproducten",
   code_39100000:"Meubilair",
   code_39110000:"Zitmeubelen, stoelen en aanverwante producten, en bijbehorende onderdelen",
   code_39111000:"Zitmeubelen",
   code_39111100:"Draaistoelen",
   code_39111200:"Theaterstoelen",
   code_39111300:"Schietstoelen",
   code_39112000:"Rechte stoelen",
   code_39112100:"Eetkamerstoelen",
   code_39113000:"Diverse zitmeubelen en stoelen",
   code_39113100:"Leunstoelen",
   code_39113200:"Canapés",
   code_39113300:"Zitbanken",
   code_39113400:"Ligstoelen",
   code_39113500:"Krukjes",
   code_39113600:"Banken",
   code_39113700:"Voetenbankjes",
   code_39114000:"Onderdelen van stoelen",
   code_39114100:"Stoffering",
   code_39120000:"Tafels, kasten, schrijftafels en boekenkasten",
   code_39121000:"Schrijftafels en tafels",
   code_39121100:"Schrijftafels",
   code_39121200:"Tafels",
   code_39122000:"Kasten en boekenkasten",
   code_39122100:"Kasten",
   code_39122200:"Boekenkasten",
   code_39130000:"Kantoormeubilair",
   code_39131000:"Kantoorrekken",
   code_39131100:"Archiefrekken",
   code_39132000:"Archiefsystemen",
   code_39132100:"Dossierkasten",
   code_39132200:"Kaartsysteemkasten",
   code_39132300:"Hangmappen",
   code_39132400:"Carrouselsystemen",
   code_39132500:"Kantoorwagentjes",
   code_39133000:"Uitstalkasten",
   code_39134000:"Computermeubilair",
   code_39134100:"Computertafels",
   code_39135000:"Sorteertafels",
   code_39135100:"Sorteerkastjes",
   code_39136000:"Kapstokken",
   code_39137000:"Waterontharders",
   code_39140000:"Huishoudmeubilair",
   code_39141000:"Keukenmeubilair en apparatuur",
   code_39141100:"Legplanken",
   code_39141200:"Werkbladen",
   code_39141300:"Legkasten",
   code_39141400:"Inbouwkeukens",
   code_39141500:"Afzuigkappen",
   code_39142000:"Tuinmeubilair",
   code_39143000:"Meubilair voor slaapkamer, eetkamer en zitkamer",
   code_39143100:"Slaapkamermeubilair",
   code_39143110:"Bedden en beddengoed en speciale woningtextiel",
   code_39143111:"Bedonderstellen",
   code_39143112:"Matrassen",
   code_39143113:"Speciale bekledingsstof",
   code_39143114:"Elektrische dekens",
   code_39143115:"Rubber lakens",
   code_39143116:"Kinderbedjes",
   code_39143120:"Meubilair voor slaapkamer, met uitzondering van bedden en beddengoed",
   code_39143121:"Hangkasten",
   code_39143122:"Ladenkasten",
   code_39143123:"Nachtkastjes",
   code_39143200:"Eetkamermeubilair",
   code_39143210:"Eettafels",
   code_39143300:"Zitkamermeubilair",
   code_39143310:"Salontafels",
   code_39144000:"Badkamermeubilair",
   code_39145000:"Elementen voor wijnkelder",
   code_39150000:"Divers meubilair en uitrusting",
   code_39151000:"Diverse meubelen",
   code_39151100:"Rekken",
   code_39151200:"Werkbanken",
   code_39151300:"Modulair meubilair",
   code_39152000:"Verrijdbare boekenrekken",
   code_39153000:"Meubilair voor conferentiezaal",
   code_39153100:"Boekenrekken",
   code_39154000:"Tentoonstellingsuitrusting",
   code_39154100:"Tentoonstellingsstands",
   code_39155000:"Bibliotheekmeubilair",
   code_39155100:"Bibliotheekuitrusting",
   code_39156000:"Meubilair voor hal en receptie",
   code_39157000:"Onderdelen van meubilair",
   code_39160000:"Schoolmeubilair",
   code_39161000:"Meubilair voor kleuterschool",
   code_39162000:"Onderwijsuitrusting",
   code_39162100:"Leermiddelen",
   code_39162110:"Schoolbenodigdheden",
   code_39162200:"Leerhulpmiddelen en -toestellen",
   code_39170000:"Winkelmeubilair",
   code_39171000:"Vitrines",
   code_39172000:"Balies",
   code_39172100:"Uitgiftebuffetten",
   code_39173000:"Magazijnunits",
   code_39174000:"Uithangborden",
   code_39180000:"Laboratoriummeubilair",
   code_39181000:"Werkbanken voor laboratorium",
   code_39190000:"Behangselpapier en andere bekleding",
   code_39191000:"Wandbekleding op papier- of kartonbasis",
   code_39191100:"Behangselpapier",
   code_39192000:"Wandbekleding van textiel",
   code_39193000:"Vloerbedekking op papier- of kartonbasis",
   code_39200000:"Meubilering",
   code_39220000:"Keukenuitrusting, huishoudelijke artikelen en cateringbenodigdheden",
   code_39221000:"Keukenuitrusting",
   code_39221100:"Keukengerei",
   code_39221110:"Vaatwerk",
   code_39221120:"Kopjes en glazen",
   code_39221121:"Kopjes",
   code_39221122:"Kleine kopjes",
   code_39221123:"Drinkglazen",
   code_39221130:"Verpakkingen voor voedingsmiddelen",
   code_39221140:"Veldflessen",
   code_39221150:"Thermosflessen",
   code_39221160:"Bakjes",
   code_39221170:"Droogrekken",
   code_39221180:"Kookgerei",
   code_39221190:"Afdruiprekken",
   code_39221200:"Tafelgerei",
   code_39221210:"Borden (servies)",
   code_39221220:"Schalen",
   code_39221230:"Schoteltjes",
   code_39221240:"Kommen",
   code_39221250:"Karaffen",
   code_39221260:"Eetketels",
   code_39222000:"Cateringbenodigdheden",
   code_39222100:"Cateringbenodigdheden voor eenmalig gebruik",
   code_39222110:"Wegwerpbestek en -borden",
   code_39222120:"Wegwerpbekers",
   code_39222200:"Dienbladen",
   code_39223000:"Lepels en vorken",
   code_39223100:"Lepels",
   code_39223200:"Vorken",
   code_39224000:"Bezems en borstels en andere diverse artikelen",
   code_39224100:"Bezems",
   code_39224200:"Borstels",
   code_39224210:"Verfkwasten",
   code_39224300:"Bezems en borstels en andere artikelen voor huishoudelijk schoonmaken",
   code_39224310:"WC-borstels",
   code_39224320:"Sponzen",
   code_39224330:"Emmers",
   code_39224340:"Afvalbakken",
   code_39224350:"Stofblikken",
   code_39225000:"Aanstekers, artikelen van brandbaar materiaal, vuurwerk, lucifers, vloeibare of vloeibaar gemaakte gasbrandstoffen",
   code_39225100:"Sigarettenaanstekers",
   code_39225200:"Pyrofore legeringen",
   code_39225300:"Lucifers",
   code_39225400:"Gasbrandstof voor aanstekers",
   code_39225500:"Pyrotechniek",
   code_39225600:"Kaarsen",
   code_39225700:"Flessen, potten en glazen flacons",
   code_39225710:"Flessen",
   code_39225720:"Glazen potten",
   code_39225730:"Glazen flacons",
   code_39226000:"Korfflessen, kratten voor flessen, kolven en haspels",
   code_39226100:"Kratten voor flessen",
   code_39226200:"Korfflessen en kolven",
   code_39226210:"Korfflessen",
   code_39226220:"Kolven",
   code_39226300:"Spoelen of haspels",
   code_39227000:"Naai- en breinaalden en vingerhoeden",
   code_39227100:"Naainaalden of breinaalden",
   code_39227110:"Naainaalden",
   code_39227120:"Breinaalden",
   code_39227200:"Vingerhoeden",
   code_39230000:"Producten voor speciale doeleinden",
   code_39234000:"Compostcontainers",
   code_39235000:"Muntjes",
   code_39236000:"Spuitcabines",
   code_39237000:"Sneeuwbakens",
   code_39240000:"Bestek",
   code_39241000:"Messen en scharen",
   code_39241100:"Messen",
   code_39241110:"Tafelmessen",
   code_39241120:"Koksmessen",
   code_39241130:"Gebruiksmessen",
   code_39241200:"Scharen",
   code_39254000:"Uurwerken",
   code_39254100:"Klokken (uurwerken)",
   code_39254110:"Wekkers",
   code_39254120:"Muurklokken",
   code_39254130:"Glas voor klokken",
   code_39260000:"Brievenbakjes en bureaubenodigdheden",
   code_39261000:"Brievenbakjes",
   code_39263000:"Bureau-uitrusting",
   code_39263100:"Schrijftafelsets",
   code_39264000:"Bundelbeugels voor losbladige banden of mappen",
   code_39265000:"Haken en ogen",
   code_39270000:"Religieuze artikelen",
   code_39290000:"Diverse meubilering",
   code_39291000:"Wasserijbenodigdheden",
   code_39292000:"Schoolleien of –borden met beschrijfbaar of tekenoppervlak, of instrumenten",
   code_39292100:"Schoolborden",
   code_39292110:"Bordenwissers voor schoolborden",
   code_39292200:"Schrijfleien",
   code_39292300:"Tekenhulpmiddelen",
   code_39292400:"Schrijfbenodigdheden",
   code_39292500:"Linialen",
   code_39293000:"Namaakartikelen",
   code_39293100:"Kunstfruit",
   code_39293200:"Kunstbloemen",
   code_39293300:"Kunstgras",
   code_39293400:"Kunstgazon",
   code_39293500:"Namaakjuwelen",
   code_39294000:"Toestellen en uitrusting voor demonstratiedoeleinden",
   code_39294100:"Informatie- en reclameproducten",
   code_39295000:"Paraplu's en parasols; wandel- en zitstokken",
   code_39295100:"Parasols",
   code_39295200:"Paraplu's",
   code_39295300:"Zitstokken",
   code_39295400:"Wandelstokken",
   code_39295500:"Onderdelen, versieringen en toebehoren van paraplu's, parasols, wandelstokken en dergelijke",
   code_39296000:"Benodigdheden voor begrafenissen",
   code_39296100:"Doodkisten",
   code_39297000:"Uitrusting voor kazernes",
   code_39298000:"Beeldjes, decoratieve voorwerpen; foto- of schilderijlijsten en spiegels",
   code_39298100:"Fotolijsten",
   code_39298200:"Schilderijlijsten",
   code_39298300:"Bloemenvazen",
   code_39298400:"Beeldjes",
   code_39298500:"Decoratieve voorwerpen",
   code_39298600:"Ballonvormig glas",
   code_39298700:"Trofeeën",
   code_39298800:"Aquariums",
   code_39298900:"Diverse decoratievoorwerpen",
   code_39298910:"Kerstboom",
   code_39299000:"Glaswerk",
   code_39299100:"Glazen ampullen",
   code_39299200:"Veiligheidsglas",
   code_39299300:"Spiegels van glas",
   code_39300000:"Diverse uitrusting",
   code_39310000:"Cateringuitrusting",
   code_39311000:"Lichte cateringuitrusting",
   code_39312000:"Uitrusting voor voedingsbereiding",
   code_39312100:"Vleessnijmachines",
   code_39312200:"Kantine-uitrusting",
   code_39313000:"Hoteluitrusting",
   code_39314000:"Industriële keukenuitrusting",
   code_39315000:"Restaurantuitrusting",
   code_39330000:"Desinfecteeruitrusting",
   code_39340000:"Uitrusting voor gasnetwerk",
   code_39341000:"Gasdrukuitrusting",
   code_39350000:"Uitrusting voor rioolzuiveringsinstallatie",
   code_39360000:"Afdichtingsuitrusting",
   code_39370000:"Waterinstallaties",
   code_39500000:"Textielwaren",
   code_39510000:"Textielwaren voor huishoudelijk gebruik",
   code_39511000:"Dekens en reisdekens",
   code_39511100:"Dekens",
   code_39511200:"Reisdekens",
   code_39512000:"Lakens en slopen",
   code_39512100:"Lakens",
   code_39512200:"Dekbedovertrekken",
   code_39512300:"Matrasovertrekken",
   code_39512400:"Donzen dekbedden",
   code_39512500:"Kussenslopen",
   code_39512600:"Peluwslopen",
   code_39513000:"Tafellinnen",
   code_39513100:"Tafellakens",
   code_39513200:"Stoffen servetten",
   code_39514000:"Badkamer- en keukenlinnen",
   code_39514100:"Handdoeken",
   code_39514200:"Theedoeken",
   code_39514300:"Rolhanddoeken",
   code_39514400:"Handdoekautomaten",
   code_39514500:"Washandjes",
   code_39515000:"Gordijnen, draperieën, valletjes en rolgordijnen van stof",
   code_39515100:"Gordijnen",
   code_39515110:"Rookwerende gordijnen",
   code_39515200:"Draperieën",
   code_39515300:"Valletjes",
   code_39515400:"Rolgordijnen",
   code_39515410:"Binnenzonwering",
   code_39515420:"Zonwering van textiel",
   code_39515430:"Jaloezieën",
   code_39515440:"Lamellengordijnen",
   code_39516000:"Meubileringsartikelen",
   code_39516100:"Woningtextiel",
   code_39516110:"Kussens",
   code_39516120:"Hoofdkussens",
   code_39518000:"Ziekenhuislinnengoed",
   code_39518100:"Doeken voor operatiekamer",
   code_39518200:"Operatielakens",
   code_39520000:"Textielfabrikaten",
   code_39522000:"Tarpaulins, zeilen voor schepen, surfplanken of zeilwagens, dekzeilen, zonweringen, tenten en kampeerartikelen",
   code_39522100:"Tarpaulin, dekzeilen en zonweringen",
   code_39522110:"Tarpaulin",
   code_39522120:"Dekzeilen",
   code_39522130:"Zonweringen",
   code_39522200:"Camouflagehoezen",
   code_39522400:"Zeilen",
   code_39522500:"Kampeerartikelen van textiel",
   code_39522510:"Luchtmatrassen",
   code_39522520:"Veldbedden",
   code_39522530:"Tenten",
   code_39522540:"Slaapzakken",
   code_39522541:"Slaapzakken met veren- of donsvulling",
   code_39523000:"Parachutes",
   code_39523100:"Bestuurbare parachutes",
   code_39523200:"Rotochutes",
   code_39525000:"Diverse textielfabrikaten",
   code_39525100:"Stofdoeken",
   code_39525200:"Stoffen filterelementen",
   code_39525300:"Reddingsvesten",
   code_39525400:"Blusdekens",
   code_39525500:"Klamboes",
   code_39525600:"Vaatdoeken",
   code_39525700:"Reddingsgordels",
   code_39525800:"Schoonmaakdoekjes",
   code_39525810:"Poetsdoeken",
   code_39530000:"Tapijten, matten en vloerkleden",
   code_39531000:"Tapijten",
   code_39531100:"Geknoopte tapijten",
   code_39531200:"Geweven tapijten",
   code_39531300:"Tufted tapijten",
   code_39531310:"Tapijttegels",
   code_39531400:"Tapijtstof",
   code_39532000:"Matten",
   code_39533000:"Vloerkleden",
   code_39534000:"Tapijten voor industrieel gebruik",
   code_39540000:"Divers touwwerk, koorden, twijn en netten",
   code_39541000:"Touwwerk, koord, twijn en netten",
   code_39541100:"Twijn, touwwerk en koorden",
   code_39541110:"Touw",
   code_39541120:"Touwwerk",
   code_39541130:"Koord (bindtouw)",
   code_39541140:"Twijn",
   code_39541200:"Netten van textiel",
   code_39541210:"Geknoopte netten",
   code_39541220:"Draagriemen",
   code_39542000:"Lompen",
   code_39550000:"Artikelen van vliesstof",
   code_39560000:"Diverse textielartikelen",
   code_39561000:"Tule, kant, band, passement en borduurwerk",
   code_39561100:"Band; passement",
   code_39561110:"Lint",
   code_39561120:"Textieltape",
   code_39561130:"Labels en merktekens van textiel",
   code_39561131:"Labels van textiel",
   code_39561132:"Merktekens van textiel",
   code_39561133:"Insignes",
   code_39561140:"Sierpassement",
   code_39561141:"Galon",
   code_39561142:"Epauletten",
   code_39561200:"Netweefsels",
   code_39562000:"Vilt",
   code_39563000:"Opvulsel van textiel, garens, weefsels en artikelen voor technisch gebruik",
   code_39563100:"Opvulsel van textiel",
   code_39563200:"Gemetalliseerde garens",
   code_39563300:"Weefsels van metaaldraad",
   code_39563400:"Geïmpregneerde, eenzijdig of tweezijdig beklede textielweefsels",
   code_39563500:"Textielartikelen voor technisch gebruik",
   code_39563510:"Slangen van textiel",
   code_39563520:"Transportbanden van textiel",
   code_39563530:"Ventilatiekanalen",
   code_39563600:"Gewatteerde textielproducten",
   code_39700000:"Huishoudelijke apparaten",
   code_39710000:"Elektrische huishoudelijke apparaten",
   code_39711000:"Elektrische huishoudelijke apparaten voor voedsel",
   code_39711100:"Koelkasten en diepvriezers",
   code_39711110:"Koel-vriescombinaties",
   code_39711120:"Diepvriezers",
   code_39711121:"Diepvrieskisten",
   code_39711122:"Diepvriezers voor huishoudelijk gebruik",
   code_39711123:"Diepvrieskasten",
   code_39711124:"Industriële diepvriezers",
   code_39711130:"Koelkasten",
   code_39711200:"Keukenmachines",
   code_39711210:"Blenders",
   code_39711211:"Keukenmixers",
   code_39711300:"Elektrothermische toestellen",
   code_39711310:"Elektrische koffiezetapparaten",
   code_39711320:"Elektrische theezetapparaten",
   code_39711330:"Elektrische broodroosters",
   code_39711340:"Rechauds",
   code_39711350:"Wafelijzers",
   code_39711360:"Ovens",
   code_39711361:"Elektrische ovens",
   code_39711362:"Magnetronovens",
   code_39711400:"Braadovens, kookplaten, warmhoudplaten en komforen",
   code_39711410:"Braadovens",
   code_39711420:"Kookplaten",
   code_39711430:"Warmhoudplaten",
   code_39711440:"Komforen",
   code_39711500:"Blikopeners",
   code_39712000:"Elektrische apparaten voor lichaamsverzorging",
   code_39712100:"Tondeuses",
   code_39712200:"Haarverzorgingsapparaten",
   code_39712210:"Haardrogers",
   code_39712300:"Elektrische handendrogers",
   code_39713000:"Elektrische huishoudelijke apparaten voor schoonmaakwerkzaamheden; strijkijzers",
   code_39713100:"Vaatwasmachines",
   code_39713200:"Wasmachines en drogers",
   code_39713210:"Was-droogcombinaties",
   code_39713211:"Droog- en perstoestel",
   code_39713300:"Afvalpersen",
   code_39713400:"Vloeronderhoudsmachines",
   code_39713410:"Vloerreinigingsmachines",
   code_39713420:"Vloerboenmachines",
   code_39713430:"Stofzuigers",
   code_39713431:"Toebehoren voor stofzuigers",
   code_39713500:"Elektrische strijkijzers",
   code_39713510:"Stoomstrijkijzers",
   code_39714000:"Afzuigkappen met of zonder luchtverversing",
   code_39714100:"Ventilatoren",
   code_39714110:"Afzuigventilatoren",
   code_39715000:"Warmwatertoestellen en verwarming voor gebouwen; loodgietersuitrusting",
   code_39715100:"Elektrische geisers of boilers en dompelaars",
   code_39715200:"Verwarmingsuitrusting",
   code_39715210:"Uitrusting voor centrale verwarming",
   code_39715220:"Elektrische verwarmingsweerstanden",
   code_39715230:"Elektrische bodemverwarmingstoestellen",
   code_39715240:"Elektrische toestellen voor ruimteverwarming",
   code_39715300:"Loodgietersuitrusting",
   code_39716000:"Delen van elektrische huishoudelijke apparaten",
   code_39717000:"Ventilatoren en klimaatregelingsapparaten",
   code_39717100:"Koelventilatoren",
   code_39717200:"Klimaatregelingsapparaten",
   code_39720000:"Niet-elektrische huishoudelijke apparaten",
   code_39721000:"Kook- of verwarmingsapparatuur voor huishoudelijk gebruik",
   code_39721100:"Kooktoestellen voor huishoudelijk gebruik",
   code_39721200:"Koelkasten op gas",
   code_39721300:"Niet-elektrische luchtverhitters of heteluchttoestellen",
   code_39721310:"Luchtverhitters",
   code_39721320:"Luchtdrogers",
   code_39721321:"Persluchtdrogers",
   code_39721400:"Niet-elektrische geisers en boilers",
   code_39721410:"Gastoestellen",
   code_39721411:"Gasbranders",
   code_39722000:"Delen van kachels, kooktoestellen, rechauds en huishoudelijke apparaten",
   code_39722100:"Delen van kachels",
   code_39722200:"Delen van kooktoestellen",
   code_39722300:"Delen van rechauds",
   code_39800000:"Producten voor schoonmaken en poetsen",
   code_39810000:"Reukstoffen en was",
   code_39811000:"Preparaten voor het parfumeren van kamers of voor het neutraliseren van geuren in kamers",
   code_39811100:"Luchtverfrisser",
   code_39811110:"Luchtverfrisserhouders",
   code_39811200:"Luchtzuiveraars",
   code_39811300:"Geurverdrijvers",
   code_39812000:"Poetsmiddelen en crèmes",
   code_39812100:"Vloerboenwas",
   code_39812200:"Schoensmeer",
   code_39812300:"Polijstwas",
   code_39812400:"Veegpoeder met bindmiddel",
   code_39812500:"Dichtingsmiddelen",
   code_39813000:"Schuurpasta's en schuurpoeders",
   code_39820000:"Organische capillairactieve middelen",
   code_39821000:"Schoonmaakproducten met ammoniak",
   code_39822000:"Bijtende schoonmaakproducten",
   code_39830000:"Reinigingsmiddelen",
   code_39831000:"Wasmiddelen",
   code_39831100:"Oliedispergeermiddel",
   code_39831200:"Detergenten",
   code_39831210:"Detergentia voor vaatwasmachines",
   code_39831220:"Ontvettingsmiddelen",
   code_39831230:"Vetoplossende middelen",
   code_39831240:"Samengestelde reinigingsmiddelen",
   code_39831250:"Spoeloplossingen",
   code_39831300:"Vloerenreinigers",
   code_39831400:"Schermreinigers",
   code_39831500:"Autoreinigers",
   code_39831600:"Toiletreinigers",
   code_39831700:"Zeepautomaten",
   code_39832000:"Afwasproducten",
   code_39832100:"Afwaspoeder",
   code_39833000:"Stofwerende middelen",
   code_39834000:"Vloeibare juwelenreiniginsmiddelen",
   code_41000000:"Verzameld en gezuiverd water",
   code_41100000:"Natuurlijk water",
   code_41110000:"Drinkwater",
   code_41120000:"Niet-drinkbaar water",
   code_42000000:"Bedrijfsmachines",
   code_42100000:"Machines voor de productie en het gebruik van mechanische energie",
   code_42110000:"Turbines en motoren",
   code_42111000:"Motoren",
   code_42111100:"Buitenboordmotoren voor schepen",
   code_42112000:"Turbine-installaties",
   code_42112100:"Stoomturbines",
   code_42112200:"Waterturbines",
   code_42112210:"Waterraderen",
   code_42112300:"Gasturbines",
   code_42112400:"Turbine-uitrusting",
   code_42112410:"Turbine-instrumenten",
   code_42113000:"Delen van turbines",
   code_42113100:"Delen van stoomturbines",
   code_42113110:"Grondplaten",
   code_42113120:"Behuizingen",
   code_42113130:"Condensor-luchtkoelsysteem",
   code_42113150:"Smeeroliesystemen",
   code_42113160:"Vochtafscheiders",
   code_42113161:"Ontvochtigers",
   code_42113170:"Roterende uitrusting",
   code_42113171:"Rotoren",
   code_42113172:"Bladen",
   code_42113190:"Draaiapparatuur",
   code_42113200:"Delen van waterturbines",
   code_42113300:"Delen van gasturbines",
   code_42113310:"Luchtinlaatsystemen",
   code_42113320:"Gasinspuitingsmodule",
   code_42113390:"Stookgassystemen",
   code_42113400:"Delen van waterraderen",
   code_42120000:"Pompen en compressoren",
   code_42121000:"Hydraulische of pneumatische krachtwerktuigen en motoren",
   code_42121100:"Hydraulische of pneumatische cilinders",
   code_42121200:"Hydraulische krachtmachines",
   code_42121300:"Pneumatische krachtmachines",
   code_42121400:"Hydraulische motoren",
   code_42121500:"Pneumatische motoren",
   code_42122000:"Pompen",
   code_42122100:"Vloeistofpompen",
   code_42122110:"Pompen voor brandbestrijding",
   code_42122120:"Tankinstallaties voor helikopters",
   code_42122130:"Waterpompen",
   code_42122160:"Koelpompen",
   code_42122161:"Koelwaterpompen",
   code_42122170:"Oliepompen",
   code_42122180:"Brandstofpompen",
   code_42122190:"Betonpompen",
   code_42122200:"Zuigerverdringerpompen voor vloeistoffen",
   code_42122210:"Hydraulische generatoren",
   code_42122220:"Afvalwaterpompen",
   code_42122230:"Doseerpompen",
   code_42122300:"Drukverhogers voor vloeistoffen",
   code_42122400:"Centrifugaalpompen en elevators voor vloeistoffen",
   code_42122410:"Pompen voor medisch gebruik",
   code_42122411:"Voedingspompen",
   code_42122419:"Perfusiepompen",
   code_42122420:"Elevators voor vloeistoffen",
   code_42122430:"Centrifugaalpompen",
   code_42122440:"Rotatiepompen",
   code_42122450:"Vacuümpompen",
   code_42122460:"Luchtpompen",
   code_42122480:"Verdringerpompen",
   code_42122500:"Laboratoriumpompen en toebehoren",
   code_42122510:"Peristaltische pompen",
   code_42123000:"Compressoren",
   code_42123100:"Gascompressoren",
   code_42123200:"Roterende compressoren",
   code_42123300:"Compressoren voor koelinstallaties",
   code_42123400:"Luchtcompressoren",
   code_42123410:"Gemonteerde luchtcompressoren",
   code_42123500:"Turbocompressoren",
   code_42123600:"Zuigerverdringercompressoren",
   code_42123610:"Persluchteenheid",
   code_42123700:"Centrifugaalcompressoren",
   code_42123800:"Compressoren voor burgerluchtvaartuigen",
   code_42124000:"Delen van pompen, compressoren, machines of motoren",
   code_42124100:"Delen van machines of motoren",
   code_42124130:"Delen van pneumatische machines",
   code_42124150:"Delen van hydraulische machines of motoren",
   code_42124170:"Delen van reactiemotoren",
   code_42124200:"Delen van pompen of elevators voor vloeistoffen",
   code_42124210:"Delen van brandstof-, hand- en betonpompen",
   code_42124211:"Delen van brandstofpompen",
   code_42124212:"Delen van handpompen",
   code_42124213:"Delen van betonpompen",
   code_42124220:"Delen van zuigerverdringerpompen",
   code_42124221:"Delen van hydraulische generatoren",
   code_42124222:"Delen van doseerpompen",
   code_42124230:"Delen van roterende verdringerpompen",
   code_42124290:"Delen van centrifugaalpompen",
   code_42124300:"Delen van lucht- of vacuümpompen, delen van lucht- of gascompressoren",
   code_42124310:"Delen van luchtpompen",
   code_42124320:"Delen van vacuümpompen",
   code_42124330:"Delen van luchtcompressoren",
   code_42124340:"Delen van gascompressoren",
   code_42130000:"Kranen, afsluiters, kleppen en dergelijke",
   code_42131000:"Kranen, afsluiters en kleppen",
   code_42131100:"Kleppen gekenmerkt door hun functie",
   code_42131110:"Afsluiters voor cv-radiatoren",
   code_42131120:"Schuiven",
   code_42131130:"Temperatuurregelaars",
   code_42131140:"Reduceerkleppen, regelkleppen, keerkleppen en veiligheidskleppen",
   code_42131141:"Reduceerkleppen",
   code_42131142:"Regelkleppen",
   code_42131143:"Hoogwaterafsluiters",
   code_42131144:"Regelafsluiters",
   code_42131145:"Keerkleppen",
   code_42131146:"Terugslagkleppen",
   code_42131147:"Veiligheidskleppen",
   code_42131148:"Afsluiters",
   code_42131150:"Standpijpkleppen",
   code_42131160:"Hydranten",
   code_42131170:"Uitstroomventielen van gasflessen",
   code_42131200:"Kleppen gekenmerkt door hun constructie",
   code_42131210:"Messchuifafsluiters",
   code_42131220:"Afsluitkleppen in drukleidingen",
   code_42131230:"Schuifafsluiters",
   code_42131240:"Kogelklepafsluiters",
   code_42131250:"Naaldafsluiters",
   code_42131260:"Kogelkleppen",
   code_42131270:"Plugkranen",
   code_42131280:"Vleugelkleppen",
   code_42131290:"Diafragmakleppen",
   code_42131291:"Schuifkleppen",
   code_42131292:"Scharnierkleppen",
   code_42131300:"Spuitkruisen en andere klepconstructies",
   code_42131310:"Kerstbomen voor booreiland",
   code_42131320:"Smoorverdeelstukken",
   code_42131390:"Klepconstructies",
   code_42131400:"Kranen en afsluiters voor sanitaire installaties",
   code_42132000:"Delen van kranen en afsluiters",
   code_42132100:"Klepaandrijvingen",
   code_42132110:"Elektrische klepaandrijvingen",
   code_42132120:"Hydraulische klepaandrijvingen",
   code_42132130:"Pneumatische klepaandrijvingen",
   code_42132200:"Kraanonderdelen",
   code_42132300:"Kleponderdelen",
   code_42140000:"Drijfwerk, tandwieloverbrengingen en drijfwerkelementen",
   code_42141000:"Overbrenging met rechte tandwielen, tandwieloverbrengingen en drijfwerkelementen",
   code_42141100:"Overbrengings-, nokken- en krukassen",
   code_42141110:"Overbrengingsassen",
   code_42141120:"Nokkenassen",
   code_42141130:"Krukassen",
   code_42141200:"Lagerhuizen",
   code_42141300:"Tandwielen en tandwieloverbrengingen",
   code_42141400:"Vliegwielen en riemschijven",
   code_42141410:"Lieren",
   code_42141500:"Koppelingen (versnellingsbak)",
   code_42141600:"Katrolblokken",
   code_42141700:"Askoppelingen",
   code_42141800:"Cardankoppelingen",
   code_42142000:"Delen van tandwieloverbrengingen en drijfwerkelementen",
   code_42142100:"Delen van tandwieloverbrengingselementen",
   code_42142200:"Delen van drijfwerkelementen",
   code_42150000:"Kernreactoren en delen daarvan",
   code_42151000:"Kernreactoren",
   code_42152000:"Delen van kernreactoren",
   code_42152100:"Reactorkoelsystemen",
   code_42152200:"Delen van kernreactorvaten",
   code_42160000:"Boilerinstallaties",
   code_42161000:"Heetwaterboilers",
   code_42162000:"Stoomboilers",
   code_42163000:"Stoomgeneratoren",
   code_42164000:"Hulptoestellen voor ketels",
   code_42165000:"Stoomcondensors",
   code_42200000:"Machines voor voedsel-, dranken- en tabaksverwerking, en toebehoren",
   code_42210000:"Machines voor voedsel-, drank- en tabaksbewerking",
   code_42211000:"Zuivelbehandelingsmachines",
   code_42211100:"Melkcentrifuges",
   code_42212000:"Machines voor het verwerken van granen of gedroogde groenten",
   code_42213000:"Machines voor het bereiden van alcoholische of vruchtendranken",
   code_42214000:"Bakovens, droogtoestellen voor landbouwproducten en toestellen voor het koken of verwarmen",
   code_42214100:"Bakovens",
   code_42214110:"Grillen",
   code_42214200:"Droogtoestellen voor landbouwproducten",
   code_42215000:"Machines voor de industriële bereiding of vervaardiging van voedingsmiddelen of dranken",
   code_42215100:"Snijmachines voor voedingsmiddelen",
   code_42215110:"Broodsnijmachines",
   code_42215120:"Baconsnijmachines",
   code_42215200:"Voedselverwerkingsmachines",
   code_42215300:"Pastamachines",
   code_42216000:"Machines voor de bewerking van tabak",
   code_42220000:"Delen van machines voor de bewerking van voedingsmiddelen, dranken en tabak",
   code_42221000:"Delen van machines voor de productie van voedingsmiddelen",
   code_42221100:"Delen van machines voor zuivelproductie",
   code_42221110:"Delen van melkmachines",
   code_42222000:"Delen van machines voor behandeling van dranken",
   code_42223000:"Delen van machines voor de bewerking van tabak",
   code_42300000:"Ovens en verbrandingsinstallaties voor industrieel gebruik of laboratorium",
   code_42310000:"Ovenbranders",
   code_42320000:"Afvalverbrandingsinstallaties",
   code_42330000:"Smeltovens",
   code_42340000:"Ovens voor niet-huishoudelijk gebruik",
   code_42341000:"Bedrijfsovens",
   code_42350000:"Crematieovens",
   code_42390000:"Delen van ovenbranders en ovens",
   code_42400000:"Hijs-, hef- en transportwerktuigen en delen daarvan",
   code_42410000:"Hijs-, hef- en transportuitrusting",
   code_42411000:"Katrolblokken en hefwerktuigen",
   code_42412000:"Lieren voor mijnschachten, lieren voor ondergronds gebruik en kaapstanders",
   code_42412100:"Lieren voor mijnschachten en lieren voor ondergronds gebruik",
   code_42412110:"Lieren voor mijnschachten",
   code_42412120:"Lieren voor ondergronds gebruik",
   code_42412200:"Kaapstanders",
   code_42413000:"Krikken en hefbruggen voor voertuigen",
   code_42413100:"Ingebouwde kriksystemen",
   code_42413200:"Hydraulische krikken",
   code_42413300:"Pneumatische krikken",
   code_42413400:"Mechanische krikken",
   code_42413500:"Hefbruggen voor voertuigen",
   code_42414000:"Kranen, mobiele hijsinstallaties en transportwagens met kraan",
   code_42414100:"Hijskranen",
   code_42414110:"Havenkranen",
   code_42414120:"Kadekranen",
   code_42414130:"Stapelkranen",
   code_42414140:"Containerkranen",
   code_42414150:"Torenkranen",
   code_42414200:"Bovenloopkranen",
   code_42414210:"Loopkranen",
   code_42414220:"Rolbrug",
   code_42414300:"Portaaldraaikraan",
   code_42414310:"Mobiele hijsinstallaties",
   code_42414320:"Bokdraaikranen",
   code_42414400:"Kranen op voertuigen",
   code_42414410:"Kranen voor vrachtwagens",
   code_42414500:"Brugkranen",
   code_42415000:"Vorkheftrucks, voertuigen voor intern transport, trekkers voor gebruik op perrons",
   code_42415100:"Heftrucks",
   code_42415110:"Vorkheftrucks",
   code_42415200:"Voertuigen voor intern transport",
   code_42415210:"Voertuigen voor intern transport voorzien van laad- en losuitrusting",
   code_42415300:"Trekkers voor gebruik op perrons",
   code_42415310:"Voertuigen met vrije baan",
   code_42415320:"Uitrusting voor reddingsvoertuigen",
   code_42416000:"Liften, skipliften, hijsinstallaties, roltrappen en rolpaden",
   code_42416100:"Liften",
   code_42416110:"Badliften",
   code_42416120:"Goederenliften",
   code_42416130:"Mechanische liften",
   code_42416200:"Skipliften",
   code_42416210:"Containerliften",
   code_42416300:"Hijsinstallaties",
   code_42416400:"Roltrappen",
   code_42416500:"Rolpaden",
   code_42417000:"Liften en transportbanden",
   code_42417100:"Pneumatische liften of transportbanden",
   code_42417200:"Transportbanden",
   code_42417210:"Continuwerkende liften of transportbanden, type jakobsladder",
   code_42417220:"Continuwerkende liften of transportbanden, bandtype",
   code_42417230:"Gepantserde transportbanden voor de mijnbouw",
   code_42417300:"Transportbanduitrusting",
   code_42417310:"Lopende banden",
   code_42418000:"Lift-, transport-, laad- of loswerktuigen",
   code_42418100:"Mijnwagendrukkers en -locomotieven of rolwagens",
   code_42418200:"Monorail- of skiliftuitrusting",
   code_42418210:"Uitrusting voor hangende monorail",
   code_42418220:"Stoeltjesliften",
   code_42418290:"Skiliftuitrusting",
   code_42418300:"Apparatuur voor het verwijderen van de top van de fakkel",
   code_42418400:"Opslag- en opzoeksysteem (carrousels)",
   code_42418500:"Uitrusting voor mechanische verwerking",
   code_42418900:"Laad- of transportmachines",
   code_42418910:"Laaduitrusting",
   code_42418920:"Losuitrusting",
   code_42418930:"Zijladers",
   code_42418940:"Containervervoeruitrusting",
   code_42419000:"Delen van hijs-, hef- en transportuitrusting",
   code_42419100:"Delen van kranen",
   code_42419200:"Delen van voertuigen voor intern transport",
   code_42419500:"Delen van liften, skiphijsinstallaties en roltrappen",
   code_42419510:"Delen van liften",
   code_42419520:"Delen van skiphijsinstallaties",
   code_42419530:"Delen van roltrappen",
   code_42419540:"Delen van rolpaden",
   code_42419800:"Delen van lopende banden",
   code_42419810:"Delen van transportbanden",
   code_42419890:"Delen van transportbanden type jakobsladder",
   code_42419900:"Delen van lieren en andere hijs-, hef- of transportuitrusting",
   code_42420000:"Graafemmers, schoppen, grijpers en tangen voor kranen of graafmachines",
   code_42500000:"Koel- en ventilatie-uitrusting",
   code_42510000:"Warmtewisselaars, luchtbehandelings- en koeluitrusting en filtertoestellen",
   code_42511000:"Warmtewisselaars en toestellen voor het vloeibaar maken van lucht of andere gassen",
   code_42511100:"Warmtewisselaars",
   code_42511110:"Warmtepompen",
   code_42511200:"Toestellen voor het vloeibaar maken van lucht of andere gassen",
   code_42512000:"Luchtbehandelingsinstallaties",
   code_42512100:"Luchtbehandelingstoestellen voor montage in ramen",
   code_42512200:"Luchtbehandelingstoestellen voor bevestiging aan muren",
   code_42512300:"Verwarmings-, ventilatie- en luchtbehandelingspakketten",
   code_42512400:"Luchtbehandelingstoestellen voor voertuigen",
   code_42512500:"Delen van luchtbehandelingstoestellen",
   code_42512510:"Luchtregelkleppen",
   code_42512520:"Ventilatieroosters",
   code_42513000:"Koel- en diepvriesuitrusting",
   code_42513100:"Diepvriesuitrusting",
   code_42513200:"Koeluitrusting",
   code_42513210:"Koelvitrines",
   code_42513220:"Koeltoonbanken",
   code_42513290:"Bedrijfskoeluitrusting",
   code_42514000:"Machines en toestellen voor het filtreren of zuiveren van gassen",
   code_42514200:"Elektrostatische lucht- en gasreinigers",
   code_42514300:"Filtertoestellen",
   code_42514310:"Luchtfilters",
   code_42514320:"Gasfilters",
   code_42515000:"Ketel voor stadsverwarming",
   code_42520000:"Ventilatie-uitrusting",
   code_42521000:"Rookafzuiguitrusting",
   code_42522000:"Ventilatoren voor niet-huishoudelijk gebruik",
   code_42522100:"Delen van ventilatoren",
   code_42530000:"Delen van koel- en diepvriesuitrusting en van warmtepompen",
   code_42531000:"Delen van koeluitrusting",
   code_42532000:"Delen van diepvriesuitrusting",
   code_42533000:"Delen van warmtepompen",
   code_42600000:"Gereedschapswerktuigen",
   code_42610000:"Gereedschapswerktuigen met laserbediening en bewerkingscentra",
   code_42611000:"Gereedschapswerktuigen voor speciale doeleinden",
   code_42612000:"Bewerkingscentrum",
   code_42612100:"Bewerkingscentrum met horizontale spil",
   code_42612200:"Bewerkingscentrum met verticale spil",
   code_42620000:"Draaibanken, boor- of freesmachines",
   code_42621000:"Draaibanken",
   code_42621100:"CNC-draaibank",
   code_42622000:"Draadsnij- of -tapmachines",
   code_42623000:"Freesmachines",
   code_42630000:"Gereedschapswerktuigen voor de metaalbewerking",
   code_42631000:"Gereedschapswerktuigen voor het afwerken van metalen",
   code_42632000:"Machines voor metaalbewerking, met numerieke besturing",
   code_42633000:"Machines voor buigen, vouwen, strekken of vlakken",
   code_42634000:"Smeedmachines",
   code_42635000:"Stempelstansmachines",
   code_42636000:"Persen",
   code_42636100:"Hydraulische persen",
   code_42637000:"Gereedschapswerktuigen voor het boren of frezen van metaal",
   code_42637100:"Gereedschapswerktuigen voor het boren van metaal",
   code_42637200:"Gereedschapswerktuigen voor het maken van gaten in metaal",
   code_42637300:"Gereedschapswerktuigen voor het frezen van metaal",
   code_42638000:"Metaalbewerkingscentrum",
   code_42640000:"Gereedschapswerktuigen voor het bewerken van harde materialen, met uitzondering van metalen",
   code_42641000:"Gereedschapswerktuigen voor het bewerken van steen, keramische producten, beton of glas",
   code_42641100:"Gereedschapswerktuigen voor het bewerken van steen",
   code_42641200:"Gereedschapswerktuigen voor het bewerken van keramische producten",
   code_42641300:"Gereedschapswerktuigen voor het bewerken van beton",
   code_42641400:"Gereedschapswerktuigen voor het bewerken van glas",
   code_42642000:"Gereedschapswerktuigen voor het bewerken van hout, been, kurk, harde rubber of harde kunststoffen",
   code_42642100:"Gereedschapswerktuigen voor het bewerken van hout",
   code_42642200:"Gereedschapswerktuigen voor het bewerken van been",
   code_42642300:"Gereedschapswerktuigen voor het bewerken van kurk",
   code_42642400:"Gereedschapswerktuigen voor het bewerken van harde rubber",
   code_42642500:"Gereedschapswerktuigen voor het bewerken van harde kunststoffen",
   code_42650000:"Pneumatisch of motorisch aangedreven handgereedschap",
   code_42651000:"Pneumatisch handgereedschap",
   code_42652000:"Elektromechanisch handgereedschap",
   code_42660000:"Toestellen voor (hard)solderen en lassen, machines en uitrusting voor oppervlakteharden; machines en toestellen voor warmspuiten",
   code_42661000:"Uitrusting voor (hard)solderen",
   code_42661100:"Soldeeruitrusting",
   code_42661200:"Hardsoldeeruitrusting",
   code_42662000:"Lasuitrusting",
   code_42662100:"Uitrusting voor elektrisch lassen",
   code_42662200:"Uitrusting voor niet-elektrisch lassen",
   code_42663000:"Machines voor oppervlakteharden",
   code_42664000:"Versmeltingsuitrusting",
   code_42664100:"Versmeltingsuitrusting voor kunststoffen",
   code_42665000:"Metaalspuitmachines",
   code_42670000:"Delen en toebehoren van gereedschapswerktuigen",
   code_42671000:"Gereedschapshouders",
   code_42671100:"Gereedschapskisten voor laboratorium",
   code_42671110:"Reageerbuisstandaard voor baden",
   code_42672000:"Werkstukhouders voor gereedschapswerktuigen",
   code_42673000:"Verdeelkoppen en speciale opzetstukken voor gereedschapswerktuigen",
   code_42674000:"Delen en toebehoren voor gereedschapswerktuigen voor metaalbewerking",
   code_42675000:"Delen en toebehoren voor gereedschapswerktuigen voor het bewerken van harde materialen",
   code_42675100:"Delen van kettingzagen",
   code_42676000:"Delen van handgereedschap",
   code_42677000:"Onderdelen van pneumatisch gereedschap",
   code_42700000:"Machines voor de productie van textiel, kleding en leder",
   code_42710000:"Machines voor textiel",
   code_42711000:"Machines voor behandelen van synthetisch textielmateriaal",
   code_42712000:"Textielspinmachines",
   code_42713000:"Weefmachines",
   code_42714000:"Breimachines",
   code_42715000:"Naaimachines",
   code_42716000:"Wasmachines, machines voor chemisch reinigen en droogmachines",
   code_42716100:"Wasinstallaties",
   code_42716110:"Wasuitrusting",
   code_42716120:"Wasmachines",
   code_42716130:"Machines voor chemisch reinigen",
   code_42716200:"Wasdrogers",
   code_42717000:"Strijk- en vouwuitrusting voor linnengoed",
   code_42717100:"Vouwuitrusting voor linnengoed",
   code_42718000:"Machines voor het afwerken van textiel",
   code_42718100:"Strijkmachines",
   code_42718200:"Strijkpersen",
   code_42720000:"Delen van machines voor de productie van textiel en kleding",
   code_42800000:"Machines voor de productie van papier of karton",
   code_42810000:"Delen van machines voor de productie van papier of karton",
   code_42900000:"Diverse machines voor algemeen en specifiek gebruik",
   code_42910000:"Distilleer-, filter- en rectificeertoestellen",
   code_42912000:"Machines en toestellen voor het filtreren of zuiveren van vloeistoffen",
   code_42912100:"Machines en toestellen voor het filtreren van vloeistoffen",
   code_42912110:"Filtertoestellen voor boorspoeling",
   code_42912120:"Hydrocyclonen",
   code_42912130:"Toestellen voor het filtreren of zuiveren van dranken",
   code_42912300:"Toestellen voor het filtreren of zuiveren van water",
   code_42912310:"Toestellen voor het filtreren van water",
   code_42912320:"Ontluchtingstoestellen",
   code_42912330:"Waterzuiveringstoestellen",
   code_42912340:"Ontziltingstoestellen",
   code_42912350:"Uitrusting voor filterinstallaties",
   code_42913000:"Oliefilters, benzinefilters en luchtfilters",
   code_42913300:"Oliefilters",
   code_42913400:"Benzinefilters",
   code_42913500:"Luchtinlaatfilters",
   code_42914000:"Recyclage-uitrusting",
   code_42920000:"Machines voor het reinigen van flessen, verpakkings-, weeg- en spraytoestellen",
   code_42921000:"Machines voor het reinigen, vullen of verpakken van flessen of andere verpakkingen",
   code_42921100:"Machines voor het reinigen of drogen van flessen of andere verpakkingen",
   code_42921200:"Machines voor het vullen of sluiten van flessen, blikken of andere verpakkingen",
   code_42921300:"Verpakkings- of inpakmachines",
   code_42921310:"Vastbindmachines",
   code_42921320:"Verpakkingsmachines",
   code_42921330:"Inpakmachines",
   code_42923000:"Weegtoestellen en -schalen",
   code_42923100:"Weegapparatuur",
   code_42923110:"Balansen",
   code_42923200:"Weegschalen",
   code_42923210:"Winkelweegschalen",
   code_42923220:"Bandwegers",
   code_42923230:"IJkweegschalen",
   code_42924200:"Stoom- of zandstraalmachines",
   code_42924300:"Spuituitrusting",
   code_42924310:"Spuitpistolen",
   code_42924700:"Mechanische toestellen voor het spuiten, verspuiten of sproeien",
   code_42924710:"Toestellen voor het verspuiten van gas",
   code_42924720:"Ontsmettingsuitrusting",
   code_42924730:"Hogedrukreinigingsapparatuur met water",
   code_42924740:"Hogedrukreinigingstoestellen",
   code_42924790:"Geurverdrijvingstoestellen",
   code_42930000:"Centrifuges, kalandermachines of verkoopautomaten",
   code_42931000:"Centrifuges",
   code_42931100:"Laboratoriumcentrifuges en toebehoren",
   code_42931110:"Op de vloer staande centrifuges",
   code_42931120:"Tafelcentrifuges",
   code_42931130:"Reduceertoestellen voor centrifuges",
   code_42931140:"Draaiende onderdelen voor centrifuges",
   code_42932000:"Kalandermachines",
   code_42932100:"Rolmachines",
   code_42933000:"Verkoopmachines",
   code_42933100:"Verkoopmachines voor sanitaire artikelen",
   code_42933200:"Postzegelautomaten",
   code_42933300:"Verkoopautomaten",
   code_42940000:"Machines voor de warmtebehandeling van materialen",
   code_42941000:"Machines voor de warmtebehandeling van gas",
   code_42942000:"Ovens en toebehoren",
   code_42942200:"Vacuümovens",
   code_42943000:"Thermostatische baden en toebehoren",
   code_42943100:"Koelslangen",
   code_42943200:"Ultrasoonbaden",
   code_42943210:"Dompelthermostaten",
   code_42943300:"Dompelkoelers",
   code_42943400:"Convectoren voor koeling en koeling/verwarming",
   code_42943500:"Koelers voor hergebruikte lucht",
   code_42943600:"Hogetemperatuurconvectoren",
   code_42943700:"Verwarmingsconvectoren",
   code_42943710:"Afdekkingen voor bad",
   code_42950000:"Delen van machines voor algemeen gebruik",
   code_42952000:"Delen van centrifuges",
   code_42953000:"Delen van kalandermachines",
   code_42954000:"Delen van rolmachines",
   code_42955000:"Delen van filtermachines",
   code_42956000:"Delen van zuiveringsmachines",
   code_42957000:"Delen van sproeimachines",
   code_42958000:"Gewichten voor weegtoestellen",
   code_42959000:"Industriële vaatwasmachines",
   code_42960000:"Apparatuur voor bedienings- en controlesysteem, drukkerij, grafische industrie, kantoorautomatisering en informatieverwerking",
   code_42961000:"Besturings- en controlesysteem",
   code_42961100:"Toegangscontrolesysteem",
   code_42961200:"Scada of gelijkwaardig systeem",
   code_42961300:"Systeem voor opsporing van voertuigen",
   code_42961400:"Expeditiesysteem",
   code_42962000:"Druk- en grafische uitrusting",
   code_42962100:"Foliedruksysteem",
   code_42962200:"Drukpers",
   code_42962300:"Grafische werkstations",
   code_42962400:"Hectografen",
   code_42962500:"Graveermachines",
   code_42963000:"Muntpers",
   code_42964000:"Kantoorautomatiseringsuitrusting",
   code_42965000:"Informatieverwerkingsuitrusting",
   code_42965100:"Magazijnbeheerssysteem",
   code_42965110:"Opslagsysteem",
   code_42967000:"Besturingsunit",
   code_42967100:"Digitale afstandsbedieningsunit",
   code_42968000:"Verdelers",
   code_42968100:"Drankenautomaat",
   code_42968200:"Automaten voor hygiënische benodigdheden",
   code_42968300:"WC-papierophangsysteem",
   code_42970000:"Delen van vaatwasmachines en van reinigings-, vul-, verpakkkings- of inpakmachines",
   code_42971000:"Delen van vaatwasmachines",
   code_42972000:"Delen van reinigingsmachines",
   code_42973000:"Delen van vulmachines",
   code_42974000:"Delen van verpakkingsmachines",
   code_42975000:"Delen van inpakmachines",
   code_42980000:"Gasgeneratoren",
   code_42981000:"Ozongeneratoren",
   code_42990000:"Diverse machines voor specifieke doeleinden",
   code_42991000:"Machines voor papier, drukken en boekbinden, en onderdelen daarvan",
   code_42991100:"Machines voor het inbinden van boeken",
   code_42991110:"Machines voor het innaaien van boeken",
   code_42991200:"Drukmachines",
   code_42991210:"Offsetdrukmachines",
   code_42991220:"Zetmachines",
   code_42991230:"Kaartjesdrukmachines",
   code_42991300:"Fotozetsysteem",
   code_42991400:"Droogtoestellen voor hout, papierpulp, papier of karton",
   code_42991500:"Delen van machines voor het drukken en het inbinden van boeken",
   code_42992000:"Elektrische benodigdheden voor specifieke doeleinden",
   code_42992100:"Kooi van Faraday",
   code_42992200:"Echoloze kamer",
   code_42992300:"Elektromagnetisch absorberend materiaal",
   code_42993000:"Machines voor de chemische industrie",
   code_42993100:"Chloreertoestellen",
   code_42993200:"Doseertoestellen",
   code_42994000:"Machines voor het bewerken van rubber of kunststoffen",
   code_42994100:"Machines voor de productie van kunststof ramen en kozijnen",
   code_42994200:"Machines voor het bewerken van kunststoffen",
   code_42994220:"Toebehoren voor lamineren",
   code_42994230:"Lamineermachines",
   code_42995000:"Diverse reinigingsmachines",
   code_42995100:"Wasstraat",
   code_42995200:"Strandreinigingsmachines",
   code_42996000:"Machines voor de afvalwaterbehandeling",
   code_42996100:"Roostervuilverkleiners",
   code_42996110:"Snijmolens voor afvalwaterbehandeling",
   code_42996200:"Afvalwaterpersen",
   code_42996300:"Schrapers",
   code_42996400:"Mengeenheden",
   code_42996500:"Rioolroosters",
   code_42996600:"Beluchtingsapparatuur",
   code_42996700:"Stofscheiders",
   code_42996800:"Bezinkingsreservoirs",
   code_42996900:"Slibverwerkingsuitrusting",
   code_42997000:"Uitrusting voor pijpleiding",
   code_42997100:"Machines voor inspectie van de binnenkant van pijpleidingen",
   code_42997200:"Machines voor reiniging van de binnenkant van pijpleidingen",
   code_42997300:"Industriële robots",
   code_42998000:"Palletzoeksysteem",
   code_42998100:"Palletuithaalsysteem",
   code_42999000:"Stofzuigers en boenmachines voor niet-huishoudelijk gebruik",
   code_42999100:"Stofzuigers, niet voor huishoudelijk gebruik",
   code_42999200:"Boenmachines, niet voor huishoudelijk gebruik",
   code_42999300:"Delen van stofzuigers, niet voor huishoudelijk gebruik",
   code_42999400:"Delen van vloerboenmachines, niet voor huishoudelijk gebruik",
   code_43000000:"Machines voor de mijnbouw, steengroeven en voor de bouw",
   code_43100000:"Mijnbouwuitrusting",
   code_43120000:"Kolen- of steenzaagmachines en tunnelgraafmachines, machines voor boren of delven",
   code_43121000:"Putboormachines",
   code_43121100:"Installatiewerktuigen voor putmond",
   code_43121200:"Apparatuur voor stijgbuisverbindingen",
   code_43121300:"Putafwerkingsuitrusting",
   code_43121400:"Putinterventie-uitrusting",
   code_43121500:"Puttestuitrusting",
   code_43121600:"Apparatuur voor eruptieafsluiters",
   code_43122000:"Steenkoolzaagmachines of steenzaagmachines",
   code_43123000:"Tunnelgraafmateriaal",
   code_43124000:"Grondboormachines",
   code_43124100:"Tunnelgraafmachines",
   code_43124900:"Rotsboormachines",
   code_43125000:"Delfmachines",
   code_43130000:"Booruitrusting",
   code_43131000:"Offshoreproductieplatforms",
   code_43131100:"Offshore-uitrusting",
   code_43131200:"Offshoreboorunit",
   code_43132000:"Oliebooruitrusting",
   code_43132100:"Boormachines",
   code_43132200:"Boorinstallatie",
   code_43132300:"Boren",
   code_43132400:"Pijpleidingsuitrusting",
   code_43132500:"Zeefpijphangers",
   code_43133000:"Uitrusting voor olieplatforms",
   code_43133100:"Skidunits",
   code_43133200:"Op skids gemonteerde modules",
   code_43134000:"Machines voor olieveld",
   code_43134100:"Onderwaterpompen",
   code_43135000:"Onderzeese uitrusting",
   code_43135100:"Onderzeese regelsystemen",
   code_43136000:"Boorgatuitrusting",
   code_43140000:"Mobiele hydraulische dakstutten voor de mijnbouw",
   code_43200000:"Grondverzet- en graafmachines, en bijbehorende onderdelen",
   code_43210000:"Grondverzetmachines",
   code_43211000:"Bulldozers",
   code_43212000:"Hoekschuivers",
   code_43220000:"Grondschaven en egaliseermachines",
   code_43221000:"Wegschaven",
   code_43230000:"Schraapmachines",
   code_43240000:"Aanstampmachines",
   code_43250000:"Laadschoppen met voorschop",
   code_43251000:"Laadschoppen met voorschop en dieplepel",
   code_43252000:"Laadschoppen met voorschop, zonder dieplepel",
   code_43260000:"Laadschoppen, graafmachines en laadschoppen en mijnbouwmachines",
   code_43261000:"Laadschoppen",
   code_43261100:"Schep- en laadmachines",
   code_43262000:"Graafmachinerie",
   code_43262100:"Graafmachines",
   code_43300000:"Machines en uitrusting voor de bouw",
   code_43310000:"Machines voor civiele techniek",
   code_43311000:"Heimachines",
   code_43312000:"Wegverhardingsmachines",
   code_43312100:"Schaafmachines",
   code_43312200:"Splitstrooiwagens",
   code_43312300:"Bestratingsmachines",
   code_43312400:"Wegwalsen",
   code_43312500:"Walsmachines",
   code_43313000:"Sneeuwploegen en sneeuwblazers",
   code_43313100:"Sneeuwploegen",
   code_43313200:"Sneeuwblazers",
   code_43314000:"Palentrekkers",
   code_43315000:"Verdichtingsmachines",
   code_43316000:"Machines voor het leggen van kabels",
   code_43320000:"Bouwuitrusting",
   code_43321000:"Uitrusting voor brugophanging",
   code_43322000:"Ontmantelingsuitrusting",
   code_43323000:"Irrigatie-uitrusting",
   code_43324000:"Draineringsuitrusting",
   code_43324100:"Uitrusting voor zwembaden",
   code_43325000:"Uitrusting voor parken en speelplaatsen",
   code_43325100:"Uitrusting voor terreinonderhoud",
   code_43327000:"Prefabuitrusting",
   code_43328000:"Hydraulische installaties",
   code_43328100:"Hydraulische uitrusting",
   code_43329000:"Uitrustingssets",
   code_43400000:"Machines voor het behandelen van ertsen en machines voor het maken van gietvormen",
   code_43410000:"Machines voor het behandelen van ertsen",
   code_43411000:"Sorteer- en zeefmachines",
   code_43412000:"Machines voor het mengen van grind met bitumen",
   code_43413000:"Beton- en mortelmengers",
   code_43413100:"Cementmengers",
   code_43414000:"Maalmachines",
   code_43414100:"Steenkoolverpulveraars",
   code_43415000:"Gietvormen",
   code_43420000:"Machines voor het maken van gietvormen",
   code_43500000:"Rupsbandvoertuigen",
   code_43600000:"Delen van machines voor mijnbouw, steengroeven en voor de bouw",
   code_43610000:"Delen van boormachines",
   code_43611000:"Delen van grondboormachines",
   code_43611100:"Brugproppen",
   code_43611200:"Industriële boorkoppen",
   code_43611300:"Boorscharen",
   code_43611400:"IJzeren boortorens",
   code_43611500:"Draaibare tafels",
   code_43611600:"Uitrusting voor de ophanging van de spoelingleiding",
   code_43611700:"Bevestigingsuitrusting",
   code_43612000:"Delen van boorputextractiemachines",
   code_43612100:"Ophangmechanismen voor verbuizing",
   code_43612200:"Uitrusting voor zeefpijphangers",
   code_43612300:"Productiestijgbuisspanners",
   code_43612400:"Boorputkoppen",
   code_43612500:"Bevestigingsuitrusting voor de productiestijgleiding",
   code_43612600:"Boorputkop-controlesysteem",
   code_43612700:"Apparatuur voor boorputkop",
   code_43612800:"Vijzelsystemen voor boorplatforms",
   code_43613000:"Delen van steenkool- of steenzaagmachines",
   code_43613100:"Delen van steenkoolzaagmachines",
   code_43613200:"Delen van steenzaagmachines",
   code_43614000:"Delen van tunnelgraafmachines",
   code_43620000:"Delen van machines voor het behandelen van ertsen",
   code_43630000:"Delen van delfmachines",
   code_43640000:"Delen van graafmachinerie",
   code_43700000:"Machines voor de metallurgie en bijbehorende onderdelen",
   code_43710000:"Metaalwalsmachines",
   code_43711000:"Onderdelen van metaalwalsmachines",
   code_43720000:"Gietmachines",
   code_43721000:"Onderdelen van gietmachines",
   code_43800000:"Werkplaatsuitrusting",
   code_43810000:"Houtbewerkingsuitrusting",
   code_43811000:"Schuurmachines",
   code_43812000:"Zaaguitrusting",
   code_43820000:"Schoenmakersuitrusting",
   code_43830000:"Elektrisch gereedschap",
   code_43840000:"Uitrusting voor smederij",
   code_44000000:"Structuren en materialen voor de bouw; ondersteunende producten voor de bouw (uitgezonderd elektrische apparatuur)",
   code_44100000:"Bouwmaterialen en aanverwante artikelen",
   code_44110000:"Constructiematerialen",
   code_44111000:"Bouwmaterialen",
   code_44111100:"Bakstenen",
   code_44111200:"Cement",
   code_44111210:"Boorspecie",
   code_44111300:"Keramiek",
   code_44111400:"Verf en wandbekleding",
   code_44111500:"Isolatoren en isolerende delen",
   code_44111510:"Isolatoren",
   code_44111511:"Isolatoren voor elektriciteit",
   code_44111520:"Warmte-isolatiemateriaal",
   code_44111530:"Elektriciteit-isolerende delen",
   code_44111540:"Isolatieglas",
   code_44111600:"Blokken",
   code_44111700:"Tegels",
   code_44111800:"Mortel (bouwnijverheid)",
   code_44111900:"Keramische plavuizen",
   code_44112000:"Diverse constructiestructuren",
   code_44112100:"Schuilhokjes",
   code_44112110:"Onderdelen van schuilhokjes",
   code_44112120:"Profielsecties",
   code_44112200:"Vloerbedekking",
   code_44112210:"Hard vloermateriaal",
   code_44112220:"Verhoogde vloeren",
   code_44112230:"Linoleum",
   code_44112240:"Parket",
   code_44112300:"Scheidingsvoorzieningen",
   code_44112310:"Scheidingswanden",
   code_44112400:"Dak",
   code_44112410:"Dakspanten",
   code_44112420:"Dakstutten",
   code_44112430:"Kapspanten",
   code_44112500:"Dakbedekkingsmateriaal",
   code_44112510:"Dakspanen",
   code_44112600:"Geluidsisolatie",
   code_44112700:"Balken",
   code_44113000:"Materiaal voor wegenbouw",
   code_44113100:"Bestratingsmateriaal",
   code_44113120:"Bestratingsplaten",
   code_44113130:"Straatstenen",
   code_44113140:"Steenmateriaal voor wegenbouw",
   code_44113200:"Flagstones",
   code_44113300:"Omhulde materialen",
   code_44113310:"Omhulde wegenbouwmaterialen",
   code_44113320:"Omhulde wegenbouwsteen",
   code_44113330:"Bekledingsmateriaal",
   code_44113500:"Glazen parels",
   code_44113600:"Bitumen en asfalt",
   code_44113610:"Bitumen",
   code_44113620:"Asfalt",
   code_44113700:"Materiaal voor reparatie van wegen",
   code_44113800:"Wegdekmateriaal",
   code_44113810:"Oppervlaktebehandeling",
   code_44113900:"Materiaal voor wegenonderhoud",
   code_44113910:"Materiaal voor gladheidsbestrijding",
   code_44114000:"Beton",
   code_44114100:"Stortklaar beton",
   code_44114200:"Betonproducten",
   code_44114210:"Betonnen pijlers",
   code_44114220:"Betonnen buizen en hulpstukken",
   code_44114250:"Betonplaten",
   code_44115000:"Hulpstukken voor bouwwerken",
   code_44115100:"Buisleidingen",
   code_44115200:"Loodgieters- en verwarmingsmateriaal",
   code_44115210:"Loodgietersmateriaal",
   code_44115220:"Materiaal voor verwarming",
   code_44115310:"Rolluiken",
   code_44115400:"Dakramen",
   code_44115500:"Sprinklersystemen",
   code_44115600:"Trapliften",
   code_44115700:"Externe zonnewering",
   code_44115710:"Luifels",
   code_44115800:"Interne hulpstukken voor gebouwen",
   code_44115810:"Gordijnroeden en gordijnen",
   code_44115811:"Gordijnroeden",
   code_44115900:"Zonweringselementen",
   code_44130000:"Hoofdleidingen voor riolering",
   code_44131000:"Rioolkolken",
   code_44132000:"Duikerelementen",
   code_44133000:"Dekplaatjes van gietijzer",
   code_44134000:"Bochtstukken",
   code_44140000:"Producten verband houdend met bouwmaterialen",
   code_44141000:"Leidingbuizen",
   code_44141100:"Elektriciteitsbuizen",
   code_44142000:"Frames",
   code_44143000:"Laadborden",
   code_44144000:"Stijlen",
   code_44160000:"Pijpleidingen, pijpleidingstelsels, pijpen, mantelbuizen, binnenbuizen en aanverwante artikelen",
   code_44161000:"Pijpleidingen",
   code_44161100:"Gaspijpleidingen",
   code_44161110:"Gasdistributienetwerk",
   code_44161200:"Hoofdwaterleiding",
   code_44161400:"Onderwaterpijpleiding",
   code_44161410:"Onderzeese pijpleiding",
   code_44161500:"Hogedrukpijpleiding",
   code_44161600:"Lagedrukpijpleiding",
   code_44161700:"Pijpschrapers",
   code_44161710:"Lanceerinstallaties voor pijpschrapers",
   code_44161720:"Opvanginstallaties voor pijpschrapers",
   code_44161730:"Bijleidingen voor pijpschraper",
   code_44162000:"Pijpleidingstelsels",
   code_44162100:"Benodigdheden voor pijpleidingstelsel",
   code_44162200:"Distributiepijpleiding",
   code_44162300:"Lozingsleidingstelsel",
   code_44162400:"Mangaten van gres",
   code_44162500:"Drinkwaterleidingen",
   code_44163000:"Pijpen en fittings",
   code_44163100:"Pijpen",
   code_44163110:"Afwateringspijpen",
   code_44163111:"Afvoerpijpen",
   code_44163112:"Afwateringssysteem",
   code_44163120:"Stadsverwarmingsbuizen",
   code_44163121:"Verwarmingsbuizen",
   code_44163130:"Rioolbuizen",
   code_44163140:"Stoom- en waterbuizen",
   code_44163150:"Lagedrukbuizen",
   code_44163160:"Distributiebuizen en toebehoren",
   code_44163200:"Pijpfittings",
   code_44163210:"Pijpklemmen",
   code_44163230:"Pijpkoppelstukken",
   code_44163240:"Pijpverbindingen",
   code_44163241:"Geïsoleerde verbindingsstukken",
   code_44164000:"Mantelbuizen en binnenbuizen",
   code_44164100:"Mantelbuizen",
   code_44164200:"Schachtbekleding",
   code_44164300:"Buisvormige goederen",
   code_44164310:"Buizen en fittings",
   code_44165000:"Slangen, stijgleidingen en moffen",
   code_44165100:"Slangen",
   code_44165110:"Boorslangen",
   code_44165200:"Stijgleidingen",
   code_44165210:"Flexibele stijgbuizen",
   code_44165300:"Moffen",
   code_44166000:"Buismateriaal voor de aardolie-industrie",
   code_44167000:"Diverse pijpfittingen",
   code_44167100:"Koppelstukken",
   code_44167110:"Flenzen",
   code_44167111:"Flensadapters",
   code_44167200:"Reparatieklemmen en -ringen",
   code_44167300:"Bochtstukken, T-stukken en fittingen voor pijpen",
   code_44167400:"Kniestukken",
   code_44170000:"Platen, bladen, strips en folie verband houdend met bouwmaterialen",
   code_44171000:"Platen (bouwnijverheid)",
   code_44172000:"Bladen (bouwnijverheid0",
   code_44173000:"Strips",
   code_44174000:"Folie",
   code_44175000:"Panelen",
   code_44176000:"Dunne folie",
   code_44190000:"Diverse materialen voor de bouw",
   code_44191000:"Diverse houten materialen voor de bouw",
   code_44191100:"Multiplex",
   code_44191200:"Houtlaminaat",
   code_44191300:"Spaanplaat",
   code_44191400:"Vezelplaat",
   code_44191500:"Geperst hout",
   code_44191600:"Panelen voor parketvloeren",
   code_44192000:"Overige diverse materialen voor de bouw",
   code_44192100:"Pvc-schuim",
   code_44192200:"Nagels",
   code_44200000:"Constructieproducten",
   code_44210000:"Assemblagestructuren en delen daarvan",
   code_44211000:"Prefabgebouwen",
   code_44211100:"Modulaire verplaatsbare bouwelementen",
   code_44211110:"Cabines",
   code_44211200:"Hokjes",
   code_44211300:"Veldhospitaal",
   code_44211400:"Veldkeukens",
   code_44211500:"Broeikassen",
   code_44212000:"Metaalbouwproducten en onderdelen, met uitzondering van prefabgebouwen",
   code_44212100:"Brug",
   code_44212110:"Brugsecties",
   code_44212120:"Metaalconstructies van bruggen",
   code_44212200:"Torens, vakwerkmasten, hefkranen en pylonen",
   code_44212210:"Hefkranen",
   code_44212211:"Boortorens",
   code_44212212:"Vaste boortorens",
   code_44212220:"Pylonen, palen en paaltjes",
   code_44212221:"Pylonen",
   code_44212222:"Hoogspanningsmasten",
   code_44212223:"Pyloonstutten",
   code_44212224:"Palen voor bovengrondse leidingen",
   code_44212225:"Palen",
   code_44212226:"Elektriciteitspalen",
   code_44212227:"Paaltjes",
   code_44212230:"Torens",
   code_44212233:"Watertorens",
   code_44212240:"Steunbalken",
   code_44212250:"Masten",
   code_44212260:"Radio- of televisiemasten",
   code_44212261:"Radiomasten",
   code_44212262:"Televisiemasten",
   code_44212263:"Vakwerkmasten",
   code_44212300:"Assemblagestructuren en losse delen",
   code_44212310:"Bouwsteigers",
   code_44212311:"Boogschoren",
   code_44212312:"Boogpijlers",
   code_44212313:"Stutten",
   code_44212314:"Draagconstructie voor pijpen",
   code_44212315:"Steigeruitrusting",
   code_44212316:"Boogklemmen",
   code_44212317:"Steigerconstructies",
   code_44212318:"Stutten voor pijpleidingen",
   code_44212320:"Diverse structuren",
   code_44212321:"Abri's",
   code_44212322:"Telefooncellen",
   code_44212329:"Veiligheidsschermen",
   code_44212380:"Delen van assemblagestructuren",
   code_44212381:"Bekleding",
   code_44212382:"Vloeddeuren",
   code_44212383:"Deuren voor schutsluis",
   code_44212390:"Sluizen",
   code_44212391:"Deuren voor in-/uitlaatsluis",
   code_44212400:"Heiwerk",
   code_44212410:"Damwandplanken",
   code_44212500:"Hoekstaal en profielstaal",
   code_44212510:"Hoekstaal",
   code_44212520:"Profielstaal",
   code_44220000:"Schrijnwerk voor de bouw",
   code_44221000:"Ramen, deuren en aanverwante artikelen",
   code_44221100:"Ramen",
   code_44221110:"Raamkozijnen",
   code_44221111:"Elementen met dubbele beglazing",
   code_44221120:"Openslaande deuren",
   code_44221200:"Deuren",
   code_44221210:"Deurplaten",
   code_44221211:"Deurkozijnen",
   code_44221212:"Deurhorren",
   code_44221213:"Deurdrempels",
   code_44221220:"Branddeuren",
   code_44221230:"Schuifdeuren",
   code_44221240:"Garagedeuren",
   code_44221300:"Toegangsdeuren",
   code_44221310:"Toegangshekken",
   code_44221400:"Luiken",
   code_44221500:"Drempels",
   code_44230000:"Timmerwerk voor de bouw",
   code_44231000:"Kant-en-klare omheiningspanelen",
   code_44232000:"Houten kapgebinten",
   code_44233000:"Trappen",
   code_44300000:"Kabels, draad en aanverwante producten",
   code_44310000:"Draadproducten",
   code_44311000:"Gevlochten draad",
   code_44312000:"Omheiningsdraad",
   code_44312300:"Prikkeldraad",
   code_44313000:"Metaalgaas",
   code_44313100:"Gaasafrastering",
   code_44313200:"Metaalweefsel",
   code_44315000:"Walsdraad",
   code_44315100:"Lastoebehoren",
   code_44315200:"Lasmateriaal",
   code_44315300:"Materiaal voor solderen en hardsolderen",
   code_44315310:"Soldeermateriaal",
   code_44315320:"Materiaal voor hardsolderen",
   code_44316000:"IJzerwaren",
   code_44316100:"Aambeelden",
   code_44316200:"Draagbare smidse",
   code_44316300:"Roosters",
   code_44316400:"Handgereedschappen",
   code_44316500:"Smidswaren",
   code_44316510:"Smeedwerk",
   code_44317000:"Stroppen van ijzer of staal",
   code_44318000:"Geleiders",
   code_44320000:"Kabel en aanverwante producten",
   code_44321000:"Kabel",
   code_44322000:"Kabeltoebehoren",
   code_44322100:"Kabeldoorvoerbuizen",
   code_44322200:"Kabelaansluitingen",
   code_44322300:"Kabelbuizen",
   code_44322400:"Kabelklemmen",
   code_44330000:"Staven, stangen, draad en profielen voor gebruik in de bouw",
   code_44331000:"Staven",
   code_44332000:"Stangen (bouwnijverheid)",
   code_44333000:"Draad",
   code_44334000:"Profielen",
   code_44400000:"Diverse fabrikaten en aanverwante producten",
   code_44410000:"Artikelen voor badkamer en keuken",
   code_44411000:"Sanitair",
   code_44411100:"Kranen",
   code_44411200:"Badkuipen",
   code_44411300:"Wastafels",
   code_44411400:"Douchebakken",
   code_44411600:"Bidets",
   code_44411700:"WC-brillen, deksels, toiletpotten en stortbakken",
   code_44411710:"WC-brillen",
   code_44411720:"WC-deksels",
   code_44411740:"Toiletpotten",
   code_44411750:"Stortbakken",
   code_44411800:"Urinoirs",
   code_44420000:"Goederen voor gebruik in de bouw",
   code_44421000:"Gepantserde of gewapende kluizen, brandkasten en deuren",
   code_44421300:"Kluizen",
   code_44421500:"Gepantserde of gewapende deuren",
   code_44421600:"Bewaarkluizen",
   code_44421700:"Kisten en afsluitbare kasten",
   code_44421710:"Bagagekluizen",
   code_44421720:"Opbergkasten",
   code_44421721:"Safekastjes",
   code_44421722:"Safekasten",
   code_44421780:"Aktenkisten",
   code_44421790:"Geldkisten",
   code_44422000:"Brievenbussen",
   code_44423000:"Diverse goederen",
   code_44423100:"Klokken (bellen)",
   code_44423200:"Ladders",
   code_44423220:"Opvouwbare trappen",
   code_44423230:"Keukentrapjes",
   code_44423300:"Laad- en losuitrusting",
   code_44423330:"Pallets voor goederenvervoer",
   code_44423340:"Toebehoren van draadkabel voor laad- en losuitrusting",
   code_44423400:"Signaleringsborden en aanverwante artikelen",
   code_44423450:"Naambordjes",
   code_44423460:"Adresplaatjes",
   code_44423700:"Delen van mangaten",
   code_44423710:"Straatpotten",
   code_44423720:"Grenskasten",
   code_44423730:"Frames voor mangaten",
   code_44423740:"Mangatdeksels",
   code_44423750:"Riooldeksels",
   code_44423760:"Toegangsdeksels",
   code_44423790:"Klimijzers voor mangaten",
   code_44423800:"Metalen stempels",
   code_44423810:"Rubberstempels",
   code_44423850:"Gevormde artikelen",
   code_44423900:"Opofferingsanodes",
   code_44424000:"Meterkasten en band",
   code_44424100:"Meterkasten",
   code_44424200:"Plakband",
   code_44424300:"Reflecterend band",
   code_44425000:"Ringen, zegels, band, stokken en voegspeciepakkers",
   code_44425100:"Elastische ringen",
   code_44425110:"Zelfklevende versterkingsringen",
   code_44425200:"Dichtingsmateriaal van rubber",
   code_44425300:"Elastiekjes",
   code_44425400:"Voegspeciepakkers",
   code_44425500:"Stokken van plastic",
   code_44430000:"Pantserplaatbekleding",
   code_44431000:"Dekplaten",
   code_44440000:"Lagers",
   code_44441000:"Microparels",
   code_44442000:"Rollagers",
   code_44450000:"Zacht staal",
   code_44451000:"Muntplaatjes",
   code_44452000:"Muntbakken voor automaten",
   code_44460000:"Schoren en stutten voor de mijnbouw",
   code_44461000:"Schoren",
   code_44461100:"Mijnschoren",
   code_44462000:"Mijnstutten",
   code_44464000:"Rupsbanden",
   code_44470000:"Gietijzeren producten",
   code_44480000:"Diverse brandbeveiligingsuitrusting",
   code_44481000:"Hoogwerkers",
   code_44481100:"Brandladders",
   code_44482000:"Brandbeveiligingstoestellen",
   code_44482100:"Brandslangen",
   code_44482200:"Brandkranen",
   code_44500000:"Gereedschap, sloten, sleutels, scharnieren, sluitingen, ketting en veren",
   code_44510000:"Gereedschap",
   code_44511000:"Handgereedschap",
   code_44511100:"Spaden en schoppen",
   code_44511110:"Spaden",
   code_44511120:"Schoppen",
   code_44511200:"Tuingaffels",
   code_44511300:"Houwelen, pikhouwelen, hakken, harken en strandharken",
   code_44511310:"Houwelen",
   code_44511320:"Pikhouwelen",
   code_44511330:"Schoffels",
   code_44511340:"Harken",
   code_44511341:"Strandharken",
   code_44511400:"Bijlen",
   code_44511500:"Handzagen",
   code_44511510:"Bladen voor handzagen",
   code_44512000:"Divers handgereedschap",
   code_44512100:"Beitels",
   code_44512200:"Buigtangen",
   code_44512210:"Wurgtangen",
   code_44512300:"Hamers",
   code_44512400:"Draadklemmen",
   code_44512500:"Moersleutels",
   code_44512600:"Gereedschap voor wegenwerken",
   code_44512610:"Pinnen voor het doorboren van toplagen van wegen",
   code_44512700:"Vijlen of raspvijlen",
   code_44512800:"Schroevendraaiers",
   code_44512900:"Boorijzers, schroevendraaierbladen en ander toebehoren",
   code_44512910:"Boorijzers",
   code_44512920:"Schroevendraaierbladen",
   code_44512930:"Draagbaar opbergsysteem voor gereedschap",
   code_44512940:"Gereedschapskisten",
   code_44513000:"Gereedschap met pedaalbediening",
   code_44514000:"Handgrepen en delen van gereedschap",
   code_44514100:"Handgrepen van gereedschap",
   code_44514200:"Delen van gereedschap",
   code_44520000:"Sloten, sleutels en scharnieren",
   code_44521000:"Diverse hangsloten en sloten",
   code_44521100:"Sloten",
   code_44521110:"Deursloten",
   code_44521120:"Elektronisch veiligheidsslot",
   code_44521130:"Verhoogde-veiligheidsslot",
   code_44521140:"Sloten voor meubelen",
   code_44521200:"Hangsloten en kettingen",
   code_44521210:"Hangsloten",
   code_44522000:"Sluitingen, delen van sloten en sleutels",
   code_44522100:"Sluithaken",
   code_44522200:"Sleutels",
   code_44522300:"Delen van hangsloten",
   code_44522400:"Delen van sloten",
   code_44523000:"Scharnieren, bevestigingsmateriaal en beslag",
   code_44523100:"Scharnieren",
   code_44523200:"Bevestigingsmateriaal",
   code_44523300:"Beslag",
   code_44530000:"Bevestigingsmiddelen",
   code_44531000:"Bevestigingsmiddelen met schroefdraad",
   code_44531100:"Houtschroeven",
   code_44531200:"Schroefhaken of schroefringen",
   code_44531300:"Zelftappende schroeven",
   code_44531400:"Bouten",
   code_44531500:"Flensverbindingsstukken",
   code_44531510:"Bouten en schroeven",
   code_44531520:"Slotbouten en kraagschroeven",
   code_44531600:"Moeren",
   code_44531700:"IJzeren of stalen bevestigingsmiddelen met schroefdraad",
   code_44532000:"Sluitartikelen zonder schroefdraad",
   code_44532100:"Klinknagels",
   code_44532200:"Sluitringen",
   code_44532300:"Splitpennen",
   code_44532400:"Stalen lasplaten",
   code_44533000:"Bevestigingsmiddelen van koper",
   code_44540000:"Ketting",
   code_44541000:"Gelede ketting",
   code_44542000:"Kettingdelen",
   code_44550000:"Veren",
   code_44600000:"Tanks, reservoirs en containers; radiatoren en ketels voor centrale verwarming",
   code_44610000:"Tanks, reservoirs, containers en drukvaten",
   code_44611000:"Tanks",
   code_44611100:"Luchtflessen",
   code_44611110:"Persluchtflessen",
   code_44611200:"Ademhalingsapparatuur",
   code_44611400:"Opslagtanks",
   code_44611410:"Olietanks",
   code_44611420:"Slibopslagtanks",
   code_44611500:"Watertanks",
   code_44611600:"Reservoirs",
   code_44612000:"Flessen voor lpg",
   code_44612100:"Gasflessen",
   code_44612200:"Gastanks",
   code_44613000:"Grote containers",
   code_44613110:"Silo's",
   code_44613200:"Koelcontainers",
   code_44613210:"Waterreservoirs",
   code_44613300:"Standaard vrachtcontainers",
   code_44613400:"Opslagcontainers",
   code_44613500:"Watercontainers",
   code_44613600:"Verrijdbare containers",
   code_44613700:"Vuilnisbakken",
   code_44613800:"Containers voor afvalmateriaal",
   code_44614000:"Fusten",
   code_44614100:"Opslagbakken",
   code_44614300:"Containeropslagsysteem",
   code_44614310:"Stapelmachines",
   code_44615000:"Drukvaten",
   code_44615100:"Stalen drukvaten",
   code_44616000:"Trommels",
   code_44616200:"Afvalvaten",
   code_44617000:"Dozen",
   code_44617100:"Kartons",
   code_44617200:"Meterbehuizingen",
   code_44617300:"Prefabkasten",
   code_44618000:"Lichte containers, kurken, doppen voor grote flacons, tonnen en deksels",
   code_44618100:"Lichte containers",
   code_44618300:"Kurken, stoppen, doppen voor grote flacons, en deksels",
   code_44618310:"Kurken",
   code_44618320:"Stoppen",
   code_44618330:"Doppen voor houders",
   code_44618340:"Deksels",
   code_44618350:"Plastic doppen",
   code_44618400:"Blikjes",
   code_44618420:"Voedselblikken",
   code_44618500:"Vaten",
   code_44619000:"Overige opbergmiddelen",
   code_44619100:"Kisten",
   code_44619200:"Kabeltrommels",
   code_44619300:"Kratten",
   code_44619400:"Tonnen",
   code_44619500:"Palletkisten",
   code_44620000:"Radiatoren en ketels en onderdelen voor centrale verwarming",
   code_44621000:"Radiatoren en ketels",
   code_44621100:"Radiatoren",
   code_44621110:"Radiatoren voor centrale verwarming",
   code_44621111:"Niet-elektrisch verwarmde radiatoren",
   code_44621112:"Delen van radiatoren voor centrale verwarming",
   code_44621200:"Verwarmingsketels",
   code_44621210:"Waterkokers",
   code_44621220:"Ketels voor centrale verwarming",
   code_44621221:"Delen van ketels voor centrale verwarming",
   code_44622000:"Warmteterugwinningssystemen",
   code_44622100:"Warmteterugwinningsuitrusting",
   code_44800000:"Verf, vernis en mastiek",
   code_44810000:"Verf",
   code_44811000:"Wegenverf",
   code_44812000:"Verf voor kunstschilders",
   code_44812100:"Email en glazuur",
   code_44812200:"Olie- en waterverf",
   code_44812210:"Olieverf",
   code_44812220:"Waterverf",
   code_44812300:"Verf voor onderwijsdoeleinden",
   code_44812310:"Verfdozen",
   code_44812320:"Verf voor het schilderen van uithangborden",
   code_44812400:"Decoratieartikelen",
   code_44820000:"Vernis",
   code_44830000:"Mastiek, vulstoffen, stopverf en oplosmiddelen",
   code_44831000:"Mastiek, vulstoffen, stopverf",
   code_44831100:"Mastiek",
   code_44831200:"Vulmiddelen",
   code_44831300:"Stopverf",
   code_44831400:"Vulspecie",
   code_44832000:"Oplosmiddelen",
   code_44832100:"Verfafbijtmiddel",
   code_44832200:"Verdunningsmiddelen",
   code_44900000:"Steen voor de bouw, kalksteen, gips en lei",
   code_44910000:"Steen voor de bouw",
   code_44911000:"Marmer en kalksteenachtige steen voor de bouw",
   code_44911100:"Marmer",
   code_44911200:"Travertijn",
   code_44912000:"Diverse bouwstenen",
   code_44912100:"Graniet",
   code_44912200:"Zandsteen",
   code_44912300:"Basalt",
   code_44912400:"Trottoirbanden",
   code_44920000:"Kalksteen, gips en krijt",
   code_44921000:"Kalksteen en gips",
   code_44921100:"Gips",
   code_44921200:"Kalk",
   code_44921210:"Poederkalk",
   code_44921300:"Kalksteen",
   code_44922000:"Krijt en dolomiet",
   code_44922100:"Krijt (gesteente)",
   code_44922200:"Dolomiet",
   code_44930000:"Leisteen",
   code_45000000:"Bouwwerkzaamheden",
   code_45100000:"Bouwrijp maken van terreinen",
   code_45110000:"Slopen en ontmantelen van gebouwen, en grondverzet",
   code_45111000:"Sloopwerkzaamheden, bouwrijp maken en ruimen van bouwterreinen",
   code_45111100:"Sloopwerkzaamheden",
   code_45111200:"Bouwrijp maken en ruimen van bouwterreinen",
   code_45111210:"Steenvrij maken door middel van explosieven en dergelijke",
   code_45111211:"Explosiewerkzaamheden",
   code_45111212:"Steenvrij maken",
   code_45111213:"Ruimen van bouwterreinen",
   code_45111214:"Ruimen door explosie",
   code_45111220:"Verwijderen van begroeiing op bouwterreinen",
   code_45111230:"Grondstabilisatie",
   code_45111240:"Gronddrainage",
   code_45111250:"Uitvoering van bodemonderzoek",
   code_45111260:"Terreinvoorbereiding voor mijnbouw",
   code_45111290:"Voorbereidende werkzaamheden voor leidingen",
   code_45111291:"Inrichten van de bouwplaats",
   code_45111300:"Demontagewerkzaamheden",
   code_45111310:"Demontagewerkzaamheden voor militaire installaties",
   code_45111320:"Demontagewerkzaamheden voor veiligheidsinstallaties",
   code_45112000:"Graafwerkzaamheden en grondverzet",
   code_45112100:"Graven van geulen",
   code_45112200:"Afgraven van grond",
   code_45112210:"Afgraven van teelaarde",
   code_45112300:"Landophoging en -winning",
   code_45112310:"Landophoging",
   code_45112320:"Landwinning",
   code_45112330:"Ruimen van bouwterrein",
   code_45112340:"Bodemsanering",
   code_45112350:"Ontginnen van braakliggende grond",
   code_45112360:"Bodemherstel",
   code_45112400:"Graafwerkzaamheden",
   code_45112410:"Graven van grafkuilen",
   code_45112420:"Uitgraven van kelders",
   code_45112440:"Aanleggen van terrassen op berghellingen",
   code_45112441:"Aanleggen van terrassen",
   code_45112450:"Archeologisch opgravingswerk",
   code_45112500:"Grondverzet",
   code_45112600:"Uitgraven en opvullen",
   code_45112700:"Werkzaamheden voor landschapsarchitectuur",
   code_45112710:"Landschappelijk ontwerp voor groenzones",
   code_45112711:"Landschappelijk ontwerp voor parken",
   code_45112712:"Landschappelijk ontwerp voor tuinen",
   code_45112713:"Landschappelijke vormgeving van daktuinen",
   code_45112714:"Landschappelijk ontwerp voor begraafplaatsen",
   code_45112720:"Landschappelijk ontwerp voor sport- en recreatieterreinen",
   code_45112721:"Landschappelijk ontwerp voor golfbanen",
   code_45112722:"Landschappelijk ontwerp voor paardrijterreinen",
   code_45112723:"Landschappelijk ontwerp voor speelterreinen",
   code_45112730:"Landschappelijke vormgeving van wegen en snelwegen",
   code_45112740:"Landschappelijke vormgeving op luchthavens",
   code_45113000:"Werken op bouwterrein",
   code_45120000:"Uitvoeren van proefboringen",
   code_45121000:"Proefboren",
   code_45122000:"Maken van proefboorgaten",
   code_45200000:"Volledige of gedeeltelijke bouw- en civieltechnische werkzaamheden",
   code_45210000:"Bouwen van gebouwen",
   code_45211000:"Bouwwerkzaamheden voor meergezins- en eengezinswoningen",
   code_45211100:"Werkzaamheden voor huizenbouw",
   code_45211200:"Bouwwerkzaamheden voor aangepaste woningen",
   code_45211300:"Bouwen van woningen",
   code_45211310:"Bouwen van badkamers",
   code_45211320:"Bouwen van veranda's",
   code_45211340:"Bouwen van meergezinswoningen",
   code_45211341:"Bouwen van flatgebouwen",
   code_45211350:"Bouwen van multifunctionele gebouwen",
   code_45211360:"Bouwwerkzaamheden voor stadsontwikkeling",
   code_45211370:"Bouwen van sauna's",
   code_45212000:"Gebouwen voor vrijetijdsbesteding, sport, cultuur, logies en restaurant",
   code_45212100:"Bouwen van vrijetijdsvoorzieningen",
   code_45212110:"Bouwen van vrijetijdscentrum",
   code_45212120:"Bouwen van themapark",
   code_45212130:"Bouwen van pretpark",
   code_45212140:"Recreatiefaciliteiten",
   code_45212150:"Bouwen van bioscoop",
   code_45212160:"Bouwen van casino",
   code_45212170:"Bouwen van gebouw voor ontspanningsdoeleinden",
   code_45212171:"Bouwen van vermaakcentrum",
   code_45212172:"Bouwen van recreatiecentrum",
   code_45212180:"Bouwen van kaartverkooppunten",
   code_45212190:"Aanbrengen van zonwering",
   code_45212200:"Bouwen van sportfaciliteiten",
   code_45212210:"Bouwen van specifieke sportvoorzieningen",
   code_45212211:"Bouwen van ijsbaan",
   code_45212212:"Bouwwerkzaamheden voor zwembad",
   code_45212213:"Aanbrengen van sportmarkeringen",
   code_45212220:"Bouwen van multisportvoorzieningen",
   code_45212221:"Bouwwerkzaamheden voor installaties voor sportterrein",
   code_45212222:"Bouwen van gymnastiekzaal",
   code_45212223:"Bouwen van wintersportvoorzieningen",
   code_45212224:"Bouwen van stadion",
   code_45212225:"Bouwen van sporthal",
   code_45212230:"Installeren van kleedkamers",
   code_45212290:"Reparatie en onderhoud van sportfaciliteiten",
   code_45212300:"Bouwen van gebouwen voor kunst en cultuur",
   code_45212310:"Gebouwen voor tentoonstellingen",
   code_45212311:"Bouwen van kunstgalerij",
   code_45212312:"Bouwen van tentoonstellingscentrum",
   code_45212313:"Bouwen van museum",
   code_45212314:"Bouwwerkzaamheden aan historisch monument of gedenkteken",
   code_45212320:"Gebouwen voor beeldende en uitvoerende kunst",
   code_45212321:"Bouwen van concertzaal",
   code_45212322:"Bouwen van theater",
   code_45212330:"Bouwen van bibliotheek",
   code_45212331:"Bouwen van mediatheek",
   code_45212340:"Bouwen van hoorzaal",
   code_45212350:"Gebouwen met bijzondere historische of architectonische waarde",
   code_45212351:"Bouwwerkzaamheden aan prehistorisch monument",
   code_45212352:"Bouwwerkzaamheden aan industrieel monument",
   code_45212353:"Bouwwerkzaamheden aan paleis",
   code_45212354:"Bouwwerkzaamheden aan kasteel",
   code_45212360:"Bouwen van religieus gebouw",
   code_45212361:"Bouwen van kerk",
   code_45212400:"Accommodatie- en restaurantgebouwen",
   code_45212410:"Bouwen van gebouwen met logiesaccommodatie",
   code_45212411:"Bouwen van hotel",
   code_45212412:"Bouwen van jeugdherberg",
   code_45212413:"Bouwen van accommodatie voor kort verblijf",
   code_45212420:"Bouwen van restaurants en dergelijke",
   code_45212421:"Bouwen van restaurant",
   code_45212422:"Bouwen van kantine",
   code_45212423:"Bouwen van cafeteria",
   code_45212500:"Verbouwing van keuken of restaurant",
   code_45212600:"Bouwen van paviljoen",
   code_45213000:"Gebouwen met een commerciële functie, pakhuizen en industriële gebouwen, en gebouwen in verband met transport",
   code_45213100:"Bouwen van gebouwen voor commerciële doeleinden",
   code_45213110:"Bouwen van winkels",
   code_45213111:"Bouwen van winkelcentrum",
   code_45213112:"Bouwen van winkeleenheden",
   code_45213120:"Bouwen van postkantoor",
   code_45213130:"Bouwen van bank",
   code_45213140:"Bouwen van markt",
   code_45213141:"Bouwen van overdekte markt",
   code_45213142:"Bouwen van openluchtmarkt",
   code_45213150:"Bouwen van kantoorpand",
   code_45213200:"Bouwen van opslagplaatsen en fabrieken",
   code_45213210:"Installaties voor koelruimten",
   code_45213220:"Bouwen van opslagloodsen",
   code_45213221:"Bouwen van magazijnen",
   code_45213230:"Bouwen van slachthuis",
   code_45213240:"Bouwen van landbouwgebouwen",
   code_45213241:"Bouwen van schuur",
   code_45213242:"Bouwen van koeienstal",
   code_45213250:"Bouwen van industriegebouwen",
   code_45213251:"Bouwen van industrie-eenheden",
   code_45213252:"Bouwen van werkplaatsen",
   code_45213260:"Bouwen van opslagruimten",
   code_45213270:"Bouwwerkzaamheden recyclingcentrum",
   code_45213280:"Bouwwerkzaamheden voor composteerinrichting",
   code_45213300:"Gebouwen in verband met vervoersdiensten",
   code_45213310:"Gebouwen in verband met vervoer over de weg",
   code_45213311:"Bouwen van busstation",
   code_45213312:"Bouwen van parkeergarage",
   code_45213313:"Bouwen van wegrestaurant met benzinestation",
   code_45213314:"Bouwen van busremise",
   code_45213315:"Bouwen van bushokje",
   code_45213316:"Installatiewerkzaamheden voor voetpaden",
   code_45213320:"Gebouwen in verband met vervoer per spoor",
   code_45213321:"Bouwen van spoorstation",
   code_45213322:"Bouwen van kopstation",
   code_45213330:"Gebouwen in verband met vervoer per vliegtuig",
   code_45213331:"Bouwen van luchthavengebouwen",
   code_45213332:"Bouwen van luchthaven-controletoren",
   code_45213333:"Installatiewerkzaamheden voor incheckbalies op luchthaven",
   code_45213340:"Gebouwen in verband met vervoer over water",
   code_45213341:"Bouwen van veerbootterminal",
   code_45213342:"Bouwen van ro-ro-terminal",
   code_45213350:"Gebouwen in verband met verschillende middelen van transport",
   code_45213351:"Bouwen van onderhoudshangar",
   code_45213352:"Bouwen van dienstopslagruimte",
   code_45213353:"Installatiewerkzaamheden voor passagiersgangways",
   code_45213400:"Installeren van personeelsruimten",
   code_45214000:"Gebouwen voor onderwijs en onderzoek",
   code_45214100:"Kleuterschoolgebouwen",
   code_45214200:"Schoolgebouwen",
   code_45214210:"Bouwen van basisschool",
   code_45214220:"Bouwen van middelbare school",
   code_45214230:"Bouwen van speciale school",
   code_45214300:"Bouwwerkzaamheden voor school voor hoger onderwijs",
   code_45214310:"Bouwen van school voor beroepsonderwijs",
   code_45214320:"Bouwen van school voor hoger technisch onderwijs",
   code_45214400:"Bouwwerkzaamheden voor universiteit",
   code_45214410:"Bouwen van polytechnische school",
   code_45214420:"Bouwen van collegezaal",
   code_45214430:"Bouwen van talenpracticum",
   code_45214500:"Gebouwen voor voortgezet onderwijs",
   code_45214600:"Gebouwen voor wetenschappelijk onderzoek",
   code_45214610:"Bouwen van laboratorium",
   code_45214620:"Bouwen van onderzoek- en testruimten",
   code_45214630:"Wetenschappelijke installaties",
   code_45214631:"Installatiewerkzaamheden voor steriele ruimten",
   code_45214640:"Bouwen van weerstations",
   code_45214700:"Studentenhuizen",
   code_45214710:"Bouwen van ingangshal",
   code_45214800:"Gebouw voor opleidingsvoorzieningen",
   code_45215000:"Gebouwen in verband met gezondheidszorg en sociale dienstverlening, crematorium en openbare toiletten",
   code_45215100:"Gebouwen voor de gezondheidszorg",
   code_45215110:"Bouwen van kuurbad",
   code_45215120:"Bouwen van speciaal medisch gebouw",
   code_45215130:"Bouwen van kliniek",
   code_45215140:"Bouwen van ziekenhuisfaciliteiten",
   code_45215141:"Bouwen van operatiekamer",
   code_45215142:"Bouwen van intensivecareafdeling",
   code_45215143:"Bouwen van onderzoekkamer voor diagnostische doeleinden",
   code_45215144:"Bouwen van onderzoekkamers",
   code_45215145:"Bouwen van fluoroscopieruimte",
   code_45215146:"Bouwen van pathologieruimte",
   code_45215147:"Bouwen van forensiche ruimte",
   code_45215148:"Bouwen van catheterruimte",
   code_45215200:"Gebouwen voor sociale dienstverlening",
   code_45215210:"Bouwen van tehuizen",
   code_45215212:"Bouwen van bejaardenhuis",
   code_45215213:"Bouwen van verzorgingstehuis",
   code_45215214:"Bouwen van tehuis",
   code_45215215:"Bouwen van kindertehuis",
   code_45215220:"Gebouwen voor sociale zorg, met uitzondering van tehuizen",
   code_45215221:"Bouwen van dagopvangcentrum",
   code_45215222:"Bouwen van bestuurscentrum",
   code_45215300:"Bouwwerkzaamheden voor crematoria",
   code_45215400:"Werkzaamheden voor begraafplaats",
   code_45215500:"Openbare toiletten",
   code_45216000:"Gebouwen voor rechts- of ordehandhaving of noodhulpdiensten, en militaire gebouwen",
   code_45216100:"Gebouwen voor diensten belast met de rechts- of ordehandhaving of voor hulpdiensten",
   code_45216110:"Gebouwen voor rechts- of ordehandhaving",
   code_45216111:"Bouwen van politiebureau",
   code_45216112:"Bouwen van gerechtshof",
   code_45216113:"Bouwen van gevangenis",
   code_45216114:"Gebouwen voor parlements- en openbare vergaderingen",
   code_45216120:"Gebouwen voor noodhulpdiensten",
   code_45216121:"Bouwen van brandweerkazerne",
   code_45216122:"Bouwen van ambulancecentrum",
   code_45216123:"Bouwen van gebouw voor bergreddingsbrigade",
   code_45216124:"Bouwen van reddingsboothangar",
   code_45216125:"Bouwen van gebouw voor nooddiensten",
   code_45216126:"Bouwen van kustwachtgebouw",
   code_45216127:"Bouwen van gebouw voor hulpdienst",
   code_45216128:"Bouwen van vuurtoren",
   code_45216129:"Schuilplaatsen",
   code_45216200:"Bouwen van militaire gebouwen en installaties",
   code_45216220:"Bouwen van militaire bunkers",
   code_45216230:"Bouwen van militaire schuilkelders",
   code_45216250:"Bouwen van loopgraven",
   code_45217000:"Opbouwen van opblaasbare gebouwen",
   code_45220000:"Civieltechnische en bouwwerkzaamheden",
   code_45221000:"Bouwen van bruggen en tunnels, schachten en ondergrondse doorgangen",
   code_45221100:"Bruggenbouwwerkzaamheden",
   code_45221110:"Bruggenbouw",
   code_45221111:"Bouwen van verkeersbrug",
   code_45221112:"Bouwen van spoorbrug",
   code_45221113:"Bouwen van voetgangersbrug",
   code_45221114:"Bouwwerkzaamheden voor ijzeren bruggen",
   code_45221115:"Bouwwerkzaamheden voor stalen bruggen",
   code_45221117:"Bouwen van weegbrug",
   code_45221118:"Bouwen van pijpleidingbrug",
   code_45221119:"Brugrenovatiebouw",
   code_45221120:"Bouwen van viaduct",
   code_45221121:"Bouwen van verkeersviaduct",
   code_45221122:"Bouwen van spoorwegviaduct",
   code_45221200:"Bouwen van tunnels, schachten en ondergrondse doorgangen",
   code_45221210:"Geheel of gedeeltelijk overdekte graafwerkzaamheden",
   code_45221211:"Onderdoorgang",
   code_45221213:"Graafwerkzaamheden voor geheel of gedeeltelijk overdekte spoorweg",
   code_45221214:"Graafwerkzaamheden voor geheel of gedeeltelijk overdekte weg",
   code_45221220:"Duikers",
   code_45221230:"Schachten",
   code_45221240:"Bouwen van tunnels",
   code_45221241:"Bouwen van verkeerstunnel",
   code_45221242:"Bouwen van spoorwegtunnel",
   code_45221243:"Bouwen van voetgangerstunnel",
   code_45221244:"Bouwen van kanaaltunnel",
   code_45221245:"Bouwen van riviertunnel",
   code_45221246:"Bouwen van onderzeetunnel",
   code_45221247:"Tunnelbouwwerkzaamheden",
   code_45221248:"Aanbrengen van tunnelbekleding",
   code_45221250:"Ondergrondse werkzaamheden, met uitzondering van tunnels, schachten en onderdoorgangen",
   code_45222000:"Civieltechnische werkzaamheden, met uitzondering van bruggen, tunnels, schachten en ondergrondse doorgangen",
   code_45222100:"Bouwen van afvalverwerkingsinstallatie",
   code_45222110:"Bouwen van afvalstortplaats",
   code_45222200:"Civiele techniek voor militaire installaties",
   code_45222300:"Civiele techniek voor veiligheidsinstallaties",
   code_45223000:"Assemblagewerkzaamheden",
   code_45223100:"Monteren van metalen constructies",
   code_45223110:"Installeren van metalen constructies",
   code_45223200:"Structuurbouw",
   code_45223210:"Constructiestaalbouw",
   code_45223220:"Ruwbouw",
   code_45223300:"Bouwen van parkeerplaats",
   code_45223310:"Bouwen van ondergrondse parkeerplaats",
   code_45223320:"Bouwen van P + R-voorziening",
   code_45223400:"Bouwen van radarstation",
   code_45223500:"Gewapendbetonstructuren",
   code_45223600:"Bouwen van hondenkennels",
   code_45223700:"Bouwen van dienstencentrum",
   code_45223710:"Bouwen van dienstencentrum langs de snelweg",
   code_45223720:"Bouwen van tankstation",
   code_45223800:"Monteren en bouwen van prefabconstructies",
   code_45223810:"Prefab-constructies",
   code_45223820:"Prefabeenheden en -onderdelen",
   code_45223821:"Prefabelementen",
   code_45223822:"Prefabcomponenten",
   code_45230000:"Aanleggen van pijpleidingen, communicatielijnen en stroomleidingen, autowegen, wegen, vliegvelden en spoorlijnen; verhardingen",
   code_45231000:"Aanleg van pijpleidingen, communicatielijnen en elektriciteitsleidingen",
   code_45231100:"Algemene bouwwerkzaamheden voor pijpleidingen",
   code_45231110:"Bouwen van pijpleidingen",
   code_45231111:"Opheffen en herleggen van pijpleiding",
   code_45231112:"Aanleggen van buisleidingen",
   code_45231113:"Herleggen van pijpleidingen",
   code_45231200:"Bouwen van olie- en gaspijpleidingen",
   code_45231210:"Bouwen van oliepijpleidingen",
   code_45231220:"Bouwen van gaspijpleidingen",
   code_45231221:"Aanleggen van gastoevoerleiding",
   code_45231222:"Werkzaamheden aan gasreservoirs",
   code_45231223:"Aanvullende werkzaamheden voor gasdistributie",
   code_45231300:"Bouwen van pijpleidingen voor water en afvalwater",
   code_45231400:"Bouwwerkzaamheden voor hoogspanningsleidingen",
   code_45231500:"Werkzaamheden voor persluchtleidingen",
   code_45231510:"Werkzaamheden voor pneumatisch circuit van postbezorging",
   code_45231600:"Bouwwerkzaamheden voor communicatielijnen",
   code_45232000:"Aanvullende werkzaamheden voor pijpleidingen en kabels",
   code_45232100:"Aanvullende werkzaamheden voor waterpijpleidingen",
   code_45232120:"Irrigatiewerken",
   code_45232121:"Aanleggen van irrigatieleidingen",
   code_45232130:"Aanleggen van hemelwaterafavoerleidingen",
   code_45232140:"Aanleggen van stadsverwarmingsleidingen",
   code_45232141:"Aanleg van verwarming",
   code_45232142:"Bouwen van een warmteoverdrachtstation",
   code_45232150:"Werkzaamheden in verband met waterleidingen",
   code_45232151:"Werkzaamheden voor het verbeteren van waterleidingen",
   code_45232152:"Bouwen van pompstation",
   code_45232153:"Bouwwerkzamheden voor watertorens",
   code_45232154:"Bouwen van hooggeplaatste drinkwaterreservoirs",
   code_45232200:"Aanvullende werken voor hoogspanningsleidingen",
   code_45232210:"Aanleg van bovengrondse leidingen",
   code_45232220:"Bouwen van verdeelstation",
   code_45232221:"Transformatoronderstation",
   code_45232300:"Aanleg van en aanvullende werkzaamheden voor telefoon- en communicatieleidingen",
   code_45232310:"Bouwwerkzaamheden voor telefoonlijnen",
   code_45232311:"Telefoonlijnen voor praatpalen",
   code_45232320:"Kabelomroeplijnen",
   code_45232330:"Opstellen van antennes",
   code_45232331:"Aanvullende werkzaamheden voor omroepwerk",
   code_45232332:"Aanvullende werkzaamheden voor telecommunicatie",
   code_45232340:"Bouwen van basisstations voor mobiele telefonie",
   code_45232400:"Bouwwerkzaamheden voor riool",
   code_45232410:"Waterzuiveringswerkzaamheden",
   code_45232411:"Aanleggen van afvalwaterleidingen",
   code_45232420:"Bouwwerkzaamheden voor rioolwaterzuiveringsinstallaties",
   code_45232421:"Rioolwaterbehandelingsinstallatie",
   code_45232422:"Slibbehandelingsinstallatie",
   code_45232423:"Bouwen van pompstation voor afvalwater",
   code_45232424:"Bouwen van rioolwaterpompstation",
   code_45232430:"Waterbehandelingswerkzaamheden",
   code_45232431:"Afvalwaterpompstation",
   code_45232440:"Aanleggen van rioleringsbuizen",
   code_45232450:"Bouwwerkzaamheden voor afwatering",
   code_45232451:"Afwaterings- en oppervlaktewerkzaamheden",
   code_45232452:"Afwateringswerkzaamheden",
   code_45232453:"Aanleggen van drainage",
   code_45232454:"Bouwen van regenwateropvangbekken",
   code_45232460:"Sanitaire installaties",
   code_45232470:"Afvaloverslagstation",
   code_45233000:"Aanleg van snelwegen en wegen",
   code_45233100:"Bouwwerkzaamheden voor hoofdwegen en wegen",
   code_45233110:"Aanleggen van snelweg",
   code_45233120:"Wegenbouwwerken",
   code_45233121:"Aanleg van hoofdweg",
   code_45233122:"Bouwen van ringweg",
   code_45233123:"Bouwen van secundaire weg",
   code_45233124:"Bouwen van hoofdweg",
   code_45233125:"Bouwen van wegkruising",
   code_45233126:"Bouwen van ongelijkvloerse kruising",
   code_45233127:"Bouwen van T-kruising",
   code_45233128:"Bouwen van rotonde",
   code_45233129:"Bouwen van kruispunt",
   code_45233130:"Bouwwerkzaamheden voor hoofdwegen",
   code_45233131:"Bouwwerkzaamheden voor verhoogde hoofdwegen",
   code_45233139:"Onderhoud van hoofdwegen",
   code_45233140:"Wegwerkzaamheden",
   code_45233141:"Wegenonderhoud",
   code_45233142:"Herstellen van wegen",
   code_45233144:"Bouwen van bovengrondse kruising",
   code_45233150:"Werkzaamheden voor vermindering van verkeersaanbod",
   code_45233160:"Paden en andere verharde oppervlakken",
   code_45233161:"Aanleggen van trottoirs",
   code_45233162:"Aanleggen van fietspad",
   code_45233200:"Diverse oppervlaktewerkzaamheden",
   code_45233210:"Wegdekwerkzaamheden voor hoofdwegen",
   code_45233220:"Wegdekwerkzaamheden voor wegen",
   code_45233221:"Aanbrengen van wegmarkeringen",
   code_45233222:"Bestratings- en asfalteerwerkzaamheden",
   code_45233223:"Vernieuwen van rijbaanwegdek",
   code_45233224:"Aanleggen van dubbele rijbaan",
   code_45233225:"Aanleggen van enkele rijbaan",
   code_45233226:"Bouwen van toegangsweg",
   code_45233227:"Bouwen van in/uitvoegstroken",
   code_45233228:"Uitvoering van oppervlaktebekleding",
   code_45233229:"Onderhoud van bermen",
   code_45233250:"Aanbrengen van verhardingen, met uitzondering van wegdek",
   code_45233251:"Vernieuwen van wegdek",
   code_45233252:"Wegdekwerkzaamheden voor straten",
   code_45233253:"Wegdekwerkzaamheden voor voetpaden",
   code_45233260:"Bouwen van voetwegen",
   code_45233261:"Bouwen van voetgangersovergang",
   code_45233262:"Aanleggen van voetgangerszone",
   code_45233270:"Schilderen van markering op parkeerterrein",
   code_45233280:"Plaatsen van middenbermbeveiliging",
   code_45233290:"Plaatsen van verkeersborden",
   code_45233291:"Plaatsen van verkeerszuiltjes",
   code_45233292:"Installatie van beveiligingsuitrusting",
   code_45233293:"Plaatsen van straatmeubilair",
   code_45233294:"Plaatsen van wegsignalisatie",
   code_45233300:"Funderingswerkzaamheden voor (hoofd)wegen, straten en voetpaden",
   code_45233310:"Funderingswerkzaamheden voor hoofdwegen",
   code_45233320:"Funderingswerkzaamheden voor wegen",
   code_45233330:"Funderingswerkzaamheden voor straten",
   code_45233340:"Funderingswerkzaamheden voor voetpaden",
   code_45234000:"Bouwwerkzaamheden voor spoorwegen en kabeltransportsystemen",
   code_45234100:"Aanleg van spoorweg",
   code_45234110:"Werkzaamheden aan intercityspoorwegen",
   code_45234111:"Bouwen van stadsspoor",
   code_45234112:"Bouwen van spoorremise",
   code_45234113:"Ontmantelen van sporen",
   code_45234114:"Bouwen van spoordijk",
   code_45234115:"Werkzaamheden voor spoorseinen",
   code_45234116:"Leggen van sporen",
   code_45234120:"Stadsspoorwegwerkzaamheden",
   code_45234121:"Werkzaamheden voor trambaan",
   code_45234122:"Werkzaamheden voor metro",
   code_45234123:"Werkzaamheden voor gedeeltelijk ondergrondse spoorweg",
   code_45234124:"Passagiersvervoer per metro",
   code_45234125:"Metrostation",
   code_45234126:"Aanleg van tramlijn",
   code_45234127:"Bouwen van tramremise",
   code_45234128:"Bouwen van tramperrons",
   code_45234129:"Aanleg van stadsspoorlijn",
   code_45234130:"Aanbrengen van ballast",
   code_45234140:"Bouwen van gelijkvloerse kruising",
   code_45234160:"Maken van bovenleidingen",
   code_45234170:"Bouwen van baanverdeelstations",
   code_45234180:"Bouwen van spoorwerkplaats",
   code_45234181:"Bouwen van spoorlijnschakelposten",
   code_45234200:"Kabelbaansystemen",
   code_45234210:"Kabelbaansystemen met cabines",
   code_45234220:"Bouwen van skiliften",
   code_45234230:"Bouwen van stoeltjesliften",
   code_45234240:"Kabelspoorsysteem",
   code_45234250:"Bouwen van luchtkabelbaan",
   code_45235000:"Bouwwerkzaamheden voor luchthavens, start- en landingsbanen en manoeuvreerplatforms",
   code_45235100:"Bouwwerkzaamheden voor luchthavens",
   code_45235110:"Bouwwerkzaamheden voor vliegvelden",
   code_45235111:"Aanleggen van wegdek op luchthaven",
   code_45235200:"Bouwwerkzaamheden voor start- of landingsbanen",
   code_45235210:"Vernieuwen van wegdek van start- of landingsbaan",
   code_45235300:"Bouwwerkzaamheden voor manoeuvreerplatforms voor vliegtuigen",
   code_45235310:"Bouwen van taxibaan",
   code_45235311:"Aanleggen van wegdek voor luchthaventaxibaan",
   code_45235320:"Bouwwerkzaamheden voor parkeerplatforms voor vliegtuigen",
   code_45236000:"Aanbrengen van verharding",
   code_45236100:"Verharding voor diverse sportvoorzieningen",
   code_45236110:"Verharding voor sportvelden",
   code_45236111:"Verharding voor golfbaan",
   code_45236112:"Verharding voor tennisbaan",
   code_45236113:"Verharding voor renbaan",
   code_45236114:"Verharding voor atletiekbaan",
   code_45236119:"Herstelwerkzaamheden op sportvelden",
   code_45236200:"Verharding voor recreatievoorzieningen",
   code_45236210:"Verharding voor kinderspeelplaats",
   code_45236220:"Verharding voor dierentuin",
   code_45236230:"Verharding voor tuinen",
   code_45236250:"Verharding voor parken",
   code_45236290:"Herstelwerkzaamheden aan recreatiegebieden",
   code_45236300:"Verharding voor begraafplaatsen",
   code_45237000:"Bouwen van toneel/platforms",
   code_45240000:"Waterbouwwerkzaamheden",
   code_45241000:"Havenbouwwerkzaamheden",
   code_45241100:"Bouwen van kades",
   code_45241200:"Bouw van offshoreterminals in situ",
   code_45241300:"Bouwen van pieren",
   code_45241400:"Bouwen van dokken",
   code_45241500:"Bouwen van scheepswerf",
   code_45241600:"Installeren van havenverlichtingsuitrusting",
   code_45242000:"Bouwen van waterrecreatievoorzieningen",
   code_45242100:"Bouwen van watersportvoorzieningen",
   code_45242110:"Bouwen van scheepshelling",
   code_45242200:"Bouwen van marina",
   code_45242210:"Bouwen van jachthaven",
   code_45243000:"Kustverdedigingswerken",
   code_45243100:"Klipbeschermingswerken",
   code_45243110:"Klipstabilisatiewerken",
   code_45243200:"Bouwen van golfbreker",
   code_45243300:"Bouwen van strandmuur",
   code_45243400:"Werkzaamheden ter voorkoming van strandafslag",
   code_45243500:"Bouwen van zeewering",
   code_45243510:"Aanleg van dijken en kades",
   code_45243600:"Bouwen van kademuren",
   code_45244000:"Maritieme bouwwerkzaamheden",
   code_45244100:"Haveninstallaties",
   code_45244200:"Havensteigers",
   code_45245000:"Bagger- en pompwerkzaamheden voor waterzuiveringsinstallaties",
   code_45246000:"Werkzaamheden voor rivierdebietregeling en hoogwaterbeheersing",
   code_45246100:"Werkzaamheden voor rivierkanalisatie",
   code_45246200:"Oeverbeschermingswerken",
   code_45246400:"Werkzaamheden voor hoogwaterpreventie",
   code_45246410:"Onderhoud van hoogwaterkeringen",
   code_45246500:"Bouwen van promenade",
   code_45246510:"Bouwken van strandpromenade",
   code_45247000:"Bouwwerkzaamheden voor dammen, kanalen, irrigatiekanalen en aquaducten",
   code_45247100:"Bouwwerkzaamheden voor waterwegen",
   code_45247110:"Aanleg van kanaal",
   code_45247111:"Aanleggen van irrigatiekanalen",
   code_45247112:"Bouwen van waterafvoerkanaal",
   code_45247120:"Waterwegen, met uitzondering van kanalen",
   code_45247130:"Bouwen van aquaduct",
   code_45247200:"Bouwwerkzaamheden voor dammen en dergelijke vaste installaties",
   code_45247210:"Bouwen van dam",
   code_45247211:"Bouwen van damwal",
   code_45247212:"Damverstevigingswerken",
   code_45247220:"Bouwen van weer",
   code_45247230:"Bouwen van dijk",
   code_45247240:"Bouwen van vaste stuw",
   code_45247270:"Bouw van waterbekken",
   code_45248000:"Bouwwerkzaamheden voor hydromechanische installaties",
   code_45248100:"Bouwen van kanaalsluizen",
   code_45248200:"Bouwen van droogdokken",
   code_45248300:"Bouwwerkzaamheden voor drijvende dokken",
   code_45248400:"Bouwen van landingssteigers",
   code_45248500:"Bouwen van beweegbare stuwen",
   code_45250000:"Bouwwerkzaamheden voor krachtcentrale, mijnbouw en industrie, en voor gebouwen in verband met olie- en gasindustrie",
   code_45251000:"Bouwwerkzaamheden voor krachtcentrales en verwarmingsinstallaties",
   code_45251100:"Bouwwerkzaamheden voor krachtcentrale",
   code_45251110:"Bouwen van kerncentrale",
   code_45251111:"Bouwwerkzaamheden voor kernreactor",
   code_45251120:"Bouwen van waterkrachtcentrale",
   code_45251140:"Bouwen van thermische centrale",
   code_45251141:"Bouwen van geothermische centrale",
   code_45251142:"Bouwen van houtgestookte centrale",
   code_45251143:"Bouwen van persluchtinstallatie",
   code_45251150:"Bouwwerkzaamheden voor koeltorens",
   code_45251160:"Werkzaamheden aan windenergie-installaties",
   code_45251200:"Bouwen van verwarmingsinstallatie",
   code_45251220:"Bouwen van warmte-krachtcentrale",
   code_45251230:"Bouwen van stoominstallatie",
   code_45251240:"Bouwen van met stortplaatsgas gestookte elektriciteitscentrale",
   code_45251250:"Bouwen van stadsverwarmingsinstallatie",
   code_45252000:"Bouwwerkzaamheden voor afvalwaterbehandelings-, zuiverings- en afvalverbrandingsinstallaties",
   code_45252100:"Bouwen van rioolwaterzuiveringsinstallatie",
   code_45252110:"Bouwen van mobiele installatie",
   code_45252120:"Bouwen van waterbehandelinginstallatie",
   code_45252121:"Bezinkingsinstallaties",
   code_45252122:"Gistingstanks voor afvalwater",
   code_45252123:"Zeefinstallaties",
   code_45252124:"Bagger- en pompwerkzaamheden",
   code_45252125:"Storten van rotsblokken",
   code_45252126:"Bouwen van drinkwaterbehandelingsinstallatie",
   code_45252127:"Bouwen van afvalwaterbehandelingsinstallatie",
   code_45252130:"Uitrusting voor rioolwaterzuivering",
   code_45252140:"Bouwen van slibontwateringinstallatie",
   code_45252150:"Bouwen van steenkoolverwerkinginstallatie",
   code_45252200:"Uitrusting voor zuiveringsinstallatie",
   code_45252210:"Bouwen van waterzuiveringsinstallatie",
   code_45252300:"Bouwen van afvalverbrandingsinstallatie",
   code_45253000:"Bouwwerkzaamheden voor chemische fabriek",
   code_45253100:"Bouwen van demineralisatie-installatie",
   code_45253200:"Bouwen van ontzwavelingsinstallatie",
   code_45253300:"Bouwen van distilleer- en rectificeerinstallatie",
   code_45253310:"Bouwen van waterdistilleerinstallatie",
   code_45253320:"Bouwen van alcoholdistilleerinstallatie",
   code_45253400:"Bouwwerkzaamheden voor petrochemische industrie",
   code_45253500:"Bouwwerkzaamheden aan of voor farmaceutische industrie",
   code_45253600:"Bouwen van de-ionisatie-installatie",
   code_45253700:"Bouwen van vergistingsinstallatie",
   code_45253800:"Bouwen van composteerinstallatie",
   code_45254000:"Bouwwerkzaamheden voor mijnbouw en industrie",
   code_45254100:"Bouwwerkzaamheden voor mijnbouw",
   code_45254110:"Bouwen van mijnschacht",
   code_45254200:"Bouwwerkzaamheden voor fabriek",
   code_45255000:"Bouwwerkzaamheden voor de olie- en gasindustrie",
   code_45255100:"Bouwwerkzaamheden voor productieplatforms",
   code_45255110:"Bouwen van boorputten",
   code_45255120:"Bouwen van boorplatformfaciliteiten",
   code_45255121:"Bouwen van oppervlakte-installaties",
   code_45255200:"Bouwen van olieraffinaderij",
   code_45255210:"Bouwen van olieterminal",
   code_45255300:"Bouwen van gasterminal",
   code_45255400:"Montagewerkzaamheden",
   code_45255410:"Offshoremontagewerkzaamheden",
   code_45255420:"Onshoremontagewerkzaamheden",
   code_45255430:"Ontmantelen van olieplatforms",
   code_45255500:"Boor- en exploratiewerkzaamheden",
   code_45255600:"Werkzaamheden met spiraalbuis",
   code_45255700:"Bouwen van steenkoolvergassinginstallatie",
   code_45255800:"Bouwen van gasproductie-installatie",
   code_45259000:"Reparatie en onderhoud van installaties",
   code_45259100:"Reparatie en onderhoud van afvalwaterinstallatie",
   code_45259200:"Reparatie en onderhoud van zuiveringsinstallatie",
   code_45259300:"Reparatie en onderhoud van verwarmingsinstallatie",
   code_45259900:"Upgraden van installaties",
   code_45260000:"Dakdekkers- en andere gespecialiseerde bouwwerkzaamheden",
   code_45261000:"Installatie- en aanverwante werkzaamheden voor dakconstructies en -bedekking",
   code_45261100:"Dakconstructiewerkzaamheden",
   code_45261200:"Dakbedekking en schilderen van daken",
   code_45261210:"Dakdekkerswerkzaamheden",
   code_45261211:"Leggen van dakpannen",
   code_45261212:"Leidekken",
   code_45261213:"Aanbrengen van metalen dakbedekking",
   code_45261214:"Aanbrengen van bitumineuze dakbedekking",
   code_45261215:"Aanbrengen van dakbedekking met zonnepanelen",
   code_45261220:"Schilderen van daken en andere bekledingswerkzaamheden",
   code_45261221:"Schilderen van daken",
   code_45261222:"Aanbrengen van cementlagen op daken",
   code_45261300:"Aanbrengen van slabben en goten",
   code_45261310:"Aanbrengen van slabben",
   code_45261320:"Aanbrengen van goten",
   code_45261400:"Aanbrengen van beplating",
   code_45261410:"Dakisolatiewerkzaamheden",
   code_45261420:"Waterdicht maken",
   code_45261900:"Reparatie en onderhoud van daken",
   code_45261910:"Reparatie van daken",
   code_45261920:"Onderhoud van daken",
   code_45262000:"Gespecialiseerde bouwwerkzaamheden, met uitzondering van dakdekkerswerkzaamheden",
   code_45262100:"Steigerbouw",
   code_45262110:"Ontmantelen van steigers",
   code_45262120:"Bouwen van steigers",
   code_45262200:"Funderingswerkzaamheden en boren van waterputten",
   code_45262210:"Funderingswerkzaamheden",
   code_45262211:"Heien",
   code_45262212:"Aanbrengen van wandbekleding, beschotting, grondkeringen",
   code_45262213:"Diepwandmethode",
   code_45262220:"Boren van waterputten",
   code_45262300:"Betonwerk",
   code_45262310:"Betonwerk van gewapend beton",
   code_45262311:"Betoncascobouw",
   code_45262320:"Afreiwerken",
   code_45262321:"Afreien van vloeren",
   code_45262330:"Reparatie van betonwerk",
   code_45262340:"Injecteren van mortel",
   code_45262350:"Betonwerk van ongewapend beton",
   code_45262360:"Cementeerwerkzaamheden",
   code_45262370:"Aanbrengen van betonbekleding",
   code_45262400:"Optrekken van staalconstructies",
   code_45262410:"Optrekken van staalconstructies voor gebouwen",
   code_45262420:"Optrekken van staalconstructies voor installaties",
   code_45262421:"Aanleg van offshore-aanmeerplaatsen",
   code_45262422:"Onderwaterboringen",
   code_45262423:"Bouwen van platforms",
   code_45262424:"Fabricage van offshoremodule",
   code_45262425:"Fabricage van jacket",
   code_45262426:"Fabricage van palen",
   code_45262500:"Metselwerk in baksteen en bouwsteen",
   code_45262510:"Steenwerk",
   code_45262511:"Steenhouwen",
   code_45262512:"Behakt steenwerk",
   code_45262520:"Metselwerk in baksteen",
   code_45262521:"Bekledingsmetselwerk",
   code_45262522:"Metselwerk",
   code_45262600:"Diverse gespecialiseerde bouwwerkzaamheden",
   code_45262610:"Fabrieksschoorstenen",
   code_45262620:"Steunmuren",
   code_45262630:"Bouw van ovens",
   code_45262640:"Milieubeschermingswerkzaamheden",
   code_45262650:"Bekledingswerkzaamheden",
   code_45262660:"Verwijderen van asbest",
   code_45262670:"Metaalbewerking",
   code_45262680:"Lassen",
   code_45262690:"Opknapwerk van vervallen gebouwen",
   code_45262700:"Verbouwingswerkzaamheden",
   code_45262710:"Onderhoud van muurschilderingen",
   code_45262800:"Uitbreidingswerkzaamheden aan gebouwen",
   code_45262900:"Werkzaamheden aan balkons",
   code_45300000:"Installatiewerkzaamheden in de bouw",
   code_45310000:"Aanleg van elektriciteit",
   code_45311000:"Aanbrengen van elektrische bedrading en fittings",
   code_45311100:"Aanbrengen van elektrische bedrading",
   code_45311200:"Aanbrengen van elektrische fittings",
   code_45312000:"Installeren van alarmsystemen en antennes",
   code_45312100:"Installeren van brandalarmsystemen",
   code_45312200:"Installeren van inbraakalarmsystemen",
   code_45312300:"Installeren van antennes",
   code_45312310:"Installatie van bliksembeveiliging",
   code_45312311:"Installeren van bliksemafleiders",
   code_45312320:"Installeren van televisieantennes",
   code_45312330:"Installeren van radioantennes",
   code_45313000:"Installeren van liften en roltrappen",
   code_45313100:"Installeren van liften",
   code_45313200:"Installeren van roltrappen",
   code_45313210:"Installeren van loopbanden",
   code_45314000:"Installeren van telecommunicatie-uitrusting",
   code_45314100:"Installeren van telefooncentrales",
   code_45314120:"Installeren van schakelborden",
   code_45314200:"Installeren van telefoonlijnen",
   code_45314300:"Aanleg van kabelinfrastructuur",
   code_45314310:"Leggen van kabels",
   code_45314320:"Leggen van computerkabels",
   code_45315000:"Elektrische installatiewerkzaamheden voor verwarming en andere elektrische apparatuur in gebouwen",
   code_45315100:"Elektrotechnische installatiewerken",
   code_45315200:"Turbine-installatie",
   code_45315300:"Elektriciteitsvoorzieningsinstallaties",
   code_45315400:"Hoogspanningsinstallaties",
   code_45315500:"Middenspanningsinstallaties",
   code_45315600:"Laagspanningsinstallaties",
   code_45315700:"Installatiewerkzaamheden voor verdeelstation",
   code_45316000:"Installeren van verlichting en signaleringssystemen",
   code_45316100:"Installeren van buitenverlichting",
   code_45316110:"Installeren van straatverlichtingsuitrusting",
   code_45316200:"Installeren van signalisatie-uitrusting",
   code_45316210:"Installeren van verkeersbewakingsuitrusting",
   code_45316211:"Installeren van verlichte verkeersborden",
   code_45316212:"Installeren van verkeerslichten",
   code_45316213:"Installeren van wegbebakening",
   code_45316220:"Installeren van luchthavenbebakening",
   code_45316230:"Installeren van havenbebakening",
   code_45317000:"Overige elektrische installatiewerkzaamheden",
   code_45317100:"Elektrische installatiewerkzaamheden voor pompuitrusting",
   code_45317200:"Elektrische installatiewerkzaamheden voor transformatoren",
   code_45317300:"Elektrische installatiewerkzaamheden voor elektrische distributietoestellen",
   code_45317400:"Elektrische installatiewerkzaamheden voor filteruitrusting",
   code_45320000:"Isolatiewerkzaamheden",
   code_45321000:"Warmte-isolatiewerkzaamheden",
   code_45323000:"Geluidsisolatiewerkzaamheden",
   code_45324000:"Aanbrengen van gipsplaten",
   code_45330000:"Loodgieterswerk",
   code_45331000:"Installeren van verwarming, ventilatie en airconditioning",
   code_45331100:"Installeren van centrale verwarming",
   code_45331110:"Installeren van boilers",
   code_45331200:"Installeren van ventilatie en airconditioning",
   code_45331210:"Installeren van ventilatiesystemen",
   code_45331211:"Installeren van ventilatie-installaties voor buitenopstelling",
   code_45331220:"Installeren van airconditioning",
   code_45331221:"Installeren van gedeeltelijke airconditioning",
   code_45331230:"Installeren van koeluitrusting",
   code_45331231:"Installeren van koel- en vriesuitrusting",
   code_45332000:"Loodgieterswerk en leggen van afvoerbuizen",
   code_45332200:"Loodgieterswerkzaamheden aan waterleidingen",
   code_45332300:"Leggen van afvoerbuizen",
   code_45332400:"Installeren van sanitair",
   code_45333000:"Installeren van gasfittings",
   code_45333100:"Installeren van gasregeluitrusting",
   code_45333200:"Installeren van gasmeters",
   code_45340000:"Plaatsen van hekken en leuningen en beveiligingsuitrusting",
   code_45341000:"Plaatsen van leuningen",
   code_45342000:"Plaatsen van hekwerk",
   code_45343000:"Installeren van brandpreventie-installaties",
   code_45343100:"Aanbrengen van brandwering",
   code_45343200:"Installeren van brandblusinstallaties",
   code_45343210:"Installeren van CO2-blusapparatuur",
   code_45343220:"Installeren van brandblusapparaten",
   code_45343230:"Installeren van sprinklersystemen",
   code_45350000:"Werktuigbouwkundige installaties",
   code_45351000:"Werktuigbouwkundige installatiewerkzaamheden",
   code_45400000:"Afwerking van gebouwen",
   code_45410000:"Pleisterwerk",
   code_45420000:"Bouwtimmerwerk en plaatsen van schrijnwerk",
   code_45421000:"Bouwtimmerwerk",
   code_45421100:"Plaatsen van deuren en ramen en bijbehorende elementen",
   code_45421110:"Plaatsen van deur- en raamkozijnen",
   code_45421111:"Plaatsen van deurkozijnen",
   code_45421112:"Plaatsen van raamkozijnen",
   code_45421120:"Plaatsen van drempels",
   code_45421130:"Plaatsen van deuren en ramen",
   code_45421131:"Plaatsen van deuren",
   code_45421132:"Plaatsen van ramen",
   code_45421140:"Plaatsen van metalen bouwtimmerwerk, met uitzondering van deuren en ramen",
   code_45421141:"Plaatsen van scheidingswanden",
   code_45421142:"Installeren van luiken",
   code_45421143:"Installeren van jaloezieën",
   code_45421144:"Installeren van zonweringen",
   code_45421145:"Installeren van rolluiken",
   code_45421146:"Aanbrengen van verlaagde plafonds",
   code_45421147:"Aanbrengen van roosters",
   code_45421148:"Plaatsen van poorten",
   code_45421150:"Aanbrengen van niet-metalen bouwtimmerwerk",
   code_45421151:"Plaatsen van inbouwkeukens",
   code_45421152:"Aanbrengen van scheidingswanden",
   code_45421153:"Plaatsen van inbouwmeubilair",
   code_45421160:"Werken aan hang- en sluitwerk",
   code_45422000:"Timmer- en schrijnwerk",
   code_45422100:"Houtwerk",
   code_45430000:"Aanbrengen van vloer- en wandbekleding",
   code_45431000:"Tegelwerk",
   code_45431100:"Leggen van vloertegels",
   code_45431200:"Zetten van wandtegels",
   code_45432000:"Leggen van vloeren en aanbrengen van vloerbedekking, wandbekleding en behang",
   code_45432100:"Aanbrengen en afwerken van vloeren",
   code_45432110:"Leggen van vloeren",
   code_45432111:"Leggen van flexibele vloerbekleding",
   code_45432112:"Aanbrengen van bestrating",
   code_45432113:"Leggen van parketvloeren",
   code_45432114:"Leggen van houten vloerbedekkingen",
   code_45432120:"Aanbrengen van dubbele vloer",
   code_45432121:"Computervloeren",
   code_45432130:"Vloerbekledingswerk",
   code_45432200:"Aanbrengen van wandbekleding en behangwerk",
   code_45432210:"Aanbrengen van wandbekleding",
   code_45432220:"Behangen",
   code_45440000:"Schilderwerk en beglazing",
   code_45441000:"Beglazing",
   code_45442000:"Aanbrengen van beschermende lagen",
   code_45442100:"Schilderwerk",
   code_45442110:"Schilderen van gebouwen",
   code_45442120:"Schilderwerk en aanbrengen van beschermende lagen op installaties",
   code_45442121:"Schilderen van installaties",
   code_45442180:"Aanbrengen van nieuwe verf",
   code_45442190:"Verwijderen van oude verf",
   code_45442200:"Aanbrengen van anticorrosielagen",
   code_45442210:"Galvaniseren",
   code_45442300:"Werkzaamheden voor oppervlakbescherming",
   code_45443000:"Gevelwerkzaamheden",
   code_45450000:"Overige werkzaamheden in verband met de afwerking van gebouwen",
   code_45451000:"Decoratiewerkzaamheden",
   code_45451100:"Aanbrengen van ornamenten",
   code_45451200:"Aanbrengen van panelen",
   code_45451300:"Binnentuinen",
   code_45452000:"Reiniging van de buitenmuren van gebouwen",
   code_45452100:"Reinigen van buitenmuren door middel van zandstralen",
   code_45453000:"Revisie- en opknapwerkzaamheden",
   code_45453100:"Opknapwerkzaamheden",
   code_45454000:"Herstructurering",
   code_45454100:"Restauratiewerkzaamheden",
   code_45500000:"Verhuur van bouw- en civieltechnische installaties en uitrusting met bedieningspersoneel",
   code_45510000:"Verhuur van kranen met bedieningspersoneel",
   code_45520000:"Verhuur van grondverzetmachines met bedieningspersoneel",
   code_48000000:"Software en informatiesystemen",
   code_48100000:"Speciale software voor de industrie",
   code_48110000:"Software voor verkooppunten",
   code_48120000:"Software voor vluchtbesturing",
   code_48121000:"Software voor luchtverkeersleiding",
   code_48130000:"Software voor grondondersteuning in de luchtvaart en testsoftware",
   code_48131000:"Software voor grondondersteuning in de luchtvaart",
   code_48132000:"Luchtvaart-testsoftware",
   code_48140000:"Software voor spoorwegverkeerscontrole",
   code_48150000:"Software voor industriële controle",
   code_48151000:"Computerbesturingssysteem",
   code_48160000:"Software voor bibliotheek",
   code_48161000:"Systeem voor bibliotheekbeheer",
   code_48170000:"Conformiteitssoftware",
   code_48180000:"Medische software",
   code_48190000:"Software voor onderwijsdoeleinden",
   code_48200000:"Software voor netwerken, internet en intranet",
   code_48210000:"Netwerksoftware",
   code_48211000:"Software voor platforminterconnectiviteit",
   code_48212000:"Software voor optical jukebox-server",
   code_48213000:"Software voor verbetering van besturingssysteem",
   code_48214000:"Software voor netwerkbesturingssysteem",
   code_48215000:"Software voor netwerkontwikkelaars",
   code_48216000:"Software voor netwerkverbindingsterminal-emulatie",
   code_48217000:"Transactionele verwerkingssoftware",
   code_48217100:"Mainframe transactionele verwerkingssoftware",
   code_48217200:"Transactionele verwerkingssoftware voor minicomputer",
   code_48217300:"Transactionele verwerkingssoftware voor microcomputer",
   code_48218000:"Software voor licentiemanagement",
   code_48219000:"Diverse netwerksoftware",
   code_48219100:"Gatewaysoftware",
   code_48219200:"Software voor CD-servers",
   code_48219300:"Administratieve software",
   code_48219400:"Software voor transactionele server",
   code_48219500:"Schakel- of routersoftware",
   code_48219600:"Software voor multiplexer",
   code_48219700:"Software voor communicatieserver",
   code_48219800:"Brug-software",
   code_48220000:"Software voor internet en intranet",
   code_48221000:"Software voor internet browsen",
   code_48222000:"Software voor webserver",
   code_48223000:"Software voor e-mail",
   code_48224000:"Software voor webpage-editen",
   code_48300000:"Software voor het maken van documenten, tekeningen, afbeeldingen, dienstregelingen en productiviteit",
   code_48310000:"Software voor het maken van documenten",
   code_48311000:"Software voor documentenbeheer",
   code_48311100:"Systeem voor documentenbeheer",
   code_48312000:"Software elektronisch publiceren",
   code_48313000:"Software voor OCR (optische schriftherkenner)",
   code_48313100:"Optisch leessysteem",
   code_48314000:"Software voor spraakherkenning",
   code_48315000:"Desktop-publicatie software",
   code_48316000:"Software voor presentatie",
   code_48317000:"Tekstverwerkingssoftware",
   code_48318000:"Scannersoftware",
   code_48319000:"Spellingcheckers",
   code_48320000:"Software voor het maken van tekeningen en afbeeldingen",
   code_48321000:"CAD-software",
   code_48321100:"CAD-systeem",
   code_48322000:"Grafische software",
   code_48323000:"CAM-software",
   code_48324000:"Cartografiesoftware",
   code_48325000:"Software voor het maken van formulieren",
   code_48326000:"Karteringssoftware",
   code_48326100:"Systeem voor digitale cartografie",
   code_48327000:"Teken- en schildersoftware",
   code_48328000:"Beeldverwerkingssoftware",
   code_48329000:"Beeldvormings- en archiefsysteem",
   code_48330000:"Software voor dienstregelingen en productiviteit",
   code_48331000:"Software voor projectmanagement",
   code_48332000:"Software voor het maken van dienstregelingen",
   code_48333000:"Software voor contactenbeheer",
   code_48400000:"Software voor zakelijke transacties en persoonlijke zaken",
   code_48410000:"Software voor investeringsmanagement en belastingaangifte",
   code_48411000:"Software voor investeringsmanagement",
   code_48412000:"Software voor belastingaangifte",
   code_48420000:"Software en opvolgingssoftware voor faciliteitenbeheer",
   code_48421000:"Software voor faciliteitenbeheer",
   code_48422000:"Opvolgingssoftware",
   code_48430000:"Software voor inventarismanagement",
   code_48440000:"Software voor financiële analyse en boekhouding",
   code_48441000:"Software voor financiële analyse",
   code_48442000:"Software voor financiële systemen",
   code_48443000:"Boekhoudsoftware",
   code_48444000:"Boekhoudsysteem",
   code_48444100:"Factureringssysteem",
   code_48445000:"Software voor elektronisch klantenbeheer",
   code_48450000:"Software voor tijdregistratie of human reousrces",
   code_48451000:"Software voor ERP (Enterprise resource planning)",
   code_48460000:"Software voor analytische-, wetenschappelijke, mathematische of voorspellingsdoeleinden",
   code_48461000:"Analytische of wetenschappelijke software",
   code_48462000:"Software voor mathematische of voorspellingsdoeleinden",
   code_48463000:"Statistieksoftware",
   code_48470000:"Veilingsoftware",
   code_48480000:"Software voor verkoop, marketing en business intelligence",
   code_48481000:"Verkoop- of marketingsoftware",
   code_48482000:"Business-intelligence software",
   code_48490000:"Aankoopsoftware",
   code_48500000:"Communicatie- en multimediasoftware",
   code_48510000:"Communicatiesoftware",
   code_48511000:"Desktop-communicatiesoftware",
   code_48512000:"Software voor interactieve spraakbeantwoorders (IVR)",
   code_48513000:"Modemsoftware",
   code_48514000:"Remote-access-software",
   code_48515000:"Software voor videoconferentie",
   code_48516000:"Uitwisselingssoftware",
   code_48517000:"IT-software",
   code_48518000:"Emulatiesoftware",
   code_48519000:"Software voor geheugenbeheer",
   code_48520000:"Multimediasoftware",
   code_48521000:"Software voor muziek- of geluidsediting",
   code_48522000:"Software voor virtueel keyboard",
   code_48600000:"Database- en besturingssoftware",
   code_48610000:"Databasesystemen",
   code_48611000:"Databasesoftware",
   code_48612000:"Databasebeheerssysteem",
   code_48613000:"Elektronische informatieverwerking (EDP)",
   code_48614000:"Gegevensregistratiesysteem",
   code_48620000:"Besturingssystemen",
   code_48621000:"Software voor mainframebesturingssysteem",
   code_48622000:"Software voor minicomputerbesturingssysteem",
   code_48623000:"Software voor microcomputerbesturingssysteem",
   code_48624000:"Software voor pc-besturingssysteem",
   code_48625000:"Opensysteembesturingssystemen",
   code_48626000:"Clusteringsoftware",
   code_48627000:"Software voor realtimebesturingssysteem",
   code_48628000:"Microchannelarchitectuur",
   code_48700000:"Softwarevoorzieningen",
   code_48710000:"Back-up- of recoverysoftware",
   code_48720000:"Software voor het aanbrengen van barcodes",
   code_48730000:"Beveiligingssoftware",
   code_48731000:"Dossierbeveiligingssoftware",
   code_48732000:"Gegevensbeveiligingssoftware",
   code_48740000:"Vertaalsoftware",
   code_48750000:"Software voor het laden van geheugendragers",
   code_48760000:"Virusbeschermingssoftware",
   code_48761000:"Anti-virussoftware",
   code_48770000:"Ontwikkeling van software voor algemene, comprimerings- en printerfaciliteiten",
   code_48771000:"Algemene softwarefaciliteiten",
   code_48772000:"Comprimeringsfaciliteiten",
   code_48773000:"Printersoftware-faciliteiten",
   code_48773100:"Printbuffersoftware",
   code_48780000:"Software voor systeem-, opslag- en inhoudbeheer",
   code_48781000:"Software voor systeembeheer",
   code_48782000:"Software voor opslagbeheer",
   code_48783000:"Software voor inhoudsbeheer",
   code_48790000:"Spellingcheckersoftware",
   code_48800000:"Informatiesystemen en servers",
   code_48810000:"Informatiesystemen",
   code_48811000:"E-mail-systeem",
   code_48812000:"Financiële-informatiesystemen",
   code_48813000:"Passagiersinformatiesysteem",
   code_48813100:"Elektronische bulletin boards",
   code_48813200:"Realtime passagiersinformatiesysteem",
   code_48814000:"Medische-informatiesystemen",
   code_48814100:"Verpleeginformatiesysteem",
   code_48814200:"Patiëntenadministratiesysteem",
   code_48814300:"OK-beheerssysteem",
   code_48814400:"Klinische-informatiesysteem",
   code_48814500:"Casemixsysteem",
   code_48820000:"Servers",
   code_48821000:"Netwerkservers",
   code_48822000:"Computerservers",
   code_48823000:"Bestandsservers",
   code_48824000:"Printerservers",
   code_48825000:"Webservers",
   code_48900000:"Diverse software en computersystemen",
   code_48910000:"Software voor computerspelletjes, gezinsvideo's en screensavers",
   code_48911000:"Software voor computerspelletjes",
   code_48912000:"Gezinsvideo's",
   code_48913000:"Screensavers",
   code_48920000:"Kantoorautomatiseringssoftware",
   code_48921000:"Automatiseringssysteem",
   code_48930000:"Software voor opleiding en vermaak",
   code_48931000:"Opleidingssoftware",
   code_48932000:"Software voor vermaak",
   code_48940000:"Software voor patroonontwerp en kalenders",
   code_48941000:"Software voor patroonontwerp",
   code_48942000:"Software voor kalenders",
   code_48950000:"Botenverhuur en omroepinstallatiesysteem",
   code_48951000:"Scheepslokaliseringssysteem",
   code_48952000:"Omroepinstallaties",
   code_48960000:"Drivers- en systeemsoftware",
   code_48961000:"Ethernetdrivers",
   code_48962000:"Drivers voor grafische kaarten",
   code_48970000:"Printshop-software",
   code_48971000:"Software voor het maken van adressenbestand",
   code_48972000:"Software voor het maken van labels/etiketten",
   code_48980000:"Programmeertalen en tools",
   code_48981000:"Compiling software",
   code_48982000:"Software voor configuratiemanagement",
   code_48983000:"Ontwikkelingssoftware",
   code_48984000:"Grafische user-interface tools (GUI)",
   code_48985000:"Programmeertalen",
   code_48986000:"Software voor testen van programma's",
   code_48987000:"Software voor het opsporen van fouten",
   code_48990000:"Software voor spreadsheets en verbetering",
   code_48991000:"Spreadsheetsoftware",
   code_50000000:"Reparatie- en onderhoudsdiensten",
   code_50100000:"Reparatie, onderhoud en aanverwante diensten voor voertuigen en aanverwante uitrusting",
   code_50110000:"Reparatie en onderhoud voor motorvoertuigen en aanverwante uitrusting",
   code_50111000:"Beheer, reparatie en onderhoud van wagenpark",
   code_50111100:"Beheer van wagenpark",
   code_50111110:"Ondersteunende diensten voor wagenpark",
   code_50112000:"Reparatie en onderhoud van auto's",
   code_50112100:"Reparatie van auto's",
   code_50112110:"Reparatie van voertuigcarrosserieën",
   code_50112111:"Uitdeukwerk",
   code_50112120:"Vervangen van voorruiten",
   code_50112200:"Onderhoud van auto's",
   code_50112300:"Autowasserij en aanverwante diensten",
   code_50113000:"Onderhoud en reparatie van bussen",
   code_50113100:"Reparatie van bussen",
   code_50113200:"Onderhoud van bussen",
   code_50114000:"Reparatie en onderhoud van vrachtwagens",
   code_50114100:"Reparatie van vrachtwagens",
   code_50114200:"Onderhoud van vrachtwagens",
   code_50115000:"Reparatie en onderhoud van motorfietsen",
   code_50115100:"Reparatie van motorfietsen",
   code_50115200:"Onderhoud van motorfietsen",
   code_50116000:"Onderhoud en reparatie van speciale onderdelen van auto's",
   code_50116100:"Reparatie van elektrische systemen",
   code_50116200:"Reparatie en onderhoud van remmen en remonderdelen van voertuigen",
   code_50116300:"Reparatie en onderhoud van versnellingsbakken van voertuigen",
   code_50116400:"Reparatie en onderhoud van voertuigtransmissie",
   code_50116500:"Repareren van banden, inclusief monteren en uitbalanceren",
   code_50116510:"Coveren van banden",
   code_50116600:"Onderhoud en reparatie van startmotoren",
   code_50117000:"Ombouwen en opknappen van voertuigen",
   code_50117100:"Ombouwen van motorvoertuigen",
   code_50117200:"Ombouwen van ambulances",
   code_50117300:"Opknappen van voertuigen",
   code_50118000:"Wegenwachtdiensten",
   code_50118100:"Wegenwacht- en wegsleepdiensten voor auto's",
   code_50118110:"Wegslepen van voertuigen",
   code_50118200:"Wegenwacht- en wegsleepdiensten voor bedrijfsvoertuigen",
   code_50118300:"Wegenwacht- en wegsleepdiensten voor bussen",
   code_50118400:"Wegenwacht- en wegsleepdiensten voor motorvoertuigen",
   code_50118500:"Wegenwacht- en wegsleepdiensten voor motorfietsen",
   code_50190000:"Slopen van voertuigen",
   code_50200000:"Reparatie, onderhoud en aanverwante diensten in verband met vliegtuig-, spoorweg-, wegen- en scheepvaartuitrusting",
   code_50210000:"Reparatie, onderhoud en aanverwante diensten in verband met vliegtuigen en andere uitrusting",
   code_50211000:"Reparatie en onderhoud van vliegtuigen",
   code_50211100:"Onderhoud van vliegtuigen",
   code_50211200:"Reparatie van vliegtuigen",
   code_50211210:"Reparatie en onderhoud van vliegtuigmotoren",
   code_50211211:"Onderhoud van vliegtuigmotoren",
   code_50211212:"Reparatie van vliegtuigmotoren",
   code_50211300:"Opknappen van vliegtuigen",
   code_50211310:"Opknappen van vliegtuigmotoren",
   code_50212000:"Reparatie en onderhoud van helikopters",
   code_50220000:"Reparatie, onderhoud en aanverwante diensten in verband met spoorwegen en andere uitrusting",
   code_50221000:"Reparatie en onderhoud van locomotieven",
   code_50221100:"Reparatie en onderhoud van versnellingsbakken van locomotieven",
   code_50221200:"Reparatie en onderhoud van transmissies van locomotieven",
   code_50221300:"Reparatie en onderhoud van wielstellen van locomotieven",
   code_50221400:"Reparatie en onderhoud van remmen en remonderdelen van locomotieven",
   code_50222000:"Reparatie en onderhoud van rollend materieel",
   code_50222100:"Reparatie en onderhoud van schokdempers",
   code_50223000:"Reparatie van locomotieven",
   code_50224000:"Opknappen van rollend materieel",
   code_50224100:"Opknappen van stoelen in rollend materieel",
   code_50224200:"Opknappen van passagierswagons",
   code_50225000:"Onderhoud van rails",
   code_50229000:"Slopen van rollend materieel",
   code_50230000:"Reparatie, onderhoud en aanverwante diensten voor wegen en andere uitrusting",
   code_50232000:"Onderhoud van installaties voor straatverlichting en verkeerslichten",
   code_50232100:"Onderhoud van straatverlichting",
   code_50232110:"Gebruiksklaarmaken van installaties voor straatverlichting",
   code_50232200:"Onderhoud van verkeerslichten",
   code_50240000:"Reparatie, onderhoud en aanverwante diensten in verband met scheepvaart en andere uitrusting",
   code_50241000:"Reparatie en onderhoud van schepen",
   code_50241100:"Reparatie van vaartuigen",
   code_50241200:"Reparatie van veerboten",
   code_50242000:"Verbouwen van schepen",
   code_50243000:"Slopen van schepen",
   code_50244000:"Opknappen van schepen of boten",
   code_50245000:"Moderniseren van schepen",
   code_50246000:"Onderhoud van havenuitrusting",
   code_50246100:"Droogdokdiensten",
   code_50246200:"Onderhoud van boeien",
   code_50246300:"Reparatie en onderhoud van drijvende constructies",
   code_50246400:"Reparatie en onderhoud van drijvende platforms",
   code_50300000:"Reparatie, onderhoud en aanverwante diensten in verband met pc's, kantooruitrusting, telecommunicatie- en audiovisuele uitrusting",
   code_50310000:"Onderhoud en reparatie van kantoormachines",
   code_50311000:"Onderhoud en reparatie van boekhoudmachines",
   code_50311400:"Onderhoud en reparatie van rekenmachines en boekhoudmachines",
   code_50312000:"Onderhoud en reparatie van computeruitrusting",
   code_50312100:"Onderhoud en reparatie van mainframecomputers",
   code_50312110:"Onderhoud van mainframecomputers",
   code_50312120:"Reparatie van mainframecomputers",
   code_50312200:"Onderhoud en reparatie van minicomputers",
   code_50312210:"Onderhoud van minicomputers",
   code_50312220:"Reparatie van minicomputers",
   code_50312300:"Onderhoud en reparatie van datanetwerkuitrusting",
   code_50312310:"Onderhoud van datanetwerkuitrusting",
   code_50312320:"Reparatie van uitrusting voor datanetwerk",
   code_50312400:"Onderhoud en reparatie van microcomputers",
   code_50312410:"Onderhoud van microcomputers",
   code_50312420:"Reparatie van microcomputers",
   code_50312600:"Onderhoud en reparatie van uitrusting voor informatietechnologie",
   code_50312610:"Onderhoud van uitrusting voor informatietechnologie",
   code_50312620:"Reparatie van uitrusting voor informatietechnologie",
   code_50313000:"Onderhoud en reparatie van reprografische machines",
   code_50313100:"Reparatie van fotokopieermachines",
   code_50313200:"Onderhoud van fotokopieermachines",
   code_50314000:"Reparatie en onderhoud van faxtoestellen",
   code_50315000:"Reparatie en onderhoud van antwoordapparaten",
   code_50316000:"Onderhoud en reparatie van kaartjesautomaten",
   code_50317000:"Onderhoud en reparatie van kaartjesafstempelautomaten",
   code_50320000:"Reparatie en onderhoud van pc's",
   code_50321000:"Reparatie van pc's",
   code_50322000:"Onderhoud van pc's",
   code_50323000:"Onderhoud en reparatie van randapparatuur",
   code_50323100:"Onderhoud van randapparatuur",
   code_50323200:"Reparatie van randapparatuur",
   code_50324000:"Hulpdiensten voor pc's",
   code_50324100:"Systeemonderhoud",
   code_50324200:"Diensten voor preventief onderhoud",
   code_50330000:"Onderhoud van telecommunicatie-uitrusting",
   code_50331000:"Reparatie en onderhoud van telecommunicatielijnen",
   code_50332000:"Onderhoud van telecommunicatie-infrastructuur",
   code_50333000:"Onderhoud van radiocommunicatie-uitrusting",
   code_50333100:"Reparatie en onderhoud van radiozenders",
   code_50333200:"Reparatie en onderhoud van radiotelefonietoestellen",
   code_50334000:"Reparatie en onderhoud van lijntelefonie- en lijntelegrafie-uitrusting",
   code_50334100:"Reparatie en onderhoud van lijntelefonie-uitrusting",
   code_50334110:"Onderhoud van telefoonnetwerk",
   code_50334120:"Upgraden van telefoonschakeluitrusting",
   code_50334130:"Reparatie en onderhoud van telefoonschakelapparatuur",
   code_50334140:"Reparatie en onderhoud van telefoontoestellen",
   code_50334200:"Reparatie en onderhoud van lijntelegrafie-uitrusting",
   code_50334300:"Reparatie en onderhoud van lijntelexuitrusting",
   code_50334400:"Onderhoud van communicatiesystemen",
   code_50340000:"Reparatie en onderhoud van audiovisuele en optische uitrusting",
   code_50341000:"Reparatie en onderhoud van televisie-uitrusting",
   code_50341100:"Reparatie en onderhoud van videotextuitrusting",
   code_50341200:"Reparatie en onderhoud van televisiezenders",
   code_50342000:"Reparatie en onderhoud van audio-uitrusting",
   code_50343000:"Reparatie en onderhoud van video-uitrusting",
   code_50344000:"Reparatie en onderhoud van optische uitrusting",
   code_50344100:"Reparatie en onderhoud van fotografische uitrusting",
   code_50344200:"Reparatie en onderhoud van cinematografische uitrusting",
   code_50400000:"Reparatie en onderhoud van medische en precisie-uitrusting",
   code_50410000:"Reparatie en onderhoud van meet-, test- en controleapparatuur",
   code_50411000:"Reparatie en onderhoud van meetapparatuur",
   code_50411100:"Reparatie en onderhoud van watermeters",
   code_50411200:"Reparatie en onderhoud van gasmeters",
   code_50411300:"Reparatie en onderhoud van elektriciteitsmeters",
   code_50411400:"Reparatie en onderhoud van tachometers",
   code_50411500:"Reparatie en onderhoud van industriële tijdmetingsuitrusting",
   code_50412000:"Reparatie en onderhoud van testapparatuur",
   code_50413000:"Reparatie en onderhoud van controleapparatuur",
   code_50413100:"Reparatie en onderhoud van gasdetectieapparatuur",
   code_50413200:"Reparatie en onderhoud van brandblusinstallaties",
   code_50420000:"Reparatie en onderhoud van medische en chirurgische uitrusting",
   code_50421000:"Reparatie en onderhoud van medische uitrusting",
   code_50421100:"Reparatie en onderhoud van rolstoelen",
   code_50421200:"Reparatie en onderhoud van röntgenuitrusting",
   code_50422000:"Reparatie en onderhoud van chirurgische uitrusting",
   code_50430000:"Reparatie en onderhoud van precisie-uitrusting",
   code_50431000:"Reparatie en onderhoud van horloges",
   code_50432000:"Reparatie en onderhoud van klokken",
   code_50433000:"Kalibreerdiensten",
   code_50500000:"Reparatie en onderhoud van pompen, kleppen, kranen, metalen containers en machines",
   code_50510000:"Reparatie en onderhoud van pompen, kleppen, kranen en metalen containers",
   code_50511000:"Reparatie en onderhoud van pompen",
   code_50511100:"Reparatie en onderhoud van vloeistofpompen",
   code_50511200:"Reparatie en onderhoud van gaspompen",
   code_50512000:"Reparatie en onderhoud van kleppen",
   code_50513000:"Reparatie en onderhoud van kranen",
   code_50514000:"Reparatie en onderhoud van metalen containers",
   code_50514100:"Reparatie en onderhoud van tanks",
   code_50514200:"Reparatie en onderhoud van reservoirs",
   code_50514300:"Reparatie van beschermhulzen",
   code_50530000:"Reparatie en onderhoud van machines",
   code_50531000:"Reparatie en onderhoud van niet-elektrische machines",
   code_50531100:"Reparatie en onderhoud van boilers",
   code_50531200:"Onderhoudsdiensten voor gastoestellen",
   code_50531300:"Reparatie en onderhoud van compressoren",
   code_50531400:"Reparatie en onderhoud van hijskranen",
   code_50531500:"Reparatie en onderhoud van derrickkranen",
   code_50531510:"Ontmantelen van boortorens",
   code_50532000:"Reparatie en onderhoud van elektrische machines, apparatuur en aanverwante uitrusting",
   code_50532100:"Reparatie en onderhoud van elektrische motoren",
   code_50532200:"Reparatie en onderhoud van transformatoren",
   code_50532300:"Reparatie en onderhoud van generatoren",
   code_50532400:"Reparatie en onderhoud van uitrusting voor elektriciteitsdistributie",
   code_50600000:"Reparatie- en onderhoudsdiensten voor beveiligings- en defensiematerialen",
   code_50610000:"Reparatie- en onderhoudsdiensten voor beveiligingsuitrusting",
   code_50620000:"Reparatie- en onderhoudsdiensten voor vuurwapens en munitie",
   code_50630000:"Reparatie- en onderhoudsdiensten voor militaire voertuigen",
   code_50640000:"Reparatie- en onderhoudsdiensten voor oorlogsschepen",
   code_50650000:"Reparatie- en onderhoudsdiensten voor militaire lucht- en ruimtevaartuigen en raketten",
   code_50660000:"Reparatie- en onderhoudsdiensten voor militaire elektronische systemen",
   code_50700000:"Reparatie en onderhoud van uitrusting in gebouwen",
   code_50710000:"Reparatie en onderhoud van elektrische en mechanische uitrusting in gebouwen",
   code_50711000:"Reparatie en onderhoud van elektrische uitrusting in gebouwen",
   code_50712000:"Reparatie en onderhoud van mechanische uitrusting in gebouwen",
   code_50720000:"Reparatie en onderhoud van centrale verwarming",
   code_50721000:"Gebruiksklaarmaken van verwarmingsinstallaties",
   code_50730000:"Reparatie en onderhoud van koelinstallaties",
   code_50740000:"Reparatie en onderhoud van roltrappen",
   code_50750000:"Liftonderhoud",
   code_50760000:"Reparatie en onderhoud van openbare toiletten",
   code_50800000:"Diverse reparatie- en onderhoudsdiensten",
   code_50810000:"Reparatie van juwelen",
   code_50820000:"Reparatie van lederwaren",
   code_50821000:"Reparatie van laarzen",
   code_50822000:"Reparatie van schoenen",
   code_50830000:"Reparatie van kleding en textiel",
   code_50840000:"Reparatie en onderhoud van wapens en wapensystemen",
   code_50841000:"Reparatie en onderhoud van wapens",
   code_50842000:"Reparatie en onderhoud van wapensystemen",
   code_50850000:"Reparatie en onderhoud van meubilair",
   code_50860000:"Reparatie en onderhoud van muziekinstrumenten",
   code_50870000:"Reparatie en onderhoud van speelplaatsuitrusting",
   code_50880000:"Reparatie en onderhoud van hotel- en restaurantuitrusting",
   code_50881000:"Reparatie en onderhoud van hoteluitrusting",
   code_50882000:"Reparatie en onderhoud van restaurantuitrusting",
   code_50883000:"Reparatie en onderhoud van cateringuitrusting",
   code_50884000:"Reparatie en onderhoud van kampeeruitrusting",
   code_51000000:"Installatiediensten (uitgezonderd software)",
   code_51100000:"Installatie van elektrische en mechanische uitrusting",
   code_51110000:"Installatie van elektrische uitrusting",
   code_51111000:"Installatie van elektromotoren, generatoren en transformatoren",
   code_51111100:"Installatie van elektromotoren",
   code_51111200:"Installatie van generatoren",
   code_51111300:"Installatie van transformatoren",
   code_51112000:"Installatie van elektriciteitsdistributie- en -regeluitrusting",
   code_51112100:"Installatie van elektriciteitsdistributie-uitrusting",
   code_51112200:"Installatie van elektriciteitsregeluitrusting",
   code_51120000:"Installatie van mechanische uitrusting",
   code_51121000:"Installatiediensten voor fitnessapparatuur",
   code_51122000:"Installatiediensten voor vlaggenmasten",
   code_51130000:"Installatie van stoomgeneratoren, turbines, compressoren en branders",
   code_51131000:"Installatie van stoomgeneratoren",
   code_51133000:"Installatie van turbines",
   code_51133100:"Installatie van gasturbines",
   code_51134000:"Installatie van compressoren",
   code_51135000:"Installatie van ovens",
   code_51135100:"Installatie van branders",
   code_51135110:"Installatie van afvalverbrandingsinstallaties",
   code_51140000:"Installatie van motoren",
   code_51141000:"Installatie van benzinemotoren",
   code_51142000:"Installatie van dieselmotoren",
   code_51143000:"Installatie van treinmotoren",
   code_51144000:"Installatie van voertuigmotoren",
   code_51145000:"Installatie van scheepsmotoren",
   code_51146000:"Installatie van vliegtuigmotoren",
   code_51200000:"Installatie van meet-, controle-, test- en navigatie-uitrusting",
   code_51210000:"Installatie van meetuitrusting",
   code_51211000:"Installatie van tijdmetingsuitrusting",
   code_51212000:"Installatiediensten voor tijdregistratieapparatuur",
   code_51213000:"Installatiediensten voor tijdopnameapparatuur",
   code_51214000:"Installatiediensten voor parkeermeterapparatuur",
   code_51215000:"Installatiediensten voor meteorologische apparatuur",
   code_51216000:"Installatiediensten voor geologische apparatuur",
   code_51220000:"Installatie van controle-uitrusting",
   code_51221000:"Installatiediensten voor automatische incheckapparatuur op luchthaven",
   code_51230000:"Installatie van testuitrusting",
   code_51240000:"Installatie van navigatie-uitrusting",
   code_51300000:"Installatie van communicatie-uitrusting",
   code_51310000:"Installatie van radio-, televisie-, audio- en video-uitrusting",
   code_51311000:"Installatie van radiouitrusting",
   code_51312000:"Installatie van televisie-uitrusting",
   code_51313000:"Installatie van audio-uitrusting",
   code_51314000:"Installatie van video-uitrusting",
   code_51320000:"Installatie van radio- en televisiezenders",
   code_51321000:"Installatie van radiozenders",
   code_51322000:"Installatie van televisiezenders",
   code_51330000:"Installatie van radiotelefonieapparatuur",
   code_51340000:"Installatie van lijntelefonie-uitrusting",
   code_51350000:"Installatie van lijntelegrafie-uitrusting",
   code_51400000:"Installatie van medische en chirurgische uitrusting",
   code_51410000:"Installatie van medische uitrusting",
   code_51411000:"Installatiediensten voor beeldapparatuur",
   code_51412000:"Installatiediensten voor tandheelkundige en daarbijbehorende apparatuur",
   code_51413000:"Installatiediensten voor apparatuur voor radiotherapie",
   code_51414000:"Installatiediensten voor apparatuur voor mechanotherapie",
   code_51415000:"Installatiediensten voor apparatuur voor elektrotherapie",
   code_51416000:"Installatiediensten voor apparatuur voor fysiotherapie",
   code_51420000:"Installatie van chirurgische uitrusting",
   code_51430000:"Installatie van laboratoriumuitrusting",
   code_51500000:"Installatie van machines en uitrusting",
   code_51510000:"Installatie van machines en uitrusting voor algemeen gebruik",
   code_51511000:"Installatie van hef- en transportuitrusting, met uitzondering van liften en roltrappen",
   code_51511100:"Installatie van hefuitrusting",
   code_51511110:"Installatie van hijskranen",
   code_51511200:"Installatie van transportuitrusting",
   code_51511300:"Installatie van bouwliften en hangsteigers",
   code_51511400:"Installatie van speciale transportsystemen",
   code_51514000:"Installatie van diverse machines voor algemeen gebruik",
   code_51514100:"Installatie van machines en toestellen voor het filtreren of zuiveren van vloeistoffen",
   code_51514110:"Installatie van machines en toestellen voor het filtreren of zuiveren van water",
   code_51520000:"Installatie van land- en bosbouwmachines",
   code_51521000:"Installatie van landbouwmachines",
   code_51522000:"Installatie van bosbouwmachines",
   code_51530000:"Installatie van gereedschapswerktuigen",
   code_51540000:"Installatie van machines en uitrusting voor bijzondere doeleinden",
   code_51541000:"Installatie van mijnbouw-, delf-, bouw- en metaalbewerkingsmachines",
   code_51541100:"Installatie van mijnbouwmachines",
   code_51541200:"Installatie van delfmachines",
   code_51541300:"Installatie van bouwmachines",
   code_51541400:"Installatie van metaalbewerkingsmachines",
   code_51542000:"Installatie van voedings-, drank- en tabaksverwerkende machines",
   code_51542100:"Installatie van voedingsverwerkende machines",
   code_51542200:"Installatie van drankverwerkende machines",
   code_51542300:"Installatie van tabaksverwerkende machines",
   code_51543000:"Installatie van textiel-, kleding- en lederproductiemachines",
   code_51543100:"Installatie van textielproductiemachines",
   code_51543200:"Installatie van kledingproductiemachines",
   code_51543300:"Installatie van lederproductiemachines",
   code_51543400:"Installatie van wasmachines, machines voor chemisch reinigen en droogmachines",
   code_51544000:"Installatie van papier- en kartonproductiemachines",
   code_51544100:"Installatie van papierproductiemachines",
   code_51544200:"Installatie van kartonproductiemachines",
   code_51545000:"Plaatsingsdiensten voor straatbrievenbussen",
   code_51550000:"Installatie van wapensystemen",
   code_51600000:"Installatie van computers en kantooruitrusting",
   code_51610000:"Installatie van computers en informatieverwerkende uitrusting",
   code_51611000:"Installatie van computers",
   code_51611100:"Installatie van hardware",
   code_51611110:"Installatiediensten voor realtimevisualisatieschermen/borden voor vertrek- en aankomsttijden op luchthavens",
   code_51611120:"Installatiediensten voor realtimevisualisatieschermen/borden voor vertrek- en aankomsttijden op stations",
   code_51612000:"Installatie van informatieverwerkende uitrusting",
   code_51620000:"Installatie van kantooruitrusting",
   code_51700000:"Installatiediensten voor brandbstrijdingsapparatuur",
   code_51800000:"Installatie van metalen containers",
   code_51810000:"Installatie van tanks",
   code_51820000:"Installatie van reservoirs",
   code_51900000:"Installatiediensten voor bebakenings- en besturingssystemen",
   code_55000000:"Diensten voor hotel, restaurant en detailhandel",
   code_55100000:"Hoteldiensten",
   code_55110000:"Diensten voor hotelaccomodatie",
   code_55120000:"Diensten voor vergaderingen en conferenties in hotel",
   code_55130000:"Overige hoteldiensten",
   code_55200000:"Kampeerterreinen en andere accommodatie met uitzondering van hotels",
   code_55210000:"Jeugdherberg",
   code_55220000:"Kampeerterrein",
   code_55221000:"Caravanterrein",
   code_55240000:"Vakantiecentra en -huizen",
   code_55241000:"Vakantiecentra",
   code_55242000:"Vakantiehuizen",
   code_55243000:"Vakantiekampen voor kinderen",
   code_55250000:"Verhuur van gemeubileerde accommodatie voor kortstondig verblijf",
   code_55260000:"Slaapwagen",
   code_55270000:"Logies-met-ontbijtpensions",
   code_55300000:"Bereiden en serveren van maaltijden",
   code_55310000:"Kelnerdiensten",
   code_55311000:"Kelnerdiensten voor niet-openbare eetgelegenheid",
   code_55312000:"Kelnerdiensten voor openbare eetgelegenheid",
   code_55320000:"Serveren van maaltijden",
   code_55321000:"Bereiden van maaltijden",
   code_55322000:"Koken van maaltijden",
   code_55330000:"Cafetariadiensten",
   code_55400000:"Verstrekken van dranken",
   code_55410000:"Barbeheer",
   code_55500000:"Kantine- en cateringdiensten",
   code_55510000:"Kantinediensten",
   code_55511000:"Diensten voor kantines en andere niet-openbare cafetaria's",
   code_55512000:"Kantinebeheer",
   code_55520000:"Cateringdiensten",
   code_55521000:"Cateringdiensten voor particulieren",
   code_55521100:"Warmemaaltijddiensten",
   code_55521200:"Maaltijdbezorgingsdiensten",
   code_55522000:"Catering voor transportbedrijven",
   code_55523000:"Catering voor ondernemingen of instellingen, uitgezonderd transportbedrijven",
   code_55523100:"Verstrekken van schoolmaaltijden",
   code_55524000:"Catering voor scholen",
   code_55900000:"Detailhandelsdiensten",
   code_60000000:"Vervoersdiensten (uitg. vervoer van afval)",
   code_60100000:"Wegvervoersdiensten",
   code_60112000:"Openbaarvervoersdiensten",
   code_60120000:"Taxidiensten",
   code_60130000:"Diensten voor speciaal personenvervoer over land",
   code_60140000:"Personenvervoer zonder dienstregeling",
   code_60150000:"Personenvervoer met door dieren getrokken voertuigen",
   code_60160000:"Postvervoer over de weg",
   code_60161000:"Pakketvervoer",
   code_60170000:"Verhuur van voertuigen voor personenvervoer met chauffeur",
   code_60171000:"Verhuur van personenauto's met chauffeur",
   code_60172000:"Bus- en autobusverhuur met chauffeur",
   code_60180000:"Verhuur van voertuigen voor goederenvervoer met chauffeur",
   code_60181000:"Verhuur van vrachtwagens met chauffeur",
   code_60182000:"Verhuur van industriële voertuigen met chauffeur",
   code_60183000:"Bestelwagenverhuur met chauffeur",
   code_60200000:"Vervoersdiensten per spoor",
   code_60210000:"Openbaar vervoersdiensten per spoor",
   code_60220000:"Postvervoer per spoor",
   code_60300000:"Transport via pijpleiding",
   code_60400000:"Luchtvervoer",
   code_60410000:"Luchtvervoer volgens dienstregeling",
   code_60411000:"Diensten voor luchtpostvervoer volgens dienstregeling",
   code_60420000:"Luchtvervoer zonder dienstregeling",
   code_60421000:"Diensten voor luchtpostvervoer zonder dienstregeling",
   code_60423000:"Vliegtuigcharterdiensten",
   code_60424000:"Verhuur van transportmiddelen voor luchtvervoer met bemanning",
   code_60424100:"Verhuur van luchtvaartuigen met bemanning",
   code_60424110:"Verhuur van vliegtuigen met bemanning",
   code_60424120:"Verhuur van helikopters met bemanning",
   code_60440000:"Luchtvaart- en aanverwante diensten",
   code_60441000:"Sproeien vanuit vliegtuigen",
   code_60442000:"Bosbrandbestrijding vanuit de lucht",
   code_60443000:"Luchtreddingsdiensten",
   code_60443100:"Lucht/zeereddingsdiensten",
   code_60444000:"Exploitatie van vliegtuigen",
   code_60444100:"Pilootdiensten",
   code_60445000:"Vliegtuigexploitatie",
   code_60500000:"Vervoer door de ruimte",
   code_60510000:"Satellietlanceringsdiensten",
   code_60520000:"Diensten voor experimentele belading",
   code_60600000:"Vervoer te water",
   code_60610000:"Vervoer per veerboot",
   code_60620000:"Postvervoer over water",
   code_60630000:"Inzet van kabelschip",
   code_60640000:"Scheepvaartactiviteiten",
   code_60650000:"Verhuur van transportmiddelen voor vervoer over water met bemanning",
   code_60651000:"Verhuur van vaartuigen met bemanning",
   code_60651100:"Verhuur van zeeschepen met bemanning",
   code_60651200:"Verhuur van binnenvaartschepen met bemanning",
   code_60651300:"Inzet van schepen voor vervuilingsbestrijding",
   code_60651400:"Inzet van schepen voor zwaar transport",
   code_60651500:"Inzet van hulpschip",
   code_60651600:"Inzet van offshorebevoorradingsschip",
   code_60653000:"Botenverhuur met bemanning",
   code_63000000:"Ondersteunende en aanvullende vervoersdiensten; reisbureaudiensten",
   code_63100000:"Vrachtbehandeling en opslag",
   code_63110000:"Vrachtbehandeling",
   code_63111000:"Containervervoer",
   code_63112000:"Bagagevervoer",
   code_63112100:"Vervoer van passagiersbagage",
   code_63112110:"Ophalen van bagage",
   code_63120000:"Opslag- en magazijndiensten",
   code_63121000:"Opslag- en opzoekdiensten",
   code_63121100:"Opslagdiensten",
   code_63121110:"Gasopslag",
   code_63122000:"Opslag in magazijn",
   code_63500000:"Reisbureau- en touroperatordiensten, hulp aan toeristen",
   code_63510000:"Reisbureaudiensten en dergelijke",
   code_63511000:"Organisatie van pakketreizen",
   code_63512000:"Verkoop van reistickets en pakketreisdiensten",
   code_63513000:"Verstrekken van toeristische informatie",
   code_63514000:"Reisleidersdiensten",
   code_63515000:"Reisdiensten",
   code_63516000:"Reisorganisatiediensten",
   code_63520000:"Expeditiekantoordiensten",
   code_63521000:"Dienstverlening door bevrachtingsagentschap",
   code_63522000:"Cargadoorsdiensten",
   code_63523000:"Scheepsagent- en expediteursdiensten",
   code_63524000:"Voorbereiden van vervoersdocumenten",
   code_63700000:"Ondersteunende diensten voor vervoer over land, te water en door de lucht",
   code_63710000:"Ondersteunende diensten voor vervoer over land",
   code_63711000:"Ondersteunende diensten voor vervoer per spoor",
   code_63711100:"Treinbewakingsdiensten",
   code_63711200:"Diensten voor rondtrekkende workshops",
   code_63712000:"Ondersteunende diensten voor vervoer over de weg",
   code_63712100:"Busstationdiensten",
   code_63712200:"Beheer van wegen",
   code_63712210:"Toldiensten voor autowegen",
   code_63712300:"Exploitatie van bruggen en tunnels",
   code_63712310:"Exploitatie van bruggen",
   code_63712311:"Brugtoldiensten",
   code_63712320:"Exploitatie van tunnel",
   code_63712321:"Tunneltoldiensten",
   code_63712400:"Exploitatie van parkeerterreinen",
   code_63712500:"Weegbrugdiensten",
   code_63712600:"Brandstofbevoorrading voor voertuigen",
   code_63712700:"Verkeersleidingsdiensten",
   code_63712710:"Verkeersbewakingsdiensten",
   code_63720000:"Ondersteunende diensten voor vervoer over water",
   code_63721000:"Exploitatie van havens en waterwegen en aanverwante diensten",
   code_63721100:"Bunkeren",
   code_63721200:"Exploitatie van haven",
   code_63721300:"Exploitatie van waterwegen",
   code_63721400:"Bijtanken van schepen",
   code_63721500:"Exploitatie van passagiersterminal",
   code_63722000:"Scheepsloodsdiensten",
   code_63723000:"Afmeerdiensten",
   code_63724000:"Navigatiediensten",
   code_63724100:"Offshore-positiebepaling",
   code_63724110:"In positie brengen van lichtschip",
   code_63724200:"Lichtschipexploitatie",
   code_63724300:"Plaatsen van boeien",
   code_63724310:"Markering d.m.v. boeien",
   code_63724400:"Vuurtorendiensten",
   code_63725000:"Bergen en lichten van schepen",
   code_63725100:"Diensten voor het bergen van vaartuigen",
   code_63725200:"Wachtschipdiensten",
   code_63725300:"Lichten van vaartuigen",
   code_63726000:"Diverse ondersteunende diensten voor vervoer over water",
   code_63726100:"Scheepsregistratie",
   code_63726200:"IJsbrekerdiensten",
   code_63726300:"Opslag van schepen",
   code_63726400:"Bevrachting van schepen",
   code_63726500:"Opleggen van schepen",
   code_63726600:"Scheepsexploitatiediensten",
   code_63726610:"Diensten voor het te water laten van schepen",
   code_63726620:"Diensten door onbemand duikvaartuig",
   code_63726700:"Vissersvaartuigdiensten",
   code_63726800:"Onderzoeksvaartuigdiensten",
   code_63726900:"Inzet van kettingboot",
   code_63727000:"Inzet van sleep- en duwboten",
   code_63727100:"Sleepdiensten",
   code_63727200:"Duwdiensten",
   code_63730000:"Ondersteunende diensten voor luchtvervoer",
   code_63731000:"Beheer van luchthaven",
   code_63731100:"Coördinatiediensten voor luchthavenslots",
   code_63732000:"Luchtverkeersleidingsdiensten",
   code_63733000:"Bijtanken van vliegtuigen",
   code_63734000:"Aanbieden van loodsen",
   code_64000000:"Post- en telecommunicatiediensten",
   code_64100000:"Post- en koeriersdiensten",
   code_64110000:"Postdiensten",
   code_64111000:"Postdiensten voor kranten en tijdschriften",
   code_64112000:"Brievenpostdienst",
   code_64113000:"Pakketpostdienst",
   code_64114000:"Postkantoordiensten",
   code_64115000:"Verhuur van postbussen",
   code_64116000:"Poste-restantediensten",
   code_64120000:"Koeriersdiensten",
   code_64121000:"Multimodale koeriersdiensten",
   code_64121100:"Postbezorging",
   code_64121200:"Pakketbezorging",
   code_64122000:"Interne kantoorbodediensten",
   code_64200000:"Telecommunicatiediensten",
   code_64210000:"Telefoon- en datatransmissiediensten",
   code_64211000:"Openbaretelefoondiensten",
   code_64211100:"Lokale telefoondiensten",
   code_64211200:"Interlokale telefoondiensten",
   code_64212000:"Mobieletelefoondiensten",
   code_64212100:"Sms-diensten",
   code_64212200:"Ems-diensten",
   code_64212300:"Mms-diensten",
   code_64212400:"Wireless Application Protocol (WAP)-diensten",
   code_64212500:"General Packet Radio Services (GPRS)-diensten",
   code_64212600:"EDGE-diensten (verbeterde data voor gsm-ontwikkeling)",
   code_64212700:"Universal Mobile Telephone System (UMTS)-diensten",
   code_64212800:"Providerdiensten voor munt- of kaarttelefoons",
   code_64212900:"Providerdiensten voor prepaid telefoonkaart",
   code_64213000:"Gemeenschappelijke telefoonnetwerkdiensten voor ondernemingen",
   code_64214000:"Specifieke telefoonnetwerkdiensten voor ondernemingen",
   code_64214100:"Verhuur van satellietverbindingen",
   code_64214200:"Telefooncentralediensten",
   code_64214400:"Verhuur van communicatielijnen over land",
   code_64215000:"IP-telefoondiensten",
   code_64216000:"Elektronische berichten- en informatiediensten",
   code_64216100:"Elektronische berichtendiensten",
   code_64216110:"Diensten voor elektronische gegevensuitwisseling",
   code_64216120:"E-maildiensten",
   code_64216130:"Telexdiensten",
   code_64216140:"Telegrafiediensten",
   code_64216200:"Elektronische informatiediensten",
   code_64216210:"Diensten voor informatie met toegevoegde waarde",
   code_64216300:"Teletekstdiensten",
   code_64220000:"Telecommunicatiediensten, met uitzondering van telefoon en datatransmissie",
   code_64221000:"Interconnectiediensten",
   code_64222000:"Telewerkdiensten",
   code_64223000:"Semafoondiensten",
   code_64224000:"Teleconferentiediensten",
   code_64225000:"Lucht-grondtelecommunicatiediensten",
   code_64226000:"Telematicadiensten",
   code_64227000:"Geïntegreerde telecommunicatiediensten",
   code_64228000:"Transmissie van televisie- en radioprogramma's",
   code_64228100:"Transmissie van televisieprogramma's",
   code_64228200:"Transmissie van radioprogramma's",
   code_65000000:"Openbare voorzieningen",
   code_65100000:"Waterdistributie en aanverwante diensten",
   code_65110000:"Waterdistributie",
   code_65111000:"Drinkwaterdistributie",
   code_65120000:"Exploitatie van een waterzuiveringsinstallatie",
   code_65121000:"Waterdemineralisatiediensten",
   code_65122000:"Waterontziltingsdiensten",
   code_65123000:"Wateronthardingsdiensten",
   code_65130000:"Exploitatie van watervoorziening",
   code_65200000:"Gasdistributie en aanverwante diensten",
   code_65210000:"Gasdistributie",
   code_65300000:"Elektriciteitsdistributie en aanverwante diensten",
   code_65310000:"Elektriciteitsdistributie",
   code_65320000:"Exploitatie van elektrische installaties",
   code_65400000:"Andere bronnen van energievoorziening en -distributie",
   code_65410000:"Exploitatie van een energiecentrale",
   code_65500000:"Diensten voor het aflezen van meters",
   code_66000000:"Financiële en verzekeringsdiensten",
   code_66100000:"Diensten op het gebied van banken en investeringen",
   code_66110000:"Bankdiensten",
   code_66111000:"Diensten van centrale banken",
   code_66112000:"Depositozaken",
   code_66113000:"Kredietverlening",
   code_66113100:"Diensten op het gebied van micro-kredietverstrekking",
   code_66114000:"Financiële leasing",
   code_66115000:"Diensten voor internationale betalingsboekingen",
   code_66120000:"Investment banking en aanverwante diensten",
   code_66121000:"Diensten in verband met fusies en overnamen",
   code_66122000:"Financiële diensten i.v.m. vennootschappen en participaties",
   code_66130000:"Courtage en daarmee verband houdende diensten voor effecten en goederen",
   code_66131000:"Diensten van effectenmakelaars",
   code_66131100:"Pensioenbelegging",
   code_66132000:"Courtagediensten voor goederen",
   code_66133000:"Verwerkings- en clearingsdiensten",
   code_66140000:"Portefeuillebeheer",
   code_66141000:"Beheer van pensioenfonds",
   code_66150000:"Diensten in verband met het beheer van financiële markten",
   code_66151000:"Operationele diensten kapitaalmarkt",
   code_66151100:"Detailhandelsdiensten voor elektronische handel",
   code_66152000:"Toezichtdiensten kapitaalmarkt",
   code_66160000:"Diensten voor trust en inbewaringstelling",
   code_66161000:"Trustdiensten",
   code_66162000:"Inbewaringstellingsdiensten",
   code_66170000:"Diensten op het gebied van financiële consultancy, financiële transacties en clearing",
   code_66171000:"Diensten voor financieel advies",
   code_66172000:"Afhandelen van financiële transacties en clearingsdiensten",
   code_66180000:"Diensten voor het wisselen van valuta's",
   code_66190000:"Courtagediensten voor leningen",
   code_66500000:"Diensten op het gebied van verzekeringen en pensioenen",
   code_66510000:"Verzekeringsdiensten",
   code_66511000:"Levensverzekeringen",
   code_66512000:"Ongevallen- en ziektekostenverzekeringen",
   code_66512100:"Ongevallenverzekeringen",
   code_66512200:"Ziektekostenverzekeringen",
   code_66512210:"Vrijwillige ziektekostenverzekeringen",
   code_66512220:"Medische verzekeringen",
   code_66513000:"Diensten voor rechtsbijstands- en allriskverzekeringen",
   code_66513100:"Rechtsbijstandsverzekeringen",
   code_66513200:"Allriskverzekeringen voor aannemers",
   code_66514000:"Vrachtverzekering en verzekering in verband met vervoer",
   code_66514100:"Verzekering in verband met vervoer",
   code_66514110:"Verzekeringen voor motorvoertuigen",
   code_66514120:"Verzekeringen voor schepen, luchtvaartuigen en andere vervoermiddelen",
   code_66514130:"Verzekeringen voor spoorwegen",
   code_66514140:"Verzekeringen voor vliegtuigen",
   code_66514150:"Verzekeringen voor schepen",
   code_66514200:"Vrachtverzekeringen",
   code_66515000:"Verzekeringen tegen schade of verlies",
   code_66515100:"Brandverzekeringen",
   code_66515200:"Opstalverzekeringen",
   code_66515300:"Verzekeringen tegen slecht weer en financieel verlies",
   code_66515400:"Slechtweerverzekeringen",
   code_66515410:"Verzekeringen tegen financieel verlies",
   code_66515411:"Verzekeringen tegen geldelijk verlies",
   code_66516000:"Aansprakelijkheidsverzekeringen",
   code_66516100:"Aansprakelijkheidsverzekeringen voor motorvoertuigen",
   code_66516200:"Aansprakelijkheidsverzekeringen voor vliegtuigen",
   code_66516300:"Aansprakelijkheidsverzekeringen voor schepen",
   code_66516400:"Algemene aansprakelijkheidsverzekeringen",
   code_66516500:"Verzekeringen tegen beroepsaansprakelijkheidsrisico's",
   code_66517000:"Krediet- en borgstellingsverzekeringsdiensten",
   code_66517100:"Kredietverzekeringen",
   code_66517200:"Borgstellingsverzekeringen",
   code_66517300:"Risicobeheersverzekeringen",
   code_66518000:"Diensten van verzekeringsmakelaars en -agentschappen",
   code_66518100:"Verzekeringsmakelaarsdiensten",
   code_66518200:"Verzekeringsagentdiensten",
   code_66518300:"Afwikkelingsdiensten schadegevallen",
   code_66519000:"Verzekeringsdiensten voor technische installaties, aanvullende, algemene, verlies- actuariële en nooddienstverzekeringen",
   code_66519100:"Verzekeringen voor olie- of gasplatforms",
   code_66519200:"Verzekeringen voor technische installaties",
   code_66519300:"Aanvullende verzekeringen",
   code_66519310:"Verzekeringsadviesdiensten",
   code_66519400:"Gewone afwikkelingsdiensten",
   code_66519500:"Schadeafwikkelingsdiensten",
   code_66519600:"Actuariële diensten",
   code_66519700:"Administratie in verband met berging",
   code_66520000:"Pensioendiensten",
   code_66521000:"Individuele pensioenen",
   code_66522000:"Pensioenen (groepsregelingen)",
   code_66523000:"Pensioenfondsadviesdiensten",
   code_66523100:"Administratie voor pensioenfonds",
   code_66600000:"Kasdiensten",
   code_66700000:"Herverzekeringen",
   code_66710000:"Herverzekeringen levensverzekering",
   code_66720000:"Herverzekeringen ongevallen- en ziektekostenverzekering",
   code_70000000:"Makelaarsdiensten",
   code_70100000:"Makelaarsdiensten voor onroerend goed in eigen beheer",
   code_70110000:"Projectontwikkelaarsdiensten",
   code_70111000:"Projectontwikkeling voor woningen",
   code_70112000:"Projectontwikkeling voor onroerend goed anders dan woningen",
   code_70120000:"Kopen en verkopen van onroerend goed",
   code_70121000:"Kopen of verkopen van gebouwen",
   code_70121100:"Verkopen van gebouwen",
   code_70121200:"Kopen van gebouwen",
   code_70122000:"Kopen of verkopen van grond",
   code_70122100:"Verkopen van land",
   code_70122110:"Verkopen van onbebouwd land",
   code_70122200:"Kopen van land",
   code_70122210:"Aankopen van onbebouwd land",
   code_70123000:"Verkopen van onroerend goed",
   code_70123100:"Verkopen van voor bewoning bestemd onroerend goed",
   code_70123200:"Verkopen van niet voor bewoning bestemd onroerend goed",
   code_70130000:"Verhuur van onroerend goed in eigen beheer",
   code_70200000:"Verhuur of leasing van eigen onroerend goed",
   code_70210000:"Residential property renting or leasing services",
   code_70220000:"Verhuur of leasing van niet voor bewoning bestemde eigen gebouwen",
   code_70300000:"Makelaarsdiensten op honorarium- of contractbasis",
   code_70310000:"Verhuur of verkoop van gebouwen",
   code_70311000:"Verhuur of verkoop van gebouwen voor bewoning",
   code_70320000:"Verhuur of verkoop van grond",
   code_70321000:"Grondverhuur",
   code_70322000:"Verhuur of verkoop van onbebouwd land",
   code_70330000:"Beheer van onroerend goed op honorarium- of contractbasis",
   code_70331000:"Beheer van voor bewoning bestemd onroerend goed",
   code_70331100:"Beheer van instellingen",
   code_70332000:"Beheer van niet voor bewoning bestemd onroerend goed",
   code_70332100:"Beheer van grond",
   code_70332200:"Beheer van onroerend goed voor commerciële doeleinden",
   code_70332300:"Diensten voor industrieel onroerend goed",
   code_70333000:"Huisvestingsdiensten",
   code_70340000:"Timesharingdiensten",
   code_71000000:"Dienstverlening op het gebied van architectuur, bouwkunde, civiele techniek en inspectie",
   code_71200000:"Dienstverlening op het gebied van architectuur en dergelijke",
   code_71210000:"Architectonische adviezen",
   code_71220000:"Maken van bouwkundige ontwerpen",
   code_71221000:"Diensten door architectenbureaus voor gebouwen",
   code_71222000:"Diensten door architectenbureaus voor buitenvoorzieningen",
   code_71222100:"Karteringsdiensten voor stedelijke gebieden",
   code_71222200:"Karteringsdiensten voor plattelandsgebieden",
   code_71223000:"Diensten door architectenbureaus voor uitbreidingsverbouwingen",
   code_71230000:"Organisatie van een architectenprijsvraag",
   code_71240000:"Dienstverlening op het gebied van architectuur, bouwkunde en planning",
   code_71241000:"Haalbaarheidsstudie, adviesverlening, analyse",
   code_71242000:"Project- en ontwerpvoorbereiding, kostenraming",
   code_71243000:"Ontwerpplannen (systemen en integratie)",
   code_71244000:"Kostenberekening en -bewaking",
   code_71245000:"Plannen ter goedkeuring, werktekeningen en specificaties",
   code_71246000:"Bepalen en opsommen van hoeveelheden in de bouw",
   code_71247000:"Toezicht op bouwwerkzaamheden",
   code_71248000:"Toezicht op plannen en documentatie",
   code_71250000:"Dienstverlening van architecten en bouwkundigen, en controles",
   code_71251000:"Architecten- en bouwinspectiediensten",
   code_71300000:"Dienstverlening door ingenieurs",
   code_71310000:"Adviezen inzake techniek en bouw",
   code_71311000:"Adviezen inzake bouwkunde",
   code_71311100:"Ondersteunende diensten voor bouwkunde",
   code_71311200:"Adviezen inzake transportsystemen",
   code_71311210:"Adviezen inzake wegenbouw",
   code_71311220:"Dienstverlening voor wegenbouwtechniek",
   code_71311230:"Dienstverlening voor spoorwegenbouwtechniek",
   code_71311240:"Dienstverlening voor luchthavenbouwtechniek",
   code_71311300:"Adviezen inzake infrastructuur",
   code_71312000:"Bouwkundig advies",
   code_71313000:"Advies inzake milieutechniek",
   code_71313100:"Advies inzake bestrijding van geluidsoverlast",
   code_71313200:"Advies inzake geluidsisolatie- en akoestiek-projecten",
   code_71313400:"Milieueffectbeoordeling voor bouwwerkzaamheden",
   code_71313410:"Risicobeoordeling voor bouwwerkzaamheden",
   code_71313420:"Milieunormen voor de bouw",
   code_71313430:"Analyse van milieu-indicatoren voor de bouw",
   code_71313440:"Milieueffectbeoordelingsdiensten voor de bouw",
   code_71313450:"Milieutoezicht voor bouwwerkzaamheden",
   code_71314000:"Energie en aanverwante diensten",
   code_71314100:"Elektriciteitsdiensten",
   code_71314200:"Energiebeheer",
   code_71314300:"Advies inzake efficiënt energiegebruik",
   code_71314310:"Diensten in verband met warmtetechniek voor gebouwen",
   code_71315000:"Technische installaties in gebouwen",
   code_71315100:"Advies inzake ruwbouw",
   code_71315200:"Bouwadvies",
   code_71315210:"Advies inzake bouwdiensten",
   code_71315300:"Bouwinspectiediensten",
   code_71315400:"Gebouwinspectiediensten",
   code_71315410:"Inspectie van ventilatiesysteem",
   code_71316000:"Advies inzake telecommunicatie",
   code_71317000:"Advies inzake beveiliging en risicobeheersing",
   code_71317100:"Advies inzake brand- en explosiebeveiliging en -controle",
   code_71317200:"Gezondheids- en veiligheidsdiensten",
   code_71317210:"Advies inzake gezondheid en veiligheid",
   code_71318000:"Technische advies- en raadgevingsdiensten",
   code_71318100:"Diensten in verband met de uitvoering van verlichtings- en daglichtvoorzieningsprojecten",
   code_71319000:"Dienstverlening door deskundige getuigen",
   code_71320000:"Technische ontwerpdiensten",
   code_71321000:"Technische ontwerpdiensten voor mechanische en elektrische installaties voor gebouwen",
   code_71321100:"Diensten voor bouweconomie",
   code_71321200:"Ontwerpen van verwarmingssysteem",
   code_71321300:"Adviesdiensten voor loodgieterswerk",
   code_71321400:"Adviesdiensten voor ventilatie",
   code_71322000:"Technische ontwerpen voor constructie van civieltechnische werken",
   code_71322100:"Diensten voor het uittrekken van hoeveelheden voor civieltechnische installaties",
   code_71322200:"Ontwerpen van pijpleidingen",
   code_71322300:"Brugontwerpdiensten",
   code_71322400:"Damontwerpdiensten",
   code_71322500:"Technische ontwerpen van verkeersinstallaties",
   code_71323000:"Technisch ontwerpen voor bedrijfsprocessen en -productie",
   code_71323100:"Ontwerpen van elektriciteitssystemen",
   code_71323200:"Ontwerpen van installaties",
   code_71324000:"Diensten voor het uittrekken van hoeveelheden",
   code_71325000:"Ontwerpen van fundering",
   code_71326000:"Aanvullende bouwdiensten",
   code_71327000:"Ontwerpen van draagconstructies",
   code_71328000:"Berekenen van draagconstructieontwerpen",
   code_71330000:"Diverse technische dienstverlening",
   code_71331000:"Technische dienstverlening op het gebied van boorspoeling",
   code_71332000:"Geotechnische diensten",
   code_71333000:"Werktuigbouwkundige diensten",
   code_71334000:"Werktuigbouwkundige en elektrotechnische diensten",
   code_71335000:"Technische onderzoeken",
   code_71336000:"Diensten voor technische ondersteuning",
   code_71337000:"Technische dienstverlening op het gebied van corrosie",
   code_71340000:"Geïntegreerde technische diensten",
   code_71350000:"Dienstverlening inzake wetenschappen en techniek",
   code_71351000:"Geologie, geofysica en andere vormen van wetenschappelijke prospectie",
   code_71351100:"Kernpreparatie en -analyse",
   code_71351200:"Geologische en geofysische adviezen",
   code_71351210:"Advies inzake geofysica",
   code_71351220:"Geologische advisering",
   code_71351300:"Micropaleontologische analyse",
   code_71351400:"Analyse op het gebied van de petrofysica",
   code_71351500:"Bodemonderzoeksdiensten",
   code_71351600:"Weervoorspellingsdiensten",
   code_71351610:"Meteorologische diensten",
   code_71351611:"Klimatologische diensten",
   code_71351612:"Hydrometereologische diensten",
   code_71351700:"Wetenschappelijke prospectiediensten",
   code_71351710:"Geofysische prospectiediensten",
   code_71351720:"Geofysische meetwerkzaamheden van archeologische vindplaatsen",
   code_71351730:"Geologische prospectie",
   code_71351800:"Diensten voor topografie en waterscheiding",
   code_71351810:"Topografische diensten",
   code_71351811:"Topografische opmeetwerkzaamheden van archeologische vindplaatsen",
   code_71351820:"Wateropsporingsdiensten",
   code_71351900:"Geologische, oceanografische en hydrologische diensten",
   code_71351910:"Geologische diensten",
   code_71351911:"Fotogeologische diensten",
   code_71351912:"Diensten voor stratigrafische geologie",
   code_71351913:"Diensten voor geologische prospectie",
   code_71351914:"Archeologische diensten",
   code_71351920:"Oceanografische en hydrologische diensten",
   code_71351921:"Diensten voor estuariumoceanografie",
   code_71351922:"Diensten voor fysische oceanografie",
   code_71351923:"Diensten voor bathymetrische metingen",
   code_71351924:"Diensten inzake onderwaterexploratie",
   code_71352000:"Diensten voor ondergronds onderzoek",
   code_71352100:"Seismische diensten",
   code_71352110:"Seismografische onderzoeksdiensten",
   code_71352120:"Registratie van seismische gegevens",
   code_71352130:"Verzamelen van seismische gegevens",
   code_71352140:"Seismische verwerkingsdiensten",
   code_71352300:"Magnetometrische onderzoeksdiensten",
   code_71353000:"Oppervlakteonderzoek",
   code_71353100:"Hydrografisch onderzoek",
   code_71353200:"Dimensionaal onderzoek",
   code_71354000:"Cartografiediensten",
   code_71354100:"Digitale kartering",
   code_71354200:"Luchtkarteringsdiensten",
   code_71354300:"Diensten voor kadastraal onderzoek",
   code_71354400:"Hydrografische diensten",
   code_71354500:"Diensten voor marien onderzoek",
   code_71355000:"Landmeetkundige diensten",
   code_71355100:"Fotogrammetriediensten",
   code_71355200:"Topografische opmetingen",
   code_71356000:"Technische diensten",
   code_71356100:"Technische controlediensten",
   code_71356200:"Technische bijstandsdiensten",
   code_71356300:"Technische ondersteuningsdiensten",
   code_71356400:"Technische planningsdiensten",
   code_71400000:"Dienstverlening op het gebied van stedenbouw en landschapsarchitectuur",
   code_71410000:"Stedenbouw",
   code_71420000:"Landschapsarchitectuurdiensten",
   code_71421000:"Landschaps- en tuinaanlegdiensten",
   code_71500000:"Diensten in verband met de bouw",
   code_71510000:"Terreinonderzoek",
   code_71520000:"Toezicht op uitvoering van de bouw",
   code_71521000:"Toezicht op de bouwplaats",
   code_71530000:"Bouwadviezen",
   code_71540000:"Coördinatie van bouwwerkzaamheden",
   code_71541000:"Bouwprojectenbeheer",
   code_71550000:"Smederijdiensten",
   code_71600000:"Diensten voor technische testen, analyse en adviezen",
   code_71610000:"Testen en analyseren van samenstelling en zuiverheid",
   code_71620000:"Analysediensten",
   code_71621000:"Diensten voor technische analyse of adviezen",
   code_71630000:"Technische controle- en testdiensten",
   code_71631000:"Technische inspectiediensten",
   code_71631100:"Diensten voor keuring van machines",
   code_71631200:"Technische controle van motorvoertuigen",
   code_71631300:"Technische controle van gebouwen",
   code_71631400:"Technische controle van technische installaties",
   code_71631420:"Inspectiediensten voor maritieme veiligheid",
   code_71631430:"Lektestdiensten",
   code_71631440:"Debietmeetdiensten",
   code_71631450:"Bruginspectiediensten",
   code_71631460:"Daminspectiediensten",
   code_71631470:"Diensten voor keuring van rails",
   code_71631480:"Wegeninspectiediensten",
   code_71631490:"Inspectiediensten voor start/landingsbanen",
   code_71632000:"Technische testdiensten",
   code_71632100:"Testen van kleppen",
   code_71632200:"Uitvoeren van niet-destructieve proeven",
   code_71700000:"Meet- en controlediensten",
   code_71730000:"Industriële inspectiediensten",
   code_71731000:"Diensten voor industriële kwaliteitscontrole",
   code_71800000:"Adviesdiensten voor watervoorziening en afval",
   code_71900000:"Laboratoriumdiensten",
   code_72000000:"IT-diensten: adviezen, softwareontwikkeling, internet en ondersteuning",
   code_72100000:"Advies inzake hardware",
   code_72110000:"Advies inzake hardwarekeuze",
   code_72120000:"Advies inzake calamiteitenherstel bij hardware",
   code_72130000:"Advies over planning computerlocatie",
   code_72140000:"Advies over acceptatietest computerhardware",
   code_72150000:"Advies over computeraudit en -hardware",
   code_72200000:"Softwareprogrammering en -advies",
   code_72210000:"Programmering van softwarepakketten",
   code_72211000:"Programmering van systeem- en gebruikerssoftware",
   code_72212000:"Programmering van toepassingssoftware",
   code_72212100:"Diensten voor ontwikkeling van speciale software voor de industrie",
   code_72212110:"Diensten voor ontwikkeling van software voor verkooppunten",
   code_72212120:"Diensten voor ontwikkeling van software voor vluchtbesturing",
   code_72212121:"Diensten voor ontwikkeling van software voor luchtverkeersleiding",
   code_72212130:"Diensten voor ontwikkeling van software voor grondondersteuning in de luchtvaart en testsoftware",
   code_72212131:"Diensten voor ontwikkeling van software voor grondondersteuning in de luchtvaart",
   code_72212132:"Diensten voor ontwikkeling van testsoftware voor de luchtvaart",
   code_72212140:"Diensten voor ontwikkeling van software voor spoorwegverkeersleiding",
   code_72212150:"Diensten voor ontwikkeling van software voor industriële controle",
   code_72212160:"Diensten voor ontwikkeling van bibliotheeksoftware",
   code_72212170:"Diensten voor ontwikkeling van conformiteitssoftware",
   code_72212180:"Diensten voor ontwikkeling van medische software",
   code_72212190:"Diensten voor ontwikkeling van onderwijssoftware",
   code_72212200:"Diensten voor ontwikkeling van software voor netwerken, internet en intranet",
   code_72212210:"Diensten voor ontwikkeling van software voor netweken",
   code_72212211:"Diensten voor ontwikkeling van software voor platforminterconnectiviteit",
   code_72212212:"Diensten voor ontwikkeling van software voor optical jukebox server",
   code_72212213:"Diensten voor ontwikkeling van software voor verbetering van besturingssysteem",
   code_72212214:"Diensten voor ontwikkeling van software voor netwerkbesturingssysteem",
   code_72212215:"Diensten voor ontwikkeling van software voor netwerkontwikkelaars",
   code_72212216:"Diensten voor ontwikkeling van software voor netwerkverbindingdsterminalemulatie",
   code_72212217:"Diensten voor ontwikkeling van transactionele verwerkingssoftware",
   code_72212218:"Diensten voor ontwikkeling van software voor licentiemanagement",
   code_72212219:"Diensten voor ontwikkeling van software voor diverse netweken",
   code_72212220:"Diensten voor ontwikkeling van software voor internet en intranet",
   code_72212221:"Diensten voor ontwikkeling van software voor internetbrowsing",
   code_72212222:"Diensten voor ontwikkeling van software voor webserver",
   code_72212223:"Diensten voor softwareontwikkeling voor e-mail",
   code_72212224:"Diensten voor ontwikkeling van software voor web page editing",
   code_72212300:"Diensten voor ontwikkeling van software voor het maken van documenten, tekeningen, afbeeldingen, dienstregelingen en productiviteit",
   code_72212310:"Diensten voor ontwikkeling van software voor het maken van documenten",
   code_72212311:"Diensten voor ontwikkeling van software voor documentenbeheer",
   code_72212312:"Diensten voor ontwikkeling van software voor elektronisch publiceren",
   code_72212313:"Diensten voor ontwikkeling van software voor OCR (optische schriftherkenner)",
   code_72212314:"Diensten voor ontwikkeling van software voor spraakherkenning",
   code_72212315:"Diensten voor ontwikkeling van software voor desktoppublishing",
   code_72212316:"Diensten voor ontwikkeling van presentatiesoftware",
   code_72212317:"Diensten voor ontwikkeling van tekstverwerkingssoftware",
   code_72212318:"Diensten voor ontwikkeling van scannersoftware",
   code_72212320:"Diensten voor ontwikkeling van software voor het maken van tekeningen en afbeeldingen",
   code_72212321:"Diensten voor CAD-softwareontwikkeling",
   code_72212322:"Diensten voor ontwikkeling van grafische software",
   code_72212323:"Diensten voor CAM-softwareontwikkeling",
   code_72212324:"Diensten voor ontwikkeling van karteringssoftware",
   code_72212325:"Diensten voor ontwikkeling van software voor het maken van formulieren",
   code_72212326:"Diensten voor ontwikkeling van cartografiesoftware",
   code_72212327:"Diensten voor ontwikkeling van teken- en schildersoftware",
   code_72212328:"Diensten voor ontwikkeling van beeldverwerkingssoftware",
   code_72212330:"Diensten voor ontwikkeling van software voor dienstregelingen en productiviteit",
   code_72212331:"Diensten voor ontwikkeling van projectmanagement-software",
   code_72212332:"Diensten voor ontwikkeling van dienstregelingen-software",
   code_72212333:"Diensten voor ontwikkeling van contactmanagement-software",
   code_72212400:"Diensten voor ontwikkeling van software voor zakelijke transacties en persoonlijke zaken",
   code_72212410:"Diensten voor ontwikkeling van software voor investeringsbeheer en belastingaangifte",
   code_72212411:"Diensten voor ontwikkeling van software voor investeringsbeheer",
   code_72212412:"Diensten voor ontwikkeling van belastingaangiftesoftware",
   code_72212420:"Diensten voor ontwikkeling van software en opvolgingssoftware voor faciliteitenbeheer",
   code_72212421:"Diensten voor ontwikkeling van software voor faciliteitenbeheer",
   code_72212422:"Diensten voor ontwikkeling van opvolgingssoftware",
   code_72212430:"Diensten voor ontwikkeling van software voor inventarisbeheer",
   code_72212440:"Diensten voor ontwikkeling van software voor financiële analyse en boekhouding",
   code_72212441:"Diensten voor ontwikkeling van software voor financiële analyse",
   code_72212442:"Diensten voor ontwikkeling van software voor financiële systemen",
   code_72212443:"Diensten voor ontwikkeling van boekhoudsoftware",
   code_72212445:"Diensten voor ontwikkeling van software voor elektronisch klantenbeheer",
   code_72212450:"Diensten voor ontwikkeling van software voor tijdregistratie of personele middelen",
   code_72212451:"Diensten voor ontwikkeling van software voor ERP (Enterprise resource planning)",
   code_72212460:"Diensten voor ontwikkeling van analytische, wetenschappelijke, mathematische of prognosesoftware",
   code_72212461:"Diensten voor ontwikkeling van analytische of wetenschappelijke software",
   code_72212462:"Diensten voor ontwikkeling van mathematische of prognosesoftware",
   code_72212463:"Diensten voor ontwikkeling van statistieksoftware",
   code_72212470:"Diensten voor ontwikkeling van software voor veilingen",
   code_72212480:"Diensten voor ontwikkeling van software voor verkoop, marketing en business intelligence",
   code_72212481:"Diensten voor ontwikkeling van software voor verkoop of marketing",
   code_72212482:"Diensten voor ontwikkeling van business intelligence software",
   code_72212490:"Diensten voor ontwikkeling van aankoopsoftware",
   code_72212500:"Diensten voor ontwikkeling van communicatie- en multimediasoftware",
   code_72212510:"Diensten voor ontwikkeling van communicatiesoftware",
   code_72212511:"Diensten voor ontwikkeling van software voor desktop-communicatie",
   code_72212512:"Diensten voor ontwikkeling van software voor interactieve spraakbeantwoorders (IVR)",
   code_72212513:"Diensten voor ontwikkeling van modemsoftware",
   code_72212514:"Diensten voor ontwikkeling van remote-access-software",
   code_72212515:"Diensten voor ontwikkeling van software voor videoconferentie",
   code_72212516:"Diensten voor ontwikkeling van uitwisselingssoftware",
   code_72212517:"Diensten voor ontwikkeling van IT-software",
   code_72212518:"Diensten voor ontwikkeling van emulatiesoftware",
   code_72212519:"Diensten voor ontwikkeling van geheugenbeheer-software",
   code_72212520:"Diensten voor ontwikkeling van multimediasoftware",
   code_72212521:"Diensten voor ontwikkeling van editingsoftware voor muziek of geluid",
   code_72212522:"Diensten voor ontwikkeling van software voor virtueel keyboard",
   code_72212600:"Diensten voor ontwikkeling van database- en besturingssoftware",
   code_72212610:"Diensten voor ontwikkeling van databasesoftware",
   code_72212620:"Diensten voor ontwikkeling van netwerkbesturingssoftware",
   code_72212630:"Diensten voor ontwikkeling van minicomputerbesturingssoftware",
   code_72212640:"Diensten voor ontwikkeling van microcomputerbesturingssoftware",
   code_72212650:"Diensten voor ontwikkeling van PC-besturingssoftware",
   code_72212660:"Diensten voor ontwikkeling van clustersoftware",
   code_72212670:"Diensten voor ontwikkeling van software voor realtimebesturingssysteem",
   code_72212700:"Diensten voor ontwikkeling van algemene dienstsoftware",
   code_72212710:"Diensten voor ontwikkeling van back-up- of recoverysoftware",
   code_72212720:"Diensten voor ontwikkeling van barcodesoftware",
   code_72212730:"Diensten voor ontwikkeling van beveiligingssoftware",
   code_72212731:"Diensten voor ontwikkeling van bestandbeveiligingssoftware",
   code_72212732:"Diensten voor ontwikkeling van databeveiligingssoftware",
   code_72212740:"Diensten voor ontwikkeling van vertaalsoftware",
   code_72212750:"Diensten voor ontwikkeling van software voor het laden van geheugendragers",
   code_72212760:"Diensten voor ontwikkeling van virusbeschermingssoftware",
   code_72212761:"Diensten voor ontwikkeling van anti-virussoftware",
   code_72212770:"Diensten voor ontwikkeling van software voor algemene, comprimerings- en drukkervoorzieningen",
   code_72212771:"Diensten voor ontwikkeling van software voor algemene voorzieningen",
   code_72212772:"Diensten voor ontwikkeling van software voor drukkervoorzieningen",
   code_72212780:"Diensten voor ontwikkeling van software voor systeem-, opslag- en inhoudbeheer",
   code_72212781:"Diensten voor ontwikkeling van systeembeheer-software",
   code_72212782:"Diensten voor ontwikkeling van opslagbeheer-software",
   code_72212783:"Diensten voor ontwikkeling van inhoudsbeheer-software",
   code_72212790:"Diensten voor ontwikkeling van spellingcheckersoftware",
   code_72212900:"Diensten voor ontwikkeling van diverse software en computersystemen",
   code_72212910:"Diensten voor ontwikkeling van software voor computerspelletjes, gezinsvideo's en screensavers",
   code_72212911:"Diensten voor ontwikkeling van software voor computerspelletjes",
   code_72212920:"Diensten voor ontwikkeling van kantoorautomatiseringssoftware",
   code_72212930:"Diensten voor ontwikkeling van opleidings- en vermaaksoftware",
   code_72212931:"Diensten voor ontwikkeling van opleidingssoftware",
   code_72212932:"Diensten voor ontwikkeling van vermaaksoftware",
   code_72212940:"Diensten voor ontwikkeling van patroonontwerp- en kalendersoftware",
   code_72212941:"Diensten voor ontwikkeling van patroonontwerpsoftware",
   code_72212942:"Diensten voor ontwikkeling van kalendersoftware",
   code_72212960:"Diensten voor ontwikkeling van besturings- en systeemsoftware",
   code_72212970:"Diensten voor ontwikkeling van printshopsoftware",
   code_72212971:"Diensten voor ontwikkeling van software voor het maken van adressenbestanden",
   code_72212972:"Diensten voor ontwikkeling van software voor het maken van labels",
   code_72212980:"Diensten voor het ontwikkelen van programmeertalen en tools",
   code_72212981:"Diensten voor ontwikkeling van compilingsoftware",
   code_72212982:"Diensten voor ontwikkeling van configuratiebeheersoftware",
   code_72212983:"Diensten voor ontwikkeling van ontwikkelingssoftware",
   code_72212984:"Diensten voor ontwikkeling van programmatestsoftware",
   code_72212985:"Diensten voor ontwikkeling van foutenopsporingssoftware",
   code_72212990:"Diensten voor ontwikkeling van software voor spreadsheets en verbetering",
   code_72212991:"Diensten voor ontwikkeling van spreadsheetsoftware",
   code_72220000:"Systeem- en technisch advies",
   code_72221000:"Bedrijfsanalyseadviezen",
   code_72222000:"Strategie- en planningsdiensten voor informatiesystemen of -technologie",
   code_72222100:"Strategisch onderzoek van informatiesystemen of -technologie",
   code_72222200:"Planning in verband met informatiesystemen of -technologie",
   code_72222300:"Diensten in verband met informatietechnologie",
   code_72223000:"Onderzoek naar vereisten inzake informatietechnologie",
   code_72224000:"Advies over projectmanagement",
   code_72224100:"Planning van systeemimplementatie",
   code_72224200:"Planning van systeemkwaliteitsborging",
   code_72225000:"Evaluatie en onderzoek van systeemkwaliteitsborging",
   code_72226000:"Advies over acceptatietest systeemsoftware",
   code_72227000:"Advies over software-integratie",
   code_72228000:"Advies over hardware-integratie",
   code_72230000:"Ontwikkeling van gebruikersspecifieke software",
   code_72231000:"Ontwikkeling van software voor militaire doeleinden",
   code_72232000:"Ontwikkeling van software voor transactieverwerking en gebruikersspecifieke software",
   code_72240000:"Systeemanalyse en programmering",
   code_72241000:"Specificatie van kritisch ontwerpdoel",
   code_72242000:"Maken van ontwerp modellen",
   code_72243000:"Programmeringsdiensten",
   code_72244000:"Maken van prototypes",
   code_72245000:"Contractsysteemanalyse en -programmering",
   code_72246000:"Systeemadviesdiensten",
   code_72250000:"Systeem- en ondersteuningsdiensten",
   code_72251000:"Herstarten na calamiteiten",
   code_72252000:"Computerarchiveringsdiensten",
   code_72253000:"Helpdesk- en ondersteuningsdiensten",
   code_72253100:"Helpdeskdiensten",
   code_72253200:"Systeemondersteuningsdiensten",
   code_72254000:"Testen van software",
   code_72254100:"Testen van systemen",
   code_72260000:"Diensten in verband met software",
   code_72261000:"Softwareondersteuningsdiensten",
   code_72262000:"Ontwikkeling van software",
   code_72263000:"Software-implementatiediensten",
   code_72264000:"Kopiëren van software",
   code_72265000:"Softwareconfiguratiediensten",
   code_72266000:"Advies over software",
   code_72267000:"Onderhouds- en reparatiediensten voor software",
   code_72267100:"Onderhoud van software voor informatietechnologie",
   code_72267200:"Reparatie van software voor informatietechnologie",
   code_72268000:"Leveren van software",
   code_72300000:"Uitwerken van gegevens",
   code_72310000:"Gegevensverwerkingsdiensten",
   code_72311000:"Tabellering per computer",
   code_72311100:"Gegevensconversie",
   code_72311200:"Batch-verwerkingsdiensten",
   code_72311300:"Diensten voor (computer)-multi-use",
   code_72312000:"Invoeren van gegevens",
   code_72312100:"Voorbereiding van gegevens",
   code_72312200:"Herkenning van optische tekens",
   code_72313000:"Gegevensvergaringsdiensten",
   code_72314000:"Verzamelen en collationeren van gegevens",
   code_72315000:"Beheer van datanetwerken en ondersteunende diensten",
   code_72315100:"Datanetwerkondersteuningsdiensten",
   code_72315200:"Beheer van gegevensnetwerk",
   code_72316000:"Analyseren van gegevens",
   code_72317000:"Opslaan van gegevens",
   code_72318000:"Datatransmissiediensten",
   code_72319000:"Verstrekking van gegevens",
   code_72320000:"Databasediensten",
   code_72321000:"Diensten voor database met toegevoegde waarde",
   code_72322000:"Gegevensbeheerdiensten",
   code_72330000:"Diensten voor inhouds- of datastandaardisatie en –classificatie",
   code_72400000:"Internetdiensten",
   code_72410000:"Providerdiensten",
   code_72411000:"Internetproviders (ISP)",
   code_72412000:"Provider voor e-maildiensten",
   code_72413000:"Diensten voor het ontwerpen van websites",
   code_72414000:"Providerdiensten voor webzoekmachines",
   code_72415000:"Verlenen van host-diensten voor websites",
   code_72416000:"Providerdiensten van applicatieservice",
   code_72417000:"Internet-domeinnamen",
   code_72420000:"Internet-ontwikkelingsdiensten",
   code_72421000:"Diensten voor ontwikkeling van internet- of intranetklantenapplicatie",
   code_72422000:"Diensten voor ontwikkeling van internet- of intranetserverapplicatie",
   code_72500000:"Informaticadiensten",
   code_72510000:"Beheerdiensten in verband met computers",
   code_72511000:"Software voor netwerkbeheer",
   code_72512000:"Documentbeheerdiensten",
   code_72513000:"Kantoorautomatiseringsdiensten",
   code_72514000:"Beheer van computerfaciliteiten",
   code_72514100:"Faciliteitenbeheer door computerbewerking",
   code_72514200:"Faciliteitenbeheer voor ontwikkeling van computersystemen",
   code_72514300:"Faciliteitenbeheer voor onderhoud van computersystemen",
   code_72540000:"Computerupgrading",
   code_72541000:"Uitbreiding van computerinstallaties",
   code_72541100:"Uitbreiding van geheugen",
   code_72590000:"Professionele dienstverlening in verband met computers",
   code_72591000:"Uitwerken van dienstverleningscontracten",
   code_72600000:"Diensten voor computerondersteuning en -advies",
   code_72610000:"Computerondersteuningsdiensten",
   code_72611000:"Technische computerondersteuningsdiensten",
   code_72700000:"Computernetwerkdiensten",
   code_72710000:"LAN-diensten",
   code_72720000:"WAN-diensten",
   code_72800000:"Computeraudit- en testdiensten",
   code_72810000:"Automatiseringscontrole",
   code_72820000:"Testen van computers",
   code_72900000:"Diensten voor computerbackup en converteren van computercatalogus",
   code_72910000:"Back-updiensten voor computers",
   code_72920000:"Converteren van computercatalogus",
   code_73000000:"Onderzoek en ontwikkeling, en aanverwante adviezen",
   code_73100000:"Onderzoek en experimentele ontwikkeling",
   code_73110000:"Uitvoeren van onderzoek",
   code_73111000:"Diensten van researchlaboratorium",
   code_73112000:"Onderzoek der zee",
   code_73120000:"Experimentele ontwikkeling",
   code_73200000:"Advies inzake onderzoek en ontwikkeling",
   code_73210000:"Advies inzake onderzoek",
   code_73220000:"Advies inzake ontwikkeling",
   code_73300000:"Planning en uitvoering van onderzoek en ontwikkeling",
   code_73400000:"O&O-diensten voor beveiligings- en defensiematerialen",
   code_73410000:"Onderzoek en technologie voor het leger",
   code_73420000:"Voorbereidende haalbaarheidsstudie en technologische demonstratie",
   code_73421000:"Ontwikkelen van veiligheidsuitrusting",
   code_73422000:"Ontwikkelen van vuurwapens en munitie",
   code_73423000:"Ontwikkelen van militaire voertuigen",
   code_73424000:"Ontwikkelen van oorlogsschepen",
   code_73425000:"Ontwikkelen van militaire lucht- en ruimtevaartuigen en raketten",
   code_73426000:"Ontwikkelen van militaire elektronische systemen",
   code_73430000:"Testen en beoordelen",
   code_73431000:"Test en evaluatie van veiligheidsuitrusting",
   code_73432000:"Testen en beoordelen van vuurwapens en munitie",
   code_73433000:"Testen en beoordelen van militaire voertuigen",
   code_73434000:"Testen en beoordelen van oorlogsschepen",
   code_73435000:"Testen en beoordelen van militaire lucht- en ruimtevaartuigen en raketten",
   code_73436000:"Testen en beoordelen van militaire elektronische systemen",
   code_75000000:"Diensten voor openbaar bestuur, defensie en sociale verzekering",
   code_75100000:"Administratiediensten",
   code_75110000:"Algemene overheidsdiensten",
   code_75111000:"Uitvoerende en wetgevende diensten",
   code_75111100:"Uitvoerende diensten",
   code_75111200:"Wetgevende diensten",
   code_75112000:"Administratieve diensten voor bedrijfsactiviteiten",
   code_75112100:"Administratieve diensten voor ontwikkelingsproject",
   code_75120000:"Administratieve diensten door agentschappen",
   code_75121000:"Administratieve diensten voor onderwijs",
   code_75122000:"Administratieve diensten voor gezondheidszorg",
   code_75123000:"Administratieve diensten voor huisvesting",
   code_75124000:"Administratieve diensten voor recreatie, cultuur en religie",
   code_75125000:"Administratieve diensten in verband met toerisme",
   code_75130000:"Ondersteunende diensten voor de overheid",
   code_75131000:"Overheidsdiensten",
   code_75131100:"Algemene personeelsdiensten voor de overheid",
   code_75200000:"Diensten ten behoeve van de gemeenschap",
   code_75210000:"Diensten voor buitenlandse zaken en andere diensten",
   code_75211000:"Diensten voor buitenlandse zaken",
   code_75211100:"Diplomatieke diensten",
   code_75211110:"Consulaire diensten",
   code_75211200:"Diensten in verband met economische hulp aan het buitenland",
   code_75211300:"Diensten in verband met militaire hulp aan het buitenland",
   code_75220000:"Defensiediensten",
   code_75221000:"Militaire defensiediensten",
   code_75222000:"Burgerbeschermingsdiensten",
   code_75230000:"Gerechtelijke diensten",
   code_75231000:"Justitiële diensten",
   code_75231100:"Administratieve diensten met betrekking tot rechtbanken",
   code_75231200:"Diensten voor verzekerde bewaring of reclassering van misdadigers",
   code_75231210:"Penitentiaire diensten",
   code_75231220:"Escorteren van gedetineerden",
   code_75231230:"Gevangenisdiensten",
   code_75231240:"Proeftijddiensten",
   code_75240000:"Diensten voor openbare orde, recht en veiligheid",
   code_75241000:"Diensten voor openbare orde",
   code_75241100:"Politiediensten",
   code_75242000:"Recht- en veiligheidsdiensten",
   code_75242100:"Dienstverlening in verband met de openbare orde",
   code_75242110:"Deurwaardersdiensten",
   code_75250000:"Brandweer- en reddingsdiensten",
   code_75251000:"Brandweerdiensten",
   code_75251100:"Brandbestrijdingsdiensten",
   code_75251110:"Brandpreventiediensten",
   code_75251120:"Bosbrandbestrijdingsdiensten",
   code_75252000:"Reddingsdiensten",
   code_75300000:"Diensten voor verplichte sociale verzekering",
   code_75310000:"Uitkeringsdiensten",
   code_75311000:"Uitkeringen bij ziekte",
   code_75312000:"Zwangerschapsuitkeringen",
   code_75313000:"Invaliditeitsuitkeringen",
   code_75313100:"Tijdelijke invaliditeitsuitkeringen",
   code_75314000:"Werkloosheidsuitkeringen",
   code_75320000:"Pensioenstelsels voor ambtenaren",
   code_75330000:"Gezinstoelagen",
   code_75340000:"Kinderbijslag",
   code_76000000:"Diensten in verband met de olie- en gasindustrie",
   code_76100000:"Specialistische diensten voor de gasindustrie",
   code_76110000:"Diensten in verband met de gaswinning",
   code_76111000:"Verdampen",
   code_76120000:"Duikersdiensten in verband met gaswinning",
   code_76121000:"Duikersdiensten voor onderzeese putten",
   code_76200000:"Specialistische diensten voor de olie-industrie",
   code_76210000:"Diensten in verband met oliewinning",
   code_76211000:"Zeefpijphangerdiensten",
   code_76211100:"Aanbrengen van bekleding",
   code_76211110:"Diensten voor het aanbrengen van bekleding in proefput",
   code_76211120:"Diensten voor het aanbrengen van putbekleding op boorlokatie",
   code_76211200:"Analyseren van boorspoeling",
   code_76300000:"Boringen",
   code_76310000:"Boringen in verband met gaswinning",
   code_76320000:"Offshoreboringen",
   code_76330000:"Turbineboringen",
   code_76331000:"Diensten voor turbineboring van spoelen",
   code_76340000:"Ringboren",
   code_76400000:"Plaatsbepaling voor platforms",
   code_76410000:"Aanbrengen van mantelbuizen en stijgbuizen voor boorputten",
   code_76411000:"Aanbrengen van mantelbuizen voor boorputten",
   code_76411100:"Personeelsdiensten voor het aanbrengen van mantelbuizen",
   code_76411200:"Planningsdiensten voor het aanbrengen van mantelbuizen",
   code_76411300:"Boordiensten voor het aanbrengen van mantelbuizen",
   code_76411400:"Diensten voor het afwerken van verbuizing",
   code_76420000:"Boorputcementering",
   code_76421000:"Diensten voor het aanbrengen van cementbekleding",
   code_76422000:"Diensten voor het aanbrengen van cementafdichtingen",
   code_76423000:"Diensten voor het aanbrengen van schuimcement",
   code_76430000:"Boren en exploiteren van boorputten",
   code_76431000:"Boren van boorputten",
   code_76431100:"Controlediensten bij boringen",
   code_76431200:"Diensten voor het terugwinnen van boormateriaal",
   code_76431300:"Ddiensten voor het demonteren van boorpijpen",
   code_76431400:"Diensten voor het uitvoeren van voorboringen",
   code_76431500:"Supervisiediensten bij boorwerkzaamheden",
   code_76431600:"Toezichtdiensten voor boorinstallatie",
   code_76440000:"Loggen in boorput",
   code_76441000:"Meetdiensten van verbuisde boorgaten",
   code_76442000:"Meetdiensten van onverbuisde boorgaten",
   code_76443000:"Overige meetdiensten",
   code_76450000:"Boorputbeheer",
   code_76460000:"Ondersteunende diensten voor boorput",
   code_76470000:"Testen van boorputten",
   code_76471000:"Diensten voor het inspecteren van boorputten op scheuren",
   code_76472000:"Inspectiediensten van boorputtesten",
   code_76473000:"Diensten voor het testen van boorputmaterieel",
   code_76480000:"Aanbrengen van stijgbuizen",
   code_76490000:"Boorputafwerking",
   code_76491000:"Afdichten van boorputten",
   code_76492000:"Positioneren van boorputten",
   code_76500000:"On- en offshorediensten",
   code_76510000:"Onshorediensten",
   code_76520000:"Offshorediensten",
   code_76521000:"Offshore-installatiediensten",
   code_76522000:"Inzet van offshorebevoorradingsschepen",
   code_76530000:"Boorgatdiensten",
   code_76531000:"Diensten voor registratie in boorgat",
   code_76532000:"Pompdiensten in boorgat",
   code_76533000:"Dataregistratiediensten in boorgat",
   code_76534000:"Diensten voor het verwijden van boorgat",
   code_76535000:"Diensten voor het openen van boorgat",
   code_76536000:"Diensten voor trillingscontrole in boorgat",
   code_76537000:"Ter beschikking stellen van speciaal gereedschap voor boorgat",
   code_76537100:"Ter beschikking stellen van speciaal gereedschap voor olieboorgat",
   code_76600000:"Inspectie van pijpleidingen",
   code_77000000:"Diensten voor land-, bos- en tuinbouw, aquicultuur en imkerij",
   code_77100000:"Diensten voor de landbouw",
   code_77110000:"Diensten in verband met landbouwproductie",
   code_77111000:"Verhuur van landbouwmachines met bedieningspersoneel",
   code_77112000:"Verhuur van maaimachines of landbouwmaterieel met bedieningspersoneel",
   code_77120000:"Composteerdiensten",
   code_77200000:"Bosbouwdiensten",
   code_77210000:"Houtwinning",
   code_77211000:"Diensten in verband met houtwinning",
   code_77211100:"Houtvestersdiensten",
   code_77211200:"Vervoer van stukken boomstam in het bos",
   code_77211300:"Rooien van bomen",
   code_77211400:"Vellen van bomen",
   code_77211500:"Onderhouden van bomen",
   code_77211600:"Zaaien van bomen",
   code_77220000:"Impregneren van hout",
   code_77230000:"Diensten in verband met bosbouw",
   code_77231000:"Diensten voor bosbouwbeheer",
   code_77231100:"Beheersdiensten van bosbestanden",
   code_77231200:"Diensten voor ongediertebestrijding in bossen",
   code_77231300:"Bosbeheersdiensten",
   code_77231400:"Bosinventarisatiediensten",
   code_77231500:"Diensten voor bosmonitoring en -beoordeling",
   code_77231600:"Bebossingsdiensten",
   code_77231700:"Diensten voor bosuitbreiding",
   code_77231800:"Beheersdiensten van bosboomkwekerij",
   code_77231900:"Sectorale planningsdiensten voor bosbouw",
   code_77300000:"Tuinbouwdiensten",
   code_77310000:"Beplanten en onderhouden van groengebieden",
   code_77311000:"Onderhouden van sier- en recreatietuinen",
   code_77312000:"Diensten voor het wieden van onkruid",
   code_77312100:"Onkruidverdelging",
   code_77313000:"Parkonderhoud",
   code_77314000:"Onderhoud van terreinen",
   code_77314100:"Aanleggen van gazons",
   code_77315000:"Zaaiwerkzaamheden",
   code_77320000:"Onderhouden van sportvelden",
   code_77330000:"Uitvoeren van bloemenarrangementen",
   code_77340000:"Snoeien van bomen en heggen",
   code_77341000:"Snoeien van bomen",
   code_77342000:"Snoeien van heggen",
   code_77400000:"Zoölogische diensten",
   code_77500000:"Veehouderijdiensten",
   code_77510000:"Diensten voor vermeerdering van de wildstand",
   code_77600000:"Diensten in verband met de jacht",
   code_77610000:"Zetten van vallen",
   code_77700000:"Diensten in verband met visserij",
   code_77800000:"Aquacultuurdiensten",
   code_77810000:"Zeewatercultuurdiensten",
   code_77820000:"Oesterkwekerijdiensten",
   code_77830000:"Schelpdierkwekerijdiensten",
   code_77840000:"Garnalenkwekerijdiensten",
   code_77850000:"Viskwekerijdiensten",
   code_77900000:"Bijenteeltdiensten",
   code_79000000:"Zakelijke dienstverlening: juridisch, marketing, consulting, drukkerij en beveiliging",
   code_79100000:"Juridische dienstverlening",
   code_79110000:"Juridisch advies en juridische vertegenwoordiging",
   code_79111000:"Juridisch advies",
   code_79112000:"Juridische vertegenwoordiging",
   code_79112100:"Vertegenwoordigingsdiensten voor belanghebbenden",
   code_79120000:"Adviezen inzake octrooien en auteursrechten",
   code_79121000:"Adviezen inzake auteursrechten",
   code_79121100:"Adviezen inzake auteursrechten voor software",
   code_79130000:"Juridische documentatie en certificering",
   code_79131000:"Documentatiedienstverlening",
   code_79132000:"Certificeringsdienstverlening",
   code_79132100:"Certificatiedienst voor elektronische handtekeningen",
   code_79140000:"Juridische adviezen en informatie",
   code_79200000:"Boekhoudkundige, audit- en fiscale diensten",
   code_79210000:"Boekhoudkundige en auditdiensten",
   code_79211000:"Boekhoudingsdiensten",
   code_79211100:"Boekhouddiensten",
   code_79211110:"Loonadministratiediensten",
   code_79211120:"Registratie van aan- en verkoop",
   code_79211200:"Opstellen van financiële verslagen",
   code_79212000:"Auditdiensten",
   code_79212100:"Uitvoeren van financiële audit",
   code_79212110:"Diensten voor corporate governance rating",
   code_79212200:"Uitvoeren van interne controle",
   code_79212300:"Uitvoeren van wettelijk verplichte controle",
   code_79212400:"Uitvoeren van fraudeonderzoek",
   code_79212500:"Controleren van de boekhouding",
   code_79220000:"Fiscale dienstverlening",
   code_79221000:"Belastingadviezen",
   code_79222000:"Hulp bij invullen van belastingformulieren",
   code_79223000:"Douanediensten",
   code_79300000:"Markt- en economieonderzoek; enquêtes en statistieken",
   code_79310000:"Uitvoeren van marktonderzoek",
   code_79311000:"Uitvoeren van studies",
   code_79311100:"Opstellen van opiniepeiling",
   code_79311200:"Diensten voor het uitvoeren van enquêtes",
   code_79311210:"Uitvoeren van telefonische enquêtes",
   code_79311300:"Analyseren van opiniepeiling",
   code_79311400:"Economisch onderzoek",
   code_79311410:"Economischeffectbeoordeling",
   code_79312000:"Testen van de markt",
   code_79313000:"Controle van prestaties",
   code_79314000:"Haalbaarheidsonderzoek",
   code_79315000:"Sociaal onderzoek",
   code_79320000:"Uitvoeren van opiniepeiling",
   code_79330000:"Statistische dienstverlening",
   code_79340000:"Reclame- en marketingdiensten",
   code_79341000:"Reclamediensten",
   code_79341100:"Diensten voor advies inzake reclame",
   code_79341200:"Diensten voor reclamebeheer",
   code_79341400:"Reclamecampagnediensten",
   code_79341500:"Luchtreclamediensten",
   code_79342000:"Marketingdiensten",
   code_79342100:"Direct marketingdiensten",
   code_79342200:"Promotiediensten",
   code_79342300:"Dienstverlening voor klanten",
   code_79342310:"Diensten voor klantenonderzoek",
   code_79342311:"Onderzoek naar tevredenheid bij klanten",
   code_79342320:"Diensten voor klantenservice",
   code_79342321:"Programma voor klantenbinding",
   code_79342400:"Veilingdiensten",
   code_79342410:"Elektronische veilingdiensten",
   code_79400000:"Adviezen inzake bedrijfsvoering en management, en aanverwante diensten",
   code_79410000:"Advies inzake bedrijfsvoering en management",
   code_79411000:"Advies inzake algemeen management",
   code_79411100:"Advisering omtrent bedrijfsontwikkeling",
   code_79412000:"Advies inzake financieel management",
   code_79413000:"Advies inzake marketing management",
   code_79414000:"Advies inzake personeelsbeheer",
   code_79415000:"Advies inzake productiebeheer",
   code_79415200:"Ontwerpadviezen",
   code_79416000:"Public relationsdiensten",
   code_79416100:"Diensten voor public relationsbeheer",
   code_79416200:"Advies inzake public relations",
   code_79417000:"Veiligheidsadviezen",
   code_79418000:"Advies inzake aanschaffingsbeleid",
   code_79419000:"Evaluatie-adviesdiensten",
   code_79420000:"Diensten op het gebied van management",
   code_79421000:"Projectbeheerdiensten, met uitzondering van projecten bij bouwwerkzaamheden",
   code_79421100:"Toezicht op uitvoering van projecten, met uitzondering van projecten bij bouwwerkzaamheden",
   code_79421200:"Maken van projecten, met uitzondering van projecten bij bouwwerkzaamheden",
   code_79422000:"Arbitrage- en bemiddelingsdiensten",
   code_79430000:"Diensten voor crisismanagement",
   code_79500000:"Ondersteuning voor kantoorwerk",
   code_79510000:"Telefoonbeantwoordingsdiensten",
   code_79511000:"Telefoonbedieningsdiensten",
   code_79512000:"Telefonisch informatiecentrum",
   code_79520000:"Reprografiediensten",
   code_79521000:"Fotokopieerdiensten",
   code_79530000:"Vertaaldiensten",
   code_79540000:"Tolkdiensten",
   code_79550000:"Typen, tekstverwerking en desktop publishing",
   code_79551000:"Typen",
   code_79552000:"Tekstverwerkingsdiensten",
   code_79553000:"Desktop-publishing-diensten",
   code_79560000:"Diensten voor dossieropslag",
   code_79570000:"Samenstelling van verzendlijsten en verzenddienst",
   code_79571000:"Verzenddiensten",
   code_79600000:"Recruteringsdiensten",
   code_79610000:"Plaatsing van personeel",
   code_79611000:"Diensten voor sollicitatiebegeleiding",
   code_79612000:"Plaatsing van ondersteunend kantoorpersoneel",
   code_79613000:"Verhuizen van personeel",
   code_79620000:"Diensten voor de terbeschikkingstelling van personeel, met inbegrip van tijdelijk personeel",
   code_79621000:"Diensten voor de terbeschikkingstelling van kantoorpersoneel",
   code_79622000:"Diensten voor de terbeschikkingstelling van huishoudelijke hulp",
   code_79623000:"Diensten voor de terbeschikkingstelling van arbeidskrachten voor handel en industrie",
   code_79624000:"Diensten voor de terbeschikkingstelling van verpleegkundig personeel",
   code_79625000:"Diensten voor de terbeschikkingstelling van medisch personeel",
   code_79630000:"Personeelsdiensten, met uitzondering van plaatsing en \"outplacement\"",
   code_79631000:"Personeels- en loonlijstdiensten",
   code_79632000:"Personeelsopleidingsdiensten",
   code_79633000:"Diensten voor voortgezette opleiding van personeel",
   code_79634000:"Diensten voor loopbaanbegeleiding",
   code_79635000:"Diensten van beoordelingscentrum voor aanwerving van personeel",
   code_79700000:"Opsporings- en beveiligingsdiensten",
   code_79710000:"Beveiligingsdiensten",
   code_79711000:"Diensten voor alarmbewaking",
   code_79713000:"Bewakingsdiensten",
   code_79714000:"Surveillancediensten",
   code_79714100:"Diensten voor opsporingssysteem",
   code_79714110:"Diensten voor het opsporen van voortvluchtigen",
   code_79715000:"Patrouillediensten",
   code_79716000:"Diensten voor het verstrekken van identificatiebadges",
   code_79720000:"Onderzoeksdiensten",
   code_79721000:"Diensten van detectivebureau",
   code_79722000:"Grafologische diensten",
   code_79723000:"Diensten voor afvalanalyse",
   code_79800000:"Drukkerij- en aanverwante diensten",
   code_79810000:"Drukkerijdiensten",
   code_79811000:"Digitale drukkerijdiensten",
   code_79812000:"Diensten voor het drukken van bankbiljetten",
   code_79820000:"Diensten in verband met het drukken",
   code_79821000:"Afwerken van drukwerk",
   code_79821100:"Diensten voor drukproevencorrectie",
   code_79822000:"Uitvoeren van zetwerk",
   code_79822100:"Fabriceren van drukplaten",
   code_79822200:"Fotogravurediensten",
   code_79822300:"Letterzetdiensten",
   code_79822400:"Lithografische diensten",
   code_79822500:"Maken van grafische ontwerpen",
   code_79823000:"Drukkerij- en afleveringsdiensten",
   code_79824000:"Drukkerij- en distributiediensten",
   code_79900000:"Diverse zakelijke en andere diensten",
   code_79910000:"Beheer van holdings",
   code_79920000:"Verpakkings- en aanverwante diensten",
   code_79921000:"Verpakkingsdiensten",
   code_79930000:"Gespecialiseerd ontwerpen",
   code_79931000:"Binnenhuisinrichting",
   code_79932000:"Binnenhuisarchitectuur",
   code_79933000:"Ontwerpondersteuning",
   code_79934000:"Ontwerpen van meubelen",
   code_79940000:"Dienstverlening door incassobureaus",
   code_79941000:"Diensten voor het innen van kosten",
   code_79950000:"Organiseren van tentoonstellingen, beurzen en congressen",
   code_79951000:"Organiseren van seminars",
   code_79952000:"Diensten voor het organiseren van evenementen",
   code_79952100:"Diensten voor het organiseren van culturele evenementen",
   code_79953000:"Diensten voor het organiseren van festivals",
   code_79954000:"Diensten voor het organiseren van feesten",
   code_79955000:"Diensten voor het organiseren van modeshows",
   code_79956000:"Diensten voor het organiseren van beurzen en tentoonstellingen",
   code_79957000:"Diensten voor het organiseren van veilingen",
   code_79960000:"Fotografische en aanvullende diensten",
   code_79961000:"Fotografische diensten",
   code_79961100:"Reclamefotografiediensten",
   code_79961200:"Luchtfotografiediensten",
   code_79961300:"Gespecialiseerde fotografiediensten",
   code_79961310:"Fotografie in boorgat",
   code_79961320:"Onderwaterfotografie",
   code_79961330:"Microfilmdiensten",
   code_79961340:"Röntgenfotografiediensten",
   code_79961350:"Diensten voor studiofotografie",
   code_79962000:"Foto-ontwikkelingsdiensten",
   code_79963000:"Restaureren, kopiëren en retoucheren van foto's",
   code_79970000:"Uitgeverijdiensten",
   code_79971000:"Inbinden en afwerken van boeken",
   code_79971100:"Afwerken van boeken",
   code_79971200:"Inbinden van boeken",
   code_79972000:"Diensten voor publicatie van woordenboeken",
   code_79972100:"Diensten voor publicatie van woordenboeken in streektalen",
   code_79980000:"Abonnementsdiensten",
   code_79990000:"Diverse zakelijke diensten",
   code_79991000:"Inventariscontrole",
   code_79992000:"Receptiediensten",
   code_79993000:"Gebouwen- en faciliteitenbeheer",
   code_79993100:"Faciliteitenbeheer",
   code_79994000:"Contractenbeheer",
   code_79995000:"Bibliotheekbeheer",
   code_79995100:"Archiveringsdiensten",
   code_79995200:"Catalogiseringsdiensten",
   code_79996000:"Bedrijfsorganisatiediensten",
   code_79996100:"Registermanagement",
   code_79997000:"Organiseren van zakenreizen",
   code_79998000:"Begeleidingsdiensten",
   code_79999000:"Scannings- en factureringsdiensten",
   code_79999100:"Scanningsdiensten",
   code_79999200:"Factureringsdiensten",
   code_80000000:"Diensten voor onderwijs en opleiding",
   code_80100000:"Diensten voor basisonderwijs",
   code_80110000:"Diensten voor kleuteronderwijs",
   code_80200000:"Diensten voor secundair onderwijs",
   code_80210000:"Diensten voor voortgezet technisch en beroepsonderwijs",
   code_80211000:"Diensten voor voorgezet technisch onderwijs",
   code_80212000:"Diensten voor voortgezet beroepsonderwijs",
   code_80300000:"Diensten voor hoger onderwijs",
   code_80310000:"Diensten voor onderwijs voor jongeren",
   code_80320000:"Diensten voor medisch onderwijs",
   code_80330000:"Diensten voor veiligheidsonderricht",
   code_80340000:"Diensten voor bijzonder onderwijs",
   code_80400000:"Volwasseneneducatie en andere vormen van onderwijs",
   code_80410000:"Diverse schooldiensten",
   code_80411000:"Autorijschooldiensten",
   code_80411100:"Rijexamendiensten",
   code_80411200:"Rijlessen",
   code_80412000:"Vliegschooldiensten",
   code_80413000:"Vaarschooldiensten",
   code_80414000:"Duikschooldiensten",
   code_80415000:"Skilesdiensten",
   code_80420000:"Diensten voor e-leren",
   code_80430000:"Volwasseneneducatie op universitair niveau",
   code_80490000:"Exploitatie van een onderwijscentrum",
   code_80500000:"Opleidingsdiensten",
   code_80510000:"Gespecialiseerde opleidingsdiensten",
   code_80511000:"Stafopleidingsdiensten",
   code_80512000:"Hondentrainigsdiensten",
   code_80513000:"Manegediensten",
   code_80520000:"Opleidingsfaciliteiten",
   code_80521000:"Diensten voor opleidingsprogramma",
   code_80522000:"Opleidingsseminars",
   code_80530000:"Beroepsopleidingsdiensten",
   code_80531000:"Diensten voor industriële en technische opleiding",
   code_80531100:"Diensten voor industriële opleiding",
   code_80531200:"Diensten voor technische opleiding",
   code_80532000:"Diensten inzake managementopleiding",
   code_80533000:"Computercursussen voor beginners en gevorderden",
   code_80533100:"Opleidingscursussen voor computergebruik",
   code_80533200:"Computercursussen",
   code_80540000:"Diensten voor opleiding inzake milieu",
   code_80550000:"Diensten voor opleiding inzake veiligheid",
   code_80560000:"Diensten voor opleiding inzake gezondheidszorg en EHBO",
   code_80561000:"Diensten voor opleiding inzake gezondheidszorg",
   code_80562000:"Diensten voor opleiding inzake EHBO",
   code_80570000:"Opleidingsdiensten voor persoonlijke ontwikkeling",
   code_80580000:"Geven van taalcursussen",
   code_80590000:"Diensten voor privé-onderwijs",
   code_80600000:"Opleidingsdiensten voor defensie- en veiligheidsmaterieel",
   code_80610000:"Opleiding en simulatie met veiligheidsapparatuur",
   code_80620000:"Opleiding en simulatie met vuurwapens en munitie",
   code_80630000:"Opleiding en simulatie met militaire voertuigen",
   code_80640000:"Opleiding en simulatie met oorlogsschepen",
   code_80650000:"Opleiding en simulatie met lucht- en ruimtevaartuigen en raketten",
   code_80660000:"Opleiding en simulatie met militaire elektronische systemen",
   code_85000000:"Gezondheidszorg en maatschappelijk werk",
   code_85100000:"Gezondheidsdiensten",
   code_85110000:"Ziekenhuis- en aanverwante diensten",
   code_85111000:"Ziekenhuisdiensten",
   code_85111100:"Chirurgische ziekenhuisdiensten",
   code_85111200:"Medische ziekenhuisdiensten",
   code_85111300:"Gynaecologische ziekenhuisdiensten",
   code_85111310:"Diensten voor in-vitrofertilisatie",
   code_85111320:"Verloskundige ziekenhuisdiensten",
   code_85111400:"Ziekenhuisdiensten voor revalidatie",
   code_85111500:"Psychiatrische ziekenhuisdiensten",
   code_85111600:"Orthopedische diensten",
   code_85111700:"Diensten voor zuurstoftherapie",
   code_85111800:"Pathologiediensten",
   code_85111810:"Bloedanalysediensten",
   code_85111820:"Bacteriologische analysediensten",
   code_85111900:"Dialysediensten in ziekenhuis",
   code_85112000:"Ondersteuningsdiensten voor ziekenhuis",
   code_85112100:"Dienstverlening ten aanzien van ziekenhuisbeddengoed",
   code_85112200:"Extramurale zorgdiensten",
   code_85120000:"Medische praktijk en aanverwante diensten",
   code_85121000:"Medische praktijken",
   code_85121100:"Diensten door huisartsen",
   code_85121200:"Diensten door medische specialisten",
   code_85121210:"Diensten in gynaecologie en obstetrie",
   code_85121220:"Diensten van nefrologie- of zenuwspecialist",
   code_85121230:"Diensten van hart- of longspecialist",
   code_85121231:"Diensten van cardioloog",
   code_85121232:"Diensten van longspecialist",
   code_85121240:"Diensten van KNO-arts of audioloog",
   code_85121250:"Diensten van gastro-enteroloog en geriater",
   code_85121251:"Diensten van gastro-enteroloog",
   code_85121252:"Diensten van geriater",
   code_85121270:"Diensten van psychiater of psycholoog",
   code_85121271:"Diensten van tehuis voor patiënten met psychische stoornissen",
   code_85121280:"Diensten van oog- of huidarts of orthopeed",
   code_85121281:"Diensten van oogarts",
   code_85121282:"Diensten van huidarts",
   code_85121283:"Diensten van orthopeed",
   code_85121290:"Diensten van kinderarts of uroloog",
   code_85121291:"Diensten van kinderarts",
   code_85121292:"Diensten van uroloog",
   code_85121300:"Diensten door chirurgische specialisten",
   code_85130000:"Tandartspraktijk en aanverwante diensten",
   code_85131000:"Tandartspraktijken",
   code_85131100:"Orthodontische diensten",
   code_85131110:"Orthodontisch-chirurgische diensten",
   code_85140000:"Diverse gezondheidszorgdiensten",
   code_85141000:"Diensten verleend door medisch personeel",
   code_85141100:"Diensten verleend door verloskundigen",
   code_85141200:"Diensten verleend door verpleegkundigen",
   code_85141210:"Diensten voor medische behandeling thuis",
   code_85141211:"Diensten voor dialysebehandeling thuis",
   code_85141220:"Adviesdiensten verleend door verpleegkundigen",
   code_85142000:"Paramedische diensten",
   code_85142100:"Fysiotherapiediensten",
   code_85142200:"Diensten van homeopaat",
   code_85142300:"Hygiënediensten",
   code_85142400:"Thuisbezorging van producten voor incontinentiepatiënten",
   code_85143000:"Ambulancediensten",
   code_85144000:"Diensten voor intramurale gezondheidszorginstelling",
   code_85144100:"Diensten voor verzorgingstehuis",
   code_85145000:"Diensten verleend door medische laboratoria",
   code_85146000:"Diensten verleend door bloedbanken",
   code_85146100:"Diensten verleend door spermabanken",
   code_85146200:"Diensten verleend door transplantatieorgaanbanken",
   code_85147000:"Diensten voor bedrijfsgeneeskunde",
   code_85148000:"Medische analysediensten",
   code_85149000:"Apotheekdiensten",
   code_85150000:"Diensten voor medische beeldvorming",
   code_85160000:"Diensten van opticien",
   code_85170000:"Diensten van acupuncturist en chiropractor",
   code_85171000:"Diensten van acupuncturist",
   code_85172000:"Diensten van chiropractor",
   code_85200000:"Veterinaire diensten",
   code_85210000:"Verzorging van huisdieren",
   code_85300000:"Maatschappelijke en aanverwante diensten",
   code_85310000:"Maatschappelijke diensten",
   code_85311000:"Maatschappelijke dienstverlening waarbij onderdak wordt verschaft",
   code_85311100:"Welzijnszorg voor bejaarden",
   code_85311200:"Welzijnszorg voor gehandicapten",
   code_85311300:"Welzijnszorg voor kinderen en jongeren",
   code_85312000:"Maatschappelijke dienstverlening waarbij geen onderdak wordt verschaft",
   code_85312100:"Diensten voor dagverblijven",
   code_85312110:"Kinderdagverblijven",
   code_85312120:"Dagverblijven voor gehandicapte kinderen en jongeren",
   code_85312200:"Bezorging van levensmiddelen aan huis",
   code_85312300:"Diensten voor begeleiding en adviesverlening",
   code_85312310:"Diensten voor begeleiding",
   code_85312320:"Diensten voor adviesverlening",
   code_85312330:"Gezinsplanningsdiensten",
   code_85312400:"Welzijnszorg die niet in tehuizen wordt verstrekt",
   code_85312500:"Revalidatiediensten",
   code_85312510:"Beroepsrevalidatiediensten",
   code_85320000:"Sociale diensten",
   code_85321000:"Administratieve sociale diensten",
   code_85322000:"Programma voor gemeenschapsacties",
   code_85323000:"Diensten voor maatschappelijke gezondheidszorg",
   code_90000000:"Diensten inzake afvalwater, afval, reiniging en milieu",
   code_90400000:"Diensten in verband met afvalwater",
   code_90410000:"Rioolwaterverwijderingsdiensten",
   code_90420000:"Rioolwaterbehandelingsdiensten",
   code_90430000:"Rioolwaterafvoerdiensten",
   code_90440000:"Diensten voor behandeling van beerputten",
   code_90450000:"Diensten voor behandeling van septische putten",
   code_90460000:"Diensten voor het ledigen van beerputten of septische putten",
   code_90470000:"Diensten voor rioolreiniging",
   code_90480000:"Beheer van riolering",
   code_90481000:"Exploitatie van een afvalwaterzuiveringsinstallatie",
   code_90490000:"Adviesdiensten inzake rioolinspectie en rioolwaterbehandeling",
   code_90491000:"Rioolonderzoeksdiensten",
   code_90492000:"Advies inzake afvalwaterbehandeling",
   code_90500000:"Diensten op het gebied van vuilnis en afval",
   code_90510000:"Afvalverzameling en –verwerking",
   code_90511000:"Diensten voor ophalen van vuilnis",
   code_90511100:"Ophalen van vast stadsafval",
   code_90511200:"Ophalen van huisvuil",
   code_90511300:"Diensten voor het verzamelen van straatafval",
   code_90511400:"Diensten voor het verzamelen van oud papier",
   code_90512000:"Diensten voor afvalvervoer",
   code_90513000:"Diensten voor het verwerken en storten van ongevaarlijk afval en vuilnis",
   code_90513100:"Verwijderen van huisvuil",
   code_90513200:"Verwijderen van vast stadsafval",
   code_90513300:"Diensten voor afvalverbranding",
   code_90513400:"Diensten voor het verwerken van as",
   code_90513500:"Behandeling en verwijdering van gevaarlijke vloeistoffen",
   code_90513600:"Diensten voor slibverwijdering",
   code_90513700:"Diensten voor het vervoeren van slib",
   code_90513800:"Diensten voor slibbehandeling",
   code_90513900:"Diensten voor slibstorting",
   code_90514000:"Diensten voor afvalrecycling",
   code_90520000:"Diensten op het gebied van radioactief, toxisch, medisch en gevaarlijk afval",
   code_90521000:"Diensten voor behandeling van radioactief afval",
   code_90521100:"Ophalen van radioactief afval",
   code_90521200:"Diensten voor opslag van radioactief afval",
   code_90521300:"Verwijderen van radioactief afval",
   code_90521400:"Vervoer van radioactief afval",
   code_90521410:"Vervoer van lichtradioactief afval",
   code_90521420:"Vervoer van middelradioactief afval",
   code_90521500:"Verpakken van radioactief afval",
   code_90521510:"Verpakken van lichtradioactief afval",
   code_90521520:"Verpakken van middelradioactief afval",
   code_90522000:"Diensten in verband met vervuilde grond",
   code_90522100:"Diensten voor het verwijderen van verontreinigde grond",
   code_90522200:"Verwijderen van verontreinigde grond",
   code_90522300:"Behandeling van verontreinigde grond",
   code_90522400:"Reiniging en behandeling van grond",
   code_90523000:"Verwijdering van giftig afval, met uitzondering van radioactief afval en vervuilde grond",
   code_90523100:"Diensten voor het verwijderen van wapens en munitie",
   code_90523200:"Ontmijningsdiensten",
   code_90523300:"Mijnenveegdiensten",
   code_90524000:"Diensten voor medisch afval",
   code_90524100:"Diensten voor het ophalen van ziekenhuisafval",
   code_90524200:"Diensten voor het verwerken van ziekenhuisafval",
   code_90524300:"Diensten voor het verwijderen van biologisch afval",
   code_90524400:"Ophalen, vervoeren en verwerken van ziekenhuisafval",
   code_90530000:"Exploitatie van een stortplaats",
   code_90531000:"Beheer van stortplaats",
   code_90532000:"Beheer van steenkoolstortplaats",
   code_90533000:"Beheer van afvalstortplaats",
   code_90600000:"Verwijderen, schoonmaken en ontsmetten binnen een stedelijke en/of landelijke omgeving",
   code_90610000:"Straatreinigings- en veegdiensten",
   code_90611000:"Straatreinigingsdiensten",
   code_90612000:"Straatveegdiensten",
   code_90620000:"Sneeuwruimingsdiensten",
   code_90630000:"IJsbestrijdingsdiensten",
   code_90640000:"Diensten voor het reinigen en leeghalen van kolken",
   code_90641000:"Diensten voor het reinigen van kolken",
   code_90642000:"Diensten voor het leeghalen van kolken",
   code_90650000:"Diensten voor het verwijderen van asbest",
   code_90660000:"Diensten voor het verwijderen van lood",
   code_90670000:"Desinfectering en ongediertebestrijding binnen een stedelijke en/of landelijke omgeving",
   code_90680000:"Strandreinigingsdiensten",
   code_90690000:"Diensten voor graffitiverwijdering",
   code_90700000:"Milieuzorgdiensten",
   code_90710000:"Milieubeheer",
   code_90711000:"Milieueffectstudie voor andere dan bouwwerkzaamheden",
   code_90711100:"Risicobeoordeling voor andere dan bouwwerkzaamheden",
   code_90711200:"Milieunormen voor andere activiteiten dan bouw",
   code_90711300:"Analyse van milieu-indicatoren voor andere dan bouwwerkzaamheden",
   code_90711400:"Uitvoeren van milieueffectstudie voor andere dan bouwwerkzaamheden",
   code_90711500:"Milieutoezicht voor andere activiteiten dan bouw",
   code_90712000:"Milieuplanning",
   code_90712100:"Planning van stadsmilieuontwikkeling",
   code_90712200:"Planning van bosbeschermingsstrategie",
   code_90712300:"Planning van zeebeschermingsstrategie",
   code_90712400:"Planningsdiensten voor beheers- of beschermingsstrategie van natuurlijke rijkdommen",
   code_90712500:"Bouwen of plannen van milieu-instituut",
   code_90713000:"Adviesdiensten inzake milieuproblematiek",
   code_90713100:"Adviesdiensten inzake watervoorziening en afvalwater, anders dan voor de bouw",
   code_90714000:"Uitvoeren van milieuaudits",
   code_90714100:"Milieu-informatiesystemen",
   code_90714200:"Diensten voor milieuaudits voor bedrijven",
   code_90714300:"Diensten voor sectorale milieuaudits",
   code_90714400:"Diensten voor activiteitengerichte milieuaudits",
   code_90714500:"Diensten voor milieukwaliteitscontrole",
   code_90714600:"Diensten voor milieuveiligheidstoezicht",
   code_90715000:"Diensten voor onderzoek naar vervuiling",
   code_90715100:"Diensten voor onderzoek naar vervuiling door chemische stoffen en olie",
   code_90715110:"Onderzoek van gasfabrieksterrein",
   code_90715120:"Onderzoek van terreinen voor afval van chemische fabrieken of olieraffinaderijen",
   code_90715200:"Overige diensten voor onderzoek naar vervuiling",
   code_90715210:"Onderzoek van terrein van olieopslagplaats of- terminal",
   code_90715220:"Onderzoek van industrieterrein",
   code_90715230:"Onderzoek van stortplaats voor industrieafval",
   code_90715240:"Onderzoek van terrein van houtbehandelingsfabriek",
   code_90715250:"Onderzoek van terrein van chemische reiniging",
   code_90715260:"Onderzoek van gieterijterrein",
   code_90715270:"Onderzoek van terrein van recyclinginstallatie",
   code_90715280:"Onderzoek van terrein van voedselverwerkingsfabriek",
   code_90720000:"Milieubescherming",
   code_90721000:"Milieuveiligheidsdiensten",
   code_90721100:"Diensten voor landschapsbescherming",
   code_90721200:"Diensten in verband met bescherming van de ozonlaag",
   code_90721300:"Diensten voor bescherming tegen voedsel- of diervoederbesmetting",
   code_90721400:"Diensten voor bescherming van genetische rijkdommen",
   code_90721500:"Diensten voor bescherming tegen giftige stoffen",
   code_90721600:"Stralingsbeschermingsdiensten",
   code_90721700:"Diensten voor bescherming van bedreigde planten- en diersoorten",
   code_90721800:"Diensten voor bescherming tegen natuurlijke risico's of gevaren",
   code_90722000:"Milieuherstel",
   code_90722100:"Schoonmaken van industrieterreinen",
   code_90722200:"Milieureinigingsdiensten",
   code_90722300:"Landwinningsdiensten",
   code_90730000:"Opsporing van vervuiling, toezicht en verwijdering",
   code_90731000:"Diensten in verband met luchtvervuling",
   code_90731100:"Beheer van de luchtkwaliteit",
   code_90731200:"Diensten voor beheer of controle in verband met grensoverschrijdende luchtverontreiniging",
   code_90731210:"Aankoop van CO2-emissierechten",
   code_90731300:"Diensten voor bescherming tegen luchtvervuiling",
   code_90731400:"Diensten voor toezicht op of meting van luchtvervuiling",
   code_90731500:"Diensten voor opsporing van giftige gassen",
   code_90731600:"Toezicht op methaan",
   code_90731700:"Diensten voor toezicht op kooldioxide",
   code_90731800:"Toezicht op deeltjes in de lucht",
   code_90731900:"Diensten voor toezicht op de aantasting van de ozonlaag",
   code_90732000:"Diensten in verband met bodemvervuiling",
   code_90732100:"Diensten voor bescherming tegen bodemvervuiling",
   code_90732200:"Diensten voor verwijdering van vervuilde grond",
   code_90732300:"Behandelen of verbeteren van vervuilde grond",
   code_90732400:"Adviesdiensten op het gebied van bodemvervuiling",
   code_90732500:"In kaart brengen van bodemvervuiling",
   code_90732600:"Toezicht op of meting van bodemvervuiling",
   code_90732700:"Beoordeling van vervuiling door organische meststoffen",
   code_90732800:"Beoordeling van vervuiling door bestrijdingsmiddelen",
   code_90732900:"Beoordeling van vervuiling door nitraten en fosfaten",
   code_90732910:"Beoordeling van vervuiling door nitraten",
   code_90732920:"Beoordeling van vervuiling door fosfaten",
   code_90733000:"Diensten in verband met watervervuiling",
   code_90733100:"Diensten voor toezicht of controle van oppervlaktewatervervuiling",
   code_90733200:"Opruimdiensten voor vervuild oppervlaktewater",
   code_90733300:"Diensten voor bescherming van oppervlaktewater tegen vervuiling",
   code_90733400:"Diensten voor behandeling van oppervlaktewater",
   code_90733500:"Afvoerdiensten van vervuild oppervlaktewater",
   code_90733600:"Beheer of controle in verband met grensoverschrijdende verontreiniging van oppervlaktewater",
   code_90733700:"Diensten voor toezicht op of beheersing van grondwatervervuiling",
   code_90733800:"Diensten voor afvoer van vervuild grondwater",
   code_90733900:"Diensten voor behandelen of zuiveren van vervuild grondwater",
   code_90740000:"Diensten in verband met opsporing, toezicht en verwijdering van vervuilende stoffen",
   code_90741000:"Diensten in verband met olievervuiling",
   code_90741100:"Diensten voor tozicht op olielekkage",
   code_90741200:"Diensten voor beheersing van olielekkage",
   code_90741300:"Opruimdiensten bij olielekkage",
   code_90742000:"Diensten in verband met geluidsvervuiling",
   code_90742100:"Diensten voor geluidsbeheersing",
   code_90742200:"Diensten voor bescherming tegen geluidsvervuiling",
   code_90742300:"Diensten voor toezicht op geluidsvervuiling",
   code_90742400:"Adviesdiensten in verband met geluidsvervuiling",
   code_90743000:"Diensten in verband met vervuiling door giftige stoffen",
   code_90743100:"Diensten voor toezicht op giftige stoffen",
   code_90743200:"Diensten voor opruimen van giftige stoffen",
   code_90900000:"Schoonmaak- en afvalverwijderingsdiensten",
   code_90910000:"Reinigingsdiensten",
   code_90911000:"Reiniging van woningen en gebouwen en glazenwassersdiensten",
   code_90911100:"Reinigen van accommodatie",
   code_90911200:"Reiniging van gebouwen",
   code_90911300:"Wassen van ramen",
   code_90912000:"Straalreiniging van buisvormige objecten",
   code_90913000:"Reinigingsdiensten van tanks en reservoirs",
   code_90913100:"Reiniging van tanks",
   code_90913200:"Reiniging van reservoir",
   code_90914000:"Reinigingsdiensten van parkeerplaatsen",
   code_90915000:"Diensten voor reiniging van ovens en schoorsteenvegen",
   code_90916000:"Reiniging van telefoonapparatuur",
   code_90917000:"Reiniging van transportuitrusting",
   code_90918000:"Reinigen van vuilnisbakken",
   code_90919000:"Reinigingsdiensten van kantoren, scholen en kantoorapparatuur",
   code_90919100:"Reiniging van kantooruitrusting",
   code_90919200:"Schoonmaken van kantoren",
   code_90919300:"Schoonmaken van scholen",
   code_90920000:"Afvalverwerkingsdiensten in verband met voorzieningen",
   code_90921000:"Desinfectering en ongediertebestrijding",
   code_90922000:"Ongediertebestrijding",
   code_90923000:"Diensten voor het uitroeien van ratten",
   code_90924000:"Fumigatiediensten",
   code_92000000:"Cultuur-, sport- en recreatiediensten",
   code_92100000:"Film- en videodiensten",
   code_92110000:"Productie van films en video's en aanverwante diensten",
   code_92111000:"Productie van films en video's",
   code_92111100:"Productie van instructiefilms en -video's",
   code_92111200:"Productie van films en video's voor reclame, propaganda en informatie",
   code_92111210:"Productie van reclamefilms",
   code_92111220:"Productie van reclamevideo's",
   code_92111230:"Productie van propagandafilms",
   code_92111240:"Productie van propagandavideo's",
   code_92111250:"Productie van informatiefilms",
   code_92111260:"Productie van informatievideo's",
   code_92111300:"Productie van amusementsfilms en -video's",
   code_92111310:"Productie van amusementsfilms",
   code_92111320:"Productie van amusementsvideo's",
   code_92112000:"Diensten in verband met de productie van films en video's",
   code_92120000:"Distributie van films en video's",
   code_92121000:"Distributie van video's",
   code_92122000:"Filmdistributiediensten",
   code_92130000:"Filmprojectiediensten",
   code_92140000:"Videobandprojectiediensten",
   code_92200000:"Radio- en televisiediensten",
   code_92210000:"Radiodiensten",
   code_92211000:"Radioproductiediensten",
   code_92213000:"Radiodiensten op kleine schaal",
   code_92214000:"Diensten voor radio-studio of apparatuur",
   code_92215000:"General Packet Radio Services (GMRS)-diensten",
   code_92216000:"Radiofamilieprogrammadiensten",
   code_92217000:"General Mobile Radio Services (GMRS)-diensten/familieradio",
   code_92220000:"Televisiediensten",
   code_92221000:"Televisieproductiediensten",
   code_92222000:"Diensten voor gesloten televisiecircuits",
   code_92224000:"Digitale tv-diensten",
   code_92225000:"Diensten voor interactieve televisie",
   code_92225100:"Diensten voor uitzending van films op verzoek",
   code_92226000:"Teleprogrammeringsdiensten",
   code_92230000:"Radio- en televisiekabeldiensten",
   code_92231000:"Internationale bilaterale diensten en internationale privé-geleaste lijnen",
   code_92232000:"Kabeltv-diensten",
   code_92300000:"Amusementsdiensten",
   code_92310000:"Scheppende en uitvoerende kunst- en literatuurdiensten",
   code_92311000:"Kunstwerken",
   code_92312000:"Artistieke diensten",
   code_92312100:"Diensten verleend door theaterproducenten, zanggroepen, bands en orkesten",
   code_92312110:"Diensten verleend door theaterproducenten",
   code_92312120:"Diensten verleend door zanggroepen",
   code_92312130:"Diensten verleend door bands",
   code_92312140:"Diensten verleend door orkesten",
   code_92312200:"Dienstverlening door auteurs, componisten, beeldhouwers, entertainers en andere individuele artiesten",
   code_92312210:"Diensten verleend door auteurs",
   code_92312211:"Diensten verleend door tekstbureau",
   code_92312212:"Diensten in verband met de opstelling van handleidingen",
   code_92312213:"Diensten verleend door auteurs van technische teksten",
   code_92312220:"Diensten verleend door componisten",
   code_92312230:"Diensten verleend door beeldhouwers",
   code_92312240:"Diensten verleend door entertainers",
   code_92312250:"Diensten verleend door individuele artiesten",
   code_92312251:"Diensten van disk-jockey",
   code_92320000:"Exploitatie van gebouwen voor uitvoerende kunsten",
   code_92330000:"Diensten inzake recreatiegebieden",
   code_92331000:"Diensten voor kermisattracties en pretparken",
   code_92331100:"Diensten voor kermissen",
   code_92331200:"Diensten voor pretparken",
   code_92331210:"Diensten voor kinderanimatie",
   code_92332000:"Stranddiensten",
   code_92340000:"Entertainmentdiensten dans en toneel",
   code_92341000:"Circusdiensten",
   code_92342000:"Danslesdiensten",
   code_92342100:"Ballroomdanslesdiensten",
   code_92342200:"Discodanslesdiensten",
   code_92350000:"Diensten voor gokken en wedden",
   code_92351000:"Gokdiensten",
   code_92351100:"Loterijdiensten",
   code_92351200:"Exploitatie van casino",
   code_92352000:"Weddiensten",
   code_92352100:"Exploitatie van totalisator",
   code_92352200:"Bookmakersdiensten",
   code_92360000:"Pyrotechnische diensten",
   code_92370000:"Diensten van geluidstechnicus",
   code_92400000:"Diensten door persagentschappen",
   code_92500000:"Bibliotheek-, archief-, musea- en andere culturele diensten",
   code_92510000:"Bibliotheek- en archiefdiensten",
   code_92511000:"Bibliotheekdiensten",
   code_92512000:"Archiefdiensten",
   code_92512100:"Archiefvernietigingsdiensten",
   code_92520000:"Museum- en aanverwante diensten",
   code_92521000:"Museumdiensten",
   code_92521100:"Diensten voor museumexposities",
   code_92521200:"Diensten voor conservatie van geëxposeerde stukken en voorwerpen",
   code_92521210:"Diensten voor conservatie van geëxposeerde stukken",
   code_92521220:"Diensten voor conservatie van voorwerpen",
   code_92522000:"Monumentenzorgdiensten",
   code_92522100:"Monumentenzorgdiensten voor historische plaatsen",
   code_92522200:"Monumentenzorg voor historische gebouwen",
   code_92530000:"Diensten voor botanische tuinen, dierentuinen en natuurreservaten",
   code_92531000:"Diensten voor botanische tuinen",
   code_92532000:"Diensten voor dierentuinen",
   code_92533000:"Diensten voor natuurreservaten",
   code_92534000:"Diensten voor wildreservaten",
   code_92600000:"Sportdiensten",
   code_92610000:"Exploitatie van sportaccommodatie",
   code_92620000:"Diensten in verband met sport",
   code_92621000:"Promotie van sportevenementen",
   code_92622000:"Organisatie van sportevenementen",
   code_92700000:"Cybercafé-diensten",
   code_98000000:"Overige gemeenschaps-, sociale en persoonlijke diensten",
   code_98100000:"Diensten door ledenverenigingen",
   code_98110000:"Diensten verleend door bedrijfs-, werkgevers- en gespecialiseerde organisaties",
   code_98111000:"Diensten verleend door bedrijfsorganisaties",
   code_98112000:"Diensten verleend door beroepsorganisaties",
   code_98113000:"Diensten verleend door gespecialiseerde organisaties",
   code_98113100:"Diensten voor nucleaire veiligheid",
   code_98120000:"Diensten verleend door vakbonden",
   code_98130000:"Diverse diensten door ledenverenigingen",
   code_98131000:"Religieuze diensten",
   code_98132000:"Diensten verleend door politieke organisaties",
   code_98133000:"Diensten verleend door ledenorganisaties",
   code_98133100:"Ondersteunende diensten voor verbetering van openbare voorzieningen en openbare centra",
   code_98133110:"Diensten verleend door jongerenverenigingen",
   code_98200000:"Advies op het gebied van gelijke kansen",
   code_98300000:"Diverse diensten",
   code_98310000:"Wasserij- en stomerijdiensten",
   code_98311000:"Ophalen van wasgoed",
   code_98311100:"Diensten voor beheer van wasgoed",
   code_98311200:"Exploitatie van wasserij",
   code_98312000:"Reinigen van textiel",
   code_98312100:"Impregneren van textiel",
   code_98313000:"Reinigen van bontwaren",
   code_98314000:"Kleuringsdiensten",
   code_98315000:"Persdiensten",
   code_98316000:"Diensten voor textielverven",
   code_98320000:"Kappers- en schoonheidsverzorgingdiensten",
   code_98321000:"Kappersdiensten",
   code_98321100:"Barbiersdiensten",
   code_98322000:"Schoonheidsverzorgingsdiensten",
   code_98322100:"Diensten voor cosmetische behandeling, manicure en pedicure",
   code_98322110:"Diensten voor cosmetische behandeling",
   code_98322120:"Manicurediensten",
   code_98322130:"Pedicurediensten",
   code_98322140:"Make-updiensten",
   code_98330000:"Diensten in verband met het lichamelijk welzijn",
   code_98331000:"Turks-baddiensten",
   code_98332000:"Kuuroorddiensten",
   code_98333000:"Massagediensten",
   code_98334000:"Wellness-diensten",
   code_98336000:"Diensten op het gebied van training, workout of aerobics",
   code_98340000:"Accommodatie- en kantoordiensten",
   code_98341000:"Accommodatiediensten",
   code_98341100:"Accommodatiebeheerdiensten",
   code_98341110:"Huishoudingsdiensten",
   code_98341120:"Kruiersdiensten",
   code_98341130:"Portiersdiensten",
   code_98341140:"Huismeesterdiensten",
   code_98342000:"Diensten voor werkomgeving",
   code_98350000:"Diensten voor stortplaats",
   code_98351000:"Beheersdiensten van parkeerruimte",
   code_98351100:"Diensten voor parkeerruimte",
   code_98351110:"Diensten voor het beteugelen van parkeerovertredingen",
   code_98360000:"Mariene diensten",
   code_98361000:"Aquatische mariene diensten",
   code_98362000:"Havenbeheerdiensten",
   code_98362100:"Ondersteuningsdiensten voor marinebasis",
   code_98363000:"Duikdiensten",
   code_98370000:"Begrafenis- en aanverwante diensten",
   code_98371000:"Begrafenisdiensten",
   code_98371100:"Begraafplaats- en crematiediensten",
   code_98371110:"Begraafplaatsdiensten",
   code_98371111:"Diensten voor het onderhouden van begraafplaatsen",
   code_98371120:"Crematiediensten",
   code_98371200:"Diensten verleend door begrafenisondernemingen",
   code_98380000:"Hondenkenneldiensten",
   code_98390000:"Overige diensten",
   code_98391000:"Ontmantelingsdiensten",
   code_98392000:"Verhuizingsdiensten",
   code_98393000:"Kleermakersdiensten",
   code_98394000:"Stofferingsdiensten",
   code_98395000:"Slotenmakersdiensten",
   code_98396000:"Stemmen van instrumenten",
   code_98500000:"Particuliere huishoudens met personeel",
   code_98510000:"Dienstverlening door commercieel en industrieel personeel",
   code_98511000:"Dienstverlening door commercieel personeel",
   code_98512000:"Dienstverlening door industrieel personeel",
   code_98513000:"Personeelsdiensten voor huishoudens",
   code_98513100:"Uitzendkrachtdiensten voor huishoudens",
   code_98513200:"Kantoorpersoneelsdiensten voor huishoudens",
   code_98513300:"Tijdelijk personeel voor huishoudens",
   code_98513310:"Thuishulpdiensten",
   code_98514000:"Huishoudelijke diensten",
   code_98900000:"Diensten verleend door extraterritoriale organisaties en instanties",
   code_98910000:"Diensten specifiek voor internationale organisaties",
   // code_00:"Other",
   code_AL:"Albania",
   code_AL0:"Shqipëria",
   code_AL01:"Veri",
   code_AL011:"Dibër",
   code_AL012:"Durrës",
   code_AL013:"Kukës",
   code_AL014:"Lezhë",
   code_AL015:"Shkodër",
   code_AL02:"Qender",
   code_AL021:"Elbasan",
   code_AL022:"Tiranë",
   code_AL03:"Jug",
   code_AL031:"Berat",
   code_AL032:"Fier",
   code_AL033:"Gjirokastër",
   code_AL034:"Korcë",
   code_AL035:"Vlorë",
   code_AT:"ÖSTERREICH",
   code_AT1:"OSTÖSTERREICH",
   code_AT11:"Burgenland",
   code_AT111:"Mittelburgenland",
   code_AT112:"Nordburgenland",
   code_AT113:"Südburgenland",
   code_AT12:"Niederösterreich",
   code_AT121:"Mostviertel-Eisenwurzen",
   code_AT122:"Niederösterreich-Süd",
   code_AT123:"Sankt Pölten",
   code_AT124:"Waldviertel",
   code_AT125:"Weinviertel",
   code_AT126:"Wiener Umland / Nordteil",
   code_AT127:"Wiener Umland / Südteil",
   code_AT13:"Wien",
   code_AT130:"Wien",
   code_AT2:"SÜDÖSTERREICH",
   code_AT21:"Kärnten",
   code_AT211:"Klagenfurt-Villach",
   code_AT212:"Oberkärnten",
   code_AT213:"Unterkärnten",
   code_AT22:"Steiermark",
   code_AT221:"Graz",
   code_AT222:"Liezen",
   code_AT223:"Östliche Obersteiermark",
   code_AT224:"Oststeiermark",
   code_AT225:"West- und Südsteiermark",
   code_AT226:"Westliche Obersteiermark",
   code_AT3:"WESTÖSTERREICH",
   code_AT31:"Oberösterreich",
   code_AT311:"Innviertel",
   code_AT312:"Linz-Wels",
   code_AT313:"Mühlviertel",
   code_AT314:"Steyr-Kirchdorf",
   code_AT315:"Traunviertel",
   code_AT32:"Salzburg",
   code_AT321:"Lungau",
   code_AT322:"Pinzgau-Pongau",
   code_AT323:"Salzburg und Umgebung",
   code_AT33:"Tirol",
   code_AT331:"Außerfern",
   code_AT332:"Innsbruck",
   code_AT333:"Osttirol",
   code_AT334:"Tiroler Oberland",
   code_AT335:"Tiroler Unterland",
   code_AT34:"Vorarlberg",
   code_AT341:"Bludenz-Bregenzer Wald",
   code_AT342:"Rheintal-Bodenseegebiet",
   code_ATZ:"EXTRA-REGIO NUTS 1",
   code_ATZZ:"Extra-Regio NUTS 2",
   code_ATZZZ:"Extra-Regio NUTS 3",
   code_BE:"BELGIQUE-BELGIË",
   code_BE1:"Brussels Hoofdstedelijk Gewest",
   code_BE10:"Brussels Hoofdstedelijk Gewest",
   code_BE100:"Arr. van Brussel-Hoofdstad",
   code_BE2:"Vlaams Gewest",
   code_BE21:"Prov. Antwerpen",
   code_BE211:"Arr. Antwerpen",
   code_BE212:"Arr. Mechelen",
   code_BE213:"Arr. Turnhout",
   code_BE22:"Prov. Limburg (BE)",
   code_BE221:"Arr. Hasselt",
   code_BE222:"Arr. Maaseik",
   code_BE223:"Arr. Tongeren",
   code_BE23:"Prov. Oost-Vlaanderen",
   code_BE231:"Arr. Aalst",
   code_BE232:"Arr. Dendermonde",
   code_BE233:"Arr. Eeklo",
   code_BE234:"Arr. Gent",
   code_BE235:"Arr. Oudenaarde",
   code_BE236:"Arr. Sint-Niklaas",
   code_BE24:"Prov. Vlaams-Brabant",
   code_BE241:"Arr. Halle-Vilvoorde",
   code_BE242:"Arr. Leuven",
   code_BE25:"Prov. West-Vlaanderen",
   code_BE251:"Arr. Brugge",
   code_BE252:"Arr. Diksmuide",
   code_BE253:"Arr. Ieper",
   code_BE254:"Arr. Kortrijk",
   code_BE255:"Arr. Oostende",
   code_BE256:"Arr. Roeselare",
   code_BE257:"Arr. Tielt",
   code_BE258:"Arr. Veurne",
   code_BE3:"RÉGION WALLONNE",
   code_BE31:"Prov. Brabant Wallon",
   code_BE310:"Arr. Nivelles",
   code_BE32:"Prov. Hainaut",
   code_BE321:"Arr. Ath",
   code_BE322:"Arr. Charleroi",
   code_BE323:"Arr. Mons",
   code_BE324:"Arr. Mouscron",
   code_BE325:"Arr. Soignies",
   code_BE326:"Arr. Thuin",
   code_BE327:"Arr. Tournai",
   code_BE33:"Prov. Liège",
   code_BE331:"Arr. Huy",
   code_BE332:"Arr. Liège",
   code_BE334:"Arr. Waremme",
   code_BE335:"Arr. Verviers - communes francophones",
   code_BE336:"Bezirk Verviers - Deutschsprachige Gemeinschaft",
   code_BE34:"Prov. Luxembourg (BE)",
   code_BE341:"Arr. Arlon",
   code_BE342:"Arr. Bastogne",
   code_BE343:"Arr. Marche-en-Famenne",
   code_BE344:"Arr. Neufchâteau",
   code_BE345:"Arr. Virton",
   code_BE35:"Prov. Namur",
   code_BE351:"Arr. Dinant",
   code_BE352:"Arr. Namur",
   code_BE353:"Arr. Philippeville",
   code_BEZ:"EXTRA-REGIO NUTS 1",
   code_BEZZ:"Extra-Regio NUTS 2",
   code_BEZZZ:"Extra-Regio NUTS 3",
   code_BG:"БЪЛГАРИЯ",
   code_BG3:"СЕВЕРНА И ЮГОИЗТОЧНА БЪЛГАРИЯ",
   code_BG31:"Северозападен",
   code_BG311:"Видин",
   code_BG312:"Монтана",
   code_BG313:"Враца",
   code_BG314:"Плевен",
   code_BG315:"Ловеч",
   code_BG32:"Северен централен",
   code_BG321:"Велико Търново",
   code_BG322:"Габрово",
   code_BG323:"Русе",
   code_BG324:"Разград",
   code_BG325:"Силистра",
   code_BG33:"Североизточен",
   code_BG331:"Варна",
   code_BG332:"Добрич",
   code_BG333:"Шумен",
   code_BG334:"Търговище",
   code_BG34:"Югоизточен",
   code_BG341:"Бургас",
   code_BG342:"Сливен",
   code_BG343:"Ямбол",
   code_BG344:"Стара Загора",
   code_BG4:"ЮГОЗАПАДНА И ЮЖНА ЦЕНТРАЛНА БЪЛГАРИЯ",
   code_BG41:"Югозападен",
   code_BG411:"София (столица)",
   code_BG412:"София",
   code_BG413:"Благоевград",
   code_BG414:"Перник",
   code_BG415:"Кюстендил",
   code_BG42:"Южен централен",
   code_BG421:"Пловдив",
   code_BG422:"Хасково",
   code_BG423:"Пазарджик",
   code_BG424:"Смолян",
   code_BG425:"Кърджали",
   code_BGZ:"EXTRA-REGIO NUTS 1",
   code_BGZZ:"Extra-Regio NUTS 2",
   code_BGZZZ:"Extra-Regio NUTS 3",
   code_CH:"Switzerland",
   code_CH0:"Schweiz/Suisse/Svizzera",
   code_CH01:"Région lémanique",
   code_CH011:"Vaud",
   code_CH012:"Valais / Wallis",
   code_CH013:"Genève",
   code_CH02:"Espace Mittelland",
   code_CH021:"Bern / Berne",
   code_CH022:"Fribourg / Freiburg",
   code_CH023:"Solothurn",
   code_CH024:"Neuchâtel",
   code_CH025:"Jura",
   code_CH03:"Nordwestschweiz",
   code_CH031:"Basel-Stadt",
   code_CH032:"Basel-Landschaft",
   code_CH033:"Aargau",
   code_CH04:"Zürich",
   code_CH040:"Zürich",
   code_CH05:"Ostschweiz",
   code_CH051:"Glarus",
   code_CH052:"Schaffhausen",
   code_CH053:"Appenzell Ausserrhoden",
   code_CH054:"Appenzell Innerrhoden",
   code_CH055:"St. Gallen",
   code_CH056:"Graubünden / Grigioni / Grischun",
   code_CH057:"Thurgau",
   code_CH06:"Zentralschweiz",
   code_CH061:"Luzern",
   code_CH062:"Uri",
   code_CH063:"Schwyz",
   code_CH064:"Obwalden",
   code_CH065:"Nidwalden",
   code_CH066:"Zug",
   code_CH07:"Ticino",
   code_CH070:"Ticino",
   code_CY:"ΚΥΠΡΟΣ",
   code_CY0:"ΚΥΠΡΟΣ",
   code_CY00:"Κύπρος",
   code_CY000:"Κύπρος",
   code_CYZ:"EXTRA-REGIO NUTS 1",
   code_CYZZ:"Extra-Regio NUTS 2",
   code_CYZZZ:"Extra-Regio NUTS 3",
   code_CZ:"ČESKÁ REPUBLIKA",
   code_CZ0:"ČESKÁ REPUBLIKA",
   code_CZ01:"Praha",
   code_CZ010:"Hlavní město Praha",
   code_CZ02:"Střední Čechy",
   code_CZ020:"Středočeský kraj",
   code_CZ03:"Jihozápad",
   code_CZ031:"Jihočeský kraj",
   code_CZ032:"Plzeňský kraj",
   code_CZ04:"Severozápad",
   code_CZ041:"Karlovarský kraj",
   code_CZ042:"Ústecký kraj",
   code_CZ05:"Severovýchod",
   code_CZ051:"Liberecký kraj",
   code_CZ052:"Královéhradecký kraj",
   code_CZ053:"Pardubický kraj",
   code_CZ06:"Jihovýchod",
   code_CZ063:"Kraj Vysočina",
   code_CZ064:"Jihomoravský kraj",
   code_CZ07:"Střední Morava",
   code_CZ071:"Olomoucký kraj",
   code_CZ072:"Zlínský kraj",
   code_CZ08:"Moravskoslezsko",
   code_CZ080:"Moravskoslezský kraj",
   code_CZZ:"EXTRA-REGIO NUTS 1",
   code_CZZZ:"Extra-Regio NUTS 2",
   code_CZZZZ:"Extra-Regio NUTS 3",
   code_DE:"DEUTSCHLAND",
   code_DE1:"BADEN-WÜRTTEMBERG",
   code_DE11:"Stuttgart",
   code_DE111:"Stuttgart, Stadtkreis",
   code_DE112:"Böblingen",
   code_DE113:"Esslingen",
   code_DE114:"Göppingen",
   code_DE115:"Ludwigsburg",
   code_DE116:"Rems-Murr-Kreis",
   code_DE117:"Heilbronn, Stadtkreis",
   code_DE118:"Heilbronn, Landkreis",
   code_DE119:"Hohenlohekreis",
   code_DE11A:"Schwäbisch Hall",
   code_DE11B:"Main-Tauber-Kreis",
   code_DE11C:"Heidenheim",
   code_DE11D:"Ostalbkreis",
   code_DE12:"Karlsruhe",
   code_DE121:"Baden-Baden, Stadtkreis",
   code_DE122:"Karlsruhe, Stadtkreis",
   code_DE123:"Karlsruhe, Landkreis",
   code_DE124:"Rastatt",
   code_DE125:"Heidelberg, Stadtkreis",
   code_DE126:"Mannheim, Stadtkreis",
   code_DE127:"Neckar-Odenwald-Kreis",
   code_DE128:"Rhein-Neckar-Kreis",
   code_DE129:"Pforzheim, Stadtkreis",
   code_DE12A:"Calw",
   code_DE12B:"Enzkreis",
   code_DE12C:"Freudenstadt",
   code_DE13:"Freiburg",
   code_DE131:"Freiburg im Breisgau, Stadtkreis",
   code_DE132:"Breisgau-Hochschwarzwald",
   code_DE133:"Emmendingen",
   code_DE134:"Ortenaukreis",
   code_DE135:"Rottweil",
   code_DE136:"Schwarzwald-Baar-Kreis",
   code_DE137:"Tuttlingen",
   code_DE138:"Konstanz",
   code_DE139:"Lörrach",
   code_DE13A:"Waldshut",
   code_DE14:"Tübingen",
   code_DE141:"Reutlingen",
   code_DE142:"Tübingen, Landkreis",
   code_DE143:"Zollernalbkreis",
   code_DE144:"Ulm, Stadtkreis",
   code_DE145:"Alb-Donau-Kreis",
   code_DE146:"Biberach",
   code_DE147:"Bodenseekreis",
   code_DE148:"Ravensburg",
   code_DE149:"Sigmaringen",
   code_DE2:"BAYERN",
   code_DE21:"Oberbayern",
   code_DE211:"Ingolstadt, Kreisfreie Stadt",
   code_DE212:"München, Kreisfreie Stadt",
   code_DE213:"Rosenheim, Kreisfreie Stadt",
   code_DE214:"Altötting",
   code_DE215:"Berchtesgadener Land",
   code_DE216:"Bad Tölz-Wolfratshausen",
   code_DE217:"Dachau",
   code_DE218:"Ebersberg",
   code_DE219:"Eichstätt",
   code_DE21A:"Erding",
   code_DE21B:"Freising",
   code_DE21C:"Fürstenfeldbruck",
   code_DE21D:"Garmisch-Partenkirchen",
   code_DE21E:"Landsberg am Lech",
   code_DE21F:"Miesbach",
   code_DE21G:"Mühldorf a. Inn",
   code_DE21H:"München, Landkreis",
   code_DE21I:"Neuburg-Schrobenhausen",
   code_DE21J:"Pfaffenhofen a. d. Ilm",
   code_DE21K:"Rosenheim, Landkreis",
   code_DE21L:"Starnberg",
   code_DE21M:"Traunstein",
   code_DE21N:"Weilheim-Schongau",
   code_DE22:"Niederbayern",
   code_DE221:"Landshut, Kreisfreie Stadt",
   code_DE222:"Passau, Kreisfreie Stadt",
   code_DE223:"Straubing, Kreisfreie Stadt",
   code_DE224:"Deggendorf",
   code_DE225:"Freyung-Grafenau",
   code_DE226:"Kelheim",
   code_DE227:"Landshut, Landkreis",
   code_DE228:"Passau, Landkreis",
   code_DE229:"Regen",
   code_DE22A:"Rottal-Inn",
   code_DE22B:"Straubing-Bogen",
   code_DE22C:"Dingolfing-Landau",
   code_DE23:"Oberpfalz",
   code_DE231:"Amberg, Kreisfreie Stadt",
   code_DE232:"Regensburg, Kreisfreie Stadt",
   code_DE233:"Weiden i. d. Opf, Kreisfreie Stadt",
   code_DE234:"Amberg-Sulzbach",
   code_DE235:"Cham",
   code_DE236:"Neumarkt i. d. OPf.",
   code_DE237:"Neustadt a. d. Waldnaab",
   code_DE238:"Regensburg, Landkreis",
   code_DE239:"Schwandorf",
   code_DE23A:"Tirschenreuth",
   code_DE24:"Oberfranken",
   code_DE241:"Bamberg, Kreisfreie Stadt",
   code_DE242:"Bayreuth, Kreisfreie Stadt",
   code_DE243:"Coburg, Kreisfreie Stadt",
   code_DE244:"Hof, Kreisfreie Stadt",
   code_DE245:"Bamberg, Landkreis",
   code_DE246:"Bayreuth, Landkreis",
   code_DE247:"Coburg, Landkreis",
   code_DE248:"Forchheim",
   code_DE249:"Hof, Landkreis",
   code_DE24A:"Kronach",
   code_DE24B:"Kulmbach",
   code_DE24C:"Lichtenfels",
   code_DE24D:"Wunsiedel i. Fichtelgebirge",
   code_DE25:"Mittelfranken",
   code_DE251:"Ansbach, Kreisfreie Stadt",
   code_DE252:"Erlangen, Kreisfreie Stadt",
   code_DE253:"Fürth, Kreisfreie Stadt",
   code_DE254:"Nürnberg, Kreisfreie Stadt",
   code_DE255:"Schwabach, Kreisfreie Stadt",
   code_DE256:"Ansbach, Landkreis",
   code_DE257:"Erlangen-Höchstadt",
   code_DE258:"Fürth, Landkreis",
   code_DE259:"Nürnberger Land",
   code_DE25A:"Neustadt a. d. Aisch-Bad Windsheim",
   code_DE25B:"Roth",
   code_DE25C:"Weißenburg-Gunzenhausen",
   code_DE26:"Unterfranken",
   code_DE261:"Aschaffenburg, Kreisfreie Stadt",
   code_DE262:"Schweinfurt, Kreisfreie Stadt",
   code_DE263:"Würzburg, Kreisfreie Stadt",
   code_DE264:"Aschaffenburg, Landkreis",
   code_DE265:"Bad Kissingen",
   code_DE266:"Rhön-Grabfeld",
   code_DE267:"Haßberge",
   code_DE268:"Kitzingen",
   code_DE269:"Miltenberg",
   code_DE26A:"Main-Spessart",
   code_DE26B:"Schweinfurt, Landkreis",
   code_DE26C:"Würzburg, Landkreis",
   code_DE27:"Schwaben",
   code_DE271:"Augsburg, Kreisfreie Stadt",
   code_DE272:"Kaufbeuren, Kreisfreie Stadt",
   code_DE273:"Kempten (Allgäu), Kreisfreie Stadt",
   code_DE274:"Memmingen, Kreisfreie Stadt",
   code_DE275:"Aichach-Friedberg",
   code_DE276:"Augsburg, Landkreis",
   code_DE277:"Dillingen a.d. Donau",
   code_DE278:"Günzburg",
   code_DE279:"Neu-Ulm",
   code_DE27A:"Lindau (Bodensee)",
   code_DE27B:"Ostallgäu",
   code_DE27C:"Unterallgäu",
   code_DE27D:"Donau-Ries",
   code_DE27E:"Oberallgäu",
   code_DE3:"BERLIN",
   code_DE30:"Berlin",
   code_DE300:"Berlin",
   code_DE4:"BRANDENBURG",
   code_DE40:"Brandenburg",
   code_DE401:"Brandenburg an der Havel, Kreisfreie Stadt",
   code_DE402:"Cottbus, Kreisfreie Stadt",
   code_DE403:"Frankfurt (Oder), Kreisfreie Stadt",
   code_DE404:"Potsdam, Kreisfreie Stadt",
   code_DE405:"Barnim",
   code_DE406:"Dahme-Spreewald",
   code_DE407:"Elbe-Elster",
   code_DE408:"Havelland",
   code_DE409:"Märkisch-Oderland",
   code_DE40A:"Oberhavel",
   code_DE40B:"Oberspreewald-Lausitz",
   code_DE40C:"Oder-Spree",
   code_DE40D:"Ostprignitz-Ruppin",
   code_DE40E:"Potsdam-Mittelmark",
   code_DE40F:"Prignitz",
   code_DE40G:"Spree-Neiße",
   code_DE40H:"Teltow-Fläming",
   code_DE40I:"Uckermark",
   code_DE5:"BREMEN",
   code_DE50:"Bremen",
   code_DE501:"Bremen, Kreisfreie Stadt",
   code_DE502:"Bremerhaven, Kreisfreie Stadt",
   code_DE6:"HAMBURG",
   code_DE60:"Hamburg",
   code_DE600:"Hamburg",
   code_DE7:"HESSEN",
   code_DE71:"Darmstadt",
   code_DE711:"Darmstadt, Kreisfreie Stadt",
   code_DE712:"Frankfurt am Main, Kreisfreie Stadt",
   code_DE713:"Offenbach am Main, Kreisfreie Stadt",
   code_DE714:"Wiesbaden, Kreisfreie Stadt",
   code_DE715:"Bergstraße",
   code_DE716:"Darmstadt-Dieburg",
   code_DE717:"Groß-Gerau",
   code_DE718:"Hochtaunuskreis",
   code_DE719:"Main-Kinzig-Kreis",
   code_DE71A:"Main-Taunus-Kreis",
   code_DE71B:"Odenwaldkreis",
   code_DE71C:"Offenbach, Landkreis",
   code_DE71D:"Rheingau-Taunus-Kreis",
   code_DE71E:"Wetteraukreis",
   code_DE72:"Gießen",
   code_DE721:"Gießen, Landkreis",
   code_DE722:"Lahn-Dill-Kreis",
   code_DE723:"Limburg-Weilburg",
   code_DE724:"Marburg-Biedenkopf",
   code_DE725:"Vogelsbergkreis",
   code_DE73:"Kassel",
   code_DE731:"Kassel, Kreisfreie Stadt",
   code_DE732:"Fulda",
   code_DE733:"Hersfeld-Rotenburg",
   code_DE734:"Kassel, Landkreis",
   code_DE735:"Schwalm-Eder-Kreis",
   code_DE736:"Waldeck-Frankenberg",
   code_DE737:"Werra-Meißner-Kreis",
   code_DE8:"MECKLENBURG-VORPOMMERN",
   code_DE80:"Mecklenburg-Vorpommern",
   code_DE803:"Rostock, Kreisfreie Stadt",
   code_DE804:"Schwerin, Kreisfreie Stadt",
   code_DE80J:"Mecklenburgische Seenplatte",
   code_DE80K:"Landkreis Rostock",
   code_DE80L:"Vorpommern-Rügen",
   code_DE80M:"Nordwestmecklenburg",
   code_DE80N:"Vorpommern-Greifswald",
   code_DE80O:"Ludwigslust-Parchim",
   code_DE9:"NIEDERSACHSEN",
   code_DE91:"Braunschweig",
   code_DE911:"Braunschweig, Kreisfreie Stadt",
   code_DE912:"Salzgitter, Kreisfreie Stadt",
   code_DE913:"Wolfsburg, Kreisfreie Stadt",
   code_DE914:"Gifhorn",
   code_DE916:"Goslar",
   code_DE917:"Helmstedt",
   code_DE918:"Northeim",
   code_DE91A:"Peine",
   code_DE91B:"Wolfenbüttel",
   code_DE91C:"Göttingen",
   code_DE92:"Hannover",
   code_DE922:"Diepholz",
   code_DE923:"Hameln-Pyrmont",
   code_DE925:"Hildesheim",
   code_DE926:"Holzminden",
   code_DE927:"Nienburg (Weser)",
   code_DE928:"Schaumburg",
   code_DE929:"Region Hannover",
   code_DE93:"Lüneburg",
   code_DE931:"Celle",
   code_DE932:"Cuxhaven",
   code_DE933:"Harburg",
   code_DE934:"Lüchow-Dannenberg",
   code_DE935:"Lüneburg, Landkreis",
   code_DE936:"Osterholz",
   code_DE937:"Rotenburg (Wümme)",
   code_DE938:"Heidekreis",
   code_DE939:"Stade",
   code_DE93A:"Uelzen",
   code_DE93B:"Verden",
   code_DE94:"Weser-Ems",
   code_DE941:"Delmenhorst, Kreisfreie Stadt",
   code_DE942:"Emden, Kreisfreie Stadt",
   code_DE943:"Oldenburg (Oldenburg), Kreisfreie Stadt",
   code_DE944:"Osnabrück, Kreisfreie Stadt",
   code_DE945:"Wilhelmshaven, Kreisfreie Stadt",
   code_DE946:"Ammerland",
   code_DE947:"Aurich",
   code_DE948:"Cloppenburg",
   code_DE949:"Emsland",
   code_DE94A:"Friesland (DE)",
   code_DE94B:"Grafschaft Bentheim",
   code_DE94C:"Leer",
   code_DE94D:"Oldenburg, Landkreis",
   code_DE94E:"Osnabrück, Landkreis",
   code_DE94F:"Vechta",
   code_DE94G:"Wesermarsch",
   code_DE94H:"Wittmund",
   code_DEA:"NORDRHEIN-WESTFALEN",
   code_DEA1:"Düsseldorf",
   code_DEA11:"Düsseldorf, Kreisfreie Stadt",
   code_DEA12:"Duisburg, Kreisfreie Stadt",
   code_DEA13:"Essen, Kreisfreie Stadt",
   code_DEA14:"Krefeld, Kreisfreie Stadt",
   code_DEA15:"Mönchengladbach, Kreisfreie Stadt",
   code_DEA16:"Mülheim an der Ruhr, Kreisfreie Stadt",
   code_DEA17:"Oberhausen, Kreisfreie Stadt",
   code_DEA18:"Remscheid, Kreisfreie Stadt",
   code_DEA19:"Solingen, Kreisfreie Stadt",
   code_DEA1A:"Wuppertal, Kreisfreie Stadt",
   code_DEA1B:"Kleve",
   code_DEA1C:"Mettmann",
   code_DEA1D:"Rhein-Kreis Neuss",
   code_DEA1E:"Viersen",
   code_DEA1F:"Wesel",
   code_DEA2:"Köln",
   code_DEA22:"Bonn, Kreisfreie Stadt",
   code_DEA23:"Köln, Kreisfreie Stadt",
   code_DEA24:"Leverkusen, Kreisfreie Stadt",
   code_DEA26:"Düren",
   code_DEA27:"Rhein-Erft-Kreis",
   code_DEA28:"Euskirchen",
   code_DEA29:"Heinsberg",
   code_DEA2A:"Oberbergischer Kreis",
   code_DEA2B:"Rheinisch-Bergischer Kreis",
   code_DEA2C:"Rhein-Sieg-Kreis",
   code_DEA2D:"Städteregion Aachen",
   code_DEA3:"Münster",
   code_DEA31:"Bottrop, Kreisfreie Stadt",
   code_DEA32:"Gelsenkirchen, Kreisfreie Stadt",
   code_DEA33:"Münster, Kreisfreie Stadt",
   code_DEA34:"Borken",
   code_DEA35:"Coesfeld",
   code_DEA36:"Recklinghausen",
   code_DEA37:"Steinfurt",
   code_DEA38:"Warendorf",
   code_DEA4:"Detmold",
   code_DEA41:"Bielefeld, Kreisfreie Stadt",
   code_DEA42:"Gütersloh",
   code_DEA43:"Herford",
   code_DEA44:"Höxter",
   code_DEA45:"Lippe",
   code_DEA46:"Minden-Lübbecke",
   code_DEA47:"Paderborn",
   code_DEA5:"Arnsberg",
   code_DEA51:"Bochum, Kreisfreie Stadt",
   code_DEA52:"Dortmund, Kreisfreie Stadt",
   code_DEA53:"Hagen, Kreisfreie Stadt",
   code_DEA54:"Hamm, Kreisfreie Stadt",
   code_DEA55:"Herne, Kreisfreie Stadt",
   code_DEA56:"Ennepe-Ruhr-Kreis",
   code_DEA57:"Hochsauerlandkreis",
   code_DEA58:"Märkischer Kreis",
   code_DEA59:"Olpe",
   code_DEA5A:"Siegen-Wittgenstein",
   code_DEA5B:"Soest",
   code_DEA5C:"Unna",
   code_DEB:"RHEINLAND-PFALZ",
   code_DEB1:"Koblenz",
   code_DEB11:"Koblenz, Kreisfreie Stadt",
   code_DEB12:"Ahrweiler",
   code_DEB13:"Altenkirchen (Westerwald)",
   code_DEB14:"Bad Kreuznach",
   code_DEB15:"Birkenfeld",
   code_DEB17:"Mayen-Koblenz",
   code_DEB18:"Neuwied",
   code_DEB1A:"Rhein-Lahn-Kreis",
   code_DEB1B:"Westerwaldkreis",
   code_DEB1C:"Cochem-Zell",
   code_DEB1D:"Rhein-Hunsrück-Kreis",
   code_DEB2:"Trier",
   code_DEB21:"Trier, Kreisfreie Stadt",
   code_DEB22:"Bernkastel-Wittlich",
   code_DEB23:"Eifelkreis Bitburg-Prüm",
   code_DEB24:"Vulkaneifel",
   code_DEB25:"Trier-Saarburg",
   code_DEB3:"Rheinhessen-Pfalz",
   code_DEB31:"Frankenthal (Pfalz), Kreisfreie Stadt",
   code_DEB32:"Kaiserslautern, Kreisfreie Stadt",
   code_DEB33:"Landau in der Pfalz, Kreisfreie Stadt",
   code_DEB34:"Ludwigshafen am Rhein, Kreisfreie Stadt",
   code_DEB35:"Mainz, Kreisfreie Stadt",
   code_DEB36:"Neustadt an der Weinstraße, Kreisfreie Stadt",
   code_DEB37:"Pirmasens, Kreisfreie Stadt",
   code_DEB38:"Speyer, Kreisfreie Stadt",
   code_DEB39:"Worms, Kreisfreie Stadt",
   code_DEB3A:"Zweibrücken, Kreisfreie Stadt",
   code_DEB3B:"Alzey-Worms",
   code_DEB3C:"Bad Dürkheim",
   code_DEB3D:"Donnersbergkreis",
   code_DEB3E:"Germersheim",
   code_DEB3F:"Kaiserslautern, Landkreis",
   code_DEB3G:"Kusel",
   code_DEB3H:"Südliche Weinstraße",
   code_DEB3I:"Rhein-Pfalz-Kreis",
   code_DEB3J:"Mainz-Bingen",
   code_DEB3K:"Südwestpfalz",
   code_DEC:"SAARLAND",
   code_DEC0:"Saarland",
   code_DEC01:"Regionalverband Saarbrücken",
   code_DEC02:"Merzig-Wadern",
   code_DEC03:"Neunkirchen",
   code_DEC04:"Saarlouis",
   code_DEC05:"Saarpfalz-Kreis",
   code_DEC06:"St. Wendel",
   code_DED:"SACHSEN",
   code_DED2:"Dresden",
   code_DED21:"Dresden, Kreisfreie Stadt",
   code_DED2C:"Bautzen",
   code_DED2D:"Görlitz",
   code_DED2E:"Meißen",
   code_DED2F:"Sächsische Schweiz-Osterzgebirge",
   code_DED4:"Chemnitz",
   code_DED41:"Chemnitz, Kreisfreie Stadt",
   code_DED42:"Erzgebirgskreis",
   code_DED43:"Mittelsachsen",
   code_DED44:"Vogtlandkreis",
   code_DED45:"Zwickau",
   code_DED5:"Leipzig",
   code_DED51:"Leipzig, Kreisfreie Stadt",
   code_DED52:"Leipzig",
   code_DED53:"Nordsachsen",
   code_DEE:"SACHSEN-ANHALT",
   code_DEE0:"Sachsen-Anhalt",
   code_DEE01:"Dessau-Roßlau, Kreisfreie Stadt",
   code_DEE02:"Halle (Saale), Kreisfreie Stadt",
   code_DEE03:"Magdeburg, Kreisfreie Stadt",
   code_DEE04:"Altmarkkreis Salzwedel",
   code_DEE05:"Anhalt-Bitterfeld",
   code_DEE06:"Jerichower Land",
   code_DEE07:"Börde",
   code_DEE08:"Burgenlandkreis",
   code_DEE09:"Harz",
   code_DEE0A:"Mansfeld-Südharz",
   code_DEE0B:"Saalekreis",
   code_DEE0C:"Salzlandkreis",
   code_DEE0D:"Stendal",
   code_DEE0E:"Wittenberg",
   code_DEF:"SCHLESWIG-HOLSTEIN",
   code_DEF0:"Schleswig-Holstein",
   code_DEF01:"Flensburg, Kreisfreie Stadt",
   code_DEF02:"Kiel, Kreisfreie Stadt",
   code_DEF03:"Lübeck, Kreisfreie Stadt",
   code_DEF04:"Neumünster, Kreisfreie Stadt",
   code_DEF05:"Dithmarschen",
   code_DEF06:"Herzogtum Lauenburg",
   code_DEF07:"Nordfriesland",
   code_DEF08:"Ostholstein",
   code_DEF09:"Pinneberg",
   code_DEF0A:"Plön",
   code_DEF0B:"Rendsburg-Eckernförde",
   code_DEF0C:"Schleswig-Flensburg",
   code_DEF0D:"Segeberg",
   code_DEF0E:"Steinburg",
   code_DEF0F:"Stormarn",
   code_DEG:"THÜRINGEN",
   code_DEG0:"Thüringen",
   code_DEG01:"Erfurt, Kreisfreie Stadt",
   code_DEG02:"Gera, Kreisfreie Stadt",
   code_DEG03:"Jena, Kreisfreie Stadt",
   code_DEG04:"Suhl, Kreisfreie Stadt",
   code_DEG05:"Weimar, Kreisfreie Stadt",
   code_DEG06:"Eichsfeld",
   code_DEG07:"Nordhausen",
   code_DEG09:"Unstrut-Hainich-Kreis",
   code_DEG0A:"Kyffhäuserkreis",
   code_DEG0B:"Schmalkalden-Meiningen",
   code_DEG0C:"Gotha",
   code_DEG0D:"Sömmerda",
   code_DEG0E:"Hildburghausen",
   code_DEG0F:"Ilm-Kreis",
   code_DEG0G:"Weimarer Land",
   code_DEG0H:"Sonneberg",
   code_DEG0I:"Saalfeld-Rudolstadt",
   code_DEG0J:"Saale-Holzland-Kreis",
   code_DEG0K:"Saale-Orla-Kreis",
   code_DEG0L:"Greiz",
   code_DEG0M:"Altenburger Land",
   code_DEG0N:"Eisenach, Kreisfreie Stadt",
   code_DEG0P:"Wartburgkreis",
   code_DEZ:"EXTRA-REGIO NUTS 1",
   code_DEZZ:"Extra-Regio NUTS 2",
   code_DEZZZ:"Extra-Regio NUTS 3",
   code_DK:"DANMARK",
   code_DK0:"DANMARK",
   code_DK01:"Hovedstaden",
   code_DK011:"Byen København",
   code_DK012:"Københavns omegn",
   code_DK013:"Nordsjælland",
   code_DK014:"Bornholm",
   code_DK02:"Sjælland",
   code_DK021:"Østsjælland",
   code_DK022:"Vest- og Sydsjælland",
   code_DK03:"Syddanmark",
   code_DK031:"Fyn",
   code_DK032:"Sydjylland",
   code_DK04:"Midtjylland",
   code_DK041:"Vestjylland",
   code_DK042:"Østjylland",
   code_DK05:"Nordjylland",
   code_DK050:"Nordjylland",
   code_DKZ:"EXTRA-REGIO NUTS 1",
   code_DKZZ:"Extra-Regio NUTS 2",
   code_DKZZZ:"Extra-Regio NUTS 3",
   code_EE:"EESTI",
   code_EE0:"EESTI",
   code_EE00:"Eesti",
   code_EE001:"Põhja-Eesti",
   code_EE004:"Lääne-Eesti",
   code_EE006:"Kesk-Eesti",
   code_EE007:"Kirde-Eesti",
   code_EE008:"Lõuna-Eesti",
   code_EEZ:"EXTRA-REGIO NUTS 1",
   code_EEZZ:"Extra-Regio NUTS 2",
   code_EEZZZ:"Extra-Regio NUTS 3",
   code_EL:"ΕΛΛΑΔΑ",
   code_EL3:"ATTIKΗ",
   code_EL30:"Aττική",
   code_EL301:"Βόρειος Τομέας Αθηνών",
   code_EL302:"Δυτικός Τομέας Αθηνών",
   code_EL303:"Κεντρικός Τομέας Αθηνών",
   code_EL304:"Νότιος Τομέας Αθηνών",
   code_EL305:"Ανατολική Αττική",
   code_EL306:"Δυτική Αττική",
   code_EL307:"Πειραιάς, Νήσοι",
   code_EL4:"NΗΣΙΑ ΑΙΓΑΙΟΥ, KΡΗΤΗ",
   code_EL41:"Βόρειο Αιγαίο",
   code_EL411:"Λέσβος, Λήμνος",
   code_EL412:"Ικαρία, Σάμος",
   code_EL413:"Χίος",
   code_EL42:"Νότιο Αιγαίο",
   code_EL421:"Κάλυμνος, Κάρπαθος, Κως, Ρόδος",
   code_EL422:"Άνδρος, Θήρα, Κέα, Μήλος, Μύκονος, Νάξος, Πάρος, Σύρος, Τήνος",
   code_EL43:"Κρήτη",
   code_EL431:"Ηράκλειο",
   code_EL432:"Λασίθι",
   code_EL433:"Ρέθυμνο",
   code_EL434:"Χανιά",
   code_EL5:"ΒΟΡΕΙΑ ΕΛΛΑΔΑ",
   code_EL51:"Aνατολική Μακεδονία, Θράκη",
   code_EL511:"Έβρος",
   code_EL512:"Ξάνθη",
   code_EL513:"Ροδόπη",
   code_EL514:"Δράμα",
   code_EL515:"Θάσος, Καβάλα",
   code_EL52:"Κεντρική Μακεδονία",
   code_EL521:"Ημαθία",
   code_EL522:"Θεσσαλονίκη",
   code_EL523:"Κιλκίς",
   code_EL524:"Πέλλα",
   code_EL525:"Πιερία",
   code_EL526:"Σέρρες",
   code_EL527:"Χαλκιδική",
   code_EL53:"Δυτική Μακεδονία",
   code_EL531:"Γρεβενά, Κοζάνη",
   code_EL532:"Καστοριά",
   code_EL533:"Φλώρινα",
   code_EL54:"Ήπειρος",
   code_EL541:"Άρτα, Πρέβεζα",
   code_EL542:"Θεσπρωτία",
   code_EL543:"Ιωάννινα",
   code_EL6:"ΚΕΝΤΡΙΚΗ ΕΛΛΑΔΑ",
   code_EL61:"Θεσσαλία",
   code_EL611:"Καρδίτσα, Τρίκαλα",
   code_EL612:"Λάρισα",
   code_EL613:"Μαγνησία, Σποράδες",
   code_EL62:"Ιόνια Νησιά",
   code_EL621:"Ζάκυνθος",
   code_EL622:"Κέρκυρα",
   code_EL623:"Ιθάκη, Κεφαλληνία",
   code_EL624:"Λευκάδα",
   code_EL63:"Δυτική Ελλάδα",
   code_EL631:"Αιτωλοακαρνανία",
   code_EL632:"Αχαΐα",
   code_EL633:"Ηλεία",
   code_EL64:"Στερεά Ελλάδα",
   code_EL641:"Βοιωτία",
   code_EL642:"Εύβοια",
   code_EL643:"Ευρυτανία",
   code_EL644:"Φθιώτιδα",
   code_EL645:"Φωκίδα",
   code_EL65:"Πελοπόννησος",
   code_EL651:"Αργολίδα, Αρκαδία",
   code_EL652:"Κορινθία",
   code_EL653:"Λακωνία, Μεσσηνία",
   code_ELZ:"EXTRA-REGIO NUTS 1",
   code_ELZZ:"Extra-Regio NUTS 2",
   code_ELZZZ:"Extra-Regio NUTS 3",
   code_ES:"ESPAÑA",
   code_ES1:"NOROESTE",
   code_ES11:"Galicia",
   code_ES111:"A Coruña",
   code_ES112:"Lugo",
   code_ES113:"Ourense",
   code_ES114:"Pontevedra",
   code_ES12:"Principado de Asturias",
   code_ES120:"Asturias",
   code_ES13:"Cantabria",
   code_ES130:"Cantabria",
   code_ES2:"NORESTE",
   code_ES21:"País Vasco",
   code_ES211:"Araba / Álava",
   code_ES212:"Gipuzkoa",
   code_ES213:"Bizkaia",
   code_ES22:"Comunidad Foral de Navarra",
   code_ES220:"Navarra",
   code_ES23:"La Rioja",
   code_ES230:"La Rioja",
   code_ES24:"Aragón",
   code_ES241:"Huesca",
   code_ES242:"Teruel",
   code_ES243:"Zaragoza",
   code_ES3:"COMUNIDAD DE MADRID",
   code_ES30:"Comunidad de Madrid",
   code_ES300:"Madrid",
   code_ES4:"CENTRO (ES)",
   code_ES41:"Castilla y León",
   code_ES411:"Ávila",
   code_ES412:"Burgos",
   code_ES413:"León",
   code_ES414:"Palencia",
   code_ES415:"Salamanca",
   code_ES416:"Segovia",
   code_ES417:"Soria",
   code_ES418:"Valladolid",
   code_ES419:"Zamora",
   code_ES42:"Castilla-La Mancha",
   code_ES421:"Albacete",
   code_ES422:"Ciudad Real",
   code_ES423:"Cuenca",
   code_ES424:"Guadalajara",
   code_ES425:"Toledo",
   code_ES43:"Extremadura",
   code_ES431:"Badajoz",
   code_ES432:"Cáceres",
   code_ES5:"ESTE",
   code_ES51:"Cataluña",
   code_ES511:"Barcelona",
   code_ES512:"Girona",
   code_ES513:"Lleida",
   code_ES514:"Tarragona",
   code_ES52:"Comunidad Valenciana",
   code_ES521:"Alicante/Alacant",
   code_ES522:"Castellón/Castelló",
   code_ES523:"Valencia/València",
   code_ES53:"Illes Balears",
   code_ES531:"Eivissa y Formentera",
   code_ES532:"Mallorca",
   code_ES533:"Menorca",
   code_ES6:"SUR",
   code_ES61:"Andalucía",
   code_ES611:"Almería",
   code_ES612:"Cádiz",
   code_ES613:"Córdoba",
   code_ES614:"Granada",
   code_ES615:"Huelva",
   code_ES616:"Jaén",
   code_ES617:"Málaga",
   code_ES618:"Sevilla",
   code_ES62:"Región de Murcia",
   code_ES620:"Murcia",
   code_ES63:"Ciudad Autónoma de Ceuta",
   code_ES630:"Ceuta",
   code_ES64:"Ciudad Autónoma de Melilla",
   code_ES640:"Melilla",
   code_ES7:"CANARIAS",
   code_ES70:"Canarias",
   code_ES703:"El Hierro",
   code_ES704:"Fuerteventura",
   code_ES705:"Gran Canaria",
   code_ES706:"La Gomera",
   code_ES707:"La Palma",
   code_ES708:"Lanzarote",
   code_ES709:"Tenerife",
   code_ESZ:"EXTRA-REGIO NUTS 1",
   code_ESZZ:"Extra-Regio NUTS 2",
   code_ESZZZ:"Extra-Regio NUTS 3",
   code_FI:"SUOMI / FINLAND",
   code_FI1:"MANNER-SUOMI",
   code_FI19:"Länsi-Suomi",
   code_FI193:"Keski-Suomi",
   code_FI194:"Etelä-Pohjanmaa",
   code_FI195:"Pohjanmaa",
   code_FI196:"Satakunta",
   code_FI197:"Pirkanmaa",
   code_FI1B:"Helsinki-Uusimaa",
   code_FI1B1:"Helsinki-Uusimaa",
   code_FI1C:"Etelä-Suomi",
   code_FI1C1:"Varsinais-Suomi",
   code_FI1C2:"Kanta-Häme",
   code_FI1C3:"Päijät-Häme",
   code_FI1C4:"Kymenlaakso",
   code_FI1C5:"Etelä-Karjala",
   code_FI1D:"Pohjois- ja Itä-Suomi",
   code_FI1D1:"Etelä-Savo",
   code_FI1D2:"Pohjois-Savo",
   code_FI1D3:"Pohjois-Karjala",
   code_FI1D5:"Keski-Pohjanmaa",
   code_FI1D7:"Lappi",
   code_FI1D8:"Kainuu",
   code_FI1D9:"Pohjois-Pohjanmaa",
   code_FI2:"ÅLAND",
   code_FI20:"Åland",
   code_FI200:"Åland",
   code_FIZ:"EXTRA-REGIO NUTS 1",
   code_FIZZ:"Extra-Regio NUTS 2",
   code_FIZZZ:"Extra-Regio NUTS 3",
   code_FR:"FRANCE",
   code_FR1:"ILE-DE-FRANCE",
   code_FR10:"Ile-de-France",
   code_FR101:"Paris",
   code_FR102:"Seine-et-Marne",
   code_FR103:"Yvelines",
   code_FR104:"Essonne",
   code_FR105:"Hauts-de-Seine",
   code_FR106:"Seine-Saint-Denis",
   code_FR107:"Val-de-Marne",
   code_FR108:"Val-d’Oise",
   code_FRB:"CENTRE – VAL DE LOIRE",
   code_FRB0:"Centre – Val de Loire",
   code_FRB01:"Cher",
   code_FRB02:"Eure-et-Loir",
   code_FRB03:"Indre",
   code_FRB04:"Indre-et-Loire",
   code_FRB05:"Loir-et-Cher",
   code_FRB06:"Loiret",
   code_FRC:"BOURGOGNE-FRANCHE-COMTÉ",
   code_FRC1:"Bourgogne",
   code_FRC11:"Côte-d’Or",
   code_FRC12:"Nièvre",
   code_FRC13:"Saône-et-Loire",
   code_FRC14:"Yonne",
   code_FRC2:"Franche-Comté",
   code_FRC21:"Doubs",
   code_FRC22:"Jura",
   code_FRC23:"Haute-Saône",
   code_FRC24:"Territoire de Belfort",
   code_FRD:"NORMANDIE",
   code_FRD1:"Basse-Normandie",
   code_FRD11:"Calvados",
   code_FRD12:"Manche",
   code_FRD13:"Orne",
   code_FRD2:"Haute-Normandie",
   code_FRD21:"Eure",
   code_FRD22:"Seine-Maritime",
   code_FRE:"NORD-PAS DE CALAIS-PICARDIE",
   code_FRE1:"Nord-Pas de Calais",
   code_FRE11:"Nord",
   code_FRE12:"Pas-de-Calais",
   code_FRE2:"Picardie",
   code_FRE21:"Aisne",
   code_FRE22:"Oise",
   code_FRE23:"Somme",
   code_FRF:"ALSACE-CHAMPAGNE-ARDENNE-LORRAINE",
   code_FRF1:"Alsace",
   code_FRF11:"Bas-Rhin",
   code_FRF12:"Haut-Rhin",
   code_FRF2:"Champagne-Ardenne",
   code_FRF21:"Ardennes",
   code_FRF22:"Aube",
   code_FRF23:"Marne",
   code_FRF24:"Haute-Marne",
   code_FRF3:"Lorraine",
   code_FRF31:"Meurthe-et-Moselle",
   code_FRF32:"Meuse",
   code_FRF33:"Moselle",
   code_FRF34:"Vosges",
   code_FRG:"PAYS DE LA LOIRE",
   code_FRG0:"Pays de la Loire",
   code_FRG01:"Loire-Atlantique",
   code_FRG02:"Maine-et-Loire",
   code_FRG03:"Mayenne",
   code_FRG04:"Sarthe",
   code_FRG05:"Vendée",
   code_FRH:"BRETAGNE",
   code_FRH0:"Bretagne",
   code_FRH01:"Côtes-d’Armor",
   code_FRH02:"Finistère",
   code_FRH03:"Ille-et-Vilaine",
   code_FRH04:"Morbihan",
   code_FRI:"AQUITAINE-LIMOUSIN-POITOU-CHARENTES",
   code_FRI1:"Aquitaine",
   code_FRI11:"Dordogne",
   code_FRI12:"Gironde",
   code_FRI13:"Landes",
   code_FRI14:"Lot-et-Garonne",
   code_FRI15:"Pyrénées-Atlantiques",
   code_FRI2:"Limousin",
   code_FRI21:"Corrèze",
   code_FRI22:"Creuse",
   code_FRI23:"Haute-Vienne",
   code_FRI3:"Poitou - Charentes",
   code_FRI31:"Charente",
   code_FRI32:"Charente-Maritime",
   code_FRI33:"Deux-Sèvres",
   code_FRI34:"Vienne",
   code_FRJ:"LANGUEDOC-ROUSSILLON-MIDI-PYRÉNÉES",
   code_FRJ1:"Languedoc-Roussillon",
   code_FRJ11:"Aude",
   code_FRJ12:"Gard",
   code_FRJ13:"Hérault",
   code_FRJ14:"Lozère",
   code_FRJ15:"Pyrénées-Orientales",
   code_FRJ2:"Midi-Pyrénées",
   code_FRJ21:"Ariège",
   code_FRJ22:"Aveyron",
   code_FRJ23:"Haute-Garonne",
   code_FRJ24:"Gers",
   code_FRJ25:"Lot",
   code_FRJ26:"Hautes-Pyrénées",
   code_FRJ27:"Tarn",
   code_FRJ28:"Tarn-et-Garonne",
   code_FRK:"AUVERGNE-RHÔNE-ALPES",
   code_FRK1:"Auvergne",
   code_FRK11:"Allier",
   code_FRK12:"Cantal",
   code_FRK13:"Haute-Loire",
   code_FRK14:"Puy-de-Dôme",
   code_FRK2:"Rhône-Alpes",
   code_FRK21:"Ain",
   code_FRK22:"Ardèche",
   code_FRK23:"Drôme",
   code_FRK24:"Isère",
   code_FRK25:"Loire",
   code_FRK26:"Rhône",
   code_FRK27:"Savoie",
   code_FRK28:"Haute-Savoie",
   code_FRL:"PROVENCE-ALPES-CÔTE D’AZUR",
   code_FRL0:"Provence-Alpes-Côte d’Azur",
   code_FRL01:"Alpes-de-Haute-Provence",
   code_FRL02:"Hautes-Alpes",
   code_FRL03:"Alpes-Maritimes",
   code_FRL04:"Bouches-du-Rhône",
   code_FRL05:"Var",
   code_FRL06:"Vaucluse",
   code_FRM:"CORSE",
   code_FRM0:"Corse",
   code_FRM01:"Corse-du-Sud",
   code_FRM02:"Haute-Corse",
   code_FRY:"RUP FR – RÉGIONS ULTRAPÉRIPHÉRIQUES FRANÇAISES",
   code_FRY1:"Guadeloupe",
   code_FRY10:"Guadeloupe",
   code_FRY2:"Martinique",
   code_FRY20:"Martinique",
   code_FRY3:"Guyane",
   code_FRY30:"Guyane",
   code_FRY4:"La Réunion",
   code_FRY40:"La Réunion",
   code_FRY5:"Mayotte",
   code_FRY50:"Mayotte",
   code_FRZ:"EXTRA-REGIO NUTS 1",
   code_FRZZ:"Extra-Regio NUTS 2",
   code_FRZZZ:"Extra-Regio NUTS 3",
   code_HR:"HRVATSKA",
   code_HR0:"HRVATSKA",
   code_HR03:"Jadranska Hrvatska",
   code_HR031:"Primorsko-goranska županija",
   code_HR032:"Ličko-senjska županija",
   code_HR033:"Zadarska županija",
   code_HR034:"Šibensko-kninska županija",
   code_HR035:"Splitsko-dalmatinska županija",
   code_HR036:"Istarska županija",
   code_HR037:"Dubrovačko-neretvanska županija",
   code_HR04:"Kontinentalna Hrvatska",
   code_HR041:"Grad Zagreb",
   code_HR042:"Zagrebačka županija",
   code_HR043:"Krapinsko-zagorska županija",
   code_HR044:"Varaždinska županija",
   code_HR045:"Koprivničko-križevačka županija",
   code_HR046:"Međimurska županija",
   code_HR047:"Bjelovarsko-bilogorska županija",
   code_HR048:"Virovitičko-podravska županija",
   code_HR049:"Požeško-slavonska županija",
   code_HR04A:"Brodsko-posavska županija",
   code_HR04B:"Osječko-baranjska županija",
   code_HR04C:"Vukovarsko-srijemska županija",
   code_HR04D:"Karlovačka županija",
   code_HR04E:"Sisačko-moslavačka županija",
   code_HRZ:"EXTRA-REGIO NUTS 1",
   code_HRZZ:"Extra-Regio NUTS 2",
   code_HRZZZ:"Extra-Regio NUTS 3",
   code_HU:"MAGYARORSZÁG",
   code_HU1:"KÖZÉP-MAGYARORSZÁG",
   code_HU11:"Budapest",
   code_HU110:"Budapest",
   code_HU12:"Pest",
   code_HU120:"Pest",
   code_HU2:"DUNÁNTÚL",
   code_HU21:"Közép-Dunántúl",
   code_HU211:"Fejér",
   code_HU212:"Komárom-Esztergom",
   code_HU213:"Veszprém",
   code_HU22:"Nyugat-Dunántúl",
   code_HU221:"Győr-Moson-Sopron",
   code_HU222:"Vas",
   code_HU223:"Zala",
   code_HU23:"Dél-Dunántúl",
   code_HU231:"Baranya",
   code_HU232:"Somogy",
   code_HU233:"Tolna",
   code_HU3:"ALFÖLD ÉS ÉSZAK",
   code_HU31:"Észak-Magyarország",
   code_HU311:"Borsod-Abaúj-Zemplén",
   code_HU312:"Heves",
   code_HU313:"Nógrád",
   code_HU32:"Észak-Alföld",
   code_HU321:"Hajdú-Bihar",
   code_HU322:"Jász-Nagykun-Szolnok",
   code_HU323:"Szabolcs-Szatmár-Bereg",
   code_HU33:"Dél-Alföld",
   code_HU331:"Bács-Kiskun",
   code_HU332:"Békés",
   code_HU333:"Csongrád",
   code_HUZ:"EXTRA-REGIO NUTS 1",
   code_HUZZ:"Extra-Regio NUTS 2",
   code_HUZZZ:"Extra-Regio NUTS 3",
   code_IE:"IRELAND",
   code_IE0:"IRELAND",
   code_IE04:"Northern and Western",
   code_IE041:"Border",
   code_IE042:"West",
   code_IE05:"Southern",
   code_IE051:"Mid-West",
   code_IE052:"South-East",
   code_IE053:"South-West",
   code_IE06:"Eastern and Midland",
   code_IE061:"Dublin",
   code_IE062:"Mid-East",
   code_IE063:"Midland",
   code_IEZ:"EXTRA-REGIO NUTS 1",
   code_IEZZ:"Extra-Regio NUTS 2",
   code_IEZZZ:"Extra-Regio NUTS 3",
   code_IS:"Iceland",
   code_IS0:"Ísland",
   code_IS00:"Ísland",
   code_IS001:"Höfuðborgarsvæði",
   code_IS002:"Landsbyggð",
   code_IT:"ITALIA",
   code_ITC:"NORD-OVEST",
   code_ITC1:"Piemonte",
   code_ITC11:"Torino",
   code_ITC12:"Vercelli",
   code_ITC13:"Biella",
   code_ITC14:"Verbano-Cusio-Ossola",
   code_ITC15:"Novara",
   code_ITC16:"Cuneo",
   code_ITC17:"Asti",
   code_ITC18:"Alessandria",
   code_ITC2:"Valle d’Aosta / Vallée d’Aoste",
   code_ITC20:"Valle d’Aosta / Vallée d’Aoste",
   code_ITC3:"Liguria",
   code_ITC31:"Imperia",
   code_ITC32:"Savona",
   code_ITC33:"Genova",
   code_ITC34:"La Spezia",
   code_ITC4:"Lombardia",
   code_ITC41:"Varese",
   code_ITC42:"Como",
   code_ITC43:"Lecco",
   code_ITC44:"Sondrio",
   code_ITC46:"Bergamo",
   code_ITC47:"Brescia",
   code_ITC48:"Pavia",
   code_ITC49:"Lodi",
   code_ITC4A:"Cremona",
   code_ITC4B:"Mantova",
   code_ITC4C:"Milano",
   code_ITC4D:"Monza e della Brianza",
   code_ITF:"SUD",
   code_ITF1:"Abruzzo",
   code_ITF11:"L’Aquila",
   code_ITF12:"Teramo",
   code_ITF13:"Pescara",
   code_ITF14:"Chieti",
   code_ITF2:"Molise",
   code_ITF21:"Isernia",
   code_ITF22:"Campobasso",
   code_ITF3:"Campania",
   code_ITF31:"Caserta",
   code_ITF32:"Benevento",
   code_ITF33:"Napoli",
   code_ITF34:"Avellino",
   code_ITF35:"Salerno",
   code_ITF4:"Puglia",
   code_ITF43:"Taranto",
   code_ITF44:"Brindisi",
   code_ITF45:"Lecce",
   code_ITF46:"Foggia",
   code_ITF47:"Bari",
   code_ITF48:"Barletta-Andria-Trani",
   code_ITF5:"Basilicata",
   code_ITF51:"Potenza",
   code_ITF52:"Matera",
   code_ITF6:"Calabria",
   code_ITF61:"Cosenza",
   code_ITF62:"Crotone",
   code_ITF63:"Catanzaro",
   code_ITF64:"Vibo Valentia",
   code_ITF65:"Reggio di Calabria",
   code_ITG:"ISOLE",
   code_ITG1:"Sicilia",
   code_ITG11:"Trapani",
   code_ITG12:"Palermo",
   code_ITG13:"Messina",
   code_ITG14:"Agrigento",
   code_ITG15:"Caltanissetta",
   code_ITG16:"Enna",
   code_ITG17:"Catania",
   code_ITG18:"Ragusa",
   code_ITG19:"Siracusa",
   code_ITG2:"Sardegna",
   code_ITG25:"Sassari",
   code_ITG26:"Nuoro",
   code_ITG27:"Cagliari",
   code_ITG28:"Oristano",
   code_ITG29:"Olbia-Tempio",
   code_ITG2A:"Ogliastra",
   code_ITG2B:"Medio Campidano",
   code_ITG2C:"Carbonia-Iglesias",
   code_ITH:"NORD-EST",
   code_ITH1:"Provincia Autonoma di Bolzano / Bozen",
   code_ITH10:"Bolzano-Bozen",
   code_ITH2:"Provincia Autonoma di Trento",
   code_ITH20:"Trento",
   code_ITH3:"Veneto",
   code_ITH31:"Verona",
   code_ITH32:"Vicenza",
   code_ITH33:"Belluno",
   code_ITH34:"Treviso",
   code_ITH35:"Venezia",
   code_ITH36:"Padova",
   code_ITH37:"Rovigo",
   code_ITH4:"Friuli-Venezia Giulia",
   code_ITH41:"Pordenone",
   code_ITH42:"Udine",
   code_ITH43:"Gorizia",
   code_ITH44:"Trieste",
   code_ITH5:"Emilia-Romagna",
   code_ITH51:"Piacenza",
   code_ITH52:"Parma",
   code_ITH53:"Reggio nell’Emilia",
   code_ITH54:"Modena",
   code_ITH55:"Bologna",
   code_ITH56:"Ferrara",
   code_ITH57:"Ravenna",
   code_ITH58:"Forlì-Cesena",
   code_ITH59:"Rimini",
   code_ITI:"CENTRO (IT)",
   code_ITI1:"Toscana",
   code_ITI11:"Massa-Carrara",
   code_ITI12:"Lucca",
   code_ITI13:"Pistoia",
   code_ITI14:"Firenze",
   code_ITI15:"Prato",
   code_ITI16:"Livorno",
   code_ITI17:"Pisa",
   code_ITI18:"Arezzo",
   code_ITI19:"Siena",
   code_ITI1A:"Grosseto",
   code_ITI2:"Umbria",
   code_ITI21:"Perugia",
   code_ITI22:"Terni",
   code_ITI3:"Marche",
   code_ITI31:"Pesaro e Urbino",
   code_ITI32:"Ancona",
   code_ITI33:"Macerata",
   code_ITI34:"Ascoli Piceno",
   code_ITI35:"Fermo",
   code_ITI4:"Lazio",
   code_ITI41:"Viterbo",
   code_ITI42:"Rieti",
   code_ITI43:"Roma",
   code_ITI44:"Latina",
   code_ITI45:"Frosinone",
   code_ITZ:"EXTRA-REGIO NUTS 1",
   code_ITZZ:"Extra-Regio NUTS 2",
   code_ITZZZ:"Extra-Regio NUTS 3",
   code_LI:"Liechtenstein",
   code_LI0:"Liechtenstein",
   code_LI00:"Liechtenstein",
   code_LI000:"Liechtenstein",
   code_LT:"LIETUVA",
   code_LT0:"LIETUVA",
   code_LT01:"Sostinės regionas",
   code_LT011:"Vilniaus apskritis",
   code_LT02:"Vidurio ir vakarų Lietuvos regionas",
   code_LT021:"Alytaus apskritis",
   code_LT022:"Kauno apskritis",
   code_LT023:"Klaipėdos apskritis",
   code_LT024:"Marijampolės apskritis",
   code_LT025:"Panevėžio apskritis",
   code_LT026:"Šiaulių apskritis",
   code_LT027:"Tauragės apskritis",
   code_LT028:"Telšių apskritis",
   code_LT029:"Utenos apskritis",
   code_LTZ:"EXTRA-REGIO NUTS 1",
   code_LTZZ:"Extra-Regio NUTS 2",
   code_LTZZZ:"Extra-Regio NUTS 3",
   code_LU:"LUXEMBOURG",
   code_LU0:"LUXEMBOURG",
   code_LU00:"Luxembourg",
   code_LU000:"Luxembourg",
   code_LUZ:"EXTRA-REGIO NUTS 1",
   code_LUZZ:"Extra-Regio NUTS 2",
   code_LUZZZ:"Extra-Regio NUTS 3",
   code_LV:"LATVIJA",
   code_LV0:"LATVIJA",
   code_LV00:"Latvija",
   code_LV003:"Kurzeme",
   code_LV005:"Latgale",
   code_LV006:"Rīga",
   code_LV007:"Pierīga",
   code_LV008:"Vidzeme",
   code_LV009:"Zemgale",
   code_LVZ:"EXTRA-REGIO NUTS 1",
   code_LVZZ:"Extra-Regio NUTS 2",
   code_LVZZZ:"Extra-Regio NUTS 3",
   code_ME:"Montenegro",
   code_ME0:"Црна Гора",
   code_ME00:"Црна Гора",
   code_ME000:"Црна Гора",
   code_MK:"Macedonia",
   code_MK0:"Северна Македонија",
   code_MK00:"Северна Македонија",
   code_MK001:"Вардарски",
   code_MK002:"Источен",
   code_MK003:"Југозападен",
   code_MK004:"Југоисточен",
   code_MK005:"Пелагониски",
   code_MK006:"Полошки",
   code_MK007:"Североисточен",
   code_MK008:"Скопски",
   code_MT:"MALTA",
   code_MT0:"MALTA",
   code_MT00:"Malta",
   code_MT001:"Malta",
   code_MT002:"Gozo and Comino / Għawdex u Kemmuna",
   code_MTZ:"EXTRA-REGIO NUTS 1",
   code_MTZZ:"Extra-Regio NUTS 2",
   code_MTZZZ:"Extra-Regio NUTS 3",
   code_NL:"NEDERLAND",
   code_NL1:"NOORD-NEDERLAND",
   code_NL11:"Groningen",
   code_NL111:"Oost-Groningen",
   code_NL112:"Delfzijl en omgeving",
   code_NL113:"Overig Groningen",
   code_NL12:"Friesland (NL)",
   code_NL124:"Noord-Friesland",
   code_NL125:"Zuidwest-Friesland",
   code_NL126:"Zuidoost-Friesland",
   code_NL13:"Drenthe",
   code_NL131:"Noord-Drenthe",
   code_NL132:"Zuidoost-Drenthe",
   code_NL133:"Zuidwest-Drenthe",
   code_NL2:"OOST-NEDERLAND",
   code_NL21:"Overijssel",
   code_NL211:"Noord-Overijssel",
   code_NL212:"Zuidwest-Overijssel",
   code_NL213:"Twente",
   code_NL22:"Gelderland",
   code_NL221:"Veluwe",
   code_NL224:"Zuidwest-Gelderland",
   code_NL225:"Achterhoek",
   code_NL226:"Arnhem/Nijmegen",
   code_NL23:"Flevoland",
   code_NL230:"Flevoland",
   code_NL3:"WEST-NEDERLAND",
   code_NL31:"Utrecht",
   code_NL310:"Utrecht",
   code_NL32:"Noord-Holland",
   code_NL321:"Kop van Noord-Holland",
   code_NL323:"IJmond",
   code_NL324:"Agglomeratie Haarlem",
   code_NL325:"Zaanstreek",
   code_NL327:"Het Gooi en Vechtstreek",
   code_NL328:"Alkmaar en omgeving",
   code_NL329:"Groot-Amsterdam",
   code_NL33:"Zuid-Holland",
   code_NL332:"Agglomeratie ’s-Gravenhage",
   code_NL333:"Delft en Westland",
   code_NL337:"Agglomeratie Leiden en Bollenstreek",
   code_NL33A:"Zuidoost-Zuid-Holland",
   code_NL33B:"Oost-Zuid-Holland",
   code_NL33C:"Groot-Rijnmond",
   code_NL34:"Zeeland",
   code_NL341:"Zeeuwsch-Vlaanderen",
   code_NL342:"Overig Zeeland",
   code_NL4:"ZUID-NEDERLAND",
   code_NL41:"Noord-Brabant",
   code_NL411:"West-Noord-Brabant",
   code_NL412:"Midden-Noord-Brabant",
   code_NL413:"Noordoost-Noord-Brabant",
   code_NL414:"Zuidoost-Noord-Brabant",
   code_NL42:"Limburg (NL)",
   code_NL421:"Noord-Limburg",
   code_NL422:"Midden-Limburg",
   code_NL423:"Zuid-Limburg",
   code_NLZ:"EXTRA-REGIO NUTS 1",
   code_NLZZ:"Extra-Regio NUTS 2",
   code_NLZZZ:"Extra-Regio NUTS 3",
   code_NO:"Norway",
   code_NO0:"Norge",
   code_NO01:"Oslo og Akershus",
   code_NO011:"Oslo",
   code_NO012:"Akershus",
   code_NO02:"Innlandet",
   code_NO021:"Hedmark",
   code_NO022:"Oppland",
   code_NO03:"Sør-Østlandet",
   code_NO031:"Østfold",
   code_NO032:"Buskerud",
   code_NO033:"Vestfold",
   code_NO034:"Telemark",
   code_NO04:"Agder og Rogaland",
   code_NO041:"Aust-Agder",
   code_NO042:"Vest-Agder",
   code_NO043:"Rogaland",
   code_NO05:"Vestlandet",
   code_NO051:"Hordaland",
   code_NO052:"Sogn og Fjordane",
   code_NO053:"Møre og Romsdal",
   code_NO06:"Trøndelag",
   code_NO061:"Sør-Trøndelag",
   code_NO062:"Nord-Trøndelag",
   code_NO07:"Nord-Norge",
   code_NO071:"Nordland",
   code_NO072:"Troms",
   code_NO073:"Finnmark",
   code_PL:"POLSKA",
   code_PL2:"MAKROREGION POŁUDNIOWY",
   code_PL21:"Małopolskie",
   code_PL213:"Miasto Kraków",
   code_PL214:"Krakowski",
   code_PL217:"Tarnowski",
   code_PL218:"Nowosądecki",
   code_PL219:"Nowotarski",
   code_PL21A:"Oświęcimski",
   code_PL22:"Śląskie",
   code_PL224:"Częstochowski",
   code_PL225:"Bielski",
   code_PL227:"Rybnicki",
   code_PL228:"Bytomski",
   code_PL229:"Gliwicki",
   code_PL22A:"Katowicki",
   code_PL22B:"Sosnowiecki",
   code_PL22C:"Tyski",
   code_PL4:"MAKROREGION PÓŁNOCNO-ZACHODNI",
   code_PL41:"Wielkopolskie",
   code_PL411:"Pilski",
   code_PL414:"Koniński",
   code_PL415:"Miasto Poznań",
   code_PL416:"Kaliski",
   code_PL417:"Leszczyński",
   code_PL418:"Poznański",
   code_PL42:"Zachodniopomorskie",
   code_PL424:"Miasto Szczecin",
   code_PL426:"Koszaliński",
   code_PL427:"Szczecinecko-pyrzycki",
   code_PL428:"Szczeciński",
   code_PL43:"Lubuskie",
   code_PL431:"Gorzowski",
   code_PL432:"Zielonogórski",
   code_PL5:"MAKROREGION POŁUDNIOWO-ZACHODNI",
   code_PL51:"Dolnośląskie",
   code_PL514:"Miasto Wrocław",
   code_PL515:"Jeleniogórski",
   code_PL516:"Legnicko-głogowski",
   code_PL517:"Wałbrzyski",
   code_PL518:"Wrocławski",
   code_PL52:"Opolskie",
   code_PL523:"Nyski",
   code_PL524:"Opolski",
   code_PL6:"MAKROREGION PÓŁNOCNY",
   code_PL61:"Kujawsko-pomorskie",
   code_PL613:"Bydgosko-toruński",
   code_PL616:"Grudziądzki",
   code_PL617:"Inowrocławski",
   code_PL618:"Świecki",
   code_PL619:"Włocławski",
   code_PL62:"Warmińsko-mazurskie",
   code_PL621:"Elbląski",
   code_PL622:"Olsztyński",
   code_PL623:"Ełcki",
   code_PL63:"Pomorskie",
   code_PL633:"Trójmiejski",
   code_PL634:"Gdański",
   code_PL636:"Słupski",
   code_PL637:"Chojnicki",
   code_PL638:"Starogardzki",
   code_PL7:"MAKROREGION CENTRALNY",
   code_PL71:"Łódzkie",
   code_PL711:"Miasto Łódź",
   code_PL712:"Łódzki",
   code_PL713:"Piotrkowski",
   code_PL714:"Sieradzki",
   code_PL715:"Skierniewicki",
   code_PL72:"Świętokrzyskie",
   code_PL721:"Kielecki",
   code_PL722:"Sandomiersko-jędrzejowski",
   code_PL8:"MAKROREGION WSCHODNI",
   code_PL81:"Lubelskie",
   code_PL811:"Bialski",
   code_PL812:"Chełmsko-zamojski",
   code_PL814:"Lubelski",
   code_PL815:"Puławski",
   code_PL82:"Podkarpackie",
   code_PL821:"Krośnieński",
   code_PL822:"Przemyski",
   code_PL823:"Rzeszowski",
   code_PL824:"Tarnobrzeski",
   code_PL84:"Podlaskie",
   code_PL841:"Białostocki",
   code_PL842:"Łomżyński",
   code_PL843:"Suwalski",
   code_PL9:"MAKROREGION WOJEWÓDZTWO MAZOWIECKIE",
   code_PL91:"Warszawski stołeczny",
   code_PL911:"Miasto Warszawa",
   code_PL912:"Warszawski wschodni",
   code_PL913:"Warszawski zachodni",
   code_PL92:"Mazowiecki regionalny",
   code_PL921:"Radomski",
   code_PL922:"Ciechanowski",
   code_PL923:"Płocki",
   code_PL924:"Ostrołęcki",
   code_PL925:"Siedlecki",
   code_PL926:"Żyrardowski",
   code_PLZ:"EXTRA-REGIO NUTS 1",
   code_PLZZ:"Extra-Regio NUTS 2",
   code_PLZZZ:"Extra-Regio NUTS 3",
   code_PT:"PORTUGAL",
   code_PT1:"CONTINENTE",
   code_PT11:"Norte",
   code_PT111:"Alto Minho",
   code_PT112:"Cávado",
   code_PT119:"Ave",
   code_PT11A:"Área Metropolitana do Porto",
   code_PT11B:"Alto Tâmega",
   code_PT11C:"Tâmega e Sousa",
   code_PT11D:"Douro",
   code_PT11E:"Terras de Trás-os-Montes",
   code_PT15:"Algarve",
   code_PT150:"Algarve",
   code_PT16:"Centro (PT)",
   code_PT16B:"Oeste",
   code_PT16D:"Região de Aveiro",
   code_PT16E:"Região de Coimbra",
   code_PT16F:"Região de Leiria",
   code_PT16G:"Viseu Dão Lafões",
   code_PT16H:"Beira Baixa",
   code_PT16I:"Médio Tejo",
   code_PT16J:"Beiras e Serra da Estrela",
   code_PT17:"Área Metropolitana de Lisboa",
   code_PT170:"Área Metropolitana de Lisboa",
   code_PT18:"Alentejo",
   code_PT181:"Alentejo Litoral",
   code_PT184:"Baixo Alentejo",
   code_PT185:"Lezíria do Tejo",
   code_PT186:"Alto Alentejo",
   code_PT187:"Alentejo Central",
   code_PT2:"REGIÃO AUTÓNOMA DOS AÇORES",
   code_PT20:"Região Autónoma dos Açores",
   code_PT200:"Região Autónoma dos Açores",
   code_PT3:"REGIÃO AUTÓNOMA DA MADEIRA",
   code_PT30:"Região Autónoma da Madeira",
   code_PT300:"Região Autónoma da Madeira",
   code_PTZ:"EXTRA-REGIO NUTS 1",
   code_PTZZ:"Extra-Regio NUTS 2",
   code_PTZZZ:"Extra-Regio NUTS 3",
   code_RO:"ROMANIA",
   code_RO1:"MACROREGIUNEA UNU",
   code_RO11:"Nord-Vest",
   code_RO111:"Bihor",
   code_RO112:"Bistriţa-Năsăud",
   code_RO113:"Cluj",
   code_RO114:"Maramureş",
   code_RO115:"Satu Mare",
   code_RO116:"Sălaj",
   code_RO12:"Centru",
   code_RO121:"Alba",
   code_RO122:"Braşov",
   code_RO123:"Covasna",
   code_RO124:"Harghita",
   code_RO125:"Mureş",
   code_RO126:"Sibiu",
   code_RO2:"MACROREGIUNEA DOI",
   code_RO21:"Nord-Est",
   code_RO211:"Bacău",
   code_RO212:"Botoşani",
   code_RO213:"Iaşi",
   code_RO214:"Neamţ",
   code_RO215:"Suceava",
   code_RO216:"Vaslui",
   code_RO22:"Sud-Est",
   code_RO221:"Brăila",
   code_RO222:"Buzău",
   code_RO223:"Constanţa",
   code_RO224:"Galaţi",
   code_RO225:"Tulcea",
   code_RO226:"Vrancea",
   code_RO3:"MACROREGIUNEA TREI",
   code_RO31:"Sud-Muntenia",
   code_RO311:"Argeş",
   code_RO312:"Călăraşi",
   code_RO313:"Dâmboviţa",
   code_RO314:"Giurgiu",
   code_RO315:"Ialomiţa",
   code_RO316:"Prahova",
   code_RO317:"Teleorman",
   code_RO32:"Bucureşti-Ilfov",
   code_RO321:"Bucureşti",
   code_RO322:"Ilfov",
   code_RO4:"MACROREGIUNEA PATRU",
   code_RO41:"Sud-Vest Oltenia",
   code_RO411:"Dolj",
   code_RO412:"Gorj",
   code_RO413:"Mehedinţi",
   code_RO414:"Olt",
   code_RO415:"Vâlcea",
   code_RO42:"Vest",
   code_RO421:"Arad",
   code_RO422:"Caraş-Severin",
   code_RO423:"Hunedoara",
   code_RO424:"Timiş",
   code_ROZ:"EXTRA-REGIO NUTS 1",
   code_ROZZ:"Extra-Regio NUTS 2",
   code_ROZZZ:"Extra-Regio NUTS 3",
   code_RS:"Serbia",
   code_SE:"SVERIGE",
   code_SE1:"ÖSTRA SVERIGE",
   code_SE11:"Stockholm",
   code_SE110:"Stockholms län",
   code_SE12:"Östra Mellansverige",
   code_SE121:"Uppsala län",
   code_SE122:"Södermanlands län",
   code_SE123:"Östergötlands län",
   code_SE124:"Örebro län",
   code_SE125:"Västmanlands län",
   code_SE2:"SÖDRA SVERIGE",
   code_SE21:"Småland med öarna",
   code_SE211:"Jönköpings län",
   code_SE212:"Kronobergs län",
   code_SE213:"Kalmar län",
   code_SE214:"Gotlands län",
   code_SE22:"Sydsverige",
   code_SE221:"Blekinge län",
   code_SE224:"Skåne län",
   code_SE23:"Västsverige",
   code_SE231:"Hallands län",
   code_SE232:"Västra Götalands län",
   code_SE3:"NORRA SVERIGE",
   code_SE31:"Norra Mellansverige",
   code_SE311:"Värmlands län",
   code_SE312:"Dalarnas län",
   code_SE313:"Gävleborgs län",
   code_SE32:"Mellersta Norrland",
   code_SE321:"Västernorrlands län",
   code_SE322:"Jämtlands län",
   code_SE33:"Övre Norrland",
   code_SE331:"Västerbottens län",
   code_SE332:"Norrbottens län",
   code_SEZ:"EXTRA-REGIO NUTS 1",
   code_SEZZ:"Extra-Regio NUTS 2",
   code_SEZZZ:"Extra-Regio NUTS 3",
   code_SI:"Slovenia",
   code_SI0:"SLOVENIJA",
   code_SI03:"Vzhodna Slovenija",
   code_SI031:"Pomurska",
   code_SI032:"Podravska",
   code_SI033:"Koroška",
   code_SI034:"Savinjska",
   code_SI035:"Zasavska",
   code_SI036:"Posavska",
   code_SI037:"Jugovzhodna Slovenija",
   code_SI038:"Primorsko-notranjska",
   code_SI04:"Zahodna Slovenija",
   code_SI041:"Osrednjeslovenska",
   code_SI042:"Gorenjska",
   code_SI043:"Goriška",
   code_SI044:"Obalno-kraška",
   code_SIZ:"EXTRA-REGIO NUTS 1",
   code_SIZZ:"Extra-Regio NUTS 2",
   code_SIZZZ:"Extra-Regio NUTS 3",
   code_SK:"SLOVENSKO",
   code_SK0:"SLOVENSKO",
   code_SK01:"Bratislavský kraj",
   code_SK010:"Bratislavský kraj",
   code_SK02:"Západné Slovensko",
   code_SK021:"Trnavský kraj",
   code_SK022:"Trenčiansky kraj",
   code_SK023:"Nitriansky kraj",
   code_SK03:"Stredné Slovensko",
   code_SK031:"Žilinský kraj",
   code_SK032:"Banskobystrický kraj",
   code_SK04:"Východné Slovensko",
   code_SK041:"Prešovský kraj",
   code_SK042:"Košický kraj",
   code_SKZ:"EXTRA-REGIO NUTS 1",
   code_SKZZ:"Extra-Regio NUTS 2",
   code_SKZZZ:"Extra-Regio NUTS 3",
   code_TR:"Turkey",
   code_TR1:"İstanbul",
   code_TR10:"İstanbul",
   code_TR100:"İstanbul",
   code_TR2:"Batı Marmara",
   code_TR21:"Tekirdağ",
   code_TR211:"Tekirdağ",
   code_TR212:"Edirne",
   code_TR213:"Kırklareli",
   code_TR22:"Balıkesir",
   code_TR221:"Balıkesir",
   code_TR222:"Çanakkale",
   code_TR3:"Ege",
   code_TR31:"İzmir",
   code_TR310:"İzmir",
   code_TR32:"Aydın",
   code_TR321:"Aydın",
   code_TR322:"Denizli",
   code_TR323:"Muğla",
   code_TR33:"Manisa",
   code_TR331:"Manisa",
   code_TR332:"Afyonkarahisar",
   code_TR333:"Kütahya",
   code_TR334:"Uşak",
   code_TR4:"Doğu Marmara",
   code_TR41:"Bursa",
   code_TR411:"Bursa",
   code_TR412:"Eskişehir",
   code_TR413:"Bilecik",
   code_TR42:"Kocaeli",
   code_TR421:"Kocaeli",
   code_TR422:"Sakarya",
   code_TR423:"Düzce",
   code_TR424:"Bolu",
   code_TR425:"Yalova",
   code_TR5:"Batı Anadolu",
   code_TR51:"Ankara",
   code_TR510:"Ankara",
   code_TR52:"Konya",
   code_TR521:"Konya",
   code_TR522:"Karaman",
   code_TR6:"Akdeniz",
   code_TR61:"Antalya",
   code_TR611:"Antalya",
   code_TR612:"Isparta",
   code_TR613:"Burdur",
   code_TR62:"Adana",
   code_TR621:"Adana",
   code_TR622:"Mersin",
   code_TR63:"Hatay",
   code_TR631:"Hatay",
   code_TR632:"Kahramanmaraş",
   code_TR633:"Osmaniye",
   code_TR7:"Orta Anadolu",
   code_TR71:"Kırıkkale",
   code_TR711:"Kırıkkale",
   code_TR712:"Aksaray",
   code_TR713:"Niğde",
   code_TR714:"Nevşehir",
   code_TR715:"Kırşehir",
   code_TR72:"Kayseri",
   code_TR721:"Kayseri",
   code_TR722:"Sivas",
   code_TR723:"Yozgat",
   code_TR8:"Batı Karadeniz",
   code_TR81:"Zonguldak",
   code_TR811:"Zonguldak",
   code_TR812:"Karabük",
   code_TR813:"Bartın",
   code_TR82:"Kastamonu",
   code_TR821:"Kastamonu",
   code_TR822:"Çankırı",
   code_TR823:"Sinop",
   code_TR83:"Samsun",
   code_TR831:"Samsun",
   code_TR832:"Tokat",
   code_TR833:"Çorum",
   code_TR834:"Amasya",
   code_TR9:"Doğu Karadeniz",
   code_TR90:"Trabzon",
   code_TR901:"Trabzon",
   code_TR902:"Ordu",
   code_TR903:"Giresun",
   code_TR904:"Rize",
   code_TR905:"Artvin",
   code_TR906:"Gümüşhane",
   code_TRA:"Kuzeydoğu Anadolu",
   code_TRA1:"Erzurum",
   code_TRA11:"Erzurum",
   code_TRA12:"Erzincan",
   code_TRA13:"Bayburt",
   code_TRA2:"Ağrı",
   code_TRA21:"Ağrı",
   code_TRA22:"Kars",
   code_TRA23:"Iğdır",
   code_TRA24:"Ardahan",
   code_TRB:"Ortadoğu Anadolu",
   code_TRB1:"Malatya",
   code_TRB11:"Malatya",
   code_TRB12:"Elazığ",
   code_TRB13:"Bingöl",
   code_TRB14:"Tunceli",
   code_TRB2:"Van",
   code_TRB21:"Van",
   code_TRB22:"Muş",
   code_TRB23:"Bitlis",
   code_TRB24:"Hakkari",
   code_TRC:"Güneydoğu Anadolu",
   code_TRC1:"Gaziantep",
   code_TRC11:"Gaziantep",
   code_TRC12:"Adıyaman",
   code_TRC13:"Kilis",
   code_TRC2:"Şanlıurfa",
   code_TRC21:"Şanlıurfa",
   code_TRC22:"Diyarbakır",
   code_TRC3:"Mardin",
   code_TRC31:"Mardin",
   code_TRC32:"Batman",
   code_TRC33:"Şırnak",
   code_TRC34:"Siirt",
   code_UK:"United Kingdom",
   code_UKC:"NORTH EAST (ENGLAND)",
   code_UKC1:"Tees Valley and Durham",
   code_UKC11:"Hartlepool and Stockton-on-Tees",
   code_UKC12:"South Teesside",
   code_UKC13:"Darlington",
   code_UKC14:"Durham CC",
   code_UKC2:"Northumberland and Tyne and Wear",
   code_UKC21:"Northumberland",
   code_UKC22:"Tyneside",
   code_UKC23:"Sunderland",
   code_UKD:"NORTH WEST (ENGLAND)",
   code_UKD1:"Cumbria",
   code_UKD11:"West Cumbria",
   code_UKD12:"East Cumbria",
   code_UKD3:"Greater Manchester",
   code_UKD33:"Manchester",
   code_UKD34:"Greater Manchester South West",
   code_UKD35:"Greater Manchester South East",
   code_UKD36:"Greater Manchester North West",
   code_UKD37:"Greater Manchester North East",
   code_UKD4:"Lancashire",
   code_UKD41:"Blackburn with Darwen",
   code_UKD42:"Blackpool",
   code_UKD44:"Lancaster and Wyre",
   code_UKD45:"Mid Lancashire",
   code_UKD46:"East Lancashire",
   code_UKD47:"Chorley and West Lancashire",
   code_UKD6:"Cheshire",
   code_UKD61:"Warrington",
   code_UKD62:"Cheshire East",
   code_UKD63:"Cheshire West and Chester",
   code_UKD7:"Merseyside",
   code_UKD71:"East Merseyside",
   code_UKD72:"Liverpool",
   code_UKD73:"Sefton",
   code_UKD74:"Wirral",
   code_UKE:"YORKSHIRE AND THE HUMBER",
   code_UKE1:"East Yorkshire and Northern Lincolnshire",
   code_UKE11:"Kingston upon Hull, City of",
   code_UKE12:"East Riding of Yorkshire",
   code_UKE13:"North and North East Lincolnshire",
   code_UKE2:"North Yorkshire",
   code_UKE21:"York",
   code_UKE22:"North Yorkshire CC",
   code_UKE3:"South Yorkshire",
   code_UKE31:"Barnsley, Doncaster and Rotherham",
   code_UKE32:"Sheffield",
   code_UKE4:"West Yorkshire",
   code_UKE41:"Bradford",
   code_UKE42:"Leeds",
   code_UKE44:"Calderdale and Kirklees",
   code_UKE45:"Wakefield",
   code_UKF:"EAST MIDLANDS (ENGLAND)",
   code_UKF1:"Derbyshire and Nottinghamshire",
   code_UKF11:"Derby",
   code_UKF12:"East Derbyshire",
   code_UKF13:"South and West Derbyshire",
   code_UKF14:"Nottingham",
   code_UKF15:"North Nottinghamshire",
   code_UKF16:"South Nottinghamshire",
   code_UKF2:"Leicestershire, Rutland and Northamptonshire",
   code_UKF21:"Leicester",
   code_UKF22:"Leicestershire CC and Rutland",
   code_UKF24:"West Northamptonshire",
   code_UKF25:"North Northamptonshire",
   code_UKF3:"Lincolnshire",
   code_UKF30:"Lincolnshire",
   code_UKG:"WEST MIDLANDS (ENGLAND)",
   code_UKG1:"Herefordshire, Worcestershire and Warwickshire",
   code_UKG11:"Herefordshire, County of",
   code_UKG12:"Worcestershire",
   code_UKG13:"Warwickshire",
   code_UKG2:"Shropshire and Staffordshire",
   code_UKG21:"Telford and Wrekin",
   code_UKG22:"Shropshire CC",
   code_UKG23:"Stoke-on-Trent",
   code_UKG24:"Staffordshire CC",
   code_UKG3:"West Midlands",
   code_UKG31:"Birmingham",
   code_UKG32:"Solihull",
   code_UKG33:"Coventry",
   code_UKG36:"Dudley",
   code_UKG37:"Sandwell",
   code_UKG38:"Walsall",
   code_UKG39:"Wolverhampton",
   code_UKH:"EAST OF ENGLAND",
   code_UKH1:"East Anglia",
   code_UKH11:"Peterborough",
   code_UKH12:"Cambridgeshire CC",
   code_UKH14:"Suffolk",
   code_UKH15:"Norwich and East Norfolk",
   code_UKH16:"North and West Norfolk",
   code_UKH17:"Breckland and South Norfolk",
   code_UKH2:"Bedfordshire and Hertfordshire",
   code_UKH21:"Luton",
   code_UKH23:"Hertfordshire",
   code_UKH24:"Bedford",
   code_UKH25:"Central Bedfordshire",
   code_UKH3:"Essex",
   code_UKH31:"Southend-on-Sea",
   code_UKH32:"Thurrock",
   code_UKH34:"Essex Haven Gateway",
   code_UKH35:"West Essex",
   code_UKH36:"Heart of Essex",
   code_UKH37:"Essex Thames Gateway",
   code_UKI:"LONDON",
   code_UKI3:"Inner London – West",
   code_UKI31:"Camden and City of London",
   code_UKI32:"Westminster",
   code_UKI33:"Kensington & Chelsea and Hammersmith & Fulham",
   code_UKI34:"Wandsworth",
   code_UKI4:"Inner London – East",
   code_UKI41:"Hackney and Newham",
   code_UKI42:"Tower Hamlets",
   code_UKI43:"Haringey and Islington",
   code_UKI44:"Lewisham and Southwark",
   code_UKI45:"Lambeth",
   code_UKI5:"Outer London – East and North East",
   code_UKI51:"Bexley and Greenwich",
   code_UKI52:"Barking & Dagenham and Havering",
   code_UKI53:"Redbridge and Waltham Forest",
   code_UKI54:"Enfield",
   code_UKI6:"Outer London – South",
   code_UKI61:"Bromley",
   code_UKI62:"Croydon",
   code_UKI63:"Merton, Kingston upon Thames and Sutton",
   code_UKI7:"Outer London – West and North West",
   code_UKI71:"Barnet",
   code_UKI72:"Brent",
   code_UKI73:"Ealing",
   code_UKI74:"Harrow and Hillingdon",
   code_UKI75:"Hounslow and Richmond upon Thames",
   code_UKJ:"SOUTH EAST (ENGLAND)",
   code_UKJ1:"Berkshire, Buckinghamshire and Oxfordshire",
   code_UKJ11:"Berkshire",
   code_UKJ12:"Milton Keynes",
   code_UKJ13:"Buckinghamshire CC",
   code_UKJ14:"Oxfordshire",
   code_UKJ2:"Surrey, East and West Sussex",
   code_UKJ21:"Brighton and Hove",
   code_UKJ22:"East Sussex CC",
   code_UKJ25:"West Surrey",
   code_UKJ26:"East Surrey",
   code_UKJ27:"West Sussex (South West)",
   code_UKJ28:"West Sussex (North East)",
   code_UKJ3:"Hampshire and Isle of Wight",
   code_UKJ31:"Portsmouth",
   code_UKJ32:"Southampton",
   code_UKJ34:"Isle of Wight",
   code_UKJ35:"South Hampshire",
   code_UKJ36:"Central Hampshire",
   code_UKJ37:"North Hampshire",
   code_UKJ4:"Kent",
   code_UKJ41:"Medway",
   code_UKJ43:"Kent Thames Gateway",
   code_UKJ44:"East Kent",
   code_UKJ45:"Mid Kent",
   code_UKJ46:"West Kent",
   code_UKK:"SOUTH WEST (ENGLAND)",
   code_UKK1:"Gloucestershire, Wiltshire and Bristol/Bath area",
   code_UKK11:"Bristol, City of",
   code_UKK12:"Bath and North East Somerset, North Somerset and South Gloucestershire",
   code_UKK13:"Gloucestershire",
   code_UKK14:"Swindon",
   code_UKK15:"Wiltshire CC",
   code_UKK2:"Dorset and Somerset",
   code_UKK21:"Bournemouth and Poole",
   code_UKK22:"Dorset CC",
   code_UKK23:"Somerset",
   code_UKK3:"Cornwall and Isles of Scilly",
   code_UKK30:"Cornwall and Isles of Scilly",
   code_UKK4:"Devon",
   code_UKK41:"Plymouth",
   code_UKK42:"Torbay",
   code_UKK43:"Devon CC",
   code_UKL:"WALES",
   code_UKL1:"West Wales and The Valleys",
   code_UKL11:"Isle of Anglesey",
   code_UKL12:"Gwynedd",
   code_UKL13:"Conwy and Denbighshire",
   code_UKL14:"South West Wales",
   code_UKL15:"Central Valleys",
   code_UKL16:"Gwent Valleys",
   code_UKL17:"Bridgend and Neath Port Talbot",
   code_UKL18:"Swansea",
   code_UKL2:"East Wales",
   code_UKL21:"Monmouthshire and Newport",
   code_UKL22:"Cardiff and Vale of Glamorgan",
   code_UKL23:"Flintshire and Wrexham",
   code_UKL24:"Powys",
   code_UKM:"SCOTLAND",
   code_UKM5:"North Eastern Scotland",
   code_UKM50:"Aberdeen City and Aberdeenshire",
   code_UKM6:"Highlands and Islands",
   code_UKM61:"Caithness & Sutherland and Ross & Cromarty",
   code_UKM62:"Inverness & Nairn and Moray, Badenoch & Strathspey",
   code_UKM63:"Lochaber, Skye & Lochalsh, Arran & Cumbrae and Argyll & Bute",
   code_UKM64:"Na h-Eileanan Siar (Western Isles)",
   code_UKM65:"Orkney Islands",
   code_UKM66:"Shetland Islands",
   code_UKM7:"Eastern Scotland",
   code_UKM71:"Angus and Dundee City",
   code_UKM72:"Clackmannanshire and Fife",
   code_UKM73:"East Lothian and Midlothian",
   code_UKM75:"Edinburgh, City of",
   code_UKM76:"Falkirk",
   code_UKM77:"Perth & Kinross and Stirling",
   code_UKM78:"West Lothian",
   code_UKM8:"West Central Scotland",
   code_UKM81:"East Dunbartonshire, West Dunbartonshire and Helensburgh & Lomond",
   code_UKM82:"Glasgow City",
   code_UKM83:"Inverclyde, East Renfrewshire and Renfrewshire",
   code_UKM84:"North Lanarkshire",
   code_UKM9:"Southern Scotland",
   code_UKM91:"Scottish Borders",
   code_UKM92:"Dumfries & Galloway",
   code_UKM93:"East Ayrshire and North Ayrshire mainland",
   code_UKM94:"South Ayrshire",
   code_UKM95:"South Lanarkshire",
   code_UKN:"NORTHERN IRELAND",
   code_UKN0:"Northern Ireland",
   code_UKN06:"Belfast",
   code_UKN07:"Armagh City, Banbridge and Craigavon",
   code_UKN08:"Newry, Mourne and Down",
   code_UKN09:"Ards and North Down",
   code_UKN10:"Derry City and Strabane",
   code_UKN11:"Mid Ulster",
   code_UKN12:"Causeway Coast and Glens",
   code_UKN13:"Antrim and Newtownabbey",
   code_UKN14:"Lisburn and Castlereagh",
   code_UKN15:"Mid and East Antrim",
   code_UKN16:"Fermanagh and Omagh",
   code_UKZ:"EXTRA-REGIO NUTS 1",
   code_UKZZ:"Extra-Regio NUTS 2",
   code_UKZZZ:"Extra-Regio NUTS 3",
   code_GR:"ELLADA",
   code_AF:"Afghanistan",
   code_DZ:"Algeria",
   code_AS:"American Samoa",
   code_AD:"Andorra",
   code_AO:"Angola",
   code_AI:"Anguilla",
   code_AQ:"Antarctica",
   code_AG:"Antigua and Barbuda",
   code_AR:"Argentina",
   code_AM:"Armenia",
   code_AW:"Aruba",
   code_AU:"Australia",
   code_AZ:"Azerbaijan",
   code_BS:"Bahamas",
   code_BH:"Bahrain",
   code_BD:"Bangladesh",
   code_BB:"Barbados",
   code_BY:"Belarus",
   code_BZ:"Belize",
   code_BJ:"Benin",
   code_BM:"Bermuda",
   code_BT:"Bhutan",
   code_BO:"Bolivia",
   code_BA:"Bosnia and Herzegovina",
   code_BW:"Botswana",
   code_BV:"Bouvet Island",
   code_BR:"Brazil",
   code_IO:"British Indian Ocean Territory",
   code_BN:"Brunei Darussalam",
   code_BF:"Burkina Faso",
   code_BI:"Burundi",
   code_KH:"Cambodia",
   code_CM:"Cameroon",
   code_CA:"Canada",
   code_CV:"Cabo Verde",
   code_KY:"Cayman Islands",
   code_CF:"Central African Republic",
   code_TD:"Chad",
   code_CL:"Chile",
   code_CN:"China",
   code_CX:"Christmas Island",
   code_CO:"Colombia",
   code_KM:"Comoros",
   code_CG:"Congo",
   code_CD:"Congo",
   code_CK:"Cook Islands",
   code_CR:"Costa Rica",
   code_CI:"Côte d'Ivoire",
   code_CU:"Cuba",
   code_DJ:"Djibouti",
   code_DM:"Dominica",
   code_DO:"Dominican Republic",
   code_EC:"Ecuador",
   code_EG:"Egypt",
   code_EH:"Western Sahara",
   code_SV:"El Salvador",
   code_GQ:"Equatorial Guinea",
   code_ER:"Eritrea",
   code_ET:"Ethiopia",
   code_FK:"Falkland Islands (Malvinas)",
   code_FO:"Faroe Islands",
   code_FJ:"Fiji",
   code_GF:"French Guiana",
   code_PF:"French Polynesia",
   code_TF:"French Southern Territories",
   code_GA:"Gabon",
   code_GM:"Gambia",
   code_GE:"Georgia",
   code_GH:"Ghana",
   code_GI:"Gibraltar",
   code_GL:"Greenland",
   code_GD:"Grenada",
   code_GP:"Guadeloupe",
   code_GU:"Guam",
   code_GT:"Guatemala",
   code_GN:"Guinea",
   code_GW:"Guinea-Bissau",
   code_GY:"Guyana",
   code_HT:"Haiti",
   code_HN:"Honduras",
   code_HK:"Hong Kong",
   code_IN:"India",
   code_ID:"Indonesia",
   code_IR:"Iran",
   code_IQ:"Iraq",
   code_IL:"Israel",
   code_JM:"Jamaica",
   code_JP:"Japan",
   code_JO:"Jordan",
   code_KZ:"Kazakhstan",
   code_KE:"Kenya",
   code_KI:"Kiribati",
   code_KP:"Korea",
   code_KR:"Korea",
   code_KW:"Kuwait",
   code_KG:"Kyrgyzstan",
   code_LA:"Lao People's Democratic Republic",
   code_LB:"Lebanon",
   code_LS:"Lesotho",
   code_LR:"Liberia",
   code_LY:"Libya",
   code_MO:"Macao",
   code_MG:"Madagascar",
   code_MW:"Malawi",
   code_MY:"Malaysia",
   code_MV:"Maldives",
   code_ML:"Mali",
   code_MH:"Marshall Islands",
   code_MQ:"Martinique",
   code_MR:"Mauritania",
   code_MU:"Mauritius",
   code_YT:"Mayotte",
   code_MX:"Mexico",
   code_FM:"Micronesia",
   code_MD:"Moldova",
   code_MC:"Monaco",
   code_MN:"Mongolia",
   code_MS:"Montserrat",
   code_MA:"Morocco",
   code_MZ:"Mozambique",
   code_MM:"Myanmar",
   code_NA:"Namibia",
   code_NR:"Nauru",
   code_NP:"Nepal",
   code_AN:"Netherlands Antilles",
   code_NZ:"New Zealand",
   code_NI:"Nicaragua",
   code_NE:"Niger",
   code_NG:"Nigeria",
   code_NU:"Niue",
   code_NF:"Norfolk Island",
   code_MP:"Northern Mariana Islands",
   code_OM:"Oman",
   code_PK:"Pakistan",
   code_PW:"Palau",
   code_PS:"Palestine",
   code_PA:"Panama",
   code_PG:"Papua New Guinea",
   code_PY:"Paraguay",
   code_PE:"Peru",
   code_PH:"Philippines",
   code_PN:"Pitcairn",
   code_PR:"Puerto Rico",
   code_QA:"Qatar",
   code_RE:"Réunion",
   code_RU:"Russian Federation",
   code_RW:"Rwanda",
   code_SH:"Saint Helena",
   code_KN:"Saint Kitts and Nevis",
   code_LC:"Saint Lucia",
   code_PM:"Saint Pierre and Miquelon",
   code_VC:"Saint Vincent and the Grenadines",
   code_WS:"Samoa",
   code_SM:"San Marino",
   code_ST:"Sao Tome and Principe",
   code_SA:"Saudi Arabia",
   code_SN:"Senegal",
   code_CS:"Serbia and Montenegro",
   code_SC:"Seychelles",
   code_SL:"Sierra Leone",
   code_SG:"Singapore",
   code_SB:"Solomon Islands",
   code_SO:"Somalia",
   code_ZA:"South Africa",
   code_GS:"South Georgia and the South Sandwich Islands",
   code_LK:"Sri Lanka",
   code_SD:"Sudan",
   code_SR:"Suriname",
   code_SJ:"Svalbard and Jan Mayen",
   code_SZ:"Swaziland",
   code_SY:"Syrian Arab Republic",
   code_TW:"Taiwan",
   code_TJ:"Tajikistan",
   code_TZ:"Tanzania",
   code_TH:"Thailand",
   code_TL:"Timor-Leste",
   code_TG:"Togo",
   code_TK:"Tokelau",
   code_TO:"Tonga",
   code_TT:"Trinidad and Tobago",
   code_TN:"Tunisia",
   code_TM:"Turkmenistan",
   code_TC:"Turks and Caicos Islands",
   code_TV:"Tuvalu",
   code_UG:"Uganda",
   code_UA:"Ukraine",
   code_AE:"United Arab Emirates",
   code_GB:"United Kingdom of Great Britain and Northern Ireland",
   code_US:"United States of America",
   code_UM:"United States Minor Outlying Islands",
   code_UY:"Uruguay",
   code_UZ:"Uzbekistan",
   code_VE:"Venezuela",
   code_VU:"Vanuatu",
   code_VN:"Viet Nam",
   code_VG:"Virgin Islands",
   code_VI:"Virgin Islands",
   code_WF:"Wallis and Futuna",
   code_YE:"Yemen",
   code_ZW:"Zimbabwe",

   code_03000000:"Landbouw- en veeteelt-, kwekerij-, visserij-, bosbouw- en aanverwante producten",
   code_FR212:"Aube",
   code_DE421:"Brandenburg an der Havel, Kreisfreie Stadt",
   code_DE422:"Cottbus, Kreisfreie Stadt",
   code_DE411:"Frankfurt (Oder), Kreisfreie Stadt",
   code_DE423:"Potsdam, Kreisfreie Stadt",
   code_DE412:"Barnim",
   code_DE424:"Dahme-Spreewald",
   code_DE425:"Elbe-Elster",
   code_DE426:"Havelland",
   code_DE413:"Märkisch-Oderland",
   code_DE414:"Oberhavel",
   code_DE427:"Oberspreewald-Lausitz",
   code_DE415:"Oder-Spree",
   code_DE416:"Ostprignitz-Ruppin",
   code_DE428:"Potsdam-Mittelmark",
   code_DE417:"Prignitz",
   code_DE429:"Spree-Neiße",
   code_DE42A:"Teltow-Fläming",
   code_DE418:"Uckermark",
   code_DEB16:"Cochem-Zell",
   code_DEB19:"Rhein-Hunsrück-Kreis",
   code_DED1:"Chemnitz",
   code_DED11:"Chemnitz, Kreisfreie Stadt",
   code_DED3:"Leipzig",
   code_DED31:"Leipzig, Kreisfreie Stadt",
   code_GR3:"ATTIKI",
   code_GR30:"Attiki",
   code_GR4:"NISIA AIGAIOU, KRITI",
   code_GR41:"Voreio Aigaio",
   code_GR411:"Lesvos",
   code_GR412:"Samos",
   code_GR413:"Chios",
   code_GR42:"Notio Aigaio",
   code_GR421:"Dodekanisos",
   code_GR422:"Kyklades",
   code_GR43:"Kriti",
   code_GR431:"Irakleio",
   code_GR432:"Lasithi",
   code_GR433:"Rethymni",
   code_GR434:"Chania",
   code_GR1:"VOREIA ELLADA",
   code_GR11:"Anatoliki Makedonia, Thraki",
   code_GR111:"Evros",
   code_GR112:"Xanthi",
   code_GR113:"Rodopi",
   code_GR114:"Drama",
   code_GR115:"Kavala",
   code_GR12:"Kentriki Makedonia",
   code_GR121:"Imathia",
   code_GR122:"Thessaloniki",
   code_GR123:"Kilkis",
   code_GR124:"Pella",
   code_GR125:"Pieria",
   code_GR126:"Serres",
   code_GR127:"Chalkidiki",
   code_GR13:"Dytiki Makedonia",
   code_GR132:"Kastoria",
   code_GR134:"Florina",
   code_GR21:"Ipeiros",
   code_GR212:"Thesprotia",
   code_GR213:"Ioannina",
   code_GR2:"KENTRIKI ELLADA",
   code_GR14:"Thessalia",
   code_GR142:"Larisa",
   code_GR143:"Magnisia",
   code_GR22:"Ionia Nisia",
   code_GR221:"Zakynthos",
   code_GR222:"Kerkyra",
   code_GR223:"Kefallinia",
   code_GR224:"Lefkada",
   code_GR23:"Dytiki Ellada",
   code_GR231:"Aitoloakarnania",
   code_GR232:"Achaia",
   code_GR233:"Ileia",
   code_GR24:"Sterea Ellada",
   code_GR241:"Voiotia",
   code_GR242:"Evvoia",
   code_GR243:"Evrytania",
   code_GR244:"Fthiotida",
   code_GR245:"Fokida",
   code_GR25:"Peloponnisos",
   code_GR253:"Korinthia",
   code_GRZ:"EXTRA-REGIO",
   code_GRZZ:"Extra-Regio",
   code_GRZZZ:"Extra-Regio",
   code_FI183:"Varsinais-Suomi",
   code_FI184:"Kanta-Häme",
   code_FI185:"Päijät-Häme",
   code_FI186:"Kymenlaakso",
   code_FI187:"Etelä-Karjala",
   code_FI131:"Etelä-Savo",
   code_FI132:"Pohjois-Savo",
   code_FI133:"Pohjois-Karjala",
   code_FI1A1:"Keski-Pohjanmaa",
   code_FI1A3:"Lappi",
   code_FI134:"Kainuu",
   code_FI1A2:"Pohjois-Pohjanmaa",
   code_FR24:"Centre",
   code_FR241:"Cher",
   code_FR242:"Eure-et-Loir",
   code_FR243:"Indre",
   code_FR244:"Indre-et-Loire",
   code_FR245:"Loir-et-Cher",
   code_FR246:"Loiret",
   code_FR26:"Bourgogne",
   code_FR261:"Côte-d'Or",
   code_FR262:"Nièvre",
   code_FR263:"Saône-et-Loire",
   code_FR264:"Yonne",
   code_FR43:"Franche-Comté",
   code_FR431:"Doubs",
   code_FR432:"Jura",
   code_FR433:"Haute-Saône",
   code_FR434:"Territoire de Belfort",
   code_FR25:"Basse-Normandie",
   code_FR251:"Calvados",
   code_FR252:"Manche",
   code_FR253:"Orne",
   code_FR23:"Haute-Normandie",
   code_FR231:"Eure",
   code_FR232:"Seine-Maritime",
   code_FR3:"NORD - PAS-DE-CALAIS",
   code_FR30:"Nord - Pas-de-Calais",
   code_FR301:"Nord",
   code_FR302:"Pas-de-Calais",
   code_FR22:"Picardie",
   code_FR221:"Aisne",
   code_FR222:"Oise",
   code_FR223:"Somme",
   code_FR42:"Alsace",
   code_FR421:"Bas-Rhin",
   code_FR422:"Haut-Rhin",
   code_FR21:"Champagne-Ardenne",
   code_FR211:"Ardennes",
   code_FR213:"Marne",
   code_FR214:"Haute-Marne",
   code_FR41:"Lorraine",
   code_FR411:"Meurthe-et-Moselle",
   code_FR412:"Meuse",
   code_FR413:"Moselle",
   code_FR414:"Vosges",
   code_FR51:"Pays de la Loire",
   code_FR511:"Loire-Atlantique",
   code_FR512:"Maine-et-Loire",
   code_FR513:"Mayenne",
   code_FR514:"Sarthe",
   code_FR515:"Vendée",
   code_FR52:"Bretagne",
   code_FR521:"Côtes-d'Armor",
   code_FR522:"Finistère",
   code_FR523:"Ille-et-Vilaine",
   code_FR524:"Morbihan",
   code_FR61:"Aquitaine",
   code_FR611:"Dordogne",
   code_FR612:"Gironde",
   code_FR613:"Landes",
   code_FR614:"Lot-et-Garonne",
   code_FR615:"Pyrénées-Atlantiques",
   code_FR63:"Limousin",
   code_FR631:"Corrèze",
   code_FR632:"Creuse",
   code_FR633:"Haute-Vienne",
   code_FR53:"Poitou-Charentes",
   code_FR531:"Charente",
   code_FR532:"Charente-Maritime",
   code_FR533:"Deux-Sèvres",
   code_FR534:"Vienne",
   code_FR81:"Languedoc-Roussillon",
   code_FR811:"Aude",
   code_FR812:"Gard",
   code_FR813:"Hérault",
   code_FR814:"Lozère",
   code_FR815:"Pyrénées-Orientales",
   code_FR62:"Midi-Pyrénées",
   code_FR621:"Ariège",
   code_FR622:"Aveyron",
   code_FR623:"Haute-Garonne",
   code_FR624:"Gers",
   code_FR625:"Lot",
   code_FR626:"Hautes-Pyrénées",
   code_FR627:"Tarn",
   code_FR628:"Tarn-et-Garonne",
   code_FR72:"Auvergne",
   code_FR721:"Allier",
   code_FR722:"Cantal",
   code_FR723:"Haute-Loire",
   code_FR724:"Puy-de-Dôme",
   code_FR71:"Rhône-Alpes",
   code_FR711:"Ain",
   code_FR712:"Ardèche",
   code_FR713:"Drôme",
   code_FR714:"Isère",
   code_FR715:"Loire",
   code_FR716:"Rhône",
   code_FR717:"Savoie",
   code_FR718:"Haute-Savoie",
   code_FR82:"Provence-Alpes-Côte d'Azur",
   code_FR821:"Alpes-de-Haute-Provence",
   code_FR822:"Hautes-Alpes",
   code_FR823:"Alpes-Maritimes",
   code_FR824:"Bouches-du-Rhône",
   code_FR825:"Var",
   code_FR826:"Vaucluse",
   code_FR83:"Corse",
   code_FR831:"Corse-du-Sud",
   code_FR832:"Haute-Corse",
   code_FR91:"Guadeloupe",
   code_FR910:"Guadeloupe",
   code_FR92:"Martinique",
   code_FR920:"Martinique",
   code_FR93:"Guyane",
   code_FR930:"Guyane",
   code_FR94:"Réunion",
   code_FR940:"Réunion",
   code_HR011:"Grad Zagreb",
   code_HR012:"Zagrebačka županija",
   code_HR013:"Krapinsko-zagorska županija",
   code_HR014:"Varaždinska županija",
   code_HR015:"Koprivničko-križevačka županija",
   code_HR016:"Međimurska županija",
   code_HR021:"Bjelovarsko-bilogorska županija",
   code_HR022:"Virovitičko-podravska županija",
   code_HR023:"Požeško-slavonska županija",
   code_HR024:"Brodsko-posavska županija",
   code_HR025:"Osječko-baranjska županija",
   code_HR026:"Vukovarsko-srijemska županija",
   code_HR027:"Karlovačka županija",
   code_HR028:"Sisačko-moslavačka županija",
   code_HU101:"Budapest",
   code_HU102:"Pest",
   code_IE01:"Border, Midland and Western",
   code_IE011:"Border",
   code_IE013:"West",
   code_IE023:"Mid-West",
   code_IE024:"South-East (IRL)",
   code_IE025:"South-West (IRL)",
   code_IE021:"Dublin",
   code_IE022:"Mid-East",
   code_IE012:"Midland",
   code_ITC45:"Milano",
   code_ITF41:"Foggia",
   code_ITF42:"Bari",
   code_ITD:"NORD-EST",
   code_ITD1:"Provincia Autonoma Bolzano/Bozen",
   code_ITD10:"Bolzano-Bozen",
   code_ITD2:"Provincia Autonoma Trento",
   code_ITD20:"Trento",
   code_ITD3:"Veneto",
   code_ITD31:"Verona",
   code_ITD32:"Vicenza",
   code_ITD33:"Belluno",
   code_ITD34:"Treviso",
   code_ITD35:"Venezia",
   code_ITD36:"Padova",
   code_ITD37:"Rovigo",
   code_ITD4:"Friuli-Venezia Giulia",
   code_ITD41:"Pordenone",
   code_ITD42:"Udine",
   code_ITD43:"Gorizia",
   code_ITD44:"Trieste",
   code_ITD5:"Emilia-Romagna",
   code_ITD51:"Piacenza",
   code_ITD52:"Parma",
   code_ITD53:"Reggio nell'Emilia",
   code_ITD54:"Modena",
   code_ITD55:"Bologna",
   code_ITD56:"Ferrara",
   code_ITD57:"Ravenna",
   code_ITD58:"Forlì-Cesena",
   code_ITD59:"Rimini",
   code_ITE:"CENTRO (I)",
   code_ITE1:"Toscana",
   code_ITE11:"Massa-Carrara",
   code_ITE12:"Lucca",
   code_ITE13:"Pistoia",
   code_ITE14:"Firenze",
   code_ITE15:"Prato",
   code_ITE16:"Livorno",
   code_ITE17:"Pisa",
   code_ITE18:"Arezzo",
   code_ITE19:"Siena",
   code_ITE1A:"Grosseto",
   code_ITE2:"Umbria",
   code_ITE21:"Perugia",
   code_ITE22:"Terni",
   code_ITE3:"Marche",
   code_ITE31:"Pesaro e Urbino",
   code_ITE32:"Ancona",
   code_ITE33:"Macerata",
   code_ITE34:"Ascoli Piceno",
   code_ITE4:"Lazio",
   code_ITE41:"Viterbo",
   code_ITE42:"Rieti",
   code_ITE43:"Roma",
   code_ITE44:"Latina",
   code_ITE45:"Frosinone",
   code_LT00A:"Vilniaus apskritis",
   code_LT001:"Alytaus apskritis",
   code_LT002:"Kauno apskritis",
   code_LT003:"Klaipėdos apskritis",
   code_LT004:"Marijampolės apskritis",
   code_LT005:"Panevėžio apskritis",
   code_LT006:"Šiaulių apskritis",
   code_LT007:"Tauragės apskritis",
   code_LT008:"Telšių apskritis",
   code_LT009:"Utenos apskritis",
   code_NL121:"Noord-Friesland",
   code_NL122:"Zuidwest-Friesland",
   code_NL123:"Zuidoost-Friesland",
   code_NL322:"Alkmaar en omgeving",
   code_NL326:"Groot-Amsterdam",
   code_NL331:"Agglomeratie Leiden en Bollenstreek",
   code_NL336:"Zuidoost-Zuid-Holland",
   code_NL334:"Oost-Zuid-Holland",
   code_NL335:"Groot-Rijnmond",
   code_PL215:"Nowosądecki",
   code_PL216:"Oświęcimski",
   code_PL422:"Koszaliński",
   code_PL425:"Szczeciński",
   code_PL521:"Nyski",
   code_PL522:"Opolski",
   code_PL614:"Grudziądzki",
   code_PL615:"Włocławski",
   code_PL631:"Słupski",
   code_PL635:"Starogardzki",
   code_PL11:"Łódzkie",
   code_PL113:"Miasto Łódź",
   code_PL114:"Łódzki",
   code_PL115:"Piotrkowski",
   code_PL116:"Sieradzki",
   code_PL117:"Skierniewicki",
   code_PL33:"Świętokrzyskie",
   code_PL331:"Kielecki",
   code_PL332:"Sandomiersko-jędrzejowski",
   code_PL3:"REGION WSCHODNI",
   code_PL31:"Lubelskie",
   code_PL311:"Bialski",
   code_PL312:"Chełmsko-zamojski",
   code_PL314:"Lubelski",
   code_PL315:"Puławski",
   code_PL32:"Podkarpackie",
   code_PL323:"Krośnieński",
   code_PL324:"Przemyski",
   code_PL325:"Rzeszowski",
   code_PL326:"Tarnobrzeski",
   code_PL34:"Podlaskie",
   code_PL343:"Białostocki",
   code_PL344:"Łomżyński",
   code_PL345:"Suwalski",
   code_PL127:"Miasto Warszawa",
   code_PL129:"Warszawski-wschodni",
   code_PL12A:"Warszawski-zachodni",
   code_PL128:"Radomski",
   code_PT113:"Ave",
   code_PT114:"Grande Porto",
   code_PT115:"Tâmega",
   code_PT116:"Entre Douro e Vouga",
   code_PT117:"Douro",
   code_PT118:"Alto Trás-os-Montes",
   code_PT163:"Pinhal Litoral",
   code_PT165:"Dâo-Lafôes",
   code_PT182:"Alto Alentejo",
   code_PT183:"Alentejo Central",
   code_SI01:"Vzhodna Slovenija",
   code_SI011:"Pomurska",
   code_SI012:"Podravska",
   code_SI013:"Koroška",
   code_SI014:"Savinjska",
   code_SI015:"Zasavska",
   code_SI016:"Spodnjeposavska",
   code_SI017:"Jugovzhodna Slovenija",
   code_SI018:"Notranjsko-kraška",
   code_SI02:"Zahodna Slovenija",
   code_SI021:"Osrednjeslovenska",
   code_SI022:"Gorenjska",
   code_SI023:"Goriška",
   code_SI024:"Obalno-kraška",
   code_UKD2:"Cheshire",
   code_UKD5:"Merseyside",
   code_UKD51:"East Merseyside",
   code_UKD52:"Liverpool",
   code_UKD53:"Sefton",
   code_UKD54:"Wirral",
   code_UKM2:"Eastern Scotland",
   code_UKM21:"Angus and Dundee City",
   code_UKM22:"Clackmannanshire and Fife",
   code_UKM23:"East Lothian and Midlothian",
   code_UKM25:"Edinburgh, City of",
   code_UKM26:"Falkirk",
   code_UKM27:"Perth & Kinross and Stirling",
   code_UKM28:"West Lothian",
   code_UKM31:"East Dunbartonshire, West Dunbartonshire and Helensburgh & Lomond",
   code_UKM34:"Glasgow City",
   code_UKM35:"Inverclyde, East Renfrewshire and Renfrewshire",
   code_UKM36:"North Lanarkshire",
   code_UKM24:"Scottish Borders",
   code_UKM32:"Dumfries & Galloway",
   code_UKM33:"East Ayrshire and North Ayrshire mainland",
   code_UKM37:"South Ayrshire",
   code_UKM38:"South Lanarkshire",
   code_UKN01:"Belfast",
   code_FR2:"Bassin Parisien",
   code_FR4:"Est",
   code_42924000:"Spraytoestellen",
   code_35811000:"Uniformen",
   code_34511000:"Patrouillevaartuigen",
   code_44115300:"Luiken",
   code_44613100:"Silos",
   code_35611000:"Gevechtsvliegtuigen, bommenwerpers en andere vliegtuigen",
   code_60110000:"Openbare vervoersdiensten",
   code_39250000:"Klokken, uurwerken en wekkers",
   code_30192120:"Pennen, stiften en penhouders",
   code_35612000:"Militaire helikopters en helikopters voor zoek- en reddingsacties",
   code_38527000:"Dosimeters",
   code_P:"Elektrische installaties",
   code_BE328:"Arr. Tournai-Mouscron",
   code_BE329:"Arr. La Louvière",
   code_BE32A:"Arr. Ath",
   code_BE32B:"Arr. Charleroi",
   code_BE32C:"Arr. Soignies",
   code_BE32D:"Arr. Thuin",
   code_EE009:"Kesk-Eesti",
   code_EE00A:"Kirde-Eesti",
   code_HR05:"Grad Zagreb",
   code_HR050:"Grad Zagreb",
   code_HR06:"Sjeverna Hrvatska",
   code_HR061:"Međimurska županija",
   code_HR062:"Varaždinska županija",
   code_HR063:"Koprivničko-križevačka županija",
   code_HR064:"Krapinsko-zagorska županija",
   code_HR065:"Zagrebačka županija",
   code_HR02:"Panonska Hrvatska",
   code_ITG2D:"Sassari",
   code_ITG2E:"Nuoro",
   code_ITG2F:"Cagliari",
   code_ITG2G:"Oristano",
   code_ITG2H:"Sud Sardegna",
   code_UKK24:"Bournemouth, Christchurch and Poole",
   code_UKK25:"Dorset",
   code_UKN0A:"Derry City and Strabane",
   code_UKN0B:"Mid Ulster",
   code_UKN0C:"Causeway Coast and Glens",
   code_UKN0D:"Antrim and Newtownabbey",
   code_UKN0E:"Lisburn and Castlereagh",
   code_UKN0F:"Mid and East Antrim",
   code_UKN0G:"Fermanagh and Omagh",
   code_NO020:"Innlandet",
   code_NO060:"Trøndelag",
   code_NO074:"Troms og Finnmark",
   code_NO08:"Oslo og Viken",
   code_NO081:"Oslo",
   code_NO082:"Viken",
   code_NO09:"Agder og Sør-Østlandet",
   code_NO091:"Vestfold og Telemark",
   code_NO092:"Agder",
   code_NO0A:"Vestlandet",
   code_NO0A1:"Rogaland",
   code_NO0A2:"Vestland",
   code_NO0A3:"Møre og Romsdal",
   code_DE41:"Brandenburg - Nordost",
   code_DE42:"Brandenburg - Südwest",
   code_DE801:"Greifswald, Kreisfreie Stadt",
   code_DE802:"Neubrandenburg, Kreisfreie Stadt",
   code_DE805:"Stralsund, Kreisfreie Stadt",
   code_DE806:"Wismar, Kreisfreie Stadt",
   code_DE807:"Bad Doberan",
   code_DE808:"Demmin",
   code_DE809:"Güstrow",
   code_DE80A:"Ludwigslust",
   code_DE80B:"Mecklenburg-Strelitz",
   code_DE80C:"Müritz",
   code_DE80D:"Nordvorpommern",
   code_DE80E:"Nordwestmecklenburg",
   code_DE80F:"Ostvorpommern",
   code_DE80G:"Parchim",
   code_DE80H:"Rügen",
   code_DE80I:"Uecker-Randow",
   code_DE915:"Göttingen",
   code_DE919:"Osterode am Harz",
   code_DEA21:"Aachen, Kreisfreie Stadt",
   code_DEA25:"Aachen, Kreis",
   code_DED12:"Plauen, Kreisfreie Stadt",
   code_DED13:"Zwickau, Kreisfreie Stadt",
   code_DED14:"Annaberg",
   code_DED15:"Chemnitzer Land",
   code_DED16:"Freiberg",
   code_DED17:"Vogtlandkreis",
   code_DED18:"Mittlerer Erzgebirgskreis",
   code_DED19:"Mittweida",
   code_DED1A:"Stollberg",
   code_DED1B:"Aue-Schwarzenberg",
   code_DED1C:"Zwickauer Land",
   code_DED22:"Görlitz, Kreisfreie Stadt",
   code_DED23:"Hoyerswerda, Kreisfreie Stadt",
   code_DED24:"Bautzen",
   code_DED25:"Meißen",
   code_DED26:"Niederschlesischer Oberlausitzkreis",
   code_DED27:"Riesa-Großenhain",
   code_DED28:"Löbau-Zittau",
   code_DED29:"Sächsische Schweiz",
   code_DED2A:"Weißeritzkreis",
   code_DED2B:"Kamenz",
   code_DED32:"Delitzsch",
   code_DED33:"Döbeln",
   code_DED34:"Leipziger Land",
   code_DED35:"Muldentalkreis",
   code_DED36:"Torgau-Oschatz",
   code_GR131:"Grevena",
   code_GR133:"Kozani",
   code_GR211:"Arta",
   code_GR214:"Preveza",
   code_GR141:"Karditsa",
   code_GR144:"Trikala",
   code_GR251:"Argolida",
   code_GR252:"Arkadia",
   code_GR254:"Lakonia",
   code_GR255:"Messinia",
   code_GR300:"Attiki",
   code_FI181:"Uusimaa",
   code_FI182:"Itä-Uusimaa",
   code_FI18:"Etelä-Suomi",
   code_FI13:"Itä-Suomi",
   code_FI1A:"Pohjois-Suomi",
   code_FR5:"OUEST",
   code_FR6:"SUD-OUEST",
   code_FR7:"CENTRE-EST",
   code_FR8:"MÉDITERRANÉE",
   code_FR9:"DÉPARTEMENTS D'OUTRE-MER",
   code_HR01:"Sjeverozapadna Hrvatska",
   code_HU10:"Közép-Magyarország",
   code_IE02:"Southern and Eastern",
   code_LT00:"Lietuva",
   code_PL1:"REGION CENTRALNY",
   code_PL12:"Mazowieckie",
   code_PL121:"Ciechanowsko-płocki",
   code_PL122:"Ostrołęcko-siedlecki",
   code_PL423:"Stargardzki",
   code_PT161:"Baixo Vouga",
   code_PT162:"Baixo Mondego",
   code_PT164:"Pinhal Interior Norte",
   code_PT166:"Pinhal Interior Sul",
   code_PT169:"Beira Interior Sul",
   code_PT16C:"Médio Tejo",
   code_PT167:"Serra da Estrela",
   code_PT168:"Beira Interior Norte",
   code_PT16A:"Cova da Beira",
   code_PT171:"Grande Lisboa",
   code_PT172:"Península de Setúbal",
   code_UKD31:"Greater Manchester South",
   code_UKD32:"Greater Manchester North",
   code_UKD43:"Lancashire CC",
   code_UKD21:"Halton and Warrington",
   code_UKD22:"Cheshire CC",
   code_UKE43:"Calderdale, Kirklees and Wakefield",
   code_UKF23:"Northamptonshire",
   code_UKG34:"Dudley and Sandwell",
   code_UKG35:"Walsall and Wolverhampton",
   code_UKH13:"Norfolk",
   code_UKH22:"Bedfordshire CC",
   code_UKH33:"Essex CC",
   code_UKI1:"Inner London",
   code_UKI11:"Inner London - West",
   code_UKI12:"Inner London - East",
   code_UKI2:"Outer London",
   code_UKI21:"Outer London - East and North East",
   code_UKI22:"Outer London - South",
   code_UKI23:"Outer London - West and North West",
   code_UKJ23:"Surrey",
   code_UKJ24:"West Sussex",
   code_UKJ33:"Hampshire CC",
   code_UKJ42:"Kent CC",
   code_UKM3:"South Western Scotland",
   code_UKN02:"Outer Belfast",
   code_UKN03:"East of Northern Ireland",
   code_UKN04:"North of Northern Ireland",
   code_UKN05:"West and South of Northern Ireland",
   code_1A:"Kosovo",
   code_ZM:"Zambia",
   code_AX:"Åland Islands",
   code_BL:"Saint Barthélemy",
   code_BQ:"Bonaire, Sint Eustatius and Saba",
   code_CC:"Cocos (Keeling) Islands",
   code_CW:"Curaçao",
   code_GG:"Guernsey",
   code_HM:"Heard Island and McDonald Islands",
   code_IM:"Isle of Man",
   code_JE:"Jersey",
   code_MF:"Saint Martin (French part)",
   code_NC:"New Caledonia",
   code_SS:"South Sudan",
   code_SX:"Sint Maarten (Dutch part)",
   code_VA:"Holy See",
   code_CP:"Clipperton Island",
   code_NO0B:"Svalbard og Jan Mayen",
   code_NO0B1:"Jan Mayen",
   code_NO0B2:"Svalbard",
   code_BE224:"Arr. Hasselt",
   code_BE225:"Arr. Maaseik",
   code_ISZ:"Extra-Regio NUTS 1",
   code_ISZZ:"Extra-Regio NUTS 2",
   code_ISZZZ:"Extra-Regio NUTS 3",
   code_LIZ:"Extra-Regio NUTS 1",
   code_LIZZ:"Extra-Regio NUTS 2",
   code_LIZZZ:"Extra-Regio NUTS 3",
   code_NOZ:"Extra-Regio NUTS 1",
   code_NOZZ:"Extra-Regio NUTS 2",
   code_NOZZZ:"Extra-Regio NUTS 3",
   code_CHZ:"Extra-Regio NUTS 1",
   code_CHZZ:"Extra-Regio NUTS 2",
   code_CHZZZ:"Extra-Regio NUTS 3",
   code_MEZ:"Extra-Regio NUTS 1",
   code_MEZZ:"Extra-Regio NUTS 2",
   code_MEZZZ:"Extra-Regio NUTS 3",
   code_MKZ:"Extra-Regio NUTS 1",
   code_MKZZ:"Extra-Regio NUTS 2",
   code_MKZZZ:"Extra-Regio NUTS 3",
   code_ALZ:"Extra-Regio NUTS 1",
   code_ALZZ:"Extra-Regio NUTS 2",
   code_ALZZZ:"Extra-Regio NUTS 3",
   code_RS1:"Србија - север ",
   code_RS11:"Београдски регион",
   code_RS110:"Београдска област",
   code_RS12:"Регион Војводине",
   code_RS121:"Западнобачка област",
   code_RS122:"Јужнобанатска област",
   code_RS123:"Јужнобачка област",
   code_RS124:"Севернобанатска област",
   code_RS125:"Севернобачка област",
   code_RS126:"Средњобанатска област",
   code_RS127:"Сремска област",
   code_RS2:"Србија - југ ",
   code_RS21:"Регион Шумадије и Западне Србије",
   code_RS211:"Златиборска област",
   code_RS212:"Колубарска област",
   code_RS213:"Мачванска област",
   code_RS214:"Моравичка област",
   code_RS215:"Поморавска област",
   code_RS216:"Расинска област",
   code_RS217:"Рашка област",
   code_RS218:"Шумадијска област",
   code_RS22:"Регион Јужне и Источне Србије",
   code_RS221:"Борска област",
   code_RS222:"Браничевска област",
   code_RS223:"Зајечарска област",
   code_RS224:"Јабланичка област",
   code_RS225:"Нишавска област",
   code_RS226:"Пиротска област",
   code_RS227:"Подунавска област",
   code_RS228:"Пчињска област",
   code_RS229:"Топличка област",
   code_RSZ:"Extra-Regio NUTS 1",
   code_RSZZ:"Extra-Regio NUTS 2",
   code_RSZZZ:"Extra-Regio NUTS 3",
   code_TRZ:"Extra-Regio NUTS 1",
   code_TRZZ:"Extra-Regio NUTS 2",
   code_TRZZZ:"Extra-Regio NUTS 3",
   accreditation_budget_class_1: "Klasse 1: tot 135.000 EUR",
   accreditation_budget_class_2: "Klasse 2: tot 275.000 EUR",
   accreditation_budget_class_3: "Klasse 3: tot 500.000 EUR",
   accreditation_budget_class_4: "Klasse 4: tot 900.000 EUR",
   accreditation_budget_class_5: "Klasse 5: tot 1.810.000 EUR",
   accreditation_budget_class_6: "Klasse 6: tot 3.225.000 EUR",
   accreditation_budget_class_7: "Klasse 7: tot 5.330.000 EUR",
   accreditation_budget_class_8: "Klasse 8: meer dan 5.330.000 EUR",
   accreditation_P1:"Elektrische installaties in gebouwen, inbegrepen het installeren van stroomaggregaten, alarmtoestellen tegen brand- en diefstal, telecommunicatie in en om gebouwen en voorzieningen voor gemengde telefonie",
   accreditation_P2:"Elektrische en elektromechanische installaties van kunstwerken en nijverheidsinrichtingen, en elektrische buiteninstallaties",
   accreditation_T6:"Slachthuisinrichtingen",
   accreditation_D11:"Pleister- en raapwerk",
   accreditation_P3:"Installaties van bovengrondse elektriciteitsleidingen",
   accreditation_D10:"Tegelwerk",
   accreditation_P4:"Elektrische installaties van haveninrichtingen",
   accreditation_D13:"Verfwerk",
   accreditation_L1:"Leidingen plaatsen",
   accreditation_D12:"Bedekkingen die niet in metaal of asfalt zijn",
   accreditation_L2:"Pomp- en turbinestations uitrusten",
   accreditation_D15:"Parketwerk",
   accreditation_D14:"Glazenmakerswerk",
   accreditation_D17:"Centrale verwarming, thermische installaties",
   accreditation_H1:"Spoorstaven lassen",
   accreditation_D16:"Sanitaire installaties en gasverwarmingsinstallaties met individuele toestellen",
   accreditation_H2:"Stroomdraden plaatsen",
   accreditation_D18:"Ventilatie, luchtverwarming en airconditioning",
   accreditation_D1:"Ruwbouwwerken en onder kap brengen van gebouwen",
   accreditation_D4:"Geluids- en warmte-isolatie, lichte scheidingswanden, valse plafonds en blinde vloeren, al dan niet geprefabriceerd",
   accreditation_D5:"Timmerwerk, houten spanten en trappen",
   accreditation_D6:"Marmer- en steenhouwerswerk",
   accreditation_D7:"Smeedwerk",
   accreditation_D8:"Dakbedekkingen in asfaltproducten (of gelijkaardige) en dichtingswerken",
   accreditation_D20:"Metalen schrijnwerk",
   accreditation_D22:"Metalen dakbedekkingen en zinkwerk",
   accreditation_D21:"Gevels reinigen en opknappen",
   accreditation_M1:"Elektronische uitrustingen met industriële of hoge frequentie, voedingsstations inbegrepen",
   accreditation_D24:"Restauratie van monumenten",
   accreditation_D23:"Restauratie door ambachtslieden",
   accreditation_D25:"Muur- en vloerbekledingen met uitzondering van marmer-, parket- en tegelwerk",
   accreditation_D29:"Vloerdeklagen en bekleding van industriële vloeren",
   accreditation_E1:"Moerriolen",
   accreditation_E2:"Paalfunderingen, dam- en diepwanden",
   accreditation_E4:"Horizontale doorpersingen van onderdelen van kunstwerken",
   accreditation_A1:"Schepen lichten en wrakken opruimen",
   accreditation_N1:"Liften, goederenliften, roltrappen en roltapijten",
   accreditation_N2:"Pneumatisch, mechanisch… vervoer langs kokers en buizen van voorwerpen, documenten of goederen",
   accreditation_F1:"Montage- en demontagewerken (zonder leveringen)",
   accreditation_F2:"Metalen draagstructuren bouwen",
   accreditation_F3:"Industrieel schilderwerk",
   accreditation_B1:"Waterlopen ruimen",
   accreditation_S1:"Openbare telefoon- en telegraafuitrustingen en databeheer",
   accreditation_S2:"Uitrustingen voor afstandsbediening, -controle en -meting",
   accreditation_S3:"Uitrustingen voor radio- en televisieuitzendingen , radar- en antenne-installaties",
   accreditation_A:"Baggerwerken",
   accreditation_S4:"Uitrustingen voor informatieverwerking en procesregeling",
   accreditation_B:"Waterbouwkundige werken",
   accreditation_C:"Wegenbouwkundige werken",
   accreditation_D:"Bouwwerken",
   accreditation_E:"Burgerlijke bouwkunde",
   accreditation_F:"Metaalconstructies",
   accreditation_K1:"Uitrustingen van kunstwerken en industriële mechanica",
   accreditation_G:"Grondwerken",
   accreditation_K2:"Overladings- en hijstoestellen (kranen, rolbruggen…) installeren",
   accreditation_H:"Spoorwerken",
   accreditation_K3:"Oleomechanische uitrustingen",
   accreditation_G1:"Borings- en sonderingswerken en injecties",
   accreditation_K:"Mechanische uitrustingen",
   accreditation_G2:"Draineerwerken",
   accreditation_L:"Hydromechanische uitrustingen",
   accreditation_G3:"Beplantingen",
   accreditation_M:"Elektronische uitrustingen",
   accreditation_G4:"Speciale bekledingen voor sportvelden",
   accreditation_N:"Transportinstallaties in gebouwen",
   accreditation_C1:"Gewone rioleringswerken",
   accreditation_G5:"Afbraakwerken",
   accreditation_C2:"Watervoorziening en leidingen leggen",
   accreditation_C3:"Niet-elektrische verkeerstekens langs verbindingswegen, niet-elektrische veiligheidsinrichtingen, afsluitingen en schermen",
   accreditation_C5:"Bitumineuze verhardingen en bestrijkingen",
   accreditation_S:"Uitrustingen voor telecommunicatie",
   accreditation_C6:"Sterkstroom- en telecommunicatiekabels in sleuven leggen, zonder aaneenkoppeling",
   accreditation_C7:"Horizontale doorpersingen van buizen voor kabels en leidingen",
   accreditation_U:"Installaties voor huisvuilverwerking",
   accreditation_V:"Installaties voor waterzuivering",
   accreditation_T2:"Bliksemafleiders, ontvangstantennes",
   accreditation_T3:"Koelinstallaties",
   accreditation_T4:"Uitrustingen voor wasserijen en grote keukens",
   accreditation_P:"Elektrische installaties",

};
