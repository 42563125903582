import { ResourceKey } from "i18next";

export const DetailsSideBar: ResourceKey = {
	share: "Share",
	shareThisTender: "Share this Tender",
	alsoShareWithMe: "also share with me",
	isGroupMail: "Send as group email",
	tenderHasBeenSharedWith: "This tender was shared with",
	tenderHasAlreadyBeenSharedWith: "Already shared with",
	viaEmail: "Via e-mail",
	emailAddress: "E-mail Address(es)",
	addAMessage: "Add a Message",
	removeLead: "Remove from shortlist",
	makeLead: "Add to shortlist",
	follow: "Follow",
	unfollow: "Unfollow",
	undeleteOpportunity: "Undo delete",
	deleteOpportunity: "Delete opportunity",
	label: "Label",
	languages: "Languages",
	language: "Language",
	downloadFullProject: "Download full project",
	match: "Match",
	addToBesixForce: "Add to BesixForce",
	goToBesixForce: "BesixForce project",
	showSimilarTenders: "Similar tenders",
};
