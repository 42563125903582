import React, {useEffect, useState} from "react";
import styles from "./signup-accelerated.module.scss";
import {useAppDispatch} from "../../../app/hooks";
import {updateToken} from "../../../hooks/slices/loginSlice";
import {showCustomErrorPopup} from "../../../hooks/slices/snaccSlice";
import {getI18n, useTranslation} from "react-i18next";
import {Form} from "../../../components/form/Form";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {usePostSignupStartMutation} from "../../../hooks/slices/signupSlice";
import {EmailUtil} from "../../../utils/email";
import {ValidatedInput} from "../../../components/validatedInput/validatedInput";
import {persistor} from "../../../app/store";
import {Loader} from "../../../components/loader";
import {useGetAllPlanTemplatesQuery} from "../../../hooks/slices/currentPlanSlice";
import {PasswordUtil} from "../../../utils/password";
import {PlanUtil} from "../../../utils/plans";
import {Duration} from "../../../types/tender";
import {useSignupErrorHandler} from "../SignupErrorHandler";
import {useNavigateToOpportunitiesIfSignupAlreadyFinished} from "../SignupFinishedHandler";
import {PrivacyPolicyOverlay, TermsAndConditionsOverlay} from "../start";
import {useGetTenderPreviewQuery} from "../../../hooks/slices/tenderDetailsSlice";
import {TenderDetailsPage} from "../../tenderDetails/main/TenderDetailsPage";
import {ErrorPage} from "../../error";

export const SignupAccelerated: React.FC = () => {
    useNavigateToOpportunitiesIfSignupAlreadyFinished();
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    let tenderUuid = searchParams.get("tenderUuid");
    let vatNumber = searchParams.get("vatNumber");
    let companyUuid = searchParams.get("companyUuid");
    const [postClient, {data: signupStartResponse, isSuccess, isError, error}] = usePostSignupStartMutation();
    const {data: availablePlanTemplates, isLoading} = useGetAllPlanTemplatesQuery();
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [highlightEmptyFields, setHighlightEmptyFields] = useState(false);
    const [highlightInvalidFields, setHighlightInvalidFields] = useState(false);
    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(false);
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
    const {t} = useTranslation();
    // on submission of the login form, we call the postClient trigger to
    // submit the client data to nighteyes and wait for a JWT in return
    const onSubmit = (
        firstName: string,
        lastName: string,
        email: string,
        password: string,
        confirmPassword: string
    ) => {
        setHighlightEmptyFields(false);
        setHighlightInvalidFields(false);
        setPasswordMatch(true);
        if (firstName === "" || lastName === "" || email === "" || password === "" || confirmPassword === "") {
            setHighlightEmptyFields(true);
            dispatch(showCustomErrorPopup(t("intake.fill_in_all_fields")));
        } else if (!EmailUtil.emailAddressIsValid(email)) {
            setHighlightInvalidFields(true);
            dispatch(showCustomErrorPopup(t("intake.invalid_email")));
        } else if (PasswordUtil.passwordIsInvalid(password)) {
            setHighlightInvalidFields(true);
            dispatch(showCustomErrorPopup(t("intake.password_is_invalid")));
        } else if (password !== confirmPassword) {
            setPasswordMatch(false);
            dispatch(showCustomErrorPopup(t("intake.passwords_dont_match")));
        } else if (passwordMatch && !highlightEmptyFields && tenderUuid) {
            let locale: string = getI18n().language;
            if (vatNumber) postClient({
                firstName,
                lastName,
                email,
                password,
                locale,
                governmentClient: false,
                vatNumber: vatNumber,
                tenderUuid: tenderUuid
            });
            if (companyUuid) postClient({
                firstName,
                lastName,
                email,
                password,
                locale,
                governmentClient: false,
                companyUuid: companyUuid,
                tenderUuid: tenderUuid
            });
            setShowLoader(true);
        }
    };
    const navigateOrShowPopup = useSignupErrorHandler();

    useEffect(() => {
        if (isSuccess && signupStartResponse) {
            setShowLoader(false);
            // pass the data from the successful login to the redux store
            persistor
                .purge() // resets the state store, in case there is already another client in the store
                .then(() => {
                    persistor.flush();
                })
                .then(() => dispatch(updateToken(signupStartResponse.token)));
            if (signupStartResponse.signupComplete && tenderUuid) {
                navigate("/tender/" + tenderUuid + "?add_to_shortlist=true");
            }
        } else if (isError && error) {
            setShowLoader(false);
            // show an error on the snackbar with a failed login
            navigateOrShowPopup(error);
        }
    }, [isSuccess, signupStartResponse, isError, error, dispatch]);

    if (isLoading || availablePlanTemplates == null || availablePlanTemplates.planTemplates == null) return <></>;
    if (!tenderUuid || (!vatNumber && !companyUuid)) return <ErrorPage/>
    return (
        <>
            {showLoader && <Loader/>}
            {showPrivacyPolicy && !showTermsAndConditions && <div className='layout-main-banner'>
                <div className='container'><PrivacyPolicyOverlay setOverlay={setShowPrivacyPolicy}/></div>
            </div>}
            {showTermsAndConditions && !showPrivacyPolicy && (<div className='layout-main-banner'>
                <div className='container'><TermsAndConditionsOverlay setOverlay={setShowTermsAndConditions}/></div>
            </div>)}
            {!showPrivacyPolicy && !showTermsAndConditions &&
                <>
                    <RegisterForm
                        onSubmit={onSubmit}
                        passwordMatch={passwordMatch}
                        highlightEmptyFields={highlightEmptyFields}
                        highlightInvalidFields={highlightInvalidFields}
                        trialPlanDuration={PlanUtil.getPlanDuration("Trial", availablePlanTemplates.planTemplates)}
                        showPrivacyPolicy={() => setShowPrivacyPolicy(true)}
                        showTermsAndConditions={() => setShowTermsAndConditions(true)}
                    />
                    <TenderPreview tenderUuid={tenderUuid} language={'NL'}/>
                </>}
        </>
    );
};


interface RegisterFormProps {
    onSubmit: (firstName: string, lastName: string, email: string, password: string, confirmPassword: string) => void;
    passwordMatch: boolean;
    highlightEmptyFields: boolean;
    highlightInvalidFields: boolean;
    trialPlanDuration: Duration;
    showTermsAndConditions: () => void;
    showPrivacyPolicy: () => void;
}

const RegisterForm: React.FC<RegisterFormProps> = ({
                                                       onSubmit,
                                                       passwordMatch,
                                                       highlightEmptyFields,
                                                       highlightInvalidFields,
                                                       trialPlanDuration,
                                                       showTermsAndConditions,
                                                       showPrivacyPolicy
                                                   }) => {
    const [searchParams] = useSearchParams();
    let emailParam = searchParams.get("email");
    let nameParam = searchParams.get("name");
    const {t} = useTranslation();

    const [firstName, setFirstName] = useState(nameParam != null ? nameParam.split(" ")[0] : "");
    const onFirstNameChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setFirstName(e.currentTarget.value);
    };

    const [lastName, setLastName] = useState(
        nameParam != null && nameParam.split(" ").length > 1 ? nameParam.split(" ")[1] : ""
    );
    const onLastNameChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setLastName(e.currentTarget.value);
    };

    const [email, setEmail] = useState(emailParam != null ? emailParam : "");
    const onEmailChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setEmail(e.currentTarget.value);
    };

    const [password, setPassword] = useState("");
    const onPasswordChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setPassword(e.currentTarget.value);
    };

    const [confirmPassword, setConfirmPassword] = useState("");
    const onConfirmPasswordChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setConfirmPassword(e.currentTarget.value);
    };

    const [termsAgree, setTermsAgree] = useState(false);
    const onTermsAgreeChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setTermsAgree(e.currentTarget.checked);
    };

    return (
        <div>
            <div>
                <div className={styles["container"]}>

                    <div className={styles["layout-top-alignment"]}>
                        <div className={styles["register-box"]}>
                            <Form
                                onSubmit={() => {
                                    onSubmit(firstName, lastName, email, password, confirmPassword);
                                }}
                            >
                                <div className={styles["input-first-grid"]}>
                                    <div className={styles["input-first-grid-items"]}>
                                        <ValidatedInput
                                            value={firstName}
                                            placeholder={t("intake.first_name")}
                                            isInvalid={firstName === ""}
                                            highlightIfInvalid={highlightEmptyFields}
                                            onChange={onFirstNameChange}
                                            testId={"intake-first-name"}
                                        />
                                    </div>
                                    <div className={styles["input-first-grid-items"]}>
                                        <ValidatedInput
                                            value={lastName}
                                            placeholder={t("intake.last_name")}
                                            isInvalid={lastName === ""}
                                            highlightIfInvalid={highlightEmptyFields}
                                            onChange={onLastNameChange}
                                            testId={"intake-second-name"}
                                        />
                                    </div>
                                </div>
                                <div className={styles["input-bottom-alignment"]}>
                                    <ValidatedInput
                                        value={email}
                                        placeholder={t("intake.email")}
                                        isInvalid={!EmailUtil.emailAddressIsValid(email) || email === ""}
                                        highlightIfInvalid={highlightInvalidFields || highlightEmptyFields}
                                        onChange={onEmailChange}
                                        testId={"intake-email"}
                                    />
                                </div>
                                <div className={styles["input-bottom-alignment"]}>
                                    <ValidatedInput
                                        value={password}
                                        placeholder={t("intake.enter_password")}
                                        isInvalid={!passwordMatch || PasswordUtil.passwordIsInvalid(password)}
                                        highlightIfInvalid={!passwordMatch || highlightEmptyFields || highlightInvalidFields}
                                        onChange={onPasswordChange}
                                        type={"password"}
                                        testId={"intake-password"}
                                    />
                                </div>
                                <div className={styles["input-bottom-alignment"]}>
                                    <ValidatedInput
                                        value={confirmPassword}
                                        placeholder={t("intake.confirm_password")}
                                        isInvalid={!passwordMatch || confirmPassword === ""}
                                        highlightIfInvalid={!passwordMatch || highlightEmptyFields}
                                        onChange={onConfirmPasswordChange}
                                        type={"password"}
                                        testId={"intake-password-confirm"}
                                    />
                                    {PasswordUtil.passwordIsInvalid(password) && highlightInvalidFields && (
                                        <div className={styles["input-message"]}>
                                            <p>{t("intake.valid_password_description")}</p>
                                        </div>
                                    )}
                                </div>
                                <div className={styles["child-text-all-alignment"]}>
                                    {<p>{"* " + t("intake.trial_message_accelerated")}</p>}
                                </div>

                                <div className={styles["checkbox-text-alignment"]}>
                                    <div>
                                        <div className={styles["child-product"]}>
                                            <input type="checkbox" id="terms" data-testid={"intake-terms"}
                                                   onChange={onTermsAgreeChange}/>
                                            <label htmlFor="terms">
                          <span>
                            {t("intake.agree")}
                              <a onClick={() => showPrivacyPolicy()}>{t("intake.privacy_policy")}</a>
                              {t("intake.and")}
                              <a onClick={() => showTermsAndConditions()}>{t("intake.terms")}</a>
                          </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles["register-button"]}>
                                    <div className={termsAgree ? "fill-button" : "fill-button disabled"}>
                                        <button id="register" data-testid={"signup-register-button"} type="submit"
                                                disabled={!termsAgree}>
                                            {t("intake.register")}
                                        </button>
                                    </div>
                                </div>
                                <div className={styles["have-a-account"]}>
                                    <p>
                                        {t("intake.already_account")} <Link to="/login">{t("intake.login")}</Link>
                                    </p>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const TenderPreview: React.FC<{ tenderUuid: string; language: string }> = ({tenderUuid, language}) => {
    const {data, isFetching, isSuccess, isError, error} = useGetTenderPreviewQuery({
        uuid: tenderUuid,
        tenderLanguage: language,
    });
    if (isError) return <div>Error</div>;
    if (isFetching || data == null) return <Loader/>;
    return (
        <div className={styles["tender-preview"]}>
            <TenderDetailsPage tender={data} languageIso={language}/>
        </div>
    );
};