import {ResourceKey} from "i18next";

export const Countries: ResourceKey = {
    AD:"Andorra",
    AE:"Verenigde Arabische Emiraten",
    AF:"Afghanistan",
    AG:"Antigua en Barbuda",
    AI:"Anguilla",
    AL:"Albanië",
    AM:"Armenië",
    AO:"Angola",
    AQ:"Antarctica",
    AR:"Argentinië",
    AS:"Amerikaans-Samoa",
    AT:"Oostenrijk",
    AU:"Australië",
    AW:"Aruba",
    AX:"Åland",
    AZ:"Azerbeidzjan",
    BA:"Bosnië en Herzegovina",
    BB:"Barbados",
    BD:"Bangladesh",
    BE:"België",
    BF:"Burkina Faso",
    BG:"Bulgarije",
    BH:"Bahrein",
    BI:"Burundi",
    BJ:"Benin",
    BL:"Saint-Barthélemy",
    BM:"Bermuda",
    BN:"Brunei",
    BO:"Bolivia",
    BQ:"Bonaire, Sint-Eustatius en Saba",
    BR:"Brazilië",
    BS:"Bahama’s",
    BT:"Bhutan",
    BV:"Bouveteiland",
    BW:"Botswana",
    BY:"Belarus",
    BZ:"Belize",
    CA:"Canada",
    CC:"Cocoseilanden",
    CD:"Democratische Republiek Congo",
    CF:"Centraal-Afrikaanse Republiek",
    CG:"Congo",
    CH:"Zwitserland",
    CI:"Ivoorkust",
    CK:"Cookeilanden",
    CL:"Chili",
    CM:"Kameroen",
    CN:"China",
    CO:"Colombia",
    CP:"Clipperton",
    CR:"Costa Rica",
    CU:"Cuba",
    CV:"Kaapverdië",
    CW:"Curaçao",
    CX:"Christmaseiland",
    CY:"Cyprus",
    CZ:"Tsjechië",
    DE:"Duitsland",
    DJ:"Djibouti",
    DK:"Denemarken",
    DM:"Dominica",
    DO:"Dominicaanse Republiek",
    DZ:"Algerije",
    EC:"Ecuador",
    EE:"Estland",
    EG:"Egypte",
    EH:"Westelijke Sahara",
    ER:"Eritrea",
    ES:"Spanje",
    ET:"Ethiopië",
    FI:"Finland",
    FJ:"Fiji",
    FK:"Falklandeilanden",
    FM:"Micronesia",
    FO:"Faeröer",
    FR:"Frankrijk",
    GA:"Gabon",
    GD:"Grenada",
    GE:"Georgië",
    GF:"Frans-Guyana",
    GG:"Guernsey",
    GH:"Ghana",
    GI:"Gibraltar",
    GL:"Groenland",
    GM:"Gambia",
    GN:"Guinee",
    GP:"Guadeloupe",
    GQ:"Equatoriaal-Guinea",
    GR:"Griekenland",
    GS:"Zuid-Georgia en de Zuidelijke Sandwicheilanden",
    GT:"Guatemala",
    GU:"Guam",
    GW:"Guinee-Bissau",
    GY:"Guyana",
    HK:"Hongkong",
    HM:"Heard en McDonaldeilanden",
    HN:"Honduras",
    HR:"Kroatië",
    HT:"Haïti",
    HU:"Hongarije",
    ID:"Indonesië",
    IE:"Ierland",
    IL:"Israël",
    IM:"Eiland Man",
    IN:"India",
    IO:"Brits Indische Oceaanterritorium",
    IQ:"Irak",
    IR:"Iran",
    IS:"IJsland",
    IT:"Italië",
    JE:"Jersey",
    JM:"Jamaica",
    JO:"Jordanië",
    JP:"Japan",
    KE:"Kenia",
    KG:"Kirgizië",
    KH:"Cambodja",
    KI:"Kiribati",
    KM:"Comoren",
    KN:"Saint Kitts en Nevis",
    KP:"Noord-Korea",
    KR:"Zuid-Korea",
    KW:"Koeweit",
    KY:"Kaaimaneilanden",
    KZ:"Kazachstan",
    LA:"Laos",
    LB:"Libanon",
    LC:"Saint Lucia",
    LI:"Liechtenstein",
    LK:"Sri Lanka",
    LR:"Liberia",
    LS:"Lesotho",
    LT:"Litouwen",
    LU:"Luxemburg",
    LV:"Letland",
    LY:"Libië",
    MA:"Marokko",
    MC:"Monaco",
    MD:"Moldavië",
    ME:"Montenegro",
    MF:"Saint-Martin",
    MG:"Madagaskar",
    MH:"Marshalleilanden",
    MK:"Noord-Macedonië",
    ML:"Mali",
    MM:"Birma/Myanmar",
    MN:"Mongolië",
    MO:"Macau",
    MP:"Noordelijke Marianen",
    MQ:"Martinique",
    MR:"Mauritanië",
    MS:"Montserrat",
    MT:"Malta",
    MU:"Mauritius",
    MV:"Maldiven",
    MW:"Malawi",
    MX:"Mexico",
    MY:"Maleisië",
    MZ:"Mozambique",
    NA:"Namibië",
    NC:"Nieuw-Caledonië",
    NE:"Niger",
    NF:"Norfolk",
    NG:"Nigeria",
    NI:"Nicaragua",
    NL:"Nederland",
    NO:"Noorwegen",
    NP:"Nepal",
    NR:"Nauru",
    NU:"Niue",
    NZ:"Nieuw-Zeeland",
    OM:"Oman",
    PA:"Panama",
    PE:"Peru",
    PF:"Frans-Polynesië",
    PG:"Papoea-Nieuw-Guinea",
    PH:"Filipijnen",
    PK:"Pakistan",
    PL:"Polen",
    PM:"Saint-Pierre en Miquelon",
    PN:"Pitcairneilanden",
    PR:"Puerto Rico",
    PS:"Palestijnse gebieden",
    PT:"Portugal",
    PW:"Palau",
    PY:"Paraguay",
    QA:"Qatar",
    RE:"Réunion",
    RO:"Roemenië",
    RS:"Servië",
    RU:"Rusland",
    RW:"Rwanda",
    SA:"Saudi-Arabië",
    SB:"Salomonseilanden",
    SC:"Seychellen",
    SD:"Sudan",
    SE:"Zweden",
    SG:"Singapore",
    SH:"Sint-Helena",
    SI:"Slovenië",
    SJ:"Svalbard en Jan Mayen",
    SK:"Slowakije",
    SL:"Sierra Leone",
    SM:"San Marino",
    SN:"Senegal",
    SO:"Somalië",
    SR:"Suriname",
    SS:"Zuid-Sudan",
    ST:"Sao Tomé en Principe",
    SV:"El Salvador",
    SX:"Sint-Maarten",
    SY:"Syrië",
    SZ:"Swaziland",
    TC:"Turks- en Caicoseilanden",
    TD:"Tsjaad",
    TF:"Franse Zuidelijke en Zuidpoolgebieden",
    TG:"Togo",
    TH:"Thailand",
    TJ:"Tadzjikistan",
    TK:"Tokelau-eilanden",
    TL:"Oost-Timor",
    TM:"Turkmenistan",
    TN:"Tunesië",
    TO:"Tonga",
    TR:"Turkije",
    TT:"Trinidad en Tobago",
    TV:"Tuvalu",
    TW:"Taiwan",
    TZ:"Tanzania",
    UA:"Oekraïne",
    UG:"Uganda",
    UK:"Verenigd Koninkrijk",
    UM:"verafgelegen eilandjes van de Verenigde Staten",
    US:"Verenigde Staten",
    UY:"Uruguay",
    UZ:"Oezbekistan",
    VA:"Vaticaanstad",
    VC:"Saint Vincent en de Grenadines",
    VE:"Venezuela",
    VG:"Britse Maagdeneilanden",
    VI:"Amerikaanse Maagdeneilanden",
    VN:"Vietnam",
    VU:"Vanuatu",
    WF:"Wallis en Futuna",
    WS:"Samoa",
    YE:"Jemen",
    YT:"Mayotte",
    ZA:"Zuid-Afrika",
    ZM:"Zambia",
    ZW:"Zimbabwe",

}