import { ResourceKey } from "i18next";

export const Login: ResourceKey = {
	welcome_back: "Welcome back!",
	welcome_back_message: "Please enter your credentials",
	forgot_password: "Forgot your password?",
	forgot_password_message: "Enter your TenderWolf email address.",
	send: "Send",
	sent_message: "Email sent if email address is registered with us",
	reset_password: "Reset your password",
	back_to_login: "Back to log in",
	no_account: "No account?",
	register: "Register",
	logIn: "Log in",
	wrong_credentials: "Incorrect username or password",
};