import "./tender-details.scss";
import { GetDetailsParams, useGetTenderDetailsQuery } from "../../../hooks/slices/tenderDetailsSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import React from "react";
import { Tender } from "../../../types/tender";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { TenderDetailsPage } from "./TenderDetailsPage";
import { ErrorPage } from "../../error";
import {UserLanguage} from "../../../hooks/raiseUser";
import {useTranslation} from "react-i18next";


export const TenderDetailsView: React.FC = () => {
  let queryParameters = QueryParameters();
  let {i18n} = useTranslation();
  let languageIso = queryParameters.tenderLanguage ? queryParameters.tenderLanguage : UserLanguage(i18n.language);
  const { data, isFetching, isSuccess, isError, error } = useGetTenderDetailsQuery(queryParameters ?? skipToken);
  if (isFetching) {
    return <DetailsPageSkeleton />;
  } else if (isSuccess && data) {
    return <TenderDetails tender={data} languageIso={languageIso}/>;
  } else if(isError && error){
    //todo a fetch error seems to be thrown here occasionally, possibly cors related
    if (error && "status" in error && error.status === "FETCH_ERROR") {return <DetailsPageSkeleton/>}
    return <ErrorPage error={error}/>
  } else return <DetailsPageSkeleton />;
};

export const QueryParameters = (): GetDetailsParams => {
  let params = useParams();
  let uuid = params.uuid || "";//TODO: throw error if uuid is undefined?
  const [searchParams] = useSearchParams();
  let language = searchParams.get("language");
  return {
    uuid: uuid,
    tenderLanguage: language ? language : undefined,
  };
};

export interface TenderDetailsProps {
  tender: Tender;
  languageIso: string;
}

const TenderDetails: React.FC<TenderDetailsProps> = ({ tender, languageIso }) => {
  return (
      <>
        <TenderDetailsPage tender={tender} languageIso={languageIso}/>
      </>
  );
};

export const DetailsPageSkeleton = () => {
  return (
    <section style={{margin: '5% 5% 5% 16%'}}>
      {/*<SkeletonSection width={"90%"} height={4} />*/}
        <div style={{width: "85%", display: "flex", justifyContent: "space-between"}}>
            <SkeletonSection width={"65%"} height={400} />
            <SkeletonSection width={"15%"} height={250}/>
        </div>
        <div style={{width: "85%", display: "flex", flexDirection: "column"}}>
            <SkeletonSection width={"100%"} height={300} />
            <SkeletonSection width={"100%"} height={300} />
            <SkeletonSection width={"100%"} height={300} />
        </div>
    </section>
  );
};

interface SkeletonSectionProps {
  width: string;
  height: number;
}

export const SkeletonSection: React.FC<SkeletonSectionProps> = ({ width, height }) => {
  return <div className={"skeleton-wave-background"} style={{height: height+ "px", width:width, borderRadius: '8px', margin: '0 0 36px 0'}}></div>;
};
