import React, { useState } from "react";
import './footer.scss';
import FooterLogo from '../../assets/logo/black-logo-row.svg';
import {useTranslation} from "react-i18next";
import {
    EmailWhiteIcon,
    LinkedInIcon,
    LocationWhiteIcon,
    PhoneWhiteIcon
} from "../icons";
import {RaiseUser} from "../../hooks/raiseUser";

export default function Footer() {
  const {t} = useTranslation();
  const userInStore = RaiseUser();
  const getHelpLink = () => {
    if (userInStore.user?.locale != null) {
      let language = userInStore.user.locale;
      if (language.startsWith("fr")) return "https://fr.tenderwolf.com/";
      if (language.startsWith("en")) return "https://en.tenderwolf.com/";
    } return "https://tenderwolf.com/";
  }

    return (
      <div>
        <footer>
          <div className="container">
            <div className="footer-grid">
              <div className="footer-grid-items">
                <div className="footer-logo svg-black-to-white">
                  <img src={FooterLogo} alt="FooterLogo" />
                </div>
                <p>Smart Tender Management</p>
                {/*<p>*/}
                {/*  <a href="https://tenderwolf.com/helpdesk/starten-met-teclearnderwolf">Helpdesk</a>*/}
                {/*</p>*/}
                {/*<p>*/}
                {/*  <a href="https://tenderwolf.com/helpdesk/begrippenlijst-overheidsopdrachten">Begrippenlijst</a>*/}
                {/*</p>*/}

                {/*<p>*/}
                {/*  <a href="https://tenderwolf.com/blog">Blog</a>*/}
                {/*</p>*/}
                <div className="follow-us">
                  <h2>{t("footer.follow_us")}</h2>
                  <div className="social-alignment">
                    <a href="https://www.linkedin.com/company/tenderwolf/">
                      <div>
                        <LinkedInIcon />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="footer-grid-items">
                <div className="footer-sub-grid">
                  <div className="footer-sub-grid-items">
                    <h3>{t("footer.help")}</h3>
                    <ul>
                        <li><a href={getHelpLink() + "helpdesk/starten-met-tenderwolf"}>{t("footer.helpdesk")}</a></li>
                        <li><a href={getHelpLink() + "helpdesk/begrippenlijst-overheidsopdrachten"}>{t("footer.list_of_concepts")}</a></li>
                        <li><a href={getHelpLink() + "blog"}>{t("footer.blog")}</a></li>
                    </ul>
                  </div>
                  <div className="footer-sub-grid-items">
                    <h3>{t("footer.legal")}</h3>
                    <ul>
                      <li><a href={"/terms-and-conditions"}>{t("footer.terms")}</a></li>
                      <li><a href={"/privacy-policy"}>{t("footer.privacy_policy")}</a></li>
                    </ul>
                  </div>
                  <div className="footer-sub-grid-items">
                    <h3>{t("footer.contact_us")}</h3>
                    <div className="icon-text-alignment">
                      <div>
                        <LocationWhiteIcon />
                      </div>
                      <div>
                        <span>
                          Tichelrei 82, 9000 {t("footer.ghent")}
                          <br />
                          {t("footer.auguste_reyerslaan")} 213, 1030 {t("footer.brussels")}
                        </span>
                      </div>
                    </div>
                    <div className="icon-text-alignment">
                      <div>
                        <PhoneWhiteIcon />
                      </div>
                      <div>
                        <span>+32 474 57 12 91</span>
                      </div>
                    </div>
                    <div className="icon-text-alignment">
                      <div>
                        <EmailWhiteIcon />
                      </div>
                      <div>
                        <span>info@tenderwolf.com</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="copy-right-text">
              <span>© {new Date().getFullYear()} TenderWolf</span>
            </div>
          </div>
        </footer>
      </div>
    );
}
