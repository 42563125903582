import React from "react";
import {SettingTab} from "../../consts/settingTabs";
import {useTranslation} from "react-i18next";

export interface SettingSidebarItemProps {
    tab: SettingTab,
    activeTab: SettingTab,
    setTab: (tab: SettingTab) => void,
}


export const SettingSidebarItem: React.FC<SettingSidebarItemProps> = ({tab, activeTab, setTab}) => {
    const {t} = useTranslation();
    return <li
        className={activeTab === tab ? "active-tab" : ""}
        onClick={() => setTab(tab)}
        data-testid={"setting-sidebar-item-"+tab}
    >
        {t("settings."+tab)}
    </li>
}

