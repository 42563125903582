import React, {KeyboardEventHandler, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {useGetShareMailsForTenderQuery, usePostShareMailMutation} from "../../../hooks/slices/shareMailSlice";
import {showCustomErrorPopup, showErrorPopup, updateSnacc} from "../../../hooks/slices/snaccSlice";
import {EmailUtil} from "../../../utils/email";
import {Loader} from "../../../components/loader";
import {CloseIcon} from "../../../components/icons";
import {DebounceDatalistInput, DropdownElement} from "../../../components/debounceInput/DebounceTextInput";

interface SharePopupProps {
    closeSharePopup: () => void;
    tenderUuid: string;
    language: string;
}

export const SharePopup: React.FC<SharePopupProps> = ({closeSharePopup, tenderUuid, language}) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const {
        data: history,
        isLoading: historyIsLoading,
        isSuccess: historyIsSuccess,
        isError: historyIsError,
        error: historyError, refetch: refetchHistory
    } = useGetShareMailsForTenderQuery({tenderUuid: tenderUuid});
    useEffect(() => {
        if (historyIsError && historyError) dispatch(showErrorPopup(historyError));
    }, [historyIsError, historyError]);
    const [postShareMail, {
        data: newShareMailHistory,
        isSuccess: shareMailHasBeenSent,
        isLoading: shareMailIsBeingSent,
        isError: postIsError,
        error: postError
    }] = usePostShareMailMutation();
    useEffect(() => {
        if (postIsError && postError) dispatch(showErrorPopup(postError));
    }, [postIsError, postError]);
    const userEmailInStore: string | undefined = useAppSelector((state) => state.user.email);
    const [sendToSelf, setSendToSelf] = useState(false);
    const [isGroupEmail, setIsisGroupEmail] = useState(true);
    // const [currentAddressee, setCurrentAddressee] = useState<string>("");
    const [recipients, setRecipients] = useState<string[]>([]);
    const [shareMailAlreadySentTo, setShareMailAlreadySentTo] = useState<string[]>([]);
    useEffect(() => {
        if (historyIsSuccess && history) {
            setShareMailAlreadySentTo(history.previousAddresseesForTender);
            setAddresseeDataList(history.allAddressees);
        }
    }, [history, historyIsSuccess]);
    useEffect(() => {
        if (shareMailHasBeenSent && newShareMailHistory) {
            closeSharePopup();
            setShareMailAlreadySentTo(newShareMailHistory.previousAddresseesForTender);
            dispatch(updateSnacc({
                message: t("detailsSideBar.tenderHasBeenSharedWith") + " " + recipients.join(", "),
                severity: "success",
                open: true
            }));
            setRecipients([]);
            // setCurrentAddressee("");
            refetchHistory(); //otherwise opening share again will show the old values
        }
    }, [shareMailHasBeenSent, newShareMailHistory]);

    const addAddresseesToList = (addressee: string) => {
        let newRecipients: string[] = [...recipients];
        let invalidRecipients: string[] = [];
        for(const a of addressee.split(',')){
            let recipient = a.trim();
            if(!EmailUtil.emailAddressIsValid(recipient)){
                if(recipient.trim() !== '') invalidRecipients.push(recipient)
            } else if(!newRecipients.includes(recipient)){
                newRecipients.push(recipient);
            }
        }
        setRecipients(newRecipients);
        if(invalidRecipients.length > 0) dispatch(showCustomErrorPopup(t("settings.invalid_email_address") + ": " + invalidRecipients.join(", ")))
    }

    const [addresseeDataList, setAddresseeDataList] = useState<string[]>([]);
    const getDataListDropdownElements = (): DropdownElement[] => {
        let sortedAddressees = [...addresseeDataList]
        return sortedAddressees.sort().map(a => ({value: a, label: a})).slice(0,20);
    }
    const filterAddressees = (filter: string) => {
        setAddresseeDataList(history ? history.allAddressees
            .filter(a => !recipients.includes(a))
            .filter(a => a.includes(filter)) : [])
    }
    const removeAddresseeFromList = (addressee: string) => {
        setRecipients([...recipients.filter(a => a !== addressee)]);
    }
    const [message, setMessage] = useState<string>("");
    const changeMessage: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
        setMessage(e.target.value);
    };
    const sendMail = () => {
        let finalAddressees = [...recipients];
        if (sendToSelf && userEmailInStore) finalAddressees = [...finalAddressees, userEmailInStore]
        let addressesAreValid = true;
        for (const addressee of finalAddressees) {
            if (!EmailUtil.emailAddressIsValid(addressee)) {
                addressesAreValid = false;
                dispatch(showCustomErrorPopup(t("settings.invalid_email_address") + ": " + addressee));
                break;
            }
        }
        if (addressesAreValid) {
            postShareMail({
                tenderUuid: tenderUuid,
                languageIso: language,
                addressees: finalAddressees,
                message: message,
                isGroupEmail:  isGroupEmail
            });
        }
    }

    if (shareMailIsBeingSent) return <Loader/>
    else if (shareMailHasBeenSent) return <></>
    return <>
        <div className="share-publication-modal-wrapper">
            <div className="publication-modal">
                <div className="header-alignment">
                    <p>{t("detailsSideBar.shareThisTender")}</p>
                    <div onClick={closeSharePopup}>
                        <CloseIcon/>
                    </div>
                </div>
                {/*<div className="via-email-content">*/}
                {/*    <p>{t("detailsSideBar.viaEmail")}</p>*/}
                {/*</div>*/}

                <div className="already-shared-with-content">
                    {/*{historyIsLoading && <p>...</p>}*/}
                    {shareMailAlreadySentTo.length > 0 &&
                        <p>{t("detailsSideBar.tenderHasAlreadyBeenSharedWith") + ": " + shareMailAlreadySentTo.join(", ")} </p>}
                </div>

                <div>
                    <label>{t("detailsSideBar.emailAddress")}</label>
                    <span className={'emails-input'} tabIndex={0}>
                        {recipients.map((addressee, index) =>
                            <span className={'emails-input-item'} key={index}>
                                <span>
                                    <span>{addressee}</span>
                                    <span onClick={() => removeAddresseeFromList(addressee)}><CloseIcon/></span>
                                </span>
                            </span>)}
                        <span className={'new-email-input'} >
                            <DebounceDatalistInput search={(f) => filterAddressees(f)}
                                 select={(a) => addAddresseesToList(a)}
                                 blur={(a) => addAddresseesToList(a)}
                                 delay={500} placeHolder={""}
                                 dataList={getDataListDropdownElements()}/>
                        </span>
                        {/*<input className={'new-email-input'} type="text" value={currentAddressee}*/}
                        {/*       onKeyDown={submitOnEnter}*/}
                        {/*       onChange={changeCurrentAddressee} onBlur={addAddresseeToList}></input>*/}
                    </span>
                </div>

                <div className="input">
                    <label>{t("detailsSideBar.addAMessage")}</label>
                    <textarea onChange={changeMessage}></textarea>
                </div>
                <div className="part-all-content-alignment">
                    <div className="part-first-section-alignment">
                        <div className="fill-button">
                            <button onClick={sendMail}>{t("detailsSideBar.share")}</button>
                        </div>
                    </div>
                    <div className="part-second-section-alignment">
                        {recipients.length > (sendToSelf ? 0 : 1) && <div>
                            <input type="checkbox" id="isGroupMailCheckbox" checked={isGroupEmail}
                                   onChange={() => setIsisGroupEmail(!isGroupEmail)}/>
                            <label htmlFor="isGroupMailCheckbox">
                                <span>{t("detailsSideBar.isGroupMail")}</span>
                            </label>
                        </div>}
                          <div className="row">
                            <input type="checkbox" id="sendToSelfCheckbox" checked={sendToSelf}
                                   onChange={() => setSendToSelf(!sendToSelf)}/>
                            <label htmlFor="sendToSelfCheckbox">
                                <span>{t("detailsSideBar.alsoShareWithMe")}</span>
                            </label>
                        </div>
                    </div>

                </div>
                {/*<div className="via-link-text-style">*/}
                {/*    <span>Via Link</span>*/}
                {/*    <div className="input">*/}
                {/*        <input type="text" placeholder="http://tenderwolf.com/" onChange={changeLink} />*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>
    </>
}