import { ResourceKey } from "i18next";

export const Intake: ResourceKey = {
	title_default: "Create new account",
	title_government: "Access to the tender specifications database",
	trial_message_start: "You will start with a free trial period of ",
	trial_message_end_days: " days.",
	trial_message_end_months: " months.",
	trial_message_end_years: " years.",
	trial_plan_message_government: "You can use our specifications database completely free of charge and without obligation.",
	trial_message_accelerated: "You will receive access to a free trial period, no strings attached.", //trial_message_accelerated: "Je krijgt toegang tot een gratis proefperiode van TenderWolf Professional. Volledig vrijblijvend.",
	change_plan_message_default: "You can change your plan at any time. If you do not change it, you will automatically switch to the plan Free after the test period.",
	change_plan_message_government: "Free access is limited to one employee per organization. Organizations wanting access for multiple employees should take out a paid subscription.",
	promotion_plan_message: "This is the fastest, most comprehensive and user-friendly tender specifications database on the Belgian market.",
	first_name: "First name",
	last_name: "Last name",
	email: "Email address",
	enter_password: "Enter password",
	confirm_password: "Confirm password",
	agree: "I agree with ",
	privacy_policy: "the privacy policy ",
	and: "and ",
	terms: "the terms and conditions",
	register: "Register",
	already_account: "Already have an account?",
	email_already_in_use: "Email address already in use",
	fill_in_all_fields: "Fill in all fields",
	passwords_dont_match: "Passwords don't match",
	password_is_invalid: "Password is invalid",
	valid_password_description: "Choose a password that is at least 8 characters long, consists of both lowercase and uppercase letters, and also contains at least one number and one punctuation mark.",
	login: "Log in",
	second_page_title: "Fill in the extra information",
	company_name: "Organization",
	invoice_reference: "Référence de la facture",
	vat_number_default: "Company number",
	vat_number_government: "Company number (if applicable)",
	country: "Country",
	website_default: "Website",
	website_government: "Website (optional)",
	tender_experience: "Has your company already participated in government tenders?",
	next: "Next",
	skip: "Skip",
	yes: "Yes",
	no: "No",
	third_page_title: "Select the countries you are interested in",
	all_publications: "All public tender publications (incl. EU institutions)",
	european_publications_only: "All public tender publications above the European thresholds (incl. EU institutions)",
	fourth_page_title: "Give us some keywords describing the public tenders you are looking for",
	company_description: "Describe your activities for which you are looking public tenders (optional)",
	hardstop_title: "Thank you for your registration!",
    hardstop_text: "Our tender search experts are notified of your request.\n" +
        "Within 6-24 hours we plan to set up your trial account of TenderWolf.\n" +
        "Thank you for your patience.",
	invalid_email: "Invalid email address",
    close: "Close",
	must_select_at_least_one_country: "You need to select at least one country",
	cant_select_more_than_six_countries: "You can't select more than six countries during your trial period",
	address: "Address",
	main_activity: "Main activity",
	starting_date: "Starting date",
	is_subcontractor: "Are you a subcontractor?",
	is_looking_for_subcontractors: "Are you looking for subcontractors?",
	is_looking_for_partners: "Are you looking for partners?",
	is_interested_in_private_contracts: "Are you interested in private contracts?",
	complete_where_needed: "Complete where necessary",
	curated_profiles_title: "Which sectors are you interested in?",
	we_found_this_info_about_you: "We retrieved this information about you",
	answer_these_questions: "Answer these questions",
	i_cant_find_my_sector: "I can't find my sector",
	you_have_accreditations: "We found the following accreditations for your company. Please check whether you want to receive public tenders for all your accreditations (categories and classes).",
	select_regions: "Indicate the regions where you want to execute public tenders",
};