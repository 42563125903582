import { ResourceKey } from "i18next";

export const Features: ResourceKey = {
	update_mail_limited: "opportuniteiten per week",
	update_mail_unlimited: "onbeperkte opportuniteiten",
	update_mail_daily: "dagelijkse update",
	update_mail_weekly: "wekelijkse update",
	country: "land",
	countries: "landen",
	up_to: "tot",
	unlimited_countries: "onbeperkt aantal landen",
	curated_user_profile: "gecureerd selectieprofiel",
	curated_user_profiles: "gecureerde selectieprofielen",
	curated_user_profiles_unlimited: "onbeperkt aantal gecureerde selectieprofielen",
	custom_user_profile: "persoonlijk selectieprofiel",
	custom_user_profiles: "persoonlijke selectieprofielen",
	custom_user_profiles_unlimited: "onbeperkt aantal persoonlijke selectieprofielen",
	advanced_search: "geavanceerd zoeken",
	unlimited_advanced_search: "onbeperkt geavanceerd zoeken",
	days_back: "dagen terug",
	one_year_back: "een jaar terug",
	labels: "labels",
	unlimited_labels: "onbeperkt aantal labels",
	export_to_excel: "exporteren naar excel",
	search_addenda: "zoeken in addenda",
}