import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PURGE} from "redux-persist";
import {NighteyesJWT} from "../../types/login";
import {ParseJwt} from "../../utils/jwt";
import {apiSlice} from "./apiSlice";

interface loginState {
  rawToken?: string;
  parsedToken?: NighteyesJWT;
  userID?: string;
  masquerading?: boolean
}

const initialState: loginState = {};

export const loginSlice = createSlice({
  initialState,
  name: "login",
  reducers: {
    updateToken(state, action: PayloadAction<string>) {
      state.rawToken = action.payload;
      state.parsedToken = ParseJwt(action.payload);
      state.userID = state.parsedToken?.sub;
    }, masquerade(state, action: PayloadAction<string>){
      state.rawToken = action.payload;
      state.parsedToken = ParseJwt(action.payload);
      state.userID = state.parsedToken?.sub;
      state.masquerading = true;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      localStorage.setItem("token", "");
      state.rawToken = initialState.rawToken;
      state.parsedToken = initialState.parsedToken;
      state.userID = initialState.userID;
      state.masquerading = initialState.masquerading;
    });
  }
});

export const { updateToken, masquerade } = loginSlice.actions;

/*
  API connection stuff
*/
export type PostLoginParams = {
  username: string;
  password: string;
};

export type ResetPasswordParams = {
  token: string;
  password: string;
}

export type MasqueradeParams = {
   targetUserUuid: string;
}

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    postLogin: build.mutation<string, PostLoginParams>({
      query: (body: PostLoginParams) => {
        return {
          url: `/shaggy/login`,
          method: "POST",
          body: body,
          responseHandler: "text",
        };
      },
    }),
    forgotPassword: build.mutation<void, string>({
      query: (email: string) => {
        return {
          url: `/shaggy/login/forgotPassword?email=`+encodeURIComponent(email),
          method: "POST",
        };
      },
    }),
    resetPassword: build.mutation<string, ResetPasswordParams>({
      query: (params: ResetPasswordParams) => {
        return {
          url: `/shaggy/login/resetPassword?token=`+encodeURIComponent(params.token)+`&password=`+encodeURIComponent(params.password),
          method: "POST",
          responseHandler: "text",
        };
      },
    }),
    masquerade: build.mutation<string, MasqueradeParams>({
      query: (params: MasqueradeParams) => {
        return {
          url: `/shaggy/masquerade`,
          method: "POST",
          body: params.targetUserUuid,
          responseHandler: "text",
        };
      },
    })
  }),
});

export const { usePostLoginMutation, useForgotPasswordMutation, useResetPasswordMutation, useMasqueradeMutation } = extendedApiSlice;
