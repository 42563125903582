import React, {useState} from "react";
import "./tender-details-card.scss";
import {TenderDetailsProps} from "./TenderDetailsView";
import {getCodeTranslations, getValue, translateCompany} from "./TenderDetailsPage";
import {
    AwardCriterium,
    AwardModification,
    Bid,
    ContractAward,
    ContractAwardNotice,
    ContractNotice,
    Corrigendum,
    CorrigendumChange,
    DesignContest,
    Lot,
    MunicipalDecision,
    OpeningReport,
    PriorInformationNotice,
    PublicationInformation,
    S3FileDocument,
    Tender,
    Value
} from "../../../types/tender";
import {TFunction, useTranslation} from "react-i18next";
import {DateUtil} from "../../../utils/date";
import {TenderUtil} from "../../../utils/tenders";
import {CompanyLink, CompanyLinks, CompanyNameWithVat} from "../../company/CompanyLink";
import {MultilingualFieldUtil} from "../../../utils/multilingualfield";
import SortedPublicationCardProps = TenderUtil.SortedPublicationCardProps;

export const TenderDetailsCards: React.FC<TenderDetailsProps> = ({ tender, languageIso }) => {
  return (
    <>
      <div>
        <div className="container">
          <div className="tender-details-card-section">
            <div className="white-box-design">
              <PublicationCardsSorted tender={tender} languageIso={languageIso} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const PublicationCardsSorted: React.FC<TenderDetailsProps> = ({tender, languageIso}) => {
    let sortedPublicationCards = TenderUtil.getPublicationInformationsSorted(tender);
    return <>
        {sortedPublicationCards.map((publication, i) => (
            <PublicationCard key={publication.publicationInformation.uuid + i} tender={tender} sortedPublicationProps={publication} languageIso={languageIso}/>))}
    </>
}

interface PublicationCardProps{
    tender: Tender;
    sortedPublicationProps: SortedPublicationCardProps;
    languageIso: string;
}

export const PublicationCard: React.FC<PublicationCardProps> = ({tender, sortedPublicationProps, languageIso}) => {
    let uuid = sortedPublicationProps.publicationInformation.uuid;
    if(tender.awardModifications != null && tender.awardModifications.length > 0){
        for(const awardModification of tender.awardModifications){
            if (awardModification.publicationInformation.uuid === uuid){
                return <AwardModificationSection awardModification={awardModification} languageIso={languageIso}/>
            }
        }
    }
    if(tender.corrigendumChanges != null && tender.corrigendumChanges.length > 0){
        let relevantCorrigenda = tender.corrigendumChanges.filter(c => c.publicationInformation.uuid === uuid);
        if (relevantCorrigenda.length > 0) {
            return <>
                {relevantCorrigenda.map((c,i) => <CorrigendumChangeSection key={i} corrigendum={c} languageIso={languageIso}/>)}
            </>
        }
    }
    if (tender.contractAwardNotices != null && tender.contractAwardNotices.length > 0){
        for (const award of tender.contractAwardNotices){
            if (award.publicationInformation.uuid === uuid) {
                return <ContractAwardNoticeSection contractAwardNotice={award} languageIso={languageIso} sortedPublicationProps={sortedPublicationProps}/>
            }
        }
    }
    if (tender.openingReports != null && tender.openingReports.length > 0){
        for (const report of tender.openingReports){
            if (report.publicationInformation.uuid === uuid) {
                return <OpeningReportSection openingReport={report} languageIso={languageIso} sortedPublicationProps={sortedPublicationProps}/>
            }
        }
    }
    if (tender.designContests != null && tender.designContests.length > 0){
        for (const designContest of tender.designContests){
            if (designContest.publicationInformation.uuid === uuid) {
                return <DesignContestSection designContest={designContest} languageIso={languageIso} sortedPublicationProps={sortedPublicationProps}/>
            }
        }
    }
    if (tender.contractNotices != null && tender.contractNotices.length > 0){
        for (const contractNotice of tender.contractNotices){
            if (contractNotice.publicationInformation.uuid === uuid) {
                return <ContractNoticeSection contractNotice={contractNotice} languageIso={languageIso} sortedPublicationProps={sortedPublicationProps}/>
            }
        }
    }
    if (tender.priorInformationNotices != null && tender.priorInformationNotices.length > 0){
        for (const priorInformationNotice of tender.priorInformationNotices){
            if (priorInformationNotice.publicationInformation.uuid === uuid) {
                return <PriorInformationNoticeSection priorInformationNotice={priorInformationNotice} languageIso={languageIso} sortedPublicationProps={sortedPublicationProps}/>
            }
        }
    }
    if (tender.municipalDecisions != null && tender.municipalDecisions.length > 0){
        for (const municipalDecision of tender.municipalDecisions){
            if (municipalDecision.publicationInformation.uuid === uuid) {
                return <MunicipalDecisionSection municipalDecision={municipalDecision} languageIso={languageIso} sortedPublicationProps={sortedPublicationProps}/>
            }
        }
    }
    return <></>
}

interface PriorInformationNoticeSectionProps{
    priorInformationNotice: PriorInformationNotice;
    languageIso: string;
    sortedPublicationProps: SortedPublicationCardProps;
}

export const PriorInformationNoticeSection: React.FC<PriorInformationNoticeSectionProps> = ({priorInformationNotice, languageIso, sortedPublicationProps}) => {
    const {t} = useTranslation();
    let lots = priorInformationNotice.lots != null ? priorInformationNotice.lots : [];
    let publicationDateString = getPublicationDateString(priorInformationNotice.publicationInformation, sortedPublicationProps.originalVersionDate, t);
    return <>
        <div className='main-card'>
            <div className='box-upper-button'>
                <button>{t("publications.priorInformationNotice")}</button>
            </div>
            <div className='short-content-alignment'>
                <TenderDetailsKeyValue label={t("publications.publicationDateTitle")} content={publicationDateString}
                                       gridClass={"sub-text-grid-top-alignment sub-text-grid"} gridItemsClass={"sub-text-grid-items"} labelClass={""}/>
                <TenderDetailsKeyValue label={t("publications.dateOfContractNoticeTitle")} content={DateUtil.formatDate(priorInformationNotice.dateOfContractNotice)}
                                       gridClass={"sub-text-grid"} gridItemsClass={"sub-text-grid-items"} labelClass={""}/>
                <LotsSection lots={lots} languageIso={languageIso}/>
                <OfficialPublicationSection publicationInformation={priorInformationNotice.publicationInformation} languageIso={languageIso}/>
            </div>

        </div>
    </>;
}

interface ContractNoticeProps{
    contractNotice: ContractNotice;
    languageIso: string;
    sortedPublicationProps: SortedPublicationCardProps;
}

function getPublicationDateString(publicationInformation: PublicationInformation, originalVersionDate: string | undefined, t: TFunction): string | null{
    let publicationDateString = DateUtil.formatDate(publicationInformation.publicationDate);
    if (originalVersionDate != null && publicationDateString != null){
        publicationDateString = DateUtil.formatDate(originalVersionDate)  + " (" + t("publications.lastChangedOn") + publicationDateString + ")";
    } return publicationDateString;
}

export const ContractNoticeSection: React.FC<ContractNoticeProps> = ({contractNotice, languageIso, sortedPublicationProps}) => {
    const {t} = useTranslation();
    let lots = contractNotice.lots != null ? contractNotice.lots : [];
    let publicationDateString = getPublicationDateString(contractNotice.publicationInformation, sortedPublicationProps.originalVersionDate, t);
    return <>
        <div className='main-card'>
            <div className='box-upper-button'>
                <button>{t("publications.contractNotice")}</button>
            </div>
            <div className='short-content-alignment'>
                <TenderDetailsKeyValue label={t("publications.publicationDateTitle")} content={publicationDateString}
                                       gridClass={"sub-text-grid"} gridItemsClass={"sub-text-grid-items"} labelClass={""}/>
                <LotsSection lots={lots} languageIso={languageIso}/>
                <TechnicalInformationSection contractNotice={contractNotice} languageIso={languageIso}/>
                <OfficialPublicationSection publicationInformation={contractNotice.publicationInformation} languageIso={languageIso}/>
            </div>

        </div>
    </>;
}

interface TechnicalInformationSectionProps{
    contractNotice: ContractNotice;
    languageIso: string;
}

export const TechnicalInformationSection: React.FC<TechnicalInformationSectionProps> = ({contractNotice, languageIso}) => {
    const {t} = useTranslation();
    let financialConditionsPresent = contractNotice.financialInformation != null || contractNotice.financialMinimumLevel != null;
    let technicalConditionsPresent = contractNotice.technicalInformation != null || contractNotice.technicalMinimumLevel != null;
    let performanceConditionsPresent = contractNotice.performanceConditions != null;
    return <>
        {financialConditionsPresent &&
            <div className='simple-details-card'>
                <h3>{t("publications.financialConditionsTitle")}</h3>
                <TenderDetailsKeyValue label={t("publications.financialConditionsInformationSubtitle")} content={MultilingualFieldUtil.optionalTranslation(contractNotice.financialInformation, languageIso)}
                                       gridClass={"sub-text-grid"} gridItemsClass={"sub-text-grid-items"} labelClass={""}/>
                <TenderDetailsKeyValue label={t("publications.financialConditionsMinimumSubtitle")} content={MultilingualFieldUtil.optionalTranslation(contractNotice.financialMinimumLevel, languageIso)}
                                       gridClass={"sub-text-grid"} gridItemsClass={"sub-text-grid-items"} labelClass={""}/>
            </div>
        }
        {technicalConditionsPresent &&
            <div className='simple-details-card'>
                <h3>{t("publications.technicalConditionsTitle")}</h3>
                <TenderDetailsKeyValue label={t("publications.technicalConditionsInformationSubtitle")} content={MultilingualFieldUtil.optionalTranslation(contractNotice.technicalInformation, languageIso)}
                                       gridClass={"sub-text-grid"} gridItemsClass={"sub-text-grid-items"} labelClass={""}/>
                <TenderDetailsKeyValue label={t("publications.technicalConditionsMinimumSubtitle")} content={MultilingualFieldUtil.optionalTranslation(contractNotice.technicalMinimumLevel, languageIso)}
                                       gridClass={"sub-text-grid"} gridItemsClass={"sub-text-grid-items"} labelClass={""}/>
            </div>
        }
        {performanceConditionsPresent &&
            <div className='simple-details-card'>
                <h3>{t("publications.performanceConditionsTitle")}</h3>
                <TenderDetailsKeyValue label={t("publications.performanceConditionsSubtitle")} content={MultilingualFieldUtil.optionalTranslation(contractNotice.performanceConditions, languageIso)}
                                       gridClass={"sub-text-grid"} gridItemsClass={"sub-text-grid-items"} labelClass={""}/>
            </div>
        }
    </>
}

interface ContractAwardNoticeSectionProps{
    contractAwardNotice: ContractAwardNotice;
    languageIso: string;
    sortedPublicationProps: SortedPublicationCardProps;
}

export const ContractAwardNoticeSection: React.FC<ContractAwardNoticeSectionProps> = ({contractAwardNotice, languageIso, sortedPublicationProps}) => {
    const {t} = useTranslation();
    let publicationDateString = getPublicationDateString(contractAwardNotice.publicationInformation, sortedPublicationProps.originalVersionDate, t);
    return (
        <>
            <div className='main-card'>
                <div className='box-upper-button'>
                    <button>{t("publications.contractAwardNotice")}</button>
                </div>
                <div className='short-content-alignment'>
                    <TenderDetailsKeyValue label={t("publications.publicationDateTitle")} content={publicationDateString}
                                           gridClass={"sub-text-grid"} gridItemsClass={"sub-text-grid-items"} labelClass={""}/>
                    <TenderDetailsKeyValue label={t("publications.exactTotalValueTitle")} content={getValue(contractAwardNotice.value)}
                                           gridClass={"sub-text-grid"} gridItemsClass={"sub-text-grid-items"} labelClass={""}/>
                    <ContractAwardsSection awards={contractAwardNotice.contractAwards} languageIso={languageIso} totalValueIsPresent={contractAwardNotice.value == null}/>
                    <OfficialPublicationSection publicationInformation={contractAwardNotice.publicationInformation} languageIso={languageIso}/>
                </div>
            </div>
        </>
    );
};

interface DesignContestSectionProps{
    designContest: DesignContest;
    languageIso: string;
    sortedPublicationProps: SortedPublicationCardProps;
}
export const DesignContestSection: React.FC<DesignContestSectionProps> = ({designContest, languageIso, sortedPublicationProps}) => {
    const {t} = useTranslation();
    let publicationDateString = getPublicationDateString(designContest.publicationInformation, sortedPublicationProps.originalVersionDate, t);
    return <>
        <div className='main-card'>
            <div className='box-upper-button'>
                <button>{t("publications.designContest")}</button>
            </div>
            <TenderDetailsKeyValue label={t("publications.publicationDateTitle")} content={publicationDateString}
                                   gridClass={"text-grid"} gridItemsClass={"text-grid-items"} labelClass={""}/>
            <TenderDetailsKeyValue label={t("publications.awardCriteriumTitle")}
                                    content={getAwardCriterium(designContest.awardCriterium, languageIso, t)}
                                    gridClass={"text-grid"} gridItemsClass={"text-grid-items"} labelClass={""}/>
            <TenderDetailsKeyValues label={t("publications.alreadySelectedTitle")}
                                   content={getAlreadySelectedContenders(designContest, languageIso)}
                                   gridClass={"text-grid"} gridItemsClass={"text-grid-items"} labelClass={""}/>
            <TenderDetailsKeyValues label={t("publications.juryTitle")}
                                    content={getJury(designContest, languageIso)}
                                    gridClass={"text-grid"} gridItemsClass={"text-grid-items"} labelClass={""}/>
            <TenderDetailsKeyValue label={t("publications.cashPrizeTitle")}
                                    content={designContest.hasCashPrize ? t("publications.true") : null}
                                    gridClass={"text-grid"} gridItemsClass={"text-grid-items"} labelClass={""}/>
            <TenderDetailsKeyValue label={t("publications.isBindingTitle")}
                                   content={designContest.isBinding ? t("publications.true") : null}
                                   gridClass={"text-grid"} gridItemsClass={"text-grid-items"} labelClass={""}/>
            <TenderDetailsKeyValue label={t("publications.willBeAwardedTitle")}
                                   content={designContest.willBeAwarded ? t("publications.true") : null}
                                   gridClass={"text-grid"} gridItemsClass={"text-grid-items"} labelClass={""}/>
            <TenderDetailsKeyValue label={t("publications.totalValueTitle")}
                                   content={MultilingualFieldUtil.optionalTranslation(designContest.prizeValue, languageIso)}
                                   gridClass={"text-grid"} gridItemsClass={"text-grid-items"} labelClass={""}/>
            <TenderDetailsKeyValue label={t("publications.detailsPaymentTitle")}
                                   content={MultilingualFieldUtil.optionalTranslation(designContest.detailsPayment, languageIso)}
                                   gridClass={"text-grid"} gridItemsClass={"text-grid-items"} labelClass={""}/>
            <OfficialPublicationSection publicationInformation={designContest.publicationInformation} languageIso={languageIso}/>
        </div>
    </>;
}

interface OpeningReportSectionProps{
    openingReport: OpeningReport;
    languageIso: string;
    sortedPublicationProps: SortedPublicationCardProps;
}

export const OpeningReportSection: React.FC<OpeningReportSectionProps> = ({openingReport, languageIso, sortedPublicationProps}) => {
    const {t} = useTranslation();
    let bids = openingReport.bids != null ? openingReport.bids : [];
    let publicationDateString = getPublicationDateString(openingReport.publicationInformation, sortedPublicationProps.originalVersionDate, t);
    return <>
        <div className='main-card'>
            <div className='box-upper-button'>
                <button>{t("publications.openingReport")}</button>
            </div>
            <TenderDetailsKeyValue label={t("publications.publicationDateTitle")} content={publicationDateString}
                                   gridClass={"text-grid"} gridItemsClass={"text-grid-items"} labelClass={""}/>
            {bids.length === 0 && <TenderDetailsKeyValue label={""} content={t("publications.noBidsTitle")}
                                                         gridClass={"text-grid"} gridItemsClass={"text-grid-items"} labelClass={""}/>}
            <div className='short-content-alignment'>

                {bids.length > 0 && bids.map((bid, i) => (
                    <div key={i} className={i !== 0 ? "sub-text-grid-top-alignment sub-text-grid-border" : "sub-text-grid-border"}>
                        <BidSection bid={bid} index={i} languageIso={languageIso}/>
                    </div>
                ))}
                <OfficialPublicationSection publicationInformation={openingReport.publicationInformation} languageIso={languageIso}/>
            </div>
        </div>
    </>;
}

export interface AwardModificationProps {
    awardModification: AwardModification;
    languageIso: string;
}

export const AwardModificationSection: React.FC<AwardModificationProps> = ({awardModification, languageIso}) => {
    const {t} = useTranslation();
    return <>
        <div className='main-card' >
            <div className='box-upper-button'>
                <button>{t("publications.awardModification")}</button>
            </div>
            <TenderDetailsKeyValue label={t("publications.publicationDateTitle")}
                                   content={DateUtil.formatDate(awardModification.publicationInformation.publicationDate)}
                                   gridClass={"text-grid"} gridItemsClass={"text-grid-items"} labelClass={""}/>
            <TenderDetailsKeyValue label={t("publications.descriptionTitle")}
                                   content={MultilingualFieldUtil.optionalTranslation(awardModification.modificationShortDescription, languageIso)}
                                   gridClass={"text-grid"} gridItemsClass={"text-grid-items"} labelClass={""}/>
            <TenderDetailsKeyValue label={t("publications.oldValueTitle")} content={getValue(awardModification.oldValue)}
                                   gridClass={"text-grid"} gridItemsClass={"text-grid-items"} labelClass={""}/>
            <TenderDetailsKeyValue label={t("publications.newValueTitle")} content={getValue(awardModification.newValue)}
                                   gridClass={"text-grid"} gridItemsClass={"text-grid-items"} labelClass={""}/>
            <OfficialPublicationSection publicationInformation={awardModification.publicationInformation} languageIso={languageIso}/>
        </div>
    </>
}

export const CorrigendumChangeSection: React.FC<CorrigendumProps> = ({corrigendum, languageIso}) => {
    const {t} = useTranslation();
    let type = getCorrigendumChangeType(corrigendum.corrigendumChange);
    // if (type == null) return <></>;
    return <>
        <div className='main-card'>
            <div className='box-upper-button'>
                <button>{t("publications." + type)}</button>
            </div>
            <div className='short-content-alignment'>
                <TenderDetailsKeyValue label={t("publications.publicationDateTitle")}
                                       content={DateUtil.formatDate(corrigendum.publicationInformation.publicationDate)}
                                       gridClass={"sub-text-grid"} gridItemsClass={"sub-text-grid-items"} labelClass={""}/>
                {corrigendum.corrigendumChange &&
                    <>
                        <TenderDetailsKeyValue label={t("publications.labelTitle")}
                                               content={MultilingualFieldUtil.optionalTranslation(corrigendum.corrigendumChange.label, languageIso)}
                                               gridClass={"sub-text-grid"} gridItemsClass={"sub-text-grid-items"} labelClass={""}/>
                        <TenderDetailsKeyValue label={t("publications.sectionTitle")}
                                               content={corrigendum.corrigendumChange.section}
                                               gridClass={"sub-text-grid"} gridItemsClass={"sub-text-grid-items"} labelClass={""}/>
                        <TenderDetailsKeyValue label={t("publications.oldValueTitle")}
                                               content={getOldValue(corrigendum.corrigendumChange, type, languageIso)}
                                               gridClass={"sub-text-grid"} gridItemsClass={"sub-text-grid-items"} labelClass={""}/>
                        <TenderDetailsKeyValue label={t("publications.newValueTitle")}
                                               content={getNewValue(corrigendum.corrigendumChange, type, languageIso)}
                                               gridClass={"sub-text-grid"} gridItemsClass={"sub-text-grid-items"} labelClass={""}/>
                    </>
                }
                {corrigendum.additionalInformation &&
                    <div className='simple-details-card'>
                        <h3>{t("publications.additionalInformationTitle")}</h3>
                        <pre>{MultilingualFieldUtil.translate(corrigendum.additionalInformation, languageIso)}</pre>
                    </div>
                }
                <OfficialPublicationSection publicationInformation={corrigendum.publicationInformation} languageIso={languageIso}/>
            </div>

        </div>
    </>
}

export interface CorrigendumProps {
    corrigendum: Corrigendum;
    languageIso: string;
}

/**
 * This refers to publications.ts
 * @param change
 */
function getCorrigendumChangeType(change: CorrigendumChange){
    if (change === null) return "corrigendum";
    if (change.newDate != null || change.originalDate != null) return "corrigendumDateChange";
    if (change.newDateTime != null || change.originalDateTime != null) return "corrigendumDateTimeChange";
    if (change.newMainSubjectCode != null || change.originalMainSubjectCode != null) return "corrigendumMainSubjectCodeChange";
    if (change.newAdditionalSubjectCodes != null || change.originalAdditionalSubjectCodes != null) return "corrigendumAdditionalSubjectCodesChange";
    if (change.newText != null || change.originalText != null) return "corrigendumTextChange";
    return "corrigendum";
}

function getOldValue(change: CorrigendumChange, type: string, languageIso: string){
    switch (type){
        case "corrigendumDateChange": return DateUtil.formatDate(change.originalDate);
        case "corrigendumDateTimeChange": return DateUtil.formatDateTime(change.originalDateTime);
        case "corrigendumMainSubjectCodeChange": return change.originalMainSubjectCode;
        case "corrigendumAdditionalSubjectCodesChange": return change.originalAdditionalSubjectCodes.join(', ');
        case "corrigendumTextChange": return MultilingualFieldUtil.translate(change.originalText, languageIso);
    } return null;
}

function getNewValue(change: CorrigendumChange, type: string, languageIso: string){
    switch (type){
        case "corrigendumDateChange": return DateUtil.formatDate(change.newDate);
        case "corrigendumDateTimeChange": return DateUtil.formatDateTime(change.newDateTime);
        case "corrigendumMainSubjectCodeChange": return change.newMainSubjectCode;
        case "corrigendumAdditionalSubjectCodesChange": return change.newAdditionalSubjectCodes.join(', ');
        case "corrigendumTextChange": return MultilingualFieldUtil.translate(change.newText, languageIso);
    } return null;
}

function getAlreadySelectedContenders(designContestDocument: DesignContest, languageIso: string){
    if (designContestDocument.alreadySelectedContenders == null || designContestDocument.alreadySelectedContenders.length === 0) return null;
    let translations = []
    for(const contender of designContestDocument.alreadySelectedContenders){
        translations.push(MultilingualFieldUtil.translate(contender, languageIso))
    } return translations;
}

function getJury(designContestDocument: DesignContest, languageIso: string){
    if (designContestDocument.jury == null || designContestDocument.jury.length === 0) return null;
    let translations = []
    for(const contender of designContestDocument.jury){
        translations.push(MultilingualFieldUtil.translate(contender, languageIso))
    } return translations;
}

//Types: DESCRIPTIVE, QUALITY, PRICE;
function getAwardCriterium(awardCriterium: AwardCriterium, languageIso: string, t: TFunction): string | null{
    if (awardCriterium == null || awardCriterium.type == null) return null;
    let criteriumDescription;
    if(awardCriterium.description == null) {
        criteriumDescription = awardCriterium.type === "PRICE" ? t("publications.price") : t("publications.quality");
    } else {
        criteriumDescription= MultilingualFieldUtil.translate(awardCriterium.description, languageIso);
    }
    let criteriumWeighting = MultilingualFieldUtil.translate(awardCriterium.weightingInPercent, languageIso);
    return [criteriumDescription, criteriumWeighting].join(" ")
}

function getAwardCriteria(lot: Lot, languageIso: string, t: TFunction): string[]{
    let awardCriteria: string[] = [];
    if (lot.awardCriteria == null) return awardCriteria;
    for (const a of lot.awardCriteria){
        let description = getAwardCriterium(a, languageIso, t);
        if(description != null) awardCriteria.push(description);
    } return awardCriteria;

}


interface OfficialPublicationSectionProps{
    publicationInformation: PublicationInformation;
    languageIso: string;
}

export const OfficialPublicationSection: React.FC<OfficialPublicationSectionProps> = ({publicationInformation, languageIso}) => {
    const {t} = useTranslation();
    let files = publicationInformation.files.filter(isOfficialPublicationFile)
    let officialPubliationUrl = publicationInformation.officialPublicationUrl;
    if (files.length === 0 && officialPubliationUrl === null) return <></>
    if(files.length > 1) {
        let filesWithCorrectLanguage = files.filter(url => url.languageIso === languageIso)
        if(filesWithCorrectLanguage.length > 0) files = filesWithCorrectLanguage;
    }
    return <>
        <div className='file-data'>
            <span>
                {files.length > 0 && <span><a href={files[0].url} target="_blank" rel="noreferrer noopener">{t("publications.officialPublication")}</a></span>}
                {files.length > 0 && officialPubliationUrl !== null && <span> | </span>}
                {officialPubliationUrl !== null && <span><a href={officialPubliationUrl} target="_blank" rel="noreferrer noopener">{t("publications.source")}</a></span>}
            </span>
        </div>
    </>
}

function isOfficialPublicationFile(file: S3FileDocument){
    return (file.type === "OFFICIAL_PUBLICATION_URL" || file.type === "CONTENT_FILE_HUMAN_READABLE") && file.url != null;
}

export interface AwardsProps {
    awards: ContractAward[];
    languageIso: string;
    totalValueIsPresent: boolean;
}

export interface AwardProps {
    award: ContractAward;
    index: number;
    languageIso: string;
    includeValue: boolean;
    includeTitle: boolean;
}

export const ContractAwardsSection: React.FC<AwardsProps> = ({awards, languageIso, totalValueIsPresent}) => {
    const {t} = useTranslation();
    if (awards == null) awards = [];
    awards = filterAwardsWithContent(awards);
    return <>
        {awards.length === 0 && <TenderDetailsKeyValue label={""} content={t("publications.noAwardsTitle")}
                                                       gridClass={"sub-text-grid"} gridItemsClass={"sub-text-grid-items"} labelClass={""}/>}
        {awards.length === 1 &&
            <ContractAwardSection award={awards[0]} index={0} languageIso={languageIso} includeValue={!totalValueIsPresent} includeTitle={false}/>
        }
        {awards.length > 1 &&
            awards.map((award, i) => (
                <div key={i} className={getContractAwardClassName(i, awards.length)}>
                    <ContractAwardSection award={award} index={i} languageIso={languageIso} includeValue={true} includeTitle={true}/>
                </div>
            ))
        }
    </>
}

function filterAwardsWithContent(awards: ContractAward[]): ContractAward[]{
    return awards.filter(a => a.title != null
        || a.winningCompanies?.length > 0
        || a.losingBids?.length > 0
        || a.value != null
        || a.lowestAndHighestValue != null
        || a.offersReceived != null
    );

}

export const ContractAwardSection: React.FC<AwardProps> = ({award,languageIso, index, includeTitle }) => {
    const {t} = useTranslation();
    const value = getValue(award.value);
    const offersReceived = getOffersReceived(award);
    const winningCompanyNames = getWinningCompanyNamesWithVatFromAward(award, languageIso);
    const losingCompanyNames = getLosingCompanyNamesWithVatFromAward(award, languageIso);
    let showAwardStatus = award.resultStatus !== 'UNKNOWN' && award.resultStatus !== 'AWARDED';
    let showDecisionReason = showAwardStatus && award.decisionReason !== 'UNKNOWN' && award.decisionReason !== 'OTHER';
    let notAwarded = value === null && offersReceived === null && winningCompanyNames === null ;
    return <>
        {includeTitle &&
            <TenderDetailsKeyValue label={getContractAwardLotTitle(award, t("publications.lotTitle"))} content={MultilingualFieldUtil.translate(award.title, languageIso)}
                                   gridClass={"sub-text-grid"} gridItemsClass={"sub-text-grid-items"} labelClass={"green-text-color"}/>
        }
        {notAwarded && <TenderDetailsKeyValue label={""} content={t("publications.noAwardsTitle")}
                                              gridClass={"sub-text-grid"} gridItemsClass={"sub-text-grid-items"} labelClass={""}/>}
        <TenderDetailsKeyValue label={t("publications.lowestAndHighestValueTitle")} content={getValue(award.lowestAndHighestValue)}
                               gridClass={"sub-text-grid"} gridItemsClass={"sub-text-grid-items"} labelClass={""}/>
        <TenderDetailsKeyValue label={t("publications.offersReceivedTitle")} content={offersReceived}
                               gridClass={"sub-text-grid"} gridItemsClass={"sub-text-grid-items"} labelClass={""}/>
        {showAwardStatus && <TenderDetailsKeyValue label={t("publications.resultStatusTitle")} content={t("publications.resultStatus" + award.resultStatus)}
                                 gridClass={"sub-text-grid"} gridItemsClass={"sub-text-grid-items"} labelClass={""}/>}
        {showDecisionReason && <TenderDetailsKeyValue label={t("publications.decisionReasonTitle")} content={t("publications.decisionReason" + award.decisionReason)}
                                                                    gridClass={"sub-text-grid"} gridItemsClass={"sub-text-grid-items"} labelClass={""}/>}
        <TenderDetailsCompanies label={winningCompanyNames.length === 1  ? t("publications.winnerTitle") : t("publications.winnersTitle")}
                                content={[]} companiesWithVatAndValue={winningCompanyNames} displayValues={true}
                                gridClass={"sub-text-grid"} gridItemsClass={"sub-text-grid-items"} labelClass={""}/>
        <TenderDetailsCompanies label={losingCompanyNames.length === 1  ? t("publications.nonWinnerTitle") : t("publications.nonWinnersTitle")}
                                content={[]} companiesWithVatAndValue={losingCompanyNames} displayValues={true}
                                gridClass={"sub-text-grid"} gridItemsClass={"sub-text-grid-items"} labelClass={""}/>
    </>
}

export interface LotsProps {
    lots: Lot[];
    languageIso: string;
}

export interface LotProps {
    lot: LotWithLotNumber;
    index: number;
    languageIso: string;
    includeTitleAndDescription: boolean;
}

interface LotWithLotNumber{
    lot: Lot;
    lotNumber: number;
}

export const LotsSection: React.FC<LotsProps> = ({lots, languageIso}) => {
    let lotsWithNumbers = getLotsWithLotNumbers(lots).sort((a,b) => a.lotNumber - b.lotNumber);
    return <>
        {lotsWithNumbers.length === 1 &&
            <div>
                <LotSection lot={lotsWithNumbers[0]} index={0} languageIso={languageIso} includeTitleAndDescription={false}/>
            </div>
        }
        {lotsWithNumbers.length > 1 && lotsWithNumbers.map((value, i) => (
            <div key={i} className={i !== 0 ? "sub-text-grid-top-alignment sub-text-grid-border" : "sub-text-grid-top-alignment sub-text-grid-border"}>
                <LotSection lot={value} index={i} languageIso={languageIso} includeTitleAndDescription={true}/>
            </div>
        ))}
    </>
}

function getLotsWithLotNumbers(lots: Lot[]): LotWithLotNumber[]{
    let lotNumbers: number[] = [];
    for(const lot of lots){
        let n = getLotNumberFromInternalLotId(lot.internalLotId);
        if (n != null) lotNumbers.push(n);
        else break;
    } if (lotNumbers.length !== lots.length) {
        return lots.map((l,index) => {return {lot: l, lotNumber: index+1}});
    } else return lots.map((l,index) => {return {lot: l, lotNumber: lotNumbers[index]}});
}

export const LotSection: React.FC<LotProps> = ({lot, languageIso, includeTitleAndDescription}) => {
    const {t} = useTranslation();
    return <>
        {includeTitleAndDescription && <>
            <TenderDetailsKeyValue label={t("publications.lotTitle") + " " + (lot.lotNumber)} content={MultilingualFieldUtil.translate(lot.lot.title, languageIso)}
                                   gridClass={"sub-text-grid"} gridItemsClass={"sub-text-grid-items"} labelClass={"green-text-color"}/>
            <TenderDetailsKeyValue label={t("publications.descriptionTitle")} content={MultilingualFieldUtil.translate(lot.lot.description, languageIso)}
                                   gridClass={"sub-text-grid"} gridItemsClass={"sub-text-grid-items"} labelClass={""}/>
        </>
        }
        <TenderDetailsKeyValue label={t("publications.durationTitle")} content={getDuration(lot.lot, t)}
                           gridClass={"sub-text-grid"} gridItemsClass={"sub-text-grid-items"} labelClass={""}/>
        <TenderDetailsKeyValues label={t("publications.supplementarySubjectCodesTitle")} content={getCodeTranslations(lot.lot.subjectCodes, t)}
                           gridClass={"sub-text-grid"} gridItemsClass={"sub-text-grid-items"} labelClass={""}/>
        <TenderDetailsKeyValues label={t("publications.regionCodesTitle")} content={getCodeTranslations(lot.lot.regionCodes, t)}
                            gridClass={"sub-text-grid"} gridItemsClass={"sub-text-grid-items"} labelClass={""}/>
        {lot.lot.selectionCriteria != null && lot.lot.selectionCriteria.length > 0 && <SelectionCriteriumSection lot={lot.lot} languageIso={languageIso}/>}
        {lot.lot.awardCriteria != null && lot.lot.awardCriteria.length > 0 && <AwardCriteriumSection lot={lot.lot} languageIso={languageIso}/>}
    </>;
}

interface SelectionCriteriumProps {
    lot: Lot;
    languageIso: string;
}
const SelectionCriteriumSection: React.FC<SelectionCriteriumProps> = ({lot,languageIso}) => {
    const {t} = useTranslation();
    const criteriaWithTypes = getSelectionCriteriumTypesWithCriteria(lot, languageIso, t);
    let title = lot.selectionCriteria.length === 1 ? t("publications.selectionCriteriumTitle") : t("publications.selectionCriteriaTitle");
    return <>
        {criteriaWithTypes.map((criteriumWithType, i) => (<span key={i}>
            <TenderDetailsKeyValue label={i === 0 ? title : ""} content={criteriumWithType.type}
                                   gridClass={"sub-text-grid"} gridItemsClass={"sub-text-grid-sub-titles"} labelClass={""}/>
            {criteriumWithType.criteria.map((criterium, j) => (<span key={i + "." + j}>
                <TenderDetailsKeyValues label={""} content={criterium.lines} gridClass={"sub-text-grid"} gridItemsClass={"sub-text-grid-items"} labelClass={""}/>
            </span>))}

        </span>))}
    </>
}

const AwardCriteriumSection: React.FC<SelectionCriteriumProps> = ({lot,languageIso}) => {
    const {t} = useTranslation();
    const descriptions = getAwardCriteria(lot, languageIso, t);
    let title = lot.awardCriteria.length === 1 ? t("publications.awardCriteriumTitle") : t("publications.awardCriteriaTitle");
    return <>
        <TenderDetailsKeyValues label={ title} content={descriptions} gridClass={"sub-text-grid"} gridItemsClass={"sub-text-grid-items"} labelClass={""}/>
    </>
}

interface SelectionCriteriumTypeWithCriteria {
    type: string;
    criteria: SelectionCriteriumDescription[];
}

interface SelectionCriteriumDescription {
    lines: string[]
}

function getSelectionCriteriumTypesWithCriteria(lot: Lot, languageIso: string, t: TFunction): SelectionCriteriumTypeWithCriteria[]{
    let criteria: SelectionCriteriumTypeWithCriteria[] = [];
    const types = Array.from(new Set(lot.selectionCriteria
        .map(criterium => criterium.type)
        .map(t => MultilingualFieldUtil.translate(t, languageIso)))).sort();
    for(const type of types){
        let criteriumDescriptions: SelectionCriteriumDescription[] = [];
        const criteriums = lot.selectionCriteria.filter(criterium => MultilingualFieldUtil.translate(criterium.type, languageIso) === type);
        for(const criterium of criteriums){
            let lines: string[] = [];
            if (criterium.name != null) lines.push(MultilingualFieldUtil.translate(criterium.name, languageIso));
            if (criterium.description != null) lines.push(MultilingualFieldUtil.translate(criterium.description, languageIso));
            if (criterium.weight != null) lines.push(MultilingualFieldUtil.translate(criterium.weight, languageIso));
            else if(criterium.weightNumeric != null) lines.push(t("publications.selectionCriteriumWeight") + criterium.weightNumeric);
            if(lines.length > 0) criteriumDescriptions.push({lines: lines});
        } if(criteriums.length > 0) criteria.push({type: type, criteria: criteriumDescriptions});
    } return criteria;
}


export interface BidProps {
    bid: Bid;
    index: number;
    languageIso: string;
}

export const BidSection: React.FC<BidProps> = ({bid, index,languageIso}) => {
    const {t} = useTranslation();
    let companyNames = getCompanyNamesWithVatFromBid(bid, languageIso);
    return <>
        <TenderDetailsKeyValue label={t("publications.bidTitle") + " " + (index+1)} content={""}
                               gridClass={"sub-text-grid"} gridItemsClass={"sub-text-grid-items"} labelClass={"green-text-color"}/>
        <TenderDetailsCompanies label={companyNames.length === 1 ? t("publications.participantTitle") : t("publications.participantsTitle")} content={[]} companiesWithVatAndValue={companyNames}
                                gridClass={"sub-text-grid"} gridItemsClass={"sub-text-grid-items"} labelClass={""}/>
        <TenderDetailsKeyValue label={t("publications.totalValueTitle") + " " + (index+1)} content={getValue(bid.value)}
                               gridClass={"sub-text-grid"} gridItemsClass={"sub-text-grid-items"} labelClass={""}/>
        <TenderDetailsKeyValue label={t("publications.lotNumberTitle")} content={bid.lotNumber != null ? bid.lotNumber.toString() : null}
                               gridClass={"sub-text-grid"} gridItemsClass={"sub-text-grid-items"} labelClass={""}/>
    </>;
}

function getDuration(lot: Lot, t: TFunction){
    if (lot.duration == null) return null;
    let durationTypeTranslation = t("time." + lot.duration.type.toLowerCase());
    if (durationTypeTranslation == null) return lot.duration.amount + " " + lot.duration.type;
    else return lot.duration.amount + " " + durationTypeTranslation;
}

function getContractAwardClassName(index: number, listSize: number){
    // if(index === 0) return 'border-bottom';
    if(index === listSize -1) return 'top-section-alignment';
    else return 'top-section-alignment border-bottom';
}

function getContractAwardLotTitle(contractAward: ContractAward, lotTitle: string){
    if (contractAward.lotNumber != null && contractAward.lotNumber != 0) return lotTitle + " " + contractAward.lotNumber;
    let lotNumber = getLotNumberFromInternalLotId(contractAward.internalLotId);
    if (lotNumber != null) return lotTitle + " " + lotNumber;
    else return lotTitle;
}

function getLotNumberFromInternalLotId(internalLotId: string | null): number | null{
    if (internalLotId == null) return null;
    if (internalLotId.match("LOT-[0-9]+")) {
        return parseInt(internalLotId.replace("LOT-", ""));
    } return null;
}

function getOffersReceived(contractAward: ContractAward){
    if (contractAward.offersReceived != null) return contractAward.offersReceived.toString();
    else return null;
}

function getWinningCompanyNamesWithVatFromAward(contractAward: ContractAward, languageIso: string){
    let names: CompanyNameWithVatAndValue[] = [];
    let counter = 0;
    for(const company of contractAward.winningCompanies){
        let companyTranslation = translateCompany(company, languageIso);
        let vatNumber = company.vatNumber != null ? company.vatNumber : company.nationalIdentifier
        if(companyTranslation != null) {
            if(++counter === 1) {
                names.push({ name: companyTranslation, vat: vatNumber, value: contractAward.value, showFollowButton: true })
            } else {
                names.push({ name: companyTranslation, vat: vatNumber, showFollowButton: true })
            }
        }
    }
    return names;
}

function getLosingCompanyNamesWithVatFromAward(contractAward: ContractAward, languageIso: string){
    let names: CompanyNameWithVatAndValue[] = [];
    for(const bid of contractAward.losingBids){
        let companyTranslation = translateCompany(bid.company, languageIso);
        let vatNumber = bid.company.vatNumber != null ? bid.company.vatNumber : bid.company.nationalIdentifier
        if(companyTranslation != null) {
            names.push({ name: companyTranslation, vat: vatNumber, value: bid.value, showFollowButton: true })
        }
    }
    return names;

}

function getCompanyNamesWithVatFromBid(bid: Bid, languageIso: string){
    let names: CompanyNameWithVatAndValue[] = [];
    let companyTranslation = translateCompany(bid.company, languageIso);
    let vatNumber = bid.company.vatNumber != null ? bid.company.vatNumber : bid.company.nationalIdentifier
    if(companyTranslation != null) {
        names.push({ name: companyTranslation, vat: vatNumber, value: bid.value, showFollowButton: true })
    }
    return names;
}

interface MunicipalDecisionSectionProps{
    municipalDecision: MunicipalDecision;
    languageIso: string;
    sortedPublicationProps: SortedPublicationCardProps;
}

export const MunicipalDecisionSection: React.FC<MunicipalDecisionSectionProps> = ({municipalDecision, languageIso, sortedPublicationProps}) => {
    const {t} = useTranslation();
    let publicationDateString = getPublicationDateString(municipalDecision.publicationInformation, sortedPublicationProps.originalVersionDate, t);
    return <>
        <div className='main-card'>
            <div className='box-upper-button'>
                <button>{t("publications.municipalDecision")}</button>
            </div>
            <div className='short-content-alignment'>
                <TenderDetailsKeyValue label={t("publications.publicationDateTitle")} content={publicationDateString}
                                       gridClass={"sub-text-grid-top-alignment sub-text-grid"} gridItemsClass={"sub-text-grid-items"} labelClass={""}/>
                <TenderDetailsKeyValue label={t("publications.municipalDecisionTypeTitle")} content={t("codes.code_" + municipalDecision.municipalDecisionType)}
                                       gridClass={"sub-text-grid-top-alignment sub-text-grid"} gridItemsClass={"sub-text-grid-items"} labelClass={""}/>
                <TenderDetailsKeyValue label={t("publications.resolutionTitle")}
                                       content={MultilingualFieldUtil.optionalTranslation(municipalDecision.resolution, languageIso)}
                                       gridClass={"text-grid"} gridItemsClass={"text-grid-items"} labelClass={""}/>
                <OfficialPublicationSection publicationInformation={municipalDecision.publicationInformation} languageIso={languageIso}/>
            </div>

        </div>
    </>;
}

export interface TenderDetailsKeyValueProps {
    label: string;
    content: string | null;
    gridClass: string;
    gridItemsClass: string;
    labelClass: string;
    href?: string;
}

export interface TenderDetailsKeyValuesProps {
    label: string;
    content: string[] | null;
    gridClass: string;
    gridItemsClass: string;
    labelClass: string;
}

export const TenderDetailsKeyValue: React.FC<TenderDetailsKeyValueProps> = (keyValueProps) => {
    if (keyValueProps.content === null) return <></>;
    const label = keyValueProps.label === "" ? "" :  keyValueProps.label + ": ";
    return (
        <>
            <div className={keyValueProps.gridClass}>
                <div className={keyValueProps.gridItemsClass}>
                    <label className={keyValueProps.labelClass}>{label}</label>
                </div>
                <div className={keyValueProps.gridItemsClass}>
                    {keyValueProps.href && <pre><a className={"hover-highlight"} href={keyValueProps.href}>{keyValueProps.content}</a></pre>}
                    {!keyValueProps.href && <pre>{keyValueProps.content}</pre>}
                </div>
            </div>
        </>
    );
};

export const TenderDetailsKeyValues: React.FC<TenderDetailsKeyValuesProps> = (keyValuesProps) => {
    const [showAll, setShowAll] = useState(false);
    const toggleShowAll = () => setShowAll(!showAll);
    if (keyValuesProps.content === null) return <></>;
    let values = keyValuesProps.content;
    if (!showAll) values = values.slice(0, 3)
    const label = keyValuesProps.label === "" ? "" :  keyValuesProps.label + ": ";
    return (
        <>
            <div className={keyValuesProps.gridClass}>
                <div className={keyValuesProps.gridItemsClass}>
                    <label>{label}</label>
                </div>
                <div className={keyValuesProps.gridItemsClass}>
                    {values.map((value, i) => (
                        <span key={i}>{value}</span>
                    ))}
                    {keyValuesProps.content.length > 3 &&
                      <p className='cursor-pointer' onClick={toggleShowAll}>...</p>
                    }
                </div>
            </div>
        </>
    );
};

interface CompanyNameWithVatAndValue extends CompanyNameWithVat {
    value?: Value;
}

export interface TenderDetailsCompaniesProps extends TenderDetailsKeyValuesProps {
    companiesWithVatAndValue: CompanyNameWithVatAndValue[];
    displayValues?: boolean;
}
export const TenderDetailsCompanies: React.FC<TenderDetailsCompaniesProps> = (props) => {
    if (props.companiesWithVatAndValue === null || props.companiesWithVatAndValue.length === 0) return <></>;
    const label = props.label === "" ? "" :  props.label + ": ";
    return (
        <>
            <div className={props.gridClass}>
                <div className={props.gridItemsClass}>
                    <label>{label}</label>
                </div>
                <div>
                    {!props.displayValues && <CompanyLinks companies={props.companiesWithVatAndValue}/>}
                    {props.displayValues &&
                       props.companiesWithVatAndValue
                         .sort((a,b) => compareValues(a.value,b.value))
                         .map((c, i) => (
                         <div className={"sub-text-grid-long"} key={i}>
                           <div>
                               <p ><CompanyLink c={c}/></p>
                           </div>
                           <div>
                             {c.value && <p>{getValue(c.value)}</p>}
                           </div>
                         </div>
                       ))}
                </div>
            </div>
        </>
    );
};

function compareValues(a: Value | undefined, b: Value | undefined): number {
    if (a === undefined && b === undefined) return 0;
    if (a === undefined) return 1;
    if (b === undefined) return -1;
    // Compare based on valueExact
    if (a.valueExact && b.valueExact) {
        return a.valueExact - b.valueExact;
    }
    // Compare based on valueRange.from
    if (a.valueRange && a.valueRange.from && b.valueRange.from) {
        return a.valueRange.from - b.valueRange.from;
    }
    return 0;
}