import {SearchFilter} from "../../../../consts/SearchFilter";
import React from "react";
import {useTranslation} from "react-i18next";
import {TFunction} from "i18next";
import {CloseIcon} from "../../../../components/icons";
import {SkeletonSection} from "../../../tenderDetails/main/TenderDetailsView";
import {SearchResultsConfiguration} from "../../GenericSearchPage";

interface SearchFilterButtonProps {
    searchFilter: SearchFilter;
    removeSearchFilter: (searchFilter: SearchFilter) => void;
    canBeRemoved: boolean;
    searchResultsConfiguration: SearchResultsConfiguration;
}

export const SearchFilterButton: React.FC<SearchFilterButtonProps> = ({
                                                                          searchFilter,
                                                                          removeSearchFilter,
                                                                          canBeRemoved,
                                                                          searchResultsConfiguration
                                                                      }) => {
    let {t} = useTranslation();
    // if (searchResultsConfiguration.hiddenFilters?.includes(searchFilter)) return <></>
    let tag = translateSearchFilterToTag(searchFilter, t);
    if (tag === "") return <></>
    return <>
        <button>
            <span>{tag}</span>
            {canBeRemoved && <span onClick={() => removeSearchFilter(searchFilter)}>
                <CloseIcon/>
            </span>}
            {/*{canBeRemoved &&*/}
            {/*    <svg onClick={() => removeSearchFilter(searchFilter)} width="16" height="16" viewBox="0 0 16 16" fill="none"*/}
            {/*         xmlns="http://www.w3.org/2000/svg">*/}
            {/*        <path*/}
            {/*            d="M12.2 3.80665C11.94 3.54665 11.52 3.54665 11.26 3.80665L7.99998 7.05998L4.73998 3.79998C4.47998 3.53998 4.05998 3.53998 3.79998 3.79998C3.53998 4.05998 3.53998 4.47998 3.79998 4.73998L7.05998 7.99998L3.79998 11.26C3.53998 11.52 3.53998 11.94 3.79998 12.2C4.05998 12.46 4.47998 12.46 4.73998 12.2L7.99998 8.93998L11.26 12.2C11.52 12.46 11.94 12.46 12.2 12.2C12.46 11.94 12.46 11.52 12.2 11.26L8.93998 7.99998L12.2 4.73998C12.4533 4.48665 12.4533 4.05998 12.2 3.80665Z"*/}
            {/*            fill="#51565F"/>*/}
            {/*    </svg>}*/}
        </button>
    </>
}

export const SearchFilterButtonSkeleton: React.FC = () => {
    return <>
        <button>
            <span>...</span>
        </button>
    </>
}

function translateSearchFilterToTag(searchFilter: SearchFilter, t: TFunction){
    if(searchFilter.valueOf().startsWith("COUNTRY_")) return t("searchFilterTags.COUNTRY_PREFIX") + t("countries." + searchFilter.valueOf().replace("COUNTRY_", ""));
    else if(searchFilter.valueOf().startsWith("LANGUAGE_")) return t("searchFilterTags.LANGUAGE_PREFIX") + t("languages." + searchFilter.valueOf().replace("LANGUAGE_", ""));
    else return t("searchFilterTags." + searchFilter.valueOf());
}
