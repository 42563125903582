import React, { useState } from "react";
import { ExportInput, useGetExportMutation } from "./exportButtonSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {Loader} from "../loader";
import { showErrorPopup } from "../../hooks/slices/snaccSlice";
import { TenderSearch } from "../../hooks/slices/tenderSearchSlice";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { CloseIcon, ExportIcon } from "../icons";
import { SearchUrlUtil } from "../../utils/searchUrlUtil";
import { FieldGroup } from "../../consts/FieldGroup";

export interface ExportButtonProps {
  tenderUuids: string[];
  tenderSearchInput?: TenderSearch;
}

export const ExportButton: React.FC<ExportButtonProps> = ({tenderUuids, tenderSearchInput}) => {

    const dispatch = useAppDispatch();
    let {t} = useTranslation();
    const authState = useAppSelector((state) => state.login);
    const searchState = useAppSelector((state) => state.tenderSearch);

    const [getExport, {
        data: exportOutput,
        isSuccess: exportIsLoaded,
        isLoading: exportIsLoading,
        isError,
        error
    }] = useGetExportMutation();
    const [exportDownloaded, setExportDownloaded] = useState(false);

    const downloadExport = () => {
        if (exportOutput) {
            const tempComponent = document.createElement('a')
            tempComponent.href = exportOutput.downloadUrl
            tempComponent.download = 'export.xls'
            tempComponent.click()
            tempComponent.parentElement?.removeChild(tempComponent)
        }
        setExportDownloaded(true);
    }
    const closeExport = () => {
        setExportDownloaded(true);
    }

    const testExport = () => {
        if (tenderSearchInput){
            setExportDownloaded(false);
            let input: ExportInput = {
                userUuid: authState.userID ? authState.userID : "",
                tenderUuids: tenderUuids,
                query: tenderSearchInput.query ? tenderSearchInput.query : "",
                filters: getFilterTranslations(tenderSearchInput, t),
                reproduceSearchUrlPath: SearchUrlUtil.construct(searchState),
            };
            getExport(input)
        }
    }

    if (isError && error) {
        dispatch(showErrorPopup(error));
    }

    return <>
        {exportIsLoading && <Loader/>}
        {/*todo replace this with ConfirmPopup*/}
        {!exportDownloaded && exportIsLoaded && exportOutput &&
            <ExportPopup downloadExport={downloadExport} closeExport={closeExport}/>}
        <div className="cursor-pointer" onClick={() => testExport()}>
            <p>{t("publications.export")}</p>
            {/*<ExportIcon/>*/}
        </div>
    </>
}

//TODO: use same method to generate filter translations here and in the top bar on the website
function getFilterTranslations(input: TenderSearch, t: TFunction): string[] {
    let translations: string[] = [];

    if (input.query) translations.push(input.query)
    if (input.fieldGroups && input.fieldGroups.length > 0 && input.fieldGroups.includes(FieldGroup.ADDENDA)) translations.push(t("publications.fields") + ":" + t("searchFilters.FIELD_GROUP_ADDENDA"))

    if (input.languageIsos && input.languageIsos.length > 0) translations.push(t("publications.languagesTitle") + ":" + input.languageIsos.map(v => t("languages." + v)).join(", "));
    if (input.countries && input.countries.length > 0) translations.push(t("publications.countries") + ":" + input.countries.map(v => t("countries." + v)).join(", "));

    if (input.publicationStartDate && input.publicationEndDate) translations.push(t("publications.publicationDateTitle") + ":" + input.publicationStartDate + " - " + input.publicationEndDate);
    if (input.publicationStartDate && input.publicationEndDate === undefined) translations.push(t("publications.publicationDateTitle") + ":" + input.publicationStartDate + " - ...");
    if (input.publicationStartDate === undefined && input.publicationEndDate) translations.push(t("publications.publicationDateTitle") + ":" + "... - " + input.publicationEndDate);

    if (input.deadlineStartDate && input.deadlineEndDate) translations.push(t("publications.deadlineTitle") + ":" + input.deadlineStartDate + " - " + input.deadlineEndDate);
    if (input.deadlineStartDate && input.deadlineEndDate === undefined) translations.push(t("publications.deadlineTitle") + ":" + input.deadlineStartDate + " - ...");
    if (input.deadlineStartDate === undefined && input.deadlineEndDate) translations.push(t("publications.deadlineTitle") + ":" + "... - " + input.deadlineEndDate);

    if (input.contractTypes && input.contractTypes.length > 0) translations.push(t("publications.contractTypeTitle") + ":" + input.contractTypes.map(c => t("codes.code_" + c)).join(", ")); //todo translate
    // if(input.procedureTypes && input.procedureTypes.length > 0) translations.push(t("publications.procedureTypeTitle") + ":" + input.procedureTypes.join(", "));

    if (input.contractingAuthorityTypes && input.contractingAuthorityTypes.length > 0) translations.push(t("publications.contractingAuthorityTitle") + ":" + input.contractingAuthorityTypes.map(c => t("contractingAuthorityTypes." + c)).join(", "));
    if (input.publicationRadius) translations.push(t("publications.publicationRadiusTitle") + ":" + (input.publicationRadius === "EUROPEAN_UNION" ? t("publications.yes") : t("publications.no")));
    if (input.containsFields && input.containsFields.includes("addenda")) translations.push(t("publications.addenda") + ":" + t("publications.yes"));
    if (input.doesNotContainFields && input.doesNotContainFields.includes("addenda")) translations.push(t("publications.addenda") + ":" + t("publications.no"));

    if (input.subjectCodes && input.subjectCodes.length > 0) translations.push(t("publications.subjectCodesTitle") + ":" + input.subjectCodes.map(c => c.code).join(", "));
    if (input.regionCodes && input.regionCodes.length > 0) translations.push(t("publications.regionCodesTitle") + ":" + input.regionCodes.map(c => c.code).join(", "));
    if (input.accreditations && input.accreditations.length > 0) translations.push(t("publications.accreditationsTitle") + ":" + input.accreditations.map(c => c.code).join(", "));
    return translations;
}

interface ExportPopupProps {
    downloadExport: () => void;
    closeExport: () => void;
}

const ExportPopup: React.FC<ExportPopupProps> = ({downloadExport, closeExport}) => {
    return <>
        <div className="awards-modal-wrapper">
            <div className="awards-modal">
                <div className="header-alignment">
                    <p>Export to excel</p>
                    <div onClick={closeExport}>
                        <CloseIcon/>
                    </div>
                </div>
                <div className="body-text">
                    <span>Your export is ready to download</span>
                </div>
                <div className="fill-button">
                    <button onClick={downloadExport}>Download</button>
                </div>
            </div>
        </div>
    </>
}

