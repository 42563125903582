import React, {useEffect, useState} from "react";
import "./login.scss";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {showCustomErrorPopup} from "../../hooks/slices/snaccSlice";
import {useTranslation} from "react-i18next";
import {Form} from "../../components/form/Form";
import {updateToken, usePostLoginMutation} from "../../hooks/slices/loginSlice";
import {Link, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {persistor} from "../../app/store";
import {EmailUtil} from "../../utils/email";
import {ValidatedInput} from "../../components/validatedInput/validatedInput";
import {RaiseUser} from "../../hooks/raiseUser";
import {DateUtil} from "../../utils/date";

export const Login: React.FC = () => {
    let navigate = useNavigate();
    let sessionToken = useAppSelector((state) => state.login.parsedToken);
    let localToken = localStorage.getItem("token");
    let tokenExpired = sessionToken && sessionToken.exp < DateUtil.today().unix();
    let user = RaiseUser();
    const dispatch = useAppDispatch();
    const [postLogin, {data: rawToken, isSuccess, isError, error}] = usePostLoginMutation();
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [invalidPassword, setInvalidPassword] = useState(false);
    // on submission of the login form, we call the postLogin trigger to
    // submit the username/password to nighteyes and wait for a JWT in return
    //note that username is always an email address
    const onSubmit = (username: string, password: string) => {
        if (!EmailUtil.emailAddressIsValid(username)) {
            dispatch(showCustomErrorPopup(t("settings.invalid_email_address")));
            setInvalidEmail(true);
        }
        postLogin({username, password});
    };
    const {t} = useTranslation();
    let path = window.location.pathname;
    useEffect(() => {
        if (user.user && !tokenExpired && sessionToken && localToken) {
            navigate("/opportunities");
        }
    }, [user, localToken, sessionToken]);

    useEffect(() => {
        if (isSuccess && rawToken) {
            // pass the data from the successful login to the redux store
            persistor
                .purge() // resets the state store, in case there is already another client in the store
                .then(() => {
                    persistor.flush();
                })
                .then(() => dispatch(updateToken(rawToken)));
            //put token in local storage for cross-browser use
            localStorage.setItem("token", rawToken);
            if (path === "/login") navigate("/opportunities");
            if (path === "/opportunities") navigate("/opportunities");
        } else if (isError && error) {
            // show an error on the snackbar with a failed login
            persistor.purge(); //this resets the state store
            setInvalidEmail(true);
            setInvalidPassword(true);
            dispatch(showCustomErrorPopup(t("login.wrong_credentials")));
        }
    }, [isSuccess, rawToken, isError, error, dispatch]);


    return (
        <>
            <LoginForm onSubmit={onSubmit} invalidEmail={invalidEmail} invalidPassword={invalidPassword}/>
        </>
    );
};

interface LoginFormProps {
    onSubmit: (username: string, password: string) => void;
    invalidEmail: boolean;
    invalidPassword: boolean;
}

const LoginForm: React.FC<LoginFormProps> = ({onSubmit, invalidEmail, invalidPassword}) => {
    const {t} = useTranslation();
    const [searchParams] = useSearchParams();
    const [username, setUsername] = useState("");
    const onUsernameChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setUsername(e.currentTarget.value);
    };

    const [password, setPassword] = useState("");
    const onPasswordChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setPassword(e.currentTarget.value);
    };

    const location = useLocation();
    const tenderUuid: string | undefined = location.pathname.startsWith("/tender/")
        ? location.pathname.split("/")[2]
        : undefined;
    let signupStartUrl = "/signup-start";
    if (tenderUuid) signupStartUrl = signupStartUrl + "?tenderUuid=" + tenderUuid;

    return (
        <div>
            <div className="layout-main-banner">
                <div className="container">
                    <div className={"layout-top-alignment"}>
                        <div className="login-box" >
                            <div className="page-title">
                                <h1>{t("login.welcome_back")}</h1>
                                <p>{t("login.welcome_back_message")}</p>
                            </div>
                            <Form
                                onSubmit={() => {
                                    onSubmit(username, password);
                                }}
                            >
                                <ValidatedInput
                                    value={username}
                                    placeholder={t("preferences.emailAddress")}
                                    isInvalid={invalidEmail}
                                    onChange={onUsernameChange}
                                    testId={"login-email"}
                                />
                                <ValidatedInput
                                    value={password}
                                    placeholder={t("preferences.password")}
                                    isInvalid={invalidPassword}
                                    onChange={onPasswordChange}
                                    type={"password"}
                                    testId={"login-password"}
                                />
                                <Link to="/forgot-password">{t("login.forgot_password")}</Link>
                                <div className="login-button">
                                    <div className="fill-button">
                                        <button type="submit" data-testid={"login-submit"}>{t("login.logIn")}</button>
                                    </div>
                                </div>
                            </Form>
                            <div className="have-a-account">
                                <p>
                                    {t("login.no_account")} <Link to={signupStartUrl}
                                                                  data-testid={"signup-start-button"}>{t("login.register")}</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
