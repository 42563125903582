import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface VersionState {
  version?: string;
}

const initialState: VersionState = {};

export const versionSlice = createSlice({
  initialState,
  name: "version",
  reducers: {
    updateVersion(state, action: PayloadAction<string>) {
        state.version = action.payload;
    },
  }
});

export const { updateVersion } = versionSlice.actions;

