import React from "react";
import "./policies.scss";
import { useTranslation } from "react-i18next";
import {t} from "i18next";
import {useNavigate} from "react-router-dom";

export const PrivacyPolicyPage: React.FC = () => {
    const navigate = useNavigate();
    return (
    <div className="conditions-page-box">
      <div className="conditions-box">
        <PrivacyPolicyWindow />
        <div className="fill-button center-bottom">
          <button onClick={() => navigate("/opportunities")}>{t("intake.close")}</button>
        </div>
      </div>
    </div>
  );
};

export const PrivacyPolicyWindow: React.FC = () => {
  return (
    <>
      <div className={"policy container"}>
        <PrivacyPolicy></PrivacyPolicy>
      </div>
    </>
  );
};

export const PrivacyPolicy: React.FC = () => {
    const {i18n} = useTranslation();
    const lang = i18n.language; // something like en-US
    const languageIso = lang.split('-')[0].toLowerCase(); // something like en
    switch (languageIso) {
        case 'en':
            return <PrivacyPolicyEN/>;
        case 'fr':
            return <PrivacyPolicyEN/>;
        case 'nl':
            return <PrivacyPolicyEN/>;
        default:
            return <PrivacyPolicyEN/>; // Default to English if language is not matched
    }
};

const PrivacyPolicyEN: React.FC = () => {
    return <>
        <p>

            IF, AND ONLY TO THE EXTENT, DATAWOODS BV PROCESSES ANY PERSONAL DATA OF THIRD PARTIES ON CUSTOMER&rsquo;S
            BEHALF WHEN RENDERING ANY SERVICES, DATAWOODS BV AND CUSTOMER RECORD THEIR INTENTION THAT, RELATING TO THE
            PROCESSING OF THE PERSONAL DATA OF THESE THIRD PARTIES, CUSTOMER WILL BE THE DATA CONTROLLER AND DATAWOODS
            BV WILL BE A MERE DATA PROCESSOR.

        </p>
        <h4 id="h.6244iet4ifvm">
            1. Definitions
        </h4>
        <p>
            1.1. &ldquo;DATAWOODS BV&rdquo;: DATAWOODS BV BV with enterprise number BE 0539. 826. 972 and registered
            office at Tichelrei 82, 9000 Gent, Belgium;

        </p>
        <p>
            1.2. &ldquo;Personal Data&rdquo;: any information relating to an identified or identifiable natural person;
            an identifiable natural person is one who can be identified, directly or indirectly, in particular by
            reference to an identifier such as a name, an identification number, location data, an online identifier or
            to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or
            social identity of that natural person;

        </p>
        <p>
            1.3. &ldquo;Customer&rdquo;: any existing person or company using the DATAWOODS BV Software for its
            legitimate purposes;

        </p>
        <p>
            1.4. &ldquo;GDPR&rdquo;: Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April
            2016 on the protection of natural persons with regard to the processing of personal data and on the free
            movement of such data.

        </p>
        <h4 id="h.6e1vcdushs0m">
            <span>2. Customer data</span>
        </h4>
        <p>
            2.1. In order to allow Customer to use the DATAWOODS BV software, DATAWOODS BV processes a limited number of
            Personal Data received from the Customer:

        </p>
        <p>
            2.1.1. Information provided in order to receive a license on DATAWOODS BV Software, namely, company name,
            VAT number, name of the authorized representative, technical or non-technical contact persons, address,
            e-mail addresses, phone numbers, account number for invoicing;

        </p>
        <p>
            2.1.2. Communication and correspondence with the Customer.
        </p>
        <p>
            2.2. If information is provided by a Customer via a form, the fields indicated with a (*) strictly are
            necessary for the purposes for which they are processed.

        </p>
        <h4 id="h.8znfd6ke1su1">
            <span>3. DATAWOODS BV software</span>
        </h4>
        <p>
            3.1. Insofar as DATAWOODS BV processes the Personal Data of third parties provided to Customer, the Parties
            confirm that when Processing these Personal Data of third parties, Customer shall be the Controller, and
            DATAWOODS BV shall be the Processor.

        </p>
        <p>
            3.2. Customer confirms that third parties whose Personal Data are processed have been informed and, where
            necessary, have given their consent.

        </p>
        <p>
            3.2.1 Customer acknowledges and agrees that the personal data may be transferred or stored in any country of
            the European Union.

        </p>
        <p>
            3.2.2 Customer will ensure that it is entitled to transfer the relevant personal and confidential data to
            DATAWOODS BV, so that DATAWOODS BV may lawfully use and process the personal data for the purposes of
            rendering services.

        </p>
        <p>
            3.2.3 Customer will ensure either (i) that the third parties whose personal data are processed have
            authorized such use, processing, storage and transfer as compliant with applicable data protection
            legislation and industry standards or (ii) if necessary, personal data are delivered
              &lsquo;de-identified&rsquo;, meaning that the personal identifiers have been extracted from all personal
            data provided to DATAWOODS BV.

        </p>
        <p>
            3.2.4 Customer acknowledges and agrees that the personal data will be shared with DATAWOODS BV&rsquo;s
            employees, representatives, officers, directors, agents, advisors, affiliates subcontractors and consultants
            who have a need to know such data for the purposes of rendering services.

        </p>
        <p>
            3.3 DATAWOODS BV processes Personal Data to carry out the agreement between DATAWOODS BV and Customer. The
            Personal Data are used to:

        </p>
        <p>
            3.3.1 Offer or execute the license of the DATAWOODS BV software;
        </p>
        <p>
            3.3.2 Allow Customers to use the DATAWOODS BV software;
        </p>
        <p>
            3.3.3 Audit the use of the DATAWOODS BV software;
        </p>
        <p>
            3.3.4 Manage incidents notified by Customer when using the DATAWOODS BV software;
        </p>
        <p>
            3.3.5 Notify Customer on the updates or upgrades to the DATAWOODS BV software;
        </p>
        <p>
            3.3.6 Perform account administration;
        </p>
        <p>
            3.3.7 Comply with applicable law.
        </p>
        <p>
            3.4 If the processing of Personal Data is based on the Customer&rsquo;s consent, Customer has the right to
            withdraw the consent at any time, without this affecting the legality of the processing on the basis of the
            permission before its withdrawal.

        </p>
        <h4 id="h.kadr3thkk3m4">
            <span>4. Disclosure of your information</span>
        </h4>
        <p>
            4.1. DATAWOODS BV does not sell or rent the Personal Data.
        </p>
        <p>
            4.2. DATAWOODS BV may use sub-processors to process Personal Data of the Customer.
        </p>
        <p>
            4.3. DATAWOODS BV may disclose or share Customer&rsquo;s Personal Data when required by law, or to enforce
            or apply DATAWOODS BV&rsquo;s Terms of License. This includes exchanging information with third party
            companies and organisations for the purposes of fraud protection and credit risk reduction.

        </p>
        <p>
            4.4. DATAWOODS BV can change its corporate structure by changing its legal form, merger, acquisitions and
            sales. In such transactions, Personal Data may be transmitted in accordance with this Privacy Statement and
            the applicable data protection legislation.

        </p>
        <h4 id="h.laj2vgwgtwsa">
            <span>5. Storage and retention of Personal Data</span>
        </h4>
        <p>
            5.1. The Personal Data that DATAWOODS BV collects from Customer are encrypted and anonymized.
        </p>
        <p>
            5.2. The Personal Data that DATAWOODS BV collects from Customer is stored on information technology systems
            located in the European Union which are operated by DATAWOODS BV.

        </p>
        <p>
            5.3. Although DATAWOODS BV gives its best effort in protecting Customer&rsquo;s Personal Data, DATAWOODS BV
            cannot guarantee the total security of Personal Data and any transmission is at Customer&rsquo;s risk.

        </p>
        <p>
            5.4. DATAWOODS BV takes appropriate technical and organizational measures to prevent unauthorized access to
            the Personal Data received.

        </p>
        <p>
            5.5. Upon instructions of the Customer and at the end of the Service, DATAWOODS BV will delete all Personal
            Data.

        </p>
        <h4 id="h.kn41y6bq8pb3">
            <span>6. Aggregated statistics</span>
        </h4>
        <p>
            6.1. DATAWOODS BV may collect statistics about the behaviour of Customers use of DATAWOODS BV software.

        </p>
        <p>
            6.2. DATAWOODS BV may retain and store personal data of Customer and personal data of third parties provided
            by Customer for as long as reasonably needed to render the services to Customer. However, upon expiration of
            the aforesaid period DATAWOODS BV will be entitled to keep storing and using said personal data for
            statistical, internal product and service development, research and scientific purposes, provided that these
            personal data are anonymized. Customer ensures that the consent from any third parties to process personal
            data includes the consent for the processing of personal data for statistical, internal product and service
            development, research and scientific purposes.

        </p>
        <p>
            6.3. DATAWOODS BV may provide its Customers or third parties with reports based on personal data that are
            anonymized.

        </p>
        <h4 id="h.trqqzzl0gduv">
            <span>7. Data Subject rights</span>
        </h4>
        <p>
            7.1. If Customer receives a request from a Data Subject exercising a right under the GDPR and relating to
            Personal Data processed by DATAWOODS BV, DATAWOODS BV shall assist Customer to the extent necessary and
            reasonable to comply with the Data Subject&rsquo;s request. If Customer as the Controller instructs
            DATAWOODS BV to do so, the Processor shall correct, delete, or process the Data Subject&rsquo;s data as
            requested by the latter.

        </p>
        <p>
            7.2. If a Data Subject approaches DATAWOODS BV directly regarding the Processing of Personal Data, DATAWOODS
            BV will refer the Data Subject to Customer. DATAWOODS BV will not process the request of the Data Subject
            itself, unless instructed otherwise by Customer.

        </p>
        <h4 id="h.wd7ngxesm8fc">
            <span>8. Customer rights</span>
        </h4>
        <p>
            8.1. Customers have the right to request to review, rectify or erase Personal Data or to limit the
            processing of their Personal Data, as well as the right to objection to processing of the Personal Data and
            the right to request data transferability.

        </p>
        <p>
            8.2. All these requests should be addressed to info@tenderwolf.com
        </p>
        <h4 id="h.xo71oo6ldw91">
            <span>9. Data Protection Impact Assessment</span>
        </h4>
        <p>
            9.1. DATAWOODS BV shall provide reasonable assistance to Customer in relation to the Data Protection Impact
            Assessment and consultation with the Supervisory Authority, if Customer reasonably believes that this is
            required pursuant to Article 35 or 36 of the GDPR, in each case exclusively in relation to the Processing of
            Personal Data of Customer and taking into account the nature of the Processing and the information available
            to DATAWOODS BV.

        </p>
        <p>
            9.2. DATAWOODS BV shall provide the necessary support as reasonably requested by Customer when Customer is
            required to cooperate with any assessment, investigation, notification, or request under the GDPR.

        </p>
        <h4 id="h.grc6fwb5ulva">
            <span>10. Audit</span>
        </h4>
        <p>
            10.1. At Customer&rsquo;s request, DATAWOODS BV shall disclose all information that is necessary to prove
            that DATAWOODS BV is complying with its obligations under this Privacy Statement.

        </p>
        <p>
            10.2. DATAWOODS BV shall cooperate with any audits conducted by Customer and related to the obligations
            under this Privacy Statement, provided that:

        </p>
        <p>
            10.2.1 Customers with a temporary trial license cannot request DATAWOODS BV&rsquo;S cooperation with any
            audits, only paying Customers can request DATAWOODS BV&rsquo;S cooperation with any audits;

        </p>
        <p>
            10.2.2 The number of audits is limited to one (1) audit per calendar year, except if Customer is required to
            do so by a Supervisory Authority;

        </p>
        <p>
            10.2.3 The audit takes place within office hours and with reasonable prior notice to DATAWOODS BV of at
            least three (3) working days.

        </p>
        <h4 id="h.o3m4zgckahee">
            <span>11. Personal data breach</span>
        </h4>
        <p>
            11.1. DATAWOODS BV shall, without undue delay and if possible, within seventy-two (72) hours of the Breach,
            notify Customer of a Breach relating to Customer&rsquo;s Personal Data.

        </p>
        <p>
            11.2. DATAWOODS BV shall inform Customer adequately to allow Customer to inform the Data Subject or the
            Supervisory Authority as regards the Breach under the GDPR and to fulfil all obligations relating to the
            investigation, litigation, and resolution of a Breach.

        </p>
        <p>
            11.3. The Processor shall state at least the following in this notification: (i) nature of the incident;
            (ii) when it was identified; (iii) Personal Data affected; (iv) reasonable measures taken to limit
            additional damage.

        </p>
        <p>
            11.4. Unless the GDPR obliges DATAWOODS BV to do so, or it is expressly required to do so by a Controller,
            the Processor shall not disclose any information regarding a Personal Data Breach to any third party.

        </p>
        <p>
            11.5. It is Customer&rsquo;s responsibility as the Controller to report any Breach to the Supervisory
            Authority.

        </p>
        <h4 id="h.f5gb1lo8id1">
            <span>12. Duration and erasure or return of Personal Data</span>
        </h4>
        <p>
            12.1 Three (3) years after termination of the subscription, DATAWOODS BV shall erase or return all of
            Customer&rsquo;s Personal Data, except if the law of the European Union or a Member State requires the
            Personal Data to be saved.

        </p>
        <h4 id="h.dobjr8npn692">
            <span>13. Changes to our Privacy Statement</span>
        </h4>
        <p>
            13.1 DATAWOODS BV has the right to make changes to this Privacy Statement.
        </p>
        <p>
            13.2 Any changes DATAWOODS BV may make to the Privacy Statement in the future will be notified to Customer
            by email.

        </p>

    </>
    }

