export enum SettingTab {
    ACCOUNT= "account",
    PASSWORD = "password",
    COMPANY="company",
    INVOICE="invoice",
    LABELS="labels",
    EMAIL="email",
    PROFILES="profiles",
    COUNTRIES="countries",
    PLAN="plan",
    FEEDBACK="feedback",
    HELP="help",
    MASQUERADE="masquerade",
}