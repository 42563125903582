import { FetchArgs } from "@reduxjs/toolkit/dist/query";
import { apiSlice } from "./apiSlice";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import { TenderSearch } from "./tenderSearchSlice";

/*
  REDUX STORE stuff
*/

export interface TenderSearchesState {
  tenderSearches?: TenderSearch[];
  curatedTenderSearches?: CuratedTenderSearch[];
}

const initialState: TenderSearchesState = {};

export const savedSearchesSlice = createSlice({
  initialState,
  name: "savedSearches",
  reducers: {
    updateTenderSearchesState(state, action: PayloadAction<TenderSearchesState>) {
      if (action.payload.tenderSearches) state.tenderSearches = action.payload.tenderSearches;
      if (action.payload.curatedTenderSearches) state.curatedTenderSearches = action.payload.curatedTenderSearches;
    },
    addTenderSearchToState(state, action: PayloadAction<TenderSearch>) {
      if (action.payload.uuid) state.tenderSearches = [...state.tenderSearches ?? [], action.payload];
    },
    deleteTenderSearchFromState(state, action: PayloadAction<TenderSearch>) {
      if (action.payload.curatedProfileId && state.curatedTenderSearches) {
        state.curatedTenderSearches = state.curatedTenderSearches.filter((p) => p.id !== action.payload.curatedProfileId)
      }
      if (action.payload.uuid && action.payload.userUuid && state.tenderSearches) {
        state.tenderSearches = state.tenderSearches.filter((p) => p.uuid !== action.payload.uuid)
      };
    },
    resetTenderSearchesState(state) {
      state.tenderSearches = undefined;
      state.curatedTenderSearches = undefined;
    },
    updateIndividualTenderSearchState(state, action: PayloadAction<TenderSearch>) {
      if (state.tenderSearches) {
        let index = state.tenderSearches.findIndex((p) => p.uuid === action.payload.uuid);
        state.tenderSearches[index] = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      state.tenderSearches = initialState.tenderSearches;
      state.curatedTenderSearches = initialState.curatedTenderSearches;
    });
  },
});

export const { deleteTenderSearchFromState, addTenderSearchToState, resetTenderSearchesState , updateIndividualTenderSearchState,
  updateTenderSearchesState} = savedSearchesSlice.actions;

/*
  API connection stuff
*/

export interface GetSavedSearchesParams {
  userUuid: string;
}

export interface GetSavedSearchesOutput {
  tenderSearches: TenderSearch[];
  curatedTenderSearches: CuratedTenderSearch[];
}

export interface CuratedUserProfile {
  id: number;
  dutchName: string;
  englishName: string;
  frenchName: string;
}
export interface CuratedTenderSearch extends CuratedUserProfile{
    tenderSearches: TenderSearch[];
}

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getSavedSearches: build.query<GetSavedSearchesOutput, GetSavedSearchesParams>({
      query: (params: GetSavedSearchesParams) => {
        let args: FetchArgs = {
          url: `/shaggy/saved_searches`,
        };
        return args;
      },
    }),
    postSavedSearch: build.mutation<TenderSearch, TenderSearch>({
      query: (body: TenderSearch) => {
        let args: FetchArgs = {
          url: `/shaggy/saved_searches`,
          method: "POST",
          body: body,
        };
        return args;
      },
    }),
    deleteSavedSearch: build.mutation<void, TenderSearch>({
      query: (body: TenderSearch) => {
        let args: FetchArgs = {
          url: `/shaggy/saved_searches`,
          method: "DELETE",
          body: body,
        };
        return args;
      },
    }),
    putSavedSearch: build.mutation<TenderSearch, TenderSearch>({
      query: (body: TenderSearch) => {
        let args: FetchArgs = {
          url: `/shaggy/saved_searches`,
          method: "PUT",
          body: body,
        };
        return args;
      },
    }),
  }),
});

export const {
  endpoints,
  useGetSavedSearchesQuery,
  usePostSavedSearchMutation,
  useDeleteSavedSearchMutation,
    usePutSavedSearchMutation,
} = extendedApiSlice;