import React from "react";
import { SearchFilter } from "../../../consts/SearchFilter";
import {
    GenericSearchPage,
    SearchConfiguration,
    SearchResultsConfiguration,
    SearchSidebarConfiguration
} from "../GenericSearchPage";
import { SearchFilterState } from "../../../hooks/slices/tenderSearchSlice";
import { ErrorPage } from "../../error";
import { SearchType } from "../../../consts/searchType";
import { Feature, FeatureName } from "../../../consts/features";
import { RaiseCurrentPlan } from "../../../hooks/raiseCurrentPlan";
import { DateToSortBy } from "../../../consts/dateToSortBy";
import { FeatureUtil } from "../../../utils/features";
import { TenderCardConfiguration } from "../results/body/TenderCard";
import { SearchFilterUtil } from "../../../utils/searchFilters";
import { useTranslation } from "react-i18next";
import { SortBy } from "../../../consts/SortBy";

export const SearchPage: React.FC = () => {
    const {t} = useTranslation();
    const features: Feature[] | undefined = RaiseCurrentPlan().currentPlan?.features;
    const preSelectedFilters: SearchFilter[] = []
    const hiddenFilters: SearchFilter[] = getHiddenFilters();
    const lockedFilters: SearchFilter[] = [...hiddenFilters];
    const userCanSearchAddenda: boolean = FeatureUtil.hasFeature(features, FeatureName.SEARCH_ADDENDA);
    if(userCanSearchAddenda) preSelectedFilters.push(SearchFilter.FIELD_GROUP_ADDENDA);
    const tenderCardConfiguration: TenderCardConfiguration = {
        showProgress: false,
        showDescription: true,
        showCompanies: true,
        showCueHighlights: true,
        showQueryHighlights: false,
        showAddendumHighlights: userCanSearchAddenda,
        showLatestPublicationTypeInUpperRightCorner: true,
        showPublicationDetails: true,
        showTags: true,
        showLeadsIcon: true,
        showFollowIcon: true,
        showDeleteOpportunityIcon: false,
    }
    const searchViewConfiguration: SearchResultsConfiguration = {
        showResponseTime:true,
        showFilterButtons: true,
        showExportButton: true,
        showLabelAggregations: true,
        showCueAggregations: false,
        hiddenFilters: hiddenFilters,
        lockedFilters: lockedFilters,
    }
    const searchFilterState: SearchFilterState = {
        query:"", //this is overwritten if there is a previous input in the store
        page: 0,
        pageSize: 10,
        sortBy: SortBy.RELEVANCE,
        dateToSortBy: DateToSortBy.PUBLICATION_DATE_DESC,
        selectedFilters: preSelectedFilters,
    }
    const searchSidebarConfiguration: SearchSidebarConfiguration = {
        queryType: SearchType.SEARCH,
        queryPlaceHolder: t('searchFilterTags.search'),
        showLabels: true,
        showAccreditations: true,
        showSearchSectorFilter: true,
        hiddenFilters: hiddenFilters,
        lockedFilters: lockedFilters,
    }
    const searchConfiguration: SearchConfiguration = {
        sidebar: searchSidebarConfiguration,
        info: searchViewConfiguration,
        tenderCard: tenderCardConfiguration,
    }
    if(features !== undefined && !FeatureUtil.hasFeature(features, FeatureName.SEARCH_TAB)) {
        return <ErrorPage errorCode={403}></ErrorPage>
    } else return <GenericSearchPage
        initialSearchFilterState={searchFilterState}
        searchConfiguration={searchConfiguration}/>
}

function getHiddenFilters(): SearchFilter[]{
    const hiddenFilters: SearchFilter[] = [];
    SearchFilterUtil.getAwardOrOpeningReportPublicationDateFilters().forEach((filter) => hiddenFilters.push(filter));
    return hiddenFilters;
}



