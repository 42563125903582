import {SearchFilter} from "../../../../consts/SearchFilter";
import {SearchSidebarConfiguration} from "../../GenericSearchPage";
import {SearchFilterUpdateProps} from "../../../../hooks/slices/tenderSearchSlice";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../app/hooks";
import {RaiseUser} from "../../../../hooks/raiseUser";

interface DropdownProps {
    label?: string;
    relevantFilters: SearchFilter[];
    searchSidebarConfiguration: SearchSidebarConfiguration;
    updateSearchFilters: (updateProps: SearchFilterUpdateProps) => void;
    getAggregation: (searchFilter: SearchFilter) => string;
    frozen?: boolean;
    optionsAreExclusive?: boolean;
    changeDropdownFilter: (newFilter: SearchFilter, dropdownFilters: SearchFilter[], currentlySelectedFilters: SearchFilter[])=>SearchFilter[];
}

export const Dropdown: React.FC<DropdownProps> = (props) => {

    let {t} = useTranslation();
    const searchFilters = useAppSelector((state) => state.tenderSearch.searchFilters);
    let userInteractions = RaiseUser().user?.userInteractions ?? [];
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const hiddenFilters = props.searchSidebarConfiguration.hiddenFilters ? props.searchSidebarConfiguration.hiddenFilters : [];
    const relevantFilters = props.relevantFilters.filter(f => !hiddenFilters.includes(f));
    if (relevantFilters.length === 0) return <></>;

    const getDropdownValues = () => {
        return relevantFilters.filter(f => f !== getCurrentSelectedElement());
    }

    const getCurrentSelectedElement = () => {
        let selectedRelevantFilters = relevantFilters.filter(f => searchFilters.selectedFilters.includes(f))
        if (selectedRelevantFilters.length === 1) return selectedRelevantFilters[0]
        else {
            return relevantFilters[0]
        }
    }

    const changeSelectedElement = (newFilter: SearchFilter) => {
        let newFilters = props.changeDropdownFilter(newFilter, relevantFilters, searchFilters.selectedFilters);
        props.updateSearchFilters({selectedFilters: newFilters, userInteractions: userInteractions});
        setDropdownOpen(false);
    }

    return (<>
        <div className={'relative-div'} data-cy={"dropdown"}>
            <div className={'dropdown-title'}>
                {props.label && <p className={''}>{props.label}</p>}
                <div className={'relative-div'}>
                    <button type={'button'}
                            onClick={() => setDropdownOpen(!dropdownOpen)}
                            onBlur={() => setDropdownOpen(false)}
                            data-cy={"dropdown-selected-element"}
                    >
                        <span>{t("searchFilters." + getCurrentSelectedElement())}</span>
                        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9.87998 0.292988L5.99998 4.17299L2.11998 0.292988C1.72998 -0.0970117 1.09998 -0.0970117 0.70998 0.292988C0.31998 0.682988 0.31998 1.31299 0.70998 1.70299L5.29998 6.29299C5.68998 6.68299 6.31998 6.68299 6.70998 6.29299L11.3 1.70299C11.69 1.31299 11.69 0.682988 11.3 0.292988C10.91 -0.0870117 10.27 -0.0970117 9.87998 0.292988Z"
                                fill="#51565F"></path>
                        </svg>
                    </button>
                </div>
            </div>
            <div className={dropdownOpen ? 'dropdown-alignment dropdown-show' : 'dropdown-alignment dropdown-hidden'}>
                <div className={'dropdown-design'}>
                    {getDropdownValues().map((dropdownElement, i) =>
                        <span key={i}
                              onClick={() => changeSelectedElement(dropdownElement)}
                              data-cy={"dropdown-unselected-element"}>
                            {t("searchFilters." + dropdownElement)}
                        </span>
                    )}
                </div>
            </div>
        </div>
    </>)
}