import React, { useEffect, useState } from "react";

import "./plan.scss";
import "./pricingCard.scss";
import { useTranslation } from "react-i18next";
import { CheckIcon } from "../../../components/icons";
import {
    PlanDto,
    PlanTemplateDto,
    useGetAvailablePlanTemplatesQuery,
    useGetCurrentAndNextPlanQuery,
    useGetPlanChangeInfoQuery,
    usePostPlanMutation
} from "../../../hooks/slices/currentPlanSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { ErrorPage } from "../../error";
import { FeatureUtil } from "../../../utils/features";
import { Feature, FeatureName } from "../../../consts/features";
import { PlanUtil } from "../../../utils/plans";
import { AlertPopup, ConfirmPopup } from "../../../components/popup/Popup";
import { showErrorPopup, updateSnacc } from "../../../hooks/slices/snaccSlice";
import {Loader} from "../../../components/loader";
import { Toggle } from "../../../components/toggle/Toggle";
import { AccountSettingsFormSkeleton } from "../accountSettingsFormSkeleton";
import { RaiseClient } from "../../../hooks/raiseClient";
import { Client } from "../../../types/client";

export const Plan: React.FC = () => {
  const { t } = useTranslation();
  const [changePlan, setChangePlan] = useState(false);
  const { data: availablePlanTemplates } = useGetAvailablePlanTemplatesQuery();
  const {
    data: currentAndNextPlan,
    error: fetchCurrentAndNextPlanError,
    isLoading: currentAndNextPlanIsLoading,
    isSuccess: currentAndNextPlanIsSuccess,
    refetch: refetchCurrentAndNextPlan,
  } = useGetCurrentAndNextPlanQuery();
  const clientUuid: string | undefined = useAppSelector((state) => state.user.clientUuid);
  if (fetchCurrentAndNextPlanError) return <ErrorPage />;
  let currentPlan = currentAndNextPlan ? currentAndNextPlan.currentPlan : undefined;
  let nextPlan = currentAndNextPlan ? currentAndNextPlan.nextPlan : undefined;
  if (
    currentPlan !== undefined &&
    currentPlan.features !== undefined &&
    clientUuid !== undefined &&
    availablePlanTemplates !== undefined
  ) {
    return (
      <div>
        <div className="plan-section-alignment">
          <div className="page-alignment">
            <div>
              <h1>{t("settings.plan")}</h1>
            </div>
          </div>
          {changePlan ? (
            <ChangePlan
              setChangePlan={setChangePlan}
              availablePlanTemplates={availablePlanTemplates.planTemplates}
              currentPlan={currentPlan}
              nextPlan={nextPlan}
              refetchCurrentAndNextPlan={refetchCurrentAndNextPlan}
            />
          ) : (
            <CurrentPlan
              setChangePlan={setChangePlan}
              availablePlanTemplates={availablePlanTemplates.planTemplates}
              currentPlan={currentPlan}
              nextPlan={nextPlan}
            />
          )}
        </div>
      </div>
    );
  } else return <AccountSettingsFormSkeleton inputElements={6} />;
};

interface PlanProps {
    setChangePlan: (changePlan: boolean) => void;
    availablePlanTemplates: PlanTemplateDto[];
    currentPlan: PlanDto;
    nextPlan?: PlanDto;
}

const CurrentPlan: React.FC<PlanProps> = ({setChangePlan, currentPlan, nextPlan, availablePlanTemplates}) => {

    const {t} = useTranslation();
    const currentPlanTemplateDto = PlanUtil.findPlanTemplate(currentPlan.planTemplateId, availablePlanTemplates);
    const planName: string = currentPlanTemplateDto ? PlanUtil.getPlanTemplateName(currentPlanTemplateDto) : currentPlan.name;
    const nextPlanTemplateDto = PlanUtil.findPlanTemplate(nextPlan ? nextPlan.planTemplateId : currentPlan.fallBackPlanTemplateId, availablePlanTemplates);
    const nextPlanName = PlanUtil.getNextPlanName(currentPlan, currentPlanTemplateDto, nextPlanTemplateDto);
    let planFrequency: string | undefined;
    if (currentPlanTemplateDto) {
        switch (currentPlanTemplateDto.duration.type) {
            case "YEAR":
                planFrequency = t("settings.plan_frequency_yearly");
                break;
            case "MONTH":
                planFrequency = t("settings.plan_frequency_monthly");
                break;
        }
    }
    return <div>
        <div className="plan-details-alignment">
            <h2>{t("settings.current_plan")}</h2>
        </div>
        <div className="plan-grid">
            <div className="plan-grid-items">
                <div className="plan-box">
                    <h3>{planName}</h3>
                    <div className="image-center-alignment">
                        <img src={PlanUtil.getPlanImage(currentPlanTemplateDto)} alt="BasicPlanImage"/>
                    </div>
                    <div className="per-month-plan">
                        <h1>
                            € {currentPlan.price}
                        </h1>
                    </div>
                    <FeatureList features={currentPlan.features} plan={currentPlan}/>
                </div>
            </div>
            <div className="plan-grid-items">
                {currentPlan.type !== "FREE" && <div className="latest-payment-box">
                    <div className="box-title">
                        <h1>{t("settings.plan_information")}</h1>
                    </div>
                    <div className="box-body">
                        {planFrequency && <div className="all-text-alignment">
                            <p>{t("settings.plan_frequency")}</p>
                            <span>{planFrequency}</span>
                        </div>}
                        <div className="all-text-alignment">
                            <p>{t("settings.plan_renewal_date")}</p>
                            <span>{currentPlan.endDate}</span>
                        </div>
                        <div className="all-text-alignment">
                            <p>{t("settings.next_plan_name")}</p>
                            <span>{nextPlanName}</span>
                        </div>
                    </div>
                </div>}
                <p className={"change-plan-prompt"}>
                    {t("settings.change_plan_prompt")}{" "}
                    <a onClick={() => setChangePlan(true)} data-testid={"change-price-plan-button"}>{t("settings.click_here")}</a>
                </p>
            </div>
        </div>
    </div>
}

interface PlanCardProps {
    updatePlan: () => void;
    planTemplateDto: PlanTemplateDto;
    currentPlan: PlanDto;
}

const PlanCard: React.FC<PlanCardProps> = ({planTemplateDto, currentPlan, updatePlan}) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    let client: Client | undefined = RaiseClient()?.client;
    const emptyOrNull = (value: string | undefined) => {return value == null || value.trim() === ""};
    const invoiceAddressIsIncomplete = (): boolean => {
        return client == null ||emptyOrNull(client.vatNumber)
    }
    const [postPlan, {data: postPlanData, isLoading: postPlanIsLoading, isSuccess: postPlanIsSuccess, isError: postPlanIsError, error: postPlanError}] = usePostPlanMutation();
    const confirmPlanChange = () => {
        postPlan({extraFeatures: [], planTemplateId: planTemplateDto.id, priceInEuro: planTemplateDto.price})
        // setShowChoosePlanConfirmationPopup(false);
        // setChangePlan(false);
    }

    const [showChoosePlanConfirmationPopup, setShowChoosePlanConfirmationPopup] = useState(false);
    const [showInvoiceAddressAlert, setShowInvoiceAddressAlert] = useState(false);
    const {data: planChangeInfo}  = useGetPlanChangeInfoQuery(planTemplateDto.id);
    let planChangeIsImmediate = planChangeInfo ? planChangeInfo.upgrade : false;

    const confirmPopupTitle = t("settings.change_plan");
    const confirmPopupMessagePartOne = t("settings.plan_change_message_part_one") + PlanUtil.getPlanTemplateName(planTemplateDto) + ". "
    const confirmPopupMessagePartTwo =
        planChangeIsImmediate
                ? t("settings.plan_change_message_part_two_upgrade")
                : t("settings.plan_change_message_part_two_downgrade") + currentPlan.endDate + ".";
    const confirmPopupMessage = confirmPopupMessagePartOne + confirmPopupMessagePartTwo;
    const noInvoiceAddressAlertTitle = t("settings.no_invoice_address_title");
    const noInvoiceAddressAlertMessage = t("settings.no_invoice_address_message");

    useEffect(() => {
        if (postPlanData && postPlanIsSuccess) {
            dispatch(updateSnacc({
                message: planChangeIsImmediate ? t("settings.plan_change_success") : t("settings.future_plan_change_success"),
                severity: "success",
                open: true
                }
            ))
            setShowChoosePlanConfirmationPopup(false);
            updatePlan(); //this navigates back to the current plan
        }
        else if(postPlanError) {
            dispatch(showErrorPopup(postPlanError));
            setShowChoosePlanConfirmationPopup(false);
        }

    }, [postPlanData, postPlanIsSuccess, postPlanError])

    const showChoosePlanConfirmationPopupOrInvoiceAddressAlert = () => {
        if (invoiceAddressIsIncomplete()) {setShowInvoiceAddressAlert(true)}
        else setShowChoosePlanConfirmationPopup(true);
    }

    return <>
        {postPlanIsLoading && <Loader/>}
        <div className="fix-width">
        <div className="grid-items" data-cy={"price-plan-card"}>
            <div className="child-box-height">
                <div className="card-title">
                    <p>{PlanUtil.getPlanTemplateName(planTemplateDto)}</p>
                </div>
                <div className="image-center-alignment">
                    <img src={PlanUtil.getPlanImage(planTemplateDto)} alt={"Plan image"}/>
                </div>
                <div className="plan-amount-height-box">
                    <h1>
                        € {planTemplateDto.price}
                    </h1>
                </div>
                <FeatureList features={planTemplateDto.features} plan={currentPlan}/>
            </div>
            <div className="fill-button">
                <button onClick={() => showChoosePlanConfirmationPopupOrInvoiceAddressAlert()} data-cy={"price-plan-card-submit-button"}>{t("settings.choose")}</button>
            </div>
        </div>
    </div>
        {showChoosePlanConfirmationPopup && <ConfirmPopup title={confirmPopupTitle} message={confirmPopupMessage}
                                                          confirm={confirmPlanChange} close={() => setShowChoosePlanConfirmationPopup(false)}/>}
        {showInvoiceAddressAlert && <AlertPopup title={noInvoiceAddressAlertTitle} message={noInvoiceAddressAlertMessage}
                                                          close={() => setShowInvoiceAddressAlert(false)}/>}
    </>
}

interface FeatureListProps {
    features: Feature[];
    plan: PlanDto;
}

const FeatureList: React.FC<FeatureListProps> = ({features, plan}) => {
    const {t} = useTranslation();
    let structuredFeatureNameList: string[] = [];

    if(FeatureUtil.hasFeature(features, FeatureName.UPDATE_MAIL_OPPORTUNITIES)) {
        const opportunitiesPerWeekLimit: number | undefined = FeatureUtil.getFeatureLimit(features, FeatureName.UPDATE_MAIL_OPPORTUNITIES);
        const opportunitiesPerWeek: string = opportunitiesPerWeekLimit ? opportunitiesPerWeekLimit + " " + t("features.update_mail_limited") : t("features.update_mail_unlimited")
        structuredFeatureNameList.push(opportunitiesPerWeek)
    }

    if(FeatureUtil.hasFeature(features, FeatureName.UPDATE_MAIL_DAILY)) {
        structuredFeatureNameList.push(t("features.update_mail_daily"));
    } else if(FeatureUtil.hasFeature(features, FeatureName.UPDATE_MAIL_WEEKLY)) {
        structuredFeatureNameList.push(t("features.update_mail_weekly"));
    }

    if (FeatureUtil.hasFeature(features, FeatureName.COUNTRIES)) {
        const countriesLimit = FeatureUtil.getFeatureLimit(features, FeatureName.COUNTRIES);
        let numberOfCountries: string;
        if (countriesLimit) {
            if (countriesLimit === 1) {
                numberOfCountries = "1 " + t("features.country");
            } else {
                numberOfCountries = countriesLimit + " " + t("features.countries");
            }
        } else {
            numberOfCountries = t("features.unlimited_countries");
        }
        structuredFeatureNameList.push(numberOfCountries);
    }

    if (FeatureUtil.hasFeature(features, FeatureName.CURATED_USER_PROFILES)) {
        const curatedUserProfilesLimit = FeatureUtil.getFeatureLimit(features, FeatureName.CURATED_USER_PROFILES);
        let numberOfCuratedUserProfiles: string;
        if (curatedUserProfilesLimit) {
            if (curatedUserProfilesLimit === 1) {
                numberOfCuratedUserProfiles = "1 " + t("features.curated_user_profile");
            } else {
                numberOfCuratedUserProfiles = curatedUserProfilesLimit + " " + t("features.curated_user_profiles");
            }
        } else {
            numberOfCuratedUserProfiles = t("features.curated_user_profiles_unlimited");
        }
        structuredFeatureNameList.push(numberOfCuratedUserProfiles);
    }

    if (FeatureUtil.hasFeature(features, FeatureName.CUSTOM_USER_PROFILES)) {
        const customSavedSearchesLimit = FeatureUtil.getFeatureLimit(features, FeatureName.CUSTOM_USER_PROFILES);
        let numberOfCustomSavedSearches: string;
        if (customSavedSearchesLimit) {
            if (customSavedSearchesLimit === 1) {
                numberOfCustomSavedSearches = "1 " + t("features.custom_user_profile");
            } else {
                numberOfCustomSavedSearches = customSavedSearchesLimit + " " + t("features.custom_user_profiles");
            }
        } else {
            numberOfCustomSavedSearches = t("features.custom_user_profiles_unlimited");
        }
        structuredFeatureNameList.push(numberOfCustomSavedSearches);
    }

    if (FeatureUtil.hasFeature(features, FeatureName.SEARCH_TAB)) {
        const searchRangeDays = FeatureUtil.getFeatureLimit(features, FeatureName.SEARCH_RANGE_DAYS);
        let searchRangeFeatureName: string;
        if (searchRangeDays) {
            if (searchRangeDays === 365) {
                searchRangeFeatureName = t("features.advanced_search") + " " + t("features.one_year_back");
            } else {
                searchRangeFeatureName = t("features.advanced_search") + " " + searchRangeDays + " " + t("features.days_back");
            }
        } else {
            searchRangeFeatureName = t("features.unlimited_advanced_search");
        }
        structuredFeatureNameList.push(searchRangeFeatureName);
    }

    if (FeatureUtil.hasFeature(features, FeatureName.LABELS)) {
        const labelsLimit = FeatureUtil.getFeatureLimit(features, FeatureName.LABELS);
        let labelFeatureName: string;
        if (labelsLimit) {
            labelFeatureName = labelsLimit + " " + t("features.labels");
        } else {
            labelFeatureName = t("features.unlimited_labels");
        }
        structuredFeatureNameList.push(labelFeatureName);
    }

    if (FeatureUtil.hasFeature(features, FeatureName.EXPORT_EXCEL)) {
        structuredFeatureNameList.push(t("features.export_to_excel"));
    }

    if (FeatureUtil.hasFeature(features, FeatureName.SEARCH_ADDENDA)) {
        structuredFeatureNameList.push(t("features.search_addenda"));
    }

    return <>
        {structuredFeatureNameList.map((name: string) => <div key={"plan_" + plan.id + "_feature_translation_" + name}><FeatureListItem name={name}/></div>
        )}
    </>;
}

interface FeatureListItemProps {
    name: string
}

const FeatureListItem: React.FC<FeatureListItemProps> = ({name}) => {
    return <div className="icon-text-alignment">
        <div>
            <CheckIcon/>
        </div>
        <div>
            <span>{name}</span>
        </div>
    </div>
}

interface ChangePlanProps {
    setChangePlan: (changePlan: boolean) => void;
    refetchCurrentAndNextPlan: () => void;
    availablePlanTemplates: PlanTemplateDto[];
    currentPlan: PlanDto;
    nextPlan?: PlanDto;
}
const ChangePlan: React.FC<ChangePlanProps> = ({setChangePlan, refetchCurrentAndNextPlan, availablePlanTemplates, currentPlan, nextPlan}) => {
    const {t} = useTranslation();
    const updatePlan = () => {
        refetchCurrentAndNextPlan();
        setChangePlan(false);
    }
    const [showMonthlyTemplates, setShowMonthlyTemplates] = useState(false);
    const toggleShowMonthlyTemplates = (): boolean => {
        let show = !showMonthlyTemplates;
        setShowMonthlyTemplates(show);
        return show;
    }
    const getAvailablePlanTemplates = () => {
        let relevantPlanTemplates = availablePlanTemplates
            .filter(t => t.type !== "TRIAL")
            .filter(t => t.id !== (nextPlan ? nextPlan.planTemplateId : currentPlan.fallBackPlanTemplateId))
        if(showMonthlyTemplates) return relevantPlanTemplates.filter(t => t.duration.type === "MONTH");
        else return relevantPlanTemplates.filter(t => t.duration.type === "YEAR");
    }
    const getPlanFrequencyLabel = (): string => {
        if(showMonthlyTemplates) return t("settings.plan_frequency_monthly");
        else return t("settings.plan_frequency_yearly");
    }
    return <div>
        <div className="plan-details-alignment">
            <span>
                <h2>{t("settings.change_plan")}</h2>
                            <p>
                <a onClick={() => setChangePlan(false)}>{t("settings.back")}</a>
            </p>
            </span>
            <span>
                <Toggle label={getPlanFrequencyLabel()} initialValue={false} toggle={toggleShowMonthlyTemplates} testId={"plan-change-type-toggle"}/>
            </span>

        </div>

        <div className="pricing-card-all-alignment1">
            <div className="new-grid">
                {getAvailablePlanTemplates()
                    .map((availablePlanTemplate: PlanTemplateDto, index) => <span key={index}><PlanCard
                        planTemplateDto={availablePlanTemplate} currentPlan={currentPlan} updatePlan={updatePlan}/></span> )}
            </div>
        </div>
    </div>
}
