export interface Code{
    code: string;
    type: CodeType;
}

export interface CodeBranch extends Code{
    inclusive: boolean;
}

export interface CodeWithClass extends Code{
    budgetClass: number;
}

export enum CodeType {
    CPV = "CPV",
    NUTS="NUTS",
    ACCREDITATION="ACCREDITATION",
}