import React, {useEffect, useState} from 'react'

import './masquerade.scss';
import {
    masquerade,
    useMasqueradeMutation,
} from "../../../hooks/slices/loginSlice";
import {Loader} from "../../../components/loader";
import {showErrorPopup, updateSnacc} from "../../../hooks/slices/snaccSlice";
import {APIError} from "../../../utils/error";
import {useAppDispatch} from "../../../app/hooks";

import {persistor} from "../../../app/store";
import {useNavigate} from "react-router-dom";

export const Masquerade: React.FC = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [masqueradeUser, {data: rawToken, isLoading, isSuccess, isError, error}] = useMasqueradeMutation();
    const [uuid, setUuid] = useState("");
    const submit = () => {
        if(uuid !== "") masqueradeUser({targetUserUuid: uuid})
    }
    useEffect(() => {
        if (isError && error) dispatch(showErrorPopup(error));
    }, [isError, error, dispatch]);

    useEffect(() => {
        if(isSuccess && rawToken) {
            persistor.purge()//resets the state store
                .then(() => dispatch(masquerade(rawToken)));
        }
    }, [dispatch, isSuccess, rawToken]);
    return <div>
        {isLoading && <Loader/>}
        <div className='feedback-section-alignment'>
            <div className='page-alignment'>
                <div>
                    <h1>Masquerade</h1>
                </div>
            </div>
            <div className='input-box'>
                <div className='input'>
                    <input type="text" placeholder='Client or user uuid' onChange={(e) => setUuid(e.currentTarget.value)}/>
                </div>
            </div>
            <div className='fill-button'>
                <button onClick={submit}>Submit</button>
            </div>
        </div>
    </div>

}
