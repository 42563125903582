import React, { useState } from "react";
import "./saveButton.scss";
import { CheckWhiteIcon } from "../icons";
import { useTranslation } from "react-i18next";

interface SaveButtonProps {
  saving: boolean;
  save: () => void;
  customSaveMessage?: string;
  customSavingMessage?: string;
  customSavedMessage?: string;
}

export const SaveButton: React.FC<SaveButtonProps> = ({saving, save, customSaveMessage, customSavingMessage, customSavedMessage}) => {
    const {t} = useTranslation();
    let saveMessage = customSaveMessage ? customSaveMessage : t("settings.save");
    let savingMessage = customSavingMessage ? customSavingMessage : t("settings.saving");
    let savedMessage = customSavedMessage ? customSavedMessage : t("settings.saved");
    let [saveButtonPushed, setSaveButtonPushed] = useState(false);
    const saveAndSetLastSave = () => {
        setSaveButtonPushed(true);
        save();
    }
    if (!saving && saveButtonPushed){
        setTimeout(() => {
            setSaveButtonPushed(false);
        }, 2000);
    }
    return <>
        {saving && <div className='save-button'>
            <button type="submit" data-testid={"save-button-saving"}>{savingMessage}</button>
        </div>}
        {!saving && !saveButtonPushed && <div className='save-button'>
            <button onClick={saveAndSetLastSave} type="submit" data-testid={"save-button-init"}>{saveMessage}</button>
        </div>}
        {!saving && saveButtonPushed && <div className='save-button'>
            <button type="submit" data-testid={"save-button-done-saving"}>{savedMessage}
                    <CheckWhiteIcon/>
            </button>
        </div>}
    </>
}