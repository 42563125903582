export namespace SearchParamUtil{

    export function passOnSearchParams(searchParams: URLSearchParams): string{
        let params: string[] = [];
        for (let [key, value] of Array.from(searchParams.entries())) {
            params.push(`${key}=${value}`);
        }
        if (params.length > 0) return "?" + params.join("&");
        return "";
    }

    export function appendToSearchParams(searchParams: URLSearchParams, key: string, value: string): URLSearchParams{
        searchParams.append(key, value);
        return searchParams;
    }

}