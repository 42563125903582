import {TenderSearchAggregation} from "../hooks/slices/tenderSearchSlice";
import {SearchFilter} from "../consts/SearchFilter";

export namespace AggregationUtil{
    export function getAggregationBracketedString(searchFilter: SearchFilter, aggregations: TenderSearchAggregation[] | undefined): string{
        if (aggregations !== undefined) {
            let relevantAggregation = aggregations.filter(a => a.searchFilter === searchFilter)
            if (relevantAggregation !== undefined && relevantAggregation.length === 1) {
                return " (" + relevantAggregation[0].count.toString() + ")"
            }
        } return "";
    }
    export function getLabelAggregationBracketedString(labelName: string, aggregations: TenderSearchAggregation[] | undefined): string{
        if (aggregations !== undefined) {
            let relevantAggregation = aggregations.filter(a => a.label === labelName)
            if (relevantAggregation !== undefined && relevantAggregation.length === 1) {
                return " (" + relevantAggregation[0].count.toString() + ")"
            }
        } return "";
    }
}