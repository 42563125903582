import { ResourceKey } from "i18next";

export const Login: ResourceKey = {
	welcome_back: "Welkom terug!",
	welcome_back_message: "Gelieve je inloggegevens in te vullen",
	forgot_password: "Wachtwoord vergeten?",
	forgot_password_message: "Vul het e-mailadres in waarmee je ingeschreven bent bij TenderWolf.",
	send: "Verzenden",
	sent_message: "E-mail verzonden indien het e-mailadres bij ons geregistreerd staat",
	reset_password: "Herstel je wachtwoord",
	back_to_login: "Terug naar inloggen",
	no_account: "Heb je geen account?",
	register: "Registreer",
	logIn: "Inloggen",
	wrong_credentials: "Foutief wachtwoord of e-mailadres",
};