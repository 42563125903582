import React, {useEffect, useState} from "react";
import {Form} from "../../../../components/form/Form";
import {SearchIcon} from "../../../../components/icons";

interface QueryInputProps{
    query: string;
    queryPlaceholder: string;
    isLoading: boolean;
    updateQueryAndSearch: (query: string) => void;
}

export const QueryInput: React.FC<QueryInputProps> = (props) => {
    const [localQuery, setLocalQuery] = useState(props.query);
    const submit = () => props.updateQueryAndSearch(localQuery);
    useEffect(() => {
        setLocalQuery(props.query);
    }, [props.query]);
    return <div className={'top-search'}>
        <Form onSubmit={submit}>
            <div className='search-input'>
                {props.isLoading && <input type="text" maxLength={255} defaultValue={"..."}/>}
                {!props.isLoading && <input type="text"
                                            maxLength={255}
                                            placeholder={props.queryPlaceholder}
                                            onChange={(e) => setLocalQuery(e.currentTarget.value)}
                                            value={localQuery}
                                            data-testid={"sidebar-search-input"}/>}
                <div className='search-icon-alignment'>
                    <SearchIcon/>
                </div>
            </div>
        </Form>
    </div>
}