import { ResourceKey } from "i18next";

export const ContractingAuthorityTypes: ResourceKey = {
    EU_INSTITUTION: "European institution/agency or international organisation",
    MINISTRY: "Ministry or any other national or federal authority, including their regional or local subdivisions",
    UTILITIES: "Utilities",
    BODY_PUBLIC: "Body governed by public law",
    REGIONAL_AUTHORITY: "Regional or local authority",
    OTHER: "Other",
    REGIONAL_AGENCY: "Regional or local agency/office",
    NATIONAL_AGENCY: "National or federal agency/office",
    CONT_ENT: "Contracting Entity",
    NOT_CONT_ENT: "Not Contracting Entity",
    BODY_PL: "Body governed by public law",
    CGA: "Central government authority",
    BODY_PL_CGA: "Body governed by public law, controlled by a central government authority",
    PUB_UNDERT_RA: "Public undertaking, controlled by a regional authority",
    PUB_UNDERT: "Public undertaking",
    DEF_CONT: "Defence contractor",
    ORG_SUB: "Organisation awarding a contract subsidised by a contracting authority",
    INT_ORG: "International organisation",
    LA: "Local authority",
    GRP_P_AUT: "Group of public authorities",
    ORG_SUB_LA: "Organisation awarding a contract subsidised by a local authority",
    BODY_PL_LA: "Body governed by public law, controlled by a local authority",
    EU_INS_BOD_AG: "EU institution, body or agency",
    RL_AUT: "Regional or local authority",
    RA: "Regional authority",
    ORG_SUB_RA: "Organisation awarding a contract subsidised by a regional authority",
    PUB_UNDERT_CGA: "Public undertaking, controlled by a central government authority",
    BODY_PL_RA: "Body governed by public law, controlled by a regional authority",
    SPEC_RIGHTS_ENTITY: "Entity with special or exclusive rights",
    EU_INT_ORG: "European Institution/Agency or International Organisation",
    ORG_SUB_CGA: "Organisation awarding a contract subsidised by a central government authority",
    PUB_UNDERT_LA: "Public undertaking, controlled by a local authority",


};
