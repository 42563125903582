import {ResourceKey} from "i18next";

export const Error: ResourceKey = {
	oopsSomethingWentWrong: "Sorry, er ging iets mis bij het weergeven van deze pagina.",
	error_400: "Je deed een ongeldig verzoek.",
	error_401: "Je hebt niet de juiste toegangsrechten om deze pagina te bekijken.",
	error_403: "Je hebt niet de juiste toegangsrechten om deze pagina te bekijken.",
	error_404: "De pagina die je zocht bestaat niet.",
	error_500: "We hadden technische problemen tijdens je laatste bewerking.",
	error_503: "Onze servers kunnen momenteel je verzoek niet verwerken.",
};