/**
 * SortByOption Enum
 *
 * Represents the various options available to users on the front-end
 * for sorting results. This is strictly for user-interface representation.
 *
 * After a user selects an option, its corresponding value in the `SortBy`
 * enum is used to communicate with the backend.
 *
 * @example
 * if (selectedOption === SortByOption.DATE) {
 *     // This might correspond to "PUBLICATION_DATE_DESC" on the backend.
 * }
 */
export enum SortBy {
  RELEVANCE= "RELEVANCE",
  DATE = "DATE",
}

export namespace SortByParser {
  export function toString(s: SortBy): string {
    return SortBy[s];
  }

  export function fromString(s: string | null): SortBy {
    if (s === null) return SortBy.RELEVANCE;
    if (s === "PUBLICATION_DATE_DESC" || s === "AWARD_DATE_DESC") return SortBy.DATE;
    return (SortBy as any)[s];
  }

  export function values(): string[]{
    return Object.values(SortBy);
  }
}