import {Label, LabelCategory} from "../types/label";

export namespace LabelsUtil{

    export function getAllLabels(labelCategories: LabelCategory[]): Label[]{
        let allLabels: Label[] = [];
        for (const c of labelCategories) {
            for (const l of c.labels) {
                allLabels.push(l);
            }
        } return allLabels;
    }
}