import {FetchBaseQueryError, skipToken} from "@reduxjs/toolkit/query";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {SerializedError} from "@reduxjs/toolkit";

import {useEffect} from "react";
import {
    GetSavedSearchesParams,
    TenderSearchesState,
    updateTenderSearchesState,
    useGetSavedSearchesQuery
} from "./slices/savedSearchesSlice";

export interface SavedSearchesInStore {
    savedSearches: TenderSearchesState;
    isLoading: boolean;
    error: FetchBaseQueryError | SerializedError | undefined;
}

export function RaiseSavedSearches(): SavedSearchesInStore {
    const dispatch = useAppDispatch();
    const userUuid = useAppSelector((state) => state.user.uuid);
    let params: GetSavedSearchesParams | undefined = userUuid ? {userUuid: userUuid}:undefined;
    const savedSearchesInStore: TenderSearchesState | undefined = useAppSelector((state) => state.savedSearches);
    let savedSearchesParams = savedSearchesInStore.tenderSearches ? undefined : params; //if the profiles are already in the store, don't fetch them
    const {data, isLoading, isSuccess, error} = useGetSavedSearchesQuery(savedSearchesParams ?? skipToken);
    useEffect(() => {
        if(savedSearchesInStore.tenderSearches === undefined && data && isSuccess) {
            dispatch(updateTenderSearchesState({
                tenderSearches: data.tenderSearches,
                curatedTenderSearches: data.curatedTenderSearches,
            }));
        }
    }, [data, dispatch, isSuccess, savedSearchesInStore])
    return {
        savedSearches: savedSearchesInStore,
        isLoading: isLoading,
        error: error
    }
}
