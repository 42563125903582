import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { SearchFilterUpdateProps, updateSearchFilters } from "../../../../hooks/slices/tenderSearchSlice";
import {DateUtil} from "../../../../utils/date";
import { Dropdown } from "./Dropdown";
import { SearchFilter } from "../../../../consts/SearchFilter";
import { CustomDateRangePicker } from "./CustomDateRangePicker";
import { SearchSidebarConfiguration } from "../../GenericSearchPage";
import { SearchFilterUtil } from "../../../../utils/searchFilters";
import {RaiseUser} from "../../../../hooks/raiseUser";
import {Moment} from "moment/moment";

interface DeadlineDateRangePickerProps {
    searchSidebarConfiguration: SearchSidebarConfiguration;
    getAggregation: (searchFilter: SearchFilter) => string;
    changeDropdownFilter: (newFilter: SearchFilter, dropdownFilters: SearchFilter[], currentlySelectedFilters: SearchFilter[])=>SearchFilter[];
}

export const DeadlineDateRangePicker: React.FC<DeadlineDateRangePickerProps> = (props) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const searchFilters = useAppSelector((state) => state.tenderSearch.searchFilters);
    let userInteractions = RaiseUser().user?.userInteractions ?? [];
    const [customDatePopupOpen, setCustomDatePopupOpen] = React.useState(false);
    const updateDateSearchFilters = (searchFilters: SearchFilterUpdateProps) => {
        if (searchFilters.selectedFilters) {
            if (searchFilters.selectedFilters.includes(SearchFilter.DEADLINE_CUSTOM)) {
                setCustomDatePopupOpen(true);
            }
            dispatch(updateSearchFilters({
                selectedFilters: searchFilters.selectedFilters,
                startDeadlineDate: SearchFilterUtil.findDeadlineStartDate(searchFilters.selectedFilters),
                endDeadlineDate: SearchFilterUtil.findDeadlineEndDate(searchFilters.selectedFilters),
                userInteractions: userInteractions
            }));
        }
    }
    const setCustomStartDate = (startDate: Moment) => {
        if (startDate.isValid()) {
            let newSearchFilters = searchFilters.selectedFilters.filter(f => !SearchFilterUtil.isDeadlineFilter(f))
            dispatch(updateSearchFilters({
                selectedFilters: [...newSearchFilters, SearchFilter.DEADLINE_CUSTOM],
                startDeadlineDate: DateUtil.getDateTimeString(startDate),
                userInteractions: userInteractions}
            ));
        }
    }
    const setCustomEndDate = (endDate: Moment) => {
        if (endDate.isValid()) {
            let newSearchFilters = searchFilters.selectedFilters.filter(f => !SearchFilterUtil.isDeadlineFilter(f))
            dispatch(updateSearchFilters({
                selectedFilters: [...newSearchFilters, SearchFilter.DEADLINE_CUSTOM],
                endDeadlineDate: DateUtil.getDateTimeString(endDate),
                userInteractions: userInteractions}
            ));
        }
    }
    return <>
        <Dropdown label={t("publications.deadlineTitle")}
                  relevantFilters={SearchFilterUtil.getRelevantDeadlineFilters()}
                  updateSearchFilters={updateDateSearchFilters}
                  searchSidebarConfiguration={props.searchSidebarConfiguration}
                  getAggregation={props.getAggregation} changeDropdownFilter={props.changeDropdownFilter}/>
        {customDatePopupOpen && <CustomDateRangePicker
            defaultStart={DateUtil.findDateMoment(DateUtil.convertDateTimeStringToDateString(searchFilters.startDeadlineDate))}
            changeStart={setCustomStartDate}
            defaultEnd={DateUtil.findDateMoment(DateUtil.convertDateTimeStringToDateString(searchFilters.endDeadlineDate))}
            changeEnd={setCustomEndDate}
            close={() => {
                updateDateSearchFilters({selectedFilters: [...searchFilters.selectedFilters], userInteractions: userInteractions});
                setCustomDatePopupOpen(false);
            }}
        />
        }
    </>
}