

//Klasse bedrag
// 1 tot 135.000 EUR
// 2 tot 275.000 EUR
// 3 tot 500.000 EUR
// 4 tot 900.000 EUR
// 5 tot 1.810.000 EUR
// 6 tot 3.225.000 EUR
// 7 tot 5.330.000 EUR
// 8 meer dan 5.330.000 EUR

export const AccreditationCategories: string[] = ['A','A1','B','B1','C','C1','C2','C3','C5','C6','C7','D','D1','D10','D11','D12','D13','D14','D15','D16','D17','D18','D20','D21','D22','D23','D24','D25','D29','D4','D5','D6','D7','D8','E','E1','E2','E4','F','F1','F2','F3','G','G1','G2','G3','G4','G5','H','H1','H2','K','K1','K2','K3','L','L1','L2','M','M1','N','N1','N2','P','P1','P2','P3','P4','S','S1','S2','S3','S4','T2','T3','T4','T6','U','V']

export const Accreditations = [
    'A-1',
    'A-2',
    'A-3',
    'A-4',
    'A-5',
    'A-6',
    'A-7',
    'A-8',
    'A1-1',
    'A1-2',
    'A1-3',
    'A1-4',
    'A1-5',
    'A1-6',
    'A1-7',
    'A1-8',
    'B-1',
    'B-2',
    'B-3',
    'B-4',
    'B-5',
    'B-6',
    'B-7',
    'B-8',
    'B1-1',
    'B1-2',
    'B1-3',
    'B1-4',
    'B1-5',
    'B1-6',
    'B1-7',
    'B1-8',
    'C-1',
    'C-2',
    'C-3',
    'C-4',
    'C-5',
    'C-6',
    'C-7',
    'C-8',
    'C1-1',
    'C1-2',
    'C1-3',
    'C1-4',
    'C1-5',
    'C1-6',
    'C1-7',
    'C1-8',
    'C2-1',
    'C2-2',
    'C2-3',
    'C2-4',
    'C2-5',
    'C2-6',
    'C2-7',
    'C2-8',
    'C3-1',
    'C3-2',
    'C3-3',
    'C3-4',
    'C3-5',
    'C3-6',
    'C3-7',
    'C3-8',
    'C5-1',
    'C5-2',
    'C5-3',
    'C5-4',
    'C5-5',
    'C5-6',
    'C5-7',
    'C5-8',
    'C6-1',
    'C6-2',
    'C6-3',
    'C6-4',
    'C6-5',
    'C6-6',
    'C6-7',
    'C6-8',
    'C7-1',
    'C7-2',
    'C7-3',
    'C7-4',
    'C7-5',
    'C7-6',
    'C7-7',
    'C7-8',
    'D-1',
    'D-2',
    'D-3',
    'D-4',
    'D-5',
    'D-6',
    'D-7',
    'D-8',
    'D1-1',
    'D1-2',
    'D1-3',
    'D1-4',
    'D1-5',
    'D1-6',
    'D1-7',
    'D1-8',
    'D10-1',
    'D10-2',
    'D10-3',
    'D10-4',
    'D10-5',
    'D10-6',
    'D10-7',
    'D10-8',
    'D11-1',
    'D11-2',
    'D11-3',
    'D11-4',
    'D11-5',
    'D11-6',
    'D11-7',
    'D11-8',
    'D12-1',
    'D12-2',
    'D12-3',
    'D12-4',
    'D12-5',
    'D12-6',
    'D12-7',
    'D12-8',
    'D13-1',
    'D13-2',
    'D13-3',
    'D13-4',
    'D13-5',
    'D13-6',
    'D13-7',
    'D13-8',
    'D14-1',
    'D14-2',
    'D14-3',
    'D14-4',
    'D14-5',
    'D14-6',
    'D14-7',
    'D14-8',
    'D15-1',
    'D15-2',
    'D15-3',
    'D15-4',
    'D15-5',
    'D15-6',
    'D15-7',
    'D15-8',
    'D16-1',
    'D16-2',
    'D16-3',
    'D16-4',
    'D16-5',
    'D16-6',
    'D16-7',
    'D16-8',
    'D17-1',
    'D17-2',
    'D17-3',
    'D17-4',
    'D17-5',
    'D17-6',
    'D17-7',
    'D17-8',
    'D18-1',
    'D18-2',
    'D18-3',
    'D18-4',
    'D18-5',
    'D18-6',
    'D18-7',
    'D18-8',
    'D20-1',
    'D20-2',
    'D20-3',
    'D20-4',
    'D20-5',
    'D20-6',
    'D20-7',
    'D20-8',
    'D21-1',
    'D21-2',
    'D21-3',
    'D21-4',
    'D21-5',
    'D21-6',
    'D21-7',
    'D21-8',
    'D22-1',
    'D22-2',
    'D22-3',
    'D22-4',
    'D22-5',
    'D22-6',
    'D22-7',
    'D22-8',
    'D23-1',
    'D23-2',
    'D23-3',
    'D23-4',
    'D23-5',
    'D23-6',
    'D23-7',
    'D23-8',
    'D24-1',
    'D24-2',
    'D24-3',
    'D24-4',
    'D24-5',
    'D24-6',
    'D24-7',
    'D24-8',
    'D25-1',
    'D25-2',
    'D25-3',
    'D25-4',
    'D25-5',
    'D25-6',
    'D25-7',
    'D25-8',
    'D29-1',
    'D29-2',
    'D29-3',
    'D29-4',
    'D29-5',
    'D29-6',
    'D29-7',
    'D29-8',
    'D4-1',
    'D4-2',
    'D4-3',
    'D4-4',
    'D4-5',
    'D4-6',
    'D4-7',
    'D4-8',
    'D5-1',
    'D5-2',
    'D5-3',
    'D5-4',
    'D5-5',
    'D5-6',
    'D5-7',
    'D5-8',
    'D6-1',
    'D6-2',
    'D6-3',
    'D6-4',
    'D6-5',
    'D6-6',
    'D6-7',
    'D6-8',
    'D7-1',
    'D7-2',
    'D7-3',
    'D7-4',
    'D7-5',
    'D7-6',
    'D7-7',
    'D7-8',
    'D8-1',
    'D8-2',
    'D8-3',
    'D8-4',
    'D8-5',
    'D8-6',
    'D8-7',
    'D8-8',
    'E-1',
    'E-2',
    'E-3',
    'E-4',
    'E-5',
    'E-6',
    'E-7',
    'E-8',
    'E1-1',
    'E1-2',
    'E1-3',
    'E1-4',
    'E1-5',
    'E1-6',
    'E1-7',
    'E1-8',
    'E2-1',
    'E2-2',
    'E2-3',
    'E2-4',
    'E2-5',
    'E2-6',
    'E2-7',
    'E2-8',
    'E4-1',
    'E4-2',
    'E4-3',
    'E4-4',
    'E4-5',
    'E4-6',
    'E4-7',
    'E4-8',
    'F-1',
    'F-2',
    'F-3',
    'F-4',
    'F-5',
    'F-6',
    'F-7',
    'F-8',
    'F1-1',
    'F1-2',
    'F1-3',
    'F1-4',
    'F1-5',
    'F1-6',
    'F1-7',
    'F1-8',
    'F2-1',
    'F2-2',
    'F2-3',
    'F2-4',
    'F2-5',
    'F2-6',
    'F2-7',
    'F2-8',
    'F3-1',
    'F3-2',
    'F3-3',
    'F3-4',
    'F3-5',
    'F3-6',
    'F3-7',
    'F3-8',
    'G-1',
    'G-2',
    'G-3',
    'G-4',
    'G-5',
    'G-6',
    'G-7',
    'G-8',
    'G1-1',
    'G1-2',
    'G1-3',
    'G1-4',
    'G1-5',
    'G1-6',
    'G1-7',
    'G1-8',
    'G2-1',
    'G2-2',
    'G2-3',
    'G2-4',
    'G2-5',
    'G2-6',
    'G2-7',
    'G2-8',
    'G3-1',
    'G3-2',
    'G3-3',
    'G3-4',
    'G3-5',
    'G3-6',
    'G3-7',
    'G3-8',
    'G4-1',
    'G4-2',
    'G4-3',
    'G4-4',
    'G4-5',
    'G4-6',
    'G4-7',
    'G4-8',
    'G5-1',
    'G5-2',
    'G5-3',
    'G5-4',
    'G5-5',
    'G5-6',
    'G5-7',
    'G5-8',
    'H-1',
    'H-2',
    'H-3',
    'H-4',
    'H-5',
    'H-6',
    'H-7',
    'H-8',
    'H1-1',
    'H1-2',
    'H1-3',
    'H1-4',
    'H1-5',
    'H1-6',
    'H1-7',
    'H1-8',
    'H2-1',
    'H2-2',
    'H2-3',
    'H2-4',
    'H2-5',
    'H2-6',
    'H2-7',
    'H2-8',
    'K-1',
    'K-2',
    'K-3',
    'K-4',
    'K-5',
    'K-6',
    'K-7',
    'K-8',
    'K1-1',
    'K1-2',
    'K1-3',
    'K1-4',
    'K1-5',
    'K1-6',
    'K1-7',
    'K1-8',
    'K2-1',
    'K2-2',
    'K2-3',
    'K2-4',
    'K2-5',
    'K2-6',
    'K2-7',
    'K2-8',
    'K3-1',
    'K3-2',
    'K3-3',
    'K3-4',
    'K3-5',
    'K3-6',
    'K3-7',
    'K3-8',
    'L-1',
    'L-2',
    'L-3',
    'L-4',
    'L-5',
    'L-6',
    'L-7',
    'L-8',
    'L1-1',
    'L1-2',
    'L1-3',
    'L1-4',
    'L1-5',
    'L1-6',
    'L1-7',
    'L1-8',
    'L2-1',
    'L2-2',
    'L2-3',
    'L2-4',
    'L2-5',
    'L2-6',
    'L2-7',
    'L2-8',
    'M-1',
    'M-2',
    'M-3',
    'M-4',
    'M-5',
    'M-6',
    'M-7',
    'M-8',
    'M1-1',
    'M1-2',
    'M1-3',
    'M1-4',
    'M1-5',
    'M1-6',
    'M1-7',
    'M1-8',
    'N-1',
    'N-2',
    'N-3',
    'N-4',
    'N-5',
    'N-6',
    'N-7',
    'N-8',
    'N1-1',
    'N1-2',
    'N1-3',
    'N1-4',
    'N1-5',
    'N1-6',
    'N1-7',
    'N1-8',
    'N2-1',
    'N2-2',
    'N2-3',
    'N2-4',
    'N2-5',
    'N2-6',
    'N2-7',
    'N2-8',
    'P-1',
    'P-2',
    'P-3',
    'P-4',
    'P-5',
    'P-6',
    'P-7',
    'P-8',
    'P1-1',
    'P1-2',
    'P1-3',
    'P1-4',
    'P1-5',
    'P1-6',
    'P1-7',
    'P1-8',
    'P2-1',
    'P2-2',
    'P2-3',
    'P2-4',
    'P2-5',
    'P2-6',
    'P2-7',
    'P2-8',
    'P3-1',
    'P3-2',
    'P3-3',
    'P3-4',
    'P3-5',
    'P3-6',
    'P3-7',
    'P3-8',
    'P4-1',
    'P4-2',
    'P4-3',
    'P4-4',
    'P4-5',
    'P4-6',
    'P4-7',
    'P4-8',
    'S-1',
    'S-2',
    'S-3',
    'S-4',
    'S-5',
    'S-6',
    'S-7',
    'S-8',
    'S1-1',
    'S1-2',
    'S1-3',
    'S1-4',
    'S1-5',
    'S1-6',
    'S1-7',
    'S1-8',
    'S2-1',
    'S2-2',
    'S2-3',
    'S2-4',
    'S2-5',
    'S2-6',
    'S2-7',
    'S2-8',
    'S3-1',
    'S3-2',
    'S3-3',
    'S3-4',
    'S3-5',
    'S3-6',
    'S3-7',
    'S3-8',
    'S4-1',
    'S4-2',
    'S4-3',
    'S4-4',
    'S4-5',
    'S4-6',
    'S4-7',
    'S4-8',
    'T2-1',
    'T2-2',
    'T2-3',
    'T2-4',
    'T2-5',
    'T2-6',
    'T2-7',
    'T2-8',
    'T3-1',
    'T3-2',
    'T3-3',
    'T3-4',
    'T3-5',
    'T3-6',
    'T3-7',
    'T3-8',
    'T4-1',
    'T4-2',
    'T4-3',
    'T4-4',
    'T4-5',
    'T4-6',
    'T4-7',
    'T4-8',
    'T6-1',
    'T6-2',
    'T6-3',
    'T6-4',
    'T6-5',
    'T6-6',
    'T6-7',
    'T6-8',
    'U-1',
    'U-2',
    'U-3',
    'U-4',
    'U-5',
    'U-6',
    'U-7',
    'U-8',
    'V-1',
    'V-2',
    'V-3',
    'V-4',
    'V-5',
    'V-6',
    'V-7',
    'V-8']
