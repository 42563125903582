import {useAppDispatch, useAppSelector} from "../app/hooks";
import {updateVersion} from "./slices/versionSlice";
import {persistor} from "../app/store";
import {apiSlice} from "./slices/apiSlice";

export function CheckVersion() {
    const dispatch = useAppDispatch();
    const currentVersion = process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION: "-1";
    const previousVersion = useAppSelector((state) => state.version.version);
    if(previousVersion === undefined) {
        dispatch(updateVersion(currentVersion));
    }
    else if(currentVersion !== undefined && currentVersion !== previousVersion){
        persistor.purge()
            .then(() => {dispatch(updateVersion(currentVersion))})//resets the state store
    }
}

