import React, {useEffect, useState} from "react";
import "./labelSettings.scss";
import {useEditLabelCategoriesMutation} from "../../../hooks/slices/labelSlice";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {useTranslation} from "react-i18next";
import {SkeletonSection} from "../../tenderDetails/main/TenderDetailsView";
import {
    ArrowDownIcon,
    CheckWhiteIcon,
    CloseIcon,
    GearIcon,
    TrashIcon
} from "../../../components/icons";
import {showCustomErrorPopup} from "../../../hooks/slices/snaccSlice";
import {Loader} from "../../../components/loader";
import {ErrorPage} from "../../error";
import {Label, LabelCategory} from "../../../types/label";
import {RaiseUser} from "../../../hooks/raiseUser";
import {updateUserLabelCategories} from "../../../hooks/slices/userSlice";
import {ConfirmPopup} from "../../../components/popup/Popup";
import {FeatureUtil} from "../../../utils/features";
import {FeatureName} from "../../../consts/features";
import {RaiseCurrentPlan} from "../../../hooks/raiseCurrentPlan";
import {LabelsUtil} from "../../../utils/labels";
import {AddLabelInput} from "../../search/results/body/Labels";

export const LabelSettings: React.FC = () => {

    const dispatch = useAppDispatch();
    const userUuid = useAppSelector((state) => state.user.uuid);
    const userInStore = RaiseUser();
    const currentPlan = RaiseCurrentPlan();
    const userCanVisitLabelsPage: boolean = FeatureUtil.hasFeature(currentPlan.currentPlan?.features, FeatureName.LABELS);
    // const labelsInStore = Labels();
    const updateLabelsInStore = (labelCategories: LabelCategory[]) => {
        dispatch(updateUserLabelCategories({labelCategories: labelCategories}));
    }
    if (userInStore.isLoading || !userUuid) return <LabelSettingsSkeleton/>
    else if(!userCanVisitLabelsPage) return <ErrorPage errorCode={403}/>
    else if(userInStore.error) return <ErrorPage error={userInStore.error}/>
    else if(userInStore.user && userInStore.user.labelCategories !== undefined) {
        return <LabelSettingsWithData userUuid={userUuid}
                                      labelCategories={userInStore.user.labelCategories}
                                      updateLabelsInStore={updateLabelsInStore}
                                      labelLimit={FeatureUtil.getFeatureLimit(currentPlan.currentPlan?.features, FeatureName.LABELS)}
        />
    }
    else return <ErrorPage errorCode={500}/>
}

interface LabelSettingsWithDataProps {
    userUuid: string,
    labelCategories: LabelCategory[];
    updateLabelsInStore: (labelCategories: LabelCategory[]) =>  void;
    labelLimit: number|undefined;
}

export const LabelSettingsWithData: React.FC<LabelSettingsWithDataProps> = ({userUuid, labelCategories, updateLabelsInStore, labelLimit}) => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const [localLabelCategories, setLocalLabelCategories] = useState<LabelCategory[]>(labelCategories);
    const [updateLabels, {data: updateOutput, isSuccess: updateIsLoaded, isLoading: updateIsLoading, isError, error}] = useEditLabelCategoriesMutation();
    const [showCreateLabelCategoryPopup, setShowCreateLabelCategoryPopup] = useState(false);
    // const [showSaveChangesButton, setShowSaveChangesButton] = useState(false);
    const hideCreateLabelCategoryPopup = () => setShowCreateLabelCategoryPopup(false);


    const saveChanges = (labelCategories: LabelCategory[]) => {
        updateLabels({userUuid: userUuid, labelCategories: labelCategories})
    }

    useEffect(() => {
        if(updateIsLoaded && updateOutput && updateOutput.labelCategories){
            setLocalLabelCategories(updateOutput.labelCategories);
            updateLabelsInStore(updateOutput.labelCategories);
        }
    }, [updateIsLoaded, updateLabelsInStore, updateOutput]);

    const throwSnaccError = (message: string) => {
        dispatch(showCustomErrorPopup(message));
    }

    const throwDuplicateLabelError = (labelName: string) => throwSnaccError(t("settings.label_with_name_already_exists_first_part") + labelName + t("settings.label_with_name_already_exists_second_part"));
    const throwEmptyLabelError = () => {/*throwSnaccError(t("settings.label_must_have_a_name"))*/};
    const throwTooManyLabelsError = () => throwSnaccError(t("settings.label_must_have_a_name"));
    const throwDuplicateLabelCategoryError = (labelName: string) => throwSnaccError(t("settings.label_group_with_name_already_exists_first_part") + labelName + t("settings.label_group_with_name_already_exists_second_part"));
    const throwEmptyLabelCategoryError = () => throwSnaccError(t("settings.label_group_must_have_a_name"));
    const userHasLabelsLeft = (): boolean => {return labelLimit === undefined || labelLimit === null || LabelsUtil.getAllLabels(localLabelCategories).length < labelLimit}
    const addLabelToDefaultCategory = (labelName: string) => {
        if (labelName === "") throwEmptyLabelError();
        else if (!labelWithNameExists(labelName, localLabelCategories)) {
            let newLabelCategories = addLabelToDefaultLabelCategoryAndReturn(labelName, localLabelCategories);
            setLocalLabelCategories(newLabelCategories);
            saveChanges(newLabelCategories);
        } else throwDuplicateLabelError(labelName);
    }
    const addNewLabelToCategory = (categoryName: string, labelName: string) => {
        if (labelName === "") throwEmptyLabelError();
        else if (!userHasLabelsLeft()) throwTooManyLabelsError(); //this should never happen
        else if (!labelWithNameExists(labelName, localLabelCategories)) {
            let newLabelCategories = addLabelToLabelCategoryAndReturn(categoryName, labelName, localLabelCategories);
            setLocalLabelCategories(newLabelCategories);
            saveChanges(newLabelCategories);
        } else throwDuplicateLabelError(labelName);

    }
    const renameLabel = (categoryName: string, oldName: string, newName: string) => {
        if (newName === "") throwEmptyLabelError();
        else if (!labelWithNameExists(newName, localLabelCategories)) {
            let newLabelCategories = renameLabelAndReturn(categoryName, oldName, newName, localLabelCategories);
            setLocalLabelCategories(newLabelCategories);
            saveChanges(newLabelCategories);
        } else throwDuplicateLabelError(newName);
    }
    const addOrEditLabelCategory = (oldName: string | undefined, newName: string, newColor: string) => {
        if(newName === "") throwEmptyLabelCategoryError();
        else if (oldName === undefined) {//add new category
            if(labelCategoryWithNameExists(newName, localLabelCategories)) throwDuplicateLabelCategoryError(newName);
            else {
                let newLabelCategories = [...localLabelCategories, {name: newName, color: newColor, isDefault: false, labels: []}];
                setLocalLabelCategories(newLabelCategories);
                saveChanges(newLabelCategories);}
            }
        else { //edit old category
            let newLabelCategories = renameLabelCategoryAndChangeColorAndReturn(oldName, newName, newColor, localLabelCategories);
            setLocalLabelCategories(newLabelCategories);
            saveChanges(newLabelCategories);
        }
    }

    const moveLabelToDifferentCategory = (labelName: string, oldCategoryName: string, newCategoryName: string) => {
        let newLabelCategories = moveLabelToDifferentCategoryAndReturn(labelName, oldCategoryName, newCategoryName, localLabelCategories);
        setLocalLabelCategories(newLabelCategories);
        saveChanges(newLabelCategories);
    }

    const removeLabel = (labelName: string, categoryName: string) => {
        let newLabelCategories = removeLabelAndReturn(labelName, categoryName, localLabelCategories);
        setLocalLabelCategories(newLabelCategories);
        saveChanges(newLabelCategories);
    }

    const switchLabelCategories = (firstName: string, secondName: string) => {
        let newLabelCategories = switchLabelCategoriesAndReturn(firstName, secondName, localLabelCategories);
        setLocalLabelCategories(newLabelCategories);
        saveChanges(newLabelCategories);    }

    const removeLabelCategory = (labelCategoryName: string) => {
        let newLabelCategories = removeLabelCategoryAndReturn(labelCategoryName, localLabelCategories);
        setLocalLabelCategories(newLabelCategories);
        saveChanges(newLabelCategories);
    }

    let labelOperations: LabelOperations = {
        addNewLabelToCategory,
        addOrEditLabelCategory,
        renameLabel,
        moveLabelToDifferentCategory,
        removeLabel,
        switchLabelCategories,
        removeLabelCategory,
        throwDuplicateLabelError,
        throwEmptyLabelError,
        throwDuplicateLabelCategoryError,
        throwEmptyLabelCategoryError
    }

    //To switch categories
    let categoryBeingDragged: string | undefined = undefined;
    let categoryBeingEntered: string | undefined = undefined;
    const dragEnter = (e: React.DragEvent<HTMLDivElement>, labelCategoryName: string) => {
        categoryBeingEntered = labelCategoryName;
    };
    const drop = (e: React.DragEvent<HTMLDivElement>, labelCategoryName: string) => {
        categoryBeingDragged = labelCategoryName;
        if(categoryBeingDragged && categoryBeingEntered && categoryBeingDragged !== categoryBeingEntered){
            switchLabelCategories(categoryBeingDragged, categoryBeingEntered);
            categoryBeingDragged = undefined;
            categoryBeingEntered = undefined;
        }
    };

    if(updateIsLoading) return <Loader/>
    else if(isError && error) return <ErrorPage error={error}/>;
    else return <>
        <div className="label-setting-section-alignment">
            <div className="header-alignment">
                <div>
                    <h1>{t("settings.labels")}</h1>
                </div>
            </div>
            <div className="label-setting-body-content-alignment">
                <div className="sub-box-center-alignment">
                    <div className="center-box">
                        {localLabelCategories.length === 0 &&
                            <LabelInputField addOrEditLabel={addLabelToDefaultCategory}/>}
                        {localLabelCategories.map((labelCategory) =>
                            <div key={labelCategory.name + "-" + labelCategory.isDefault + "-" + labelCategory.labels.length}
                                 onDragEnter={(e) => dragEnter(e, labelCategory.name)}
                                 onDragEnd={(e) => drop(e, labelCategory.name)}
                                 draggable>
                                <LabelCategoryCard labelCategory={labelCategory}
                                           labelOperations={labelOperations}
                                           allCategoryNames={localLabelCategories.map(c => c.name)}
                                                   userCanAddLabels={userHasLabelsLeft()}
                                />
                            </div>
                        )}
                        <div className="add-tag-group">
                            <button onClick={() => setShowCreateLabelCategoryPopup(!showCreateLabelCategoryPopup)}>
                                {t("settings.add_label_group")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {showCreateLabelCategoryPopup && (
            <>
                {/*<CreateLabelCategoryDialogue setCreateLabelCategoryPopup={setShowCreateLabelCategoryPopup}/>*/}
                <AddOrEditLabelCategoryPopup closePopup={hideCreateLabelCategoryPopup}
                                             labelOperations={labelOperations}
                />
            </>
        )}
    </>
}

function labelWithNameExists(labelName: string, labelCategories: LabelCategory[]){
    for (const c of labelCategories) {
        for (const l of c.labels) {
            if(labelNameEquals(l.name, labelName)) return true;
        }
    } return false;
}

function labelCategoryWithNameExists(labelCategoryName: string, labelCategories: LabelCategory[]){
    return labelCategories.filter(c => labelNameEquals(c.name, labelCategoryName)).length > 0;
}

function categoryContainsLabel(categoryName: string, labelName: string, labelCategories: LabelCategory[]): boolean {
    for (const c of labelCategories) {
        if (c.name === categoryName) {
            return c.labels.filter(l => labelNameEquals(l.name, labelName)).length > 0;
        }
    }
    return false;
}

function labelNameEquals(a: string, b: string){
    return a.trim().toLowerCase() === b.trim().toLowerCase();
}

function addLabelToDefaultLabelCategoryAndReturn(labelName: string, labelCategories: LabelCategory[]): LabelCategory[] {
    let newLabelCategories: LabelCategory[] = JSON.parse(JSON.stringify(labelCategories));
    if (labelName === "") return newLabelCategories;
    let containsDefaultLabelCategory = newLabelCategories.filter(c => c.isDefault).length > 0;
    if (!containsDefaultLabelCategory) newLabelCategories.push({name: "", isDefault: true, color: "07A7A4", labels: []});
    for (const c of newLabelCategories) {
        if (c.isDefault) {
            c.labels = [...c.labels, {name: labelName}]
        }
    }
    return newLabelCategories;
}

function addLabelToLabelCategoryAndReturn(labelCategoryName: string, labelName: string, labelCategories: LabelCategory[]): LabelCategory[] {
    let newLabelCategories: LabelCategory[] = JSON.parse(JSON.stringify(labelCategories));
    if (labelName === "") return newLabelCategories;
    for (let c of newLabelCategories) {
        if (c.name === labelCategoryName) {
            c.labels = [...c.labels, {name: labelName}]
        }
    }
    return newLabelCategories;
}

function renameLabelAndReturn(categoryName: string, oldName: string, newName: string, labelCategories: LabelCategory[]): LabelCategory[] {
    // let newLabelCategories: LabelCategoryDto[] = JSON.parse(JSON.stringify(labelCategories));
    let newLabelCategories: LabelCategory[] = JSON.parse(JSON.stringify(labelCategories)); //deep copy
    outerLoop: for (const c of newLabelCategories.filter(c => c.name === categoryName)) {
        for (const l of c.labels) {
            if (l.name === oldName) {
                l.name = newName;
                break outerLoop;
            }
        }
    }
    return newLabelCategories;
}

function reorderLabelCategoriesAndReturn(newCategoryNames: string[], labelCategories: LabelCategory[]): LabelCategory[] {
    let newLabelCategories: LabelCategory[] = [];
    for(const name of newCategoryNames){
        labelCategories.filter(c => c.name === name).forEach(c => newLabelCategories.push(c))
    }
    return newLabelCategories;
}

function switchLabelCategoriesAndReturn(firstCategory: string, secondCategory: string, labelCategories: LabelCategory[]): LabelCategory[] {
    let newLabelCategories: LabelCategory[] = JSON.parse(JSON.stringify(labelCategories));
    if(firstCategory === "" || secondCategory === "") return newLabelCategories;
    let firstIndex = newLabelCategories.findIndex(c => c.name === firstCategory);
    let secondIndex = newLabelCategories.findIndex(c => c.name === secondCategory);
    if(firstIndex < 0 && secondIndex < 0) return newLabelCategories;
    else [newLabelCategories[firstIndex], newLabelCategories[secondIndex]] = [newLabelCategories[secondIndex], newLabelCategories[firstIndex]];//'destructuring'
    return newLabelCategories;
}

function renameLabelCategoryAndChangeColorAndReturn(oldName: string, newName: string, newColor: string, labelCategories: LabelCategory[]): LabelCategory[] {
    let newLabelCategories: LabelCategory[] = JSON.parse(JSON.stringify(labelCategories));
    for (const c of newLabelCategories) {
        if (c.name === oldName) {
            c.name = newName;
            c.color = newColor;
            break;
        }
    }
    return newLabelCategories;
}

function moveLabelToDifferentCategoryAndReturn(labelName: string, oldCategoryName: string, newCategoryName: string, labelCategories: LabelCategory[]): LabelCategory[]{
    let newLabelCategories: LabelCategory[] = JSON.parse(JSON.stringify(labelCategories));
    let label: Label | undefined = undefined;
    for (const c of newLabelCategories ) {
        if (c.name === oldCategoryName|| (oldCategoryName === "" && c.isDefault)) {
            let relevantLabels = c.labels.filter(l => l.name === labelName);
            if (relevantLabels.length > 0) label = relevantLabels[0]
            c.labels = c.labels.filter(l => l.name !== labelName)
            break;
        }
    } if(label){
        for (const c of newLabelCategories) {
            if (c.name === newCategoryName || (newCategoryName === "" && c.isDefault)) {
                c.labels = [...c.labels, label]
                break;
            }
        }
    } return newLabelCategories;
}

function removeLabelAndReturn(labelName: string, categoryName: string, labelCategories: LabelCategory[]): LabelCategory[]{
    let newLabelCategories: LabelCategory[] = JSON.parse(JSON.stringify(labelCategories));
    for (const c of newLabelCategories) {
        if (c.name === categoryName || (categoryName === "" && c.isDefault)) {
            c.labels = c.labels.filter(l => l.name !== labelName)
            break;
        }
    }  return newLabelCategories;
}

function removeLabelCategoryAndReturn(categoryName: string, labelCategories: LabelCategory[]): LabelCategory[]{
    let newLabelCategories: LabelCategory[] = JSON.parse(JSON.stringify(labelCategories));
    if(categoryName === "") return newLabelCategories;
    //move labels to default before removing
    let newDefaultLabels: Label[] = [];
    for (const c of newLabelCategories) {
        if (c.name === categoryName) {
            newDefaultLabels = c.labels;
            break;
        }
    }
    for (const c of newLabelCategories) {
        if (c.isDefault) {
            newDefaultLabels.forEach(l => c.labels.push(l))
            break;
        }
    }
    return newLabelCategories.filter(c => c.name !== categoryName);
}

interface LabelOperations{
    addNewLabelToCategory: (labelCategoryName: string, labelName: string) => void;
    addOrEditLabelCategory: (oldName: string | undefined, newName: string, newColor: string) => void;
    renameLabel: (categoryName: string, oldName: string, newName: string) => void;
    moveLabelToDifferentCategory: (labelName: string, oldCategoryName: string, newCategoryName: string) => void;
    removeLabel: (labelName: string, categoryName: string) => void;
    switchLabelCategories: (firstName: string, secondName: string) => void;
    removeLabelCategory: (labelCategoryName: string) => void;
    throwDuplicateLabelError: (name: string) => void;
    throwEmptyLabelError: () => void;
    throwDuplicateLabelCategoryError: (name: string) => void;
    throwEmptyLabelCategoryError: () => void;
}

interface LabelCategoryProps {
    labelCategory: LabelCategory;
    allCategoryNames: string[];
    labelOperations: LabelOperations;
    userCanAddLabels: boolean;
}

const LabelCategoryCard: React.FC<LabelCategoryProps> = ({
                                                         labelCategory,
                                                         labelOperations,
                                                         allCategoryNames,
                                                             userCanAddLabels
                                                     }) => {

    const {t} = useTranslation();
    const [showAddLabelInputField, setShowAddLabelInputField] = useState(false);
    const [showEditLabelCategoryPopup, setShowEditLabelCategoryPopup] = useState(false);
    const [showDeleteLabelCategoryPopup, setShowDeleteLabelCategoryPopup] = useState(false);
    const hideEditLabelCategoryPopup = () => setShowEditLabelCategoryPopup(false);
    const addLabel = (labelName: string) => {
        labelOperations.addNewLabelToCategory(labelCategory.name, labelName);
        setShowAddLabelInputField(false);
    }
    const deleteLabelCategory = () => {
        labelOperations.removeLabelCategory(labelCategory.name);
        setShowDeleteLabelCategoryPopup(false);
    }

    return (
        <>
            {showDeleteLabelCategoryPopup && <ConfirmPopup confirm={deleteLabelCategory} title={t("settings.delete_label_group_title")}
                                                           close={() => setShowDeleteLabelCategoryPopup(false)}
                                                           message={t("settings.delete_label_group_confirm")}
            />}
            <div className="first-header-alignment">
                {!labelCategory.isDefault && <>
                    <div className="text-icon-alignment">
                        <span>{labelCategory.name}</span>
                        <div className={'color-button'} style={{background: `#${labelCategory.color}`}}></div>
                    </div>
                    <div className="icon-design">
                        <span onClick={() => setShowEditLabelCategoryPopup(true)}>
                            <GearIcon />
                        </span>
                        <span onClick={() => setShowDeleteLabelCategoryPopup(true)}>
                            <TrashIcon/>
                        </span>
                        {showEditLabelCategoryPopup &&
                            <AddOrEditLabelCategoryPopup labelCategory={labelCategory}
                                                         closePopup={hideEditLabelCategoryPopup}
                                                         labelOperations={labelOperations}
                            />
                        }
                    </div>
                </>}
            </div>
            <div className="single-tags-button-alignment">
                {
                    labelCategory.labels.map((labelDto) => {
                        return <LabelTag key={labelDto.name} label={labelDto}
                                         labelCategory={labelCategory}
                                         allCategoryNames={allCategoryNames}
                                         labelOperations={labelOperations}
                        />
                    })
                }
                <div className={'label-div'} >

                    {userCanAddLabels &&
                        <AddLabelInput isLoading={false} showLabelInput={showAddLabelInputField} setShowLabelInput={setShowAddLabelInputField}
                                       showAddLabelPlaceholder={labelCategory.labels.length === 0}
                                       searchLabels={() => ""} addLabel={addLabel} dropdownElements={[]} blur={() => setShowAddLabelInputField(false)}/>
                    }

                </div>

            </div>

        </>
    )
}

interface LabelInputFieldProps {
    addOrEditLabel: (name: string) => void;
    initialState?: string;
}

const LabelInputField: React.FC<LabelInputFieldProps> = ({addOrEditLabel, initialState}) => {

    const {t} = useTranslation();

    const [name, setName] = useState(initialState ? initialState : "");
    const onNameChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setName(e.currentTarget.value);
    };

    return (
        <div className="add-a-group-name-alignemnt">
            <div className="add-tag-input-width-fix">
                <div className="input-new-relative">
                    <input type="text" maxLength={255} placeholder={t("settings.add_label")} onChange={onNameChange}/>
                    <div className="icon-alignment" onClick={() => {
                        addOrEditLabel(name)
                    }}>
                        <svg
                            width="26"
                            height="26"
                            viewBox="0 0 26 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect
                                x="1.30078"
                                y="1.2998"
                                width="23.4"
                                height="23.4"
                                rx="4"
                                fill="#1EC3CB"
                            />
                            <path
                                d="M7.80078 12.9998C7.80078 12.8274 7.86926 12.6621 7.99116 12.5402C8.11306 12.4183 8.27839 12.3498 8.45078 12.3498H12.3508V8.4498C12.3508 8.27741 12.4193 8.11208 12.5412 7.99019C12.6631 7.86829 12.8284 7.7998 13.0008 7.7998C13.1732 7.7998 13.3385 7.86829 13.4604 7.99019C13.5823 8.11208 13.6508 8.27741 13.6508 8.4498V12.3498H17.5508C17.7232 12.3498 17.8885 12.4183 18.0104 12.5402C18.1323 12.6621 18.2008 12.8274 18.2008 12.9998C18.2008 13.1722 18.1323 13.3375 18.0104 13.4594C17.8885 13.5813 17.7232 13.6498 17.5508 13.6498H13.6508V17.5498C13.6508 17.7222 13.5823 17.8875 13.4604 18.0094C13.3385 18.1313 13.1732 18.1998 13.0008 18.1998C12.8284 18.1998 12.6631 18.1313 12.5412 18.0094C12.4193 17.8875 12.3508 17.7222 12.3508 17.5498V13.6498H8.45078C8.27839 13.6498 8.11306 13.5813 7.99116 13.4594C7.86926 13.3375 7.80078 13.1722 7.80078 12.9998Z"
                                fill="white"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </div>)
}


interface ColorGridProps {
    selectedColor: string;
    setColor: (color: string) => void
}

const ColorGrid: React.FC<ColorGridProps> = ({selectedColor, setColor}) => {

    const {t} = useTranslation();

    const colors: string[] = [
        "#059D9A", "#F1D001", "#E64E3C", "#B0E47A", "#47DDB9", "#4084DF", "#F59B1F"
    ]

    return (
        <>

            <div className="color-plat">
                <label htmlFor="">{t("settings.add_label_group_color")}</label>
                <div className="color-grid">
                    {colors.map((color) =>
                        <div key={color}
                             className={color === "#" + selectedColor ? "color-grid-item selected" : "color-grid-item"}
                             style={{
                                 background: `${color}`
                             }}
                             onClick={() => setColor(color)}>
                            <CheckWhiteIcon/>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

interface LabelProps {
    label: Label
    labelCategory: LabelCategory;
    allCategoryNames: string[];
    labelOperations: LabelOperations;
}

export const LabelTag: React.FC<LabelProps> = ({label, labelCategory, allCategoryNames,  labelOperations}) => {

    const {t} = useTranslation();

    const [labelActionsDropdown, setLabelActionsDropdown] = useState(false);
    const [showMoveLabelDialogue, setShowMoveLabelDialogue] = useState(false);
    const [showRenamePopup, setShowRenamePopup] = useState(false);
    const closeRenamePopup = () => {
        setShowRenamePopup(false)
    }
    const [originalName, setOriginalName] = useState(label.name);
    const changeName = (newName: string) => {
        if (labelCategory.labels.filter(c => c.name === newName).length === 0) {
            labelOperations.renameLabel(labelCategory.name, originalName, newName);
            setOriginalName(newName);
        } //else name already in category

    }

    const openMoveLabelPopup = () => {
        setLabelActionsDropdown(false);
        setShowMoveLabelDialogue(true);
    }

    const openRenameLabelPopup = () => {
        setLabelActionsDropdown(false);
        setShowRenamePopup(true);
    }
    const [showDeleteLabelPopup, setShowDeleteLabelPopup] = useState(false);

    const deleteLabel = () => {
        labelOperations.removeLabel(label.name, labelCategory.name);
        setShowDeleteLabelPopup(false);
    }

    const labelColorStyle = {
        color: `#${labelCategory.color}`,
        border: `1px solid #${labelCategory.color}`
    }

    return (
        <>
            {showDeleteLabelPopup && <ConfirmPopup confirm={deleteLabel}
                                                           close={() => setShowDeleteLabelPopup(false)}
                                                           message={t("settings.delete_label_confirm")}
                                                           title={t("settings.delete_label_title")}
            />}
            <div className="label-div">
                <button style={labelColorStyle} onClick={() => setLabelActionsDropdown(!labelActionsDropdown)}
                        onBlur={() => setLabelActionsDropdown(false)} tabIndex={0}>
                    <span>{originalName}</span>
                </button>
                <div
                    className={labelActionsDropdown ? "relative-alignment-label-dropdown label-dropdown-show" : "relative-alignment-label-dropdown label-dropdown-hidden"}>
                    {/*onMouseDown is needed here because otherwise the onBlur event precedes the onClick and the option isn't triggered*/}
                    <div className="label-dropdown-design-alignment">
                        <a onMouseDown={() => openRenameLabelPopup()}>{t('settings.rename_label')}</a>
                        {allCategoryNames.length > 1 &&
                        <a onMouseDown={() => openMoveLabelPopup()}>{t('settings.move_label')}</a>}
                        <a onMouseDown={() => setShowDeleteLabelPopup(true)}>{t('settings.delete_label')}</a>
                    </div>
                </div>
            </div>
            {showMoveLabelDialogue && (
                <MoveLabelPopup labelCategories={[labelCategory]} currentCategoryName={labelCategory.name}
                                label={label} closePopup={() => setShowMoveLabelDialogue(false)}
                                moveLabelToDifferentCategory={labelOperations.moveLabelToDifferentCategory}
                                allCategoryNames={allCategoryNames}/>
            )}
            {showRenamePopup && (
                <AddOrEditLabelPopup labelName={label.name} changeLabelName={changeName} closePopup={closeRenamePopup}/>
            )}
        </>
    );
}

interface AddOrEditLabelPopupProps {
    labelName: string;
    changeLabelName: (newName: string) => void;
    closePopup: () => void;
}


export const AddOrEditLabelPopup: React.FC<AddOrEditLabelPopupProps> = ({labelName, changeLabelName, closePopup}) => {
    let {t} = useTranslation();
    const [localName, setLocalName] = useState(labelName);
    const save = () => {
        if (localName === ""){
            //maybe show a message here?
        }
        else {
            changeLabelName(localName);
            closePopup();
        }

    }
    return <>
        <div className="modal-wrapper-filter">
            <div className="small-modal">
                <div className="modal-header-alignment">
                    <div><h4>{t('settings.rename_label_popup_title')}</h4></div>
                    <div onClick={closePopup}><CloseIcon/></div>
                </div>
                <div className="modal-body">
                    <div className="input">
                        <label>{t("settings.add_label_group_name")}</label>
                        <input defaultValue={localName} onChange={e => setLocalName(e.currentTarget.value)}
                               type="text"/>
                    </div>
                    <div className="create-button">
                        <button onClick={save}>{t("settings.edit_label_group")}</button>
                    </div>
                </div>

            </div>
        </div>
    </>
}

interface AddOrEditLabelCategoryPopupProps {
    labelCategory?: LabelCategory;
    closePopup: () => void;
    labelOperations: LabelOperations;
}

export const AddOrEditLabelCategoryPopup: React.FC<AddOrEditLabelCategoryPopupProps> = (props) => {
    let labelCategory = props.labelCategory;
    let {t} = useTranslation();
    const [localName, setLocalName] = useState(labelCategory ? labelCategory.name : "");
    const [localColor, setLocalColor] = useState(labelCategory ? labelCategory.color : "059D9A");
    const setColor = (color: string) => {
        setLocalColor(color.replace("#", ""))
    }
    const save = () => {
        if (localName === "") props.labelOperations.throwEmptyLabelCategoryError();
        else {
            props.labelOperations.addOrEditLabelCategory(labelCategory ? labelCategory.name : undefined, localName, localColor);
            props.closePopup();
        }
    }
    return <>
        {/*the draggable and onDragStart attributes are to avoid propagation of parent drag in the popup*/}
        <div className="modal-wrapper-filter"
             draggable={true}
             onDragStart={(e) => {e.preventDefault(); e.stopPropagation();}}
        >
            <div className="small-modal">
                <div className="modal-header-alignment">
                    <div><h4>{t('settings.edit_label_group')}</h4></div>
                    <div onClick={props.closePopup}><CloseIcon/></div>
                </div>
                <div className="modal-body">
                    <div className="input">
                        <label>{t("settings.add_label_group_name")}</label>
                        <input defaultValue={localName} onChange={e => setLocalName(e.currentTarget.value)}
                               type="text"/>
                    </div>
                    <ColorGrid selectedColor={localColor} setColor={setColor}/>
                    <div className="create-button">
                        <button onClick={save}>{t("settings.save")}</button>
                    </div>
                </div>

            </div>
        </div>
    </>
}

interface MoveLabelDialogueProps {
    currentCategoryName: string;
    labelCategories: LabelCategory[];
    label: Label;
    closePopup: () => void
    moveLabelToDifferentCategory: (labelName: string, oldCategoryName: string, newCategoryName: string) => void;
    allCategoryNames: string[];

}

export const MoveLabelPopup: React.FC<MoveLabelDialogueProps> = ({
                                                                        currentCategoryName,
                                                                        labelCategories,
                                                                        label,
                                                                     allCategoryNames,
                                                                     moveLabelToDifferentCategory,
                                                                        closePopup
                                                                    }) => {

    const {t} = useTranslation();
    const [showDropDown, setShowDropDown] = useState(false);
    const [selectedCategoryName, setSelectedCategoryName] = useState(currentCategoryName);
    const [unselectedCategoryNames, setUnselectedCategoryNames] = useState(allCategoryNames.filter(c => c !== selectedCategoryName));
    const select = (categoryName: string) => {
        setSelectedCategoryName(categoryName);
        setUnselectedCategoryNames([...allCategoryNames].filter(c => c !== categoryName));
        setShowDropDown(false);
    }
    const save = () => {
        moveLabelToDifferentCategory(label.name, currentCategoryName, selectedCategoryName);
    }

    return <>
        <div className="modal-wrapper-filter">
            <div className="small-modal">
                <div className="modal-header-alignment">
                    <div><h4>{t('settings.move_label_popup_title')}</h4></div>
                    <div onClick={closePopup}><CloseIcon/></div>
                </div>
                <div className="modal-body">
                    <div className="input">
                        <div className="input-relative" onClick={() => setShowDropDown(!showDropDown)}>
                            <input type="text" maxLength={255} placeholder={selectedCategoryName !== "" ? selectedCategoryName : "default"} />
                            {/*<div>TEST</div>*/}
                            <div className="right-side-icon-input">
                                <div className={'center-align-image'}>
                                    <ArrowDownIcon/>
                                </div>
                            </div>
                            <div
                                className={
                                    showDropDown
                                        ? "input-dropdown input-dropdown-show"
                                        : "input-dropdown input-dropdown-hidden"
                                }>
                                <div className="input-dropdown-design">
                                    {unselectedCategoryNames.map(categoryName =>
                                        <span key={categoryName} onClick={() => select(categoryName)}>{categoryName !== "" ? categoryName : "default"}</span>)
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="create-button">
                        <button onClick={save}>{t("settings.move_label_popup_button")}</button>
                    </div>
                </div>

            </div>
        </div>
    </>
}

const LabelSettingsSkeleton = () => {
    return (
        <section>
            <SkeletonSection width={"80%"} height={4}/>
            <SkeletonSection width={"60%"} height={4}/>
            <SkeletonSection width={"60%"} height={4}/>
            <SkeletonSection width={"60%"} height={4}/>
            <SkeletonSection width={"60%"} height={4}/>
            {/*<SkeletonSection width={"60%"} height={12} />*/}
            {/*<SkeletonSection width={"60%"} height={12} />*/}
        </section>
    );
};


