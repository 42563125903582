import {ResourceKey} from "i18next";

export const Footer: ResourceKey = {
	follow_us: "Suivez-nous",
	contact_us: "Contactez-nous",
	office_ghent: "Bureau de Gand",
	office_brussels: "Bureau de Bruxelles",
	auguste_reyerslaan: "Boulevard Auguste Reyers",
	ghent: "Gand",
    brussels: "Bruxelles",
	help: "Help",
	helpdesk: "Helpdesk",
	list_of_concepts: "Glossaire",
	blog: "Blog",
	legal: "Mentions légales",
	privacy_policy: "Politique de confidentialité ",
	terms: "Conditions générales",
}