import {ResourceKey} from "i18next";

export const Footer: ResourceKey = {
	follow_us: "Follow us",
	contact_us: "Contact us",
	office_ghent: "Office Ghent",
	office_brussels: "Office Brussels",
	auguste_reyerslaan: "Boulevard Auguste Reyers",
	ghent: "Ghent",
    brussels: "Brussels",
	help: "Help",
	helpdesk: "Helpdesk",
	list_of_concepts: "List of concepts",
	blog: "Blog",
	legal: "Legal",
	privacy_policy: "Privacy policy ",
	terms: "Terms and conditions",
}