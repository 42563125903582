import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showCustomErrorPopup } from "../../hooks/slices/snaccSlice";
import { getErrorCode } from "../error";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";


export const useSignupErrorHandler = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (error: any) => {
    let errorCode = getErrorCode(error);
    if (errorCode === 400) {
      navigate("/opportunities"); //user is already signed up, navigate without showing error
    } else {
      dispatch(showCustomErrorPopup(getErrorMessage(errorCode, t)));
    }
  };
};

function getErrorMessage(errorCode: any, t: TFunction): string {
  switch (errorCode) {
    case 409:
      return t("intake.email_already_in_use");
    default:
      return t("error.oopsSomethingWentWrong");
  }
}