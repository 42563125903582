import { apiSlice } from "./apiSlice";
import { Client } from "../../types/client";
import { UserInteraction } from "./userSlice";
import { CodeBranch, CodeWithClass } from "../../types/code";
import { CuratedUserProfile } from "./savedSearchesSlice";

export type SignupCountriesPagePostParams = {
  countries: string[];
};

export type SignupAccreditationsPagePostParams = {
    accreditationCodes: CodeWithClass[]
    regionCodes: CodeBranch[]
};

export type SignupKeywordsPagePostParams = {
    cues: string[],
    activityDescription:string
};

export type SignupStartPagePostParams = {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    locale: string;
    governmentClient: boolean;
    companyUuid?: string;
    vatNumber?: string;
    tenderUuid?: string;
};

export type SignupCompanyPagePostParams = {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    locale: string;
    companyUuid: string;
};

export type SignupDetailsPagePostParams = {
    companyName: string;
    vatNumber: string;
    country: string | null | undefined;
    website: string;
};

export type SignupSurveyPostParams = {
    isSubcontractor: boolean;
    isLookingForSubcontractors: boolean;
    isLookingForPartners: boolean;
    isInterestedInPrivateContracts: boolean;
};

export type SignupCuratedProfilesParams = {
    curatedUserProfiles: CuratedUserProfile[]
};

export type SignupStartResponse = {
    token: string;
    signupComplete: boolean;
};

export type SignupDetailsResponse = {
    client: Client;
    shortlist: UserInteraction[];
    companyPageUuid: string;
};

export type SignupSurveyResponse = {
    accreditationCodes: CodeWithClass[];
};


const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        postSignupCountries: build.mutation<string, SignupCountriesPagePostParams>({
            query: (body: SignupCountriesPagePostParams) => {
                return {
                    url: `/shaggy/signup/third-countries`,
                    method: "POST",
                    body: body,
                    responseHandler: "text",
                };
            },
        }),
        postSignupKeywords: build.mutation<string, SignupKeywordsPagePostParams>({
            query: (body: SignupKeywordsPagePostParams) => {
                return {
                    url: `/shaggy/signup/fourth`,
                    method: "POST",
                    body: body,
                    responseHandler: "text",
                };
            },
        }),
        postSignupStart: build.mutation<SignupStartResponse, SignupStartPagePostParams>({
            query: (body: SignupStartPagePostParams) => {
                return {
                    url: `/shaggy/signup/first`,
                    method: "POST",
                    body: body,
                };
            },
        }),
        postSignupCompany: build.mutation<SignupStartResponse, SignupCompanyPagePostParams>({
            query: (body: SignupCompanyPagePostParams) => {
                return {
                    url: `/shaggy/signup/first-company`,
                    method: "POST",
                    body: body,
                };
            },
        }),
        postSignupDetails: build.mutation<SignupDetailsResponse, SignupDetailsPagePostParams>({
            query: (body: SignupDetailsPagePostParams) => {
                return {
                    url: `/shaggy/signup/second`,
                    method: "POST",
                    body: body,
                };
            },
        }),
        postSignupSurvey: build.mutation<SignupSurveyResponse, SignupSurveyPostParams>({
            query: (body: SignupSurveyPostParams) => {
                return {
                    url: `/shaggy/signup/third-survey`,
                    method: "POST",
                    body: body,
                };
            },
        }),
        postSignupAccreditations: build.mutation<string, SignupAccreditationsPagePostParams>({
            query: (body: SignupAccreditationsPagePostParams) => {
                return {
                    url: `/shaggy/signup/fourth-accreditations`,
                    method: "POST",
                    body: body,
                    responseHandler: "text",
                };
            },
        }),
        getSignupCuratedProfiles: build.query<SignupCuratedProfilesParams, void>({
            query: (params: void) => {
                return {
                    url: `/shaggy/signup/fourth-curated-profiles`,
                };
            },
        }),
        postSignupCuratedProfiles: build.mutation<string, SignupCuratedProfilesParams>({
            query: (body: SignupCuratedProfilesParams) => {
                return {
                    url: `/shaggy/signup/fourth-curated-profiles`,
                    method: "POST",
                    body: body,
                    responseHandler: "text",
                };
            },
        }),
    }),
});

export const {
    usePostSignupCountriesMutation,
    usePostSignupKeywordsMutation,
    usePostSignupStartMutation,
    usePostSignupCompanyMutation,
    usePostSignupDetailsMutation,
    usePostSignupAccreditationsMutation,
    usePostSignupSurveyMutation,
    usePostSignupCuratedProfilesMutation,
    useGetSignupCuratedProfilesQuery,
} = extendedApiSlice;