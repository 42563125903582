import React from "react";
import {SkeletonSection} from "../tenderDetails/main/TenderDetailsView";

interface AccountSettingsFormSkeletonProps {
    inputElements: number;
}
export const AccountSettingsFormSkeleton: React.FC<AccountSettingsFormSkeletonProps> = ({inputElements}) => {
    return <section style={{marginTop: '0px'}}>
            {/*<SkeletonSection width={"90%"} height={4} />*/}
            <div style={{width: "85%", display: "flex", flexDirection: "column"}}>
                <AccountSettingsSkeletonSection width={"25%"} height={60}/>
                {Array.from({length:inputElements},(v,k)=>k+1).map((i) =>
                    <AccountSettingsSkeletonSection width={"50%"} height={60} key={"AccountSettingsFormSkeleton_"+i} />)}
                <AccountSettingsSkeletonSection width={"25%"} height={60} />
            </div>
        </section>
    ;
};

interface AccountSectionSkeletonSectionProps {
    width: string;
    height: number;
}

export const AccountSettingsSkeletonSection: React.FC<AccountSectionSkeletonSectionProps> = ({ width, height }) => {
    return <div className={"skeleton-wave-background"} style={{height: height+ "px", width:width, borderRadius: '8px', margin: '0 0 24px 0'}}></div>;
};
