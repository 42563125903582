
export namespace PasswordUtil {
    export function passwordIsInvalid(password: string): boolean {
        return password.length < 8
            || !password.match(/[a-z]/)
            || !password.match(/[A-Z]/)
            || !password.match(/[0-9]/)
            || !password.match(/[^A-Za-z0-9]/); //special character
    }
}
