import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    CreateBesixProjectInput,
    useAddBesixProjectMutation,
    useGetBesixProjectStatusQuery
} from "../../../hooks/slices/tenderDetailsSlice";
import {skipToken} from "@reduxjs/toolkit/query";
import {BesixForceIcon} from "../../../components/icons";

export interface AddBesixProjectProps {
    tenderUuid: string;
    userUuid: string;
}

export const AddBesixProjectButton: React.FC<AddBesixProjectProps> = ({ tenderUuid, userUuid }) => {
    const { t } = useTranslation();
    let besixInput: CreateBesixProjectInput = { tenderUuid: tenderUuid, userUuid: userUuid };
    const [projectUrl, setProjectUrl] = useState<string | null>(null);
    const { data: projectStatus, isFetching } = useGetBesixProjectStatusQuery(besixInput ?? skipToken);
    const [addProject, { data: projectAdded, isLoading, isSuccess }] = useAddBesixProjectMutation();
    const addBesixProject = () => {
        addProject(besixInput);
    };
    const projectInvalid = projectStatus && !projectStatus.addNewProjectButton && !projectStatus.url;
    useEffect(() => {
        if (projectStatus != null && projectStatus.url != null) setProjectUrl(projectStatus.url);
    }, [projectStatus]);
    useEffect(() => {
        if (projectAdded != null && projectAdded.url != null) setProjectUrl(projectAdded.url);
    }, [projectAdded]);

    if (isFetching || !projectStatus || projectInvalid) return <></>;
    return (
        <li>
            <div className={"center-align converted-icon"}>
                <BesixForceIcon />
            </div>
            {isLoading && <div className={"center-align"}>{projectUrl == null && <span>...</span>}</div>}
            {!isLoading && (
                <div className={"center-align"}>
                    {projectUrl == null && <span onClick={addBesixProject}>{t("detailsSideBar.addToBesixForce")}</span>}
                    {projectUrl != null && (
                        <span onClick={addBesixProject}>
              <a href={projectUrl} target="_blank" rel="noreferrer">
                {t("detailsSideBar.goToBesixForce")}
              </a>
            </span>
                    )}
                </div>
            )}
        </li>
    );
};