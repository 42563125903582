import React, { useEffect, useState } from "react";
import {SearchParamUtil} from "../../../utils/searchparams";
import "./signup-start.scss";
import { useAppDispatch } from "../../../app/hooks";
import { updateToken } from "../../../hooks/slices/loginSlice";
import { showCustomErrorPopup } from "../../../hooks/slices/snaccSlice";
import { getI18n, useTranslation } from "react-i18next";
import { Form } from "../../../components/form/Form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { usePostSignupStartMutation } from "../../../hooks/slices/signupSlice";
import { EmailUtil } from "../../../utils/email";
import { ValidatedInput } from "../../../components/validatedInput/validatedInput";
import {GeneralTermsAndConditionsWindow} from "../../policies/generalTermsAndConditions";
import { CloseIcon } from "../../../components/icons";
import { persistor } from "../../../app/store";
import {Loader} from "../../../components/loader";
import { useGetAllPlanTemplatesQuery } from "../../../hooks/slices/currentPlanSlice";
import { PasswordUtil } from "../../../utils/password";
import { PlanUtil } from "../../../utils/plans";
import { Duration } from "../../../types/tender";
import { useSignupErrorHandler } from "../SignupErrorHandler";
import { useNavigateToOpportunitiesIfSignupAlreadyFinished } from "../SignupFinishedHandler";
import {PrivacyPolicyWindow} from "../../policies/privacyPolicy";

interface SignupStartProps {
  governmentClient: boolean;
}

export const SignupStartDefault: React.FC = () => {
    return <SignupStart governmentClient={false}></SignupStart>
}
export const SignupStartGovernment: React.FC = () => {
    return <SignupStart governmentClient={true}></SignupStart>
}

const SignupStart: React.FC<SignupStartProps> = ({governmentClient}) => {
  useNavigateToOpportunitiesIfSignupAlreadyFinished();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  let tenderUuid = searchParams.get("tenderUuid");
  let companyUuid = searchParams.get("companyUuid");
  const [postClient, { data: signupStartResponse, isSuccess, isError, error }] = usePostSignupStartMutation();
  const { data: availablePlanTemplates, isLoading } = useGetAllPlanTemplatesQuery();
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [highlightEmptyFields, setHighlightEmptyFields] = useState(false);
  const [highlightInvalidFields, setHighlightInvalidFields] = useState(false);
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const { t } = useTranslation();
  // on submission of the login form, we call the postClient trigger to
  // submit the client data to nighteyes and wait for a JWT in return
  const onSubmit = (firstName: string, lastName: string, email: string, password: string, confirmPassword: string, governmentClient: boolean) => {
    setHighlightEmptyFields(false);
    setHighlightInvalidFields(false);
    setPasswordMatch(true);
    if (firstName === "" || lastName === "" || email === "" || password === "" || confirmPassword === "") {
      setHighlightEmptyFields(true);
      dispatch(showCustomErrorPopup(t("intake.fill_in_all_fields")));
    } else if (!EmailUtil.emailAddressIsValid(email)) {
      setHighlightInvalidFields(true);
      dispatch(showCustomErrorPopup(t("intake.invalid_email")));
    } else if (PasswordUtil.passwordIsInvalid(password)) {
        setHighlightInvalidFields(true);
        dispatch(showCustomErrorPopup(t("intake.password_is_invalid")));
    }
    else if (password !== confirmPassword) {
      setPasswordMatch(false);
      dispatch(showCustomErrorPopup(t("intake.passwords_dont_match")));
    } else if (passwordMatch && !highlightEmptyFields) {
      let locale: string = getI18n().language;
      if(companyUuid != null) postClient({ firstName, lastName, email, password, locale, governmentClient, companyUuid});
      else postClient({ firstName, lastName, email, password, locale, governmentClient});
      setShowLoader(true);
    }
  };
  const navigateOrShowPopup = useSignupErrorHandler();

  useEffect(() => {
    if (isSuccess && signupStartResponse) {
      setShowLoader(false);
      // pass the data from the successful login to the redux store
      persistor
        .purge() // resets the state store, in case there is already another client in the store
        .then(() => {
          persistor.flush();
        })
        .then(() => dispatch(updateToken(signupStartResponse.token)));
      if (signupStartResponse.signupComplete && tenderUuid) {
            navigate("/tender/" + tenderUuid + "?add_to_shortlist=true");
      } else if (signupStartResponse.signupComplete) {
          navigate("/shortlist?deadline_date=everything");
      } else {
          navigate("/signup-details" + SearchParamUtil.passOnSearchParams(searchParams));
      }
    } else if (isError && error) {
      setShowLoader(false);
      // show an error on the snackbar with a failed login
      navigateOrShowPopup(error);
    }
  }, [isSuccess, signupStartResponse, isError, error, dispatch]);


  if (isLoading || availablePlanTemplates == null || availablePlanTemplates.planTemplates == null) return <></>;
  return (
    <>
      {showLoader && <Loader />}
      <RegisterForm
        onSubmit={onSubmit}
        passwordMatch={passwordMatch}
        highlightEmptyFields={highlightEmptyFields}
        highlightInvalidFields={highlightInvalidFields}
        trialPlanDuration={PlanUtil.getPlanDuration(governmentClient ? "Government Trial" : "Trial", availablePlanTemplates.planTemplates)}
        governmentClient={governmentClient}
      />
    </>
  );
};




interface RegisterFormProps {
    onSubmit: (firstName: string, lastName: string, email: string, password: string, confirmPassword: string, governmentClient: boolean) => void;
    passwordMatch: boolean;
    highlightEmptyFields: boolean;
    highlightInvalidFields: boolean;
    trialPlanDuration: Duration;
    governmentClient: boolean;

}

const RegisterForm: React.FC<RegisterFormProps> = ({onSubmit, passwordMatch, highlightEmptyFields, highlightInvalidFields, trialPlanDuration, governmentClient}) => {
    const [searchParams] = useSearchParams();
    let emailParam = searchParams.get("email");
    let nameParam = searchParams.get("name");
    const {t} = useTranslation();

    const [firstName, setFirstName] = useState(nameParam != null ? nameParam.split(" ")[0] : "");
    const onFirstNameChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setFirstName(e.currentTarget.value);
    };

    const [lastName, setLastName] = useState(nameParam != null && nameParam.split(" ").length > 1 ? nameParam.split(" ")[1] : "");
    const onLastNameChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setLastName(e.currentTarget.value);
    };

    const [email, setEmail] = useState(emailParam != null ? emailParam : "");
    const onEmailChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setEmail(e.currentTarget.value);
    };

    const [password, setPassword] = useState("");
    const onPasswordChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setPassword(e.currentTarget.value);
    };

    const [confirmPassword, setConfirmPassword] = useState("");
    const onConfirmPasswordChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setConfirmPassword(e.currentTarget.value);
    };

    const [termsAgree, setTermsAgree] = useState(false);
    const onTermsAgreeChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setTermsAgree(e.currentTarget.checked);
    };

    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
    let trialMessage = t("intake.trial_message_start") + trialPlanDuration.amount;
    if (trialPlanDuration.type === "DAY") trialMessage += t("intake.trial_message_end_days");
    else if (trialPlanDuration.type === "MONTH") trialMessage += t("intake.trial_message_end_months");
    else if (trialPlanDuration.type === "YEAR") trialMessage += t("intake.trial_message_end_years");
    return (
        <div>
            <div></div>
            <div className='layout-main-banner'>
                <div className='container'>
                        {(showPrivacyPolicy && !showTermsAndConditions) &&
                          <PrivacyPolicyOverlay setOverlay={setShowPrivacyPolicy}/>}
                        {(showTermsAndConditions && !showPrivacyPolicy) &&
                          <TermsAndConditionsOverlay setOverlay={setShowTermsAndConditions}/>
                        }
                        {(!showPrivacyPolicy && !showTermsAndConditions) &&
                          <div className='layout-top-alignment'>
                          <div className="register-box">
                            <div className='page-title'>
                                <h1>{governmentClient ? t("intake.title_government") : t("intake.title_default")}</h1>
                                <div className='child-text-all-alignment'>
                                    <p>*</p>
                                    {!governmentClient && <p>{trialMessage}</p>}
                                    {governmentClient && <p>{t("intake.trial_plan_message_government")}</p>}
                                </div>
                                <div className='child-text-all-alignment'>
                                    <p>*</p>
                                    {!governmentClient && <p>{t("intake.change_plan_message_default")}</p>}
                                    {governmentClient && <p>{t("intake.change_plan_message_government")}</p>}
                                </div>
                                {governmentClient && <div className='child-text-all-alignment'>
                                    <p>*</p>
                                    <p>{t("intake.promotion_plan_message")}</p>
                                </div>
                                }
                            </div>
                            <Form
                                onSubmit={() => {
                                    onSubmit(firstName, lastName, email, password, confirmPassword, governmentClient);
                                }}
                            >
                                <div className='input-first-grid'>
                                    <div className='input-first-grid-items'>
                                        <ValidatedInput
                                          value={firstName} placeholder={t("intake.first_name")}
                                          isInvalid={firstName === ""} highlightIfInvalid={highlightEmptyFields}
                                          onChange={onFirstNameChange}
                                          testId={"intake-first-name"}
                                        />
                                    </div>
                                    <div className='input-first-grid-items'>
                                        <ValidatedInput
                                          value={lastName} placeholder={t("intake.last_name")}
                                          isInvalid={lastName === ""} highlightIfInvalid={highlightEmptyFields}
                                          onChange={onLastNameChange} testId={"intake-second-name"}/>
                                    </div>
                                </div>
                                <div className='input-bottom-alignment'>
                                    <ValidatedInput
                                      value={email} placeholder={t("intake.email")}
                                      isInvalid={!EmailUtil.emailAddressIsValid(email) || email === ""} highlightIfInvalid={highlightInvalidFields || highlightEmptyFields}
                                      onChange={onEmailChange}
                                      testId={"intake-email"}
                                    />
                                </div>
                                <div className='input-bottom-alignment'>
                                    <ValidatedInput
                                      value={password} placeholder={t("intake.enter_password")}
                                      isInvalid={!passwordMatch || PasswordUtil.passwordIsInvalid(password)} highlightIfInvalid={!passwordMatch || highlightEmptyFields || highlightInvalidFields}
                                      onChange={onPasswordChange} type={"password"}
                                      testId={"intake-password"}
                                    />
                                </div>
                                <div className='input-bottom-alignment'>
                                    <ValidatedInput
                                      value={confirmPassword} placeholder={t("intake.confirm_password")}
                                      isInvalid={!passwordMatch || confirmPassword === ""} highlightIfInvalid={!passwordMatch || highlightEmptyFields}
                                      onChange={onConfirmPasswordChange} type={"password"}
                                      testId={"intake-password-confirm"}
                                    />
                                    {(PasswordUtil.passwordIsInvalid(password) && highlightInvalidFields) &&
                                        <div className='input-message'>
                                            <p>{t("intake.valid_password_description")}</p>
                                        </div>
                                    }
                                </div>
                                <div className='checkbox-text-alignment'>
                                    <div>
                                        <div className='child-product'>
                                            <input type="checkbox" id="terms" data-testid={"intake-terms"} onChange={onTermsAgreeChange}/>
                                            <label htmlFor="terms"><span>{t("intake.agree")}
                                                <a onClick={() => setShowPrivacyPolicy(true)}>
                                                    {t("intake.privacy_policy")}</a>
                                                {t("intake.and")}
                                                <a onClick={() => setShowTermsAndConditions(true)}>
                                                    {t("intake.terms")}</a></span></label>
                                        </div>
                                    </div>
                                </div>
                                <div className='register-button'>
                                    <div className={termsAgree ? "fill-button" : "fill-button disabled"}>
                                        <button id="register" data-testid={"signup-register-button"} type="submit"
                                                disabled={!termsAgree}>{t("intake.register")}</button>
                                    </div>
                                </div>
                                <div className='have-a-account'>
                                    <p>{t("intake.already_account")} <Link to="/login">{t("intake.login")}</Link></p>
                                </div>
                            </Form>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    )
}

interface PolicyOverlayProps {
  setOverlay: (isOverlayed: boolean) => void;

}



export const PrivacyPolicyOverlay: React.FC<PolicyOverlayProps> = ({ setOverlay }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="conditions-box">
        <PrivacyPolicyWindow />
        <div className="fill-button center-bottom">
          <button onClick={() => setOverlay(false)}>{t("intake.close")}</button>
        </div>
      </div>
    </>
  );
};
export const TermsAndConditionsOverlay: React.FC<PolicyOverlayProps> = ({ setOverlay }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="conditions-box">
        <GeneralTermsAndConditionsWindow />
        <div className="fill-button center-bottom">
          <button onClick={() => setOverlay(false)}>{t("intake.close")}</button>
        </div>
      </div>
    </>
  );
};
