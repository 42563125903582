import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../../components/loader";
import { updateCurrentPlan, usePostRenewTrialMutation } from "../../../hooks/slices/currentPlanSlice";
import { useAppDispatch } from "../../../app/hooks";
import {showCustomErrorPopup, showCustomSuccessPopup} from "../../../hooks/slices/snaccSlice";

export const RenewTrial: React.FC = () => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  let dispatch = useAppDispatch();
  const [postRenewTrial, { data, isLoading, isSuccess, isError, error: postPlanError }] = usePostRenewTrialMutation();

  useEffect(() => {
    postRenewTrial();
  }, []); //this only runs once (the array never change)

  useEffect(() => {
    if (data && isSuccess) {
      dispatch(updateCurrentPlan({ currentPlan: data.currentPlan }));
      dispatch(showCustomSuccessPopup(t("settings.your_trial_plan_has_been_renewed")));
      navigate("/account?tab=plan");
    } if (isError){
        dispatch(showCustomErrorPopup(t("settings.your_trial_plan_has_not_been_renewed")));
        navigate("/account?tab=plan");
    }
  }, [data, isLoading, isSuccess]);

  return <Loader />;
};
