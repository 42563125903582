import { ResourceKey } from "i18next";

export const Preferences: ResourceKey = {
	basicDetails: "Basic Details",
	firstName: "first name",
	emailAddress: "email address",
	language: "language",
	username: "username",
	password: "password",
	addressDetails: "Address Details",
	company: "company",
	street: "street",
	number: "number",
	city: "city",
	zip: "zip",
	phone: "phone",
	update: "update settings",
	unsubscribe_successful: "You have been unsubscribed",
	unsubscribe_error: "Unknown user",

};
