import { ResourceKey } from "i18next";

export const Time: ResourceKey = {
	from: "à partir de",
	to: "jusqu'à",
	today: "aujourd'hui",
	thisWeek: "cette semaine",
	thisMonth: "ce mois-ci",
	notExpired: "pas encore expiré",
	year: "année",
	month: "mois",
	day: "jour",
};
