import { useParams } from "react-router";
import { GetTenderUuidParams, useGetTenderUuidQuery } from "../../hooks/slices/publicationSlice";
import { Navigate } from "../navigate/Navigate";
import {Loader} from "../loader";
import { ErrorPage } from "../../pages/error";

export const ForwardToTender: React.FC = () => {
  const { data, isFetching, isSuccess, isError, error } = useGetTenderUuidQuery(QueryParameters());
  if(isSuccess && data) {
    let uuid: string = data ? data.uuid : "error";
    return <Navigate location={"/tender/" + uuid} />;
  } else if(isError) {
    return <ErrorPage error={error} />;
  } else {
    return <Loader />;
  }
};

export const QueryParameters = (): GetTenderUuidParams => {
  let params = useParams();
  let snid = params.snid || "";
  return {
    publicationSnid: snid,
  };
};