import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { forwardRef } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {closeSnacc} from "../../hooks/slices/snaccSlice";

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Snacc = () => {
    const snaccState = useAppSelector((state) => state.snacc);
    const dispatch = useAppDispatch();

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }

        dispatch(closeSnacc());
    };

    return (
        <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={snaccState.open}
            autoHideDuration={5000}
            onClose={handleClose}
        >
            <Alert severity={snaccState.severity} onClose={handleClose} sx={{ width: "100%" }} data-testid={"snaccbar-message"}>
                {snaccState.message}
            </Alert>
        </Snackbar>
    );
};
