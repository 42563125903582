import { ResourceKey } from "i18next";

export const Codes: ResourceKey = {

   code_SERVICES:"Services",
   code_WORKS:"Works",
   code_SUPPLIES:"Supplies",
   // Procedure types
   code_OPEN: "Open procedure",
   code_RESTRICTED: "Restricted procedure",
   code_PT_COMPETITIVE_NEGOTIATION: "Competitive procedure with negotiation",
   code_PT_COMPETITIVE_DIALOGUE: "Competitive dialogue",
   code_PT_INNOVATION_PARTNERSHIP: "Innovation partnership",
   code_INVOLVING_NEGOTIATION: "Procedure with negotiation",
   code_PT_NEGOTIATED_WITH_PRIOR_CALL: "Negotiated procedure with prior call for competition",
   code_NEGOTIATED_WITHOUT_PUBLICATION: "Negotiated procedure without prior call for competition",
   code_PT_COMPETITIVE: "Negotiated procedure without prior publication (with publication in the Free Market)",
   code_OTHER: "Other",
   code_PT_OPEN: "Open procedure",
   code_PT_RESTRICTED: "Restricted procedure",
   code_PT_INVOLVING_NEGOTIATION: "Procedure involving negotiations",
   code_NEGOTIATED: "Procedure involving negotiations",
   code_PT_NEGOTIATED_CHOICE: "Procedure involving negotiations",
   code_UNKNOWN: "Unknown",
   code_INNOVATION: "Innovation partnership",
   code_NEG_WO_CALL: "Negotiated without prior call for competition",
   code_NEG_W_CALL: "Negotiated with prior publication of a call for competition / competitive with negotiation",
   code_COMP_TEND: "Competitive tendering",
   code_OTH_SINGLE: "Other single stage procedure",
   code_COMP_DIAL: "Competitive dialogue",
   code_OTH_MULT: "Other multiple stage procedure",
   //MuncipalDecisionTypes
   code_ANNOUNCEMENT: "Announcement",
   code_AWARD: "Award",
// Accreditations
   code_A:"Dredging companies",
   code_A1:"Refloating of ships and removal of wrecks",
   code_B:"Waterworks contractors",
   code_B1:"River cleaning",
   code_C:"General road contractors",
   code_C1:"General sewerage works",
   code_C2:"Water supply and laying of various pipes",
   code_C3:"Non-electrical signalling of communication routes, safety devices, fences and screens of any type, non-electrical all types, non-electrical",
   code_C5:"Hydrocarbon coatings and renderings",
   code_C6:"Trenching of electrical power and telecommunication cables, without connection",
   code_C7:"Horizontal pipe bores for cables and pipes",
   code_D:"General building contractors",
   code_D1:"All structural work and roofing of buildings",
   code_D4:"Acoustic or thermal insulation, light partitions, false ceilings and false floors, prefabricated or not",
   code_D5:"General carpentry, wooden frames and stairs",
   code_D6:"Marble work and stone cutting",
   code_D7:"Ironwork",
   code_D8:"Asphalt roofing or similar, waterproofing work",
   code_D10:"Tiles",
   code_D11:"Plastering",
   code_D12:"Non-metallic and non-asphaltic roofing",
   code_D13:"Painting",
   code_D14:"Glazing",
   code_D15:"Flooring",
   code_D16:"Sanitary installations and gas heating installations by individual appliances",
   code_D17:"Central heating, thermal installations",
   code_D18:"Ventilation, warm air heating, air conditioning",
   code_D20:"Metal joinery",
   code_D21:"Façade renovation and restoration",
   code_D22:"Metal roofing and zinc work",
   code_D23:"Restoration by craftsmen",
   code_D24:"Restoration of monuments",
   code_D25:"Wall and floor coverings, other than marble, parquet and tiles",
   code_D29:"Industrial floor screeds and floor coverings",
   code_E:"Civil engineering contractors",
   code_E1:"Sewers",
   code_E2:"Deep foundations on piles, sheet piling, stamped walls",
   code_E4:"Horizontal foundations for civil engineering structures",
   code_F:"Steel construction companies",
   code_F1:"Erection and dismantling works (without supplies)",
   code_F2:"Construction of steel structures",
   code_F3:"Industrial painting",
   code_G:"Earthwork contractors",
   code_G1:"Drilling, boring and injection works",
   code_G2:"Drainage works",
   code_G3:"Plantations",
   code_G4:"Special surfaces for sports grounds",
   code_G5:"Demolition works",
   code_H:"Railway undertakings",
   code_H1:"Rail welding work",
   code_H2:"Installation of catenaries",
   code_K:"Mechanical equipment contractors",
   code_K1:"Equipment for civil engineering or industrial mechanics",
   code_K2:"Handling and lifting equipment (cranes, overhead cranes...)",
   code_K3:"Oleo-mechanical equipment",
   code_L:"Installation companies of hydromechanical equipment",
   code_L1:"Piping installations",
   code_L2:"Pumping and turbine station equipment",
   code_M:"Electronic equipment installation companies",
   code_M1:"Industrial or high frequency electronic equipment including power station equipment power stations",
   code_N:"Installation companies of transport systems in buildings",
   code_N1:"Lifts, hoists, escalators and moving walkways",
   code_N2:"Transport of objects, documents or goods by shafts or tubes (pneumatic, mechanical...)",
   code_P1:"Electrical installations in buildings, including generator installations, fire detection equipment fire and theft detection equipment, remote transmission in buildings and their surroundings and mixed telephony installations.",
   code_P2:"Electrical and electromechanical installations of engineering or industrial structures and electrical installations",
   code_P3:"Installations of overhead electric transmission lines",
   code_P4:"Electrical installations of port structures",
   code_S:"Installation companies of remote transmission and data management equipment",
   code_S1:"Telephone and telegraph equipment",
   code_S2:"Remote control, telecontrol and telemetry equipment",
   code_S3:"Radio and television transmission equipment, radar and antennas",
   code_S4:"Computer and process control equipment",
   code_T2:"Lightning conductors, receiving antennas",
   code_T3:"Refrigeration equipment",
   code_T4:"Laundry and kitchen equipment",
   code_T6:"Slaughterhouse equipment",
   code_U:"Refuse disposal equipment",
   code_V:"Water purification plants",
   code_03100000:"Agricultural and horticultural products",
   code_03110000:"Crops, products of market gardening and horticulture",
   code_03111000:"Seeds",
   code_03111100:"Soya beans",
   code_03111200:"Peanuts",
   code_03111300:"Sunflower seeds",
   code_03111400:"Cotton seeds",
   code_03111500:"Sesame seeds",
   code_03111600:"Mustard seeds",
   code_03111700:"Vegetable seeds",
   code_03111800:"Fruit seeds",
   code_03111900:"Flower seeds",
   code_03112000:"Unmanufactured tobacco",
   code_03113000:"Plants used for sugar manufacturing",
   code_03113100:"Sugar beet",
   code_03113200:"Sugar cane",
   code_03114000:"Straw and forage",
   code_03114100:"Straw",
   code_03114200:"Forage",
   code_03115000:"Raw vegetable materials",
   code_03115100:"Raw vegetable materials used in textile production",
   code_03115110:"Cotton",
   code_03115120:"Jute",
   code_03115130:"Flax",
   code_03116000:"Natural rubber and latex, and associated products",
   code_03116100:"Natural rubber",
   code_03116200:"Natural latex",
   code_03116300:"Latex products",
   code_03117000:"Plants used in specific fields",
   code_03117100:"Plants used in perfumery or pharmacy, or for insecticidal or similar purposes",
   code_03117110:"Plants used in perfumery",
   code_03117120:"Plants used in pharmacy",
   code_03117130:"Plants used for insecticidal purposes",
   code_03117140:"Plants used for fungicidal or similar purposes",
   code_03117200:"Seeds of plants used in specific fields",
   code_03120000:"Horticultural and nursery products",
   code_03121000:"Horticultural products",
   code_03121100:"Live plants, bulbs, roots, cuttings and slips",
   code_03121200:"Cut flowers",
   code_03121210:"Floral arrangements",
   code_03130000:"Beverage and spice crops",
   code_03131000:"Beverage crops",
   code_03131100:"Coffee beans",
   code_03131200:"Tea bushes",
   code_03131300:"Maté",
   code_03131400:"Cocoa beans",
   code_03132000:"Unprocessed spices",
   code_03140000:"Animal products and related products",
   code_03141000:"Bulls' semen",
   code_03142000:"Animal products",
   code_03142100:"Natural honey",
   code_03142200:"Snails",
   code_03142300:"Edible products of animal origin",
   code_03142400:"Waxes",
   code_03142500:"Eggs",
   code_03143000:"Products of mixed farming",
   code_03144000:"Agricultural supplies",
   code_03200000:"Cereals, potatoes, vegetables, fruits and nuts",
   code_03210000:"Cereals and potatoes",
   code_03211000:"Cereals",
   code_03211100:"Wheat",
   code_03211110:"Durum wheat",
   code_03211120:"Soft wheat",
   code_03211200:"Maize",
   code_03211300:"Rice",
   code_03211400:"Barley",
   code_03211500:"Rye",
   code_03211600:"Oats",
   code_03211700:"Malt",
   code_03211900:"Grain products",
   code_03212000:"Potatoes and dried vegetables",
   code_03212100:"Potatoes",
   code_03212200:"Dried leguminous vegetables and pulses",
   code_03212210:"Dried leguminous vegetables",
   code_03212211:"Lentils",
   code_03212212:"Chick peas",
   code_03212213:"Dried peas",
   code_03212220:"Pulses",
   code_03220000:"Vegetables, fruits and nuts",
   code_03221000:"Vegetables",
   code_03221100:"Root and tuber vegetables",
   code_03221110:"Root vegetables",
   code_03221111:"Beetroot",
   code_03221112:"Carrots",
   code_03221113:"Onions",
   code_03221114:"Turnips",
   code_03221120:"Tuber vegetables",
   code_03221200:"Fruit vegetables",
   code_03221210:"Beans",
   code_03221211:"Broad beans",
   code_03221212:"Green beans",
   code_03221213:"Runner beans",
   code_03221220:"Peas",
   code_03221221:"Garden peas",
   code_03221222:"Mange-tout",
   code_03221230:"Peppers",
   code_03221240:"Tomatoes",
   code_03221250:"Courgettes",
   code_03221260:"Mushrooms",
   code_03221270:"Cucumbers",
   code_03221300:"Leaf vegetables",
   code_03221310:"Lettuce",
   code_03221320:"Salad leaves",
   code_03221330:"Artichokes",
   code_03221340:"Spinach",
   code_03221400:"Cabbage vegetables",
   code_03221410:"Cabbage",
   code_03221420:"Cauliflower",
   code_03221430:"Broccoli",
   code_03221440:"Brussels sprouts",
   code_03222000:"Fruit and nuts",
   code_03222100:"Tropical fruit and nuts",
   code_03222110:"Tropical fruit",
   code_03222111:"Bananas",
   code_03222112:"Pineapples",
   code_03222113:"Mangoes",
   code_03222114:"Dates",
   code_03222115:"Raisins",
   code_03222116:"Figs",
   code_03222117:"Avocados",
   code_03222118:"Kiwi fruit",
   code_03222120:"Coconuts",
   code_03222200:"Citrus fruit",
   code_03222210:"Lemons",
   code_03222220:"Oranges",
   code_03222230:"Grapefruit",
   code_03222240:"Tangerines",
   code_03222250:"Limes",
   code_03222300:"Non-tropical fruit",
   code_03222310:"Berry fruit",
   code_03222311:"Currants",
   code_03222312:"Gooseberries",
   code_03222313:"Strawberries",
   code_03222314:"Raspberries",
   code_03222315:"Cranberries",
   code_03222320:"Apples, pears and quinces",
   code_03222321:"Apples",
   code_03222322:"Pears",
   code_03222323:"Quinces",
   code_03222330:"Stone fruit",
   code_03222331:"Apricots",
   code_03222332:"Peaches",
   code_03222333:"Cherries",
   code_03222334:"Plums",
   code_03222340:"Grapes",
   code_03222341:"Table grapes",
   code_03222342:"Wine grapes",
   code_03222400:"Olives",
   code_03300000:"Farming, hunting and fishing products",
   code_03310000:"Fish, crustaceans and aquatic products",
   code_03311000:"Fish",
   code_03311100:"Flat fish",
   code_03311110:"Sole",
   code_03311120:"Plaice",
   code_03311200:"Fish of the cod family",
   code_03311210:"Cod",
   code_03311220:"Pollock",
   code_03311230:"Hake",
   code_03311240:"Haddock",
   code_03311300:"Herring",
   code_03311400:"Tuna",
   code_03311500:"Whiting",
   code_03311600:"Whitebait",
   code_03311700:"Salmon",
   code_03312000:"Crustaceans",
   code_03312100:"Oysters",
   code_03312200:"Shellfish",
   code_03312300:"Aquatic invertebrates",
   code_03313000:"Aquatic products",
   code_03313100:"Corals or similar products",
   code_03313200:"Natural sponges",
   code_03313300:"Seaweeds",
   code_03313310:"Algae",
   code_03320000:"Cattle, livestock and small animals",
   code_03321000:"Cattle",
   code_03321100:"Bovine cattle",
   code_03321200:"Calves",
   code_03322000:"Livestock",
   code_03322100:"Sheep",
   code_03322200:"Goats",
   code_03322300:"Horses",
   code_03323000:"Pigs",
   code_03324000:"Live poultry",
   code_03325000:"Small animals",
   code_03325100:"Rabbits",
   code_03325200:"Hares",
   code_03330000:"Farm animal products",
   code_03331000:"Fresh milk from sheep and goats",
   code_03331100:"Sheep's milk",
   code_03331200:"Goats' milk",
   code_03332000:"Wool and animal hair",
   code_03332100:"Shorn wool",
   code_03332200:"Animal hair",
   code_03333000:"Fresh cows' milk",
   code_03340000:"Animal ear tags",
   code_03341000:"Bovine ear tags",
   code_03400000:"Forestry and logging products",
   code_03410000:"Wood",
   code_03411000:"Coniferous wood",
   code_03412000:"Tropical wood",
   code_03413000:"Fuel wood",
   code_03414000:"Rough wood",
   code_03415000:"Softwood",
   code_03416000:"Wood waste",
   code_03417000:"Scrap wood",
   code_03417100:"Sawdust",
   code_03418000:"Logs",
   code_03418100:"Hardwood",
   code_03419000:"Timber",
   code_03419100:"Timber products",
   code_03419200:"Mining timber",
   code_03420000:"Gums",
   code_03421000:"Balsams",
   code_03422000:"Lac",
   code_03430000:"Cork",
   code_03431000:"Natural cork",
   code_03432000:"Basketware",
   code_03432100:"Wickerwork",
   code_03440000:"Forestry products",
   code_03441000:"Ornamental plants, grasses, mosses or lichens",
   code_03450000:"Tree-nursery products",
   code_03451000:"Plants",
   code_03451100:"Bedding plants",
   code_03451200:"Flower bulbs",
   code_03451300:"Shrubs",
   code_03452000:"Trees",
   code_03460000:"Pulp",
   code_03461000:"Wood pulp",
   code_03461100:"Chemical wood pulp",
   code_09000000:"Petroleum products, fuel, electricity and other sources of energy",
   code_09100000:"Fuels",
   code_09110000:"Solid fuels",
   code_09111000:"Coal and coal-based fuels",
   code_09111100:"Coal",
   code_09111200:"Coal-based fuels",
   code_09111210:"Hard coal",
   code_09111220:"Briquettes",
   code_09111300:"Fossil fuels",
   code_09111400:"Wood fuels",
   code_09112000:"Lignite and peat",
   code_09112100:"Lignite",
   code_09112200:"Peat",
   code_09113000:"Coke",
   code_09120000:"Gaseous fuels",
   code_09121000:"Coal gas, mains gas or similar gases",
   code_09121100:"Coal gas or similar gases",
   code_09121200:"Mains gas",
   code_09122000:"Propane and butane",
   code_09122100:"Propane gas",
   code_09122110:"Liquefied propane gas",
   code_09122200:"Butane gas",
   code_09122210:"Liquefied butane gas",
   code_09123000:"Natural gas",
   code_09130000:"Petroleum and distillates",
   code_09131000:"Aviation kerosene",
   code_09131100:"Kerosene jet type fuels",
   code_09132000:"Petrol",
   code_09132100:"Unleaded petrol",
   code_09132200:"Leaded petrol",
   code_09132300:"Petrol with ethanol",
   code_09133000:"Liquefied Petroleum Gas (LPG)",
   code_09134000:"Gas oils",
   code_09134100:"Diesel oil",
   code_09134200:"Diesel fuel",
   code_09134210:"Diesel fuel (0,2)",
   code_09134220:"Diesel fuel (EN 590)",
   code_09134230:"Biodiesel",
   code_09134231:"Biodiesel (B20)",
   code_09134232:"Biodiesel (B100)",
   code_09135000:"Fuel oils",
   code_09135100:"Heating oil",
   code_09135110:"Low-sulphur combustible oils",
   code_09200000:"Petroleum, coal and oil products",
   code_09210000:"Lubricating preparations",
   code_09211000:"Lubricating oils and lubricating agents",
   code_09211100:"Motor oils",
   code_09211200:"Compressor lube oils",
   code_09211300:"Turbine lube oils",
   code_09211400:"Gear oils",
   code_09211500:"Reductor oils",
   code_09211600:"Oils for use in hydraulic systems and other purposes",
   code_09211610:"Liquids for hydraulic purposes",
   code_09211620:"Mould release oils",
   code_09211630:"Anti-corrosion oils",
   code_09211640:"Electrical insulating oils",
   code_09211650:"Brake fluids",
   code_09211700:"White oils and liquid paraffin",
   code_09211710:"White oils",
   code_09211720:"Liquid paraffin",
   code_09211800:"Petroleum oils and preparations",
   code_09211810:"Light oils",
   code_09211820:"Petroleum oils",
   code_09211900:"Lubricating traction oils",
   code_09220000:"Petroleum jelly, waxes and special spirits",
   code_09221000:"Petroleum jelly and waxes",
   code_09221100:"Petroleum jelly",
   code_09221200:"Paraffin wax",
   code_09221300:"Petroleum wax",
   code_09221400:"Petroleum residues",
   code_09222000:"Special spirits",
   code_09222100:"White spirit",
   code_09230000:"Petroleum (crude)",
   code_09240000:"Oil and coal-related products",
   code_09241000:"Bituminous or oil shale",
   code_09242000:"Coal-related products",
   code_09242100:"Coal oil",
   code_09300000:"Electricity, heating, solar and nuclear energy",
   code_09310000:"Electricity",
   code_09320000:"Steam, hot water and associated products",
   code_09321000:"Hot water",
   code_09322000:"Steam",
   code_09323000:"District heating",
   code_09324000:"Long-distance heating",
   code_09330000:"Solar energy",
   code_09331000:"Solar panels",
   code_09331100:"Solar collectors for heat production",
   code_09331200:"Solar photovoltaic modules",
   code_09332000:"Solar installation",
   code_09340000:"Nuclear fuels",
   code_09341000:"Uranium",
   code_09342000:"Plutonium",
   code_09343000:"Radioactive materials",
   code_09344000:"Radio-isotopes",
   code_14000000:"Mining, basic metals and related products",
   code_14200000:"Sand and clay",
   code_14210000:"Gravel, sand, crushed stone and aggregates",
   code_14211000:"Sand",
   code_14211100:"Natural sand",
   code_14212000:"Granules, chippings, stone powder, pebbles, gravel, broken and crushed stone, stone mixtures, sand-gravel mixtures and other aggregates",
   code_14212100:"Pebbles and gravel",
   code_14212110:"Pebbles",
   code_14212120:"Gravel",
   code_14212200:"Aggregates",
   code_14212210:"Sand-gravel mix",
   code_14212300:"Broken and crushed stone",
   code_14212310:"Ballast",
   code_14212320:"Crushed granite",
   code_14212330:"Crushed basalt",
   code_14212400:"Soil",
   code_14212410:"Topsoil",
   code_14212420:"Subsoil",
   code_14212430:"Stone chippings",
   code_14213000:"Macadam, tarmac and tar sands",
   code_14213100:"Macadam",
   code_14213200:"Tarmac",
   code_14213300:"Tar sands",
   code_14220000:"Clay and kaolin",
   code_14221000:"Clay",
   code_14222000:"Kaolin",
   code_14300000:"Chemical and fertiliser minerals",
   code_14310000:"Fertiliser minerals",
   code_14311000:"Natural calcium, aluminium calcium phosphate and crude natural potassium salts",
   code_14311100:"Natural calcium",
   code_14311200:"Aluminium calcium phosphates",
   code_14311300:"Crude natural potassium salts",
   code_14312000:"Iron pyrites",
   code_14312100:"Unroasted iron pyrites",
   code_14320000:"Chemical minerals",
   code_14400000:"Salt and pure sodium chloride",
   code_14410000:"Rock salt",
   code_14420000:"Sea salt",
   code_14430000:"Evaporated salt and pure sodium chloride",
   code_14450000:"Salt in brine",
   code_14500000:"Related mining and quarrying products",
   code_14520000:"Precious and semi-precious stones, pumice stone, emery, natural abrasives, other minerals and precious metals",
   code_14521000:"Precious and semi-precious stones",
   code_14521100:"Precious stones",
   code_14521140:"Dust or powder of precious stones",
   code_14521200:"Semi-precious stones",
   code_14521210:"Dust or powder of semi-precious stones",
   code_14522000:"Industrial diamonds, pumice stone, emery and other natural abrasives",
   code_14522100:"Pumice stone",
   code_14522200:"Industrial diamonds",
   code_14522300:"Emery",
   code_14522400:"Natural abrasives",
   code_14523000:"Related minerals, precious metals and associated products",
   code_14523100:"Minerals",
   code_14523200:"Gold",
   code_14523300:"Silver",
   code_14523400:"Platinum",
   code_14600000:"Metal ores and alloys",
   code_14610000:"Metal ores",
   code_14611000:"Iron ores",
   code_14612000:"Non-ferrous metal ores",
   code_14612100:"Copper ores",
   code_14612200:"Nickel ores",
   code_14612300:"Aluminium ores",
   code_14612400:"Precious-metal ores",
   code_14612500:"Lead ores",
   code_14612600:"Zinc ores",
   code_14612700:"Tin ores",
   code_14613000:"Uranium and thorium ores",
   code_14613100:"Uranium ores",
   code_14613200:"Thorium ores",
   code_14614000:"Miscellaneous ores",
   code_14620000:"Alloys",
   code_14621000:"Ferro-alloys",
   code_14621100:"Non-ECSC ferro-alloys",
   code_14621110:"Ferro-manganese",
   code_14621120:"Ferro-chromium",
   code_14621130:"Ferro-nickel",
   code_14622000:"Steel",
   code_14630000:"Slag, dross, ferrous waste and scrap",
   code_14700000:"Basic metals",
   code_14710000:"Iron, lead, zinc, tin and copper",
   code_14711000:"Iron",
   code_14711100:"Pig iron",
   code_14712000:"Lead",
   code_14713000:"Zinc",
   code_14714000:"Tin",
   code_14715000:"Copper",
   code_14720000:"Aluminium, nickel, scandium, titanium and vanadium",
   code_14721000:"Aluminium",
   code_14721100:"Aluminium oxide",
   code_14722000:"Nickel",
   code_14723000:"Scandium",
   code_14724000:"Titanium",
   code_14725000:"Vanadium",
   code_14730000:"Chromium, manganese, cobalt, yttrium and zirconium",
   code_14731000:"Chromium",
   code_14732000:"Manganese",
   code_14733000:"Cobalt",
   code_14734000:"Yttrium",
   code_14735000:"Zirconium",
   code_14740000:"Molybdenum, technetium, ruthenium and rhodium",
   code_14741000:"Molybdenum",
   code_14742000:"Technetium",
   code_14743000:"Ruthenium",
   code_14744000:"Rhodium",
   code_14750000:"Cadmium, lutetium, hafnium, tantalum and tungsten",
   code_14751000:"Cadmium",
   code_14752000:"Lutetium",
   code_14753000:"Hafnium",
   code_14754000:"Tantalum",
   code_14755000:"Tungsten",
   code_14760000:"Iridium, gallium, indium, thallium and barium",
   code_14761000:"Iridium",
   code_14762000:"Gallium",
   code_14763000:"Indium",
   code_14764000:"Thallium",
   code_14765000:"Barium",
   code_14770000:"Caesium, strontium, rubidium and calcium",
   code_14771000:"Caesium",
   code_14772000:"Strontium",
   code_14773000:"Rubidium",
   code_14774000:"Calcium",
   code_14780000:"Potassium, magnesium, sodium and lithium",
   code_14781000:"Potassium",
   code_14782000:"Magnesium",
   code_14783000:"Sodium",
   code_14784000:"Lithium",
   code_14790000:"Niobium, osmium, rhenium and palladium",
   code_14791000:"Niobium",
   code_14792000:"Osmium",
   code_14793000:"Rhenium",
   code_14794000:"Palladium",
   code_14800000:"Miscellaneous non-metallic mineral products",
   code_14810000:"Abrasive products",
   code_14811000:"Millstones, grindstones and grinding wheels",
   code_14811100:"Millstones",
   code_14811200:"Grindstones",
   code_14811300:"Grinding wheels",
   code_14812000:"Abrasive powder or grain",
   code_14813000:"Artificial corundum",
   code_14814000:"Artificial graphite",
   code_14820000:"Glass",
   code_14830000:"Fibreglass",
   code_14900000:"Recovered secondary raw materials",
   code_14910000:"Recovered secondary metal raw materials",
   code_14920000:"Recovered secondary non-metal raw materials",
   code_14930000:"Ash and residues containing metals",
   code_15000000:"Food, beverages, tobacco and related products",
   code_15100000:"Animal products, meat and meat products",
   code_15110000:"Meat",
   code_15111000:"Bovine meat",
   code_15111100:"Beef",
   code_15111200:"Veal",
   code_15112000:"Poultry",
   code_15112100:"Fresh poultry",
   code_15112110:"Geese",
   code_15112120:"Turkeys",
   code_15112130:"Chickens",
   code_15112140:"Ducks",
   code_15112300:"Poultry livers",
   code_15112310:"Foie gras",
   code_15113000:"Pork",
   code_15114000:"Offal",
   code_15115000:"Lamb and mutton",
   code_15115100:"Lamb",
   code_15115200:"Mutton",
   code_15117000:"Goat meat",
   code_15118000:"Horse, ass, mule or hinny meat",
   code_15118100:"Horsemeat",
   code_15118900:"Ass, mule or hinny meat",
   code_15119000:"Various meats",
   code_15119100:"Rabbit meat",
   code_15119200:"Hare meat",
   code_15119300:"Game",
   code_15119400:"Frogs' legs",
   code_15119500:"Pigeons",
   code_15119600:"Fish meat",
   code_15130000:"Meat products",
   code_15131000:"Meat preserves and preparations",
   code_15131100:"Sausage-meat products",
   code_15131110:"Sausage meat",
   code_15131120:"Sausage products",
   code_15131130:"Sausages",
   code_15131134:"Black pudding and other blood sausages",
   code_15131135:"Poultry sausages",
   code_15131200:"Dried, salted, smoked or seasoned meat",
   code_15131210:"Gammon",
   code_15131220:"Bacon",
   code_15131230:"Salami",
   code_15131300:"Liver preparations",
   code_15131310:"Pâté",
   code_15131320:"Preparations of goose or duck liver",
   code_15131400:"Pork products",
   code_15131410:"Ham",
   code_15131420:"Meatballs",
   code_15131490:"Prepared pork dishes",
   code_15131500:"Poultry products",
   code_15131600:"Beef and veal products",
   code_15131610:"Beef meatballs",
   code_15131620:"Minced beef",
   code_15131640:"Beefburgers",
   code_15131700:"Meat preparations",
   code_15200000:"Prepared and preserved fish",
   code_15210000:"Fish fillets, fish livers and roe",
   code_15211000:"Fish fillets",
   code_15211100:"Fresh fish fillets",
   code_15212000:"Fish roe",
   code_15213000:"Fish livers",
   code_15220000:"Frozen fish, fish fillets and other fish meat",
   code_15221000:"Frozen fish",
   code_15229000:"Frozen fish products",
   code_15230000:"Dried or salted fish; fish in brine; smoked fish",
   code_15231000:"Dried fish",
   code_15232000:"Salted fish",
   code_15233000:"Fish in brine",
   code_15234000:"Smoked fish",
   code_15235000:"Preserved fish",
   code_15240000:"Canned or tinned fish and other prepared or preserved fish",
   code_15241000:"Coated, canned or tinned fish",
   code_15241100:"Canned salmon",
   code_15241200:"Prepared or preserved herring",
   code_15241300:"Sardines",
   code_15241400:"Canned tuna",
   code_15241500:"Mackerel",
   code_15241600:"Anchovies",
   code_15241700:"Fish fingers",
   code_15241800:"Coated fish preparations",
   code_15242000:"Prepared fish dishes",
   code_15243000:"Preparations of fish",
   code_15244000:"Caviar and fish eggs",
   code_15244100:"Caviar",
   code_15244200:"Fish eggs",
   code_15250000:"Seafood",
   code_15251000:"Frozen crustaceans",
   code_15252000:"Prepared or preserved crustaceans",
   code_15253000:"Shellfish products",
   code_15300000:"Fruit, vegetables and related products",
   code_15310000:"Potatoes and potato products",
   code_15311000:"Frozen potatoes",
   code_15311100:"Chips or french fries",
   code_15311200:"Diced, sliced and other frozen potatoes",
   code_15312000:"Potato products",
   code_15312100:"Instant mashed potatoes",
   code_15312200:"Part-fried potato chips",
   code_15312300:"Potato crisps",
   code_15312310:"Flavoured potato crisps",
   code_15312400:"Potato snacks",
   code_15312500:"Potato croquettes",
   code_15313000:"Processed potatoes",
   code_15320000:"Fruit and vegetable juices",
   code_15321000:"Fruit juices",
   code_15321100:"Orange juice",
   code_15321200:"Grapefruit juice",
   code_15321300:"Lemon juice",
   code_15321400:"Pineapple juice",
   code_15321500:"Grape juice",
   code_15321600:"Apple juice",
   code_15321700:"Mixtures of unconcentrated juices",
   code_15321800:"Concentrated juices",
   code_15322000:"Vegetable juices",
   code_15322100:"Tomato juice",
   code_15330000:"Processed fruit and vegetables",
   code_15331000:"Processed vegetables",
   code_15331100:"Fresh or frozen vegetables",
   code_15331110:"Processed root vegetables",
   code_15331120:"Processed tuber vegetables",
   code_15331130:"Beans, peas, peppers, tomatoes and other vegetables",
   code_15331131:"Processed beans",
   code_15331132:"Processed peas",
   code_15331133:"Split peas",
   code_15331134:"Processed tomatoes",
   code_15331135:"Processed mushrooms",
   code_15331136:"Processed peppers",
   code_15331137:"Soybean sprouts",
   code_15331138:"Truffles",
   code_15331140:"Leaf and cabbage vegetables",
   code_15331142:"Processed cabbage",
   code_15331150:"Processed pulses",
   code_15331170:"Frozen vegetables",
   code_15331400:"Preserved and/or canned vegetables",
   code_15331410:"Beans in tomato sauce",
   code_15331411:"Baked beans",
   code_15331420:"Preserved tomatoes",
   code_15331423:"Canned tomatoes",
   code_15331425:"Tomato purée",
   code_15331427:"Concentrated tomato purée",
   code_15331428:"Tomato sauce",
   code_15331430:"Canned mushrooms",
   code_15331450:"Processed olives",
   code_15331460:"Canned vegetables",
   code_15331461:"Canned sauerkraut",
   code_15331462:"Canned peas",
   code_15331463:"Canned shelled beans",
   code_15331464:"Canned whole beans",
   code_15331465:"Canned asparagus",
   code_15331466:"Canned olives",
   code_15331470:"Sweet corn",
   code_15331480:"Provisionally preserved vegetables",
   code_15331500:"Vegetables preserved in vinegar",
   code_15332000:"Processed fruit and nuts",
   code_15332100:"Processed fruit",
   code_15332140:"Processed apples",
   code_15332150:"Processed pears",
   code_15332160:"Processed bananas",
   code_15332170:"Rhubarb",
   code_15332180:"Melons",
   code_15332200:"Jams and marmalades; fruit jellies; fruit or nut purée and pastes",
   code_15332230:"Marmalades",
   code_15332231:"Orange marmalade",
   code_15332232:"Lemon marmalade",
   code_15332240:"Fruit jellies",
   code_15332250:"Fruit pastes",
   code_15332260:"Nut pastes",
   code_15332261:"Peanut butter",
   code_15332270:"Fruit purées",
   code_15332290:"Jams",
   code_15332291:"Apricot jam",
   code_15332292:"Blackberry jam",
   code_15332293:"Blackcurrant jam",
   code_15332294:"Cherry jam",
   code_15332295:"Raspberry jam",
   code_15332296:"Strawberry jam",
   code_15332300:"Processed nuts",
   code_15332310:"Roasted or salted nuts",
   code_15332400:"Preserved fruits",
   code_15332410:"Dried fruit",
   code_15332411:"Processed currants",
   code_15332412:"Processed raisins",
   code_15332419:"Sultanas",
   code_15333000:"Vegetable by-products",
   code_15400000:"Animal or vegetable oils and fats",
   code_15410000:"Crude animal or vegetable oils and fats",
   code_15411000:"Animal or vegetable oils",
   code_15411100:"Vegetable oils",
   code_15411110:"Olive oil",
   code_15411120:"Sesame oil",
   code_15411130:"Groundnut oil",
   code_15411140:"Coconut oil",
   code_15411200:"Cooking oil",
   code_15411210:"Frying oil",
   code_15412000:"Fats",
   code_15412100:"Animal fats",
   code_15412200:"Vegetable fats",
   code_15413000:"Solid residues of vegetable fats or oils",
   code_15413100:"Oilcake",
   code_15420000:"Refined oils and fats",
   code_15421000:"Refined oils",
   code_15422000:"Refined fats",
   code_15423000:"Hydrogenated or esterified oils or fats",
   code_15424000:"Vegetable waxes",
   code_15430000:"Edible fats",
   code_15431000:"Margarine and similar preparations",
   code_15431100:"Margarine",
   code_15431110:"Liquid margarine",
   code_15431200:"Reduced or low-fat spreads",
   code_15500000:"Dairy products",
   code_15510000:"Milk and cream",
   code_15511000:"Milk",
   code_15511100:"Pasteurised milk",
   code_15511200:"Sterilised milk",
   code_15511210:"UHT milk",
   code_15511300:"Skimmed milk",
   code_15511400:"Semi-skimmed milk",
   code_15511500:"Full-cream milk",
   code_15511600:"Condensed milk",
   code_15511700:"Milk powder",
   code_15512000:"Cream",
   code_15512100:"Single cream",
   code_15512200:"Double cream",
   code_15512300:"Clotted cream",
   code_15512900:"Whipping cream",
   code_15530000:"Butter",
   code_15540000:"Cheese products",
   code_15541000:"Table cheese",
   code_15542000:"Fresh cheese",
   code_15542100:"Cottage cheese",
   code_15542200:"Soft cheese",
   code_15542300:"Feta cheese",
   code_15543000:"Grated, powdered, blue-veined and other cheese",
   code_15543100:"Blue cheese",
   code_15543200:"Cheddar cheese",
   code_15543300:"Grated cheese",
   code_15543400:"Parmesan cheese",
   code_15544000:"Hard cheese",
   code_15545000:"Cheese spreads",
   code_15550000:"Assorted dairy products",
   code_15551000:"Yoghurt and other fermented milk products",
   code_15551300:"Yoghurt",
   code_15551310:"Unflavoured yoghurt",
   code_15551320:"Flavoured yoghurt",
   code_15551500:"Buttermilk",
   code_15552000:"Casein",
   code_15553000:"Lactose or lactose syrup",
   code_15554000:"Whey",
   code_15555000:"Ice cream and similar products",
   code_15555100:"Ice cream",
   code_15555200:"Sorbet",
   code_15600000:"Grain mill products, starches and starch products",
   code_15610000:"Grain mill products",
   code_15611000:"Husked rice",
   code_15612000:"Cereal or vegetable flour and related products",
   code_15612100:"Wheat flour",
   code_15612110:"Wholemeal flour",
   code_15612120:"Breadmaking flour",
   code_15612130:"Plain flour",
   code_15612150:"Pastry flour",
   code_15612190:"Self-raising flour",
   code_15612200:"Cereal flour",
   code_15612210:"Corn flour",
   code_15612220:"Rice flour",
   code_15612300:"Vegetable flour and meal",
   code_15612400:"Mixes for the preparation of baker's wares",
   code_15612410:"Cake mixes",
   code_15612420:"Baking mixes",
   code_15612500:"Bakery products",
   code_15613000:"Cereal grain products",
   code_15613100:"Ground oats",
   code_15613300:"Cereal products",
   code_15613310:"Prepared breakfast cereals",
   code_15613311:"Cornflakes",
   code_15613313:"Muesli or equivalent",
   code_15613319:"Puffed wheat",
   code_15613380:"Rolled oats",
   code_15614000:"Processed rice",
   code_15614100:"Long-grain rice",
   code_15614200:"Milled rice",
   code_15614300:"Broken rice",
   code_15615000:"Bran",
   code_15620000:"Starches and starch products",
   code_15621000:"Corn oil",
   code_15622000:"Glucose and glucose products; fructose and fructose products",
   code_15622100:"Glucose and glucose products",
   code_15622110:"Glucose",
   code_15622120:"Glucose syrup",
   code_15622300:"Fructose and fructose products",
   code_15622310:"Fructose",
   code_15622320:"Fructose preparations",
   code_15622321:"Fructose solutions",
   code_15622322:"Fructose syrup",
   code_15623000:"Starches",
   code_15624000:"Tapioca",
   code_15625000:"Semolina",
   code_15626000:"Custard powder",
   code_15700000:"Animal feedstuffs",
   code_15710000:"Prepared animal feeds for farm and other animals",
   code_15711000:"Fish food",
   code_15712000:"Dry fodder",
   code_15713000:"Pet food",
   code_15800000:"Miscellaneous food products",
   code_15810000:"Bread products, fresh pastry goods and cakes",
   code_15811000:"Bread products",
   code_15811100:"Bread",
   code_15811200:"Rolls",
   code_15811300:"Croissants",
   code_15811400:"Crumpets",
   code_15811500:"Prepared bread products",
   code_15811510:"Sandwiches",
   code_15811511:"Prepared sandwiches",
   code_15812000:"Pastry goods and cakes",
   code_15812100:"Pastry goods",
   code_15812120:"Pies",
   code_15812121:"Savoury pies",
   code_15812122:"Sweet pies",
   code_15812200:"Cakes",
   code_15813000:"Morning goods",
   code_15820000:"Rusks and biscuits; preserved pastry goods and cakes",
   code_15821000:"Toasted bread products and pastry goods",
   code_15821100:"Toasted bread products",
   code_15821110:"Toasted bread",
   code_15821130:"Crispbread",
   code_15821150:"Rusks",
   code_15821200:"Sweet biscuits",
   code_15830000:"Sugar and related products",
   code_15831000:"Sugar",
   code_15831200:"White sugar",
   code_15831300:"Maple sugar and maple syrup",
   code_15831400:"Molasses",
   code_15831500:"Sugar syrups",
   code_15831600:"Honey",
   code_15832000:"Sugar-manufacture waste",
   code_15833000:"Sugar products",
   code_15833100:"Desserts",
   code_15833110:"Flans",
   code_15840000:"Cocoa; chocolate and sugar confectionery",
   code_15841000:"Cocoa",
   code_15841100:"Cocoa paste",
   code_15841200:"Cocoa butter, fat or oil",
   code_15841300:"Unsweetened cocoa powder",
   code_15841400:"Sweetened cocoa powder",
   code_15842000:"Chocolate and sugar confectionery",
   code_15842100:"Chocolate",
   code_15842200:"Chocolate products",
   code_15842210:"Drinking chocolate",
   code_15842220:"Chocolate bars",
   code_15842300:"Confectionery",
   code_15842310:"Boiled sweets",
   code_15842320:"Nougat",
   code_15842400:"Fruit, nuts or fruit peel preserved by sugar",
   code_15850000:"Pasta products",
   code_15851000:"Farinaceous products",
   code_15851100:"Uncooked pasta",
   code_15851200:"Prepared pasta and couscous",
   code_15851210:"Prepared pasta",
   code_15851220:"Stuffed pasta",
   code_15851230:"Lasagne",
   code_15851250:"Couscous",
   code_15851290:"Canned pasta",
   code_15860000:"Coffee, tea and related products",
   code_15861000:"Coffee",
   code_15861100:"Roasted coffee",
   code_15861200:"Decaffeinated coffee",
   code_15862000:"Coffee substitutes",
   code_15863000:"Tea",
   code_15863100:"Green tea",
   code_15863200:"Black tea",
   code_15864000:"Preparations of tea or maté",
   code_15864100:"Teabags",
   code_15865000:"Herbal infusions",
   code_15870000:"Condiments and seasonings",
   code_15871000:"Vinegar; sauces; mixed condiments; mustard flour and meal; prepared mustard",
   code_15871100:"Vinegar and vinegar substitutes",
   code_15871110:"Vinegar or equivalent",
   code_15871200:"Sauces, mixed condiments and mixed seasonings",
   code_15871210:"Soya sauce",
   code_15871230:"Tomato ketchup",
   code_15871250:"Mustard",
   code_15871260:"Sauces",
   code_15871270:"Mixed condiments",
   code_15871273:"Mayonnaise",
   code_15871274:"Sandwich spreads",
   code_15871279:"Chutney",
   code_15872000:"Herbs and spices",
   code_15872100:"Pepper",
   code_15872200:"Spices",
   code_15872300:"Herbs",
   code_15872400:"Salt",
   code_15872500:"Ginger",
   code_15880000:"Special nutritional products",
   code_15881000:"Homogenised food preparations",
   code_15882000:"Dietetic products",
   code_15884000:"Baby food",
   code_15890000:"Miscellaneous food products and dried goods",
   code_15891000:"Soups and broths",
   code_15891100:"Meat soups",
   code_15891200:"Fish soups",
   code_15891300:"Mixed soups",
   code_15891400:"Soups",
   code_15891410:"Soup mixes",
   code_15891500:"Broths",
   code_15891600:"Stocks",
   code_15891610:"Mixes for stocks",
   code_15891900:"Vegetable soups",
   code_15892000:"Vegetable saps, extracts, peptic substances and thickeners",
   code_15892100:"Vegetable saps",
   code_15892200:"Vegetable extracts",
   code_15892400:"Thickeners",
   code_15893000:"Dry goods",
   code_15893100:"Food mixes",
   code_15893200:"Dessert mixes",
   code_15893300:"Gravy mixes",
   code_15894000:"Processed food products",
   code_15894100:"Vegan packs",
   code_15894200:"Prepared meals",
   code_15894210:"School meals",
   code_15894220:"Hospital meals",
   code_15894300:"Prepared dishes",
   code_15894400:"Snacks",
   code_15894500:"Vending-machine ingredients",
   code_15894600:"Sandwich fillings",
   code_15894700:"Delicatessen",
   code_15895000:"Fast-food products",
   code_15895100:"Hamburgers",
   code_15896000:"Deep-frozen products",
   code_15897000:"Canned goods and field rations",
   code_15897100:"Field rations",
   code_15897200:"Canned goods",
   code_15897300:"Food parcels",
   code_15898000:"Yeast",
   code_15899000:"Baking powder",
   code_15900000:"Beverages, tobacco and related products",
   code_15910000:"Distilled alcoholic beverages",
   code_15911000:"Spirituous beverages",
   code_15911100:"Spirits",
   code_15911200:"Liqueurs",
   code_15930000:"Wines",
   code_15931000:"Unflavoured wines",
   code_15931100:"Sparkling wine",
   code_15931200:"Table wine",
   code_15931300:"Port",
   code_15931400:"Madeira",
   code_15931500:"Grape must",
   code_15931600:"Sherry",
   code_15932000:"Wine lees",
   code_15940000:"Cider and other fruit wines",
   code_15941000:"Cider",
   code_15942000:"Fruit wines",
   code_15950000:"Non-distilled fermented beverages",
   code_15951000:"Vermouth",
   code_15960000:"Malt beer",
   code_15961000:"Beer",
   code_15961100:"Lager",
   code_15962000:"Brewing or distilling dregs",
   code_15980000:"Non-alcoholic beverages",
   code_15981000:"Mineral water",
   code_15981100:"Still mineral water",
   code_15981200:"Sparkling mineral water",
   code_15981300:"Water in solid form",
   code_15981310:"Ice",
   code_15981320:"Snow",
   code_15981400:"Flavoured mineral waters",
   code_15982000:"Soft drinks",
   code_15982100:"Fruit squashes",
   code_15982200:"Chocolate milk",
   code_15990000:"Tobacco, tobacco goods and supplies",
   code_15991000:"Tobacco goods",
   code_15991100:"Cigars",
   code_15991200:"Cigarillos",
   code_15991300:"Cigarettes",
   code_15992000:"Tobacco",
   code_15992100:"Manufactured tobacco",
   code_15993000:"Tobacconist supplies",
   code_15994000:"Cigarette paper and filter paper",
   code_15994100:"Cigarette paper",
   code_15994200:"Filter paper",
   code_16000000:"Agricultural machinery",
   code_16100000:"Agricultural and forestry machinery for soil preparation or cultivation",
   code_16110000:"Ploughs or disc harrows",
   code_16120000:"Harrows, scarifiers, cultivators, weeders or hoes",
   code_16130000:"Seeders, planters or transplanters",
   code_16140000:"Manure spreaders",
   code_16141000:"Fertiliser distributors",
   code_16150000:"Lawn or sports-ground rollers",
   code_16160000:"Miscellaneous gardening equipment",
   code_16300000:"Harvesting machinery",
   code_16310000:"Mowers",
   code_16311000:"Lawnmowers",
   code_16311100:"Lawn, park or sports-ground mowers",
   code_16320000:"Haymaking machinery",
   code_16330000:"Straw or fodder balers",
   code_16331000:"Pick-up balers",
   code_16340000:"Harvesting and threshing machinery",
   code_16400000:"Spraying machinery for agriculture or horticulture",
   code_16500000:"Self-loading or unloading trailers and semi-trailers for agriculture",
   code_16510000:"Self-loading trailers for agriculture",
   code_16520000:"Unloading trailers for agriculture",
   code_16530000:"Self-loading semi-trailers for agriculture",
   code_16540000:"Unloading semi-trailers for agriculture",
   code_16600000:"Specialist agricultural or forestry machinery",
   code_16610000:"Machines for cleaning, sorting or grading eggs, fruit or other produce",
   code_16611000:"Machines for cleaning produce",
   code_16611100:"Machines for cleaning eggs",
   code_16611200:"Machines for cleaning fruit",
   code_16612000:"Machines for sorting or grading produce",
   code_16612100:"Machines for sorting or grading eggs",
   code_16612200:"Machines for sorting or grading fruit",
   code_16613000:"Machines for cleaning, sorting or grading seed, grain or dried vegetables",
   code_16620000:"Milking machines",
   code_16630000:"Machinery for preparing animal feeding stuffs",
   code_16640000:"Bee-keeping machinery",
   code_16650000:"Poultry-keeping machinery",
   code_16651000:"Poultry incubators and brooders",
   code_16700000:"Tractors",
   code_16710000:"Pedestrian-controlled agricultural tractors",
   code_16720000:"Used tractors",
   code_16730000:"Traction motors",
   code_16800000:"Parts of agricultural and forestry machinery",
   code_16810000:"Parts of agricultural machinery",
   code_16820000:"Parts of forestry machinery",
   code_18000000:"Clothing, footwear, luggage articles and accessories",
   code_18100000:"Occupational clothing, special workwear and accessories",
   code_18110000:"Occupational clothing",
   code_18113000:"Industrial clothing",
   code_18114000:"Coveralls",
   code_18130000:"Special workwear",
   code_18132000:"Flying clothing",
   code_18132100:"Flight jackets",
   code_18132200:"Flight suits",
   code_18140000:"Workwear accessories",
   code_18141000:"Work gloves",
   code_18142000:"Safety visors",
   code_18143000:"Protective gear",
   code_18200000:"Outerwear",
   code_18210000:"Coats",
   code_18211000:"Capes",
   code_18212000:"Cloaks",
   code_18213000:"Wind jackets",
   code_18220000:"Weatherproof clothing",
   code_18221000:"Waterproof clothing",
   code_18221100:"Waterproof capes",
   code_18221200:"Anoraks",
   code_18221300:"Raincoats",
   code_18222000:"Corporate clothing",
   code_18222100:"Suits",
   code_18222200:"Ensembles",
   code_18223000:"Jackets and blazers",
   code_18223100:"Blazers",
   code_18223200:"Jackets",
   code_18224000:"Clothing made of coated or impregnated textile fabrics",
   code_18230000:"Miscellaneous outerwear",
   code_18231000:"Dresses",
   code_18232000:"Skirts",
   code_18233000:"Shorts",
   code_18234000:"Trousers",
   code_18235000:"Pullovers, cardigans and similar articles",
   code_18235100:"Pullovers",
   code_18235200:"Cardigans",
   code_18235300:"Sweatshirts",
   code_18235400:"Waistcoats",
   code_18300000:"Garments",
   code_18310000:"Underwear",
   code_18311000:"Slips",
   code_18312000:"Underpants",
   code_18313000:"Panties",
   code_18314000:"Bathrobes",
   code_18315000:"Stockings",
   code_18316000:"Tights",
   code_18317000:"Socks",
   code_18318000:"Nightwear",
   code_18318100:"Nightshirts",
   code_18318200:"Dressing gowns",
   code_18318300:"Pyjamas",
   code_18318400:"Vests",
   code_18318500:"Nightdresses",
   code_18320000:"Brassieres, corsets, suspenders and similar articles",
   code_18321000:"Brassieres",
   code_18322000:"Corsets",
   code_18323000:"Suspenders",
   code_18330000:"T-shirts and shirts",
   code_18331000:"T-shirts",
   code_18332000:"Shirts",
   code_18333000:"Polo shirts",
   code_18400000:"Special clothing and accessories",
   code_18410000:"Special clothing",
   code_18411000:"Baby clothing",
   code_18412000:"Sportswear",
   code_18412100:"Tracksuits",
   code_18412200:"Sports shirts",
   code_18412300:"Ski suits",
   code_18412800:"Swimwear",
   code_18420000:"Clothing accessories",
   code_18421000:"Handkerchiefs",
   code_18422000:"Scarves",
   code_18423000:"Ties",
   code_18424000:"Gloves",
   code_18424300:"Disposable gloves",
   code_18424400:"Mittens",
   code_18424500:"Gauntlets",
   code_18425000:"Belts",
   code_18425100:"Bandoliers",
   code_18440000:"Hats and headgear",
   code_18441000:"Hats",
   code_18443000:"Headgear and headgear accessories",
   code_18443100:"Headbands",
   code_18443300:"Headgear",
   code_18443310:"Berets",
   code_18443320:"Field caps",
   code_18443330:"Hoods",
   code_18443340:"Caps",
   code_18443400:"Chin straps for headgear",
   code_18443500:"Visors",
   code_18444000:"Protective headgear",
   code_18444100:"Safety headgear",
   code_18444110:"Helmets",
   code_18444111:"Crash helmets",
   code_18444112:"Bicycle helmets",
   code_18444200:"Hard hats",
   code_18450000:"Fasteners (clothing)",
   code_18451000:"Buttons",
   code_18451100:"Parts of buttons",
   code_18452000:"Safety pins",
   code_18453000:"Zip fasteners",
   code_18500000:"Jewellery, watches and related articles",
   code_18510000:"Jewellery and related articles",
   code_18511000:"Precious stone for jewellery",
   code_18511100:"Diamonds",
   code_18511200:"Rubies",
   code_18511300:"Emeralds",
   code_18511400:"Opal stone",
   code_18511500:"Quartz stone",
   code_18511600:"Tourmaline stone",
   code_18512000:"Coins and medals",
   code_18512100:"Coins",
   code_18512200:"Medals",
   code_18513000:"Articles of jewellery",
   code_18513100:"Pearls",
   code_18513200:"Goldsmiths' wares",
   code_18513300:"Articles of precious metal",
   code_18513400:"Articles of precious or semi-precious stones",
   code_18513500:"Silversmiths' wares",
   code_18520000:"Personal horology",
   code_18521000:"Watches",
   code_18521100:"Glass for watches",
   code_18522000:"Wristwatches",
   code_18523000:"Stopwatches",
   code_18530000:"Presents and rewards",
   code_18600000:"Furs and articles of fur",
   code_18610000:"Fur articles",
   code_18611000:"Fur skins",
   code_18612000:"Fur clothing",
   code_18613000:"Artificial fur articles",
   code_18620000:"Furs",
   code_18800000:"Footwear",
   code_18810000:"Footwear other than sports and protective footwear",
   code_18811000:"Waterproof footwear",
   code_18812000:"Footwear with rubber or plastic parts",
   code_18812100:"Sandals with uppers of rubber or plastics",
   code_18812200:"Rubber boots",
   code_18812300:"Town footwear with rubber or plastic uppers",
   code_18812400:"Flip-flops",
   code_18813000:"Footwear with uppers of leather",
   code_18813100:"Sandals",
   code_18813200:"Slippers",
   code_18813300:"Town footwear",
   code_18814000:"Footwear with uppers of textile materials",
   code_18815000:"Boots",
   code_18815100:"Ankle boots",
   code_18815200:"Calf boots",
   code_18815300:"Knee boots",
   code_18815400:"Waders",
   code_18816000:"Galoshes",
   code_18820000:"Sports footwear",
   code_18821000:"Ski footwear",
   code_18821100:"Cross-country ski footwear",
   code_18822000:"Training shoes",
   code_18823000:"Climbing boots",
   code_18824000:"Football boots",
   code_18830000:"Protective footwear",
   code_18831000:"Footwear incorporating a protective metal toecap",
   code_18832000:"Special footwear",
   code_18832100:"Flying footwear",
   code_18840000:"Parts of footwear",
   code_18841000:"Footwear uppers",
   code_18842000:"Soles",
   code_18843000:"Heels",
   code_18900000:"Luggage, saddlery, sacks and bags",
   code_18910000:"Saddlery",
   code_18911000:"Saddles",
   code_18912000:"Riding crops",
   code_18913000:"Whips",
   code_18920000:"Luggage",
   code_18921000:"Suitcases",
   code_18923000:"Pouches and wallets",
   code_18923100:"Pouches",
   code_18923200:"Wallets",
   code_18924000:"Trunks",
   code_18925000:"Water-bottle holders and holsters",
   code_18925100:"Water-bottle holders",
   code_18925200:"Holsters",
   code_18929000:"Toilet cases",
   code_18930000:"Sacks and bags",
   code_18931000:"Travel bags",
   code_18931100:"Rucksacks",
   code_18932000:"Sports bags",
   code_18933000:"Mail or parcel bags",
   code_18933100:"Post pouches",
   code_18934000:"Kitbags",
   code_18935000:"Laundry bags",
   code_18936000:"Textile bags",
   code_18937000:"Goods-packing sacks",
   code_18937100:"Goods-packing bags",
   code_18938000:"Padded bags",
   code_18939000:"Handbags",
   code_19000000:"Leather and textile fabrics, plastic and rubber materials",
   code_19100000:"Leather",
   code_19110000:"Chamois leather",
   code_19120000:"Leather of bovine or equine animals",
   code_19130000:"Leather of sheep, goats or pigs",
   code_19131000:"Sheep- or lamb-skin leather",
   code_19132000:"Goat- or kid-skin leather",
   code_19133000:"Pig leather",
   code_19140000:"Leather of other animals, composite leather and other leather",
   code_19141000:"Leather of other animals",
   code_19142000:"Composite leather",
   code_19143000:"Imitation leather",
   code_19144000:"Patent leather",
   code_19160000:"Watch straps",
   code_19170000:"Leather articles used in machinery or mechanical appliances",
   code_19200000:"Textile fabrics and related items",
   code_19210000:"Woven fabrics",
   code_19211000:"Synthetic woven fabrics",
   code_19211100:"Mixed woven fabrics",
   code_19212000:"Woven fabrics of cotton",
   code_19212100:"Ticking",
   code_19212200:"Denim",
   code_19212300:"Canvas",
   code_19212310:"Canvas items",
   code_19212400:"Poplin",
   code_19212500:"Webbing",
   code_19212510:"Webbing straps",
   code_19220000:"Woollen fabrics",
   code_19230000:"Linen fabrics",
   code_19231000:"Linen",
   code_19240000:"Special fabrics",
   code_19241000:"Pile",
   code_19242000:"Terry towelling",
   code_19243000:"Upholstery fabrics",
   code_19244000:"Curtain fabrics",
   code_19245000:"Lining fabrics",
   code_19250000:"Knitted or crocheted fabrics",
   code_19251000:"Knitted fabrics",
   code_19251100:"Pile fabrics",
   code_19252000:"Crocheted fabrics",
   code_19260000:"Cloth",
   code_19270000:"Non-woven fabrics",
   code_19280000:"Animal wool, hides and skins",
   code_19281000:"Wool",
   code_19282000:"Animal skins",
   code_19283000:"Bird skins and feathers",
   code_19400000:"Textile yarn and thread",
   code_19410000:"Natural textile fibres",
   code_19420000:"Artificial textile fibres",
   code_19430000:"Textile yarn and thread of natural fibres",
   code_19431000:"Silk yarn",
   code_19432000:"Wool yarn",
   code_19433000:"Cotton yarn",
   code_19434000:"Flax yarn",
   code_19435000:"Sewing thread and yarn of natural fibres",
   code_19435100:"Sewing thread",
   code_19435200:"Knitting yarn",
   code_19436000:"Vegetable textile-fibre yarn",
   code_19440000:"Synthetic yarn or thread",
   code_19441000:"Synthetic yarn",
   code_19442000:"Synthetic thread",
   code_19442100:"Synthetic sewing thread",
   code_19442200:"Synthetic knitting yarn",
   code_19500000:"Rubber and plastic materials",
   code_19510000:"Rubber products",
   code_19511000:"Rubber inner tubes, treads and flaps",
   code_19511100:"Tyre flaps",
   code_19511200:"Inner tubes",
   code_19511300:"Tyre treads",
   code_19512000:"Unvulcanised rubber articles",
   code_19513000:"Rubberised textile fabrics",
   code_19513100:"Tyre-cord fabric",
   code_19513200:"Adhesive tape of rubberised textiles",
   code_19514000:"Reclaimed rubber",
   code_19520000:"Plastic products",
   code_19521000:"Polystyrene products",
   code_19521100:"Polystyrene sheeting",
   code_19521200:"Polystyrene slabs",
   code_19522000:"Resins",
   code_19522100:"Epoxy resin",
   code_19522110:"Epoxy-resin tubing",
   code_19600000:"Leather, textile, rubber and plastic waste",
   code_19610000:"Leather waste",
   code_19620000:"Textile waste",
   code_19630000:"Rubber waste",
   code_19640000:"Polythene waste and refuse sacks and bags",
   code_19700000:"Synthetic rubber and fibres",
   code_19710000:"Synthetic rubber",
   code_19720000:"Synthetic fibres",
   code_19721000:"Synthetic filament tow",
   code_19722000:"High-tenacity yarn",
   code_19723000:"Single textured yarn",
   code_19724000:"Synthetic monofilament",
   code_19730000:"Artificial fibres",
   code_19731000:"Artificial staple fibres",
   code_19732000:"Polypropylene",
   code_19733000:"Artificial textured yarn",
   code_22000000:"Printed matter and related products",
   code_22100000:"Printed books, brochures and leaflets",
   code_22110000:"Printed books",
   code_22111000:"School books",
   code_22112000:"Textbooks",
   code_22113000:"Library books",
   code_22114000:"Dictionaries, maps, music books and other books",
   code_22114100:"Dictionaries",
   code_22114200:"Atlases",
   code_22114300:"Maps",
   code_22114310:"Cadastral maps",
   code_22114311:"Blueprints",
   code_22114400:"Printed music",
   code_22114500:"Encyclopaedias",
   code_22120000:"Publications",
   code_22121000:"Technical publications",
   code_22130000:"Directories",
   code_22140000:"Leaflets",
   code_22150000:"Brochures",
   code_22160000:"Booklets",
   code_22200000:"Newspapers, journals, periodicals and magazines",
   code_22210000:"Newspapers",
   code_22211000:"Journals",
   code_22211100:"Official journals",
   code_22212000:"Periodicals",
   code_22212100:"Serials",
   code_22213000:"Magazines",
   code_22300000:"Postcards, greeting cards and other printed matter",
   code_22310000:"Postcards",
   code_22312000:"Pictures",
   code_22313000:"Transfers",
   code_22314000:"Designs",
   code_22315000:"Photographs",
   code_22320000:"Greeting cards",
   code_22321000:"Christmas cards",
   code_22400000:"Stamps, cheque forms, banknotes, stock certificates, trade advertising material, catalogues and manuals",
   code_22410000:"Stamps",
   code_22411000:"Christmas stamps",
   code_22412000:"New stamps",
   code_22413000:"Savings stamps",
   code_22414000:"Mail stamp holders",
   code_22420000:"Stamp-impressed paper",
   code_22430000:"Banknotes",
   code_22440000:"Cheque forms",
   code_22450000:"Security-type printed matter",
   code_22451000:"Passports",
   code_22452000:"Postal orders",
   code_22453000:"Car-tax discs",
   code_22454000:"Driving licences",
   code_22455000:"ID cards",
   code_22455100:"Identity bracelet",
   code_22456000:"Permits",
   code_22457000:"Entrance cards",
   code_22458000:"Bespoke printed matter",
   code_22459000:"Tickets",
   code_22459100:"Advertising stickers and strips",
   code_22460000:"Trade-advertising material, commercial catalogues and manuals",
   code_22461000:"Catalogues",
   code_22461100:"List holders",
   code_22462000:"Advertising material",
   code_22470000:"Manuals",
   code_22471000:"Computer manuals",
   code_22472000:"Instruction manuals",
   code_22473000:"Technical manuals",
   code_22500000:"Printing plates or cylinders or other media for use in printing",
   code_22510000:"Offset plates",
   code_22520000:"Dry-etching equipment",
   code_22521000:"Embossing equipment",
   code_22600000:"Ink",
   code_22610000:"Printing ink",
   code_22611000:"Intaglio ink",
   code_22612000:"India ink",
   code_22800000:"Paper or paperboard registers, account books, binders, forms and other articles of printed stationery",
   code_22810000:"Paper or paperboard registers",
   code_22813000:"Account books",
   code_22814000:"Receipt books",
   code_22815000:"Notebooks",
   code_22816000:"Pads",
   code_22816100:"Note pads",
   code_22816200:"Shorthand notebook",
   code_22816300:"Sticky-note pads",
   code_22817000:"Diaries or personal organisers",
   code_22819000:"Address books",
   code_22820000:"Forms",
   code_22821000:"Electoral forms",
   code_22822000:"Business forms",
   code_22822100:"Continuous business forms",
   code_22822200:"Non-continuous business forms",
   code_22830000:"Exercise books",
   code_22831000:"Refills for school notebooks",
   code_22832000:"Exercise papers",
   code_22840000:"Albums for samples",
   code_22841000:"Collection albums",
   code_22841100:"Stamp books",
   code_22841200:"Philatelic binders",
   code_22850000:"Binders and related accessories",
   code_22851000:"Binders",
   code_22852000:"Folders",
   code_22852100:"File covers",
   code_22853000:"File holders",
   code_22900000:"Miscellaneous printed matter",
   code_22990000:"Newsprint, handmade paper and other uncoated paper or paperboard for graphic purposes",
   code_22991000:"Paper for newsprint",
   code_22992000:"Hand-made paper or paperboard",
   code_22993000:"Photosensitive, heat-sensitive or thermographic paper and paperboard",
   code_22993100:"Photosensitive paper or paperboard",
   code_22993200:"Heat-sensitive paper or paperboard",
   code_22993300:"Thermographic paper or paperboard",
   code_22993400:"Corrugated paper or paperboard",
   code_24000000:"Chemical products",
   code_24100000:"Gases",
   code_24110000:"Industrial gases",
   code_24111000:"Hydrogen, argon, rare gases, nitrogen and oxygen",
   code_24111100:"Argon",
   code_24111200:"Rare gases",
   code_24111300:"Helium",
   code_24111400:"Neon",
   code_24111500:"Medical gases",
   code_24111600:"Hydrogen",
   code_24111700:"Nitrogen",
   code_24111800:"Liquid nitrogen",
   code_24111900:"Oxygen",
   code_24112000:"Inorganic oxygen compounds",
   code_24112100:"Carbon dioxide",
   code_24112200:"Nitrogen oxides",
   code_24112300:"Gaseous inorganic oxygen compounds",
   code_24113000:"Liquid and compressed air",
   code_24113100:"Liquid air",
   code_24113200:"Compressed air",
   code_24200000:"Dyes and pigments",
   code_24210000:"Oxides, peroxides and hydroxides",
   code_24211000:"Zinc oxide and peroxide, titanium oxide, dyes and pigments",
   code_24211100:"Zinc oxide",
   code_24211200:"Zinc peroxide",
   code_24211300:"Titanium oxide",
   code_24212000:"Chromium, manganese, magnesium, lead and copper oxides and hydroxides",
   code_24212100:"Chromium oxide",
   code_24212200:"Manganese oxide",
   code_24212300:"Lead oxide",
   code_24212400:"Copper oxide",
   code_24212500:"Magnesium oxide",
   code_24212600:"Hydroxides for dyes and pigments",
   code_24212610:"Chromium hydroxide",
   code_24212620:"Manganese hydroxide",
   code_24212630:"Lead hydroxide",
   code_24212640:"Copper hydroxide",
   code_24212650:"Magnesium hydroxide",
   code_24213000:"Hydrated lime",
   code_24220000:"Tanning extracts, dyeing extracts, tannins and colouring matter",
   code_24221000:"Dyeing extracts",
   code_24222000:"Tanning extracts",
   code_24223000:"Tannins",
   code_24224000:"Colouring matter",
   code_24225000:"Tanning preparations",
   code_24300000:"Basic inorganic and organic chemicals",
   code_24310000:"Basic inorganic chemicals",
   code_24311000:"Chemical elements, inorganic acids and compounds",
   code_24311100:"Metalloids",
   code_24311110:"Phosphides",
   code_24311120:"Carbides",
   code_24311130:"Hydrides",
   code_24311140:"Nitrides",
   code_24311150:"Azides",
   code_24311160:"Silicides",
   code_24311170:"Borides",
   code_24311180:"Refined sulphur",
   code_24311200:"Halogen",
   code_24311300:"Alkali metals",
   code_24311310:"Mercury",
   code_24311400:"Hydrogen chloride, inorganic acids, silicon dioxide and sulphur dioxide",
   code_24311410:"Inorganic acids",
   code_24311411:"Sulphuric acid",
   code_24311420:"Phosphoric acid",
   code_24311430:"Polyphosphoric acids",
   code_24311440:"Hexafluorosilicic acid",
   code_24311450:"Sulphur dioxide",
   code_24311460:"Silicon dioxide",
   code_24311470:"Hydrogen chloride",
   code_24311500:"Hydroxides as basic inorganic chemicals",
   code_24311510:"Metal oxides",
   code_24311511:"Iron pyrites and iron oxides",
   code_24311520:"Sodium hydroxide",
   code_24311521:"Caustic soda",
   code_24311522:"Liquid soda",
   code_24311600:"Sulphur compounds",
   code_24311700:"Sulphur",
   code_24311800:"Carbon",
   code_24311900:"Chlorine",
   code_24312000:"Metallic halogenates; hypochlorites, chlorates and perchlorates",
   code_24312100:"Metallic halogenates",
   code_24312110:"Sodium hexafluorosilicate",
   code_24312120:"Chlorides",
   code_24312121:"Aluminium chloride",
   code_24312122:"Ferric chloride",
   code_24312123:"Poly aluminium chloride",
   code_24312130:"Aluminium chlorohydrate",
   code_24312200:"Hypochlorites and chlorates",
   code_24312210:"Sodium chlorite",
   code_24312220:"Sodium hypochlorite",
   code_24313000:"Sulphides, sulphates; nitrates, phosphates and carbonates",
   code_24313100:"Sulphides, sulphites and sulphates",
   code_24313110:"Miscellaneous sulphides",
   code_24313111:"Hydrogen sulphide",
   code_24313112:"Polysulphides",
   code_24313120:"Sulphates",
   code_24313121:"Sodium thiosulphate",
   code_24313122:"Ferric sulphate",
   code_24313123:"Sulphate of aluminium",
   code_24313124:"Sodium sulphate",
   code_24313125:"Iron sulphate",
   code_24313126:"Copper sulphate",
   code_24313200:"Phosphinates, phosphonates, phosphates and polyphosphates",
   code_24313210:"Sodium hexametaphosphate",
   code_24313220:"Phosphates",
   code_24313300:"Carbonates",
   code_24313310:"Sodium carbonate",
   code_24313320:"Sodium bicarbonate",
   code_24313400:"Nitrates",
   code_24314000:"Miscellaneous metal acid salts",
   code_24314100:"Potassium permanganate",
   code_24314200:"Oxometallic acid salts",
   code_24315000:"Miscellaneous inorganic chemicals",
   code_24315100:"Heavy water, other isotopes and their compounds",
   code_24315200:"Cyanide, cyanide oxide, fulminates, cyanates, silicates, borates, perborates, salts of inorganic acids",
   code_24315210:"Cyanides",
   code_24315220:"Cyanide oxide",
   code_24315230:"Fulminates",
   code_24315240:"Cyanates",
   code_24315300:"Hydrogen peroxide",
   code_24315400:"Piezo-electric quartz",
   code_24315500:"Compounds of rare earth metals",
   code_24315600:"Silicates",
   code_24315610:"Sodium silicate",
   code_24315700:"Borates and perborates",
   code_24316000:"Distilled water",
   code_24317000:"Synthetic stones",
   code_24317100:"Synthetic precious stones",
   code_24317200:"Synthetic semi-precious stones",
   code_24320000:"Basic organic chemicals",
   code_24321000:"Hydrocarbons",
   code_24321100:"Saturated hydrocarbons",
   code_24321110:"Saturated acyclic hydrocarbons",
   code_24321111:"Methane",
   code_24321112:"Ethylene",
   code_24321113:"Propene",
   code_24321114:"Butene",
   code_24321115:"Acetylene",
   code_24321120:"Saturated cyclic hydrocarbons",
   code_24321200:"Unsaturated hydrocarbons",
   code_24321210:"Unsaturated acyclic hydrocarbons",
   code_24321220:"Unsaturated cyclic hydrocarbons",
   code_24321221:"Benzene",
   code_24321222:"Toluene",
   code_24321223:"O-xylenes",
   code_24321224:"M-xylenes",
   code_24321225:"Styrene",
   code_24321226:"Ethylbenzene",
   code_24321300:"Other halogenated derivatives of hydrocarbons",
   code_24321310:"Tetrachloroethylene",
   code_24321320:"Carbon tetrachloride",
   code_24322000:"Alcohols, phenols, phenol-alcohols and their halogenated, sulphonated, nitrated or nitrosated derivatives; industrial fatty alcohols",
   code_24322100:"Industrial fatty alcohols",
   code_24322200:"Monohydric alcohols",
   code_24322210:"Methanol",
   code_24322220:"Ethanol",
   code_24322300:"Diols, polyalcohols and derivatives",
   code_24322310:"Ethylene glycol",
   code_24322320:"Alcohol derivatives",
   code_24322400:"Phenols and derivatives",
   code_24322500:"Alcohol",
   code_24322510:"Ethyl alcohol",
   code_24323000:"Industrial monocarboxylic fatty acids",
   code_24323100:"Acid oils from refining",
   code_24323200:"Carboxylic acids",
   code_24323210:"Acetic acid",
   code_24323220:"Peracetic acid",
   code_24323300:"Unsaturated monocarboxylic acids and compounds",
   code_24323310:"Esters of methacrylic acid",
   code_24323320:"Esters of acrylic acid",
   code_24323400:"Aromatic polycarboxylic and carboxylic acids",
   code_24324000:"Organic compounds with nitrogen functions",
   code_24324100:"Amine function compounds",
   code_24324200:"Oxygen-function amino-compounds",
   code_24324300:"Ureines",
   code_24324400:"Compounds with nitrogen functions",
   code_24325000:"Organo-sulphur compounds",
   code_24326000:"Aldehyde, ketone, organic peroxides and ethers",
   code_24326100:"Aldehyde function compounds",
   code_24326200:"Ketone and quinone function compounds",
   code_24326300:"Organic peroxides",
   code_24326310:"Ethylene oxide",
   code_24326320:"Ethers",
   code_24327000:"Miscellaneous organic chemicals",
   code_24327100:"Vegetable derivatives for dyeing",
   code_24327200:"Wood charcoal",
   code_24327300:"Oils and products of the distillation of high-temperature coal tar, pitch and pitch tar",
   code_24327310:"Coal tar",
   code_24327311:"Creosote",
   code_24327320:"Pitch",
   code_24327330:"Pitch tar",
   code_24327400:"Resin products",
   code_24327500:"Residual lyes from the manufacture of wood pulp",
   code_24400000:"Fertilisers and nitrogen compounds",
   code_24410000:"Nitrogenous fertilisers",
   code_24411000:"Nitric acid and salts",
   code_24411100:"Sodium nitrate",
   code_24412000:"Sulphonitric acids",
   code_24413000:"Ammonia",
   code_24413100:"Liquid ammonia",
   code_24413200:"Ammonium chloride",
   code_24413300:"Ammonium sulphate",
   code_24420000:"Phosphatic fertilisers",
   code_24421000:"Mineral phosphatic fertilisers",
   code_24422000:"Chemical phosphatic fertilisers",
   code_24430000:"Animal or vegetal fertilisers",
   code_24440000:"Miscellaneous fertilisers",
   code_24450000:"Agro-chemical products",
   code_24451000:"Pesticides",
   code_24452000:"Insecticides",
   code_24453000:"Herbicides",
   code_24454000:"Plant-growth regulators",
   code_24455000:"Disinfectants",
   code_24456000:"Rodenticides",
   code_24457000:"Fungicides",
   code_24500000:"Plastics in primary forms",
   code_24510000:"Primary-form polymers of ethylene",
   code_24520000:"Primary-form polymers of propylene",
   code_24530000:"Primary-form polymers of styrene",
   code_24540000:"Primary-form of vinyl polymers",
   code_24541000:"Primary-form polymers of vinyl acetate",
   code_24542000:"Primary-form acrylic polymers",
   code_24550000:"Primary-form of polyesters",
   code_24560000:"Primary-form polyamides",
   code_24570000:"Primary-form urea resins",
   code_24580000:"Primary-form amino-resins",
   code_24590000:"Primary-form silicones",
   code_24600000:"Explosives",
   code_24610000:"Prepared explosives",
   code_24611000:"Propellant powders",
   code_24611100:"Propergol fuels",
   code_24612000:"Miscellaneous explosives",
   code_24612100:"Dynamite",
   code_24612200:"TNT",
   code_24612300:"Nitro-glycerine",
   code_24613000:"Signalling flares, rain rockets, fog signals and pyrotechnic articles",
   code_24613100:"Bird-scaring cartridges",
   code_24613200:"Fireworks",
   code_24615000:"Fuses, caps, igniters and electric detonators",
   code_24900000:"Fine and various chemical products",
   code_24910000:"Glues",
   code_24911000:"Gelatines",
   code_24911200:"Adhesives",
   code_24920000:"Essential oils",
   code_24930000:"Photographic chemicals",
   code_24931000:"Photographic plates and films",
   code_24931200:"Emulsions for photographic use",
   code_24931210:"Photographic developers",
   code_24931220:"Photographic fixers",
   code_24931230:"X-ray developers",
   code_24931240:"X-ray fixers",
   code_24931250:"Culture medium",
   code_24931260:"Image intensifiers",
   code_24950000:"Specialised chemical products",
   code_24951000:"Greases and lubricants",
   code_24951100:"Lubricants",
   code_24951110:"Drilling mud",
   code_24951120:"Silicon grease",
   code_24951130:"Drilling fluids",
   code_24951200:"Additives for oils",
   code_24951210:"Fire-extinguisher powder",
   code_24951220:"Fire-extinguisher agents",
   code_24951230:"Fire-extinguisher charges",
   code_24951300:"Hydraulic fluids",
   code_24951310:"De-icing agents",
   code_24951311:"Anti-freezing preparations",
   code_24951400:"Chemically-modified fats and oils",
   code_24952000:"Modelling pastes",
   code_24952100:"Dental wax",
   code_24953000:"Finishing agents",
   code_24954000:"Activated carbon",
   code_24954100:"New activated carbon",
   code_24954200:"Regenerated activated carbon",
   code_24955000:"Chemical toilets",
   code_24956000:"Peptones and protein substances",
   code_24957000:"Chemical additives",
   code_24957100:"Prepared binders for foundry moulds or cores",
   code_24957200:"Additives for cements, mortars or concretes",
   code_24958000:"Chemical products for the oil and gas industry",
   code_24958100:"Downhole chemicals",
   code_24958200:"Flocculating agents",
   code_24958300:"Mud chemicals",
   code_24958400:"Gel ampoules for stemming explosives",
   code_24959000:"Aerosols and chemicals in disc form",
   code_24959100:"Aerosols",
   code_24959200:"Chemical elements in disc form",
   code_24960000:"Various chemical products",
   code_24961000:"Radiator fluids",
   code_24962000:"Water-treatment chemicals",
   code_24963000:"Anti-corrosion products",
   code_24964000:"Glycerol",
   code_24965000:"Enzymes",
   code_30000000:"Office and computing machinery, equipment and supplies except furniture and software packages",
   code_30100000:"Office machinery, equipment and supplies except computers, printers and furniture",
   code_30110000:"Word-processing machines",
   code_30111000:"Word processors",
   code_30120000:"Photocopying and offset printing equipment",
   code_30121000:"Photocopying and thermocopying equipment",
   code_30121100:"Photocopiers",
   code_30121200:"Photocopying equipment",
   code_30121300:"Reproduction equipment",
   code_30121400:"Duplicating machines",
   code_30121410:"Faxswitch machines",
   code_30121420:"Digital senders",
   code_30121430:"Digital duplicators",
   code_30122000:"Office-type offset printing machinery",
   code_30122100:"Digital offset systems",
   code_30122200:"Digital offset equipments",
   code_30123000:"Office and business machines",
   code_30123100:"Ticket-validation machines",
   code_30123200:"Automatic cash dispensers",
   code_30123300:"Stencil duplicating machines",
   code_30123400:"Folding machines",
   code_30123500:"Perforation machines",
   code_30123600:"Coin-handling machines",
   code_30123610:"Coin-sorting machines",
   code_30123620:"Coin-counting machines",
   code_30123630:"Coin-wrapping machines",
   code_30124000:"Parts and accessories of office machines",
   code_30124100:"Fusers",
   code_30124110:"Fuser oil",
   code_30124120:"Fuser wiper",
   code_30124130:"Fuser lamps",
   code_30124140:"Fuser cleaning pad",
   code_30124150:"Fuser filters",
   code_30124200:"Fuser kits",
   code_30124300:"Drums for office machine",
   code_30124400:"Staple cartridges",
   code_30124500:"Scanner accessories",
   code_30124510:"Endorsers",
   code_30124520:"Scanner document feeders",
   code_30124530:"Scanner transparency adapters",
   code_30125000:"Parts and accessories of photocopying apparatus",
   code_30125100:"Toner cartridges",
   code_30125110:"Toner for laser printers/fax machines",
   code_30125120:"Toner for photocopiers",
   code_30125130:"Toner for data-processing and research and documentation centres",
   code_30130000:"Post-office equipment",
   code_30131000:"Mailroom equipment",
   code_30131100:"Paper or envelope folding machines",
   code_30131200:"Envelope-stuffing machines",
   code_30131300:"Addressing machines",
   code_30131400:"Postage machines",
   code_30131500:"Mail opening machines",
   code_30131600:"Mail sealing machines",
   code_30131700:"Stamp canceling machines",
   code_30131800:"Stamp affixers",
   code_30132000:"Sorting equipment",
   code_30132100:"Mail-sorting equipment",
   code_30132200:"Banknote counting machines",
   code_30132300:"Sorters",
   code_30133000:"Mailing equipment",
   code_30133100:"Bulk-mailing equipment",
   code_30140000:"Calculating and accounting machines",
   code_30141000:"Calculating machines",
   code_30141100:"Pocket calculators",
   code_30141200:"Desktop calculators",
   code_30141300:"Printing calculators",
   code_30141400:"Adding machines",
   code_30142000:"Accounting machines and cash registers",
   code_30142100:"Accounting machines",
   code_30142200:"Cash registers",
   code_30144000:"Calculation-type machines",
   code_30144100:"Postage-franking machines",
   code_30144200:"Ticket-issuing machines",
   code_30144300:"Vehicle-counting machines",
   code_30144400:"Automatic fare collection",
   code_30145000:"Parts and accessories calculating machines",
   code_30145100:"Calculator rolls",
   code_30150000:"Typewriters",
   code_30151000:"Electronic typewriters",
   code_30152000:"Parts and accessories of typewriters",
   code_30160000:"Magnetic cards",
   code_30161000:"Credit cards",
   code_30162000:"Smart cards",
   code_30163000:"Charge cards",
   code_30163100:"Agency fuel cards",
   code_30170000:"Labelling machines",
   code_30171000:"Dating or numbering machines",
   code_30172000:"Identification ID press machines",
   code_30173000:"Label applying machines",
   code_30174000:"Label making machines",
   code_30175000:"Lettering equipment",
   code_30176000:"Tape embosser",
   code_30177000:"Automatic labelling systems",
   code_30178000:"Semi automatic labelling systems",
   code_30179000:"Label dispensers",
   code_30180000:"Check endorsing and writing machines",
   code_30181000:"Check endorsing machines",
   code_30182000:"Check writing machines",
   code_30190000:"Various office equipment and supplies",
   code_30191000:"Office equipment except furniture",
   code_30191100:"Filing equipment",
   code_30191110:"Card carousel systems",
   code_30191120:"Magazine racks",
   code_30191130:"Clipboards",
   code_30191140:"Personal identification accessories",
   code_30191200:"Overhead projectors",
   code_30191400:"Shredders",
   code_30192000:"Office supplies",
   code_30192100:"Erasers",
   code_30192110:"Ink products",
   code_30192111:"Ink pads",
   code_30192112:"Ink sources for printing machinery",
   code_30192113:"Ink cartridges",
   code_30192121:"Ballpoint pens",
   code_30192122:"Fountain pens",
   code_30192123:"Fibre pens",
   code_30192124:"Felt-tipped pens",
   code_30192125:"Markers",
   code_30192126:"Technical pens",
   code_30192127:"Pen holders",
   code_30192130:"Pencils",
   code_30192131:"Propelling or sliding pencils",
   code_30192132:"Pencil lead refills",
   code_30192133:"Pencil sharpeners",
   code_30192134:"Pencil holders",
   code_30192150:"Date stamps",
   code_30192151:"Sealing stamps",
   code_30192152:"Numbering stamps",
   code_30192153:"Phrase stamps",
   code_30192154:"Replacement stamp pads",
   code_30192155:"Office stamp holders",
   code_30192160:"Correctors",
   code_30192170:"Notice boards",
   code_30192200:"Measuring tapes",
   code_30192300:"Ink ribbons",
   code_30192310:"Typewriter ribbons",
   code_30192320:"Printer ribbons",
   code_30192330:"Calculator ribbons and drums",
   code_30192340:"Facsimile ribbons",
   code_30192350:"Cash register ribbons",
   code_30192400:"Reprographic supplies",
   code_30192500:"Overhead transparencies",
   code_30192600:"Drawing boards",
   code_30192700:"Stationery",
   code_30192800:"Self-adhesive labels",
   code_30192900:"Correction media",
   code_30192910:"Correction film or tape",
   code_30192920:"Correction fluid",
   code_30192930:"Correction pens",
   code_30192940:"Correction pen refills",
   code_30192950:"Electrical erasers",
   code_30193000:"Organisers and accessories",
   code_30193100:"Desk drawer organisers",
   code_30193200:"Desktop trays or organisers",
   code_30193300:"Hanging organisers",
   code_30193400:"Book ends",
   code_30193500:"Literature rack",
   code_30193600:"Support for diaries or calendars",
   code_30193700:"File storage box",
   code_30193800:"Message holders",
   code_30193900:"Copy holders",
   code_30194000:"Drafting supplies",
   code_30194100:"Curves",
   code_30194200:"Drafting dots, tapes and films",
   code_30194210:"Drafting dots or tapes",
   code_30194220:"Drafting films",
   code_30194300:"Drafting kits, sets and papers",
   code_30194310:"Drafting kits or sets",
   code_30194320:"Drafting papers",
   code_30194400:"Drafting table covers",
   code_30194500:"Lettering aids",
   code_30194600:"Protractors",
   code_30194700:"Templates",
   code_30194800:"T-squares and triangles",
   code_30194810:"T-squares",
   code_30194820:"Triangles",
   code_30194900:"Work surface protection covers",
   code_30195000:"Boards",
   code_30195100:"Planning boards or accessories",
   code_30195200:"Electronic copyboards or accessories",
   code_30195300:"Letter boards or accessories",
   code_30195400:"Dry erase boards or accessories",
   code_30195500:"Chalk boards or accessories",
   code_30195600:"Bulletin boards or accessories",
   code_30195700:"Board cleaning kits or accessories",
   code_30195800:"Hanging rails or holders",
   code_30195900:"Whiteboards and magnetic boards",
   code_30195910:"Whiteboards",
   code_30195911:"Whiteboard accessories",
   code_30195912:"Whiteboard easels",
   code_30195913:"Flipchart easels",
   code_30195920:"Magnetic boards",
   code_30195921:"Erasers for magnetic boards",
   code_30196000:"Planning systems",
   code_30196100:"Meeting planners",
   code_30196200:"Appointment books or refills",
   code_30196300:"Suggestion box",
   code_30197000:"Small office equipment",
   code_30197100:"Staples, tacks, drawing pins",
   code_30197110:"Staples",
   code_30197120:"Tacks",
   code_30197130:"Drawing pins",
   code_30197200:"Ring binders and paper clips",
   code_30197210:"Ring binders",
   code_30197220:"Paper clips",
   code_30197221:"Paperclip holder",
   code_30197300:"Letter openers, staplers and hole punches",
   code_30197310:"Letter openers",
   code_30197320:"Staplers",
   code_30197321:"Staple removers",
   code_30197330:"Hole punches",
   code_30197400:"Stamp sponge",
   code_30197500:"Sealing wax",
   code_30197510:"Sealing wax accessories",
   code_30197600:"Processed paper and paperboard",
   code_30197610:"Composite paper and paperboard",
   code_30197620:"Writing paper",
   code_30197621:"Flipchart pad",
   code_30197630:"Printing paper",
   code_30197640:"Self-copy or other copy paper",
   code_30197641:"Thermographic paper",
   code_30197642:"Photocopier paper and xerographic paper",
   code_30197643:"Photocopier paper",
   code_30197644:"Xerographic paper",
   code_30197645:"Card for printing",
   code_30198000:"Lottery machines",
   code_30198100:"Pulling machines",
   code_30199000:"Paper stationery and other items",
   code_30199100:"Carbon paper, self-copy paper, paper duplicator stencils and carbonless paper",
   code_30199110:"Carbon paper",
   code_30199120:"Self-copy paper",
   code_30199130:"Carbonless paper",
   code_30199140:"Paper duplicator stencils",
   code_30199200:"Envelopes, letter cards and plain postcards",
   code_30199210:"Letter cards",
   code_30199220:"Plain postcards",
   code_30199230:"Envelopes",
   code_30199240:"Mailing kit",
   code_30199300:"Embossed or perforated paper",
   code_30199310:"Embossed or perforated printing paper",
   code_30199320:"Embossed or perforated writing paper",
   code_30199330:"Continuous paper for computer printers",
   code_30199340:"Continuous forms",
   code_30199400:"Gummed or adhesive paper",
   code_30199410:"Self-adhesive paper",
   code_30199500:"Box files, letter trays, storage boxes and similar articles",
   code_30199600:"Dividers for stationery",
   code_30199700:"Printed stationery except forms",
   code_30199710:"Printed envelopes",
   code_30199711:"Printed window envelopes",
   code_30199712:"Printed non-window envelopes",
   code_30199713:"Printed X-ray envelopes",
   code_30199720:"Notepaper",
   code_30199730:"Business cards",
   code_30199731:"Business card holders",
   code_30199740:"Compliment slips",
   code_30199750:"Coupons",
   code_30199760:"Labels",
   code_30199761:"Bar-coded labels",
   code_30199762:"Luggage tags",
   code_30199763:"Theft protection labels",
   code_30199770:"Luncheon vouchers",
   code_30199780:"Blotting pads",
   code_30199790:"Timetables",
   code_30199791:"Wall planners",
   code_30199792:"Calendars",
   code_30199793:"Diary stands",
   code_30200000:"Computer equipment and supplies",
   code_30210000:"Data-processing machines (hardware)",
   code_30211000:"Mainframe computer",
   code_30211100:"Super computer",
   code_30211200:"Mainframe hardware",
   code_30211300:"Computer platforms",
   code_30211400:"Computer configurations",
   code_30211500:"Central processing unit (CPU) or processors",
   code_30212000:"Minicomputer hardware",
   code_30212100:"Central processing units for minicomputers",
   code_30213000:"Personal computers",
   code_30213100:"Portable computers",
   code_30213200:"Tablet computer",
   code_30213300:"Desktop computer",
   code_30213400:"Central processing units for personal computers",
   code_30213500:"Pocket computers",
   code_30214000:"Workstations",
   code_30215000:"Microcomputer hardware",
   code_30215100:"Central processing units for microcomputers",
   code_30216000:"Magnetic or optical readers",
   code_30216100:"Optical readers",
   code_30216110:"Scanners for computer use",
   code_30216120:"Optical-character-recognition equipment",
   code_30216130:"Barcode readers",
   code_30216200:"Magnetic card readers",
   code_30216300:"Punchcard readers",
   code_30220000:"Digital cartography equipment",
   code_30221000:"Digital cadastral maps",
   code_30230000:"Computer-related equipment",
   code_30231000:"Computer screens and consoles",
   code_30231100:"Computer terminals",
   code_30231200:"Consoles",
   code_30231300:"Display screens",
   code_30231310:"Flat panel displays",
   code_30231320:"Touch screen monitors",
   code_30232000:"Peripheral equipment",
   code_30232100:"Printers and plotters",
   code_30232110:"Laser printers",
   code_30232120:"Dot-matrix printers",
   code_30232130:"Colour graphics printers",
   code_30232140:"Plotters",
   code_30232150:"Inkjet printers",
   code_30232600:"Encoders",
   code_30232700:"Central controlling unit",
   code_30233000:"Media storage and reader devices",
   code_30233100:"Computer storage units",
   code_30233110:"Magnetic card storage units",
   code_30233120:"Magnetic tape storage units",
   code_30233130:"Magnetic disk storage units",
   code_30233131:"Floppy-disk drives",
   code_30233132:"Hard-disk drives",
   code_30233140:"Direct-access storage devices (DASD)",
   code_30233141:"Redundant Array of Independent Disk (RAID)",
   code_30233150:"Optical-disk drives",
   code_30233151:"Compact disk (CD) reader and/or burner",
   code_30233152:"Digital versatile disc (DVD) reader and/or burner",
   code_30233153:"Compact disk (CD) and digital versatile disk (DVD) reader and/or burner",
   code_30233160:"Tape streamers",
   code_30233161:"Cassette-handling equipment",
   code_30233170:"Carousel units",
   code_30233180:"Flash memory storage devices",
   code_30233190:"Disk controller",
   code_30233300:"Smart card readers",
   code_30233310:"Fingerprint readers",
   code_30233320:"Combined smart card and fingerprint readers",
   code_30234000:"Storage media",
   code_30234100:"Magnetic disk",
   code_30234200:"Optical disks",
   code_30234300:"Compact disks (CDs)",
   code_30234400:"Digital versatile disks (DVDs)",
   code_30234500:"Memory storage media",
   code_30234600:"Flash memory",
   code_30234700:"Magnetic tapes",
   code_30236000:"Miscellaneous computer equipment",
   code_30236100:"Memory-expansion equipment",
   code_30236110:"Random access memory (RAM)",
   code_30236111:"Dynamic random access memory (DRAM)",
   code_30236112:"Static random access memory (SRAM)",
   code_30236113:"Synchronous dynamic random access memory (SDRAM)",
   code_30236114:"Rambus dynamic random access memory (RDRAM)",
   code_30236115:"Synchronous graphic random access memory (SGRAM)",
   code_30236120:"Read only memory (ROM)",
   code_30236121:"Programmable read only memory (PROM)",
   code_30236122:"Erasable programmable read only memory (EPROM)",
   code_30236123:"Electronically erasable programmable read only memory (EEPROM)",
   code_30236200:"Data-processing equipment",
   code_30237000:"Parts, accessories and supplies for computers",
   code_30237100:"Parts of computers",
   code_30237110:"Network interfaces",
   code_30237120:"Computer ports",
   code_30237121:"Serial infrared ports",
   code_30237130:"Computer cards",
   code_30237131:"Electronic cards",
   code_30237132:"Universal Serial Bus (USB) Interfaces",
   code_30237133:"Personal Computer Memory Card International Association (PCMCIA) adaptors and interfaces",
   code_30237134:"Graphic accelerator cards",
   code_30237135:"Network interfaces cards",
   code_30237136:"Audio cards",
   code_30237140:"Motherboards",
   code_30237200:"Computer accessories",
   code_30237210:"Anti-glare screens",
   code_30237220:"Mousepads",
   code_30237230:"Caches",
   code_30237240:"Web camera",
   code_30237250:"Computer cleaning accessories",
   code_30237251:"Computer cleaning kits",
   code_30237252:"Pressurised air dusters",
   code_30237253:"Dust covers for computer equipment",
   code_30237260:"Monitor wall mount arms",
   code_30237270:"Portable computer carrying cases",
   code_30237280:"Power supply accessories",
   code_30237290:"Keyboard wrist rests",
   code_30237295:"Keyguards",
   code_30237300:"Computer supplies",
   code_30237310:"Font cartridges for printers",
   code_30237320:"Diskettes",
   code_30237330:"Digital Audio Tape (DAT) cartridges",
   code_30237340:"Digital Linear Tape (DLT) cartridges",
   code_30237350:"Data cartridges",
   code_30237360:"Linear Tape-Open (LTO) cartridges",
   code_30237370:"Recording cartridges",
   code_30237380:"CD-ROM",
   code_30237400:"Data entry accessories",
   code_30237410:"Computer mouse",
   code_30237420:"Joysticks",
   code_30237430:"Light pens",
   code_30237440:"Trackballs",
   code_30237450:"Graphics tablets",
   code_30237460:"Computer keyboards",
   code_30237461:"Programmable keyboards",
   code_30237470:"Braille pads",
   code_30237475:"Electric sensors",
   code_30237480:"Input units",
   code_30238000:"Library automation equipment",
   code_31000000:"Electrical machinery, apparatus, equipment and consumables; lighting",
   code_31100000:"Electric motors, generators and transformers",
   code_31110000:"Electric motors",
   code_31111000:"Adapters",
   code_31120000:"Generators",
   code_31121000:"Generating sets",
   code_31121100:"Generating sets with compression-ignition engines",
   code_31121110:"Power converters",
   code_31121111:"Electric rotary converters",
   code_31121200:"Generating sets with spark-ignition engines",
   code_31121300:"Wind-energy generators",
   code_31121310:"Windmills",
   code_31121320:"Wind turbines",
   code_31121330:"Wind turbine generators",
   code_31121331:"Turbine rotors",
   code_31121340:"Wind farm",
   code_31122000:"Generator units",
   code_31122100:"Fuel cells",
   code_31124000:"Steam-turbine generator and related apparatus",
   code_31124100:"Turbine generator sets",
   code_31124200:"Turbine generator control apparatus",
   code_31126000:"Dynamos",
   code_31127000:"Emergency generator",
   code_31128000:"Turbogenerator",
   code_31130000:"Alternators",
   code_31131000:"Single-phase motors",
   code_31131100:"Actuators",
   code_31131200:"Anodes",
   code_31132000:"Multi-phase motors",
   code_31140000:"Cooling towers",
   code_31141000:"Water coolers",
   code_31150000:"Ballasts for discharge lamps or tubes",
   code_31151000:"Static converters",
   code_31153000:"Rectifiers",
   code_31154000:"Uninterruptible power supplies",
   code_31155000:"Inverters",
   code_31156000:"Interruptible power supplies",
   code_31157000:"Inductors",
   code_31158000:"Chargers",
   code_31158100:"Battery chargers",
   code_31158200:"Supercharger",
   code_31158300:"Turbocharger",
   code_31160000:"Parts of electric motors, generators and transformers",
   code_31161000:"Parts for electrical motors and generators",
   code_31161100:"Excitation systems",
   code_31161200:"Gas cooling systems",
   code_31161300:"Generator rotors",
   code_31161400:"Primary water systems",
   code_31161500:"Seal oil systems",
   code_31161600:"Stator cooling water systems",
   code_31161700:"Parts of steam generators",
   code_31161800:"Parts of gas generators",
   code_31161900:"Voltage-control systems",
   code_31162000:"Parts of transformers, inductors and static converters",
   code_31162100:"Parts of condensers",
   code_31170000:"Transformers",
   code_31171000:"Liquid dielectric transformers",
   code_31172000:"Voltage transformers",
   code_31173000:"Instrument transformer",
   code_31174000:"Power supply transformers",
   code_31200000:"Electricity distribution and control apparatus",
   code_31210000:"Electrical apparatus for switching or protecting electrical circuits",
   code_31211000:"Boards and fuse boxes",
   code_31211100:"Boards for electrical apparatus",
   code_31211110:"Control panels",
   code_31211200:"Fuse boxes",
   code_31211300:"Fuses",
   code_31211310:"Cut-outs",
   code_31211320:"Fuse blocks",
   code_31211330:"Fuse wires",
   code_31211340:"Fuse clips",
   code_31212000:"Circuit breakers",
   code_31212100:"Overhead circuit breakers",
   code_31212200:"Circuit testers",
   code_31212300:"Magnetic circuit breakers",
   code_31212400:"Miniature circuit breakers",
   code_31213000:"Distribution equipment",
   code_31213100:"Distribution boxes",
   code_31213200:"Distribution transformers",
   code_31213300:"Cable distribution cabinet",
   code_31213400:"Distribution system",
   code_31214000:"Switchgear",
   code_31214100:"Switches",
   code_31214110:"Isolating switches",
   code_31214120:"Earthing switch",
   code_31214130:"Safety switches",
   code_31214140:"Dimmer switches",
   code_31214150:"Drum switches",
   code_31214160:"Pressure switches",
   code_31214170:"Toggle switches",
   code_31214180:"Slide switches",
   code_31214190:"Limit switches",
   code_31214200:"Switch disconnector",
   code_31214300:"Outdoor switching installations",
   code_31214400:"Fuse switch disconnector",
   code_31214500:"Electric switchboards",
   code_31214510:"Distribution switchboards",
   code_31214520:"Medium-voltage switchboards",
   code_31215000:"Voltage limiters",
   code_31216000:"Lightning arrestors",
   code_31216100:"Lightning-protection equipment",
   code_31216200:"Lightning conductors",
   code_31217000:"Surge suppressors",
   code_31218000:"Busbars",
   code_31219000:"Protection boxes",
   code_31220000:"Electrical circuit components",
   code_31221000:"Electrical relays",
   code_31221100:"Power relays",
   code_31221200:"General purpose relays",
   code_31221300:"Socket relays",
   code_31221400:"Alternating voltage relays",
   code_31221500:"Mercury relays",
   code_31221600:"Time relays",
   code_31221700:"Overload relays",
   code_31223000:"Lamp-holders",
   code_31224000:"Connections and contact elements",
   code_31224100:"Plugs and sockets",
   code_31224200:"Coaxial connectors",
   code_31224300:"Connection boxes",
   code_31224400:"Connection cables",
   code_31224500:"Terminals",
   code_31224600:"Dimmers",
   code_31224700:"Junction boxes",
   code_31224800:"Cable joining kits",
   code_31224810:"Extension cables",
   code_31230000:"Parts of electricity distribution or control apparatus",
   code_31300000:"Insulated wire and cable",
   code_31310000:"Mains",
   code_31311000:"Mains connections",
   code_31320000:"Power distribution cables",
   code_31321000:"Electricity power lines",
   code_31321100:"Overhead power lines",
   code_31321200:"Low- and medium-voltage cable",
   code_31321210:"Low-voltage cable",
   code_31321220:"Medium-voltage cable",
   code_31321300:"High-voltage cable",
   code_31321400:"Underwater cable",
   code_31321500:"Submarine cable",
   code_31321600:"Shielded cable",
   code_31321700:"Signalling cable",
   code_31330000:"Coaxial cable",
   code_31340000:"Insulated cable accessories",
   code_31341000:"Insulated cable reels",
   code_31342000:"Insulated cable junctions",
   code_31343000:"Insulated cable joints",
   code_31344000:"Insulated cable glands",
   code_31350000:"Electric conductors for data and control purposes",
   code_31351000:"Electric conductors for access control systems",
   code_31400000:"Accumulators, primary cells and primary batteries",
   code_31410000:"Primary cells",
   code_31411000:"Alkaline batteries",
   code_31420000:"Primary batteries",
   code_31421000:"Lead batteries",
   code_31422000:"Battery packs",
   code_31430000:"Electric accumulators",
   code_31431000:"Lead-acid accumulators",
   code_31432000:"Nickel-cadmium accumulators",
   code_31433000:"Nickel-iron accumulators",
   code_31434000:"Lithium accumulators",
   code_31440000:"Batteries",
   code_31500000:"Lighting equipment and electric lamps",
   code_31510000:"Electric filament lamps",
   code_31511000:"Sealed-beam lamp units",
   code_31512000:"Tungsten halogen filament lamps",
   code_31512100:"Halogen bulbs, linear",
   code_31512200:"Halogen bulbs, bi-pin",
   code_31512300:"Halogen bulbs, dichroic",
   code_31514000:"Discharge lamps",
   code_31515000:"Ultraviolet lamps",
   code_31516000:"Infrared lamps",
   code_31517000:"Arc lamps",
   code_31518000:"Signalling lights",
   code_31518100:"Floodlights",
   code_31518200:"Emergency lighting equipment",
   code_31518210:"Stormlights",
   code_31518220:"Light stick",
   code_31518300:"Rooflights",
   code_31518500:"Mercury-vapour lamps",
   code_31518600:"Searchlights",
   code_31519000:"Incandescent and neon lamps",
   code_31519100:"Incandescent lamps",
   code_31519200:"Neon lamps",
   code_31520000:"Lamps and light fittings",
   code_31521000:"Lamps",
   code_31521100:"Desk lamps",
   code_31521200:"Floor-standing lamps",
   code_31521300:"Portable electric lamps",
   code_31521310:"Warning lights",
   code_31521320:"Torches",
   code_31521330:"Rechargeable portable electric lamps",
   code_31522000:"Christmas tree lights",
   code_31523000:"Illuminated signs and nameplates",
   code_31523100:"Advertising neon lights",
   code_31523200:"Permanent message signs",
   code_31523300:"Illuminated nameplates",
   code_31524000:"Ceiling or wall light fittings",
   code_31524100:"Ceiling light fittings",
   code_31524110:"Operating-theatre lamps",
   code_31524120:"Ceiling lights",
   code_31524200:"Wall light fittings",
   code_31524210:"Wall lights",
   code_31527000:"Spotlights",
   code_31527200:"Exterior lights",
   code_31527210:"Lanterns",
   code_31527260:"Lighting systems",
   code_31527270:"Platforms lighting",
   code_31527300:"Domestic lights",
   code_31527400:"Underwater lights",
   code_31530000:"Parts of lamps and lighting equipment",
   code_31531000:"Light bulbs",
   code_31531100:"Electric tubes",
   code_31532000:"Parts of lamps and light fittings",
   code_31532100:"Tube lamps",
   code_31532110:"Fluorescent tube lamps",
   code_31532120:"Compact fluorescent tube lamps",
   code_31532200:"Ring lamps",
   code_31532210:"Fluorescent ring lamps",
   code_31532300:"Globe lamps",
   code_31532310:"Compact fluorescent globe lamps",
   code_31532400:"Lamps sockets",
   code_31532500:"Lamp starters",
   code_31532510:"Starters for fluorescent lamps",
   code_31532600:"Lamp reactors",
   code_31532610:"Reactors for fluorescent lamps",
   code_31532700:"Lamp covers",
   code_31532800:"Lamp arms",
   code_31532900:"Fluorescent lights",
   code_31532910:"Fluorescent tubes",
   code_31532920:"Bulbs and fluorescent lamps",
   code_31600000:"Electrical equipment and apparatus",
   code_31610000:"Electrical equipment for engines and vehicles",
   code_31611000:"Wiring sets",
   code_31612000:"Electrical wiring looms for engines",
   code_31612200:"Starter motors",
   code_31612300:"Electrical signalling equipment for engines",
   code_31612310:"Blinkers",
   code_31620000:"Sound or visual signalling apparatus",
   code_31625000:"Burglar and fire alarms",
   code_31625100:"Fire-detection systems",
   code_31625200:"Fire-alarm systems",
   code_31625300:"Burglar-alarm systems",
   code_31630000:"Magnets",
   code_31640000:"Machines and apparatus with individual functions",
   code_31642000:"Electronic detection apparatus",
   code_31642100:"Detection apparatus for metal pipes",
   code_31642200:"Detection apparatus for mines",
   code_31642300:"Detection apparatus for plastics",
   code_31642400:"Detection apparatus for non-metallic objects",
   code_31642500:"Detection apparatus for timber",
   code_31643000:"Particle accelerators",
   code_31643100:"Linear accelerators",
   code_31644000:"Miscellaneous data recorders",
   code_31645000:"Pinball machines",
   code_31650000:"Insulating fittings",
   code_31651000:"Electrical tape",
   code_31660000:"Carbon electrodes",
   code_31670000:"Electrical parts of machinery or apparatus",
   code_31671000:"Glass envelopes and cathode-ray tubes",
   code_31671100:"Glass envelopes",
   code_31671200:"Cathode-ray tubes",
   code_31680000:"Electrical supplies and accessories",
   code_31681000:"Electrical accessories",
   code_31681100:"Electrical contacts",
   code_31681200:"Electric pumps",
   code_31681300:"Electrical circuits",
   code_31681400:"Electrical components",
   code_31681410:"Electrical materials",
   code_31681500:"Rechargers",
   code_31682000:"Electricity supplies",
   code_31682100:"Electricity boxes",
   code_31682110:"Electricity box covers",
   code_31682200:"Instrument panels",
   code_31682210:"Instrumentation and control equipment",
   code_31682220:"Mixing panels",
   code_31682230:"Graphic display panels",
   code_31682300:"Medium-voltage equipment",
   code_31682310:"Medium-voltage panels",
   code_31682400:"Overhead electrical equipment",
   code_31682410:"Overhead cable carriers",
   code_31682500:"Emergency electricity equipment",
   code_31682510:"Emergency power systems",
   code_31682520:"Emergency shutdown systems",
   code_31682530:"Emergency power supplies",
   code_31682540:"Substation equipment",
   code_31700000:"Electronic, electromechanical and electrotechnical supplies",
   code_31710000:"Electronic equipment",
   code_31711000:"Electronic supplies",
   code_31711100:"Electronic components",
   code_31711110:"Transceivers",
   code_31711120:"Transducers",
   code_31711130:"Resistors",
   code_31711131:"Electrical resistors",
   code_31711140:"Electrodes",
   code_31711150:"Electrical capacitors",
   code_31711151:"Fixed capacitors",
   code_31711152:"Variable or adjustable capacitors",
   code_31711154:"Capacitor banks",
   code_31711155:"Capacitor networks",
   code_31711200:"Electronic scoreboards",
   code_31711300:"Electronic timekeeping systems",
   code_31711310:"System for recording attendance",
   code_31711400:"Valves and tubes",
   code_31711410:"Cathode-ray television picture tubes",
   code_31711411:"Television camera tubes",
   code_31711420:"Microwave tubes and equipment",
   code_31711421:"Magnetrons",
   code_31711422:"Microwave equipment",
   code_31711423:"Microwave radio equipment",
   code_31711424:"Klystrons",
   code_31711430:"Valve tubes",
   code_31711440:"Receiver or amplifier valves and tubes",
   code_31711500:"Parts of electronic assemblies",
   code_31711510:"Parts of electrical capacitors",
   code_31711520:"Parts of electrical resistors, rheostats and potentiometers",
   code_31711530:"Parts of electronic valves and tubes",
   code_31712000:"Microelectronic machinery and apparatus and microsystems",
   code_31712100:"Microelectronic machinery and apparatus",
   code_31712110:"Electronic integrated circuits and microassemblies",
   code_31712111:"Phone cards",
   code_31712112:"SIM cards",
   code_31712113:"Cards containing integrated circuits",
   code_31712114:"Integrated electronic circuits",
   code_31712115:"Microassemblies",
   code_31712116:"Microprocessors",
   code_31712117:"Integrated circuit packages",
   code_31712118:"Integrated circuit sockets or mounts",
   code_31712119:"Integrated circuit lids",
   code_31712200:"Microsystems",
   code_31712300:"Printed circuits",
   code_31712310:"Populated printed circuit boards",
   code_31712320:"Unpopulated printed circuit boards",
   code_31712330:"Semiconductors",
   code_31712331:"Photovoltaic cells",
   code_31712332:"Thyristors",
   code_31712333:"Diacs",
   code_31712334:"Triacs",
   code_31712335:"Optical coupled isolators",
   code_31712336:"Crystal oscillators",
   code_31712340:"Diodes",
   code_31712341:"Light-emitting diodes",
   code_31712342:"Microwave or small signal diodes",
   code_31712343:"Zener diodes",
   code_31712344:"Schottky diodes",
   code_31712345:"Tunnel diodes",
   code_31712346:"Photosensitive diodes",
   code_31712347:"Power or solar diodes",
   code_31712348:"Laser diodes",
   code_31712349:"Radio frequency (RF) diodes",
   code_31712350:"Transistors",
   code_31712351:"Photo sensitive transistors",
   code_31712352:"Field effect transistors (FET)",
   code_31712353:"Metal oxide field effect transistors (MOSFET)",
   code_31712354:"Transistor chips",
   code_31712355:"Bipolar darlington or radio frequency (RF) transistors",
   code_31712356:"Unijunction transistors",
   code_31712357:"Insulated gate bipolar transistors (IGBT)",
   code_31712358:"Junction field effect transistors (JFET)",
   code_31712359:"Bipolar junction transistors (BJT)",
   code_31712360:"Mounted piezo-electric crystals",
   code_31720000:"Electromechanical equipment",
   code_31730000:"Electrotechnical equipment",
   code_31731000:"Electrotechnical supplies",
   code_31731100:"Modules",
   code_32000000:"Radio, television, communication, telecommunication and related equipment",
   code_32200000:"Transmission apparatus for radiotelephony, radiotelegraphy, radio broadcasting and television",
   code_32210000:"Broadcasting equipment",
   code_32211000:"Broadcast production equipment",
   code_32220000:"Television transmission apparatus without reception apparatus",
   code_32221000:"Radio beacons",
   code_32222000:"Video-signal coding machines",
   code_32223000:"Video transmission apparatus",
   code_32224000:"Television transmission apparatus",
   code_32230000:"Radio transmission apparatus with reception apparatus",
   code_32231000:"Closed-circuit television apparatus",
   code_32232000:"Video-conferencing equipment",
   code_32233000:"Radio-frequency booster stations",
   code_32234000:"Closed-circuit television cameras",
   code_32235000:"Closed-circuit surveillance system",
   code_32236000:"Radio telephones",
   code_32237000:"Walkie-talkies",
   code_32240000:"Television cameras",
   code_32250000:"Mobile telephones",
   code_32251000:"Car telephones",
   code_32251100:"Hands-free set",
   code_32252000:"GSM telephones",
   code_32252100:"Hands-free mobile telephones",
   code_32252110:"Hands-free mobile telephones (wireless)",
   code_32260000:"Data-transmission equipment",
   code_32270000:"Digital transmission apparatus",
   code_32300000:"Television and radio receivers, and sound or video recording or reproducing apparatus",
   code_32310000:"Radio broadcast receivers",
   code_32320000:"Television and audio-visual equipment",
   code_32321000:"Television projection equipment",
   code_32321100:"Film equipment",
   code_32321200:"Audio-visual equipment",
   code_32321300:"Audio-visual materials",
   code_32322000:"Multimedia equipment",
   code_32323000:"Video monitors",
   code_32323100:"Colour video monitors",
   code_32323200:"Monochrome video monitors",
   code_32323300:"Video equipment",
   code_32323400:"Video-playback equipment",
   code_32323500:"Video-surveillance system",
   code_32324000:"Televisions",
   code_32324100:"Colour televisions",
   code_32324200:"Monochrome televisions",
   code_32324300:"Television equipment",
   code_32324310:"Satellite antennas",
   code_32324400:"Television aerials",
   code_32324500:"Video tuners",
   code_32324600:"Digital-TV boxes",
   code_32330000:"Apparatus for sound, video-recording and reproduction",
   code_32331000:"Turntables",
   code_32331100:"Record players",
   code_32331200:"Cassette players",
   code_32331300:"Sound-reproduction apparatus",
   code_32331500:"Recorders",
   code_32331600:"MP3 player",
   code_32332000:"Magnetic tape recorders",
   code_32332100:"Dictating machines",
   code_32332200:"Telephone-answering machines",
   code_32332300:"Sound recorders",
   code_32333000:"Video recording or reproducing apparatus",
   code_32333100:"Video recorders",
   code_32333200:"Video camcorders",
   code_32333300:"Video-reproducing apparatus",
   code_32333400:"Video players",
   code_32340000:"Microphones and loudspeakers",
   code_32341000:"Microphones",
   code_32342000:"Loudspeakers",
   code_32342100:"Headphones",
   code_32342200:"Earphones",
   code_32342300:"Microphones and speaker sets",
   code_32342400:"Acoustic devices",
   code_32342410:"Sound equipment",
   code_32342411:"Mini speakers",
   code_32342412:"Speakers",
   code_32342420:"Studio mixing console",
   code_32342430:"Speech-compression system",
   code_32342440:"Voice-mail system",
   code_32342450:"Voice recorders",
   code_32343000:"Amplifiers",
   code_32343100:"Audio-frequency amplifiers",
   code_32343200:"Megaphones",
   code_32344000:"Reception apparatus for radiotelephony or radiotelegraphy",
   code_32344100:"Portable receivers for calling and paging",
   code_32344110:"Voice-logging system",
   code_32344200:"Radio receivers",
   code_32344210:"Radio equipment",
   code_32344220:"Radio pagers",
   code_32344230:"Radio stations",
   code_32344240:"Radio tower",
   code_32344250:"Radio installations",
   code_32344260:"Radio and multiplex equipment",
   code_32344270:"Radio and telephone control system",
   code_32344280:"Portable radios",
   code_32350000:"Parts of sound and video equipment",
   code_32351000:"Accessories for sound and video equipment",
   code_32351100:"Video-editing equipment",
   code_32351200:"Screens",
   code_32351300:"Audio equipment accessories",
   code_32351310:"Audio cassettes",
   code_32352000:"Aerials and reflectors",
   code_32352100:"Parts of radio and radar equipment",
   code_32352200:"Radar spare parts and accessories",
   code_32353000:"Sound recordings",
   code_32353100:"Records",
   code_32353200:"Music cassettes",
   code_32354000:"Film products",
   code_32354100:"Radiology film",
   code_32354110:"X-ray film",
   code_32354120:"Blue diazo film",
   code_32354200:"Cinematographic film",
   code_32354300:"Photographic film",
   code_32354400:"Instant-print film",
   code_32354500:"Video films",
   code_32354600:"Video cassettes",
   code_32354700:"Video tapes",
   code_32354800:"Cling film",
   code_32360000:"Intercom equipment",
   code_32400000:"Networks",
   code_32410000:"Local area network",
   code_32411000:"Token-ring network",
   code_32412000:"Communications network",
   code_32412100:"Telecommunications network",
   code_32412110:"Internet network",
   code_32412120:"Intranet network",
   code_32413000:"Integrated network",
   code_32413100:"Network routers",
   code_32415000:"Ethernet network",
   code_32416000:"ISDN network",
   code_32416100:"ISDX network",
   code_32417000:"Multimedia networks",
   code_32418000:"Radio network",
   code_32420000:"Network equipment",
   code_32421000:"Network cabling",
   code_32422000:"Network components",
   code_32423000:"Network hubs",
   code_32424000:"Network infrastructure",
   code_32425000:"Network operating system",
   code_32426000:"Network publishing system",
   code_32427000:"Network system",
   code_32428000:"Network upgrade",
   code_32429000:"Telephone network equipment",
   code_32430000:"Wide area network",
   code_32440000:"Telemetry and terminal equipment",
   code_32441000:"Telemetry equipment",
   code_32441100:"Telemetry surveillance system",
   code_32441200:"Telemetry and control equipment",
   code_32441300:"Telematics system",
   code_32442000:"Terminal equipment",
   code_32442100:"Terminal boards",
   code_32442200:"Terminal boxes",
   code_32442300:"Terminal emulators",
   code_32442400:"Termination blocks",
   code_32500000:"Telecommunications equipment and supplies",
   code_32510000:"Wireless telecommunications system",
   code_32520000:"Telecommunications cable and equipment",
   code_32521000:"Telecommunications cable",
   code_32522000:"Telecommunications equipment",
   code_32523000:"Telecommunications facilities",
   code_32524000:"Telecommunications system",
   code_32530000:"Satellite-related communications equipment",
   code_32531000:"Satellite communications equipment",
   code_32532000:"Satellite dishes",
   code_32533000:"Satellite earth stations",
   code_32534000:"Satellite platforms",
   code_32540000:"Switchboards",
   code_32541000:"Switchboard equipment",
   code_32542000:"Switchboard panels",
   code_32543000:"Telephone switchboards",
   code_32544000:"PABX equipment",
   code_32545000:"PABX systems",
   code_32546000:"Digital switching equipment",
   code_32546100:"Digital switchboards",
   code_32547000:"Vacuum switchboards",
   code_32550000:"Telephone equipment",
   code_32551000:"Telephone cables and associated equipment",
   code_32551100:"Telephone connections",
   code_32551200:"Telephone exchanges",
   code_32551300:"Telephone headsets",
   code_32551400:"Telephone network",
   code_32551500:"Telephone cables",
   code_32552000:"Electrical apparatus for line telephony or line telegraphy",
   code_32552100:"Telephone sets",
   code_32552110:"Cordless telephones",
   code_32552120:"Emergency telephones",
   code_32552130:"Public telephones",
   code_32552140:"Payphone equipment",
   code_32552150:"Telephones for visually-impaired",
   code_32552160:"Telephones for hearing-impaired",
   code_32552200:"Teleprinters",
   code_32552300:"Telephonic or telegraphic switching apparatus",
   code_32552310:"Digital telephone exchanges",
   code_32552320:"Multiplexers",
   code_32552330:"Telephone switching apparatus",
   code_32552400:"Audio-frequency signal conversion apparatus",
   code_32552410:"Modems",
   code_32552420:"Frequency converter",
   code_32552430:"Coding equipment",
   code_32552500:"Teletext apparatus",
   code_32552510:"Videotext terminals",
   code_32552520:"Telex equipment",
   code_32552600:"Entrance telephones",
   code_32553000:"Parts of electrical telephonic or telegraphic apparatus",
   code_32560000:"Fibre-optic materials",
   code_32561000:"Fibre-optic connections",
   code_32562000:"Optical-fibre cables",
   code_32562100:"Optical-fibre cables for information transmission",
   code_32562200:"Optical telecommunication cables",
   code_32562300:"Optical-fibre cables for data transmission",
   code_32570000:"Communications equipment",
   code_32571000:"Communications infrastructure",
   code_32572000:"Communications cable",
   code_32572100:"Communications cable with multiple electrical conductors",
   code_32572200:"Communications cable with coaxial conductors",
   code_32572300:"Communications cable for special applications",
   code_32573000:"Communications control system",
   code_32580000:"Data equipment",
   code_32581000:"Data-communications equipment",
   code_32581100:"Data-transmission cable",
   code_32581110:"Data-transmission cable with multiple electrical conductors",
   code_32581120:"Data-transmission cable with coaxial conductors",
   code_32581130:"Data-transmission cable for special applications",
   code_32581200:"Fax equipment",
   code_32581210:"Accessories and components for fax equipment",
   code_32582000:"Data carriers",
   code_32583000:"Data and voice media",
   code_32584000:"Data-bearing media",
   code_33000000:"Medical equipments, pharmaceuticals and personal care products",
   code_33100000:"Medical equipments",
   code_33110000:"Imaging equipment for medical, dental and veterinary use",
   code_33111000:"X-ray devices",
   code_33111100:"X-ray table",
   code_33111200:"X-ray workstations",
   code_33111300:"X-ray processing devices",
   code_33111400:"X-ray fluoroscopy devices",
   code_33111500:"Dental X-ray",
   code_33111600:"Radiography devices",
   code_33111610:"Magnetic resonance unit",
   code_33111620:"Gamma cameras",
   code_33111640:"Thermographs",
   code_33111650:"Mammography devices",
   code_33111660:"Bone densitometers",
   code_33111700:"Angiography room",
   code_33111710:"Angiography supplies",
   code_33111720:"Angiography devices",
   code_33111721:"Digital angiography devices",
   code_33111730:"Angioplasty supplies",
   code_33111740:"Angioplasty devices",
   code_33111800:"Diagnostic X-ray system",
   code_33112000:"Echo, ultrasound and doppler imaging equipment",
   code_33112100:"Ultrasonic heart detector",
   code_33112200:"Ultrasonic unit",
   code_33112300:"Ultrasound scanners",
   code_33112310:"Colour-flow doppler",
   code_33112320:"Doppler equipment",
   code_33112330:"Echoencephalographs",
   code_33112340:"Echocardiographs",
   code_33113000:"Magnetic resonance imaging equipment",
   code_33113100:"Magnetic resonance scanners",
   code_33113110:"Nuclear magnetic resonance scanners",
   code_33114000:"Spectroscopy devices",
   code_33115000:"Tomography devices",
   code_33115100:"CT scanners",
   code_33115200:"CAT scanners",
   code_33120000:"Recording systems and exploration devices",
   code_33121000:"Long term ambulatory recording system",
   code_33121100:"Electro-encephalographs",
   code_33121200:"Scintigraphy devices",
   code_33121300:"Electromyographs",
   code_33121400:"Audiometers",
   code_33121500:"Electrocardiogram",
   code_33122000:"Ophthalmology equipment",
   code_33123000:"Cardiovascular devices",
   code_33123100:"Tensiometer",
   code_33123200:"Electrocardiography devices",
   code_33123210:"Cardiac-monitoring devices",
   code_33123220:"Cardio-angiography devices",
   code_33123230:"Cardiographs",
   code_33124000:"Diagnostics and radiodiagnostic devices and supplies",
   code_33124100:"Diagnostic devices",
   code_33124110:"Diagnostic systems",
   code_33124120:"Diagnostic ultrasound devices",
   code_33124130:"Diagnostic supplies",
   code_33124131:"Reagent strips",
   code_33124200:"Radiodiagnostic devices",
   code_33124210:"Radiodiagnostic supplies",
   code_33125000:"Urology, exploration devices",
   code_33126000:"Stomatology devices",
   code_33127000:"Immuno-analysis devices",
   code_33128000:"Medical laser other than for surgery",
   code_33130000:"Dental and subspecialty instruments and devices",
   code_33131000:"Dental hand instrument",
   code_33131100:"Dental surgical instrument",
   code_33131110:"Dental nippers, brushes, retractors and burnishers",
   code_33131111:"Dental nippers",
   code_33131112:"Dental operative brushes",
   code_33131113:"Dental retractors",
   code_33131114:"Dental burnishers",
   code_33131120:"Dental cryosurgical, gauges, elevators and excavators",
   code_33131121:"Dental cryosurgical units",
   code_33131122:"Dental depth gauges",
   code_33131123:"Dental elevators",
   code_33131124:"Dental excavators",
   code_33131130:"Dental fingers protectors and forceps",
   code_33131131:"Dental fingers protectors",
   code_33131132:"Dental forceps",
   code_33131140:"Dental mirrors and reamers",
   code_33131141:"Dental mirrors",
   code_33131142:"Dental reamers",
   code_33131150:"Dental root tip picks, scalers and scale",
   code_33131151:"Dental root tip picks",
   code_33131152:"Dental scalers",
   code_33131153:"Dental scale",
   code_33131160:"Dental scissors and knives",
   code_33131161:"Dental scissors",
   code_33131162:"Dental knives",
   code_33131170:"Dental spatulas, tweezers and wax carvers",
   code_33131171:"Dental spatulas",
   code_33131172:"Dental tweezers",
   code_33131173:"Dental wax carvers",
   code_33131200:"Dental suture needle",
   code_33131300:"Dental disposable instrument",
   code_33131400:"Dental probe",
   code_33131500:"Dental extraction instrument",
   code_33131510:"Dental drills",
   code_33131600:"Dental filling instrument",
   code_33132000:"Dental implant",
   code_33133000:"Dental impression accessories",
   code_33134000:"Endodontics accessories",
   code_33135000:"Orthodontic devices",
   code_33136000:"Rotary and abrasive instrument",
   code_33137000:"Dental prophylaxis accessories",
   code_33138000:"Prosthodontic and relining products",
   code_33138100:"Dentures",
   code_33140000:"Medical consumables",
   code_33141000:"Disposable non-chemical medical consumables and haematological consumables",
   code_33141100:"Dressings; clip, suture, ligature supplies",
   code_33141110:"Dressings",
   code_33141111:"Adhesive dressings",
   code_33141112:"Plasters",
   code_33141113:"Bandages",
   code_33141114:"Medical gauze",
   code_33141115:"Medical wadding",
   code_33141116:"Dressing packs",
   code_33141117:"Cotton wool",
   code_33141118:"Wipes",
   code_33141119:"Compresses",
   code_33141120:"Clip, suture, ligature supplies",
   code_33141121:"Surgical sutures",
   code_33141122:"Surgical staples",
   code_33141123:"Sharps containers",
   code_33141124:"Sharps pads",
   code_33141125:"Material for surgical sutures",
   code_33141126:"Ligatures",
   code_33141127:"Absorbable haemostatics",
   code_33141128:"Needles for sutures",
   code_33141200:"Catheters",
   code_33141210:"Balloon catheters",
   code_33141220:"Cannulae",
   code_33141230:"Dilator",
   code_33141240:"Catheter accessories",
   code_33141300:"Venepuncture, blood sampling devices",
   code_33141310:"Syringes",
   code_33141320:"Medical needles",
   code_33141321:"Anesthesia needles",
   code_33141322:"Arterial needles",
   code_33141323:"Biopsy needles",
   code_33141324:"Dialysis needles",
   code_33141325:"Fistula needles",
   code_33141326:"Radiology procedural needles",
   code_33141327:"Vented needles",
   code_33141328:"Epidural needles",
   code_33141329:"Amniocentesia needles",
   code_33141400:"Wire-cutter and bistoury; surgical gloves",
   code_33141410:"Wire-cutter and bistoury",
   code_33141411:"Scalpels and blades",
   code_33141420:"Surgical gloves",
   code_33141500:"Haematological consumables",
   code_33141510:"Blood products",
   code_33141520:"Plasma extracts",
   code_33141530:"Blood coagulants",
   code_33141540:"Albumin",
   code_33141550:"Heparin",
   code_33141560:"Human organs",
   code_33141570:"Human blood",
   code_33141580:"Animal blood",
   code_33141600:"Collector and collection bags, drainage and kits",
   code_33141610:"Collection bag",
   code_33141613:"Blood bags",
   code_33141614:"Plasma bags",
   code_33141615:"Bags for urine",
   code_33141620:"Medical kits",
   code_33141621:"Incontinence kit",
   code_33141622:"AIDS-prevention kits",
   code_33141623:"First-aid boxes",
   code_33141624:"Administration sets",
   code_33141625:"Diagnostic kits",
   code_33141626:"Dosage kits",
   code_33141630:"Blood plasma filters",
   code_33141640:"Drain",
   code_33141641:"Probes",
   code_33141642:"Drain accessories",
   code_33141700:"Orthopaedic supplies",
   code_33141710:"Crutches",
   code_33141720:"Walking aids",
   code_33141730:"Surgical collars",
   code_33141740:"Orthopaedic footwear",
   code_33141750:"Artificial joints",
   code_33141760:"Splints",
   code_33141770:"Fracture appliances, pins and plates",
   code_33141800:"Dental consumables",
   code_33141810:"Dental filling materials",
   code_33141820:"Teeth",
   code_33141821:"Porcelain teeth",
   code_33141822:"Acrylic teeth",
   code_33141830:"Cement base",
   code_33141840:"Dental haemostatic",
   code_33141850:"Dental hygiene products",
   code_33141900:"Blood lancets",
   code_33150000:"Radiotherapy, mechanotherapy, electrotherapy and physical therapy devices",
   code_33151000:"Radiotherapy devices and supplies",
   code_33151100:"Gamma therapy devices",
   code_33151200:"X-ray therapy devices",
   code_33151300:"Spectrographs",
   code_33151400:"Radiotherapy supplies",
   code_33152000:"Incubators",
   code_33153000:"Lithotripter",
   code_33154000:"Mechanotherapy devices",
   code_33155000:"Physical therapy devices",
   code_33156000:"Psychology testing devices",
   code_33157000:"Gas-therapy and respiratory devices",
   code_33157100:"Medical gas masks",
   code_33157110:"Oxygen mask",
   code_33157200:"Oxygen kits",
   code_33157300:"Oxygen tents",
   code_33157400:"Medical breathing devices",
   code_33157500:"Hyperbaric chambers",
   code_33157700:"Blow bottle",
   code_33157800:"Oxygen administration unit",
   code_33157810:"Oxygen therapy unit",
   code_33158000:"Electrical, electromagnetic and mechanical treatment",
   code_33158100:"Electromagnetic unit",
   code_33158200:"Electrotherapy devices",
   code_33158210:"Stimulator",
   code_33158300:"Ultraviolet medical devices",
   code_33158400:"Mechanical therapy unit",
   code_33158500:"Infrared medical devices",
   code_33159000:"Clinical chemistry system",
   code_33160000:"Operating techniques",
   code_33161000:"Electrosurgical unit",
   code_33162000:"Operating theatre devices and instruments",
   code_33162100:"Operating-theatre devices",
   code_33162200:"Operating-theatre instruments",
   code_33163000:"Tent for medical use",
   code_33164000:"Coelioscopy devices",
   code_33164100:"Colposcope",
   code_33165000:"Cryosurgical and cryotherapy devices",
   code_33166000:"Dermatological devices",
   code_33167000:"Surgical lights",
   code_33168000:"Endoscopy, endosurgery devices",
   code_33168100:"Endoscopes",
   code_33169000:"Surgical instruments",
   code_33169100:"Surgical laser",
   code_33169200:"Surgical baskets",
   code_33169300:"Surgical trays",
   code_33169400:"Surgical containers",
   code_33169500:"Surgical tracking and tracing systems",
   code_33170000:"Anaesthesia and resuscitation",
   code_33171000:"Instruments for anaesthesia and resuscitation",
   code_33171100:"Instruments for anaesthesia",
   code_33171110:"Anaesthesia mask",
   code_33171200:"Instruments for resuscitation",
   code_33171210:"Resuscitation mask",
   code_33171300:"Epidural kits or packs",
   code_33172000:"Anaesthesia and resuscitation devices",
   code_33172100:"Anaesthesia devices",
   code_33172200:"Resuscitation devices",
   code_33180000:"Functional support",
   code_33181000:"Renal support devices",
   code_33181100:"Haemodialysis devices",
   code_33181200:"Dialysis filters",
   code_33181300:"Haemodialysis individual monitor",
   code_33181400:"Haemodialysis multiposition",
   code_33181500:"Renal consumables",
   code_33181510:"Renal fluid",
   code_33181520:"Renal dialysis consumables",
   code_33182000:"Cardiac support devices",
   code_33182100:"Defibrillator",
   code_33182200:"Cardiac stimulation devices",
   code_33182210:"Pacemaker",
   code_33182220:"Cardiac valve",
   code_33182230:"Ventricle",
   code_33182240:"Parts and accessories for pacemakers",
   code_33182241:"Batteries for pacemakers",
   code_33182300:"Cardiac surgery devices",
   code_33182400:"Cardiac X-ray system",
   code_33183000:"Orthopaedic support devices",
   code_33183100:"Orthopaedic implants",
   code_33183200:"Orthopaedic prostheses",
   code_33183300:"Osteosynthesis devices",
   code_33184000:"Artificial parts of the body",
   code_33184100:"Surgical implants",
   code_33184200:"Vascular prostheses",
   code_33184300:"Artificial parts of the heart",
   code_33184400:"Breast prostheses",
   code_33184410:"Internal breast prostheses",
   code_33184420:"External breast prostheses",
   code_33184500:"Coronary endoprostheses",
   code_33184600:"False eyes",
   code_33185000:"Hearing aids",
   code_33185100:"Parts and accessories for hearing aids",
   code_33185200:"Cochlear implant",
   code_33185300:"Otolaryngology implant",
   code_33185400:"Larynx artificial",
   code_33186000:"Extracorporeal circulatory unit",
   code_33186100:"Oxygenator",
   code_33186200:"Blood and fluid warming",
   code_33190000:"Miscellaneous medical devices and products",
   code_33191000:"Sterilisation, disinfection and hygiene devices",
   code_33191100:"Steriliser",
   code_33191110:"Autoclaves",
   code_33192000:"Medical furniture",
   code_33192100:"Beds for medical use",
   code_33192110:"Orthopaedic beds",
   code_33192120:"Hospital beds",
   code_33192130:"Motorised beds",
   code_33192140:"Psychiatric couches",
   code_33192150:"Therapy beds",
   code_33192160:"Stretchers",
   code_33192200:"Medical tables",
   code_33192210:"Examination tables",
   code_33192230:"Operating tables",
   code_33192300:"Medical furniture except beds and tables",
   code_33192310:"Traction or suspension devices for medical beds",
   code_33192320:"Urine-bottle holders",
   code_33192330:"Transfusion pods",
   code_33192340:"Operating theatre furniture except tables",
   code_33192350:"Medical cultivation cabinet",
   code_33192400:"Dental workstations",
   code_33192410:"Dental chairs",
   code_33192500:"Test tubes",
   code_33192600:"Lifting equipment for health care sector",
   code_33193000:"Invalid carriages, wheelchairs and associated devices",
   code_33193100:"Invalid carriages and wheelchairs",
   code_33193110:"Invalid carriages",
   code_33193120:"Wheelchairs",
   code_33193121:"Motorised wheelchairs",
   code_33193200:"Parts and accessories for invalid carriages and wheelchairs",
   code_33193210:"Parts and accessories for invalid carriages",
   code_33193211:"Motors for invalid carriages",
   code_33193212:"Steering devices for invalid carriages",
   code_33193213:"Control devices for invalid carriages",
   code_33193214:"Chassis for invalid carriages",
   code_33193220:"Parts and accessories for wheelchairs",
   code_33193221:"Wheelchair cushions",
   code_33193222:"Wheelchair frames",
   code_33193223:"Wheelchair seats",
   code_33193224:"Wheelchair wheels",
   code_33193225:"Wheelchair tyres",
   code_33194000:"Devices and instruments for transfusion and infusion",
   code_33194100:"Devices and instruments for infusion",
   code_33194110:"Infusion pumps",
   code_33194120:"Infusion supplies",
   code_33194200:"Devices and instruments for transfusion",
   code_33194210:"Blood-transfusion devices",
   code_33194220:"Blood-transfusion supplies",
   code_33195000:"Patient-monitoring system",
   code_33195100:"Monitors",
   code_33195110:"Respiratory monitors",
   code_33195200:"Central monitoring station",
   code_33196000:"Medical aids",
   code_33196100:"Devices for the elderly",
   code_33196200:"Devices for the disabled",
   code_33197000:"Medical computer equipment",
   code_33198000:"Hospital paper articles",
   code_33198100:"Paper compresses",
   code_33198200:"Paper sterilisation pouches or wraps",
   code_33199000:"Medical clothing",
   code_33600000:"Pharmaceutical products",
   code_33610000:"Medicinal products for the alimentary tract and metabolism",
   code_33611000:"Medicinal products for acid related disorders",
   code_33612000:"Medicinal products for functional gastrointestinal disorders",
   code_33613000:"Laxatives",
   code_33614000:"Antidiarrhoeals, intestinal anti-inflammatory/anti-infective agents",
   code_33615000:"Medicinal products used in diabetes",
   code_33615100:"Insulin",
   code_33616000:"Vitamins",
   code_33616100:"Provitamins",
   code_33617000:"Mineral supplements",
   code_33620000:"Medicinal products for the blood, blood-forming organs and the cardiovascular system",
   code_33621000:"Medicinal products for the blood and blood-forming organs",
   code_33621100:"Antithrombotic agents",
   code_33621200:"Antihaemorrhagics",
   code_33621300:"Antianaemic preparations",
   code_33621400:"Blood substitutes and perfusion solutions",
   code_33622000:"Medicinal products for the cardiovascular system",
   code_33622100:"Cardiac therapy medicinal products",
   code_33622200:"Antihypertensives",
   code_33622300:"Diuretics",
   code_33622400:"Vasoprotectives",
   code_33622500:"Antihaemorrhoidals for topical use",
   code_33622600:"Beta-blocking agents",
   code_33622700:"Calcium channel blockers",
   code_33622800:"Agents acting on the renin-angiotensin system",
   code_33630000:"Medicinal products for dermatology and the musculo-skeletal system",
   code_33631000:"Medicinal products for dermatology",
   code_33631100:"Antifungals for dermatological use",
   code_33631110:"Salicylic acids",
   code_33631200:"Emollients and protectives",
   code_33631300:"Antipsoriatics",
   code_33631400:"Antibiotics and chemotherapeutics for dermatological use",
   code_33631500:"Corticosteroids for dermatological use and dermatological preparations",
   code_33631600:"Antiseptics and disinfectants",
   code_33631700:"Anti-acne preparations",
   code_33632000:"Medicinal products for the musculo-skeletal system",
   code_33632100:"Anti-inflammatory and anti-rheumatic products",
   code_33632200:"Muscle relaxants",
   code_33632300:"Antigout preparations",
   code_33640000:"Medicinal products for the genitourinary system and hormones",
   code_33641000:"Medicinal products for the genitourinary system and sex hormones",
   code_33641100:"Gynaecological anti-infectives and antiseptics",
   code_33641200:"Other gynaecologicals",
   code_33641300:"Sex hormones and modulators of the genital system",
   code_33641400:"Contraceptives",
   code_33641410:"Oral contraceptives",
   code_33641420:"Chemical contraceptives",
   code_33642000:"Systemic hormonal preparations, excl. sex hormones",
   code_33642100:"Pituitary, hypothalamic hormones and analogues",
   code_33642200:"Corticosteroids for systemic use",
   code_33642300:"Thyroid therapy medicinal products",
   code_33650000:"General anti-infectives for systemic use, vaccines, antineoplastic and immunodulating agents",
   code_33651000:"General anti-infectives for systemic use and vaccines",
   code_33651100:"Antibacterials for systemic use",
   code_33651200:"Antimycotics for systemic use",
   code_33651300:"Antimycobacterials",
   code_33651400:"Antivirals for systemic use",
   code_33651500:"Immune sera and immunoglobulins",
   code_33651510:"Antisera",
   code_33651520:"Immunoglobulins",
   code_33651600:"Vaccines",
   code_33651610:"Diphtheria-pertussis-tetanus vaccines",
   code_33651620:"Diphtheria-tetanus vaccines",
   code_33651630:"BCG vaccines (dried)",
   code_33651640:"Measles-mumps-rubella vaccines",
   code_33651650:"Typhus vaccines",
   code_33651660:"Influenza vaccines",
   code_33651670:"Polio vaccines",
   code_33651680:"Hepatitis B vaccines",
   code_33651690:"Vaccines for veterinary medicine",
   code_33652000:"Antineoplastic and immunomodulating agents",
   code_33652100:"Antineoplastic agents",
   code_33652200:"Endocrine therapy medicinal products",
   code_33652300:"Immunosuppressive agents",
   code_33660000:"Medicinal products for the nervous system and sensory organs",
   code_33661000:"Medicinal products for the nervous system",
   code_33661100:"Anaesthetics",
   code_33661200:"Analgesics",
   code_33661300:"Antiepileptics",
   code_33661400:"Anti-Parkinson medicinal products",
   code_33661500:"Psycholeptics",
   code_33661600:"Psychoanaleptics",
   code_33661700:"Other nervous system medicinal products",
   code_33662000:"Medicinal products for sensory organs",
   code_33662100:"Ophthalmologicals",
   code_33670000:"Medicinal products for the respiratory system",
   code_33673000:"Medicinal products for obstructive airway diseases",
   code_33674000:"Cough and cold preparations",
   code_33675000:"Antihistamines for systemic use",
   code_33680000:"Pharmaceutical articles",
   code_33681000:"Teats, nipple shields and similar articles for babies",
   code_33682000:"Rubber tiles",
   code_33683000:"Rubber cushioning",
   code_33690000:"Various medicinal products",
   code_33691000:"Antiparasitic products, insecticides and repellents",
   code_33691100:"Antiprotozoals",
   code_33691200:"Anthelmintics",
   code_33691300:"Ectoparasiticides, incl. scabicides, insecticides and repellents",
   code_33692000:"Medical solutions",
   code_33692100:"Infusion solutions",
   code_33692200:"Parenteral nutrition products",
   code_33692210:"Parenteral feeding solutions",
   code_33692300:"Enteral feeds",
   code_33692400:"Perfusion solutions",
   code_33692500:"Injectable solutions",
   code_33692510:"Intravenous fluids",
   code_33692600:"Galenical solutions",
   code_33692700:"Glucose solutions",
   code_33692800:"Dialysis solutions",
   code_33693000:"Other therapeutic products",
   code_33693100:"Toxins",
   code_33693200:"Nicotine substitutes",
   code_33693300:"Addiction treatment",
   code_33694000:"Diagnostic agents",
   code_33695000:"All other non-therapeutic products",
   code_33696000:"Reagents and contrast media",
   code_33696100:"Blood-grouping reagents",
   code_33696200:"Blood-testing reagents",
   code_33696300:"Chemical reagents",
   code_33696400:"Isotopic reagents",
   code_33696500:"Laboratory reagents",
   code_33696600:"Reagents for electrophoresis",
   code_33696700:"Urological reagents",
   code_33696800:"X-ray contrast media",
   code_33697000:"Medical preparations excluding dental consumables",
   code_33697100:"Enema preparations",
   code_33697110:"Bone reconstruction cements",
   code_33698000:"Clinical products",
   code_33698100:"Microbiological cultures",
   code_33698200:"Glands and their extracts",
   code_33698300:"Peptic substances",
   code_33700000:"Personal care products",
   code_33710000:"Perfumes, toiletries and condoms",
   code_33711000:"Perfumes and toiletries",
   code_33711100:"Toilet waters",
   code_33711110:"Deodorants",
   code_33711120:"Antiperspirants",
   code_33711130:"Colognes",
   code_33711140:"Fragrances",
   code_33711150:"Rose water",
   code_33711200:"Make-up preparations",
   code_33711300:"Manicure or pedicure preparations",
   code_33711400:"Beauty products",
   code_33711410:"Cotton buds",
   code_33711420:"Makeup kits",
   code_33711430:"Disposable personal wipes",
   code_33711440:"Lip balm",
   code_33711450:"Tattoos",
   code_33711500:"Skin-care products",
   code_33711510:"Sun protection products",
   code_33711520:"Bath gels",
   code_33711530:"Shower caps",
   code_33711540:"Para-pharmaceutical creams or lotions",
   code_33711600:"Hair preparations and articles",
   code_33711610:"Shampoos",
   code_33711620:"Combs",
   code_33711630:"Wigs",
   code_33711640:"Vanity kits",
   code_33711700:"Articles and preparations for oral or dental hygiene",
   code_33711710:"Toothbrushes",
   code_33711720:"Toothpaste",
   code_33711730:"Toothpicks",
   code_33711740:"Mouthwash",
   code_33711750:"Mouth fresheners",
   code_33711760:"Dental floss",
   code_33711770:"Infant soother, pacifier and dummy",
   code_33711780:"Denture cleaning tablets",
   code_33711790:"Dental kits",
   code_33711800:"Shaving preparations",
   code_33711810:"Shaving creams",
   code_33711900:"Soap",
   code_33712000:"Condoms",
   code_33713000:"Foot care products",
   code_33720000:"Razors and manicure or pedicure sets",
   code_33721000:"Razors",
   code_33721100:"Razor blades",
   code_33721200:"Shavers",
   code_33722000:"Manicure or pedicure sets",
   code_33722100:"Manicure sets",
   code_33722110:"Manicure implements",
   code_33722200:"Pedicure sets",
   code_33722210:"Pedicure implements",
   code_33722300:"Barrettes",
   code_33730000:"Eye care products and corrective lenses",
   code_33731000:"Contact lenses",
   code_33731100:"Corrective lenses",
   code_33731110:"Intraocular lenses",
   code_33731120:"Spectacle lenses",
   code_33732000:"Contact lenses lotions",
   code_33733000:"Sunglasses",
   code_33734000:"Spectacles",
   code_33734100:"Frames and mountings for spectacles",
   code_33734200:"Glass for spectacles",
   code_33735000:"Goggles",
   code_33735100:"Protective goggles",
   code_33735200:"Frames and mountings for goggles",
   code_33740000:"Hand and nails care products",
   code_33741000:"Hand care products",
   code_33741100:"Hand cleaner",
   code_33741200:"Hand or body lotions",
   code_33741300:"Hand sanitizer",
   code_33742000:"Nail care products",
   code_33742100:"Nail clippers",
   code_33742200:"Nail polish",
   code_33750000:"Baby care products",
   code_33751000:"Disposable nappies",
   code_33752000:"Nursing pad",
   code_33760000:"Toilet paper, handkerchiefs, hand towels and serviettes",
   code_33761000:"Toilet paper",
   code_33762000:"Paper handkerchiefs",
   code_33763000:"Paper hand towels",
   code_33764000:"Paper serviettes",
   code_33770000:"Paper sanitary",
   code_33771000:"Sanitary paper products",
   code_33771100:"Sanitary towels or tampons",
   code_33771200:"Paper napkin liners",
   code_33772000:"Disposable paper products",
   code_33790000:"Laboratory, hygienic or pharmaceutical glassware",
   code_33791000:"Hygienic glassware",
   code_33792000:"Pharmaceutical glassware",
   code_33793000:"Laboratory glassware",
   code_33900000:"Post-mortem and mortuary equipment and supplies",
   code_33910000:"Pathology dissection instruments and supplies",
   code_33911000:"Autopsy scissors",
   code_33912000:"Autopsy dissection kits",
   code_33912100:"Autopsy dissection forceps",
   code_33913000:"Autopsy bullet probes",
   code_33914000:"Post-mortem thread, needles or incision clips",
   code_33914100:"Post-mortem thread",
   code_33914200:"Post-mortem needles",
   code_33914300:"Post-mortem incision clips",
   code_33915000:"Autopsy vein directors",
   code_33916000:"Autopsy saw blades or accessories",
   code_33916100:"Autopsy saws",
   code_33917000:"Dissection boards or pads",
   code_33918000:"Cases for post-mortem surgical instruments or accessories",
   code_33919000:"Instrument rolls for post-mortem surgical instruments or accessories",
   code_33920000:"Autopsy equipment and supplies",
   code_33921000:"Bone dust collectors",
   code_33922000:"Cadaver transport bags",
   code_33923000:"Autopsy head rests, body boards or hanging scales",
   code_33923100:"Autopsy head rests",
   code_33923200:"Autopsy body boards",
   code_33923300:"Autopsy hanging scales",
   code_33924000:"Autopsy infectious disease kits",
   code_33925000:"Post-mortem identification tags or bracelets",
   code_33926000:"Autopsy fluid collection vacuum aspirators or tubing",
   code_33927000:"Post-mortem rectal thermometers",
   code_33928000:"Post-mortem finger straighteners",
   code_33929000:"Cadaver tissue builder kits",
   code_33930000:"Autopsy furniture",
   code_33931000:"Autopsy grossing workstations or accessories",
   code_33932000:"Autopsy sinks or accessories",
   code_33933000:"Autopsy tables or accessories",
   code_33933100:"Autopsy tables",
   code_33934000:"Necropsy tables or accessories",
   code_33935000:"Post-mortem animal dissection tables or accessories",
   code_33936000:"Embalming workstations or accessories",
   code_33937000:"Autopsy downdraft workstations or accessories",
   code_33940000:"Cadaver transport and storage equipment and supplies",
   code_33941000:"Cadaver storage racks",
   code_33942000:"Cadaver carriers",
   code_33943000:"Cadaver scissor lift trolleys",
   code_33944000:"Morgue cabinet refrigerators or freezers",
   code_33945000:"Morgue walk in refrigerators",
   code_33946000:"Autopsy carts",
   code_33947000:"Cadaver trays",
   code_33948000:"Cadaver lifter or transfer devices",
   code_33949000:"Body transport containers",
   code_33950000:"Clinical forensics equipment and supplies",
   code_33951000:"Post-mortem fingerprint or impression materials",
   code_33952000:"Transport equipment and auxiliary products to transportation",
   code_33953000:"Post-mortem blood detection kits or supplies",
   code_33954000:"Biological evidence collection kits",
   code_33960000:"Embalming equipment and supplies",
   code_33961000:"Embalming cavity injectors",
   code_33962000:"Embalming vein drainage tubes",
   code_33963000:"Embalming fluids or chemical treatments",
   code_33964000:"Embalming injecting tubes",
   code_33965000:"Embalming sinks or accessories",
   code_33966000:"Embalming kits",
   code_33967000:"Embalming injector needles",
   code_33968000:"Eye caps",
   code_33970000:"Mortuary equipment and supplies",
   code_33971000:"Mortuary outfits",
   code_33972000:"Mortuary packs",
   code_33973000:"Mortuary wraps",
   code_33974000:"Mortuary aspirators",
   code_33975000:"Mortuary hardening compounds",
   code_34000000:"Transport equipment and auxiliary products to transportation",
   code_34100000:"Motor vehicles",
   code_34110000:"Passenger cars",
   code_34111000:"Estate and saloon cars",
   code_34111100:"Estate cars",
   code_34111200:"Saloon cars",
   code_34113000:"4-wheel-drive vehicles",
   code_34113100:"Jeeps",
   code_34113200:"All-terrain vehicles",
   code_34113300:"Off-road vehicles",
   code_34114000:"Specialist vehicles",
   code_34114100:"Emergency vehicles",
   code_34114110:"Rescue vehicles",
   code_34114120:"Paramedic vehicles",
   code_34114121:"Ambulances",
   code_34114122:"Patient-transport vehicles",
   code_34114200:"Police cars",
   code_34114210:"Prisoner-transport vehicles",
   code_34114300:"Welfare vehicles",
   code_34114400:"Minibuses",
   code_34115000:"Other passenger cars",
   code_34115200:"Motor vehicles for the transport of fewer than 10 persons",
   code_34115300:"Second-hand transport vehicles",
   code_34120000:"Motor vehicles for the transport of 10 or more persons",
   code_34121000:"Buses and coaches",
   code_34121100:"Public-service buses",
   code_34121200:"Articulated buses",
   code_34121300:"Double-decker buses",
   code_34121400:"Low-floor buses",
   code_34121500:"Coaches",
   code_34130000:"Motor vehicles for the transport of goods",
   code_34131000:"Pick-ups",
   code_34132000:"Motor sledges",
   code_34133000:"Articulated trucks",
   code_34133100:"Tankers",
   code_34133110:"Fuel-tanker trucks",
   code_34134000:"Flatbed and Tipper trucks",
   code_34134100:"Flatbed trucks",
   code_34134200:"Tipper trucks",
   code_34136000:"Vans",
   code_34136100:"Light vans",
   code_34136200:"Panel vans",
   code_34137000:"Second-hand goods vehicles",
   code_34138000:"Road tractor units",
   code_34139000:"Chassis",
   code_34139100:"Chassis cabs",
   code_34139200:"Chassis bodies",
   code_34139300:"Complete chassis",
   code_34140000:"Heavy-duty motor vehicles",
   code_34142000:"Crane and dumper trucks",
   code_34142100:"Elevator-platforms trucks",
   code_34142200:"Skip loaders",
   code_34142300:"Dumper trucks",
   code_34143000:"Winter-maintenance vehicles",
   code_34144000:"Special-purpose motor vehicles",
   code_34144100:"Mobile drilling derricks",
   code_34144200:"Vehicles for the emergency services",
   code_34144210:"Firefighting vehicles",
   code_34144211:"Turntable-ladder trucks",
   code_34144212:"Water-tender vehicles",
   code_34144213:"Fire engines",
   code_34144220:"Breakdown vehicles",
   code_34144300:"Mobile bridges",
   code_34144400:"Road-maintenance vehicles",
   code_34144410:"Gully emptiers",
   code_34144420:"Salt spreaders",
   code_34144430:"Road-sweeping vehicles",
   code_34144431:"Suction-sweeper vehicles",
   code_34144440:"Gritter vehicles",
   code_34144450:"Sprinkler vehicles",
   code_34144500:"Vehicles for refuse and sewage",
   code_34144510:"Vehicles for refuse",
   code_34144511:"Refuse-collection vehicles",
   code_34144512:"Refuse-compaction vehicles",
   code_34144520:"Sewage tankers",
   code_34144700:"Utility vehicles",
   code_34144710:"Wheeled loaders",
   code_34144730:"Aircraft-refuelling vehicles",
   code_34144740:"Aircraft-towing vehicles",
   code_34144750:"Cargo carriers",
   code_34144751:"Straddle carriers",
   code_34144760:"Mobile library vehicles",
   code_34144800:"Mobile homes",
   code_34144900:"Electric vehicles",
   code_34144910:"Electric buses",
   code_34150000:"Simulators",
   code_34151000:"Driving simulators",
   code_34152000:"Training simulators",
   code_34200000:"Vehicle bodies, trailers or semi-trailers",
   code_34210000:"Vehicle bodies",
   code_34211000:"Bus bodies, ambulance bodies and vehicle bodies for goods vehicles",
   code_34211100:"Bus bodies",
   code_34211200:"Ambulance bodies",
   code_34211300:"Vehicle bodies for goods vehicles",
   code_34220000:"Trailers, semi-trailers and mobile containers",
   code_34221000:"Special-purpose mobile containers",
   code_34221100:"Mobile incident units",
   code_34221200:"Mobile emergency units",
   code_34221300:"Chemical incident unit",
   code_34223000:"Trailers and semi-trailers",
   code_34223100:"Semi-trailers",
   code_34223200:"Bowsers",
   code_34223300:"Trailers",
   code_34223310:"General-purpose trailers",
   code_34223320:"Horsebox trailers",
   code_34223330:"Mobile units on trailers",
   code_34223340:"Tanker trailers",
   code_34223350:"Turntable-ladder trailers",
   code_34223360:"Refuelling trailers",
   code_34223370:"Tipper trailers",
   code_34223400:"Caravan-type trailers and semi-trailers",
   code_34224000:"Parts of trailers, semi-trailers and other vehicles",
   code_34224100:"Parts of trailers and semi-trailers",
   code_34224200:"Parts of other vehicles",
   code_34300000:"Parts and accessories for vehicles and their engines",
   code_34310000:"Engines and engine parts",
   code_34311000:"Engines",
   code_34311100:"Internal-combustion engines for motor vehicles and motorcycles",
   code_34311110:"Spark-ignition engines",
   code_34311120:"Compression-ignition engines",
   code_34312000:"Engine parts",
   code_34312100:"Fan belts",
   code_34312200:"Spark plugs",
   code_34312300:"Vehicle radiators",
   code_34312400:"Pistons",
   code_34312500:"Gaskets",
   code_34312600:"Rubber conveyor belts",
   code_34312700:"Rubber transmission belts",
   code_34320000:"Mechanical spare parts except engines and engine parts",
   code_34321000:"Axles and gearboxes",
   code_34321100:"Axles",
   code_34321200:"Gearboxes",
   code_34322000:"Brakes and brake parts",
   code_34322100:"Brake equipment",
   code_34322200:"Disc brakes",
   code_34322300:"Brake linings",
   code_34322400:"Brake pads",
   code_34322500:"Brake shoes",
   code_34324000:"Wheels, parts and accessories",
   code_34324100:"Wheel-balancing equipment",
   code_34325000:"Silencers and exhaust pipes",
   code_34325100:"Silencers",
   code_34325200:"Exhaust pipes",
   code_34326000:"Vehicle jacks, clutches and associated parts",
   code_34326100:"Clutches and associated parts",
   code_34326200:"Vehicle jacks",
   code_34327000:"Steering wheels, columns and boxes",
   code_34327100:"Steering wheels",
   code_34327200:"Columns and boxes",
   code_34328000:"Test benches, vehicle conversion kits and seat belts",
   code_34328100:"Test benches",
   code_34328200:"Vehicle conversion kits",
   code_34328300:"Seat belts",
   code_34330000:"Spare parts for goods vehicles, vans and cars",
   code_34350000:"Tyres for heavy/light-duty vehicles",
   code_34351000:"Light-duty tyres",
   code_34351100:"Tyres for motor cars",
   code_34352000:"Heavy-duty tyres",
   code_34352100:"Tyres for trucks",
   code_34352200:"Tyres for buses",
   code_34352300:"Agrarian tyres",
   code_34360000:"Seats for civil aircraft",
   code_34370000:"Seats for motor vehicles",
   code_34390000:"Tractor accessories",
   code_34400000:"Motorcycles, bicycles and sidecars",
   code_34410000:"Motorcycles",
   code_34411000:"Parts and accessories for motorcycles",
   code_34411100:"Motorcycle sidecars",
   code_34411110:"Parts and accessories for motorcycle sidecars",
   code_34411200:"Tyres for motorcycles",
   code_34420000:"Motor scooters and cycles with auxiliary motors",
   code_34421000:"Motor scooters",
   code_34422000:"Cycles with auxiliary motors",
   code_34430000:"Bicycles",
   code_34431000:"Non-motorised bicycles",
   code_34432000:"Parts and accessories for bicycles",
   code_34432100:"Tyres for bicycles",
   code_34500000:"Ships and boats",
   code_34510000:"Ships",
   code_34511100:"Marine patrol vessels",
   code_34512000:"Ships and similar vessels for the transport of persons or goods",
   code_34512100:"Ferry boats",
   code_34512200:"Cruise ships",
   code_34512300:"Bulk carriers",
   code_34512400:"Cargo ships",
   code_34512500:"Goods vessels",
   code_34512600:"Container carriers",
   code_34512700:"Ro-Ro vessels",
   code_34512800:"Tanker ships",
   code_34512900:"Car carriers",
   code_34512950:"Refrigerated vessels",
   code_34513000:"Fishing, emergency and other special vessels",
   code_34513100:"Fishing vessels",
   code_34513150:"Factory ships",
   code_34513200:"Tug boats",
   code_34513250:"Dredgers",
   code_34513300:"Seagoing floating docks",
   code_34513350:"Dive-support vessels",
   code_34513400:"Floating cranes",
   code_34513450:"Production vessels",
   code_34513500:"Seismic survey vessels",
   code_34513550:"Survey vessels",
   code_34513600:"Pollution-control vessels",
   code_34513650:"Fire vessels",
   code_34513700:"Rescue vessels",
   code_34513750:"Light vessels",
   code_34514000:"Floating or submersible drilling or production platforms",
   code_34514100:"Drillships",
   code_34514200:"Jack-up rigs",
   code_34514300:"Platforms drilling rigs",
   code_34514400:"Floating drilling platforms",
   code_34514500:"Floating production facility",
   code_34514600:"Semi-submersible rigs",
   code_34514700:"Mobile platforms",
   code_34514800:"Offshore platforms",
   code_34514900:"Drilling platforms",
   code_34515000:"Floating structures",
   code_34515100:"Marker buoys",
   code_34515200:"Inflatable rafts",
   code_34516000:"Marine fenders",
   code_34520000:"Boats",
   code_34521000:"Specialised boats",
   code_34521100:"Surveillance boats",
   code_34521200:"Customs patrol boats",
   code_34521300:"Police patrol boats",
   code_34521400:"Lifeboats",
   code_34522000:"Pleasure and sporting boats",
   code_34522100:"Sailing boats",
   code_34522150:"Catamaran sailing boats",
   code_34522200:"Rescue dinghies",
   code_34522250:"Sailing dinghies",
   code_34522300:"Small craft",
   code_34522350:"Fibreglass dinghies",
   code_34522400:"Semi-rigid dinghies",
   code_34522450:"Inflatable craft",
   code_34522500:"Rubber dinghies",
   code_34522550:"Canoes",
   code_34522600:"Rowing boats",
   code_34522700:"Pedal boats",
   code_34600000:"Railway and tramway locomotives and rolling stock and associated parts",
   code_34610000:"Rail locomotives and tenders",
   code_34611000:"Locomotives",
   code_34612000:"Locomotive tenders and cable cars",
   code_34612100:"Locomotive tenders",
   code_34612200:"Cable cars",
   code_34620000:"Rolling stock",
   code_34621000:"Railway maintenance or service vehicles, and railway freight wagons",
   code_34621100:"Railway freight wagons",
   code_34621200:"Railway maintenance or service vehicles",
   code_34622000:"Railway and tramway passenger coaches, and trolleybuses",
   code_34622100:"Tramway passenger coaches",
   code_34622200:"Railway passenger coaches",
   code_34622300:"Trolleybuses",
   code_34622400:"Railway carriages",
   code_34622500:"Luggage vans and special-purpose vans",
   code_34630000:"Parts of railway or tramway locomotives or rolling stock; railways traffic-control equipment",
   code_34631000:"Parts of locomotives or rolling stock",
   code_34631100:"Monobloc wheels",
   code_34631200:"Buffers and drawgear",
   code_34631300:"Rolling-stock seats",
   code_34631400:"Wheel axles and tyres and other parts of locomotives or rolling stock",
   code_34632000:"Railways traffic-control equipment",
   code_34632100:"Mechanical signalling",
   code_34632200:"Electrical signalling equipment for railways",
   code_34632300:"Electrical installations for railways",
   code_34640000:"Automotive elements",
   code_34700000:"Aircraft and spacecraft",
   code_34710000:"Helicopters, aeroplanes, spacecraft and other powered aircraft",
   code_34711000:"Helicopters and aeroplanes",
   code_34711100:"Aeroplanes",
   code_34711110:"Fixed-wing aircrafts",
   code_34711200:"Non-piloted aircraft",
   code_34711300:"Piloted aircraft",
   code_34711400:"Special-purpose aircraft",
   code_34711500:"Helicopters",
   code_34712000:"Spacecraft, satellites and launch vehicles",
   code_34712100:"Spacecraft",
   code_34712200:"Satellites",
   code_34712300:"Spacecraft launchers",
   code_34720000:"Balloons, dirigibles and other non-powered aircraft",
   code_34721000:"Gliders",
   code_34721100:"Hang-gliders",
   code_34722000:"Balloons and dirigibles",
   code_34722100:"Balloons",
   code_34722200:"Dirigibles",
   code_34730000:"Parts for aircraft, spacecraft and helicopters",
   code_34731000:"Parts for aircraft",
   code_34731100:"Aircraft engines",
   code_34731200:"Turbojets",
   code_34731300:"Turbopropellers",
   code_34731400:"Jet engines",
   code_34731500:"Parts for aircraft engines",
   code_34731600:"Parts for turbojets or turbopropellers",
   code_34731700:"Parts for helicopters",
   code_34731800:"Tyres for aircraft",
   code_34740000:"Aircraft or spacecraft equipment, trainers, simulators and associated parts",
   code_34741000:"Aircraft equipment",
   code_34741100:"Aircraft-launching gear",
   code_34741200:"Aircraft catapult systems",
   code_34741300:"Deck-arresting gear",
   code_34741400:"Flight simulators",
   code_34741500:"Ground flying trainer",
   code_34741600:"Air-sickness bags",
   code_34900000:"Miscellaneous transport equipment and spare parts",
   code_34910000:"Horse or hand-drawn carts, other non-mechanically-propelled vehicles, baggage carts and miscellaneous spare parts",
   code_34911000:"Horse or hand-drawn carts and other non-mechanically-propelled vehicles",
   code_34911100:"Trolleys",
   code_34912000:"Baggage carts",
   code_34912100:"Pushchairs",
   code_34913000:"Miscellaneous spare parts",
   code_34913100:"Used tyres",
   code_34913200:"Retreaded tyres",
   code_34913300:"Fenders",
   code_34913400:"Clocks for vehicles",
   code_34913500:"Vehicle locks",
   code_34913510:"Bicycle locks",
   code_34913600:"Ship propellers",
   code_34913700:"Firefighting vehicle ladders",
   code_34913800:"Anchors",
   code_34920000:"Road equipment",
   code_34921000:"Road-maintenance equipment",
   code_34921100:"Road sweepers",
   code_34921200:"Runway sweepers",
   code_34922000:"Road-marking equipment",
   code_34922100:"Road markings",
   code_34922110:"Glass beads for road marking",
   code_34923000:"Road traffic-control equipment",
   code_34924000:"Variable message signs",
   code_34926000:"Car park control equipment",
   code_34927000:"Toll equipment",
   code_34927100:"Road salt",
   code_34928000:"Road furniture",
   code_34928100:"Crash barriers",
   code_34928110:"Road barriers",
   code_34928120:"Barrier components",
   code_34928200:"Fences",
   code_34928210:"Wooden posts",
   code_34928220:"Fencing components",
   code_34928230:"Noise fence",
   code_34928300:"Safety barriers",
   code_34928310:"Safety fencing",
   code_34928320:"Guardrails",
   code_34928330:"Paravalanche devices",
   code_34928340:"Snow fences",
   code_34928400:"Urban furniture",
   code_34928410:"Marker posts",
   code_34928420:"Road-danger lamps",
   code_34928430:"Beacons",
   code_34928440:"Bus-stop posts",
   code_34928450:"Bollards",
   code_34928460:"Road cones",
   code_34928470:"Signage",
   code_34928471:"Sign materials",
   code_34928472:"Sign posts",
   code_34928480:"Waste and rubbish containers and bins",
   code_34928500:"Street-lighting equipment",
   code_34928510:"Street-lighting columns",
   code_34928520:"Lampposts",
   code_34928530:"Street lamps",
   code_34929000:"Highway materials",
   code_34930000:"Marine equipment",
   code_34931000:"Harbour equipment",
   code_34931100:"Docking installations",
   code_34931200:"Passenger boarding bridges for ships",
   code_34931300:"Passenger boarding stairs for ships",
   code_34931400:"Ship bridge simulators",
   code_34931500:"Vessel traffic control equipment",
   code_34932000:"Radar sets",
   code_34933000:"Navigation equipment",
   code_34934000:"Propeller blades",
   code_34940000:"Railway equipment",
   code_34941000:"Rails and accessories",
   code_34941100:"Rods",
   code_34941200:"Track rails",
   code_34941300:"Tramline",
   code_34941500:"Crossheads",
   code_34941600:"Crossovers",
   code_34941800:"Railway points",
   code_34942000:"Signalling equipment",
   code_34942100:"Signal posts",
   code_34942200:"Signalling boxes",
   code_34943000:"Train-monitoring system",
   code_34944000:"Points heating system",
   code_34945000:"Track-alignment machinery",
   code_34946000:"Railway-track construction materials and supplies",
   code_34946100:"Railway-track construction materials",
   code_34946110:"Rails",
   code_34946120:"Railway materials",
   code_34946121:"Fishplates and sole plates",
   code_34946122:"Check rails",
   code_34946200:"Railway-track construction supplies",
   code_34946210:"Current-conducting rails",
   code_34946220:"Switch blades, crossing frogs, point rods and crossing pieces",
   code_34946221:"Switch blades",
   code_34946222:"Crossing frogs",
   code_34946223:"Point rods",
   code_34946224:"Crossing pieces",
   code_34946230:"Rail clips, bedplates and ties",
   code_34946231:"Rail clips",
   code_34946232:"Bedplates and ties",
   code_34946240:"Chairs and chair wedges",
   code_34947000:"Sleepers and parts of sleepers",
   code_34947100:"Sleepers",
   code_34947200:"Parts of sleepers",
   code_34950000:"Loadbearing equipment",
   code_34951000:"Access platforms",
   code_34951200:"Sludge-handling equipment",
   code_34951300:"Sludge-drier installation",
   code_34952000:"Hydraulic-platforms hoists",
   code_34953000:"Access ramps",
   code_34953100:"Ferry ramps",
   code_34953300:"Passenger walkway",
   code_34954000:"Gantries",
   code_34955000:"Floating dock",
   code_34955100:"Floating storage unit",
   code_34960000:"Airport equipment",
   code_34961000:"Baggage-handling system",
   code_34961100:"Baggage-handling equipment",
   code_34962000:"Air-traffic control equipment",
   code_34962100:"Control tower equipment",
   code_34962200:"Air-traffic control",
   code_34962210:"Air-traffic control simulation",
   code_34962220:"Air-traffic control systems",
   code_34962230:"Air-traffic control training",
   code_34963000:"Instrument Landing System (ILS)",
   code_34964000:"Doppler VHF Omni direction Range (DVOR)",
   code_34965000:"Distance Measuring Equipment (DME)",
   code_34966000:"Radio Direction Finder and Non-Directional Beacon",
   code_34966100:"Radio Direction Finder (RDF)",
   code_34966200:"Non-Directional Beacon (NDB)",
   code_34967000:"Airport Communication System (COM)",
   code_34968000:"Airport Surveillance System and Lighting System",
   code_34968100:"Airport Surveillance System (SUR)",
   code_34968200:"Airport Lighting System (PAPI)",
   code_34969000:"Passenger boarding bridges and stairs for aircraft",
   code_34969100:"Passenger boarding bridges for aircraft",
   code_34969200:"Passenger boarding stairs for aircraft",
   code_34970000:"Traffic-monitoring equipment",
   code_34971000:"Speed camera equipment",
   code_34972000:"Traffic-flow measuring system",
   code_34980000:"Transport tickets",
   code_34990000:"Control, safety, signalling and light equipment",
   code_34991000:"Field operating lights",
   code_34992000:"Signs and illuminated signs",
   code_34992100:"Illuminated traffic signs",
   code_34992200:"Road signs",
   code_34992300:"Street signs",
   code_34993000:"Road lights",
   code_34993100:"Tunnel lighting",
   code_34994000:"Lighting for ship guidance and illumination",
   code_34994100:"Lighting for river guidance and illumination",
   code_34995000:"Lighting for aircraft guidance and illumination",
   code_34996000:"Control, safety or signalling equipment for roads",
   code_34996100:"Traffic lights",
   code_34996200:"Control, safety or signalling equipment for inland waterways",
   code_34996300:"Control, safety or signalling equipment for parking facilities",
   code_34997000:"Control, safety or signalling equipment for airports",
   code_34997100:"Flight recorders",
   code_34997200:"Airport lighting",
   code_34997210:"Runway lights",
   code_34998000:"Control, safety or signalling equipment for port installations",
   code_34999000:"Signal generators, aerial signal splitters and electroplating machines",
   code_34999100:"Signal generators",
   code_34999200:"Aerial signal splitters",
   code_34999300:"Electroplating machines",
   code_34999400:"Scale models",
   code_34999410:"Aircraft scale models",
   code_34999420:"Boat scale models",
   code_35000000:"Security, fire-fighting, police and defence equipment",
   code_35100000:"Emergency and security equipment",
   code_35110000:"Firefighting, rescue and safety equipment",
   code_35111000:"Firefighting equipment",
   code_35111100:"Breathing apparatus for firefighting",
   code_35111200:"Firefighting materials",
   code_35111300:"Fire extinguishers",
   code_35111310:"Foam packages",
   code_35111320:"Portable fire-extinguishers",
   code_35111400:"Fire escape equipment",
   code_35111500:"Fire suppression system",
   code_35111510:"Fire suppression hand tools",
   code_35111520:"Fire suppression foam or similar compounds",
   code_35112000:"Rescue and emergency equipment",
   code_35112100:"Emergency training dolls",
   code_35112200:"Emergency shower",
   code_35112300:"Eye shower",
   code_35113000:"Safety equipment",
   code_35113100:"Site-safety equipment",
   code_35113110:"Nuclear-reactor protection system",
   code_35113200:"Nuclear, biological, chemical and radiological protection equipment",
   code_35113210:"Nuclear safety equipment",
   code_35113300:"Safety installations",
   code_35113400:"Protective and safety clothing",
   code_35113410:"Garments for biological or chemical protection",
   code_35113420:"Nuclear and radiological protection clothing",
   code_35113430:"Safety vests",
   code_35113440:"Reflective vests",
   code_35113450:"Protective coats or ponchos",
   code_35113460:"Protective socks or hosiery",
   code_35113470:"Protective shirts or pants",
   code_35113480:"Protective wristbands",
   code_35113490:"Protective frock",
   code_35120000:"Surveillance and security systems and devices",
   code_35121000:"Security equipment",
   code_35121100:"Buzzers",
   code_35121200:"Detector for forged money",
   code_35121300:"Security fittings",
   code_35121400:"Security bags",
   code_35121500:"Seals",
   code_35121600:"Tags",
   code_35121700:"Alarm systems",
   code_35121800:"Convex security mirrors",
   code_35121900:"Radar detectors",
   code_35123000:"Site-identification equipment",
   code_35123100:"Magnetic-card system",
   code_35123200:"Flexible-working-hours equipment",
   code_35123300:"Timekeeping system",
   code_35123400:"Identification badges",
   code_35123500:"Video identification systems",
   code_35124000:"Metal detectors",
   code_35125000:"Surveillance system",
   code_35125100:"Sensors",
   code_35125110:"Biometric sensors",
   code_35125200:"Time control system or working time recorder",
   code_35125300:"Security cameras",
   code_35126000:"Bar code scanning equipment",
   code_35200000:"Police equipment",
   code_35210000:"Targets for shooting practice",
   code_35220000:"Anti-riot equipment",
   code_35221000:"Water cannons",
   code_35230000:"Handcuffs",
   code_35240000:"Sirens",
   code_35250000:"Repellents for canine attack",
   code_35260000:"Police signs",
   code_35261000:"Information panels",
   code_35261100:"Changing message indicator panels",
   code_35262000:"Crossing control signalling equipment",
   code_35300000:"Weapons, ammunition and associated parts",
   code_35310000:"Miscellaneous weapons",
   code_35311000:"Swords, cutlasses, bayonets and lances",
   code_35311100:"Swords",
   code_35311200:"Cutlasses",
   code_35311300:"Bayonets",
   code_35311400:"Lances",
   code_35312000:"Gas guns",
   code_35320000:"Firearms",
   code_35321000:"Light firearms",
   code_35321100:"Hand guns",
   code_35321200:"Rifles",
   code_35321300:"Machine guns",
   code_35322000:"Artillery",
   code_35322100:"Anti-aircraft",
   code_35322200:"Self-propelled artillery",
   code_35322300:"Towed artillery",
   code_35322400:"Mortars",
   code_35322500:"Howitzer",
   code_35330000:"Ammunition",
   code_35331000:"Ammunition for firearms and warfare",
   code_35331100:"Bullets",
   code_35331200:"Shells",
   code_35331300:"Grenades",
   code_35331400:"Land mines",
   code_35331500:"Cartridges",
   code_35332000:"Ammunition for naval warfare",
   code_35332100:"Torpedoes",
   code_35332200:"Sea mines",
   code_35333000:"Ammunition for aerial warfare",
   code_35333100:"Bombs",
   code_35333200:"Rockets",
   code_35340000:"Parts of firearms and ammunition",
   code_35341000:"Parts of light firearms",
   code_35341100:"Gunmetal pipe fittings",
   code_35342000:"Parts of rocket launchers",
   code_35343000:"Parts of mortars",
   code_35400000:"Military vehicles and associated parts",
   code_35410000:"Armoured military vehicles",
   code_35411000:"Battle tanks",
   code_35411100:"Main battle tanks",
   code_35411200:"Light battle tanks",
   code_35412000:"Armoured combat vehicles",
   code_35412100:"Infantry fighting vehicles",
   code_35412200:"Armoured personnel carriers",
   code_35412300:"Armoured weapon carriers",
   code_35412400:"Reconnaissance and patrol vehicles",
   code_35412500:"Command and liaison vehicles",
   code_35420000:"Parts of military vehicles",
   code_35421000:"Mechanical spare parts for military vehicles",
   code_35421100:"Engines and engine parts for military vehicles",
   code_35422000:"Electronic and electrical spare parts for military vehicles",
   code_35500000:"Warships and associated parts",
   code_35510000:"Warships",
   code_35511000:"Surface combatant",
   code_35511100:"Aircraft carriers",
   code_35511200:"Destroyers and frigates",
   code_35511300:"Corvettes and patrol boats",
   code_35511400:"Amphibious crafts and ships",
   code_35512000:"Submarines",
   code_35512100:"Strategic submarine nuclear fuelled",
   code_35512200:"Attack submarine nuclear fuelled",
   code_35512300:"Attack submarine diesel fuelled",
   code_35512400:"Unmanned underwater vehicles",
   code_35513000:"Mine warfare and auxiliary ships",
   code_35513100:"Mine hunter/minesweeper",
   code_35513200:"Auxiliary research vessel",
   code_35513300:"Auxiliary intelligence collection vessel",
   code_35513400:"Auxiliary hospital; cargo; tanker; ro-ro vessel",
   code_35520000:"Parts for warships",
   code_35521000:"Hull and mechanical spare parts for warships",
   code_35521100:"Engines and engine parts for warships",
   code_35522000:"Electronic and electrical spare parts for warships",
   code_35600000:"Military aircrafts, missiles and spacecrafts",
   code_35610000:"Military aircrafts",
   code_35611100:"Fighter aircrafts",
   code_35611200:"Fighter-bomber/ground attack aircrafts",
   code_35611300:"Bomber aircrafts",
   code_35611400:"Military transport aircrafts",
   code_35611500:"Training aircrafts",
   code_35611600:"Maritime patrol aircrafts",
   code_35611700:"Tanker aircrafts",
   code_35611800:"Reconnaissance aircrafts",
   code_35612100:"Combat helicopters",
   code_35612200:"Anti submarine warfare helicopters",
   code_35612300:"Support helicopters",
   code_35612400:"Military transport helicopters",
   code_35612500:"Search and rescue helicopters",
   code_35613000:"Unmanned aerial vehicles",
   code_35613100:"Unmanned combat aerial vehicles",
   code_35620000:"Missiles",
   code_35621000:"Strategic missiles",
   code_35621100:"Strategic anti-ballistic missiles",
   code_35621200:"Inter continental ballistic missiles",
   code_35621300:"Submarine launched ballistic missiles",
   code_35621400:"Intermediate range ballistic missiles",
   code_35622000:"Tactical missiles",
   code_35622100:"Air-to-air missiles",
   code_35622200:"Air-to-ground missiles",
   code_35622300:"Anti-ship missiles",
   code_35622400:"Anti-submarines rockets",
   code_35622500:"Tactical anti-ballistic missiles",
   code_35622600:"Anti-tank guided missiles",
   code_35622700:"Surface-to-air missiles",
   code_35623000:"Cruise missiles",
   code_35623100:"Air/ground/sea launched cruise missiles",
   code_35630000:"Military spacecrafts",
   code_35631000:"Military satellites",
   code_35631100:"Communication satellites",
   code_35631200:"Observation satellites",
   code_35631300:"Navigation satellites",
   code_35640000:"Parts for military aerospace equipment",
   code_35641000:"Structure and mechanical spare parts for military aerospace equipment",
   code_35641100:"Engines and engine parts for military aerospace equipment",
   code_35642000:"Electronic and electrical spare parts for military aerospace equipment",
   code_35700000:"Military electronic systems",
   code_35710000:"Command, control, communication and computer systems",
   code_35711000:"Command, control, communication systems",
   code_35712000:"Tactical command, control and communication systems",
   code_35720000:"Intelligence, surveillance, target acquisition and reconnaissance",
   code_35721000:"Electronic intelligence system",
   code_35722000:"Radar",
   code_35723000:"Air defence radar",
   code_35730000:"Electronic warfare systems and counter measures",
   code_35740000:"Battle simulators",
   code_35800000:"Individual and support equipment",
   code_35810000:"Individual equipment",
   code_35811100:"Fire-brigade uniforms",
   code_35811200:"Police uniforms",
   code_35811300:"Military uniforms",
   code_35812000:"Combat uniforms",
   code_35812100:"Camouflage jackets",
   code_35812200:"Combat suits",
   code_35812300:"Combat gear",
   code_35813000:"Military helmets",
   code_35813100:"Helmet covers",
   code_35814000:"Gas masks",
   code_35815000:"Garments for anti-ballistic protection",
   code_35815100:"Bullet-proof vests",
   code_35820000:"Support equipment",
   code_35821000:"Flags",
   code_35821100:"Flagpole",
   code_37000000:"Musical instruments, sport goods, games, toys, handicraft, art materials and accessories",
   code_37300000:"Musical instruments and parts",
   code_37310000:"Musical instruments",
   code_37311000:"Keyboard instruments",
   code_37311100:"Pianos",
   code_37311200:"Accordions",
   code_37311300:"Musical organs",
   code_37311400:"Celestas",
   code_37312000:"Brass instruments",
   code_37312100:"Trumpets",
   code_37312200:"Trombones",
   code_37312300:"Sousaphones",
   code_37312400:"Saxophones",
   code_37312500:"Whistle",
   code_37312600:"Bugles",
   code_37312700:"Saxhorns",
   code_37312800:"Mellophones",
   code_37312900:"Alto, baritone, flugel and French horns",
   code_37312910:"Alto horns",
   code_37312920:"Baritone horns",
   code_37312930:"Flugel horns",
   code_37312940:"French horns",
   code_37313000:"String instruments",
   code_37313100:"Harpsichords",
   code_37313200:"Clavichords",
   code_37313300:"Guitars",
   code_37313400:"Violins",
   code_37313500:"Harps",
   code_37313600:"Banjos",
   code_37313700:"Mandolins",
   code_37313800:"Violoncellos",
   code_37313900:"Basses",
   code_37314000:"Wind instruments",
   code_37314100:"Clarinets",
   code_37314200:"Oboes",
   code_37314300:"Musical cornets and flutes",
   code_37314310:"Musical cornets",
   code_37314320:"Musical flutes",
   code_37314400:"Piccolos",
   code_37314500:"Bagpipes",
   code_37314600:"Harmonicas",
   code_37314700:"Kazoos",
   code_37314800:"English horns",
   code_37314900:"Ocarinas",
   code_37315000:"Electrically amplified musical instruments",
   code_37315100:"Synthesisers",
   code_37316000:"Percussion instruments",
   code_37316100:"Cymbals",
   code_37316200:"Bells (instrument)",
   code_37316300:"Tambourines",
   code_37316400:"Castanets",
   code_37316500:"Drums (instrument)",
   code_37316600:"Xylophones",
   code_37316700:"Vibraphones",
   code_37320000:"Parts and accessories of musical instruments",
   code_37321000:"Accessories of musical instruments",
   code_37321100:"Metronomes",
   code_37321200:"Reeds",
   code_37321300:"Accessories for stringed instruments",
   code_37321400:"Instrument strings or picks",
   code_37321500:"Percussion instrument accessory",
   code_37321600:"Musical instrument pouches or cases or accessories",
   code_37321700:"Musical instrument stands or sheet holders",
   code_37322000:"Parts of musical instruments",
   code_37322100:"Tuning pins",
   code_37322200:"Music boxes or mechanisms",
   code_37322300:"Mouthpieces",
   code_37322400:"Mutes",
   code_37322500:"Tuning bars",
   code_37322600:"Conductors' batons",
   code_37322700:"Piccolo pads",
   code_37400000:"Sports goods and equipment",
   code_37410000:"Outdoor sports equipment",
   code_37411000:"Winter equipments",
   code_37411100:"Skiing and snowboarding equipment",
   code_37411110:"Ski boots",
   code_37411120:"Skis",
   code_37411130:"Ski poles",
   code_37411140:"Bindings",
   code_37411150:"Snowboards",
   code_37411160:"Skiing outfits",
   code_37411200:"Skating and ice hockey equipment",
   code_37411210:"Hockey pucks",
   code_37411220:"Ice skates",
   code_37411230:"Hockey sticks",
   code_37411300:"Arctic clothing and equipment",
   code_37412000:"Water-sports equipment",
   code_37412100:"Water skis",
   code_37412200:"Scuba and snorkelling gear",
   code_37412210:"Buoyancy compensators",
   code_37412220:"Scuba tanks",
   code_37412230:"Scuba regulators",
   code_37412240:"Diving instruments or accessories",
   code_37412241:"Breathing apparatus for diving",
   code_37412242:"Diving wear",
   code_37412243:"Immersion suits",
   code_37412250:"Masks, fins or snorkels",
   code_37412260:"Wetsuits",
   code_37412270:"Dry suits",
   code_37412300:"Surf and swim equipment and accessories",
   code_37412310:"Wakeboards, kneeboards or boogieboards",
   code_37412320:"Windsurfing equipment",
   code_37412330:"Surfboards",
   code_37412340:"Swim goggles or swim fins",
   code_37412350:"Parasailing equipment",
   code_37413000:"Articles for hunting or fishing",
   code_37413100:"Fishing tackle",
   code_37413110:"Fishing rods",
   code_37413120:"Fishing line",
   code_37413130:"Fishing reels",
   code_37413140:"Fishing lures",
   code_37413150:"Fishing bait",
   code_37413160:"Fishing weights or sinkers",
   code_37413200:"Hunting products",
   code_37413210:"Animal calls",
   code_37413220:"Sporting decoys",
   code_37413230:"Sporting traps",
   code_37413240:"Gun barrel",
   code_37414000:"Camping goods",
   code_37414100:"Sleeping pads",
   code_37414200:"Ice chests",
   code_37414300:"Tent repair kits",
   code_37414600:"Camping or outdoor stoves",
   code_37414700:"Drink coolers",
   code_37414800:"Survival suits",
   code_37415000:"Athletics equipment",
   code_37416000:"Leisure equipment",
   code_37420000:"Gymnasium equipment",
   code_37421000:"Gymnasium mats",
   code_37422000:"Gymnastic bars or beams",
   code_37422100:"Gymnastic bars",
   code_37422200:"Gymnastic beams",
   code_37423000:"Gymnastic ropes or rings or climbing accessories",
   code_37423100:"Gymnastic ropes",
   code_37423200:"Gymnastic rings",
   code_37423300:"Gymnastic climbing accessories",
   code_37424000:"Gymnastic vaulting equipment",
   code_37425000:"Gymnastic trampolines",
   code_37426000:"Balance equipment",
   code_37430000:"Boxing equipment",
   code_37431000:"Boxing rings",
   code_37432000:"Punching bags",
   code_37433000:"Boxing gloves",
   code_37440000:"Fitness equipments",
   code_37441000:"Aerobic training equipment",
   code_37441100:"Treadmills",
   code_37441200:"Stair climbers",
   code_37441300:"Stationary bicycles",
   code_37441400:"Rowing machines",
   code_37441500:"Jump ropes",
   code_37441600:"Exercise trampolines",
   code_37441700:"Exercise balls",
   code_37441800:"Step aerobic equipment",
   code_37441900:"Cross trainers",
   code_37442000:"Weight and resistance training equipment",
   code_37442100:"Dumbbells",
   code_37442200:"Barbells",
   code_37442300:"Lower and upper body resistance machines",
   code_37442310:"Lower body resistance machines",
   code_37442320:"Upper body resistance machines",
   code_37442400:"Weight benches or racks",
   code_37442500:"Fitness weights",
   code_37442600:"Pilates machines",
   code_37442700:"Grip strengtheners",
   code_37442800:"Resistance bands and tubes",
   code_37442810:"Resistance bands",
   code_37442820:"Resistance tubes",
   code_37442900:"Multi gyms",
   code_37450000:"Field and court sports equipment",
   code_37451000:"Field sports equipment",
   code_37451100:"Baseballs",
   code_37451110:"Baseball backstops or fences",
   code_37451120:"Baseball bases",
   code_37451130:"Baseball bats",
   code_37451140:"Baseball batting aids",
   code_37451150:"Baseball gloves",
   code_37451160:"Baseball or softball protective gear",
   code_37451200:"Field hockey equipment",
   code_37451210:"Field hockey balls",
   code_37451220:"Field hockey sticks",
   code_37451300:"Footballs",
   code_37451310:"Football blocking sleds",
   code_37451320:"Football kicking tees",
   code_37451330:"Football tackling dummies",
   code_37451340:"Flag football gear",
   code_37451400:"Lacrosse balls",
   code_37451500:"Lacrosse sticks",
   code_37451600:"Pitching machines",
   code_37451700:"Soccer balls",
   code_37451710:"Soccer field marking equipment",
   code_37451720:"Soccer protective equipment",
   code_37451730:"Soccer training aids",
   code_37451800:"Softballs",
   code_37451810:"Softball bats",
   code_37451820:"Softball gloves",
   code_37451900:"Handballs",
   code_37451920:"Team handball school sets",
   code_37452000:"Racquet and court sports equipment",
   code_37452100:"Badminton equipment",
   code_37452110:"Badminton birdies or shuttlecocks",
   code_37452120:"Badminton rackets",
   code_37452200:"Basketballs",
   code_37452210:"Basketball complete game systems",
   code_37452300:"Floor hockey protective equipment",
   code_37452400:"Racquetball balls, grips and strings",
   code_37452410:"Racquetball balls",
   code_37452420:"Racquetball grips",
   code_37452430:"Racquetball strings",
   code_37452500:"Racquetball rackets",
   code_37452600:"Squash equipment",
   code_37452610:"Squash balls",
   code_37452620:"Squash racquets",
   code_37452700:"Tennis equipment",
   code_37452710:"Tennis balls",
   code_37452720:"Tennis court equipment",
   code_37452730:"Tennis racquets",
   code_37452740:"Tennis training aids",
   code_37452800:"Tether balls and poles",
   code_37452810:"Tether balls",
   code_37452820:"Tether poles",
   code_37452900:"Volleyballs",
   code_37452910:"Volleyball gymnasium standards",
   code_37452920:"Volleyball storage for balls or nets",
   code_37453000:"Track sports equipment",
   code_37453100:"Javelins",
   code_37453200:"Jumping bars",
   code_37453300:"Discus",
   code_37453400:"Shotputs",
   code_37453500:"Vaulting poles",
   code_37453600:"Hurdles",
   code_37453700:"Batons",
   code_37460000:"Target and table games and equipments",
   code_37461000:"Table games and equipment",
   code_37461100:"Air hockey tables or accessories",
   code_37461200:"Foosballs",
   code_37461210:"Foosball replacement players",
   code_37461220:"Foosball tables",
   code_37461300:"Pool cues",
   code_37461400:"Shuffleboard equipment",
   code_37461500:"Tennis tables",
   code_37461510:"Table tennis balls",
   code_37461520:"Table tennis paddles",
   code_37462000:"Target games and equipment",
   code_37462100:"Archery equipments",
   code_37462110:"Archery arm guards",
   code_37462120:"Archery arrows",
   code_37462130:"Archery backstops",
   code_37462140:"Archery bow strings",
   code_37462150:"Archery bows",
   code_37462160:"Archery gloves",
   code_37462170:"Archery target stands",
   code_37462180:"Archery targets",
   code_37462200:"Darts",
   code_37462210:"Dart boards",
   code_37462300:"Throwing targets",
   code_37462400:"Trapshooting equipment",
   code_37470000:"Golf and bowling equipments",
   code_37471000:"Golf equipment",
   code_37471100:"Golf bags",
   code_37471200:"Golf balls",
   code_37471300:"Golf clubs",
   code_37471400:"Golf tees",
   code_37471500:"Golf club head covers",
   code_37471600:"Golf gloves",
   code_37471700:"Divot fixers",
   code_37471800:"Golfscopes",
   code_37471900:"Golf putting partner",
   code_37472000:"Bowling equipments",
   code_37480000:"Machinery or apparatus for leisure equipment",
   code_37481000:"Ice maintenance machines",
   code_37482000:"Sports information billboards",
   code_37500000:"Games and toys; fairground amusements",
   code_37510000:"Dolls",
   code_37511000:"Doll houses",
   code_37512000:"Doll parts or accessories",
   code_37513000:"Puppets",
   code_37513100:"Puppet theatres",
   code_37520000:"Toys",
   code_37521000:"Toy musical instruments",
   code_37522000:"Wheeled toys",
   code_37523000:"Puzzles",
   code_37524000:"Games",
   code_37524100:"Educational games",
   code_37524200:"Board games",
   code_37524300:"Classic games",
   code_37524400:"Collaborative games",
   code_37524500:"Strategy games",
   code_37524600:"Memory games",
   code_37524700:"Game accessories",
   code_37524800:"Lotto games",
   code_37524810:"Lottery formulary",
   code_37524900:"Game kits",
   code_37525000:"Toy balloons and balls",
   code_37526000:"Toy pails",
   code_37527000:"Toy trains and vehicles",
   code_37527100:"Toy trains",
   code_37527200:"Toy vehicles",
   code_37528000:"Toy weapons",
   code_37529000:"Inflatable and riding toys",
   code_37529100:"Inflatable toys",
   code_37529200:"Riding toys",
   code_37530000:"Articles for funfair, table or parlour games",
   code_37531000:"Playing cards",
   code_37532000:"Video games",
   code_37533000:"Billiards",
   code_37533100:"Billiard balls",
   code_37533200:"Billiard chalk",
   code_37533300:"Billiard cue tips",
   code_37533400:"Billiard racks",
   code_37533500:"Billiard tables",
   code_37534000:"Coin- or disc-operated games",
   code_37535000:"Roundabouts, swings, shooting galleries and other fairground amusements",
   code_37535100:"Swings",
   code_37535200:"Playground equipment",
   code_37535210:"Playground swings",
   code_37535220:"Playground climbing apparatus",
   code_37535230:"Playground merry go rounds",
   code_37535240:"Playground slides",
   code_37535250:"Playground see saws",
   code_37535260:"Playground tunnels",
   code_37535270:"Playground sandboxes",
   code_37535280:"Playground bleachers",
   code_37535290:"Wall and rope climbing equipment",
   code_37535291:"Wall climbing equipment",
   code_37535292:"Rope climbing equipment",
   code_37540000:"Gambling machines",
   code_37800000:"Handicraft and art supplies",
   code_37810000:"Handicraft supplies",
   code_37820000:"Art supplies",
   code_37821000:"Artists' brushes",
   code_37822000:"Drawing pens",
   code_37822100:"Crayons",
   code_37822200:"Drawing charcoal",
   code_37822300:"Chalks",
   code_37822400:"Pastels",
   code_37823000:"Greaseproof paper and other paper items",
   code_37823100:"Greaseproof paper",
   code_37823200:"Tracing paper",
   code_37823300:"Glassine paper",
   code_37823400:"Transparent or translucent paper",
   code_37823500:"Art and craft paper",
   code_37823600:"Drawing paper",
   code_37823700:"Paper for maps",
   code_37823800:"Multi-ply paper and paperboard",
   code_37823900:"Kraftliner",
   code_38000000:"Laboratory, optical and precision equipments (excl. glasses)",
   code_38100000:"Navigational and meteorological instruments",
   code_38110000:"Navigational instruments",
   code_38111000:"Direction-finding equipment",
   code_38111100:"Compasses",
   code_38111110:"Compass accessories",
   code_38112000:"Sextants",
   code_38112100:"Global navigation and positioning systems (GPS or equivalent)",
   code_38113000:"Sonars",
   code_38114000:"Echo sounders",
   code_38115000:"Radar apparatus",
   code_38115100:"Radar surveillance equipment",
   code_38120000:"Meteorological instruments",
   code_38121000:"Anemometers",
   code_38122000:"Barometers",
   code_38123000:"Precipitation or evaporation recorders",
   code_38124000:"Radiosonde apparatus",
   code_38125000:"Rainfall recorders",
   code_38126000:"Surface observing apparatus",
   code_38126100:"Precipitation or evaporation surface observing apparatus",
   code_38126200:"Solar radiation surface observing apparatus",
   code_38126300:"Temperature or humidity surface observing apparatus",
   code_38126400:"Wind surface observing apparatus",
   code_38127000:"Weather stations",
   code_38128000:"Meteorology instrument accessories",
   code_38200000:"Geological and geophysical instruments",
   code_38210000:"Geological compasses",
   code_38220000:"Geological prospecting apparatus",
   code_38221000:"Geographic information systems (GIS or equivalent)",
   code_38230000:"Electromagnetic geophysical instruments",
   code_38240000:"Gravity geophysical instruments",
   code_38250000:"Induced polarization IP geophysical instruments",
   code_38260000:"Magnetometer geophysical instruments",
   code_38270000:"Resistivity geophysical instruments",
   code_38280000:"Gravimeters",
   code_38290000:"Surveying, hydrographic, oceanographic and hydrological instruments and appliances",
   code_38291000:"Telemetry apparatus",
   code_38292000:"Hydrographic instruments",
   code_38293000:"Seismic equipment",
   code_38294000:"Theodolites",
   code_38295000:"Topography equipment",
   code_38296000:"Surveying instruments",
   code_38300000:"Measuring instruments",
   code_38310000:"Precision balances",
   code_38311000:"Electronic scales and accessories",
   code_38311100:"Electronic analytical balances",
   code_38311200:"Electronic technical balances",
   code_38311210:"Calibration weights",
   code_38320000:"Drafting tables",
   code_38321000:"Drafting machines",
   code_38322000:"Pantographs",
   code_38323000:"Slide rules",
   code_38330000:"Hand-held instruments for measuring length",
   code_38331000:"Squares",
   code_38340000:"Instruments for measuring quantities",
   code_38341000:"Apparatus for measuring radiation",
   code_38341100:"Electron-beam recorders",
   code_38341200:"Radiation dosimeters",
   code_38341300:"Instruments for measuring electrical quantities",
   code_38341310:"Ammeters",
   code_38341320:"Voltmeters",
   code_38341400:"Geiger counters",
   code_38341500:"Contamination-monitoring devices",
   code_38341600:"Radiation monitors",
   code_38342000:"Oscilloscopes",
   code_38342100:"Oscillographs",
   code_38343000:"Error-monitoring equipment",
   code_38344000:"Pollution-monitoring devices",
   code_38400000:"Instruments for checking physical characteristics",
   code_38410000:"Metering instruments",
   code_38411000:"Hydrometers",
   code_38412000:"Thermometers",
   code_38413000:"Pyrometers",
   code_38414000:"Hygrometers",
   code_38415000:"Psychrometers",
   code_38416000:"PH meters",
   code_38417000:"Thermocouples",
   code_38418000:"Calorimeters",
   code_38420000:"Instruments for measuring flow, level and pressure of liquids and gases",
   code_38421000:"Flow-measuring equipment",
   code_38421100:"Water meters",
   code_38421110:"Flowmeters",
   code_38422000:"Level-measuring equipment",
   code_38423000:"Pressure-measuring equipment",
   code_38423100:"Pressure gauges",
   code_38424000:"Measuring and control equipment",
   code_38425000:"Fluid mechanics equipment",
   code_38425100:"Manometers",
   code_38425200:"Viscosimeters",
   code_38425300:"Depth indicators",
   code_38425400:"Structure estimation apparatus",
   code_38425500:"Strength estimation apparatus",
   code_38425600:"Pycnometers",
   code_38425700:"Surface tension measuring instruments",
   code_38425800:"Densitometers",
   code_38426000:"Coulometers",
   code_38427000:"Fluxmeters",
   code_38428000:"Rheometers",
   code_38429000:"Rotameters",
   code_38430000:"Detection and analysis apparatus",
   code_38431000:"Detection apparatus",
   code_38431100:"Gas-detection apparatus",
   code_38431200:"Smoke-detection apparatus",
   code_38431300:"Fault detectors",
   code_38432000:"Analysis apparatus",
   code_38432100:"Gas-analysis apparatus",
   code_38432200:"Chromatographs",
   code_38432210:"Gas chromatographs",
   code_38432300:"Smoke-analysis apparatus",
   code_38433000:"Spectrometers",
   code_38433100:"Mass spectrometer",
   code_38433200:"Emission measurement equipment",
   code_38433210:"Emission spectrometer",
   code_38433300:"Spectrum analyser",
   code_38434000:"Analysers",
   code_38434100:"Expansion analysers",
   code_38434200:"Sound-measuring equipment",
   code_38434210:"Sonometers",
   code_38434220:"Sound velocity analyzers",
   code_38434300:"Noise-measuring equipment",
   code_38434310:"Decibel meter",
   code_38434400:"Vibration analysers",
   code_38434500:"Biochemical analysers",
   code_38434510:"Cytometers",
   code_38434520:"Blood analysers",
   code_38434530:"Milk analysers",
   code_38434540:"Biomedical equipment",
   code_38434550:"Blood-cell counters",
   code_38434560:"Chemistry analyser",
   code_38434570:"Haematology analysers",
   code_38434580:"Immunoassay analysers",
   code_38435000:"Apparatus for detecting fluids",
   code_38436000:"Shakers and accessories",
   code_38436100:"Mechanical shakers",
   code_38436110:"Erlenmeyer flask basket for shakers",
   code_38436120:"Erlenmeyer flask clamps for shakers",
   code_38436130:"Stands for separating funnels",
   code_38436140:"Platform for Erlenmeyer flask clamps for shakers",
   code_38436150:"Petri dish stand for shakers",
   code_38436160:"Test tube stand for shakers",
   code_38436170:"Flask adaptor for shakers",
   code_38436200:"Rotating evaporators",
   code_38436210:"Protective shield for rotating evaporators",
   code_38436220:"Boiling temperature sensor for rotating evaporators",
   code_38436230:"Pressure regulator for rotating evaporators",
   code_38436300:"Incubating shakers",
   code_38436310:"Heating plates",
   code_38436320:"Heating plates for flasks",
   code_38436400:"Magnetic shakers",
   code_38436410:"Thermal regulators for mechanical shakers with heating plates",
   code_38436500:"Mechanical stirrers",
   code_38436510:"Blade impellers for mechanical stirrers",
   code_38436600:"Immersion homogenisers",
   code_38436610:"Dispersing instruments for immersion homogenisers",
   code_38436700:"Ultrasound disintegrators",
   code_38436710:"Probes for ultrasound disintegrators",
   code_38436720:"Converters for ultrasound disintegrators",
   code_38436730:"Continuous-flow chambers for ultrasound disintegrators",
   code_38436800:"Rotating blade homogenizers",
   code_38437000:"Laboratory pipettes and accessories",
   code_38437100:"Pipettes",
   code_38437110:"Pipette tips",
   code_38437120:"Pipette stands",
   code_38500000:"Checking and testing apparatus",
   code_38510000:"Microscopes",
   code_38511000:"Electron microscopes",
   code_38511100:"Scanning electron microscopes",
   code_38511200:"Transmission electron microscope",
   code_38512000:"Ion and molecular microscopes",
   code_38512100:"Ion microscopes",
   code_38512200:"Molecular microscopes",
   code_38513000:"Inverted and metallurgical microscopes",
   code_38513100:"Inverted microscopes",
   code_38513200:"Metallurgical microscopes",
   code_38514000:"Darkfield and scanning probe microscopes",
   code_38514100:"Darkfield microscopes",
   code_38514200:"Scanning probe microscopes",
   code_38515000:"Fluorescent and polarising microscopes",
   code_38515100:"Polarising microscopes",
   code_38515200:"Fluorescent microscopes",
   code_38516000:"Monocular and/or binocular light compound microscopes",
   code_38517000:"Acoustic and projection microscopes",
   code_38517100:"Acoustic microscopes",
   code_38517200:"Projection microscopes",
   code_38518000:"Wide field, stereo or dissecting light microscopes",
   code_38518100:"Wide field microscopes",
   code_38518200:"Stereo or dissecting light microscopes",
   code_38519000:"Miscellaneous compounds for microscopes",
   code_38519100:"Illuminators for microscopes",
   code_38519200:"Microscope objectives",
   code_38519300:"Photo or video attachments for microscopes",
   code_38519310:"Photo attachments for microscopes",
   code_38519320:"Video attachments for microscopes",
   code_38519400:"Automated microscope stages",
   code_38519500:"Laboratory microscope replacement bulbs",
   code_38519600:"Microscope eyepieces, condensers, collectors, tubes, stages and covers",
   code_38519610:"Microscope eyepieces",
   code_38519620:"Microscope condensers",
   code_38519630:"Microscope collectors",
   code_38519640:"Microscope tubes",
   code_38519650:"Microscope stages",
   code_38519660:"Microscope covers",
   code_38520000:"Scanners",
   code_38521000:"Pressure scanners",
   code_38522000:"Chromatographic scanners",
   code_38527100:"Ionization chamber dosimeters",
   code_38527200:"Dosimeters",
   code_38527300:"Secondary standard dosimetry systems",
   code_38527400:"Phantom dosimeters",
   code_38530000:"Diffraction apparatus",
   code_38540000:"Machines and apparatus for testing and measuring",
   code_38541000:"Solderability testers",
   code_38542000:"Servo-hydraulic test apparatus",
   code_38543000:"Gas-detection equipment",
   code_38544000:"Drug detection apparatus",
   code_38545000:"Gas-testing kits",
   code_38546000:"Explosives detection system",
   code_38546100:"Bomb detectors",
   code_38547000:"Dosimetry system",
   code_38548000:"Instruments for vehicles",
   code_38550000:"Meters",
   code_38551000:"Energy meters",
   code_38552000:"Electronic meters",
   code_38553000:"Magnetic meters",
   code_38554000:"Electricity meters",
   code_38560000:"Production counters",
   code_38561000:"Revolution counters",
   code_38561100:"Speed indicators for vehicles",
   code_38561110:"Tachometers",
   code_38561120:"Taxi meters",
   code_38562000:"Stroboscopes",
   code_38570000:"Regulating and controlling instruments and apparatus",
   code_38571000:"Speed limiters",
   code_38580000:"Non-medical equipment based on the use of radiations",
   code_38581000:"Baggage-scanning equipment",
   code_38582000:"X-ray inspection equipment",
   code_38600000:"Optical instruments",
   code_38620000:"Polarising material",
   code_38621000:"Fibre-optic apparatus",
   code_38622000:"Mirrors",
   code_38623000:"Optical filters",
   code_38624000:"Optical aids",
   code_38630000:"Astronomical and optical instruments",
   code_38631000:"Binoculars",
   code_38632000:"Nightglasses",
   code_38633000:"Telescopic sights",
   code_38634000:"Optical microscopes",
   code_38635000:"Telescopes",
   code_38636000:"Specialist optical instruments",
   code_38636100:"Lasers",
   code_38636110:"Industrial lasers",
   code_38640000:"Liquid crystal devices",
   code_38641000:"Periscopes",
   code_38650000:"Photographic equipment",
   code_38651000:"Cameras",
   code_38651100:"Camera lenses",
   code_38651200:"Camera bodies",
   code_38651300:"Cameras for preparing printing plates or cylinders",
   code_38651400:"Instant print cameras",
   code_38651500:"Cinematographic cameras",
   code_38651600:"Digital cameras",
   code_38652000:"Cinematographic projectors",
   code_38652100:"Projectors",
   code_38652110:"Slide projectors",
   code_38652120:"Video projectors",
   code_38652200:"Enlargers",
   code_38652300:"Reducers",
   code_38653000:"Apparatus for photographic laboratories",
   code_38653100:"Flashlights",
   code_38653110:"Photographic flashbulbs",
   code_38653111:"Photographic flashcubes",
   code_38653200:"Photographic enlargers",
   code_38653300:"Apparatus and equipment for developing film",
   code_38653400:"Projection screens",
   code_38654000:"Microfilm and microfiche equipment",
   code_38654100:"Microfilm equipment",
   code_38654110:"Microfilm readers",
   code_38654200:"Microfiche equipment",
   code_38654210:"Microfiche readers",
   code_38654300:"Microform equipment",
   code_38654310:"Microform readers",
   code_38700000:"Time registers and the like; parking meters",
   code_38710000:"Time registers",
   code_38720000:"Time recorders",
   code_38730000:"Parking meters",
   code_38731000:"Token meters",
   code_38740000:"Process timers",
   code_38750000:"Time switches",
   code_38800000:"Industrial process control equipment and remote-control equipment",
   code_38810000:"Industrial process control equipment",
   code_38820000:"Remote-control equipment",
   code_38821000:"Radio remote-control apparatus",
   code_38822000:"Remote-control siren devices",
   code_38900000:"Miscellaneous evaluation or testing instruments",
   code_38910000:"Hygiene monitoring and testing equipment",
   code_38911000:"Manual swab test kits",
   code_38912000:"Automated swab test kits",
   code_38920000:"Seed and feed equipment",
   code_38921000:"Grain analysers",
   code_38922000:"Seed counters",
   code_38923000:"Feed analysers",
   code_38930000:"Humidity and moisture measuring instruments",
   code_38931000:"Temperature humidity testers",
   code_38932000:"Moisture meters",
   code_38940000:"Nuclear evaluation instruments",
   code_38941000:"Alpha counters",
   code_38942000:"Alpha beta counters",
   code_38943000:"Beta counters",
   code_38944000:"Beta gamma counters",
   code_38945000:"Gamma counters",
   code_38946000:"KVP meters",
   code_38947000:"X-ray microanalysers",
   code_38950000:"Polymerase Chain Reaction (PCR) equipment",
   code_38951000:"Real-time Polymerase Chain Reaction (PCR) equipment",
   code_38960000:"Alcohol ignition lock",
   code_38970000:"Research, testing and scientific technical simulator",
   code_39000000:"Furniture (incl. office furniture), furnishings, domestic appliances (excl. lighting) and cleaning products",
   code_39100000:"Furniture",
   code_39110000:"Seats, chairs and related products, and associated parts",
   code_39111000:"Seats",
   code_39111100:"Swivel seats",
   code_39111200:"Theatre seats",
   code_39111300:"Ejector seats",
   code_39112000:"Chairs",
   code_39112100:"Dining chairs",
   code_39113000:"Miscellaneous seats and chairs",
   code_39113100:"Armchairs",
   code_39113200:"Settees",
   code_39113300:"Bench seats",
   code_39113400:"Deck chairs",
   code_39113500:"Stools",
   code_39113600:"Benches",
   code_39113700:"Footrests",
   code_39114000:"Parts of seats",
   code_39114100:"Upholstering",
   code_39120000:"Tables, cupboards, desk and bookcases",
   code_39121000:"Desks and tables",
   code_39121100:"Desks",
   code_39121200:"Tables",
   code_39122000:"Cupboards and bookcases",
   code_39122100:"Cupboards",
   code_39122200:"Bookcases",
   code_39130000:"Office furniture",
   code_39131000:"Office shelving",
   code_39131100:"Archive shelving",
   code_39132000:"Filing systems",
   code_39132100:"Filing cabinets",
   code_39132200:"Card-index cabinets",
   code_39132300:"Hanging files",
   code_39132400:"Carousel systems",
   code_39132500:"Office trolleys",
   code_39133000:"Display units",
   code_39134000:"Computer furniture",
   code_39134100:"Computer tables",
   code_39135000:"Sorting tables",
   code_39135100:"Sorting frames",
   code_39136000:"Coat hangers",
   code_39137000:"Water softeners",
   code_39140000:"Domestic furniture",
   code_39141000:"Kitchen furniture and equipment",
   code_39141100:"Shelves",
   code_39141200:"Worktops",
   code_39141300:"Cabinets",
   code_39141400:"Fitted kitchens",
   code_39141500:"Fume cupboards",
   code_39142000:"Garden furniture",
   code_39143000:"Bedroom, dining room and living-room furniture",
   code_39143100:"Bedroom furniture",
   code_39143110:"Beds and bedding and specialist soft furnishings",
   code_39143111:"Mattress supports",
   code_39143112:"Mattresses",
   code_39143113:"Specialist soft furnishings",
   code_39143114:"Electric blankets",
   code_39143115:"Rubber sheets",
   code_39143116:"Cots",
   code_39143120:"Bedroom furniture, other than beds and beddings",
   code_39143121:"Wardrobes",
   code_39143122:"Chests of drawers",
   code_39143123:"Bedside tables",
   code_39143200:"Dining-room furniture",
   code_39143210:"Dining tables",
   code_39143300:"Living-room furniture",
   code_39143310:"Coffee tables",
   code_39144000:"Bathroom furniture",
   code_39145000:"Wine cellar fixtures",
   code_39150000:"Miscellaneous furniture and equipment",
   code_39151000:"Miscellaneous furniture",
   code_39151100:"Racking",
   code_39151200:"Work benches",
   code_39151300:"Modular furniture",
   code_39152000:"Mobile bookshelves",
   code_39153000:"Conference-room furniture",
   code_39153100:"Bookstands",
   code_39154000:"Exhibition equipment",
   code_39154100:"Exhibition stands",
   code_39155000:"Library furniture",
   code_39155100:"Library equipment",
   code_39156000:"Lounge and reception-area furniture",
   code_39157000:"Parts of furniture",
   code_39160000:"School furniture",
   code_39161000:"Kindergarten furniture",
   code_39162000:"Educational equipment",
   code_39162100:"Teaching equipment",
   code_39162110:"Teaching supplies",
   code_39162200:"Training aids and devices",
   code_39170000:"Shop furniture",
   code_39171000:"Display cases",
   code_39172000:"Counters",
   code_39172100:"Servery counters",
   code_39173000:"Storage units",
   code_39174000:"Shop signs",
   code_39180000:"Laboratory furniture",
   code_39181000:"Laboratory benching",
   code_39190000:"Wallpaper and other coverings",
   code_39191000:"Paper- or paperboard-based wall coverings",
   code_39191100:"Wallpaper",
   code_39192000:"Textile wall coverings",
   code_39193000:"Paper- or paperboard-based floor coverings",
   code_39200000:"Furnishing",
   code_39220000:"Kitchen equipment, household and domestic items and catering supplies",
   code_39221000:"Kitchen equipment",
   code_39221100:"Kitchenware",
   code_39221110:"Crockery",
   code_39221120:"Cups and glasses",
   code_39221121:"Cups",
   code_39221122:"Cuplets",
   code_39221123:"Drinking glasses",
   code_39221130:"Food containers",
   code_39221140:"Water canteens",
   code_39221150:"Vacuum flasks",
   code_39221160:"Trays",
   code_39221170:"Drying racks",
   code_39221180:"Cooking utensils",
   code_39221190:"Plate racks",
   code_39221200:"Tableware",
   code_39221210:"Plates",
   code_39221220:"Dishes",
   code_39221230:"Saucers",
   code_39221240:"Bowls",
   code_39221250:"Decanters",
   code_39221260:"Mess tins",
   code_39222000:"Catering supplies",
   code_39222100:"Disposable catering supplies",
   code_39222110:"Disposable cutlery and plates",
   code_39222120:"Disposable cups",
   code_39222200:"Food trays",
   code_39223000:"Spoons, forks",
   code_39223100:"Spoons",
   code_39223200:"Forks",
   code_39224000:"Brooms and brushes and other articles of various types",
   code_39224100:"Brooms",
   code_39224200:"Brushes",
   code_39224210:"Painters' brushes",
   code_39224300:"Brooms and brushes and other articles for household cleaning",
   code_39224310:"Toilet brushes",
   code_39224320:"Sponges",
   code_39224330:"Buckets",
   code_39224340:"Bins",
   code_39224350:"Dustpans",
   code_39225000:"Lighters, articles of combustible materials, pyrotechnics, matches and liquid or liquefied gas fuels",
   code_39225100:"Cigarette lighters",
   code_39225200:"Pyrophoric alloys",
   code_39225300:"Matches",
   code_39225400:"Gas fuels for lighters",
   code_39225500:"Pyrotechnics",
   code_39225600:"Candles",
   code_39225700:"Bottles, jars and phials",
   code_39225710:"Bottles",
   code_39225720:"Jars",
   code_39225730:"Phials",
   code_39226000:"Carboys, bottle cases, flasks and bobbins",
   code_39226100:"Bottle cases",
   code_39226200:"Carboys and flasks",
   code_39226210:"Carboys",
   code_39226220:"Flasks",
   code_39226300:"Spools or bobbins",
   code_39227000:"Sewing and knitting needles, and thimbles",
   code_39227100:"Sewing needles or knitting needles",
   code_39227110:"Sewing needles",
   code_39227120:"Knitting needles",
   code_39227200:"Thimbles",
   code_39230000:"Special-purpose product",
   code_39234000:"Compost boxes",
   code_39235000:"Tokens",
   code_39236000:"Spray booths",
   code_39237000:"Snow poles",
   code_39240000:"Cutlery",
   code_39241000:"Knives and scissors",
   code_39241100:"Knives",
   code_39241110:"Table knives",
   code_39241120:"Cooks' knives",
   code_39241130:"Utility knives",
   code_39241200:"Scissors",
   code_39254000:"Horology",
   code_39254100:"Clocks",
   code_39254110:"Alarm clocks",
   code_39254120:"Wall clocks",
   code_39254130:"Glass for clocks",
   code_39260000:"Delivery trays and desk equipment",
   code_39261000:"Delivery trays",
   code_39263000:"Desk equipment",
   code_39263100:"Desk sets",
   code_39264000:"Fittings for loose-leaf binders or files",
   code_39265000:"Hooks and eyes",
   code_39270000:"Religious articles",
   code_39290000:"Miscellaneous furnishing",
   code_39291000:"Laundry supplies",
   code_39292000:"School slates or boards with writing or drawing surfaces or instruments",
   code_39292100:"Blackboards",
   code_39292110:"Erasers for blackboards",
   code_39292200:"Writing slates",
   code_39292300:"Drawing instruments",
   code_39292400:"Writing instruments",
   code_39292500:"Rulers",
   code_39293000:"Artificial products",
   code_39293100:"Artificial fruit",
   code_39293200:"Artificial flowers",
   code_39293300:"Artificial grass",
   code_39293400:"Artificial lawn",
   code_39293500:"Imitation jewellery",
   code_39294000:"Apparatus and equipment designed for demonstrational purposes",
   code_39294100:"Information and promotion products",
   code_39295000:"Umbrellas and sunshades; walking sticks and seat sticks",
   code_39295100:"Sunshades",
   code_39295200:"Umbrellas",
   code_39295300:"Seat sticks",
   code_39295400:"Walking sticks",
   code_39295500:"Parts, trimmings and accessories of umbrellas, sunshades, walking sticks and similar articles",
   code_39296000:"Funeral supplies",
   code_39296100:"Coffins",
   code_39297000:"Barracks supplies",
   code_39298000:"Statuettes, ornaments; photograph or picture frames, and mirrors",
   code_39298100:"Photograph frames",
   code_39298200:"Picture frames",
   code_39298300:"Flower bowls",
   code_39298400:"Statuettes",
   code_39298500:"Ornaments",
   code_39298600:"Globes",
   code_39298700:"Trophies",
   code_39298800:"Aquariums",
   code_39298900:"Miscellaneous decoration items",
   code_39298910:"Christmas tree",
   code_39299000:"Glassware",
   code_39299100:"Glass ampoules",
   code_39299200:"Safety glass",
   code_39299300:"Glass mirrors",
   code_39300000:"Miscellaneous equipment",
   code_39310000:"Catering equipment",
   code_39311000:"Light catering equipment",
   code_39312000:"Food-preparation equipment",
   code_39312100:"Meat slicers",
   code_39312200:"Canteen equipment",
   code_39313000:"Hotel equipment",
   code_39314000:"Industrial kitchen equipment",
   code_39315000:"Restaurant equipment",
   code_39330000:"Disinfection equipment",
   code_39340000:"Gas network equipment",
   code_39341000:"Gas pressure equipment",
   code_39350000:"Sewerage works equipment",
   code_39360000:"Sealing equipment",
   code_39370000:"Water installations",
   code_39500000:"Textile articles",
   code_39510000:"Textile household articles",
   code_39511000:"Blankets and travelling rugs",
   code_39511100:"Blankets",
   code_39511200:"Travelling rugs",
   code_39512000:"Bed linen",
   code_39512100:"Sheets",
   code_39512200:"Duvet covers",
   code_39512300:"Mattress covers",
   code_39512400:"Eiderdowns",
   code_39512500:"Pillowcases",
   code_39512600:"Bolstercases",
   code_39513000:"Table linen",
   code_39513100:"Tablecloths",
   code_39513200:"Table napkins",
   code_39514000:"Toilet and kitchen linen",
   code_39514100:"Towels",
   code_39514200:"Tea towels",
   code_39514300:"Roller towels",
   code_39514400:"Automatic towel dispensers",
   code_39514500:"Face cloths",
   code_39515000:"Curtains, drapes, valances and textile blinds",
   code_39515100:"Curtains",
   code_39515110:"Smoke curtains",
   code_39515200:"Drapes",
   code_39515300:"Valances",
   code_39515400:"Blinds",
   code_39515410:"Interior blinds",
   code_39515420:"Textile blinds",
   code_39515430:"Venetian blinds",
   code_39515440:"Vertical blinds",
   code_39516000:"Furnishing articles",
   code_39516100:"Soft furnishings",
   code_39516110:"Cushions",
   code_39516120:"Pillows",
   code_39518000:"Hospital linen",
   code_39518100:"Operating-theatre drapes",
   code_39518200:"Operating-theatre sheets",
   code_39520000:"Made-up textile articles",
   code_39522000:"Tarpaulins, sails for boats, sailboards or land craft, awnings, sunblinds, tents and camping goods",
   code_39522100:"Tarpaulins, awnings and sunblinds",
   code_39522110:"Tarpaulins",
   code_39522120:"Awnings",
   code_39522130:"Sunblinds",
   code_39522200:"Camouflage covers",
   code_39522400:"Sails",
   code_39522500:"Textile camping goods",
   code_39522510:"Pneumatic mattresses",
   code_39522520:"Camp beds",
   code_39522530:"Tents",
   code_39522540:"Sleeping bags",
   code_39522541:"Sleeping bags filled with feathers or down",
   code_39523000:"Parachutes",
   code_39523100:"Dirigible parachutes",
   code_39523200:"Rotochutes",
   code_39525000:"Miscellaneous manufactured textile articles",
   code_39525100:"Dusters",
   code_39525200:"Filter elements of cloth",
   code_39525300:"Life jackets",
   code_39525400:"Fire blankets",
   code_39525500:"Mosquito nets",
   code_39525600:"Dishcloths",
   code_39525700:"Life belts",
   code_39525800:"Cleaning cloths",
   code_39525810:"Polishing cloths",
   code_39530000:"Carpets, mats and rugs",
   code_39531000:"Carpets",
   code_39531100:"Knotted carpeting",
   code_39531200:"Woven carpeting",
   code_39531300:"Tufted carpeting",
   code_39531310:"Carpet tiles",
   code_39531400:"Carpeting",
   code_39532000:"Mats",
   code_39533000:"Rugs",
   code_39534000:"Industrial carpeting",
   code_39540000:"Miscellaneous cordage, rope, twine and netting",
   code_39541000:"Cordage, rope, twine and netting",
   code_39541100:"Twine, cordage and rope",
   code_39541110:"Rope",
   code_39541120:"Cordage",
   code_39541130:"String",
   code_39541140:"Twine",
   code_39541200:"Textile nets",
   code_39541210:"Knotted nets",
   code_39541220:"Slings",
   code_39542000:"Rags",
   code_39550000:"Non-woven articles",
   code_39560000:"Miscellaneous textile articles",
   code_39561000:"Tulle, lace, narrow-woven fabrics, trimmings and embroidery",
   code_39561100:"Narrow-woven fabrics; trimmings",
   code_39561110:"Ribbon",
   code_39561120:"Textile tape",
   code_39561130:"Textile labels and badges",
   code_39561131:"Textile labels",
   code_39561132:"Textile badges",
   code_39561133:"Insignia",
   code_39561140:"Ornamental trimmings",
   code_39561141:"Braids",
   code_39561142:"Epaulettes",
   code_39561200:"Net fabrics",
   code_39562000:"Felt",
   code_39563000:"Textile wadding, yarns, fabrics and articles for technical uses",
   code_39563100:"Textile wadding",
   code_39563200:"Metallised yarn",
   code_39563300:"Woven fabrics of metal thread",
   code_39563400:"Impregnated, coated or covered textile fabrics",
   code_39563500:"Textile articles for technical uses",
   code_39563510:"Textile hosepiping",
   code_39563520:"Textile conveyor belts",
   code_39563530:"Ventilation ducting",
   code_39563600:"Quilted textile products",
   code_39700000:"Domestic appliances",
   code_39710000:"Electrical domestic appliances",
   code_39711000:"Electrical domestic appliances for use with foodstuffs",
   code_39711100:"Refrigerators and freezers",
   code_39711110:"Refrigerator-freezers",
   code_39711120:"Freezers",
   code_39711121:"Chest-type freezers",
   code_39711122:"Household freezers",
   code_39711123:"Upright-type freezers",
   code_39711124:"Industrial freezers",
   code_39711130:"Refrigerators",
   code_39711200:"Food processors",
   code_39711210:"Food blenders",
   code_39711211:"Food mixers",
   code_39711300:"Electrothermic appliances",
   code_39711310:"Electric coffee makers",
   code_39711320:"Electric tea makers",
   code_39711330:"Electric toasters",
   code_39711340:"Plate warmers",
   code_39711350:"Waffle irons",
   code_39711360:"Ovens",
   code_39711361:"Electric ovens",
   code_39711362:"Microwave ovens",
   code_39711400:"Roasters, hobs, hotplates and boiling rings",
   code_39711410:"Roasters",
   code_39711420:"Hobs",
   code_39711430:"Hotplates",
   code_39711440:"Boiling rings",
   code_39711500:"Can openers",
   code_39712000:"Electrical domestic appliances for use with the human body",
   code_39712100:"Hair clippers",
   code_39712200:"Hairdressing appliances",
   code_39712210:"Hair dryers",
   code_39712300:"Hand-drying apparatus",
   code_39713000:"Electrical domestic appliances for cleaning; smoothing irons",
   code_39713100:"Dishwashing machines",
   code_39713200:"Clothes-washing and drying machines",
   code_39713210:"Washer/dryers",
   code_39713211:"Drying and pressing unit",
   code_39713300:"Rubbish compactors",
   code_39713400:"Floor-maintenance machines",
   code_39713410:"Floor-cleaning machines",
   code_39713420:"Floor polishers",
   code_39713430:"Vacuum cleaners",
   code_39713431:"Accessories for vacuum cleaners",
   code_39713500:"Electric irons",
   code_39713510:"Steam irons",
   code_39714000:"Ventilating or recycling hoods",
   code_39714100:"Ventilators",
   code_39714110:"Extraction ventilators",
   code_39715000:"Water heaters and heating for buildings; plumbing equipment",
   code_39715100:"Electric instantaneous or storage water heaters and immersion heaters",
   code_39715200:"Heating equipment",
   code_39715210:"Central-heating equipment",
   code_39715220:"Electric heating resistors",
   code_39715230:"Electric soil-heating apparatus",
   code_39715240:"Electric space-heating apparatus",
   code_39715300:"Plumbing equipment",
   code_39716000:"Parts of electrical domestic appliances",
   code_39717000:"Fans and air-conditioning appliances",
   code_39717100:"Fans",
   code_39717200:"Air-conditioning appliances",
   code_39720000:"Non-electric domestic appliances",
   code_39721000:"Domestic cooking or heating equipment",
   code_39721100:"Domestic cooking appliances",
   code_39721200:"Gas refrigerators",
   code_39721300:"Non-electric air heaters or hot air-distributors",
   code_39721310:"Air heaters",
   code_39721320:"Air dryers",
   code_39721321:"Compressed-air dryers",
   code_39721400:"Instantaneous or storage non-electric water heaters",
   code_39721410:"Gas appliances",
   code_39721411:"Gas heaters",
   code_39722000:"Parts of stoves, cookers, plate warmers and domestic appliances",
   code_39722100:"Parts of stoves",
   code_39722200:"Parts of cookers",
   code_39722300:"Parts of plate warmers",
   code_39800000:"Cleaning and polishing products",
   code_39810000:"Odoriferous preparations and waxes",
   code_39811000:"Preparations for perfuming or deodorising rooms",
   code_39811100:"Air freshener",
   code_39811110:"Air freshener dispensers",
   code_39811200:"Air sanitisers",
   code_39811300:"Deodorisers",
   code_39812000:"Polishes and creams",
   code_39812100:"Floor polishes",
   code_39812200:"Shoe polishes",
   code_39812300:"Polishing waxes",
   code_39812400:"Sweeping compounds",
   code_39812500:"Sealants",
   code_39813000:"Scouring pastes and powders",
   code_39820000:"Organic surface-active agents",
   code_39821000:"Ammonia cleaners",
   code_39822000:"Caustic cleaners",
   code_39830000:"Cleaning products",
   code_39831000:"Washing preparations",
   code_39831100:"Oil dispersant",
   code_39831200:"Detergents",
   code_39831210:"Dishwasher detergents",
   code_39831220:"Degreasing agents",
   code_39831230:"Grease digesters",
   code_39831240:"Cleaning compounds",
   code_39831250:"Rinsing solutions",
   code_39831300:"Floor cleaners",
   code_39831400:"Screen cleaners",
   code_39831500:"Automotive cleaners",
   code_39831600:"Toilet cleaners",
   code_39831700:"Automatic soap dispensers",
   code_39832000:"Dishwashing products",
   code_39832100:"Dishwashing powder",
   code_39833000:"Anti-dust products",
   code_39834000:"Jewellery cleaning solutions",
   code_41000000:"Collected and purified water",
   code_41100000:"Natural water",
   code_41110000:"Drinking water",
   code_41120000:"Non-drinking water",
   code_42000000:"Industrial machinery",
   code_42100000:"Machinery for the production and use of mechanical power",
   code_42110000:"Turbines and motors",
   code_42111000:"Motors",
   code_42111100:"Outboard motors for marine propulsion",
   code_42112000:"Turbine installations",
   code_42112100:"Steam turbines",
   code_42112200:"Hydraulic turbines",
   code_42112210:"Water wheels",
   code_42112300:"Gas turbines",
   code_42112400:"Turbine equipment",
   code_42112410:"Turbine instruments",
   code_42113000:"Parts of turbines",
   code_42113100:"Parts of steam turbines",
   code_42113110:"Base plates",
   code_42113120:"Casings",
   code_42113130:"Condenser air-cooling systems",
   code_42113150:"Lubricating oil systems",
   code_42113160:"Moisture separators",
   code_42113161:"Dehumidifiers",
   code_42113170:"Rotary equipment",
   code_42113171:"Rotors",
   code_42113172:"Blades",
   code_42113190:"Turning gear",
   code_42113200:"Parts of hydraulic turbines",
   code_42113300:"Parts of gas turbines",
   code_42113310:"Air-inlet systems",
   code_42113320:"Gas-injection module",
   code_42113390:"Fuel-gas systems",
   code_42113400:"Parts of water wheels",
   code_42120000:"Pumps and compressors",
   code_42121000:"Hydraulic or pneumatic power engines and motors",
   code_42121100:"Hydraulic or pneumatic cylinders",
   code_42121200:"Hydraulic power engines",
   code_42121300:"Pneumatic power engines",
   code_42121400:"Hydraulic power motors",
   code_42121500:"Pneumatic power motors",
   code_42122000:"Pumps",
   code_42122100:"Pumps for liquids",
   code_42122110:"Pumps for firefighting",
   code_42122120:"Helicopter refuelling package",
   code_42122130:"Water pumps",
   code_42122160:"Cooling pumps",
   code_42122161:"Cooling-water pumps",
   code_42122170:"Lubricating pumps",
   code_42122180:"Fuel pumps",
   code_42122190:"Concrete pumps",
   code_42122200:"Reciprocating positive-displacement pumps for liquids",
   code_42122210:"Hydraulic power packs",
   code_42122220:"Sewage pumps",
   code_42122230:"Dosing pumps",
   code_42122300:"Pressure boosters for liquids",
   code_42122400:"Centrifugal pumps and liquid elevators",
   code_42122410:"Pumps for medical use",
   code_42122411:"Nutritional pumps",
   code_42122419:"Perfusion pumps",
   code_42122420:"Liquid elevators",
   code_42122430:"Centrifugal pumps",
   code_42122440:"Rotary pumps",
   code_42122450:"Vacuum pumps",
   code_42122460:"Air pumps",
   code_42122480:"Impeller pumps",
   code_42122500:"Laboratory pumps and accessories",
   code_42122510:"Peristaltic pumps",
   code_42123000:"Compressors",
   code_42123100:"Gas compressors",
   code_42123200:"Rotary compressors",
   code_42123300:"Compressors for refrigerating equipment",
   code_42123400:"Air compressors",
   code_42123410:"Mounted air compressors",
   code_42123500:"Turbo-compressors",
   code_42123600:"Reciprocating displacement compressors",
   code_42123610:"Compressed-air package",
   code_42123700:"Centrifugal compressors",
   code_42123800:"Compressors for use in civil aircraft",
   code_42124000:"Parts of pumps, compressors, engines or motors",
   code_42124100:"Parts of engines or motors",
   code_42124130:"Parts of pneumatic engines",
   code_42124150:"Parts of hydraulic power engines or motors",
   code_42124170:"Parts of reaction engines",
   code_42124200:"Parts of pumps or liquid elevators",
   code_42124210:"Parts of fuel, hand and concrete pumps",
   code_42124211:"Parts of fuel pumps",
   code_42124212:"Parts of hand pumps",
   code_42124213:"Parts of concrete pumps",
   code_42124220:"Parts of reciprocating positive-displacement pumps",
   code_42124221:"Parts of hydraulic power packs",
   code_42124222:"Parts of dosing pumps",
   code_42124230:"Parts of rotary positive-displacement pumps",
   code_42124290:"Parts of centrifugal pumps",
   code_42124300:"Parts of air or vacuum pumps, of air or gas compressors",
   code_42124310:"Parts of air pumps",
   code_42124320:"Parts of vacuum pumps",
   code_42124330:"Parts of air compressors",
   code_42124340:"Parts of gas compressors",
   code_42130000:"Taps, cocks, valves and similar appliances",
   code_42131000:"Taps, cocks and valves",
   code_42131100:"Valves defined by function",
   code_42131110:"Central-heating radiator valves",
   code_42131120:"Sluice valves",
   code_42131130:"Temperature regulators",
   code_42131140:"Pressure-reducing, control, check or safety valves",
   code_42131141:"Pressure-reducing valves",
   code_42131142:"Control valves",
   code_42131143:"Flood-control valves",
   code_42131144:"Process-control valves",
   code_42131145:"Check valves",
   code_42131146:"Non-return valves",
   code_42131147:"Safety valves",
   code_42131148:"Stop valves",
   code_42131150:"Standpipe valves",
   code_42131160:"Hydrants",
   code_42131170:"Gas-cylinder outlet valves",
   code_42131200:"Valves defined by construction",
   code_42131210:"Knife valves",
   code_42131220:"Penstock valves",
   code_42131230:"Gate valves",
   code_42131240:"Globe valves",
   code_42131250:"Needle valves",
   code_42131260:"Ball valves",
   code_42131270:"Plug valves",
   code_42131280:"Butterfly valves",
   code_42131290:"Diaphragm valves",
   code_42131291:"Sliding valves",
   code_42131292:"Flap valves",
   code_42131300:"Christmas trees and other assemblies of valves",
   code_42131310:"Oilrig Christmas trees",
   code_42131320:"Choke manifolds",
   code_42131390:"Assemblies of valves",
   code_42131400:"Sanitary taps, cocks",
   code_42132000:"Parts of taps and valves",
   code_42132100:"Valve actuators",
   code_42132110:"Electric valve actuators",
   code_42132120:"Hydraulic valve actuators",
   code_42132130:"Pneumatic valve actuators",
   code_42132200:"Tap parts",
   code_42132300:"Valve parts",
   code_42140000:"Gears, gearing and driving elements",
   code_42141000:"Plain gears, gearing and driving elements",
   code_42141100:"Transmission, cam- and crank- shafts",
   code_42141110:"Transmission shafts",
   code_42141120:"Camshafts",
   code_42141130:"Crankshafts",
   code_42141200:"Bearing housings",
   code_42141300:"Gears and gearing",
   code_42141400:"Flywheels and pulleys",
   code_42141410:"Winches",
   code_42141500:"Clutches",
   code_42141600:"Pulley blocks",
   code_42141700:"Shaft couplings",
   code_42141800:"Universal joints",
   code_42142000:"Parts of gearing and driving elements",
   code_42142100:"Parts of gearing elements",
   code_42142200:"Parts of driving elements",
   code_42150000:"Nuclear reactors and parts",
   code_42151000:"Nuclear reactors",
   code_42152000:"Parts of nuclear reactors",
   code_42152100:"Reactor-cooling systems",
   code_42152200:"Parts of nuclear-reactor vessels",
   code_42160000:"Boiler installations",
   code_42161000:"Hot-water boilers",
   code_42162000:"Steam-generating boilers",
   code_42163000:"Steam generators",
   code_42164000:"Auxiliary plant for use with boilers",
   code_42165000:"Steam condensers",
   code_42200000:"Machinery for food, beverage and tobacco processing and associated parts",
   code_42210000:"Food, beverage and tobacco-processing machinery",
   code_42211000:"Dairy machinery",
   code_42211100:"Centrifugal cream separators",
   code_42212000:"Machinery for processing cereals or dried vegetables",
   code_42213000:"Machinery used in the manufacture of alcoholic or fruit beverages",
   code_42214000:"Cooking ovens, dryers for agricultural products and equipment for cooking or heating",
   code_42214100:"Cooking ovens",
   code_42214110:"Grills",
   code_42214200:"Dryers for agricultural products",
   code_42215000:"Machinery for the industrial preparation or manufacture of food or drink",
   code_42215100:"Food-cutting machines",
   code_42215110:"Bread-slicing machines",
   code_42215120:"Bacon slicers",
   code_42215200:"Food-processing machinery",
   code_42215300:"Pasta-making machines",
   code_42216000:"Machinery for processing tobacco",
   code_42220000:"Parts of machinery for food, beverage and tobacco processing",
   code_42221000:"Parts of machinery for food processing",
   code_42221100:"Parts of dairy machinery",
   code_42221110:"Parts of milking machines",
   code_42222000:"Parts of machinery for beverage processing",
   code_42223000:"Parts of machinery for tobacco processing",
   code_42300000:"Industrial or laboratory furnaces, incinerators and ovens",
   code_42310000:"Furnace burners",
   code_42320000:"Waste incinerators",
   code_42330000:"Smelting furnaces",
   code_42340000:"Non-domestic ovens",
   code_42341000:"Commercial ovens",
   code_42350000:"Cremators",
   code_42390000:"Parts of furnace burners, furnaces or ovens",
   code_42400000:"Lifting and handling equipment and parts",
   code_42410000:"Lifting and handling equipment",
   code_42411000:"Pulley tackle and hoists",
   code_42412000:"Pithead winding gear, winches for underground use, and capstans",
   code_42412100:"Pithead winding gear and winches for use underground",
   code_42412110:"Pithead winding gear",
   code_42412120:"Winches for use underground",
   code_42412200:"Capstans",
   code_42413000:"Jacks and vehicle hoists",
   code_42413100:"Built-in jacking systems",
   code_42413200:"Hydraulic jacks",
   code_42413300:"Pneumatic jacks",
   code_42413400:"Mechanical jacks",
   code_42413500:"Vehicle hoists",
   code_42414000:"Cranes, mobile lifting frames and works trucks fitted with a crane",
   code_42414100:"Cranes",
   code_42414110:"Harbour cranes",
   code_42414120:"Quayside cranes",
   code_42414130:"Stacker cranes",
   code_42414140:"Container cranes",
   code_42414150:"Tower cranes",
   code_42414200:"Overhead travelling cranes",
   code_42414210:"Travelling crane",
   code_42414220:"Traversing bridge",
   code_42414300:"Portal jib cranes",
   code_42414310:"Mobile lifting frames",
   code_42414320:"Pedestal jib cranes",
   code_42414400:"Vehicle-mounted cranes",
   code_42414410:"Cranes for trucks",
   code_42414500:"Bridge cranes",
   code_42415000:"Forklift trucks, works trucks, railway-station platforms tractors",
   code_42415100:"Lifting trucks",
   code_42415110:"Forklift trucks",
   code_42415200:"Works trucks",
   code_42415210:"Works trucks fitted with handling equipment",
   code_42415300:"Railway-station platforms tractors",
   code_42415310:"Free-steered vehicles (FSVS)",
   code_42415320:"Equipment for emergency vehicles",
   code_42416000:"Lifts, skip hoists, hoists, escalators and moving walkways",
   code_42416100:"Lifts",
   code_42416110:"Bath lifts",
   code_42416120:"Goods lifts",
   code_42416130:"Mechanical lifts",
   code_42416200:"Skip hoists",
   code_42416210:"Bin-lifts",
   code_42416300:"Hoists",
   code_42416400:"Escalators",
   code_42416500:"Moving walkways",
   code_42417000:"Elevators and conveyors",
   code_42417100:"Pneumatic elevators or conveyors",
   code_42417200:"Conveyors",
   code_42417210:"Bucket-type, continuous-action elevators or conveyors",
   code_42417220:"Belt-type continuous-action elevators or conveyors",
   code_42417230:"Armoured-faced conveyors (AFCS) for mining",
   code_42417300:"Conveyor equipment",
   code_42417310:"Conveyor belts",
   code_42418000:"Lifting, handling, loading or unloading machinery",
   code_42418100:"Mine-wagon pushers and locomotive or wagon traversers",
   code_42418200:"Monorail or ski-lift equipment",
   code_42418210:"Overhead monorail equipment",
   code_42418220:"Chairlifts",
   code_42418290:"Ski-lift equipment",
   code_42418300:"Flare tip removal equipment",
   code_42418400:"Carousel storage and retrieval machines",
   code_42418500:"Mechanical handling equipment",
   code_42418900:"Loading or handling machinery",
   code_42418910:"Loading equipment",
   code_42418920:"Unloading equipment",
   code_42418930:"Sideloaders",
   code_42418940:"Container-handling equipment",
   code_42419000:"Parts of lifting and handling equipment",
   code_42419100:"Parts of cranes",
   code_42419200:"Parts of works trucks",
   code_42419500:"Parts of lift, skip hoists or escalators",
   code_42419510:"Parts of lifts",
   code_42419520:"Parts of skip hoists",
   code_42419530:"Parts of escalators",
   code_42419540:"Parts of moving walkways",
   code_42419800:"Parts of conveyors",
   code_42419810:"Parts of belt conveyors",
   code_42419890:"Parts of bucket conveyors",
   code_42419900:"Parts of winding gear and other lifting or handling equipment",
   code_42420000:"Buckets, shovels, grabs and grips for cranes or excavators",
   code_42500000:"Cooling and ventilation equipment",
   code_42510000:"Heat-exchange units, air-conditioning and refrigerating equipment, and filtering machinery",
   code_42511000:"Heat-exchange units and machinery for liquefying air or other gases",
   code_42511100:"Heat-exchange units",
   code_42511110:"Heat pumps",
   code_42511200:"Machinery for liquefying air or other gases",
   code_42512000:"Air-conditioning installations",
   code_42512100:"Window air-conditioning machines",
   code_42512200:"Wall air-conditioning machines",
   code_42512300:"HVAC packages",
   code_42512400:"Vehicle air conditioners",
   code_42512500:"Parts of air-conditioning machines",
   code_42512510:"Dampers",
   code_42512520:"Louvres",
   code_42513000:"Refrigerating and freezing equipment",
   code_42513100:"Freezing equipment",
   code_42513200:"Refrigerating equipment",
   code_42513210:"Refrigerated showcases",
   code_42513220:"Refrigerated counters",
   code_42513290:"Commercial refrigerating equipment",
   code_42514000:"Machinery and apparatus for filtering or purifying gases",
   code_42514200:"Electrostatic air and gas cleaners",
   code_42514300:"Filtering apparatus",
   code_42514310:"Air filters",
   code_42514320:"Gas filters",
   code_42515000:"District heating boiler",
   code_42520000:"Ventilation equipment",
   code_42521000:"Smoke-extraction equipment",
   code_42522000:"Non-domestic fans",
   code_42522100:"Parts of fans",
   code_42530000:"Parts of refrigerating and freezing equipment and heat pumps",
   code_42531000:"Parts of refrigerating equipment",
   code_42532000:"Parts of freezing equipment",
   code_42533000:"Parts of heat pumps",
   code_42600000:"Machine tools",
   code_42610000:"Machine tools operated by laser and machining centres",
   code_42611000:"Special-purpose machine tools",
   code_42612000:"Machining centre",
   code_42612100:"Horizontal-spindle machining centre",
   code_42612200:"Vertical-spindle machining centre",
   code_42620000:"Lathes, boring and milling machine tools",
   code_42621000:"Lathes",
   code_42621100:"CNC lathe",
   code_42622000:"Threading or tapping machines",
   code_42623000:"Milling machines",
   code_42630000:"Metal-working machine tools",
   code_42631000:"Machine tools for finishing metals",
   code_42632000:"Numerically-controlled machines for metal",
   code_42633000:"Bending, folding, straightening or flattening machines",
   code_42634000:"Forging machines",
   code_42635000:"Die-stamping machines",
   code_42636000:"Presses",
   code_42636100:"Hydraulic presses",
   code_42637000:"Machine tools for drilling, boring or milling metal",
   code_42637100:"Machine tools for drilling metal",
   code_42637200:"Machine tools for boring metal",
   code_42637300:"Machine tools for milling metal",
   code_42638000:"Metal-working machining centre",
   code_42640000:"Machine tools for working hard materials except metals",
   code_42641000:"Machine tools for working stone, ceramics, concrete or glass",
   code_42641100:"Machine tools for working stone",
   code_42641200:"Machine tools for working ceramics",
   code_42641300:"Machine tools for working concrete",
   code_42641400:"Machine tools for working glass",
   code_42642000:"Machine tools for working wood, bone, cork, hard rubber or hard plastics",
   code_42642100:"Machine tools for working wood",
   code_42642200:"Machine tools for working bone",
   code_42642300:"Machine tools for working cork",
   code_42642400:"Machine tools for working hard rubber",
   code_42642500:"Machine tools for working hard plastics",
   code_42650000:"Pneumatic or motorised hand tools",
   code_42651000:"Pneumatic hand tools",
   code_42652000:"Hand-held electromechanical tools",
   code_42660000:"Soldering, brazing and welding tools, surface tempering and hot-spraying machines and equipment",
   code_42661000:"Soldering and brazing equipment",
   code_42661100:"Soldering equipment",
   code_42661200:"Brazing equipment",
   code_42662000:"Welding equipment",
   code_42662100:"Electric welding equipment",
   code_42662200:"Non-electric welding equipment",
   code_42663000:"Surface tempering machines",
   code_42664000:"Fusion equipment",
   code_42664100:"Fusion equipment for plastics",
   code_42665000:"Metal-spraying machinery",
   code_42670000:"Parts and accessories of machine tools",
   code_42671000:"Tool holders",
   code_42671100:"Laboratory tool carriers",
   code_42671110:"Test tube racks for baths",
   code_42672000:"Machine-tool work holders",
   code_42673000:"Machine-tool dividing special attachments",
   code_42674000:"Parts and accessories for metal-working machine tools",
   code_42675000:"Parts and accessories for hard material-working machine tools",
   code_42675100:"Parts of chain saws",
   code_42676000:"Parts of hand tools",
   code_42677000:"Parts of pneumatic tools",
   code_42700000:"Machinery for textile, apparel and leather production",
   code_42710000:"Machinery for textiles",
   code_42711000:"Machines for processing man-made textile materials",
   code_42712000:"Textile spinning machines",
   code_42713000:"Weaving machines",
   code_42714000:"Knitting machines",
   code_42715000:"Sewing machines",
   code_42716000:"Laundry washing, dry-cleaning and drying machines",
   code_42716100:"Washing installation",
   code_42716110:"Washing equipment",
   code_42716120:"Washing machines",
   code_42716130:"Dry-cleaning machines",
   code_42716200:"Drying machines",
   code_42717000:"Linen ironing and folding equipment",
   code_42717100:"Linen folding equipment",
   code_42718000:"Textile-finishing machinery",
   code_42718100:"Ironing machines",
   code_42718200:"Ironing presses",
   code_42720000:"Parts of machinery for textile and apparel production",
   code_42800000:"Machinery for paper or paperboard production",
   code_42810000:"Parts of machinery for paper or paperboard production",
   code_42900000:"Miscellaneous general and special-purpose machinery",
   code_42910000:"Distilling, filtering or rectifying apparatus",
   code_42912000:"Liquid filtering or purifying machinery and apparatus",
   code_42912100:"Machinery and apparatus for filtering liquids",
   code_42912110:"Drilling-mud filtering apparatus",
   code_42912120:"Hydrocyclone machinery",
   code_42912130:"Apparatus for filtering or purifying beverages",
   code_42912300:"Machinery and apparatus for filtering or purifying water",
   code_42912310:"Water filtration apparatus",
   code_42912320:"De-aeration apparatus",
   code_42912330:"Water-purifying apparatus",
   code_42912340:"Desalination apparatus",
   code_42912350:"Filtration plant equipment",
   code_42913000:"Oil, petrol and air-intake filters",
   code_42913300:"Oil filters",
   code_42913400:"Petrol filters",
   code_42913500:"Air-intake filters",
   code_42914000:"Recycling equipment",
   code_42920000:"Machinery for cleaning bottles, packing and weighing and spraying machinery",
   code_42921000:"Machinery for cleaning, filling, packing or wrapping bottles or other containers",
   code_42921100:"Machinery for cleaning or drying bottles or other containers",
   code_42921200:"Machinery for filling or closing bottles, cans or other containers",
   code_42921300:"Packing or wrapping machinery",
   code_42921310:"Strapping machines",
   code_42921320:"Packaging machines",
   code_42921330:"Wrapping machines",
   code_42923000:"Weighing machinery and scales",
   code_42923100:"Weighing machinery",
   code_42923110:"Balances",
   code_42923200:"Scales",
   code_42923210:"Shop scales",
   code_42923220:"Scales for continuous weighing of goods",
   code_42923230:"Checkweigher scales",
   code_42924200:"Steam or sand blasting machines",
   code_42924300:"Spraying equipment",
   code_42924310:"Spray guns",
   code_42924700:"Mechanical appliances for projecting, dispersing or spraying",
   code_42924710:"Gas-dispersing apparatus",
   code_42924720:"Decontamination equipment",
   code_42924730:"Pressurised water cleaning apparatus",
   code_42924740:"High-pressure cleaning apparatus",
   code_42924790:"Odour-masking apparatus",
   code_42930000:"Centrifuges, calendering or vending machines",
   code_42931000:"Centrifuges",
   code_42931100:"Laboratory centrifuges and accessories",
   code_42931110:"Floor-model centrifuges",
   code_42931120:"Tabletop centrifuges",
   code_42931130:"Inserts for centrifuges",
   code_42931140:"Rotary equipment for centrifuges",
   code_42932000:"Calendering machines",
   code_42932100:"Rolling machines",
   code_42933000:"Vending machines",
   code_42933100:"Sanitary vending machines",
   code_42933200:"Stamp-vending machines",
   code_42933300:"Automatic goods-vending machines",
   code_42940000:"Machinery for the heat treatment of materials",
   code_42941000:"Machinery for the heat treatment of gas",
   code_42942000:"Ovens and accessories",
   code_42942200:"Vacuum ovens",
   code_42943000:"Thermostatic baths and accessories",
   code_42943100:"Refrigeration coils",
   code_42943200:"Ultrasonic baths",
   code_42943210:"Immersion thermostats",
   code_42943300:"Immersion coolers",
   code_42943400:"Refrigerated and refrigerated/heating circulators",
   code_42943500:"Recirculating coolers",
   code_42943600:"High-temperature circulators",
   code_42943700:"Heating circulators",
   code_42943710:"Bath covers",
   code_42950000:"Parts of general-purpose machinery",
   code_42952000:"Parts of centrifuges",
   code_42953000:"Parts of calendering machines",
   code_42954000:"Parts of rolling machines",
   code_42955000:"Parts of filtering machinery",
   code_42956000:"Parts of purifying machinery",
   code_42957000:"Parts of spraying machines",
   code_42958000:"Weights for weighing machines",
   code_42959000:"Non-domestic dishwashing machines",
   code_42960000:"Command and control system, printing, graphics, office automation and information-processing equipment",
   code_42961000:"Command and control system",
   code_42961100:"Access control system",
   code_42961200:"Scada or equivalent system",
   code_42961300:"Vehicle location system",
   code_42961400:"Dispatch system",
   code_42962000:"Printing and graphics equipment",
   code_42962100:"Film printing system",
   code_42962200:"Printing press",
   code_42962300:"Graphics workstations",
   code_42962400:"Hectographs",
   code_42962500:"Engraving machines",
   code_42963000:"Coin press",
   code_42964000:"Office automation equipment",
   code_42965000:"Information-processing equipment",
   code_42965100:"Warehouse management system",
   code_42965110:"Depot system",
   code_42967000:"Controller unit",
   code_42967100:"Digital remote-control unit",
   code_42968000:"Dispensers",
   code_42968100:"Drinks dispensers",
   code_42968200:"Sanitary dispensing machines",
   code_42968300:"Toilet-roll dispenser system",
   code_42970000:"Parts of dishwashing machines and of machines for cleaning, filling, packing or wrapping",
   code_42971000:"Parts of dishwashing machines",
   code_42972000:"Parts of cleaning machines",
   code_42973000:"Parts of filling machines",
   code_42974000:"Parts of packing machines",
   code_42975000:"Parts of wrapping machines",
   code_42980000:"Gas generators",
   code_42981000:"Ozone generators",
   code_42990000:"Miscellaneous special-purpose machinery",
   code_42991000:"Paper, printing and bookbinding machinery and parts",
   code_42991100:"Bookbinding machinery",
   code_42991110:"Book-sewing machinery",
   code_42991200:"Printing machinery",
   code_42991210:"Offset printing machinery",
   code_42991220:"Typesetting machinery",
   code_42991230:"Ticket printers",
   code_42991300:"Photocomposing system",
   code_42991400:"Dryers for wood, paper pulp, paper or paperboard",
   code_42991500:"Parts of printing or bookbinding machinery",
   code_42992000:"Special-purpose electrical goods",
   code_42992100:"Faraday cage",
   code_42992200:"Anechoic chamber",
   code_42992300:"Electromagnetic absorbent material",
   code_42993000:"Chemical industry machinery",
   code_42993100:"Chlorinators",
   code_42993200:"Dosing plant",
   code_42994000:"Machinery for working rubber or plastics",
   code_42994100:"Machines for the production of plastic windows and frames",
   code_42994200:"Machinery for working plastics",
   code_42994220:"Lamination accessories",
   code_42994230:"Laminators",
   code_42995000:"Miscellaneous cleaning machines",
   code_42995100:"Tunnel washer",
   code_42995200:"Beach cleaning machines",
   code_42996000:"Machinery for the treatment of sewage",
   code_42996100:"Comminutors",
   code_42996110:"Macerators for the treatment of sewage",
   code_42996200:"Sewage presses",
   code_42996300:"Scrapers",
   code_42996400:"Mixer units",
   code_42996500:"Sewage screens",
   code_42996600:"Oxygenation equipment",
   code_42996700:"Precipitators",
   code_42996800:"Sedimentation beds",
   code_42996900:"Sludge-processing equipment",
   code_42997000:"Pipeline machinery",
   code_42997100:"Machines for inspecting the internal surface of pipelines",
   code_42997200:"Machines for cleaning the internal surface of pipelines",
   code_42997300:"Industrial robots",
   code_42998000:"Pallet-picking system",
   code_42998100:"Pallet-retrieving system",
   code_42999000:"Non-domestic vacuum cleaners and floor polishers",
   code_42999100:"Non-domestic vacuum cleaners",
   code_42999200:"Non-domestic floor polishers",
   code_42999300:"Parts of non-domestic vacuum cleaners",
   code_42999400:"Parts of non-domestic floor polishers",
   code_43000000:"Machinery for mining, quarrying, construction equipment",
   code_43100000:"Mining equipment",
   code_43120000:"Coal or rock cutters and tunnelling machinery, and boring or sinking machinery",
   code_43121000:"Well-drilling machinery",
   code_43121100:"Wellhead running tools",
   code_43121200:"Riser connector apparatus",
   code_43121300:"Well-completion equipment",
   code_43121400:"Well-intervention equipment",
   code_43121500:"Well-testing equipment",
   code_43121600:"Blowout prevention (BOP) apparatus",
   code_43122000:"Coal or rock-cutting machinery",
   code_43123000:"Tunnelling machinery",
   code_43124000:"Boring machinery",
   code_43124100:"Moles",
   code_43124900:"Rock-drilling equipment",
   code_43125000:"Sinking machinery",
   code_43130000:"Drilling equipment",
   code_43131000:"Offshore production platforms",
   code_43131100:"Offshore equipment",
   code_43131200:"Offshore drilling unit",
   code_43132000:"Oil drilling equipment",
   code_43132100:"Drilling machinery",
   code_43132200:"Drilling rig",
   code_43132300:"Drills",
   code_43132400:"Line equipment",
   code_43132500:"Liner hangers",
   code_43133000:"Oil platform equipment",
   code_43133100:"Skid units",
   code_43133200:"Skid-mounted modules",
   code_43134000:"Oil-field machinery",
   code_43134100:"Submersible pumps",
   code_43135000:"Subsea equipment",
   code_43135100:"Subsea control systems",
   code_43136000:"Downhole equipment",
   code_43140000:"Mobile hydraulic-powered mine roof supports",
   code_43200000:"Earthmoving and excavating machinery, and associated parts",
   code_43210000:"Earthmoving machinery",
   code_43211000:"Bulldozers",
   code_43212000:"Angle-dozers",
   code_43220000:"Graders and levellers",
   code_43221000:"Road graders",
   code_43230000:"Scraper machines",
   code_43240000:"Tamping machines",
   code_43250000:"Front-end shovel loaders",
   code_43251000:"Front-end shovel loaders with backhoe",
   code_43252000:"Front-end shovel loaders without backhoe",
   code_43260000:"Mechanical shovels, excavators and shovel loaders, and mining machinery",
   code_43261000:"Mechanical shovels",
   code_43261100:"Mechanical shovel loaders",
   code_43262000:"Excavating machinery",
   code_43262100:"Mechanical excavators",
   code_43300000:"Construction machinery and equipment",
   code_43310000:"Civil engineering machinery",
   code_43311000:"Pile drivers",
   code_43312000:"Road-surfacing machinery",
   code_43312100:"Planers",
   code_43312200:"Chippers",
   code_43312300:"Paving machinery",
   code_43312400:"Road rollers",
   code_43312500:"Mechanical rollers",
   code_43313000:"Snowploughs and snowblowers",
   code_43313100:"Snowploughs",
   code_43313200:"Snowblowers",
   code_43314000:"Pile extractors",
   code_43315000:"Compacting machinery",
   code_43316000:"Cable-laying machinery",
   code_43320000:"Construction equipment",
   code_43321000:"Bridge-suspension equipment",
   code_43322000:"Dismantling equipment",
   code_43323000:"Irrigation equipment",
   code_43324000:"Drainage equipment",
   code_43324100:"Equipment for swimming pools",
   code_43325000:"Park and playground equipment",
   code_43325100:"Grounds-maintenance equipment",
   code_43327000:"Prefabricated equipment",
   code_43328000:"Hydraulic installations",
   code_43328100:"Hydraulic equipment",
   code_43329000:"Equipment sets",
   code_43400000:"Mineral-processing and foundry mould-forming machinery",
   code_43410000:"Mineral-processing machinery",
   code_43411000:"Sorting and screening machines",
   code_43412000:"Machines for mixing gravel with bitumen",
   code_43413000:"Concrete or mortar mixers",
   code_43413100:"Cement mixers",
   code_43414000:"Grinding machines",
   code_43414100:"Coal pulverising mills",
   code_43415000:"Foundry moulds",
   code_43420000:"Foundry mould-forming machinery",
   code_43500000:"Track-laying vehicles",
   code_43600000:"Parts of machinery for mining, quarrying and construction",
   code_43610000:"Parts for boring machinery",
   code_43611000:"Parts for well-drilling machinery",
   code_43611100:"Bridge plugs",
   code_43611200:"Industrial drill bits",
   code_43611300:"Drilling jars",
   code_43611400:"Iron roughnecks",
   code_43611500:"Rotary tables",
   code_43611600:"Mudline suspension equipment",
   code_43611700:"Tieback equipment",
   code_43612000:"Parts of well-extraction machinery",
   code_43612100:"Casing hangers",
   code_43612200:"Liner hanger equipment",
   code_43612300:"Production riser tensioners",
   code_43612400:"Wellheads",
   code_43612500:"Production riser tieback equipment",
   code_43612600:"Wellhead control system",
   code_43612700:"Wellhead equipment",
   code_43612800:"Rig-jacking systems",
   code_43613000:"Parts of coal or rock cutting machinery",
   code_43613100:"Parts of coal cutting machinery",
   code_43613200:"Parts of rock cutting machinery",
   code_43614000:"Parts of tunnelling machinery",
   code_43620000:"Parts of mineral-processing machinery",
   code_43630000:"Parts of sinking machinery",
   code_43640000:"Parts of excavating machinery",
   code_43700000:"Machinery for metallurgy and associated parts",
   code_43710000:"Metal-rolling machinery",
   code_43711000:"Parts of metal-rolling machinery",
   code_43720000:"Casting machines",
   code_43721000:"Parts of casting machines",
   code_43800000:"Workshop equipment",
   code_43810000:"Woodworking equipment",
   code_43811000:"Sanding machines",
   code_43812000:"Sawing equipment",
   code_43820000:"Shoe-making equipment",
   code_43830000:"Power tools",
   code_43840000:"Blacksmiths' equipment",
   code_44000000:"Construction structures and materials; auxiliary products to construction (except electric apparatus)",
   code_44100000:"Construction materials and associated items",
   code_44110000:"Construction materials",
   code_44111000:"Building materials",
   code_44111100:"Bricks",
   code_44111200:"Cement",
   code_44111210:"Drilling cement",
   code_44111300:"Ceramics",
   code_44111400:"Paints and wallcoverings",
   code_44111500:"Insulators and insulating fittings",
   code_44111510:"Insulators",
   code_44111511:"Electrical insulators",
   code_44111520:"Thermal insulating material",
   code_44111530:"Electrical insulating fittings",
   code_44111540:"Insulating glass",
   code_44111600:"Blocks",
   code_44111700:"Tiles",
   code_44111800:"Mortar (construction)",
   code_44111900:"Ceramic flags",
   code_44112000:"Miscellaneous building structures",
   code_44112100:"Shelters",
   code_44112110:"Shelter parts",
   code_44112120:"Profile sections",
   code_44112200:"Floor coverings",
   code_44112210:"Solid flooring",
   code_44112220:"False floors",
   code_44112230:"Linoleum",
   code_44112240:"Parquet",
   code_44112300:"Partitions",
   code_44112310:"Partition walls",
   code_44112400:"Roof",
   code_44112410:"Roof frames",
   code_44112420:"Roof supports",
   code_44112430:"Roof trusses",
   code_44112500:"Roofing materials",
   code_44112510:"Shingles",
   code_44112600:"Sound insulation",
   code_44112700:"Beams",
   code_44113000:"Road-construction materials",
   code_44113100:"Paving materials",
   code_44113120:"Paving slabs",
   code_44113130:"Paving stones",
   code_44113140:"Roadstone",
   code_44113200:"Flagstones",
   code_44113300:"Coated materials",
   code_44113310:"Coated road materials",
   code_44113320:"Coated roadstone",
   code_44113330:"Coating materials",
   code_44113500:"Glass beads",
   code_44113600:"Bitumen and asphalt",
   code_44113610:"Bitumen",
   code_44113620:"Asphalt",
   code_44113700:"Road-repair materials",
   code_44113800:"Road-surfacing materials",
   code_44113810:"Surface dressing",
   code_44113900:"Road-maintenance materials",
   code_44113910:"Winter-maintenance materials",
   code_44114000:"Concrete",
   code_44114100:"Ready-mixed concrete",
   code_44114200:"Concrete products",
   code_44114210:"Concrete piles",
   code_44114220:"Concrete pipes and fittings",
   code_44114250:"Concrete slabs",
   code_44115000:"Building fittings",
   code_44115100:"Ducting",
   code_44115200:"Plumbing and heating materials",
   code_44115210:"Plumbing materials",
   code_44115220:"Heating materials",
   code_44115310:"Roller-type shutters",
   code_44115400:"Skylights",
   code_44115500:"Sprinkler systems",
   code_44115600:"Stairlifts",
   code_44115700:"Exterior blinds",
   code_44115710:"Canopies",
   code_44115800:"Building internal fittings",
   code_44115810:"Curtain rails and hangings",
   code_44115811:"Curtain rails",
   code_44115900:"Sun protection devices",
   code_44130000:"Sewer mains",
   code_44131000:"Sewage chambers",
   code_44132000:"Culvert elements",
   code_44133000:"Ductile-iron end caps",
   code_44134000:"Bends",
   code_44140000:"Products related to construction materials",
   code_44141000:"Conduit",
   code_44141100:"Electric conduit",
   code_44142000:"Frames",
   code_44143000:"Pallets",
   code_44144000:"Posts",
   code_44160000:"Pipeline, piping, pipes, casing, tubing and related items",
   code_44161000:"Pipelines",
   code_44161100:"Gas pipelines",
   code_44161110:"Gas-distribution network",
   code_44161200:"Water mains",
   code_44161400:"Underwater pipeline",
   code_44161410:"Subsea pipelines",
   code_44161500:"High-pressure pipeline",
   code_44161600:"Low-pressure pipeline",
   code_44161700:"Pipeline pigs",
   code_44161710:"Pig launchers",
   code_44161720:"Pig receivers",
   code_44161730:"Pig traps",
   code_44162000:"Piping",
   code_44162100:"Piping supplies",
   code_44162200:"Distribution piping",
   code_44162300:"Outfall piping",
   code_44162400:"Vitrified clay manholes",
   code_44162500:"Drinking-water piping",
   code_44163000:"Pipes and fittings",
   code_44163100:"Pipes",
   code_44163110:"Drainage pipes",
   code_44163111:"Drain pipes",
   code_44163112:"Drainage system",
   code_44163120:"Distance-heating pipes",
   code_44163121:"Heating pipes",
   code_44163130:"Sewer pipes",
   code_44163140:"Steam and water pipes",
   code_44163150:"Low-pressure pipes",
   code_44163160:"Distribution pipes and accessories",
   code_44163200:"Pipe fittings",
   code_44163210:"Pipe clamps",
   code_44163230:"Pipe connectors",
   code_44163240:"Pipe joints",
   code_44163241:"Insulated joints",
   code_44164000:"Casing and tubing",
   code_44164100:"Casing",
   code_44164200:"Tubing",
   code_44164300:"Tubular goods",
   code_44164310:"Tubes and fittings",
   code_44165000:"Hoses, risers and sleeves",
   code_44165100:"Hoses",
   code_44165110:"Drilling hoses",
   code_44165200:"Risers",
   code_44165210:"Flexible risers",
   code_44165300:"Sleeves",
   code_44166000:"Oil country tubular goods",
   code_44167000:"Various pipe fittings",
   code_44167100:"Couplings",
   code_44167110:"Flanges",
   code_44167111:"Flange adaptors",
   code_44167200:"Repair clamps and collars",
   code_44167300:"Bends, tees and pipe fittings",
   code_44167400:"Elbows",
   code_44170000:"Plates, sheets, strip and foil related to construction materials",
   code_44171000:"Plates (construction)",
   code_44172000:"Sheets (construction)",
   code_44173000:"Strip",
   code_44174000:"Foil",
   code_44175000:"Panels",
   code_44176000:"Film",
   code_44190000:"Miscellaneous construction materials",
   code_44191000:"Miscellaneous construction materials in wood",
   code_44191100:"Plywood",
   code_44191200:"Laminated wood",
   code_44191300:"Particle board",
   code_44191400:"Fibreboard",
   code_44191500:"Densified wood",
   code_44191600:"Parquet panels",
   code_44192000:"Other miscellaneous construction materials",
   code_44192100:"PVC foam",
   code_44192200:"Nails",
   code_44200000:"Structural products",
   code_44210000:"Structures and parts of structures",
   code_44211000:"Prefabricated buildings",
   code_44211100:"Modular and portable buildings",
   code_44211110:"Cabins",
   code_44211200:"Cubicles",
   code_44211300:"Field hospital",
   code_44211400:"Field kitchens",
   code_44211500:"Glasshouses",
   code_44212000:"Structural products and parts except prefabricated buildings",
   code_44212100:"Bridge",
   code_44212110:"Bridge sections",
   code_44212120:"Structures of bridges",
   code_44212200:"Towers, lattice masts, derricks and pylons",
   code_44212210:"Derricks",
   code_44212211:"Drilling derricks",
   code_44212212:"Static drilling derricks",
   code_44212220:"Pylons, poles and pickets",
   code_44212221:"Pylons",
   code_44212222:"Electricity transmission pylons",
   code_44212223:"Pylon supports",
   code_44212224:"Poles for carrying overhead lines",
   code_44212225:"Poles",
   code_44212226:"Electricity poles",
   code_44212227:"Pickets",
   code_44212230:"Towers",
   code_44212233:"Water towers",
   code_44212240:"Girders",
   code_44212250:"Masts",
   code_44212260:"Radio or television masts",
   code_44212261:"Radio masts",
   code_44212262:"Television masts",
   code_44212263:"Lattice masts",
   code_44212300:"Structures and parts",
   code_44212310:"Scaffolding",
   code_44212311:"Arch struts",
   code_44212312:"Arch stilts",
   code_44212313:"Supports",
   code_44212314:"Pipe-hanging brackets",
   code_44212315:"Equipment for scaffolding",
   code_44212316:"Arch clips",
   code_44212317:"Scaffolding structures",
   code_44212318:"Pipeline supports",
   code_44212320:"Miscellaneous structures",
   code_44212321:"Bus shelters",
   code_44212322:"Telephone booths",
   code_44212329:"Security screens",
   code_44212380:"Parts of structures",
   code_44212381:"Cladding",
   code_44212382:"Floodgates",
   code_44212383:"Lock gates",
   code_44212390:"Sluices",
   code_44212391:"Sluice gates",
   code_44212400:"Piling",
   code_44212410:"Sheet piling",
   code_44212500:"Angles and sections",
   code_44212510:"Angles",
   code_44212520:"Sections",
   code_44220000:"Builders' joinery",
   code_44221000:"Windows, doors and related items",
   code_44221100:"Windows",
   code_44221110:"Window frames",
   code_44221111:"Double-glazed units",
   code_44221120:"French windows",
   code_44221200:"Doors",
   code_44221210:"Door blanks",
   code_44221211:"Door frames",
   code_44221212:"Door screens",
   code_44221213:"Door thresholds",
   code_44221220:"Fire doors",
   code_44221230:"Sliding doors",
   code_44221240:"Garage doors",
   code_44221300:"Gates",
   code_44221310:"Access gates",
   code_44221400:"Shutters",
   code_44221500:"Thresholds",
   code_44230000:"Builders' carpentry",
   code_44231000:"Made-up fencing panels",
   code_44232000:"Timber roof trusses",
   code_44233000:"Staircases",
   code_44300000:"Cable, wire and related products",
   code_44310000:"Wire products",
   code_44311000:"Stranded wire",
   code_44312000:"Fencing wire",
   code_44312300:"Barbed wire",
   code_44313000:"Metal netting",
   code_44313100:"Wire-mesh fencing",
   code_44313200:"Wire cloth",
   code_44315000:"Wire rods",
   code_44315100:"Welding accessories",
   code_44315200:"Welding materials",
   code_44315300:"Soldering or brazing materials",
   code_44315310:"Soldering materials",
   code_44315320:"Brazing materials",
   code_44316000:"Ironmongery",
   code_44316100:"Anvils",
   code_44316200:"Portable forge",
   code_44316300:"Grilles",
   code_44316400:"Hardware",
   code_44316500:"Smiths' wares",
   code_44316510:"Blacksmiths' wares",
   code_44317000:"Iron or steel slings",
   code_44318000:"Conductors",
   code_44320000:"Cable and related products",
   code_44321000:"Cable",
   code_44322000:"Cable accessories",
   code_44322100:"Cable conduits",
   code_44322200:"Cable connectors",
   code_44322300:"Cable ducts",
   code_44322400:"Cable fasteners",
   code_44330000:"Bars, rods, wire and profiles used in construction",
   code_44331000:"Bars",
   code_44332000:"Rods (construction)",
   code_44333000:"Wire",
   code_44334000:"Profiles",
   code_44400000:"Miscellaneous fabricated products and related items",
   code_44410000:"Articles for the bathroom and kitchen",
   code_44411000:"Sanitary ware",
   code_44411100:"Taps",
   code_44411200:"Baths",
   code_44411300:"Washbasins",
   code_44411400:"Shower basins",
   code_44411600:"Bidets",
   code_44411700:"Lavatory seats, covers, bowls and cisterns",
   code_44411710:"Lavatory seats",
   code_44411720:"Lavatory covers",
   code_44411740:"Lavatory bowls",
   code_44411750:"Lavatory cisterns",
   code_44411800:"Urinals",
   code_44420000:"Goods used in construction",
   code_44421000:"Armoured or reinforced safes, strongboxes and doors",
   code_44421300:"Safes",
   code_44421500:"Armoured or reinforced doors",
   code_44421600:"Safe-deposit lockers",
   code_44421700:"Boxes and lockers",
   code_44421710:"Left-luggage lockers",
   code_44421720:"Lockers",
   code_44421721:"Safe-deposit boxes",
   code_44421722:"Safety cases",
   code_44421780:"Deed boxes",
   code_44421790:"Cash boxes",
   code_44422000:"Letterboxes",
   code_44423000:"Miscellaneous articles",
   code_44423100:"Bells",
   code_44423200:"Ladders",
   code_44423220:"Folding steps",
   code_44423230:"Step stools",
   code_44423300:"Goods-handling equipment",
   code_44423330:"Platforms for handling goods",
   code_44423340:"Wire-rope accessories for handling goods",
   code_44423400:"Signs and related items",
   code_44423450:"Nameplates",
   code_44423460:"Address plates",
   code_44423700:"Manhole elements",
   code_44423710:"Surface boxes",
   code_44423720:"Boundary boxes",
   code_44423730:"Manhole frames",
   code_44423740:"Manhole covers",
   code_44423750:"Drain covers",
   code_44423760:"Access covers",
   code_44423790:"Manhole step irons",
   code_44423800:"Metal stamps",
   code_44423810:"Rubber stamps",
   code_44423850:"Shapes",
   code_44423900:"Sacrificial anodes",
   code_44424000:"Meter-housing boxes and tape",
   code_44424100:"Meter-housing boxes",
   code_44424200:"Adhesive tape",
   code_44424300:"Reflective tape",
   code_44425000:"Rings, seals, bands, sticks and grout packers",
   code_44425100:"Elastic rings",
   code_44425110:"Self-adhesive reinforcement rings",
   code_44425200:"Rubber seals",
   code_44425300:"Rubber bands",
   code_44425400:"Grout packers",
   code_44425500:"Plastic sticks",
   code_44430000:"Armour plating",
   code_44431000:"Cover plates",
   code_44440000:"Bearings",
   code_44441000:"Microbeads",
   code_44442000:"Roller bearings",
   code_44450000:"Mild steel",
   code_44451000:"Coin blanks",
   code_44452000:"Coin boxes",
   code_44460000:"Props and mining struts",
   code_44461000:"Props",
   code_44461100:"Mine props",
   code_44462000:"Mining struts",
   code_44464000:"Caterpillar tracks",
   code_44470000:"Cast-iron products",
   code_44480000:"Miscellaneous fire-protection equipment",
   code_44481000:"Platforms ladders",
   code_44481100:"Fire ladders",
   code_44482000:"Fire-protection devices",
   code_44482100:"Fire hoses",
   code_44482200:"Fire hydrants",
   code_44500000:"Tools, locks, keys, hinges, fasteners, chain and springs",
   code_44510000:"Tools",
   code_44511000:"Hand tools",
   code_44511100:"Spades and shovels",
   code_44511110:"Spades",
   code_44511120:"Shovels",
   code_44511200:"Gardening forks",
   code_44511300:"Mattocks, picks, hoes, rakes and beach rakes",
   code_44511310:"Mattocks",
   code_44511320:"Picks",
   code_44511330:"Hoes",
   code_44511340:"Rakes",
   code_44511341:"Beach rakes",
   code_44511400:"Axes",
   code_44511500:"Hand saws",
   code_44511510:"Handsaw blades",
   code_44512000:"Miscellaneous hand tools",
   code_44512100:"Chisels",
   code_44512200:"Pliers",
   code_44512210:"Crimping pliers",
   code_44512300:"Hammers",
   code_44512400:"Wire grips",
   code_44512500:"Spanners",
   code_44512600:"Roadworking tools",
   code_44512610:"Spikes for piercing road surfaces",
   code_44512700:"Files or rasps",
   code_44512800:"Screwdrivers",
   code_44512900:"Drill bits, screwdriver bits and other accessories",
   code_44512910:"Drill bits",
   code_44512920:"Screwdriver bits",
   code_44512930:"Tool carriers",
   code_44512940:"Tool kits",
   code_44513000:"Treadle-operated tools",
   code_44514000:"Tool handles and tool parts",
   code_44514100:"Tool handles",
   code_44514200:"Tool parts",
   code_44520000:"Locks, keys and hinges",
   code_44521000:"Miscellaneous padlocks and locks",
   code_44521100:"Locks",
   code_44521110:"Door locks",
   code_44521120:"Electronic security lock",
   code_44521130:"Enhanced security lock",
   code_44521140:"Furniture locks",
   code_44521200:"Padlocks and chains",
   code_44521210:"Padlocks",
   code_44522000:"Clasps, lock parts and keys",
   code_44522100:"Clasps",
   code_44522200:"Keys",
   code_44522300:"Parts of padlocks",
   code_44522400:"Parts of locks",
   code_44523000:"Hinges, mountings and fittings",
   code_44523100:"Hinges",
   code_44523200:"Mountings",
   code_44523300:"Fittings",
   code_44530000:"Fasteners",
   code_44531000:"Threaded fasteners",
   code_44531100:"Wood screws",
   code_44531200:"Screw hooks or screw rings",
   code_44531300:"Self-tapping screws",
   code_44531400:"Bolts",
   code_44531500:"Flange jointing sets",
   code_44531510:"Bolts and screws",
   code_44531520:"Coach bolts and screws",
   code_44531600:"Nuts",
   code_44531700:"Iron or steel threaded articles",
   code_44532000:"Non-threaded fasteners",
   code_44532100:"Rivets",
   code_44532200:"Washers",
   code_44532300:"Cotter pins",
   code_44532400:"Steel fishplates",
   code_44533000:"Copper fasteners",
   code_44540000:"Chain",
   code_44541000:"Articulated chain",
   code_44542000:"Parts of chain",
   code_44550000:"Springs",
   code_44600000:"Tanks, reservoirs and containers; central-heating radiators and boilers",
   code_44610000:"Tanks, reservoirs, containers and pressure vessels",
   code_44611000:"Tanks",
   code_44611100:"Air cylinders",
   code_44611110:"Compressed-air cylinders",
   code_44611200:"Breathing apparatus",
   code_44611400:"Storage tanks",
   code_44611410:"Oil-storage tanks",
   code_44611420:"Sludge-storage tanks",
   code_44611500:"Water tanks",
   code_44611600:"Reservoirs",
   code_44612000:"Liquefied-gas containers",
   code_44612100:"Gas cylinders",
   code_44612200:"Gas tanks",
   code_44613000:"Large containers",
   code_44613110:"Silos",
   code_44613200:"Refrigerated containers",
   code_44613210:"Water chambers",
   code_44613300:"Standard freight containers",
   code_44613400:"Storage containers",
   code_44613500:"Water containers",
   code_44613600:"Wheeled containers",
   code_44613700:"Refuse skips",
   code_44613800:"Containers for waste material",
   code_44614000:"Casks",
   code_44614100:"Storage bins",
   code_44614300:"Container storage system",
   code_44614310:"Stacking machinery",
   code_44615000:"Pressure vessels",
   code_44615100:"Steel pressure vessels",
   code_44616000:"Drums",
   code_44616200:"Waste drums",
   code_44617000:"Boxes",
   code_44617100:"Cartons",
   code_44617200:"Meter housings",
   code_44617300:"Prefabricated boxes",
   code_44618000:"Light containers, corks, tops for containers, vats and lids",
   code_44618100:"Light containers",
   code_44618300:"Corks, stoppers, tops for containers and lids",
   code_44618310:"Corks",
   code_44618320:"Stoppers",
   code_44618330:"Tops for containers",
   code_44618340:"Lids",
   code_44618350:"Plastic caps",
   code_44618400:"Cans",
   code_44618420:"Food cans",
   code_44618500:"Vats",
   code_44619000:"Other containers",
   code_44619100:"Cases",
   code_44619200:"Cable drums",
   code_44619300:"Crates",
   code_44619400:"Barrels",
   code_44619500:"Pallet boxes",
   code_44620000:"Central-heating radiators and boilers and parts",
   code_44621000:"Radiators and boilers",
   code_44621100:"Radiators",
   code_44621110:"Central-heating radiators",
   code_44621111:"Non-electrically-heated central-heating radiators",
   code_44621112:"Parts of central-heating radiators",
   code_44621200:"Boilers",
   code_44621210:"Water boilers",
   code_44621220:"Central-heating boilers",
   code_44621221:"Parts of central-heating boilers",
   code_44622000:"Heat-recovery systems",
   code_44622100:"Heat-recovery equipment",
   code_44800000:"Paints, varnishes and mastics",
   code_44810000:"Paints",
   code_44811000:"Road paint",
   code_44812000:"Artists' paints",
   code_44812100:"Enamels and glazes",
   code_44812200:"Oil and water paints",
   code_44812210:"Oil paints",
   code_44812220:"Water paints",
   code_44812300:"Students' paints",
   code_44812310:"Colours in sets",
   code_44812320:"Signboard painters' colours",
   code_44812400:"Decorating supplies",
   code_44820000:"Varnishes",
   code_44830000:"Mastics, fillers, putty and solvents",
   code_44831000:"Mastics, fillers, putty",
   code_44831100:"Mastics",
   code_44831200:"Fillers",
   code_44831300:"Putty",
   code_44831400:"Grout",
   code_44832000:"Solvents",
   code_44832100:"Paint stripper",
   code_44832200:"Thinners",
   code_44900000:"Stone for construction, limestone, gypsum and slate",
   code_44910000:"Stone for construction",
   code_44911000:"Marble and calcarous building stone",
   code_44911100:"Marble",
   code_44911200:"Travertine",
   code_44912000:"Miscellaneous building stone",
   code_44912100:"Granite",
   code_44912200:"Sandstone",
   code_44912300:"Basalt",
   code_44912400:"Kerbstones",
   code_44920000:"Limestone, gypsum and chalk",
   code_44921000:"Limestone and gypsum",
   code_44921100:"Gypsum",
   code_44921200:"Lime",
   code_44921210:"Powdered lime",
   code_44921300:"Limestone",
   code_44922000:"Chalk and dolomite",
   code_44922100:"Chalk",
   code_44922200:"Dolomite",
   code_44930000:"Slate",
   code_45000000:"Construction work",
   code_45100000:"Site preparation work",
   code_45110000:"Building demolition and wrecking work and earthmoving work",
   code_45111000:"Demolition, site preparation and clearance work",
   code_45111100:"Demolition work",
   code_45111200:"Site preparation and clearance work",
   code_45111210:"Blasting and associated rock-removal work",
   code_45111211:"Blasting work",
   code_45111212:"Rock-removal work",
   code_45111213:"Site-clearance work",
   code_45111214:"Blast-clearing work",
   code_45111220:"Scrub-removal work",
   code_45111230:"Ground-stabilisation work",
   code_45111240:"Ground-drainage work",
   code_45111250:"Ground investigation work",
   code_45111260:"Site-preparation work for mining",
   code_45111290:"Primary works for services",
   code_45111291:"Site-development work",
   code_45111300:"Dismantling works",
   code_45111310:"Dismantling works for military installations",
   code_45111320:"Dismantling works for security installations",
   code_45112000:"Excavating and earthmoving work",
   code_45112100:"Trench-digging work",
   code_45112200:"Soil-stripping work",
   code_45112210:"Topsoil-stripping work",
   code_45112300:"Infill and land-reclamation work",
   code_45112310:"Infill work",
   code_45112320:"Land-reclamation work",
   code_45112330:"Site-reclamation work",
   code_45112340:"Soil-decontamination work",
   code_45112350:"Reclamation of waste land",
   code_45112360:"Land rehabilitation work",
   code_45112400:"Excavating work",
   code_45112410:"Grave-digging work",
   code_45112420:"Basement excavation work",
   code_45112440:"Terracing of hillsides",
   code_45112441:"Terracing work",
   code_45112450:"Excavation work at archaeological sites",
   code_45112500:"Earthmoving work",
   code_45112600:"Cut and fill",
   code_45112700:"Landscaping work",
   code_45112710:"Landscaping work for green areas",
   code_45112711:"Landscaping work for parks",
   code_45112712:"Landscaping work for gardens",
   code_45112713:"Landscaping work for roof gardens",
   code_45112714:"Landscaping work for cemeteries",
   code_45112720:"Landscaping work for sports grounds and recreational areas",
   code_45112721:"Landscaping work for golf courses",
   code_45112722:"Landscaping work for riding areas",
   code_45112723:"Landscaping work for playgrounds",
   code_45112730:"Landscaping work for roads and motorways",
   code_45112740:"Landscaping work for airports",
   code_45113000:"Siteworks",
   code_45120000:"Test drilling and boring work",
   code_45121000:"Test drilling work",
   code_45122000:"Test boring work",
   code_45200000:"Works for complete or part construction and civil engineering work",
   code_45210000:"Building construction work",
   code_45211000:"Construction work for multi-dwelling buildings and individual houses",
   code_45211100:"Construction work for houses",
   code_45211200:"Sheltered housing construction work",
   code_45211300:"Houses construction work",
   code_45211310:"Bathrooms construction work",
   code_45211320:"Porches construction work",
   code_45211340:"Multi-dwelling buildings construction work",
   code_45211341:"Flats construction work",
   code_45211350:"Multi-functional buildings construction work",
   code_45211360:"Urban development construction work",
   code_45211370:"Construction works for saunas",
   code_45212000:"Construction work for buildings relating to leisure, sports, culture, lodging and restaurants",
   code_45212100:"Construction work of leisure facilities",
   code_45212110:"Leisure centre construction work",
   code_45212120:"Theme park construction work",
   code_45212130:"Amusement park construction work",
   code_45212140:"Recreation installation",
   code_45212150:"Cinema construction work",
   code_45212160:"Casino construction work",
   code_45212170:"Entertainment building construction work",
   code_45212171:"Entertainment centre construction work",
   code_45212172:"Recreation centre construction work",
   code_45212180:"Ticket offices construction work",
   code_45212190:"Sun-protection works",
   code_45212200:"Construction work for sports facilities",
   code_45212210:"Single-purpose sports facilities construction work",
   code_45212211:"Ice rink construction work",
   code_45212212:"Construction work for swimming pool",
   code_45212213:"Sport markings works",
   code_45212220:"Multi-purpose sports facilities construction work",
   code_45212221:"Construction work in connection with structures for sports ground",
   code_45212222:"Gymnasium construction work",
   code_45212223:"Winter-sports facilities construction work",
   code_45212224:"Stadium construction work",
   code_45212225:"Sports hall construction work",
   code_45212230:"Installation of changing rooms",
   code_45212290:"Repair and maintenance work in connection with sports facilities",
   code_45212300:"Construction work for art and cultural buildings",
   code_45212310:"Construction work for buildings relating to exhibitions",
   code_45212311:"Art gallery construction work",
   code_45212312:"Exhibition centre construction work",
   code_45212313:"Museum construction work",
   code_45212314:"Historical monument or memorial construction work",
   code_45212320:"Construction work for buildings relating to artistic performances",
   code_45212321:"Auditorium construction work",
   code_45212322:"Theatre construction work",
   code_45212330:"Library construction work",
   code_45212331:"Multimedia library construction work",
   code_45212340:"Lecture hall construction work",
   code_45212350:"Buildings of particular historical or architectural interest",
   code_45212351:"Prehistoric monument construction work",
   code_45212352:"Industrial monument construction work",
   code_45212353:"Palace construction work",
   code_45212354:"Castle construction work",
   code_45212360:"Religious buildings construction work",
   code_45212361:"Church construction work",
   code_45212400:"Accommodation and restaurant buildings",
   code_45212410:"Construction work for lodging buildings",
   code_45212411:"Hotel construction work",
   code_45212412:"Hostel construction work",
   code_45212413:"Short-stay accommodation construction work",
   code_45212420:"Construction work for restaurants and similar facilities",
   code_45212421:"Restaurant construction work",
   code_45212422:"Canteen construction work",
   code_45212423:"Cafeteria construction work",
   code_45212500:"Kitchen or restaurant conversion",
   code_45212600:"Pavilion construction work",
   code_45213000:"Construction work for commercial buildings, warehouses and industrial buildings, buildings relating to transport",
   code_45213100:"Construction work for commercial buildings",
   code_45213110:"Shop buildings construction work",
   code_45213111:"Shopping centre construction work",
   code_45213112:"Shop units construction work",
   code_45213120:"Post office construction work",
   code_45213130:"Bank construction work",
   code_45213140:"Market construction work",
   code_45213141:"Covered market construction work",
   code_45213142:"Open-air market construction work",
   code_45213150:"Office block construction work",
   code_45213200:"Construction work for warehouses and industrial buildings",
   code_45213210:"Cold-storage installations",
   code_45213220:"Construction work for warehouses",
   code_45213221:"Warehouse stores construction work",
   code_45213230:"Abattoir construction work",
   code_45213240:"Agricultural buildings construction work",
   code_45213241:"Barn construction work",
   code_45213242:"Cowsheds construction work",
   code_45213250:"Construction work for industrial buildings",
   code_45213251:"Industrial units construction work",
   code_45213252:"Workshops construction work",
   code_45213260:"Stores depot construction work",
   code_45213270:"Construction works for recycling station",
   code_45213280:"Construction works for compost facility",
   code_45213300:"Buildings associated with transport",
   code_45213310:"Construction work for buildings relating to road transport",
   code_45213311:"Bus station construction work",
   code_45213312:"Car park building construction work",
   code_45213313:"Service-area building construction work",
   code_45213314:"Bus garage construction work",
   code_45213315:"Bus-stop shelter construction work",
   code_45213316:"Installation works of walkways",
   code_45213320:"Construction work for buildings relating to railway transport",
   code_45213321:"Railway station construction work",
   code_45213322:"Rail terminal building construction work",
   code_45213330:"Construction work for buildings relating to air transport",
   code_45213331:"Airport buildings construction work",
   code_45213332:"Airport control tower construction work",
   code_45213333:"Installation works of airport check-in counters",
   code_45213340:"Construction work for buildings relating to water transport",
   code_45213341:"Ferry terminal building construction work",
   code_45213342:"Ro-ro terminal construction work",
   code_45213350:"Construction work for buildings relating to various means of transport",
   code_45213351:"Maintenance hangar construction work",
   code_45213352:"Service depot construction work",
   code_45213353:"Installation works of passenger boarding bridges",
   code_45213400:"Installation of staff rooms",
   code_45214000:"Construction work for buildings relating to education and research",
   code_45214100:"Construction work for kindergarten buildings",
   code_45214200:"Construction work for school buildings",
   code_45214210:"Primary school construction work",
   code_45214220:"Secondary school construction work",
   code_45214230:"Special school construction work",
   code_45214300:"Construction work for college buildings",
   code_45214310:"Vocational college construction work",
   code_45214320:"Technical college construction work",
   code_45214400:"Construction work for university buildings",
   code_45214410:"Polytechnic construction work",
   code_45214420:"Lecture theatre construction work",
   code_45214430:"Language laboratory construction work",
   code_45214500:"Construction work for buildings of further education",
   code_45214600:"Construction work for research buildings",
   code_45214610:"Laboratory building construction work",
   code_45214620:"Research and testing facilities construction work",
   code_45214630:"Scientific installations",
   code_45214631:"Installation works of cleanrooms",
   code_45214640:"Meteorological stations construction work",
   code_45214700:"Construction work for halls of residence",
   code_45214710:"Entrance hall construction work",
   code_45214800:"Training facilities building",
   code_45215000:"Construction work for buildings relating to health and social services, for crematoriums and public conveniences",
   code_45215100:"Construction work for buildings relating to health",
   code_45215110:"Spa construction work",
   code_45215120:"Special medical building construction work",
   code_45215130:"Clinic construction work",
   code_45215140:"Hospital facilities construction work",
   code_45215141:"Operating theatre construction work",
   code_45215142:"Intensive-care unit construction work",
   code_45215143:"Diagnostic screening room construction work",
   code_45215144:"Screening rooms construction work",
   code_45215145:"Fluoroscopy room construction work",
   code_45215146:"Pathology room construction work",
   code_45215147:"Forensic room construction work",
   code_45215148:"Catheter room construction work",
   code_45215200:"Construction work for social services buildings",
   code_45215210:"Construction work for subsidised residential accommodation",
   code_45215212:"Retirement home construction work",
   code_45215213:"Nursing home construction work",
   code_45215214:"Residential homes construction work",
   code_45215215:"Children's home construction work",
   code_45215220:"Construction work for social facilities other than subsidised residential accommodation",
   code_45215221:"Daycare centre construction work",
   code_45215222:"Civic centre construction work",
   code_45215300:"Construction work for crematoriums",
   code_45215400:"Cemetery works",
   code_45215500:"Public conveniences",
   code_45216000:"Construction work for buildings relating to law and order or emergency services and for military buildings",
   code_45216100:"Construction work for buildings relating to law and order or emergency services",
   code_45216110:"Construction work for buildings relating to law and order",
   code_45216111:"Police station construction work",
   code_45216112:"Court building construction work",
   code_45216113:"Prison building construction work",
   code_45216114:"Parliament and public assembly buildings",
   code_45216120:"Construction work for buildings relating to emergency services",
   code_45216121:"Fire station construction work",
   code_45216122:"Ambulance station construction work",
   code_45216123:"Mountain-rescue building construction work",
   code_45216124:"Lifeboat station construction work",
   code_45216125:"Emergency-services building construction work",
   code_45216126:"Coastguard building construction work",
   code_45216127:"Rescue-service station construction work",
   code_45216128:"Lighthouse construction work",
   code_45216129:"Protective shelters",
   code_45216200:"Construction work for military buildings and installations",
   code_45216220:"Military bunker construction work",
   code_45216230:"Military shelter construction work",
   code_45216250:"Trench defences construction work",
   code_45217000:"Inflatable buildings construction work",
   code_45220000:"Engineering works and construction works",
   code_45221000:"Construction work for bridges and tunnels, shafts and subways",
   code_45221100:"Construction work for bridges",
   code_45221110:"Bridge construction work",
   code_45221111:"Road bridge construction work",
   code_45221112:"Railway bridge construction work",
   code_45221113:"Footbridge construction work",
   code_45221114:"Construction work for iron bridges",
   code_45221115:"Construction work for steel bridges",
   code_45221117:"Weighbridge construction work",
   code_45221118:"Pipeline-carrying bridge construction work",
   code_45221119:"Bridge renewal construction work",
   code_45221120:"Viaduct construction work",
   code_45221121:"Road viaduct construction work",
   code_45221122:"Railway viaduct construction work",
   code_45221200:"Construction work for tunnels, shafts and subways",
   code_45221210:"Covered or partially-covered excavations",
   code_45221211:"Underpass",
   code_45221213:"Covered or partially-covered railway excavations",
   code_45221214:"Covered or partially-covered road excavations",
   code_45221220:"Culverts",
   code_45221230:"Shafts",
   code_45221240:"Construction work for tunnels",
   code_45221241:"Road tunnel construction work",
   code_45221242:"Railway tunnel construction work",
   code_45221243:"Pedestrian tunnel construction work",
   code_45221244:"Canal tunnel construction work",
   code_45221245:"Under-river tunnel construction work",
   code_45221246:"Undersea tunnel construction work",
   code_45221247:"Tunnelling works",
   code_45221248:"Tunnel linings construction work",
   code_45221250:"Underground work other than tunnels, shafts and subways",
   code_45222000:"Construction work for engineering works except bridges, tunnels, shafts and subways",
   code_45222100:"Waste-treatment plant construction work",
   code_45222110:"Waste disposal site construction work",
   code_45222200:"Engineering work for military installations",
   code_45222300:"Engineering work for security installations",
   code_45223000:"Structures construction work",
   code_45223100:"Assembly of metal structures",
   code_45223110:"Installation of metal structures",
   code_45223200:"Structural works",
   code_45223210:"Structural steelworks",
   code_45223220:"Structural shell work",
   code_45223300:"Parking lot construction work",
   code_45223310:"Underground car park construction work",
   code_45223320:"Park-and-ride facility construction work",
   code_45223400:"Radar station construction work",
   code_45223500:"Reinforced-concrete structures",
   code_45223600:"Dog kennels construction work",
   code_45223700:"Service area construction work",
   code_45223710:"Motorway service area construction work",
   code_45223720:"Petrol/gas stations construction work",
   code_45223800:"Assembly and erection of prefabricated structures",
   code_45223810:"Prefabricated constructions",
   code_45223820:"Prefabricated units and components",
   code_45223821:"Prefabricated units",
   code_45223822:"Prefabricated components",
   code_45230000:"Construction work for pipelines, communication and power lines, for highways, roads, airfields and railways; flatwork",
   code_45231000:"Construction work for pipelines, communication and power lines",
   code_45231100:"General construction work for pipelines",
   code_45231110:"Pipelaying construction work",
   code_45231111:"Pipeline lifting and relaying",
   code_45231112:"Installation of pipe system",
   code_45231113:"Pipeline relaying works",
   code_45231200:"Construction work for oil and gas pipelines",
   code_45231210:"Construction work for oil pipelines",
   code_45231220:"Construction work for gas pipelines",
   code_45231221:"Gas supply mains construction work",
   code_45231222:"Gasholder works",
   code_45231223:"Gas distribution ancillary work",
   code_45231300:"Construction work for water and sewage pipelines",
   code_45231400:"Construction work for electricity power lines",
   code_45231500:"Compressed-air pipeline work",
   code_45231510:"Compressed-air pipeline work for mailing system",
   code_45231600:"Construction work for communication lines",
   code_45232000:"Ancillary works for pipelines and cables",
   code_45232100:"Ancillary works for water pipelines",
   code_45232120:"Irrigation works",
   code_45232121:"Irrigation piping construction work",
   code_45232130:"Storm-water piping construction work",
   code_45232140:"District-heating mains construction work",
   code_45232141:"Heating works",
   code_45232142:"Heat-transfer station construction work",
   code_45232150:"Works related to water-distribution pipelines",
   code_45232151:"Water-main refurbishment construction work",
   code_45232152:"Pumping station construction work",
   code_45232153:"Construction work for water towers",
   code_45232154:"Construction work of elevated tanks for drinking water",
   code_45232200:"Ancillary works for electricity power lines",
   code_45232210:"Overhead line construction",
   code_45232220:"Substation construction work",
   code_45232221:"Transformer substation",
   code_45232300:"Construction and ancillary works for telephone and communication lines",
   code_45232310:"Construction work for telephone lines",
   code_45232311:"Roadside emergency telephone lines",
   code_45232320:"Cable broadcasting lines",
   code_45232330:"Erection of aerials",
   code_45232331:"Ancillary works for broadcasting",
   code_45232332:"Ancillary works for telecommunications",
   code_45232340:"Mobile-telephone base-stations construction work",
   code_45232400:"Sewer construction work",
   code_45232410:"Sewerage work",
   code_45232411:"Foul-water piping construction work",
   code_45232420:"Sewage work",
   code_45232421:"Sewage treatment works",
   code_45232422:"Sludge-treatment works",
   code_45232423:"Sewage pumping stations construction work",
   code_45232424:"Sewage outfall construction work",
   code_45232430:"Water-treatment work",
   code_45232431:"Wastewater pumping station",
   code_45232440:"Construction work for sewage pipes",
   code_45232450:"Drainage construction works",
   code_45232451:"Drainage and surface works",
   code_45232452:"Drainage works",
   code_45232453:"Drains construction work",
   code_45232454:"Rain-water basin construction work",
   code_45232460:"Sanitary works",
   code_45232470:"Waste transfer station",
   code_45233000:"Construction, foundation and surface works for highways, roads",
   code_45233100:"Construction work for highways, roads",
   code_45233110:"Motorway construction works",
   code_45233120:"Road construction works",
   code_45233121:"Main road construction works",
   code_45233122:"Ring road construction work",
   code_45233123:"Secondary road construction work",
   code_45233124:"Trunk road construction work",
   code_45233125:"Road junction construction work",
   code_45233126:"Grade-separated junction construction work",
   code_45233127:"T-junction construction work",
   code_45233128:"Roundabout construction work",
   code_45233129:"Crossroad construction work",
   code_45233130:"Construction work for highways",
   code_45233131:"Construction work for elevated highways",
   code_45233139:"Highway maintenance work",
   code_45233140:"Roadworks",
   code_45233141:"Road-maintenance works",
   code_45233142:"Road-repair works",
   code_45233144:"Overpass construction work",
   code_45233150:"Traffic-calming works",
   code_45233160:"Paths and other metalled surfaces",
   code_45233161:"Footpath construction work",
   code_45233162:"Cycle path construction work",
   code_45233200:"Various surface works",
   code_45233210:"Surface work for highways",
   code_45233220:"Surface work for roads",
   code_45233221:"Road-surface painting work",
   code_45233222:"Paving and asphalting works",
   code_45233223:"Carriageway resurfacing works",
   code_45233224:"Dual carriageway construction work",
   code_45233225:"Single carriageway construction work",
   code_45233226:"Access road construction work",
   code_45233227:"Slip road construction work",
   code_45233228:"Surface coating construction work",
   code_45233229:"Verge maintenance work",
   code_45233250:"Surfacing work except for roads",
   code_45233251:"Resurfacing works",
   code_45233252:"Surface work for streets",
   code_45233253:"Surface work for footpaths",
   code_45233260:"Pedestrian ways construction work",
   code_45233261:"Pedestrian overpass construction work",
   code_45233262:"Pedestrian zone construction work",
   code_45233270:"Parking-lot-surface painting work",
   code_45233280:"Erection of road-barriers",
   code_45233290:"Installation of road signs",
   code_45233291:"Installation of bollards",
   code_45233292:"Installation of safety equipment",
   code_45233293:"Installation of street furniture",
   code_45233294:"Installation of road signals",
   code_45233300:"Foundation work for highways, roads, streets and footpaths",
   code_45233310:"Foundation work for highways",
   code_45233320:"Foundation work for roads",
   code_45233330:"Foundation work for streets",
   code_45233340:"Foundation work for footpaths",
   code_45234000:"Construction work for railways and cable transport systems",
   code_45234100:"Railway construction works",
   code_45234110:"Intercity railway works",
   code_45234111:"City railway construction work",
   code_45234112:"Railway depot construction work",
   code_45234113:"Demolition of tracks",
   code_45234114:"Railway embankment construction work",
   code_45234115:"Railway signalling works",
   code_45234116:"Track construction works",
   code_45234120:"Urban railway works",
   code_45234121:"Tramway works",
   code_45234122:"Underground railway works",
   code_45234123:"Partially underground railway works",
   code_45234124:"Underground passenger railway transport",
   code_45234125:"Underground railway station",
   code_45234126:"Tramline construction works",
   code_45234127:"Tramway depot construction work",
   code_45234128:"Tramway platforms construction work",
   code_45234129:"Urban railway track construction works",
   code_45234130:"Ballast construction works",
   code_45234140:"Level crossing construction works",
   code_45234160:"Catenary's construction works",
   code_45234170:"Locomotive-substations construction works",
   code_45234180:"Construction work for railways workshop",
   code_45234181:"Construction work for rail track sectioning cabins",
   code_45234200:"Cable-supported transport systems",
   code_45234210:"Cable-supported transport systems with cabins",
   code_45234220:"Construction work for ski lifts",
   code_45234230:"Construction work for chair lifts",
   code_45234240:"Funicular railway system",
   code_45234250:"Teleferic construction work",
   code_45235000:"Construction work for airfields, runways and manoeuvring surfaces",
   code_45235100:"Construction work for airports",
   code_45235110:"Construction work for airfields",
   code_45235111:"Airfield pavement construction work",
   code_45235200:"Runway construction works",
   code_45235210:"Runway resurfacing",
   code_45235300:"Construction work for aircraft-manoeuvring surfaces",
   code_45235310:"Taxiway construction work",
   code_45235311:"Taxiway pavement construction work",
   code_45235320:"Construction work for aircraft aprons",
   code_45236000:"Flatwork",
   code_45236100:"Flatwork for miscellaneous sports installations",
   code_45236110:"Flatwork for sports fields",
   code_45236111:"Flatwork for golf course",
   code_45236112:"Flatwork for tennis court",
   code_45236113:"Flatwork for racecourse",
   code_45236114:"Flatwork for running tracks",
   code_45236119:"Repair work on sports fields",
   code_45236200:"Flatwork for recreation installations",
   code_45236210:"Flatwork for children's play area",
   code_45236220:"Flatwork for zoo",
   code_45236230:"Flatwork for gardens",
   code_45236250:"Flatwork for parks",
   code_45236290:"Repair work on recreational areas",
   code_45236300:"Flatwork for cemeteries",
   code_45237000:"Stage construction works",
   code_45240000:"Construction work for water projects",
   code_45241000:"Harbour construction works",
   code_45241100:"Quay construction work",
   code_45241200:"Offshore terminal in situ construction work",
   code_45241300:"Pier construction work",
   code_45241400:"Dock construction work",
   code_45241500:"Wharf construction work",
   code_45241600:"Installation of port lighting equipment",
   code_45242000:"Waterside leisure facilities construction work",
   code_45242100:"Water-sports facilities construction work",
   code_45242110:"Launchway construction work",
   code_45242200:"Marina construction work",
   code_45242210:"Yacht harbour construction work",
   code_45243000:"Coastal-defence works",
   code_45243100:"Cliff-protection works",
   code_45243110:"Cliff-stabilisation works",
   code_45243200:"Breakwater construction work",
   code_45243300:"Sea wall construction work",
   code_45243400:"Beach-consolidation works",
   code_45243500:"Sea defences construction work",
   code_45243510:"Embankment works",
   code_45243600:"Quay wall construction work",
   code_45244000:"Marine construction works",
   code_45244100:"Marine installations",
   code_45244200:"Jetties",
   code_45245000:"Dredging and pumping works for water treatment plant installations",
   code_45246000:"River regulation and flood control works",
   code_45246100:"River-wall construction",
   code_45246200:"Riverbank protection works",
   code_45246400:"Flood-prevention works",
   code_45246410:"Flood-defences maintenance works",
   code_45246500:"Promenade construction work",
   code_45246510:"Boardwalk construction work",
   code_45247000:"Construction work for dams, canals, irrigation channels and aqueducts",
   code_45247100:"Construction work for waterways",
   code_45247110:"Canal construction",
   code_45247111:"Irrigation channel construction work",
   code_45247112:"Drainage canal construction work",
   code_45247120:"Waterways except canals",
   code_45247130:"Aqueduct construction work",
   code_45247200:"Construction work for dams and similar fixed structures",
   code_45247210:"Dam construction work",
   code_45247211:"Dam wall construction work",
   code_45247212:"Dam-reinforcement works",
   code_45247220:"Weir construction work",
   code_45247230:"Dyke construction work",
   code_45247240:"Static barrage construction work",
   code_45247270:"Reservoir construction works",
   code_45248000:"Construction work for hydro-mechanical structures",
   code_45248100:"Canal locks construction work",
   code_45248200:"Dry docks construction work",
   code_45248300:"Construction work for floating docks",
   code_45248400:"Landing stages construction work",
   code_45248500:"Movable barrages construction work",
   code_45250000:"Construction works for plants, mining and manufacturing and for buildings relating to the oil and gas industry",
   code_45251000:"Construction works for power plants and heating plants",
   code_45251100:"Construction work for power plant",
   code_45251110:"Nuclear-power station construction work",
   code_45251111:"Construction work for nuclear reactors",
   code_45251120:"Hydro-electric plant construction work",
   code_45251140:"Thermal power plant construction work",
   code_45251141:"Geothermal power station construction work",
   code_45251142:"Wood-fired power station construction work",
   code_45251143:"Compressed-air generating plant construction work",
   code_45251150:"Construction work for cooling towers",
   code_45251160:"Wind-power installation works",
   code_45251200:"Heating plant construction work",
   code_45251220:"Cogeneration plant construction work",
   code_45251230:"Steam-generation plant construction work",
   code_45251240:"Landfill-gas electricity generating plant construction work",
   code_45251250:"District-heating plant construction work",
   code_45252000:"Construction works for sewage treatment plants, purification plants and refuse incineration plants",
   code_45252100:"Sewage-treatment plant construction work",
   code_45252110:"Mobile plant construction work",
   code_45252120:"Water-treatment plant construction work",
   code_45252121:"Sedimentation installations",
   code_45252122:"Sewage digesters",
   code_45252123:"Screening installations",
   code_45252124:"Dredging and pumping works",
   code_45252125:"Rock-dumping work",
   code_45252126:"Drinking-water treatment plant construction work",
   code_45252127:"Wastewater treatment plant construction work",
   code_45252130:"Sewage plant equipment",
   code_45252140:"Sludge-dewatering plant construction work",
   code_45252150:"Coal-handling plant construction work",
   code_45252200:"Purification plant equipment",
   code_45252210:"Water purification plant construction work",
   code_45252300:"Refuse-incineration plant construction work",
   code_45253000:"Construction work for chemical-processing plant",
   code_45253100:"Demineralisation plant construction work",
   code_45253200:"Desulphurisation plant construction work",
   code_45253300:"Distilling or rectifying plant construction work",
   code_45253310:"Water-distillation plants construction work",
   code_45253320:"Alcohol-distillation plants construction work",
   code_45253400:"Construction work for petrochemical plant",
   code_45253500:"Construction work for pharmaceutical plant",
   code_45253600:"Deionisation plant construction work",
   code_45253700:"Digestion plant construction work",
   code_45253800:"Composting plant construction work",
   code_45254000:"Construction work for mining and manufacturing",
   code_45254100:"Construction work for mining",
   code_45254110:"Pithead construction work",
   code_45254200:"Construction work for manufacturing plant",
   code_45255000:"Construction work for the oil and gas industry",
   code_45255100:"Construction work for production platforms",
   code_45255110:"Wells construction work",
   code_45255120:"Platforms facilities construction work",
   code_45255121:"Topside facilities construction work",
   code_45255200:"Oil refinery construction work",
   code_45255210:"Oil terminal construction work",
   code_45255300:"Gas terminal construction work",
   code_45255400:"Fabrication work",
   code_45255410:"Offshore fabrication work",
   code_45255420:"Onshore fabrication work",
   code_45255430:"Demolition of oil platforms",
   code_45255500:"Drilling and exploration work",
   code_45255600:"Coiled-tubing wellwork",
   code_45255700:"Coal-gasification plant construction work",
   code_45255800:"Gas-production plant construction work",
   code_45259000:"Repair and maintenance of plant",
   code_45259100:"Wastewater-plant repair and maintenance work",
   code_45259200:"Purification-plant repair and maintenance work",
   code_45259300:"Heating-plant repair and maintenance work",
   code_45259900:"Plant upgrade work",
   code_45260000:"Roof works and other special trade construction works",
   code_45261000:"Erection and related works of roof frames and coverings",
   code_45261100:"Roof-framing work",
   code_45261200:"Roof-covering and roof-painting work",
   code_45261210:"Roof-covering work",
   code_45261211:"Roof-tiling work",
   code_45261212:"Roof-slating work",
   code_45261213:"Metal roof-covering work",
   code_45261214:"Bituminous roof-covering work",
   code_45261215:"Solar panel roof-covering work",
   code_45261220:"Roof-painting and other coating work",
   code_45261221:"Roof-painting work",
   code_45261222:"Cement roof-coating work",
   code_45261300:"Flashing and guttering work",
   code_45261310:"Flashing work",
   code_45261320:"Guttering work",
   code_45261400:"Sheeting work",
   code_45261410:"Roof insulation work",
   code_45261420:"Waterproofing work",
   code_45261900:"Roof repair and maintenance work",
   code_45261910:"Roof repair",
   code_45261920:"Roof maintenance work",
   code_45262000:"Special trade construction works other than roof works",
   code_45262100:"Scaffolding work",
   code_45262110:"Scaffolding dismantling work",
   code_45262120:"Scaffolding erection work",
   code_45262200:"Foundation work and water-well drilling",
   code_45262210:"Foundation work",
   code_45262211:"Pile driving",
   code_45262212:"Trench sheeting work",
   code_45262213:"Diaphragm wall technique",
   code_45262220:"Water-well drilling",
   code_45262300:"Concrete work",
   code_45262310:"Reinforced-concrete work",
   code_45262311:"Concrete carcassing work",
   code_45262320:"Screed works",
   code_45262321:"Floor-screed works",
   code_45262330:"Concrete repair work",
   code_45262340:"Grouting work",
   code_45262350:"Unreinforced-concrete work",
   code_45262360:"Cementing work",
   code_45262370:"Concrete-coating work",
   code_45262400:"Structural steel erection work",
   code_45262410:"Structural steel erection work for buildings",
   code_45262420:"Structural steel erection work for structures",
   code_45262421:"Offshore mooring work",
   code_45262422:"Subsea drilling work",
   code_45262423:"Deck-fabrication work",
   code_45262424:"Offshore-module fabrication work",
   code_45262425:"Jacket-fabrication work",
   code_45262426:"Pile-fabrication work",
   code_45262500:"Masonry and bricklaying work",
   code_45262510:"Stonework",
   code_45262511:"Stone carving",
   code_45262512:"Dressed stonework",
   code_45262520:"Bricklaying work",
   code_45262521:"Facing brickwork",
   code_45262522:"Masonry work",
   code_45262600:"Miscellaneous special-trade construction work",
   code_45262610:"Industrial chimneys",
   code_45262620:"Supporting walls",
   code_45262630:"Construction of furnaces",
   code_45262640:"Environmental improvement works",
   code_45262650:"Cladding works",
   code_45262660:"Asbestos-removal work",
   code_45262670:"Metalworking",
   code_45262680:"Welding",
   code_45262690:"Refurbishment of run-down buildings",
   code_45262700:"Building alteration work",
   code_45262710:"Fresco maintenance work",
   code_45262800:"Building extension work",
   code_45262900:"Balcony work",
   code_45300000:"Building installation work",
   code_45310000:"Electrical installation work",
   code_45311000:"Electrical wiring and fitting work",
   code_45311100:"Electrical wiring work",
   code_45311200:"Electrical fitting work",
   code_45312000:"Alarm system and antenna installation work",
   code_45312100:"Fire-alarm system installation work",
   code_45312200:"Burglar-alarm system installation work",
   code_45312300:"Antenna installation work",
   code_45312310:"Lightning-protection works",
   code_45312311:"Lightning-conductor installation work",
   code_45312320:"Television aerial installation work",
   code_45312330:"Radio aerial installation work",
   code_45313000:"Lift and escalator installation work",
   code_45313100:"Lift installation work",
   code_45313200:"Escalator installation work",
   code_45313210:"Travelator installation work",
   code_45314000:"Installation of telecommunications equipment",
   code_45314100:"Installation of telephone exchanges",
   code_45314120:"Installation of switchboards",
   code_45314200:"Installation of telephone lines",
   code_45314300:"Installation of cable infrastructure",
   code_45314310:"Installation of cable laying",
   code_45314320:"Installation of computer cabling",
   code_45315000:"Electrical installation work of heating and other electrical building-equipment",
   code_45315100:"Electrical engineering installation works",
   code_45315200:"Turbine works",
   code_45315300:"Electricity supply installations",
   code_45315400:"High voltage installation work",
   code_45315500:"Medium-voltage installation work",
   code_45315600:"Low-voltage installation work",
   code_45315700:"Switching station installation work",
   code_45316000:"Installation work of illumination and signalling systems",
   code_45316100:"Installation of outdoor illumination equipment",
   code_45316110:"Installation of road lighting equipment",
   code_45316200:"Installation of signalling equipment",
   code_45316210:"Installation of traffic monitoring equipment",
   code_45316211:"Installation of illuminated road signs",
   code_45316212:"Installation of traffic lights",
   code_45316213:"Installation of traffic guidance equipment",
   code_45316220:"Installation of airport signalling equipment",
   code_45316230:"Installation of port signalling equipment",
   code_45317000:"Other electrical installation work",
   code_45317100:"Electrical installation work of pumping equipment",
   code_45317200:"Electrical installation work of transformers",
   code_45317300:"Electrical installation work of electrical distribution apparatus",
   code_45317400:"Electrical installation work of filtration equipment",
   code_45320000:"Insulation work",
   code_45321000:"Thermal insulation work",
   code_45323000:"Sound insulation work",
   code_45324000:"Plasterboard works",
   code_45330000:"Plumbing and sanitary works",
   code_45331000:"Heating, ventilation and air-conditioning installation work",
   code_45331100:"Central-heating installation work",
   code_45331110:"Boiler installation work",
   code_45331200:"Ventilation and air-conditioning installation work",
   code_45331210:"Ventilation installation work",
   code_45331211:"Outdoor ventilation installation work",
   code_45331220:"Air-conditioning installation work",
   code_45331221:"Partial air-conditioning installation work",
   code_45331230:"Installation work of cooling equipment",
   code_45331231:"Installation work of refrigeration equipment",
   code_45332000:"Plumbing and drain-laying work",
   code_45332200:"Water plumbing work",
   code_45332300:"Drain-laying work",
   code_45332400:"Sanitary fixture installation work",
   code_45333000:"Gas-fitting installation work",
   code_45333100:"Gas regulation equipment installation work",
   code_45333200:"Gas meter installation work",
   code_45340000:"Fencing, railing and safety equipment installation work",
   code_45341000:"Erection of railings",
   code_45342000:"Erection of fencing",
   code_45343000:"Fire-prevention installation works",
   code_45343100:"Fireproofing work",
   code_45343200:"Firefighting equipment installation work",
   code_45343210:"CO2 fire-extinguishing equipment installation work",
   code_45343220:"Fire-extinguishers installation work",
   code_45343230:"Sprinkler systems installation work",
   code_45350000:"Mechanical installations",
   code_45351000:"Mechanical engineering installation works",
   code_45400000:"Building completion work",
   code_45410000:"Plastering work",
   code_45420000:"Joinery and carpentry installation work",
   code_45421000:"Joinery work",
   code_45421100:"Installation of doors and windows and related components",
   code_45421110:"Installation of door and window frames",
   code_45421111:"Installation of door frames",
   code_45421112:"Installation of window frames",
   code_45421120:"Installation of thresholds",
   code_45421130:"Installation of doors and windows",
   code_45421131:"Installation of doors",
   code_45421132:"Installation of windows",
   code_45421140:"Installation of metal joinery except doors and windows",
   code_45421141:"Installation of partitioning",
   code_45421142:"Installation of shutters",
   code_45421143:"Installation work of blinds",
   code_45421144:"Installation work of awnings",
   code_45421145:"Installation work of roller blinds",
   code_45421146:"Installation of suspended ceilings",
   code_45421147:"Installation of grilles",
   code_45421148:"Installation of gates",
   code_45421150:"Non-metal joinery installation work",
   code_45421151:"Installation of fitted kitchens",
   code_45421152:"Installation of partition walls",
   code_45421153:"Installation of built-in furniture",
   code_45421160:"Ironmongery work",
   code_45422000:"Carpentry installation work",
   code_45422100:"Woodwork",
   code_45430000:"Floor and wall covering work",
   code_45431000:"Tiling work",
   code_45431100:"Floor-tiling work",
   code_45431200:"Wall-tiling work",
   code_45432000:"Floor-laying and covering, wall-covering and wall-papering work",
   code_45432100:"Floor laying and covering work",
   code_45432110:"Floor-laying work",
   code_45432111:"Laying work of flexible floor coverings",
   code_45432112:"Laying of paving",
   code_45432113:"Parquet flooring",
   code_45432114:"Wood flooring work",
   code_45432120:"False floor installation work",
   code_45432121:"Computer floors",
   code_45432130:"Floor-covering work",
   code_45432200:"Wall-covering and wall-papering work",
   code_45432210:"Wall-covering work",
   code_45432220:"Wall-papering work",
   code_45440000:"Painting and glazing work",
   code_45441000:"Glazing work",
   code_45442000:"Application work of protective coatings",
   code_45442100:"Painting work",
   code_45442110:"Painting work of buildings",
   code_45442120:"Painting and protective-coating work of structures",
   code_45442121:"Painting work of structures",
   code_45442180:"Repainting work",
   code_45442190:"Paint-stripping work",
   code_45442200:"Application work of anti-corrosive coatings",
   code_45442210:"Galvanizing works",
   code_45442300:"Surface-protection work",
   code_45443000:"Facade work",
   code_45450000:"Other building completion work",
   code_45451000:"Decoration work",
   code_45451100:"Ornamentation fitting work",
   code_45451200:"Panelling work",
   code_45451300:"Interior gardens",
   code_45452000:"Exterior cleaning work for buildings",
   code_45452100:"Blast cleaning work for building exteriors",
   code_45453000:"Overhaul and refurbishment work",
   code_45453100:"Refurbishment work",
   code_45454000:"Restructuring work",
   code_45454100:"Restoration work",
   code_45500000:"Hire of construction and civil engineering machinery and equipment with operator",
   code_45510000:"Hire of cranes with operator",
   code_45520000:"Hire of earthmoving equipment with operator",
   code_48000000:"Software package and information systems",
   code_48100000:"Industry specific software package",
   code_48110000:"Point of sale (POS) software package",
   code_48120000:"Flight control software package",
   code_48121000:"Air traffic control software package",
   code_48130000:"Aviation ground support and test software package",
   code_48131000:"Aviation ground support software package",
   code_48132000:"Aviation test software package",
   code_48140000:"Railway traffic control software package",
   code_48150000:"Industrial control software package",
   code_48151000:"Computer control system",
   code_48160000:"Library software package",
   code_48161000:"Library management system",
   code_48170000:"Compliance software package",
   code_48180000:"Medical software package",
   code_48190000:"Educational software package",
   code_48200000:"Networking, Internet and intranet software package",
   code_48210000:"Networking software package",
   code_48211000:"Platform interconnectivity software package",
   code_48212000:"Optical jukebox server software package",
   code_48213000:"Operating system enhancement software package",
   code_48214000:"Network operating system software package",
   code_48215000:"Networking developers' software package",
   code_48216000:"Network connectivity terminal emulation software package",
   code_48217000:"Transaction-processing software package",
   code_48217100:"Mainframe transaction processing software package",
   code_48217200:"Minicomputer transaction processing software package",
   code_48217300:"Microcomputer transaction processing software package",
   code_48218000:"License management software package",
   code_48219000:"Miscellaneous networking software package",
   code_48219100:"Gateway software package",
   code_48219200:"Compact disk (CD) server software package",
   code_48219300:"Administration software package",
   code_48219400:"Transaction server software package",
   code_48219500:"Switch or router software package",
   code_48219600:"Multiplexer software package",
   code_48219700:"Communications server software package",
   code_48219800:"Bridge software package",
   code_48220000:"Internet and intranet software package",
   code_48221000:"Internet browsing software package",
   code_48222000:"Web server software package",
   code_48223000:"Electronic mail software package",
   code_48224000:"Web page editing software package",
   code_48300000:"Document creation, drawing, imaging, scheduling and productivity software package",
   code_48310000:"Document creation software package",
   code_48311000:"Document management software package",
   code_48311100:"Document management system",
   code_48312000:"Electronic publishing software package",
   code_48313000:"Optical-character-recognition (OCR) software package",
   code_48313100:"Optical reading system",
   code_48314000:"Voice recognition software package",
   code_48315000:"Desktop-publishing software package",
   code_48316000:"Presentation software package",
   code_48317000:"Word-processing software package",
   code_48318000:"Scanner software package",
   code_48319000:"Spell checkers",
   code_48320000:"Drawing and imaging software package",
   code_48321000:"Computer-aided design (CAD) software package",
   code_48321100:"Computer-aided design (CAD) system",
   code_48322000:"Graphics software package",
   code_48323000:"Computer-aided manufacturing (CAM) software package",
   code_48324000:"Charting software package",
   code_48325000:"Form-making software package",
   code_48326000:"Mapping software package",
   code_48326100:"Digital mapping system",
   code_48327000:"Drawing and painting software package",
   code_48328000:"Image-processing software package",
   code_48329000:"Imaging and archiving system",
   code_48330000:"Scheduling and productivity software package",
   code_48331000:"Project management software package",
   code_48332000:"Scheduling software package",
   code_48333000:"Contact management software package",
   code_48400000:"Business transaction and personal business software package",
   code_48410000:"Investment management and tax preparation software package",
   code_48411000:"Investment management software package",
   code_48412000:"Tax preparation software package",
   code_48420000:"Facilities management software package and software package suite",
   code_48421000:"Facilities management software package",
   code_48422000:"Software package suites",
   code_48430000:"Inventory management software package",
   code_48440000:"Financial analysis and accounting software package",
   code_48441000:"Financial analysis software package",
   code_48442000:"Financial systems software package",
   code_48443000:"Accounting software package",
   code_48444000:"Accounting system",
   code_48444100:"Billing system",
   code_48445000:"Customer Relation Management software package",
   code_48450000:"Time accounting or human resources software package",
   code_48451000:"Enterprise resource planning software package",
   code_48460000:"Analytical, scientific, mathematical or forecasting software package",
   code_48461000:"Analytical or scientific software package",
   code_48462000:"Mathematical or forecasting software package",
   code_48463000:"Statistical software package",
   code_48470000:"Auction software package",
   code_48480000:"Sales, marketing and business intelligence software package",
   code_48481000:"Sales or marketing software package",
   code_48482000:"Business intelligence software package",
   code_48490000:"Procurement software package",
   code_48500000:"Communication and multimedia software package",
   code_48510000:"Communication software package",
   code_48511000:"Desktop communications software package",
   code_48512000:"Interactive voice response software package",
   code_48513000:"Modem software package",
   code_48514000:"Remote access software package",
   code_48515000:"Video conferencing software package",
   code_48516000:"Exchange software package",
   code_48517000:"IT software package",
   code_48518000:"Emulation software package",
   code_48519000:"Memory-management software package",
   code_48520000:"Multimedia software package",
   code_48521000:"Music or sound editing software package",
   code_48522000:"Virtual keyboard software package",
   code_48600000:"Database and operating software package",
   code_48610000:"Database systems",
   code_48611000:"Database software package",
   code_48612000:"Database-management system",
   code_48613000:"Electronic data management (EDM)",
   code_48614000:"Data-acquisition system",
   code_48620000:"Operating systems",
   code_48621000:"Mainframe operating system software package",
   code_48622000:"Minicomputer operating system software package",
   code_48623000:"Microcomputer operating system software package",
   code_48624000:"Personal computer (PC) operating system software package",
   code_48625000:"Open systems operating systems",
   code_48626000:"Clustering software package",
   code_48627000:"Real-time operating system software package",
   code_48628000:"Micro-channel architecture",
   code_48700000:"Software package utilities",
   code_48710000:"Backup or recovery software package",
   code_48720000:"Bar coding software package",
   code_48730000:"Security software package",
   code_48731000:"File security software package",
   code_48732000:"Data security software package",
   code_48740000:"Foreign language translation software package",
   code_48750000:"Storage media loading software package",
   code_48760000:"Virus protection software package",
   code_48761000:"Anti-virus software package",
   code_48770000:"General, compression and print utility software package",
   code_48771000:"General utility software package",
   code_48772000:"Compression utilities",
   code_48773000:"Print utility software package",
   code_48773100:"Print-spooling software package",
   code_48780000:"System, storage and content management software package",
   code_48781000:"System management software package",
   code_48782000:"Storage management software package",
   code_48783000:"Content management software package",
   code_48790000:"Version checker software package",
   code_48800000:"Information systems and servers",
   code_48810000:"Information systems",
   code_48811000:"E-mail system",
   code_48812000:"Financial information systems",
   code_48813000:"Passenger information system",
   code_48813100:"Electronic bulletin boards",
   code_48813200:"Real-time passenger information system",
   code_48814000:"Medical information systems",
   code_48814100:"Nursing information system",
   code_48814200:"Patient-administration system",
   code_48814300:"Theatre management system",
   code_48814400:"Clinical information system",
   code_48814500:"Casemix system",
   code_48820000:"Servers",
   code_48821000:"Network servers",
   code_48822000:"Computer servers",
   code_48823000:"File servers",
   code_48824000:"Printer servers",
   code_48825000:"Web servers",
   code_48900000:"Miscellaneous software package and computer systems",
   code_48910000:"Computer game software package, family titles and screen savers",
   code_48911000:"Computer game software package",
   code_48912000:"Family titles",
   code_48913000:"Screen savers",
   code_48920000:"Office automation software package",
   code_48921000:"Automation system",
   code_48930000:"Training and entertainment software package",
   code_48931000:"Training software package",
   code_48932000:"Entertainment software package",
   code_48940000:"Pattern design and calendar software package",
   code_48941000:"Pattern design software package",
   code_48942000:"Calendar software package",
   code_48950000:"Boat-location and public address system",
   code_48951000:"Boat-location system",
   code_48952000:"Public address systems",
   code_48960000:"Drivers and system software package",
   code_48961000:"Ethernet drivers",
   code_48962000:"Graphics card drivers",
   code_48970000:"Print shop software package",
   code_48971000:"Address book making software package",
   code_48972000:"Label making software package",
   code_48980000:"Programming languages and tools",
   code_48981000:"Compiling software packages",
   code_48982000:"Configuration management software package",
   code_48983000:"Development software package",
   code_48984000:"Graphical user interface (GUI) tools",
   code_48985000:"Programming languages",
   code_48986000:"Program testing software package",
   code_48987000:"Debugging software package",
   code_48990000:"Spreadsheets and enhancement software package",
   code_48991000:"Spreadsheet software package",
   code_50000000:"Repair and maintenance services",
   code_50100000:"Repair, maintenance and associated services of vehicles and related equipment",
   code_50110000:"Repair and maintenance services of motor vehicles and associated equipment",
   code_50111000:"Fleet management, repair and maintenance services",
   code_50111100:"Vehicle-fleet management services",
   code_50111110:"Vehicle-fleet-support services",
   code_50112000:"Repair and maintenance services of cars",
   code_50112100:"Car repair services",
   code_50112110:"Body-repair services for vehicles",
   code_50112111:"Panel-beating services",
   code_50112120:"Windscreen replacement services",
   code_50112200:"Car maintenance services",
   code_50112300:"Car-washing and similar services",
   code_50113000:"Repair and maintenance services of buses",
   code_50113100:"Bus repair services",
   code_50113200:"Bus maintenance services",
   code_50114000:"Repair and maintenance services of trucks",
   code_50114100:"Truck repair services",
   code_50114200:"Truck maintenance services",
   code_50115000:"Repair and maintenance services of motorcycles",
   code_50115100:"Motorcycle repair services",
   code_50115200:"Motorcycle maintenance services",
   code_50116000:"Maintenance and repair services related to specific parts of vehicles",
   code_50116100:"Electrical-system repair services",
   code_50116200:"Repair and maintenance services of vehicle brakes and brake parts",
   code_50116300:"Repair and maintenance services of vehicle gearboxes",
   code_50116400:"Repair and maintenance services of vehicle transmissions",
   code_50116500:"Tyre repair services, including fitting and balancing",
   code_50116510:"Tyre-remoulding services",
   code_50116600:"Repair and maintenance services of starter motors",
   code_50117000:"Vehicle conversion and reconditioning services",
   code_50117100:"Motor vehicle conversion services",
   code_50117200:"Ambulance conversion services",
   code_50117300:"Reconditioning services of vehicles",
   code_50118000:"Automobile emergency road services",
   code_50118100:"Breakdown and recovery services for cars",
   code_50118110:"Vehicle towing-away services",
   code_50118200:"Breakdown and recovery services for commercial vehicles",
   code_50118300:"Breakdown and recovery services for buses",
   code_50118400:"Breakdown and recovery services for motor vehicles",
   code_50118500:"Breakdown and recovery services for motorcycles",
   code_50190000:"Demolition services of vehicles",
   code_50200000:"Repair, maintenance and associated services related to aircraft, railways, roads and marine equipment",
   code_50210000:"Repair, maintenance and associated services related to aircraft and other equipment",
   code_50211000:"Repair and maintenance services of aircraft",
   code_50211100:"Aircraft maintenance services",
   code_50211200:"Aircraft repair services",
   code_50211210:"Repair and maintenance services of aircraft engines",
   code_50211211:"Aircraft-engine maintenance services",
   code_50211212:"Aircraft-engine repair services",
   code_50211300:"Reconditioning services of aircraft",
   code_50211310:"Reconditioning services of aircraft engines",
   code_50212000:"Repair and maintenance services of helicopters",
   code_50220000:"Repair, maintenance and associated services related to railways and other equipment",
   code_50221000:"Repair and maintenance services of locomotives",
   code_50221100:"Repair and maintenance services of locomotive gearboxes",
   code_50221200:"Repair and maintenance services of locomotive transmissions",
   code_50221300:"Repair and maintenance services of locomotive wheelsets",
   code_50221400:"Repair and maintenance services of locomotive brakes and brake parts",
   code_50222000:"Repair and maintenance services of rolling stock",
   code_50222100:"Repair and maintenance services of dampers",
   code_50223000:"Reconditioning services of locomotives",
   code_50224000:"Reconditioning services of rolling stock",
   code_50224100:"Reconditioning services of rolling stock seats",
   code_50224200:"Reconditioning services of passenger coaches",
   code_50225000:"Railway-track maintenance services",
   code_50229000:"Demolition of rolling stock",
   code_50230000:"Repair, maintenance and associated services related to roads and other equipment",
   code_50232000:"Maintenance services of public-lighting installations and traffic lights",
   code_50232100:"Street-lighting maintenance services",
   code_50232110:"Commissioning of public lighting installations",
   code_50232200:"Traffic-signal maintenance services",
   code_50240000:"Repair, maintenance and associated services related to marine and other equipment",
   code_50241000:"Repair and maintenance services of ships",
   code_50241100:"Vessel repair services",
   code_50241200:"Ferry repair services",
   code_50242000:"Conversion services of ships",
   code_50243000:"Demolition services of ships",
   code_50244000:"Reconditioning services of ships or boats",
   code_50245000:"Upgrading services of ships",
   code_50246000:"Harbour equipment maintenance services",
   code_50246100:"Dry-docking services",
   code_50246200:"Buoy maintenance services",
   code_50246300:"Repair and maintenance services of floating structures",
   code_50246400:"Repair and maintenance services of floating platforms",
   code_50300000:"Repair, maintenance and associated services related to personal computers, office equipment, telecommunications and audio-visual equipment",
   code_50310000:"Maintenance and repair of office machinery",
   code_50311000:"Maintenance and repair of office accounting machinery",
   code_50311400:"Maintenance and repair of calculators and accounting machinery",
   code_50312000:"Maintenance and repair of computer equipment",
   code_50312100:"Maintenance and repair of mainframe computers",
   code_50312110:"Maintenance of mainframe computers",
   code_50312120:"Repair of mainframe computers",
   code_50312200:"Maintenance and repair of minicomputers",
   code_50312210:"Maintenance of minicomputers",
   code_50312220:"Repair of minicomputers",
   code_50312300:"Maintenance and repair of data network equipment",
   code_50312310:"Maintenance of data network equipment",
   code_50312320:"Repair of data network equipment",
   code_50312400:"Maintenance and repair of microcomputers",
   code_50312410:"Maintenance of microcomputers",
   code_50312420:"Repair of microcomputers",
   code_50312600:"Maintenance and repair of information technology equipment",
   code_50312610:"Maintenance of information technology equipment",
   code_50312620:"Repair of information technology equipment",
   code_50313000:"Maintenance and repair of reprographic machinery",
   code_50313100:"Photocopier repair services",
   code_50313200:"Photocopier maintenance services",
   code_50314000:"Repair and maintenance services of facsimile machines",
   code_50315000:"Repair and maintenance services of telephone-answering machines",
   code_50316000:"Maintenance and repair of ticket-issuing machinery",
   code_50317000:"Maintenance and repair of ticket-validation machinery",
   code_50320000:"Repair and maintenance services of personal computers",
   code_50321000:"Repair services of personal computers",
   code_50322000:"Maintenance services of personal computers",
   code_50323000:"Maintenance and repair of computer peripherals",
   code_50323100:"Maintenance of computer peripherals",
   code_50323200:"Repair of computer peripherals",
   code_50324000:"Support services of personal computers",
   code_50324100:"System maintenance services",
   code_50324200:"Preventive maintenance services",
   code_50330000:"Maintenance services of telecommunications equipment",
   code_50331000:"Repair and maintenance services of telecommunications lines",
   code_50332000:"Telecommunications-infrastructure maintenance services",
   code_50333000:"Maintenance services of radio-communications equipment",
   code_50333100:"Repair and maintenance services of radio transmitters",
   code_50333200:"Repair and maintenance services of radiotelephony apparatus",
   code_50334000:"Repair and maintenance services of line telephony and line telegraphy equipment",
   code_50334100:"Repair and maintenance services of line telephony equipment",
   code_50334110:"Telephone network maintenance services",
   code_50334120:"Upgrade services of telephone switching equipment",
   code_50334130:"Repair and maintenance services of telephone switching apparatus",
   code_50334140:"Repair and maintenance services of telephone sets",
   code_50334200:"Repair and maintenance services of line telegraphy equipment",
   code_50334300:"Repair and maintenance services of line telex equipment",
   code_50334400:"Communications system maintenance services",
   code_50340000:"Repair and maintenance services of audio-visual and optical equipment",
   code_50341000:"Repair and maintenance services of television equipment",
   code_50341100:"Repair and maintenance services of videotext equipment",
   code_50341200:"Repair and maintenance services of television transmitters",
   code_50342000:"Repair and maintenance services of audio equipment",
   code_50343000:"Repair and maintenance services of video equipment",
   code_50344000:"Repair and maintenance services of optical equipment",
   code_50344100:"Repair and maintenance services of photographic equipment",
   code_50344200:"Repair and maintenance services of cinematographic equipment",
   code_50400000:"Repair and maintenance services of medical and precision equipment",
   code_50410000:"Repair and maintenance services of measuring, testing and checking apparatus",
   code_50411000:"Repair and maintenance services of measuring apparatus",
   code_50411100:"Repair and maintenance services of water meters",
   code_50411200:"Repair and maintenance services of gas meters",
   code_50411300:"Repair and maintenance services of electricity meters",
   code_50411400:"Repair and maintenance services of tachometers",
   code_50411500:"Repair and maintenance services of industrial time-measuring equipment",
   code_50412000:"Repair and maintenance services of testing apparatus",
   code_50413000:"Repair and maintenance services of checking apparatus",
   code_50413100:"Repair and maintenance services of gas-detection equipment",
   code_50413200:"Repair and maintenance services of firefighting equipment",
   code_50420000:"Repair and maintenance services of medical and surgical equipment",
   code_50421000:"Repair and maintenance services of medical equipment",
   code_50421100:"Repair and maintenance services of wheelchairs",
   code_50421200:"Repair and maintenance services of X-ray equipment",
   code_50422000:"Repair and maintenance services of surgical equipment",
   code_50430000:"Repair and maintenance services of precision equipment",
   code_50431000:"Repair and maintenance services of watches",
   code_50432000:"Repair and maintenance services of clocks",
   code_50433000:"Calibration services",
   code_50500000:"Repair and maintenance services for pumps, valves, taps and metal containers and machinery",
   code_50510000:"Repair and maintenance services of pumps, valves, taps and metal containers",
   code_50511000:"Repair and maintenance services of pumps",
   code_50511100:"Repair and maintenance services of liquid pumps",
   code_50511200:"Repair and maintenance services of gas pumps",
   code_50512000:"Repair and maintenance services of valves",
   code_50513000:"Repair and maintenance services of taps",
   code_50514000:"Repair and maintenance services of metal containers",
   code_50514100:"Repair and maintenance services of tanks",
   code_50514200:"Repair and maintenance services of reservoirs",
   code_50514300:"Sleeving repair services",
   code_50530000:"Repair and maintenance services of machinery",
   code_50531000:"Repair and maintenance services for non-electrical machinery",
   code_50531100:"Repair and maintenance services of boilers",
   code_50531200:"Gas appliance maintenance services",
   code_50531300:"Repair and maintenance services of compressors",
   code_50531400:"Repair and maintenance services of cranes",
   code_50531500:"Repair and maintenance services of derricks",
   code_50531510:"Derrick-dismantling services",
   code_50532000:"Repair and maintenance services of electrical machinery, apparatus and associated equipment",
   code_50532100:"Repair and maintenance services of electric motors",
   code_50532200:"Repair and maintenance services of transformers",
   code_50532300:"Repair and maintenance services of generators",
   code_50532400:"Repair and maintenance services of electrical distribution equipment",
   code_50600000:"Repair and maintenance services of security and defence materials",
   code_50610000:"Repair and maintenance services of security equipment",
   code_50620000:"Repair and maintenance services of firearms and ammunition",
   code_50630000:"Repair and maintenance services of military vehicles",
   code_50640000:"Repair and maintenance services of warships",
   code_50650000:"Repair and maintenance services of military aircrafts, missiles and spacecrafts",
   code_50660000:"Repair and maintenance services of military electronic systems",
   code_50700000:"Repair and maintenance services of building installations",
   code_50710000:"Repair and maintenance services of electrical and mechanical building installations",
   code_50711000:"Repair and maintenance services of electrical building installations",
   code_50712000:"Repair and maintenance services of mechanical building installations",
   code_50720000:"Repair and maintenance services of central heating",
   code_50721000:"Commissioning of heating installations",
   code_50730000:"Repair and maintenance services of cooler groups",
   code_50740000:"Repair and maintenance services of escalators",
   code_50750000:"Lift-maintenance services",
   code_50760000:"Repair and maintenance of public conveniences",
   code_50800000:"Miscellaneous repair and maintenance services",
   code_50810000:"Repair services of jewellery",
   code_50820000:"Repair services of leather personal goods",
   code_50821000:"Repair services of boots",
   code_50822000:"Repair services of shoes",
   code_50830000:"Repair services of garments and textiles",
   code_50840000:"Repair and maintenance services of weapons and weapon systems",
   code_50841000:"Repair and maintenance services of weapons",
   code_50842000:"Repair and maintenance services of weapon systems",
   code_50850000:"Repair and maintenance services of furniture",
   code_50860000:"Repair and maintenance services of musical instruments",
   code_50870000:"Repair and maintenance services of playground equipment",
   code_50880000:"Repair and maintenance services of hotel and restaurant equipment",
   code_50881000:"Repair and maintenance services of hotel equipment",
   code_50882000:"Repair and maintenance services of restaurant equipment",
   code_50883000:"Repair and maintenance services of catering equipment",
   code_50884000:"Repair and maintenance services of camping equipment",
   code_51000000:"Installation services (except software)",
   code_51100000:"Installation services of electrical and mechanical equipment",
   code_51110000:"Installation services of electrical equipment",
   code_51111000:"Installation services of electric motors, generators and transformers",
   code_51111100:"Installation services of electric motors",
   code_51111200:"Installation services of generators",
   code_51111300:"Installation services of transformers",
   code_51112000:"Installation services of electricity distribution and control equipment",
   code_51112100:"Installation services of electricity distribution equipment",
   code_51112200:"Installation services of electricity control equipment",
   code_51120000:"Installation services of mechanical equipment",
   code_51121000:"Installation services of fitness equipment",
   code_51122000:"Installation services of flagpoles",
   code_51130000:"Installation services of steam generators, turbines, compressors and burners",
   code_51131000:"Installation services of steam generators",
   code_51133000:"Installation services of turbines",
   code_51133100:"Installation services of gas turbines",
   code_51134000:"Installation services of compressors",
   code_51135000:"Installation services of furnaces",
   code_51135100:"Installation services of burners",
   code_51135110:"Installation services of waste incinerators",
   code_51140000:"Installation services of engines",
   code_51141000:"Installation services of petrol engines",
   code_51142000:"Installation services of diesel engines",
   code_51143000:"Installation services of railway engines",
   code_51144000:"Installation services of vehicle engines",
   code_51145000:"Installation services of marine engines",
   code_51146000:"Installation services of aircraft engines",
   code_51200000:"Installation services of equipment for measuring, checking, testing and navigating",
   code_51210000:"Installation services of measuring equipment",
   code_51211000:"Installation services of time-measuring equipment",
   code_51212000:"Installation services of time register equipment",
   code_51213000:"Installation services of time recorder equipment",
   code_51214000:"Installation services of parking meter equipment",
   code_51215000:"Installation services of meteorological equipment",
   code_51216000:"Installation services of geological equipment",
   code_51220000:"Installation services of checking equipment",
   code_51221000:"Installation services of automatic airport check-in devices",
   code_51230000:"Installation services of testing equipment",
   code_51240000:"Installation services of navigating equipment",
   code_51300000:"Installation services of communications equipment",
   code_51310000:"Installation services of radio, television, sound and video equipment",
   code_51311000:"Installation services of radio equipment",
   code_51312000:"Installation services of television equipment",
   code_51313000:"Installation services of sound equipment",
   code_51314000:"Installation services of video equipment",
   code_51320000:"Installation services of radio and television transmitters",
   code_51321000:"Installation services of radio transmitters",
   code_51322000:"Installation services of television transmitters",
   code_51330000:"Installation services of radiotelephony apparatus",
   code_51340000:"Installation services of line telephony equipment",
   code_51350000:"Installation services of line telegraphy equipment",
   code_51400000:"Installation services of medical and surgical equipment",
   code_51410000:"Installation services of medical equipment",
   code_51411000:"Installation services of imaging equipment",
   code_51412000:"Installation services of dental and subspecialty equipment",
   code_51413000:"Installation services of radiotherapy equipment",
   code_51414000:"Installation services of mechanotherapy equipment",
   code_51415000:"Installation services of electrotherapy equipment",
   code_51416000:"Installation services of physical therapy equipment",
   code_51420000:"Installation services of surgical equipment",
   code_51430000:"Installation services of laboratory equipment",
   code_51500000:"Installation services of machinery and equipment",
   code_51510000:"Installation services of general-purpose machinery and equipment",
   code_51511000:"Installation services of lifting and handling equipment, except lifts and escalators",
   code_51511100:"Installation services of lifting equipment",
   code_51511110:"Installation services of cranes",
   code_51511200:"Installation services of handling equipment",
   code_51511300:"Installation services of suspended access equipment",
   code_51511400:"Installation services of special conveying systems",
   code_51514000:"Installation services of miscellaneous general-purpose machinery",
   code_51514100:"Installation services of liquid filtering or purifying machinery and apparatus",
   code_51514110:"Installation services of machinery and apparatus for filtering or purifying water",
   code_51520000:"Installation services of agricultural and forestry machinery",
   code_51521000:"Installation services of agricultural machinery",
   code_51522000:"Installation services of forestry machinery",
   code_51530000:"Installation services of machine tools",
   code_51540000:"Installation services of special-purpose machinery and equipment",
   code_51541000:"Installation services of mining, quarrying, construction and metallurgy machinery",
   code_51541100:"Installation services of mining machinery",
   code_51541200:"Installation services of quarrying machinery",
   code_51541300:"Installation services of construction machinery",
   code_51541400:"Installation services of metallurgy machinery",
   code_51542000:"Installation services of food-, beverage- and tobacco-processing machinery",
   code_51542100:"Installation services of food-processing machinery",
   code_51542200:"Installation services of beverage-processing machinery",
   code_51542300:"Installation services of tobacco-processing machinery",
   code_51543000:"Installation services of textile-, clothing- and leather-production machinery",
   code_51543100:"Installation services of textile-production machinery",
   code_51543200:"Installation services of clothing-production machinery",
   code_51543300:"Installation services of leather-production machinery",
   code_51543400:"Installation services of laundry washing, dry-cleaning and drying machines",
   code_51544000:"Installation services of paper- and paperboard-production machinery",
   code_51544100:"Installation services of paper-production machinery",
   code_51544200:"Installation services of paperboard-production machinery",
   code_51545000:"Installation services of street mailboxes",
   code_51550000:"Installation services of weapon systems",
   code_51600000:"Installation services of computers and office equipment",
   code_51610000:"Installation services of computers and information-processing equipment",
   code_51611000:"Installation services of computers",
   code_51611100:"Hardware installation services",
   code_51611110:"Installation services of airport real-time departures and arrival display screens or boards",
   code_51611120:"Installation services of railway real-time departures and arrival display screens or boards",
   code_51612000:"Installation services of information-processing equipment",
   code_51620000:"Installation services of office equipment",
   code_51700000:"Installation services of fire protection equipment",
   code_51800000:"Installation services of metal containers",
   code_51810000:"Installation services of tanks",
   code_51820000:"Installation services of reservoirs",
   code_51900000:"Installation services of guidance and control systems",
   code_55000000:"Hotel, restaurant and retail trade services",
   code_55100000:"Hotel services",
   code_55110000:"Hotel accommodation services",
   code_55120000:"Hotel meeting and conference services",
   code_55130000:"Other hotel services",
   code_55200000:"Camping sites and other non-hotel accommodation",
   code_55210000:"Youth hostel services",
   code_55220000:"Camping-site services",
   code_55221000:"Caravan-site services",
   code_55240000:"Holiday centre and holiday home services",
   code_55241000:"Holiday centre services",
   code_55242000:"Holiday home services",
   code_55243000:"Children's holiday-camp services",
   code_55250000:"Letting services of short-stay furnished accommodation",
   code_55260000:"Sleeping-car services",
   code_55270000:"Services provided by bed and breakfast establishments",
   code_55300000:"Restaurant and food-serving services",
   code_55310000:"Restaurant waiter services",
   code_55311000:"Restricted-clientele restaurant waiter services",
   code_55312000:"Unrestricted-clientele restaurant waiter services",
   code_55320000:"Meal-serving services",
   code_55321000:"Meal-preparation services",
   code_55322000:"Meal-cooking services",
   code_55330000:"Cafeteria services",
   code_55400000:"Beverage-serving services",
   code_55410000:"Bar management services",
   code_55500000:"Canteen and catering services",
   code_55510000:"Canteen services",
   code_55511000:"Canteen and other restricted-clientele cafeteria services",
   code_55512000:"Canteen management services",
   code_55520000:"Catering services",
   code_55521000:"Catering services for private households",
   code_55521100:"Meals-on-wheels services",
   code_55521200:"Meal delivery service",
   code_55522000:"Catering services for transport enterprises",
   code_55523000:"Catering services for other enterprises or other institutions",
   code_55523100:"School-meal services",
   code_55524000:"School catering services",
   code_55900000:"Retail trade services",
   code_60000000:"Transport services (excl. Waste transport)",
   code_60100000:"Road transport services",
   code_60112000:"Public road transport services",
   code_60120000:"Taxi services",
   code_60130000:"Special-purpose road passenger-transport services",
   code_60140000:"Non-scheduled passenger transport",
   code_60150000:"Passenger transport by animal-drawn vehicles",
   code_60160000:"Mail transport by road",
   code_60161000:"Parcel transport services",
   code_60170000:"Hire of passenger transport vehicles with driver",
   code_60171000:"Hire of passenger cars with driver",
   code_60172000:"Hire of buses and coaches with driver",
   code_60180000:"Hire of goods-transport vehicles with driver",
   code_60181000:"Hire of trucks with driver",
   code_60182000:"Hire of industrial vehicles with driver",
   code_60183000:"Hire of vans with driver",
   code_60200000:"Railway transport services",
   code_60210000:"Public transport services by railways",
   code_60220000:"Mail transport by railway",
   code_60300000:"Pipeline transport services",
   code_60400000:"Air transport services",
   code_60410000:"Scheduled air transport services",
   code_60411000:"Scheduled airmail transport services",
   code_60420000:"Non-scheduled air transport services",
   code_60421000:"Non-scheduled airmail transport services",
   code_60423000:"Air-charter services",
   code_60424000:"Hire of air transport equipment with crew",
   code_60424100:"Hire of aircraft with crew",
   code_60424110:"Hire of fixed-wing aircraft with crew",
   code_60424120:"Hire of helicopters with crew",
   code_60440000:"Aerial and related services",
   code_60441000:"Aerial spraying services",
   code_60442000:"Aerial forest-firefighting services",
   code_60443000:"Air-rescue services",
   code_60443100:"Air-sea rescue services",
   code_60444000:"Aircraft-operation services",
   code_60444100:"Pilot services",
   code_60445000:"Aircraft operating services",
   code_60500000:"Space transport services",
   code_60510000:"Satellite launch services",
   code_60520000:"Experimental payload services",
   code_60600000:"Water transport services",
   code_60610000:"Ferry transport services",
   code_60620000:"Transport by water of mail",
   code_60630000:"Cable-laying ship services",
   code_60640000:"Shipping operations",
   code_60650000:"Hire of water transport equipment with crew",
   code_60651000:"Hire of vessels with crew",
   code_60651100:"Hire of sea-going vessels with crew",
   code_60651200:"Hire of inland waterway vessels with crew",
   code_60651300:"Anti-pollution ship services",
   code_60651400:"Heavy-lift ship services",
   code_60651500:"Standby ship services",
   code_60651600:"Offshore supply ship services",
   code_60653000:"Hire of boats with crew",
   code_63000000:"Supporting and auxiliary transport services; travel agencies services",
   code_63100000:"Cargo handling and storage services",
   code_63110000:"Cargo handling services",
   code_63111000:"Container handling services",
   code_63112000:"Baggage handling services",
   code_63112100:"Passenger baggage handling services",
   code_63112110:"Baggage collection services",
   code_63120000:"Storage and warehousing services",
   code_63121000:"Storage and retrieval services",
   code_63121100:"Storage services",
   code_63121110:"Gas storage services",
   code_63122000:"Warehousing services",
   code_63500000:"Travel agency, tour operator and tourist assistance services",
   code_63510000:"Travel agency and similar services",
   code_63511000:"Organisation of package tours",
   code_63512000:"Sale of travel tickets and package tours services",
   code_63513000:"Tourist information services",
   code_63514000:"Tourist guide services",
   code_63515000:"Travel services",
   code_63516000:"Travel management services",
   code_63520000:"Transport agency services",
   code_63521000:"Freight transport agency services",
   code_63522000:"Ship brokerage services",
   code_63523000:"Port and forwarding agency services",
   code_63524000:"Transport document preparation services",
   code_63700000:"Support services for land, water and air transport",
   code_63710000:"Support services for land transport",
   code_63711000:"Support services for railway transport",
   code_63711100:"Train monitoring services",
   code_63711200:"Moving workshops services",
   code_63712000:"Support services for road transport",
   code_63712100:"Bus station services",
   code_63712200:"Highway operation services",
   code_63712210:"Highway toll services",
   code_63712300:"Bridge and tunnel operation services",
   code_63712310:"Bridge operating services",
   code_63712311:"Bridge toll services",
   code_63712320:"Tunnel operation services",
   code_63712321:"Tunnel toll services",
   code_63712400:"Parking services",
   code_63712500:"Weighbridge services",
   code_63712600:"Vehicle refuelling services",
   code_63712700:"Traffic control services",
   code_63712710:"Traffic monitoring services",
   code_63720000:"Support services for water transport",
   code_63721000:"Port and waterway operation services and associated services",
   code_63721100:"Bunkering services",
   code_63721200:"Port operation services",
   code_63721300:"Waterway operation services",
   code_63721400:"Ship refuelling services",
   code_63721500:"Passenger terminal operation services",
   code_63722000:"Ship-piloting services",
   code_63723000:"Berthing services",
   code_63724000:"Navigation services",
   code_63724100:"Offshore positioning services",
   code_63724110:"Lightship positioning services",
   code_63724200:"Lightship services",
   code_63724300:"Buoy positioning services",
   code_63724310:"Buoy marking services",
   code_63724400:"Lighthouse services",
   code_63725000:"Salvage and refloating services",
   code_63725100:"Vessel-salvaging services",
   code_63725200:"Standby vessel services",
   code_63725300:"Vessel refloating services",
   code_63726000:"Miscellaneous water transport support services",
   code_63726100:"Vessel registration services",
   code_63726200:"Ice-breaking services",
   code_63726300:"Vessel storage services",
   code_63726400:"Ship chartering services",
   code_63726500:"Vessel laying-up services",
   code_63726600:"Ship-operating services",
   code_63726610:"Ship-launching services",
   code_63726620:"ROV services",
   code_63726700:"Fishing-vessel services",
   code_63726800:"Research vessel services",
   code_63726900:"Anchor handling services",
   code_63727000:"Towing and pushing services of ships",
   code_63727100:"Towing services",
   code_63727200:"Pushing services",
   code_63730000:"Support services for air transport",
   code_63731000:"Airport operation services",
   code_63731100:"Airport slot coordination services",
   code_63732000:"Air-traffic control services",
   code_63733000:"Aircraft refuelling services",
   code_63734000:"Hangar services",
   code_64000000:"Postal and telecommunications services",
   code_64100000:"Post and courier services",
   code_64110000:"Postal services",
   code_64111000:"Postal services related to newspapers and periodicals",
   code_64112000:"Postal services related to letters",
   code_64113000:"Postal services related to parcels",
   code_64114000:"Post office counter services",
   code_64115000:"Mailbox rental",
   code_64116000:"Post-restante services",
   code_64120000:"Courier services",
   code_64121000:"Multi-modal courier services",
   code_64121100:"Mail delivery services",
   code_64121200:"Parcel delivery services",
   code_64122000:"Internal office mail and messenger services",
   code_64200000:"Telecommunications services",
   code_64210000:"Telephone and data transmission services",
   code_64211000:"Public-telephone services",
   code_64211100:"Local telephone services",
   code_64211200:"Long distance telephone services",
   code_64212000:"Mobile-telephone services",
   code_64212100:"Short Message Service (SMS) services",
   code_64212200:"Enhanced Messaging Service (EMS) services",
   code_64212300:"Multimedia Message Service (MMS) services",
   code_64212400:"Wireless Application Protocol (WAP) services",
   code_64212500:"General Packet Radio Services (GPRS) services",
   code_64212600:"Enhanced Data for GSM Evolution (EDGE) services",
   code_64212700:"Universal Mobile Telephone System (UMTS) services",
   code_64212800:"Pay phone provider services",
   code_64212900:"Pre-paid phone card provider services",
   code_64213000:"Shared-business telephone network services",
   code_64214000:"Dedicated-business telephone network services",
   code_64214100:"Satellite circuit rental services",
   code_64214200:"Telephone switchboard services",
   code_64214400:"Communication land-line rental",
   code_64215000:"IP telephone services",
   code_64216000:"Electronic message and information services",
   code_64216100:"Electronic message services",
   code_64216110:"Electronic data exchange services",
   code_64216120:"Electronic mail services",
   code_64216130:"Telex services",
   code_64216140:"Telegraph services",
   code_64216200:"Electronic information services",
   code_64216210:"Value-added information services",
   code_64216300:"Teletext services",
   code_64220000:"Telecommunication services except telephone and data transmission services",
   code_64221000:"Interconnection services",
   code_64222000:"Teleworking services",
   code_64223000:"Paging services",
   code_64224000:"Teleconferencing services",
   code_64225000:"Air-to-ground telecommunications services",
   code_64226000:"Telematics services",
   code_64227000:"Integrated telecommunications services",
   code_64228000:"Television and radio broadcast transmission services",
   code_64228100:"Television broadcast transmission services",
   code_64228200:"Radio broadcast transmission services",
   code_65000000:"Public utilities",
   code_65100000:"Water distribution and related services",
   code_65110000:"Water distribution",
   code_65111000:"Drinking-water distribution",
   code_65120000:"Operation of a water-purification plant",
   code_65121000:"Water demineralisation services",
   code_65122000:"Water desalination services",
   code_65123000:"Water softening services",
   code_65130000:"Operation of water supplies",
   code_65200000:"Gas distribution and related services",
   code_65210000:"Gas distribution",
   code_65300000:"Electricity distribution and related services",
   code_65310000:"Electricity distribution",
   code_65320000:"Operation of electrical installations",
   code_65400000:"Other sources of energy supplies and distribution",
   code_65410000:"Operation of a power plant",
   code_65500000:"Meter reading service",
   code_66000000:"Financial and insurance services",
   code_66100000:"Banking and investment services",
   code_66110000:"Banking services",
   code_66111000:"Central bank services",
   code_66112000:"Deposit services",
   code_66113000:"Credit granting services",
   code_66113100:"Micro-credit granting services",
   code_66114000:"Financial leasing services",
   code_66115000:"International payment transfer services",
   code_66120000:"Investment banking services and related services",
   code_66121000:"Mergers and acquisition services",
   code_66122000:"Corporate finance and venture capital services",
   code_66130000:"Brokerage and related securities and commodities services",
   code_66131000:"Security brokerage services",
   code_66131100:"Pension investment services",
   code_66132000:"Commodity brokerage services",
   code_66133000:"Processing and clearing services",
   code_66140000:"Portfolio management services",
   code_66141000:"Pension fund management services",
   code_66150000:"Financial markets administration services",
   code_66151000:"Financial market operational services",
   code_66151100:"Electronic marketplace retailing services",
   code_66152000:"Financial market regulatory services",
   code_66160000:"Trust and custody services",
   code_66161000:"Trust services",
   code_66162000:"Custody services",
   code_66170000:"Financial consultancy, financial transaction processing and clearing-house services",
   code_66171000:"Financial consultancy services",
   code_66172000:"Financial transaction processing and clearing-house services",
   code_66180000:"Foreign exchange services",
   code_66190000:"Loan brokerage services",
   code_66500000:"Insurance and pension services",
   code_66510000:"Insurance services",
   code_66511000:"Life insurance services",
   code_66512000:"Accident and health insurance services",
   code_66512100:"Accident insurance services",
   code_66512200:"Health insurance services",
   code_66512210:"Voluntary health insurance services",
   code_66512220:"Medical insurance services",
   code_66513000:"Legal insurance and all-risk insurance services",
   code_66513100:"Legal expenses insurance services",
   code_66513200:"Contractor's all-risk insurance services",
   code_66514000:"Freight insurance and Insurance services relating to transport",
   code_66514100:"Insurance related to Transport",
   code_66514110:"Motor vehicle insurance services",
   code_66514120:"Marine, aviation and other transport insurance services",
   code_66514130:"Railway insurance services",
   code_66514140:"Aircraft insurance services",
   code_66514150:"Vessel insurance services",
   code_66514200:"Freight insurance services",
   code_66515000:"Damage or loss insurance services",
   code_66515100:"Fire insurance services",
   code_66515200:"Property insurance services",
   code_66515300:"Weather and financial loss insurance services",
   code_66515400:"Weather-related insurance services",
   code_66515410:"Financial loss insurance services",
   code_66515411:"Pecuniary loss insurance services",
   code_66516000:"Liability insurance services",
   code_66516100:"Motor vehicle liability insurance services",
   code_66516200:"Aircraft liability insurance services",
   code_66516300:"Vessel liability insurance services",
   code_66516400:"General liability insurance services",
   code_66516500:"Professional liability insurance services",
   code_66517000:"Credit and surety insurance services",
   code_66517100:"Credit insurance services",
   code_66517200:"Surety insurance services",
   code_66517300:"Risk management insurance services",
   code_66518000:"Insurance brokerage and agency services",
   code_66518100:"Insurance brokerage services",
   code_66518200:"Insurance agency services",
   code_66518300:"Insurance claims adjustment services",
   code_66519000:"Engineering, auxiliary, average, loss, actuarial and salvage insurance services",
   code_66519100:"Oil or gas platforms insurance services",
   code_66519200:"Engineering insurance services",
   code_66519300:"Auxiliary insurance services",
   code_66519310:"Insurance consultancy services",
   code_66519400:"Average adjustment services",
   code_66519500:"Loss adjustment services",
   code_66519600:"Actuarial services",
   code_66519700:"Salvage administration services",
   code_66520000:"Pension services",
   code_66521000:"Individual pension services",
   code_66522000:"Group pension services",
   code_66523000:"Pension fund consultancy services",
   code_66523100:"Pension fund administration services",
   code_66600000:"Treasury services",
   code_66700000:"Reinsurance services",
   code_66710000:"Life reinsurance services",
   code_66720000:"Accident and health reinsurance services",
   code_70000000:"Real estate services",
   code_70100000:"Real estate services with own property",
   code_70110000:"Development services of real estate",
   code_70111000:"Development of residential real estate",
   code_70112000:"Development of non-residential real estate",
   code_70120000:"Buying and selling of real estate",
   code_70121000:"Building sale or purchase services",
   code_70121100:"Building sale services",
   code_70121200:"Building purchase services",
   code_70122000:"Land sale or purchase services",
   code_70122100:"Land sale services",
   code_70122110:"Vacant-land sale services",
   code_70122200:"Land purchase services",
   code_70122210:"Vacant-land purchase services",
   code_70123000:"Sale of real estate",
   code_70123100:"Sale of residential real estate",
   code_70123200:"Sale of non-residential estate",
   code_70130000:"Letting services of own property",
   code_70200000:"Renting or leasing services of own property",
   code_70210000:"Residential property renting or leasing services",
   code_70220000:"Non-residential property renting or leasing services",
   code_70300000:"Real estate agency services on a fee or contract basis",
   code_70310000:"Building rental or sale services",
   code_70311000:"Residential building rental or sale services",
   code_70320000:"Land rental or sale services",
   code_70321000:"Land rental services",
   code_70322000:"Vacant-land rental or sale services",
   code_70330000:"Property management services of real estate on a fee or contract basis",
   code_70331000:"Residential property services",
   code_70331100:"Institution management services",
   code_70332000:"Non-residential property services",
   code_70332100:"Land management services",
   code_70332200:"Commercial property management services",
   code_70332300:"Industrial property services",
   code_70333000:"Housing services",
   code_70340000:"Time-sharing services",
   code_71000000:"Architectural, construction, engineering and inspection services",
   code_71200000:"Architectural and related services",
   code_71210000:"Advisory architectural services",
   code_71220000:"Architectural design services",
   code_71221000:"Architectural services for buildings",
   code_71222000:"Architectural services for outdoor areas",
   code_71222100:"Urban areas mapping services",
   code_71222200:"Rural areas mapping services",
   code_71223000:"Architectural services for building extensions",
   code_71230000:"Organisation of architectural design contests",
   code_71240000:"Architectural, engineering and planning services",
   code_71241000:"Feasibility study, advisory service, analysis",
   code_71242000:"Project and design preparation, estimation of costs",
   code_71243000:"Draft plans (systems and integration)",
   code_71244000:"Calculation of costs, monitoring of costs",
   code_71245000:"Approval plans, working drawings and specifications",
   code_71246000:"Determining and listing of quantities in construction",
   code_71247000:"Supervision of building work",
   code_71248000:"Supervision of project and documentation",
   code_71250000:"Architectural, engineering and surveying services",
   code_71251000:"Architectural and building-surveying services",
   code_71300000:"Engineering services",
   code_71310000:"Consultative engineering and construction services",
   code_71311000:"Civil engineering consultancy services",
   code_71311100:"Civil engineering support services",
   code_71311200:"Transport systems consultancy services",
   code_71311210:"Highways consultancy services",
   code_71311220:"Highways engineering services",
   code_71311230:"Railway engineering services",
   code_71311240:"Airport engineering services",
   code_71311300:"Infrastructure works consultancy services",
   code_71312000:"Structural engineering consultancy services",
   code_71313000:"Environmental engineering consultancy services",
   code_71313100:"Noise-control consultancy services",
   code_71313200:"Sound insulation and room acoustics consultancy services",
   code_71313400:"Environmental impact assessment for construction",
   code_71313410:"Risk or hazard assessment for construction",
   code_71313420:"Environmental standards for construction",
   code_71313430:"Environmental indicators analysis for construction",
   code_71313440:"Environmental Impact Assessment (EIA) services for construction",
   code_71313450:"Environmental monitoring for construction",
   code_71314000:"Energy and related services",
   code_71314100:"Electrical services",
   code_71314200:"Energy-management services",
   code_71314300:"Energy-efficiency consultancy services",
   code_71314310:"Heating engineering services for buildings",
   code_71315000:"Building services",
   code_71315100:"Building-fabric consultancy services",
   code_71315200:"Building consultancy services",
   code_71315210:"Building services consultancy services",
   code_71315300:"Building surveying services",
   code_71315400:"Building-inspection services",
   code_71315410:"Inspection of ventilation system",
   code_71316000:"Telecommunication consultancy services",
   code_71317000:"Hazard protection and control consultancy services",
   code_71317100:"Fire and explosion protection and control consultancy services",
   code_71317200:"Health and safety services",
   code_71317210:"Health and safety consultancy services",
   code_71318000:"Advisory and consultative engineering services",
   code_71318100:"Artificial and natural lighting engineering services for buildings",
   code_71319000:"Expert witness services",
   code_71320000:"Engineering design services",
   code_71321000:"Engineering design services for mechanical and electrical installations for buildings",
   code_71321100:"Construction economics services",
   code_71321200:"Heating-system design services",
   code_71321300:"Plumbing consultancy services",
   code_71321400:"Ventilation consultancy services",
   code_71322000:"Engineering design services for the construction of civil engineering works",
   code_71322100:"Quantity surveying services for civil engineering works",
   code_71322200:"Pipeline-design services",
   code_71322300:"Bridge-design services",
   code_71322400:"Dam-design services",
   code_71322500:"Engineering-design services for traffic installations",
   code_71323000:"Engineering-design services for industrial process and production",
   code_71323100:"Electrical power systems design services",
   code_71323200:"Plant engineering design services",
   code_71324000:"Quantity surveying services",
   code_71325000:"Foundation-design services",
   code_71326000:"Ancillary building services",
   code_71327000:"Load-bearing structure design services",
   code_71328000:"Verification of load-bearing structure design services",
   code_71330000:"Miscellaneous engineering services",
   code_71331000:"Drilling-mud engineering services",
   code_71332000:"Geotechnical engineering services",
   code_71333000:"Mechanical engineering services",
   code_71334000:"Mechanical and electrical engineering services",
   code_71335000:"Engineering studies",
   code_71336000:"Engineering support services",
   code_71337000:"Corrosion engineering services",
   code_71340000:"Integrated engineering services",
   code_71350000:"Engineering-related scientific and technical services",
   code_71351000:"Geological, geophysical and other scientific prospecting services",
   code_71351100:"Core preparation and analysis services",
   code_71351200:"Geological and geophysical consultancy services",
   code_71351210:"Geophysical consultancy services",
   code_71351220:"Geological consultancy services",
   code_71351300:"Micropalaeontological analysis services",
   code_71351400:"Petrophysical interpretation services",
   code_71351500:"Ground investigation services",
   code_71351600:"Weather-forecasting services",
   code_71351610:"Meteorology services",
   code_71351611:"Climatology services",
   code_71351612:"Hydrometeorology services",
   code_71351700:"Scientific prospecting services",
   code_71351710:"Geophysical prospecting services",
   code_71351720:"Geophysical surveys of archaeological sites",
   code_71351730:"Geological prospecting services",
   code_71351800:"Topographical and water divining services",
   code_71351810:"Topographical services",
   code_71351811:"Topographical surveys of archaeological sites",
   code_71351820:"Water divining services",
   code_71351900:"Geology, oceanography and hydrology services",
   code_71351910:"Geology services",
   code_71351911:"Photogeology services",
   code_71351912:"Stratigraphic geology services",
   code_71351913:"Geological exploration services",
   code_71351914:"Archaeological services",
   code_71351920:"Oceanography and hydrology services",
   code_71351921:"Estuarine oceanography services",
   code_71351922:"Physical oceanography services",
   code_71351923:"Bathymetric surveys services",
   code_71351924:"Underwater exploration services",
   code_71352000:"Subsurface surveying services",
   code_71352100:"Seismic services",
   code_71352110:"Seismographic surveying services",
   code_71352120:"Seismic data acquisition services",
   code_71352130:"Seismic data collection services",
   code_71352140:"Seismic processing services",
   code_71352300:"Magnetometric surveying services",
   code_71353000:"Surface surveying services",
   code_71353100:"Hydrographic surveying services",
   code_71353200:"Dimensional surveying services",
   code_71354000:"Map-making services",
   code_71354100:"Digital mapping services",
   code_71354200:"Aerial mapping services",
   code_71354300:"Cadastral surveying services",
   code_71354400:"Hydrographic services",
   code_71354500:"Marine survey services",
   code_71355000:"Surveying services",
   code_71355100:"Photogrammetry services",
   code_71355200:"Ordnance surveying",
   code_71356000:"Technical services",
   code_71356100:"Technical control services",
   code_71356200:"Technical assistance services",
   code_71356300:"Technical support services",
   code_71356400:"Technical planning services",
   code_71400000:"Urban planning and landscape architectural services",
   code_71410000:"Urban planning services",
   code_71420000:"Landscape architectural services",
   code_71421000:"Landscape gardening services",
   code_71500000:"Construction-related services",
   code_71510000:"Site-investigation services",
   code_71520000:"Construction supervision services",
   code_71521000:"Construction-site supervision services",
   code_71530000:"Construction consultancy services",
   code_71540000:"Construction management services",
   code_71541000:"Construction project management services",
   code_71550000:"Blacksmith services",
   code_71600000:"Technical testing, analysis and consultancy services",
   code_71610000:"Composition and purity testing and analysis services",
   code_71620000:"Analysis services",
   code_71621000:"Technical analysis or consultancy services",
   code_71630000:"Technical inspection and testing services",
   code_71631000:"Technical inspection services",
   code_71631100:"Machinery-inspection services",
   code_71631200:"Technical automobile inspection services",
   code_71631300:"Technical building-inspection services",
   code_71631400:"Technical inspection services of engineering structures",
   code_71631420:"Maritime safety inspection services",
   code_71631430:"Leak-testing services",
   code_71631440:"Flow-monitoring services",
   code_71631450:"Bridge-inspection services",
   code_71631460:"Dam-inspection services",
   code_71631470:"Railway-track inspection services",
   code_71631480:"Road-inspection services",
   code_71631490:"Runway-inspection services",
   code_71632000:"Technical testing services",
   code_71632100:"Valve-testing services",
   code_71632200:"Non-destructive testing services",
   code_71700000:"Monitoring and control services",
   code_71730000:"Industrial inspection services",
   code_71731000:"Industrial quality control services",
   code_71800000:"Consulting services for water-supply and waste consultancy",
   code_71900000:"Laboratory services",
   code_72000000:"IT services: consulting, software development, Internet and support",
   code_72100000:"Hardware consultancy services",
   code_72110000:"Hardware selection consultancy services",
   code_72120000:"Hardware disaster-recovery consultancy services",
   code_72130000:"Computer-site planning consultancy services",
   code_72140000:"Computer hardware acceptance testing consultancy services",
   code_72150000:"Computer audit consultancy and hardware consultancy services",
   code_72200000:"Software programming and consultancy services",
   code_72210000:"Programming services of packaged software products",
   code_72211000:"Programming services of systems and user software",
   code_72212000:"Programming services of application software",
   code_72212100:"Industry specific software development services",
   code_72212110:"Point of sale (POS) software development services",
   code_72212120:"Flight control software development services",
   code_72212121:"Air traffic control software development services",
   code_72212130:"Aviation ground support and test software development services",
   code_72212131:"Aviation ground support software development services",
   code_72212132:"Aviation test software development services",
   code_72212140:"Railway traffic control software development services",
   code_72212150:"Industrial control software development services",
   code_72212160:"Library software development services",
   code_72212170:"Compliance software development services",
   code_72212180:"Medical software development services",
   code_72212190:"Educational software development services",
   code_72212200:"Networking, Internet and intranet software development services",
   code_72212210:"Networking software development services",
   code_72212211:"Platform interconnectivity software development services",
   code_72212212:"Optical jukebox server software development services",
   code_72212213:"Operating system enhancement software development services",
   code_72212214:"Network operating system software development services",
   code_72212215:"Networking developers software development services",
   code_72212216:"Network connectivity terminal emulation software development services",
   code_72212217:"Transaction-processing software development services",
   code_72212218:"License management software development services",
   code_72212219:"Miscellaneous networking software development services",
   code_72212220:"Internet and intranet software development services",
   code_72212221:"Internet browsing software development services",
   code_72212222:"Web server software development services",
   code_72212223:"Electronic mail software development services",
   code_72212224:"Web page editing software development services",
   code_72212300:"Document creation, drawing, imaging, scheduling and productivity software development services",
   code_72212310:"Document creation software development services",
   code_72212311:"Document management software development services",
   code_72212312:"Electronic publishing software development services",
   code_72212313:"Optical-character-recognition (OCR) software development services",
   code_72212314:"Voice recognition software development services",
   code_72212315:"Desktop-publishing software development services",
   code_72212316:"Presentation software development services",
   code_72212317:"Word-processing software development services",
   code_72212318:"Scanner software development services",
   code_72212320:"Drawing and imaging software development services",
   code_72212321:"Computer-aided design (CAD) software development services",
   code_72212322:"Graphics software development services",
   code_72212323:"Computer-aided manufacturing (CAM) software development services",
   code_72212324:"Charting software development services",
   code_72212325:"Form making software development services",
   code_72212326:"Mapping software development services",
   code_72212327:"Drawing and painting software development services",
   code_72212328:"Image-processing software development services",
   code_72212330:"Scheduling and productivity software development services",
   code_72212331:"Project management software development services",
   code_72212332:"Scheduling software development services",
   code_72212333:"Contact management software development services",
   code_72212400:"Business transaction and personal business software development services",
   code_72212410:"Investment management and tax preparation software development services",
   code_72212411:"Investment management software development services",
   code_72212412:"Tax preparation software development services",
   code_72212420:"Facilities management software development services and software development services suite",
   code_72212421:"Facilities management software development services",
   code_72212422:"Software development services suites",
   code_72212430:"Inventory management software development services",
   code_72212440:"Financial analysis and accounting software development services",
   code_72212441:"Financial analysis software development services",
   code_72212442:"Financial systems software development services",
   code_72212443:"Accounting software development services",
   code_72212445:"Customer Relation Management software development services",
   code_72212450:"Time accounting or human resources software development services",
   code_72212451:"Enterprise resource planning software development services",
   code_72212460:"Analytical, scientific, mathematical or forecasting software development services",
   code_72212461:"Analytical or scientific software development services",
   code_72212462:"Mathematical or forecasting software development services",
   code_72212463:"Statistical software development services",
   code_72212470:"Auction software development services",
   code_72212480:"Sales, marketing and business intelligence software development services",
   code_72212481:"Sales or marketing software development services",
   code_72212482:"Business intelligence software development services",
   code_72212490:"Procurement software development services",
   code_72212500:"Communication and multimedia software development services",
   code_72212510:"Communication software development services",
   code_72212511:"Desktop communications software development services",
   code_72212512:"Interactive voice response software development services",
   code_72212513:"Modem software development services",
   code_72212514:"Remote access software development services",
   code_72212515:"Video conferencing software development services",
   code_72212516:"Exchange software development services",
   code_72212517:"IT software development services",
   code_72212518:"Emulation software development services",
   code_72212519:"Memory-management software development services",
   code_72212520:"Multimedia software development services",
   code_72212521:"Music or sound editing software development services",
   code_72212522:"Virtual keyboard software development services",
   code_72212600:"Database and operating software development services",
   code_72212610:"Database software development services",
   code_72212620:"Mainframe operating system software development services",
   code_72212630:"Minicomputer operating system software development services",
   code_72212640:"Microcomputer operating system software development services",
   code_72212650:"Personal computer (PC) operating system software development services",
   code_72212660:"Clustering software development services",
   code_72212670:"Real time operating system software development services",
   code_72212700:"Software development services utilities",
   code_72212710:"Backup or recovery software development services",
   code_72212720:"Bar coding software development services",
   code_72212730:"Security software development services",
   code_72212731:"File security software development services",
   code_72212732:"Data security software development services",
   code_72212740:"Foreign language translation software development services",
   code_72212750:"Storage media loading software development services",
   code_72212760:"Virus protection software development services",
   code_72212761:"Anti-virus software development services",
   code_72212770:"General, compression and print utility software development services",
   code_72212771:"General utility software development services",
   code_72212772:"Print utility software development services",
   code_72212780:"System, storage and content management software development services",
   code_72212781:"System management software development services",
   code_72212782:"Storage management software development services",
   code_72212783:"Content management software development services",
   code_72212790:"Version checker software development services",
   code_72212900:"Miscellaneous software development services and computer systems",
   code_72212910:"Computer game software development services, family titles and screen savers",
   code_72212911:"Computer game software development services",
   code_72212920:"Office automation software development services",
   code_72212930:"Training and entertainment software development services",
   code_72212931:"Training software development services",
   code_72212932:"Entertainment software development services",
   code_72212940:"Pattern design and calendar software development services",
   code_72212941:"Pattern design software development services",
   code_72212942:"Calendar software development services",
   code_72212960:"Drivers and system software development services",
   code_72212970:"Print shop software development services",
   code_72212971:"Address book making software development services",
   code_72212972:"Label making software development services",
   code_72212980:"Programming languages and tools development services",
   code_72212981:"Compiling software development services",
   code_72212982:"Configuration management software development services",
   code_72212983:"Development software development services",
   code_72212984:"Program testing software development services",
   code_72212985:"Debugging software development services",
   code_72212990:"Spreadsheets and enhancement software development services",
   code_72212991:"Spreadsheet software development services",
   code_72220000:"Systems and technical consultancy services",
   code_72221000:"Business analysis consultancy services",
   code_72222000:"Information systems or technology strategic review and planning services",
   code_72222100:"Information systems or technology strategic review services",
   code_72222200:"Information systems or technology planning services",
   code_72222300:"Information technology services",
   code_72223000:"Information technology requirements review services",
   code_72224000:"Project management consultancy services",
   code_72224100:"System implementation planning services",
   code_72224200:"System quality assurance planning services",
   code_72225000:"System quality assurance assessment and review services",
   code_72226000:"System software acceptance testing consultancy services",
   code_72227000:"Software integration consultancy services",
   code_72228000:"Hardware integration consultancy services",
   code_72230000:"Custom software development services",
   code_72231000:"Development of software for military applications",
   code_72232000:"Development of transaction processing and custom software",
   code_72240000:"Systems analysis and programming services",
   code_72241000:"Critical design target specification services",
   code_72242000:"Design-modelling services",
   code_72243000:"Programming services",
   code_72244000:"Prototyping services",
   code_72245000:"Contract systems analysis and programming services",
   code_72246000:"Systems consultancy services",
   code_72250000:"System and support services",
   code_72251000:"Disaster recovery services",
   code_72252000:"Computer archiving services",
   code_72253000:"Helpdesk and support services",
   code_72253100:"Helpdesk services",
   code_72253200:"Systems support services",
   code_72254000:"Software testing",
   code_72254100:"Systems testing services",
   code_72260000:"Software-related services",
   code_72261000:"Software support services",
   code_72262000:"Software development services",
   code_72263000:"Software implementation services",
   code_72264000:"Software reproduction services",
   code_72265000:"Software configuration services",
   code_72266000:"Software consultancy services",
   code_72267000:"Software maintenance and repair services",
   code_72267100:"Maintenance of information technology software",
   code_72267200:"Repair of information technology software",
   code_72268000:"Software supply services",
   code_72300000:"Data services",
   code_72310000:"Data-processing services",
   code_72311000:"Computer tabulation services",
   code_72311100:"Data conversion services",
   code_72311200:"Batch processing services",
   code_72311300:"Computer time-sharing services",
   code_72312000:"Data entry services",
   code_72312100:"Data preparation services",
   code_72312200:"Optical character recognition services",
   code_72313000:"Data capture services",
   code_72314000:"Data collection and collation services",
   code_72315000:"Data network management and support services",
   code_72315100:"Data network support services",
   code_72315200:"Data network management services",
   code_72316000:"Data analysis services",
   code_72317000:"Data storage services",
   code_72318000:"Data transmission services",
   code_72319000:"Data supply services",
   code_72320000:"Database services",
   code_72321000:"Added-value database services",
   code_72322000:"Data management services",
   code_72330000:"Content or data standardization and classification services",
   code_72400000:"Internet services",
   code_72410000:"Provider services",
   code_72411000:"Internet service providers ISP",
   code_72412000:"Electronic mail service provider",
   code_72413000:"World wide web (www) site design services",
   code_72414000:"Web search engine providers",
   code_72415000:"World wide web (www) site operation host services",
   code_72416000:"Application service providers",
   code_72417000:"Internet domain names",
   code_72420000:"Internet development services",
   code_72421000:"Internet or intranet client application development services",
   code_72422000:"Internet or intranet server application development services",
   code_72500000:"Computer-related services",
   code_72510000:"Computer-related management services",
   code_72511000:"Network management software services",
   code_72512000:"Document management services",
   code_72513000:"Office automation services",
   code_72514000:"Computer facilities management services",
   code_72514100:"Facilities management services involving computer operation",
   code_72514200:"Facilities management services for computer systems development",
   code_72514300:"Facilities management services for computer systems maintenance",
   code_72540000:"Computer upgrade services",
   code_72541000:"Computer expansion services",
   code_72541100:"Memory expansion services",
   code_72590000:"Computer-related professional services",
   code_72591000:"Development of service level agreements",
   code_72600000:"Computer support and consultancy services",
   code_72610000:"Computer support services",
   code_72611000:"Technical computer support services",
   code_72700000:"Computer network services",
   code_72710000:"Local area network services",
   code_72720000:"Wide area network services",
   code_72800000:"Computer audit and testing services",
   code_72810000:"Computer audit services",
   code_72820000:"Computer testing services",
   code_72900000:"Computer back-up and catalogue conversion services",
   code_72910000:"Computer back-up services",
   code_72920000:"Computer catalogue conversion services",
   code_73000000:"Research and development services and related consultancy services",
   code_73100000:"Research and experimental development services",
   code_73110000:"Research services",
   code_73111000:"Research laboratory services",
   code_73112000:"Marine research services",
   code_73120000:"Experimental development services",
   code_73200000:"Research and development consultancy services",
   code_73210000:"Research consultancy services",
   code_73220000:"Development consultancy services",
   code_73300000:"Design and execution of research and development",
   code_73400000:"Research and Development services on security and defence materials",
   code_73410000:"Military research and technology",
   code_73420000:"Pre-feasibility study and technological demonstration",
   code_73421000:"Development of security equipment",
   code_73422000:"Development of firearms and ammunition",
   code_73423000:"Development of military vehicles",
   code_73424000:"Development of warships",
   code_73425000:"Development of military aircrafts, missiles and spacecrafts",
   code_73426000:"Development of military electronic systems",
   code_73430000:"Test and evaluation",
   code_73431000:"Test and evaluation of security equipment",
   code_73432000:"Test and evaluation of firearms and ammunition",
   code_73433000:"Test and evaluation of military vehicles",
   code_73434000:"Test and evaluation of warships",
   code_73435000:"Test and evaluation of military aircrafts, missiles and spacecrafts",
   code_73436000:"Test and evaluation of military electronic systems",
   code_75000000:"Administration, defence and social security services",
   code_75100000:"Administration services",
   code_75110000:"General public services",
   code_75111000:"Executive and legislative services",
   code_75111100:"Executive services",
   code_75111200:"Legislative services",
   code_75112000:"Administrative services for business operations",
   code_75112100:"Administrative development project services",
   code_75120000:"Administrative services of agencies",
   code_75121000:"Administrative educational services",
   code_75122000:"Administrative healthcare services",
   code_75123000:"Administrative housing services",
   code_75124000:"Administrative recreational, cultural and religious services",
   code_75125000:"Administrative services related to tourism affairs",
   code_75130000:"Supporting services for the government",
   code_75131000:"Government services",
   code_75131100:"General personnel services for the government",
   code_75200000:"Provision of services to the community",
   code_75210000:"Foreign affairs and other services",
   code_75211000:"Foreign-affairs services",
   code_75211100:"Diplomatic services",
   code_75211110:"Consular services",
   code_75211200:"Foreign economic-aid-related services",
   code_75211300:"Foreign military-aid-related services",
   code_75220000:"Defence services",
   code_75221000:"Military defence services",
   code_75222000:"Civil defence services",
   code_75230000:"Justice services",
   code_75231000:"Judicial services",
   code_75231100:"Law-courts-related administrative services",
   code_75231200:"Services related to the detention or rehabilitation of criminals",
   code_75231210:"Imprisonment services",
   code_75231220:"Prisoner-escort services",
   code_75231230:"Prison services",
   code_75231240:"Probation services",
   code_75240000:"Public security, law and order services",
   code_75241000:"Public security services",
   code_75241100:"Police services",
   code_75242000:"Public law and order services",
   code_75242100:"Public-order services",
   code_75242110:"Bailiff services",
   code_75250000:"Fire-brigade and rescue services",
   code_75251000:"Fire-brigade services",
   code_75251100:"Firefighting services",
   code_75251110:"Fire-prevention services",
   code_75251120:"Forest-firefighting services",
   code_75252000:"Rescue services",
   code_75300000:"Compulsory social security services",
   code_75310000:"Benefit services",
   code_75311000:"Sickness benefits",
   code_75312000:"Maternity benefits",
   code_75313000:"Disability benefits",
   code_75313100:"Temporary disablement benefits",
   code_75314000:"Unemployment compensation benefits",
   code_75320000:"Government employee pension schemes",
   code_75330000:"Family allowances",
   code_75340000:"Child allowances",
   code_76000000:"Services related to the oil and gas industry",
   code_76100000:"Professional services for the gas industry",
   code_76110000:"Services incidental to gas extraction",
   code_76111000:"Regasification services",
   code_76120000:"Diving services incidental to gas extraction",
   code_76121000:"Subsea well diving services",
   code_76200000:"Professional services for the oil industry",
   code_76210000:"Services incidental to oil extraction",
   code_76211000:"Liner-hanger services",
   code_76211100:"Lining services",
   code_76211110:"Test pit lining services",
   code_76211120:"Well site pit lining services",
   code_76211200:"Mudlogging services",
   code_76300000:"Drilling services",
   code_76310000:"Drilling services incidental to gas extraction",
   code_76320000:"Offshore drilling services",
   code_76330000:"Turbine drilling services",
   code_76331000:"Coiled turbine drilling services",
   code_76340000:"Core drilling",
   code_76400000:"Rig-positioning services",
   code_76410000:"Well-casing and tubing services",
   code_76411000:"Well-casing services",
   code_76411100:"Well-casing crew services",
   code_76411200:"Well-casing planning services",
   code_76411300:"Well-casing milling services",
   code_76411400:"Well-casing completion services",
   code_76420000:"Well-cementing services",
   code_76421000:"Liner cementing services",
   code_76422000:"Plug cementing services",
   code_76423000:"Foam cementing services",
   code_76430000:"Well-drilling and production services",
   code_76431000:"Well-drilling services",
   code_76431100:"Well-drilling control services",
   code_76431200:"Well-drilling pickup services",
   code_76431300:"Well-drilling laydown services",
   code_76431400:"Rathole well-drilling services",
   code_76431500:"Well-drilling supervision services",
   code_76431600:"Well-drilling rig monitor services",
   code_76440000:"Well-logging services",
   code_76441000:"Cased hole logging services",
   code_76442000:"Open hole logging services",
   code_76443000:"Other logging services",
   code_76450000:"Well-management services",
   code_76460000:"Well-support services",
   code_76470000:"Well-testing services",
   code_76471000:"Well fracture testing services",
   code_76472000:"Well site inspection or testing services",
   code_76473000:"Well equipment testing services",
   code_76480000:"Tubing services",
   code_76490000:"Well-completion services",
   code_76491000:"Well-plugging services",
   code_76492000:"Well-positioning services",
   code_76500000:"Onshore and offshore services",
   code_76510000:"Onshore services",
   code_76520000:"Offshore services",
   code_76521000:"Offshore installation services",
   code_76522000:"Offshore supply-vessel services",
   code_76530000:"Downhole services",
   code_76531000:"Downhole logging services",
   code_76532000:"Downhole pumping services",
   code_76533000:"Downhole recording services",
   code_76534000:"Downhole underreaming services",
   code_76535000:"Downhole hole opening services",
   code_76536000:"Downhole vibration control services",
   code_76537000:"Downhole tool services",
   code_76537100:"Downhole oilifield tools services",
   code_76600000:"Pipeline-inspection services",
   code_77000000:"Agricultural, forestry, horticultural, aquacultural and apicultural services",
   code_77100000:"Agricultural services",
   code_77110000:"Services incidental to agricultural production",
   code_77111000:"Hire of agricultural machinery with operator",
   code_77112000:"Hire of mowers or agricultural equipment with operator",
   code_77120000:"Composting services",
   code_77200000:"Forestry services",
   code_77210000:"Logging services",
   code_77211000:"Services incidental to logging",
   code_77211100:"Timber harvesting services",
   code_77211200:"Transport of logs within the forest",
   code_77211300:"Tree-clearing services",
   code_77211400:"Tree-cutting services",
   code_77211500:"Tree-maintenance services",
   code_77211600:"Tree seeding",
   code_77220000:"Timber-impregnation services",
   code_77230000:"Services incidental to forestry",
   code_77231000:"Forestry management services",
   code_77231100:"Forest resources management services",
   code_77231200:"Forest pest control services",
   code_77231300:"Forest administration services",
   code_77231400:"Forest inventory services",
   code_77231500:"Forest monitoring or evaluation services",
   code_77231600:"Afforestation services",
   code_77231700:"Forestry extension services",
   code_77231800:"Forest nursery management services",
   code_77231900:"Forest sectoral planning services",
   code_77300000:"Horticultural services",
   code_77310000:"Planting and maintenance services of green areas",
   code_77311000:"Ornamental and pleasure gardens maintenance services",
   code_77312000:"Weed-clearance services",
   code_77312100:"Weed-killing services",
   code_77313000:"Parks maintenance services",
   code_77314000:"Grounds maintenance services",
   code_77314100:"Grassing services",
   code_77315000:"Seeding services",
   code_77320000:"Sports fields maintenance services",
   code_77330000:"Floral-display services",
   code_77340000:"Tree pruning and hedge trimming",
   code_77341000:"Tree pruning",
   code_77342000:"Hedge trimming",
   code_77400000:"Zoological services",
   code_77500000:"Animal husbandry services",
   code_77510000:"Game-propagation services",
   code_77600000:"Hunting services",
   code_77610000:"Trapping services",
   code_77700000:"Services incidental to fishing",
   code_77800000:"Aquaculture services",
   code_77810000:"Mariculture services",
   code_77820000:"Ostreiculture services",
   code_77830000:"Shellfish culture services",
   code_77840000:"Shrimp farming services",
   code_77850000:"Fish farming services",
   code_77900000:"Apiculture services",
   code_79000000:"Business services: law, marketing, consulting, recruitment, printing and security",
   code_79100000:"Legal services",
   code_79110000:"Legal advisory and representation services",
   code_79111000:"Legal advisory services",
   code_79112000:"Legal representation services",
   code_79112100:"Stakeholders representation services",
   code_79120000:"Patent and copyright consultancy services",
   code_79121000:"Copyright consultancy services",
   code_79121100:"Software copyright consultancy services",
   code_79130000:"Legal documentation and certification services",
   code_79131000:"Documentation services",
   code_79132000:"Certification services",
   code_79132100:"Electronic signature certification services",
   code_79140000:"Legal advisory and information services",
   code_79200000:"Accounting, auditing and fiscal services",
   code_79210000:"Accounting and auditing services",
   code_79211000:"Accounting services",
   code_79211100:"Bookkeeping services",
   code_79211110:"Payroll management services",
   code_79211120:"Sales and purchases recording services",
   code_79211200:"Compilation of financial statements services",
   code_79212000:"Auditing services",
   code_79212100:"Financial auditing services",
   code_79212110:"Corporate governance rating services",
   code_79212200:"Internal audit services",
   code_79212300:"Statutory audit services",
   code_79212400:"Fraud audit services",
   code_79212500:"Accounting review services",
   code_79220000:"Fiscal services",
   code_79221000:"Tax consultancy services",
   code_79222000:"Tax-return preparation services",
   code_79223000:"Custom broker services",
   code_79300000:"Market and economic research; polling and statistics",
   code_79310000:"Market research services",
   code_79311000:"Survey services",
   code_79311100:"Survey design services",
   code_79311200:"Survey conduction services",
   code_79311210:"Telephone survey services",
   code_79311300:"Survey analysis services",
   code_79311400:"Economic research services",
   code_79311410:"Economic impact assessment",
   code_79312000:"Market-testing services",
   code_79313000:"Performance review services",
   code_79314000:"Feasibility study",
   code_79315000:"Social research services",
   code_79320000:"Public-opinion polling services",
   code_79330000:"Statistical services",
   code_79340000:"Advertising and marketing services",
   code_79341000:"Advertising services",
   code_79341100:"Advertising consultancy services",
   code_79341200:"Advertising management services",
   code_79341400:"Advertising campaign services",
   code_79341500:"Aeral advertising services",
   code_79342000:"Marketing services",
   code_79342100:"Direct marketing services",
   code_79342200:"Promotional services",
   code_79342300:"Customer services",
   code_79342310:"Customer survey services",
   code_79342311:"Customer satisfaction survey",
   code_79342320:"Customer-care services",
   code_79342321:"Customer-loyalty programme",
   code_79342400:"Auction services",
   code_79342410:"Electronic auction services",
   code_79400000:"Business and management consultancy and related services",
   code_79410000:"Business and management consultancy services",
   code_79411000:"General management consultancy services",
   code_79411100:"Business development consultancy services",
   code_79412000:"Financial management consultancy services",
   code_79413000:"Marketing management consultancy services",
   code_79414000:"Human resources management consultancy services",
   code_79415000:"Production management consultancy services",
   code_79415200:"Design consultancy services",
   code_79416000:"Public relations services",
   code_79416100:"Public relations management services",
   code_79416200:"Public relations consultancy services",
   code_79417000:"Safety consultancy services",
   code_79418000:"Procurement consultancy services",
   code_79419000:"Evaluation consultancy services",
   code_79420000:"Management-related services",
   code_79421000:"Project-management services other than for construction work",
   code_79421100:"Project-supervision services other than for construction work",
   code_79421200:"Project-design services other than for construction work",
   code_79422000:"Arbitration and conciliation services",
   code_79430000:"Crisis management services",
   code_79500000:"Office-support services",
   code_79510000:"Telephone-answering services",
   code_79511000:"Telephone operator services",
   code_79512000:"Call centre",
   code_79520000:"Reprographic services",
   code_79521000:"Photocopying services",
   code_79530000:"Translation services",
   code_79540000:"Interpretation services",
   code_79550000:"Typing, word-processing and desktop publishing services",
   code_79551000:"Typing services",
   code_79552000:"Word-processing services",
   code_79553000:"Desktop publishing services",
   code_79560000:"Filing services",
   code_79570000:"Mailing-list compilation and mailing services",
   code_79571000:"Mailing services",
   code_79600000:"Recruitment services",
   code_79610000:"Placement services of personnel",
   code_79611000:"Job search services",
   code_79612000:"Placement services of office-support personnel",
   code_79613000:"Employee relocation services",
   code_79620000:"Supply services of personnel including temporary staff",
   code_79621000:"Supply services of office personnel",
   code_79622000:"Supply services of domestic help personnel",
   code_79623000:"Supply services of commercial or industrial workers",
   code_79624000:"Supply services of nursing personnel",
   code_79625000:"Supply services of medical personnel",
   code_79630000:"Personnel services except placement and supply services",
   code_79631000:"Personnel and payroll services",
   code_79632000:"Personnel-training services",
   code_79633000:"Staff development services",
   code_79634000:"Career guidance services",
   code_79635000:"Assessment centre services for recruitment",
   code_79700000:"Investigation and security services",
   code_79710000:"Security services",
   code_79711000:"Alarm-monitoring services",
   code_79713000:"Guard services",
   code_79714000:"Surveillance services",
   code_79714100:"Tracing system services",
   code_79714110:"Absconder-tracing services",
   code_79715000:"Patrol services",
   code_79716000:"Identification badge release services",
   code_79720000:"Investigation services",
   code_79721000:"Detective agency services",
   code_79722000:"Graphology services",
   code_79723000:"Waste analysis services",
   code_79800000:"Printing and related services",
   code_79810000:"Printing services",
   code_79811000:"Digital printing services",
   code_79812000:"Banknote printing services",
   code_79820000:"Services related to printing",
   code_79821000:"Print finishing services",
   code_79821100:"Proofreading services",
   code_79822000:"Composition services",
   code_79822100:"Print-plate making services",
   code_79822200:"Photogravure services",
   code_79822300:"Typesetting services",
   code_79822400:"Lithographic services",
   code_79822500:"Graphic design services",
   code_79823000:"Printing and delivery services",
   code_79824000:"Printing and distribution services",
   code_79900000:"Miscellaneous business and business-related services",
   code_79910000:"Management holdings services",
   code_79920000:"Packaging and related services",
   code_79921000:"Packaging services",
   code_79930000:"Specialty design services",
   code_79931000:"Interior decorating services",
   code_79932000:"Interior design services",
   code_79933000:"Design support services",
   code_79934000:"Furniture design services",
   code_79940000:"Collection agency services",
   code_79941000:"Toll-collection services",
   code_79950000:"Exhibition, fair and congress organisation services",
   code_79951000:"Seminar organisation services",
   code_79952000:"Event services",
   code_79952100:"Cultural event organisation services",
   code_79953000:"Festival organisation services",
   code_79954000:"Party organisation services",
   code_79955000:"Fashion shows organisation services",
   code_79956000:"Fair and exhibition organisation services",
   code_79957000:"Auction organisation services",
   code_79960000:"Photographic and ancillary services",
   code_79961000:"Photographic services",
   code_79961100:"Advertising photography services",
   code_79961200:"Aerial photography services",
   code_79961300:"Specialised photography services",
   code_79961310:"Downhole photography services",
   code_79961320:"Underwater photography services",
   code_79961330:"Microfilming services",
   code_79961340:"X-ray photography services",
   code_79961350:"Studio photography services",
   code_79962000:"Photograph processing services",
   code_79963000:"Photograph restoration, copying and retouching services",
   code_79970000:"Publishing services",
   code_79971000:"Bookbinding and finishing services",
   code_79971100:"Book finishing services",
   code_79971200:"Bookbinding services",
   code_79972000:"Language dictionary publishing services",
   code_79972100:"Regional language dictionary publishing services",
   code_79980000:"Subscription services",
   code_79990000:"Miscellaneous business-related services",
   code_79991000:"Stock-control services",
   code_79992000:"Reception services",
   code_79993000:"Building and facilities management services",
   code_79993100:"Facilities management services",
   code_79994000:"Contract administration services",
   code_79995000:"Library management services",
   code_79995100:"Archiving services",
   code_79995200:"Cataloguing services",
   code_79996000:"Business organisation services",
   code_79996100:"Records management",
   code_79997000:"Business travel services",
   code_79998000:"Coaching services",
   code_79999000:"Scanning and invoicing services",
   code_79999100:"Scanning services",
   code_79999200:"Invoicing services",
   code_80000000:"Education and training services",
   code_80100000:"Primary education services",
   code_80110000:"Pre-school education services",
   code_80200000:"Secondary education services",
   code_80210000:"Technical and vocational secondary education services",
   code_80211000:"Technical secondary education services",
   code_80212000:"Vocational secondary education services",
   code_80300000:"Higher education services",
   code_80310000:"Youth education services",
   code_80320000:"Medical education services",
   code_80330000:"Safety education services",
   code_80340000:"Special education services",
   code_80400000:"Adult and other education services",
   code_80410000:"Various school services",
   code_80411000:"Driving-school services",
   code_80411100:"Driving-test services",
   code_80411200:"Driving lessons",
   code_80412000:"Flying-school services",
   code_80413000:"Sailing-school services",
   code_80414000:"Diving-school services",
   code_80415000:"Ski-training services",
   code_80420000:"E-learning services",
   code_80430000:"Adult-education services at university level",
   code_80490000:"Operation of an educational centre",
   code_80500000:"Training services",
   code_80510000:"Specialist training services",
   code_80511000:"Staff training services",
   code_80512000:"Dog training services",
   code_80513000:"Horse riding school services",
   code_80520000:"Training facilities",
   code_80521000:"Training programme services",
   code_80522000:"Training seminars",
   code_80530000:"Vocational training services",
   code_80531000:"Industrial and technical training services",
   code_80531100:"Industrial training services",
   code_80531200:"Technical training services",
   code_80532000:"Management training services",
   code_80533000:"Computer-user familiarisation and training services",
   code_80533100:"Computer training services",
   code_80533200:"Computer courses",
   code_80540000:"Environmental training services",
   code_80550000:"Safety training services",
   code_80560000:"Health and first-aid training services",
   code_80561000:"Health training services",
   code_80562000:"First-aid training services",
   code_80570000:"Personal development training services",
   code_80580000:"Provision of language courses",
   code_80590000:"Tutorial services",
   code_80600000:"Training services in defence and security materials",
   code_80610000:"Training and simulation in security equipment",
   code_80620000:"Training and simulation in firearms and ammunition",
   code_80630000:"Training and simulation in military vehicles",
   code_80640000:"Training and simulation in warships",
   code_80650000:"Training and simulation in aircrafts, missiles and spacecrafts",
   code_80660000:"Training and simulation in military electronic systems",
   code_85000000:"Health and social work services",
   code_85100000:"Health services",
   code_85110000:"Hospital and related services",
   code_85111000:"Hospital services",
   code_85111100:"Surgical hospital services",
   code_85111200:"Medical hospital services",
   code_85111300:"Gynaecological hospital services",
   code_85111310:"In vitro fertilisation services",
   code_85111320:"Obstetrical hospital services",
   code_85111400:"Rehabilitation hospital services",
   code_85111500:"Psychiatric hospital services",
   code_85111600:"Orthotic services",
   code_85111700:"Oxygen-therapy services",
   code_85111800:"Pathology services",
   code_85111810:"Blood analysis services",
   code_85111820:"Bacteriological analysis services",
   code_85111900:"Hospital dialysis services",
   code_85112000:"Hospital support services",
   code_85112100:"Hospital-bedding services",
   code_85112200:"Outpatient care services",
   code_85120000:"Medical practice and related services",
   code_85121000:"Medical practice services",
   code_85121100:"General-practitioner services",
   code_85121200:"Medical specialist services",
   code_85121210:"Gyneacologic or obstetric services",
   code_85121220:"Nephrology or nervous system specialist services",
   code_85121230:"Cardiology services or pulmonary specialists services",
   code_85121231:"Cardiology services",
   code_85121232:"Pulmonary specialists services",
   code_85121240:"ENT or audiologist services",
   code_85121250:"Gastroenterologist and geriatric services",
   code_85121251:"Gastroenterologist services",
   code_85121252:"Geriatric services",
   code_85121270:"Psychiatrist or psychologist services",
   code_85121271:"Home for the psychologically disturbed services",
   code_85121280:"Ophthalmologist, dermatology or orthopedics services",
   code_85121281:"Ophthalmologist services",
   code_85121282:"Dermatology services",
   code_85121283:"Orthopaedic services",
   code_85121290:"Paediatric or urologist services",
   code_85121291:"Paediatric services",
   code_85121292:"Urologist services",
   code_85121300:"Surgical specialist services",
   code_85130000:"Dental practice and related services",
   code_85131000:"Dental-practice services",
   code_85131100:"Orthodontic services",
   code_85131110:"Orthodontic-surgery services",
   code_85140000:"Miscellaneous health services",
   code_85141000:"Services provided by medical personnel",
   code_85141100:"Services provided by midwives",
   code_85141200:"Services provided by nurses",
   code_85141210:"Home medical treatment services",
   code_85141211:"Dialysis home medical treatment services",
   code_85141220:"Advisory services provided by nurses",
   code_85142000:"Paramedical services",
   code_85142100:"Physiotherapy services",
   code_85142200:"Homeopathic services",
   code_85142300:"Hygiene services",
   code_85142400:"Home delivery of incontinence products",
   code_85143000:"Ambulance services",
   code_85144000:"Residential health facilities services",
   code_85144100:"Residential nursing care services",
   code_85145000:"Services provided by medical laboratories",
   code_85146000:"Services provided by blood banks",
   code_85146100:"Services provided by sperm banks",
   code_85146200:"Services provided by transplant organ banks",
   code_85147000:"Company health services",
   code_85148000:"Medical analysis services",
   code_85149000:"Pharmacy services",
   code_85150000:"Medical imaging services",
   code_85160000:"Optician services",
   code_85170000:"Acupuncture and chiropractor services",
   code_85171000:"Acupuncture services",
   code_85172000:"Chiropractor services",
   code_85200000:"Veterinary services",
   code_85210000:"Domestic animal nurseries",
   code_85300000:"Social work and related services",
   code_85310000:"Social work services",
   code_85311000:"Social work services with accommodation",
   code_85311100:"Welfare services for the elderly",
   code_85311200:"Welfare services for the handicapped",
   code_85311300:"Welfare services for children and young people",
   code_85312000:"Social work services without accommodation",
   code_85312100:"Daycare services",
   code_85312110:"Child daycare services",
   code_85312120:"Daycare services for handicapped children and young people",
   code_85312200:"Homedelivery of provisions",
   code_85312300:"Guidance and counselling services",
   code_85312310:"Guidance services",
   code_85312320:"Counselling services",
   code_85312330:"Family-planning services",
   code_85312400:"Welfare services not delivered through residential institutions",
   code_85312500:"Rehabilitation services",
   code_85312510:"Vocational rehabilitation services",
   code_85320000:"Social services",
   code_85321000:"Administrative social services",
   code_85322000:"Community action programme",
   code_85323000:"Community health services",
   code_90000000:"Sewage, refuse, cleaning and environmental services",
   code_90400000:"Sewage services",
   code_90410000:"Sewage removal services",
   code_90420000:"Sewage treatment services",
   code_90430000:"Sewage disposal services",
   code_90440000:"Treatment services of cesspools",
   code_90450000:"Treatment services of septic tanks",
   code_90460000:"Cesspool or septic tank emptying services",
   code_90470000:"Sewer cleaning services",
   code_90480000:"Sewerage management services",
   code_90481000:"Operation of a sewage plant",
   code_90490000:"Sewer survey and sewage treatment consultancy services",
   code_90491000:"Sewer survey services",
   code_90492000:"Sewage treatment consultancy services",
   code_90500000:"Refuse and waste related services",
   code_90510000:"Refuse disposal and treatment",
   code_90511000:"Refuse collection services",
   code_90511100:"Urban solid-refuse collection services",
   code_90511200:"Household-refuse collection services",
   code_90511300:"Litter collection services",
   code_90511400:"Paper collecting services",
   code_90512000:"Refuse transport services",
   code_90513000:"Non-hazardous refuse and waste treatment and disposal services",
   code_90513100:"Household-refuse disposal services",
   code_90513200:"Urban solid-refuse disposal services",
   code_90513300:"Refuse incineration services",
   code_90513400:"Ash disposal services",
   code_90513500:"Treatment and disposal of foul liquids",
   code_90513600:"Sludge removal services",
   code_90513700:"Sludge transport services",
   code_90513800:"Sludge treatment services",
   code_90513900:"Sludge disposal services",
   code_90514000:"Refuse recycling services",
   code_90520000:"Radioactive-, toxic-, medical- and hazardous waste services",
   code_90521000:"Radioactive waste treatment services",
   code_90521100:"Collection of radioactive waste",
   code_90521200:"Radioactive waste storage services",
   code_90521300:"Disposal of radioactive waste",
   code_90521400:"Transport of radioactive waste",
   code_90521410:"Transportation of low level nuclear waste",
   code_90521420:"Transportation of intermediate level nuclear waste",
   code_90521500:"Packaging of radioactive waste",
   code_90521510:"Packaging of low level nuclear waste",
   code_90521520:"Packaging of intermediate level nuclear waste",
   code_90522000:"Services relating to contaminated soil",
   code_90522100:"Removal of contaminated soil",
   code_90522200:"Disposal of contaminated soil",
   code_90522300:"Contaminated-soil treatment services",
   code_90522400:"Cleaning and treatment of soil",
   code_90523000:"Toxic waste disposal services except radioactive waste and contaminated soil",
   code_90523100:"Weapons and ammunition disposal services",
   code_90523200:"Bomb-disposal services",
   code_90523300:"Mine sweeping services",
   code_90524000:"Medical waste services",
   code_90524100:"Clinical-waste collection services",
   code_90524200:"Clinical-waste disposal services",
   code_90524300:"Removal services of biological waste",
   code_90524400:"Collection, transport and disposal of hospital waste",
   code_90530000:"Operation of a refuse site",
   code_90531000:"Landfill management services",
   code_90532000:"Coal-tip management services",
   code_90533000:"Waste-tip management services",
   code_90600000:"Cleaning and sanitation services in urban or rural areas, and related services",
   code_90610000:"Street-cleaning and sweeping services",
   code_90611000:"Street-cleaning services",
   code_90612000:"Street-sweeping services",
   code_90620000:"Snow-clearing services",
   code_90630000:"Ice-clearing services",
   code_90640000:"Gully cleaning and emptying services",
   code_90641000:"Gully cleaning services",
   code_90642000:"Gully emptying services",
   code_90650000:"Asbestos removal services",
   code_90660000:"Deleading services",
   code_90670000:"Disinfecting and exterminating services in urban or rural areas",
   code_90680000:"Beach cleaning services",
   code_90690000:"Graffiti removal services",
   code_90700000:"Environmental services",
   code_90710000:"Environmental management",
   code_90711000:"Environmental impact assessment other than for construction",
   code_90711100:"Risk or hazard assessment other than for construction",
   code_90711200:"Environmental standards other than for construction",
   code_90711300:"Environmental indicators analysis other than for construction",
   code_90711400:"Environmental Impact Assessment (EIA) services other than for construction",
   code_90711500:"Environmental monitoring other than for construction",
   code_90712000:"Environmental planning",
   code_90712100:"Urban environmental development planning",
   code_90712200:"Forest conservation strategy planning",
   code_90712300:"Marine conservation strategy planning",
   code_90712400:"Natural resources management or conservation strategy planning services",
   code_90712500:"Environmental institution building or planning",
   code_90713000:"Environmental issues consultancy services",
   code_90713100:"Consulting services for water-supply and waste-water other than for construction",
   code_90714000:"Environmental auditing",
   code_90714100:"Environmental information systems",
   code_90714200:"Corporate environmental auditing services",
   code_90714300:"Sectoral environmental auditing services",
   code_90714400:"Activity specific environmental auditing services",
   code_90714500:"Environmental quality control services",
   code_90714600:"Environmental security control services",
   code_90715000:"Pollution investigation services",
   code_90715100:"Chemicals and oil pollution investigation services",
   code_90715110:"Gasworks site investigation",
   code_90715120:"Chemical works or oil refinery waste site investigation",
   code_90715200:"Other pollution investigation services",
   code_90715210:"Oil depot or terminal site investigation",
   code_90715220:"Industrial site investigation",
   code_90715230:"Industrial waste site investigation",
   code_90715240:"Wood treatment plant site investigation",
   code_90715250:"Dry cleaning plants site investigation",
   code_90715260:"Foundry site investigation",
   code_90715270:"Recycling plant site investigation",
   code_90715280:"Food processing plant site investigation",
   code_90720000:"Environmental protection",
   code_90721000:"Environmental safety services",
   code_90721100:"Landscape protection services",
   code_90721200:"Ozone protection services",
   code_90721300:"Food or feed contamination protection services",
   code_90721400:"Genetic resources protection services",
   code_90721500:"Toxic substances protection services",
   code_90721600:"Radiation protection services",
   code_90721700:"Endangered species protection services",
   code_90721800:"Natural risks or hazards protection services",
   code_90722000:"Environmental rehabilitation",
   code_90722100:"Industrial site rehabilitation",
   code_90722200:"Environmental decontamination services",
   code_90722300:"Land reclamation services",
   code_90730000:"Pollution tracking and monitoring and rehabilitation",
   code_90731000:"Services related to air pollution",
   code_90731100:"Air quality management",
   code_90731200:"Transboundary air pollution management or control services",
   code_90731210:"Purchase of CO2 emission credits",
   code_90731300:"Air pollution protection services",
   code_90731400:"Air pollution monitoring or measurement services",
   code_90731500:"Toxic gas detection services",
   code_90731600:"Methane monitoring",
   code_90731700:"Carbon dioxide monitoring services",
   code_90731800:"Airborne particle monitoring",
   code_90731900:"Ozone depletion monitoring services",
   code_90732000:"Services related to soil pollution",
   code_90732100:"Soil pollution protection services",
   code_90732200:"Polluted soil removal services",
   code_90732300:"Polluted soil treatment or rehabilitation",
   code_90732400:"Soil pollution advisory services",
   code_90732500:"Soil pollution mapping",
   code_90732600:"Soil pollution measurement or monitoring",
   code_90732700:"Organic fertilizer pollution assessment",
   code_90732800:"Pesticides pollution assessment",
   code_90732900:"Nitrates and phosphates pollution assessment",
   code_90732910:"Nitrates pollution assessment",
   code_90732920:"Phosphates pollution assessment",
   code_90733000:"Services related to water pollution",
   code_90733100:"Surface water pollution monitoring or control services",
   code_90733200:"Surface water pollution rehabilitation services",
   code_90733300:"Surface water pollution protection services",
   code_90733400:"Surface water treatment services",
   code_90733500:"Surface water pollution drainage services",
   code_90733600:"Transboundary water pollution management or control services",
   code_90733700:"Groundwater pollution monitoring or control services",
   code_90733800:"Groundwater pollution drainage services",
   code_90733900:"Groundwater pollution treatment or rehabilitation",
   code_90740000:"Pollutants tracking and monitoring and rehabilitation services",
   code_90741000:"Services related to oil pollution",
   code_90741100:"Oil spillage monitoring services",
   code_90741200:"Oil spillage control services",
   code_90741300:"Oil spillage rehabilitation services",
   code_90742000:"Services related to noise pollution",
   code_90742100:"Noise control services",
   code_90742200:"Noise pollution protection services",
   code_90742300:"Noise pollution monitoring services",
   code_90742400:"Noise pollution advisory services",
   code_90743000:"Services related to toxic substances pollution",
   code_90743100:"Toxic substances monitoring services",
   code_90743200:"Toxic substances rehabilitation services",
   code_90900000:"Cleaning and sanitation services",
   code_90910000:"Cleaning services",
   code_90911000:"Accommodation, building and window cleaning services",
   code_90911100:"Accommodation cleaning services",
   code_90911200:"Building-cleaning services",
   code_90911300:"Window-cleaning services",
   code_90912000:"Blast-cleaning services for tubular structures",
   code_90913000:"Tank and reservoir cleaning services",
   code_90913100:"Tank-cleaning services",
   code_90913200:"Reservoir cleaning services",
   code_90914000:"Car park cleaning services",
   code_90915000:"Furnace and chimney cleaning services",
   code_90916000:"Cleaning services of telephone equipment",
   code_90917000:"Cleaning services of transport equipment",
   code_90918000:"Bin-cleaning services",
   code_90919000:"Office, school and office equipment cleaning services",
   code_90919100:"Cleaning services of office equipment",
   code_90919200:"Office cleaning services",
   code_90919300:"School cleaning services",
   code_90920000:"Facility related sanitation services",
   code_90921000:"Disinfecting and exterminating services",
   code_90922000:"Pest-control services",
   code_90923000:"Rat-disinfestation services",
   code_90924000:"Fumigation services",
   code_92000000:"Recreational, cultural and sporting services",
   code_92100000:"Motion picture and video services",
   code_92110000:"Motion picture and video tape production and related services",
   code_92111000:"Motion picture and video production services",
   code_92111100:"Training-film and video-tape production",
   code_92111200:"Advertising, propaganda and information film and video-tape production",
   code_92111210:"Advertising film production",
   code_92111220:"Advertising video-tape production",
   code_92111230:"Propaganda film production",
   code_92111240:"Propaganda video-tape production",
   code_92111250:"Information film production",
   code_92111260:"Information video-tape production",
   code_92111300:"Entertainment film and video-tape production",
   code_92111310:"Entertainment film production",
   code_92111320:"Entertainment video-tape production",
   code_92112000:"Services in connection with motion-picture and video-tape production",
   code_92120000:"Motion-picture or video-tape distribution services",
   code_92121000:"Video-tape distribution services",
   code_92122000:"Motion picture distribution services",
   code_92130000:"Motion picture projection services",
   code_92140000:"Video-tape projection services",
   code_92200000:"Radio and television services",
   code_92210000:"Radio services",
   code_92211000:"Radio production services",
   code_92213000:"Small scale radio systems services",
   code_92214000:"Radio studio or equipment services",
   code_92215000:"General Mobile Radio Services (GMRS)",
   code_92216000:"Family Radio Services (FRS)",
   code_92217000:"General Mobile Radio Services/Family Radio Services (GMRS/FRS)",
   code_92220000:"Television services",
   code_92221000:"Television production services",
   code_92222000:"Closed circuit television services",
   code_92224000:"Digital television",
   code_92225000:"Interactive television",
   code_92225100:"Film-on-demand television",
   code_92226000:"Teleprogrammation",
   code_92230000:"Radio and television cable services",
   code_92231000:"International bilateral services and international private leased lines",
   code_92232000:"Cable TV",
   code_92300000:"Entertainment services",
   code_92310000:"Artistic and literary creation and interpretation services",
   code_92311000:"Works of art",
   code_92312000:"Artistic services",
   code_92312100:"Theatrical producers', singer groups', bands' and orchestras' entertainment services",
   code_92312110:"Theatrical producer entertainment services",
   code_92312120:"Singer group entertainment services",
   code_92312130:"Band entertainment services",
   code_92312140:"Orchestral entertainment services",
   code_92312200:"Services provided by authors, composers, sculptors, entertainers and other individual artists",
   code_92312210:"Services provided by authors",
   code_92312211:"Writing agency services",
   code_92312212:"Services related to the preparation of training manuals",
   code_92312213:"Technical author services",
   code_92312220:"Services provided by composers",
   code_92312230:"Services provided by sculptors",
   code_92312240:"Services provided by entertainers",
   code_92312250:"Services provided by individual artists",
   code_92312251:"Disk-jockey services",
   code_92320000:"Arts-facility operation services",
   code_92330000:"Recreational-area services",
   code_92331000:"Fair and amusement park services",
   code_92331100:"Fair services",
   code_92331200:"Amusement park services",
   code_92331210:"Children animation services",
   code_92332000:"Beach services",
   code_92340000:"Dance and performance entertainment services",
   code_92341000:"Circus services",
   code_92342000:"Dance-instruction services",
   code_92342100:"Ballroom dance-instruction services",
   code_92342200:"Discotheque dance-instruction services",
   code_92350000:"Gambling and betting services",
   code_92351000:"Gambling services",
   code_92351100:"Lottery operating services",
   code_92351200:"Casino operating services",
   code_92352000:"Betting services",
   code_92352100:"Totalisator operating services",
   code_92352200:"Bookmaking services",
   code_92360000:"Pyrotechnic services",
   code_92370000:"Sound technician",
   code_92400000:"News-agency services",
   code_92500000:"Library, archives, museums and other cultural services",
   code_92510000:"Library and archive services",
   code_92511000:"Library services",
   code_92512000:"Archive services",
   code_92512100:"Archive destruction services",
   code_92520000:"Museum services and preservation services of historical sites and buildings",
   code_92521000:"Museum services",
   code_92521100:"Museum-exhibition services",
   code_92521200:"Preservation services of exhibits and specimens",
   code_92521210:"Preservation services of exhibits",
   code_92521220:"Preservation services of specimens",
   code_92522000:"Preservation services of historical sites and buildings",
   code_92522100:"Preservation services of historical sites",
   code_92522200:"Preservation services of historical buildings",
   code_92530000:"Botanical and zoological garden services and nature reserve services",
   code_92531000:"Botanical garden services",
   code_92532000:"Zoological garden services",
   code_92533000:"Nature reserve services",
   code_92534000:"Wildlife preservation services",
   code_92600000:"Sporting services",
   code_92610000:"Sports facilities operation services",
   code_92620000:"Sport-related services",
   code_92621000:"Sports-event promotion services",
   code_92622000:"Sports-event organisation services",
   code_92700000:"Cybercafé services",
   code_98000000:"Other community, social and personal services",
   code_98100000:"Membership organisation services",
   code_98110000:"Services furnished by business, professional and specialist organisations",
   code_98111000:"Services furnished by business organisations",
   code_98112000:"Services furnished by professional organisations",
   code_98113000:"Services furnished by specialist organisations",
   code_98113100:"Nuclear safety services",
   code_98120000:"Services furnished by trade unions",
   code_98130000:"Miscellaneous membership organisations services",
   code_98131000:"Religious services",
   code_98132000:"Services furnished by political organisations",
   code_98133000:"Services furnished by social membership organisations",
   code_98133100:"Civic betterment and community facility support services",
   code_98133110:"Services provided by youth associations",
   code_98200000:"Equal opportunities consultancy services",
   code_98300000:"Miscellaneous services",
   code_98310000:"Washing and dry-cleaning services",
   code_98311000:"Laundry-collection services",
   code_98311100:"Laundry-management services",
   code_98311200:"Laundry-operation services",
   code_98312000:"Textile-cleaning services",
   code_98312100:"Textile-impregnation services",
   code_98313000:"Fur-products cleaning services",
   code_98314000:"Colouring services",
   code_98315000:"Pressing services",
   code_98316000:"Dyeing services",
   code_98320000:"Hairdressing and beauty treatment services",
   code_98321000:"Hairdressing services",
   code_98321100:"Barbers' services",
   code_98322000:"Beauty treatment services",
   code_98322100:"Cosmetic treatment, manicuring and pedicuring services",
   code_98322110:"Cosmetic treatment services",
   code_98322120:"Manicuring services",
   code_98322130:"Pedicuring services",
   code_98322140:"Make-up services",
   code_98330000:"Physical well-being services",
   code_98331000:"Turkish bath services",
   code_98332000:"Spa services",
   code_98333000:"Massage services",
   code_98334000:"Wellness services",
   code_98336000:"Training, workout or aerobic services",
   code_98340000:"Accommodation and office services",
   code_98341000:"Accommodation services",
   code_98341100:"Accommodation management services",
   code_98341110:"Housekeeping services",
   code_98341120:"Portering services",
   code_98341130:"Janitorial services",
   code_98341140:"Caretaker services",
   code_98342000:"Work environment services",
   code_98350000:"Civic-amenity services",
   code_98351000:"Car park management services",
   code_98351100:"Car park services",
   code_98351110:"Parking enforcement services",
   code_98360000:"Marine services",
   code_98361000:"Aquatic marine services",
   code_98362000:"Port management services",
   code_98362100:"Marine-base support services",
   code_98363000:"Diving services",
   code_98370000:"Funeral and related services",
   code_98371000:"Funeral services",
   code_98371100:"Cemetery services and cremation services",
   code_98371110:"Cemetery services",
   code_98371111:"Cemetery maintenance services",
   code_98371120:"Cremation services",
   code_98371200:"Undertaking services",
   code_98380000:"Dog kennel services",
   code_98390000:"Other services",
   code_98391000:"Decommissioning services",
   code_98392000:"Relocation services",
   code_98393000:"Tailoring services",
   code_98394000:"Upholstering services",
   code_98395000:"Locksmith services",
   code_98396000:"Instrument tuning services",
   code_98500000:"Private households with employed persons",
   code_98510000:"Services of commercial and industrial workers",
   code_98511000:"Services of commercial workers",
   code_98512000:"Services of industrial workers",
   code_98513000:"Manpower services for households",
   code_98513100:"Agency staff services for households",
   code_98513200:"Clerical staff services for households",
   code_98513300:"Temporary staff for households",
   code_98513310:"Home-help services",
   code_98514000:"Domestic services",
   code_98900000:"Services provided by extra-territorial organisations and bodies",
   code_98910000:"Services specific to international organisations and bodies",
   // code_00:"Other",
   code_AL:"Albania",
   code_AL0:"Shqipëria",
   code_AL01:"Veri",
   code_AL011:"Dibër",
   code_AL012:"Durrës",
   code_AL013:"Kukës",
   code_AL014:"Lezhë",
   code_AL015:"Shkodër",
   code_AL02:"Qender",
   code_AL021:"Elbasan",
   code_AL022:"Tiranë",
   code_AL03:"Jug",
   code_AL031:"Berat",
   code_AL032:"Fier",
   code_AL033:"Gjirokastër",
   code_AL034:"Korcë",
   code_AL035:"Vlorë",
   code_AT:"ÖSTERREICH",
   code_AT1:"OSTÖSTERREICH",
   code_AT11:"Burgenland",
   code_AT111:"Mittelburgenland",
   code_AT112:"Nordburgenland",
   code_AT113:"Südburgenland",
   code_AT12:"Niederösterreich",
   code_AT121:"Mostviertel-Eisenwurzen",
   code_AT122:"Niederösterreich-Süd",
   code_AT123:"Sankt Pölten",
   code_AT124:"Waldviertel",
   code_AT125:"Weinviertel",
   code_AT126:"Wiener Umland / Nordteil",
   code_AT127:"Wiener Umland / Südteil",
   code_AT13:"Wien",
   code_AT130:"Wien",
   code_AT2:"SÜDÖSTERREICH",
   code_AT21:"Kärnten",
   code_AT211:"Klagenfurt-Villach",
   code_AT212:"Oberkärnten",
   code_AT213:"Unterkärnten",
   code_AT22:"Steiermark",
   code_AT221:"Graz",
   code_AT222:"Liezen",
   code_AT223:"Östliche Obersteiermark",
   code_AT224:"Oststeiermark",
   code_AT225:"West- und Südsteiermark",
   code_AT226:"Westliche Obersteiermark",
   code_AT3:"WESTÖSTERREICH",
   code_AT31:"Oberösterreich",
   code_AT311:"Innviertel",
   code_AT312:"Linz-Wels",
   code_AT313:"Mühlviertel",
   code_AT314:"Steyr-Kirchdorf",
   code_AT315:"Traunviertel",
   code_AT32:"Salzburg",
   code_AT321:"Lungau",
   code_AT322:"Pinzgau-Pongau",
   code_AT323:"Salzburg und Umgebung",
   code_AT33:"Tirol",
   code_AT331:"Außerfern",
   code_AT332:"Innsbruck",
   code_AT333:"Osttirol",
   code_AT334:"Tiroler Oberland",
   code_AT335:"Tiroler Unterland",
   code_AT34:"Vorarlberg",
   code_AT341:"Bludenz-Bregenzer Wald",
   code_AT342:"Rheintal-Bodenseegebiet",
   code_ATZ:"EXTRA-REGIO NUTS 1",
   code_ATZZ:"Extra-Regio NUTS 2",
   code_ATZZZ:"Extra-Regio NUTS 3",
   code_BE:"BELGIQUE-BELGIË",
   code_BE1:"Brussels Hoofdstedelijk Gewest",
   code_BE10:"Brussels Hoofdstedelijk Gewest",
   code_BE100:"Arr. van Brussel-Hoofdstad",
   code_BE2:"Vlaams Gewest",
   code_BE21:"Prov. Antwerpen",
   code_BE211:"Arr. Antwerpen",
   code_BE212:"Arr. Mechelen",
   code_BE213:"Arr. Turnhout",
   code_BE22:"Prov. Limburg (BE)",
   code_BE221:"Arr. Hasselt",
   code_BE222:"Arr. Maaseik",
   code_BE223:"Arr. Tongeren",
   code_BE23:"Prov. Oost-Vlaanderen",
   code_BE231:"Arr. Aalst",
   code_BE232:"Arr. Dendermonde",
   code_BE233:"Arr. Eeklo",
   code_BE234:"Arr. Gent",
   code_BE235:"Arr. Oudenaarde",
   code_BE236:"Arr. Sint-Niklaas",
   code_BE24:"Prov. Vlaams-Brabant",
   code_BE241:"Arr. Halle-Vilvoorde",
   code_BE242:"Arr. Leuven",
   code_BE25:"Prov. West-Vlaanderen",
   code_BE251:"Arr. Brugge",
   code_BE252:"Arr. Diksmuide",
   code_BE253:"Arr. Ieper",
   code_BE254:"Arr. Kortrijk",
   code_BE255:"Arr. Oostende",
   code_BE256:"Arr. Roeselare",
   code_BE257:"Arr. Tielt",
   code_BE258:"Arr. Veurne",
   code_BE3:"RÉGION WALLONNE",
   code_BE31:"Prov. Brabant Wallon",
   code_BE310:"Arr. Nivelles",
   code_BE32:"Prov. Hainaut",
   code_BE321:"Arr. Ath",
   code_BE322:"Arr. Charleroi",
   code_BE323:"Arr. Mons",
   code_BE324:"Arr. Mouscron",
   code_BE325:"Arr. Soignies",
   code_BE326:"Arr. Thuin",
   code_BE327:"Arr. Tournai",
   code_BE33:"Prov. Liège",
   code_BE331:"Arr. Huy",
   code_BE332:"Arr. Liège",
   code_BE334:"Arr. Waremme",
   code_BE335:"Arr. Verviers - communes francophones",
   code_BE336:"Bezirk Verviers - Deutschsprachige Gemeinschaft",
   code_BE34:"Prov. Luxembourg (BE)",
   code_BE341:"Arr. Arlon",
   code_BE342:"Arr. Bastogne",
   code_BE343:"Arr. Marche-en-Famenne",
   code_BE344:"Arr. Neufchâteau",
   code_BE345:"Arr. Virton",
   code_BE35:"Prov. Namur",
   code_BE351:"Arr. Dinant",
   code_BE352:"Arr. Namur",
   code_BE353:"Arr. Philippeville",
   code_BEZ:"EXTRA-REGIO NUTS 1",
   code_BEZZ:"Extra-Regio NUTS 2",
   code_BEZZZ:"Extra-Regio NUTS 3",
   code_BG:"БЪЛГАРИЯ",
   code_BG3:"СЕВЕРНА И ЮГОИЗТОЧНА БЪЛГАРИЯ",
   code_BG31:"Северозападен",
   code_BG311:"Видин",
   code_BG312:"Монтана",
   code_BG313:"Враца",
   code_BG314:"Плевен",
   code_BG315:"Ловеч",
   code_BG32:"Северен централен",
   code_BG321:"Велико Търново",
   code_BG322:"Габрово",
   code_BG323:"Русе",
   code_BG324:"Разград",
   code_BG325:"Силистра",
   code_BG33:"Североизточен",
   code_BG331:"Варна",
   code_BG332:"Добрич",
   code_BG333:"Шумен",
   code_BG334:"Търговище",
   code_BG34:"Югоизточен",
   code_BG341:"Бургас",
   code_BG342:"Сливен",
   code_BG343:"Ямбол",
   code_BG344:"Стара Загора",
   code_BG4:"ЮГОЗАПАДНА И ЮЖНА ЦЕНТРАЛНА БЪЛГАРИЯ",
   code_BG41:"Югозападен",
   code_BG411:"София (столица)",
   code_BG412:"София",
   code_BG413:"Благоевград",
   code_BG414:"Перник",
   code_BG415:"Кюстендил",
   code_BG42:"Южен централен",
   code_BG421:"Пловдив",
   code_BG422:"Хасково",
   code_BG423:"Пазарджик",
   code_BG424:"Смолян",
   code_BG425:"Кърджали",
   code_BGZ:"EXTRA-REGIO NUTS 1",
   code_BGZZ:"Extra-Regio NUTS 2",
   code_BGZZZ:"Extra-Regio NUTS 3",
   code_CH:"Switzerland",
   code_CH0:"Schweiz/Suisse/Svizzera",
   code_CH01:"Région lémanique",
   code_CH011:"Vaud",
   code_CH012:"Valais / Wallis",
   code_CH013:"Genève",
   code_CH02:"Espace Mittelland",
   code_CH021:"Bern / Berne",
   code_CH022:"Fribourg / Freiburg",
   code_CH023:"Solothurn",
   code_CH024:"Neuchâtel",
   code_CH025:"Jura",
   code_CH03:"Nordwestschweiz",
   code_CH031:"Basel-Stadt",
   code_CH032:"Basel-Landschaft",
   code_CH033:"Aargau",
   code_CH04:"Zürich",
   code_CH040:"Zürich",
   code_CH05:"Ostschweiz",
   code_CH051:"Glarus",
   code_CH052:"Schaffhausen",
   code_CH053:"Appenzell Ausserrhoden",
   code_CH054:"Appenzell Innerrhoden",
   code_CH055:"St. Gallen",
   code_CH056:"Graubünden / Grigioni / Grischun",
   code_CH057:"Thurgau",
   code_CH06:"Zentralschweiz",
   code_CH061:"Luzern",
   code_CH062:"Uri",
   code_CH063:"Schwyz",
   code_CH064:"Obwalden",
   code_CH065:"Nidwalden",
   code_CH066:"Zug",
   code_CH07:"Ticino",
   code_CH070:"Ticino",
   code_CY:"ΚΥΠΡΟΣ",
   code_CY0:"ΚΥΠΡΟΣ",
   code_CY00:"Κύπρος",
   code_CY000:"Κύπρος",
   code_CYZ:"EXTRA-REGIO NUTS 1",
   code_CYZZ:"Extra-Regio NUTS 2",
   code_CYZZZ:"Extra-Regio NUTS 3",
   code_CZ:"ČESKÁ REPUBLIKA",
   code_CZ0:"ČESKÁ REPUBLIKA",
   code_CZ01:"Praha",
   code_CZ010:"Hlavní město Praha",
   code_CZ02:"Střední Čechy",
   code_CZ020:"Středočeský kraj",
   code_CZ03:"Jihozápad",
   code_CZ031:"Jihočeský kraj",
   code_CZ032:"Plzeňský kraj",
   code_CZ04:"Severozápad",
   code_CZ041:"Karlovarský kraj",
   code_CZ042:"Ústecký kraj",
   code_CZ05:"Severovýchod",
   code_CZ051:"Liberecký kraj",
   code_CZ052:"Královéhradecký kraj",
   code_CZ053:"Pardubický kraj",
   code_CZ06:"Jihovýchod",
   code_CZ063:"Kraj Vysočina",
   code_CZ064:"Jihomoravský kraj",
   code_CZ07:"Střední Morava",
   code_CZ071:"Olomoucký kraj",
   code_CZ072:"Zlínský kraj",
   code_CZ08:"Moravskoslezsko",
   code_CZ080:"Moravskoslezský kraj",
   code_CZZ:"EXTRA-REGIO NUTS 1",
   code_CZZZ:"Extra-Regio NUTS 2",
   code_CZZZZ:"Extra-Regio NUTS 3",
   code_DE:"DEUTSCHLAND",
   code_DE1:"BADEN-WÜRTTEMBERG",
   code_DE11:"Stuttgart",
   code_DE111:"Stuttgart, Stadtkreis",
   code_DE112:"Böblingen",
   code_DE113:"Esslingen",
   code_DE114:"Göppingen",
   code_DE115:"Ludwigsburg",
   code_DE116:"Rems-Murr-Kreis",
   code_DE117:"Heilbronn, Stadtkreis",
   code_DE118:"Heilbronn, Landkreis",
   code_DE119:"Hohenlohekreis",
   code_DE11A:"Schwäbisch Hall",
   code_DE11B:"Main-Tauber-Kreis",
   code_DE11C:"Heidenheim",
   code_DE11D:"Ostalbkreis",
   code_DE12:"Karlsruhe",
   code_DE121:"Baden-Baden, Stadtkreis",
   code_DE122:"Karlsruhe, Stadtkreis",
   code_DE123:"Karlsruhe, Landkreis",
   code_DE124:"Rastatt",
   code_DE125:"Heidelberg, Stadtkreis",
   code_DE126:"Mannheim, Stadtkreis",
   code_DE127:"Neckar-Odenwald-Kreis",
   code_DE128:"Rhein-Neckar-Kreis",
   code_DE129:"Pforzheim, Stadtkreis",
   code_DE12A:"Calw",
   code_DE12B:"Enzkreis",
   code_DE12C:"Freudenstadt",
   code_DE13:"Freiburg",
   code_DE131:"Freiburg im Breisgau, Stadtkreis",
   code_DE132:"Breisgau-Hochschwarzwald",
   code_DE133:"Emmendingen",
   code_DE134:"Ortenaukreis",
   code_DE135:"Rottweil",
   code_DE136:"Schwarzwald-Baar-Kreis",
   code_DE137:"Tuttlingen",
   code_DE138:"Konstanz",
   code_DE139:"Lörrach",
   code_DE13A:"Waldshut",
   code_DE14:"Tübingen",
   code_DE141:"Reutlingen",
   code_DE142:"Tübingen, Landkreis",
   code_DE143:"Zollernalbkreis",
   code_DE144:"Ulm, Stadtkreis",
   code_DE145:"Alb-Donau-Kreis",
   code_DE146:"Biberach",
   code_DE147:"Bodenseekreis",
   code_DE148:"Ravensburg",
   code_DE149:"Sigmaringen",
   code_DE2:"BAYERN",
   code_DE21:"Oberbayern",
   code_DE211:"Ingolstadt, Kreisfreie Stadt",
   code_DE212:"München, Kreisfreie Stadt",
   code_DE213:"Rosenheim, Kreisfreie Stadt",
   code_DE214:"Altötting",
   code_DE215:"Berchtesgadener Land",
   code_DE216:"Bad Tölz-Wolfratshausen",
   code_DE217:"Dachau",
   code_DE218:"Ebersberg",
   code_DE219:"Eichstätt",
   code_DE21A:"Erding",
   code_DE21B:"Freising",
   code_DE21C:"Fürstenfeldbruck",
   code_DE21D:"Garmisch-Partenkirchen",
   code_DE21E:"Landsberg am Lech",
   code_DE21F:"Miesbach",
   code_DE21G:"Mühldorf a. Inn",
   code_DE21H:"München, Landkreis",
   code_DE21I:"Neuburg-Schrobenhausen",
   code_DE21J:"Pfaffenhofen a. d. Ilm",
   code_DE21K:"Rosenheim, Landkreis",
   code_DE21L:"Starnberg",
   code_DE21M:"Traunstein",
   code_DE21N:"Weilheim-Schongau",
   code_DE22:"Niederbayern",
   code_DE221:"Landshut, Kreisfreie Stadt",
   code_DE222:"Passau, Kreisfreie Stadt",
   code_DE223:"Straubing, Kreisfreie Stadt",
   code_DE224:"Deggendorf",
   code_DE225:"Freyung-Grafenau",
   code_DE226:"Kelheim",
   code_DE227:"Landshut, Landkreis",
   code_DE228:"Passau, Landkreis",
   code_DE229:"Regen",
   code_DE22A:"Rottal-Inn",
   code_DE22B:"Straubing-Bogen",
   code_DE22C:"Dingolfing-Landau",
   code_DE23:"Oberpfalz",
   code_DE231:"Amberg, Kreisfreie Stadt",
   code_DE232:"Regensburg, Kreisfreie Stadt",
   code_DE233:"Weiden i. d. Opf, Kreisfreie Stadt",
   code_DE234:"Amberg-Sulzbach",
   code_DE235:"Cham",
   code_DE236:"Neumarkt i. d. OPf.",
   code_DE237:"Neustadt a. d. Waldnaab",
   code_DE238:"Regensburg, Landkreis",
   code_DE239:"Schwandorf",
   code_DE23A:"Tirschenreuth",
   code_DE24:"Oberfranken",
   code_DE241:"Bamberg, Kreisfreie Stadt",
   code_DE242:"Bayreuth, Kreisfreie Stadt",
   code_DE243:"Coburg, Kreisfreie Stadt",
   code_DE244:"Hof, Kreisfreie Stadt",
   code_DE245:"Bamberg, Landkreis",
   code_DE246:"Bayreuth, Landkreis",
   code_DE247:"Coburg, Landkreis",
   code_DE248:"Forchheim",
   code_DE249:"Hof, Landkreis",
   code_DE24A:"Kronach",
   code_DE24B:"Kulmbach",
   code_DE24C:"Lichtenfels",
   code_DE24D:"Wunsiedel i. Fichtelgebirge",
   code_DE25:"Mittelfranken",
   code_DE251:"Ansbach, Kreisfreie Stadt",
   code_DE252:"Erlangen, Kreisfreie Stadt",
   code_DE253:"Fürth, Kreisfreie Stadt",
   code_DE254:"Nürnberg, Kreisfreie Stadt",
   code_DE255:"Schwabach, Kreisfreie Stadt",
   code_DE256:"Ansbach, Landkreis",
   code_DE257:"Erlangen-Höchstadt",
   code_DE258:"Fürth, Landkreis",
   code_DE259:"Nürnberger Land",
   code_DE25A:"Neustadt a. d. Aisch-Bad Windsheim",
   code_DE25B:"Roth",
   code_DE25C:"Weißenburg-Gunzenhausen",
   code_DE26:"Unterfranken",
   code_DE261:"Aschaffenburg, Kreisfreie Stadt",
   code_DE262:"Schweinfurt, Kreisfreie Stadt",
   code_DE263:"Würzburg, Kreisfreie Stadt",
   code_DE264:"Aschaffenburg, Landkreis",
   code_DE265:"Bad Kissingen",
   code_DE266:"Rhön-Grabfeld",
   code_DE267:"Haßberge",
   code_DE268:"Kitzingen",
   code_DE269:"Miltenberg",
   code_DE26A:"Main-Spessart",
   code_DE26B:"Schweinfurt, Landkreis",
   code_DE26C:"Würzburg, Landkreis",
   code_DE27:"Schwaben",
   code_DE271:"Augsburg, Kreisfreie Stadt",
   code_DE272:"Kaufbeuren, Kreisfreie Stadt",
   code_DE273:"Kempten (Allgäu), Kreisfreie Stadt",
   code_DE274:"Memmingen, Kreisfreie Stadt",
   code_DE275:"Aichach-Friedberg",
   code_DE276:"Augsburg, Landkreis",
   code_DE277:"Dillingen a.d. Donau",
   code_DE278:"Günzburg",
   code_DE279:"Neu-Ulm",
   code_DE27A:"Lindau (Bodensee)",
   code_DE27B:"Ostallgäu",
   code_DE27C:"Unterallgäu",
   code_DE27D:"Donau-Ries",
   code_DE27E:"Oberallgäu",
   code_DE3:"BERLIN",
   code_DE30:"Berlin",
   code_DE300:"Berlin",
   code_DE4:"BRANDENBURG",
   code_DE40:"Brandenburg",
   code_DE401:"Brandenburg an der Havel, Kreisfreie Stadt",
   code_DE402:"Cottbus, Kreisfreie Stadt",
   code_DE403:"Frankfurt (Oder), Kreisfreie Stadt",
   code_DE404:"Potsdam, Kreisfreie Stadt",
   code_DE405:"Barnim",
   code_DE406:"Dahme-Spreewald",
   code_DE407:"Elbe-Elster",
   code_DE408:"Havelland",
   code_DE409:"Märkisch-Oderland",
   code_DE40A:"Oberhavel",
   code_DE40B:"Oberspreewald-Lausitz",
   code_DE40C:"Oder-Spree",
   code_DE40D:"Ostprignitz-Ruppin",
   code_DE40E:"Potsdam-Mittelmark",
   code_DE40F:"Prignitz",
   code_DE40G:"Spree-Neiße",
   code_DE40H:"Teltow-Fläming",
   code_DE40I:"Uckermark",
   code_DE5:"BREMEN",
   code_DE50:"Bremen",
   code_DE501:"Bremen, Kreisfreie Stadt",
   code_DE502:"Bremerhaven, Kreisfreie Stadt",
   code_DE6:"HAMBURG",
   code_DE60:"Hamburg",
   code_DE600:"Hamburg",
   code_DE7:"HESSEN",
   code_DE71:"Darmstadt",
   code_DE711:"Darmstadt, Kreisfreie Stadt",
   code_DE712:"Frankfurt am Main, Kreisfreie Stadt",
   code_DE713:"Offenbach am Main, Kreisfreie Stadt",
   code_DE714:"Wiesbaden, Kreisfreie Stadt",
   code_DE715:"Bergstraße",
   code_DE716:"Darmstadt-Dieburg",
   code_DE717:"Groß-Gerau",
   code_DE718:"Hochtaunuskreis",
   code_DE719:"Main-Kinzig-Kreis",
   code_DE71A:"Main-Taunus-Kreis",
   code_DE71B:"Odenwaldkreis",
   code_DE71C:"Offenbach, Landkreis",
   code_DE71D:"Rheingau-Taunus-Kreis",
   code_DE71E:"Wetteraukreis",
   code_DE72:"Gießen",
   code_DE721:"Gießen, Landkreis",
   code_DE722:"Lahn-Dill-Kreis",
   code_DE723:"Limburg-Weilburg",
   code_DE724:"Marburg-Biedenkopf",
   code_DE725:"Vogelsbergkreis",
   code_DE73:"Kassel",
   code_DE731:"Kassel, Kreisfreie Stadt",
   code_DE732:"Fulda",
   code_DE733:"Hersfeld-Rotenburg",
   code_DE734:"Kassel, Landkreis",
   code_DE735:"Schwalm-Eder-Kreis",
   code_DE736:"Waldeck-Frankenberg",
   code_DE737:"Werra-Meißner-Kreis",
   code_DE8:"MECKLENBURG-VORPOMMERN",
   code_DE80:"Mecklenburg-Vorpommern",
   code_DE803:"Rostock, Kreisfreie Stadt",
   code_DE804:"Schwerin, Kreisfreie Stadt",
   code_DE80J:"Mecklenburgische Seenplatte",
   code_DE80K:"Landkreis Rostock",
   code_DE80L:"Vorpommern-Rügen",
   code_DE80M:"Nordwestmecklenburg",
   code_DE80N:"Vorpommern-Greifswald",
   code_DE80O:"Ludwigslust-Parchim",
   code_DE9:"NIEDERSACHSEN",
   code_DE91:"Braunschweig",
   code_DE911:"Braunschweig, Kreisfreie Stadt",
   code_DE912:"Salzgitter, Kreisfreie Stadt",
   code_DE913:"Wolfsburg, Kreisfreie Stadt",
   code_DE914:"Gifhorn",
   code_DE916:"Goslar",
   code_DE917:"Helmstedt",
   code_DE918:"Northeim",
   code_DE91A:"Peine",
   code_DE91B:"Wolfenbüttel",
   code_DE91C:"Göttingen",
   code_DE92:"Hannover",
   code_DE922:"Diepholz",
   code_DE923:"Hameln-Pyrmont",
   code_DE925:"Hildesheim",
   code_DE926:"Holzminden",
   code_DE927:"Nienburg (Weser)",
   code_DE928:"Schaumburg",
   code_DE929:"Region Hannover",
   code_DE93:"Lüneburg",
   code_DE931:"Celle",
   code_DE932:"Cuxhaven",
   code_DE933:"Harburg",
   code_DE934:"Lüchow-Dannenberg",
   code_DE935:"Lüneburg, Landkreis",
   code_DE936:"Osterholz",
   code_DE937:"Rotenburg (Wümme)",
   code_DE938:"Heidekreis",
   code_DE939:"Stade",
   code_DE93A:"Uelzen",
   code_DE93B:"Verden",
   code_DE94:"Weser-Ems",
   code_DE941:"Delmenhorst, Kreisfreie Stadt",
   code_DE942:"Emden, Kreisfreie Stadt",
   code_DE943:"Oldenburg (Oldenburg), Kreisfreie Stadt",
   code_DE944:"Osnabrück, Kreisfreie Stadt",
   code_DE945:"Wilhelmshaven, Kreisfreie Stadt",
   code_DE946:"Ammerland",
   code_DE947:"Aurich",
   code_DE948:"Cloppenburg",
   code_DE949:"Emsland",
   code_DE94A:"Friesland (DE)",
   code_DE94B:"Grafschaft Bentheim",
   code_DE94C:"Leer",
   code_DE94D:"Oldenburg, Landkreis",
   code_DE94E:"Osnabrück, Landkreis",
   code_DE94F:"Vechta",
   code_DE94G:"Wesermarsch",
   code_DE94H:"Wittmund",
   code_DEA:"NORDRHEIN-WESTFALEN",
   code_DEA1:"Düsseldorf",
   code_DEA11:"Düsseldorf, Kreisfreie Stadt",
   code_DEA12:"Duisburg, Kreisfreie Stadt",
   code_DEA13:"Essen, Kreisfreie Stadt",
   code_DEA14:"Krefeld, Kreisfreie Stadt",
   code_DEA15:"Mönchengladbach, Kreisfreie Stadt",
   code_DEA16:"Mülheim an der Ruhr, Kreisfreie Stadt",
   code_DEA17:"Oberhausen, Kreisfreie Stadt",
   code_DEA18:"Remscheid, Kreisfreie Stadt",
   code_DEA19:"Solingen, Kreisfreie Stadt",
   code_DEA1A:"Wuppertal, Kreisfreie Stadt",
   code_DEA1B:"Kleve",
   code_DEA1C:"Mettmann",
   code_DEA1D:"Rhein-Kreis Neuss",
   code_DEA1E:"Viersen",
   code_DEA1F:"Wesel",
   code_DEA2:"Köln",
   code_DEA22:"Bonn, Kreisfreie Stadt",
   code_DEA23:"Köln, Kreisfreie Stadt",
   code_DEA24:"Leverkusen, Kreisfreie Stadt",
   code_DEA26:"Düren",
   code_DEA27:"Rhein-Erft-Kreis",
   code_DEA28:"Euskirchen",
   code_DEA29:"Heinsberg",
   code_DEA2A:"Oberbergischer Kreis",
   code_DEA2B:"Rheinisch-Bergischer Kreis",
   code_DEA2C:"Rhein-Sieg-Kreis",
   code_DEA2D:"Städteregion Aachen",
   code_DEA3:"Münster",
   code_DEA31:"Bottrop, Kreisfreie Stadt",
   code_DEA32:"Gelsenkirchen, Kreisfreie Stadt",
   code_DEA33:"Münster, Kreisfreie Stadt",
   code_DEA34:"Borken",
   code_DEA35:"Coesfeld",
   code_DEA36:"Recklinghausen",
   code_DEA37:"Steinfurt",
   code_DEA38:"Warendorf",
   code_DEA4:"Detmold",
   code_DEA41:"Bielefeld, Kreisfreie Stadt",
   code_DEA42:"Gütersloh",
   code_DEA43:"Herford",
   code_DEA44:"Höxter",
   code_DEA45:"Lippe",
   code_DEA46:"Minden-Lübbecke",
   code_DEA47:"Paderborn",
   code_DEA5:"Arnsberg",
   code_DEA51:"Bochum, Kreisfreie Stadt",
   code_DEA52:"Dortmund, Kreisfreie Stadt",
   code_DEA53:"Hagen, Kreisfreie Stadt",
   code_DEA54:"Hamm, Kreisfreie Stadt",
   code_DEA55:"Herne, Kreisfreie Stadt",
   code_DEA56:"Ennepe-Ruhr-Kreis",
   code_DEA57:"Hochsauerlandkreis",
   code_DEA58:"Märkischer Kreis",
   code_DEA59:"Olpe",
   code_DEA5A:"Siegen-Wittgenstein",
   code_DEA5B:"Soest",
   code_DEA5C:"Unna",
   code_DEB:"RHEINLAND-PFALZ",
   code_DEB1:"Koblenz",
   code_DEB11:"Koblenz, Kreisfreie Stadt",
   code_DEB12:"Ahrweiler",
   code_DEB13:"Altenkirchen (Westerwald)",
   code_DEB14:"Bad Kreuznach",
   code_DEB15:"Birkenfeld",
   code_DEB17:"Mayen-Koblenz",
   code_DEB18:"Neuwied",
   code_DEB1A:"Rhein-Lahn-Kreis",
   code_DEB1B:"Westerwaldkreis",
   code_DEB1C:"Cochem-Zell",
   code_DEB1D:"Rhein-Hunsrück-Kreis",
   code_DEB2:"Trier",
   code_DEB21:"Trier, Kreisfreie Stadt",
   code_DEB22:"Bernkastel-Wittlich",
   code_DEB23:"Eifelkreis Bitburg-Prüm",
   code_DEB24:"Vulkaneifel",
   code_DEB25:"Trier-Saarburg",
   code_DEB3:"Rheinhessen-Pfalz",
   code_DEB31:"Frankenthal (Pfalz), Kreisfreie Stadt",
   code_DEB32:"Kaiserslautern, Kreisfreie Stadt",
   code_DEB33:"Landau in der Pfalz, Kreisfreie Stadt",
   code_DEB34:"Ludwigshafen am Rhein, Kreisfreie Stadt",
   code_DEB35:"Mainz, Kreisfreie Stadt",
   code_DEB36:"Neustadt an der Weinstraße, Kreisfreie Stadt",
   code_DEB37:"Pirmasens, Kreisfreie Stadt",
   code_DEB38:"Speyer, Kreisfreie Stadt",
   code_DEB39:"Worms, Kreisfreie Stadt",
   code_DEB3A:"Zweibrücken, Kreisfreie Stadt",
   code_DEB3B:"Alzey-Worms",
   code_DEB3C:"Bad Dürkheim",
   code_DEB3D:"Donnersbergkreis",
   code_DEB3E:"Germersheim",
   code_DEB3F:"Kaiserslautern, Landkreis",
   code_DEB3G:"Kusel",
   code_DEB3H:"Südliche Weinstraße",
   code_DEB3I:"Rhein-Pfalz-Kreis",
   code_DEB3J:"Mainz-Bingen",
   code_DEB3K:"Südwestpfalz",
   code_DEC:"SAARLAND",
   code_DEC0:"Saarland",
   code_DEC01:"Regionalverband Saarbrücken",
   code_DEC02:"Merzig-Wadern",
   code_DEC03:"Neunkirchen",
   code_DEC04:"Saarlouis",
   code_DEC05:"Saarpfalz-Kreis",
   code_DEC06:"St. Wendel",
   code_DED:"SACHSEN",
   code_DED2:"Dresden",
   code_DED21:"Dresden, Kreisfreie Stadt",
   code_DED2C:"Bautzen",
   code_DED2D:"Görlitz",
   code_DED2E:"Meißen",
   code_DED2F:"Sächsische Schweiz-Osterzgebirge",
   code_DED4:"Chemnitz",
   code_DED41:"Chemnitz, Kreisfreie Stadt",
   code_DED42:"Erzgebirgskreis",
   code_DED43:"Mittelsachsen",
   code_DED44:"Vogtlandkreis",
   code_DED45:"Zwickau",
   code_DED5:"Leipzig",
   code_DED51:"Leipzig, Kreisfreie Stadt",
   code_DED52:"Leipzig",
   code_DED53:"Nordsachsen",
   code_DEE:"SACHSEN-ANHALT",
   code_DEE0:"Sachsen-Anhalt",
   code_DEE01:"Dessau-Roßlau, Kreisfreie Stadt",
   code_DEE02:"Halle (Saale), Kreisfreie Stadt",
   code_DEE03:"Magdeburg, Kreisfreie Stadt",
   code_DEE04:"Altmarkkreis Salzwedel",
   code_DEE05:"Anhalt-Bitterfeld",
   code_DEE06:"Jerichower Land",
   code_DEE07:"Börde",
   code_DEE08:"Burgenlandkreis",
   code_DEE09:"Harz",
   code_DEE0A:"Mansfeld-Südharz",
   code_DEE0B:"Saalekreis",
   code_DEE0C:"Salzlandkreis",
   code_DEE0D:"Stendal",
   code_DEE0E:"Wittenberg",
   code_DEF:"SCHLESWIG-HOLSTEIN",
   code_DEF0:"Schleswig-Holstein",
   code_DEF01:"Flensburg, Kreisfreie Stadt",
   code_DEF02:"Kiel, Kreisfreie Stadt",
   code_DEF03:"Lübeck, Kreisfreie Stadt",
   code_DEF04:"Neumünster, Kreisfreie Stadt",
   code_DEF05:"Dithmarschen",
   code_DEF06:"Herzogtum Lauenburg",
   code_DEF07:"Nordfriesland",
   code_DEF08:"Ostholstein",
   code_DEF09:"Pinneberg",
   code_DEF0A:"Plön",
   code_DEF0B:"Rendsburg-Eckernförde",
   code_DEF0C:"Schleswig-Flensburg",
   code_DEF0D:"Segeberg",
   code_DEF0E:"Steinburg",
   code_DEF0F:"Stormarn",
   code_DEG:"THÜRINGEN",
   code_DEG0:"Thüringen",
   code_DEG01:"Erfurt, Kreisfreie Stadt",
   code_DEG02:"Gera, Kreisfreie Stadt",
   code_DEG03:"Jena, Kreisfreie Stadt",
   code_DEG04:"Suhl, Kreisfreie Stadt",
   code_DEG05:"Weimar, Kreisfreie Stadt",
   code_DEG06:"Eichsfeld",
   code_DEG07:"Nordhausen",
   code_DEG09:"Unstrut-Hainich-Kreis",
   code_DEG0A:"Kyffhäuserkreis",
   code_DEG0B:"Schmalkalden-Meiningen",
   code_DEG0C:"Gotha",
   code_DEG0D:"Sömmerda",
   code_DEG0E:"Hildburghausen",
   code_DEG0F:"Ilm-Kreis",
   code_DEG0G:"Weimarer Land",
   code_DEG0H:"Sonneberg",
   code_DEG0I:"Saalfeld-Rudolstadt",
   code_DEG0J:"Saale-Holzland-Kreis",
   code_DEG0K:"Saale-Orla-Kreis",
   code_DEG0L:"Greiz",
   code_DEG0M:"Altenburger Land",
   code_DEG0N:"Eisenach, Kreisfreie Stadt",
   code_DEG0P:"Wartburgkreis",
   code_DEZ:"EXTRA-REGIO NUTS 1",
   code_DEZZ:"Extra-Regio NUTS 2",
   code_DEZZZ:"Extra-Regio NUTS 3",
   code_DK:"DANMARK",
   code_DK0:"DANMARK",
   code_DK01:"Hovedstaden",
   code_DK011:"Byen København",
   code_DK012:"Københavns omegn",
   code_DK013:"Nordsjælland",
   code_DK014:"Bornholm",
   code_DK02:"Sjælland",
   code_DK021:"Østsjælland",
   code_DK022:"Vest- og Sydsjælland",
   code_DK03:"Syddanmark",
   code_DK031:"Fyn",
   code_DK032:"Sydjylland",
   code_DK04:"Midtjylland",
   code_DK041:"Vestjylland",
   code_DK042:"Østjylland",
   code_DK05:"Nordjylland",
   code_DK050:"Nordjylland",
   code_DKZ:"EXTRA-REGIO NUTS 1",
   code_DKZZ:"Extra-Regio NUTS 2",
   code_DKZZZ:"Extra-Regio NUTS 3",
   code_EE:"EESTI",
   code_EE0:"EESTI",
   code_EE00:"Eesti",
   code_EE001:"Põhja-Eesti",
   code_EE004:"Lääne-Eesti",
   code_EE006:"Kesk-Eesti",
   code_EE007:"Kirde-Eesti",
   code_EE008:"Lõuna-Eesti",
   code_EEZ:"EXTRA-REGIO NUTS 1",
   code_EEZZ:"Extra-Regio NUTS 2",
   code_EEZZZ:"Extra-Regio NUTS 3",
   code_EL:"ΕΛΛΑΔΑ",
   code_EL3:"ATTIKΗ",
   code_EL30:"Aττική",
   code_EL301:"Βόρειος Τομέας Αθηνών",
   code_EL302:"Δυτικός Τομέας Αθηνών",
   code_EL303:"Κεντρικός Τομέας Αθηνών",
   code_EL304:"Νότιος Τομέας Αθηνών",
   code_EL305:"Ανατολική Αττική",
   code_EL306:"Δυτική Αττική",
   code_EL307:"Πειραιάς, Νήσοι",
   code_EL4:"NΗΣΙΑ ΑΙΓΑΙΟΥ, KΡΗΤΗ",
   code_EL41:"Βόρειο Αιγαίο",
   code_EL411:"Λέσβος, Λήμνος",
   code_EL412:"Ικαρία, Σάμος",
   code_EL413:"Χίος",
   code_EL42:"Νότιο Αιγαίο",
   code_EL421:"Κάλυμνος, Κάρπαθος, Κως, Ρόδος",
   code_EL422:"Άνδρος, Θήρα, Κέα, Μήλος, Μύκονος, Νάξος, Πάρος, Σύρος, Τήνος",
   code_EL43:"Κρήτη",
   code_EL431:"Ηράκλειο",
   code_EL432:"Λασίθι",
   code_EL433:"Ρέθυμνο",
   code_EL434:"Χανιά",
   code_EL5:"ΒΟΡΕΙΑ ΕΛΛΑΔΑ",
   code_EL51:"Aνατολική Μακεδονία, Θράκη",
   code_EL511:"Έβρος",
   code_EL512:"Ξάνθη",
   code_EL513:"Ροδόπη",
   code_EL514:"Δράμα",
   code_EL515:"Θάσος, Καβάλα",
   code_EL52:"Κεντρική Μακεδονία",
   code_EL521:"Ημαθία",
   code_EL522:"Θεσσαλονίκη",
   code_EL523:"Κιλκίς",
   code_EL524:"Πέλλα",
   code_EL525:"Πιερία",
   code_EL526:"Σέρρες",
   code_EL527:"Χαλκιδική",
   code_EL53:"Δυτική Μακεδονία",
   code_EL531:"Γρεβενά, Κοζάνη",
   code_EL532:"Καστοριά",
   code_EL533:"Φλώρινα",
   code_EL54:"Ήπειρος",
   code_EL541:"Άρτα, Πρέβεζα",
   code_EL542:"Θεσπρωτία",
   code_EL543:"Ιωάννινα",
   code_EL6:"ΚΕΝΤΡΙΚΗ ΕΛΛΑΔΑ",
   code_EL61:"Θεσσαλία",
   code_EL611:"Καρδίτσα, Τρίκαλα",
   code_EL612:"Λάρισα",
   code_EL613:"Μαγνησία, Σποράδες",
   code_EL62:"Ιόνια Νησιά",
   code_EL621:"Ζάκυνθος",
   code_EL622:"Κέρκυρα",
   code_EL623:"Ιθάκη, Κεφαλληνία",
   code_EL624:"Λευκάδα",
   code_EL63:"Δυτική Ελλάδα",
   code_EL631:"Αιτωλοακαρνανία",
   code_EL632:"Αχαΐα",
   code_EL633:"Ηλεία",
   code_EL64:"Στερεά Ελλάδα",
   code_EL641:"Βοιωτία",
   code_EL642:"Εύβοια",
   code_EL643:"Ευρυτανία",
   code_EL644:"Φθιώτιδα",
   code_EL645:"Φωκίδα",
   code_EL65:"Πελοπόννησος",
   code_EL651:"Αργολίδα, Αρκαδία",
   code_EL652:"Κορινθία",
   code_EL653:"Λακωνία, Μεσσηνία",
   code_ELZ:"EXTRA-REGIO NUTS 1",
   code_ELZZ:"Extra-Regio NUTS 2",
   code_ELZZZ:"Extra-Regio NUTS 3",
   code_ES:"ESPAÑA",
   code_ES1:"NOROESTE",
   code_ES11:"Galicia",
   code_ES111:"A Coruña",
   code_ES112:"Lugo",
   code_ES113:"Ourense",
   code_ES114:"Pontevedra",
   code_ES12:"Principado de Asturias",
   code_ES120:"Asturias",
   code_ES13:"Cantabria",
   code_ES130:"Cantabria",
   code_ES2:"NORESTE",
   code_ES21:"País Vasco",
   code_ES211:"Araba / Álava",
   code_ES212:"Gipuzkoa",
   code_ES213:"Bizkaia",
   code_ES22:"Comunidad Foral de Navarra",
   code_ES220:"Navarra",
   code_ES23:"La Rioja",
   code_ES230:"La Rioja",
   code_ES24:"Aragón",
   code_ES241:"Huesca",
   code_ES242:"Teruel",
   code_ES243:"Zaragoza",
   code_ES3:"COMUNIDAD DE MADRID",
   code_ES30:"Comunidad de Madrid",
   code_ES300:"Madrid",
   code_ES4:"CENTRO (ES)",
   code_ES41:"Castilla y León",
   code_ES411:"Ávila",
   code_ES412:"Burgos",
   code_ES413:"León",
   code_ES414:"Palencia",
   code_ES415:"Salamanca",
   code_ES416:"Segovia",
   code_ES417:"Soria",
   code_ES418:"Valladolid",
   code_ES419:"Zamora",
   code_ES42:"Castilla-La Mancha",
   code_ES421:"Albacete",
   code_ES422:"Ciudad Real",
   code_ES423:"Cuenca",
   code_ES424:"Guadalajara",
   code_ES425:"Toledo",
   code_ES43:"Extremadura",
   code_ES431:"Badajoz",
   code_ES432:"Cáceres",
   code_ES5:"ESTE",
   code_ES51:"Cataluña",
   code_ES511:"Barcelona",
   code_ES512:"Girona",
   code_ES513:"Lleida",
   code_ES514:"Tarragona",
   code_ES52:"Comunidad Valenciana",
   code_ES521:"Alicante/Alacant",
   code_ES522:"Castellón/Castelló",
   code_ES523:"Valencia/València",
   code_ES53:"Illes Balears",
   code_ES531:"Eivissa y Formentera",
   code_ES532:"Mallorca",
   code_ES533:"Menorca",
   code_ES6:"SUR",
   code_ES61:"Andalucía",
   code_ES611:"Almería",
   code_ES612:"Cádiz",
   code_ES613:"Córdoba",
   code_ES614:"Granada",
   code_ES615:"Huelva",
   code_ES616:"Jaén",
   code_ES617:"Málaga",
   code_ES618:"Sevilla",
   code_ES62:"Región de Murcia",
   code_ES620:"Murcia",
   code_ES63:"Ciudad Autónoma de Ceuta",
   code_ES630:"Ceuta",
   code_ES64:"Ciudad Autónoma de Melilla",
   code_ES640:"Melilla",
   code_ES7:"CANARIAS",
   code_ES70:"Canarias",
   code_ES703:"El Hierro",
   code_ES704:"Fuerteventura",
   code_ES705:"Gran Canaria",
   code_ES706:"La Gomera",
   code_ES707:"La Palma",
   code_ES708:"Lanzarote",
   code_ES709:"Tenerife",
   code_ESZ:"EXTRA-REGIO NUTS 1",
   code_ESZZ:"Extra-Regio NUTS 2",
   code_ESZZZ:"Extra-Regio NUTS 3",
   code_FI:"SUOMI / FINLAND",
   code_FI1:"MANNER-SUOMI",
   code_FI19:"Länsi-Suomi",
   code_FI193:"Keski-Suomi",
   code_FI194:"Etelä-Pohjanmaa",
   code_FI195:"Pohjanmaa",
   code_FI196:"Satakunta",
   code_FI197:"Pirkanmaa",
   code_FI1B:"Helsinki-Uusimaa",
   code_FI1B1:"Helsinki-Uusimaa",
   code_FI1C:"Etelä-Suomi",
   code_FI1C1:"Varsinais-Suomi",
   code_FI1C2:"Kanta-Häme",
   code_FI1C3:"Päijät-Häme",
   code_FI1C4:"Kymenlaakso",
   code_FI1C5:"Etelä-Karjala",
   code_FI1D:"Pohjois- ja Itä-Suomi",
   code_FI1D1:"Etelä-Savo",
   code_FI1D2:"Pohjois-Savo",
   code_FI1D3:"Pohjois-Karjala",
   code_FI1D5:"Keski-Pohjanmaa",
   code_FI1D7:"Lappi",
   code_FI1D8:"Kainuu",
   code_FI1D9:"Pohjois-Pohjanmaa",
   code_FI2:"ÅLAND",
   code_FI20:"Åland",
   code_FI200:"Åland",
   code_FIZ:"EXTRA-REGIO NUTS 1",
   code_FIZZ:"Extra-Regio NUTS 2",
   code_FIZZZ:"Extra-Regio NUTS 3",
   code_FR:"FRANCE",
   code_FR1:"ILE-DE-FRANCE",
   code_FR10:"Ile-de-France",
   code_FR101:"Paris",
   code_FR102:"Seine-et-Marne",
   code_FR103:"Yvelines",
   code_FR104:"Essonne",
   code_FR105:"Hauts-de-Seine",
   code_FR106:"Seine-Saint-Denis",
   code_FR107:"Val-de-Marne",
   code_FR108:"Val-d’Oise",
   code_FRB:"CENTRE – VAL DE LOIRE",
   code_FRB0:"Centre – Val de Loire",
   code_FRB01:"Cher",
   code_FRB02:"Eure-et-Loir",
   code_FRB03:"Indre",
   code_FRB04:"Indre-et-Loire",
   code_FRB05:"Loir-et-Cher",
   code_FRB06:"Loiret",
   code_FRC:"BOURGOGNE-FRANCHE-COMTÉ",
   code_FRC1:"Bourgogne",
   code_FRC11:"Côte-d’Or",
   code_FRC12:"Nièvre",
   code_FRC13:"Saône-et-Loire",
   code_FRC14:"Yonne",
   code_FRC2:"Franche-Comté",
   code_FRC21:"Doubs",
   code_FRC22:"Jura",
   code_FRC23:"Haute-Saône",
   code_FRC24:"Territoire de Belfort",
   code_FRD:"NORMANDIE",
   code_FRD1:"Basse-Normandie",
   code_FRD11:"Calvados",
   code_FRD12:"Manche",
   code_FRD13:"Orne",
   code_FRD2:"Haute-Normandie",
   code_FRD21:"Eure",
   code_FRD22:"Seine-Maritime",
   code_FRE:"NORD-PAS DE CALAIS-PICARDIE",
   code_FRE1:"Nord-Pas de Calais",
   code_FRE11:"Nord",
   code_FRE12:"Pas-de-Calais",
   code_FRE2:"Picardie",
   code_FRE21:"Aisne",
   code_FRE22:"Oise",
   code_FRE23:"Somme",
   code_FRF:"ALSACE-CHAMPAGNE-ARDENNE-LORRAINE",
   code_FRF1:"Alsace",
   code_FRF11:"Bas-Rhin",
   code_FRF12:"Haut-Rhin",
   code_FRF2:"Champagne-Ardenne",
   code_FRF21:"Ardennes",
   code_FRF22:"Aube",
   code_FRF23:"Marne",
   code_FRF24:"Haute-Marne",
   code_FRF3:"Lorraine",
   code_FRF31:"Meurthe-et-Moselle",
   code_FRF32:"Meuse",
   code_FRF33:"Moselle",
   code_FRF34:"Vosges",
   code_FRG:"PAYS DE LA LOIRE",
   code_FRG0:"Pays de la Loire",
   code_FRG01:"Loire-Atlantique",
   code_FRG02:"Maine-et-Loire",
   code_FRG03:"Mayenne",
   code_FRG04:"Sarthe",
   code_FRG05:"Vendée",
   code_FRH:"BRETAGNE",
   code_FRH0:"Bretagne",
   code_FRH01:"Côtes-d’Armor",
   code_FRH02:"Finistère",
   code_FRH03:"Ille-et-Vilaine",
   code_FRH04:"Morbihan",
   code_FRI:"AQUITAINE-LIMOUSIN-POITOU-CHARENTES",
   code_FRI1:"Aquitaine",
   code_FRI11:"Dordogne",
   code_FRI12:"Gironde",
   code_FRI13:"Landes",
   code_FRI14:"Lot-et-Garonne",
   code_FRI15:"Pyrénées-Atlantiques",
   code_FRI2:"Limousin",
   code_FRI21:"Corrèze",
   code_FRI22:"Creuse",
   code_FRI23:"Haute-Vienne",
   code_FRI3:"Poitou - Charentes",
   code_FRI31:"Charente",
   code_FRI32:"Charente-Maritime",
   code_FRI33:"Deux-Sèvres",
   code_FRI34:"Vienne",
   code_FRJ:"LANGUEDOC-ROUSSILLON-MIDI-PYRÉNÉES",
   code_FRJ1:"Languedoc-Roussillon",
   code_FRJ11:"Aude",
   code_FRJ12:"Gard",
   code_FRJ13:"Hérault",
   code_FRJ14:"Lozère",
   code_FRJ15:"Pyrénées-Orientales",
   code_FRJ2:"Midi-Pyrénées",
   code_FRJ21:"Ariège",
   code_FRJ22:"Aveyron",
   code_FRJ23:"Haute-Garonne",
   code_FRJ24:"Gers",
   code_FRJ25:"Lot",
   code_FRJ26:"Hautes-Pyrénées",
   code_FRJ27:"Tarn",
   code_FRJ28:"Tarn-et-Garonne",
   code_FRK:"AUVERGNE-RHÔNE-ALPES",
   code_FRK1:"Auvergne",
   code_FRK11:"Allier",
   code_FRK12:"Cantal",
   code_FRK13:"Haute-Loire",
   code_FRK14:"Puy-de-Dôme",
   code_FRK2:"Rhône-Alpes",
   code_FRK21:"Ain",
   code_FRK22:"Ardèche",
   code_FRK23:"Drôme",
   code_FRK24:"Isère",
   code_FRK25:"Loire",
   code_FRK26:"Rhône",
   code_FRK27:"Savoie",
   code_FRK28:"Haute-Savoie",
   code_FRL:"PROVENCE-ALPES-CÔTE D’AZUR",
   code_FRL0:"Provence-Alpes-Côte d’Azur",
   code_FRL01:"Alpes-de-Haute-Provence",
   code_FRL02:"Hautes-Alpes",
   code_FRL03:"Alpes-Maritimes",
   code_FRL04:"Bouches-du-Rhône",
   code_FRL05:"Var",
   code_FRL06:"Vaucluse",
   code_FRM:"CORSE",
   code_FRM0:"Corse",
   code_FRM01:"Corse-du-Sud",
   code_FRM02:"Haute-Corse",
   code_FRY:"RUP FR – RÉGIONS ULTRAPÉRIPHÉRIQUES FRANÇAISES",
   code_FRY1:"Guadeloupe",
   code_FRY10:"Guadeloupe",
   code_FRY2:"Martinique",
   code_FRY20:"Martinique",
   code_FRY3:"Guyane",
   code_FRY30:"Guyane",
   code_FRY4:"La Réunion",
   code_FRY40:"La Réunion",
   code_FRY5:"Mayotte",
   code_FRY50:"Mayotte",
   code_FRZ:"EXTRA-REGIO NUTS 1",
   code_FRZZ:"Extra-Regio NUTS 2",
   code_FRZZZ:"Extra-Regio NUTS 3",
   code_HR:"HRVATSKA",
   code_HR0:"HRVATSKA",
   code_HR03:"Jadranska Hrvatska",
   code_HR031:"Primorsko-goranska županija",
   code_HR032:"Ličko-senjska županija",
   code_HR033:"Zadarska županija",
   code_HR034:"Šibensko-kninska županija",
   code_HR035:"Splitsko-dalmatinska županija",
   code_HR036:"Istarska županija",
   code_HR037:"Dubrovačko-neretvanska županija",
   code_HR04:"Kontinentalna Hrvatska",
   code_HR041:"Grad Zagreb",
   code_HR042:"Zagrebačka županija",
   code_HR043:"Krapinsko-zagorska županija",
   code_HR044:"Varaždinska županija",
   code_HR045:"Koprivničko-križevačka županija",
   code_HR046:"Međimurska županija",
   code_HR047:"Bjelovarsko-bilogorska županija",
   code_HR048:"Virovitičko-podravska županija",
   code_HR049:"Požeško-slavonska županija",
   code_HR04A:"Brodsko-posavska županija",
   code_HR04B:"Osječko-baranjska županija",
   code_HR04C:"Vukovarsko-srijemska županija",
   code_HR04D:"Karlovačka županija",
   code_HR04E:"Sisačko-moslavačka županija",
   code_HRZ:"EXTRA-REGIO NUTS 1",
   code_HRZZ:"Extra-Regio NUTS 2",
   code_HRZZZ:"Extra-Regio NUTS 3",
   code_HU:"MAGYARORSZÁG",
   code_HU1:"KÖZÉP-MAGYARORSZÁG",
   code_HU11:"Budapest",
   code_HU110:"Budapest",
   code_HU12:"Pest",
   code_HU120:"Pest",
   code_HU2:"DUNÁNTÚL",
   code_HU21:"Közép-Dunántúl",
   code_HU211:"Fejér",
   code_HU212:"Komárom-Esztergom",
   code_HU213:"Veszprém",
   code_HU22:"Nyugat-Dunántúl",
   code_HU221:"Győr-Moson-Sopron",
   code_HU222:"Vas",
   code_HU223:"Zala",
   code_HU23:"Dél-Dunántúl",
   code_HU231:"Baranya",
   code_HU232:"Somogy",
   code_HU233:"Tolna",
   code_HU3:"ALFÖLD ÉS ÉSZAK",
   code_HU31:"Észak-Magyarország",
   code_HU311:"Borsod-Abaúj-Zemplén",
   code_HU312:"Heves",
   code_HU313:"Nógrád",
   code_HU32:"Észak-Alföld",
   code_HU321:"Hajdú-Bihar",
   code_HU322:"Jász-Nagykun-Szolnok",
   code_HU323:"Szabolcs-Szatmár-Bereg",
   code_HU33:"Dél-Alföld",
   code_HU331:"Bács-Kiskun",
   code_HU332:"Békés",
   code_HU333:"Csongrád",
   code_HUZ:"EXTRA-REGIO NUTS 1",
   code_HUZZ:"Extra-Regio NUTS 2",
   code_HUZZZ:"Extra-Regio NUTS 3",
   code_IE:"IRELAND",
   code_IE0:"IRELAND",
   code_IE04:"Northern and Western",
   code_IE041:"Border",
   code_IE042:"West",
   code_IE05:"Southern",
   code_IE051:"Mid-West",
   code_IE052:"South-East",
   code_IE053:"South-West",
   code_IE06:"Eastern and Midland",
   code_IE061:"Dublin",
   code_IE062:"Mid-East",
   code_IE063:"Midland",
   code_IEZ:"EXTRA-REGIO NUTS 1",
   code_IEZZ:"Extra-Regio NUTS 2",
   code_IEZZZ:"Extra-Regio NUTS 3",
   code_IS:"Iceland",
   code_IS0:"Ísland",
   code_IS00:"Ísland",
   code_IS001:"Höfuðborgarsvæði",
   code_IS002:"Landsbyggð",
   code_IT:"ITALIA",
   code_ITC:"NORD-OVEST",
   code_ITC1:"Piemonte",
   code_ITC11:"Torino",
   code_ITC12:"Vercelli",
   code_ITC13:"Biella",
   code_ITC14:"Verbano-Cusio-Ossola",
   code_ITC15:"Novara",
   code_ITC16:"Cuneo",
   code_ITC17:"Asti",
   code_ITC18:"Alessandria",
   code_ITC2:"Valle d’Aosta / Vallée d’Aoste",
   code_ITC20:"Valle d’Aosta / Vallée d’Aoste",
   code_ITC3:"Liguria",
   code_ITC31:"Imperia",
   code_ITC32:"Savona",
   code_ITC33:"Genova",
   code_ITC34:"La Spezia",
   code_ITC4:"Lombardia",
   code_ITC41:"Varese",
   code_ITC42:"Como",
   code_ITC43:"Lecco",
   code_ITC44:"Sondrio",
   code_ITC46:"Bergamo",
   code_ITC47:"Brescia",
   code_ITC48:"Pavia",
   code_ITC49:"Lodi",
   code_ITC4A:"Cremona",
   code_ITC4B:"Mantova",
   code_ITC4C:"Milano",
   code_ITC4D:"Monza e della Brianza",
   code_ITF:"SUD",
   code_ITF1:"Abruzzo",
   code_ITF11:"L’Aquila",
   code_ITF12:"Teramo",
   code_ITF13:"Pescara",
   code_ITF14:"Chieti",
   code_ITF2:"Molise",
   code_ITF21:"Isernia",
   code_ITF22:"Campobasso",
   code_ITF3:"Campania",
   code_ITF31:"Caserta",
   code_ITF32:"Benevento",
   code_ITF33:"Napoli",
   code_ITF34:"Avellino",
   code_ITF35:"Salerno",
   code_ITF4:"Puglia",
   code_ITF43:"Taranto",
   code_ITF44:"Brindisi",
   code_ITF45:"Lecce",
   code_ITF46:"Foggia",
   code_ITF47:"Bari",
   code_ITF48:"Barletta-Andria-Trani",
   code_ITF5:"Basilicata",
   code_ITF51:"Potenza",
   code_ITF52:"Matera",
   code_ITF6:"Calabria",
   code_ITF61:"Cosenza",
   code_ITF62:"Crotone",
   code_ITF63:"Catanzaro",
   code_ITF64:"Vibo Valentia",
   code_ITF65:"Reggio di Calabria",
   code_ITG:"ISOLE",
   code_ITG1:"Sicilia",
   code_ITG11:"Trapani",
   code_ITG12:"Palermo",
   code_ITG13:"Messina",
   code_ITG14:"Agrigento",
   code_ITG15:"Caltanissetta",
   code_ITG16:"Enna",
   code_ITG17:"Catania",
   code_ITG18:"Ragusa",
   code_ITG19:"Siracusa",
   code_ITG2:"Sardegna",
   code_ITG25:"Sassari",
   code_ITG26:"Nuoro",
   code_ITG27:"Cagliari",
   code_ITG28:"Oristano",
   code_ITG29:"Olbia-Tempio",
   code_ITG2A:"Ogliastra",
   code_ITG2B:"Medio Campidano",
   code_ITG2C:"Carbonia-Iglesias",
   code_ITH:"NORD-EST",
   code_ITH1:"Provincia Autonoma di Bolzano / Bozen",
   code_ITH10:"Bolzano-Bozen",
   code_ITH2:"Provincia Autonoma di Trento",
   code_ITH20:"Trento",
   code_ITH3:"Veneto",
   code_ITH31:"Verona",
   code_ITH32:"Vicenza",
   code_ITH33:"Belluno",
   code_ITH34:"Treviso",
   code_ITH35:"Venezia",
   code_ITH36:"Padova",
   code_ITH37:"Rovigo",
   code_ITH4:"Friuli-Venezia Giulia",
   code_ITH41:"Pordenone",
   code_ITH42:"Udine",
   code_ITH43:"Gorizia",
   code_ITH44:"Trieste",
   code_ITH5:"Emilia-Romagna",
   code_ITH51:"Piacenza",
   code_ITH52:"Parma",
   code_ITH53:"Reggio nell’Emilia",
   code_ITH54:"Modena",
   code_ITH55:"Bologna",
   code_ITH56:"Ferrara",
   code_ITH57:"Ravenna",
   code_ITH58:"Forlì-Cesena",
   code_ITH59:"Rimini",
   code_ITI:"CENTRO (IT)",
   code_ITI1:"Toscana",
   code_ITI11:"Massa-Carrara",
   code_ITI12:"Lucca",
   code_ITI13:"Pistoia",
   code_ITI14:"Firenze",
   code_ITI15:"Prato",
   code_ITI16:"Livorno",
   code_ITI17:"Pisa",
   code_ITI18:"Arezzo",
   code_ITI19:"Siena",
   code_ITI1A:"Grosseto",
   code_ITI2:"Umbria",
   code_ITI21:"Perugia",
   code_ITI22:"Terni",
   code_ITI3:"Marche",
   code_ITI31:"Pesaro e Urbino",
   code_ITI32:"Ancona",
   code_ITI33:"Macerata",
   code_ITI34:"Ascoli Piceno",
   code_ITI35:"Fermo",
   code_ITI4:"Lazio",
   code_ITI41:"Viterbo",
   code_ITI42:"Rieti",
   code_ITI43:"Roma",
   code_ITI44:"Latina",
   code_ITI45:"Frosinone",
   code_ITZ:"EXTRA-REGIO NUTS 1",
   code_ITZZ:"Extra-Regio NUTS 2",
   code_ITZZZ:"Extra-Regio NUTS 3",
   code_LI:"Liechtenstein",
   code_LI0:"Liechtenstein",
   code_LI00:"Liechtenstein",
   code_LI000:"Liechtenstein",
   code_LT:"LIETUVA",
   code_LT0:"LIETUVA",
   code_LT01:"Sostinės regionas",
   code_LT011:"Vilniaus apskritis",
   code_LT02:"Vidurio ir vakarų Lietuvos regionas",
   code_LT021:"Alytaus apskritis",
   code_LT022:"Kauno apskritis",
   code_LT023:"Klaipėdos apskritis",
   code_LT024:"Marijampolės apskritis",
   code_LT025:"Panevėžio apskritis",
   code_LT026:"Šiaulių apskritis",
   code_LT027:"Tauragės apskritis",
   code_LT028:"Telšių apskritis",
   code_LT029:"Utenos apskritis",
   code_LTZ:"EXTRA-REGIO NUTS 1",
   code_LTZZ:"Extra-Regio NUTS 2",
   code_LTZZZ:"Extra-Regio NUTS 3",
   code_LU:"LUXEMBOURG",
   code_LU0:"LUXEMBOURG",
   code_LU00:"Luxembourg",
   code_LU000:"Luxembourg",
   code_LUZ:"EXTRA-REGIO NUTS 1",
   code_LUZZ:"Extra-Regio NUTS 2",
   code_LUZZZ:"Extra-Regio NUTS 3",
   code_LV:"LATVIJA",
   code_LV0:"LATVIJA",
   code_LV00:"Latvija",
   code_LV003:"Kurzeme",
   code_LV005:"Latgale",
   code_LV006:"Rīga",
   code_LV007:"Pierīga",
   code_LV008:"Vidzeme",
   code_LV009:"Zemgale",
   code_LVZ:"EXTRA-REGIO NUTS 1",
   code_LVZZ:"Extra-Regio NUTS 2",
   code_LVZZZ:"Extra-Regio NUTS 3",
   code_ME:"Montenegro",
   code_ME0:"Црна Гора",
   code_ME00:"Црна Гора",
   code_ME000:"Црна Гора",
   code_MK:"Macedonia",
   code_MK0:"Северна Македонија",
   code_MK00:"Северна Македонија",
   code_MK001:"Вардарски",
   code_MK002:"Источен",
   code_MK003:"Југозападен",
   code_MK004:"Југоисточен",
   code_MK005:"Пелагониски",
   code_MK006:"Полошки",
   code_MK007:"Североисточен",
   code_MK008:"Скопски",
   code_MT:"MALTA",
   code_MT0:"MALTA",
   code_MT00:"Malta",
   code_MT001:"Malta",
   code_MT002:"Gozo and Comino / Għawdex u Kemmuna",
   code_MTZ:"EXTRA-REGIO NUTS 1",
   code_MTZZ:"Extra-Regio NUTS 2",
   code_MTZZZ:"Extra-Regio NUTS 3",
   code_NL:"NEDERLAND",
   code_NL1:"NOORD-NEDERLAND",
   code_NL11:"Groningen",
   code_NL111:"Oost-Groningen",
   code_NL112:"Delfzijl en omgeving",
   code_NL113:"Overig Groningen",
   code_NL12:"Friesland (NL)",
   code_NL124:"Noord-Friesland",
   code_NL125:"Zuidwest-Friesland",
   code_NL126:"Zuidoost-Friesland",
   code_NL13:"Drenthe",
   code_NL131:"Noord-Drenthe",
   code_NL132:"Zuidoost-Drenthe",
   code_NL133:"Zuidwest-Drenthe",
   code_NL2:"OOST-NEDERLAND",
   code_NL21:"Overijssel",
   code_NL211:"Noord-Overijssel",
   code_NL212:"Zuidwest-Overijssel",
   code_NL213:"Twente",
   code_NL22:"Gelderland",
   code_NL221:"Veluwe",
   code_NL224:"Zuidwest-Gelderland",
   code_NL225:"Achterhoek",
   code_NL226:"Arnhem/Nijmegen",
   code_NL23:"Flevoland",
   code_NL230:"Flevoland",
   code_NL3:"WEST-NEDERLAND",
   code_NL31:"Utrecht",
   code_NL310:"Utrecht",
   code_NL32:"Noord-Holland",
   code_NL321:"Kop van Noord-Holland",
   code_NL323:"IJmond",
   code_NL324:"Agglomeratie Haarlem",
   code_NL325:"Zaanstreek",
   code_NL327:"Het Gooi en Vechtstreek",
   code_NL328:"Alkmaar en omgeving",
   code_NL329:"Groot-Amsterdam",
   code_NL33:"Zuid-Holland",
   code_NL332:"Agglomeratie ’s-Gravenhage",
   code_NL333:"Delft en Westland",
   code_NL337:"Agglomeratie Leiden en Bollenstreek",
   code_NL33A:"Zuidoost-Zuid-Holland",
   code_NL33B:"Oost-Zuid-Holland",
   code_NL33C:"Groot-Rijnmond",
   code_NL34:"Zeeland",
   code_NL341:"Zeeuwsch-Vlaanderen",
   code_NL342:"Overig Zeeland",
   code_NL4:"ZUID-NEDERLAND",
   code_NL41:"Noord-Brabant",
   code_NL411:"West-Noord-Brabant",
   code_NL412:"Midden-Noord-Brabant",
   code_NL413:"Noordoost-Noord-Brabant",
   code_NL414:"Zuidoost-Noord-Brabant",
   code_NL42:"Limburg (NL)",
   code_NL421:"Noord-Limburg",
   code_NL422:"Midden-Limburg",
   code_NL423:"Zuid-Limburg",
   code_NLZ:"EXTRA-REGIO NUTS 1",
   code_NLZZ:"Extra-Regio NUTS 2",
   code_NLZZZ:"Extra-Regio NUTS 3",
   code_NO:"Norway",
   code_NO0:"Norge",
   code_NO01:"Oslo og Akershus",
   code_NO011:"Oslo",
   code_NO012:"Akershus",
   code_NO02:"Innlandet",
   code_NO021:"Hedmark",
   code_NO022:"Oppland",
   code_NO03:"Sør-Østlandet",
   code_NO031:"Østfold",
   code_NO032:"Buskerud",
   code_NO033:"Vestfold",
   code_NO034:"Telemark",
   code_NO04:"Agder og Rogaland",
   code_NO041:"Aust-Agder",
   code_NO042:"Vest-Agder",
   code_NO043:"Rogaland",
   code_NO05:"Vestlandet",
   code_NO051:"Hordaland",
   code_NO052:"Sogn og Fjordane",
   code_NO053:"Møre og Romsdal",
   code_NO06:"Trøndelag",
   code_NO061:"Sør-Trøndelag",
   code_NO062:"Nord-Trøndelag",
   code_NO07:"Nord-Norge",
   code_NO071:"Nordland",
   code_NO072:"Troms",
   code_NO073:"Finnmark",
   code_PL:"POLSKA",
   code_PL2:"MAKROREGION POŁUDNIOWY",
   code_PL21:"Małopolskie",
   code_PL213:"Miasto Kraków",
   code_PL214:"Krakowski",
   code_PL217:"Tarnowski",
   code_PL218:"Nowosądecki",
   code_PL219:"Nowotarski",
   code_PL21A:"Oświęcimski",
   code_PL22:"Śląskie",
   code_PL224:"Częstochowski",
   code_PL225:"Bielski",
   code_PL227:"Rybnicki",
   code_PL228:"Bytomski",
   code_PL229:"Gliwicki",
   code_PL22A:"Katowicki",
   code_PL22B:"Sosnowiecki",
   code_PL22C:"Tyski",
   code_PL4:"MAKROREGION PÓŁNOCNO-ZACHODNI",
   code_PL41:"Wielkopolskie",
   code_PL411:"Pilski",
   code_PL414:"Koniński",
   code_PL415:"Miasto Poznań",
   code_PL416:"Kaliski",
   code_PL417:"Leszczyński",
   code_PL418:"Poznański",
   code_PL42:"Zachodniopomorskie",
   code_PL424:"Miasto Szczecin",
   code_PL426:"Koszaliński",
   code_PL427:"Szczecinecko-pyrzycki",
   code_PL428:"Szczeciński",
   code_PL43:"Lubuskie",
   code_PL431:"Gorzowski",
   code_PL432:"Zielonogórski",
   code_PL5:"MAKROREGION POŁUDNIOWO-ZACHODNI",
   code_PL51:"Dolnośląskie",
   code_PL514:"Miasto Wrocław",
   code_PL515:"Jeleniogórski",
   code_PL516:"Legnicko-głogowski",
   code_PL517:"Wałbrzyski",
   code_PL518:"Wrocławski",
   code_PL52:"Opolskie",
   code_PL523:"Nyski",
   code_PL524:"Opolski",
   code_PL6:"MAKROREGION PÓŁNOCNY",
   code_PL61:"Kujawsko-pomorskie",
   code_PL613:"Bydgosko-toruński",
   code_PL616:"Grudziądzki",
   code_PL617:"Inowrocławski",
   code_PL618:"Świecki",
   code_PL619:"Włocławski",
   code_PL62:"Warmińsko-mazurskie",
   code_PL621:"Elbląski",
   code_PL622:"Olsztyński",
   code_PL623:"Ełcki",
   code_PL63:"Pomorskie",
   code_PL633:"Trójmiejski",
   code_PL634:"Gdański",
   code_PL636:"Słupski",
   code_PL637:"Chojnicki",
   code_PL638:"Starogardzki",
   code_PL7:"MAKROREGION CENTRALNY",
   code_PL71:"Łódzkie",
   code_PL711:"Miasto Łódź",
   code_PL712:"Łódzki",
   code_PL713:"Piotrkowski",
   code_PL714:"Sieradzki",
   code_PL715:"Skierniewicki",
   code_PL72:"Świętokrzyskie",
   code_PL721:"Kielecki",
   code_PL722:"Sandomiersko-jędrzejowski",
   code_PL8:"MAKROREGION WSCHODNI",
   code_PL81:"Lubelskie",
   code_PL811:"Bialski",
   code_PL812:"Chełmsko-zamojski",
   code_PL814:"Lubelski",
   code_PL815:"Puławski",
   code_PL82:"Podkarpackie",
   code_PL821:"Krośnieński",
   code_PL822:"Przemyski",
   code_PL823:"Rzeszowski",
   code_PL824:"Tarnobrzeski",
   code_PL84:"Podlaskie",
   code_PL841:"Białostocki",
   code_PL842:"Łomżyński",
   code_PL843:"Suwalski",
   code_PL9:"MAKROREGION WOJEWÓDZTWO MAZOWIECKIE",
   code_PL91:"Warszawski stołeczny",
   code_PL911:"Miasto Warszawa",
   code_PL912:"Warszawski wschodni",
   code_PL913:"Warszawski zachodni",
   code_PL92:"Mazowiecki regionalny",
   code_PL921:"Radomski",
   code_PL922:"Ciechanowski",
   code_PL923:"Płocki",
   code_PL924:"Ostrołęcki",
   code_PL925:"Siedlecki",
   code_PL926:"Żyrardowski",
   code_PLZ:"EXTRA-REGIO NUTS 1",
   code_PLZZ:"Extra-Regio NUTS 2",
   code_PLZZZ:"Extra-Regio NUTS 3",
   code_PT:"PORTUGAL",
   code_PT1:"CONTINENTE",
   code_PT11:"Norte",
   code_PT111:"Alto Minho",
   code_PT112:"Cávado",
   code_PT119:"Ave",
   code_PT11A:"Área Metropolitana do Porto",
   code_PT11B:"Alto Tâmega",
   code_PT11C:"Tâmega e Sousa",
   code_PT11D:"Douro",
   code_PT11E:"Terras de Trás-os-Montes",
   code_PT15:"Algarve",
   code_PT150:"Algarve",
   code_PT16:"Centro (PT)",
   code_PT16B:"Oeste",
   code_PT16D:"Região de Aveiro",
   code_PT16E:"Região de Coimbra",
   code_PT16F:"Região de Leiria",
   code_PT16G:"Viseu Dão Lafões",
   code_PT16H:"Beira Baixa",
   code_PT16I:"Médio Tejo",
   code_PT16J:"Beiras e Serra da Estrela",
   code_PT17:"Área Metropolitana de Lisboa",
   code_PT170:"Área Metropolitana de Lisboa",
   code_PT18:"Alentejo",
   code_PT181:"Alentejo Litoral",
   code_PT184:"Baixo Alentejo",
   code_PT185:"Lezíria do Tejo",
   code_PT186:"Alto Alentejo",
   code_PT187:"Alentejo Central",
   code_PT2:"REGIÃO AUTÓNOMA DOS AÇORES",
   code_PT20:"Região Autónoma dos Açores",
   code_PT200:"Região Autónoma dos Açores",
   code_PT3:"REGIÃO AUTÓNOMA DA MADEIRA",
   code_PT30:"Região Autónoma da Madeira",
   code_PT300:"Região Autónoma da Madeira",
   code_PTZ:"EXTRA-REGIO NUTS 1",
   code_PTZZ:"Extra-Regio NUTS 2",
   code_PTZZZ:"Extra-Regio NUTS 3",
   code_RO:"ROMANIA",
   code_RO1:"MACROREGIUNEA UNU",
   code_RO11:"Nord-Vest",
   code_RO111:"Bihor",
   code_RO112:"Bistriţa-Năsăud",
   code_RO113:"Cluj",
   code_RO114:"Maramureş",
   code_RO115:"Satu Mare",
   code_RO116:"Sălaj",
   code_RO12:"Centru",
   code_RO121:"Alba",
   code_RO122:"Braşov",
   code_RO123:"Covasna",
   code_RO124:"Harghita",
   code_RO125:"Mureş",
   code_RO126:"Sibiu",
   code_RO2:"MACROREGIUNEA DOI",
   code_RO21:"Nord-Est",
   code_RO211:"Bacău",
   code_RO212:"Botoşani",
   code_RO213:"Iaşi",
   code_RO214:"Neamţ",
   code_RO215:"Suceava",
   code_RO216:"Vaslui",
   code_RO22:"Sud-Est",
   code_RO221:"Brăila",
   code_RO222:"Buzău",
   code_RO223:"Constanţa",
   code_RO224:"Galaţi",
   code_RO225:"Tulcea",
   code_RO226:"Vrancea",
   code_RO3:"MACROREGIUNEA TREI",
   code_RO31:"Sud-Muntenia",
   code_RO311:"Argeş",
   code_RO312:"Călăraşi",
   code_RO313:"Dâmboviţa",
   code_RO314:"Giurgiu",
   code_RO315:"Ialomiţa",
   code_RO316:"Prahova",
   code_RO317:"Teleorman",
   code_RO32:"Bucureşti-Ilfov",
   code_RO321:"Bucureşti",
   code_RO322:"Ilfov",
   code_RO4:"MACROREGIUNEA PATRU",
   code_RO41:"Sud-Vest Oltenia",
   code_RO411:"Dolj",
   code_RO412:"Gorj",
   code_RO413:"Mehedinţi",
   code_RO414:"Olt",
   code_RO415:"Vâlcea",
   code_RO42:"Vest",
   code_RO421:"Arad",
   code_RO422:"Caraş-Severin",
   code_RO423:"Hunedoara",
   code_RO424:"Timiş",
   code_ROZ:"EXTRA-REGIO NUTS 1",
   code_ROZZ:"Extra-Regio NUTS 2",
   code_ROZZZ:"Extra-Regio NUTS 3",
   code_RS:"Serbia",
   code_SE:"SVERIGE",
   code_SE1:"ÖSTRA SVERIGE",
   code_SE11:"Stockholm",
   code_SE110:"Stockholms län",
   code_SE12:"Östra Mellansverige",
   code_SE121:"Uppsala län",
   code_SE122:"Södermanlands län",
   code_SE123:"Östergötlands län",
   code_SE124:"Örebro län",
   code_SE125:"Västmanlands län",
   code_SE2:"SÖDRA SVERIGE",
   code_SE21:"Småland med öarna",
   code_SE211:"Jönköpings län",
   code_SE212:"Kronobergs län",
   code_SE213:"Kalmar län",
   code_SE214:"Gotlands län",
   code_SE22:"Sydsverige",
   code_SE221:"Blekinge län",
   code_SE224:"Skåne län",
   code_SE23:"Västsverige",
   code_SE231:"Hallands län",
   code_SE232:"Västra Götalands län",
   code_SE3:"NORRA SVERIGE",
   code_SE31:"Norra Mellansverige",
   code_SE311:"Värmlands län",
   code_SE312:"Dalarnas län",
   code_SE313:"Gävleborgs län",
   code_SE32:"Mellersta Norrland",
   code_SE321:"Västernorrlands län",
   code_SE322:"Jämtlands län",
   code_SE33:"Övre Norrland",
   code_SE331:"Västerbottens län",
   code_SE332:"Norrbottens län",
   code_SEZ:"EXTRA-REGIO NUTS 1",
   code_SEZZ:"Extra-Regio NUTS 2",
   code_SEZZZ:"Extra-Regio NUTS 3",
   code_SI:"Slovenia",
   code_SI0:"SLOVENIJA",
   code_SI03:"Vzhodna Slovenija",
   code_SI031:"Pomurska",
   code_SI032:"Podravska",
   code_SI033:"Koroška",
   code_SI034:"Savinjska",
   code_SI035:"Zasavska",
   code_SI036:"Posavska",
   code_SI037:"Jugovzhodna Slovenija",
   code_SI038:"Primorsko-notranjska",
   code_SI04:"Zahodna Slovenija",
   code_SI041:"Osrednjeslovenska",
   code_SI042:"Gorenjska",
   code_SI043:"Goriška",
   code_SI044:"Obalno-kraška",
   code_SIZ:"EXTRA-REGIO NUTS 1",
   code_SIZZ:"Extra-Regio NUTS 2",
   code_SIZZZ:"Extra-Regio NUTS 3",
   code_SK:"SLOVENSKO",
   code_SK0:"SLOVENSKO",
   code_SK01:"Bratislavský kraj",
   code_SK010:"Bratislavský kraj",
   code_SK02:"Západné Slovensko",
   code_SK021:"Trnavský kraj",
   code_SK022:"Trenčiansky kraj",
   code_SK023:"Nitriansky kraj",
   code_SK03:"Stredné Slovensko",
   code_SK031:"Žilinský kraj",
   code_SK032:"Banskobystrický kraj",
   code_SK04:"Východné Slovensko",
   code_SK041:"Prešovský kraj",
   code_SK042:"Košický kraj",
   code_SKZ:"EXTRA-REGIO NUTS 1",
   code_SKZZ:"Extra-Regio NUTS 2",
   code_SKZZZ:"Extra-Regio NUTS 3",
   code_TR:"Turkey",
   code_TR1:"İstanbul",
   code_TR10:"İstanbul",
   code_TR100:"İstanbul",
   code_TR2:"Batı Marmara",
   code_TR21:"Tekirdağ",
   code_TR211:"Tekirdağ",
   code_TR212:"Edirne",
   code_TR213:"Kırklareli",
   code_TR22:"Balıkesir",
   code_TR221:"Balıkesir",
   code_TR222:"Çanakkale",
   code_TR3:"Ege",
   code_TR31:"İzmir",
   code_TR310:"İzmir",
   code_TR32:"Aydın",
   code_TR321:"Aydın",
   code_TR322:"Denizli",
   code_TR323:"Muğla",
   code_TR33:"Manisa",
   code_TR331:"Manisa",
   code_TR332:"Afyonkarahisar",
   code_TR333:"Kütahya",
   code_TR334:"Uşak",
   code_TR4:"Doğu Marmara",
   code_TR41:"Bursa",
   code_TR411:"Bursa",
   code_TR412:"Eskişehir",
   code_TR413:"Bilecik",
   code_TR42:"Kocaeli",
   code_TR421:"Kocaeli",
   code_TR422:"Sakarya",
   code_TR423:"Düzce",
   code_TR424:"Bolu",
   code_TR425:"Yalova",
   code_TR5:"Batı Anadolu",
   code_TR51:"Ankara",
   code_TR510:"Ankara",
   code_TR52:"Konya",
   code_TR521:"Konya",
   code_TR522:"Karaman",
   code_TR6:"Akdeniz",
   code_TR61:"Antalya",
   code_TR611:"Antalya",
   code_TR612:"Isparta",
   code_TR613:"Burdur",
   code_TR62:"Adana",
   code_TR621:"Adana",
   code_TR622:"Mersin",
   code_TR63:"Hatay",
   code_TR631:"Hatay",
   code_TR632:"Kahramanmaraş",
   code_TR633:"Osmaniye",
   code_TR7:"Orta Anadolu",
   code_TR71:"Kırıkkale",
   code_TR711:"Kırıkkale",
   code_TR712:"Aksaray",
   code_TR713:"Niğde",
   code_TR714:"Nevşehir",
   code_TR715:"Kırşehir",
   code_TR72:"Kayseri",
   code_TR721:"Kayseri",
   code_TR722:"Sivas",
   code_TR723:"Yozgat",
   code_TR8:"Batı Karadeniz",
   code_TR81:"Zonguldak",
   code_TR811:"Zonguldak",
   code_TR812:"Karabük",
   code_TR813:"Bartın",
   code_TR82:"Kastamonu",
   code_TR821:"Kastamonu",
   code_TR822:"Çankırı",
   code_TR823:"Sinop",
   code_TR83:"Samsun",
   code_TR831:"Samsun",
   code_TR832:"Tokat",
   code_TR833:"Çorum",
   code_TR834:"Amasya",
   code_TR9:"Doğu Karadeniz",
   code_TR90:"Trabzon",
   code_TR901:"Trabzon",
   code_TR902:"Ordu",
   code_TR903:"Giresun",
   code_TR904:"Rize",
   code_TR905:"Artvin",
   code_TR906:"Gümüşhane",
   code_TRA:"Kuzeydoğu Anadolu",
   code_TRA1:"Erzurum",
   code_TRA11:"Erzurum",
   code_TRA12:"Erzincan",
   code_TRA13:"Bayburt",
   code_TRA2:"Ağrı",
   code_TRA21:"Ağrı",
   code_TRA22:"Kars",
   code_TRA23:"Iğdır",
   code_TRA24:"Ardahan",
   code_TRB:"Ortadoğu Anadolu",
   code_TRB1:"Malatya",
   code_TRB11:"Malatya",
   code_TRB12:"Elazığ",
   code_TRB13:"Bingöl",
   code_TRB14:"Tunceli",
   code_TRB2:"Van",
   code_TRB21:"Van",
   code_TRB22:"Muş",
   code_TRB23:"Bitlis",
   code_TRB24:"Hakkari",
   code_TRC:"Güneydoğu Anadolu",
   code_TRC1:"Gaziantep",
   code_TRC11:"Gaziantep",
   code_TRC12:"Adıyaman",
   code_TRC13:"Kilis",
   code_TRC2:"Şanlıurfa",
   code_TRC21:"Şanlıurfa",
   code_TRC22:"Diyarbakır",
   code_TRC3:"Mardin",
   code_TRC31:"Mardin",
   code_TRC32:"Batman",
   code_TRC33:"Şırnak",
   code_TRC34:"Siirt",
   code_UK:"United Kingdom",
   code_UKC:"NORTH EAST (ENGLAND)",
   code_UKC1:"Tees Valley and Durham",
   code_UKC11:"Hartlepool and Stockton-on-Tees",
   code_UKC12:"South Teesside",
   code_UKC13:"Darlington",
   code_UKC14:"Durham CC",
   code_UKC2:"Northumberland and Tyne and Wear",
   code_UKC21:"Northumberland",
   code_UKC22:"Tyneside",
   code_UKC23:"Sunderland",
   code_UKD:"NORTH WEST (ENGLAND)",
   code_UKD1:"Cumbria",
   code_UKD11:"West Cumbria",
   code_UKD12:"East Cumbria",
   code_UKD3:"Greater Manchester",
   code_UKD33:"Manchester",
   code_UKD34:"Greater Manchester South West",
   code_UKD35:"Greater Manchester South East",
   code_UKD36:"Greater Manchester North West",
   code_UKD37:"Greater Manchester North East",
   code_UKD4:"Lancashire",
   code_UKD41:"Blackburn with Darwen",
   code_UKD42:"Blackpool",
   code_UKD44:"Lancaster and Wyre",
   code_UKD45:"Mid Lancashire",
   code_UKD46:"East Lancashire",
   code_UKD47:"Chorley and West Lancashire",
   code_UKD6:"Cheshire",
   code_UKD61:"Warrington",
   code_UKD62:"Cheshire East",
   code_UKD63:"Cheshire West and Chester",
   code_UKD7:"Merseyside",
   code_UKD71:"East Merseyside",
   code_UKD72:"Liverpool",
   code_UKD73:"Sefton",
   code_UKD74:"Wirral",
   code_UKE:"YORKSHIRE AND THE HUMBER",
   code_UKE1:"East Yorkshire and Northern Lincolnshire",
   code_UKE11:"Kingston upon Hull, City of",
   code_UKE12:"East Riding of Yorkshire",
   code_UKE13:"North and North East Lincolnshire",
   code_UKE2:"North Yorkshire",
   code_UKE21:"York",
   code_UKE22:"North Yorkshire CC",
   code_UKE3:"South Yorkshire",
   code_UKE31:"Barnsley, Doncaster and Rotherham",
   code_UKE32:"Sheffield",
   code_UKE4:"West Yorkshire",
   code_UKE41:"Bradford",
   code_UKE42:"Leeds",
   code_UKE44:"Calderdale and Kirklees",
   code_UKE45:"Wakefield",
   code_UKF:"EAST MIDLANDS (ENGLAND)",
   code_UKF1:"Derbyshire and Nottinghamshire",
   code_UKF11:"Derby",
   code_UKF12:"East Derbyshire",
   code_UKF13:"South and West Derbyshire",
   code_UKF14:"Nottingham",
   code_UKF15:"North Nottinghamshire",
   code_UKF16:"South Nottinghamshire",
   code_UKF2:"Leicestershire, Rutland and Northamptonshire",
   code_UKF21:"Leicester",
   code_UKF22:"Leicestershire CC and Rutland",
   code_UKF24:"West Northamptonshire",
   code_UKF25:"North Northamptonshire",
   code_UKF3:"Lincolnshire",
   code_UKF30:"Lincolnshire",
   code_UKG:"WEST MIDLANDS (ENGLAND)",
   code_UKG1:"Herefordshire, Worcestershire and Warwickshire",
   code_UKG11:"Herefordshire, County of",
   code_UKG12:"Worcestershire",
   code_UKG13:"Warwickshire",
   code_UKG2:"Shropshire and Staffordshire",
   code_UKG21:"Telford and Wrekin",
   code_UKG22:"Shropshire CC",
   code_UKG23:"Stoke-on-Trent",
   code_UKG24:"Staffordshire CC",
   code_UKG3:"West Midlands",
   code_UKG31:"Birmingham",
   code_UKG32:"Solihull",
   code_UKG33:"Coventry",
   code_UKG36:"Dudley",
   code_UKG37:"Sandwell",
   code_UKG38:"Walsall",
   code_UKG39:"Wolverhampton",
   code_UKH:"EAST OF ENGLAND",
   code_UKH1:"East Anglia",
   code_UKH11:"Peterborough",
   code_UKH12:"Cambridgeshire CC",
   code_UKH14:"Suffolk",
   code_UKH15:"Norwich and East Norfolk",
   code_UKH16:"North and West Norfolk",
   code_UKH17:"Breckland and South Norfolk",
   code_UKH2:"Bedfordshire and Hertfordshire",
   code_UKH21:"Luton",
   code_UKH23:"Hertfordshire",
   code_UKH24:"Bedford",
   code_UKH25:"Central Bedfordshire",
   code_UKH3:"Essex",
   code_UKH31:"Southend-on-Sea",
   code_UKH32:"Thurrock",
   code_UKH34:"Essex Haven Gateway",
   code_UKH35:"West Essex",
   code_UKH36:"Heart of Essex",
   code_UKH37:"Essex Thames Gateway",
   code_UKI:"LONDON",
   code_UKI3:"Inner London – West",
   code_UKI31:"Camden and City of London",
   code_UKI32:"Westminster",
   code_UKI33:"Kensington & Chelsea and Hammersmith & Fulham",
   code_UKI34:"Wandsworth",
   code_UKI4:"Inner London – East",
   code_UKI41:"Hackney and Newham",
   code_UKI42:"Tower Hamlets",
   code_UKI43:"Haringey and Islington",
   code_UKI44:"Lewisham and Southwark",
   code_UKI45:"Lambeth",
   code_UKI5:"Outer London – East and North East",
   code_UKI51:"Bexley and Greenwich",
   code_UKI52:"Barking & Dagenham and Havering",
   code_UKI53:"Redbridge and Waltham Forest",
   code_UKI54:"Enfield",
   code_UKI6:"Outer London – South",
   code_UKI61:"Bromley",
   code_UKI62:"Croydon",
   code_UKI63:"Merton, Kingston upon Thames and Sutton",
   code_UKI7:"Outer London – West and North West",
   code_UKI71:"Barnet",
   code_UKI72:"Brent",
   code_UKI73:"Ealing",
   code_UKI74:"Harrow and Hillingdon",
   code_UKI75:"Hounslow and Richmond upon Thames",
   code_UKJ:"SOUTH EAST (ENGLAND)",
   code_UKJ1:"Berkshire, Buckinghamshire and Oxfordshire",
   code_UKJ11:"Berkshire",
   code_UKJ12:"Milton Keynes",
   code_UKJ13:"Buckinghamshire CC",
   code_UKJ14:"Oxfordshire",
   code_UKJ2:"Surrey, East and West Sussex",
   code_UKJ21:"Brighton and Hove",
   code_UKJ22:"East Sussex CC",
   code_UKJ25:"West Surrey",
   code_UKJ26:"East Surrey",
   code_UKJ27:"West Sussex (South West)",
   code_UKJ28:"West Sussex (North East)",
   code_UKJ3:"Hampshire and Isle of Wight",
   code_UKJ31:"Portsmouth",
   code_UKJ32:"Southampton",
   code_UKJ34:"Isle of Wight",
   code_UKJ35:"South Hampshire",
   code_UKJ36:"Central Hampshire",
   code_UKJ37:"North Hampshire",
   code_UKJ4:"Kent",
   code_UKJ41:"Medway",
   code_UKJ43:"Kent Thames Gateway",
   code_UKJ44:"East Kent",
   code_UKJ45:"Mid Kent",
   code_UKJ46:"West Kent",
   code_UKK:"SOUTH WEST (ENGLAND)",
   code_UKK1:"Gloucestershire, Wiltshire and Bristol/Bath area",
   code_UKK11:"Bristol, City of",
   code_UKK12:"Bath and North East Somerset, North Somerset and South Gloucestershire",
   code_UKK13:"Gloucestershire",
   code_UKK14:"Swindon",
   code_UKK15:"Wiltshire CC",
   code_UKK2:"Dorset and Somerset",
   code_UKK21:"Bournemouth and Poole",
   code_UKK22:"Dorset CC",
   code_UKK23:"Somerset",
   code_UKK3:"Cornwall and Isles of Scilly",
   code_UKK30:"Cornwall and Isles of Scilly",
   code_UKK4:"Devon",
   code_UKK41:"Plymouth",
   code_UKK42:"Torbay",
   code_UKK43:"Devon CC",
   code_UKL:"WALES",
   code_UKL1:"West Wales and The Valleys",
   code_UKL11:"Isle of Anglesey",
   code_UKL12:"Gwynedd",
   code_UKL13:"Conwy and Denbighshire",
   code_UKL14:"South West Wales",
   code_UKL15:"Central Valleys",
   code_UKL16:"Gwent Valleys",
   code_UKL17:"Bridgend and Neath Port Talbot",
   code_UKL18:"Swansea",
   code_UKL2:"East Wales",
   code_UKL21:"Monmouthshire and Newport",
   code_UKL22:"Cardiff and Vale of Glamorgan",
   code_UKL23:"Flintshire and Wrexham",
   code_UKL24:"Powys",
   code_UKM:"SCOTLAND",
   code_UKM5:"North Eastern Scotland",
   code_UKM50:"Aberdeen City and Aberdeenshire",
   code_UKM6:"Highlands and Islands",
   code_UKM61:"Caithness & Sutherland and Ross & Cromarty",
   code_UKM62:"Inverness & Nairn and Moray, Badenoch & Strathspey",
   code_UKM63:"Lochaber, Skye & Lochalsh, Arran & Cumbrae and Argyll & Bute",
   code_UKM64:"Na h-Eileanan Siar (Western Isles)",
   code_UKM65:"Orkney Islands",
   code_UKM66:"Shetland Islands",
   code_UKM7:"Eastern Scotland",
   code_UKM71:"Angus and Dundee City",
   code_UKM72:"Clackmannanshire and Fife",
   code_UKM73:"East Lothian and Midlothian",
   code_UKM75:"Edinburgh, City of",
   code_UKM76:"Falkirk",
   code_UKM77:"Perth & Kinross and Stirling",
   code_UKM78:"West Lothian",
   code_UKM8:"West Central Scotland",
   code_UKM81:"East Dunbartonshire, West Dunbartonshire and Helensburgh & Lomond",
   code_UKM82:"Glasgow City",
   code_UKM83:"Inverclyde, East Renfrewshire and Renfrewshire",
   code_UKM84:"North Lanarkshire",
   code_UKM9:"Southern Scotland",
   code_UKM91:"Scottish Borders",
   code_UKM92:"Dumfries & Galloway",
   code_UKM93:"East Ayrshire and North Ayrshire mainland",
   code_UKM94:"South Ayrshire",
   code_UKM95:"South Lanarkshire",
   code_UKN:"NORTHERN IRELAND",
   code_UKN0:"Northern Ireland",
   code_UKN06:"Belfast",
   code_UKN07:"Armagh City, Banbridge and Craigavon",
   code_UKN08:"Newry, Mourne and Down",
   code_UKN09:"Ards and North Down",
   code_UKN10:"Derry City and Strabane",
   code_UKN11:"Mid Ulster",
   code_UKN12:"Causeway Coast and Glens",
   code_UKN13:"Antrim and Newtownabbey",
   code_UKN14:"Lisburn and Castlereagh",
   code_UKN15:"Mid and East Antrim",
   code_UKN16:"Fermanagh and Omagh",
   code_UKZ:"EXTRA-REGIO NUTS 1",
   code_UKZZ:"Extra-Regio NUTS 2",
   code_UKZZZ:"Extra-Regio NUTS 3",
   code_GR:"ELLADA",
   code_AF:"Afghanistan",
   code_DZ:"Algeria",
   code_AS:"American Samoa",
   code_AD:"Andorra",
   code_AO:"Angola",
   code_AI:"Anguilla",
   code_AQ:"Antarctica",
   code_AG:"Antigua and Barbuda",
   code_AR:"Argentina",
   code_AM:"Armenia",
   code_AW:"Aruba",
   code_AU:"Australia",
   code_AZ:"Azerbaijan",
   code_BS:"Bahamas",
   code_BH:"Bahrain",
   code_BD:"Bangladesh",
   code_BB:"Barbados",
   code_BY:"Belarus",
   code_BZ:"Belize",
   code_BJ:"Benin",
   code_BM:"Bermuda",
   code_BT:"Bhutan",
   code_BO:"Bolivia",
   code_BA:"Bosnia and Herzegovina",
   code_BW:"Botswana",
   code_BV:"Bouvet Island",
   code_BR:"Brazil",
   code_IO:"British Indian Ocean Territory",
   code_BN:"Brunei Darussalam",
   code_BF:"Burkina Faso",
   code_BI:"Burundi",
   code_KH:"Cambodia",
   code_CM:"Cameroon",
   code_CA:"Canada",
   code_CV:"Cabo Verde",
   code_KY:"Cayman Islands",
   code_CF:"Central African Republic",
   code_TD:"Chad",
   code_CL:"Chile",
   code_CN:"China",
   code_CX:"Christmas Island",
   code_CO:"Colombia",
   code_KM:"Comoros",
   code_CG:"Congo",
   code_CD:"Congo",
   code_CK:"Cook Islands",
   code_CR:"Costa Rica",
   code_CI:"Côte d'Ivoire",
   code_CU:"Cuba",
   code_DJ:"Djibouti",
   code_DM:"Dominica",
   code_DO:"Dominican Republic",
   code_EC:"Ecuador",
   code_EG:"Egypt",
   code_EH:"Western Sahara",
   code_SV:"El Salvador",
   code_GQ:"Equatorial Guinea",
   code_ER:"Eritrea",
   code_ET:"Ethiopia",
   code_FK:"Falkland Islands (Malvinas)",
   code_FO:"Faroe Islands",
   code_FJ:"Fiji",
   code_GF:"French Guiana",
   code_PF:"French Polynesia",
   code_TF:"French Southern Territories",
   code_GA:"Gabon",
   code_GM:"Gambia",
   code_GE:"Georgia",
   code_GH:"Ghana",
   code_GI:"Gibraltar",
   code_GL:"Greenland",
   code_GD:"Grenada",
   code_GP:"Guadeloupe",
   code_GU:"Guam",
   code_GT:"Guatemala",
   code_GN:"Guinea",
   code_GW:"Guinea-Bissau",
   code_GY:"Guyana",
   code_HT:"Haiti",
   code_HN:"Honduras",
   code_HK:"Hong Kong",
   code_IN:"India",
   code_ID:"Indonesia",
   code_IR:"Iran",
   code_IQ:"Iraq",
   code_IL:"Israel",
   code_JM:"Jamaica",
   code_JP:"Japan",
   code_JO:"Jordan",
   code_KZ:"Kazakhstan",
   code_KE:"Kenya",
   code_KI:"Kiribati",
   code_KP:"Korea",
   code_KR:"Korea",
   code_KW:"Kuwait",
   code_KG:"Kyrgyzstan",
   code_LA:"Lao People's Democratic Republic",
   code_LB:"Lebanon",
   code_LS:"Lesotho",
   code_LR:"Liberia",
   code_LY:"Libya",
   code_MO:"Macao",
   code_MG:"Madagascar",
   code_MW:"Malawi",
   code_MY:"Malaysia",
   code_MV:"Maldives",
   code_ML:"Mali",
   code_MH:"Marshall Islands",
   code_MQ:"Martinique",
   code_MR:"Mauritania",
   code_MU:"Mauritius",
   code_YT:"Mayotte",
   code_MX:"Mexico",
   code_FM:"Micronesia",
   code_MD:"Moldova",
   code_MC:"Monaco",
   code_MN:"Mongolia",
   code_MS:"Montserrat",
   code_MA:"Morocco",
   code_MZ:"Mozambique",
   code_MM:"Myanmar",
   code_NA:"Namibia",
   code_NR:"Nauru",
   code_NP:"Nepal",
   code_AN:"Netherlands Antilles",
   code_NZ:"New Zealand",
   code_NI:"Nicaragua",
   code_NE:"Niger",
   code_NG:"Nigeria",
   code_NU:"Niue",
   code_NF:"Norfolk Island",
   code_MP:"Northern Mariana Islands",
   code_OM:"Oman",
   code_PK:"Pakistan",
   code_PW:"Palau",
   code_PS:"Palestine",
   code_PA:"Panama",
   code_PG:"Papua New Guinea",
   code_PY:"Paraguay",
   code_PE:"Peru",
   code_PH:"Philippines",
   code_PN:"Pitcairn",
   code_PR:"Puerto Rico",
   code_QA:"Qatar",
   code_RE:"Réunion",
   code_RU:"Russian Federation",
   code_RW:"Rwanda",
   code_SH:"Saint Helena",
   code_KN:"Saint Kitts and Nevis",
   code_LC:"Saint Lucia",
   code_PM:"Saint Pierre and Miquelon",
   code_VC:"Saint Vincent and the Grenadines",
   code_WS:"Samoa",
   code_SM:"San Marino",
   code_ST:"Sao Tome and Principe",
   code_SA:"Saudi Arabia",
   code_SN:"Senegal",
   code_CS:"Serbia and Montenegro",
   code_SC:"Seychelles",
   code_SL:"Sierra Leone",
   code_SG:"Singapore",
   code_SB:"Solomon Islands",
   code_SO:"Somalia",
   code_ZA:"South Africa",
   code_GS:"South Georgia and the South Sandwich Islands",
   code_LK:"Sri Lanka",
   code_SD:"Sudan",
   code_SR:"Suriname",
   code_SJ:"Svalbard and Jan Mayen",
   code_SZ:"Swaziland",
   code_SY:"Syrian Arab Republic",
   code_TW:"Taiwan",
   code_TJ:"Tajikistan",
   code_TZ:"Tanzania",
   code_TH:"Thailand",
   code_TL:"Timor-Leste",
   code_TG:"Togo",
   code_TK:"Tokelau",
   code_TO:"Tonga",
   code_TT:"Trinidad and Tobago",
   code_TN:"Tunisia",
   code_TM:"Turkmenistan",
   code_TC:"Turks and Caicos Islands",
   code_TV:"Tuvalu",
   code_UG:"Uganda",
   code_UA:"Ukraine",
   code_AE:"United Arab Emirates",
   code_GB:"United Kingdom of Great Britain and Northern Ireland",
   code_US:"United States of America",
   code_UM:"United States Minor Outlying Islands",
   code_UY:"Uruguay",
   code_UZ:"Uzbekistan",
   code_VE:"Venezuela",
   code_VU:"Vanuatu",
   code_VN:"Viet Nam",
   code_VG:"Virgin Islands",
   code_VI:"Virgin Islands",
   code_WF:"Wallis and Futuna",
   code_YE:"Yemen",
   code_ZW:"Zimbabwe",

   code_03000000:"Agricultural, farming, fishing, forestry and related products",
   code_FR212:"Aube",
   code_DE421:"Brandenburg an der Havel, Kreisfreie Stadt",
   code_DE422:"Cottbus, Kreisfreie Stadt",
   code_DE411:"Frankfurt (Oder), Kreisfreie Stadt",
   code_DE423:"Potsdam, Kreisfreie Stadt",
   code_DE412:"Barnim",
   code_DE424:"Dahme-Spreewald",
   code_DE425:"Elbe-Elster",
   code_DE426:"Havelland",
   code_DE413:"Märkisch-Oderland",
   code_DE414:"Oberhavel",
   code_DE427:"Oberspreewald-Lausitz",
   code_DE415:"Oder-Spree",
   code_DE416:"Ostprignitz-Ruppin",
   code_DE428:"Potsdam-Mittelmark",
   code_DE417:"Prignitz",
   code_DE429:"Spree-Neiße",
   code_DE42A:"Teltow-Fläming",
   code_DE418:"Uckermark",
   code_DEB16:"Cochem-Zell",
   code_DEB19:"Rhein-Hunsrück-Kreis",
   code_DED1:"Chemnitz",
   code_DED11:"Chemnitz, Kreisfreie Stadt",
   code_DED3:"Leipzig",
   code_DED31:"Leipzig, Kreisfreie Stadt",
   code_GR3:"ATTIKI",
   code_GR30:"Attiki",
   code_GR4:"NISIA AIGAIOU, KRITI",
   code_GR41:"Voreio Aigaio",
   code_GR411:"Lesvos",
   code_GR412:"Samos",
   code_GR413:"Chios",
   code_GR42:"Notio Aigaio",
   code_GR421:"Dodekanisos",
   code_GR422:"Kyklades",
   code_GR43:"Kriti",
   code_GR431:"Irakleio",
   code_GR432:"Lasithi",
   code_GR433:"Rethymni",
   code_GR434:"Chania",
   code_GR1:"VOREIA ELLADA",
   code_GR11:"Anatoliki Makedonia, Thraki",
   code_GR111:"Evros",
   code_GR112:"Xanthi",
   code_GR113:"Rodopi",
   code_GR114:"Drama",
   code_GR115:"Kavala",
   code_GR12:"Kentriki Makedonia",
   code_GR121:"Imathia",
   code_GR122:"Thessaloniki",
   code_GR123:"Kilkis",
   code_GR124:"Pella",
   code_GR125:"Pieria",
   code_GR126:"Serres",
   code_GR127:"Chalkidiki",
   code_GR13:"Dytiki Makedonia",
   code_GR132:"Kastoria",
   code_GR134:"Florina",
   code_GR21:"Ipeiros",
   code_GR212:"Thesprotia",
   code_GR213:"Ioannina",
   code_GR2:"KENTRIKI ELLADA",
   code_GR14:"Thessalia",
   code_GR142:"Larisa",
   code_GR143:"Magnisia",
   code_GR22:"Ionia Nisia",
   code_GR221:"Zakynthos",
   code_GR222:"Kerkyra",
   code_GR223:"Kefallinia",
   code_GR224:"Lefkada",
   code_GR23:"Dytiki Ellada",
   code_GR231:"Aitoloakarnania",
   code_GR232:"Achaia",
   code_GR233:"Ileia",
   code_GR24:"Sterea Ellada",
   code_GR241:"Voiotia",
   code_GR242:"Evvoia",
   code_GR243:"Evrytania",
   code_GR244:"Fthiotida",
   code_GR245:"Fokida",
   code_GR25:"Peloponnisos",
   code_GR253:"Korinthia",
   code_GRZ:"EXTRA-REGIO",
   code_GRZZ:"Extra-Regio",
   code_GRZZZ:"Extra-Regio",
   code_FI183:"Varsinais-Suomi",
   code_FI184:"Kanta-Häme",
   code_FI185:"Päijät-Häme",
   code_FI186:"Kymenlaakso",
   code_FI187:"Etelä-Karjala",
   code_FI131:"Etelä-Savo",
   code_FI132:"Pohjois-Savo",
   code_FI133:"Pohjois-Karjala",
   code_FI1A1:"Keski-Pohjanmaa",
   code_FI1A3:"Lappi",
   code_FI134:"Kainuu",
   code_FI1A2:"Pohjois-Pohjanmaa",
   code_FR24:"Centre",
   code_FR241:"Cher",
   code_FR242:"Eure-et-Loir",
   code_FR243:"Indre",
   code_FR244:"Indre-et-Loire",
   code_FR245:"Loir-et-Cher",
   code_FR246:"Loiret",
   code_FR26:"Bourgogne",
   code_FR261:"Côte-d'Or",
   code_FR262:"Nièvre",
   code_FR263:"Saône-et-Loire",
   code_FR264:"Yonne",
   code_FR43:"Franche-Comté",
   code_FR431:"Doubs",
   code_FR432:"Jura",
   code_FR433:"Haute-Saône",
   code_FR434:"Territoire de Belfort",
   code_FR25:"Basse-Normandie",
   code_FR251:"Calvados",
   code_FR252:"Manche",
   code_FR253:"Orne",
   code_FR23:"Haute-Normandie",
   code_FR231:"Eure",
   code_FR232:"Seine-Maritime",
   code_FR3:"NORD - PAS-DE-CALAIS",
   code_FR30:"Nord - Pas-de-Calais",
   code_FR301:"Nord",
   code_FR302:"Pas-de-Calais",
   code_FR22:"Picardie",
   code_FR221:"Aisne",
   code_FR222:"Oise",
   code_FR223:"Somme",
   code_FR42:"Alsace",
   code_FR421:"Bas-Rhin",
   code_FR422:"Haut-Rhin",
   code_FR21:"Champagne-Ardenne",
   code_FR211:"Ardennes",
   code_FR213:"Marne",
   code_FR214:"Haute-Marne",
   code_FR41:"Lorraine",
   code_FR411:"Meurthe-et-Moselle",
   code_FR412:"Meuse",
   code_FR413:"Moselle",
   code_FR414:"Vosges",
   code_FR51:"Pays de la Loire",
   code_FR511:"Loire-Atlantique",
   code_FR512:"Maine-et-Loire",
   code_FR513:"Mayenne",
   code_FR514:"Sarthe",
   code_FR515:"Vendée",
   code_FR52:"Bretagne",
   code_FR521:"Côtes-d'Armor",
   code_FR522:"Finistère",
   code_FR523:"Ille-et-Vilaine",
   code_FR524:"Morbihan",
   code_FR61:"Aquitaine",
   code_FR611:"Dordogne",
   code_FR612:"Gironde",
   code_FR613:"Landes",
   code_FR614:"Lot-et-Garonne",
   code_FR615:"Pyrénées-Atlantiques",
   code_FR63:"Limousin",
   code_FR631:"Corrèze",
   code_FR632:"Creuse",
   code_FR633:"Haute-Vienne",
   code_FR53:"Poitou-Charentes",
   code_FR531:"Charente",
   code_FR532:"Charente-Maritime",
   code_FR533:"Deux-Sèvres",
   code_FR534:"Vienne",
   code_FR81:"Languedoc-Roussillon",
   code_FR811:"Aude",
   code_FR812:"Gard",
   code_FR813:"Hérault",
   code_FR814:"Lozère",
   code_FR815:"Pyrénées-Orientales",
   code_FR62:"Midi-Pyrénées",
   code_FR621:"Ariège",
   code_FR622:"Aveyron",
   code_FR623:"Haute-Garonne",
   code_FR624:"Gers",
   code_FR625:"Lot",
   code_FR626:"Hautes-Pyrénées",
   code_FR627:"Tarn",
   code_FR628:"Tarn-et-Garonne",
   code_FR72:"Auvergne",
   code_FR721:"Allier",
   code_FR722:"Cantal",
   code_FR723:"Haute-Loire",
   code_FR724:"Puy-de-Dôme",
   code_FR71:"Rhône-Alpes",
   code_FR711:"Ain",
   code_FR712:"Ardèche",
   code_FR713:"Drôme",
   code_FR714:"Isère",
   code_FR715:"Loire",
   code_FR716:"Rhône",
   code_FR717:"Savoie",
   code_FR718:"Haute-Savoie",
   code_FR82:"Provence-Alpes-Côte d'Azur",
   code_FR821:"Alpes-de-Haute-Provence",
   code_FR822:"Hautes-Alpes",
   code_FR823:"Alpes-Maritimes",
   code_FR824:"Bouches-du-Rhône",
   code_FR825:"Var",
   code_FR826:"Vaucluse",
   code_FR83:"Corse",
   code_FR831:"Corse-du-Sud",
   code_FR832:"Haute-Corse",
   code_FR91:"Guadeloupe",
   code_FR910:"Guadeloupe",
   code_FR92:"Martinique",
   code_FR920:"Martinique",
   code_FR93:"Guyane",
   code_FR930:"Guyane",
   code_FR94:"Réunion",
   code_FR940:"Réunion",
   code_HR011:"Grad Zagreb",
   code_HR012:"Zagrebačka županija",
   code_HR013:"Krapinsko-zagorska županija",
   code_HR014:"Varaždinska županija",
   code_HR015:"Koprivničko-križevačka županija",
   code_HR016:"Međimurska županija",
   code_HR021:"Bjelovarsko-bilogorska županija",
   code_HR022:"Virovitičko-podravska županija",
   code_HR023:"Požeško-slavonska županija",
   code_HR024:"Brodsko-posavska županija",
   code_HR025:"Osječko-baranjska županija",
   code_HR026:"Vukovarsko-srijemska županija",
   code_HR027:"Karlovačka županija",
   code_HR028:"Sisačko-moslavačka županija",
   code_HU101:"Budapest",
   code_HU102:"Pest",
   code_IE01:"Border, Midland and Western",
   code_IE011:"Border",
   code_IE013:"West",
   code_IE023:"Mid-West",
   code_IE024:"South-East (IRL)",
   code_IE025:"South-West (IRL)",
   code_IE021:"Dublin",
   code_IE022:"Mid-East",
   code_IE012:"Midland",
   code_ITC45:"Milano",
   code_ITF41:"Foggia",
   code_ITF42:"Bari",
   code_ITD:"NORD-EST",
   code_ITD1:"Provincia Autonoma Bolzano/Bozen",
   code_ITD10:"Bolzano-Bozen",
   code_ITD2:"Provincia Autonoma Trento",
   code_ITD20:"Trento",
   code_ITD3:"Veneto",
   code_ITD31:"Verona",
   code_ITD32:"Vicenza",
   code_ITD33:"Belluno",
   code_ITD34:"Treviso",
   code_ITD35:"Venezia",
   code_ITD36:"Padova",
   code_ITD37:"Rovigo",
   code_ITD4:"Friuli-Venezia Giulia",
   code_ITD41:"Pordenone",
   code_ITD42:"Udine",
   code_ITD43:"Gorizia",
   code_ITD44:"Trieste",
   code_ITD5:"Emilia-Romagna",
   code_ITD51:"Piacenza",
   code_ITD52:"Parma",
   code_ITD53:"Reggio nell'Emilia",
   code_ITD54:"Modena",
   code_ITD55:"Bologna",
   code_ITD56:"Ferrara",
   code_ITD57:"Ravenna",
   code_ITD58:"Forlì-Cesena",
   code_ITD59:"Rimini",
   code_ITE:"CENTRO (I)",
   code_ITE1:"Toscana",
   code_ITE11:"Massa-Carrara",
   code_ITE12:"Lucca",
   code_ITE13:"Pistoia",
   code_ITE14:"Firenze",
   code_ITE15:"Prato",
   code_ITE16:"Livorno",
   code_ITE17:"Pisa",
   code_ITE18:"Arezzo",
   code_ITE19:"Siena",
   code_ITE1A:"Grosseto",
   code_ITE2:"Umbria",
   code_ITE21:"Perugia",
   code_ITE22:"Terni",
   code_ITE3:"Marche",
   code_ITE31:"Pesaro e Urbino",
   code_ITE32:"Ancona",
   code_ITE33:"Macerata",
   code_ITE34:"Ascoli Piceno",
   code_ITE4:"Lazio",
   code_ITE41:"Viterbo",
   code_ITE42:"Rieti",
   code_ITE43:"Roma",
   code_ITE44:"Latina",
   code_ITE45:"Frosinone",
   code_LT00A:"Vilniaus apskritis",
   code_LT001:"Alytaus apskritis",
   code_LT002:"Kauno apskritis",
   code_LT003:"Klaipėdos apskritis",
   code_LT004:"Marijampolės apskritis",
   code_LT005:"Panevėžio apskritis",
   code_LT006:"Šiaulių apskritis",
   code_LT007:"Tauragės apskritis",
   code_LT008:"Telšių apskritis",
   code_LT009:"Utenos apskritis",
   code_NL121:"Noord-Friesland",
   code_NL122:"Zuidwest-Friesland",
   code_NL123:"Zuidoost-Friesland",
   code_NL322:"Alkmaar en omgeving",
   code_NL326:"Groot-Amsterdam",
   code_NL331:"Agglomeratie Leiden en Bollenstreek",
   code_NL336:"Zuidoost-Zuid-Holland",
   code_NL334:"Oost-Zuid-Holland",
   code_NL335:"Groot-Rijnmond",
   code_PL215:"Nowosądecki",
   code_PL216:"Oświęcimski",
   code_PL422:"Koszaliński",
   code_PL425:"Szczeciński",
   code_PL521:"Nyski",
   code_PL522:"Opolski",
   code_PL614:"Grudziądzki",
   code_PL615:"Włocławski",
   code_PL631:"Słupski",
   code_PL635:"Starogardzki",
   code_PL11:"Łódzkie",
   code_PL113:"Miasto Łódź",
   code_PL114:"Łódzki",
   code_PL115:"Piotrkowski",
   code_PL116:"Sieradzki",
   code_PL117:"Skierniewicki",
   code_PL33:"Świętokrzyskie",
   code_PL331:"Kielecki",
   code_PL332:"Sandomiersko-jędrzejowski",
   code_PL3:"REGION WSCHODNI",
   code_PL31:"Lubelskie",
   code_PL311:"Bialski",
   code_PL312:"Chełmsko-zamojski",
   code_PL314:"Lubelski",
   code_PL315:"Puławski",
   code_PL32:"Podkarpackie",
   code_PL323:"Krośnieński",
   code_PL324:"Przemyski",
   code_PL325:"Rzeszowski",
   code_PL326:"Tarnobrzeski",
   code_PL34:"Podlaskie",
   code_PL343:"Białostocki",
   code_PL344:"Łomżyński",
   code_PL345:"Suwalski",
   code_PL127:"Miasto Warszawa",
   code_PL129:"Warszawski-wschodni",
   code_PL12A:"Warszawski-zachodni",
   code_PL128:"Radomski",
   code_PT113:"Ave",
   code_PT114:"Grande Porto",
   code_PT115:"Tâmega",
   code_PT116:"Entre Douro e Vouga",
   code_PT117:"Douro",
   code_PT118:"Alto Trás-os-Montes",
   code_PT163:"Pinhal Litoral",
   code_PT165:"Dâo-Lafôes",
   code_PT182:"Alto Alentejo",
   code_PT183:"Alentejo Central",
   code_SI01:"Vzhodna Slovenija",
   code_SI011:"Pomurska",
   code_SI012:"Podravska",
   code_SI013:"Koroška",
   code_SI014:"Savinjska",
   code_SI015:"Zasavska",
   code_SI016:"Spodnjeposavska",
   code_SI017:"Jugovzhodna Slovenija",
   code_SI018:"Notranjsko-kraška",
   code_SI02:"Zahodna Slovenija",
   code_SI021:"Osrednjeslovenska",
   code_SI022:"Gorenjska",
   code_SI023:"Goriška",
   code_SI024:"Obalno-kraška",
   code_UKD2:"Cheshire",
   code_UKD5:"Merseyside",
   code_UKD51:"East Merseyside",
   code_UKD52:"Liverpool",
   code_UKD53:"Sefton",
   code_UKD54:"Wirral",
   code_UKM2:"Eastern Scotland",
   code_UKM21:"Angus and Dundee City",
   code_UKM22:"Clackmannanshire and Fife",
   code_UKM23:"East Lothian and Midlothian",
   code_UKM25:"Edinburgh, City of",
   code_UKM26:"Falkirk",
   code_UKM27:"Perth & Kinross and Stirling",
   code_UKM28:"West Lothian",
   code_UKM31:"East Dunbartonshire, West Dunbartonshire and Helensburgh & Lomond",
   code_UKM34:"Glasgow City",
   code_UKM35:"Inverclyde, East Renfrewshire and Renfrewshire",
   code_UKM36:"North Lanarkshire",
   code_UKM24:"Scottish Borders",
   code_UKM32:"Dumfries & Galloway",
   code_UKM33:"East Ayrshire and North Ayrshire mainland",
   code_UKM37:"South Ayrshire",
   code_UKM38:"South Lanarkshire",
   code_UKN01:"Belfast",
   code_FR2:"Bassin Parisien",
   code_FR4:"Est",
   code_42924000:"Sprayers",
   code_35811000:"Uniforms",
   code_34511000:"Patrol vessels",
   code_44115300:"Shutters",
   code_44613100:"Silos",
   code_35611000:"Fighter planes, bombers and other aircraft",
   code_60110000:"Public transport services",
   code_39250000:"Clocks, watches and alarm clocks",
   code_30192120:"Pens, markers and pen holders",
   code_35612000:"Military helicopters and search and rescue helicopters",
   code_38527000:"Dosimeters",
   code_P:"Electrical installations",
   code_BE328:"Arr. Tournai-Mouscron",
   code_BE329:"Arr. La Louvière",
   code_BE32A:"Arr. Ath",
   code_BE32B:"Arr. Charleroi",
   code_BE32C:"Arr. Soignies",
   code_BE32D:"Arr. Thuin",
   code_EE009:"Kesk-Eesti",
   code_EE00A:"Kirde-Eesti",
   code_HR05:"Grad Zagreb",
   code_HR050:"Grad Zagreb",
   code_HR06:"Sjeverna Hrvatska",
   code_HR061:"Međimurska županija",
   code_HR062:"Varaždinska županija",
   code_HR063:"Koprivničko-križevačka županija",
   code_HR064:"Krapinsko-zagorska županija",
   code_HR065:"Zagrebačka županija",
   code_HR02:"Panonska Hrvatska",
   code_ITG2D:"Sassari",
   code_ITG2E:"Nuoro",
   code_ITG2F:"Cagliari",
   code_ITG2G:"Oristano",
   code_ITG2H:"Sud Sardegna",
   code_UKK24:"Bournemouth, Christchurch and Poole",
   code_UKK25:"Dorset",
   code_UKN0A:"Derry City and Strabane",
   code_UKN0B:"Mid Ulster",
   code_UKN0C:"Causeway Coast and Glens",
   code_UKN0D:"Antrim and Newtownabbey",
   code_UKN0E:"Lisburn and Castlereagh",
   code_UKN0F:"Mid and East Antrim",
   code_UKN0G:"Fermanagh and Omagh",
   code_NO020:"Innlandet",
   code_NO060:"Trøndelag",
   code_NO074:"Troms og Finnmark",
   code_NO08:"Oslo og Viken",
   code_NO081:"Oslo",
   code_NO082:"Viken",
   code_NO09:"Agder og Sør-Østlandet",
   code_NO091:"Vestfold og Telemark",
   code_NO092:"Agder",
   code_NO0A:"Vestlandet",
   code_NO0A1:"Rogaland",
   code_NO0A2:"Vestland",
   code_NO0A3:"Møre og Romsdal",
   code_DE41:"Brandenburg - Nordost",
   code_DE42:"Brandenburg - Südwest",
   code_DE801:"Greifswald, Kreisfreie Stadt",
   code_DE802:"Neubrandenburg, Kreisfreie Stadt",
   code_DE805:"Stralsund, Kreisfreie Stadt",
   code_DE806:"Wismar, Kreisfreie Stadt",
   code_DE807:"Bad Doberan",
   code_DE808:"Demmin",
   code_DE809:"Güstrow",
   code_DE80A:"Ludwigslust",
   code_DE80B:"Mecklenburg-Strelitz",
   code_DE80C:"Müritz",
   code_DE80D:"Nordvorpommern",
   code_DE80E:"Nordwestmecklenburg",
   code_DE80F:"Ostvorpommern",
   code_DE80G:"Parchim",
   code_DE80H:"Rügen",
   code_DE80I:"Uecker-Randow",
   code_DE915:"Göttingen",
   code_DE919:"Osterode am Harz",
   code_DEA21:"Aachen, Kreisfreie Stadt",
   code_DEA25:"Aachen, Kreis",
   code_DED12:"Plauen, Kreisfreie Stadt",
   code_DED13:"Zwickau, Kreisfreie Stadt",
   code_DED14:"Annaberg",
   code_DED15:"Chemnitzer Land",
   code_DED16:"Freiberg",
   code_DED17:"Vogtlandkreis",
   code_DED18:"Mittlerer Erzgebirgskreis",
   code_DED19:"Mittweida",
   code_DED1A:"Stollberg",
   code_DED1B:"Aue-Schwarzenberg",
   code_DED1C:"Zwickauer Land",
   code_DED22:"Görlitz, Kreisfreie Stadt",
   code_DED23:"Hoyerswerda, Kreisfreie Stadt",
   code_DED24:"Bautzen",
   code_DED25:"Meißen",
   code_DED26:"Niederschlesischer Oberlausitzkreis",
   code_DED27:"Riesa-Großenhain",
   code_DED28:"Löbau-Zittau",
   code_DED29:"Sächsische Schweiz",
   code_DED2A:"Weißeritzkreis",
   code_DED2B:"Kamenz",
   code_DED32:"Delitzsch",
   code_DED33:"Döbeln",
   code_DED34:"Leipziger Land",
   code_DED35:"Muldentalkreis",
   code_DED36:"Torgau-Oschatz",
   code_GR131:"Grevena",
   code_GR133:"Kozani",
   code_GR211:"Arta",
   code_GR214:"Preveza",
   code_GR141:"Karditsa",
   code_GR144:"Trikala",
   code_GR251:"Argolida",
   code_GR252:"Arkadia",
   code_GR254:"Lakonia",
   code_GR255:"Messinia",
   code_GR300:"Attiki",
   code_FI181:"Uusimaa",
   code_FI182:"Itä-Uusimaa",
   code_FI18:"Etelä-Suomi",
   code_FI13:"Itä-Suomi",
   code_FI1A:"Pohjois-Suomi",
   code_FR5:"OUEST",
   code_FR6:"SUD-OUEST",
   code_FR7:"CENTRE-EST",
   code_FR8:"MÉDITERRANÉE",
   code_FR9:"DÉPARTEMENTS D'OUTRE-MER",
   code_HR01:"Sjeverozapadna Hrvatska",
   code_HU10:"Közép-Magyarország",
   code_IE02:"Southern and Eastern",
   code_LT00:"Lietuva",
   code_PL1:"REGION CENTRALNY",
   code_PL12:"Mazowieckie",
   code_PL121:"Ciechanowsko-płocki",
   code_PL122:"Ostrołęcko-siedlecki",
   code_PL423:"Stargardzki",
   code_PT161:"Baixo Vouga",
   code_PT162:"Baixo Mondego",
   code_PT164:"Pinhal Interior Norte",
   code_PT166:"Pinhal Interior Sul",
   code_PT169:"Beira Interior Sul",
   code_PT16C:"Médio Tejo",
   code_PT167:"Serra da Estrela",
   code_PT168:"Beira Interior Norte",
   code_PT16A:"Cova da Beira",
   code_PT171:"Grande Lisboa",
   code_PT172:"Península de Setúbal",
   code_UKD31:"Greater Manchester South",
   code_UKD32:"Greater Manchester North",
   code_UKD43:"Lancashire CC",
   code_UKD21:"Halton and Warrington",
   code_UKD22:"Cheshire CC",
   code_UKE43:"Calderdale, Kirklees and Wakefield",
   code_UKF23:"Northamptonshire",
   code_UKG34:"Dudley and Sandwell",
   code_UKG35:"Walsall and Wolverhampton",
   code_UKH13:"Norfolk",
   code_UKH22:"Bedfordshire CC",
   code_UKH33:"Essex CC",
   code_UKI1:"Inner London",
   code_UKI11:"Inner London - West",
   code_UKI12:"Inner London - East",
   code_UKI2:"Outer London",
   code_UKI21:"Outer London - East and North East",
   code_UKI22:"Outer London - South",
   code_UKI23:"Outer London - West and North West",
   code_UKJ23:"Surrey",
   code_UKJ24:"West Sussex",
   code_UKJ33:"Hampshire CC",
   code_UKJ42:"Kent CC",
   code_UKM3:"South Western Scotland",
   code_UKN02:"Outer Belfast",
   code_UKN03:"East of Northern Ireland",
   code_UKN04:"North of Northern Ireland",
   code_UKN05:"West and South of Northern Ireland",
   code_1A:"Kosovo",
   code_ZM:"Zambia",
   code_AX:"Åland Islands",
   code_BL:"Saint Barthélemy",
   code_BQ:"Bonaire, Sint Eustatius and Saba",
   code_CC:"Cocos (Keeling) Islands",
   code_CW:"Curaçao",
   code_GG:"Guernsey",
   code_HM:"Heard Island and McDonald Islands",
   code_IM:"Isle of Man",
   code_JE:"Jersey",
   code_MF:"Saint Martin (French part)",
   code_NC:"New Caledonia",
   code_SS:"South Sudan",
   code_SX:"Sint Maarten (Dutch part)",
   code_VA:"Holy See",
   code_CP:"Clipperton Island",
   code_NO0B:"Svalbard og Jan Mayen",
   code_NO0B1:"Jan Mayen",
   code_NO0B2:"Svalbard",
   code_BE224:"Arr. Hasselt",
   code_BE225:"Arr. Maaseik",
   code_ISZ:"Extra-Regio NUTS 1",
   code_ISZZ:"Extra-Regio NUTS 2",
   code_ISZZZ:"Extra-Regio NUTS 3",
   code_LIZ:"Extra-Regio NUTS 1",
   code_LIZZ:"Extra-Regio NUTS 2",
   code_LIZZZ:"Extra-Regio NUTS 3",
   code_NOZ:"Extra-Regio NUTS 1",
   code_NOZZ:"Extra-Regio NUTS 2",
   code_NOZZZ:"Extra-Regio NUTS 3",
   code_CHZ:"Extra-Regio NUTS 1",
   code_CHZZ:"Extra-Regio NUTS 2",
   code_CHZZZ:"Extra-Regio NUTS 3",
   code_MEZ:"Extra-Regio NUTS 1",
   code_MEZZ:"Extra-Regio NUTS 2",
   code_MEZZZ:"Extra-Regio NUTS 3",
   code_MKZ:"Extra-Regio NUTS 1",
   code_MKZZ:"Extra-Regio NUTS 2",
   code_MKZZZ:"Extra-Regio NUTS 3",
   code_ALZ:"Extra-Regio NUTS 1",
   code_ALZZ:"Extra-Regio NUTS 2",
   code_ALZZZ:"Extra-Regio NUTS 3",
   code_RS1:"Србија - север ",
   code_RS11:"Београдски регион",
   code_RS110:"Београдска област",
   code_RS12:"Регион Војводине",
   code_RS121:"Западнобачка област",
   code_RS122:"Јужнобанатска област",
   code_RS123:"Јужнобачка област",
   code_RS124:"Севернобанатска област",
   code_RS125:"Севернобачка област",
   code_RS126:"Средњобанатска област",
   code_RS127:"Сремска област",
   code_RS2:"Србија - југ ",
   code_RS21:"Регион Шумадије и Западне Србије",
   code_RS211:"Златиборска област",
   code_RS212:"Колубарска област",
   code_RS213:"Мачванска област",
   code_RS214:"Моравичка област",
   code_RS215:"Поморавска област",
   code_RS216:"Расинска област",
   code_RS217:"Рашка област",
   code_RS218:"Шумадијска област",
   code_RS22:"Регион Јужне и Источне Србије",
   code_RS221:"Борска област",
   code_RS222:"Браничевска област",
   code_RS223:"Зајечарска област",
   code_RS224:"Јабланичка област",
   code_RS225:"Нишавска област",
   code_RS226:"Пиротска област",
   code_RS227:"Подунавска област",
   code_RS228:"Пчињска област",
   code_RS229:"Топличка област",
   code_RSZ:"Extra-Regio NUTS 1",
   code_RSZZ:"Extra-Regio NUTS 2",
   code_RSZZZ:"Extra-Regio NUTS 3",
   code_TRZ:"Extra-Regio NUTS 1",
   code_TRZZ:"Extra-Regio NUTS 2",
   code_TRZZZ:"Extra-Regio NUTS 3",
   accreditation_budget_class_1: "Class 1: until 135.000 EUR",
   accreditation_budget_class_2: "Class 2: until 275.000 EUR",
   accreditation_budget_class_3: "Class 3: until 500.000 EUR",
   accreditation_budget_class_4: "Class 4: until 900.000 EUR",
   accreditation_budget_class_5: "Class 5: until 1.810.000 EUR",
   accreditation_budget_class_6: "Class 6: until 3.225.000 EUR",
   accreditation_budget_class_7: "Class 7: until 5.330.000 EUR",
   accreditation_budget_class_8: "Class 8: more than 5.330.000 EUR",
   accreditation_P1:"Electrical installations in buildings, including generator installations, fire detection equipment fire and theft detection equipment, remote transmission in buildings and their surroundings and mixed telephony installations.",
   accreditation_P2:"Electrical and electromechanical installations of engineering or industrial structures and electrical installations",
   accreditation_T6:"Slaughterhouse equipment",
   accreditation_D11:"Plastering",
   accreditation_P3:"Installations of overhead electric transmission lines",
   accreditation_D10:"Tiles",
   accreditation_P4:"Electrical installations of port structures",
   accreditation_D13:"Painting",
   accreditation_L1:"Piping installations",
   accreditation_D12:"Non-metallic and non-asphaltic roofing",
   accreditation_L2:"Pumping and turbine station equipment",
   accreditation_D15:"Flooring",
   accreditation_D14:"Glazing",
   accreditation_D17:"Central heating, thermal installations",
   accreditation_H1:"Rail welding work",
   accreditation_D16:"Sanitary installations and gas heating installations by individual appliances",
   accreditation_H2:"Installation of catenaries",
   accreditation_D18:"Ventilation, warm air heating, air conditioning",
   accreditation_D1:"All structural work and roofing of buildings",
   accreditation_D4:"Acoustic or thermal insulation, light partitions, false ceilings and false floors, prefabricated or not",
   accreditation_D5:"General carpentry, wooden frames and stairs",
   accreditation_D6:"Marble work and stone cutting",
   accreditation_D7:"Ironwork",
   accreditation_D8:"Asphalt roofing or similar, waterproofing work",
   accreditation_D20:"Metal joinery",
   accreditation_D22:"Metal roofing and zinc work",
   accreditation_D21:"Façade renovation and restoration",
   accreditation_M1:"Industrial or high frequency electronic equipment including power station equipment power stations",
   accreditation_D24:"Restoration of monuments",
   accreditation_D23:"Restoration by craftsmen",
   accreditation_D25:"Wall and floor coverings, other than marble, parquet and tiles",
   accreditation_D29:"Industrial floor screeds and floor coverings",
   accreditation_E1:"Sewers",
   accreditation_E2:"Deep foundations on piles, sheet piling, stamped walls",
   accreditation_E4:"Horizontal foundations for civil engineering structures",
   accreditation_A1:"Refloating of ships and removal of wrecks",
   accreditation_N1:"Lifts, hoists, escalators and moving walkways",
   accreditation_N2:"Transport of objects, documents or goods by shafts or tubes (pneumatic, mechanical...)",
   accreditation_F1:"Erection and dismantling works (without supplies)",
   accreditation_F2:"Construction of steel structures",
   accreditation_F3:"Industrial painting",
   accreditation_B1:"River cleaning",
   accreditation_S1:"Telephone and telegraph equipment",
   accreditation_S2:"Remote control, telecontrol and telemetry equipment",
   accreditation_S3:"Radio and television transmission equipment, radar and antennas",
   accreditation_A:"Dredging companies",
   accreditation_S4:"Computer and process control equipment",
   accreditation_B:"Waterworks contractors",
   accreditation_C:"General road contractors",
   accreditation_D:"General building contractors",
   accreditation_E:"Civil engineering contractors",
   accreditation_F:"Steel construction companies",
   accreditation_K1:"Equipment for civil engineering or industrial mechanics",
   accreditation_G:"Earthwork contractors",
   accreditation_K2:"Handling and lifting equipment (cranes, overhead cranes...)",
   accreditation_H:"Railway undertakings",
   accreditation_K3:"Oleo-mechanical equipment",
   accreditation_G1:"Drilling, boring and injection works",
   accreditation_K:"Mechanical equipment contractors",
   accreditation_G2:"Drainage works",
   accreditation_L:"Installation companies of hydromechanical equipment",
   accreditation_G3:"Plantations",
   accreditation_M:"Electronic equipment installation companies",
   accreditation_G4:"Special surfaces for sports grounds",
   accreditation_N:"Installation companies of transport systems in buildings",
   accreditation_C1:"General sewerage works",
   accreditation_G5:"Demolition works",
   accreditation_C2:"Water supply and laying of various pipes",
   accreditation_C3:"Non-electrical signalling of communication routes, safety devices, fences and screens of any type, non-electrical all types, non-electrical",
   accreditation_C5:"Hydrocarbon coatings and renderings",
   accreditation_S:"Installation companies of remote transmission and data management equipment",
   accreditation_C6:"Trenching of electrical power and telecommunication cables, without connection",
   accreditation_C7:"Horizontal pipe bores for cables and pipes",
   accreditation_U:"Refuse disposal equipment",
   accreditation_V:"Water purification plants",
   accreditation_T2:"Lightning conductors, receiving antennas",
   accreditation_T3:"Refrigeration equipment",
   accreditation_T4:"Laundry and kitchen equipment",
   accreditation_P:"Electrical installations",


}