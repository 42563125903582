import React, {useEffect, useState} from 'react'
import './reset-password.scss';
import {updateToken, useResetPasswordMutation} from "../../hooks/slices/loginSlice";
import {useLocation, useNavigate} from "react-router-dom";
import {showCustomErrorPopup, updateSnacc} from "../../hooks/slices/snaccSlice";
import {useAppDispatch} from "../../app/hooks";
import {useTranslation} from "react-i18next";
import {Form} from "../../components/form/Form";
import {PasswordUtil} from "../../utils/password";

export const ResetPassword: React.FC = () => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [resetPassword, {data: loginToken, isSuccess, isError, error}] = useResetPasswordMutation();

    //TODO: move to hook
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const passwordResetToken = query.get('token');

    const [passwordMatch, setPasswordMatch] = useState(true);

    const onSubmit = (password: string, confirmPassword: string) => {
        if (PasswordUtil.passwordIsInvalid(password)) dispatch(showCustomErrorPopup(t("intake.valid_password_description")));
        else {
            setPasswordMatch(true);
            if (password !== confirmPassword) {
                setPasswordMatch(false);
                dispatch(showCustomErrorPopup(t("intake.passwords_dont_match")));
            } else {
                if (passwordResetToken) {
                    resetPassword({token: passwordResetToken, password});
                }
            }
        }
    };

    useEffect(() => {
        if (isSuccess && loginToken) {
            //put token in local storage for cross-browser use
            localStorage.setItem("token", loginToken);
            // pass the data from the successful login to the redux store
            dispatch(updateToken(loginToken));
            navigate("/");
        } else if (isError && error) {
            // show an error on the snackbar with a failed login
            dispatch(showCustomErrorPopup(t("error.oopsSomethingWentWrong")));
        }
    }, [isSuccess, loginToken, isError, error, dispatch]);

    return (
        <>
            <ResetPasswordForm onSubmit={onSubmit} passwordMatch={passwordMatch}/>
        </>
    );
};

interface ResetPasswordFormProps {
    passwordMatch: boolean;
    onSubmit: (password: string, repeatPassword: string) => void;
}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({passwordMatch, onSubmit}) => {

    const [password, setPassword] = useState("");
    const onPasswordChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setPassword(e.currentTarget.value);
    };

    const [confirmPassword, setConfirmPassword] = useState("");
    const onConfirmPasswordChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setConfirmPassword(e.currentTarget.value);
    };

    const {t} = useTranslation();
    return (
        <div>
            <div className='layout-main-banner'>
                <div className='container'>
                    <div className='layout-top-alignment'>
                        <div className='new-password-box'>
                            <Form onSubmit={() => onSubmit(password, confirmPassword)}>
                                <div className='page-title'>
                                    <h1>{t("login.reset_password")}</h1>
                                </div>
                                <div className='input'>
                                    <input type="password" placeholder={t("intake.enter_password")}
                                           onChange={onPasswordChange}
                                           className={!passwordMatch ? "error" : ""}/>
                                </div>
                                <div className='input'>
                                    <input type="password" placeholder={t("intake.confirm_password")}
                                           onChange={onConfirmPasswordChange}
                                           className={!passwordMatch ? "error" : ""}/>
                                </div>
                                <div className='request-to-login-button'>
                                    <div className='fill-button'>
                                        <button type={"submit"}>{t("settings.save")}</button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

