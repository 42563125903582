import React from "react";
import "./loader.scss";
import TenderWolfLogo from '../../assets/logo/black-logo-icon.svg';

export const Loader: React.FC = () => {
    return (
        <div>
            <div className="loader-wrapper">
                <div className={'loader-box'}>
                    <div className="loader-element spinner"></div>
                    <div className={'loader-element'}>
                        <div style={{padding: '22px', filter: 'invert(1)'}}>
                            <img src={TenderWolfLogo} alt="TenderWolfLogo"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
