import {S3FileDocument} from "../types/tender";

export namespace S3FileUtil {
    export function extractFileName(file: S3FileDocument) {
        if (file && file.s3Key) {
            const lastSlashIndex = file.s3Key.lastIndexOf('/');
            if (lastSlashIndex !== -1) {
                return file.s3Key.substring(lastSlashIndex + 1);
            }
        } else {
            return file.fileName;
        }
    }

    export function filterDownloadableFiles(files: S3FileDocument[]) {
        const uniqueKeys = new Set();
        const filteredFiles: S3FileDocument[] = [];

        files.forEach(file => {
            if (file) {
                // If the file doesn't have an s3Key or it's a unique s3Key, add it to the list
                if (!file.s3Key || (file.s3Key && !uniqueKeys.has(file.s3Key))) {
                    if (file.s3Key) {
                        uniqueKeys.add(file.s3Key);
                    }
                    filteredFiles.push(file);
                }
            }
        });

        return filteredFiles;
    }

    export function prioritizeSpecifications(files: S3FileDocument[]) {
        let specifications: S3FileDocument[] = [];
        let technicalSpecifications: S3FileDocument[] = [];
        let selectionGuides: S3FileDocument[] = [];
        let billOfQuantities: S3FileDocument[] = [];
        let addenda: S3FileDocument[] = [];
        files.forEach(file => {
            if (file.type === 'SPECIFICATIONS_FILE') {specifications.push(file);}
            else if (file.type === 'ADMINISTRATIVE_SPECIFICATIONS') {specifications.push(file);}
            else if (file.type === 'TECHNICAL_SPECIFICATIONS') {technicalSpecifications.push(file);}
            else if (file.type === 'SELECTION_GUIDE') {selectionGuides.push(file);}
            else if (file.type === 'BILL_OF_QUANTITIES') {billOfQuantities.push(file);}
            else {addenda.push(file);}
        })
        return specifications
            .concat(technicalSpecifications)
            .concat(selectionGuides)
            .concat(billOfQuantities)
            .concat(addenda);
    }
}