import { ResourceKey } from "i18next";

export const Intake: ResourceKey = {
	title_default: "Créer un nouveau compte",
	title_government: "Accès à la base de données des spécifications",
	trial_message_start: "Vous bénéficiez d'une période d'essai gratuite de ",
	trial_message_end_days: " jours.",
	trial_message_end_months: " mois.",
	trial_message_end_years: " années.",
	trial_plan_message_government: "Vous pouvez utiliser notre base de données de cahiers des charges gratuitement et sans aucune obligation.",
	trial_message_accelerated: "Vous avez accès à une version d'essai gratuite de TenderWolf Professional. Complètement sans engagement",
	change_plan_message_default: "Pendant la période d'essai, vous pouvez à tout moment passer au plan que vous préférez. Sans action de votre part, vous passerez automatiquement au plan Free à l'issue de la période d'essai.",
	change_plan_message_government: "L'accès gratuit est limité à un employé par organisation. Les organisations qui souhaitent un accès pour plusieurs employés doivent prendre un abonnement payant.",
	promotion_plan_message: "Il s'agit de la base de données de spécifications la plus rapide, la plus complète et la plus conviviale du marché belge.",
	first_name: "Prénom",
	last_name: "Nom",
	email: "Adresse e-mail",
	enter_password: "Mot de passe",
	confirm_password: "Confirmez le mot de passe",
	agree: "J'accepte ",
	privacy_policy: "le politique de confidentialité ",
	and: "et ",
	terms: "les conditions générales",
	register: "Inscrivez-vous",
	already_account: "Déjà inscrit ?",
	email_already_in_use: "Adresse électronique déjà utilisée",
	fill_in_all_fields: "Remplissez tous les champs",
	passwords_dont_match: "Mots de passe ne correspondent pas",
	password_is_invalid: "Le mot de passe n'est pas valide",
	valid_password_description: "Choisissez un mot de passe d'au moins 8 caractères, composé de lettres minuscules et majuscules, et contenant au moins un chiffre et un signe de ponctuation.",
	login: "Connectez-vous",
	second_page_title: "Veuillez remplir les informations complémentaires",
	company_name: "Organisation",
	invoice_reference: "Invoice reference",
	vat_number_default: "Numéro d'entreprise",
	vat_number_government: "Numéro d'entreprise (le cas échéant)",
	country: "Pays",
	website_default: "Site web",
	website_government: "Site web (facultatif)",
	tender_experience: "Votre entreprise a-t-elle déjà participé à des appels d'offres publics ?",
	next: "Suivant",
	skip: "Sauter",
	yes: "Oui",
	no: "Non",
	third_page_title: "Sélectionnez les pays qui vous intéressent",
	all_publications: "Tous les marchés publics (y compris les institutions de l'UE)",
	european_publications_only: "Tous les marchés publics au-dessus des seuils européens (y compris les institutions de l'UE)",
	fourth_page_title: "Donnez-nous quelques mots-clés décrivant les appels d'offres publics que vous recherchez",
	company_description: "Décrivez vos activités pour lesquelles vous recherchez des offres publiques (facultatif)",
	hardstop_title: "Merci pour votre inscription !",
	hardstop_text: "Nos experts en recherche d'offres ont été informés de votre demande.\n"
		+"Dans un délai de 6 à 24 heures, nous prévoyons de mettre en place votre compte d'essai de TenderWolf.\n"
		+ "Nous vous remercions de votre patience.",
	invalid_email: "Adresse e-mail non valide",
    close: "Fermer",
	must_select_at_least_one_country : "Vous devez sélectionner au moins un pays",
	cant_select_more_than_six_countries : "Vous ne pouvez pas sélectionner plus de six pays pendant votre période d'essai",
	address : "Adresse",
	main_activity: "Activité principale",
	starting_date: "Date de début",
	is_subcontractor : "Êtes-vous un sous-traitant ?",
	is_looking_for_subcontractors : "Recherchez-vous des sous-traitants ?",
	is_looking_for_partners : "Recherchez-vous des partenaires ?",
	is_interested_in_private_contracts : "Êtes-vous intéressé par des contrats privés ?",
	complete_where_needed : "Compléter si nécessaire",
	curated_profiles_title: "Quels sont les secteurs qui vous intéressent ?",
	we_found_this_info_about_you : "Nous avons retrouvé ces informations sur vous",
	answer_these_questions: "Répondez à ces questions",
	i_cant_find_my_sector: "Je ne trouve pas mon secteur",
	you_have_accreditations: "Nous avons retrouvé les agréations suivantes pour votre entreprise. Veuillez vérifier si vous souhaitez recevoir des marchés publics pour toutes vos agréations (catégorie et classe).",
	select_regions: "Indiquez les régions où vous souhaitez exécuter des marchés publics",

};