import { ResourceKey } from "i18next";

export const ContractingAuthorityMainActivities: ResourceKey = {
    GENERAL_PUBLIC_SERVICES: "Services généraux des administrations publiques",
    EDUCATION: "Éducation",
    RAILWAY_SERVICES: "Services de chemin de fer",
    PRODUCTION_TRANSPORT_DISTRIBUTION_GAS_HEAT: "Production, transport et distribution de gaz et de chaleur",
    ELECTRICITY: "Électricité",
    HEALTH: "Santé",
    PUBLIC_ORDER_AND_SAFETY: "Ordre et sécurité publics",
    URBAN_RAILWAY_TRAMWAY_TROLLEYBUS_BUS_SERVICES: "Services de chemin de fer urbain, de tramway, de trolleybus ou d'autobus",
    AIRPORT_RELATED_ACTIVITIES: "Activités aéroportuaires",
    WATER: "Eau",
    POSTAL_SERVICES: "Services postaux",
    DEFENCE: "Défense",
    RECREATION_CULTURE_AND_RELIGION: "Loisirs, culture et religion",
    HOUSING_AND_COMMUNITY_AMENITIES: "Logement et équipements collectifs",
    PORT_RELATED_ACTIVITIES: "Activités portuaires",
    OTHER: "Autre",
    SOCIAL_PROTECTION: "Protection sociale",
    EXPLORATION_EXTRACTION_GAS_OIL: "Extraction de gaz et de pétrole",
    ENVIRONMENT: "Environnement",
    EXPLORATION_EXTRACTION_COAL_OTHER_SOLID_FUEL: "Prospection et extraction de charbon et d'autres combustibles solides",
    URBAN_RAILWAY_OR_BUS_SERVICES: "Services de chemin de fer urbain ou d'autobus",
    ECONOMIC_AFFAIRS: "Affaires économiques",
    RCR: "Loisirs, culture et culte",
    HC_AM: "Logement et équipements collectifs",
    PUB_OS: "Ordre et sécurité publics",
    ECON_AFF: "Affaires économiques",
    ENV_PRO: "Protection de l’environnement",
    SOC_PRO: "Protection sociale",
    GEN_PUB: "Services d’administration générale",
    POST: "Services postaux",
    AIRPORT: "Activités aéroportuaires",
    GAS_OIL: "Extraction de gaz ou de pétrole",
    URTTB: "Services de chemin de fer urbain, de tramway, de trolleybus ou d’autobus",
    SOLID_FUEL: "Prospection ou extraction de charbon ou d’autres combustibles solides",
    RAIL: "Services de chemin de fer",
    PORT: "Activités portuaires",
    GAS_HEAT: "Production, transport ou distribution de gaz ou de chaleur",

}
