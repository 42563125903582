import { FetchBaseQueryError, skipToken } from "@reduxjs/toolkit/query";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { SerializedError } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import { GetUserParams, updateUser, useGetUserQuery, UserState } from "./slices/userSlice";
import { useEffect } from "react";
import { Language, LanguageParser } from "../consts/languages";
import { apiSlice } from "./slices/apiSlice";

export interface UserInStore {
  user?: UserState;
  isLoading: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
}

export function RaiseUser(): UserInStore {
    const dispatch = useAppDispatch();
    const {i18n} = useTranslation();
    // check if the user has a token already
    const authState = useAppSelector((state) => state.login);
    const userInStore: UserState = useAppSelector((state) => state.user);
    // only define get user params if a) we have an auth state already (we've got a token),
    // and b) we don't have a user state yet (we haven't done the getUser call already)
    // console.log("User id: " + authState.userID);
    let userNeedsToBeFetched = userInStore.uuid === undefined;
    let getUserParams: GetUserParams | undefined = (authState.userID && userNeedsToBeFetched) ? {userID:authState.userID} : undefined;
    const {data, isLoading, isSuccess, error, isFetching } = useGetUserQuery(getUserParams ?? skipToken); // skip query if params not defined

    useEffect(() => {
        //!(isLoading ||isFetching) is needed to avoid issues where the old user sticks
        if (userNeedsToBeFetched && isSuccess && data && !(isLoading ||isFetching)) {
            //interactions are fetched via the user so we might as well add everything to the store at once
            dispatch(updateUser(data));
            //remove cached api data
            dispatch(apiSlice.internalActions.resetApiState());
        }
    }, [userNeedsToBeFetched, isSuccess, data, dispatch])

    useEffect(() => {
        if (userInStore.locale) {
            i18n.changeLanguage(userInStore.locale);
        }
    }, [i18n, userInStore]);
    return {
        user: userInStore,
        isLoading: isLoading,
        error: error
    }
}

export function UserLanguage(i18nLanguageParam: string): Language {
    const languageIso = i18nLanguageParam.substring(0, 2).toUpperCase(); // just becomes EN
    let lang = LanguageParser.fromString(languageIso);
    if (lang == null) return Language.EN;
    return lang;
}
