import { ResourceKey } from "i18next";

export const Codes: ResourceKey = {

   code_SERVICES:"Services",
   code_WORKS:"Travaux",
   code_SUPPLIES:"Livraisons",
   // Procedure types
   code_OPEN: "Procédure ouverte",
   code_RESTRICTED: "Procédure restreinte",
   code_PT_COMPETITIVE_NEGOTIATION: "Procédure concurrentielle avec négociation",
   code_PT_COMPETITIVE_DIALOGUE: "Dialogue compétitif",
   code_PT_INNOVATION_PARTNERSHIP: "Partenariat d'innovation",
   code_INVOLVING_NEGOTIATION: "Procédure avec négociation",
   code_PT_NEGOTIATED_WITH_PRIOR_CALL: "Procédure négociée avec appel à la concurrence préalable",
   code_NEGOTIATED_WITHOUT_PUBLICATION: "Procédure négociée sans appel à la concurrence préalable",
   code_PT_COMPETITIVE: "Procédure négociée sans publication préalable (avec publication dans le Free Market) ",
   code_OTHER: "Autre",
   code_PT_OPEN: "Procédure ouverte",
   code_PT_RESTRICTED: "Procédure restreinte",
   code_PT_INVOLVING_NEGOTIATION: "Procédure impliquant des négociations",
   code_NEGOTIATED: "Procédure impliquant des négociations",
   code_PT_NEGOTIATED_CHOICE: "Procédure impliquant des négociations",
   code_UNKNOWN: "Inconnu",
   code_INNOVATION: "Partenariat d’innovation",
   code_NEG_WO_CALL: "Négociée sans mise en concurrence préalable",
   code_NEG_W_CALL: "Négociée avec publication préalable d’un appel à la concurrence / concurrentielle avec négociation",
   code_COMP_TEND: "Appel d’offres compétitive",
   code_OTH_SINGLE: "Autre procédure en une seule étape",
   code_COMP_DIAL: "Dialogue compétitif",
   code_OTH_MULT: "Autre procédure en plusieurs étapes",
   //MuncipalDecisionTypes
   code_ANNOUNCEMENT: "Annonce",
   code_AWARD: "Attribution",
   // Accreditations
   code_A:"Entreprises de dragage",
   code_A1:"Renflouage de bateaux et enlèvement d’épaves",
   code_B:"Entreprises de travaux hydrauliques",
   code_B1:"Curage de cours d’eau",
   code_C:"Entreprises générales de travaux routiers",
   code_C1:"Travaux d’égouts courants",
   code_C2:"Distribution d’eau et pose de canalisations diverses",
   code_C3:"Signalisation non-électrique des voies de communication, dispositifs de sécurité, clôtures et écrans de tout type, non électriques",
   code_C5:"Revêtements hydrocarbonés et enduisages",
   code_C6:"Pose en tranchées de câbles électriques d’énergie et de télécommunication, sans connexion",
   code_C7:"Fonçages horizontaux de tuyaux pour câbles et canalisations",
   code_D:"Entreprises générales de bâtiments",
   code_D1:"Tous travaux de gros oeuvre et de mise sous toit de bâtiments",
   code_D4:"Isolation acoustique ou thermique, cloisons légères, faux plafonds et faux planchers préfabriqués ou non",
   code_D5:"Menuiserie générale, charpentes et escaliers en bois",
   code_D6:"Marbrerie et taille de pierres",
   code_D7:"Ferronnerie",
   code_D8:"Couvertures de toiture asphaltiques ou similaires, travaux d’étanchéité",
   code_D10:"Carrelages",
   code_D11:"Plafonnage, crépissage",
   code_D12:"Couvertures non métalliques et non asphaltiques",
   code_D13:"Peinture",
   code_D14:"Vitrerie",
   code_D15:"Parquetage",
   code_D16:"Installations sanitaires et installations de chauffage au gaz par appareils individuels",
   code_D17:"Chauffage central, installations thermiques",
   code_D18:"Ventilation, chauffage à air chaud, conditionnement d’air",
   code_D20:"Menuiserie métallique",
   code_D21:"Ravalement et remise en état de façades",
   code_D22:"Couvertures métalliques de toiture et zinguerie",
   code_D23:"Restauration par des artisans",
   code_D24:"Restauration de monuments",
   code_D25:"Revêtements de murs et de sols, autres que la marbrerie, le parquetage et les carrelages",
   code_D29:"Chapes de sols et revêtements de sols industriels",
   code_E:"Entreprises de génie civil",
   code_E1:"Egouts collecteurs",
   code_E2:"Fondations profondes sur pieux, rideaux de palplanches, murs emboués",
   code_E4:"Fonçages horizontaux d’éléments constitutifs d’ouvrages d’art",
   code_F:"Entreprises de constructions métalliques",
   code_F1:"Travaux de montage et de démontage (sans fournitures)",
   code_F2:"Construction de charpentes métalliques",
   code_F3:"Peinture industrielle",
   code_G:"Entreprises de terrassements",
   code_G1:"Travaux de forage, de sondage et d’injection",
   code_G2:"Travaux de drainage",
   code_G3:"Plantations",
   code_G4:"Revêtements spéciaux pour terrains de sport",
   code_G5:"Travaux de démolition",
   code_H:"Entreprises de voies ferrées",
   code_H1:"Travaux de soudure de rails",
   code_H2:"Pose de caténaires",
   code_K:"Entreprises d’équipements mécaniques",
   code_K1:"Equipements d’ouvrages d’art ou de mécanique industrielle",
   code_K2:"Installations d’engins de manutention et de levage (grues, ponts roulants…)",
   code_K3:"Equipements oléomécaniques",
   code_L:"Entreprises d’installations d’équipements hydromécaniques",
   code_L1:"Installations de tuyauteries",
   code_L2:"Equipements de stations de pompage ou de turbinage",
   code_M:"Entreprises d’installations d’équipements électroniques",
   code_M1:"Equipements électroniques à fréquence industrielle ou élevée y compris équipements des stations d’alimentation",
   code_N:"Entreprise d’installations de transport dans les bâtiments",
   code_N1:"Ascenseurs, monte-charges, escaliers et trottoirs roulants",
   code_N2:"Transports par gaines ou tubes d’objets, de documents ou de marchandises (pneumatique, mécanique…)",
   code_P1:"Installations électriques des bâtiments, y compris installations de groupes électrogènes, équipements de détection d’incendie et de vol, télétransmissions dans les bâtiments et leur périphérie et installations ou équipements de téléphonie mixte",
   code_P2:"Installations électriques et électromécaniques d’ouvrages d’art ou industriels et installations électriques extérieures",
   code_P3:"Installations de lignes aériennes de transport électrique",
   code_P4:"Installations électriques d’ouvrages portuaires",
   code_S:"Entreprises d’installation d’équipements de télétransmission et de gestion de données",
   code_S1:"Equipements de téléphonie et de télégraphie",
   code_S2:"Equipements de télécommande, télécontrôle et télémesure",
   code_S3:"Equipements de transmission de radio et de télévision, radar et antennes",
   code_S4:"Equipements d’informatique et de régulation de processus",
   code_T2:"Paratonnerres, antennes de réception",
   code_T3:"Equipements frigorifiques",
   code_T4:"Equipements de buanderies et de grandes cuisines",
   code_T6:"Equipements d’abattoirs",
   code_U:"Installations pour traitement des immondices",
   code_V:"Installations d’épuration d’eau",
   code_03100000:"Produits agricoles et produits de l'horticulture",
   code_03110000:"Produits agricoles, produits de la culture maraîchère et de l'horticulture commerciale",
   code_03111000:"Graines",
   code_03111100:"Graines de soja",
   code_03111200:"Graines d'arachide",
   code_03111300:"Graines de tournesol",
   code_03111400:"Graines de coton",
   code_03111500:"Graines de sésame",
   code_03111600:"Graines de moutarde",
   code_03111700:"Graines de légumes",
   code_03111800:"Graines de fruits",
   code_03111900:"Graines de fleurs",
   code_03112000:"Tabacs non fabriqués",
   code_03113000:"Plantes utilisées pour la fabrication du sucre",
   code_03113100:"Betteraves sucrières",
   code_03113200:"Canne à sucre",
   code_03114000:"Paille et plantes fourragères",
   code_03114100:"Paille",
   code_03114200:"Plantes fourragères",
   code_03115000:"Matières premières végétales",
   code_03115100:"Matières premières végétales destinées à l'industrie textile",
   code_03115110:"Coton",
   code_03115120:"Jute",
   code_03115130:"Lin",
   code_03116000:"Caoutchouc naturel, latex naturel et produits connexes",
   code_03116100:"Caoutchouc naturel",
   code_03116200:"Latex naturel",
   code_03116300:"Produits en latex",
   code_03117000:"Plantes à usage spécifique",
   code_03117100:"Plantes utilisées en parfumerie ou en pharmacie, ou plantes à usage insecticide ou à usage similaire",
   code_03117110:"Plantes utilisées en parfumerie",
   code_03117120:"Plantes utilisées en pharmacie",
   code_03117130:"Plantes à usage insecticide",
   code_03117140:"Plantes à usage fongicide ou à usage similaire",
   code_03117200:"Graines de plantes à usage spécifique",
   code_03120000:"Produits horticoles et produits de pépinières",
   code_03121000:"Produits horticoles",
   code_03121100:"Plantes vivantes, bulbes, racines, boutures et greffons",
   code_03121200:"Fleurs coupées",
   code_03121210:"Compositions florales",
   code_03130000:"Plantes pour boissons et épices",
   code_03131000:"Plantes pour boissons",
   code_03131100:"Grains de café",
   code_03131200:"Théiers",
   code_03131300:"Maté",
   code_03131400:"Fèves de cacao",
   code_03132000:"Épices brutes",
   code_03140000:"Produits animaux et produits connexes",
   code_03141000:"Sperme de taureau",
   code_03142000:"Produits de l'élevage",
   code_03142100:"Miel naturel",
   code_03142200:"Escargots",
   code_03142300:"Produits alimentaires d'origine animale",
   code_03142400:"Cires",
   code_03142500:"Œufs",
   code_03143000:"Produits de la polyculture",
   code_03144000:"Fournitures pour l'agriculture",
   code_03200000:"Céréales, pommes de terre, légumes, fruits et noix",
   code_03210000:"Céréales et pommes de terre",
   code_03211000:"Céréales",
   code_03211100:"Blé",
   code_03211110:"Blé dur",
   code_03211120:"Blé tendre",
   code_03211200:"Maïs",
   code_03211300:"Riz",
   code_03211400:"Orge",
   code_03211500:"Seigle",
   code_03211600:"Avoine",
   code_03211700:"Malt",
   code_03211900:"Produits à graines",
   code_03212000:"Pommes de terre et légumes secs",
   code_03212100:"Pommes de terre",
   code_03212200:"Légumes secs à cosse et à gousse",
   code_03212210:"Légumes secs à cosse",
   code_03212211:"Lentilles",
   code_03212212:"Pois chiches",
   code_03212213:"Pois secs",
   code_03212220:"Légumes à gousse",
   code_03220000:"Légumes, fruits et noix",
   code_03221000:"Légumes",
   code_03221100:"Légumes-racines et légumes-tubercules",
   code_03221110:"Légumes-racines",
   code_03221111:"Betteraves",
   code_03221112:"Carottes",
   code_03221113:"Oignons",
   code_03221114:"Navets",
   code_03221120:"Légumes-tubercules",
   code_03221200:"Légumes-fruits",
   code_03221210:"Haricots",
   code_03221211:"Fèves",
   code_03221212:"Haricots verts",
   code_03221213:"Haricots d'Espagne",
   code_03221220:"Pois",
   code_03221221:"Petits pois",
   code_03221222:"Mange-tout",
   code_03221230:"Poivrons",
   code_03221240:"Tomates",
   code_03221250:"Courgettes",
   code_03221260:"Champignons",
   code_03221270:"Concombres",
   code_03221300:"Légumes à feuilles",
   code_03221310:"Laitues",
   code_03221320:"Feuilles de salade",
   code_03221330:"Artichauts",
   code_03221340:"Épinards",
   code_03221400:"Choux",
   code_03221410:"Choux blancs",
   code_03221420:"Choux-fleurs",
   code_03221430:"Brocolis",
   code_03221440:"Choux de Bruxelles",
   code_03222000:"Fruits et fruits à coque",
   code_03222100:"Fruits et fruits à coque tropicaux",
   code_03222110:"Fruits tropicaux",
   code_03222111:"Bananes",
   code_03222112:"Ananas",
   code_03222113:"Mangues",
   code_03222114:"Dattes",
   code_03222115:"Raisins secs",
   code_03222116:"Figues",
   code_03222117:"Avocats",
   code_03222118:"Kiwis",
   code_03222120:"Noix de coco",
   code_03222200:"Agrumes",
   code_03222210:"Citrons",
   code_03222220:"Oranges",
   code_03222230:"Pamplemousses",
   code_03222240:"Tangerines",
   code_03222250:"Citrons verts",
   code_03222300:"Fruits non tropicaux",
   code_03222310:"Baies",
   code_03222311:"Raisins secs de Corinthe",
   code_03222312:"Groseilles à maquereau",
   code_03222313:"Fraises",
   code_03222314:"Framboises",
   code_03222315:"Canneberges",
   code_03222320:"Pommes, poires et coings",
   code_03222321:"Pommes",
   code_03222322:"Poires",
   code_03222323:"Coings",
   code_03222330:"Fruits à noyau",
   code_03222331:"Abricots",
   code_03222332:"Pêches",
   code_03222333:"Cerises",
   code_03222334:"Prunes",
   code_03222340:"Raisins",
   code_03222341:"Raisins de table",
   code_03222342:"Raisins de cuve",
   code_03222400:"Olives",
   code_03300000:"Produits agricoles, de la chasse et de la pêche",
   code_03310000:"Poissons, crustacés et produits aquatiques",
   code_03311000:"Poissons",
   code_03311100:"Poissons plats",
   code_03311110:"Sole",
   code_03311120:"Plie",
   code_03311200:"Poissons de la famille des gatidés",
   code_03311210:"Morue",
   code_03311220:"Lieu",
   code_03311230:"Merlu",
   code_03311240:"Haddock",
   code_03311300:"Hareng",
   code_03311400:"Thon",
   code_03311500:"Merlan",
   code_03311600:"Blanquet",
   code_03311700:"Saumon",
   code_03312000:"Crustacés",
   code_03312100:"Huîtres",
   code_03312200:"Coquillages",
   code_03312300:"Invertébrés aquatiques",
   code_03313000:"Produits aquatiques",
   code_03313100:"Corail ou produits similaires",
   code_03313200:"Éponges naturelles",
   code_03313300:"Algues marines",
   code_03313310:"Algues",
   code_03320000:"Bétail, cheptel et petits animaux",
   code_03321000:"Bétail",
   code_03321100:"Bovins",
   code_03321200:"Veaux",
   code_03322000:"Cheptel",
   code_03322100:"Moutons",
   code_03322200:"Chèvres",
   code_03322300:"Chevaux",
   code_03323000:"Suidés",
   code_03324000:"Volaille vivante",
   code_03325000:"Petits animaux",
   code_03325100:"Lapins",
   code_03325200:"Lièvres",
   code_03330000:"Produits des animaux d'élévage",
   code_03331000:"Lait frais de brebis et de chèvre",
   code_03331100:"Lait de brebis",
   code_03331200:"Lait de chèvre",
   code_03332000:"Laine et poils",
   code_03332100:"Laine de tonte",
   code_03332200:"Poil",
   code_03333000:"Lait de vache frais",
   code_03340000:"Marques auriculaires pour animaux",
   code_03341000:"Marques auriculaires pour bovins",
   code_03400000:"Produits de la sylviculture et de l'exploitation forestière",
   code_03410000:"Bois",
   code_03411000:"Bois de conifères",
   code_03412000:"Bois tropical",
   code_03413000:"Bois de chauffage",
   code_03414000:"Bois brut",
   code_03415000:"Bois tendre",
   code_03416000:"Chutes de bois",
   code_03417000:"Copeaux de bois",
   code_03417100:"Sciure de bois",
   code_03418000:"Grumes",
   code_03418100:"Bois dur",
   code_03419000:"Bois d'œuvre",
   code_03419100:"Produits du bois",
   code_03419200:"Bois de mine",
   code_03420000:"Gommes",
   code_03421000:"Baumes",
   code_03422000:"Laque",
   code_03430000:"Liège",
   code_03431000:"Liège naturel",
   code_03432000:"Vannerie",
   code_03432100:"Sparterie",
   code_03440000:"Produits de la sylviculture",
   code_03441000:"Plantes, graminées, mousses ou lichens d'ornement",
   code_03450000:"Produits de pépinière",
   code_03451000:"Plantes",
   code_03451100:"Plantes à repiquer",
   code_03451200:"Bulbes de fleurs",
   code_03451300:"Arbustes",
   code_03452000:"Arbres",
   code_03460000:"Pâte à papier",
   code_03461000:"Pâte de bois",
   code_03461100:"Pâte de bois chimique",
   code_09000000:"Produits pétroliers, combustibles, électricité et autres sources d'énergie",
   code_09100000:"Combustibles",
   code_09110000:"Combustibles solides",
   code_09111000:"Charbon et combustibles à base de charbon",
   code_09111100:"Charbon",
   code_09111200:"Combustibles dérivés du charbon",
   code_09111210:"Charbon maigre",
   code_09111220:"Briquettes",
   code_09111300:"Combustibles fossiles",
   code_09111400:"Combustibles à base de bois",
   code_09112000:"Lignite et tourbe",
   code_09112100:"Lignite",
   code_09112200:"Tourbe",
   code_09113000:"Coke",
   code_09120000:"Combustibles gazeux",
   code_09121000:"Gaz de houille, gaz de ville ou gaz similaires",
   code_09121100:"Gaz de houille ou gaz similaires",
   code_09121200:"Gaz de ville",
   code_09122000:"Propane et butane",
   code_09122100:"Gaz propane",
   code_09122110:"Propane liquéfié",
   code_09122200:"Gaz butane",
   code_09122210:"Butane liquéfié",
   code_09123000:"Gaz naturel",
   code_09130000:"Pétrole et distillats",
   code_09131000:"Kérosène aviation",
   code_09131100:"Carburéacteurs de type kérosène",
   code_09132000:"Essence",
   code_09132100:"Essence sans plomb",
   code_09132200:"Essence au plomb",
   code_09132300:"Essence à l'éthanol",
   code_09133000:"Gaz de pétrole liquéfié (LPG)",
   code_09134000:"Gasoils",
   code_09134100:"Huile diesel",
   code_09134200:"Carburant diesel",
   code_09134210:"Carburant diesel (02)",
   code_09134220:"Carburant diesel (EN 590)",
   code_09134230:"Biodiesel",
   code_09134231:"Biodiesel (B20)",
   code_09134232:"Biodiesel (B100)",
   code_09135000:"Mazout",
   code_09135100:"Fioul domestique",
   code_09135110:"Huiles combustibles à basse teneur en soufre",
   code_09200000:"Produits pétroliers, charbon et huiles",
   code_09210000:"Préparations lubrifiantes",
   code_09211000:"Huiles lubrifiantes et agents lubrifiants",
   code_09211100:"Huiles pour moteurs",
   code_09211200:"Huiles pour compresseurs",
   code_09211300:"Huiles pour turbines",
   code_09211400:"Huiles pour engrenages",
   code_09211500:"Huiles pour réducteurs",
   code_09211600:"Huiles destinées aux systèmes hydrauliques et à d'autres usages",
   code_09211610:"Liquides à usage hydraulique",
   code_09211620:"Huiles de démoulage",
   code_09211630:"Huiles anticorrosives",
   code_09211640:"Huiles utilisées comme isolants électriques",
   code_09211650:"Liquides de frein",
   code_09211700:"Huiles blanches et paraffines liquides",
   code_09211710:"Huiles blanches",
   code_09211720:"Paraffines liquides",
   code_09211800:"Huiles minérales et préparations dérivées du pétrole",
   code_09211810:"Huiles légères",
   code_09211820:"Huiles minérales",
   code_09211900:"Huiles lubrifiantes pour traction",
   code_09220000:"Vaseline, cires de pétrole et essences spéciales",
   code_09221000:"Vaseline et cire de pétrole",
   code_09221100:"Vaseline",
   code_09221200:"Paraffine",
   code_09221300:"Cire de pétrole",
   code_09221400:"Résidus de pétrole",
   code_09222000:"Essences spéciales",
   code_09222100:"White-spirit",
   code_09230000:"Pétrole (brut)",
   code_09240000:"Huile et produits dérivés du charbon",
   code_09241000:"Schiste bitumineux ou kérobitumeux",
   code_09242000:"Produits dérivés du charbon",
   code_09242100:"Huile lourde de houille",
   code_09300000:"Électricité, chauffage, énergie solaire et nucléaire",
   code_09310000:"Électricité",
   code_09320000:"Vapeur, eau chaude et produits connexes",
   code_09321000:"Eau chaude",
   code_09322000:"Vapeur",
   code_09323000:"Chauffage urbain",
   code_09324000:"Chauffage fourni à grande distance",
   code_09330000:"Énergie solaire",
   code_09331000:"Panneaux solaires",
   code_09331100:"Capteurs solaires pour la production de chaleur",
   code_09331200:"Modules solaires photovoltaïques",
   code_09332000:"Installation solaire",
   code_09340000:"Combustibles nucléaires",
   code_09341000:"Uranium",
   code_09342000:"Plutonium",
   code_09343000:"Matériaux radioactifs",
   code_09344000:"Radio-isotopes",
   code_14000000:"Produits d'exploitation des mines, métaux de base et produits connexes",
   code_14200000:"Sable et argile",
   code_14210000:"Gravier, sable, pierre concassée et agrégats",
   code_14211000:"Sable",
   code_14211100:"Sable naturel",
   code_14212000:"Granulés, gravillons, sable fin, cailloux, gravier, pierraille et pierre concassée, mélanges de pierres, de grave et autres agrégats",
   code_14212100:"Cailloux et gravier",
   code_14212110:"Cailloux",
   code_14212120:"Gravier",
   code_14212200:"Agrégats",
   code_14212210:"Grave",
   code_14212300:"Pierraille et pierre concassée",
   code_14212310:"Ballast",
   code_14212320:"Granit concassé",
   code_14212330:"Basalte concassé",
   code_14212400:"Terre",
   code_14212410:"Terre végétale",
   code_14212420:"Sous-sol",
   code_14212430:"Gravillons",
   code_14213000:"Macadam, tarmacadam et sable bitumineux",
   code_14213100:"Macadam",
   code_14213200:"Tarmacadam",
   code_14213300:"Sable bitumineux",
   code_14220000:"Argile et kaolin",
   code_14221000:"Argile",
   code_14222000:"Kaolin",
   code_14300000:"Produits inorganiques chimiques et engrais minéraux",
   code_14310000:"Engrais minéraux",
   code_14311000:"Calcium naturel, phosphate aluminocalcique et sels de potassium naturel brut",
   code_14311100:"Calcium naturel",
   code_14311200:"Phosphates aluminocalciques",
   code_14311300:"Sels de potassium naturel brut",
   code_14312000:"Pyrites de fer",
   code_14312100:"Pyrites de fer non grillées",
   code_14320000:"Produits inorganiques chimiques",
   code_14400000:"Sel et chlorure de sodium pur",
   code_14410000:"Sel gemme",
   code_14420000:"Sel marin",
   code_14430000:"Sel concentré par évaporation et chlorure de sodium pur",
   code_14450000:"Saumure",
   code_14500000:"Produits connexes d'exploitation de mines et de carrières",
   code_14520000:"Pierres précieuses et semi-précieuses, pierre ponce, émeri, abrasifs naturels, autres minéraux et métaux précieux",
   code_14521000:"Pierres précieuses et semi-précieuses",
   code_14521100:"Pierres précieuses",
   code_14521140:"Poussière ou poudre de pierres précieuses",
   code_14521200:"Pierres semi-précieuses",
   code_14521210:"Poussière ou poudre de pierres semi-précieuses",
   code_14522000:"Diamants industriels, pierre ponce, émeri et autres abrasifs naturels",
   code_14522100:"Pierre ponce",
   code_14522200:"Diamants industriels",
   code_14522300:"Émeri",
   code_14522400:"Abrasifs naturels",
   code_14523000:"Minéraux, métaux précieux connexes et produits associés",
   code_14523100:"Minéraux",
   code_14523200:"Or",
   code_14523300:"Argent",
   code_14523400:"Platine",
   code_14600000:"Minerais de métaux et alliages",
   code_14610000:"Minerais de métaux",
   code_14611000:"Minerais de fer",
   code_14612000:"Minerais de métaux non ferreux",
   code_14612100:"Minerais de cuivre",
   code_14612200:"Minerais de nickel",
   code_14612300:"Minerais d'aluminium",
   code_14612400:"Minerais de métaux précieux",
   code_14612500:"Minerais de plomb",
   code_14612600:"Minerais de zinc",
   code_14612700:"Minerais d'étain",
   code_14613000:"Minerais d'uranium et de thorium",
   code_14613100:"Minerais d'uranium",
   code_14613200:"Minerais de thorium",
   code_14614000:"Minerais divers",
   code_14620000:"Alliages",
   code_14621000:"Ferroalliages",
   code_14621100:"Ferroalliages hors CECA",
   code_14621110:"Ferromanganèse",
   code_14621120:"Ferrochrome",
   code_14621130:"Ferronickel",
   code_14622000:"Acier",
   code_14630000:"Scories, laitier, déchets et débris ferreux",
   code_14700000:"Métaux de base",
   code_14710000:"Fer, plomb, zinc, étain et cuivre",
   code_14711000:"Fer",
   code_14711100:"Fonte brute",
   code_14712000:"Plomb",
   code_14713000:"Zinc",
   code_14714000:"Étain",
   code_14715000:"Cuivre",
   code_14720000:"Aluminium, nickel, scandium, titane et vanadium",
   code_14721000:"Aluminium",
   code_14721100:"Oxyde d'aluminium",
   code_14722000:"Nickel",
   code_14723000:"Scandium",
   code_14724000:"Titane",
   code_14725000:"Vanadium",
   code_14730000:"Chrome, manganèse, cobalt, yttrium et zirconium",
   code_14731000:"Chrome",
   code_14732000:"Manganèse",
   code_14733000:"Cobalt",
   code_14734000:"Yttrium",
   code_14735000:"Zirconium",
   code_14740000:"Molybdène, technétium, ruthénium et rhodium",
   code_14741000:"Molybdène",
   code_14742000:"Technétium",
   code_14743000:"Ruthénium",
   code_14744000:"Rhodium",
   code_14750000:"Cadmium, lutétium, hafnium, tantale et tungstène",
   code_14751000:"Cadmium",
   code_14752000:"Lutétium",
   code_14753000:"Hafnium",
   code_14754000:"Tantale",
   code_14755000:"Tungstène",
   code_14760000:"Iridium, gallium, indium, thallium et barium",
   code_14761000:"Iridium",
   code_14762000:"Gallium",
   code_14763000:"Indium",
   code_14764000:"Thallium",
   code_14765000:"Barium",
   code_14770000:"Césium, strontium, rubidium et calcium",
   code_14771000:"Césium",
   code_14772000:"Strontium",
   code_14773000:"Rubidium",
   code_14774000:"Calcium",
   code_14780000:"Potassium, magnésium, sodium et lithium",
   code_14781000:"Potassium",
   code_14782000:"Magnésium",
   code_14783000:"Sodium",
   code_14784000:"Lithium",
   code_14790000:"Niobium, osmium, rhénium et palladium",
   code_14791000:"Niobium",
   code_14792000:"Osmium",
   code_14793000:"Rhénium",
   code_14794000:"Palladium",
   code_14800000:"Produits minéraux non métalliques divers",
   code_14810000:"Produits abrasifs",
   code_14811000:"Pierres à meuler, pierres à broyer et meules",
   code_14811100:"Pierres à meuler",
   code_14811200:"Pierres à broyer",
   code_14811300:"Meules",
   code_14812000:"Poudre ou grains abrasifs",
   code_14813000:"Corindon artificiel",
   code_14814000:"Graphite artificiel",
   code_14820000:"Verre",
   code_14830000:"Fibres de verre",
   code_14900000:"Matières premières de récupération",
   code_14910000:"Matières premières métalliques de récupération",
   code_14920000:"Matières premières non métalliques de récupération",
   code_14930000:"Cendres et résidus contenant des métaux",
   code_15000000:"Produits alimentaires, boissons, tabac et produits connexes",
   code_15100000:"Produits de l'élevage, viande et produits à base de viande",
   code_15110000:"Viande",
   code_15111000:"Viande bovine",
   code_15111100:"Viande de bœuf",
   code_15111200:"Viande de veau",
   code_15112000:"Volaille",
   code_15112100:"Volaille fraîche",
   code_15112110:"Oies",
   code_15112120:"Dindes",
   code_15112130:"Poulets",
   code_15112140:"Canards",
   code_15112300:"Foies de volaille",
   code_15112310:"Foie gras",
   code_15113000:"Viande de porc",
   code_15114000:"Abats",
   code_15115000:"Viande d'agneau et de mouton",
   code_15115100:"Viande d'agneau",
   code_15115200:"Viande de mouton",
   code_15117000:"Viande de chèvre",
   code_15118000:"Viande de cheval, d'âne, de mule ou de bardot",
   code_15118100:"Viande de cheval",
   code_15118900:"Viande d'âne, de mule ou de bardot",
   code_15119000:"Viandes diverses",
   code_15119100:"Viande de lapin",
   code_15119200:"Viande de lièvre",
   code_15119300:"Gibier",
   code_15119400:"Cuisses de grenouilles",
   code_15119500:"Pigeons",
   code_15119600:"Chair de poisson",
   code_15130000:"Produits à base de viande",
   code_15131000:"Conserves et préparations à base de viande",
   code_15131100:"Produits à base de chair à saucisse",
   code_15131110:"Chair à saucisse",
   code_15131120:"Charcuterie",
   code_15131130:"Saucisses",
   code_15131134:"Boudin noir et autres saucisses à base de sang",
   code_15131135:"Saucisses de volaille",
   code_15131200:"Viande séchée, salée, fumée ou assaisonnée",
   code_15131210:"Jambon fumé",
   code_15131220:"Lard",
   code_15131230:"Salami",
   code_15131300:"Préparations à base de foie",
   code_15131310:"Pâté",
   code_15131320:"Préparations à base de foie d'oie ou de canard",
   code_15131400:"Produits à base de porc",
   code_15131410:"Jambon",
   code_15131420:"Boulettes de viande",
   code_15131490:"Plats préparés de porc",
   code_15131500:"Produits à base de volaille",
   code_15131600:"Produits à base de bœuf et de veau",
   code_15131610:"Boulettes de bœuf",
   code_15131620:"Viande de bœuf hachée",
   code_15131640:"Steaks hachés de bœuf",
   code_15131700:"Préparations à base de viande",
   code_15200000:"Poisson préparé et conserves de poisson",
   code_15210000:"Filets de poisson, foie et œufs ou laitance de poisson",
   code_15211000:"Filets de poisson",
   code_15211100:"Filets de poisson frais",
   code_15212000:"Œufs ou laitance de poisson",
   code_15213000:"Foie de poisson",
   code_15220000:"Poisson, filets de poisson et autre chair de poisson congelés",
   code_15221000:"Poisson congelé",
   code_15229000:"Produits congelés à base de poisson",
   code_15230000:"Poisson séché ou salé; poisson en saumure; poisson fumé",
   code_15231000:"Poisson séché",
   code_15232000:"Poisson salé",
   code_15233000:"Poisson en saumure",
   code_15234000:"Poisson fumé",
   code_15235000:"Conserves de poisson",
   code_15240000:"Poisson en conserve ou en boîte et autres poissons préparés ou en conserve",
   code_15241000:"Poisson pané ou autrement enrobé, en conserve ou en boîte",
   code_15241100:"Saumon en conserve",
   code_15241200:"Hareng préparé ou en conserve",
   code_15241300:"Sardines",
   code_15241400:"Thon en conserve",
   code_15241500:"Maquereaux",
   code_15241600:"Anchois",
   code_15241700:"Bâtonnets de poisson",
   code_15241800:"Préparations à base de poisson pané ou autrement enrobé",
   code_15242000:"Plats préparés de poisson",
   code_15243000:"Préparations à base de poisson",
   code_15244000:"Caviar et œufs de poissons",
   code_15244100:"Caviar",
   code_15244200:"Œufs de poissons",
   code_15250000:"Fruits de mer",
   code_15251000:"Crustacés congelés",
   code_15252000:"Crustacés préparés ou en conserve",
   code_15253000:"Produits à base de mollusques",
   code_15300000:"Fruits, légumes et produits connexes",
   code_15310000:"Pommes de terre et produits à base de pomme de terre",
   code_15311000:"Pommes de terre congelées",
   code_15311100:"Chips et pommes frites",
   code_15311200:"Pommes de terre coupées en dés, en tranches et autres pommes de terre congelées",
   code_15312000:"Produits à base de pommes de terre",
   code_15312100:"Purée de pommes de terre instantanée",
   code_15312200:"Pommes de terre préfrites",
   code_15312300:"Pommes chips",
   code_15312310:"Pommes chips aromatisées",
   code_15312400:"Produits apéritifs à base de pommes de terre",
   code_15312500:"Croquettes de pommes de terre",
   code_15313000:"Pommes de terre transformées",
   code_15320000:"Jus de fruits et de légumes",
   code_15321000:"Jus de fruits",
   code_15321100:"Jus d'orange",
   code_15321200:"Jus de pamplemousse",
   code_15321300:"Jus de citron",
   code_15321400:"Jus d'ananas",
   code_15321500:"Jus de raisin",
   code_15321600:"Jus de pomme",
   code_15321700:"Mélanges de jus non concentrés",
   code_15321800:"Jus concentrés",
   code_15322000:"Jus de légumes",
   code_15322100:"Jus de tomate",
   code_15330000:"Fruits et légumes transformés",
   code_15331000:"Légumes transformés",
   code_15331100:"Légumes frais ou congelés",
   code_15331110:"Légumes-racines transformés",
   code_15331120:"Légumes-tubercules transformés",
   code_15331130:"Haricots, pois, poivrons, tomates et autres légumes",
   code_15331131:"Haricots transformés",
   code_15331132:"Pois transformés",
   code_15331133:"Pois cassés",
   code_15331134:"Tomates transformées",
   code_15331135:"Champignons transformés",
   code_15331136:"Poivrons transformés",
   code_15331137:"Germes de soja",
   code_15331138:"Truffes",
   code_15331140:"Légumes à feuilles et choux",
   code_15331142:"Choux transformés",
   code_15331150:"Légumes à cosse transformés",
   code_15331170:"Légumes congelés",
   code_15331400:"Légumes en conserve et/ou en boîte",
   code_15331410:"Haricots à la sauce tomate",
   code_15331411:"Haricots blancs à la sauce tomate",
   code_15331420:"Tomates en conserve",
   code_15331423:"Tomates en boîte",
   code_15331425:"Purée de tomates",
   code_15331427:"Concentré de tomates",
   code_15331428:"Sauce tomate",
   code_15331430:"Champignons en boîte",
   code_15331450:"Olives transformées",
   code_15331460:"Légumes en boîte",
   code_15331461:"Choucroute en boîte",
   code_15331462:"Pois en boîte",
   code_15331463:"Haricots écossés en boîte",
   code_15331464:"Haricots entiers en boîte",
   code_15331465:"Asperges en boîte",
   code_15331466:"Olives en boîte",
   code_15331470:"Maïs doux",
   code_15331480:"Légumes ayant subi un traitement de conservation temporaire",
   code_15331500:"Légumes conservés dans le vinaigre",
   code_15332000:"Fruits et fruits à coque transformés",
   code_15332100:"Fruits transformés",
   code_15332140:"Pommes transformées",
   code_15332150:"Poires transformées",
   code_15332160:"Bananes transformées",
   code_15332170:"Rhubarbe",
   code_15332180:"Melons",
   code_15332200:"Confitures et marmelades; gelées de fruits; purées et pâtes de fruits ou de fruits à coque",
   code_15332230:"Marmelades",
   code_15332231:"Marmelade d'oranges",
   code_15332232:"Marmelade de citrons",
   code_15332240:"Gelées de fruits",
   code_15332250:"Pâtes de fruits",
   code_15332260:"Pâtes de fruits à coque",
   code_15332261:"Beurre d'arachide",
   code_15332270:"Purées de fruits",
   code_15332290:"Confitures",
   code_15332291:"Confiture d'abricots",
   code_15332292:"Confiture de mûres",
   code_15332293:"Confiture de cassis",
   code_15332294:"Confiture de cerises",
   code_15332295:"Confiture de framboises",
   code_15332296:"Confiture de fraises",
   code_15332300:"Fruits à coque transformés",
   code_15332310:"Fruits à coque grillés ou salés",
   code_15332400:"Conserves de fruits",
   code_15332410:"Fruits secs",
   code_15332411:"Raisins secs de Corinthe transformés",
   code_15332412:"Raisins transformés",
   code_15332419:"Sultanines",
   code_15333000:"Sous-produits végétaux",
   code_15400000:"Huiles et graisses animales ou végétales",
   code_15410000:"Huiles et graisses animales ou végétales brutes",
   code_15411000:"Huiles animales ou végétales",
   code_15411100:"Huiles végétales",
   code_15411110:"Huile d'olive",
   code_15411120:"Huile de sésame",
   code_15411130:"Huile d'arachide",
   code_15411140:"Huile de coco",
   code_15411200:"Huile de cuisson",
   code_15411210:"Huile de friture",
   code_15412000:"Graisses",
   code_15412100:"Graisses animales",
   code_15412200:"Graisses végétales",
   code_15413000:"Résidus solides de graisses ou huiles végétales",
   code_15413100:"Tourteaux",
   code_15420000:"Huiles et graisses raffinées",
   code_15421000:"Huiles raffinées",
   code_15422000:"Graisses raffinées",
   code_15423000:"Graisses ou huiles hydrogénées ou estérifiées",
   code_15424000:"Cires végétales",
   code_15430000:"Graisses comestibles",
   code_15431000:"Margarine et préparations similaires",
   code_15431100:"Margarine",
   code_15431110:"Margarine liquide",
   code_15431200:"Pâtes à tartiner allégées ou à faible teneur en matières grasses",
   code_15500000:"Produits laitiers",
   code_15510000:"Lait et crème fraîche",
   code_15511000:"Lait",
   code_15511100:"Lait pasteurisé",
   code_15511200:"Lait stérilisé",
   code_15511210:"Lait UHT",
   code_15511300:"Lait écrémé",
   code_15511400:"Lait demi-écrémé",
   code_15511500:"Lait entier",
   code_15511600:"Lait condensé",
   code_15511700:"Lait en poudre",
   code_15512000:"Crème fraîche",
   code_15512100:"Crème fraîche liquide",
   code_15512200:"Crème fraîche épaisse",
   code_15512300:"Crème épaisse",
   code_15512900:"Crème fraîche à fouetter",
   code_15530000:"Beurre",
   code_15540000:"Fromages",
   code_15541000:"Fromage de table",
   code_15542000:"Fromage frais",
   code_15542100:"Cottage cheese",
   code_15542200:"Fromage à pâte molle",
   code_15542300:"Feta",
   code_15543000:"Fromage râpé, en poudre, bleu et autre fromage",
   code_15543100:"Fromage à pâte bleue",
   code_15543200:"Cheddar",
   code_15543300:"Fromage râpé",
   code_15543400:"Parmesan",
   code_15544000:"Fromage à pâte dure",
   code_15545000:"Fromage à tartiner",
   code_15550000:"Produits laitiers divers",
   code_15551000:"Yaourt et autres produits laitiers fermentés",
   code_15551300:"Yaourt",
   code_15551310:"Yaourt nature",
   code_15551320:"Yaourt aromatisé",
   code_15551500:"Babeurre",
   code_15552000:"Caséine",
   code_15553000:"Lactose ou sirop de lactose",
   code_15554000:"Petit-lait",
   code_15555000:"Crème glacée et produits similaires",
   code_15555100:"Crème glacée",
   code_15555200:"Sorbet",
   code_15600000:"Produits de la minoterie, amidon et produits amylacés",
   code_15610000:"Produits de la minoterie",
   code_15611000:"Riz décortiqué",
   code_15612000:"Farine de céréales ou farine végétale et produits connexes",
   code_15612100:"Farine de blé",
   code_15612110:"Farine complète",
   code_15612120:"Farine de boulangerie",
   code_15612130:"Farine sans levure",
   code_15612150:"Farine de pâtisserie",
   code_15612190:"Farine fermentante",
   code_15612200:"Farine de céréales",
   code_15612210:"Farine de maïs",
   code_15612220:"Farine de riz",
   code_15612300:"Farines végétales",
   code_15612400:"Mélanges utilisés pour la préparation de produits de boulangerie",
   code_15612410:"Préparations pour gâteaux",
   code_15612420:"Préparations à cuire au four",
   code_15612500:"Produits de boulangerie",
   code_15613000:"Produits à base de grains de céréales",
   code_15613100:"Gruaux",
   code_15613300:"Produits céréaliers",
   code_15613310:"Céréales pour petit déjeuner",
   code_15613311:"Flocons de maïs",
   code_15613313:"Muesli ou équivalent",
   code_15613319:"Blé soufflé",
   code_15613380:"Flocons d'avoine",
   code_15614000:"Riz transformé",
   code_15614100:"Riz à grains longs",
   code_15614200:"Riz usiné",
   code_15614300:"Brisures de riz",
   code_15615000:"Son",
   code_15620000:"Amidons et produits amylacés",
   code_15621000:"Huile de maïs",
   code_15622000:"Glucose et produits à base de glucose, de fructose et produits à base de fructose",
   code_15622100:"Glucose et produits à base de glucose",
   code_15622110:"Glucose",
   code_15622120:"Sirop de glucose",
   code_15622300:"Fructose et produits à base de fructose",
   code_15622310:"Fructose",
   code_15622320:"Préparations à base de fructose",
   code_15622321:"Solutions de fructose",
   code_15622322:"Sirop de fructose",
   code_15623000:"Amidons et fécules",
   code_15624000:"Tapioca",
   code_15625000:"Semoule",
   code_15626000:"Poudre pour pudding",
   code_15700000:"Aliments pour animaux",
   code_15710000:"Aliments prêts à l'emploi pour animaux d'élevage et autres animaux",
   code_15711000:"Nourriture pour poissons",
   code_15712000:"Fourrage sec",
   code_15713000:"Aliments pour animaux de compagnie",
   code_15800000:"Produits alimentaires divers",
   code_15810000:"Produits de panification, pâtisserie fraîche et gâteaux",
   code_15811000:"Produits de panification",
   code_15811100:"Pain",
   code_15811200:"Petits pains",
   code_15811300:"Croissants",
   code_15811400:"Petites crêpes épaisses",
   code_15811500:"Produits de panification préparés",
   code_15811510:"Sandwichs",
   code_15811511:"Sandwichs préparés",
   code_15812000:"Pâtisserie et gâteaux",
   code_15812100:"Pâtisserie",
   code_15812120:"Tourtes",
   code_15812121:"Tourtes salées",
   code_15812122:"Tourtes sucrées",
   code_15812200:"Gâteaux",
   code_15813000:"Aliments pour petit déjeuner",
   code_15820000:"Biscottes et biscuits; pâtisserie et gâteaux de conservation",
   code_15821000:"Produits de panification grillés et pâtisserie",
   code_15821100:"Produits de panification grillés",
   code_15821110:"Pain grillé",
   code_15821130:"Pain croustillant dit Knaeckebrot",
   code_15821150:"Biscottes",
   code_15821200:"Biscuits sucrés",
   code_15830000:"Sucre et produits connexes",
   code_15831000:"Sucre",
   code_15831200:"Sucre blanc",
   code_15831300:"Sucre d'érable et sirop d'érable",
   code_15831400:"Mélasse",
   code_15831500:"Sirops de sucre",
   code_15831600:"Miel",
   code_15832000:"Déchets provenant de la fabrication du sucre",
   code_15833000:"Produits à base de sucre",
   code_15833100:"Desserts",
   code_15833110:"Fonds de tartes",
   code_15840000:"Cacao; chocolat et sucreries",
   code_15841000:"Cacao",
   code_15841100:"Pâte de cacao",
   code_15841200:"Beurre, graisse ou huile de cacao",
   code_15841300:"Poudre de cacao non sucrée",
   code_15841400:"Poudre de cacao sucrée",
   code_15842000:"Chocolat et sucreries",
   code_15842100:"Chocolat",
   code_15842200:"Produits à base de chocolat",
   code_15842210:"Boisson chocolatée",
   code_15842220:"Barres de chocolat",
   code_15842300:"Confiserie",
   code_15842310:"Bonbons",
   code_15842320:"Nougat",
   code_15842400:"Fruits, fruits à coque ou écorces de fruits confits au sucre",
   code_15850000:"Pâtes alimentaires",
   code_15851000:"Produits farinacés",
   code_15851100:"Pâtes alimentaires non cuites",
   code_15851200:"Pâtes alimentaires préparées et couscous",
   code_15851210:"Pâtes alimentaires préparées",
   code_15851220:"Pâtes alimentaires farcies",
   code_15851230:"Lasagnes",
   code_15851250:"Couscous",
   code_15851290:"Pâtes alimentaires en conserve",
   code_15860000:"Café, thé et produits connexes",
   code_15861000:"Café",
   code_15861100:"Café torréfié",
   code_15861200:"Café décaféiné",
   code_15862000:"Succédanés de café",
   code_15863000:"Thé",
   code_15863100:"Thé vert",
   code_15863200:"Thé noir",
   code_15864000:"Préparations de thé ou de maté",
   code_15864100:"Thé en sachets",
   code_15865000:"Infusions",
   code_15870000:"Condiments et assaisonnements",
   code_15871000:"Vinaigre; sauces; condiments composés; farine et poudre de moutarde; moutarde préparée",
   code_15871100:"Vinaigre et succédanés de vinaigre",
   code_15871110:"Vinaigre ou équivalent",
   code_15871200:"Sauces, condiments et assaisonnements composés",
   code_15871210:"Sauce soja",
   code_15871230:"Ketchup",
   code_15871250:"Moutarde",
   code_15871260:"Sauces",
   code_15871270:"Condiments composés",
   code_15871273:"Mayonnaise",
   code_15871274:"Pâtes à tartiner pour sandwichs",
   code_15871279:"Chutney",
   code_15872000:"Herbes et épices",
   code_15872100:"Poivre",
   code_15872200:"Épices",
   code_15872300:"Herbes",
   code_15872400:"Sel",
   code_15872500:"Gingembre",
   code_15880000:"Produits nutritionnels spéciaux",
   code_15881000:"Préparations alimentaires homogénéisées",
   code_15882000:"Produits diététiques",
   code_15884000:"Aliments pour nourrissons",
   code_15890000:"Produits alimentaires et produits secs divers",
   code_15891000:"Soupes et bouillons",
   code_15891100:"Soupes à base de viande",
   code_15891200:"Soupes à base de poisson",
   code_15891300:"Soupes composées",
   code_15891400:"Soupes",
   code_15891410:"Soupes instantanées",
   code_15891500:"Bouillons",
   code_15891600:"Fonds",
   code_15891610:"Bouillons instantanés",
   code_15891900:"Soupes de légumes",
   code_15892000:"Sucs et extraits d'origine végétale, matières peptiques et épaississants",
   code_15892100:"Sucs d'origine végétale",
   code_15892200:"Extraits d'origine végétale",
   code_15892400:"Épaississants",
   code_15893000:"Denrées sèches",
   code_15893100:"Préparations alimentaires",
   code_15893200:"Préparations pour desserts",
   code_15893300:"Préparations pour sauces",
   code_15894000:"Produits alimentaires transformés",
   code_15894100:"Repas végétariens",
   code_15894200:"Repas préparés",
   code_15894210:"Repas pour écoles",
   code_15894220:"Repas pour hôpitaux",
   code_15894300:"Plats préparés",
   code_15894400:"Casse-croûte",
   code_15894500:"Produits pour distributeurs automatiques",
   code_15894600:"Garnitures pour sandwichs",
   code_15894700:"Épicerie fine",
   code_15895000:"Aliments pour restauration rapide",
   code_15895100:"Hamburgers",
   code_15896000:"Produits surgelés",
   code_15897000:"Aliments en conserve et rations de campagne",
   code_15897100:"Rations de campagne",
   code_15897200:"Aliments en conserve",
   code_15897300:"Colis alimentaires",
   code_15898000:"Levure",
   code_15899000:"Levure chimique",
   code_15900000:"Boissons, tabac et produits connexes",
   code_15910000:"Boissons alcoolisées distillées",
   code_15911000:"Boissons spiritueuses",
   code_15911100:"Spiritueux",
   code_15911200:"Liqueurs",
   code_15930000:"Vins",
   code_15931000:"Vins non aromatisés",
   code_15931100:"Vin mousseux",
   code_15931200:"Vin de table",
   code_15931300:"Porto",
   code_15931400:"Madère",
   code_15931500:"Moût de raisin",
   code_15931600:"Sherry",
   code_15932000:"Lie de vin",
   code_15940000:"Cidre et autres vins à base de fruits",
   code_15941000:"Cidre",
   code_15942000:"Vins à base de fruits",
   code_15950000:"Boissons fermentées non distillées",
   code_15951000:"Vermouth",
   code_15960000:"Bière de malt",
   code_15961000:"Bière",
   code_15961100:"Bière blonde",
   code_15962000:"Drèches de brasserie ou de distillerie",
   code_15980000:"Boissons sans alcool",
   code_15981000:"Eau minérale",
   code_15981100:"Eau minérale plate",
   code_15981200:"Eau minérale gazeuse",
   code_15981300:"Eau à l'état solide",
   code_15981310:"Glace",
   code_15981320:"Neige",
   code_15981400:"Eaux minérales aromatisées",
   code_15982000:"Boissons non alcoolisées",
   code_15982100:"Sirops de fruits",
   code_15982200:"Lait chocolaté",
   code_15990000:"Tabac, produits à base de tabac et articles connexes",
   code_15991000:"Produits à base de tabac",
   code_15991100:"Cigares",
   code_15991200:"Cigarillos",
   code_15991300:"Cigarettes",
   code_15992000:"Tabac",
   code_15992100:"Tabac manufacturé",
   code_15993000:"Articles de bureau de tabac",
   code_15994000:"Papier à cigarettes et papier-filtre",
   code_15994100:"Papier à cigarettes",
   code_15994200:"Papier-filtre",
   code_16000000:"Machines agricoles",
   code_16100000:"Machines agricoles et sylvicoles pour la préparation ou la culture des sols",
   code_16110000:"Charrues ou herses à disques",
   code_16120000:"Herses, scarificateurs, cultivateurs, sarcleuses ou houes",
   code_16130000:"Semoirs, plantoirs ou repiqueurs",
   code_16140000:"Épandeurs de fumier",
   code_16141000:"Distributeurs d'engrais",
   code_16150000:"Rouleaux pour pelouses ou terrains de sports",
   code_16160000:"Matériel de jardinage divers",
   code_16300000:"Moissonneuses",
   code_16310000:"Faucheuses",
   code_16311000:"Tondeuses à gazon",
   code_16311100:"Tondeuses à gazon pour pelouses, parcs ou terrains de sports",
   code_16320000:"Machines de fenaison",
   code_16330000:"Presses à paille ou à fourrage",
   code_16331000:"Presses ramasseuses",
   code_16340000:"Moissonneuses-batteuses",
   code_16400000:"Machines de pulvérisation à usage agricole ou horticole",
   code_16500000:"Remorques et semi-remorques autochargeuses ou déchargeuses à usage agricole",
   code_16510000:"Remorques autochargeuses à usage agricole",
   code_16520000:"Remorques déchargeuses à usage agricole",
   code_16530000:"Semi-remorques autochargeuses à usage agricole",
   code_16540000:"Semi-remorques déchargeuses à usage agricole",
   code_16600000:"Machines spécialisées à usage agricole ou sylvicole",
   code_16610000:"Machines de nettoyage, de tri ou de calibrage d'œufs, de fruits ou d'autres produits",
   code_16611000:"Machines de nettoyage de produits agricoles",
   code_16611100:"Machines de nettoyage d'œufs",
   code_16611200:"Machines de nettoyage de fruits",
   code_16612000:"Machines de tri ou de calibrage de produits agricoles",
   code_16612100:"Machines de tri ou de calibrage d'œufs",
   code_16612200:"Machines de tri ou de calibrage de fruits",
   code_16613000:"Machines de nettoyage, de tri ou de calibrage de semences, de graines ou de légumes secs",
   code_16620000:"Trayeuses",
   code_16630000:"Machines de préparation d'aliments pour animaux",
   code_16640000:"Machines apicoles",
   code_16650000:"Machines avicoles",
   code_16651000:"Incubateurs et écloseries avicoles",
   code_16700000:"Tracteurs",
   code_16710000:"Tracteurs agricoles à conducteur accompagnant",
   code_16720000:"Tracteurs d'occasion",
   code_16730000:"Moteurs de traction",
   code_16800000:"Pièces pour machines agricoles et sylvicoles",
   code_16810000:"Pièces pour machines agricoles",
   code_16820000:"Pièces pour machines sylvicoles",
   code_18000000:"Vêtements, articles chaussants, bagages et accessoires",
   code_18100000:"Vêtements professionnels, vêtements de travail spéciaux et accessoires",
   code_18110000:"Vêtements professionnels",
   code_18113000:"Vêtements à usage industriel",
   code_18114000:"Combinaisons de travail",
   code_18130000:"Vêtements de travail spéciaux",
   code_18132000:"Vêtements d'aviateur",
   code_18132100:"Vestons d'aviateur",
   code_18132200:"Combinaisons d'aviateur",
   code_18140000:"Accessoires pour vêtements de travail",
   code_18141000:"Gants de travail",
   code_18142000:"Visières de sécurité",
   code_18143000:"Équipements de protection",
   code_18200000:"Vêtements d'extérieur",
   code_18210000:"Manteaux",
   code_18211000:"Pèlerines",
   code_18212000:"Capes",
   code_18213000:"Coupe-vent",
   code_18220000:"Vêtements de protection contre les intempéries",
   code_18221000:"Vêtements imperméables",
   code_18221100:"Pèlerines imperméables",
   code_18221200:"Anoraks",
   code_18221300:"Imperméables",
   code_18222000:"Vêtements de fonction",
   code_18222100:"Costumes (hommes); tailleurs (femmes)",
   code_18222200:"Ensembles",
   code_18223000:"Vestes et blazers",
   code_18223100:"Blazers",
   code_18223200:"Vestes",
   code_18224000:"Vêtements en tissu enduit ou imprégné",
   code_18230000:"Vêtements d'extérieur divers",
   code_18231000:"Robes",
   code_18232000:"Jupes",
   code_18233000:"Shorts",
   code_18234000:"Pantalons",
   code_18235000:"Pull-overs, cardigans et articles similaires",
   code_18235100:"Pull-overs",
   code_18235200:"Cardigans",
   code_18235300:"Sweat-shirts",
   code_18235400:"Gilets",
   code_18300000:"Articles d'habillement",
   code_18310000:"Sous-vêtements",
   code_18311000:"Combinaisons",
   code_18312000:"Caleçons",
   code_18313000:"Slips pour femmes",
   code_18314000:"Peignoirs de bain",
   code_18315000:"Bas",
   code_18316000:"Collants",
   code_18317000:"Chaussettes",
   code_18318000:"Vêtements de nuit",
   code_18318100:"Chemises de nuit",
   code_18318200:"Robes de chambre",
   code_18318300:"Pyjamas",
   code_18318400:"Maillots de corps",
   code_18318500:"Chemises de nuit pour femmes",
   code_18320000:"Soutiens-gorge, corsets, jarretelles et articles similaires",
   code_18321000:"Soutiens-gorge",
   code_18322000:"Corsets",
   code_18323000:"Jarretelles",
   code_18330000:"T-shirts et chemises",
   code_18331000:"T-shirts",
   code_18332000:"Chemises",
   code_18333000:"Chemises polo",
   code_18400000:"Vêtements spéciaux et accessoires",
   code_18410000:"Vêtements spéciaux",
   code_18411000:"Vêtements pour bébés",
   code_18412000:"Vêtements de sport",
   code_18412100:"Survêtements de sport",
   code_18412200:"Chemises de sport",
   code_18412300:"Combinaisons de ski",
   code_18412800:"Maillots de bain",
   code_18420000:"Accessoires d'habillement",
   code_18421000:"Mouchoirs",
   code_18422000:"Écharpes",
   code_18423000:"Cravates",
   code_18424000:"Gants",
   code_18424300:"Gants jetables",
   code_18424400:"Moufles",
   code_18424500:"Gants à crispin",
   code_18425000:"Ceintures",
   code_18425100:"Cartouchières",
   code_18440000:"Chapeaux et coiffures",
   code_18441000:"Chapeaux",
   code_18443000:"Coiffures et accessoires de coiffure",
   code_18443100:"Serre-tête",
   code_18443300:"Coiffures",
   code_18443310:"Bérets",
   code_18443320:"Calots de campagne",
   code_18443330:"Capuchons",
   code_18443340:"Képis",
   code_18443400:"Jugulaires pour coiffures",
   code_18443500:"Visières",
   code_18444000:"Coiffures de protection",
   code_18444100:"Coiffures de sécurité",
   code_18444110:"Casques",
   code_18444111:"Casques antichoc",
   code_18444112:"Casques pour cyclistes",
   code_18444200:"Casques de sécurité",
   code_18450000:"Fermetures (vêtements)",
   code_18451000:"Boutons",
   code_18451100:"Parties de boutons",
   code_18452000:"Épingles de sûreté",
   code_18453000:"Fermetures à glissière",
   code_18500000:"Bijouterie, montres et articles connexes",
   code_18510000:"Bijouterie et articles connexes",
   code_18511000:"Pierres précieuses pour bijouterie",
   code_18511100:"Diamants",
   code_18511200:"Rubis",
   code_18511300:"Émeraudes",
   code_18511400:"Opale",
   code_18511500:"Quartz",
   code_18511600:"Tourmaline",
   code_18512000:"Pièces de monnaie et médailles",
   code_18512100:"Pièces de monnaie",
   code_18512200:"Médailles",
   code_18513000:"Bijoux",
   code_18513100:"Perles",
   code_18513200:"Pièces d'orfèvrerie",
   code_18513300:"Objets en métal précieux",
   code_18513400:"Objets en pierres précieuses ou semi-précieuses",
   code_18513500:"Pièces d'argenterie",
   code_18520000:"Horlogerie personnelle",
   code_18521000:"Montres",
   code_18521100:"Verre à montres",
   code_18522000:"Montres-bracelets",
   code_18523000:"Chronomètres",
   code_18530000:"Cadeaux et prix",
   code_18600000:"Fourrure et articles en fourrure",
   code_18610000:"Articles en fourrure",
   code_18611000:"Pelleterie",
   code_18612000:"Vêtements en fourrure",
   code_18613000:"Articles en fourrure synthétique",
   code_18620000:"Fourrures",
   code_18800000:"Articles chaussants",
   code_18810000:"Chaussures autres que les chaussures de sport ou de protection",
   code_18811000:"Chaussures étanches",
   code_18812000:"Chaussures partiellement en caoutchouc ou en plastique",
   code_18812100:"Sandales à dessus caoutchouc ou plastique",
   code_18812200:"Bottes en caoutchouc",
   code_18812300:"Chaussures de ville à dessus caoutchouc ou plastique",
   code_18812400:"Tongs",
   code_18813000:"Chaussures à dessus cuir",
   code_18813100:"Sandales",
   code_18813200:"Pantoufles",
   code_18813300:"Chaussures de ville",
   code_18814000:"Chaussures à dessus textile",
   code_18815000:"Bottes",
   code_18815100:"Bottines",
   code_18815200:"Bottillons",
   code_18815300:"Bottes hautes",
   code_18815400:"Cuissardes",
   code_18816000:"Galoches",
   code_18820000:"Chaussures de sport",
   code_18821000:"Chaussures de ski",
   code_18821100:"Chaussures de ski de fond",
   code_18822000:"Chaussures d'entraînement",
   code_18823000:"Chaussures de montagne",
   code_18824000:"Chaussures de football",
   code_18830000:"Chaussures de protection",
   code_18831000:"Chaussures à embout de protection métallique",
   code_18832000:"Chaussures spéciales",
   code_18832100:"Chaussures d'aviateur",
   code_18840000:"Parties de chaussures",
   code_18841000:"Dessus de chaussures",
   code_18842000:"Semelles",
   code_18843000:"Talons",
   code_18900000:"Bagages, sellerie, sacs et sachets",
   code_18910000:"Sellerie",
   code_18911000:"Selles d'équitation",
   code_18912000:"Cravaches",
   code_18913000:"Fouets",
   code_18920000:"Bagages",
   code_18921000:"Valises",
   code_18923000:"Bourses et portefeuilles",
   code_18923100:"Bourses",
   code_18923200:"Portefeuilles",
   code_18924000:"Malles",
   code_18925000:"Porte-gourdes et étuis",
   code_18925100:"Porte-gourdes",
   code_18925200:"Étuis",
   code_18929000:"Trousses de toilette",
   code_18930000:"Sacs et sachets",
   code_18931000:"Sacs de voyage",
   code_18931100:"Sacs à dos",
   code_18932000:"Sacs de sports",
   code_18933000:"Sacs pour courrier ou colis",
   code_18933100:"Sacs postaux",
   code_18934000:"Sacoches",
   code_18935000:"Sacs à linge",
   code_18936000:"Sacs en textile",
   code_18937000:"Sacs d'emballage",
   code_18937100:"Sachets d'emballage",
   code_18938000:"Enveloppes rembourrées",
   code_18939000:"Sacs à main",
   code_19000000:"Produits en cuir et textiles, matériaux en plastique et en caoutchouc",
   code_19100000:"Cuir",
   code_19110000:"Peau de chamois",
   code_19120000:"Cuir de bovidé ou d'équidé",
   code_19130000:"Cuir d'ovin, de caprin ou de porcin",
   code_19131000:"Peau de mouton ou d'agneau",
   code_19132000:"Peau de chèvre ou de chevreau",
   code_19133000:"Cuir de porcin",
   code_19140000:"Cuir d'autres animaux, cuir reconstitué et autres cuirs",
   code_19141000:"Cuir d'autres animaux",
   code_19142000:"Cuir reconstitué",
   code_19143000:"Similicuir",
   code_19144000:"Cuir verni",
   code_19160000:"Bracelets de montre",
   code_19170000:"Articles en cuir utilisés dans les machines ou les appareils mécaniques",
   code_19200000:"Textiles et articles connexes",
   code_19210000:"Tissus",
   code_19211000:"Tissu synthétique",
   code_19211100:"Tissu mélangé",
   code_19212000:"Tissu en coton",
   code_19212100:"Coutil",
   code_19212200:"Denim",
   code_19212300:"Toile",
   code_19212310:"Articles en toile",
   code_19212400:"Popeline",
   code_19212500:"Sangle",
   code_19212510:"Sangles",
   code_19220000:"Tissu cardé",
   code_19230000:"Tissu de lin",
   code_19231000:"Linge",
   code_19240000:"Tissu spécial",
   code_19241000:"Velours",
   code_19242000:"Tissu éponge",
   code_19243000:"Tissu d'ameublement",
   code_19244000:"Tissu à rideaux",
   code_19245000:"Tissu de doublure",
   code_19250000:"Tissu tricoté ou crocheté",
   code_19251000:"Tricot",
   code_19251100:"Tissu de velours",
   code_19252000:"Tissu crocheté",
   code_19260000:"Étoffe",
   code_19270000:"Tissu non-tissé",
   code_19280000:"Laine, cuirs et peaux",
   code_19281000:"Laine",
   code_19282000:"Peaux",
   code_19283000:"Peaux et plumes d'oiseaux",
   code_19400000:"Fil textile",
   code_19410000:"Fibres textiles naturelles",
   code_19420000:"Fibres textiles artificielles",
   code_19430000:"Fil textile en fibres naturelles",
   code_19431000:"Fil de soie",
   code_19432000:"Fil de laine",
   code_19433000:"Fil de coton",
   code_19434000:"Fil de lin",
   code_19435000:"Fil à coudre en fibres naturelles",
   code_19435100:"Fil à coudre",
   code_19435200:"Fil à tricoter",
   code_19436000:"Fil en fibres textiles végétales",
   code_19440000:"Fil ou filé synthétique",
   code_19441000:"Filé synthétique",
   code_19442000:"Fil synthétique",
   code_19442100:"Fil à coudre synthétique",
   code_19442200:"Fil à tricoter synthétique",
   code_19500000:"Produits en caoutchouc et en plastique",
   code_19510000:"Produits en caoutchouc",
   code_19511000:"Chambres à air, bandes de roulement et flaps en caoutchouc",
   code_19511100:"Flaps",
   code_19511200:"Chambres à air",
   code_19511300:"Bandes de roulement",
   code_19512000:"Articles en caoutchouc non vulcanisé",
   code_19513000:"Tissus caoutchoutés",
   code_19513100:"Tissu pour cordée de pneumatique",
   code_19513200:"Ruban adhésif en tissu caoutchouté",
   code_19514000:"Caoutchouc régénéré",
   code_19520000:"Produits en plastique",
   code_19521000:"Produits en polystyrène",
   code_19521100:"Feuilles de polystyrène",
   code_19521200:"Plaques de polystyrène",
   code_19522000:"Résines",
   code_19522100:"Résine époxy",
   code_19522110:"Tubes en résine d'époxy",
   code_19600000:"Déchets de cuir, déchets textiles, déchets de caoutchouc et déchets de plastique",
   code_19610000:"Déchets de cuir",
   code_19620000:"Déchets textiles",
   code_19630000:"Déchets de caoutchouc",
   code_19640000:"Sacs et sachets à ordures en polyéthylène",
   code_19700000:"Caoutchouc et fibres synthétiques",
   code_19710000:"Caoutchouc synthétique",
   code_19720000:"Fibres synthétiques",
   code_19721000:"Câbles de fibres synthétiques",
   code_19722000:"Fil à haute ténacité",
   code_19723000:"Fil à texture simple",
   code_19724000:"Monofilament synthétique",
   code_19730000:"Fibres artificielles",
   code_19731000:"Fibres artificielles discontinues",
   code_19732000:"Polypropylène",
   code_19733000:"Fil texturé artificiel",
   code_22000000:"Imprimés et produits connexes",
   code_22100000:"Livres, brochures et dépliants imprimés",
   code_22110000:"Livres imprimés",
   code_22111000:"Livres scolaires",
   code_22112000:"Manuels scolaires",
   code_22113000:"Livres de bibliothèque",
   code_22114000:"Dictionnaires, cartes, livres de musique et autres livres",
   code_22114100:"Dictionnaires",
   code_22114200:"Atlas",
   code_22114300:"Cartes",
   code_22114310:"Plans cadastraux",
   code_22114311:"Cyanotypies",
   code_22114400:"Partitions",
   code_22114500:"Encyclopédies",
   code_22120000:"Publications",
   code_22121000:"Publications techniques",
   code_22130000:"Annuaires",
   code_22140000:"Dépliants",
   code_22150000:"Brochures",
   code_22160000:"Fascicules",
   code_22200000:"Journaux, revues spécialisées, périodiques et magazines",
   code_22210000:"Journaux",
   code_22211000:"Revues spécialisées",
   code_22211100:"Journaux officiels",
   code_22212000:"Périodiques",
   code_22212100:"Publications périodiques",
   code_22213000:"Magazines",
   code_22300000:"Cartes postales, cartes de vœux et autres imprimés",
   code_22310000:"Cartes postales",
   code_22312000:"Images",
   code_22313000:"Décalcomanies",
   code_22314000:"Dessins",
   code_22315000:"Photographies",
   code_22320000:"Cartes de vœux",
   code_22321000:"Cartes de Noël",
   code_22400000:"Timbres, formules de chèque, billets de banque, actions, publicité professionnelle, catalogues et manuels",
   code_22410000:"Timbres",
   code_22411000:"Timbres de Noël",
   code_22412000:"Nouveaux timbres",
   code_22413000:"Timbres-épargne",
   code_22414000:"Carnets de timbres-poste",
   code_22420000:"Papier timbré",
   code_22430000:"Billets de banque",
   code_22440000:"Formules de chèque",
   code_22450000:"Imprimés infalsifiables",
   code_22451000:"Passeports",
   code_22452000:"Formules de mandat de poste",
   code_22453000:"Vignettes automobiles",
   code_22454000:"Permis de conduire",
   code_22455000:"Cartes d'identité",
   code_22455100:"Bracelet d'identité",
   code_22456000:"Permis",
   code_22457000:"Cartes d'accès",
   code_22458000:"Imprimés sur commande",
   code_22459000:"Tickets",
   code_22459100:"Autocollants et bandes publicitaires",
   code_22460000:"Publicité professionnelle, catalogues commerciaux et manuels",
   code_22461000:"Catalogues",
   code_22461100:"Porte-liste",
   code_22462000:"Imprimés publicitaires",
   code_22470000:"Manuels",
   code_22471000:"Manuels informatiques",
   code_22472000:"Manuels d'instruction",
   code_22473000:"Manuels techniques",
   code_22500000:"Plaques ou cylindres d'impression, autre matériel d'imprimerie",
   code_22510000:"Plaques offset",
   code_22520000:"Matériel de gravure sèche",
   code_22521000:"Matériel d'estampage",
   code_22600000:"Encre",
   code_22610000:"Encre d'imprimerie",
   code_22611000:"Encre pour héliogravure",
   code_22612000:"Encre de Chine",
   code_22800000:"Registres, livres comptables, classeurs, formulaires et autres articles de papeterie imprimés en papier ou en carton",
   code_22810000:"Registres en papier ou en carton",
   code_22813000:"Livres comptables",
   code_22814000:"Carnets de quittances",
   code_22815000:"Calepins",
   code_22816000:"Blocs",
   code_22816100:"Bloc-notes",
   code_22816200:"Carnets de sténographie",
   code_22816300:"Carnets de Post-it",
   code_22817000:"Agendas ou mémentos personnels",
   code_22819000:"Carnets d'adresses",
   code_22820000:"Formulaires",
   code_22821000:"Formulaires électoraux",
   code_22822000:"Formulaires commerciaux",
   code_22822100:"Formulaires commerciaux en continu",
   code_22822200:"Formulaires commerciaux non continus",
   code_22830000:"Cahiers d'exercices",
   code_22831000:"Recharges pour cahiers d'exercices",
   code_22832000:"Feuilles d'exercices",
   code_22840000:"Albums pour échantillons",
   code_22841000:"Albums pour collections",
   code_22841100:"Carnets de timbres",
   code_22841200:"Albums de timbres",
   code_22850000:"Classeurs et accessoires pour classeurs",
   code_22851000:"Classeurs",
   code_22852000:"Chemises de classement",
   code_22852100:"Chemises à dossier",
   code_22853000:"Bacs à documents",
   code_22900000:"Imprimés divers",
   code_22990000:"Papiers à usage graphique, ni couchés ni enduits",
   code_22991000:"Papier journal",
   code_22992000:"Papier ou carton de fabrication artisanale",
   code_22993000:"Papier ou carton photosensible, thermosensible ou thermographique",
   code_22993100:"Papier ou carton photosensible",
   code_22993200:"Papier ou carton thermosensible",
   code_22993300:"Papier ou carton thermographique",
   code_22993400:"Papier ou carton ondulé",
   code_24000000:"Produits chimiques",
   code_24100000:"Gaz",
   code_24110000:"Gaz industriels",
   code_24111000:"Hydrogène, argon, gaz rares, azote et oxygène",
   code_24111100:"Argon",
   code_24111200:"Gaz rares",
   code_24111300:"Hélium",
   code_24111400:"Néon",
   code_24111500:"Gaz médicaux",
   code_24111600:"Hydrogène",
   code_24111700:"Azote",
   code_24111800:"Azote liquide",
   code_24111900:"Oxygène",
   code_24112000:"Composés oxygénés inorganiques",
   code_24112100:"Dioxyde de carbone",
   code_24112200:"Oxydes azotés",
   code_24112300:"Composés oxygénés inorganiques gazeux",
   code_24113000:"Air liquide et air comprimé",
   code_24113100:"Air liquide",
   code_24113200:"Air comprimé",
   code_24200000:"Colorants et pigments",
   code_24210000:"Oxydes, peroxydes et hydroxydes",
   code_24211000:"Oxyde et peroxyde de zinc, oxyde de titane, colorants et pigments",
   code_24211100:"Oxyde de zinc",
   code_24211200:"Peroxyde de zinc",
   code_24211300:"Oxyde de titane",
   code_24212000:"Oxydes et hydroxydes de chrome, de manganèse, de magnésium, de plomb et de cuivre",
   code_24212100:"Oxyde de chrome",
   code_24212200:"Oxyde de manganèse",
   code_24212300:"Oxyde de plomb",
   code_24212400:"Oxyde de cuivre",
   code_24212500:"Oxyde de magnésium",
   code_24212600:"Hydroxydes pour colorants et pigments",
   code_24212610:"Hydroxyde de chrome",
   code_24212620:"Hydroxyde de manganèse",
   code_24212630:"Hydroxyde de plomb",
   code_24212640:"Hydroxyde de cuivre",
   code_24212650:"Hydroxyde de magnésium",
   code_24213000:"Chaux hydratée",
   code_24220000:"Extraits tannants, extraits tinctoriaux, tannins et matières colorantes",
   code_24221000:"Extraits tinctoriaux",
   code_24222000:"Extraits tannants",
   code_24223000:"Tannins",
   code_24224000:"Matières colorantes",
   code_24225000:"Produits de tannage",
   code_24300000:"Produits chimiques organiques et inorganiques de base",
   code_24310000:"Produits chimiques inorganiques de base",
   code_24311000:"Éléments chimiques, acides inorganiques et composés",
   code_24311100:"Métalloïdes",
   code_24311110:"Phosphures",
   code_24311120:"Carbures",
   code_24311130:"Hydrures",
   code_24311140:"Nitrures",
   code_24311150:"Azotures",
   code_24311160:"Siliciures",
   code_24311170:"Borures",
   code_24311180:"Soufre raffiné",
   code_24311200:"Halogène",
   code_24311300:"Métaux alcalins",
   code_24311310:"Mercure",
   code_24311400:"Chlorure d'hydrogène, acides inorganiques, dioxyde de silicium et de soufre",
   code_24311410:"Acides inorganiques",
   code_24311411:"Acide sulfurique",
   code_24311420:"Acide phosphorique",
   code_24311430:"Acides polyphosphoriques",
   code_24311440:"Acide hexafluorosilicique",
   code_24311450:"Dioxyde de soufre",
   code_24311460:"Dioxyde de silicium",
   code_24311470:"Chlorure d'hydrogène",
   code_24311500:"Hydroxydes utilisés comme produits chimiques inorganiques de base",
   code_24311510:"Oxydes métalliques",
   code_24311511:"Pyrites et oxydes de fer",
   code_24311520:"Hydroxyde de sodium",
   code_24311521:"Soude caustique",
   code_24311522:"Soude liquide",
   code_24311600:"Composés du soufre",
   code_24311700:"Soufre",
   code_24311800:"Carbone",
   code_24311900:"Chlore",
   code_24312000:"Halogénures métalliques, hypochlorites, chlorates et perchlorates",
   code_24312100:"Halogénures métalliques",
   code_24312110:"Hexafluorosilicate de sodium",
   code_24312120:"Chlorures",
   code_24312121:"Chlorure d'aluminium",
   code_24312122:"Chlorure ferrique",
   code_24312123:"Polychlorure d'aluminium",
   code_24312130:"Chlorhydrate d'aluminium",
   code_24312200:"Hypochlorites et chlorates",
   code_24312210:"Chlorite de sodium",
   code_24312220:"Hypochlorite de sodium",
   code_24313000:"Sulfures, sulfates, nitrates, phosphates et carbonates",
   code_24313100:"Sulfures, sulfites et sulfates",
   code_24313110:"Sulfures divers",
   code_24313111:"Sulfure d'hydrogène",
   code_24313112:"Polysulfures",
   code_24313120:"Sulfates",
   code_24313121:"Thiosulfate de sodium",
   code_24313122:"Sulfate ferrique",
   code_24313123:"Sulfate d'aluminium",
   code_24313124:"Sulfate de sodium",
   code_24313125:"Sulfate de fer",
   code_24313126:"Sulfate de cuivre",
   code_24313200:"Phosphinates, phosphonates, phosphates et polyphosphates",
   code_24313210:"Hexamétaphosphate de sodium",
   code_24313220:"Phosphates",
   code_24313300:"Carbonates",
   code_24313310:"Carbonate de sodium",
   code_24313320:"Bicarbonate de sodium",
   code_24313400:"Nitrates",
   code_24314000:"Sels métalliques acides divers",
   code_24314100:"Permanganate de potassium",
   code_24314200:"Sels oxométalliques acides",
   code_24315000:"Produits chimiques inorganiques divers",
   code_24315100:"Eau lourde, autres isotopes et leurs composés",
   code_24315200:"Cyanure, oxyde de cyanure, fulminates, cyanates, silicates, borates, perborates, sels d'acides inorganiques",
   code_24315210:"Cyanures",
   code_24315220:"Oxyde de cyanure",
   code_24315230:"Fulminates",
   code_24315240:"Cyanates",
   code_24315300:"Peroxyde d'hydrogène",
   code_24315400:"Quartz piézoélectrique",
   code_24315500:"Composés de métaux des terres rares",
   code_24315600:"Silicates",
   code_24315610:"Silicate de sodium",
   code_24315700:"Borates et perborates",
   code_24316000:"Eau distillée",
   code_24317000:"Pierres synthétiques",
   code_24317100:"Pierres précieuses synthétiques",
   code_24317200:"Pierres semi-précieuses synthétiques",
   code_24320000:"Produits chimiques organiques de base",
   code_24321000:"Hydrocarbures",
   code_24321100:"Hydrocarbures saturés",
   code_24321110:"Hydrocarbures acycliques saturés",
   code_24321111:"Méthane",
   code_24321112:"Éthylène",
   code_24321113:"Propène",
   code_24321114:"Butène",
   code_24321115:"Acétylène",
   code_24321120:"Hydrocarbures cycliques saturés",
   code_24321200:"Hydrocarbures non saturés",
   code_24321210:"Hydrocarbures acycliques non saturés",
   code_24321220:"Hydrocarbures cycliques non saturés",
   code_24321221:"Benzène",
   code_24321222:"Toluène",
   code_24321223:"O-xylènes",
   code_24321224:"M-xylènes",
   code_24321225:"Styrène",
   code_24321226:"Éthylbenzène",
   code_24321300:"Autres dérivés halogénés d'hydrocarbures",
   code_24321310:"Tétrachloroéthylène",
   code_24321320:"Tétrachlorure de carbone",
   code_24322000:"Alcools, phénols, phénols-alcools et leurs dérivés halogénés, sulfonés, nitrés ou nitrosés, alcools gras industriels",
   code_24322100:"Alcools gras industriels",
   code_24322200:"Monoalcools",
   code_24322210:"Méthanol",
   code_24322220:"Éthanol",
   code_24322300:"Diols, polyalcools et dérivés",
   code_24322310:"Éthylène glycol",
   code_24322320:"Dérivés d'alcools",
   code_24322400:"Phénols et dérivés de phénols",
   code_24322500:"Alcool",
   code_24322510:"Alcool éthylique",
   code_24323000:"Acides gras monocarboxyliques industriels",
   code_24323100:"Huiles acides de raffinage",
   code_24323200:"Acides carboxyliques",
   code_24323210:"Acide acétique",
   code_24323220:"Acide peracétique",
   code_24323300:"Acides monocarboxyliques non saturés et leurs composés",
   code_24323310:"Esters d'acide méthacrylique",
   code_24323320:"Esters d'acide acrylique",
   code_24323400:"Acides aromatiques polycarboxyliques et carboxyliques",
   code_24324000:"Composés organiques à fonction azotée",
   code_24324100:"Composés à fonction aminée",
   code_24324200:"Amino-composés à fonction oxygénée",
   code_24324300:"Uréides",
   code_24324400:"Composés à fonction azotée",
   code_24325000:"Composés organosulfureux",
   code_24326000:"Aldéhyde, cétone, peroxydes organiques et éthers",
   code_24326100:"Composés à fonction aldéhyde",
   code_24326200:"Composés à fonction cétone et à fonction quinone",
   code_24326300:"Peroxydes organiques",
   code_24326310:"Oxyde d'éthylène",
   code_24326320:"Éthers",
   code_24327000:"Produits chimiques organiques divers",
   code_24327100:"Dérivés végétaux pour teinture",
   code_24327200:"Charbon de bois",
   code_24327300:"Huiles et produits de la distillation du goudron de houille, poix et brai de goudron à haute température",
   code_24327310:"Goudron de houille",
   code_24327311:"Créosote",
   code_24327320:"Poix",
   code_24327330:"Brai de goudron",
   code_24327400:"Produits à base de résine",
   code_24327500:"Lessives résiduelles provenant de la fabrication de la pâte à papier",
   code_24400000:"Engrais et composés azotés",
   code_24410000:"Engrais azotés",
   code_24411000:"Acide nitrique et sels",
   code_24411100:"Nitrate de sodium",
   code_24412000:"Acides sulfonitriques",
   code_24413000:"Ammoniac",
   code_24413100:"Ammoniac liquide",
   code_24413200:"Chlorure d'ammonium",
   code_24413300:"Sulfate d'ammonium",
   code_24420000:"Engrais phosphatés",
   code_24421000:"Engrais minéraux phosphatés",
   code_24422000:"Engrais chimiques phosphatés",
   code_24430000:"Engrais d'origine animale ou végétale",
   code_24440000:"Engrais divers",
   code_24450000:"Produits agrochimiques",
   code_24451000:"Pesticides",
   code_24452000:"Insecticides",
   code_24453000:"Herbicides",
   code_24454000:"Régulateurs de croissance végétale",
   code_24455000:"Désinfectants",
   code_24456000:"Rodenticides",
   code_24457000:"Fongicides",
   code_24500000:"Matières plastiques sous forme primaire",
   code_24510000:"Polymères d'éthylène sous forme primaire",
   code_24520000:"Polymères de propylène sous forme primaire",
   code_24530000:"Polymères de styrène sous forme primaire",
   code_24540000:"Polymères de vinyle sous forme primaire",
   code_24541000:"Polymères d'acétate de vinyle sous forme primaire",
   code_24542000:"Polymères acryliques sous forme primaire",
   code_24550000:"Polyesters sous forme primaire",
   code_24560000:"Polyamides sous forme primaire",
   code_24570000:"Résines uréiques sous forme primaire",
   code_24580000:"Résines aminiques sous forme primaire",
   code_24590000:"Silicones sous forme primaire",
   code_24600000:"Explosifs",
   code_24610000:"Explosifs préparés",
   code_24611000:"Poudres propulsives",
   code_24611100:"Combustibles au propergol",
   code_24612000:"Explosifs divers",
   code_24612100:"Dynamite",
   code_24612200:"TNT",
   code_24612300:"Nitroglycérine",
   code_24613000:"Fusées de signalisation, fusées paragrêle, signaux de brume et articles de pyrotechnie",
   code_24613100:"Cartouches-épouvantails sonores",
   code_24613200:"Feux d'artifice",
   code_24615000:"Mèches, détonateurs, amorceurs et détonateurs électriques",
   code_24900000:"Produits de chimie fine et produits de chimie variés",
   code_24910000:"Colles",
   code_24911000:"Gélatines",
   code_24911200:"Adhésifs",
   code_24920000:"Huiles essentielles",
   code_24930000:"Substances chimiques pour photographie",
   code_24931000:"Plaques et films pour photographie",
   code_24931200:"Émulsions à usage photographique",
   code_24931210:"Révélateurs pour photographie",
   code_24931220:"Fixateurs pour photographie",
   code_24931230:"Révélateurs pour radiologie",
   code_24931240:"Fixateurs pour radiologie",
   code_24931250:"Milieux de culture",
   code_24931260:"Renforçateurs d'image",
   code_24950000:"Préparations chimiques spéciales",
   code_24951000:"Graisses et lubrifiants",
   code_24951100:"Lubrifiants",
   code_24951110:"Boue de forage",
   code_24951120:"Graisse de silicone",
   code_24951130:"Fluides de forage",
   code_24951200:"Additifs pour huiles",
   code_24951210:"Poudre pour extincteurs d'incendie",
   code_24951220:"Produits extincteurs",
   code_24951230:"Charges pour extincteurs d'incendie",
   code_24951300:"Fluides hydrauliques",
   code_24951310:"Agents de dégivrage",
   code_24951311:"Produits antigel",
   code_24951400:"Graisses et huiles chimiquement modifiées",
   code_24952000:"Pâtes à modeler",
   code_24952100:"Cire dentaire",
   code_24953000:"Agents de finissage",
   code_24954000:"Charbon actif",
   code_24954100:"Charbon actif neuf",
   code_24954200:"Charbon actif régénéré",
   code_24955000:"Toilettes chimiques",
   code_24956000:"Peptones et substances protéiques",
   code_24957000:"Additifs chimiques",
   code_24957100:"Liants préparés pour moules ou noyaux de fonderie",
   code_24957200:"Additifs pour ciment, mortier ou béton",
   code_24958000:"Produits chimiques pour l'industrie du pétrole et du gaz",
   code_24958100:"Produits chimiques pour fond de trou de forage",
   code_24958200:"Agents floculants",
   code_24958300:"Produits chimiques à boue",
   code_24958400:"Ampoules à gel pour le bourrage d'explosifs",
   code_24959000:"Aérosols et produits chimiques sous forme de disque",
   code_24959100:"Aérosols",
   code_24959200:"Éléments chimiques en forme de disque",
   code_24960000:"Produits chimiques variés",
   code_24961000:"Liquides de radiateur",
   code_24962000:"Produits chimiques utilisés pour le traitement de l'eau",
   code_24963000:"Produits anticorrosion",
   code_24964000:"Glycérine",
   code_24965000:"Enzymes",
   code_30000000:"Machines, matériel et fourniture informatique et de bureau, excepté les meubles et logiciels",
   code_30100000:"Machines, matériel et fournitures de bureau, excepté ordinateurs, imprimantes et meubles",
   code_30110000:"Machines de traitement de texte",
   code_30111000:"Traitements de texte",
   code_30120000:"Photocopieurs et matériel d'impression offset",
   code_30121000:"Photocopieurs et appareils de thermocopie",
   code_30121100:"Photocopieurs",
   code_30121200:"Matériel de photocopie",
   code_30121300:"Matériel de reproduction",
   code_30121400:"Duplicateurs",
   code_30121410:"Télécopieurs",
   code_30121420:"Émetteurs numériques",
   code_30121430:"Duplicateurs numériques",
   code_30122000:"Machines d'impression offset de bureau",
   code_30122100:"Système d'impression offset numérique",
   code_30122200:"Matériel d'impression offset numérique",
   code_30123000:"Machines de bureau",
   code_30123100:"Oblitérateurs automatiques",
   code_30123200:"Distributeurs automatiques de billets de banque",
   code_30123300:"Duplicateurs à stencil",
   code_30123400:"Plieuses",
   code_30123500:"Perforateurs",
   code_30123600:"Machines à manipuler les pièces de monnaie",
   code_30123610:"Machines à trier les pièces de monnaie",
   code_30123620:"Machines à compter les pièces de monnaie",
   code_30123630:"Machines de mise en rouleaux de pièces de monnaie",
   code_30124000:"Pièces et accessoires de machines de bureau",
   code_30124100:"Unités de fusion",
   code_30124110:"Huile pour unités de fusion",
   code_30124120:"Chiffon de nettoyage pour l'unité de fusion",
   code_30124130:"Lampes pour unité de fusion",
   code_30124140:"Tampon de nettoyage de l'unité de fusion",
   code_30124150:"Filtres de nettoyage de l'unité de fusion",
   code_30124200:"Kits pour unité de fusion",
   code_30124300:"Tambours pour machines de bureau",
   code_30124400:"Cartouches d'agrafes",
   code_30124500:"Accessoires de scanner",
   code_30124510:"Endosseurs",
   code_30124520:"Bac d'alimentation de documents pour scanner",
   code_30124530:"Adaptateurs de transparents pour scanner",
   code_30125000:"Pièces et accessoires de photocopieurs",
   code_30125100:"Cartouches de toner",
   code_30125110:"Encre pour imprimantes laser/télécopieurs",
   code_30125120:"Encre pour photocopieurs",
   code_30125130:"Encre pour centres de calcul, de recherche et de documentation",
   code_30130000:"Matériel de bureau de poste",
   code_30131000:"Équipement de salle du courrier",
   code_30131100:"Plieuses de papier ou d'enveloppes",
   code_30131200:"Machines à mettre sous enveloppe",
   code_30131300:"Adressographes",
   code_30131400:"Machines à affranchir",
   code_30131500:"Machines à ouvrir le courrier",
   code_30131600:"Machines à cacheter le courrier",
   code_30131700:"Machines à oblitérer",
   code_30131800:"Timbreuses",
   code_30132000:"Matériel de tri",
   code_30132100:"Matériel de tri du courrier",
   code_30132200:"Machines à compter les billets de banque",
   code_30132300:"Trieuses",
   code_30133000:"Matériel pour envoi postal",
   code_30133100:"Matériel pour envoi postal en nombre",
   code_30140000:"Machines à calculer et machines comptables",
   code_30141000:"Machines à calculer",
   code_30141100:"Calculatrices de poche",
   code_30141200:"Calculatrices de bureau",
   code_30141300:"Calculatrices imprimantes",
   code_30141400:"Machines à additionner",
   code_30142000:"Machines comptables et caisses enregistreuses",
   code_30142100:"Machines comptables",
   code_30142200:"Caisses enregistreuses",
   code_30144000:"Machines du type machines à calculer",
   code_30144100:"Affranchisseuses",
   code_30144200:"Distributeurs automatiques de tickets",
   code_30144300:"Machines de comptage des véhicules",
   code_30144400:"Péage automatique",
   code_30145000:"Pièces et accessoires de machines à calculer",
   code_30145100:"Rouleaux pour machines à calculer",
   code_30150000:"Machines à écrire",
   code_30151000:"Machines à écrire électroniques",
   code_30152000:"Pièces et accessoires de machines à écrire",
   code_30160000:"Cartes magnétiques",
   code_30161000:"Cartes de crédit",
   code_30162000:"Cartes à puce",
   code_30163000:"Cartes accréditives",
   code_30163100:"Cartes pour l'achat de carburant",
   code_30170000:"Étiqueteuses",
   code_30171000:"Machines à dater ou à numéroter",
   code_30172000:"Machines à imprimer les documents d'identité",
   code_30173000:"Machines d'étiquetage",
   code_30174000:"Machines à produires des étiquettes",
   code_30175000:"Nécessaire pour lettrage",
   code_30176000:"Titreuse manuelle",
   code_30177000:"Machines à étiqueter automatiques",
   code_30178000:"Machines à étiqueter semi-automatiques",
   code_30179000:"Distributeurs d'étiquettes",
   code_30180000:"Machines à endosser et à rédiger les chèques",
   code_30181000:"Machines à endosser les chèques",
   code_30182000:"Machines à rédiger les chèques",
   code_30190000:"Machines, fournitures et équipement de bureau divers",
   code_30191000:"Équipement de bureau, excepté les meubles",
   code_30191100:"Équipement de classement",
   code_30191110:"Carrousels à cartes",
   code_30191120:"Porte-revues",
   code_30191130:"Presse-papiers",
   code_30191140:"Accessoires d'identification personnelle",
   code_30191200:"Rétroprojecteurs",
   code_30191400:"Déchiqueteuses",
   code_30192000:"Fournitures de bureau",
   code_30192100:"Effaceurs",
   code_30192110:"Produits d'encrage",
   code_30192111:"Tampons encreurs",
   code_30192112:"Encre pour machines d'impression",
   code_30192113:"Cartouches d'encre",
   code_30192121:"Stylos à bille",
   code_30192122:"Stylos à encre",
   code_30192123:"Feutres",
   code_30192124:"Stylos-feutres",
   code_30192125:"Marqueurs",
   code_30192126:"Stylos techniques",
   code_30192127:"Porte-plumes",
   code_30192130:"Crayons",
   code_30192131:"Porte-mines",
   code_30192132:"Mines de crayon de rechange",
   code_30192133:"Taille-crayons",
   code_30192134:"Porte-crayons",
   code_30192150:"Timbres dateurs",
   code_30192151:"Tampons à cacheter",
   code_30192152:"Tampons numéroteurs",
   code_30192153:"Tampons avec texte",
   code_30192154:"Recharges pour tampons à timbrer",
   code_30192155:"Porte-tampons de bureau",
   code_30192160:"Correcteurs",
   code_30192170:"Tableaux d'affichage",
   code_30192200:"Mètres-rubans",
   code_30192300:"Rubans encreurs",
   code_30192310:"Rubans pour machines à écrire",
   code_30192320:"Rubans pour imprimantes",
   code_30192330:"Rubans et rouleaux pour calculatrices",
   code_30192340:"Rubans pour télécopieurs",
   code_30192350:"Rubans pour caisses enregistreuses",
   code_30192400:"Fournitures pour reprographie",
   code_30192500:"Transparents pour rétroprojecteurs",
   code_30192600:"Planches à dessin",
   code_30192700:"Papeterie",
   code_30192800:"Étiquettes autocollantes",
   code_30192900:"Outils de correction",
   code_30192910:"Film ou ruban correcteur",
   code_30192920:"Liquide correcteur",
   code_30192930:"Stylos correcteurs",
   code_30192940:"Recharges de stylos correcteurs",
   code_30192950:"Effaceurs électriques",
   code_30193000:"Organisateurs et accessoires",
   code_30193100:"Organisateurs de tiroirs",
   code_30193200:"Plateaux ou organisateurs pour bureau",
   code_30193300:"Classeurs à dossiers suspendus",
   code_30193400:"Serre-livres",
   code_30193500:"Présentoir d'imprimés",
   code_30193600:"Supports pour agendas ou calendriers",
   code_30193700:"Boîte de rangement de dossiers",
   code_30193800:"Supports de feuillets pour prise de message",
   code_30193900:"Porte-copies",
   code_30194000:"Fournitures pour dessin",
   code_30194100:"Pistolets à dessin",
   code_30194200:"Pastilles, bandes et films à dessin",
   code_30194210:"Pastilles ou bandes à dessin",
   code_30194220:"Films à dessin",
   code_30194300:"Kits, ensembles et papiers à dessin",
   code_30194310:"Kits ou ensembles à dessin",
   code_30194320:"Papiers à dessin",
   code_30194400:"Protections de table à dessin",
   code_30194500:"Trace-lettres",
   code_30194600:"Rapporteurs d'angles",
   code_30194700:"Normographes",
   code_30194800:"Équerres en T et triangles",
   code_30194810:"Équerres en T",
   code_30194820:"Triangles",
   code_30194900:"Rabats de protection de plans de travail",
   code_30195000:"Tableaux",
   code_30195100:"Plannings muraux ou accessoires",
   code_30195200:"Tableaux-copieurs électroniques ou accessoires",
   code_30195300:"Fournitures de bureau ou accessoires",
   code_30195400:"Tableaux effaçables à sec ou accessoires",
   code_30195500:"Tableaux ou accessoires",
   code_30195600:"Tableaux d'affichage ou accessoires",
   code_30195700:"Kits de nettoyage de tableaux ou accessoires",
   code_30195800:"Supports ou rails d'accrochage",
   code_30195900:"Tableaux blancs et tableaux magnétiques",
   code_30195910:"Tableaux blancs",
   code_30195911:"Accessoires pour tableaux blancs",
   code_30195912:"Présentoirs pour tableaux blancs",
   code_30195913:"Présentoirs pour tableaux à feuilles mobiles",
   code_30195920:"Tableaux magnétiques",
   code_30195921:"Effaceurs pour tableaux magnétiques",
   code_30196000:"Systèmes de planification",
   code_30196100:"Plannings de réunions",
   code_30196200:"Carnets de rendez-vous ou recharges",
   code_30196300:"Boîtes à suggestions",
   code_30197000:"Petit matériel de bureau",
   code_30197100:"Agrafes, pointes, punaises",
   code_30197110:"Agrafes",
   code_30197120:"Pointes",
   code_30197130:"Punaises",
   code_30197200:"Classeurs à anneaux et trombones",
   code_30197210:"Classeurs à anneaux",
   code_30197220:"Trombones à papier",
   code_30197221:"Sébiles à trombones",
   code_30197300:"Ouvre-lettres, agrafeuses et perforateurs à trous",
   code_30197310:"Ouvre-lettres",
   code_30197320:"Agrafeuses",
   code_30197321:"Dégrafeuses",
   code_30197330:"Perforateurs à trous",
   code_30197400:"Éponge à humecter les timbres",
   code_30197500:"Cire à cacheter",
   code_30197510:"Accessoires pour cire à cacheter",
   code_30197600:"Papier et carton traités",
   code_30197610:"Papier et carton assemblés",
   code_30197620:"Papier à écrire",
   code_30197621:"Bloc de feuilles pour tableaux à feuilles mobiles",
   code_30197630:"Papier d'impression",
   code_30197640:"Papier autocopiant ou autre papier à copies",
   code_30197641:"Papier thermographique",
   code_30197642:"Papier pour photocopie et papier pour xérographie",
   code_30197643:"Papier pour photocopie",
   code_30197644:"Papier pour xérographie",
   code_30197645:"Carte pour impression",
   code_30198000:"Machines de loterie",
   code_30198100:"Machines à sous",
   code_30199000:"Articles de papeterie et autres articles en papier",
   code_30199100:"Papier carbone, papier autocopiant, papier stencil et papier non carboné",
   code_30199110:"Papier carbone",
   code_30199120:"Papier autocopiant",
   code_30199130:"Papier non carboné",
   code_30199140:"Papier stencil",
   code_30199200:"Enveloppes, cartes-lettres et cartes postales non illustrées",
   code_30199210:"Cartes-lettres",
   code_30199220:"Cartes postales non illustrées",
   code_30199230:"Enveloppes",
   code_30199240:"Kit d'expédition de courrier",
   code_30199300:"Papier estampé ou perforé",
   code_30199310:"Papier d'impression estampé ou perforé",
   code_30199320:"Papier à lettres estampé ou perforé",
   code_30199330:"Papier en continu pour imprimantes d'ordinateurs",
   code_30199340:"Formulaires en continu",
   code_30199400:"Papier gommé ou adhésif",
   code_30199410:"Papier autocollant",
   code_30199500:"Boîtes-classeurs, classe-lettres, boîtes de rangement et articles similaires",
   code_30199600:"Intercalaires de papeterie",
   code_30199700:"Articles de papeterie imprimés, excepté les formulaires",
   code_30199710:"Enveloppes imprimées",
   code_30199711:"Enveloppes à fenêtre imprimées",
   code_30199712:"Enveloppes sans fenêtre imprimées",
   code_30199713:"Enveloppes pour radiographies imprimées",
   code_30199720:"Papier à lettres",
   code_30199730:"Cartes de visite",
   code_30199731:"Porte-cartes professionnelles",
   code_30199740:"Papillons",
   code_30199750:"Coupons",
   code_30199760:"Étiquettes",
   code_30199761:"Étiquettes à codes-barres",
   code_30199762:"Étiquettes pour bagages",
   code_30199763:"Etiquettes antivol",
   code_30199770:"Chèques-repas",
   code_30199780:"Sous-main",
   code_30199790:"Tableaux horaires",
   code_30199791:"Emplois du temps muraux",
   code_30199792:"Calendriers",
   code_30199793:"Supports d'agendas",
   code_30200000:"Matériel et fournitures informatiques",
   code_30210000:"Machines de traitement des données (matériel)",
   code_30211000:"Unité centrale de traitement",
   code_30211100:"Superordinateur",
   code_30211200:"Matériel pour unité centrale",
   code_30211300:"Plates-formes informatiques",
   code_30211400:"Configurations informatiques",
   code_30211500:"Unité centrale de traitement (CPU)",
   code_30212000:"Matériel pour mini-ordinateurs",
   code_30212100:"Unités centrales pour mini-ordinateurs",
   code_30213000:"Ordinateurs personnels",
   code_30213100:"Ordinateurs portables",
   code_30213200:"Ordinateur tablette",
   code_30213300:"Ordinateur de bureau",
   code_30213400:"Unités centrales pour ordinateurs personnels",
   code_30213500:"Ordinateurs de poche",
   code_30214000:"Postes de travail",
   code_30215000:"Matériel pour micro-ordinateurs",
   code_30215100:"Unités centrales pour micro-ordinateurs",
   code_30216000:"Lecteurs magnétiques ou optiques",
   code_30216100:"Lecteurs optiques",
   code_30216110:"Scanners informatiques",
   code_30216120:"Matériel de reconnaissance optique de caractères",
   code_30216130:"Lecteurs de codes-barres",
   code_30216200:"Lecteurs de cartes magnétiques",
   code_30216300:"Lecteurs de cartes perforées",
   code_30220000:"Matériel de cartographie numérique",
   code_30221000:"Plans cadastraux numériques",
   code_30230000:"Matériel informatique",
   code_30231000:"Écrans et consoles d'ordinateurs",
   code_30231100:"Terminaux informatiques",
   code_30231200:"Consoles",
   code_30231300:"Écrans de visualisation",
   code_30231310:"Écrans plats",
   code_30231320:"Moniteurs à écran tactile",
   code_30232000:"Périphériques (appareils)",
   code_30232100:"Imprimantes et traceurs",
   code_30232110:"Imprimantes laser",
   code_30232120:"Imprimantes matricielles",
   code_30232130:"Imprimantes graphiques couleur",
   code_30232140:"Traceurs",
   code_30232150:"Imprimantes à jet d'encre",
   code_30232600:"Codeurs",
   code_30232700:"Unité centrale de commande",
   code_30233000:"Unités de mémoire et de lecture",
   code_30233100:"Unités de mémoire",
   code_30233110:"Unités de mémoire à carte magnétique",
   code_30233120:"Unités de mémoire à bande magnétique",
   code_30233130:"Unités de mémoire à disque magnétique",
   code_30233131:"Unités de disques souples",
   code_30233132:"Unités de disque dur",
   code_30233140:"Unités de mémoire à accès sélectif (MAS)",
   code_30233141:"Matrice redondante de disques indépendants (RAID)",
   code_30233150:"Unités de disque optique",
   code_30233151:"Lecteur et/ou graveur de CD",
   code_30233152:"Lecteur et/ou graveur de DVD",
   code_30233153:"Lecteur et/ou graveur de CD et de DVD",
   code_30233160:"Dérouleurs en continu",
   code_30233161:"Matériel de manipulation de cassettes",
   code_30233170:"Carrousels",
   code_30233180:"Dispositifs de stockage à mémoire flash",
   code_30233190:"Contrôleur de disques",
   code_30233300:"Lecteurs de cartes à puce",
   code_30233310:"Lecteurs d'empreintes digitales",
   code_30233320:"Lecteurs combinés de cartes à puces et d'empreintes digitales",
   code_30234000:"Supports de mémoire",
   code_30234100:"Disque magnétique",
   code_30234200:"Disques optiques",
   code_30234300:"Disques compacts (CD)",
   code_30234400:"Disques numériques polyvalents (DVD)",
   code_30234500:"Supports de stockage à mémoire",
   code_30234600:"Mémoire flash",
   code_30234700:"Bandes magnétiques",
   code_30236000:"Matériel informatique divers",
   code_30236100:"Matériel d'extension de mémoire",
   code_30236110:"Mémoire vive (RAM)",
   code_30236111:"Mémoire vive dynamique (DRAM)",
   code_30236112:"Mémoire vive statique (SRAM)",
   code_30236113:"Mémoire vive dynamique synchrone (SDRAM)",
   code_30236114:"Mémoire vive dynamique en bus (RDRAM)",
   code_30236115:"Mémoire vive graphique synchrone (SGRAM)",
   code_30236120:"Mémoire morte (ROM)",
   code_30236121:"Mémoire morte programmable (PROM)",
   code_30236122:"Mémoire morte reprogrammable (EPROM)",
   code_30236123:"Mémoire morte reprogrammable électroniquement (EEPROM)",
   code_30236200:"Matériel de traitement de données",
   code_30237000:"Pièces, accessoires et fournitures pour ordinateurs",
   code_30237100:"Parties et pièces d'ordinateurs",
   code_30237110:"Interfaces de réseau",
   code_30237120:"Bornes d'entrée",
   code_30237121:"Ports infrarouges de série",
   code_30237130:"Cartes informatiques",
   code_30237131:"Cartes électroniques",
   code_30237132:"Bus série universel (USB)",
   code_30237133:"Adaptateurs et interfaces PCMCIA (Personal Computer Memory Card International Association)",
   code_30237134:"Cartes accélératrices graphiques",
   code_30237135:"Cartes d'interface réseau",
   code_30237136:"Cartes audio",
   code_30237140:"Cartes mères",
   code_30237200:"Accessoires informatiques",
   code_30237210:"Écrans antireflet",
   code_30237220:"Tapis de souris",
   code_30237230:"Caches",
   code_30237240:"Caméra web",
   code_30237250:"Accessoires de nettoyage pour ordinateur",
   code_30237251:"Kits de nettoyage pour ordinateur",
   code_30237252:"Aspirateurs pneumatiques pressurisés",
   code_30237253:"Housses pour matériel informatique",
   code_30237260:"Arbres de montage mural pour moniteurs",
   code_30237270:"Mallettes de transport d'ordinateur portable",
   code_30237280:"Accessoires d'alimentation",
   code_30237290:"Appuie-poignets pour clavier",
   code_30237295:"Protège-claviers",
   code_30237300:"Fournitures informatiques",
   code_30237310:"Cartouches de polices de caractères pour imprimantes",
   code_30237320:"Disquettes",
   code_30237330:"Cartouches de bandes audionumériques (DAT)",
   code_30237340:"Cartouches de bandes linéaires numériques (DLT)",
   code_30237350:"Chargeurs de données",
   code_30237360:"Cartouches de bandes linéaires ouvertes (LTO)",
   code_30237370:"Cartouches d'enregistrement",
   code_30237380:"CD-ROM",
   code_30237400:"Accessoires de saisie de données",
   code_30237410:"Souris d'ordinateur",
   code_30237420:"Manches à balai",
   code_30237430:"Photostyles",
   code_30237440:"Boules de commande",
   code_30237450:"Tablettes graphiques",
   code_30237460:"Claviers d'ordinateurs",
   code_30237461:"Claviers programmables",
   code_30237470:"Claviers braille",
   code_30237475:"Capteurs électriques",
   code_30237480:"Unités d'entrée",
   code_30238000:"Matériel d'automatisation de bibliothèque",
   code_31000000:"Machines, appareils, équipements et consommables électriques; éclairage",
   code_31100000:"Moteurs, générateurs et transformateurs électriques",
   code_31110000:"Moteurs électriques",
   code_31111000:"Adaptateurs",
   code_31120000:"Générateurs",
   code_31121000:"Groupes électrogènes",
   code_31121100:"Groupes électrogènes à moteur à allumage par compression",
   code_31121110:"Convertisseurs",
   code_31121111:"Convertisseurs électriques rotatifs",
   code_31121200:"Groupes électrogènes à moteurs à allumage par étincelle",
   code_31121300:"Aérogénérateurs",
   code_31121310:"Éoliennes",
   code_31121320:"Turbines éoliennes",
   code_31121330:"Générateurs à turbines éoliennes",
   code_31121331:"Rotors de turbine",
   code_31121340:"Ferme éolienne",
   code_31122000:"Groupes générateurs",
   code_31122100:"Piles à combustible",
   code_31124000:"Générateur à turbine à vapeur et appareillage",
   code_31124100:"Générateurs à turbines",
   code_31124200:"Appareils de commande de générateurs à turbines",
   code_31126000:"Dynamos",
   code_31127000:"Générateur de secours",
   code_31128000:"Turbogénérateur",
   code_31130000:"Alternateurs",
   code_31131000:"Moteurs monophasés",
   code_31131100:"Actionneurs",
   code_31131200:"Anodes",
   code_31132000:"Moteurs polyphasés",
   code_31140000:"Tours de refroidissement",
   code_31141000:"Refroidisseurs d'eau",
   code_31150000:"Ballast pour lampes ou tubes à décharge",
   code_31151000:"Convertisseurs statiques",
   code_31153000:"Redresseurs",
   code_31154000:"Sources d'alimentation électrique ininterruptible",
   code_31155000:"Onduleurs",
   code_31156000:"Alimentation interruptible en courant",
   code_31157000:"Inducteurs",
   code_31158000:"Chargeurs",
   code_31158100:"Chargeurs de batterie",
   code_31158200:"Compresseur d'alimentation",
   code_31158300:"Turbocompresseur",
   code_31160000:"Pièces pour moteurs, générateurs et transformateurs électriques",
   code_31161000:"Pièces pour moteurs et générateurs électriques",
   code_31161100:"Systèmes d'excitation",
   code_31161200:"Systèmes de refroidissement de gaz",
   code_31161300:"Rotors de générateurs",
   code_31161400:"Systèmes d'eau primaire",
   code_31161500:"Systèmes d'huile d'étanchéité",
   code_31161600:"Systèmes d'eau de refroidissement de stators",
   code_31161700:"Pièces de générateurs de vapeur",
   code_31161800:"Pièces pour générateurs de gaz",
   code_31161900:"Systèmes de réglage de tension",
   code_31162000:"Pièces de transformateurs, d'inducteurs et de convertisseurs statiques",
   code_31162100:"Pièces de condenseurs",
   code_31170000:"Transformateurs",
   code_31171000:"Transformateurs à diélectrique liquide",
   code_31172000:"Transformateurs de tension",
   code_31173000:"Transformateur de mesure",
   code_31174000:"Transformateurs d'alimentation",
   code_31200000:"Appareils de distribution et de commande électriques",
   code_31210000:"Appareils électriques de commutation ou de protection de circuits électriques",
   code_31211000:"Tableaux et boîtes à fusibles",
   code_31211100:"Tableaux de commande électriques",
   code_31211110:"Tableaux de contrôle",
   code_31211200:"Boîtes à fusibles",
   code_31211300:"Fusibles",
   code_31211310:"Coupe-circuits",
   code_31211320:"Blocs à fusibles",
   code_31211330:"Fils fusibles",
   code_31211340:"Douilles de fusibles",
   code_31212000:"Disjoncteurs",
   code_31212100:"Disjoncteurs suspendus",
   code_31212200:"Testeurs de circuits",
   code_31212300:"Coupe-circuits magnétiques",
   code_31212400:"Coupe-circuits miniatures",
   code_31213000:"Équipement de distribution",
   code_31213100:"Boîtes de distribution",
   code_31213200:"Transformateurs de distribution",
   code_31213300:"Boîtiers de distribution par câble",
   code_31213400:"Système de distribution",
   code_31214000:"Appareillage de commutation",
   code_31214100:"Interrupteurs",
   code_31214110:"Sectionneurs",
   code_31214120:"Interrupteur de mise à la terre",
   code_31214130:"Interrupteurs de sécurité",
   code_31214140:"Commandes d'intensité d'éclairage",
   code_31214150:"Interrupteurs à tambour",
   code_31214160:"Interrupteurs à pression",
   code_31214170:"Interrupteurs à lÉvier",
   code_31214180:"Interrupteurs à glissière",
   code_31214190:"Interrupteurs de fin de course",
   code_31214200:"Déconnecteur",
   code_31214300:"Appareillage de commutation pour l'extérieur",
   code_31214400:"Sectionneur à fusible",
   code_31214500:"Tableaux électriques",
   code_31214510:"Tableaux de distribution",
   code_31214520:"Tableau moyenne tension",
   code_31215000:"Limiteurs de tension",
   code_31216000:"Parafoudre",
   code_31216100:"Équipement de protection contre la foudre",
   code_31216200:"Paratonnerre",
   code_31217000:"Limiteurs de surcharge",
   code_31218000:"Barres omnibus",
   code_31219000:"Boîtiers de protection",
   code_31220000:"Composants de circuits électriques",
   code_31221000:"Relais électriques",
   code_31221100:"Relais de puissance",
   code_31221200:"Relais polyvalents",
   code_31221300:"Relais à douille",
   code_31221400:"Relais à tension alternative",
   code_31221500:"Relais au mercure",
   code_31221600:"Relais retardés",
   code_31221700:"Relais de surcharge",
   code_31223000:"Douilles",
   code_31224000:"Connexions et éléments de contact",
   code_31224100:"Fiches et prises de courant",
   code_31224200:"Connecteurs coaxiaux",
   code_31224300:"Boîtes de raccordement",
   code_31224400:"Câbles de connexion",
   code_31224500:"Bornes",
   code_31224600:"Atténuateurs",
   code_31224700:"Boîtes de jonction",
   code_31224800:"Kits de raccordement de câbles",
   code_31224810:"Câbles prolongateurs",
   code_31230000:"Pièces pour appareils de distribution ou de commande électrique",
   code_31300000:"Fils et câbles isolés",
   code_31310000:"Canalisations",
   code_31311000:"Connexions de réseau",
   code_31320000:"Câbles de distribution électrique",
   code_31321000:"Lignes électriques",
   code_31321100:"Lignes électriques aériennes",
   code_31321200:"Câble basse et moyenne tension",
   code_31321210:"Câble basse tension",
   code_31321220:"Câble moyenne tension",
   code_31321300:"Câble haute tension",
   code_31321400:"Câble subaquatique",
   code_31321500:"Câble sous-marin",
   code_31321600:"Câble blindé",
   code_31321700:"Câble de signalisation",
   code_31330000:"Câbles coaxiaux",
   code_31340000:"Accessoires de câble, isolés",
   code_31341000:"Bobines de câbles, isolées",
   code_31342000:"Embranchements de câbles, isolés",
   code_31343000:"Jonctions de câbles, isolées",
   code_31344000:"Serre-garnitures de câbles, isolées",
   code_31350000:"Conducteurs électriques pour données et contrôle",
   code_31351000:"Conducteurs électriques pour systèmes de contrôle d'accès",
   code_31400000:"Accumulateurs, piles et batteries primaires",
   code_31410000:"Piles primaires",
   code_31411000:"Piles alcalines",
   code_31420000:"Batteries primaires",
   code_31421000:"Batteries au plomb",
   code_31422000:"Groupes de batteries",
   code_31430000:"Accumulateurs électriques",
   code_31431000:"Accumulateurs au plomb",
   code_31432000:"Accumulateurs au nickel-cadmium",
   code_31433000:"Accumulateurs au nickel-fer",
   code_31434000:"Accumulateurs au lithium",
   code_31440000:"Batteries",
   code_31500000:"Appareils d'éclairage et lampes électriques",
   code_31510000:"Lampes électriques à incandescence",
   code_31511000:"Phares scellés",
   code_31512000:"Lampes à incandescence à halogène",
   code_31512100:"Ampoules halogène, linéaires",
   code_31512200:"Ampoules halogène, bicontact",
   code_31512300:"Ampoules halogène, dichroïques",
   code_31514000:"Lampes à décharge",
   code_31515000:"Émetteurs ultraviolets",
   code_31516000:"Émetteurs infrarouges",
   code_31517000:"Lampes à arc",
   code_31518000:"Feux de signalisation",
   code_31518100:"Projecteurs",
   code_31518200:"Matériel d'éclairage de secours",
   code_31518210:"Lampes-tempête",
   code_31518220:"Bâton lumineux",
   code_31518300:"Luminaires de toiture",
   code_31518500:"Lampes à vapeur de mercure",
   code_31518600:"Projecteurs orientables",
   code_31519000:"Lampes à incandescence et lampes au néon",
   code_31519100:"Lampes à incandescence",
   code_31519200:"Lampes au néon",
   code_31520000:"Lampes et appareils d'éclairage",
   code_31521000:"Lampes",
   code_31521100:"Lampes de bureau",
   code_31521200:"Lampadaires",
   code_31521300:"Lampes électriques portatives",
   code_31521310:"Avertisseurs lumineux",
   code_31521320:"Torches",
   code_31521330:"Lampes électriques portatives rechargeables",
   code_31522000:"Lampes décoratives pour arbres de Noël",
   code_31523000:"Enseignes et plaques indicatrices lumineuses",
   code_31523100:"Enseignes au néon",
   code_31523200:"Panneaux à messages permanents",
   code_31523300:"Plaques indicatrices lumineuses",
   code_31524000:"Plafonniers ou appliques murales",
   code_31524100:"Appareils d'éclairage à fixer au plafond",
   code_31524110:"Scialytiques",
   code_31524120:"Plafonniers",
   code_31524200:"Accessoires d'appliques murales",
   code_31524210:"Appliques murales",
   code_31527000:"Spots",
   code_31527200:"Éclairage extérieur",
   code_31527210:"Lanternes",
   code_31527260:"Systèmes d'éclairage",
   code_31527270:"Éclairage de quai",
   code_31527300:"Éclairage domestique",
   code_31527400:"Eclairages sous-marin",
   code_31530000:"Pièces de lampes et d'appareils d'éclairage",
   code_31531000:"Ampoules",
   code_31531100:"Tubes d'éclairage",
   code_31532000:"Pièces de lampes et accessoires d'éclairage",
   code_31532100:"Lampes à tube",
   code_31532110:"Lampes à tube fluorescent",
   code_31532120:"Lampes à tube fluorescent compactes",
   code_31532200:"Lampes annulaires",
   code_31532210:"Lampes annulaires fluorescentes",
   code_31532300:"Lampes globe",
   code_31532310:"Lampes globe fluorescentes compactes",
   code_31532400:"Douilles de lampes",
   code_31532500:"Interrupteurs de lampes",
   code_31532510:"Interrupteurs de lampes fluorescentes",
   code_31532600:"Bobines de réactance de lampes",
   code_31532610:"Bobines de réactance de lampes fluorescentes",
   code_31532700:"Écran protecteur de lampe",
   code_31532800:"Bras de lampe",
   code_31532900:"Lampes fluorescentes",
   code_31532910:"Tubes fluorescents",
   code_31532920:"Ampoules et lampes fluorescentes",
   code_31600000:"Matériel électrique",
   code_31610000:"Matériel électrique pour moteurs et véhicules",
   code_31611000:"Jeux de câblage",
   code_31612000:"Câblage électrique préassemblé pour moteurs",
   code_31612200:"Moteurs de démarrage",
   code_31612300:"Matériel électrique de signalisation pour moteurs",
   code_31612310:"Clignotants",
   code_31620000:"Appareils de signalisation acoustique ou optique",
   code_31625000:"Avertisseurs d'effraction et d'incendie",
   code_31625100:"Systèmes de détection d'incendie",
   code_31625200:"Systèmes d'alarme incendie",
   code_31625300:"Systèmes avertisseurs d'effraction",
   code_31630000:"Aimants",
   code_31640000:"Machines et appareils à usage spécifique",
   code_31642000:"Appareils de détection électronique",
   code_31642100:"Détecteurs de tubes métalliques",
   code_31642200:"Détecteurs de mines",
   code_31642300:"Détecteurs de matières plastiques",
   code_31642400:"Détecteurs d'objets non métalliques",
   code_31642500:"Détecteurs de bois",
   code_31643000:"Accélérateurs de particules",
   code_31643100:"Accélérateurs linéaires",
   code_31644000:"Enregistreurs de données divers",
   code_31645000:"Billards électriques",
   code_31650000:"Pièces isolantes",
   code_31651000:"Ruban isolant",
   code_31660000:"Électrodes au carbone",
   code_31670000:"Pièces électriques de machines ou d'appareils",
   code_31671000:"Enveloppes de verre et tubes cathodiques",
   code_31671100:"Enveloppes en verre",
   code_31671200:"Tubes cathodiques",
   code_31680000:"Fournitures et accessoires électriques",
   code_31681000:"Accessoires électriques",
   code_31681100:"Contacts électriques",
   code_31681200:"Pompes électriques",
   code_31681300:"Circuits électriques",
   code_31681400:"Composants électriques",
   code_31681410:"Matériels électriques",
   code_31681500:"Rechargeurs",
   code_31682000:"Approvisionnement en électricité",
   code_31682100:"Coffres électriques",
   code_31682110:"Couvercles de coffret de branchement",
   code_31682200:"Tableaux d'instruments",
   code_31682210:"Instruments et équipement de commande et de contrôle",
   code_31682220:"Tables de mixage",
   code_31682230:"Panneaux à écran graphique",
   code_31682300:"Équipement moyenne tension",
   code_31682310:"Panneaux moyenne tension",
   code_31682400:"Équipement électrique aérien",
   code_31682410:"Supports de câbles aériens",
   code_31682500:"Équipement électrique de secours",
   code_31682510:"Systèmes électriques de secours",
   code_31682520:"Systèmes d'arrêt d'urgence",
   code_31682530:"Alimentation électrique de secours",
   code_31682540:"Équipement de sous-station",
   code_31700000:"Fournitures électroniques, électromécaniques et électrotechniques",
   code_31710000:"Équipement électronique",
   code_31711000:"Fournitures électroniques",
   code_31711100:"Composants électroniques",
   code_31711110:"Émetteurs-récepteurs",
   code_31711120:"Transducteurs",
   code_31711130:"Résistances",
   code_31711131:"Résistances électriques",
   code_31711140:"Électrodes",
   code_31711150:"Condensateurs électriques",
   code_31711151:"Condensateurs fixes",
   code_31711152:"Condensateurs variables ou réglables",
   code_31711154:"Batteries de condensateurs",
   code_31711155:"Réseaux de condensateurs",
   code_31711200:"Tableaux indicateurs électroniques",
   code_31711300:"Systèmes électroniques d'enregistrement des temps",
   code_31711310:"Pointeuse",
   code_31711400:"Valves et tubes",
   code_31711410:"Tubes cathodiques pour récepteurs de télévision",
   code_31711411:"Tubes pour caméras de télévision",
   code_31711420:"Tubes et équipement pour hyperfréquences",
   code_31711421:"Magnétrons",
   code_31711422:"Équipement à hyperfréquences",
   code_31711423:"Équipement hertzien",
   code_31711424:"Klystrons",
   code_31711430:"Tubes électroniques",
   code_31711440:"Tubes de récepteur ou d'amplificateur",
   code_31711500:"Pièces pour assemblages électroniques",
   code_31711510:"Pièces pour condensateurs électriques",
   code_31711520:"Pièces pour résistances électriques, rhéostats et potentiomètres",
   code_31711530:"Pièces pour valves et tubes électroniques",
   code_31712000:"Machines et appareils microélectroniques et microsystèmes",
   code_31712100:"Machines et appareils microélectroniques",
   code_31712110:"Circuits intégrés et microassemblages électroniques",
   code_31712111:"Cartes téléphoniques",
   code_31712112:"Cartes SIM",
   code_31712113:"Cartes à circuits intégrés",
   code_31712114:"Circuits intégrés électroniques",
   code_31712115:"Microassemblages",
   code_31712116:"Microprocesseurs",
   code_31712117:"Boîtiers de circuits intégrés",
   code_31712118:"Supports ou montures de circuits intégrés",
   code_31712119:"Capots de circuits intégrés",
   code_31712200:"Microsystèmes",
   code_31712300:"Circuits imprimés",
   code_31712310:"Cartes de circuits imprimés garnies",
   code_31712320:"Cartes de circuits imprimés non garnies",
   code_31712330:"Semi-conducteurs",
   code_31712331:"Cellules photovoltaïques",
   code_31712332:"Thyristors",
   code_31712333:"Diacs",
   code_31712334:"Triacs",
   code_31712335:"Coupleurs optiques",
   code_31712336:"Oscillateurs à quartz",
   code_31712340:"Diodes",
   code_31712341:"Diodes électroluminescentes",
   code_31712342:"Diodes micro-ondes ou diodes à petits signaux",
   code_31712343:"Diodes Zener",
   code_31712344:"Diodes Schottky",
   code_31712345:"Diodes tunnel",
   code_31712346:"Diodes photosensibles",
   code_31712347:"Diodes de puissance ou diodes solaires",
   code_31712348:"Diodes laser",
   code_31712349:"Diodes à radiofréquence (RF)",
   code_31712350:"Transistors",
   code_31712351:"Transistors photosensibles",
   code_31712352:"Transistors à effet de champ (FET)",
   code_31712353:"Transistors à effet de champ à oxdes métalliques (MOSFET)",
   code_31712354:"Puces à transistor",
   code_31712355:"Transistors bipolaires darlington ou transistors à radiofréquence (RF)",
   code_31712356:"Transistors unijonction",
   code_31712357:"Transistors bipolaires à grille isolée (IGBT)",
   code_31712358:"Transistors à effet de champ à jonction (JFET)",
   code_31712359:"Transistors bipolaires à jonction (BJT)",
   code_31712360:"Cristaux piézoélectriques montés",
   code_31720000:"Équipement électromécanique",
   code_31730000:"Équipement électrotechnique",
   code_31731000:"Fournitures électrotechniques",
   code_31731100:"Modules",
   code_32000000:"Équipements et appareils de radio, de télévision, de communication, de télécommunication et équipements connexes",
   code_32200000:"Appareils émetteurs de radiotéléphonie, de radiotélégraphie, de radiodiffusion et de télévision",
   code_32210000:"Matériel de radiodiffusion et de télévision",
   code_32211000:"Matériel de production pour radiodiffusion et télévision",
   code_32220000:"Appareils émetteurs de télévision sans appareils récepteurs",
   code_32221000:"Radiobalises",
   code_32222000:"Appareils de codage de signaux vidéo",
   code_32223000:"Appareils de transmission vidéo",
   code_32224000:"Appareils émetteurs de télévision",
   code_32230000:"Appareils émetteurs de radio avec appareils récepteurs",
   code_32231000:"Appareils de télévision en circuit fermé",
   code_32232000:"Matériel de visioconférence",
   code_32233000:"Postes d'amplification de fréquences radio",
   code_32234000:"Caméras de télévision en circuit fermé",
   code_32235000:"Système de surveillance en circuit fermé",
   code_32236000:"Radiotéléphones",
   code_32237000:"Talkies-walkies",
   code_32240000:"Caméras de télévision",
   code_32250000:"Téléphones mobiles",
   code_32251000:"Téléphones de voiture",
   code_32251100:"Ensembles mains-libres",
   code_32252000:"Téléphones GSM",
   code_32252100:"Téléphones mobiles mains-libres",
   code_32252110:"Téléphones mobiles mains-libres (sans fil)",
   code_32260000:"Matériel de transmission de données",
   code_32270000:"Appareils de transmission numérique",
   code_32300000:"Récepteurs de télévision et de radio et appareils d'enregistrement ou de reproduction du son ou de l'image",
   code_32310000:"Récepteurs de radiodiffusion",
   code_32320000:"Matériel de télévision et matériel audiovisuel",
   code_32321000:"Matériel de projection télévisée",
   code_32321100:"Appareils pour films et vidéo",
   code_32321200:"Équipement audiovisuel",
   code_32321300:"Matériel audiovisuel",
   code_32322000:"Équipement multimédia",
   code_32323000:"Moniteurs vidéo",
   code_32323100:"Moniteurs vidéo couleur",
   code_32323200:"Moniteurs vidéo monochrome",
   code_32323300:"Matériel vidéo",
   code_32323400:"Matériel de reproduction vidéo",
   code_32323500:"Système de surveillance vidéo",
   code_32324000:"Récepteurs de télévision",
   code_32324100:"Récepteurs de télévision couleur",
   code_32324200:"Récepteurs de télévision monochrome",
   code_32324300:"Matériel de télévision",
   code_32324310:"Antennes satellitaires",
   code_32324400:"Antennes de télévision",
   code_32324500:"Syntoniseurs vidéo",
   code_32324600:"Décodeurs de télévision numérique",
   code_32330000:"Appareils d'enregistrement et de reproduction audio et vidéo",
   code_32331000:"Plateaux pour phonographes",
   code_32331100:"Tourne-disques",
   code_32331200:"Lecteurs de cassettes",
   code_32331300:"Appareils de reproduction audio",
   code_32331500:"Enregistreurs",
   code_32331600:"Lecteur MP3",
   code_32332000:"Enregistreurs à bande magnétique",
   code_32332100:"Dictaphones",
   code_32332200:"Répondeurs téléphoniques",
   code_32332300:"Enregistreurs de son",
   code_32333000:"Appareils d'enregistrement ou de reproduction vidéo",
   code_32333100:"Magnétoscopes",
   code_32333200:"Caméscopes",
   code_32333300:"Appareils de reproduction vidéophonique",
   code_32333400:"Lecteurs vidéo",
   code_32340000:"Micros et haut-parleurs",
   code_32341000:"Micros",
   code_32342000:"Haut-parleurs",
   code_32342100:"Casques d'écoute",
   code_32342200:"Écouteurs",
   code_32342300:"Microphones et haut-parleurs",
   code_32342400:"Dispositifs acoustiques",
   code_32342410:"Matériel de sonorisation",
   code_32342411:"Mini-haut-parleurs",
   code_32342412:"Enceintes",
   code_32342420:"Tables de mixage en studio",
   code_32342430:"Système de compression de la parole",
   code_32342440:"Système de messagerie vocale",
   code_32342450:"Enregistreurs de voix",
   code_32343000:"Amplificateurs",
   code_32343100:"Amplificateurs d'audiofréquences",
   code_32343200:"Mégaphones",
   code_32344000:"Appareils récepteurs de radiotéléphonie ou de radiotélégraphie",
   code_32344100:"Récepteurs portables d'appel et de radiomessagerie",
   code_32344110:"Système d'enregistrement de voix",
   code_32344200:"Récepteurs radio",
   code_32344210:"Matériel radio",
   code_32344220:"Récepteurs de radiomessagerie",
   code_32344230:"Stations radio",
   code_32344240:"Relais hertzien",
   code_32344250:"Installations radio",
   code_32344260:"Équipement radio et de multiplexage",
   code_32344270:"Système de commande radio et téléphonique",
   code_32344280:"Radios portables",
   code_32350000:"Pièces pour matériel audio et vidéo",
   code_32351000:"Accessoires pour matériel audio et vidéo",
   code_32351100:"Matériel de montage vidéo",
   code_32351200:"Écrans",
   code_32351300:"Accessoires pour matériel audio",
   code_32351310:"Cassettes audio",
   code_32352000:"Antennes et réflecteurs",
   code_32352100:"Pièces pour matériel radio et radar",
   code_32352200:"Pièces détachées et accessoires pour radars",
   code_32353000:"Enregistrements sonores",
   code_32353100:"Disques",
   code_32353200:"Cassettes musicales",
   code_32354000:"Films",
   code_32354100:"Films de radiologie",
   code_32354110:"Films radiographiques",
   code_32354120:"Film diazo",
   code_32354200:"Films cinématographiques",
   code_32354300:"Pellicules photographiques",
   code_32354400:"Pellicules à développement instantané",
   code_32354500:"Films vidéo",
   code_32354600:"Cassettes vidéo",
   code_32354700:"Bandes vidéo",
   code_32354800:"Pellicule rétractable",
   code_32360000:"Matériel d'interphonie",
   code_32400000:"Réseaux",
   code_32410000:"Réseau local",
   code_32411000:"Réseau en anneau à jeton",
   code_32412000:"Réseau de communications",
   code_32412100:"Réseau de télécommunications",
   code_32412110:"Réseau internet",
   code_32412120:"Réseau intranet",
   code_32413000:"Réseau intégré",
   code_32413100:"Routeurs",
   code_32415000:"Réseau éthernet",
   code_32416000:"Réseau RNIS",
   code_32416100:"Réseau ISDX",
   code_32417000:"Réseaux multimédia",
   code_32418000:"Réseau radio",
   code_32420000:"Matériel de réseau",
   code_32421000:"Câblage de réseau",
   code_32422000:"Composants de réseau",
   code_32423000:"Nœuds de réseau",
   code_32424000:"Infrastructure de réseau",
   code_32425000:"Système d'exploitation de réseau",
   code_32426000:"Système de publication sur réseau",
   code_32427000:"Système de réseau",
   code_32428000:"Extension de réseau",
   code_32429000:"Matériel de réseau téléphonique",
   code_32430000:"Réseau à grande distance",
   code_32440000:"Matériel de télémétrie et équipement terminal",
   code_32441000:"Matériel de télémétrie",
   code_32441100:"Système de surveillance télémétrique",
   code_32441200:"Matériel de télémétrie et de commande",
   code_32441300:"Système de télématique",
   code_32442000:"Équipement terminal",
   code_32442100:"Plaquettes de connexion",
   code_32442200:"Boîtes de connexion",
   code_32442300:"Émulateurs de terminal",
   code_32442400:"Blocs terminaux",
   code_32500000:"Matériel de télécommunications",
   code_32510000:"Système de télécommunications sans fil",
   code_32520000:"Câbles et matériel de télécommunications",
   code_32521000:"Câbles de télécommunications",
   code_32522000:"Équipements de télécommunications",
   code_32523000:"Infrastructures de télécommunications",
   code_32524000:"Système de télécommunications",
   code_32530000:"Matériel de communication par satellite",
   code_32531000:"Matériel de transmission d'informations par satellite",
   code_32532000:"Antennes paraboliques",
   code_32533000:"Stations terrestres de satellites",
   code_32534000:"Plates-formes satellitaires",
   code_32540000:"Standards téléphoniques",
   code_32541000:"Matériel de standard téléphonique",
   code_32542000:"Tableaux de commutation téléphonique",
   code_32543000:"Commutateurs téléphoniques",
   code_32544000:"Matériel PABX",
   code_32545000:"Systèmes PABX",
   code_32546000:"Matériel de commutation téléphonique numérique",
   code_32546100:"Standards téléphoniques numériques",
   code_32547000:"Standards téléphoniques à vide",
   code_32550000:"Matériel téléphonique",
   code_32551000:"Câbles téléphoniques et matériel connexe",
   code_32551100:"Raccordements téléphoniques",
   code_32551200:"Centraux téléphoniques",
   code_32551300:"Casques d'écoute téléphonique",
   code_32551400:"Réseau téléphonique",
   code_32551500:"Câbles téléphoniques",
   code_32552000:"Appareils électriques pour téléphonie ou télégraphie par fil",
   code_32552100:"Combinés téléphoniques",
   code_32552110:"Téléphones sans fil",
   code_32552120:"Postes téléphoniques de secours",
   code_32552130:"Téléphones publics",
   code_32552140:"Matériel téléphonique pour cabines publiques",
   code_32552150:"Téléphones pour malvoyants",
   code_32552160:"Téléphones pour malentendants",
   code_32552200:"Téléimprimeurs",
   code_32552300:"Appareils de commutation téléphonique ou télégraphique",
   code_32552310:"Centraux téléphoniques numériques",
   code_32552320:"Multiplexeurs",
   code_32552330:"Appareils de commutation téléphonique",
   code_32552400:"Appareils de conversion de signaux audibles",
   code_32552410:"Modems",
   code_32552420:"Convertisseur de fréquence",
   code_32552430:"Matériel de codage",
   code_32552500:"Appareils télétexte",
   code_32552510:"Terminaux vidéotexte",
   code_32552520:"Télex",
   code_32552600:"Interphones",
   code_32553000:"Pièces détachées pour appareils électriques téléphoniques ou télégraphiques",
   code_32560000:"Matériaux à fibres optiques",
   code_32561000:"Connexions à fibres optiques",
   code_32562000:"Câbles à fibres optiques",
   code_32562100:"Câbles à fibres optiques pour la transmission d'informations",
   code_32562200:"Câbles de télécommunications à fibres optiques",
   code_32562300:"Câbles à fibres optiques pour la transmission de données",
   code_32570000:"Matériel de communications",
   code_32571000:"Infrastructure de communications",
   code_32572000:"Câbles de communications",
   code_32572100:"Câble de communications à conducteurs électriques multiples",
   code_32572200:"Câble de communications à conducteurs coaxiaux",
   code_32572300:"Câble de communications à usage spécifique",
   code_32573000:"Système de commande de communications",
   code_32580000:"Équipement de traitement de données",
   code_32581000:"Matériel de communication de données",
   code_32581100:"Câble de transmission de données",
   code_32581110:"Câble de transmission de données à conducteurs électriques multiples",
   code_32581120:"Câble de transmission de données à conducteurs coaxiaux",
   code_32581130:"Câble de transmission de données à usage spécifique",
   code_32581200:"Matériel de fax",
   code_32581210:"Accessoires et composants pour matériel de fax",
   code_32582000:"Supports de données",
   code_32583000:"Supports de données et de voix",
   code_32584000:"Supports d'informations",
   code_33000000:"Matériels médicaux, pharmaceutiques et produits de soins personnnels",
   code_33100000:"Équipements médicaux",
   code_33110000:"Matériel d'imagerie à usages médical, dentaire et vétérinaire",
   code_33111000:"Appareils de radiographie",
   code_33111100:"Table de radiologie",
   code_33111200:"Postes de travail de radiologie",
   code_33111300:"Machines à développer radiologiques",
   code_33111400:"Fluoroscopes",
   code_33111500:"Radiographie dentaire",
   code_33111600:"Instruments de radiographie",
   code_33111610:"Unité de résonance magnétique",
   code_33111620:"Gamma caméras",
   code_33111640:"Thermographes",
   code_33111650:"Appareils de mammographie",
   code_33111660:"Ostéodensitomètres",
   code_33111700:"Salle d'angiographie",
   code_33111710:"Fournitures pour l'angiographie",
   code_33111720:"Appareils d'angiographie",
   code_33111721:"Appareils d'angiographie numérique",
   code_33111730:"Fournitures pour l'angioplastie",
   code_33111740:"Appareils d'angioplastie",
   code_33111800:"Système de radiodiagnostic",
   code_33112000:"Matériel d'imagerie échographique, ultrasonore et Doppler",
   code_33112100:"Détecteur échocardiographique",
   code_33112200:"Échographe",
   code_33112300:"Scanographes ultrasoniques",
   code_33112310:"Doppler couleur",
   code_33112320:"Appareils Doppler",
   code_33112330:"Échoencéphalographes",
   code_33112340:"Échocardiographes",
   code_33113000:"Matériel d'imagerie à résonance magnétique",
   code_33113100:"Scanographes à résonance magnétique",
   code_33113110:"Scanographes à résonance magnétique nucléaire",
   code_33114000:"Appareils de spectroscopie",
   code_33115000:"Appareils de tomographie",
   code_33115100:"Tomodensitomètres",
   code_33115200:"Tomographes axiaux transverses couplés avec un ordinateur",
   code_33120000:"Systèmes d'enregistrement et appareils d'exploration",
   code_33121000:"Système d'enregistrement ambulatoire de longue durée",
   code_33121100:"Électroencéphalographes",
   code_33121200:"Appareils de scintigraphie",
   code_33121300:"Électromyographes",
   code_33121400:"Audiomètres",
   code_33121500:"Électrocardiogramme",
   code_33122000:"Équipement d'ophthalmologie",
   code_33123000:"Matériel pour exploration cardio-vasculaire",
   code_33123100:"Tensiomètre",
   code_33123200:"Appareils d'électrocardiographie",
   code_33123210:"Système de surveillance cardiaque",
   code_33123220:"Angiocardiographie",
   code_33123230:"Cardiographes",
   code_33124000:"Équipements et matériels de diagnostic et de radiodiagnostic",
   code_33124100:"Appareils de diagnostic",
   code_33124110:"Systèmes de diagnostic",
   code_33124120:"Appareils de diagnostic à ultrasons",
   code_33124130:"Fournitures pour diagnostic",
   code_33124131:"Bandelettes réactives",
   code_33124200:"Équipements de radiodiagnostic",
   code_33124210:"Matériels de radiodiagnostic",
   code_33125000:"Matériel pour exploration urologique",
   code_33126000:"Équipement pour stomatologie",
   code_33127000:"Équipement pour immuno-analyse",
   code_33128000:"Laser médical autre que chirurgical",
   code_33130000:"Instruments et appareils de dentisterie et de ses sous-spécialités",
   code_33131000:"Instruments dentaires",
   code_33131100:"Instruments de chirurgie dentaire",
   code_33131110:"Pinces, brosses, écarteurs et brunissoirs dentaires",
   code_33131111:"Pinces dentaires",
   code_33131112:"Brosses dentaires",
   code_33131113:"Écarteurs dentaires",
   code_33131114:"Brunissoirs dentaires",
   code_33131120:"Instruments de cryochirurgie dentaire, jauges de profondeur, élévateurs et excavateurs dentaires",
   code_33131121:"Instruments de cryochirurgie dentaire",
   code_33131122:"Jauges de profondeur dentaire",
   code_33131123:"Élévateurs dentaires",
   code_33131124:"Excavateurs dentaires",
   code_33131130:"Protections de doigt à usage dentaire et daviers",
   code_33131131:"Protections de doigt à usage dentaire",
   code_33131132:"Daviers",
   code_33131140:"Miroirs dentaires et alésoirs dentaires",
   code_33131141:"Miroirs dentaires",
   code_33131142:"Alésoirs dentaires",
   code_33131150:"Poinçons pour apex de la racine dentaire, instruments à détartrer et échelles dentaires",
   code_33131151:"Poinçons pour apex de la racine dentaire",
   code_33131152:"Instruments à détartrer",
   code_33131153:"Échelles dentaires",
   code_33131160:"Ciseaux et bistouris dentaires",
   code_33131161:"Ciseaux dentaires",
   code_33131162:"Bistouris dentaires",
   code_33131170:"Spatules dentaires, brucelles et spatules pour cire dentaire",
   code_33131171:"Spatules dentaires",
   code_33131172:"Brucelles dentaires",
   code_33131173:"Spatules pour cire dentaire",
   code_33131200:"Aiguilles dentaires à suture",
   code_33131300:"Instruments dentaires à usage unique",
   code_33131400:"Sonde dentaire",
   code_33131500:"Instrument d'extraction",
   code_33131510:"Forets dentaires",
   code_33131600:"Instrument d'obturation",
   code_33132000:"Implant utilisé en odontostomatologie",
   code_33133000:"Petit matériel pour empreintes dentaires",
   code_33134000:"Petit matériel d'endodontie",
   code_33135000:"Dispositifs orthodontiques",
   code_33136000:"Instrument rotatif et abrasif",
   code_33137000:"Petit matériel de prophylaxie dentaire",
   code_33138000:"Produits pour prothèses et rebasage",
   code_33138100:"Dentiers",
   code_33140000:"Consommables médicaux",
   code_33141000:"Consommables médicaux autres que chimiques à usage unique et consommables hématologiques",
   code_33141100:"Pansements; clips, sutures, ligatures",
   code_33141110:"Pansements",
   code_33141111:"Pansements adhésifs",
   code_33141112:"Sparadraps",
   code_33141113:"Bandages",
   code_33141114:"Gaze à usage médical",
   code_33141115:"Ouate médicale",
   code_33141116:"Tampons et compresses",
   code_33141117:"Ouate",
   code_33141118:"Compresses de gaze",
   code_33141119:"Compresses",
   code_33141120:"Clips, sutures, ligatures",
   code_33141121:"Sutures chirurgicales",
   code_33141122:"Agrafes chirurgicales",
   code_33141123:"Collecteurs d'aiguilles",
   code_33141124:"Collecteurs d'instruments pointus et tranchants",
   code_33141125:"Matériel de suture chirurgical",
   code_33141126:"Ligatures",
   code_33141127:"Hémostatiques résorbables",
   code_33141128:"Aiguilles à sutures",
   code_33141200:"Cathéters",
   code_33141210:"Cathéters à ballonnet",
   code_33141220:"Canules",
   code_33141230:"Dilatateur",
   code_33141240:"Accessoires de cathéter",
   code_33141300:"Dispositifs de ponction veineuse et de prélèvement sanguin",
   code_33141310:"Seringues",
   code_33141320:"Aiguilles à usage médical",
   code_33141321:"Aiguilles d'anesthésie",
   code_33141322:"Aiguilles à usage artériel",
   code_33141323:"Aiguilles de biopsie",
   code_33141324:"Aiguilles de dialyse",
   code_33141325:"Aiguillles à fistule",
   code_33141326:"Aiguilles de radiologie",
   code_33141327:"Aiguilles ventilées",
   code_33141328:"Aiguilles de péridurale",
   code_33141329:"Aiguillles d'amniocentèse",
   code_33141400:"Coupe-fil métallique et bistouri; gants de chirurgie",
   code_33141410:"Coupe-fil métallique et bistouri",
   code_33141411:"Bistouris et lames",
   code_33141420:"Gants de chirurgie",
   code_33141500:"Consommables hématologiques",
   code_33141510:"Produits sanguins",
   code_33141520:"Dérivés plasmatiques",
   code_33141530:"Coagulants sanguins",
   code_33141540:"Albumine",
   code_33141550:"Héparine",
   code_33141560:"Organes humains",
   code_33141570:"Sang humain",
   code_33141580:"Sang animal",
   code_33141600:"Collecteur et poches, drainage et kits",
   code_33141610:"Poches",
   code_33141613:"Poches à sang",
   code_33141614:"Poches à plasma",
   code_33141615:"Poches à urine",
   code_33141620:"Trousses médicales",
   code_33141621:"Kit d'incontinence",
   code_33141622:"Kits de prévention du sida",
   code_33141623:"Kits de secours",
   code_33141624:"Kits d'administration",
   code_33141625:"Kits de diagnostic",
   code_33141626:"Kits de dosage",
   code_33141630:"Filtres à plasma sanguin",
   code_33141640:"Drain",
   code_33141641:"Sondes",
   code_33141642:"Accessoires de drain",
   code_33141700:"Fournitures orthopédiques",
   code_33141710:"Béquilles",
   code_33141720:"Aides à la marche",
   code_33141730:"Minerves",
   code_33141740:"Chaussures orthopédiques",
   code_33141750:"Articulations artificielles",
   code_33141760:"Attelles",
   code_33141770:"Appareils pour fractures, broches et plaques",
   code_33141800:"Consommables dentaires",
   code_33141810:"Produits d'obturation dentaire",
   code_33141820:"Dents",
   code_33141821:"Dents artificielles en céramique",
   code_33141822:"Dents artificielles en résine acrylique",
   code_33141830:"Fond de cavité en ciment",
   code_33141840:"Hémostatique dentaire",
   code_33141850:"Produits d'hygiène dentaire",
   code_33141900:"Lancettes à saigner",
   code_33150000:"Appareils de radiothérapie, de mécanothérapie, d'électrothérapie et de physiothérapie",
   code_33151000:"Appareils et fournitures pour radiothérapie",
   code_33151100:"Appareils de gammathérapie",
   code_33151200:"Appareils de radiothérapie",
   code_33151300:"Spectrographes",
   code_33151400:"Fournitures pour radiothérapie",
   code_33152000:"Incubateurs",
   code_33153000:"Lithotriteur",
   code_33154000:"Appareils de mécanothérapie",
   code_33155000:"Équipement de physiothérapie",
   code_33156000:"Matériel pour tests psychologiques",
   code_33157000:"Oxygénothérapie et assistance respiratoire",
   code_33157100:"Masques pour gaz médicaux",
   code_33157110:"Masque pour oxygénothérapie",
   code_33157200:"Kits d'oxygène",
   code_33157300:"Tentes à oxygène",
   code_33157400:"Respirateurs médicaux",
   code_33157500:"Caissons hyperbares",
   code_33157700:"Barboteur pour oxygénothérapie",
   code_33157800:"Dispositif d'administration d'oxygène",
   code_33157810:"Installation d'oxygénothérapie",
   code_33158000:"Thérapie électrique, électromagnétique et mécanique",
   code_33158100:"Système électromagnétique",
   code_33158200:"Équipement pour électrothérapie",
   code_33158210:"Stimulateur",
   code_33158300:"Équipement ultraviolet à usage médical",
   code_33158400:"Matériel de thérapie mécanique",
   code_33158500:"Équipement infrarouge à usage médical",
   code_33159000:"Système de chimie clinique",
   code_33160000:"Techniques opératoires",
   code_33161000:"Installation d'électrochirurgie",
   code_33162000:"Appareils et instruments pour bloc opératoire",
   code_33162100:"Équipement pour bloc opératoire",
   code_33162200:"Instruments pour bloc opératoire",
   code_33163000:"Tente à usage médical",
   code_33164000:"Équipement pour cœlioscopie",
   code_33164100:"Colposcope",
   code_33165000:"Équipement pour cryochirurgie et cryothérapie",
   code_33166000:"Équipements et instruments pour dermatologie",
   code_33167000:"Éclairage opératoire",
   code_33168000:"Appareils d'endoscopie et d'endochirurgie",
   code_33168100:"Endoscopes",
   code_33169000:"Instruments de chirurgie",
   code_33169100:"Laser chirurgical",
   code_33169200:"Paniers à usage chirurgical",
   code_33169300:"Plateaux à usage chirurgical",
   code_33169400:"Conteneurs à usage chirurgical",
   code_33169500:"Systèmes de suivi et de traçabilité chirurgicaux",
   code_33170000:"Anesthésie et réanimation",
   code_33171000:"Instruments d'anesthésie et de réanimation",
   code_33171100:"Instruments pour l'anesthésie",
   code_33171110:"Masque d'anesthésie-réanimation",
   code_33171200:"Instruments de réanimation",
   code_33171210:"Masque laryngé d'anesthésie-réanimation",
   code_33171300:"Kits ou ensembles pour péridurale",
   code_33172000:"Appareils d'anesthésie et de réanimation",
   code_33172100:"Appareils d'anesthésie",
   code_33172200:"Appareils de réanimation",
   code_33180000:"Assistance fonctionnelle",
   code_33181000:"Appareils de suppléance rénale",
   code_33181100:"Appareils d'hémodialyse",
   code_33181200:"Filtres pour dialyse",
   code_33181300:"Moniteur individuel pour hémodialyse",
   code_33181400:"Multiposte pour hémodialyse",
   code_33181500:"Consommables rénaux",
   code_33181510:"Fluide rénal",
   code_33181520:"Consommables pour dialyse rénale",
   code_33182000:"Appareils de suppléance cardiaque",
   code_33182100:"Défibrillateur",
   code_33182200:"Système de stimulation cardiaque",
   code_33182210:"Stimulateur cardiaque",
   code_33182220:"Valves cardiaques",
   code_33182230:"Ventricule",
   code_33182240:"Pièces et accessoires pour stimulateurs cardiaques",
   code_33182241:"Piles pour stimulateurs cardiaques",
   code_33182300:"Appareils et instruments de chirurgie cardiaque",
   code_33182400:"Radiologie cardiaque",
   code_33183000:"Suppléance orthopédique",
   code_33183100:"Implants orthopédiques",
   code_33183200:"Prothèses orthopédiques",
   code_33183300:"Matériel d'ostéosynthèse",
   code_33184000:"Prothèses",
   code_33184100:"Implants chirurgicaux",
   code_33184200:"Prothèses vasculaires",
   code_33184300:"Prothèses cardiaques",
   code_33184400:"Implants mammaires",
   code_33184410:"Prothèses mammaires internes",
   code_33184420:"Prothèses mammaires externes",
   code_33184500:"Endoprothèses coronariennes",
   code_33184600:"Yeux artificiels",
   code_33185000:"Prothèses auditives",
   code_33185100:"Pièces et accessoires pour prothèses auditives",
   code_33185200:"Implant cochléaire",
   code_33185300:"Implant ORL",
   code_33185400:"Larynx artificiel",
   code_33186000:"Circulation extracorporelle (CEC)",
   code_33186100:"Oxygénateur",
   code_33186200:"Chauffage du sang et des fluides",
   code_33190000:"Appareils et instruments médicaux divers",
   code_33191000:"Dispositifs de stérilisation, de désinfection et d'hygiène",
   code_33191100:"Stérilisateur",
   code_33191110:"Autoclaves",
   code_33192000:"Mobilier médical",
   code_33192100:"Lits médicaux",
   code_33192110:"Lits orthopédiques",
   code_33192120:"Lits d'hôpital",
   code_33192130:"Lits à moteur",
   code_33192140:"Divans d'examen psychiatrique",
   code_33192150:"Lits spécialisés",
   code_33192160:"Chariots-brancards",
   code_33192200:"Tables médicales",
   code_33192210:"Tables d'examen",
   code_33192230:"Tables d'opération",
   code_33192300:"Mobilier médical, excepté lits et tables",
   code_33192310:"Dispositifs de traction et de suspension pour lits médicaux",
   code_33192320:"Porte-urinaux",
   code_33192330:"Potences de transfusion",
   code_33192340:"Mobilier pour bloc opératoire, excepté tables",
   code_33192350:"Mobilier pour cultures à des fins médicales",
   code_33192400:"Unités dentaires",
   code_33192410:"Fauteuils dentaires",
   code_33192500:"Éprouvettes",
   code_33192600:"Matériels de levage pour le secteur des soins de santé",
   code_33193000:"Véhicules pour handicapés physiques, fauteuils roulants et dispositifs assimilés",
   code_33193100:"Véhicules pour handicapés physiques et fauteuils roulants",
   code_33193110:"Véhicules pour handicapés physiques",
   code_33193120:"Fauteuils roulants",
   code_33193121:"Fauteuils roulants à énergie motrice",
   code_33193200:"Pièces et acccessoires de véhicules pour handicapés physiques et de fauteuils roulants",
   code_33193210:"Pièces et acccessoires de véhicules pour handicapés physiques",
   code_33193211:"Moteurs de véhicules pour handicapés physiques",
   code_33193212:"Dispositifs de direction de véhicules pour handicapés physiques",
   code_33193213:"Dispositifs de commande de véhicules pour handicapés physiques",
   code_33193214:"Châssis de véhicules pour handicapés physiques",
   code_33193220:"Pièces et accessoires pour fauteuils roulants",
   code_33193221:"Coussins pour fauteuils roulants",
   code_33193222:"Bâtis de fauteuils roulants",
   code_33193223:"Sièges pour fauteuils roulants",
   code_33193224:"Roues de fauteuils roulants",
   code_33193225:"Pneus pour chaises roulantes",
   code_33194000:"Appareils et instruments de transfusion et de perfusion",
   code_33194100:"Appareils et instruments pour perfusion",
   code_33194110:"Pompes à perfusion",
   code_33194120:"Fournitures pour perfusion",
   code_33194200:"Appareils et instruments pour transfusion",
   code_33194210:"Appareils de transfusion sanguine",
   code_33194220:"Matériels de transfusion sanguine",
   code_33195000:"Système de surveillance des patients",
   code_33195100:"Moniteurs",
   code_33195110:"Moniteurs respiratoires",
   code_33195200:"Poste central de surveillance",
   code_33196000:"Aides médicales",
   code_33196100:"Équipement pour personnes âgées",
   code_33196200:"Équipement pour handicapés",
   code_33197000:"Matériel informatique à usage médical",
   code_33198000:"Articles en papier à usage hospitalier",
   code_33198100:"Compresses en papier",
   code_33198200:"Sachets ou enveloppes en papier pour stérilisation",
   code_33199000:"Vêtements pour personnel médical",
   code_33600000:"Produits pharmaceutiques",
   code_33610000:"Médicaments des voies digestives et du métabolisme",
   code_33611000:"Médicaments contre les troubles liés à l'hyperacidité",
   code_33612000:"Médicaments contre les troubles gastro-intestinaux fonctionnels",
   code_33613000:"Laxatifs",
   code_33614000:"Antidiarrhéiques, anti-inflammatoires et anti-infectieux intestinaux",
   code_33615000:"Médicaments du diabète",
   code_33615100:"Insuline",
   code_33616000:"Vitamines",
   code_33616100:"Provitamines",
   code_33617000:"Suppléments minéraux",
   code_33620000:"Médicaments du sang, des organes hématopoïétiques et du système cardio-vasculaire",
   code_33621000:"Médicaments du sang et des organes hématopoïétiques",
   code_33621100:"Antithrombotiques",
   code_33621200:"Antihémorragiques",
   code_33621300:"Préparations antianémiques",
   code_33621400:"Substituts de plasma sanguin et solutions pour perfusions",
   code_33622000:"Médicaments du système cardio-vasculaire",
   code_33622100:"Médicaments utilisés en cardiologie",
   code_33622200:"Antihypertenseurs",
   code_33622300:"Diurétiques",
   code_33622400:"Vasculoprotecteurs",
   code_33622500:"Antihémorroïdaires à usage topique",
   code_33622600:"Bêta-bloquants",
   code_33622700:"Inhibiteurs calciques",
   code_33622800:"Médicaments agissant sur le système rénine-angiotensine",
   code_33630000:"Médicaments utilisés en dermatologie et pour le système squelettique et musculaire",
   code_33631000:"Médicaments utilisés en dermatologie",
   code_33631100:"Antifongiques à usage dermatologique",
   code_33631110:"Acides salicyliques",
   code_33631200:"Émollients et protecteurs",
   code_33631300:"Médicaments contre le psoriasis",
   code_33631400:"Antibiotiques et médicaments chimiothérapeutiques à usage dermatologique",
   code_33631500:"Corticostéroïdes à usage dermatologique et préparations dermatologiques",
   code_33631600:"Antiseptiques et désinfectants",
   code_33631700:"Préparations antiacnéiques",
   code_33632000:"Médicaments du système squelettique et musculaire",
   code_33632100:"Anti-inflammatoires et antirhumatismaux",
   code_33632200:"Myorelaxants",
   code_33632300:"Préparations contre la goutte",
   code_33640000:"Médicaments du système génito-urinaire et hormones",
   code_33641000:"Médicaments du système génito-urinaire et hormones sexuelles",
   code_33641100:"Anti-infectieux et antiseptiques gynécologiques",
   code_33641200:"Autres médicaments gynécologiques",
   code_33641300:"Hormones sexuelles et modulateurs de la fonction génitale",
   code_33641400:"Contraceptifs",
   code_33641410:"Contraceptifs oraux",
   code_33641420:"Contraceptifs chimiques",
   code_33642000:"Préparations hormonales systémiques, hormones sexuelles exclues",
   code_33642100:"Hormones hypophysaires, hypothalamiques et analogues",
   code_33642200:"Corticostéroïdes à usage systémique",
   code_33642300:"Thérapeutique thyroïdienne",
   code_33650000:"Anti-infectieux généraux à usage systémique, vaccins, antinéoplasiques et immunomodulateurs",
   code_33651000:"Anti-infectieux généraux à usage systémique",
   code_33651100:"Antibactériens à usage systémique",
   code_33651200:"Antimycosiques à usage systémique",
   code_33651300:"Antimycobactériens",
   code_33651400:"Antiviraux à usage systémique",
   code_33651500:"Immun-sérums et immunoglobulines",
   code_33651510:"Antisérums",
   code_33651520:"Immunoglobulines",
   code_33651600:"Vaccins",
   code_33651610:"Vaccins antidiphtérie, anticoqueluche et antitétanos",
   code_33651620:"Vaccins antidiphtérie et antitétanos",
   code_33651630:"Vaccins BCG (secs)",
   code_33651640:"Vaccins ROR (rougeole, oreillons, rubéole)",
   code_33651650:"Vaccins antityphus",
   code_33651660:"Vaccins antigrippe",
   code_33651670:"Vaccins antipolio",
   code_33651680:"Vaccins antihépatite B",
   code_33651690:"Vaccins à usage vétérinaire",
   code_33652000:"Antinéoplasiques et immunomodulateurs",
   code_33652100:"Antinéoplasiques",
   code_33652200:"Thérapeutique endocrine",
   code_33652300:"Immunosuppresseurs",
   code_33660000:"Médicaments du système nerveux et des organes sensoriels",
   code_33661000:"Médicaments du système nerveux",
   code_33661100:"Anesthésiques",
   code_33661200:"Analgésiques",
   code_33661300:"Antiépileptiques",
   code_33661400:"Antiparkinsoniens",
   code_33661500:"Psycholeptiques",
   code_33661600:"Psychoanaleptiques",
   code_33661700:"Autres médicaments du système nerveux",
   code_33662000:"Médicaments des organes sensoriels",
   code_33662100:"Médicaments ophtalmologiques",
   code_33670000:"Médicaments du système respiratoire",
   code_33673000:"Médicaments contre les maladies obstructives des voies respiratoires",
   code_33674000:"Médicaments de la toux et du rhume",
   code_33675000:"Antihistaminiques à usage systémique",
   code_33680000:"Articles de pharmacie",
   code_33681000:"Tétines, téterelles et articles similaires pour nourrissons",
   code_33682000:"Dalles en caoutchouc",
   code_33683000:"Butoirs en caoutchouc",
   code_33690000:"Médicaments divers",
   code_33691000:"Produits antiparasitaires, insecticides et insectifuges",
   code_33691100:"Antiprotozoaires",
   code_33691200:"Antihelminthiques",
   code_33691300:"Médicaments contre les ectoparasites, y compris scabicides, insecticides et insectifuges",
   code_33692000:"Solutions médicamenteuses",
   code_33692100:"Solutions pour infusions",
   code_33692200:"Produits de nutrition parentérale",
   code_33692210:"Solutions d'alimentation parentérale",
   code_33692300:"Produits d'alimentation entérale",
   code_33692400:"Solutions pour perfusions",
   code_33692500:"Solutions injectables",
   code_33692510:"Liquides intraveineux",
   code_33692600:"Solutions galéniques",
   code_33692700:"Solutions de glucose",
   code_33692800:"Solutions de dialyse",
   code_33693000:"Tous les autres produits thérapeutiques",
   code_33693100:"Toxines",
   code_33693200:"Succédanés de nicotine",
   code_33693300:"Traitement de l'addiction",
   code_33694000:"Agents diagnostiques",
   code_33695000:"Tous les autres produits non thérapeutiques",
   code_33696000:"Réactifs et produits de contraste",
   code_33696100:"Réactifs de groupage sanguin",
   code_33696200:"Réactifs pour analyses de sang",
   code_33696300:"Réactifs chimiques",
   code_33696400:"Réactifs isotopiques",
   code_33696500:"Réactifs de laboratoire",
   code_33696600:"Réactifs d'électrophorèse",
   code_33696700:"Réactifs d'urologie",
   code_33696800:"Produits de contraste à usage radiologique",
   code_33697000:"Préparations médicales, excepté consommables dentaires",
   code_33697100:"Préparations pour lavements",
   code_33697110:"Ciments de reconstruction osseuse",
   code_33698000:"Produits à usage clinique",
   code_33698100:"Cultures microbiologiques",
   code_33698200:"Glandes et leurs extraits",
   code_33698300:"Substances peptiques",
   code_33700000:"Produits de soins personnels",
   code_33710000:"Parfums, produits de toilette et condoms",
   code_33711000:"Parfums et produits de toilette",
   code_33711100:"Eaux de toilette",
   code_33711110:"Déodorants",
   code_33711120:"Antisudoraux",
   code_33711130:"Eaux de Cologne",
   code_33711140:"Fragrances",
   code_33711150:"Eau de rose",
   code_33711200:"Produits de maquillage",
   code_33711300:"Produits de manucure ou de pédicure",
   code_33711400:"Produits de beauté",
   code_33711410:"Coton-Tiges",
   code_33711420:"Kits de maquillage",
   code_33711430:"Serviettes personnelles à jeter",
   code_33711440:"Pommade pour les lèvres",
   code_33711450:"Tatouages",
   code_33711500:"Produits de soins de la peau",
   code_33711510:"Produits de protection solaire",
   code_33711520:"Gels de bain",
   code_33711530:"Bonnets de douche",
   code_33711540:"Crèmes ou lotions parapharmaceutiques",
   code_33711600:"Préparation et articles capillaires",
   code_33711610:"Shampooings",
   code_33711620:"Peignes",
   code_33711630:"Perruques",
   code_33711640:"Nécessaires de toilette",
   code_33711700:"Articles et préparations pour hygiène orale ou dentaire",
   code_33711710:"Brosses à dents",
   code_33711720:"Dentifrice",
   code_33711730:"Cure-dents",
   code_33711740:"Bain de bouche",
   code_33711750:"Bains de bouche rafraîchissants",
   code_33711760:"Fil dentaire",
   code_33711770:"Anneau dentaire, tétine, sucette",
   code_33711780:"Pastilles de produit de nettoyage pour appareils dentaires",
   code_33711790:"Kits d'hygiène dentaire",
   code_33711800:"Produits de rasage",
   code_33711810:"Crèmes à raser",
   code_33711900:"Savon",
   code_33712000:"Préservatifs",
   code_33713000:"Produits de soin pour les pieds",
   code_33720000:"Rasoirs et trousses de manucure ou de pédicure",
   code_33721000:"Rasoirs",
   code_33721100:"Lames de rasoir",
   code_33721200:"Rasoirs électriques",
   code_33722000:"Trousses de manucure ou de pédicure",
   code_33722100:"Trousses de manucure",
   code_33722110:"Accessoires de manucure",
   code_33722200:"Trousses de pédicure",
   code_33722210:"Accessoires de pédicure",
   code_33722300:"Barrettes",
   code_33730000:"Produits pour le soin des yeux et verres correcteurs",
   code_33731000:"Verres de contact",
   code_33731100:"Verres correcteurs",
   code_33731110:"Lentilles intra-oculaires",
   code_33731120:"Verres de lunettes",
   code_33732000:"Lotions pour verres de contact",
   code_33733000:"Lunettes de soleil",
   code_33734000:"Lunettes",
   code_33734100:"Montures de lunettes et logements d'oculaires",
   code_33734200:"Verre à lunettes",
   code_33735000:"Lunettes masques",
   code_33735100:"Lunettes de protection",
   code_33735200:"Montures et logements d'oculaires de lunettes de protection",
   code_33740000:"Produits de soins pour mains et ongles",
   code_33741000:"Produits de soins pour mains",
   code_33741100:"Produits nettoyants pour les mains",
   code_33741200:"Lotions pour mains ou lotions corporelles",
   code_33741300:"Produits désinfectants pour les mains",
   code_33742000:"Produits de soins pour les ongles",
   code_33742100:"Coupe-ongles",
   code_33742200:"Vernis à ongles",
   code_33750000:"Produits de soins pour bébés",
   code_33751000:"Langes jetables",
   code_33752000:"Coussinets d'allaitement",
   code_33760000:"Papier hygiénique, mouchoirs, essuie-mains et serviettes de table",
   code_33761000:"Papier hygiénique",
   code_33762000:"Mouchoirs en papier",
   code_33763000:"Essuie-mains en papier",
   code_33764000:"Serviettes de table en papier",
   code_33770000:"Articles en papier à usage sanitaire",
   code_33771000:"Produits en papier à usage sanitaire",
   code_33771100:"Serviettes hygiéniques ou tampons",
   code_33771200:"Couches en papier",
   code_33772000:"Articles jetables en papier",
   code_33790000:"Verrerie de laboratoire, d'hygiène ou de pharmacie",
   code_33791000:"Verrerie d'hygiène",
   code_33792000:"Verrerie de pharmacie",
   code_33793000:"Verrerie de laboratoire",
   code_33900000:"Équipement et fournitures pour autopsie et à usage mortuaire",
   code_33910000:"Instruments et fournitures pour dissection",
   code_33911000:"Ciseaux pour autopsie",
   code_33912000:"Trousses de dissection pour autopsie",
   code_33912100:"Pinces à dissection pour autopsie",
   code_33913000:"Sondes de localisation de balles pour autopsie",
   code_33914000:"Fils, aiguilles et agrafes pour incision pour autopsie",
   code_33914100:"Fils pour autopsie",
   code_33914200:"Aiguilles pour autopsie",
   code_33914300:"Agrafes pour incision, pour autopsie",
   code_33915000:"Sondes canelées pour autopsie",
   code_33916000:"Lames ou accessoires de scie pour autopsie",
   code_33916100:"Scies pour autopsie",
   code_33917000:"Planches ou blocs de dissection",
   code_33918000:"Trousses pour instruments ou accessoires de chirurgie pour autopsie",
   code_33919000:"Trousses enroulables pour instruments ou accessoires pour autopsie",
   code_33920000:"Équipement et fournitures pour autopsie",
   code_33921000:"Collecteurs de poussières d'os",
   code_33922000:"Housses de transport de corps",
   code_33923000:"Repose-têtes, plans durs ou balances suspendues pour autopsie",
   code_33923100:"Repose-têtes pour autopsie",
   code_33923200:"Plans durs pour autopsie",
   code_33923300:"Balances suspendues pour autopsie",
   code_33924000:"Kits maladies infectieuse pour autopsie",
   code_33925000:"Etiquettes ou bracelets d'identification pour autopsie",
   code_33926000:"Aspirateurs à liquides ou tubulures pour autopsie",
   code_33927000:"Thermomètres rectaux pour autopsie",
   code_33928000:"Redresseurs de doigts pour autopsie",
   code_33929000:"Kits de reconstitution des tissus",
   code_33930000:"Mobilier pour autopsie",
   code_33931000:"Stations de travail ou accessoires pour autopsie macroscopique",
   code_33932000:"Éviers ou accessoires pour autopsie",
   code_33933000:"Tables pour autopsie ou accessoires",
   code_33933100:"Tables d'autopsie",
   code_33934000:"Tables ou accessoires pour nécropsie",
   code_33935000:"Tables de dissection ou accessoires pour dissection d'animaux",
   code_33936000:"Stations de travail ou accessoires pour embaumement",
   code_33937000:"Stations de travail pour autopsie avec dispositif d'évacuation des liquides corporels ou accessoires",
   code_33940000:"Équipement et fournitures de transport et de conservation des cadavres",
   code_33941000:"Casiers de conservation des cadavres",
   code_33942000:"Dispositifs de transport de cadavres",
   code_33943000:"Chariots élévateurs pour les cadavres",
   code_33944000:"Armoires réfrigérées ou congélateurs pour morgue",
   code_33945000:"Chambres froides pour morgue",
   code_33946000:"Chariots pour autopsie",
   code_33947000:"Plateaux pour cadavres",
   code_33948000:"Élévateurs ou dispositifs de transfert pour cadavres",
   code_33949000:"Conteneurs de transport des corps",
   code_33950000:"Équipement et fournitures cliniques médico-légales",
   code_33951000:"Matériel de prise d'empreintes digitales pour autopsie",
   code_33952000:"Masques antiputréfaction",
   code_33953000:"Kits ou fournitures de dépistage sanguin pour autopsie",
   code_33954000:"Kits de prélèvement d'éléments biologiques",
   code_33960000:"Équipement et fournitures pour embaumement",
   code_33961000:"Injecteurs pour cavités pour embaumement",
   code_33962000:"Tubes de drainage de veine pour embaumement",
   code_33963000:"Traitements chimiques ou fluides pour embaumement",
   code_33964000:"Tubes pour injecteur d'embaumement",
   code_33965000:"Éviers ou accessoires pour embaumement",
   code_33966000:"Kits d'embaumement",
   code_33967000:"Aiguilles d'injecteur pour embaumement",
   code_33968000:"Couvre-yeux",
   code_33970000:"Matériel et fournitures mortuaires",
   code_33971000:"Tenues mortuaires",
   code_33972000:"Packs mortuaires",
   code_33973000:"Housses mortuaires",
   code_33974000:"Aspirateurs à usage mortuaire",
   code_33975000:"Agents de durcissement à usage mortuaire",
   code_34000000:"Équipement de transport et produits auxiliaires pour le transport",
   code_34100000:"Véhicules à moteur",
   code_34110000:"Voitures particulières",
   code_34111000:"Breaks et berlines",
   code_34111100:"Breaks",
   code_34111200:"Berlines",
   code_34113000:"Véhicules à quatre roues motrices",
   code_34113100:"Jeeps",
   code_34113200:"Véhicules tout terrain",
   code_34113300:"Quatre-quatre",
   code_34114000:"Véhicules à usage spécial",
   code_34114100:"Véhicules de secours",
   code_34114110:"Véhicules de sauvetage",
   code_34114120:"Véhicules à usage paramédical",
   code_34114121:"Ambulances",
   code_34114122:"Véhicules de transport de patients",
   code_34114200:"Voitures de police",
   code_34114210:"Fourgons cellulaires",
   code_34114300:"Véhicules pour services d'assistance sociale",
   code_34114400:"Minibus",
   code_34115000:"Autres véhicules particuliers",
   code_34115200:"Véhicules à moteur pour le transport de moins de dix personnes",
   code_34115300:"Véhicules de transport d'occasion",
   code_34120000:"Véhicules à moteur servant au transport de dix personnes ou plus",
   code_34121000:"Autobus et cars",
   code_34121100:"Autobus publics",
   code_34121200:"Autobus articulés",
   code_34121300:"Autobus à impériale",
   code_34121400:"Autobus à plancher surbaissé",
   code_34121500:"Autocars",
   code_34130000:"Véhicules à moteur servant au transport de marchandises",
   code_34131000:"Camionnettes à espace ouvert à l'arrière (pick-up)",
   code_34132000:"Traîneaux à moteur",
   code_34133000:"Camions articulés",
   code_34133100:"Camions-citernes",
   code_34133110:"Camions-citernes à essence",
   code_34134000:"Camions à carrosserie ouverte et à plate-forme",
   code_34134100:"Camions à carrosserie ouverte",
   code_34134200:"Camions à benne basculante",
   code_34136000:"Fourgons intégrés",
   code_34136100:"Fourgonnettes",
   code_34136200:"Fourgons tôlés",
   code_34137000:"Véhicules d'occasion servant au transport de marchandises",
   code_34138000:"Tracteurs routiers",
   code_34139000:"Châssis",
   code_34139100:"Châssis-cabines",
   code_34139200:"Châssis avec carrosseries",
   code_34139300:"Châssis complets",
   code_34140000:"Poids lourds",
   code_34142000:"Camions-grues et camions à plate-forme élévatrice",
   code_34142100:"Camions à plate-forme élévatrice",
   code_34142200:"Bacs-chargeurs",
   code_34142300:"Tombereaux",
   code_34143000:"Véhicules de service hivernal",
   code_34144000:"Véhicules automobiles à usage spécifique",
   code_34144100:"Tours de forage mobiles",
   code_34144200:"Véhicules des services de secours",
   code_34144210:"Véhicules de lutte contre l'incendie",
   code_34144211:"Camions de lutte contre l'incendie à échelle pivotante",
   code_34144212:"Fourgons pompe-tonne",
   code_34144213:"Véhicules d'incendie",
   code_34144220:"Dépanneuses",
   code_34144300:"Ponts mobiles",
   code_34144400:"Véhicules d'entretien routier",
   code_34144410:"Camions de vidange",
   code_34144420:"Saleuses",
   code_34144430:"Balayeuses",
   code_34144431:"Balayeuses aspiratrices",
   code_34144440:"Gravillonneuses",
   code_34144450:"Arroseuses de voirie",
   code_34144500:"Véhicules pour ordures et eaux usées",
   code_34144510:"Véhicules pour ordures",
   code_34144511:"Bennes à ordures",
   code_34144512:"Véhicules de compactage des ordures ménagères",
   code_34144520:"Camions-citernes pour eaux usées",
   code_34144700:"Véhicules utilitaires",
   code_34144710:"Chargeurs sur roues",
   code_34144730:"Véhicules d'avitaillement",
   code_34144740:"Tracteurs d'avions",
   code_34144750:"Porte-charges",
   code_34144751:"Chariots-cavaliers",
   code_34144760:"Bibliobus",
   code_34144800:"Caravanes résidentielles",
   code_34144900:"Véhicules électriques",
   code_34144910:"Autobus électriques",
   code_34150000:"Simulateurs",
   code_34151000:"Simulateurs de conduite",
   code_34152000:"Simulateurs de formation",
   code_34200000:"Carrosseries, remorques ou semi-remorques de véhicules",
   code_34210000:"Carrosseries de véhicules",
   code_34211000:"Carrosseries d'autobus, carrosseries d'ambulances et carrosseries de véhicules servant au transport de marchandises",
   code_34211100:"Carrosseries d'autobus",
   code_34211200:"Carrosseries d'ambulances",
   code_34211300:"Carrosseries de véhicules servant au transport de marchandises",
   code_34220000:"Remorques, semi-remorques et conteneurs mobiles",
   code_34221000:"Conteneurs mobiles à usage spécifique",
   code_34221100:"Unités mobiles de secours",
   code_34221200:"Unités mobiles d'intervention d'urgence",
   code_34221300:"Unité d'intervention en cas d'accident chimique",
   code_34223000:"Remorques et semi-remorques",
   code_34223100:"Semi-remorques",
   code_34223200:"Avitailleurs",
   code_34223300:"Remorques",
   code_34223310:"Remorques à usage général",
   code_34223320:"Remorques pour le transport de chevaux",
   code_34223330:"Unités mobiles sur remorques",
   code_34223340:"Remorques-citernes",
   code_34223350:"Remorques à échelle pivotante",
   code_34223360:"Remorques d'avitaillement",
   code_34223370:"Remorques à benne basculante",
   code_34223400:"Remorques et semi-remorques de type caravane",
   code_34224000:"Pièces détachées pour remorques, semi-remorques et autres véhicules",
   code_34224100:"Pièces pour remorques et semi-remorques",
   code_34224200:"Pièces pour autres véhicules",
   code_34300000:"Pièces détachées et accessoires pour véhicules et moteurs de véhicules",
   code_34310000:"Moteurs et pièces de moteurs (véhicules)",
   code_34311000:"Moteurs (véhicules)",
   code_34311100:"Moteurs à combustion interne pour véhicules à moteur et motocycles",
   code_34311110:"Moteurs à allumage par étincelles",
   code_34311120:"Moteurs à allumage par compression",
   code_34312000:"Pièces de moteurs",
   code_34312100:"Courroies de ventilateur",
   code_34312200:"Bougies d'allumage",
   code_34312300:"Radiateurs pour véhicules",
   code_34312400:"Pistons",
   code_34312500:"Joints d'étanchéité",
   code_34312600:"Bandes convoyeuses en caoutchouc",
   code_34312700:"Courroies de transmission en caoutchouc",
   code_34320000:"Pièces de rechange mécaniques, excepté moteurs et parties de moteurs",
   code_34321000:"Essieux et boîtes de vitesses",
   code_34321100:"Essieux",
   code_34321200:"Boîtes de vitesses",
   code_34322000:"Freins et pièces de freins",
   code_34322100:"Équipements de freins",
   code_34322200:"Freins à disque",
   code_34322300:"Garnitures de frein",
   code_34322400:"Plaquettes de frein",
   code_34322500:"Mâchoires de frein",
   code_34324000:"Roues, pièces détachées et accessoires",
   code_34324100:"Matériel d'équilibrage des roues",
   code_34325000:"Silencieux et tuyaux d'échappement",
   code_34325100:"Silencieux",
   code_34325200:"Tuyaux d'échappement",
   code_34326000:"Vérins pour véhicules, embrayages et pièces connexes",
   code_34326100:"Embrayages et pièces d'embrayages",
   code_34326200:"Vérins pour véhicules",
   code_34327000:"Volants, colonnes et boîtiers de direction",
   code_34327100:"Volants",
   code_34327200:"Colonnes et boîtiers de direction",
   code_34328000:"Bancs d'essai, kits de conversion de véhicules et ceintures de sécurité",
   code_34328100:"Bancs d'essai",
   code_34328200:"Kits de conversion de véhicules",
   code_34328300:"Ceintures de sécurité",
   code_34330000:"Pièces de rechanges pour poids lourds, camionnettes et automobiles",
   code_34350000:"Pneus pour charges légères et lourdes",
   code_34351000:"Pneus pour charges légères",
   code_34351100:"Pneus pour voitures",
   code_34352000:"Pneus pour fortes charges",
   code_34352100:"Pneus pour camions",
   code_34352200:"Pneus pour autobus",
   code_34352300:"Pneus pour véhicules agricoles",
   code_34360000:"Sièges pour aéronefs civils",
   code_34370000:"Sièges pour véhicules à moteur",
   code_34390000:"Accessoires pour tracteurs",
   code_34400000:"Motos, bicyclettes et side-cars",
   code_34410000:"Motos",
   code_34411000:"Pièces détachées et accessoires pour motos",
   code_34411100:"Side-cars",
   code_34411110:"Pièces détachées et accessoires pour side-cars",
   code_34411200:"Pneus pour motocycles",
   code_34420000:"Scooters et cycles à moteur auxiliaire",
   code_34421000:"Scooters",
   code_34422000:"Cycles à moteur auxiliaire",
   code_34430000:"Bicyclettes",
   code_34431000:"Bicyclettes sans moteur",
   code_34432000:"Pièces détachées et accessoires pour bicyclettes",
   code_34432100:"Pneus pour bicyclettes",
   code_34500000:"Navires et bateaux",
   code_34510000:"Navires",
   code_34511100:"Patrouilleurs maritimes",
   code_34512000:"Navires et bâtiments similaires servant au transport de personnes ou de marchandises",
   code_34512100:"Ferry-boats",
   code_34512200:"Paquebots de croisière",
   code_34512300:"Vraquiers",
   code_34512400:"Cargos",
   code_34512500:"Navires de marchandises",
   code_34512600:"Navires porte-conteneurs",
   code_34512700:"Navires transrouliers",
   code_34512800:"Navires-citernes",
   code_34512900:"Transbordeurs pour voitures",
   code_34512950:"Navires frigorifiques",
   code_34513000:"Navires de pêche, navires de sauvetage et autres navires spécialisés",
   code_34513100:"Navires de pêche",
   code_34513150:"Navires-usines",
   code_34513200:"Remorqueurs",
   code_34513250:"Dragues",
   code_34513300:"Docks de mer flottants",
   code_34513350:"Navires supports de plongée",
   code_34513400:"Grues flottantes",
   code_34513450:"Navires de production",
   code_34513500:"Navires d'exploration sismique",
   code_34513550:"Navires hydrographes",
   code_34513600:"Navires de lutte antipollution",
   code_34513650:"Bateaux de service d'incendie",
   code_34513700:"Bateaux de sauvetage",
   code_34513750:"Bateaux-phares",
   code_34514000:"Plates-formes de forage ou de production, flottantes ou submersibles",
   code_34514100:"Navires de forage",
   code_34514200:"Plates-formes de forage autoélévatrices",
   code_34514300:"Tours de forage pour plates-formes",
   code_34514400:"Plates-formes de forage flottantes",
   code_34514500:"Installation de production flottante",
   code_34514600:"Tours de forage semi-submersibles",
   code_34514700:"Plate-forme mobile",
   code_34514800:"Plate-forme offshore",
   code_34514900:"Plates-formes de forage",
   code_34515000:"Structures flottantes",
   code_34515100:"Bouées de marquage",
   code_34515200:"Radeaux pneumatiques",
   code_34516000:"Défenses d'accostage",
   code_34520000:"Bateaux",
   code_34521000:"Bateaux spécialisés",
   code_34521100:"Vedettes de surveillance",
   code_34521200:"Vedettes de patrouille douanière",
   code_34521300:"Vedettes de patrouille de police",
   code_34521400:"Canots de sauvetage",
   code_34522000:"Bateaux de plaisance et de sport",
   code_34522100:"Bateaux à voile",
   code_34522150:"Catamarans",
   code_34522200:"Embarcations de sauvetage",
   code_34522250:"Petits voiliers",
   code_34522300:"Petites embarcations",
   code_34522350:"Canots en fibres de verre",
   code_34522400:"Canots semi-rigides",
   code_34522450:"Embarcations pneumatiques",
   code_34522500:"Canots pneumatiques",
   code_34522550:"Canoës",
   code_34522600:"Embarcations à rames",
   code_34522700:"Bateaux à pédales",
   code_34600000:"Locomotives et matériel roulant de chemin de fer et de tramway et pièces détachées",
   code_34610000:"Locomotives et tenders de chemin de fer",
   code_34611000:"Locomotives",
   code_34612000:"Tenders de locomotives et funiculaires",
   code_34612100:"Tenders",
   code_34612200:"Funiculaires",
   code_34620000:"Matériel roulant",
   code_34621000:"Véhicules d'entretien ou de services des voies ferrées, et wagons de marchandises",
   code_34621100:"Wagons de marchandises",
   code_34621200:"Véhicules d'entretien ou de service des voies ferrées",
   code_34622000:"Voitures de chemin de fer et de tramway, trolleybus",
   code_34622100:"Voitures de tramway",
   code_34622200:"Voitures de chemin de fer",
   code_34622300:"Trolleybus",
   code_34622400:"Voitures à voyageurs",
   code_34622500:"Fourgons à bagages et fourgons spéciaux",
   code_34630000:"Pièces détachées pour locomotives ou matériel ferroviaire roulant, équipement de commande et de contrôle du trafic ferroviaire",
   code_34631000:"Pièces détachées pour locomotives ou matériel ferroviaire roulant",
   code_34631100:"Roues monobloc",
   code_34631200:"Tampons de choc et barres de traction",
   code_34631300:"Sièges pour matériel ferroviaire roulant",
   code_34631400:"Essieux, pneus et autres pièces détachées pour locomotives ou matériel ferroviaire roulant",
   code_34632000:"Équipement de commande et de contrôle du trafic ferroviaire",
   code_34632100:"Signalisation mécanique",
   code_34632200:"Équipement électrique de signalisation de chemin de fer",
   code_34632300:"Installations électriques de chemin de fer",
   code_34640000:"Éléments automobiles",
   code_34700000:"Aéronefs et spationefs",
   code_34710000:"Hélicoptères, aéronefs, spationefs et autres aéronefs à moteur",
   code_34711000:"Hélicoptères et avions",
   code_34711100:"Avions",
   code_34711110:"Avions à voilure fixe",
   code_34711200:"Aéronefs sans pilote",
   code_34711300:"Aéronefs pilotés",
   code_34711400:"Aéronefs à usage spécifique",
   code_34711500:"Hélicoptères",
   code_34712000:"Spationefs, satellites et dispositifs de lancement",
   code_34712100:"Spationefs",
   code_34712200:"Satellites",
   code_34712300:"Dispositifs de lancement de spationefs",
   code_34720000:"Ballons, dirigeables et autres aéronefs non motorisés",
   code_34721000:"Planeurs",
   code_34721100:"Deltaplane",
   code_34722000:"Ballons et dirigeables",
   code_34722100:"Ballons",
   code_34722200:"Dirigeables",
   code_34730000:"Pièces détachées pour aéronefs, spationefs et hélicoptères",
   code_34731000:"Pièces détachées pour aéronefs",
   code_34731100:"Moteurs d'aéronefs",
   code_34731200:"Turboréacteurs",
   code_34731300:"Turbopropulseurs",
   code_34731400:"Moteurs à réaction",
   code_34731500:"Pièces détachées pour moteurs d'aéronefs",
   code_34731600:"Pièces pour turboréacteurs ou turbopropulseurs",
   code_34731700:"Pièces détachées pour hélicoptères",
   code_34731800:"Pneus pour avions",
   code_34740000:"Équipements, appareils d'entraînement, simulateurs et pièces pour aéronefs et spationefs",
   code_34741000:"Équipement pour aéronefs",
   code_34741100:"Dispositifs de lancement d'aéronefs",
   code_34741200:"Systèmes de catapultage d'aéronefs",
   code_34741300:"Dispositifs d'arrêt de plate-forme d'appontage",
   code_34741400:"Simulateurs de vol",
   code_34741500:"Appareils au sol d'entraînement au vol",
   code_34741600:"Sachets pour mal de l'air",
   code_34900000:"Équipement de transport et pièces détachées divers",
   code_34910000:"Charrettes à chevaux ou à bras, autres véhicules nonautomoteurs, chariots à bagages et pièces détachées diverses",
   code_34911000:"Charrettes à chevaux ou à bras et autres véhicules nonautomoteurs",
   code_34911100:"Chariots à bras",
   code_34912000:"Chariots à bagages",
   code_34912100:"Poussettes",
   code_34913000:"Pièces détachées diverses",
   code_34913100:"Pneus usagés",
   code_34913200:"Pneus rechapés",
   code_34913300:"Pare-chocs",
   code_34913400:"Montres pour véhicules",
   code_34913500:"Serrures pour véhicules",
   code_34913510:"Cadenas pour bicyclettes",
   code_34913600:"Hélices de bateau",
   code_34913700:"Échelles pour véhicules de lutte contre l'incendie",
   code_34913800:"Ancres",
   code_34920000:"Équipement routier",
   code_34921000:"Équipement d'entretien routier",
   code_34921100:"Balayeuses de routes",
   code_34921200:"Balayeuses de pistes",
   code_34922000:"Matériel de marquage routier",
   code_34922100:"Marquage routier",
   code_34922110:"Billes de verre pour marquage routier",
   code_34923000:"Équipement de contrôle de la circulation routière",
   code_34924000:"Panneaux à messages variables",
   code_34926000:"Équipement de contrôle des parcs de stationnement",
   code_34927000:"Équipement de péage",
   code_34927100:"Sel de déneigement",
   code_34928000:"Mobilier routier",
   code_34928100:"Glissières de sécurité",
   code_34928110:"Séparateurs de voies",
   code_34928120:"Composants de barrières",
   code_34928200:"Clôtures",
   code_34928210:"Poteaux en bois",
   code_34928220:"Éléments de clôture",
   code_34928230:"Barrière antibruit",
   code_34928300:"Barrières de sécurité",
   code_34928310:"Clôtures de protection",
   code_34928320:"Garde-corps",
   code_34928330:"Dispositifs pare-avalanche",
   code_34928340:"Barrières pare-neige",
   code_34928400:"Mobilier urbain",
   code_34928410:"Balises",
   code_34928420:"Avertisseurs lumineux de danger",
   code_34928430:"Cônes de sécurité",
   code_34928440:"Poteaux d'arrêt d'autobus",
   code_34928450:"Bornes lumineuses",
   code_34928460:"Cônes routiers",
   code_34928470:"Fléchage",
   code_34928471:"Matériel de signalisation",
   code_34928472:"Poteaux de signalisation",
   code_34928480:"Conteneurs et poubelles de déchets",
   code_34928500:"Équipement d'éclairage public",
   code_34928510:"Lampadaires d'éclairage public",
   code_34928520:"Réverbères",
   code_34928530:"Lampes d'éclairage public",
   code_34929000:"Matériaux de construction autoroutière",
   code_34930000:"Équipement maritime",
   code_34931000:"Équipement pour port",
   code_34931100:"Installations d'amarrage",
   code_34931200:"Passerelles d'embarquement des passagers pour navires",
   code_34931300:"Échelles mobiles pour l'embarquement des passagers pour navires",
   code_34931400:"Simulateurs de passerelle d'embarquement",
   code_34931500:"Équipement de contrôle du trafic maritime",
   code_34932000:"Systèmes radars",
   code_34933000:"Matériel de navigation",
   code_34934000:"Pales d'hélices",
   code_34940000:"Équipement ferroviaire",
   code_34941000:"Rails et accessoires",
   code_34941100:"Barres",
   code_34941200:"Rails de chemin de fer",
   code_34941300:"Rails de tramway",
   code_34941500:"Pièces d'entretoisement",
   code_34941600:"Jonctions de voies",
   code_34941800:"Lames d'aiguilles de chemin de fer",
   code_34942000:"Équipement de signalisation",
   code_34942100:"Poteaux indicateurs",
   code_34942200:"Boîtiers de signalisation",
   code_34943000:"Système de surveillance de la marche des trains",
   code_34944000:"Système de réchauffage des aiguillages",
   code_34945000:"Machines d'alignement des voies",
   code_34946000:"Matériaux de construction ferroviaire et fournitures",
   code_34946100:"Matériaux de construction ferroviaire ferrées",
   code_34946110:"Rails",
   code_34946120:"Matériaux ferroviaires",
   code_34946121:"Éclisses et selles de rail",
   code_34946122:"Contre-rails",
   code_34946200:"Matériaux de construction de voies ferrées",
   code_34946210:"Rails d'alimentation en courant",
   code_34946220:"Lames d'aiguille, cœurs de croisement, tringles de manœuvre d'aiguille et pièces de croisement",
   code_34946221:"Lames d'aiguille",
   code_34946222:"Cœurs de croisement",
   code_34946223:"Tringles de manœuvre d'aiguille",
   code_34946224:"Pièces de croisement",
   code_34946230:"Crapauds ferroviaires, plaques d'écartement et traverses",
   code_34946231:"Crapauds ferroviaires",
   code_34946232:"Plaques d'écartement et traverses",
   code_34946240:"Sièges et cale-sièges",
   code_34947000:"Traverses et pièces pour traverses",
   code_34947100:"Traverses",
   code_34947200:"Pièces pour traverses",
   code_34950000:"Structures porteuses",
   code_34951000:"Plates-formes d'accès",
   code_34951200:"Équipement de manipulation des boues",
   code_34951300:"Installation de dessication des boues",
   code_34952000:"Dispositifs de levage de plate-forme hydraulique",
   code_34953000:"Passerelles d'accès",
   code_34953100:"Passerelles pour ferry",
   code_34953300:"Passerelles pour passagers",
   code_34954000:"Passerelles de service",
   code_34955000:"Dock flottant",
   code_34955100:"Unité de stockage flottante",
   code_34960000:"Équipement aéroportuaire",
   code_34961000:"Système de manutention des bagages",
   code_34961100:"Équipement de manutention des bagages",
   code_34962000:"Équipement de contrôle du trafic aérien",
   code_34962100:"Équipement de tour de contrôle",
   code_34962200:"Contrôle du trafic aérien",
   code_34962210:"Simulation du contrôle du trafic aérien",
   code_34962220:"Systèmes de contrôle du trafic aérien",
   code_34962230:"Formation au contrôle du trafic aérien",
   code_34963000:"Système d'atterrissage aux instruments (ILS)",
   code_34964000:"Radiophare d'alignement omnidirectionnel VHF Doppler (DVOR)",
   code_34965000:"Appareil de mesure des distances (DME)",
   code_34966000:"Radiogoniomètre et radiophare non directionnel",
   code_34966100:"Radiogoniomètre (RDF)",
   code_34966200:"Radiophare non directionnel (NDB)",
   code_34967000:"Système de communication aéroportuaire (COM)",
   code_34968000:"Système de surveillance d'aéroport et système d'éclairage",
   code_34968100:"Système de surveillance d'aéroport (SUR)",
   code_34968200:"Système d'éclairage aéroportuaire",
   code_34969000:"Passerelles et échelles mobiles d'embarquement des passagers pour avions",
   code_34969100:"Passerelles d'embarquement des passagers pour avions",
   code_34969200:"Échelles mobiles d'embarquement des passagers pour avions",
   code_34970000:"Équipement de surveillance de la circulation",
   code_34971000:"Caméras de contrôle de la vitesse",
   code_34972000:"Système de mesure du flux de la circulation",
   code_34980000:"Billets de transport",
   code_34990000:"Équipement de contrôle, de sécurité, de signalisation et d'éclairage",
   code_34991000:"Projecteurs d'intervention extérieure",
   code_34992000:"Panneaux de signalisation et panneaux de signalisation lumineux",
   code_34992100:"Panneaux de signalisation lumineux",
   code_34992200:"Panneaux de signalisation routière",
   code_34992300:"Indicateurs de rues",
   code_34993000:"Éclairage public",
   code_34993100:"Éclairage de tunnel",
   code_34994000:"Signalisation et éclairage maritimes",
   code_34994100:"Signalisation et éclairage de navigation fluviale",
   code_34995000:"Balisage et éclairage d'aérodrome",
   code_34996000:"Équipement de commande et de contrôle, de sécurité ou de signalisation des routes",
   code_34996100:"Feux de circulation",
   code_34996200:"Équipement de commande et de contrôle, de sécurité ou de signalisation des voies navigables",
   code_34996300:"Équipement de commande et de contrôle, de sécurité ou de signalisation de parking",
   code_34997000:"Équipement de commande et de contrôle, de sécurité ou de signalisation d'aéroport",
   code_34997100:"Enregistreurs de vol",
   code_34997200:"Éclairage d'aéroport",
   code_34997210:"Feux de balisage de piste",
   code_34998000:"Équipement de commande et de contrôle, de sécurité ou de signalisation d'installations portuaires",
   code_34999000:"Générateurs de signaux, séparateurs de signal d'antenne et machines de galvanoplastie",
   code_34999100:"Générateurs de signaux",
   code_34999200:"Séparateurs de signal d'antenne",
   code_34999300:"Machines de galvanoplastie",
   code_34999400:"Maquettes",
   code_34999410:"Maquettes d'avions",
   code_34999420:"Maquettes de bateaux",
   code_35000000:"Équipement de sécurité, de lutte contre l'incendie, de police et de défense",
   code_35100000:"Matériel de secours et de sécurité",
   code_35110000:"Équipement de lutte contre l'incendie, de sauvetage et de sécurité",
   code_35111000:"Matériel de lutte contre l'incendie",
   code_35111100:"Appareils respiratoires pour la lutte contre l'incendie",
   code_35111200:"Équipement de lutte contre l'incendie",
   code_35111300:"Appareils extincteurs",
   code_35111310:"Mousse pour extincteurs",
   code_35111320:"Extincteurs portatifs",
   code_35111400:"Équipement d'évacuation en cas d'incendie",
   code_35111500:"Système d'extinction d'incendie",
   code_35111510:"Outillage pour l'extinction d'incendie",
   code_35111520:"Mousse d'extinction d'incendie ou composés similaires",
   code_35112000:"Équipement de sauvetage et d'urgence",
   code_35112100:"Mannequins d'entraînement pour les urgences",
   code_35112200:"Douche de sécurité",
   code_35112300:"Douche oculaire",
   code_35113000:"Matériel de sécurité",
   code_35113100:"Équipement de sécurité de site",
   code_35113110:"Système de protection de réacteurs nucléaires",
   code_35113200:"Équipement de protection nucléaire, biologique, chimique et radiologique",
   code_35113210:"Équipement de sûreté nucléaire",
   code_35113300:"Installations de sécurité",
   code_35113400:"Vêtements de protection et de sécurité",
   code_35113410:"Vêtements de protection biologique et chimique",
   code_35113420:"Vêtements de protection radiologique et nucléaire",
   code_35113430:"Gilets de protection",
   code_35113440:"Gilets réfléchissants",
   code_35113450:"Vêtements ou ponchos de protection",
   code_35113460:"Chaussettes ou articles de bonneterie de protection",
   code_35113470:"Chemises ou pantalons de protection",
   code_35113480:"Serre-poignets de protection",
   code_35113490:"Blouses de protection",
   code_35120000:"Systèmes et appareils de surveillance et de sécurité",
   code_35121000:"Équipement de sécurité",
   code_35121100:"Vibreurs (sonores)",
   code_35121200:"Détecteur de fausse monnaie",
   code_35121300:"Accessoires de sécurité",
   code_35121400:"Sacs de sécurité",
   code_35121500:"Joints",
   code_35121600:"Marques",
   code_35121700:"Systèmes d'alarme",
   code_35121800:"Miroirs de sécurité convexes",
   code_35121900:"Détecteurs de radar",
   code_35123000:"Équipement d'identification du site",
   code_35123100:"Système à cartes magnétiques",
   code_35123200:"Équipement pour horaires flexibles",
   code_35123300:"Système de chronométrage",
   code_35123400:"Badges d'identification",
   code_35123500:"Systèmes vidéo d'identification",
   code_35124000:"Détecteurs de métal",
   code_35125000:"Système de surveillance",
   code_35125100:"Capteurs",
   code_35125110:"Capteurs biométriques",
   code_35125200:"Système de chronométrage ou système d'enregistrement du temps de travail",
   code_35125300:"Caméras de sécurité",
   code_35126000:"Équipement de lecture des codes-barres",
   code_35200000:"Équipement de police",
   code_35210000:"Cibles de tir",
   code_35220000:"Équipement antiémeutes",
   code_35221000:"Canons à eau",
   code_35230000:"Menottes",
   code_35240000:"Sirènes",
   code_35250000:"Répulsifs pour chiens",
   code_35260000:"Signalisation de police",
   code_35261000:"Panneaux d'information",
   code_35261100:"Panneaux indicateurs à messages variables",
   code_35262000:"Équipement de signalisation de carrefour",
   code_35300000:"Armes, munitions et pièces associées",
   code_35310000:"Armes diverses",
   code_35311000:"Epées, machettes, baïonnettes et lances",
   code_35311100:"Épées",
   code_35311200:"Machettes",
   code_35311300:"Baïonnettes",
   code_35311400:"Lances",
   code_35312000:"Pistolets à gaz",
   code_35320000:"Armes à feu",
   code_35321000:"Armes à feu légères",
   code_35321100:"Pistolets",
   code_35321200:"Fusils",
   code_35321300:"Mitrailleuses",
   code_35322000:"Artillerie",
   code_35322100:"Artillerie antiaérienne",
   code_35322200:"Artillerie automotrice",
   code_35322300:"Artillerie tractée",
   code_35322400:"Mortiers",
   code_35322500:"Obusier",
   code_35330000:"Munitions",
   code_35331000:"Munitions d'armes à feu et de guerre",
   code_35331100:"Balles",
   code_35331200:"Obus",
   code_35331300:"Grenades",
   code_35331400:"Mines terrestres",
   code_35331500:"Cartouches",
   code_35332000:"Munitions de guerre navale",
   code_35332100:"Torpilles",
   code_35332200:"Mines marines",
   code_35333000:"Munitions de guerre aérienne",
   code_35333100:"Bombes",
   code_35333200:"Roquettes",
   code_35340000:"Pièces pour armes à feu et munitions",
   code_35341000:"Pièces pour armes à feu légères",
   code_35341100:"Accessoires de tuyauterie en bronze à canon",
   code_35342000:"Pièces de lance-roquettes",
   code_35343000:"Pièces pour mortiers",
   code_35400000:"Véhicules militaires et pièces connexes",
   code_35410000:"Véhicules blindés militaires",
   code_35411000:"Chars de combat",
   code_35411100:"Chars de combat lourds",
   code_35411200:"Chars de combat légers",
   code_35412000:"Véhicules blindés de combat",
   code_35412100:"Véhicules de combat d'infanterie",
   code_35412200:"Véhicules blindés de transport de troupes",
   code_35412300:"Véhicules blindés de transport d'armes",
   code_35412400:"Véhicules de reconnaissance et de patrouille",
   code_35412500:"Véhicules de commandement et de liaison",
   code_35420000:"Pièces de véhicules militaires",
   code_35421000:"Pièces mécaniques de rechange pour véhicules militaires",
   code_35421100:"Moteurs et pièces de moteur pour véhicules militaires",
   code_35422000:"Pièces électroniques et électriques de rechange pour véhicules militaires",
   code_35500000:"Navires de guerre et pièces connexes",
   code_35510000:"Navires de guerre",
   code_35511000:"Navire de combat de surface",
   code_35511100:"Porte-avions",
   code_35511200:"Destroyers et frégates",
   code_35511300:"Corvettes et patrouilleurs",
   code_35511400:"Engins et navires amphibies",
   code_35512000:"Sous-marins",
   code_35512100:"Sous-marin stratégique à propulsion nucléaire",
   code_35512200:"Sous-marin d'attaque à propulsion nucléaire",
   code_35512300:"Sous-marin d'attaque à propulsion diesel",
   code_35512400:"Submersibles sans équipage",
   code_35513000:"Mouilleurs de mines et navires auxiliaires",
   code_35513100:"Chasseurs de mines/dragueurs de mines",
   code_35513200:"Navire de recherche auxiliaire",
   code_35513300:"Navire auxiliaire de recherche du renseignement",
   code_35513400:"Navires hôpital/cargo/pétrolier/ro-ro auxiliaires",
   code_35520000:"Pièces pour navires de guerre",
   code_35521000:"Pièces de rechange pour coque et pièces mécaniques pour navires de guerre",
   code_35521100:"Moteur et pièces de moteur pour navires de guerre",
   code_35522000:"Pièces électroniques et électriques de rechange pour navires de guerre",
   code_35600000:"Avions, missiles et spationefs militaires",
   code_35610000:"Avions militaires",
   code_35611100:"Avions de combat",
   code_35611200:"Chasseurs-bombardiers/Avions d'attaque au sol",
   code_35611300:"Bombardiers",
   code_35611400:"Avions militaires de transport",
   code_35611500:"Avions d'entraînement",
   code_35611600:"Avions de patrouille maritime",
   code_35611700:"Avions ravitailleurs",
   code_35611800:"Avions de reconnaissance",
   code_35612100:"Hélicoptères de combat",
   code_35612200:"Hélicoptères de lutte anti-sous-marine",
   code_35612300:"Hélicoptères de soutien",
   code_35612400:"Hélicoptères militaires de transport",
   code_35612500:"Hélicoptères de recherche et de sauvetage",
   code_35613000:"Véhicules aériens sans pilote",
   code_35613100:"Véhicules aériens de combat sans pilote",
   code_35620000:"Missiles",
   code_35621000:"Missiles stratégiques",
   code_35621100:"Missiles stratégiques antibalistiques",
   code_35621200:"Missiles balistiques intercontinentaux",
   code_35621300:"Missiles balistiques lancés par sous-marin",
   code_35621400:"Missiles balistiques à portée intermédiaire",
   code_35622000:"Missiles tactiques",
   code_35622100:"Missiles air-air",
   code_35622200:"Missiles sol-air",
   code_35622300:"Missiles antinavire",
   code_35622400:"Roquettes anti-sous-marin",
   code_35622500:"Missiles tactiques antibalistiques",
   code_35622600:"Missiles guidés antichar",
   code_35622700:"Missiles surface-air",
   code_35623000:"Missiles de croisière",
   code_35623100:"Missiles de croisière lancés par air/à partir du sol/à partir de la mer",
   code_35630000:"Spationefs militaires",
   code_35631000:"Satellites militaires",
   code_35631100:"Satellites de communication",
   code_35631200:"Satellites d'observation",
   code_35631300:"Satellites de navigation",
   code_35640000:"Pièces pour équipement aérospatial militaire",
   code_35641000:"Pièces de rechange structurelles et mécaniques pour équipement aérospatial militaire",
   code_35641100:"Moteurs et pièces de moteur pour équipement aérospatial militaire",
   code_35642000:"Pièces de rechange électriques et électroniques pour équipement aérospatial militaire",
   code_35700000:"Systèmes électroniques militaires",
   code_35710000:"Systèmes de commandement, de contrôle, de communication et d'informatique",
   code_35711000:"Systèmes de commandement, de contrôle et de communication",
   code_35712000:"Systèmes tactiques de commandement, de contrôle et de communication",
   code_35720000:"Renseignement, surveillance, acquisition d'objectifs et reconnaissance",
   code_35721000:"Système de renseignement électronique",
   code_35722000:"Radar",
   code_35723000:"Radar de défense aérienne",
   code_35730000:"Systèmes de guerre électronique et contre-mesures",
   code_35740000:"Simulateurs de combat",
   code_35800000:"Équipement individuel et de soutien",
   code_35810000:"Équipement individuel",
   code_35811100:"Uniformes de pompiers",
   code_35811200:"Uniformes de police",
   code_35811300:"Uniformes militaires",
   code_35812000:"Uniformes de combat",
   code_35812100:"Vestes de camouflage",
   code_35812200:"Tenues de combat",
   code_35812300:"Équipements de combat",
   code_35813000:"Casques militaires",
   code_35813100:"Couvre-casques",
   code_35814000:"Masques à gaz",
   code_35815000:"Vêtements pare-balles",
   code_35815100:"Gilets pare-balles",
   code_35820000:"Équipement de soutien",
   code_35821000:"Drapeaux",
   code_35821100:"Mât porte-drapeau",
   code_37000000:"Instruments de musique, articles de sport, jeux, jouets, articles pour artisanat, articles pour travaux artistiques et accessoires",
   code_37300000:"Instruments de musique et pièces pour instruments de musique",
   code_37310000:"Instruments de musique",
   code_37311000:"Instruments à clavier",
   code_37311100:"Pianos",
   code_37311200:"Accordéons",
   code_37311300:"Orgues musicaux",
   code_37311400:"Célestas",
   code_37312000:"Instruments à vent en cuivre",
   code_37312100:"Trompettes",
   code_37312200:"Trombones",
   code_37312300:"Soubassophones",
   code_37312400:"Saxophones",
   code_37312500:"Sifflet",
   code_37312600:"Bugles",
   code_37312700:"Saxhorns, bugles à pistons",
   code_37312800:"Mellophones",
   code_37312900:"Cors alto, baryton, flugel et français",
   code_37312910:"Cors alto",
   code_37312920:"Cors baryton",
   code_37312930:"Cors flugel",
   code_37312940:"Cors français",
   code_37313000:"Instruments à cordes",
   code_37313100:"Clavecins",
   code_37313200:"Clavicordes",
   code_37313300:"Guitares",
   code_37313400:"Violons",
   code_37313500:"Harpes",
   code_37313600:"Banjos",
   code_37313700:"Mandolines",
   code_37313800:"Violoncelles",
   code_37313900:"Basses",
   code_37314000:"Instruments à vent",
   code_37314100:"Clarinettes",
   code_37314200:"Hautbois",
   code_37314300:"Cornets et flûtes musicales",
   code_37314310:"Cornets musicaux",
   code_37314320:"Flûtes musicales",
   code_37314400:"Piccolos",
   code_37314500:"Cornemuse",
   code_37314600:"Harmonicas",
   code_37314700:"Mirlitons",
   code_37314800:"Cors anglais",
   code_37314900:"Ocarinas",
   code_37315000:"Instruments de musique à amplificateur électrique",
   code_37315100:"Synthétiseurs",
   code_37316000:"Instruments à percussion",
   code_37316100:"Cymbales",
   code_37316200:"Cloches",
   code_37316300:"Tambourins",
   code_37316400:"Castagnettes",
   code_37316500:"Tambours",
   code_37316600:"Xylophones",
   code_37316700:"Vibraphones",
   code_37320000:"Pièces et accessoires pour instruments de musique",
   code_37321000:"Accessoires pour instruments de musique",
   code_37321100:"Métronomes",
   code_37321200:"Anches",
   code_37321300:"Accessoires pour instruments à cordes",
   code_37321400:"Cordes ou médiators pour instrument",
   code_37321500:"Accessoires pour instruments à percussion",
   code_37321600:"Sacoches, étuis ou accessoires pour instruments de musique",
   code_37321700:"Trépieds ou porte-partitions pour instruments de musique",
   code_37322000:"Pièces pour instruments de musique",
   code_37322100:"Chevilles",
   code_37322200:"Boîtes ou mécanismes à musique",
   code_37322300:"Embouchures",
   code_37322400:"Sourdines",
   code_37322500:"Diapasons droits",
   code_37322600:"Baguettes de chef d'orchestre",
   code_37322700:"Support rembourré pour piccolo",
   code_37400000:"Articles et équipements de sport",
   code_37410000:"Équipements de sports de plein air",
   code_37411000:"Équipement d'hiver",
   code_37411100:"Équipement de ski et de planches à ski",
   code_37411110:"Bottes de ski",
   code_37411120:"Skis",
   code_37411130:"Bâtons de ski",
   code_37411140:"Fixations",
   code_37411150:"Planches à ski",
   code_37411160:"Tenues de ski",
   code_37411200:"Équipement de patinage et de hockey sur glace",
   code_37411210:"Rondelles de hockey",
   code_37411220:"Patins à glace",
   code_37411230:"Cannes de hockey",
   code_37411300:"Vêtements et équipements polaires",
   code_37412000:"Équipements de sports nautiques",
   code_37412100:"Skis nautiques",
   code_37412200:"Appareil respiratoire autonome de plongée et de snorkeling",
   code_37412210:"Compensateurs",
   code_37412220:"Bouteilles d'air comprimé",
   code_37412230:"Détendeurs",
   code_37412240:"Instruments ou accessoires de plongée",
   code_37412241:"Appareils respiratoires pour la plongée",
   code_37412242:"Tenues de plongée",
   code_37412243:"Combinaisons d'immersion",
   code_37412250:"Masques, palmes ou tubas",
   code_37412260:"Combinaisons de plongée",
   code_37412270:"Combinaisons étanches",
   code_37412300:"Équipement et accessoires pour le surf et la natation",
   code_37412310:"Planches à skis, kneeboards ou boogieboards",
   code_37412320:"Équipement pour planche à voile",
   code_37412330:"Planches de surf",
   code_37412340:"Lunettes ou palmes de natation",
   code_37412350:"Équipement pour parapente",
   code_37413000:"Articles de chasse et de pêche",
   code_37413100:"Articles de pêche",
   code_37413110:"Cannes à pêche",
   code_37413120:"Ligne de pêche",
   code_37413130:"Moulinets de pêche",
   code_37413140:"Leurres de pêche",
   code_37413150:"Appâts de pêche",
   code_37413160:"Plombs de pêche",
   code_37413200:"Articles de chasse",
   code_37413210:"Appeaux",
   code_37413220:"Appelants",
   code_37413230:"Ball-traps",
   code_37413240:"Canon d'arme à feu",
   code_37414000:"Articles de camping",
   code_37414100:"Matelas de camping",
   code_37414200:"Bacs à glace",
   code_37414300:"Trousses de réparation de tentes",
   code_37414600:"Réchauds de camping",
   code_37414700:"Glacières",
   code_37414800:"Combinaisons de survie",
   code_37415000:"Équipements d'athlétisme",
   code_37416000:"Équipements de loisirs",
   code_37420000:"Équipements de gymnastique",
   code_37421000:"Tapis de gymnastique",
   code_37422000:"Barres ou poutres de gymnastique",
   code_37422100:"Barres de gymnastique",
   code_37422200:"Poutres de gymnastique",
   code_37423000:"Cordes, anneaux ou espaliers de gymnastique",
   code_37423100:"Cordes de gymnastique",
   code_37423200:"Anneaux de gymnastique",
   code_37423300:"Espaliers de gymnastique",
   code_37424000:"Équipements de saut gymnastique",
   code_37425000:"Trampolines de gymnastique",
   code_37426000:"Équipement pour le travail de l'équilibre",
   code_37430000:"Équipement de boxe",
   code_37431000:"Rings de boxe",
   code_37432000:"Sacs de sable",
   code_37433000:"Gants de boxe",
   code_37440000:"Équipement de culture physique",
   code_37441000:"Équipement d'entraînement pour l'aérobique",
   code_37441100:"Tapis d'exercice",
   code_37441200:"Simulateur d'escalier",
   code_37441300:"Vélos d'exercice",
   code_37441400:"Rameurs",
   code_37441500:"Cordes à sauter",
   code_37441600:"Trampolines d'exercice",
   code_37441700:"Ballons d'exercice",
   code_37441800:"Équipement de step",
   code_37441900:"Appareils elliptiques",
   code_37442000:"Équipement d'haltérophilie et de musculation",
   code_37442100:"Haltères",
   code_37442200:"Barres à disques",
   code_37442300:"Machines de musculation des membres inférieurs et supérieurs",
   code_37442310:"Machines de musculation des membres inférieurs",
   code_37442320:"Machines de musculation des membres supérieurs",
   code_37442400:"Bancs et supports de poids",
   code_37442500:"Poids d'exercice",
   code_37442600:"Machines de Pilates",
   code_37442700:"Appareil pour musculation des mains",
   code_37442800:"Bandes et tubes de résistance",
   code_37442810:"Bandes de résistance",
   code_37442820:"Tubes de résistance",
   code_37442900:"Appareils multigym",
   code_37450000:"Équipement pour sports de plein air et sur courts",
   code_37451000:"Équipements pour sports de plein air",
   code_37451100:"Balles de base-ball",
   code_37451110:"Écrans arrière ou clôture pour le base-ball",
   code_37451120:"Bases de base-ball",
   code_37451130:"Battes de base-ball",
   code_37451140:"Aides à la frappe pour le base-ball",
   code_37451150:"Gants de base-ball",
   code_37451160:"Protections pour le base-ball ou le softball",
   code_37451200:"Hockey sur gazon",
   code_37451210:"Balles de hockey sur gazon",
   code_37451220:"Crosses de hockey sur gazon",
   code_37451300:"Ballons de football américain",
   code_37451310:"Traîneaux de blocage pour le football américain",
   code_37451320:"Tees de tir pour le football américain",
   code_37451330:"Mannequins d'entraînement pour le football américain",
   code_37451340:"Équipement pour le football au drapeau (flag-football)",
   code_37451400:"Balles du jeu de crosse",
   code_37451500:"Bâtons du jeu de crosse",
   code_37451600:"Lance-balles automatiques",
   code_37451700:"Ballons de football",
   code_37451710:"Matériel de marquage des terrains de football",
   code_37451720:"Équipement de protection pour le football",
   code_37451730:"Dispositifs d'entraînement pour le football",
   code_37451800:"Balles de softball",
   code_37451810:"Battes de softball",
   code_37451820:"Gants de softball",
   code_37451900:"Ballons de handball",
   code_37451920:"Équipements scolaires pour le handball",
   code_37452000:"Équipement pour sports de raquette et sur courts",
   code_37452100:"Équipement de badminton",
   code_37452110:"Volants de badminton",
   code_37452120:"Raquettes de badminton",
   code_37452200:"Ballons de basket-ball",
   code_37452210:"Ensemble complet de basket-ball",
   code_37452300:"Équipement de protection pour le hockey en salle",
   code_37452400:"Balles, grips et cordes pour le racquetball",
   code_37452410:"Balles de racquetball",
   code_37452420:"Grips de racquetball",
   code_37452430:"Cordes de racquetball",
   code_37452500:"Raquettes de racquetball",
   code_37452600:"Équipement de squash",
   code_37452610:"Balles de squash",
   code_37452620:"Raquettes de squash",
   code_37452700:"Équipement de tennis",
   code_37452710:"Balles de tennis",
   code_37452720:"Matériel de court de tennis",
   code_37452730:"Raquettes de tennis",
   code_37452740:"Aides à l'entraînement pour le tennis",
   code_37452800:"Balles et piquets de spirobole",
   code_37452810:"Balles de spirobole",
   code_37452820:"Piquets de spirobole",
   code_37452900:"Ballons de volley-ball",
   code_37452910:"Normes de volley-ball applicables aux gymnases",
   code_37452920:"Stockage des ballons et des filets de volley-ball",
   code_37453000:"Équipement d'athlétisme",
   code_37453100:"Javelots",
   code_37453200:"Sautoir",
   code_37453300:"Disque",
   code_37453400:"Poids à lancer",
   code_37453500:"Perches à sauter",
   code_37453600:"Haies",
   code_37453700:"Témoins",
   code_37460000:"Équipement pour jeux de tir et de table",
   code_37461000:"Équipement pour jeux de table",
   code_37461100:"Tables pour hockey sur coussins d'air ou accessoires",
   code_37461200:"Baby-foot",
   code_37461210:"Joueurs de rechange pour baby-foot",
   code_37461220:"Tables de baby-foot",
   code_37461300:"Queues de billard",
   code_37461400:"Équipement de jeu de palets",
   code_37461500:"Table de tennis",
   code_37461510:"Balles de tennis de table",
   code_37461520:"Raquettes de tennis de table",
   code_37462000:"Jeux de tir et équipement",
   code_37462100:"Équipement de tir à l'arc",
   code_37462110:"Bracelet pour le tir à l'arc",
   code_37462120:"Flèches de tir à l'arc",
   code_37462130:"Écrans arrière pour le tir à l'arc",
   code_37462140:"Cordes pour le tir à l'arc",
   code_37462150:"Arcs",
   code_37462160:"Gants d'archer",
   code_37462170:"Cibles sur pied pour le tir à l'arc",
   code_37462180:"Cibles pour le tir à l'arc",
   code_37462200:"Fléchettes",
   code_37462210:"Cibles à fléchettes",
   code_37462300:"Cibles à lancer",
   code_37462400:"Équipement de tir au pigeon",
   code_37470000:"Équipements pour le golf et le jeu de quilles",
   code_37471000:"Équipement de golf",
   code_37471100:"Sacs de golf",
   code_37471200:"Balles de golf",
   code_37471300:"Clubs de golf",
   code_37471400:"Tees de golf",
   code_37471500:"Housses pour clubs de golf",
   code_37471600:"Gants de golf",
   code_37471700:"Assujettisseurs de mottes",
   code_37471800:"Télémètres de golf",
   code_37471900:"Aide au putting",
   code_37472000:"Équipements pour le jeu de quilles",
   code_37480000:"Machines ou appareils pour l'équipement de loisir",
   code_37481000:"Machines d'entretien de la glace",
   code_37482000:"Tableaux d'affichage pour les informations sportives",
   code_37500000:"Jeux et jouets; attractions foraines",
   code_37510000:"Poupées",
   code_37511000:"Maisons de poupées",
   code_37512000:"Pièces ou accessoires pour poupées",
   code_37513000:"Marionnettes",
   code_37513100:"Théâtres de marionnettes",
   code_37520000:"Jouets",
   code_37521000:"Instruments de musique en jouets",
   code_37522000:"Jouets à roues",
   code_37523000:"Puzzles",
   code_37524000:"Jeux",
   code_37524100:"Jeux éducatifs",
   code_37524200:"Jeux de table",
   code_37524300:"Jeux classiques",
   code_37524400:"Jeux coopératifs",
   code_37524500:"Jeux de stratégie",
   code_37524600:"Jeux de mémoire",
   code_37524700:"Accessoires de jeux",
   code_37524800:"Jeux de loto",
   code_37524810:"Formulaire de loterie",
   code_37524900:"Kits de jeux",
   code_37525000:"Ballonnets et ballons",
   code_37526000:"Seaux-jouets",
   code_37527000:"Trains et véhicules-jouets, petits trains et petites voitures",
   code_37527100:"Petits trains",
   code_37527200:"Petites voitures",
   code_37528000:"Armes-jouets",
   code_37529000:"Jouets gonflables et à enfourcher",
   code_37529100:"Jouets gonflables",
   code_37529200:"Jouets à enfourcher",
   code_37530000:"Articles pour fêtes foraines, jeux de société ou jeux de salon",
   code_37531000:"Jeux de cartes",
   code_37532000:"Jeux vidéo",
   code_37533000:"Billards",
   code_37533100:"Boules de billard",
   code_37533200:"Craie de billard",
   code_37533300:"Queues de billard",
   code_37533400:"Triangles de billard",
   code_37533500:"Tables de billard",
   code_37534000:"Jeux à pièces de monnaie ou à jetons",
   code_37535000:"Manèges, balançoires, stands de tir et autres attractions foraines",
   code_37535100:"Balançoires",
   code_37535200:"Équipements pour terrain de jeux",
   code_37535210:"Balançoires pour terrain de jeux",
   code_37535220:"Matériels à grimper pour terrain de jeux",
   code_37535230:"Manèges pour terrain de jeux",
   code_37535240:"Toboggans pour terrain de jeux",
   code_37535250:"Balançoires à bascules pour terrain de jeux",
   code_37535260:"Tunnels pour terrain de jeux",
   code_37535270:"Bacs à sable pour terrain de jeux",
   code_37535280:"Gradins pour terrain de jeux",
   code_37535290:"Équipement pour l'escalade ou le grimper à la corde",
   code_37535291:"Équipement pour l'escalade",
   code_37535292:"Équipement pour le grimper à la corde",
   code_37540000:"Machines de jeu",
   code_37800000:"Fournitures pour travaux d'artisanat et travaux artistiques",
   code_37810000:"Fournitures pour travaux d'artisanat",
   code_37820000:"Fournitures pour travaux artistiques",
   code_37821000:"Pinceaux d'art",
   code_37822000:"Plumes à dessin",
   code_37822100:"Crayons de couleur",
   code_37822200:"Fusain",
   code_37822300:"Craies",
   code_37822400:"Pastels",
   code_37823000:"Papier simili-sulfurisé et autres articles en papier",
   code_37823100:"Papier simili-sulfurisé",
   code_37823200:"Papier-calque",
   code_37823300:"Papier cristal",
   code_37823400:"Papier transparent ou papier translucide",
   code_37823500:"Papier couché et papier d'artisanat",
   code_37823600:"Papier à dessin",
   code_37823700:"Papier pour cartographie",
   code_37823800:"Papier et carton multicouches",
   code_37823900:"Papier kraft",
   code_38000000:"Équipements de laboratoire, d'optique et de précision (excepté les lunettes)",
   code_38100000:"Instruments de navigation et de météorologie",
   code_38110000:"Instruments de navigation",
   code_38111000:"Matériel de radiogoniométrie",
   code_38111100:"Compas",
   code_38111110:"Accessoires pour compas",
   code_38112000:"Sextants",
   code_38112100:"Systèmes de positionnement à capacité globale (GPS ou équivalent)",
   code_38113000:"Sonars",
   code_38114000:"Sondeurs à écho",
   code_38115000:"Radars",
   code_38115100:"Matériel de surveillance radar",
   code_38120000:"Instruments de météorologie",
   code_38121000:"Anémomètres",
   code_38122000:"Baromètres",
   code_38123000:"Enregistreurs de précipitations ou d'évaporation",
   code_38124000:"Appareils de radiosondage",
   code_38125000:"Pluviographes",
   code_38126000:"Appareils d'observation en surface",
   code_38126100:"Appareils d'observation en surface des précipitations ou de l'évaporation",
   code_38126200:"Appareils d'observation en surface des radiations solaires",
   code_38126300:"Appareils d'observation en surface des températures ou de l'humidité",
   code_38126400:"Appareils d'observation en surface des vents",
   code_38127000:"Stations météorologiques",
   code_38128000:"Accessoires pour instruments météorologiques",
   code_38200000:"Instruments géologiques et géophysiques",
   code_38210000:"Boussoles de géologue",
   code_38220000:"Appareils de prospection géologique",
   code_38221000:"Systèmes d'information géographique (GIS ou équivalent)",
   code_38230000:"Instruments géophysiques électromagnétiques",
   code_38240000:"Instruments géophysiques de mesure de la gravité",
   code_38250000:"Instruments géophysique de mesure de la polarité induite (IP)",
   code_38260000:"Instruments géophysiques de magnétomètrie",
   code_38270000:"Instruments géophysiques pour la mesure de la résistivité",
   code_38280000:"Gravimètres",
   code_38290000:"Instruments et matériels de télémétrie, d'hydrographie, d'océanographie et d'hydrologie",
   code_38291000:"Appareils de télémétrie",
   code_38292000:"Instruments d'hydrographie",
   code_38293000:"Matériel de sismologie",
   code_38294000:"Théodolites",
   code_38295000:"Matériel de topographie",
   code_38296000:"Instruments de géodésie",
   code_38300000:"Instruments de mesure",
   code_38310000:"Balances de précision",
   code_38311000:"Balances électroniques et accessoires",
   code_38311100:"Balances analytiques électroniques",
   code_38311200:"Balances techniques électroniques",
   code_38311210:"Masses d'étalonnage",
   code_38320000:"Tables de dessin",
   code_38321000:"Machines de dessin",
   code_38322000:"Pantographes",
   code_38323000:"Règles à calcul",
   code_38330000:"Instruments à main pour la mesure de longueurs",
   code_38331000:"Équerres",
   code_38340000:"Instruments de mesure de grandeurs",
   code_38341000:"Appareils de mesure du rayonnement",
   code_38341100:"Enregistreurs à faisceaux d'électrons",
   code_38341200:"Dosimètres de rayonnement",
   code_38341300:"Instruments de mesure de grandeurs électriques",
   code_38341310:"Ampèremètres",
   code_38341320:"Voltmètres",
   code_38341400:"Compteurs Geiger",
   code_38341500:"Système de surveillance de la contamination",
   code_38341600:"Moniteurs de rayonnement",
   code_38342000:"Oscilloscopes",
   code_38342100:"Oscillographes",
   code_38343000:"Restauration",
   code_38344000:"Système de surveillance de la pollution",
   code_38400000:"Instruments de contrôle de propriétés physiques",
   code_38410000:"Instruments de comptage",
   code_38411000:"Hydromètres",
   code_38412000:"Thermomètres",
   code_38413000:"Pyromètres",
   code_38414000:"Hygromètres",
   code_38415000:"Psychromètres",
   code_38416000:"PHmètres",
   code_38417000:"Thermocouples",
   code_38418000:"Calorimètres",
   code_38420000:"Instruments de mesure du débit, du niveau et de la pression de liquides ou de gaz",
   code_38421000:"Débitmètres",
   code_38421100:"Compteurs à eau",
   code_38421110:"Débitmètres",
   code_38422000:"Matériel de mesure du niveau",
   code_38423000:"Matériel de mesure de la pression",
   code_38423100:"Indicateurs de pression",
   code_38424000:"Matériel de mesure et de contrôle",
   code_38425000:"Équipement pour mécanique des fluides",
   code_38425100:"Manomètres",
   code_38425200:"Viscosimètres",
   code_38425300:"Indicateurs de profondeur",
   code_38425400:"Appareils d'estimation des structures",
   code_38425500:"Appareil d'estimation des forces",
   code_38425600:"Pycnomètres",
   code_38425700:"Instruments de mesure de la tension superficielle",
   code_38425800:"Densitomètres",
   code_38426000:"Coulomètres",
   code_38427000:"Fluxmètres",
   code_38428000:"Rhéomètres",
   code_38429000:"Rotamètres",
   code_38430000:"Appareils de détection et d'analyse",
   code_38431000:"Appareils de détection",
   code_38431100:"Détecteurs de gaz",
   code_38431200:"Détecteurs de fumée",
   code_38431300:"Détecteurs d'erreurs",
   code_38432000:"Appareils d'analyses",
   code_38432100:"Analyseurs de gaz",
   code_38432200:"Chromatographes",
   code_38432210:"Chromatographes à gaz",
   code_38432300:"Analyseurs de fumée",
   code_38433000:"Spectromètres",
   code_38433100:"Spectromètre de masse",
   code_38433200:"Matériel de mesure des émissions",
   code_38433210:"Spectromètre à émission",
   code_38433300:"Analyseur de spectre",
   code_38434000:"Analyseurs",
   code_38434100:"Analyseurs d'expansion",
   code_38434200:"Matériel de mesure du son",
   code_38434210:"Sonomètres",
   code_38434220:"Analyseurs de la vitesse du son",
   code_38434300:"Matériel de mesure du bruit",
   code_38434310:"Décibelmètres",
   code_38434400:"Analyseurs de vibrations",
   code_38434500:"Analyseurs biochimiques",
   code_38434510:"Cytomètres",
   code_38434520:"Analyseurs de sang",
   code_38434530:"Analyseurs de lait",
   code_38434540:"Matériel biomédical",
   code_38434550:"Compte-globules",
   code_38434560:"Analyseur de chimie",
   code_38434570:"Analyseurs d'hématologie",
   code_38434580:"Analyseurs d'immunologie",
   code_38435000:"Détecteurs de fluides",
   code_38436000:"Agitateurs et accessoires",
   code_38436100:"Agitateurs mécaniques",
   code_38436110:"Corbeille pour flacons Erlenmeyer pour agitateurs",
   code_38436120:"Pinces à flacons Erlenmeyer pour agitateurs",
   code_38436130:"Supports pour entonnoirs de séparation",
   code_38436140:"Porte-pinces à flacons Erlenmeyer pour agitateurs",
   code_38436150:"Porte-boîtes de Petri pour agitateurs",
   code_38436160:"Porte-éprouvettes pour agitateurs",
   code_38436170:"Adaptateur pour flacons pour agitateurs",
   code_38436200:"Évaporateurs rotatifs",
   code_38436210:"Écran protecteur pour évaporateurs rotatifs",
   code_38436220:"Capteur de température d'ébullition pour évaporateurs rotatifs",
   code_38436230:"Régulateur de pression pour évaporateurs rotatifs",
   code_38436300:"Agitateurs d'incubation",
   code_38436310:"Plaques chauffantes",
   code_38436320:"Plaques chauffantes pour flacons",
   code_38436400:"Agitateurs magnétiques",
   code_38436410:"Thermorégulateurs pour agitateurs mécaniques avec plaques chauffantes",
   code_38436500:"Mélangeurs mécaniques",
   code_38436510:"Aubes pour mélangeurs mécaniques",
   code_38436600:"Homogénéisateurs à immersion",
   code_38436610:"Instruments de dispersion pour homogénéisateurs à immersion",
   code_38436700:"Désintégrateurs à ultrasons",
   code_38436710:"Sondes pour désintégrateurs à ultrasons",
   code_38436720:"Convertisseurs pour désintégrateurs à ultrasons",
   code_38436730:"Chambres à débit continu pour désintégrateurs à ultrasons",
   code_38436800:"Homogénéisateurs à lames rotatives",
   code_38437000:"Pipettes de laboratoire et accessoires",
   code_38437100:"Pipettes",
   code_38437110:"Embouts de pipettes",
   code_38437120:"Porte-pipettes",
   code_38500000:"Appareils de contrôle et d'essai",
   code_38510000:"Microscopes",
   code_38511000:"Microscopes électroniques",
   code_38511100:"Microscopes électroniques à balayage",
   code_38511200:"Microscopes électroniques à transmission",
   code_38512000:"Microscopes ioniques et monoculaires",
   code_38512100:"Microscopes ioniques",
   code_38512200:"Microscopes moléculaires",
   code_38513000:"Microscopes inversés et métallurgiques",
   code_38513100:"Microscopes inversés",
   code_38513200:"Microscopes métallurgiques",
   code_38514000:"Microscopes à fond noir et microscopes à sonde à balayage",
   code_38514100:"Microscopes à fond noir",
   code_38514200:"Microscopes à sonde à balayage",
   code_38515000:"Microscopes polarisants et microscopes à fluorescence",
   code_38515100:"Microscopes polarisants",
   code_38515200:"Microscope à fluorescence",
   code_38516000:"Microscopes composés monoculaires et/ou binoculaires",
   code_38517000:"Microscopes acoustiques et microscopes à projection",
   code_38517100:"Microscopes acoustiques",
   code_38517200:"Microscopes à projection",
   code_38518000:"Microscopes à grand champ, microscopes stéréoscopiques ou microscopes à dissection",
   code_38518100:"Microscopes à grand champ",
   code_38518200:"Microscopes stéréoscopiques ou microscopes à dissection",
   code_38519000:"Composés divers pour microscopes",
   code_38519100:"Illuminateurs pour microscopes",
   code_38519200:"Objectifs pour microscopes",
   code_38519300:"Accessoires photo ou vidéo pour microscopes",
   code_38519310:"Accessoires photo pour microscopes",
   code_38519320:"Accessoires vidéo pour microscopes",
   code_38519400:"Platines porte-objet automatisées pour microscopes",
   code_38519500:"Ampoules de rechange pour microscope de laboratoire",
   code_38519600:"Oculaires, condensateurs, collecteurs, platines porte-objet et housses de microscope",
   code_38519610:"Oculaires de microscope",
   code_38519620:"Condensateurs de microscope",
   code_38519630:"Collecteurs de microscope",
   code_38519640:"Tubes de microscope",
   code_38519650:"Platines porte-objet de microscope",
   code_38519660:"Housses de microscope",
   code_38520000:"Scanners",
   code_38521000:"Scintigraphes à pression",
   code_38522000:"Scintigraphes chromatographiques",
   code_38527100:"Dosimètres à chambre d'ionisation",
   code_38527200:"Dosimètres",
   code_38527300:"Systèmes standard de dosimétrie secondaire",
   code_38527400:"Dosimètres de fantômes",
   code_38530000:"Diffractographes",
   code_38540000:"Machines et appareils d'essai et de mesure",
   code_38541000:"Testeurs de soudabilité",
   code_38542000:"Appareils d'essai servo-hydrauliques",
   code_38543000:"Matériel de détection de gaz",
   code_38544000:"Détecteurs de stupéfiants",
   code_38545000:"Kits d'analyse de gaz",
   code_38546000:"Système de détection d'explosifs",
   code_38546100:"Détecteurs de bombes",
   code_38547000:"Système de dosimétrie",
   code_38548000:"Instruments pour véhicules",
   code_38550000:"Compteurs",
   code_38551000:"Compteurs d'énergie",
   code_38552000:"Compteurs électroniques",
   code_38553000:"Compteurs magnétiques",
   code_38554000:"Compteurs d'électricité",
   code_38560000:"Compteurs de production",
   code_38561000:"Compte-tours",
   code_38561100:"Indicateurs de vitesse pour véhicules",
   code_38561110:"Tachymètres",
   code_38561120:"Compteurs de taxi",
   code_38562000:"Stroboscopes",
   code_38570000:"Instruments et appareils de réglage et de contrôle",
   code_38571000:"Limiteurs de vitesse",
   code_38580000:"Appareils utilisant les rayonnements et destinés à des usages autres que médicaux",
   code_38581000:"Équipement de scanning pour le contrôle des bagages",
   code_38582000:"Équipement de contrôle aux rayons X",
   code_38600000:"Instruments d'optique",
   code_38620000:"Matériel de polarisation",
   code_38621000:"Appareils à fibres optiques",
   code_38622000:"Miroirs",
   code_38623000:"Filtres optiques",
   code_38624000:"Dispositifs d'aide optique",
   code_38630000:"Instruments d'astronomie et d'optique",
   code_38631000:"Jumelles",
   code_38632000:"Lunettes de vision nocturne",
   code_38633000:"Lunettes de visée",
   code_38634000:"Microscopes optiques",
   code_38635000:"Télescopes",
   code_38636000:"Instruments d'optique spécialisés",
   code_38636100:"Lasers",
   code_38636110:"Lasers industriels",
   code_38640000:"Dispositifs à cristaux liquides",
   code_38641000:"Périscopes",
   code_38650000:"Matériel de photographie",
   code_38651000:"Appareils photographiques",
   code_38651100:"Objectifs photographiques",
   code_38651200:"Corps d'appareils photographiques",
   code_38651300:"Appareils de reproduction pour la préparation de plaques ou de cylindres d'impression",
   code_38651400:"Appareils photographiques à développement instantané",
   code_38651500:"Caméras cinématographiques",
   code_38651600:"Appareils photographiques numériques",
   code_38652000:"Projecteurs cinématographiques",
   code_38652100:"Appareils de projection",
   code_38652110:"Projecteurs de diapositives",
   code_38652120:"Vidéoprojecteurs",
   code_38652200:"Agrandisseurs",
   code_38652300:"Réducteurs",
   code_38653000:"Appareils pour laboratoires photographiques",
   code_38653100:"Flashes",
   code_38653110:"Ampoules de flash",
   code_38653111:"Cubes flashes",
   code_38653200:"Agrandisseurs photographiques",
   code_38653300:"Appareils et matériel de développement de pellicules photographiques",
   code_38653400:"Écrans de projection",
   code_38654000:"Matériel pour microfilms et microfiches",
   code_38654100:"Matériel pour microfilms",
   code_38654110:"Lecteurs de microfilms",
   code_38654200:"Matériel pour microfiches",
   code_38654210:"Lecteurs de microfiches",
   code_38654300:"Matériel pour microformes",
   code_38654310:"Lecteurs de microformes",
   code_38700000:"Horloges de pointage et compteurs similaires; parcmètres",
   code_38710000:"Horloges de pointage",
   code_38720000:"Horodateurs",
   code_38730000:"Parcmètres",
   code_38731000:"Compteurs à jetons",
   code_38740000:"Minuteurs",
   code_38750000:"Interrupteurs horaires",
   code_38800000:"Équipement de commande des processus industriels et matériel de télécommande",
   code_38810000:"Équipement de commande des processus industriels",
   code_38820000:"Matériel de télécommande",
   code_38821000:"Appareils de radiotélécommande",
   code_38822000:"Sirènes télécommandées",
   code_38900000:"Instruments divers d'évaluation ou de test",
   code_38910000:"Matériel de vérification et de test d'hygiène",
   code_38911000:"Kits pour prélèvement manuels sur écouvillon",
   code_38912000:"Kits pour prélèvement automatiques sur écouvillon",
   code_38920000:"Équipement pour grains et aliments pour animaux",
   code_38921000:"Analyseurs de grains",
   code_38922000:"Compteurs de grains",
   code_38923000:"Analyseurs d'aliments",
   code_38930000:"Instruments de mesure de l'humidité",
   code_38931000:"Appareils de contrôle de l'humidité et de la température",
   code_38932000:"Appareils de mesure de l'humidité",
   code_38940000:"Instruments d'évaluation nucléaire",
   code_38941000:"Compteurs alpha",
   code_38942000:"Compteurs alpha beta",
   code_38943000:"Compteurs beta",
   code_38944000:"Compteurs beta gamma",
   code_38945000:"Compteurs gamma",
   code_38946000:"Kilovoltmètres",
   code_38947000:"Microanalyseurs de rayons X",
   code_38950000:"Matériel pour réaction en chaîne à la polymérase (PCR)",
   code_38951000:"Matériel pour réaction en chaîne à la polymérase en temps réel (RT-PCR)",
   code_38960000:"Éthylotest antidémarrage",
   code_38970000:"Simulateur de recherche, d'essai et scientifique et technique",
   code_39000000:"Meubles (y compris les meubles de bureau), aménagements, appareils électroménagers (à l'exclusion de l'éclairage) et produits de nettoyage",
   code_39100000:"Mobilier",
   code_39110000:"Sièges, chaises et articles assimilés, et pièces connexes",
   code_39111000:"Sièges",
   code_39111100:"Sièges pivotants",
   code_39111200:"Sièges de théâtre",
   code_39111300:"Sièges éjectables",
   code_39112000:"Chaises",
   code_39112100:"Chaises de salle à manger",
   code_39113000:"Sièges et chaises divers",
   code_39113100:"Fauteuils",
   code_39113200:"Canapés",
   code_39113300:"Banquettes",
   code_39113400:"Chaises longues",
   code_39113500:"Tabourets",
   code_39113600:"Bancs",
   code_39113700:"Repose-pieds",
   code_39114000:"Éléments de sièges",
   code_39114100:"Rembourrage",
   code_39120000:"Tables, armoires, bureaux et bibliothèques",
   code_39121000:"Bureaux et tables",
   code_39121100:"Bureaux",
   code_39121200:"Tables",
   code_39122000:"Armoires et bibliothèques",
   code_39122100:"Armoires",
   code_39122200:"Bibliothèques",
   code_39130000:"Mobilier de bureau",
   code_39131000:"Rayonnages de bureau",
   code_39131100:"Rayonnages d'archives",
   code_39132000:"Systèmes de classement",
   code_39132100:"Armoires de classement",
   code_39132200:"Meubles fichiers",
   code_39132300:"Dossiers suspendus",
   code_39132400:"Systèmes de carrousels",
   code_39132500:"Chariots de bureau",
   code_39133000:"Présentoirs",
   code_39134000:"Mobilier informatique",
   code_39134100:"Tables pour ordinateurs",
   code_39135000:"Tables de tri",
   code_39135100:"Casiers de tri",
   code_39136000:"Porte-manteaux",
   code_39137000:"Adoucisseurs d'eau",
   code_39140000:"Mobilier de maison",
   code_39141000:"Mobilier et agencement de cuisine",
   code_39141100:"Étagères",
   code_39141200:"Plans de travail",
   code_39141300:"Placards",
   code_39141400:"Cuisines équipées",
   code_39141500:"Hottes d'aspiration",
   code_39142000:"Mobilier de jardin",
   code_39143000:"Mobilier de chambre à coucher, mobilier de salle à manger et mobilier de salle de séjour",
   code_39143100:"Mobilier de chambre à coucher",
   code_39143110:"Lits, literie et tissus d'ameublement spéciaux",
   code_39143111:"Sommiers",
   code_39143112:"Matelas",
   code_39143113:"Tissus spéciaux d'ameublement",
   code_39143114:"Couvertures chauffantes",
   code_39143115:"Alèses en caoutchouc",
   code_39143116:"Lits d'enfant",
   code_39143120:"Mobilier de chambre à coucher autre que lits et literie",
   code_39143121:"Armoires-penderies",
   code_39143122:"Commodes",
   code_39143123:"Tables de chevet",
   code_39143200:"Mobilier de salle à manger",
   code_39143210:"Tables de salle à manger",
   code_39143300:"Mobilier de séjour",
   code_39143310:"Tables basses",
   code_39144000:"Mobilier de salle de bains",
   code_39145000:"Accessoires pour caves à vin",
   code_39150000:"Mobilier et équipements divers",
   code_39151000:"Mobilier divers",
   code_39151100:"Râteliers",
   code_39151200:"Établis",
   code_39151300:"Mobilier modulaire",
   code_39152000:"Rayonnages mobiles",
   code_39153000:"Mobilier de salle de conférence",
   code_39153100:"Porte-livres",
   code_39154000:"Équipements d'exposition",
   code_39154100:"Stands d'exposition",
   code_39155000:"Mobilier de bibliothèque",
   code_39155100:"Équipements de bibliothèque",
   code_39156000:"Mobilier d'accueil et de réception",
   code_39157000:"Éléments de meubles",
   code_39160000:"Mobilier scolaire",
   code_39161000:"Mobilier pour jardin d'enfants",
   code_39162000:"Matériel d'enseignement",
   code_39162100:"Matériel pédagogique",
   code_39162110:"Fournitures scolaires",
   code_39162200:"Matériels et appareils de formation",
   code_39170000:"Mobilier de magasin",
   code_39171000:"Vitrines",
   code_39172000:"Comptoirs",
   code_39172100:"Comptoirs de distribution de repas",
   code_39173000:"Unités de stockage",
   code_39174000:"Enseignes de magasin",
   code_39180000:"Mobilier de laboratoire",
   code_39181000:"Paillasses de laboratoire",
   code_39190000:"Papier peint et autres revêtements",
   code_39191000:"Revêtements muraux à base de papier ou de carton",
   code_39191100:"Papier peint",
   code_39192000:"Revêtements muraux en textile",
   code_39193000:"Revêtements de sol à base de papier ou de carton",
   code_39200000:"Aménagements",
   code_39220000:"Équipement de cuisine, articles de maison, articles ménagers et fournitures de restauration",
   code_39221000:"Équipement de cuisine",
   code_39221100:"Ustensiles de cuisine",
   code_39221110:"Vaisselle",
   code_39221120:"Tasses et verres",
   code_39221121:"Tasses",
   code_39221122:"Petites tasses",
   code_39221123:"Verres à boire",
   code_39221130:"Récipients pour aliments",
   code_39221140:"Bidons à eau",
   code_39221150:"Récipients isothermes",
   code_39221160:"Plateaux",
   code_39221170:"Égouttoirs",
   code_39221180:"Ustensiles de cuisson",
   code_39221190:"Égouttoirs à vaisselle",
   code_39221200:"Vaisselle de table",
   code_39221210:"Assiettes",
   code_39221220:"Plats",
   code_39221230:"Soucoupes",
   code_39221240:"Bols",
   code_39221250:"Décanteuses",
   code_39221260:"Gamelles en fer blanc",
   code_39222000:"Fournitures pour restauration",
   code_39222100:"Fournitures jetables pour restauration",
   code_39222110:"Couteaux et assiettes jetables",
   code_39222120:"Tasses jetables",
   code_39222200:"Plateaux pour repas",
   code_39223000:"Cuillères, fourchettes",
   code_39223100:"Cuillères",
   code_39223200:"Fourchettes",
   code_39224000:"Balais et brosses et autres articles de divers types",
   code_39224100:"Balais",
   code_39224200:"Brosses",
   code_39224210:"Pinceaux de peintre en bâtiment",
   code_39224300:"Balais et brosses et autres produits de nettoyage ménager",
   code_39224310:"Brosses de toilette",
   code_39224320:"Éponges",
   code_39224330:"Seaux",
   code_39224340:"Poubelles",
   code_39224350:"Pelles à poussière",
   code_39225000:"Briquets, articles en matières inflammables, articles de pyrotechnie, allumettes et combustibles liquides ou gaz combustibles liquéfiés",
   code_39225100:"Briquets",
   code_39225200:"Alliages pyrophoriques",
   code_39225300:"Allumettes",
   code_39225400:"Gaz pour briquets",
   code_39225500:"Articles de pyrotechnie",
   code_39225600:"Bougies",
   code_39225700:"Bouteille, bocaux et flacons",
   code_39225710:"Bouteilles",
   code_39225720:"Bocaux",
   code_39225730:"Flacons",
   code_39226000:"Bonbonnes, casiers à bouteilles, fioles et bobines",
   code_39226100:"Casiers à bouteilles",
   code_39226200:"Bonbonnes et fioles",
   code_39226210:"Bonbonnes",
   code_39226220:"Fioles",
   code_39226300:"Bobines",
   code_39227000:"Aiguilles à coudre et à tricoter, et dés à coudre",
   code_39227100:"Aiguilles à coudre ou à tricoter",
   code_39227110:"Aiguilles à coudre",
   code_39227120:"Aiguilles à tricoter",
   code_39227200:"Dés à coudre",
   code_39230000:"Produit à usage spécial",
   code_39234000:"Caisses à compost",
   code_39235000:"Jetons",
   code_39236000:"Cabines de peinture",
   code_39237000:"Bornes de neige",
   code_39240000:"Coutellerie",
   code_39241000:"Couteaux et ciseaux",
   code_39241100:"Couteaux",
   code_39241110:"Couteaux de table",
   code_39241120:"Couteaux de cuisine",
   code_39241130:"Couteaux universels",
   code_39241200:"Ciseaux",
   code_39254000:"Horlogerie",
   code_39254100:"Horloges",
   code_39254110:"Réveils",
   code_39254120:"Horloges murales",
   code_39254130:"Verre à horloges",
   code_39260000:"Plateaux de réception et matériel de bureau",
   code_39261000:"Plateaux de réception",
   code_39263000:"Matériel de bureau",
   code_39263100:"Accessoires de bureau",
   code_39264000:"Accessoires pour classeurs ou fichiers",
   code_39265000:"Crochets et œillets",
   code_39270000:"Articles religieux",
   code_39290000:"Aménagements divers",
   code_39291000:"Fournitures pour blanchisserie",
   code_39292000:"Ardoises d'écolier et tableaux à écrire ou à dessiner ou instruments",
   code_39292100:"Tableaux noirs",
   code_39292110:"Éponges à effacer pour tableaux noirs",
   code_39292200:"Ardoises d'écolier",
   code_39292300:"Instruments de dessin",
   code_39292400:"Instruments d'écriture",
   code_39292500:"Règles",
   code_39293000:"Produits artificiels",
   code_39293100:"Fruits artificiels",
   code_39293200:"Fleurs artificielles",
   code_39293300:"Gazon artificiel",
   code_39293400:"Pelouse artificielle",
   code_39293500:"Bijouterie de fantaisie",
   code_39294000:"Appareils et équipement de démonstration",
   code_39294100:"Produits d'information et de promotion",
   code_39295000:"Parapluies et parasols, cannes et cannes-sièges",
   code_39295100:"Parasols",
   code_39295200:"Parapluies",
   code_39295300:"Cannes-sièges",
   code_39295400:"Cannes de marche",
   code_39295500:"Pièces, garnitures et accessoires de parapluies, parasols, cannes et articles similaires",
   code_39296000:"Articles funéraires",
   code_39296100:"Cercueils",
   code_39297000:"Fournitures de caserne",
   code_39298000:"Statuettes, ornements, cadres pour photographies ou images et miroirs",
   code_39298100:"Cadres pour photographies",
   code_39298200:"Cadres pour images",
   code_39298300:"Vases",
   code_39298400:"Statuettes",
   code_39298500:"Ornements",
   code_39298600:"Globes",
   code_39298700:"Trophées",
   code_39298800:"Aquariums",
   code_39298900:"Éléments décoratifs divers",
   code_39298910:"Arbre de Noël",
   code_39299000:"Verrerie",
   code_39299100:"Ampoules en verre",
   code_39299200:"Verre de sécurité",
   code_39299300:"Miroirs en verre",
   code_39300000:"Équipement divers",
   code_39310000:"Équipement de restauration",
   code_39311000:"Petit matériel de restauration",
   code_39312000:"Matériel pour la préparation des aliments",
   code_39312100:"Machines à couper la viande",
   code_39312200:"Matériel de cantine",
   code_39313000:"Équipement hôtelier",
   code_39314000:"Équipement de cuisine industrielle",
   code_39315000:"Équipement de restaurant",
   code_39330000:"Matériel de désinfection",
   code_39340000:"Équipement de réseau à gaz",
   code_39341000:"Équipement pour pression du gaz",
   code_39350000:"Équipement de réseau d'assainissement",
   code_39360000:"Matériel de scellement",
   code_39370000:"Installations de distribution d'eau",
   code_39500000:"Articles textiles",
   code_39510000:"Articles textiles ménagers",
   code_39511000:"Couvertures et plaids",
   code_39511100:"Couvertures",
   code_39511200:"Plaids",
   code_39512000:"Linge de lit",
   code_39512100:"Draps",
   code_39512200:"Housses de couettes",
   code_39512300:"Protège-matelas",
   code_39512400:"Édredons",
   code_39512500:"Taies d'oreillers",
   code_39512600:"Taies de traversins",
   code_39513000:"Linge de table",
   code_39513100:"Nappes",
   code_39513200:"Serviettes de table",
   code_39514000:"Linge de toilette et de cuisine",
   code_39514100:"Serviettes de toilette",
   code_39514200:"Torchons",
   code_39514300:"Essuie-mains en rouleau",
   code_39514400:"Distributeurs automatiques de serviettes",
   code_39514500:"Gants de toilette",
   code_39515000:"Rideaux, tentures, lambrequins et stores en textile",
   code_39515100:"Rideaux",
   code_39515110:"Pare-fumée",
   code_39515200:"Tentures",
   code_39515300:"Lambrequins",
   code_39515400:"Stores",
   code_39515410:"Stores d'intérieur",
   code_39515420:"Stores en textile",
   code_39515430:"Stores vénitiens",
   code_39515440:"Jalousies",
   code_39516000:"Articles d'ameublement",
   code_39516100:"Articles textiles d'ameublement",
   code_39516110:"Coussins",
   code_39516120:"Oreillers",
   code_39518000:"Linge d'hôpital",
   code_39518100:"Champs opératoires",
   code_39518200:"Draps pour salle d'opération",
   code_39520000:"Articles textiles confectionnés",
   code_39522000:"Bâches imperméables, voiles pour bateaux, planches à voile ou chars à voile, auvents, stores, tentes et articles de camping",
   code_39522100:"Bâches imperméables, auvents et stores",
   code_39522110:"Bâches imperméables",
   code_39522120:"Marquises",
   code_39522130:"Rideaux pare-soleil",
   code_39522200:"Toiles de camouflage",
   code_39522400:"Voiles",
   code_39522500:"Articles de camping en textile",
   code_39522510:"Matelas pneumatiques",
   code_39522520:"Lits de camp",
   code_39522530:"Tentes",
   code_39522540:"Sacs de couchage",
   code_39522541:"Sacs de couchage en plume ou en duvet",
   code_39523000:"Parachutes",
   code_39523100:"Parachutes dirigeables",
   code_39523200:"Rotochutes",
   code_39525000:"Articles textiles manufacturés divers",
   code_39525100:"Chiffons à poussière",
   code_39525200:"Éléments filtrants en tissu",
   code_39525300:"Gilets de sauvetage",
   code_39525400:"Couvertures ignifuges",
   code_39525500:"Moustiquaires",
   code_39525600:"Torchons à vaisselle",
   code_39525700:"Ceintures de sauvetage",
   code_39525800:"Torchons de nettoyage",
   code_39525810:"Torchons à polir",
   code_39530000:"Tapis, paillassons et carpettes",
   code_39531000:"Tapis",
   code_39531100:"Revêtement de sol à points noués",
   code_39531200:"Revêtement de sol tissé",
   code_39531300:"Revêtement de sol touffeté",
   code_39531310:"Dalles de moquette",
   code_39531400:"Revêtement de sol en textile",
   code_39532000:"Paillassons",
   code_39533000:"Carpettes",
   code_39534000:"Revêtement de sol industriel en textile",
   code_39540000:"Cordages, cordes, ficelles et filets divers",
   code_39541000:"Cordage, corde, ficelle et filets",
   code_39541100:"Ficelles, cordages et câbles",
   code_39541110:"Corde",
   code_39541120:"Cordage",
   code_39541130:"Cordon",
   code_39541140:"Ficelle",
   code_39541200:"Filets en textile",
   code_39541210:"Filets à mailles nouées",
   code_39541220:"Élingues",
   code_39542000:"Chiffons",
   code_39550000:"Articles en non-tissé",
   code_39560000:"Articles textiles divers",
   code_39561000:"Tulle, dentelle, rubanerie, passementerie et broderie",
   code_39561100:"Rubanerie, passementerie",
   code_39561110:"Ruban",
   code_39561120:"Bande textile",
   code_39561130:"Étiquettes et badges en textile",
   code_39561131:"Étiquettes en textile",
   code_39561132:"Badges en textile",
   code_39561133:"Insignes",
   code_39561140:"Passementerie d'ornement",
   code_39561141:"Galons",
   code_39561142:"Épaulettes",
   code_39561200:"Tissu à mailles",
   code_39562000:"Feutre",
   code_39563000:"Textile de rembourrage, fils, tissus et articles textiles utilisés à des fins techniques",
   code_39563100:"Rembourrage textile",
   code_39563200:"Fil métallisé",
   code_39563300:"Tissu de fils métalliques",
   code_39563400:"Tissu imprégné, enduit ou recouvert",
   code_39563500:"Articles textiles à usage technique",
   code_39563510:"Tuyaux en textile",
   code_39563520:"Bandes convoyeuses en textile",
   code_39563530:"Conduites d'aération",
   code_39563600:"Produits textiles matelassés",
   code_39700000:"Appareils ménagers",
   code_39710000:"Appareils ménagers électriques",
   code_39711000:"Appareils électrodomestiques pour la cuisine",
   code_39711100:"Réfrigérateurs et congélateurs",
   code_39711110:"Réfrigérateurs-congélateurs",
   code_39711120:"Congélateurs",
   code_39711121:"Congélateurs-bahuts",
   code_39711122:"Congélateurs ménagers",
   code_39711123:"Congélateurs-armoires",
   code_39711124:"Congélateurs industriels",
   code_39711130:"Réfrigérateurs",
   code_39711200:"Machines pour la préparation des aliments",
   code_39711210:"Robots ménagers",
   code_39711211:"Mixeurs pour aliments",
   code_39711300:"Appareils électrothermiques",
   code_39711310:"Cafetières électriques",
   code_39711320:"Théières électriques",
   code_39711330:"Grille-pain électriques",
   code_39711340:"Chauffe-plats",
   code_39711350:"Gaufriers",
   code_39711360:"Fours",
   code_39711361:"Fours électriques",
   code_39711362:"Fours à micro-ondes",
   code_39711400:"Rôtissoires, plaques de cuisson, plaques chauffantes et réchauds",
   code_39711410:"Rôtissoires",
   code_39711420:"Foyers de cuisson",
   code_39711430:"Plaques de cuisson",
   code_39711440:"Réchauds",
   code_39711500:"Ouvre-boîtes",
   code_39712000:"Appareils électrodomestiques pour soins corporels",
   code_39712100:"Tondeuses à cheveux",
   code_39712200:"Appareils de coiffure",
   code_39712210:"Sèche-cheveux",
   code_39712300:"Sèche-mains",
   code_39713000:"Appareils électrodomestiques de nettoyage; fers à repasser",
   code_39713100:"Lave-vaisselle",
   code_39713200:"Lave-linge et sèche-linge",
   code_39713210:"Lave-linge et sèche-linge combinés",
   code_39713211:"Unité de séchage et de pressage",
   code_39713300:"Compacteurs d'ordures ménagères",
   code_39713400:"Machines d'entretien des sols",
   code_39713410:"Machines de nettoyage des sols",
   code_39713420:"Cireuses",
   code_39713430:"Aspirateurs",
   code_39713431:"Accessoires pour aspirateurs",
   code_39713500:"Fers à repasser électriques",
   code_39713510:"Fers à vapeur",
   code_39714000:"Hottes de ventilation ou de recyclage",
   code_39714100:"Aérateurs",
   code_39714110:"Ventilateurs d'aspiration",
   code_39715000:"Chauffe-eau et chauffage de bâtiment; équipement de plomberie",
   code_39715100:"Distributeurs électriques d'eau chaude ou chauffe-eau à accumulation et thermoplongeurs",
   code_39715200:"Équipement de chauffage",
   code_39715210:"Matériel de chauffage central",
   code_39715220:"Résistances de chauffage électrique",
   code_39715230:"Appareils électriques de chauffage au sol",
   code_39715240:"Appareils électriques de chauffage des locaux",
   code_39715300:"Équipement de plomberie",
   code_39716000:"Pièces pour appareils électroménagers",
   code_39717000:"Ventilateurs et appareils de conditionnement d'air",
   code_39717100:"Ventilateurs",
   code_39717200:"Appareils de conditionnement d'air",
   code_39720000:"Appareils ménagers non électriques",
   code_39721000:"Appareils ménagers de cuisson ou de chauffage",
   code_39721100:"Appareils ménagers de cuisson",
   code_39721200:"Réfrigérateurs à gaz",
   code_39721300:"Réchauffeurs d'air ou distributeurs d'air chaud non électriques",
   code_39721310:"Réchauffeurs d'air",
   code_39721320:"Déshydrateurs d'air",
   code_39721321:"Séchoirs à air comprimé",
   code_39721400:"Chauffe-eau instantanés ou à accumulation non électriques",
   code_39721410:"Appareils à gaz",
   code_39721411:"Radiateurs à gaz",
   code_39722000:"Pièces pour poêles, cuisinières, chauffe-plats et appareils ménagers",
   code_39722100:"Pièces pour poêles",
   code_39722200:"Pièces pour cuisinières",
   code_39722300:"Pièces pour chauffe-plats",
   code_39800000:"Produits de nettoyage et produits à polir",
   code_39810000:"Préparations odoriférantes et cires",
   code_39811000:"Parfums et désodorisants d'intérieur",
   code_39811100:"Désodorisants d'intérieur",
   code_39811110:"Appareils distributeurs de désodorisants d'intérieur",
   code_39811200:"Produits d'assainissement de l'air",
   code_39811300:"Désodorisants",
   code_39812000:"Cirages et crèmes",
   code_39812100:"Produits d'entretien des sols",
   code_39812200:"Produits d'entretien des chaussures",
   code_39812300:"Cires à encaustiquer",
   code_39812400:"Sciures de balayage",
   code_39812500:"Agents d'étanchéité",
   code_39813000:"Pâtes et poudres à récurer",
   code_39820000:"Agents organiques tensioactifs",
   code_39821000:"Produits nettoyants à l'ammoniac",
   code_39822000:"Produits nettoyants à la soude caustique",
   code_39830000:"Produits de nettoyage",
   code_39831000:"Préparations nettoyantes",
   code_39831100:"Dispersants",
   code_39831200:"Détergents",
   code_39831210:"Détergents pour lave-vaisselle",
   code_39831220:"Agents dégraissants",
   code_39831230:"Digesteurs de graisses",
   code_39831240:"Agents nettoyants",
   code_39831250:"Solutions de rinçage",
   code_39831300:"Produits nettoyants pour sols",
   code_39831400:"Produits nettoyants pour écrans",
   code_39831500:"Produits nettoyants pour automobiles",
   code_39831600:"Produits nettoyants pour les toilettes",
   code_39831700:"Distributeur de savon automatique",
   code_39832000:"Produits pour lave-vaisselle",
   code_39832100:"Poudre pour lave-vaisselle",
   code_39833000:"Produits antipoussière",
   code_39834000:"Solutions de nettoyage pour bijoux",
   code_41000000:"Eau collectée et purifiée",
   code_41100000:"Eau brute",
   code_41110000:"Eau potable",
   code_41120000:"Eau non potable",
   code_42000000:"Machines industrielles",
   code_42100000:"Machines de production et d'utilisation de la puissance mécanique",
   code_42110000:"Turbines et moteurs",
   code_42111000:"Moteurs (puissance motrice)",
   code_42111100:"Moteurs hors-bord pour propulsion navale",
   code_42112000:"Installations de turbines",
   code_42112100:"Turbines à vapeur d'eau",
   code_42112200:"Turbines hydrauliques",
   code_42112210:"Roues hydrauliques",
   code_42112300:"Turbines à gaz",
   code_42112400:"Équipements de turbines",
   code_42112410:"Instruments pour turbines",
   code_42113000:"Pièces pour turbines",
   code_42113100:"Pièces pour turbines à vapeur d'eau",
   code_42113110:"Plaques d'assise",
   code_42113120:"Caissons",
   code_42113130:"Systèmes de refroidissement à air avec condensateurs",
   code_42113150:"Systèmes à huile lubrifiante",
   code_42113160:"Séparateurs d'humidité",
   code_42113161:"Déshumidificateurs",
   code_42113170:"Dispositifs rotatifs",
   code_42113171:"Rotors",
   code_42113172:"Lames",
   code_42113190:"Vireurs",
   code_42113200:"Pièces pour turbines hydrauliques",
   code_42113300:"Pièces pour turbines à gaz",
   code_42113310:"Systèmes d'admission d'air",
   code_42113320:"Module d'injection à gaz",
   code_42113390:"Systèmes à gaz combustibles",
   code_42113400:"Pièces pour roues hydrauliques",
   code_42120000:"Pompes et compresseurs",
   code_42121000:"Machines et moteurs hydrauliques ou pneumatiques",
   code_42121100:"Cylindres hydrauliques ou pneumatiques",
   code_42121200:"Moteurs hydrauliques (véhicules)",
   code_42121300:"Moteurs pneumatiques (véhicules)",
   code_42121400:"Moteurs hydrauliques (puissance motrice)",
   code_42121500:"Moteurs pneumatiques (puissance motrice)",
   code_42122000:"Pompes",
   code_42122100:"Pompes à liquides",
   code_42122110:"Pompes pour la lutte contre l'incendie",
   code_42122120:"Dispositifs de ravitaillement d'hélicoptères",
   code_42122130:"Pompes à eau",
   code_42122160:"Pompes de refroidissement",
   code_42122161:"Pompes à eau de refroidissement",
   code_42122170:"Pompes à huile",
   code_42122180:"Pompes à essence",
   code_42122190:"Pompes à béton",
   code_42122200:"Pompes à mouvement rectiligne alternatif pour liquides",
   code_42122210:"Groupes hydrauliques",
   code_42122220:"Pompes à eaux d'égouts",
   code_42122230:"Pompes de dosage",
   code_42122300:"Surpresseurs à liquides",
   code_42122400:"Pompes centrifuges et élévateurs à liquides",
   code_42122410:"Pompes à usage médical",
   code_42122411:"Pompes à usage nutritionnel",
   code_42122419:"Pompes pour perfusion",
   code_42122420:"Élévateurs à liquides",
   code_42122430:"Pompes centrifuges",
   code_42122440:"Pompes rotatives",
   code_42122450:"Pompes à vide",
   code_42122460:"Pompes à air",
   code_42122480:"Pompes à roue",
   code_42122500:"Pompes de laboratoire et accessoires",
   code_42122510:"Pompes péristaltiques",
   code_42123000:"Compresseurs",
   code_42123100:"Compresseurs à gaz",
   code_42123200:"Compresseurs rotatifs",
   code_42123300:"Compresseurs pour équipements frigorifiques",
   code_42123400:"Compresseurs d'air",
   code_42123410:"Compresseurs d'air portés",
   code_42123500:"Turbocompresseurs",
   code_42123600:"Compresseurs à mouvement rectiligne alternatif",
   code_42123610:"Dispositif à air comprimé",
   code_42123700:"Compresseurs centrifuges",
   code_42123800:"Compresseurs pour aviation civile",
   code_42124000:"Pièces pour pompes, compresseurs, machines ou moteurs",
   code_42124100:"Pièces pour machines ou moteurs",
   code_42124130:"Pièces pour machines pneumatiques",
   code_42124150:"Pièces pour machines ou moteurs hydrauliques",
   code_42124170:"Pièces pour moteurs à réaction",
   code_42124200:"Pièces pour pompes ou élévateurs à liquides",
   code_42124210:"Pièces pour pompes à essence, pompes à main et pompes à béton",
   code_42124211:"Pièces pour pompes à essence",
   code_42124212:"Pièces pour pompes à main",
   code_42124213:"Pièces pour pompes à béton",
   code_42124220:"Pièces pour pompes à mouvement rectiligne alternatif",
   code_42124221:"Pièces pour groupes hydrauliques",
   code_42124222:"Pièces pour pompes de dosage",
   code_42124230:"Pièces pour pompes à mouvement rectiligne rotatif",
   code_42124290:"Pièces pour pompes centrifuges",
   code_42124300:"Pièces pour pompes à air ou à vide, compresseurs d'air ou à gaz",
   code_42124310:"Pièces pour pompes à air",
   code_42124320:"Pièces pour pompes à vide",
   code_42124330:"Pièces pour compresseurs d'air",
   code_42124340:"Pièces pour compresseurs à gaz",
   code_42130000:"Robinets, vannes et dispositifs similaires",
   code_42131000:"Robinets et vannes",
   code_42131100:"Vannes définies selon leur fonction",
   code_42131110:"Vannes pour radiateurs de chauffage central",
   code_42131120:"Robinets-vannes",
   code_42131130:"Régulateurs de température",
   code_42131140:"Soupapes de détente, de régulation, de retenue ou de sûreté",
   code_42131141:"Soupapes de détente",
   code_42131142:"Soupapes de régulation",
   code_42131143:"Vannes de maîtrise des crues",
   code_42131144:"Vannes de commande de processus",
   code_42131145:"Soupapes de retenue",
   code_42131146:"Clapets de non-retour",
   code_42131147:"Soupapes de sécurité",
   code_42131148:"Soupapes d'arrêt",
   code_42131150:"Vannes de colonnes montantes",
   code_42131160:"Hydrants",
   code_42131170:"Robinets de bouteilles de gaz",
   code_42131200:"Vannes définies selon leur fabrication",
   code_42131210:"Vannes à pâte",
   code_42131220:"Vannes de conduites forcées",
   code_42131230:"Robinets à vanne",
   code_42131240:"Robinets à soupape",
   code_42131250:"Vannes à pointeau",
   code_42131260:"Robinets à tournant sphérique",
   code_42131270:"Robinets à boisseau",
   code_42131280:"Robinets à papillon",
   code_42131290:"Robinets à membrane",
   code_42131291:"Tiroirs de distribution",
   code_42131292:"Soupapes à clapet",
   code_42131300:"Arbres de Noël et autres assemblages de vannes",
   code_42131310:"Arbres de Noël de forage",
   code_42131320:"Manifolds de duses",
   code_42131390:"Assemblages de vannes",
   code_42131400:"Robinets ou vannes pour installations sanitaires",
   code_42132000:"Pièces pour robinets et vannes",
   code_42132100:"Commandes de soupapes",
   code_42132110:"Commandes de soupapes électriques",
   code_42132120:"Commandes de soupapes hydrauliques",
   code_42132130:"Commandes de soupapes pneumatiques",
   code_42132200:"Pièces pour robinets",
   code_42132300:"Pièces pour vannes",
   code_42140000:"Engrenages, éléments d'engrenage et d'entraînement",
   code_42141000:"Engrenages, éléments d'engrenage et d'entraînement cylindriques",
   code_42141100:"Arbres de transmission, arbres à cames et vilebrequins",
   code_42141110:"Arbres de transmission",
   code_42141120:"Arbres à cames",
   code_42141130:"Vilebrequins",
   code_42141200:"Boîtes de roulements",
   code_42141300:"Engrenages",
   code_42141400:"Volants et poulies",
   code_42141410:"Treuils",
   code_42141500:"Embrayages",
   code_42141600:"Palans",
   code_42141700:"Accouplements d'arbres",
   code_42141800:"Joints cardan",
   code_42142000:"Pièces pour éléments d'engrenage et d'entraînement",
   code_42142100:"Pièces pour éléments d'engrenage",
   code_42142200:"Pièces pour éléments d'entraînement",
   code_42150000:"Réacteurs nucléaires et pièces détachées",
   code_42151000:"Réacteurs nucléaires",
   code_42152000:"Pièces détachées de réacteurs nucléaires",
   code_42152100:"Systèmes de refroidissement du réacteur",
   code_42152200:"Pièces détachées de cuves de réacteurs nucléaires",
   code_42160000:"Installations de chaudières",
   code_42161000:"Appareils de production d'eau chaude",
   code_42162000:"Chaudières génératrices de vapeur",
   code_42163000:"Générateurs de vapeur",
   code_42164000:"Installations auxiliaires pour chaudières",
   code_42165000:"Condenseurs de vapeur",
   code_42200000:"Machines utilisées pour le traitement des aliments, des boissons et du tabac et pièces accessoires",
   code_42210000:"Machines de traitement des aliments, des boissons et du tabac",
   code_42211000:"Machines de laiterie",
   code_42211100:"Écrémeuses centrifuges",
   code_42212000:"Machines de traitement des céréales ou des légumes secs",
   code_42213000:"Machines utilisées dans la fabrication de boissons alcoolisées ou à base de fruits",
   code_42214000:"Fours de cuisson, séchoirs pour produits agricoles et matériel de cuisson ou de chauffage",
   code_42214100:"Fours de cuisson",
   code_42214110:"Grils",
   code_42214200:"Séchoirs pour produits agricoles",
   code_42215000:"Machines pour la préparation ou la fabrication industrielle d'aliments ou de boissons",
   code_42215100:"Machines à couper les aliments",
   code_42215110:"Machines à trancher le pain",
   code_42215120:"Machines à trancher le lard",
   code_42215200:"Machines de traitement des aliments",
   code_42215300:"Machines pour la fabrication des pâtes alimentaires",
   code_42216000:"Machines de traitement du tabac",
   code_42220000:"Pièces pour machines de traitement des aliments, des boissons et du tabac",
   code_42221000:"Pièces pour machines de traitement des aliments",
   code_42221100:"Pièces pour machines de laiterie",
   code_42221110:"Pièces pour trayeuses",
   code_42222000:"Pièces pour machines de traitement des boissons",
   code_42223000:"Pièces pour machines de traitement du tabac",
   code_42300000:"Fourneaux, incinérateurs et fours industriels ou de laboratoire",
   code_42310000:"Brûleurs pour l'alimentation des foyers",
   code_42320000:"Incinérateurs de déchets",
   code_42330000:"Fours de fusion",
   code_42340000:"Fours à usages non domestiques",
   code_42341000:"Fours industriels",
   code_42350000:"Fours crématoires",
   code_42390000:"Pièces pour brûleurs, fourneaux ou fours",
   code_42400000:"Matériel de levage et de manutention et pièces détachées",
   code_42410000:"Matériel de levage et de manutention",
   code_42411000:"Palans et treuils",
   code_42412000:"Machines d'extraction, treuils à usage souterrain et cabestans",
   code_42412100:"Machines d'extraction et treuils à usage souterrain",
   code_42412110:"Machines d'extraction",
   code_42412120:"Treuils à usage souterrain",
   code_42412200:"Cabestans",
   code_42413000:"Crics et appareils de levage pour véhicules",
   code_42413100:"Systèmes de levage intégrés",
   code_42413200:"Vérins hydrauliques",
   code_42413300:"Vérins pneumatiques",
   code_42413400:"Vérins mécaniques",
   code_42413500:"Appareils de levage pour véhicules",
   code_42414000:"Grues, portiques mobiles et chariots équipés d'une grue",
   code_42414100:"Grues",
   code_42414110:"Grues portuaires",
   code_42414120:"Grues de quai",
   code_42414130:"Grues empileuses",
   code_42414140:"Grues à conteneurs",
   code_42414150:"Grues à tour",
   code_42414200:"Ponts roulants",
   code_42414210:"Grue roulante",
   code_42414220:"Portique roulant",
   code_42414300:"Portiques de levage",
   code_42414310:"Portiques mobiles",
   code_42414320:"Grues sur portique",
   code_42414400:"Grues montées sur véhicule",
   code_42414410:"Grues pour camions",
   code_42414500:"Ponts-grues",
   code_42415000:"Chariots élévateurs, chariots de manutention, tracteurs à plate-forme utilisés dans les gares",
   code_42415100:"Chariots élévateurs",
   code_42415110:"Chariot élévateurs",
   code_42415200:"Chariots de manutention",
   code_42415210:"Chariots équipés de matériel de manutention",
   code_42415300:"Tracteurs à plate-forme utilisés dans les gares",
   code_42415310:"Engins de transport indépendants",
   code_42415320:"Équipement pour véhicules de secours",
   code_42416000:"Ascenseurs, skips, monte-charges, escaliers mécaniques et trottoirs roulants",
   code_42416100:"Ascenseurs",
   code_42416110:"Dispositifs de levage pour baignoire",
   code_42416120:"Monte-charges",
   code_42416130:"Dispositifs mécaniques de levage",
   code_42416200:"Skips",
   code_42416210:"Dispositifs de levage de bennes",
   code_42416300:"Dispositifs de levage",
   code_42416400:"Escaliers mécaniques",
   code_42416500:"Trottoirs roulants",
   code_42417000:"Élévateurs et convoyeurs",
   code_42417100:"Élévateurs ou convoyeurs pneumatiques",
   code_42417200:"Convoyeurs",
   code_42417210:"Élévateurs ou transporteurs à godets à action continue",
   code_42417220:"Élévateurs ou transporteurs à courroie à action continue",
   code_42417230:"Convoyeurs blindés de taille pour mines",
   code_42417300:"Équipement pour transporteur",
   code_42417310:"Bandes convoyeuses",
   code_42418000:"Machines de levage, manutention, chargement ou déchargement",
   code_42418100:"Pousse-wagons et chariots-transbordeurs de mine",
   code_42418200:"Monorails ou remonte-pentes",
   code_42418210:"Monorail suspendu",
   code_42418220:"Télésièges",
   code_42418290:"Remonte-pentes",
   code_42418300:"Matériel d'enlèvement de torchères",
   code_42418400:"Carrousels de stockage et de recherche",
   code_42418500:"Équipement mécanique de manutention",
   code_42418900:"Machines de chargement ou de manutention",
   code_42418910:"Matériel de chargement",
   code_42418920:"Matériel de déchargement",
   code_42418930:"Chariots à prise latérale",
   code_42418940:"Matériel de manutention de conteneurs",
   code_42419000:"Pièces pour matériel de levage et de manutention",
   code_42419100:"Pièces pour grues",
   code_42419200:"Pièces pour chariots de manutention",
   code_42419500:"Pièces pour ascenseurs, skips ou escaliers mécaniques",
   code_42419510:"Pièces pour ascenseurs",
   code_42419520:"Pièces pour skips",
   code_42419530:"Pièces pour escaliers mécaniques",
   code_42419540:"Pièces pour trottoirs roulants",
   code_42419800:"Pièces pour transporteurs",
   code_42419810:"Pièces pour convoyeurs à bande",
   code_42419890:"Pièces pour convoyeurs à godets",
   code_42419900:"Pièces pour machines d'extraction et autre matériel de levage ou de manutention",
   code_42420000:"Godets, pelles, grappins et grips pour grues ou excavateurs",
   code_42500000:"Matériel de réfrigération et de ventilation",
   code_42510000:"Échangeurs de chaleur, matériel de climatisation et de réfrigération et matériel de filtration",
   code_42511000:"Échangeurs de chaleur et appareils de liquéfaction de l'air ou d'autres gaz",
   code_42511100:"Échangeurs de chaleur",
   code_42511110:"Pompes à chaleur",
   code_42511200:"Appareils de liquéfaction de l'air ou d'autres gaz",
   code_42512000:"Installations de climatisation",
   code_42512100:"Machines de climatisation de type fenêtre",
   code_42512200:"Machines de climatisation de type mural",
   code_42512300:"Unités de climatisation",
   code_42512400:"Appareils de climatisation pour véhicules",
   code_42512500:"Pièces pour machines de climatisation",
   code_42512510:"Registres",
   code_42512520:"Volets d'aération",
   code_42513000:"Matériel de réfrigération et de congélation",
   code_42513100:"Matériel de congélation",
   code_42513200:"Matériel de réfrigération",
   code_42513210:"Présentoirs frigorifiques",
   code_42513220:"Comptoirs frigorifiques",
   code_42513290:"Matériel industriel de réfrigération",
   code_42514000:"Machines et appareils de filtration ou de purification des gaz",
   code_42514200:"Épurateurs électrostatiques d'air et de gaz",
   code_42514300:"Appareils de filtration",
   code_42514310:"Filtres à air",
   code_42514320:"Filtres à gaz",
   code_42515000:"Chaudière de chauffage urbain",
   code_42520000:"Matériel de ventilation",
   code_42521000:"Matériel de désenfumage",
   code_42522000:"Ventilateurs à usages non domestiques",
   code_42522100:"Pièces pour ventilateurs",
   code_42530000:"Pièces pour matériel de réfrigération et de congélation et pompes à chaleur",
   code_42531000:"Pièces pour matériel de réfrigération",
   code_42532000:"Pièces pour matériel de congélation",
   code_42533000:"Pièces pour pompes à chaleur",
   code_42600000:"Machines-outils",
   code_42610000:"Machines-outils à laser et centres d'usinage",
   code_42611000:"Machines-outils à usage spécifique",
   code_42612000:"Centre d'usinage",
   code_42612100:"Centre d'usinage à broche horizontale",
   code_42612200:"Centre d'usinage à broche verticale",
   code_42620000:"Tours, machines à aléser et à fraiser",
   code_42621000:"Tours",
   code_42621100:"Tour CNC",
   code_42622000:"Machines à fileter ou à tarauder",
   code_42623000:"Fraiseuses",
   code_42630000:"Machines-outils pour le travail des métaux",
   code_42631000:"Machines-outils de finissage des métaux",
   code_42632000:"Machines à commande numérique pour le travail des métaux",
   code_42633000:"Machines à cintrer, à plier, à dresser ou à aplanir",
   code_42634000:"Machines à forger",
   code_42635000:"Machines à estamper",
   code_42636000:"Presses",
   code_42636100:"Presses hydrauliques",
   code_42637000:"Machines-outils à percer, à aléser ou à fraiser les métaux",
   code_42637100:"Machines-outils à percer les métaux",
   code_42637200:"Machines-outils à aléser les métaux",
   code_42637300:"Machines-outils à fraiser les métaux",
   code_42638000:"Centre d'usinage pour le travail des métaux",
   code_42640000:"Machines-outils à travailler les matériaux durs, excepté les métaux",
   code_42641000:"Machines-outils pour le travail de la pierre, de la céramique, du béton ou du verre",
   code_42641100:"Machines-outils pour le travail de la pierre",
   code_42641200:"Machines-outils pour le travail de la céramique",
   code_42641300:"Machines-outils pour le travail du béton",
   code_42641400:"Machines-outils pour le travail du verre",
   code_42642000:"Machines-outils pour le travail du bois, de l'os, du liège, du caoutchouc dur ou du plastique dur",
   code_42642100:"Machines-outils pour le travail du bois",
   code_42642200:"Machines-outils pour le travail de l'os",
   code_42642300:"Machines-outils pour le travail du liège",
   code_42642400:"Machines-outils pour le travail du caoutchouc dur",
   code_42642500:"Machines-outils pour le travail du plastique dur",
   code_42650000:"Outils à main pneumatiques ou à moteur",
   code_42651000:"Outils à main pneumatiques",
   code_42652000:"Outils à main électromécaniques",
   code_42660000:"Outils pour le brasage tendre, le brasage fort et le soudage, machines et matériel pour la trempe superficielle et la projection à chaud",
   code_42661000:"Matériel de brasage tendre et de brasage fort",
   code_42661100:"Matériel de brasage tendre",
   code_42661200:"Matériel de brasage fort",
   code_42662000:"Matériel de soudage",
   code_42662100:"Matériel de soudage électrique",
   code_42662200:"Matériel de soudage non électrique",
   code_42663000:"Machines pour la trempe superficielle",
   code_42664000:"Matériel de fusion",
   code_42664100:"Matériel de fusion pour plastique",
   code_42665000:"Machines de projection métallique",
   code_42670000:"Pièces et accessoires de machines-outils",
   code_42671000:"Supports pour outils",
   code_42671100:"Porte-outils de laboratoire",
   code_42671110:"Porte-éprouvettes pour bains",
   code_42672000:"Porte-pièces pour machines-outils",
   code_42673000:"Appareils diviseurs spéciaux pour machines-outils",
   code_42674000:"Pièces et accessoires de machines-outils pour le travail des métaux",
   code_42675000:"Pièces et accessoires de machines-outils pour le travail des matériaux durs",
   code_42675100:"Pièces pour scies articulées",
   code_42676000:"Pièces pour outils à main",
   code_42677000:"Pièces pour outils pneumatiques",
   code_42700000:"Machines pour l'industrie du textile, de l'habillement et du cuir",
   code_42710000:"Machines pour l'industrie textile",
   code_42711000:"Machines de traitement de matières textiles synthétiques",
   code_42712000:"Métiers à filer le textile",
   code_42713000:"Métiers à tisser",
   code_42714000:"Machines à tricoter",
   code_42715000:"Machines à coudre",
   code_42716000:"Machines à laver, machines de nettoyage à sec et sèche-linge de blanchisserie",
   code_42716100:"Installation de lavage",
   code_42716110:"Matériel de lavage",
   code_42716120:"Machines à laver",
   code_42716130:"Machines de nettoyage à sec",
   code_42716200:"Sèche-linge",
   code_42717000:"Matériel de repassage et de pliage du linge",
   code_42717100:"Matériel de pliage du linge",
   code_42718000:"Machines de finissage textile",
   code_42718100:"Machines à repasser",
   code_42718200:"Presses à repasser",
   code_42720000:"Pièces pour machines de l'industrie du textile et de l'habillement",
   code_42800000:"Machines de fabrication du papier ou du carton",
   code_42810000:"Pièces pour machines de fabrication du papier ou du carton",
   code_42900000:"Machines diverses à usage général et à usage spécial",
   code_42910000:"Appareils de distillation, de filtration ou de rectification",
   code_42912000:"Machines et appareils de filtration ou de purification de liquides",
   code_42912100:"Machines et appareils de filtration de liquides",
   code_42912110:"Appareils de filtration des boues de forage",
   code_42912120:"Hydrocyclones",
   code_42912130:"Appareils de filtration ou de purification de boissons",
   code_42912300:"Machines et appareils de filtration ou de purification de l'eau",
   code_42912310:"Appareils de filtration de l'eau",
   code_42912320:"Appareils de désaération",
   code_42912330:"Appareils de purification de l'eau",
   code_42912340:"Appareils de dessalement",
   code_42912350:"Matériel pour installation de filtration",
   code_42913000:"Filtres à huile, filtres à essence et filtres à air",
   code_42913300:"Filtres à huile",
   code_42913400:"Filtres à essence",
   code_42913500:"Filtres à aspiration d'air",
   code_42914000:"Matériel de recyclage",
   code_42920000:"Machines de nettoyage de bouteilles, machines d'emballage, de pesage et de pulvérisation",
   code_42921000:"Machines de nettoyage, de remplissage, d'emballage ou de conditionnement de bouteilles ou autres récipients",
   code_42921100:"Machines de nettoyage ou de séchage de bouteilles ou autres récipients",
   code_42921200:"Machines de remplissage ou de fermeture de bouteilles, de boîtes ou autres récipients",
   code_42921300:"Machines d'emballage ou de conditionnement",
   code_42921310:"Machines de cerclage",
   code_42921320:"Machines d'emballage",
   code_42921330:"Machines de conditionnement",
   code_42923000:"Machines de pesage et balances",
   code_42923100:"Machines de pesage",
   code_42923110:"Balances",
   code_42923200:"Bascules",
   code_42923210:"Balances de magasin",
   code_42923220:"Bascules continues",
   code_42923230:"Balances de vérification",
   code_42924200:"Machines à jet de vapeur ou de sable",
   code_42924300:"Matériel de pulvérisation",
   code_42924310:"Pistolets marqueurs",
   code_42924700:"Appareils mécaniques de projection, de dispersion ou de pulvérisation",
   code_42924710:"Appareils de dispersion de gaz",
   code_42924720:"Matériel de décontamination",
   code_42924730:"Appareils de nettoyage à eau sous pression",
   code_42924740:"Appareils de nettoyage à haute pression",
   code_42924790:"Appareils de désodorisation",
   code_42930000:"Centrifugeuses, machines de calandrage ou distributeurs automatiques",
   code_42931000:"Centrifugeuses",
   code_42931100:"Centrifugeuse de laboratoire et accessoires",
   code_42931110:"Centrifugeuses fixées au plancher",
   code_42931120:"Centrifugeuses de table",
   code_42931130:"Réducteurs pour centrifugeuses",
   code_42931140:"Équipement rotatif pour centrifugeuses",
   code_42932000:"Machines de calandrage",
   code_42932100:"Machines à laminer",
   code_42933000:"Distributeurs automatiques",
   code_42933100:"Distributeurs automatiques d'articles d'hygiène",
   code_42933200:"Distributeurs automatiques de timbres",
   code_42933300:"Distributeurs automatiques de produits",
   code_42940000:"Machines pour le traitement thermique de matériaux",
   code_42941000:"Machines pour le traitement thermique de gaz",
   code_42942000:"Fours et accessoires",
   code_42942200:"Étuves à vide",
   code_42943000:"Bains thermostatiques et accessoires",
   code_42943100:"Bobines de réfrigération",
   code_42943200:"Bains ultrasoniques",
   code_42943210:"Thermostats à immersion",
   code_42943300:"Refroidisseurs à immersion",
   code_42943400:"Circulateurs réfrigérés et circulateurs réfrigérés/chauffants",
   code_42943500:"Refroidisseurs/recycleurs",
   code_42943600:"Circulateurs à haute température",
   code_42943700:"Circulateurs chauffants",
   code_42943710:"Couvercles de bains",
   code_42950000:"Pièces pour machines à usage général",
   code_42952000:"Pièces pour centrifugeuses",
   code_42953000:"Pièces pour machines de calandrage",
   code_42954000:"Pièces pour machines à laminer",
   code_42955000:"Pièces pour machines de filtrage",
   code_42956000:"Pièces pour machines de purification",
   code_42957000:"Pièces pour machines de pulvérisation",
   code_42958000:"Poids pour machines de pesage",
   code_42959000:"Lave-vaisselle à usage non ménager",
   code_42960000:"Systèmes de commande et de contrôle, équipement d'impression et de graphisme, matériel bureautique et matériel de traitement de l'information",
   code_42961000:"Système de commande et de contrôle",
   code_42961100:"Système de contrôle des accès",
   code_42961200:"Système Scada ou équivalent",
   code_42961300:"Système de localisation de véhicules",
   code_42961400:"Système d'expédition",
   code_42962000:"Équipement d'impression et de graphisme",
   code_42962100:"Système d'impression sur feuille",
   code_42962200:"Presse à imprimer",
   code_42962300:"Postes de travail graphique",
   code_42962400:"Hectographes",
   code_42962500:"Machines à graver",
   code_42963000:"Presse à monnaie",
   code_42964000:"Matériel bureautique",
   code_42965000:"Matériel de traitement de l'information",
   code_42965100:"Système de gestion d'entrepôt",
   code_42965110:"Système de dépôt",
   code_42967000:"Unité de contrôle",
   code_42967100:"Unité de commande à distance numérique",
   code_42968000:"Distributeurs",
   code_42968100:"Distributeurs de boissons",
   code_42968200:"Distributeurs d'articles d'hygiène",
   code_42968300:"Dérouleurs de papier hygiénique",
   code_42970000:"Pièces pour lave-vaisselle et machines de nettoyage, de remplissage, d'emballage ou de conditionnement",
   code_42971000:"Pièces pour lave-vaisselle",
   code_42972000:"Pièces pour machines de nettoyage",
   code_42973000:"Pièces pour machines de remplissage",
   code_42974000:"Pièces pour machines d'emballage",
   code_42975000:"Pièces pour machines de conditionnement",
   code_42980000:"Générateurs de gaz",
   code_42981000:"Générateurs d'ozone",
   code_42990000:"Machines diverses à usage spécifique",
   code_42991000:"Machines à papier, d'imprimerie et de reliure et parties de machines",
   code_42991100:"Machines à relier",
   code_42991110:"Machines à brocher",
   code_42991200:"Machines à imprimer",
   code_42991210:"Machines d'impression offset",
   code_42991220:"Machines de composition",
   code_42991230:"Imprimantes de tickets",
   code_42991300:"Système de photocomposition",
   code_42991400:"Séchoirs pour bois, pâte à papier, papier ou carton",
   code_42991500:"Pièces pour machines à imprimer ou à relier",
   code_42992000:"Matériel électrique à usage spécifique",
   code_42992100:"Cage de Faraday",
   code_42992200:"Chambre anéchoïde",
   code_42992300:"Absorbant électromagnétique",
   code_42993000:"Machines pour l'industrie chimique",
   code_42993100:"Chlorateurs",
   code_42993200:"Installation de dosage",
   code_42994000:"Machines pour le travail du caoutchouc ou du plastique",
   code_42994100:"Machines pour la fabrication de fenêtres et encadrements en plastique",
   code_42994200:"Machines pour le travail du plastique",
   code_42994220:"Accessoires pour le contrecollage",
   code_42994230:"Contrecolleuses",
   code_42995000:"Machines à nettoyer diverses",
   code_42995100:"Tunnel de lavage",
   code_42995200:"Machines à nettoyer les plages",
   code_42996000:"Machines de traitement des eaux usées",
   code_42996100:"Dilacérateurs",
   code_42996110:"Dilacérateurs pour le traitement des eaux usées",
   code_42996200:"Presses à eaux usées",
   code_42996300:"Racloirs",
   code_42996400:"Mélangeurs",
   code_42996500:"Filtres à eaux usées",
   code_42996600:"Matériel d'oxygénation",
   code_42996700:"Précipitateurs",
   code_42996800:"Lits de sédimentation",
   code_42996900:"Équipement de traitement des boues",
   code_42997000:"Matériel pour pipelines",
   code_42997100:"Machines d'inspection de la surface intérieure de pipelines",
   code_42997200:"Machines de nettoyage de la surface intérieure de pipelines",
   code_42997300:"Robots industriels",
   code_42998000:"Système de levage de palettes",
   code_42998100:"Système de récupération de palettes",
   code_42999000:"Aspirateurs et cireuses à usage non ménager",
   code_42999100:"Aspirateurs à usage non ménager",
   code_42999200:"Cireuses à usage non ménager",
   code_42999300:"Pièces pour aspirateurs à usage non ménager",
   code_42999400:"Pièces pour cireuses à usage non ménager",
   code_43000000:"Équipement minier, équipement pour l'exploitation de carrières, matériel de construction",
   code_43100000:"Équipement minier",
   code_43120000:"Haveuses à charbon ou à roche, tunneliers et machines de forage ou de fonçage",
   code_43121000:"Machines de forage de puits",
   code_43121100:"Outils d'exploitation des têtes de puits",
   code_43121200:"Connecteurs de colonnes montantes",
   code_43121300:"Matériel d'achèvement de puits",
   code_43121400:"Matériel d'intervention sur puits",
   code_43121500:"Matériel d'essais de puits",
   code_43121600:"Appareils pour bloc obturateur de puits (BOP)",
   code_43122000:"Haveuses à charbon ou à roche",
   code_43123000:"Tunneliers",
   code_43124000:"Machines de percement",
   code_43124100:"Taupes",
   code_43124900:"Matériel de forage de roches",
   code_43125000:"Machines de fonçage",
   code_43130000:"Matériel de forage",
   code_43131000:"Plates-formes de production offshore",
   code_43131100:"Matériel offshore",
   code_43131200:"Unité de forage offshore",
   code_43132000:"Matériel de forage pétrolier",
   code_43132100:"Machines de forage",
   code_43132200:"Tour de forage",
   code_43132300:"Foreuses",
   code_43132400:"Matériel de tubage",
   code_43132500:"Dispositifs de suspension pour colonne de tubage perdue",
   code_43133000:"Matériel de plate-forme pétrolière",
   code_43133100:"Unités sur patins",
   code_43133200:"Modules montés sur patins",
   code_43134000:"Machines pour champs de pétrole",
   code_43134100:"Pompes submersibles",
   code_43135000:"Équipement sous-marin",
   code_43135100:"Systèmes de contrôle sous-marin",
   code_43136000:"Équipement de fonds de puits",
   code_43140000:"Soutènements miniers mobiles à commande hydraulique",
   code_43200000:"Machines de terrassement et d'excavation et pièces détachées",
   code_43210000:"Machines de terrassement",
   code_43211000:"Bulldozers",
   code_43212000:"Bouteurs biais",
   code_43220000:"Niveleuses-régleuses et niveleuses-surfaceuses",
   code_43221000:"Niveleuses automotrices",
   code_43230000:"Machines décapeuses",
   code_43240000:"Machines à bourrer",
   code_43250000:"Chargeuses à benne frontale",
   code_43251000:"Chargeuses à benne frontale à pelle rétrocaveuse",
   code_43252000:"Chargeuses à benne frontale sans pelle rétrocaveuse",
   code_43260000:"Pelles mécaniques, excavateurs, chouleurs et machines de mines",
   code_43261000:"Pelles mécaniques",
   code_43261100:"Chouleurs mécaniques",
   code_43262000:"Machines excavatrices",
   code_43262100:"Excavateurs mécaniques",
   code_43300000:"Machines et équipement pour la construction",
   code_43310000:"Machines de génie civil",
   code_43311000:"Sonnettes de battage",
   code_43312000:"Machines de revêtement routier",
   code_43312100:"Engins de nivelage",
   code_43312200:"Épandeuses de gravillons",
   code_43312300:"Machines de pavage",
   code_43312400:"Rouleaux pour routes",
   code_43312500:"Rouleaux mécaniques",
   code_43313000:"Chasse-neige et balayeuses soufflantes",
   code_43313100:"Chasse-neige",
   code_43313200:"Balayeuses soufflantes",
   code_43314000:"Machines pour l'arrachage des pieux",
   code_43315000:"Compacteurs",
   code_43316000:"Machines pour la pose de câbles",
   code_43320000:"Équipement de construction",
   code_43321000:"Matériel de suspension de pont",
   code_43322000:"Matériel de démantèlement",
   code_43323000:"Matériel d'irrigation",
   code_43324000:"Matériel de drainage",
   code_43324100:"Équipement de piscine",
   code_43325000:"Équipement de parcs et de terrains de jeux",
   code_43325100:"Matériel d'entretien de terrains",
   code_43327000:"Matériel préfabriqué",
   code_43328000:"Installations hydrauliques",
   code_43328100:"Matériel hydraulique",
   code_43329000:"Ensembles d'équipement",
   code_43400000:"Machines de traitement des minéraux et de formage des moules de fonderie",
   code_43410000:"Machines de traitement des minéraux",
   code_43411000:"Machines de tri et de criblage",
   code_43412000:"Mélangeuses de gravier et de bitume",
   code_43413000:"Mélangeuses de béton ou mortier",
   code_43413100:"Mélangeuses de ciment",
   code_43414000:"Rectifieuses",
   code_43414100:"Pulvérisateurs de charbon",
   code_43415000:"Moules de fonderie",
   code_43420000:"Machines de formage des moules de fonderie",
   code_43500000:"Véhicules à chenilles",
   code_43600000:"Pièces pour machines pour les industries excavatrices et la construction",
   code_43610000:"Pièces pour machines de forage",
   code_43611000:"Pièces pour machines de forage de puits",
   code_43611100:"Bouchons de support",
   code_43611200:"Trépans industriels",
   code_43611300:"Pivots de forage",
   code_43611400:"Foreurs en fer",
   code_43611500:"Tables rotatives",
   code_43611600:"Supports de conduites de boues",
   code_43611700:"Équipement de raccordement",
   code_43612000:"Pièces de machines d'extraction de puits",
   code_43612100:"Suspensions de cuvelage",
   code_43612200:"Équipements de suspension pour colonne de tubage perdue",
   code_43612300:"Tensionneurs de riser de production",
   code_43612400:"Têtes de puits",
   code_43612500:"Équipement de raccordement de riser de production",
   code_43612600:"Système de commande de tête de puits",
   code_43612700:"Équipement pour têtes de puits",
   code_43612800:"Systèmes de levage d'appareils de forage",
   code_43613000:"Pièces pour haveuses à charbon ou à roche",
   code_43613100:"Pièces pour haveuses à charbon",
   code_43613200:"Pièces pour haveuses à roche",
   code_43614000:"Pièces pour tunneliers",
   code_43620000:"Pièces pour machines de traitement de minerais",
   code_43630000:"Pièces pour machines de fonçage",
   code_43640000:"Pièces pour excavateurs",
   code_43700000:"Machines de métallurgie et pièces associées",
   code_43710000:"Machines à laminer les métaux",
   code_43711000:"Pièces pour machines à laminer les métaux",
   code_43720000:"Machines à couler",
   code_43721000:"Pièces pour machines à couler",
   code_43800000:"Équipement d'atelier",
   code_43810000:"Équipement pour le travail du bois",
   code_43811000:"Ponceuses",
   code_43812000:"Matériel de sciage",
   code_43820000:"Matériel de cordonnerie",
   code_43830000:"Outils à moteur",
   code_43840000:"Matériel de forge",
   code_44000000:"Matériaux et structures de construction; produits auxiliaires pour la construction (à l'exception des appareils électriques)",
   code_44100000:"Matériaux de construction et articles connexes",
   code_44110000:"Matériaux de construction",
   code_44111000:"Matériaux de bâtiment",
   code_44111100:"Briques",
   code_44111200:"Ciment",
   code_44111210:"Ciment de forage",
   code_44111300:"Céramique",
   code_44111400:"Peintures et revêtements muraux",
   code_44111500:"Isolants et pièces isolantes",
   code_44111510:"Isolants",
   code_44111511:"Isolants éléctriques",
   code_44111520:"Matériaux d'isolation thermique",
   code_44111530:"Pièces isolantes électriques",
   code_44111540:"Verre isolant",
   code_44111600:"Blocs de pavage",
   code_44111700:"Carreaux",
   code_44111800:"Mortier (construction)",
   code_44111900:"Dalles en céramique",
   code_44112000:"Structures de construction diverses",
   code_44112100:"Abris",
   code_44112110:"Éléments d'abris",
   code_44112120:"Sections profilées",
   code_44112200:"Revêtements de sol",
   code_44112210:"Revêtements de sol rigides",
   code_44112220:"Faux planchers",
   code_44112230:"Linoléum",
   code_44112240:"Parquet",
   code_44112300:"Cloisons",
   code_44112310:"Murs de cloisonnement",
   code_44112400:"Toit",
   code_44112410:"Charpentes de toit",
   code_44112420:"Soutènements de toit",
   code_44112430:"Fermes de toit",
   code_44112500:"Matériaux pour toiture",
   code_44112510:"Bardeaux",
   code_44112600:"Isolation acoustique",
   code_44112700:"Solives",
   code_44113000:"Matériaux de construction routière",
   code_44113100:"Matériaux de pavage",
   code_44113120:"Dalles de pavage",
   code_44113130:"Pierres de pavage",
   code_44113140:"Cailloux pour l'empierrement des routes",
   code_44113200:"Dalles",
   code_44113300:"Matériaux enrobés",
   code_44113310:"Matériaux routiers enrobés",
   code_44113320:"Enrobés routiers",
   code_44113330:"Produits d'enrobage",
   code_44113500:"Perles de verre",
   code_44113600:"Bitume et asphalte",
   code_44113610:"Bitume",
   code_44113620:"Asphalte",
   code_44113700:"Matériaux de réparation routière",
   code_44113800:"Matériaux de surfaçage routier",
   code_44113810:"Enduit superficiel",
   code_44113900:"Matériaux d'entretien routier",
   code_44113910:"Matériaux d'entretien routier hivernal",
   code_44114000:"Béton",
   code_44114100:"Béton prêt à l'emploi",
   code_44114200:"Produits en béton",
   code_44114210:"Pieux en béton",
   code_44114220:"Conduites et raccords en béton",
   code_44114250:"Dalles en béton",
   code_44115000:"Infrastructures de bâtiment",
   code_44115100:"Conduits",
   code_44115200:"Matériel de plomberie et de chauffage",
   code_44115210:"Matériel de plomberie",
   code_44115220:"Matériel de chauffage",
   code_44115310:"Volets roulants",
   code_44115400:"Claires-voies",
   code_44115500:"Systèmes de pulvérisation d'eau",
   code_44115600:"Monte-personnes d'escalier",
   code_44115700:"Stores d'extérieur",
   code_44115710:"Auvents",
   code_44115800:"Aménagements intérieurs de bâtiment",
   code_44115810:"Rails pour rideaux et tentures",
   code_44115811:"Rails pour rideaux",
   code_44115900:"Dispositifs de protection contre le soleil",
   code_44130000:"Conduites d'égouts",
   code_44131000:"Chambres à eaux d'égouts",
   code_44132000:"Éléments d'aqueducs",
   code_44133000:"Bouchons en fer ductile",
   code_44134000:"Raccords angulaires",
   code_44140000:"Produits connexes aux matériaux de construction",
   code_44141000:"Canalisation",
   code_44141100:"Canalisation électrique",
   code_44142000:"Charpentes",
   code_44143000:"Palettes",
   code_44144000:"Poteaux (construction)",
   code_44160000:"Pipelines, tuyauterie, conduites, gainage, tubage et articles connexes",
   code_44161000:"Pipelines",
   code_44161100:"Gazoducs",
   code_44161110:"Réseaux de distribution de gaz",
   code_44161200:"Canalisations d'eau",
   code_44161400:"Pipelines subaquatiques",
   code_44161410:"Pipelines sous-marins",
   code_44161500:"Conduites à haute pression",
   code_44161600:"Conduites à basse pression",
   code_44161700:"Râcleurs de conduites",
   code_44161710:"Dispositifs de lancement de râcleurs",
   code_44161720:"Dispositifs de réception de râcleurs",
   code_44161730:"Sas pour râcleurs",
   code_44162000:"Tuyauterie",
   code_44162100:"Accessoires de tuyauterie",
   code_44162200:"Conduites de distribution",
   code_44162300:"Conduites de déversement",
   code_44162400:"Trous d'homme en grès vitrifié",
   code_44162500:"Conduites d'eau potable",
   code_44163000:"Tuyaux et raccords",
   code_44163100:"Conduites",
   code_44163110:"Tuyaux d'assèchement",
   code_44163111:"Tuyaux de drainage",
   code_44163112:"Système de drainage",
   code_44163120:"Conduites de chauffage à distance",
   code_44163121:"Conduites de chauffage",
   code_44163130:"Canalisations d'égouts",
   code_44163140:"Conduites à vapeur et à eau",
   code_44163150:"Conduites basse pression",
   code_44163160:"Conduites de distribution et accessoires",
   code_44163200:"Raccords de tuyauterie",
   code_44163210:"Colliers de serrage pour tuyaux",
   code_44163230:"Raccords pour tuyaux",
   code_44163240:"Joints de tuyauterie",
   code_44163241:"Joints isolants",
   code_44164000:"Gainage et tubage",
   code_44164100:"Gainage",
   code_44164200:"Tubage",
   code_44164300:"Matériel tubulaire",
   code_44164310:"Tubes et raccords",
   code_44165000:"Tuyaux, conduites ascendantes et manchons",
   code_44165100:"Tuyaux",
   code_44165110:"Tuyaux de forage",
   code_44165200:"Conduites ascendantes",
   code_44165210:"Conduites ascendantes souples",
   code_44165300:"Manchons",
   code_44166000:"Conduites de pétrole à grande distance",
   code_44167000:"Accessoires de tuyauterie divers",
   code_44167100:"Raccords",
   code_44167110:"Brides",
   code_44167111:"Adaptateurs de brides",
   code_44167200:"Brides et colliers de réparation",
   code_44167300:"Coudes, pièces en T et accessoires de tuyauterie",
   code_44167400:"Coudes",
   code_44170000:"Plaques, tôles, bandes et feuilles connexes aux matériaux de construction",
   code_44171000:"Plaques (construction)",
   code_44172000:"Tôles (construction)",
   code_44173000:"Bandes",
   code_44174000:"Feuilles",
   code_44175000:"Panneaux",
   code_44176000:"Films (construction)",
   code_44190000:"Matériaux de construction divers",
   code_44191000:"Matériaux divers de construction en bois",
   code_44191100:"Contreplaqué",
   code_44191200:"Bois stratifié",
   code_44191300:"Panneaux de particules",
   code_44191400:"Panneaux de fibres",
   code_44191500:"Bois densifié",
   code_44191600:"Panneaux de parquet",
   code_44192000:"Autres matériaux de construction divers",
   code_44192100:"Mousse de PVC",
   code_44192200:"Clous",
   code_44200000:"Produits structurels",
   code_44210000:"Structures et pièces structurelles",
   code_44211000:"Bâtiments préfabriqués",
   code_44211100:"Bâtiments modulaires préfabriqués",
   code_44211110:"Cabines",
   code_44211200:"Cellules",
   code_44211300:"Hôpital de campagne",
   code_44211400:"Cuisines de campagne",
   code_44211500:"Serres",
   code_44212000:"Produits structurels et pièces structurelles, à l'exception des bâtiments préfabriqués",
   code_44212100:"Pont",
   code_44212110:"Sections de pont",
   code_44212120:"Structures de pont",
   code_44212200:"Tours, mâts en treillis, derricks et pylônes",
   code_44212210:"Derricks",
   code_44212211:"Mâts de forage",
   code_44212212:"Mâts de forage statiques",
   code_44212220:"Pylônes, poteaux et piquets",
   code_44212221:"Pylônes",
   code_44212222:"Pylônes pour lignes électriques",
   code_44212223:"Socles pour pylônes",
   code_44212224:"Poteaux pour lignes électriques aériennes",
   code_44212225:"Poteaux",
   code_44212226:"Poteaux électriques",
   code_44212227:"Piquets",
   code_44212230:"Tours en métal",
   code_44212233:"Châteaux d'eau",
   code_44212240:"Poutres",
   code_44212250:"Mâts",
   code_44212260:"Pylônes de radio ou de télévision",
   code_44212261:"Pylônes de radio",
   code_44212262:"Pylônes de télévision",
   code_44212263:"Mâts en treillis",
   code_44212300:"Structures et pièces",
   code_44212310:"Échafaudages",
   code_44212311:"Contre-fiches cintrées",
   code_44212312:"Trépieds cintrés",
   code_44212313:"Supports",
   code_44212314:"Brides d'attache pour tuyaux",
   code_44212315:"Équipement pour échafaudages",
   code_44212316:"Colliers de serrage cintrés",
   code_44212317:"Structures d'échafaudage",
   code_44212318:"Supports pour tuyauterie",
   code_44212320:"Structures diverses",
   code_44212321:"Abris d'autobus",
   code_44212322:"Cabines téléphoniques",
   code_44212329:"Écrans de sécurité",
   code_44212380:"Pièces structurelles",
   code_44212381:"Revêtement métallique",
   code_44212382:"Vannes",
   code_44212383:"Portes d'écluse",
   code_44212390:"Écluses de vidange",
   code_44212391:"Portes d'écluse de vidange",
   code_44212400:"Palplanches",
   code_44212410:"Rideaux de palplanches",
   code_44212500:"Angles et sections",
   code_44212510:"Angles",
   code_44212520:"Sections",
   code_44220000:"Menuiserie pour la construction",
   code_44221000:"Fenêtres, portes et articles connexes",
   code_44221100:"Fenêtres",
   code_44221110:"Encadrements de fenêtres",
   code_44221111:"Double vitrage",
   code_44221120:"Portes-fenêtres",
   code_44221200:"Portes",
   code_44221210:"Panneaux de portes",
   code_44221211:"Encadrements de portes",
   code_44221212:"Portes moustiquaires",
   code_44221213:"Seuils de porte",
   code_44221220:"Portes coupe-feu",
   code_44221230:"Portes coulissantes",
   code_44221240:"Portes de garage",
   code_44221300:"Portails",
   code_44221310:"Portails d'entrée",
   code_44221400:"Volets",
   code_44221500:"Seuils",
   code_44230000:"Charpenterie pour la construction",
   code_44231000:"Panneaux de clôture prêts à poser",
   code_44232000:"Poutres de toiture en bois",
   code_44233000:"Escaliers",
   code_44300000:"Câble, fil métallique et produits connexes",
   code_44310000:"Articles en fils métalliques",
   code_44311000:"Toron tressé",
   code_44312000:"Fil de fer pour clôture",
   code_44312300:"Fil de fer barbelé",
   code_44313000:"Filet en métal",
   code_44313100:"Clôture en treillis métallique",
   code_44313200:"Toile métallique",
   code_44315000:"Fil machine",
   code_44315100:"Accessoires de soudage",
   code_44315200:"Matériaux de soudage",
   code_44315300:"Matériaux de brasage tendre ou de brasage fort",
   code_44315310:"Matériaux de brasage tendre",
   code_44315320:"Matériaux de brasage fort",
   code_44316000:"Quincaillerie",
   code_44316100:"Enclumes",
   code_44316200:"Forge portative",
   code_44316300:"Grilles",
   code_44316400:"Articles de quincaillerie",
   code_44316500:"Serrurerie",
   code_44316510:"Ferronnerie",
   code_44317000:"Élingues en fer ou en acier",
   code_44318000:"Conducteurs",
   code_44320000:"Câbles et produits connexes",
   code_44321000:"Câble",
   code_44322000:"Accessoires pour câble",
   code_44322100:"Conduites de câble",
   code_44322200:"Raccords de câbles",
   code_44322300:"Gaines de câble",
   code_44322400:"Attaches pour câble",
   code_44330000:"Barres, tiges, fils et profilés pour la construction",
   code_44331000:"Barres (construction)",
   code_44332000:"Tiges",
   code_44333000:"Fil métallique",
   code_44334000:"Profilés",
   code_44400000:"Produits fabriqués divers et éléments connexes",
   code_44410000:"Articles de salle de bains et de cuisine",
   code_44411000:"Appareils sanitaires",
   code_44411100:"Robinets",
   code_44411200:"Baignoires",
   code_44411300:"Lavabos",
   code_44411400:"Receveurs de douche",
   code_44411600:"Bidets",
   code_44411700:"Lunettes, abattants, cuvettes et réservoirs de chasse d'eau de WC",
   code_44411710:"Sièges de WC",
   code_44411720:"Abattants de WC",
   code_44411740:"Cuvettes de WC",
   code_44411750:"Réservoirs de chasse d'eau",
   code_44411800:"Urinoirs",
   code_44420000:"Produits utilisés dans la construction",
   code_44421000:"Coffres, cassettes de sûreté et portes blindés ou armés",
   code_44421300:"Coffres-forts",
   code_44421500:"Portes blindées ou armées",
   code_44421600:"Coffres de valeurs",
   code_44421700:"Consignes et coffres",
   code_44421710:"Consignes",
   code_44421720:"Coffres",
   code_44421721:"Coffres à valeurs",
   code_44421722:"Coffrets de sécurité",
   code_44421780:"Coffres à documents",
   code_44421790:"Caisses pour argent liquide",
   code_44422000:"Boîtes à lettres",
   code_44423000:"Article divers",
   code_44423100:"Cloches",
   code_44423200:"Échelles",
   code_44423220:"Escaliers pliants",
   code_44423230:"Escabeaux",
   code_44423300:"Équipement de manutention de marchandises",
   code_44423330:"Plates-formes de manutention de marchandises",
   code_44423340:"Accessoires en câble pour la manutention de marchandises",
   code_44423400:"Panneaux de signalisation et articles connexes",
   code_44423450:"Plaques indicatrices",
   code_44423460:"Plaques d'adresse",
   code_44423700:"Éléments de trous d'homme",
   code_44423710:"Couvercles de bouches d'incendie",
   code_44423720:"Boîtes de délimitation",
   code_44423730:"Cadres de trous d'homme",
   code_44423740:"Couvercles de trous d'homme",
   code_44423750:"Plaques d'égouts",
   code_44423760:"Couvercles d'accès",
   code_44423790:"Barreaux d'échelle en fer pour trous d'homme",
   code_44423800:"Poinçons en métal",
   code_44423810:"Timbres en caoutchouc",
   code_44423850:"Formes",
   code_44423900:"Anodes sacrifiées",
   code_44424000:"Coffrets-compteurs et ruban",
   code_44424100:"Coffrets-compteurs",
   code_44424200:"Ruban adhésif",
   code_44424300:"Bande réfléchissante",
   code_44425000:"Anneaux, joints, bracelets, baguettes et masses de scellement",
   code_44425100:"Anneaux élastiques",
   code_44425110:"Anneaux de renfort autoadhésifs",
   code_44425200:"Joints en caoutchouc",
   code_44425300:"Bracelets en caoutchouc",
   code_44425400:"Masses de scellement",
   code_44425500:"Baguettes en plastique",
   code_44430000:"Plaques de blindage",
   code_44431000:"Plaques de recouvrement",
   code_44440000:"Paliers de roulement",
   code_44441000:"Microbilles",
   code_44442000:"Roulements à rouleaux",
   code_44450000:"Acier doux",
   code_44451000:"Flans de monnaie",
   code_44452000:"Boîtes réceptrices de monnaie",
   code_44460000:"Étançons et étais miniers",
   code_44461000:"Étançons",
   code_44461100:"Étançons miniers",
   code_44462000:"Étais miniers",
   code_44464000:"Chenilles",
   code_44470000:"Produits en fonte",
   code_44480000:"Équipements divers de protection contre l'incendie",
   code_44481000:"Échelles à plate-forme",
   code_44481100:"Échelles d'incendie",
   code_44482000:"Dispositifs de protection contre l'incendie",
   code_44482100:"Tuyaux d'incendie",
   code_44482200:"Bouches d'incendie",
   code_44500000:"Outils, serrures, clés, charnières, fermoirs, chaînes et ressorts",
   code_44510000:"Outils",
   code_44511000:"Outils à main",
   code_44511100:"Bêches et pelles",
   code_44511110:"Bêches",
   code_44511120:"Pelles",
   code_44511200:"Fourches de jardinage",
   code_44511300:"Pioches, pics, houes, râteaux et râteaux de plage",
   code_44511310:"Pioches",
   code_44511320:"Pics",
   code_44511330:"Houes",
   code_44511340:"Râteaux",
   code_44511341:"Râteaux de plage",
   code_44511400:"Haches",
   code_44511500:"Scies à main",
   code_44511510:"Lames de scies à main",
   code_44512000:"Outils à main divers",
   code_44512100:"Burins",
   code_44512200:"Pinces",
   code_44512210:"Pinces à sertir",
   code_44512300:"Marteaux",
   code_44512400:"Mâchoires pour fil métallique",
   code_44512500:"Clés de serrage",
   code_44512600:"Outils pour travaux routiers",
   code_44512610:"Pointes à poinçonner le revêtement routier",
   code_44512700:"Limes ou râpes",
   code_44512800:"Tournevis",
   code_44512900:"Trépans, embouts de tournevis et autres accessoires",
   code_44512910:"Trépans",
   code_44512920:"Embouts de tournevis",
   code_44512930:"Porte-outils",
   code_44512940:"Boîtes à outils",
   code_44513000:"Outils à pédale",
   code_44514000:"Manches d'outils et pièces d'outils",
   code_44514100:"Manches d'outils",
   code_44514200:"Pièces d'outils",
   code_44520000:"Serrures, clés et charnières",
   code_44521000:"Cadenas et serrures divers",
   code_44521100:"Serrures",
   code_44521110:"Serrures de portes",
   code_44521120:"Verrous de sécurité électroniques",
   code_44521130:"Verrous de sécurité renforcés",
   code_44521140:"Serrures pour mobilier",
   code_44521200:"Cadenas et chaînes",
   code_44521210:"Cadenas",
   code_44522000:"Fermoirs, pièces pour serrures et clés",
   code_44522100:"Fermoirs",
   code_44522200:"Clés",
   code_44522300:"Pièces pour cadenas",
   code_44522400:"Pièces pour serrures",
   code_44523000:"Charnières, ferrures et raccords",
   code_44523100:"Charnières",
   code_44523200:"Ferrures",
   code_44523300:"Raccords",
   code_44530000:"Attaches",
   code_44531000:"Attaches filetées",
   code_44531100:"Vis à bois",
   code_44531200:"Crochets à vis ou bagues à pas de vis",
   code_44531300:"Vis autotaraudeuses",
   code_44531400:"Boulons",
   code_44531500:"Ensembles de raccordements à brides",
   code_44531510:"Boulons et vis",
   code_44531520:"Tire-fonds et vis",
   code_44531600:"Écrous",
   code_44531700:"Articles filetés en fer ou en acier",
   code_44532000:"Attaches non filetées",
   code_44532100:"Rivets",
   code_44532200:"Rondelles",
   code_44532300:"Goupilles",
   code_44532400:"Éclisses en acier",
   code_44533000:"Attaches en cuivre",
   code_44540000:"Chaînes",
   code_44541000:"Chaîne à articulations",
   code_44542000:"Éléments de chaîne",
   code_44550000:"Ressorts",
   code_44600000:"Citernes, réservoirs, conteneurs; radiateurs et chaudières de chauffage central",
   code_44610000:"Citernes, réservoirs, conteneurs et cuves sous pression",
   code_44611000:"Citernes",
   code_44611100:"Cylindres à air",
   code_44611110:"Cylindres à air comprimé",
   code_44611200:"Appareils respiratoires",
   code_44611400:"Réservoirs de stockage",
   code_44611410:"Réservoirs à huile",
   code_44611420:"Réservoirs à boues",
   code_44611500:"Cuves à eau",
   code_44611600:"Réservoirs",
   code_44612000:"Bouteilles de gaz liquéfié",
   code_44612100:"Bouteilles de gaz",
   code_44612200:"Réservoirs à gaz",
   code_44613000:"Grands conteneurs",
   code_44613110:"Silos",
   code_44613200:"Conteneurs réfrigérés",
   code_44613210:"Citernes à eau",
   code_44613300:"Conteneurs à marchandises standard",
   code_44613400:"Conteneurs de stockage",
   code_44613500:"Conteneurs à eau",
   code_44613600:"Conteneurs sur roues",
   code_44613700:"Bennes à déchets",
   code_44613800:"Conteneurs à déchets",
   code_44614000:"Tonneaux en métal",
   code_44614100:"Caisses de stockage",
   code_44614300:"Système de stockage de conteneurs",
   code_44614310:"Machines à empiler",
   code_44615000:"Cuves sous pression",
   code_44615100:"Cuves sous pression en acier",
   code_44616000:"Fûts en métal",
   code_44616200:"Fûts de déchets",
   code_44617000:"Boîtiers",
   code_44617100:"Cartons",
   code_44617200:"Compartiments pour compteurs",
   code_44617300:"Boîtiers préfabriqués",
   code_44618000:"Conteneurs légers, bouchons, couvercles pour conteneurs, cuves et couvercles",
   code_44618100:"Conteneurs légers",
   code_44618300:"Bouchons, couvercles pour conteneurs, cuves et couvercles",
   code_44618310:"Bouchons",
   code_44618320:"Obturateurs à vis",
   code_44618330:"Couvercles pour conteneurs",
   code_44618340:"Couvercles",
   code_44618350:"Capsules en plastique",
   code_44618400:"Boîtes métalliques",
   code_44618420:"Boîtes pour aliments",
   code_44618500:"Cuves",
   code_44619000:"Autres conteneurs",
   code_44619100:"Caisses",
   code_44619200:"Enrouleurs de câbles",
   code_44619300:"Caisses à claire-voie",
   code_44619400:"Tonneaux en bois",
   code_44619500:"Caisses-palettes",
   code_44620000:"Radiateurs et chaudières pour chauffage central et pièces détachées",
   code_44621000:"Radiateurs et chaudières",
   code_44621100:"Radiateurs",
   code_44621110:"Radiateurs pour chauffage central",
   code_44621111:"Radiateurs non électriques pour chauffage central",
   code_44621112:"Pièces détachées de radiateurs pour chauffage central",
   code_44621200:"Chaudières",
   code_44621210:"Chaudières à eau",
   code_44621220:"Chaudières pour chauffage central",
   code_44621221:"Pièces détachées de chaudières pour chauffage central",
   code_44622000:"Systèmes de récupération de chaleur",
   code_44622100:"Équipement de récupération de chaleur",
   code_44800000:"Peintures, vernis et mastics",
   code_44810000:"Peintures",
   code_44811000:"Peinture de marquage routier",
   code_44812000:"Peintures à usage artistique",
   code_44812100:"Émail et peintures transparentes",
   code_44812200:"Peintures à l'huile et à l'eau",
   code_44812210:"Peintures à l'huile",
   code_44812220:"Peintures à l'eau",
   code_44812300:"Peintures scolaires",
   code_44812310:"Assortiments de couleurs",
   code_44812320:"Couleurs pour signalisation",
   code_44812400:"Articles pour peintres et tapissiers",
   code_44820000:"Vernis",
   code_44830000:"Mastics, matières de charge, ciment de scellement et diluants",
   code_44831000:"Mastics, matières de charge, ciment de scellement",
   code_44831100:"Mastics",
   code_44831200:"Matières de charge",
   code_44831300:"Ciment de scellement",
   code_44831400:"Coulis de ciment",
   code_44832000:"Solvants",
   code_44832100:"Décapant",
   code_44832200:"Diluants",
   code_44900000:"Pierre de construction, chaux, gypse et ardoise",
   code_44910000:"Pierre de construction",
   code_44911000:"Marbre et pierre calcaire de construction",
   code_44911100:"Marbre",
   code_44911200:"Travertin",
   code_44912000:"Pierres de construction diverses",
   code_44912100:"Granit",
   code_44912200:"Grès",
   code_44912300:"Basalte",
   code_44912400:"Bordures",
   code_44920000:"Pierre calcaire, gypse et craie",
   code_44921000:"Pierre calcaire et gypse",
   code_44921100:"Gypse",
   code_44921200:"Chaux",
   code_44921210:"Chaux en poudre",
   code_44921300:"Pierre calcaire",
   code_44922000:"Craie et dolomie",
   code_44922100:"Craie",
   code_44922200:"Dolomie",
   code_44930000:"Ardoise",
   code_45000000:"Travaux de construction",
   code_45100000:"Travaux de préparation de chantier",
   code_45110000:"Travaux de démolition de bâtiments et travaux de terrassement",
   code_45111000:"Travaux de démolition, travaux de préparation et de dégagement de chantier",
   code_45111100:"Travaux de démolition",
   code_45111200:"Travaux de préparation et de dégagement de chantier",
   code_45111210:"Travaux de démolition à l'explosif et travaux de déblaiement consécutifs",
   code_45111211:"Travaux de démolition à l'explosif",
   code_45111212:"Travaux de déblaiement",
   code_45111213:"Travaux de dégagement de chantier",
   code_45111214:"Travaux d'enlèvement de gravats",
   code_45111220:"Travaux de débroussaillage",
   code_45111230:"Travaux de stabilisation des sols",
   code_45111240:"Travaux d'assèchement des sols",
   code_45111250:"Travaux d'étude géotechnique",
   code_45111260:"Travaux de préparation de sites miniers",
   code_45111290:"Travaux préparatoires de viabilité",
   code_45111291:"Travaux d'aménagement du terrain",
   code_45111300:"Travaux de démantèlement",
   code_45111310:"Travaux de démantèlement d'installations militaires",
   code_45111320:"Travaux de démantèlement d'installations de sécurité",
   code_45112000:"Travaux de fouille et de terrassement",
   code_45112100:"Travaux de creusement de tranchées",
   code_45112200:"Travaux de décapage de terre",
   code_45112210:"Travaux de décapage de terre végétale",
   code_45112300:"Travaux de remblayage et de mise en condition du terrain",
   code_45112310:"Travaux de remblayage",
   code_45112320:"Travaux de mise en condition du terrain",
   code_45112330:"Travaux de mise en état du chantier",
   code_45112340:"Travaux de décontamination du sol",
   code_45112350:"Remise en valeur de terrains vagues",
   code_45112360:"Travaux de remise en état du terrain",
   code_45112400:"Travaux d'excavation",
   code_45112410:"Travaux de fossoyage",
   code_45112420:"Travaux de fouille en excavation",
   code_45112440:"Dressement de talus",
   code_45112441:"Talutage",
   code_45112450:"Travaux d'excavation sur sites archéologiques",
   code_45112500:"Travaux de terrassement",
   code_45112600:"Déblai-remblai",
   code_45112700:"Travaux d'aménagement paysager",
   code_45112710:"Travaux d'aménagement paysager d'espaces verts",
   code_45112711:"Travaux d'aménagement paysager de parcs",
   code_45112712:"Travaux d'aménagement paysager de jardins",
   code_45112713:"Travaux d'aménagement paysager de jardins sur les toits",
   code_45112714:"Travaux d'aménagement paysager de cimetières",
   code_45112720:"Travaux d'aménagement paysager de terrains de sport et d'aires de loisirs",
   code_45112721:"Travaux d'aménagement paysager de terrains de golf",
   code_45112722:"Travaux d'aménagement paysager de terrains d'équitation",
   code_45112723:"Travaux d'aménagement paysager de terrains de jeux",
   code_45112730:"Travaux d'aménagement paysager de routes et d'autoroutes",
   code_45112740:"Travaux d'aménagement paysager d'aéroports",
   code_45113000:"Travaux de chantier",
   code_45120000:"Travaux de sondage et de forage de reconnaissance",
   code_45121000:"Travaux de sondage expérimental",
   code_45122000:"Travaux de forage de reconnaissance",
   code_45200000:"Travaux de construction complète ou partielle et travaux de génie civil",
   code_45210000:"Travaux de construction de bâtiments",
   code_45211000:"Travaux de construction d'immeubles collectifs et de maisons individuelles",
   code_45211100:"Travaux de construction de maisons",
   code_45211200:"Travaux de construction de logements-foyers",
   code_45211300:"Travaux de construction complète de maisons",
   code_45211310:"Travaux de construction de salles de bains",
   code_45211320:"Travaux de construction de porches",
   code_45211340:"Travaux de construction d'immeubles collectifs",
   code_45211341:"Travaux de construction d'appartements",
   code_45211350:"Travaux de construction de bâtiments multifonctionnels",
   code_45211360:"Travaux de développement urbain",
   code_45211370:"Travaux de construction de saunas",
   code_45212000:"Travaux de construction de bâtiments destinés aux loisirs, aux sports, à la culture, à l'hébergement et de restaurants",
   code_45212100:"Construction d'équipements pour les loisirs",
   code_45212110:"Travaux de construction de centres de loisirs",
   code_45212120:"Travaux de construction de parcs à thèmes",
   code_45212130:"Travaux de construction de parcs d'attractions",
   code_45212140:"Installation récréative",
   code_45212150:"Travaux de construction de cinémas",
   code_45212160:"Travaux de construction de casinos",
   code_45212170:"Travaux de construction de bâtiments de loisirs",
   code_45212171:"Travaux de construction de centres de divertissements",
   code_45212172:"Travaux de construction de centres récréatifs",
   code_45212180:"Travaux de construction de billetteries",
   code_45212190:"Ouvrages de protection contre le soleil",
   code_45212200:"Travaux de construction d'installations sportives",
   code_45212210:"Travaux de construction d'installations sportives monovalentes",
   code_45212211:"Travaux de construction de patinoires",
   code_45212212:"Travaux de construction de piscines",
   code_45212213:"Travaux de marquage sportif",
   code_45212220:"Travaux de construction d'installations sportives polyvalentes",
   code_45212221:"Travaux de construction d'ouvrages pour terrain de sports",
   code_45212222:"Travaux de construction de gymnases",
   code_45212223:"Travaux de construction d'installations de sports d'hiver",
   code_45212224:"Travaux de construction de stades",
   code_45212225:"Travaux de construction de palais des sports",
   code_45212230:"Installation de vestiaires",
   code_45212290:"Réparation et entretien d'infrastructures sportives",
   code_45212300:"Travaux de construction de bâtiments à usage artistique et culturel",
   code_45212310:"Travaux de construction de bâtiments destinés aux expositions",
   code_45212311:"Travaux de construction de galeries d'art",
   code_45212312:"Travaux de construction de parcs des expositions",
   code_45212313:"Travaux de construction de musées",
   code_45212314:"Travaux de construction de monuments historiques ou de mémoriaux",
   code_45212320:"Travaux de construction de bâtiments destinés aux activités artistiques",
   code_45212321:"Travaux de construction d'auditoriums",
   code_45212322:"Travaux de construction de theâtres",
   code_45212330:"Travaux de construction de bibliothèques",
   code_45212331:"Travaux de construction de bibliothèques multimédia",
   code_45212340:"Travaux de construction de salle de conférences",
   code_45212350:"Bâtiments présentant un intérêt historique ou architectural particulier",
   code_45212351:"Travaux de construction de monuments préhistoriques",
   code_45212352:"Travaux de construction de monuments industriels",
   code_45212353:"Travaux de construction de palais",
   code_45212354:"Travaux de construction de châteaux",
   code_45212360:"Travaux de construction de bâtiments religieux",
   code_45212361:"Travaux de construction d'églises",
   code_45212400:"Hôtels et restaurants",
   code_45212410:"Travaux de construction de bâtiments destinés à l'hébergement",
   code_45212411:"Travaux de construction d'hôtels",
   code_45212412:"Travaux de construction de foyers",
   code_45212413:"Travaux de construction de logements pour séjours de courte durée",
   code_45212420:"Travaux de construction de restaurants et d'établissements similaires",
   code_45212421:"Travaux de construction de restaurants",
   code_45212422:"Travaux de construction de cantines",
   code_45212423:"Travaux de construction de cafétarias",
   code_45212500:"Transformation de cuisines ou de restaurants",
   code_45212600:"Travaux de construction de pavillons",
   code_45213000:"Travaux de construction de locaux commerciaux, d'entrepôts et de bâtiments industriels, de bâtiments liés aux transports",
   code_45213100:"Travaux de construction de locaux commerciaux",
   code_45213110:"Travaux de construction de magasins",
   code_45213111:"Travaux de construction de centres commerciaux",
   code_45213112:"Travaux de construction de boutiques",
   code_45213120:"Travaux de construction de bureaux de poste",
   code_45213130:"Travaux de construction de banques",
   code_45213140:"Travaux de construction de marchés",
   code_45213141:"Travaux de construction de marchés couverts",
   code_45213142:"Travaux de construction de marchés à ciel ouvert",
   code_45213150:"Travaux de construction d'immeubles de bureaux",
   code_45213200:"Travaux de construction d'entrepôts et de bâtiments industriels",
   code_45213210:"Entrepôts frigorifiques",
   code_45213220:"Travaux de construction d'entrepôts",
   code_45213221:"Travaux de construction de dépôts",
   code_45213230:"Travaux de construction d'abattoirs",
   code_45213240:"Travaux de construction de bâtiments agricoles",
   code_45213241:"Travaux de construction de granges",
   code_45213242:"Travaux de construction d'étables",
   code_45213250:"Travaux de construction de bâtiments industriels",
   code_45213251:"Travaux de construction d'unités industrielles",
   code_45213252:"Travaux de construction d'ateliers",
   code_45213260:"Travaux de construction d'entrepôts de marchandises",
   code_45213270:"Travaux de construction de stations de recyclage",
   code_45213280:"Travaux de construction d'installations de compostage",
   code_45213300:"Bâtiments liés aux activités de transport",
   code_45213310:"Travaux de construction de bâtiments liés aux transports routiers",
   code_45213311:"Travaux de construction de gares routières",
   code_45213312:"Travaux de construction de parkings à étages",
   code_45213313:"Travaux de construction de bâtiments d'aire de services",
   code_45213314:"Travaux de construction de dépôts d'autobus",
   code_45213315:"Travaux de construction d'abris-bus",
   code_45213316:"Travaux d'installation de passerelles",
   code_45213320:"Travaux de construction de bâtiments liés aux transports ferroviaires",
   code_45213321:"Travaux de construction de gares de chemin de fer",
   code_45213322:"Travaux de construction de terminaux ferroviaires",
   code_45213330:"Travaux de construction de bâtiments liés aux transports aériens",
   code_45213331:"Travaux de construction de bâtiments aéroportuaires",
   code_45213332:"Travaux de construction de tours de contrôle d'aéroport",
   code_45213333:"Travaux d' installation de comptoirs d'enregistrement d'aéroport",
   code_45213340:"Travaux de construction de bâtiments liés aux transports par voie d'eau",
   code_45213341:"Travaux de construction de terminaux de ferry",
   code_45213342:"Travaux de construction de terminaux Ro-Ro",
   code_45213350:"Travaux de construction de bâtiments liés à plusieurs modes de transport",
   code_45213351:"Travaux de construction de hangars d'entretien",
   code_45213352:"Travaux de construction de dépôts de service",
   code_45213353:"Travaux d'installation de passerelles pour passagers",
   code_45213400:"Installation de locaux pour le personnel",
   code_45214000:"Travaux de construction d'établissements d'enseignement et de centres de recherche",
   code_45214100:"Travaux de construction de jardins d'enfants",
   code_45214200:"Travaux de construction de bâtiments scolaires",
   code_45214210:"Travaux de construction d'écoles primaires",
   code_45214220:"Travaux de construction d'écoles secondaires",
   code_45214230:"Travaux de construction d'écoles spécialisées",
   code_45214300:"Construction d'établissements d'enseignement supérieur",
   code_45214310:"Travaux de construction de collèges professionnels",
   code_45214320:"Travaux de construction de collèges techniques",
   code_45214400:"Construction de bâtiments universitaires",
   code_45214410:"Travaux de construction d'instituts polytechniques",
   code_45214420:"Travaux de construction d'amphithéâtres",
   code_45214430:"Travaux de construction de laboratoires de langues",
   code_45214500:"Travaux de construction de centres de formation continue",
   code_45214600:"Construction de centres de recherche",
   code_45214610:"Travaux de construction de bâtiments de laboratoire",
   code_45214620:"Travaux de construction d'équipements de recherche et d'essai",
   code_45214630:"Installations scientifiques",
   code_45214631:"Travaux d'installation de salles blanches",
   code_45214640:"Travaux de construction de stations météorologiques",
   code_45214700:"Travaux de construction de cité universitaire",
   code_45214710:"Travaux de construction de halls d'entrée",
   code_45214800:"Construction d'équipements de formation",
   code_45215000:"Travaux de construction de bâtiments liés à la santé et aux services sociaux, de crématoriums et de toilettes publiques",
   code_45215100:"Travaux de construction de bâtiments liés à la santé",
   code_45215110:"Travaux de construction de spas",
   code_45215120:"Travaux de construction de bâtiments médicaux spécialisés",
   code_45215130:"Travaux de construction de cliniques",
   code_45215140:"Travaux de construction d'équipements hospitaliers",
   code_45215141:"Travaux de construction de salles d'opération",
   code_45215142:"Travaux de construction d'unités de soins intensifs",
   code_45215143:"Travaux de construction de salles de dépistage et de diagnostic",
   code_45215144:"Travaux de construction de salles de dépistage",
   code_45215145:"Travaux de construction de salles de radioscopie",
   code_45215146:"Travaux de construction de salles de pathologie",
   code_45215147:"Travaux de construction de salles de médecine légale",
   code_45215148:"Travaux de construction de salles de cathétérisme",
   code_45215200:"Construction de bâtiments pour les services sociaux",
   code_45215210:"Travaux de construction de foyers et de structures d'accueil à caractère social",
   code_45215212:"Travaux de construction de maisons de retraite",
   code_45215213:"Travaux de construction de centres de soins",
   code_45215214:"Travaux de construction de foyers résidentiels",
   code_45215215:"Travaux de construction de foyers pour enfants",
   code_45215220:"Travaux de construction d'établissements sociaux, excepté foyers et structures d'accueil à caractère social",
   code_45215221:"Travaux de construction de foyers de jour",
   code_45215222:"Travaux de construction de centres administratifs",
   code_45215300:"Travaux de construction de crématorium",
   code_45215400:"Cimetière",
   code_45215500:"Toilettes publiques",
   code_45216000:"Travaux de construction de bâtiments destinés aux institutions d'ordre public ou aux services de secours et de bâtiments militaires",
   code_45216100:"Travaux de construction de bâtiments destinés aux institutions d'ordre public ou aux services de secours",
   code_45216110:"Travaux de construction de bâtiments destinés aux institutions d'ordre public",
   code_45216111:"Travaux de construction de commissariats de police",
   code_45216112:"Travaux de construction de tribunaux",
   code_45216113:"Travaux de construction de prisons",
   code_45216114:"Édifices parlementaires et salles de réunion publiques",
   code_45216120:"Travaux de construction de bâtiments destinés aux services de secours",
   code_45216121:"Travaux de construction de centres de secours des sapeurs-pompiers",
   code_45216122:"Travaux de construction de centres ambulanciers",
   code_45216123:"Travaux de construction de postes de secours en montagne",
   code_45216124:"Travaux de construction de postes de secours en mer",
   code_45216125:"Travaux de construction de bâtiments des services de secours",
   code_45216126:"Travaux de construction de postes de garde-côtes",
   code_45216127:"Travaux de construction de postes de secours et de sauvetage",
   code_45216128:"Travaux de construction de phares",
   code_45216129:"Abris de protection",
   code_45216200:"Travaux de construction de bâtiments et d'installations militaires",
   code_45216220:"Travaux de construction de bunkers militaires",
   code_45216230:"Travaux de construction d'abris militaires",
   code_45216250:"Travaux de construction de défenses en tranchée",
   code_45217000:"Travaux de construction de bâtiments gonflables",
   code_45220000:"Ouvrages d'art et de génie civil",
   code_45221000:"Travaux de construction de ponts et de tunnels, de puits et de passages souterrains",
   code_45221100:"Travaux de construction de ponts",
   code_45221110:"Travaux de construction de ponts",
   code_45221111:"Travaux de construction de ponts routiers",
   code_45221112:"Travaux de construction de ponts de chemin de fer",
   code_45221113:"Travaux de construction de passerelles pour piétons",
   code_45221114:"Travaux de construction de ponts en fer",
   code_45221115:"Travaux de construction de ponts en acier",
   code_45221117:"Travaux de construction de ponts à bascule",
   code_45221118:"Travaux de construction de ponts pour conduites",
   code_45221119:"Travaux de rénovation de ponts",
   code_45221120:"Travaux de construction de viaducs",
   code_45221121:"Travaux de construction de viaducs routiers",
   code_45221122:"Travaux de construction de viaducs ferroviaires",
   code_45221200:"Travaux de construction de tunnels, de puits et de passages souterrains",
   code_45221210:"Excavations couvertes ou partiellement couvertes",
   code_45221211:"Passage inférieur",
   code_45221213:"Excavations ferroviaires couvertes ou partiellement couvertes",
   code_45221214:"Excavations routières couvertes ou partiellement couvertes",
   code_45221220:"Ponceaux",
   code_45221230:"Puits",
   code_45221240:"Travaux de construction de tunnels",
   code_45221241:"Travaux de construction de tunnels routiers",
   code_45221242:"Travaux de construction de tunnels ferroviaires",
   code_45221243:"Travaux de construction de tunnels pour piétons",
   code_45221244:"Travaux de construction de tunnels pour canal",
   code_45221245:"Travaux de construction de tunnels sous-fluviaux",
   code_45221246:"Travaux de construction de tunnels sous-marins",
   code_45221247:"Percement de tunnels",
   code_45221248:"Travaux de construction de revêtements de tunnels",
   code_45221250:"Ouvrages souterrains autres que tunnels, puits et passages souterrains",
   code_45222000:"Travaux de construction d'ouvrages de génie civil, excepté ponts, tunnels, puits et passages souterrains",
   code_45222100:"Travaux de construction d'usines de traitement des déchets",
   code_45222110:"Travaux de construction de décharges",
   code_45222200:"Travaux de génie militaire",
   code_45222300:"Travaux de génie pour installations de sécurité",
   code_45223000:"Travaux de construction d'ouvrages",
   code_45223100:"Assemblage de structures en métal",
   code_45223110:"Installation de structures en métal",
   code_45223200:"Ossatures",
   code_45223210:"Ossatures métalliques",
   code_45223220:"Travaux de gros œuvre",
   code_45223300:"Travaux de construction de parkings",
   code_45223310:"Travaux de construction de parkings souterrains",
   code_45223320:"Travaux de construction de parkings de dissuasion",
   code_45223400:"Travaux de construction de stations radar",
   code_45223500:"Structures en béton armé",
   code_45223600:"Travaux de construction de chenils",
   code_45223700:"Travaux de construction d'aires de service",
   code_45223710:"Travaux de construction d'aires de service d'autoroute",
   code_45223720:"Travaux de construction de stations-services",
   code_45223800:"Assemblage et montage de structures préfabriquées",
   code_45223810:"Constructions préfabriquées",
   code_45223820:"Éléments et composants préfabriqués",
   code_45223821:"Éléments préfabriqués",
   code_45223822:"Composants préfabriqués",
   code_45230000:"Travaux de construction de pipelines, de lignes de communication et d'énergie, d'autoroutes, de routes, d'aérodromes et de voies ferrées; travaux de nivelage",
   code_45231000:"Travaux de construction de pipelines, de lignes de communications et de lignes électriques",
   code_45231100:"Travaux généraux de construction pour pipelines",
   code_45231110:"Travaux de pose de conduites",
   code_45231111:"Travaux de démontage et de remplacement de pipelines",
   code_45231112:"Installation de réseau de conduites",
   code_45231113:"Travaux de remplacement de pipelines",
   code_45231200:"Travaux de construction d'oléoducs et de gazoducs",
   code_45231210:"Travaux de construction d'oléoducs",
   code_45231220:"Travaux de construction de gazoducs",
   code_45231221:"Travaux de construction de canalisations d'alimentation en gaz",
   code_45231222:"Gazomètres",
   code_45231223:"Ouvrages annexes pour la distribution de gaz",
   code_45231300:"Travaux de construction de conduites d'eau et de canalisations d'eaux usées à grande distance",
   code_45231400:"Travaux de construction de lignes électriques",
   code_45231500:"Travaux de canalisations d'air comprimé",
   code_45231510:"Travaux relatifs à des circuits pneumatiques de distribution de courrier",
   code_45231600:"Travaux de construction de lignes de communications",
   code_45232000:"Ouvrages annexes de pipelines et de câbles",
   code_45232100:"Ouvrages annexes pour canalisations d'eau",
   code_45232120:"Travaux d'irrigation",
   code_45232121:"Travaux de construction de canalisations d'irrigation",
   code_45232130:"Travaux de construction de canalisations d'eaux pluviales",
   code_45232140:"Travaux de construction de réseaux de chauffage urbain",
   code_45232141:"Installations de chauffage",
   code_45232142:"Travaux de construction de stations de transfert thermique",
   code_45232150:"Travaux relatifs aux conduites d'alimentation en eau",
   code_45232151:"Travaux de remise à neuf de conduites d'eau",
   code_45232152:"Travaux de construction de stations de pompage",
   code_45232153:"Travaux de construction de châteaux d'eau",
   code_45232154:"Travaux de construction de châteaux d'eau pour l'eau potable",
   code_45232200:"Ouvrages annexes pour lignes électriques",
   code_45232210:"Construction de lignes aériennes",
   code_45232220:"Travaux de construction de sous-stations",
   code_45232221:"Sous-station de transformation",
   code_45232300:"Travaux de construction de lignes téléphoniques et de lignes de communications et ouvrages annexes",
   code_45232310:"Travaux de construction de lignes téléphoniques",
   code_45232311:"Lignes téléphoniques pour bornes d'appel d'urgence",
   code_45232320:"Lignes de télédistribution par câble",
   code_45232330:"Montage d'antennes",
   code_45232331:"Ouvrages annexes de télédiffusion",
   code_45232332:"Ouvrages annexes de télécommunications",
   code_45232340:"Travaux de construction de stations de base de téléphonie mobile",
   code_45232400:"Travaux de construction d'égouts",
   code_45232410:"Travaux d'assainissement",
   code_45232411:"Travaux de construction de canalisations d'eaux usées",
   code_45232420:"Travaux de construction de stations d'épuration des eaux usées",
   code_45232421:"Station de traitement des eaux usées",
   code_45232422:"Station de traitement des boues",
   code_45232423:"Travaux de construction de stations de pompage des eaux usées",
   code_45232424:"Travaux de construction d'émissaires",
   code_45232430:"Travaux de traitement de l'eau",
   code_45232431:"Station de pompage des eaux résiduaires",
   code_45232440:"Travaux de construction de conduites d'eaux d'égouts",
   code_45232450:"Travaux de construction pour système de drainage",
   code_45232451:"Travaux de drainage et travaux de surface",
   code_45232452:"Ouvrages de drainage",
   code_45232453:"Travaux de construction de tuyaux de drainage",
   code_45232454:"Travaux de construction de bassins d'eaux pluviales",
   code_45232460:"Travaux d'installations sanitaires",
   code_45232470:"Station de transfert des déchets",
   code_45233000:"Travaux de construction, de fondation et de revêtement d'autoroutes, de routes",
   code_45233100:"Travaux de construction d'autoroutes, de routes",
   code_45233110:"Travaux de construction d'autoroutes",
   code_45233120:"Travaux de construction de routes",
   code_45233121:"Travaux de construction de routes principales",
   code_45233122:"Travaux de construction de rocades",
   code_45233123:"Travaux de construction de routes secondaires",
   code_45233124:"Travaux de construction d'artères de grande circulation",
   code_45233125:"Travaux de construction d'intersections de routes",
   code_45233126:"Travaux de construction de carrefours dénivelés",
   code_45233127:"Travaux de construction de carrefours en T",
   code_45233128:"Travaux de construction de carrefours giratoires",
   code_45233129:"Travaux de construction de routes transversales",
   code_45233130:"Travaux de construction de routes nationales",
   code_45233131:"Travaux de construction d'autoroutes aériennes",
   code_45233139:"Entretien des routes nationales",
   code_45233140:"Travaux routiers",
   code_45233141:"Travaux d'entretien routier",
   code_45233142:"Travaux de réparation de routes",
   code_45233144:"Travaux de construction de passages supérieurs",
   code_45233150:"Ouvrages de modération du trafic",
   code_45233160:"Chemins et autres aires empierrées",
   code_45233161:"Travaux de construction de chemins piétonniers",
   code_45233162:"Travaux de construction de pistes cyclables",
   code_45233200:"Travaux de revêtement divers",
   code_45233210:"Travaux de revêtement d'autoroutes",
   code_45233220:"Travaux de revêtement de routes",
   code_45233221:"Travaux de marquage routier",
   code_45233222:"Travaux de pavage et de resurfaçage de chaussées",
   code_45233223:"Travaux de resurfaçage de chaussées",
   code_45233224:"Travaux de construction de routes à deux chaussées séparées",
   code_45233225:"Travaux de construction de routes à chaussée unique",
   code_45233226:"Travaux de construction de routes d'accès",
   code_45233227:"Travaux de construction de bretelles",
   code_45233228:"Travaux de construction de revêtements de surface",
   code_45233229:"Entretien des accotements",
   code_45233250:"Travaux de revêtements, excepté revêtements de chaussées",
   code_45233251:"Travaux de resurfaçage",
   code_45233252:"Travaux de revêtement de rues",
   code_45233253:"Travaux de revêtement de chemins piétonniers",
   code_45233260:"Travaux de construction de voies piétonnes",
   code_45233261:"Travaux de construction de passages supérieurs pour piétons",
   code_45233262:"Travaux de construction de zones piétonnes",
   code_45233270:"Travaux de marquage d'emplacements de parking",
   code_45233280:"Pose de barrières routières",
   code_45233290:"Installation de panneaux de signalisation",
   code_45233291:"Installation de bollards",
   code_45233292:"Installation de dispositifs de sécurité",
   code_45233293:"Installation de mobilier urbain",
   code_45233294:"Installation de signalisation routière",
   code_45233300:"Travaux de fondation d'autoroutes, de routes, de rues et de chemins piétonniers",
   code_45233310:"Travaux de fondation d'autoroutes",
   code_45233320:"Travaux de fondation de routes",
   code_45233330:"Travaux de fondation de rues",
   code_45233340:"Travaux de fondation de chemins piétonniers",
   code_45234000:"Travaux de construction de voies ferrées et de systèmes de transport par câble",
   code_45234100:"Ouvrages ferroviaires",
   code_45234110:"Chemin de fer interurbain",
   code_45234111:"Travaux de construction de chemins de fer urbain",
   code_45234112:"Travaux de construction de dépôts ferroviaires",
   code_45234113:"Démolition de voies ferrées",
   code_45234114:"Travaux de construction de remblais de chemin de fer",
   code_45234115:"Travaux de signalisation ferroviaire",
   code_45234116:"Travaux de construction de voies",
   code_45234120:"Ouvrages ferroviaires urbains",
   code_45234121:"Ouvrages pour le tramway",
   code_45234122:"Ouvrages pour le métro",
   code_45234123:"Chemin de fer partiellement souterrain",
   code_45234124:"Transport ferroviaire souterrain de passagers",
   code_45234125:"Station de métro",
   code_45234126:"Travaux de construction de lignes de tramway",
   code_45234127:"Travaux de construction de dépôts de tramway",
   code_45234128:"Travaux de construction d'arrêts de tramway",
   code_45234129:"Travaux de construction de voies ferrées urbaines",
   code_45234130:"Travaux de construction de ballasts",
   code_45234140:"Travaux de construction des passages à niveau",
   code_45234160:"Travaux de construction de caténaires",
   code_45234170:"Travaux de construction de sous-stations électriques à usage ferroviaire",
   code_45234180:"Travaux de construction d'ateliers de chemin de fer",
   code_45234181:"Travaux de construction de postes de sectionnement",
   code_45234200:"Systèmes de transport par câble",
   code_45234210:"Systèmes de transport par câble avec cabines",
   code_45234220:"Travaux de construction de remonte-pente",
   code_45234230:"Travaux de construction de télésièges",
   code_45234240:"Funiculaire",
   code_45234250:"Travaux de construction de téléphériques",
   code_45235000:"Travaux de construction de terrains d'aviation, de pistes d'envol et d'atterrissage et d'aires de manœuvre",
   code_45235100:"Travaux de construction d'aéroports",
   code_45235110:"Travaux de construction de terrains d'aviation",
   code_45235111:"Travaux de revêtement de terrains d'aviation",
   code_45235200:"Travaux de construction de pistes d'envol et d'atterrissage",
   code_45235210:"Resurfaçage de pistes d'envol et d'atterrissage",
   code_45235300:"Travaux de construction d'aires de manœuvre pour avions",
   code_45235310:"Travaux de construction de pistes de roulement",
   code_45235311:"Travaux de revêtement de pistes de roulement",
   code_45235320:"Travaux de construction d'aires de stationnement pour avions",
   code_45236000:"Travaux de nivelage",
   code_45236100:"Travaux de nivelage pour équipements sportifs divers",
   code_45236110:"Travaux de nivelage de terrains de sports",
   code_45236111:"Travaux de nivelage de terrains de golf",
   code_45236112:"Travaux de nivelage de courts de tennis",
   code_45236113:"Travaux de nivelage de champs de courses",
   code_45236114:"Travaux de nivelage de pistes",
   code_45236119:"Travaux de réparation de terrains de sports",
   code_45236200:"Travaux de nivelage d'installations récréatives",
   code_45236210:"Travaux de nivelage d'aires de jeu pour enfants",
   code_45236220:"Travaux de nivelage de zoos",
   code_45236230:"Travaux de nivelage de jardins",
   code_45236250:"Travaux de nivelage de parcs",
   code_45236290:"Travaux de réparation d'espaces de loisir",
   code_45236300:"Travaux de nivelage pour cimetières",
   code_45237000:"Travaux de construction de scènes",
   code_45240000:"Travaux de construction d'ouvrages hydrauliques",
   code_45241000:"Travaux de construction de ports",
   code_45241100:"Travaux de construction de quais",
   code_45241200:"Travaux de construction in situ de terminaux offshore",
   code_45241300:"Travaux de construction de jetées",
   code_45241400:"Travaux de construction de docks",
   code_45241500:"Travaux de construction de môles",
   code_45241600:"Installation de matériel d'éclairage portuaire",
   code_45242000:"Travaux de construction d'installations de loisirs nautiques",
   code_45242100:"Travaux de construction d'installations de sports nautiques",
   code_45242110:"Travaux de construction de voies de mise à l'eau",
   code_45242200:"Travaux de construction de marinas",
   code_45242210:"Travaux de construction de ports de plaisance",
   code_45243000:"Ouvrages de défense des côtes",
   code_45243100:"Ouvrages de protection des falaises",
   code_45243110:"Travaux de stabilisation des falaises",
   code_45243200:"Travaux de construction de brise-lames",
   code_45243300:"Travaux de construction de digues de mer",
   code_45243400:"Travaux de consolidation des plages",
   code_45243500:"Travaux de construction d'ouvrages de défense maritimes",
   code_45243510:"Travaux d'endiguement",
   code_45243600:"Travaux de construction de murs de quai",
   code_45244000:"Ouvrages maritimes",
   code_45244100:"Installations maritimes",
   code_45244200:"Jetées",
   code_45245000:"Ouvrages de dragage et de pompage pour installations de traitement des eaux",
   code_45246000:"Ouvrages de régularisation des cours d'eau et de maîtrise des crues",
   code_45246100:"Construction de murs de rive",
   code_45246200:"Travaux de protection des berges",
   code_45246400:"Ouvrages de protection contre les inondations",
   code_45246410:"Entretien de digues",
   code_45246500:"Travaux de construction de promenades",
   code_45246510:"Travaux de construction de chemins de planches",
   code_45247000:"Travaux de construction de barrages, de canaux, de canaux d'irrigation et d'aqueducs",
   code_45247100:"Travaux de construction de voies navigables",
   code_45247110:"Construction de canaux",
   code_45247111:"Travaux de construction de canaux d'irrigation",
   code_45247112:"Travaux de construction de canaux de drainage",
   code_45247120:"Voies navigables, excepté canaux",
   code_45247130:"Travaux de construction d'aqueducs",
   code_45247200:"Travaux de construction de barrages et d'ouvrages fixes similaires",
   code_45247210:"Travaux de construction de barrages",
   code_45247211:"Travaux de construction de murs de barrage",
   code_45247212:"Travaux de renforcement de barrage",
   code_45247220:"Travaux de construction de déversoirs",
   code_45247230:"Travaux de construction de digues",
   code_45247240:"Travaux de construction de barrages statiques",
   code_45247270:"Travaux de construction de réservoirs",
   code_45248000:"Travaux de construction d'ouvrages hydromécaniques",
   code_45248100:"Travaux de construction d'écluses de canal",
   code_45248200:"Travaux de construction de cales sèches",
   code_45248300:"Travaux de construction de docks flottants",
   code_45248400:"Travaux de construction d'appontements",
   code_45248500:"Travaux de construction de barrages mobiles",
   code_45250000:"Travaux de construction d'usines, d'exploitations minières et d'unités de fabrication et de bâtiments pour l'industrie du pétrole et du gaz",
   code_45251000:"Travaux de construction de centrales électriques et de centrales thermiques",
   code_45251100:"Travaux de construction de centrales électriques",
   code_45251110:"Travaux de construction de centrales nucléaires",
   code_45251111:"Travaux de construction d'un réacteur nucléaire",
   code_45251120:"Travaux de construction de centrales hydroélectriques",
   code_45251140:"Travaux de construction de centrales thermiques",
   code_45251141:"Travaux de construction de centrales géothermiques",
   code_45251142:"Travaux de construction de centrales alimentées au bois",
   code_45251143:"Travaux de construction d'unités de production d'air comprimé",
   code_45251150:"Travaux de construction de tours de refroidissement",
   code_45251160:"Installations à énergie éolienne",
   code_45251200:"Travaux de construction de centrales de chauffage",
   code_45251220:"Travaux de construction de centrales de cogénération",
   code_45251230:"Travaux de construction d'usines génératrices de vapeur",
   code_45251240:"Travaux de construction de centrales de production d'électricité alimentées au gaz de décharge",
   code_45251250:"Travaux de construction de centrales de chauffage urbain",
   code_45252000:"Travaux de construction de stations de traitement des eaux usées, de stations d'épuration et d'usines d'incinération des ordures ménagères",
   code_45252100:"Travaux de construction de stations d'épuration",
   code_45252110:"Travaux de construction de centrales mobiles",
   code_45252120:"Travaux de construction d'installations de traitement des eaux",
   code_45252121:"Installations de sédimentation",
   code_45252122:"Digesteurs de boues",
   code_45252123:"Installations de criblage",
   code_45252124:"Travaux de dragage et de pompage",
   code_45252125:"Travaux d'enrochement",
   code_45252126:"Travaux de construction de stations de traitement de l'eau potable",
   code_45252127:"Travaux de construction de stations de traitement des eaux usées",
   code_45252130:"Équipement de station d'épuration",
   code_45252140:"Travaux de construction d'installations de déshydratation des boues",
   code_45252150:"Travaux de construction d'installations de manutention du charbon",
   code_45252200:"Matériel pour installation de purification",
   code_45252210:"Travaux de construction de stations de purification de l'eau",
   code_45252300:"Travaux de construction d'usines d'incinération des ordures ménagères",
   code_45253000:"Travaux de construction d'usines de traitement chimique",
   code_45253100:"Travaux de construction d'installations de déminéralisation",
   code_45253200:"Travaux de construction d'installations de désulfuration",
   code_45253300:"Travaux de construction d'installations de distillation ou de rectification",
   code_45253310:"Travaux de construction d'installations de distillation d'eau",
   code_45253320:"Travaux de construction d'installations de distillation d'alcool",
   code_45253400:"Travaux de construction d'usines pétrochimiques",
   code_45253500:"Travaux de construction d'usines pharmaceutiques",
   code_45253600:"Travaux de construction d'installations de déionisation",
   code_45253700:"Travaux de construction d'installations de digestion",
   code_45253800:"Travaux de construction d'usines de compostage",
   code_45254000:"Travaux de construction d'exploitations minières et d'unités de fabrication",
   code_45254100:"Travaux de construction d'exploitations minières",
   code_45254110:"Travaux de construction de bâtiments de puits",
   code_45254200:"Travaux de construction d'usines de production",
   code_45255000:"Travaux de construction pour l'industrie du pétrole et du gaz",
   code_45255100:"Travaux de construction de Plates-formes de production",
   code_45255110:"Travaux de construction de puits d'extraction",
   code_45255120:"Travaux de construction d'installations pour plate-formes",
   code_45255121:"Travaux de construction d'installations de surface",
   code_45255200:"Travaux de construction de raffineries de pétrole",
   code_45255210:"Travaux de construction de terminaux pétroliers",
   code_45255300:"Travaux de construction de terminaux à gaz",
   code_45255400:"Travaux de montage",
   code_45255410:"Travaux de montage offshore",
   code_45255420:"Travaux de montage à terre",
   code_45255430:"Démolition de Plates-formes pétrolières",
   code_45255500:"Travaux de forage et d'exploration",
   code_45255600:"Intervention au tube enroulé sur puits sous pression",
   code_45255700:"Travaux de construction d'usines de gazéificaton du charbon",
   code_45255800:"Travaux de construction d'unités de production de gaz",
   code_45259000:"Réparation et entretien d'équipements",
   code_45259100:"Réparation et entretien de station d'épuration",
   code_45259200:"Réparation et entretien de station de purification",
   code_45259300:"Réparation et entretien de centrales thermiques",
   code_45259900:"Modernisation d'installations",
   code_45260000:"Travaux de couverture et travaux d'autres corps de métier spécialisés",
   code_45261000:"Travaux de charpente et de couverture et travaux connexes",
   code_45261100:"Travaux de charpente",
   code_45261200:"Travaux de couverture et de peinture de toiture",
   code_45261210:"Travaux de couverture",
   code_45261211:"Travaux de couverture en tuiles",
   code_45261212:"Travaux de couverture en ardoises",
   code_45261213:"Travaux de couverture métallique",
   code_45261214:"Travaux de couverture en bardeaux bitumés",
   code_45261215:"Travaux de couverture de panneaux solaires",
   code_45261220:"Travaux de peinture de couverture et autres travaux d'application d'enduits",
   code_45261221:"Travaux de peinture de toiture",
   code_45261222:"Travaux de pose d'enduit sur couverture en ciment",
   code_45261300:"Travaux de garnissage et de pose de gouttières",
   code_45261310:"Travaux de garnissage",
   code_45261320:"Travaux de pose de gouttières",
   code_45261400:"Travaux de revêtement",
   code_45261410:"Travaux d'isolation de toiture",
   code_45261420:"Travaux d'étanchéification",
   code_45261900:"Réparation et entretien de toiture",
   code_45261910:"Réparation de toiture",
   code_45261920:"Travaux d'entretien de toiture",
   code_45262000:"Travaux de corps de métier spécialisés autres que travaux de couverture",
   code_45262100:"Travaux d'installation d'échafaudages",
   code_45262110:"Travaux de démontage d'échafaudages",
   code_45262120:"Travaux de montage d'échafaudages",
   code_45262200:"Travaux de fondation et forage de puits de captage",
   code_45262210:"Travaux de fondation",
   code_45262211:"Battage de pieux",
   code_45262212:"Travaux de coffrage",
   code_45262213:"Parois moulées en tranchée",
   code_45262220:"Forage de puits de captage",
   code_45262300:"Travaux de bétonnage",
   code_45262310:"Travaux de mise en œuvre de béton armé",
   code_45262311:"Travaux de gros œuvre en béton",
   code_45262320:"Ragréages",
   code_45262321:"Travaux de chape",
   code_45262330:"Travaux de réparation d'ouvrages en béton",
   code_45262340:"Travaux d'injection de ciment",
   code_45262350:"Travaux de mise en œuvre de béton non armé",
   code_45262360:"Travaux de cimentation",
   code_45262370:"Travaux d'enrobage de béton",
   code_45262400:"Travaux d'assemblage d'ossatures métalliques",
   code_45262410:"Travaux d'assemblage d'ossatures métalliques de bâtiment",
   code_45262420:"Travaux d'assemblage d'ossatures métalliques d'ouvrage",
   code_45262421:"Installation de dispositifs d'amarrage en mer",
   code_45262422:"Travaux de forage sous-marin",
   code_45262423:"Travaux de fabrication de ponts",
   code_45262424:"Travaux de fabrication de modules offshore",
   code_45262425:"Travaux de fabrication de treillis de plate-forme",
   code_45262426:"Travaux de fabrication de piles",
   code_45262500:"Travaux de maçonnerie et de briquetage",
   code_45262510:"Maçonnerie de pierres",
   code_45262511:"Taille de pierres",
   code_45262512:"Maçonnerie de pierres de taille",
   code_45262520:"Travaux de briquetage",
   code_45262521:"Maçonnerie de parement",
   code_45262522:"Travaux de maçonnerie",
   code_45262600:"Travaux de construction spécialisés divers",
   code_45262610:"Cheminées industrielles",
   code_45262620:"Murs de soutènement",
   code_45262630:"Construction de fourneaux",
   code_45262640:"Travaux d'amélioration de l'environnement",
   code_45262650:"Travaux de bardage",
   code_45262660:"Travaux de désamiantage",
   code_45262670:"Transformation des métaux",
   code_45262680:"Soudage",
   code_45262690:"Remise en état de bâtiments dégradés",
   code_45262700:"Travaux de transformation de bâtiments",
   code_45262710:"Travaux d'entretien de fresques",
   code_45262800:"Travaux d'extension de bâtiments",
   code_45262900:"Travaux de balcon",
   code_45300000:"Travaux d'équipement du bâtiment",
   code_45310000:"Travaux d'équipement électrique",
   code_45311000:"Travaux de câblage et d'installations électriques",
   code_45311100:"Travaux de câblage électrique",
   code_45311200:"Travaux d'installations électriques",
   code_45312000:"Travaux d'installation de systèmes d'alarme et d'antennes",
   code_45312100:"Travaux d'installation de systèmes avertisseurs d'incendie",
   code_45312200:"Travaux d'installation de systèmes avertisseurs d'effraction",
   code_45312300:"Travaux d'installation d'antennes",
   code_45312310:"Installations de paratonnerre",
   code_45312311:"Travaux d'installation de paratonnerre",
   code_45312320:"Travaux d'installation d'antennes de télévision",
   code_45312330:"Travaux d'installation d'antennes radio",
   code_45313000:"Travaux d'installation d'ascenseurs et d'escaliers mécaniques",
   code_45313100:"Travaux d'installation d'ascenseurs",
   code_45313200:"Travaux d'installation d'escaliers mécaniques",
   code_45313210:"Travaux d'installation de trottoirs roulants",
   code_45314000:"Installation de matériel de télécommunications",
   code_45314100:"Installation de centraux téléphoniques",
   code_45314120:"Installation de standards téléphoniques",
   code_45314200:"Installation de lignes téléphoniques",
   code_45314300:"Installation d'infrastructures de câblage",
   code_45314310:"Installation de câblage",
   code_45314320:"Installation de câblage informatique",
   code_45315000:"Travaux d'installation de chauffage électrique et d'autres équipements électriques de bâtiment",
   code_45315100:"Installations électrotechniques",
   code_45315200:"Travaux relatifs aux turbines",
   code_45315300:"Installations d'alimentation électrique",
   code_45315400:"Installations haute tension",
   code_45315500:"Installations moyenne tension",
   code_45315600:"Installations basse tension",
   code_45315700:"Travaux d'installation de postes de sectionnement",
   code_45316000:"Travaux d'installation de systèmes d'illumination et de signalisation",
   code_45316100:"Installation d'appareils d'éclairage extérieur",
   code_45316110:"Installation de matériel d'éclairage public",
   code_45316200:"Installation de matériel de signalisation",
   code_45316210:"Installation de matériel de contrôle du trafic routier",
   code_45316211:"Installation de panneaux lumineux de signalisation",
   code_45316212:"Installation de feux de circulation",
   code_45316213:"Installation de balisage",
   code_45316220:"Installation de matériel de signalisation aéroportuaire",
   code_45316230:"Installation de matériel de signalisation portuaire",
   code_45317000:"Autres travaux d'installation électrique",
   code_45317100:"Travaux d'installation électrique de matériel de pompage",
   code_45317200:"Travaux d'installation électrique de transformateurs",
   code_45317300:"Travaux d'installation électrique d'appareils de distribution",
   code_45317400:"Travaux d'installation électrique de matériel de filtration",
   code_45320000:"Travaux d'isolation",
   code_45321000:"Travaux d'isolation thermique",
   code_45323000:"Travaux d'isolation acoustique",
   code_45324000:"Travaux de pose de plaques de plâtre",
   code_45330000:"Travaux de plomberie",
   code_45331000:"Travaux d'installation de matériel de chauffage, de ventilation et de climatisation",
   code_45331100:"Travaux d'installation de chauffage central",
   code_45331110:"Travaux d'installation de chaudières",
   code_45331200:"Travaux d'installation de matériel de ventilation et de climatisation",
   code_45331210:"Travaux d'installation de ventilation",
   code_45331211:"Travaux d'installation de ventilation extérieure",
   code_45331220:"Travaux d'installation de matériel de climatisation",
   code_45331221:"Travaux d'installation de matériel de climatisation partielle de l'air",
   code_45331230:"Travaux d'installation de matériel de refroidissement",
   code_45331231:"Travaux d'installation de matériel de réfrigération",
   code_45332000:"Travaux de plomberie et de pose de conduits d'évacuation",
   code_45332200:"Travaux d'installation de distribution d'eau",
   code_45332300:"Travaux de pose de conduits d'évacuation",
   code_45332400:"Travaux d'installation d'appareils sanitaires",
   code_45333000:"Travaux d'installation du gaz",
   code_45333100:"Travaux d'installation de régulateurs de gaz",
   code_45333200:"Travaux d'installation de compteurs à gaz",
   code_45340000:"Travaux d'installation de clôtures, de garde-corps et de dispositifs de sécurité",
   code_45341000:"Pose de garde-corps",
   code_45342000:"Pose de clôtures",
   code_45343000:"Travaux d'installation de dispositifs de prévention contre les incendies",
   code_45343100:"Travaux d'ignifugation",
   code_45343200:"Travaux d'installation de dispositifs d'extinction d'incendie",
   code_45343210:"Travaux d'installation de dispositifs d'extinction d'incendie au CO2",
   code_45343220:"Travaux d'installation d'extincteurs",
   code_45343230:"Travaux d'installation de systèmes de pulvérisation d'eau",
   code_45350000:"Installations mécaniques",
   code_45351000:"Travaux d'installation en construction mécanique",
   code_45400000:"Travaux de parachèvement de bâtiment",
   code_45410000:"Travaux de plâtrerie",
   code_45420000:"Travaux de menuiserie et de charpenterie",
   code_45421000:"Travaux de menuiserie",
   code_45421100:"Pose de portes et de fenêtres et d'éléments accessoires",
   code_45421110:"Pose d'encadrements de portes et de fenêtres",
   code_45421111:"Pose d'encadrements de portes",
   code_45421112:"Pose d'encadrements de fenêtres",
   code_45421120:"Pose de seuils",
   code_45421130:"Poses de portes et de fenêtres",
   code_45421131:"Pose de portes",
   code_45421132:"Pose de fenêtres",
   code_45421140:"Pose de menuiseries métalliques, excepté portes et fenêtres",
   code_45421141:"Travaux de cloisonnement",
   code_45421142:"Installation de volets",
   code_45421143:"Travaux d'installation de stores",
   code_45421144:"Travaux d'installation de vélums",
   code_45421145:"Travaux d'installation de volets roulants",
   code_45421146:"Mise en place de plafonds suspendus",
   code_45421147:"Installation de grilles",
   code_45421148:"Installation de portails",
   code_45421150:"Travaux d'installation de menuiseries non métalliques",
   code_45421151:"Installation de cuisines équipées",
   code_45421152:"Installation de cloisons",
   code_45421153:"Installation de meubles encastrés",
   code_45421160:"Ferrage",
   code_45422000:"Travaux de charpenterie",
   code_45422100:"Ouvrages en bois",
   code_45430000:"Revêtement de sols et de murs",
   code_45431000:"Carrelages",
   code_45431100:"Carrelage de sols",
   code_45431200:"Carrelage mural",
   code_45432000:"Travaux de pose de revêtement de sols et de murs et pose de papiers peints",
   code_45432100:"Travaux de pose de revêtements de sols",
   code_45432110:"Travaux de pose de sols",
   code_45432111:"Travaux de pose de revêtements de sols souples",
   code_45432112:"Pose de pavage",
   code_45432113:"Pose de parquets",
   code_45432114:"Pavage en bois",
   code_45432120:"Travaux d'installation de faux plancher",
   code_45432121:"Planchers techniques pour salles informatiques",
   code_45432130:"Travaux de revêtements de sols",
   code_45432200:"Travaux de pose de revêtements muraux et de papiers peints",
   code_45432210:"Travaux de revêtement mural",
   code_45432220:"Travaux de pose de papier peint",
   code_45440000:"Travaux de peinture et de vitrerie",
   code_45441000:"Travaux de vitrerie",
   code_45442000:"Travaux d'application de revêtements de protection",
   code_45442100:"Travaux de peinture",
   code_45442110:"Travaux de peinture de bâtiments",
   code_45442120:"Travaux de peinture et de revêtement de protection de structures",
   code_45442121:"Travaux de peinture de structures",
   code_45442180:"Travaux de pose d'une nouvelle couche de peinture",
   code_45442190:"Travaux de décapage",
   code_45442200:"Travaux d'application de revêtements anticorrosion",
   code_45442210:"Travaux de galvanisation",
   code_45442300:"Travaux de protection des surfaces",
   code_45443000:"Travaux de façade",
   code_45450000:"Autres travaux de parachèvement de bâtiment",
   code_45451000:"Travaux de décoration",
   code_45451100:"Travaux d'ornementation",
   code_45451200:"Travaux de lambrissage",
   code_45451300:"Jardins intérieurs",
   code_45452000:"Travaux de nettoyage extérieur de bâtiments",
   code_45452100:"Nettoyage, au sable, des parties extérieures des bâtiments",
   code_45453000:"Travaux de remise en état et de remise à neuf",
   code_45453100:"Travaux de remise en état",
   code_45454000:"Travaux de restructuration",
   code_45454100:"Travaux de réfection",
   code_45500000:"Location de machines et de matériel de construction et de génie civil avec opérateur",
   code_45510000:"Location de grues avec opérateur",
   code_45520000:"Location de matériel de terrassement avec opérateur",
   code_48000000:"Logiciels et systèmes d'information",
   code_48100000:"Logiciels pour l'industrie",
   code_48110000:"Logiciels de points de vente (POS)",
   code_48120000:"Logiciel de commande vol",
   code_48121000:"Logiciels de contrôle du trafic aérien",
   code_48130000:"Logiciels de soutien au sol et de test pour l'aviation",
   code_48131000:"Logiciels de support au sol pour l'aviation",
   code_48132000:"Logiciels de test pour l'aviation",
   code_48140000:"Logiciels de contrôle du trafic ferroviaire",
   code_48150000:"Logiciels de contrôle industriel",
   code_48151000:"Système de contrôle informatique",
   code_48160000:"Logiciels de bibliothèque",
   code_48161000:"Système de gestion de bibliothèque",
   code_48170000:"Logiciels de vérification de conformité",
   code_48180000:"Logiciels médicaux",
   code_48190000:"Logiciels pédagogiques",
   code_48200000:"Logiciels de réseau d'internet et d'intranet",
   code_48210000:"Logiciels de gestion de réseau",
   code_48211000:"Logiciels d'interconnectivité de plates-formes",
   code_48212000:"Logiciels de serveur mémoire à disques",
   code_48213000:"Logiciels d'amélioration du système d'exploitation",
   code_48214000:"Logiciels de système d'exploitation de réseau",
   code_48215000:"Logiciels pour développeurs réseau",
   code_48216000:"Logiciels d'émulation de terminaux pour connectivité au réseau",
   code_48217000:"Logiciels de traitement des transactions",
   code_48217100:"Logiciels de traitement des transactions d'ordinateur central",
   code_48217200:"Logiciels de traitement des transaction de mini-ordinateur",
   code_48217300:"Logiciels de traitement des transactions de micro-ordinateur",
   code_48218000:"Logiciels de gestion des licences",
   code_48219000:"Logiciels réseau divers",
   code_48219100:"Logiciel de passerelle",
   code_48219200:"Logiciels de serveur de disques compacts (CD)",
   code_48219300:"Logiciels d'administration",
   code_48219400:"Logiciels de serveur de transactions",
   code_48219500:"Logiciels de commutation ou de routage",
   code_48219600:"Logiciels de multiplexeur",
   code_48219700:"Logiciels de serveur de communications",
   code_48219800:"Logiciels de passerelle",
   code_48220000:"Logiciels pour internet et intranet",
   code_48221000:"Logiciels de navigation sur l'internet",
   code_48222000:"Logiciels de serveur web",
   code_48223000:"Logiciels de courrier électronique",
   code_48224000:"Logiciels d'édition de pages web",
   code_48300000:"Logiciels de création de documents, de dessin, de synthèse d'images, de planification et de productivité",
   code_48310000:"Logiciels de création de documents",
   code_48311000:"Logiciel de gestion des documents",
   code_48311100:"Système de gestion de documents",
   code_48312000:"Electronic publishing software package: logiciels de PAO",
   code_48313000:"Logiciels de reconnaissance optique de caractères (OCR)",
   code_48313100:"Système de lecture optique",
   code_48314000:"Logiciels de reconnaissance vocale",
   code_48315000:"Logiciels de microédition",
   code_48316000:"Logiciels de présentation",
   code_48317000:"Logiciels de traitement de texte",
   code_48318000:"Logiciels de scanner",
   code_48319000:"Vérificateurs orthographiques",
   code_48320000:"Logiciels de dessin et de synthèse d'images",
   code_48321000:"Logiciels de CAO",
   code_48321100:"Système de CAO",
   code_48322000:"Logiciels graphiques",
   code_48323000:"Computer aided manufacturing (CAM) software package: logiciels de PAO",
   code_48324000:"Logiciels de tracé de diagrammes",
   code_48325000:"Logiciels d'élaboration de formulaires",
   code_48326000:"Logiciel de cartographie",
   code_48326100:"Système de cartographie numérique",
   code_48327000:"Logiciels de dessin et de peinture",
   code_48328000:"Logiciels de traitement de l'image",
   code_48329000:"Système d'imagerie et d'archivage",
   code_48330000:"Logiciels de planification et de productivité",
   code_48331000:"Logiciels de gestion de projets",
   code_48332000:"Logiciels de planification",
   code_48333000:"Logiciels de gestion des contacts",
   code_48400000:"Logiciels de transactions commerciales et de transactions personnelles",
   code_48410000:"Logiciels de gestion des investissements et d'établissement des déclarations fiscales",
   code_48411000:"Logiciels de gestion des investissements",
   code_48412000:"Logiciels d'établissement des déclarations fiscales",
   code_48420000:"Logiciels de gestion des installations et suites logicielles",
   code_48421000:"Logiciels de gestion des installations",
   code_48422000:"Suites logicielles",
   code_48430000:"Logiciels de gestion des stocks",
   code_48440000:"Logiciels d'analyse financière et logiciels comptables",
   code_48441000:"Logiciels d'analyse financière",
   code_48442000:"Logiciels pour systèmes financiers",
   code_48443000:"Logiciels comptables",
   code_48444000:"Système comptable",
   code_48444100:"Système de facturation",
   code_48445000:"Logiciels de gestion de la relation clientèle",
   code_48450000:"Logiciels de gestion des temps de travail et des ressources humaines",
   code_48451000:"Logiciels de planification des ressources de l'entreprise",
   code_48460000:"Logiciels analytiques, scientifiques, mathématiques ou prévisionnels",
   code_48461000:"Logiciels analytiques ou scientifiques",
   code_48462000:"Logiciels mathématiques ou prévisionnels",
   code_48463000:"Logiciels statistiques",
   code_48470000:"Logiciels de vente aux enchères",
   code_48480000:"Logiciels de vente, de marketing et de veille économique",
   code_48481000:"Logiciels de vente ou de marketing",
   code_48482000:"Logiciels de veille économique",
   code_48490000:"Logiciels de passation de marchés",
   code_48500000:"Logiciels de communications et multimédias",
   code_48510000:"Logiciels de communications",
   code_48511000:"Logiciels de communication par ordinateur",
   code_48512000:"Logiciels de réponse vocale interactive",
   code_48513000:"Logiciels de modem",
   code_48514000:"Logiciel de télécommande",
   code_48515000:"Logiciels de vidéoconférence",
   code_48516000:"Logiciels d'échange",
   code_48517000:"Logiciels de TI",
   code_48518000:"Logiciels d'émulation",
   code_48519000:"Logiciels de gestion de mémoire",
   code_48520000:"Logiciels multimédias",
   code_48521000:"Logiciel musical ou d'édition sonore",
   code_48522000:"Logiciels de clavier virtuel",
   code_48600000:"Logiciels de bases de données et d'exploitation",
   code_48610000:"Systèmes de bases de données",
   code_48611000:"Logiciels de bases de données",
   code_48612000:"Système de gestion de bases de données",
   code_48613000:"Gestion électronique de données (GED)",
   code_48614000:"Système de saisie de données",
   code_48620000:"Systèmes d'exploitation",
   code_48621000:"Logiciels de système d'exploitation d'unité centrale",
   code_48622000:"Logiciels de système d'exploitation de mini-ordinateur",
   code_48623000:"Logiciels de système d'exploitation de micro-ordinateur",
   code_48624000:"Logiciels de système d'exploitation d'ordinateur personnel",
   code_48625000:"Systèmes d'exploitation de systèmes ouverts",
   code_48626000:"Logiciels de groupage",
   code_48627000:"Logiciels de système d'exploitation en temps réel",
   code_48628000:"Architecture microcanal",
   code_48700000:"Logiciels utilitaires",
   code_48710000:"Logiciels de copie de sécurité ou de récupération",
   code_48720000:"Logiciels de codes-barres",
   code_48730000:"Logiciels de sécurité",
   code_48731000:"Logiciels de sécurité des fichiers",
   code_48732000:"Logiciels de sécurité des données",
   code_48740000:"Logiciels de traduction de langues étrangères",
   code_48750000:"Logiciels de chargement des supports d'enregistrement",
   code_48760000:"Logiciels de protection antivirus",
   code_48761000:"Logiciels antivirus",
   code_48770000:"Utilitaires généraux, utilitaires de compression et utilitaires d'impression",
   code_48771000:"Utilitaires généraux",
   code_48772000:"Utilitaires de compression",
   code_48773000:"Utilitaires d'impression",
   code_48773100:"Logiciels d'impression différée",
   code_48780000:"Logiciels de gestion de système, de stockage et de gestion de contenu",
   code_48781000:"Logiciels de gestion de systèmes",
   code_48782000:"Logiciels de gestion de stockage",
   code_48783000:"Logiciels de gestion de contenu",
   code_48790000:"Logiciels de vérification des versions",
   code_48800000:"Systèmes d'information et serveurs",
   code_48810000:"Systèmes d'information",
   code_48811000:"Système de courrier électronique",
   code_48812000:"Systèmes d'information dans le domaine financier",
   code_48813000:"Système d'information des passagers",
   code_48813100:"Tableaux électroniques",
   code_48813200:"Système d'information des passagers en temps réel",
   code_48814000:"Systèmes d'information dans le domaine médical",
   code_48814100:"Système d'information dans le domaine de l'infirmerie",
   code_48814200:"Système d'administration relatif aux patients",
   code_48814300:"Système de gestion de salles d'opération",
   code_48814400:"Système d'information clinique",
   code_48814500:"Système case-mix",
   code_48820000:"Serveurs",
   code_48821000:"Serveurs de réseau",
   code_48822000:"Serveurs informatiques",
   code_48823000:"Serveurs de fichiers",
   code_48824000:"Serveurs d'imprimantes",
   code_48825000:"Serveurs web",
   code_48900000:"Logiciels et systèmes informatiques divers",
   code_48910000:"Logiciels de jeux vidéo, de généalogie et économiseurs d'écran",
   code_48911000:"Logiciels de jeux vidéo",
   code_48912000:"Jeux pour la famille",
   code_48913000:"Économiseurs d'écran",
   code_48920000:"Logiciels de bureautique",
   code_48921000:"Système d'automatisation",
   code_48930000:"Logiciels de formation et de divertissement",
   code_48931000:"Logiciels de formation",
   code_48932000:"Logiciels de divertissement",
   code_48940000:"Logiciels de création de patrons et de calendriers",
   code_48941000:"Logiciels de création de patrons",
   code_48942000:"Logiciels de création de calendriers",
   code_48950000:"Systèmes de localisation de bateaux et systèmes de sonorisation",
   code_48951000:"Système de localisation de bateaux",
   code_48952000:"Systèmes de sonorisation",
   code_48960000:"Pilotes et logiciels de système",
   code_48961000:"Pilotes éthernet",
   code_48962000:"Pilotes de cartes graphiques",
   code_48970000:"Logiciels d'imprimerie",
   code_48971000:"Logiciels de création de carnets d'adresses",
   code_48972000:"Logiciels d'élaboration d'étiquettes",
   code_48980000:"Langages de programmation et outils",
   code_48981000:"Logiciels de compilation",
   code_48982000:"Logiciels de gestion de la configuration",
   code_48983000:"Logiciels de développement",
   code_48984000:"Outils d'interface utilisateur graphique (GUI)",
   code_48985000:"Langages de programmation",
   code_48986000:"Logiciels d'essai de programmes",
   code_48987000:"Logiciels de débogage",
   code_48990000:"Logiciels de tableurs et logiciels de mise à jour",
   code_48991000:"Logiciels de tableurs",
   code_50000000:"Services de réparation et d'entretien",
   code_50100000:"Services de réparation et d'entretien de véhicules et d'équipements associés et services connexes",
   code_50110000:"Services de réparation et d'entretien de véhicules à moteur et d'équipements connexes",
   code_50111000:"Services de gestion, de réparation et d'entretien de parc de véhicules",
   code_50111100:"Services de gestion de parc de véhicules",
   code_50111110:"Services d'assistance pour parc de véhicules",
   code_50112000:"Services de réparation et d'entretien de voitures",
   code_50112100:"Services de réparation de voitures",
   code_50112110:"Services de réparation de carrosseries de véhicules",
   code_50112111:"Services de réparation de carrosserie",
   code_50112120:"Services de remplacement de pare-brise",
   code_50112200:"Services d'entretien de voitures",
   code_50112300:"Services de lavage de voitures et services similaires",
   code_50113000:"Services de réparation et d'entretien d'autobus",
   code_50113100:"Services de réparation d'autobus",
   code_50113200:"Services d'entretien d'autobus",
   code_50114000:"Services de réparation et d'entretien de camions",
   code_50114100:"Services de réparation de camions",
   code_50114200:"Services d'entretien de camions",
   code_50115000:"Services de réparation et d'entretien de motos",
   code_50115100:"Services de réparation de motos",
   code_50115200:"Services d'entretien de motos",
   code_50116000:"Services de réparation et d'entretien de pièces spéciales de véhicules",
   code_50116100:"Services de réparation de systèmes électriques",
   code_50116200:"Services de réparation et d'entretien de freins et de pièces de freins de véhicules",
   code_50116300:"Services de réparation et d'entretien de boîtes de vitesses de véhicules",
   code_50116400:"Services de réparation et d'entretien de transmissions de véhicules",
   code_50116500:"Services de réparation de pneus, y compris montage et équilibrage",
   code_50116510:"Services de rechapage de pneus",
   code_50116600:"Services de réparation et d'entretien de moteurs de démarrage",
   code_50117000:"Services de conversion et de remise en état de véhicules",
   code_50117100:"Services de conversion de véhicules à moteur",
   code_50117200:"Services de conversion d'ambulances",
   code_50117300:"Services de remise en état de véhicules",
   code_50118000:"Services de secours routier",
   code_50118100:"Services de dépannage de voitures",
   code_50118110:"Services de remorquage de véhicules",
   code_50118200:"Services de dépannage de véhicules commerciaux",
   code_50118300:"Services de dépannage d'autobus",
   code_50118400:"Services de dépannage de véhicules à moteur",
   code_50118500:"Services de dépannage de motos",
   code_50190000:"Démolition de véhicules",
   code_50200000:"Services de réparation, d'entretien et services connexes relatifs au matériel de transport aérien, ferroviaire, routier et maritime",
   code_50210000:"Services de réparation, d'entretien et services connexes relatifs aux aéronefs et à d'autres équipements",
   code_50211000:"Services de réparation et d'entretien d'aéronefs",
   code_50211100:"Services d'entretien d'aéronefs",
   code_50211200:"Services de réparation d'aéronefs",
   code_50211210:"Services de réparation et d'entretien de moteurs d'aéronefs",
   code_50211211:"Services d'entretien de moteurs d'aéronefs",
   code_50211212:"Services de réparation de moteurs d'aéronefs",
   code_50211300:"Services de remise en état d'aéronefs",
   code_50211310:"Services de remise en état de moteurs d'aéronefs",
   code_50212000:"Services de réparation et d'entretien d'hélicoptères",
   code_50220000:"Services de réparation, d'entretien et services connexes liés au transport ferroviaire et à d'autres équipements",
   code_50221000:"Services de réparation et d'entretien de locomotives",
   code_50221100:"Services de réparation et d'entretien de boîtes de transmission de locomotives",
   code_50221200:"Services de réparation et d'entretien de transmissions de locomotives",
   code_50221300:"Services de réparation et d'entretien d'essieux montés de locomotives",
   code_50221400:"Services de réparation et d'entretien de freins et de pièces de freins de locomotives",
   code_50222000:"Services de réparation et d'entretien de matériel roulant",
   code_50222100:"Services de réparation et d'entretien d'amortisseurs",
   code_50223000:"Services de remise en état de locomotives",
   code_50224000:"Services de remise en état de matériel roulant",
   code_50224100:"Services de remise en état de sièges pour matériel roulant",
   code_50224200:"Services de remise en état de voitures à voyageurs",
   code_50225000:"Services d'entretien de voies ferrées",
   code_50229000:"Démolition de matériel roulant",
   code_50230000:"Services de réparation, d'entretien et services connexes relatifs au transport routier et à d'autres équipements",
   code_50232000:"Entretien d'installations d'éclairage public et de feux de signalisation",
   code_50232100:"Services d'entretien de l'éclairage public",
   code_50232110:"Mise en état d'exploitation d'installations d'éclairage public",
   code_50232200:"Services d'entretien des installations de signalisation",
   code_50240000:"Services de réparation, d'entretien et services connexes relatifs au transport maritime et à d'autres équipements",
   code_50241000:"Services de réparation et d'entretien de bateaux",
   code_50241100:"Services de réparation de navires",
   code_50241200:"Services de réparation de ferrys",
   code_50242000:"Services de conversion de bateaux",
   code_50243000:"Démolition de bateaux",
   code_50244000:"Services de remise en état de navires ou de bateaux",
   code_50245000:"Services de modernisation de bateaux",
   code_50246000:"Entretien d'équipement portuaire",
   code_50246100:"Services de mise en cale sèche",
   code_50246200:"Services d'entretien de bouées",
   code_50246300:"Services de réparation et d'entretien de structures flottantes",
   code_50246400:"Services de réparation et d'entretien de Plates-formes flottantes",
   code_50300000:"Services de réparation, d'entretien et services connexes relatifs aux ordinateurs personnels, au matériel de bureau, au matériel de télécommunications et au matériel audiovisuel",
   code_50310000:"Maintenance et réparation de machines de bureau",
   code_50311000:"Maintenance et réparation de machines comptables de bureau",
   code_50311400:"Maintenance et réparation de machines à calculer et de machines comptables",
   code_50312000:"Maintenance et réparation de matériel informatique",
   code_50312100:"Maintenance et réparation d'unités centrales",
   code_50312110:"Maintenance d'unités centrales",
   code_50312120:"Réparation d'unités centrales",
   code_50312200:"Maintenance et réparation de mini-ordinateurs",
   code_50312210:"Maintenance de mini-ordinateurs",
   code_50312220:"Réparation de mini-ordinateurs",
   code_50312300:"Maintenance et réparation de matériel de réseau informatique",
   code_50312310:"Maintenance de matériel de réseau informatique",
   code_50312320:"Réparation de matériel de réseau informatique",
   code_50312400:"Maintenance et réparation de micro-ordinateurs",
   code_50312410:"Maintenance de micro-ordinateurs",
   code_50312420:"Réparation de micro-ordinateurs",
   code_50312600:"Maintenance et réparation de matériel de technologies de l'information",
   code_50312610:"Maintenance de matériel de technologies de l'information",
   code_50312620:"Réparation de matériel de technologies de l'information",
   code_50313000:"Maintenance et réparation de matériel de reprographie",
   code_50313100:"Services de réparation de photocopieurs",
   code_50313200:"Services d'entretien de photocopieurs",
   code_50314000:"Services de réparation et d'entretien de télécopieurs",
   code_50315000:"Services de réparation et d'entretien de répondeurs téléphoniques",
   code_50316000:"Maintenance et réparation de distributeurs automatiques de tickets",
   code_50317000:"Maintenance et réparation de composteurs de billets",
   code_50320000:"Services de réparation et d'entretien d'ordinateurs personnels",
   code_50321000:"Services de réparation d'ordinateurs personnels",
   code_50322000:"Services d'entretien d'ordinateurs personnels",
   code_50323000:"Maintenance et réparation de périphériques d'ordinateur",
   code_50323100:"Maintenance de périphériques d'ordinateur",
   code_50323200:"Réparation de périphériques d'ordinateur",
   code_50324000:"Services d'assistance pour ordinateurs personnels",
   code_50324100:"Services de maintenance de systèmes",
   code_50324200:"Services de maintenance préventive",
   code_50330000:"Services d'entretien de matériel de télécommunications",
   code_50331000:"Services de réparation et d'entretien de lignes de télécommunications",
   code_50332000:"Services d'entretien d'infrastructures de télécommunications",
   code_50333000:"Services d'entretien de matériel de radiocommunications",
   code_50333100:"Services de réparation et d'entretien d'émetteurs radio",
   code_50333200:"Réparation et entretien d'appareils de radiotéléphonie",
   code_50334000:"Services de réparation et d'entretien de matériel de téléphonie et de télégraphie par fil",
   code_50334100:"Services de réparation et d'entretien de matériel de téléphonie par fil",
   code_50334110:"Entretien de réseau téléphonique",
   code_50334120:"Modernisation de standards téléphoniques",
   code_50334130:"Services de réparation et d'entretien de standards téléphoniques",
   code_50334140:"Services de réparation et d'entretien d'appareils téléphoniques",
   code_50334200:"Services de réparation et d'entretien de matériel de télégraphie par fil",
   code_50334300:"Services de réparation et d'entretien de matériel de télex par fil",
   code_50334400:"Entretien de systèmes de communications",
   code_50340000:"Services de réparation et d'entretien de matériel audiovisuel et d'optique",
   code_50341000:"Services de réparation et d'entretien de matériel de télévision",
   code_50341100:"Services de réparation et d'entretien de matériel vidéotexte",
   code_50341200:"Services de réparation et d'entretien d'émetteurs de télévision",
   code_50342000:"Services de réparation et d'entretien de matériel audio",
   code_50343000:"Services de réparation et d'entretien de matériel vidéo",
   code_50344000:"Services de réparation et d'entretien de matériel d'optique",
   code_50344100:"Services de réparation et d'entretien de matériel photographique",
   code_50344200:"Services de réparation et d'entretien de matériel cinématographique",
   code_50400000:"Services de réparation et d'entretien de matériel médical et de matériel de précision",
   code_50410000:"Services de réparation et d'entretien d'appareils de mesure, d'essai et de contrôle",
   code_50411000:"Services de réparation et d'entretien d'appareils de mesure",
   code_50411100:"Services de réparation et d'entretien de compteurs à eau",
   code_50411200:"Services de réparation et d'entretien de compteurs à gaz",
   code_50411300:"Services de réparation et d'entretien de compteurs d'électricité",
   code_50411400:"Services de réparation et d'entretien de tachymètres",
   code_50411500:"Services de réparation et d'entretien d'instruments de mesure du temps industriels",
   code_50412000:"Services de réparation et d'entretien d'appareils d'essai",
   code_50413000:"Services de réparation et d'entretien d'appareils de contrôle",
   code_50413100:"Services de réparation et d'entretien de matériel de détection de gaz",
   code_50413200:"Services de réparation et d'entretien d'installations d'extinction d'incendie",
   code_50420000:"Services de réparation et d'entretien de matériel médical et chirurgical",
   code_50421000:"Services de réparation et d'entretien de matériel médical",
   code_50421100:"Services de réparation et d'entretien de chaises roulantes",
   code_50421200:"Services de réparation et d'entretien de matériel de radiologie",
   code_50422000:"Services de réparation et d'entretien de matériel chirurgical",
   code_50430000:"Services de réparation et d'entretien de matériel de précision",
   code_50431000:"Services de réparation et d'entretien de montres",
   code_50432000:"Services de réparation et d'entretien d'horloges",
   code_50433000:"Services d'étalonnage",
   code_50500000:"Services de réparation et d'entretien de pompes, de vannes, de robinets, de conteneurs en métal et de machines",
   code_50510000:"Services de réparation et d'entretien de pompes, de vannes, de robinets et de conteneurs en métal",
   code_50511000:"Services de réparation et d'entretien de pompes",
   code_50511100:"Services de réparation et d'entretien de pompes à liquide",
   code_50511200:"Services de réparation et d'entretien de pompes à gaz",
   code_50512000:"Services de réparation et d'entretien de vannes",
   code_50513000:"Services de réparation et d'entretien de robinets",
   code_50514000:"Services de réparation et d'entretien de conteneurs en métal",
   code_50514100:"Services de réparation et d'entretien de citernes",
   code_50514200:"Services de réparation et d'entretien de réservoirs",
   code_50514300:"Services de réparation de gainage",
   code_50530000:"Services de réparation et d'entretien de machines",
   code_50531000:"Services de réparation et d'entretien de machines non électriques",
   code_50531100:"Services de réparation et d'entretien de chaudières",
   code_50531200:"Services d'entretien d'appareils à gaz",
   code_50531300:"Services de réparation et d'entretien de compresseurs",
   code_50531400:"Services de réparation et d'entretien de grues",
   code_50531500:"Services de réparation et d'entretien de grues derricks",
   code_50531510:"Services de démontage de derricks",
   code_50532000:"Services de réparation et d'entretien de machines et d'appareils électriques et de matériel connexe",
   code_50532100:"Services de réparation et d'entretien de moteurs électriques",
   code_50532200:"Services de réparation et d'entretien de transformateurs",
   code_50532300:"Services de réparation et d'entretien de générateurs",
   code_50532400:"Services de réparation et d'entretien de matériel de distribution électrique",
   code_50600000:"Services de réparation et d'entretien des matériels de défense et de sécurité",
   code_50610000:"Services de réparation et d'entretien du matériel de sécurité",
   code_50620000:"Services de réparation et d'entretien des armes à feu et des munitions",
   code_50630000:"Services de réparation et d'entretien des véhicules militaires",
   code_50640000:"Services de réparation et d'entretien des navires de guerre",
   code_50650000:"Services de réparation et d'entretien des aéronefs, des missiles et des spationefs militaires",
   code_50660000:"Services de réparation et d'entretien des systèmes électroniques militaires",
   code_50700000:"Services de réparation et d'entretien d'installations de bâtiments",
   code_50710000:"Services de réparation et d'entretien d'installations électriques et mécaniques de bâtiment",
   code_50711000:"Services de réparation et d'entretien d'installations électriques de bâtiment",
   code_50712000:"Services de réparation et d'entretien d'installations mécaniques de bâtiment",
   code_50720000:"Services de réparation et d'entretien de chauffage central",
   code_50721000:"Mise en état d'exploitation d'installations de chauffage",
   code_50730000:"Services de réparation et d'entretien de groupes de réfrigération",
   code_50740000:"Services de réparation et d'entretien d'escaliers mécaniques",
   code_50750000:"Services d'entretien d'ascenseurs",
   code_50760000:"Réparation et entretien de toilettes publiques",
   code_50800000:"Services divers d'entretien et de réparation",
   code_50810000:"Services de réparation de bijoux",
   code_50820000:"Services de réparation d'articles en cuir",
   code_50821000:"Services de réparation de bottes",
   code_50822000:"Services de réparation de chaussures",
   code_50830000:"Services de réparation de vêtements et d'articles textiles",
   code_50840000:"Services de réparation et d'entretien d'armes et de systèmes d'arme",
   code_50841000:"Services de réparation et d'entretien d'armes",
   code_50842000:"Services de réparation et d'entretien de systèmes d'arme",
   code_50850000:"Services de réparation et d'entretien de meubles",
   code_50860000:"Services de réparation et d'entretien d'instruments de musique",
   code_50870000:"Services de réparation et d'entretien d'équipements de terrains de jeux",
   code_50880000:"Services de réparation et d'entretien de matériel d'hôtellerie et de restauration",
   code_50881000:"Services de réparation et d'entretien de matériel d'hôtellerie",
   code_50882000:"Services de réparation et d'entretien de matériel pour restaurants",
   code_50883000:"Services de réparation et d'entretien de matériel de restauration",
   code_50884000:"Services de réparation et d'entretien de matériel de camping",
   code_51000000:"Services d'installation (à l'exception des logiciels)",
   code_51100000:"Services d'installation de matériel électrique et mécanique",
   code_51110000:"Services d'installation de matériel électrique",
   code_51111000:"Services d'installation de moteurs électriques, de générateurs et de transformateurs",
   code_51111100:"Services d'installation de moteurs électriques",
   code_51111200:"Services d'installation de générateurs",
   code_51111300:"Services d'installation de transformateurs",
   code_51112000:"Services d'installation de matériel de distribution et de commande électriques",
   code_51112100:"Services d'installation de matériel de distribution électrique",
   code_51112200:"Services d'installation de matériel de commande électrique",
   code_51120000:"Services d'installation de matériel mécanique",
   code_51121000:"Services d'installation de matériel de culture physique (fitness)",
   code_51122000:"Services d'installation de mâts porte-drapeau",
   code_51130000:"Services d'installation de générateurs à vapeur, de turbines, de compresseurs et de brûleurs",
   code_51131000:"Services d'installation de générateurs à vapeur",
   code_51133000:"Services d'installation de turbines",
   code_51133100:"Services d'installation de turbines à gaz",
   code_51134000:"Services d'installation de compresseurs",
   code_51135000:"Services d'installation de fourneaux",
   code_51135100:"Services d'installation de brûleurs",
   code_51135110:"Services d'installation d'incinérateurs de déchets",
   code_51140000:"Services d'installation de moteurs",
   code_51141000:"Services d'installation de moteurs à essence",
   code_51142000:"Services d'installation de moteurs diesel",
   code_51143000:"Services d'installation de moteurs de matériel ferroviaire",
   code_51144000:"Services d'installation de moteurs de véhicules",
   code_51145000:"Services d'installation de moteurs de navires",
   code_51146000:"Services d'installation de moteurs d'aéronefs",
   code_51200000:"Services d'installation de matériel de mesure, de contrôle, d'essai et de navigation",
   code_51210000:"Services d'installation de matériel de mesure",
   code_51211000:"Services d'installation de matériel de mesure du temps",
   code_51212000:"Services d'installation de matériel d'enregistrement des présences",
   code_51213000:"Services d'installation d'horloges de pointage",
   code_51214000:"Services d'installation de parcmètres",
   code_51215000:"Services d'installation de matériel météorologique",
   code_51216000:"Services d'installation de matériel géologique",
   code_51220000:"Services d'installation de matériel de contrôle",
   code_51221000:"Services d'installation d'appareils d'enregistrement automatiques pour aéroports",
   code_51230000:"Services d'installation de matériel d'essai",
   code_51240000:"Services d'installation de matériel de navigation",
   code_51300000:"Services d'installation de matériel de communications",
   code_51310000:"Services d'installation de matériel radio, de télévision, audio et vidéo",
   code_51311000:"Services d'installation de matériel radio",
   code_51312000:"Services d'installation de matériel de télévision",
   code_51313000:"Services d'installation de matériel audio",
   code_51314000:"Services d'installation de matériel vidéo",
   code_51320000:"Services d'installation d'émetteurs radio et de télévision",
   code_51321000:"Services d'installation d'émetteurs radio",
   code_51322000:"Services d'installation d'émetteurs de télévision",
   code_51330000:"Services d'installation d'appareils de radiotéléphonie",
   code_51340000:"Services d'installation de matériel de téléphonie par fil",
   code_51350000:"Services d'installation de matériel de télégraphie par fil",
   code_51400000:"Services d'installation de matériel médical et chirurgical",
   code_51410000:"Services d'installation de matériel médical",
   code_51411000:"Services d'installation de matériel d'imagerie",
   code_51412000:"Services d'installation de matériel de dentisterie et de ses sous-spécialités",
   code_51413000:"Services d'installation de matériel de radiothérapie",
   code_51414000:"Services d'installation de matériel de mécanothérapie",
   code_51415000:"Services d'installation de matériel d'électrothérapie",
   code_51416000:"Services d'installation de matériel de physiothérapie",
   code_51420000:"Services d'installation de matériel chirurgical",
   code_51430000:"Services d'installation de matériel de laboratoire",
   code_51500000:"Services d'installation de machines et d'équipements",
   code_51510000:"Services d'installation de machines et d'équipements à usage général",
   code_51511000:"Services d'installation de matériel de levage et de manutention, excepté ascenseurs et escaliers mécaniques",
   code_51511100:"Services d'installation de matériel de levage",
   code_51511110:"Services d'installation de grues",
   code_51511200:"Services d'installation de matériel de manutention",
   code_51511300:"Services d'installation de nacelles de nettoyage des façades",
   code_51511400:"Services d'installation de matériel de manutention spécial",
   code_51514000:"Services d'installation de machines diverses à usage général",
   code_51514100:"Services d'installation de machines et d'appareils de filtration ou de purification de liquides",
   code_51514110:"Services d'installation de machines et d'appareils de filtration ou de purification de l'eau",
   code_51520000:"Services d'installation de machines agricoles et sylvicoles",
   code_51521000:"Services d'installation de machines agricoles",
   code_51522000:"Services d'installation de machines sylvicoles",
   code_51530000:"Services d'installation de machines-outils",
   code_51540000:"Services d'installation de machines et d'équipements à usage spécifique",
   code_51541000:"Services d'installation de machines utilisées dans les mines, les carrières, la construction et la métallurgie",
   code_51541100:"Services d'installation de machines utilisées dans les mines",
   code_51541200:"Services d'installation de machines utilisées dans les carrières",
   code_51541300:"Services d'installation de machines utilisées dans la construction",
   code_51541400:"Services d'installation de machines utilisées dans la métallurgie",
   code_51542000:"Services d'installation de machines de traitement des aliments, des boissons et du tabac",
   code_51542100:"Services d'installation de machines de traitement des aliments",
   code_51542200:"Services d'installation de machines de traitement des boissons",
   code_51542300:"Services d'installation de machines de traitement du tabac",
   code_51543000:"Services d'installation de machines pour la fabrication de textile, de vêtements et de cuir",
   code_51543100:"Services d'installation de machines pour la fabrication textile",
   code_51543200:"Services d'installation de machines pour la fabrication de vêtements",
   code_51543300:"Services d'installation de machines pour la fabrication du cuir",
   code_51543400:"Services d'installation de machines à laver, de machines de nettoyage à sec et de sèche-linge de blanchisserie",
   code_51544000:"Services d'installation de machines pour la fabrication du papier et du carton",
   code_51544100:"Services d'installation de machines pour la fabrication du papier",
   code_51544200:"Services d'installation de machines pour la fabrication du carton",
   code_51545000:"Services d'installation de boîtes à lettres publiques",
   code_51550000:"Services d'installation de systèmes d'arme",
   code_51600000:"Services d'installation d'ordinateurs et de matériel de bureau",
   code_51610000:"Services d'installation d'ordinateurs et de matériel de traitement de l'information",
   code_51611000:"Services d'installation d'ordinateurs",
   code_51611100:"Services d'installation de matériel informatique",
   code_51611110:"Services d'installation d'écrans et de tableaux d'affichage des départs et des arrivées d'avions",
   code_51611120:"Services d'installation d'écrans et de tableaux d'affichage des départs et des arrivées de trains",
   code_51612000:"Services d'installation de matériel de traitement de l'information",
   code_51620000:"Services d'installation de matériel de bureau",
   code_51700000:"Services d'installation de matériel de protection contre l'incendie",
   code_51800000:"Services d'installation de conteneurs en métal",
   code_51810000:"Services d'installation de citernes",
   code_51820000:"Services d'installation de réservoirs",
   code_51900000:"Services d'installation de systèmes de guidage et de commande",
   code_55000000:"Services d'hôtellerie, de restauration et de commerce au détail",
   code_55100000:"Services d'hôtellerie",
   code_55110000:"Services d'hébergement hôtelier",
   code_55120000:"Services de réunions et de conférences organisés dans des hôtels",
   code_55130000:"Autres services d'hôtellerie",
   code_55200000:"Campings et autre hébergement non hôtelier",
   code_55210000:"Services d'hébergement en auberge de jeunesse",
   code_55220000:"Services de terrain de camping",
   code_55221000:"Services d'aire de caravanage",
   code_55240000:"Services de centres aérés et de centres de vacances",
   code_55241000:"Services de centres aérés",
   code_55242000:"Services de centres de vacances",
   code_55243000:"Services de colonies de vacances",
   code_55250000:"Services de location de meublés pour des séjours de courte durée",
   code_55260000:"Services des wagons-lits",
   code_55270000:"Services prestés par les établissements proposant des chambres avec petit-déjeuner",
   code_55300000:"Services de restaurant et services de personnel en salle",
   code_55310000:"Services de serveurs de restaurant",
   code_55311000:"Services de serveurs de restaurant pour clientèle restreinte",
   code_55312000:"Services de serveurs de restaurant sans restriction d'accès",
   code_55320000:"Services de distribution de repas",
   code_55321000:"Services de préparation de repas",
   code_55322000:"Services d'élaboration (cuisson) de repas",
   code_55330000:"Services de cafétéria",
   code_55400000:"Services de débits de boissons",
   code_55410000:"Services de gestion de bars",
   code_55500000:"Services de cantine et service traiteur",
   code_55510000:"Services de cantine",
   code_55511000:"Services de cantine et autres services de cafétéria pour clientèle restreinte",
   code_55512000:"Services de gestion de cantine",
   code_55520000:"Services traiteur",
   code_55521000:"Service traiteur pour ménages",
   code_55521100:"Services de repas livrés à domicile",
   code_55521200:"Services de livraison de repas",
   code_55522000:"Service traiteur pour entreprises de transport",
   code_55523000:"Services traiteur pour autres entreprises ou autres institutions",
   code_55523100:"Services de restauration scolaire",
   code_55524000:"Service traiteur pour écoles",
   code_55900000:"Services de vente au détail",
   code_60000000:"Services de transport (à l'exclusion du transport des déchets)",
   code_60100000:"Services de transport routier",
   code_60112000:"Services de transport routier public",
   code_60120000:"Services de taxi",
   code_60130000:"Services spécialisés de transport routier de passagers",
   code_60140000:"Transport non régulier de passagers",
   code_60150000:"Transport de passagers par véhicules à traction animale",
   code_60160000:"Transport routier postal",
   code_60161000:"Services de transport de colis",
   code_60170000:"Location de véhicules de transport de personnes avec chauffeur",
   code_60171000:"Location de voitures particulières avec chauffeur",
   code_60172000:"Location d'autobus et d'autocars avec chauffeur",
   code_60180000:"Location de véhicules de transport de marchandises avec chauffeur",
   code_60181000:"Location de camions avec chauffeur",
   code_60182000:"Location de véhicules industriels avec chauffeur",
   code_60183000:"Location de camionnettes avec chauffeur",
   code_60200000:"Services de transport ferroviaire",
   code_60210000:"Services de transport ferroviaire public",
   code_60220000:"Transport ferroviaire postal",
   code_60300000:"Services de transport par pipeline",
   code_60400000:"Services de transport aérien",
   code_60410000:"Services de transport aérien régulier",
   code_60411000:"Services de transport aérien régulier du courrier",
   code_60420000:"Services de transport aérien non régulier",
   code_60421000:"Services de transport aérien non régulier du courrier",
   code_60423000:"Services de charter",
   code_60424000:"Location de matériel de transport aérien avec équipage",
   code_60424100:"Location d'aéronefs avec équipage",
   code_60424110:"Location d'aéronefs à voilure fixe avec équipage",
   code_60424120:"Location d'hélicoptères avec équipage",
   code_60440000:"Services aériens et services connexes",
   code_60441000:"Services de pulvérisation par voie aérienne",
   code_60442000:"Services de lutte aérienne contre les incendies de forêt",
   code_60443000:"Services de secours aérien",
   code_60443100:"Services aéronavals de sauvetage",
   code_60444000:"Services d'exploitation d'aéronefs",
   code_60444100:"Services de pilotage",
   code_60445000:"Services d'exploitation d'avions",
   code_60500000:"Services de transport spatial",
   code_60510000:"Services de lancement de satellites",
   code_60520000:"Services de charges utiles expérimentales",
   code_60600000:"Services de transport par voie d'eau",
   code_60610000:"Services de transport par ferry",
   code_60620000:"Transport de courrier par voie d'eau",
   code_60630000:"Services de navires câbliers",
   code_60640000:"Opérations de transport maritime",
   code_60650000:"Location de matériel de transport par voie d'eau avec équipage",
   code_60651000:"Location de vaisseaux avec équipage",
   code_60651100:"Location de navires de mer avec équipage",
   code_60651200:"Location de bateaux de navigation intérieure avec équipage",
   code_60651300:"Services de navires antipollution",
   code_60651400:"Services de navires transporteurs de charges lourdes",
   code_60651500:"Services de navires de secours",
   code_60651600:"Services de navires de ravitaillement offshore",
   code_60653000:"Location de bateaux avec équipage",
   code_63000000:"Services d'appui et services auxiliaires dans le domaine des transports, services des agences de voyages",
   code_63100000:"Services de manutention et d'entreposage de cargaisons",
   code_63110000:"Services de manutention de cargaisons",
   code_63111000:"Services de manutention de conteneurs",
   code_63112000:"Services de manutention de bagages",
   code_63112100:"Services de manutention des bagages des passagers",
   code_63112110:"Services de rassemblement des bagages",
   code_63120000:"Services de stockage et d'entreposage",
   code_63121000:"Services de stockage et de récupération",
   code_63121100:"Services de stockage",
   code_63121110:"Services de stockage de gaz",
   code_63122000:"Services d'entreposage",
   code_63500000:"Services d'agences de voyages, de voyagistes et d'assistance aux touristes",
   code_63510000:"Services d'agences de voyages et services similaires",
   code_63511000:"Organisation de voyages à forfait",
   code_63512000:"Vente de billets de voyages à forfait",
   code_63513000:"Services d'information touristique",
   code_63514000:"Services de guides touristiques",
   code_63515000:"Services de voyages",
   code_63516000:"Services de gestion de voyages",
   code_63520000:"Services d'agences de transport",
   code_63521000:"Services d'agences de transport de marchandises",
   code_63522000:"Services de courtage maritime",
   code_63523000:"Services d'agences portuaires et d'agences d'expédition",
   code_63524000:"Services de préparation de documents de transport",
   code_63700000:"Services d'appui dans le domaine des transports terrestres, maritimes et aériens",
   code_63710000:"Services d'appui dans le domaine des transports terrestres",
   code_63711000:"Services d'appui dans le domaine des transports ferroviaires",
   code_63711100:"Services de surveillance de la marche des trains",
   code_63711200:"Services d'ateliers mobiles",
   code_63712000:"Services d'appui dans le domaine des transports routiers",
   code_63712100:"Services de gares routières",
   code_63712200:"Services d'exploitation des autoroutes",
   code_63712210:"Services de péage autoroutier",
   code_63712300:"Services d'exploitation de ponts et de tunnels",
   code_63712310:"Services d'exploitation de ponts",
   code_63712311:"Services de péage de pont",
   code_63712320:"Services d'exploitation de tunnels",
   code_63712321:"Services de péage de tunnel",
   code_63712400:"Services de parc de stationnement",
   code_63712500:"Services de pont-bascule",
   code_63712600:"Services de ravitaillement de véhicules",
   code_63712700:"Services de la circulation routière",
   code_63712710:"Services de surveillance de la circulation routière",
   code_63720000:"Services d'appui dans le domaine des transports par voie d'eau",
   code_63721000:"Services d'exploitation de ports et de voies navigables et services connexes",
   code_63721100:"Services de mise en soute",
   code_63721200:"Services d'exploitation portuaire",
   code_63721300:"Services d'exploitation des voies navigables",
   code_63721400:"Services de ravitaillement de navires",
   code_63721500:"Services d'exploitation de terminaux passagers",
   code_63722000:"Services de pilotage de navires",
   code_63723000:"Services d'accostage",
   code_63724000:"Services de navigation",
   code_63724100:"Services de positionnement en mer",
   code_63724110:"Services de positionnement de bateaux-phares",
   code_63724200:"Services de bateaux-phares",
   code_63724300:"Services de positionnement de bouées",
   code_63724310:"Services de balisage",
   code_63724400:"Services de phares",
   code_63725000:"Services de sauvetage et de renflouage",
   code_63725100:"Services de sauvetage de navires",
   code_63725200:"Services des bateaux de sauvegarde",
   code_63725300:"Services de renflouage de navires",
   code_63726000:"Services d'assistance divers dans le domaine des transports par voie d'eau",
   code_63726100:"Services d'enregistrement des navires",
   code_63726200:"Services de navires brise-glaces",
   code_63726300:"Services de stockage en navire",
   code_63726400:"Services d'affrètement de navires",
   code_63726500:"Services de désarmement de navires",
   code_63726600:"Services d'exploitation de navires",
   code_63726610:"Services de mise à l'eau de navires",
   code_63726620:"Services d'engins inhabités télécommandés",
   code_63726700:"Services des navires de pêche",
   code_63726800:"Services des navires de recherche",
   code_63726900:"Services de bateau manipulateur d'ancre",
   code_63727000:"Services de remorquage et de poussage de bateaux",
   code_63727100:"Services de remorquage",
   code_63727200:"Services de poussage",
   code_63730000:"Services d'appui dans le domaine des transports aériens",
   code_63731000:"Services d'exploitation d'aéroport",
   code_63731100:"Services de coordination des créneaux aéroportuaires",
   code_63732000:"Services de contrôle du trafic aérien",
   code_63733000:"Services d'avitaillement",
   code_63734000:"Services de hangar",
   code_64000000:"Services des postes et télécommunications",
   code_64100000:"Services postaux et services de courrier",
   code_64110000:"Services postaux",
   code_64111000:"Services postaux relatifs aux journaux et aux périodiques",
   code_64112000:"Services postaux relatifs aux lettres",
   code_64113000:"Services postaux relatifs aux colis",
   code_64114000:"Services de guichets de bureaux de poste",
   code_64115000:"Location de boîtes aux lettres",
   code_64116000:"Services de poste restante",
   code_64120000:"Services de courrier",
   code_64121000:"Services multimodaux de courrier",
   code_64121100:"Services de distribution de courrier",
   code_64121200:"Services de livraison de colis",
   code_64122000:"Services de courrier et de messagerie interne des administrations",
   code_64200000:"Services de télécommunications",
   code_64210000:"Services de téléphonie et de transmission de données",
   code_64211000:"Services de cabines téléphoniques publiques",
   code_64211100:"Services de téléphonie locale",
   code_64211200:"Services de téléphonie interurbaine",
   code_64212000:"Services de téléphonie mobile",
   code_64212100:"Services de messages courts (SMS)",
   code_64212200:"Services de messagerie améliorés (EMS)",
   code_64212300:"Services de messagerie multimédia (MMS)",
   code_64212400:"Services WAP (Wireless Application Protocol)",
   code_64212500:"Services GPRS (Services généraux de radiocommunication par paquets)",
   code_64212600:"Services EDGE (Enhanced Data rates for GSM Evolution)",
   code_64212700:"Services UMTS (système de télécommunications mobiles universelles)",
   code_64212800:"Services de fourniture de publiphone",
   code_64212900:"Services de fourniture de cartes de téléphone prépayées",
   code_64213000:"Services des réseaux téléphoniques commerciaux partagés",
   code_64214000:"Services des réseaux téléphoniques commerciaux spécialisés",
   code_64214100:"Services de location de circuits par satellite",
   code_64214200:"Services de commutateurs téléphoniques",
   code_64214400:"Location de lignes terrestres de communication",
   code_64215000:"Services de téléphonie IP",
   code_64216000:"Services de messagerie et d'information électroniques",
   code_64216100:"Services de messagerie électronique",
   code_64216110:"Services d'échange de données électroniques",
   code_64216120:"Services de courrier électronique",
   code_64216130:"Services de télex",
   code_64216140:"Services de télégraphie",
   code_64216200:"Services d'information électronique",
   code_64216210:"Services d'information à valeur ajoutée",
   code_64216300:"Services de télétexte",
   code_64220000:"Services de télécommunications, excepté téléphone et transmission de données",
   code_64221000:"Services d'interconnexion",
   code_64222000:"Services de télétravail",
   code_64223000:"Services de radiomessagerie",
   code_64224000:"Services de téléconférence",
   code_64225000:"Services de télécommunications air-sol",
   code_64226000:"Services de télématique",
   code_64227000:"Services de télécommunications intégrés",
   code_64228000:"Retransmission de programmes de télévision et de radio",
   code_64228100:"Retransmission de programmes de télévision",
   code_64228200:"Retransmission de programmes de radio",
   code_65000000:"Services publics",
   code_65100000:"Distribution d'eau et services connexes",
   code_65110000:"Distribution d'eau",
   code_65111000:"Distribution d'eau potable",
   code_65120000:"Exploitation d'une station d'épuration d'eau",
   code_65121000:"Services de déminéralisation de l'eau",
   code_65122000:"Services de dessalement de l'eau",
   code_65123000:"Services d'adoucissement de l'eau",
   code_65130000:"Exploitation de l'alimentation en eau",
   code_65200000:"Distribution de gaz et services connexes",
   code_65210000:"Distribution de gaz",
   code_65300000:"Distribution d'électricité et services connexes",
   code_65310000:"Distribution d'électricité",
   code_65320000:"Exploitation d'installations électriques",
   code_65400000:"Autres sources d'approvisionnement en énergie et leur distribution",
   code_65410000:"Exploitation d'une centrale électrique",
   code_65500000:"Services de lecture de compteurs",
   code_66000000:"Services financiers et d'assurance",
   code_66100000:"Services bancaires et d'investissement",
   code_66110000:"Services bancaires",
   code_66111000:"Services de banques centrales",
   code_66112000:"Services de dépôts",
   code_66113000:"Services de concession de crédit",
   code_66113100:"Services de concession de micro-crédits",
   code_66114000:"Services de leasing financier",
   code_66115000:"Services de virements internationaux",
   code_66120000:"Services bancaires d'investissement et services connexes",
   code_66121000:"Services de fusions-acquisitions",
   code_66122000:"Services de financement des entreprises et de capital-risque",
   code_66130000:"Services de courtage et services liés de titres et marchandises",
   code_66131000:"Services de courtage de titres",
   code_66131100:"Services d'investissement des fonds de pension",
   code_66132000:"Services de courtage de marchandises",
   code_66133000:"Services de traitement d'opérations et services de compensation",
   code_66140000:"Services de gestion de portefeuilles",
   code_66141000:"Services de gestion des fonds de pension",
   code_66150000:"Services d'administration des marchés financiers",
   code_66151000:"Services de gestion des marchés financiers",
   code_66151100:"Services de vente au détail par le biais de marché électronique",
   code_66152000:"Services de réglementation des marchés financiers",
   code_66160000:"Services de fiducie et de garde",
   code_66161000:"Services de fiducie",
   code_66162000:"Services de garde",
   code_66170000:"Services de conseils financiers, services de traitement des transactions financières et services de chambres de compensation",
   code_66171000:"Services de conseils financiers",
   code_66172000:"Services de traitement des transactions financières et services de chambre de compensation",
   code_66180000:"Services de change",
   code_66190000:"Services de courtage d'emprunts",
   code_66500000:"Services d'assurance et services de retraite",
   code_66510000:"Services d'assurance",
   code_66511000:"Services d'assurance vie",
   code_66512000:"Services d'assurances accidents et maladie",
   code_66512100:"Services d'assurance accidents",
   code_66512200:"Services d'assurance maladie",
   code_66512210:"Services d'assurance maladie volontaire",
   code_66512220:"Services d'assurance médicale",
   code_66513000:"Services d'assurance défense et recours et service d'assurance tous risques chantier",
   code_66513100:"Services d'assurance défense et recours",
   code_66513200:"Services d'assurance tous risques chantier",
   code_66514000:"Services d'assurance fret et services connexes",
   code_66514100:"Services d'assurance transport",
   code_66514110:"Services d'assurance de véhicules à moteur",
   code_66514120:"Services d'assurance maritime, aérienne et de transports d'autre type",
   code_66514130:"Services d'assurance de matériel ferroviaire",
   code_66514140:"Services d'assurance d'aéronefs",
   code_66514150:"Services d'assurance de bateaux",
   code_66514200:"Services d'assurance fret",
   code_66515000:"Services d'assurance dommages ou pertes",
   code_66515100:"Services d'assurance incendie",
   code_66515200:"Services d'assurance de biens",
   code_66515300:"Services d'assurance intempéries et pertes financières",
   code_66515400:"Services d'assurance intempéries",
   code_66515410:"Services d'assurance pertes financières",
   code_66515411:"Services d'assurance pertes pécuniaires",
   code_66516000:"Services d'assurance responsabilité civile",
   code_66516100:"Services d'assurance responsabilité civile automobile",
   code_66516200:"Services d'assurance responsabilité civile aviation",
   code_66516300:"Services d'assurance responsabilité civile bateaux",
   code_66516400:"Services d'assurance responsabilité civile générale",
   code_66516500:"Services d'assurance de responsabilité professionnelle",
   code_66517000:"Services d'assurance crédit et cautionnement",
   code_66517100:"Services d'assurance crédit",
   code_66517200:"Services d'assurance cautionnement",
   code_66517300:"Services d'assurance gestion des risques",
   code_66518000:"Services de courtage et services d'agence dans le secteur des assurances",
   code_66518100:"Services de courtage en assurances",
   code_66518200:"Services d'agences d'assurances",
   code_66518300:"Services de règlement des déclarations de sinistre",
   code_66519000:"Services d'assurance d'installations techniques, services d'assurance auxiliaire, services de règlement d'avaries, services de règlement de sinistres, services des actuaires et services d'administration des droits de sauvetage",
   code_66519100:"Services d'assurance de plate-forme de pétrole et de gaz",
   code_66519200:"Services d'assurance d'installations techniques",
   code_66519300:"Services d'assurance auxiliaire",
   code_66519310:"Services de conseil en assurances",
   code_66519400:"Services de règlement d'avaries",
   code_66519500:"Services de règlement des sinistres",
   code_66519600:"Services des actuaires",
   code_66519700:"Services d'administration des droits de sauvetage",
   code_66520000:"Services de retraite",
   code_66521000:"Services de retraite individuelle",
   code_66522000:"Services de retraite collective",
   code_66523000:"Services de conseil en matière de fonds de pension",
   code_66523100:"Services d'administration des fonds de pension",
   code_66600000:"Services de trésorerie",
   code_66700000:"Services de réassurance",
   code_66710000:"Services de réassurance vie",
   code_66720000:"Services de réassurance accidents et maladie",
   code_70000000:"Services immobiliers",
   code_70100000:"Services immobiliers propres",
   code_70110000:"Services de promotion immobilière",
   code_70111000:"Promotion de biens immobiliers à usage résidentiel",
   code_70112000:"Promotion de biens immobiliers à usage non résidentiel",
   code_70120000:"Achat et vente de biens immobiliers",
   code_70121000:"Services de vente ou d'achat d'immeubles",
   code_70121100:"Services de vente d'immeubles",
   code_70121200:"Services d'achat d'immeubles",
   code_70122000:"Services de vente ou d'achat de terrains",
   code_70122100:"Services de vente de terrains",
   code_70122110:"Services de vente de terrains libres",
   code_70122200:"Services d'achat de terrains",
   code_70122210:"Services d'achat de terrains libres",
   code_70123000:"Vente de biens immobiliers",
   code_70123100:"Vente de biens immobiliers à usage résidentiel",
   code_70123200:"Vente de biens immobiliers à usage non résidentiel",
   code_70130000:"Services de location de biens immobiliers propres",
   code_70200000:"Services de crédit-bail ou de location de biens immobiliers propres",
   code_70210000:"Services de crédit-bail ou de location de propriétés résidentielles propres",
   code_70220000:"Services de crédit-bail ou de location de propriétés non résidentielles propres",
   code_70300000:"Services d'agence immobilière prestés pour le compte de tiers",
   code_70310000:"Services de location ou de vente d'immeubles",
   code_70311000:"Services de location ou de vente d'immeubles à usage résidentiel",
   code_70320000:"Services de location ou de vente de terrains",
   code_70321000:"Services de location de terrains",
   code_70322000:"Services de location ou de vente de terrains libres",
   code_70330000:"Services de gestion de biens immobiliers pour le compte de tiers",
   code_70331000:"Services d'immobilier résidentiel",
   code_70331100:"Services de gestion d'institutions",
   code_70332000:"Services d'immobilier non résidentiel",
   code_70332100:"Services de gestion de terrains",
   code_70332200:"Services de gestion de biens immobiliers à usage commercial",
   code_70332300:"Services relatifs à l'immobilier industriel",
   code_70333000:"Services de logement",
   code_70340000:"Services de multipropriété",
   code_71000000:"Services d'architecture, services de construction, services d'ingénierie et services d'inspection",
   code_71200000:"Services d'architecture",
   code_71210000:"Services de conseil en architecture",
   code_71220000:"Services de création architecturale",
   code_71221000:"Services d'architecte pour les bâtiments",
   code_71222000:"Services d'architecte pour la conception d'ouvrages extérieurs",
   code_71222100:"Services de cartographie des zones urbaines",
   code_71222200:"Services de cartographie des zones rurales",
   code_71223000:"Services d'architecte pour des travaux d'extension de bâtiment",
   code_71230000:"Services d'organisation de concours d'architecture",
   code_71240000:"Services d'architecture, d'ingénierie et de planification",
   code_71241000:"Études de faisabilité, service de conseil, analyse",
   code_71242000:"Préparation du projet et de la conception, estimation des coûts",
   code_71243000:"Projets de plans (systémes et intégration)",
   code_71244000:"Calcul des coûts, contrôle des coûts",
   code_71245000:"Plans d'approbation, plans d'exécution et spécifications",
   code_71246000:"Détermination et listage des quantités nécessaires à la construction",
   code_71247000:"Supervision des travaux de construction",
   code_71248000:"Supervision du projet et documentation",
   code_71250000:"Services d'architecture, d'ingénierie et de métrage",
   code_71251000:"Services d'architecture et de métrage vérification",
   code_71300000:"Services d'ingénierie",
   code_71310000:"Services de conseil en matière d'ingénierie et de construction",
   code_71311000:"Services de conseil en génie civil",
   code_71311100:"Services d'assistance en génie civil",
   code_71311200:"Services de conseil en matière de systèmes de transport",
   code_71311210:"Services de conseil en matière d'autoroutes",
   code_71311220:"Services d'ingénierie autoroutière",
   code_71311230:"Services d'ingénierie ferroviaire",
   code_71311240:"Services d'ingénierie aéroportuaire",
   code_71311300:"Services de conseil en matière d'infrastructure",
   code_71312000:"Services de conseil en ingénierie de la construction",
   code_71313000:"Services de conseil en ingénierie de l'environnement",
   code_71313100:"Services de conseil en matière de lutte contre le bruit",
   code_71313200:"Services de conseil en insonorisation et en acoustique intérieure",
   code_71313400:"Étude d'impact sur l'environnement pour la construction",
   code_71313410:"Évaluation des risque et dangers pour la construction",
   code_71313420:"Normes environnementales pour la construction",
   code_71313430:"Indicateurs environnementaux pour la construction",
   code_71313440:"Services d'étude d'impact sur l'environnement (EIA) pour la construction",
   code_71313450:"Surveillance environnementale pour la construction",
   code_71314000:"Énergie et services connexes",
   code_71314100:"Services d'électricité",
   code_71314200:"Services de gestion de l'énergie",
   code_71314300:"Services de conseil en rendement énergétique",
   code_71314310:"Services de physique thermique pour la construction",
   code_71315000:"Installations techniques de bâtiment",
   code_71315100:"Services de conseil en gros œuvre",
   code_71315200:"Services de conseil en bâtiment",
   code_71315210:"Services de conseil en installations techniques de bâtiment",
   code_71315300:"Services de métré de bâtiments",
   code_71315400:"Services d'inspection et de vérification de bâtiment",
   code_71315410:"Inspection du système de ventilation",
   code_71316000:"Services de conseil en télécommunications",
   code_71317000:"Services de conseil en protection et contrôle des risques",
   code_71317100:"Services de conseil en protection et contrôle en matière d'incendie et d'explosion",
   code_71317200:"Services de santé et de sécurité",
   code_71317210:"Services de conseil en matière de santé et de sécurité",
   code_71318000:"Services de conseil et de consultation en ingénierie",
   code_71318100:"Services pour les techniques d'éclairage naturel et artificiel",
   code_71319000:"Services de témoins experts",
   code_71320000:"Services de conception technique",
   code_71321000:"Services de conception technique des installations mécaniques et électriques de bâtiments",
   code_71321100:"Services relatifs aux aspects économiques de la construction",
   code_71321200:"Services de conception des systèmes de chauffage",
   code_71321300:"Services de conseil en matière de plomberie",
   code_71321400:"Services de conseil en matière de ventilation",
   code_71322000:"Services de conception technique pour la construction d'ouvrages de génie civil",
   code_71322100:"Services d'estimatif pour travaux de génie civil",
   code_71322200:"Services de conception de pipelines",
   code_71322300:"Services de conception de ponts",
   code_71322400:"Services de conception de barrages",
   code_71322500:"Services de conception technique pour installations de transport",
   code_71323000:"Services de conception technique pour processus et production industriels",
   code_71323100:"Services de conception de réseaux d'énergie électrique",
   code_71323200:"Services de conception technique d'usines",
   code_71324000:"Services d'état estimatif",
   code_71325000:"Services de conception de fondations",
   code_71326000:"Services auxiliaires de bâtiment",
   code_71327000:"Services de conception des structures portantes",
   code_71328000:"Services de vérification de la conception des structures portantes",
   code_71330000:"Services divers d'ingénierie",
   code_71331000:"Services d'ingénierie en matière de boues de forage",
   code_71332000:"Services d'ingénierie géotechnique",
   code_71333000:"Services d'ingénierie mécanique",
   code_71334000:"Services d'ingénierie mécanique et électrique",
   code_71335000:"Études techniques",
   code_71336000:"Services d'assistance dans le domaine de l'ingénierie",
   code_71337000:"Services d'ingénierie relatifs à la corrosion",
   code_71340000:"Services intégrés d'ingénierie",
   code_71350000:"Services scientifiques et techniques dans le domaine de l'ingénierie",
   code_71351000:"Services de prospection géologique et géophysique et autre prospection scientifique",
   code_71351100:"Services de préparation et d'analyse de carottage",
   code_71351200:"Services de conseil en géologie et en géophysique",
   code_71351210:"Services de conseil géophysique",
   code_71351220:"Services de conseil en géologie",
   code_71351300:"Services d'analyses micropaléontologiques",
   code_71351400:"Services d'interprétation pétrophysique",
   code_71351500:"Services d'étude du sol",
   code_71351600:"Services de prévisions météorologiques",
   code_71351610:"Services de météorologie",
   code_71351611:"Services de climatologie",
   code_71351612:"Services d'hydrométéorologie",
   code_71351700:"Services de prospection scientifique",
   code_71351710:"Services de prospection géophysique",
   code_71351720:"Levés géophysiques de sites archéologiques",
   code_71351730:"Services de prospection géologique",
   code_71351800:"Services topographiques et services de radiesthésie",
   code_71351810:"Services topographiques",
   code_71351811:"Levés topographiques de sites archéologiques",
   code_71351820:"Services de radiesthésie",
   code_71351900:"Services de géologie, d'océanographie et d'hydrologie",
   code_71351910:"Services de géologie",
   code_71351911:"Services de photogéologie",
   code_71351912:"Services de géologie stratigraphique",
   code_71351913:"Services d'exploration géologique",
   code_71351914:"Services archéologiques",
   code_71351920:"Services d'océanographie et d'hydrologie",
   code_71351921:"Services d'océanographie des estuaires",
   code_71351922:"Services d'océanographie physique",
   code_71351923:"Services de levés bathymétriques",
   code_71351924:"Services d'exploration sous-marine",
   code_71352000:"Services de levés souterrains",
   code_71352100:"Services de sismique",
   code_71352110:"Services de levés sismographiques",
   code_71352120:"Services d'acquisition de données sismiques",
   code_71352130:"Services de collecte de données sismiques",
   code_71352140:"Services de traitement des données sismiques",
   code_71352300:"Services d'enregistrement magnétométrique",
   code_71353000:"Services de levés en surface",
   code_71353100:"Services de levés hydrographiques",
   code_71353200:"Services de levés dimensionnels",
   code_71354000:"Services d'établissement de cartes",
   code_71354100:"Services de cartographie numérique",
   code_71354200:"Services de cartographie aérienne",
   code_71354300:"Services cadastraux",
   code_71354400:"Services d'hydrographie",
   code_71354500:"Services de cartographie maritime",
   code_71355000:"Services d'arpentage",
   code_71355100:"Services de photogrammétrie",
   code_71355200:"Services d'arpentage cadastral",
   code_71356000:"Services techniques",
   code_71356100:"Services de contrôle technique",
   code_71356200:"Services d'assistance technique",
   code_71356300:"Services d'appui technique",
   code_71356400:"Services de planification technique",
   code_71400000:"Services d'urbanisme et d'architecture paysagère",
   code_71410000:"Services d'urbanisme",
   code_71420000:"Services d'architecture paysagère",
   code_71421000:"Services d'architecture des jardins",
   code_71500000:"Services relatifs à la construction",
   code_71510000:"Services de reconnaissance sur le site",
   code_71520000:"Services de conduite des travaux",
   code_71521000:"Services de conduite de chantier",
   code_71530000:"Services de conseil en construction",
   code_71540000:"Services de gestion de la construction",
   code_71541000:"Services de gestion de projets de construction",
   code_71550000:"Services de travaux de forge",
   code_71600000:"Services d'essais techniques, services d'analyses et services de conseil",
   code_71610000:"Services d'essais et d'analyses de la composition et de la pureté",
   code_71620000:"Services d'analyses",
   code_71621000:"Services d'analyse technique ou services de conseil",
   code_71630000:"Services de contrôle et d'essais techniques",
   code_71631000:"Services d'inspection technique",
   code_71631100:"Services de contrôle de machines",
   code_71631200:"Services de contrôle technique automobile",
   code_71631300:"Services de contrôle technique de bâtiments",
   code_71631400:"Services de contrôle technique d'ouvrages de génie civil",
   code_71631420:"Services d'inspection dans le domaine de la sécurité maritime",
   code_71631430:"Services d'essais d'étanchéité",
   code_71631440:"Services de contrôle du débit",
   code_71631450:"Services d'inspection des ponts",
   code_71631460:"Services d'inspection des barrages",
   code_71631470:"Service de contrôle des voies ferrées",
   code_71631480:"Service d'inspection routière",
   code_71631490:"Service d'inspection des pistes",
   code_71632000:"Services d'essais techniques",
   code_71632100:"Services d'essai de vannes",
   code_71632200:"Services d'essais non destructifs",
   code_71700000:"Services de surveillance et de contrôle",
   code_71730000:"Services d'inspection industrielle",
   code_71731000:"Services de contrôle de qualité industriel",
   code_71800000:"Services de conseil en matière d'approvisionnement en eau et services de conseil en matière de déchets",
   code_71900000:"Services de laboratoire",
   code_72000000:"Services de technologies de l'information, conseil, développement de logiciels, internet et appui",
   code_72100000:"Services de conseil en matériel informatique",
   code_72110000:"Services de conseil en sélection de matériel informatique",
   code_72120000:"Services de conseil en reprise des applications informatiques en cas d'interruption accidentelle",
   code_72130000:"Services de conseil en aménagement de site informatique",
   code_72140000:"Services de conseil en essai d'acceptation de matériel informatique",
   code_72150000:"Services de conseil en audit informatique et services de conseil en matériel informatique",
   code_72200000:"Services de programmation et de conseil en logiciels",
   code_72210000:"Services de programmation de progiciels",
   code_72211000:"Services de programmation de systèmes et de logiciels utilitaires",
   code_72212000:"Services de programmation de logiciels d'application",
   code_72212100:"Services de développement de logiciels spécifiques à l'industrie",
   code_72212110:"Services de développement de logiciels de points de vente (POS)",
   code_72212120:"Services de développement de logiciels de commande de vol",
   code_72212121:"Services de développement de logiciels de contrôle du trafic aérien",
   code_72212130:"Services de développement de logiciels de soutien au sol et de test pour l'aviation",
   code_72212131:"Services de développement de logiciels de soutien au sol pour l'aviation",
   code_72212132:"Services de développement de logiciels de test pour l'aviation",
   code_72212140:"Services de développement de logiciels de contrôle du trafic ferroviaire",
   code_72212150:"Services de développement de logiciels de contrôle industriel",
   code_72212160:"Services de développement de logiciels de bibliothèques",
   code_72212170:"Services de développement de logiciels de vérification de conformité",
   code_72212180:"Services de développement de logiciels médicaux",
   code_72212190:"Services de développement de logiciels pédagogiques",
   code_72212200:"Services de développement de logiciels de gestion de réseau, d'internet et d'intranet",
   code_72212210:"Services de développement de logiciels de gestion de réseau",
   code_72212211:"Services de développement de logiciels d'interconnectivité de plates-formes",
   code_72212212:"Services de développement de logiciels de serveur à mémoire optique à disques",
   code_72212213:"Services de développement de logiciels d'amélioration des systèmes d'exploitation",
   code_72212214:"Services de développement de logiciels de gestion des systèmes d'exploitation réseau",
   code_72212215:"Services de développement de logiciels de développement de réseaux",
   code_72212216:"Services de développement de logiciels d'émulation de terminaux de connectivité au réseau",
   code_72212217:"Services de développement de logiciels transactionnels",
   code_72212218:"Services de développement de logiciels de gestion des licences",
   code_72212219:"Services de développement de logiciels de gestion de réseau divers",
   code_72212220:"Services de développement de logiciels de gestion d'internet et d'intranet",
   code_72212221:"Services de développement de logiciels de navigation sur l'internet",
   code_72212222:"Services de développement de logiciels de serveurs web",
   code_72212223:"Services de développement de logiciels de courrier électronique",
   code_72212224:"Services de développement de logiciels d'édition de pages web",
   code_72212300:"Services de développement de logiciels de création de documents, de dessin, de traitement de l'image, d'ordonnancemennt et de productivité",
   code_72212310:"Services de développement de logiciels de création de documents",
   code_72212311:"Services de développement de logiciels de gestion de documents",
   code_72212312:"Services de développement de logiciels d'édition électronique",
   code_72212313:"Services de développement de logiciels de reconnaissance optique de caractères (OCR)",
   code_72212314:"Services de développement de logiciels de reconnaissance vocale",
   code_72212315:"Services de développement de logiciels de PAO",
   code_72212316:"Services de développement de logiciels de présentation",
   code_72212317:"Services de développement de logiciels de traitement de texte",
   code_72212318:"Services de développement de logiciels de scanner",
   code_72212320:"Services de développement de logiciels de dessin et de traitement de l'image",
   code_72212321:"Services de développement de logiciels de conception assistée par ordinateur (CAO)",
   code_72212322:"Services de développement de logiciels graphiques",
   code_72212323:"Services de développement de logiciels de production assistée par ordinateur (PAO)",
   code_72212324:"Services de développement de logiciels de tracé de diagrammes",
   code_72212325:"Services de développement de logiciels d'élaboration de formulaires",
   code_72212326:"Services de développement de logiciels de cartographie",
   code_72212327:"Services de développement de logiciels de dessin et de peinture",
   code_72212328:"Services de développement de logiciels de traitement de l'image",
   code_72212330:"Services de développement de logiciels de planification et de productivité",
   code_72212331:"Services de développement de logiciels de gestion de projet",
   code_72212332:"Services de développment de logiciels de planification",
   code_72212333:"Services de développement de logiciels de gestion des contacts",
   code_72212400:"Services de développement de logiciels transactionnels commerciaux et personnels",
   code_72212410:"Services de développement de logiciels de gestion des investissements et de préparation de déclarations de revenus",
   code_72212411:"Services de développement de logiciels de gestion des investissements",
   code_72212412:"Services de développement de logiciels de préparation de déclarations de revenus",
   code_72212420:"Services de développement de logiciels d'infogérance et services de développement de suites logicielles",
   code_72212421:"Services de développement de logiciels d'infogérance",
   code_72212422:"Services de développement de suites logicielles",
   code_72212430:"Services de développement de logiciels de gestion des stocks",
   code_72212440:"Services de développement de logiciels d'analyse financière et de logiciels comptables",
   code_72212441:"Services de développement de logiciels d'analyse financière",
   code_72212442:"Services de développement de logiciels financiers",
   code_72212443:"Services de développement de logiciels comptables",
   code_72212445:"Services de développement de logiciels de gestion des relations clientèle (CRM)",
   code_72212450:"Services de développement de logiciels d'enregistrement des horaires ou de logiciels de gestion des ressources humaines",
   code_72212451:"Services de développement de logiciels de planification des ressources de l'entreprise (ERP)",
   code_72212460:"Services de développement de logiciels analytiques, scientifiques, mathématiques ou prévisionnels",
   code_72212461:"Services de développement de logiciels analytiques ou scientifiques",
   code_72212462:"Services de développement de logiciels mathématiques ou prévisionnels",
   code_72212463:"Services de développement de logiciels statistiques",
   code_72212470:"Services de développement de logiciels de vente aux enchères",
   code_72212480:"Services de développement de logiciels de vente, de marketing et de veille économique",
   code_72212481:"Services de développement de logiciels de vente ou de marketing",
   code_72212482:"Services de développement de logiciels de veille économique",
   code_72212490:"Services de développement de logiciels de passation de marchés",
   code_72212500:"Services de développement de logiciels de communication et de logiciels multimédia",
   code_72212510:"Services de développement de logiciels de communication",
   code_72212511:"Services de développement de logiciels de communication de bureau",
   code_72212512:"Services de développement de logiciels de réponse vocale interactive",
   code_72212513:"Services de développement de logiciels pour modem",
   code_72212514:"Services de développement de logiciels d'accès à distance",
   code_72212515:"Services de développement de logiciels de vidéoconférence",
   code_72212516:"Services de développement de logiciels d'échange",
   code_72212517:"Services de développement de logiciels de technologies de l'information",
   code_72212518:"Services de développement de logiciels d'émulation",
   code_72212519:"Services de développement de logiciels de gestion de mémoire",
   code_72212520:"Services de développement de logiciels multimédias",
   code_72212521:"Services de développement de logiciels d'édition musicale ou sonore",
   code_72212522:"Services de développement de logiciels de clavier virtuel",
   code_72212600:"Services de développement de logiciels de bases de données et de logiciels d'exploitation",
   code_72212610:"Services de développement de logiciels de bases de données",
   code_72212620:"Services de développement de logiciels de système d'exploitation d'unité centrale",
   code_72212630:"Services de développement de logiciels de système d'exploitation de mini-ordinateur",
   code_72212640:"Services de développement de logiciels de système d'exploitation de micro-ordinateur",
   code_72212650:"Services de développement de logiciels de système d'exploitation d'ordinateur personnel",
   code_72212660:"Services de développement de logiciels de groupage",
   code_72212670:"Services de développement de logiciels de gestion du système d'exploitation en temps réel",
   code_72212700:"Services de développement de logiciels utilitaires",
   code_72212710:"Services de développement de logiciels de copie de sécurité ou de récupération",
   code_72212720:"Services de développement de logiciels de codes-barres",
   code_72212730:"Services de développement de logiciels de sécurité",
   code_72212731:"Services de développement de logiciels de sécurité des fichiers",
   code_72212732:"Services de développement de logiciels de sécurité des données",
   code_72212740:"Services de développement de logiciels de traduction de langues étrangères",
   code_72212750:"Services de développement de logiciels de chargement des supports d'enregistrement",
   code_72212760:"Services de développement de logiciels de protection contre les virus",
   code_72212761:"Services de développement de logiciels antivirus",
   code_72212770:"Services de développement de logiciels utilitaires généraux, de compression et d'impression",
   code_72212771:"Services de développement de logiciels utilitaires généraux",
   code_72212772:"Services de développement de logiciels utilitaires d'impression",
   code_72212780:"Services de développement de logiciels de gestion des systèmes, de stockage et de contenu",
   code_72212781:"Services de développement de logiciels de gestion des systèmes",
   code_72212782:"Services de développement de logiciels de gestion de stockage",
   code_72212783:"Services de développement de logiciels de gestion de contenu",
   code_72212790:"Services de développement de logiciels de vérifications des versions",
   code_72212900:"Services de développement de logiciels divers et systèmes informatiques",
   code_72212910:"Services de développement de logiciels de jeu vidéo, de jeux pour la famille et d'économiseurs d'écran",
   code_72212911:"Services de développement de logiciels de jeux vidéo",
   code_72212920:"Services de développement de logiciels de bureautique",
   code_72212930:"Services de développement de logiciels de formation et de divertissement",
   code_72212931:"Services de développement de logiciels de formation et de divertissement",
   code_72212932:"Services de développement de logiciels de divertissement",
   code_72212940:"Services de développement de logiciels de création de patrons et de gestion des calendriers",
   code_72212941:"Services de développement de logiciels de création de patrons",
   code_72212942:"Services de développement de logiciels de gestion des calendriers",
   code_72212960:"Services de développement de logiciels de gestion des pilotes et de systèmes",
   code_72212970:"Services de développement de logiciels d'impression",
   code_72212971:"Services de développement de logiciels de création de carnets d'adresses",
   code_72212972:"Services de développement de logiciels d'élaboration d'étiquettes",
   code_72212980:"Services de développement de langages de programmation et d'outils",
   code_72212981:"Services de développement de logiciels de compilation",
   code_72212982:"Services de développement de logiciels de gestion de la configuration",
   code_72212983:"Services de développement de logiciels de développement",
   code_72212984:"Services de développement de logiciels d'essai de programmes",
   code_72212985:"Services de développement de logiciels de débogage",
   code_72212990:"Services de développement de logiciels de tableur et d'amélioration de tableur",
   code_72212991:"Services de développement de logiciels de tableur",
   code_72220000:"Services de conseil en systèmes informatiques et conseils techniques",
   code_72221000:"Services de conseil en analyse d'exploitation",
   code_72222000:"Services d'analyse stratégique et de planification de systèmes ou de technologies de l'information",
   code_72222100:"Services d'analyse stratégique de systèmes ou de technologies de l'information",
   code_72222200:"Services de planification de systèmes ou de technologies de l'information",
   code_72222300:"Services de technologies de l'information",
   code_72223000:"Services d'analyse des exigences de la technologie de l'information",
   code_72224000:"Services de conseil en gestion de projet",
   code_72224100:"Services de planification de la mise en application de systèmes",
   code_72224200:"Services de planification de l'assurance qualité des systèmes",
   code_72225000:"Services d'évaluation et d'analyse de l'assurance qualité des systèmes",
   code_72226000:"Services de conseil en essai d'acceptation de logiciels d'exploitation",
   code_72227000:"Services de conseil en intégration de logiciels",
   code_72228000:"Services de conseil en intégration de matériel informatique",
   code_72230000:"Services de développement de logiciels personnalisés",
   code_72231000:"Développement de logiciels pour applications militaires",
   code_72232000:"Développement de logiciels transactionnels et de logiciels personnalisés",
   code_72240000:"Services d'analyses et de programmation de systèmes",
   code_72241000:"Services de spécification de cibles de projets critiques",
   code_72242000:"Services de modélisation de projets",
   code_72243000:"Services de programmation",
   code_72244000:"Services de prototypage",
   code_72245000:"Services contractuels d'analyse et de programmation de systèmes",
   code_72246000:"Services de conseil en systèmes informatiques",
   code_72250000:"Services de maintenance des systèmes et services d'assistance",
   code_72251000:"Services de reprise des applications informatiques en cas d'interruption accidentelle",
   code_72252000:"Services d'archivage informatique",
   code_72253000:"Services d'aide aux utilisateurs et services d'assistance",
   code_72253100:"Services d'aide aux utilisateurs",
   code_72253200:"Services d'assistance relative aux systèmes",
   code_72254000:"Services d'essais de logiciels",
   code_72254100:"Services d'essais de systèmes",
   code_72260000:"Services relatifs aux logiciels",
   code_72261000:"Services d'assistance relative aux logiciels",
   code_72262000:"Services de développement de logiciels",
   code_72263000:"Services d'implémentation de logiciels",
   code_72264000:"Services de reproduction de logiciels",
   code_72265000:"Services de configuration de logiciels",
   code_72266000:"Services de conseil en logiciels",
   code_72267000:"Services de maintenance et de réparation de logiciels",
   code_72267100:"Maintenance de logiciels de technologies de l'information",
   code_72267200:"Réparation de logiciels de technologies de l'information",
   code_72268000:"Services de fourniture de logiciels",
   code_72300000:"Services de commutation de données",
   code_72310000:"Services de traitement de données",
   code_72311000:"Services informatiques de tabulation",
   code_72311100:"Services de conversion de données",
   code_72311200:"Services de traitement par lots",
   code_72311300:"Services informatiques d'exploitation en temps partagé",
   code_72312000:"Services de saisie de données",
   code_72312100:"Services de préparation de données",
   code_72312200:"Services de reconnaissance optique de caractères",
   code_72313000:"Services de capture de données",
   code_72314000:"Services de collecte et de collation de données",
   code_72315000:"Services de gestion et d'assistance relatifs aux réseaux de données",
   code_72315100:"Services d'assistance relative aux réseaux informatiques",
   code_72315200:"Services de gestion de réseaux informatiques",
   code_72316000:"Services d'analyse de données",
   code_72317000:"Services de stockage de données",
   code_72318000:"Services de transmission de données",
   code_72319000:"Services de fourniture de données",
   code_72320000:"Services de bases de données",
   code_72321000:"Services de bases de données à valeur ajoutée",
   code_72322000:"Services de gestion de données",
   code_72330000:"Services de normalisation et de classification des contenus et des données",
   code_72400000:"Services internet",
   code_72410000:"Services de fournisseurs",
   code_72411000:"Fournisseur de services internet (ISP)",
   code_72412000:"Fournisseur de services de courrier électronique",
   code_72413000:"Services de conception de sites WWW (World Wide Web)",
   code_72414000:"Fournisseurs de moteurs de recherche sur le web",
   code_72415000:"Services d'hébergement pour l'exploitatuion de sites WWW",
   code_72416000:"Fournisseurs de services applicatifs",
   code_72417000:"Noms de domaine internet",
   code_72420000:"Services de développement de l'internet",
   code_72421000:"Services de développement des applications client internet ou intranet",
   code_72422000:"Services de développement des applications serveur internet ou intranet",
   code_72500000:"Services informatiques",
   code_72510000:"Services de gestion relatifs à l'informatique",
   code_72511000:"Services de logiciels de gestion de réseau",
   code_72512000:"Services de gestion de documents",
   code_72513000:"Services de bureautique",
   code_72514000:"Services de gestion des installations informatiques",
   code_72514100:"Services de gestion d'installations impliquant des activités informatiques",
   code_72514200:"Services de gestion d'installations pour le développement de systèmes informatiques",
   code_72514300:"Services de gestion d'installations pour la maintenance de systèmes informatiques",
   code_72540000:"Services de mise à jour d'ordinateurs",
   code_72541000:"Services d'extension informatique",
   code_72541100:"Services d'extension de mémoire",
   code_72590000:"Services informatiques professionnels",
   code_72591000:"Développement d'accords de niveau de service",
   code_72600000:"Services d'assistance et de conseils informatiques",
   code_72610000:"Services d'assistance informatique",
   code_72611000:"Services d'assistance technique informatique",
   code_72700000:"Services de réseaux informatiques",
   code_72710000:"Services de réseau local",
   code_72720000:"Services de réseau à grande distance",
   code_72800000:"Services d'audit informatique et services d'essai informatique",
   code_72810000:"Services d'audit informatique",
   code_72820000:"Services d'essai informatique",
   code_72900000:"Services de secours informatique et services informatiques de conversion de catalogues",
   code_72910000:"Services de secours informatique",
   code_72920000:"Services informatiques de conversion de catalogues",
   code_73000000:"Services de recherche et développement et services de conseil connexes",
   code_73100000:"Services de recherche et développement expérimental",
   code_73110000:"Services de recherche",
   code_73111000:"Services de laboratoire de recherche",
   code_73112000:"Services de recherche marine",
   code_73120000:"Services de développement expérimental",
   code_73200000:"Services de conseil en recherche et développement",
   code_73210000:"Services de conseil en recherche",
   code_73220000:"Services de conseil en développement",
   code_73300000:"Conception et exécution dans le domaine de la recherche et du développement",
   code_73400000:"Services de recherche et développement en matière de matériels de sécurité et de défense",
   code_73410000:"Recherche et technologie militaires",
   code_73420000:"Étude de préfaisabilité et démonstration technique",
   code_73421000:"Développement de matériel de sécurité",
   code_73422000:"Développement d'armes à feu et de munitions",
   code_73423000:"Développement de véhicules militaires",
   code_73424000:"Développement de navires de guerre",
   code_73425000:"Développement d'aréonefs, de missiles et de spationefs militaires",
   code_73426000:"Développement de systèmes électroniques militaires",
   code_73430000:"Test et évaluation",
   code_73431000:"Test et évaluation de matériel de sécurité",
   code_73432000:"Test et évaluation des armes à feu et des munitions",
   code_73433000:"Test et évaluation des véhicules militaires",
   code_73434000:"Test et évaluation des navires de guerre",
   code_73435000:"Test et évaluation des aéronefs, des missiles et des spationefs militaires",
   code_73436000:"Test et évaluation des systèmes électroniques militaires",
   code_75000000:"Services de l'administration publique, de la défense et de la sécurité sociale",
   code_75100000:"Services de l'administration publique",
   code_75110000:"Services d'administration générale",
   code_75111000:"Services du législatif et de l'exécutif",
   code_75111100:"Services de l'exécutif",
   code_75111200:"Services du législatif",
   code_75112000:"Services administratifs relatifs aux activités des entreprises",
   code_75112100:"Services administratifs relatifs aux projets de développement",
   code_75120000:"Services administratifs d'agences",
   code_75121000:"Services administratifs de l'enseignement",
   code_75122000:"Services administratifs de la santé",
   code_75123000:"Services administratifs du logement",
   code_75124000:"Services administratifs récréatifs, culturels et religieux",
   code_75125000:"Services administratifs dans le secteur du tourisme",
   code_75130000:"Services d'appui aux pouvoirs publics",
   code_75131000:"Services des pouvoirs publics",
   code_75131100:"Services généraux de personnel pour les pouvoirs publics",
   code_75200000:"Prestations de services pour la collectivité",
   code_75210000:"Affaires étrangères et autres services",
   code_75211000:"Services des affaires étrangères",
   code_75211100:"Services diplomatiques",
   code_75211110:"Services consulaires",
   code_75211200:"Services liés à l'aide économique aux pays étrangers",
   code_75211300:"Services liés à l'aide militaire aux pays étrangers",
   code_75220000:"Services de défense",
   code_75221000:"Services de la défense",
   code_75222000:"Services de protection civile",
   code_75230000:"Services de la justice",
   code_75231000:"Services judiciaires",
   code_75231100:"Services administratifs des tribunaux",
   code_75231200:"Services liés à la détention ou à la réhabilitation de criminels",
   code_75231210:"Services d'incarcération",
   code_75231220:"Services d'escorte de prisonniers",
   code_75231230:"Services de l'administration pénitentiaire",
   code_75231240:"Services de réinsertion",
   code_75240000:"Services de sécurité et d'ordre public",
   code_75241000:"Services de sécurité publique",
   code_75241100:"Services de police",
   code_75242000:"Services d'ordre et de droit publics",
   code_75242100:"Services d'ordre public",
   code_75242110:"Services d'huissier de justice",
   code_75250000:"Services d'incendie et de secours",
   code_75251000:"Services d'incendie",
   code_75251100:"Services de lutte contre l'incendie",
   code_75251110:"Services de prévention des incendies",
   code_75251120:"Services de lutte contre les incendies de forêt",
   code_75252000:"Services de sauvetage",
   code_75300000:"Services de sécurité sociale obligatoire",
   code_75310000:"Services de prestations",
   code_75311000:"Indemnités de maladie",
   code_75312000:"Indemnités de maternité",
   code_75313000:"Indemnités d'invalidité",
   code_75313100:"Indemnités d'incapacité temporaire",
   code_75314000:"Indemnités de chômage",
   code_75320000:"Régimes de pensions des fonctionnaires de l'État",
   code_75330000:"Prestations familiales",
   code_75340000:"Allocations familiales",
   code_76000000:"Services relatifs à l'industrie du pétrole et du gaz",
   code_76100000:"Services professionnels relatifs à l'industrie du gaz",
   code_76110000:"Services relatifs à l'extraction du gaz",
   code_76111000:"Services de regazéification",
   code_76120000:"Services de plongée liés à l'extraction du gaz",
   code_76121000:"Services de plongée liés à l'extraction du pétrole",
   code_76200000:"Services professionnels relatifs à l'industrie pétrolière",
   code_76210000:"Services liés à l'extraction du pétrole",
   code_76211000:"Services de suspension de la colonne perdue",
   code_76211100:"Services de chemisage",
   code_76211110:"Services de chemisage d'essai",
   code_76211120:"Services de chemisage à l'emplacement du puits",
   code_76211200:"Services de diagraphie des boues de forage",
   code_76300000:"Services de forage",
   code_76310000:"Services de forage liés à l'extraction du gaz",
   code_76320000:"Services de forage offshore",
   code_76330000:"Services de forage par turbine",
   code_76331000:"Services de forage par turbine spiralée",
   code_76340000:"Services de carottage",
   code_76400000:"Services de positionnement d'appareils de forage",
   code_76410000:"Services de cuvelage et de tubage de puits",
   code_76411000:"Services de cuvelage de puits",
   code_76411100:"Services de fourniture de personnel pour le cuvelage de puits",
   code_76411200:"Services de planification pour le cuvelage de puits",
   code_76411300:"Services de fraisage pour le cuvelage de puits",
   code_76411400:"Services d'achèvement du cuvelage de puits",
   code_76420000:"Services de cimentation de puits",
   code_76421000:"Services de cimentation en colonne de tubage perdue",
   code_76422000:"Services de cimentation des obturateurs",
   code_76423000:"Services de cimentation mousse",
   code_76430000:"Services de forage de puits et de production",
   code_76431000:"Services de forage de puits",
   code_76431100:"Services de contrôle de forage de puits",
   code_76431200:"Services d'extraction des tubes de forage de puits",
   code_76431300:"Services de dévissage des trains de tiges pour forage de puits",
   code_76431400:"Services de forage des trous de rat",
   code_76431500:"Services de suivi de forage de puits",
   code_76431600:"Services de suivi des appareils de forage de puits",
   code_76440000:"Services de diagraphie des sondages",
   code_76441000:"Services de diagraphie de puits tubé",
   code_76442000:"Services de diagraphie de puits non tubé",
   code_76443000:"Autres services de diagraphie",
   code_76450000:"Services de gestion de puits",
   code_76460000:"Services d'assistance relative aux puits",
   code_76470000:"Services d'essais de puits",
   code_76471000:"Services d'essais de fracturation de puits",
   code_76472000:"Services d'inspection ou d'essais de puits",
   code_76473000:"Services d'essais des équipement de puits",
   code_76480000:"Services de tubage",
   code_76490000:"Services d'achèvement de puits",
   code_76491000:"Services de bouchage de puits",
   code_76492000:"Services de positionnement de puits",
   code_76500000:"Services onshore et offshore",
   code_76510000:"Services onshore",
   code_76520000:"Services offshore",
   code_76521000:"Services d'installation offshore",
   code_76522000:"Services offshore de navires ravitailleurs",
   code_76530000:"Services de fond de puits",
   code_76531000:"Services de diagraphie en fond de puits",
   code_76532000:"Services de pompage de fond de puits",
   code_76533000:"Services d'enregistrement de fond de puits",
   code_76534000:"Services d'élargissement de fond de puits",
   code_76535000:"Services de forage de fond de puits",
   code_76536000:"Services de contrôle des vibrations de fond de puits",
   code_76537000:"Services d'outillage de fond de puits",
   code_76537100:"Services d'outillage pétrolier de fond de puits",
   code_76600000:"Services de contrôle de pipelines",
   code_77000000:"Services agricoles, sylvicoles, horticoles, d'aquaculture et d'apiculture",
   code_77100000:"Services agricoles",
   code_77110000:"Services liés à la production agricole",
   code_77111000:"Location de matériel agricole avec opérateur",
   code_77112000:"Location de faucheuses ou de matériel agricole avec opérateur",
   code_77120000:"Services de compostage",
   code_77200000:"Services sylvicoles",
   code_77210000:"Services de débardage",
   code_77211000:"Services liés au débardage",
   code_77211100:"Services d'exploitation forestière",
   code_77211200:"Transport de grumes dans les forêts",
   code_77211300:"Services de défrichement",
   code_77211400:"Services d'abattage d'arbres",
   code_77211500:"Services d'élagage",
   code_77211600:"Plantation d'arbres par ensemencement",
   code_77220000:"Services d'imprégnation du bois",
   code_77230000:"Services liés à la sylviculture",
   code_77231000:"Services de gestion des forêts",
   code_77231100:"Services de gestion des ressources forestières",
   code_77231200:"Services de lutte contre les animaux nuisibles de la forêt",
   code_77231300:"Services d'administration forestière",
   code_77231400:"Services d'inventaire forestier",
   code_77231500:"Services de surveillance ou d'évaluation des forêts",
   code_77231600:"Services de boisement",
   code_77231700:"Services d'extension forestière",
   code_77231800:"Services de gestion de pépinières de forêt",
   code_77231900:"Services de planification sectorielle de la forêt",
   code_77300000:"Services horticoles",
   code_77310000:"Réalisation et entretien d'espaces verts",
   code_77311000:"Entretien de pelouses décoratives ou d'agrément",
   code_77312000:"Services d'enlèvement des mauvaises herbes",
   code_77312100:"Services de désherbage",
   code_77313000:"Services d'entretien de parcs",
   code_77314000:"Services d'entretien de terrains",
   code_77314100:"Services d'engazonnement",
   code_77315000:"Services d'ensemencement",
   code_77320000:"Entretien de pelouses sportives",
   code_77330000:"Services d'exposition florale",
   code_77340000:"Élagage des arbres et taille des haies",
   code_77341000:"Élagage des arbres",
   code_77342000:"Taille des haies",
   code_77400000:"Services zoologiques",
   code_77500000:"Services d'élevage",
   code_77510000:"Services de propagation du gibier",
   code_77600000:"Services liés à la chasse",
   code_77610000:"Services de pose de pièges",
   code_77700000:"Services liés à la pêche",
   code_77800000:"Services liés à l'aquaculture",
   code_77810000:"Services liés à la mariculture",
   code_77820000:"Services liés à l'ostréiculture",
   code_77830000:"Services liés à la conchyliculture",
   code_77840000:"Services liés à la crevetticulture",
   code_77850000:"Services liés à la pisciculture",
   code_77900000:"Services liés à l'apiculture",
   code_79000000:"Services aux entreprises: droit, marketing, conseil, recrutement, impression et sécurité",
   code_79100000:"Services juridiques",
   code_79110000:"Services de conseils et de représentation juridiques",
   code_79111000:"Services de conseil juridique",
   code_79112000:"Services de représentation légale",
   code_79112100:"Services de représentation des parties prenantes",
   code_79120000:"Services de conseils en matière de brevets et de droits d'auteurs",
   code_79121000:"Services de conseils en matière de droits d'auteur",
   code_79121100:"Services de conseils en matière de droits d'auteurs de logiciels",
   code_79130000:"Services de documentation et de certification juridiques",
   code_79131000:"Services de documentation",
   code_79132000:"Services de certification",
   code_79132100:"Services de certification de signature électronique",
   code_79140000:"Services de conseils et d'information juridiques",
   code_79200000:"Services de comptabilité, services d'audit et services fiscaux",
   code_79210000:"Services de comptabilité et d'audit",
   code_79211000:"Services de comptabilité",
   code_79211100:"Services comptables",
   code_79211110:"Services de gestion des salaires",
   code_79211120:"Services d'enregistrement des ventes et des achats",
   code_79211200:"Services d'établissement d'états financiers",
   code_79212000:"Services d'audit",
   code_79212100:"Services d'audit financier",
   code_79212110:"Services de notation en matière de gouvernance d'entreprise",
   code_79212200:"Services d'audit interne",
   code_79212300:"Services de contrôle légal des comptes",
   code_79212400:"Services de vérification des fraudes",
   code_79212500:"Services de vérification comptable",
   code_79220000:"Services fiscaux",
   code_79221000:"Services de conseils fiscaux",
   code_79222000:"Services de préparation des déclarations d'impôts",
   code_79223000:"Services de courtage des douanes",
   code_79300000:"Études de marché et recherche économique; sondages et statistiques",
   code_79310000:"Services d'études de marchés",
   code_79311000:"Services d'études",
   code_79311100:"Services de conception d'études",
   code_79311200:"Services de réalisation d'enquêtes",
   code_79311210:"Services d'enquête téléphonique",
   code_79311300:"Services d'analyses d'enquêtes",
   code_79311400:"Services de recherche économique",
   code_79311410:"Services d'évaluation de l'impact économique",
   code_79312000:"Services de test de marché",
   code_79313000:"Services d'évaluation des performances",
   code_79314000:"Étude de faisabilité",
   code_79315000:"Services de recherche sociale",
   code_79320000:"Services de sondages d'opinion",
   code_79330000:"Services statistiques",
   code_79340000:"Services de publicité et de marketing",
   code_79341000:"Services de publicité",
   code_79341100:"Services de conseils en publicité",
   code_79341200:"Services de gestion publicitaire",
   code_79341400:"Services de campagne publicitaire",
   code_79341500:"Services de publicité par voie aérienne",
   code_79342000:"Services de marketing",
   code_79342100:"Services de marketing direct",
   code_79342200:"Services de promotion",
   code_79342300:"Services à la clientèle",
   code_79342310:"Services d'enquête auprès des consommateurs",
   code_79342311:"Services d'enquête sur la satisfaction des consommateurs",
   code_79342320:"Services après-vente",
   code_79342321:"Programme de fidélisation du client",
   code_79342400:"Services d'enchères",
   code_79342410:"Services d'enchères électroniques",
   code_79400000:"Conseil en affaires et en gestion et services connexes",
   code_79410000:"Services de conseil en affaires et en gestion",
   code_79411000:"Services de conseil en gestion générale",
   code_79411100:"Services de conseil en développement des entreprises",
   code_79412000:"Services de conseil en gestion financière",
   code_79413000:"Services de conseil en gestion du marketing",
   code_79414000:"Services de conseil en gestion des ressources humaines",
   code_79415000:"Services de conseil en gestion de la production",
   code_79415200:"Services de conseil en conception",
   code_79416000:"Services de relations publiques",
   code_79416100:"Services de gestion des relations publiques",
   code_79416200:"Services de conseil en relations publiques",
   code_79417000:"Services de conseil en matière de sécurité",
   code_79418000:"Services de conseil en matière d'acquisitions",
   code_79419000:"Services de conseil et d'évaluation",
   code_79420000:"Services relatifs à la gestion",
   code_79421000:"Services de gestion de projets autres que pour les travaux de construction",
   code_79421100:"Services de supervision de projets autres que pour les travaux de construction",
   code_79421200:"Services de conception de projets autres que pour les travaux de construction",
   code_79422000:"Services d'arbitrage et de conciliation",
   code_79430000:"Services de gestion de crise",
   code_79500000:"Services d'appui bureautiques",
   code_79510000:"Services de répondeur téléphonique",
   code_79511000:"Services de standard téléphonique",
   code_79512000:"Centre d'appels",
   code_79520000:"Services de reprographie",
   code_79521000:"Services de photocopie",
   code_79530000:"Services de traduction",
   code_79540000:"Services d'interprétation",
   code_79550000:"Services de dactylographie, de traitement de texte et de publication assistée par ordinateur",
   code_79551000:"Services de dactylographie",
   code_79552000:"Services de traitement de texte",
   code_79553000:"Services de publication assistée par ordinateur",
   code_79560000:"Services de classement",
   code_79570000:"Services d'établissement de listes d'adresses et services d'envoi",
   code_79571000:"Services d'envoi",
   code_79600000:"Services de recrutement",
   code_79610000:"Services de placement de personnel",
   code_79611000:"Services de recherche d'emplois",
   code_79612000:"Services de placement de personnel de bureau",
   code_79613000:"Services de déplacement d'employés",
   code_79620000:"Services de mise à disposition de personnel, y compris de personnel temporaire",
   code_79621000:"Services de mise à disposition de personnel de bureau",
   code_79622000:"Services de mise à disposition de personnel d'aide à domicile",
   code_79623000:"Services de mise à disposition de personnel commercial ou industriel",
   code_79624000:"Services de mise à disposition de personnel infirmier",
   code_79625000:"Services de mise à disposition de personnel médical",
   code_79630000:"Services de gestion du personnel, excepté services de placement et de mise à disposition",
   code_79631000:"Services du personnel et des salaires",
   code_79632000:"Services de formation de personnel",
   code_79633000:"Services de développement du personnel",
   code_79634000:"Services d'orientation professionnelle",
   code_79635000:"Services de centres d'évaluation en vue du recrutement",
   code_79700000:"Services d'enquête et de sécurité",
   code_79710000:"Services de sécurité",
   code_79711000:"Services de surveillance d'installations d'alarme",
   code_79713000:"Services de gardiennage",
   code_79714000:"Services de surveillance",
   code_79714100:"Services de localisation",
   code_79714110:"Services de recherche de fugitifs",
   code_79715000:"Services de patrouille",
   code_79716000:"Services de fourniture de badges d'identification",
   code_79720000:"Services d'enquêtes",
   code_79721000:"Services d'agences de détectives",
   code_79722000:"Services de graphologie",
   code_79723000:"Services d'analyse des déchets",
   code_79800000:"Services d'impression et services connexes",
   code_79810000:"Services d'impression",
   code_79811000:"Services d'impression numérique",
   code_79812000:"Services d'impression de billets de banque",
   code_79820000:"Services relatifs à l'impression",
   code_79821000:"Services de finition d'imprimés",
   code_79821100:"Services de correction d'épreuves",
   code_79822000:"Services de composition",
   code_79822100:"Services de préparation de plaques d'impression",
   code_79822200:"Services de photogravure",
   code_79822300:"Services de composition typographique",
   code_79822400:"Services de lithographie",
   code_79822500:"Services de conception graphique",
   code_79823000:"Services d'impression et de livraison",
   code_79824000:"Services d'impression et de distribution",
   code_79900000:"Services divers aux entreprises et services connexes",
   code_79910000:"Services de holdings de gestion",
   code_79920000:"Services de conditionnement et services connexes",
   code_79921000:"Services de conditionnement",
   code_79930000:"Conception de modèles",
   code_79931000:"Services de décoration d'intérieur",
   code_79932000:"Services d'architecture d'intérieur",
   code_79933000:"Services d'assistance à la conception",
   code_79934000:"Services de conception de mobilier",
   code_79940000:"Services des agences de recouvrement",
   code_79941000:"Services de péage",
   code_79950000:"Services d'organisation d'expositions, de foires et de congrès",
   code_79951000:"Services d'organisation de séminaires",
   code_79952000:"Services d'organisation d'événements",
   code_79952100:"Services d'organisation d'événements culturels",
   code_79953000:"Services d'organisation de festivals",
   code_79954000:"Services d'organisation de fêtes",
   code_79955000:"Services d'organisation de défilés de mode",
   code_79956000:"Services d'organisation de foires et d'expositions",
   code_79957000:"Services d'organisation de ventes aux enchères",
   code_79960000:"Services photographiques et services connexes",
   code_79961000:"Services photographiques",
   code_79961100:"Services de photographie publicitaire",
   code_79961200:"Services de photographie aérienne",
   code_79961300:"Services de photographie spécialisée",
   code_79961310:"Services de photographie en fond de trou",
   code_79961320:"Services de photographie sous-marine",
   code_79961330:"Services de microfilmage",
   code_79961340:"Services de radiographie",
   code_79961350:"Services de photographie en studio",
   code_79962000:"Services de développement de photographies",
   code_79963000:"Services de restauration, de copie et de retouche de photographies",
   code_79970000:"Services d'édition",
   code_79971000:"Services de reliure et de finition",
   code_79971100:"Services de finition de livres",
   code_79971200:"Services de reliure",
   code_79972000:"Services d'édition de dictionnaires de langue",
   code_79972100:"Services d'édition de dictionnaires de langue régionale",
   code_79980000:"Services d'abonnement",
   code_79990000:"Services divers aux entreprises",
   code_79991000:"Services de contrôle des stocks",
   code_79992000:"Services de réception",
   code_79993000:"Services de gestion d'immeubles et d'installations",
   code_79993100:"Services de gestion d'installations",
   code_79994000:"Services de gestion des contrats",
   code_79995000:"Services de gestion de bibliothèque",
   code_79995100:"Services d'archivage",
   code_79995200:"Services de catalogage",
   code_79996000:"Services d'organisation économique",
   code_79996100:"Gestion des documents",
   code_79997000:"Services de voyages d'affaires",
   code_79998000:"Services d'accompagnement professionnel",
   code_79999000:"Services de scanning et services de facturation",
   code_79999100:"Services de scanning",
   code_79999200:"Services de facturation",
   code_80000000:"Services d'enseignement et de formation",
   code_80100000:"Services d'enseignement primaire",
   code_80110000:"Services d'enseignement préscolaire",
   code_80200000:"Services d'enseignement secondaire",
   code_80210000:"Services d'enseignement secondaire technique et professionnel",
   code_80211000:"Services d'enseignement secondaire technique",
   code_80212000:"Services d'enseignement secondaire professionnel",
   code_80300000:"Services d'enseignement supérieur",
   code_80310000:"Services de formation pour la jeunesse",
   code_80320000:"Services de formation médicale",
   code_80330000:"Services de formation en matière de sécurité",
   code_80340000:"Services d'enseignement spécialisé",
   code_80400000:"Services d'éducation des adultes et autres services d'enseignement",
   code_80410000:"Services scolaires divers",
   code_80411000:"Services des auto-écoles",
   code_80411100:"Services d'épreuves de conduite automobile",
   code_80411200:"Cours de conduite",
   code_80412000:"Services des écoles d'aviation",
   code_80413000:"Services des écoles de voile",
   code_80414000:"Services des écoles de plongée",
   code_80415000:"Services des écoles de ski",
   code_80420000:"Services d'enseignement par voie électronique",
   code_80430000:"Services d'enseignement de niveau universitaire pour adultes",
   code_80490000:"Exploitation d'un centre d'enseignement",
   code_80500000:"Services de formation",
   code_80510000:"Services de formation spécialisée",
   code_80511000:"Services de formation du personnel",
   code_80512000:"Services de dressage de chiens",
   code_80513000:"Services d'écoles d'équitation",
   code_80520000:"Installations de formation",
   code_80521000:"Services relatifs aux programmes de formation",
   code_80522000:"Séminaires de formation",
   code_80530000:"Services de formation professionnelle",
   code_80531000:"Services de formation industrielle et technique",
   code_80531100:"Services de formation industrielle",
   code_80531200:"Services de formation technique",
   code_80532000:"Services de formation en gestion",
   code_80533000:"Services de formation et de familiarisation dans le domaine de l'informatique",
   code_80533100:"Services de formation informatique",
   code_80533200:"Cours d'informatique",
   code_80540000:"Services de formation dans le domaine de l'environnement",
   code_80550000:"Services de formation dans le domaine de la sécurité",
   code_80560000:"Services de formation dans le domaine de la santé et des premiers secours",
   code_80561000:"Services de formation dans le domaine de la santé",
   code_80562000:"Services de formation dans le domaine des premiers secours",
   code_80570000:"Services de formation pour l'épanouissement de la personnalité",
   code_80580000:"Fourniture de cours de langues",
   code_80590000:"Services d'aide pédagogique",
   code_80600000:"Services de formation dans le domaine des équipements militaires et de sécurité",
   code_80610000:"Formation et simulation dans le domaine des équipements de sécurité",
   code_80620000:"Formation et simulation dans le domaine des armes à feu et des munitions",
   code_80630000:"Formation et simulation dans le domaine des véhicules militaires",
   code_80640000:"Formation et simulation dans le domaine des navires de guerre",
   code_80650000:"Formation et simulation dans le domaines des aéronefs, des missiles et des spationefs militaires",
   code_80660000:"Formation et simulation dans le domaine des systèmes électroniques militaires",
   code_85000000:"Services de santé et services sociaux",
   code_85100000:"Services de santé",
   code_85110000:"Services hospitaliers et services connexes",
   code_85111000:"Services hospitaliers",
   code_85111100:"Services hospitaliers de chirurgie",
   code_85111200:"Services hospitaliers de médecine",
   code_85111300:"Services hospitaliers de gynécologie",
   code_85111310:"Services de fertilisation in vitro",
   code_85111320:"Services hospitaliers d'obstétrique",
   code_85111400:"Services hospitaliers de rééducation",
   code_85111500:"Services hospitaliers de psychiatrie",
   code_85111600:"Services d'orthèse",
   code_85111700:"Services d'oxygénothérapie",
   code_85111800:"Services de pathologie",
   code_85111810:"Services d'analyses de sang",
   code_85111820:"Services d'analyses bactériologiques",
   code_85111900:"Services hospitaliers de dialyse",
   code_85112000:"Services d'appui hospitalier",
   code_85112100:"Services de literie hospitalière",
   code_85112200:"Services de soins ambulatoires",
   code_85120000:"Services de pratique médicale et services connexes",
   code_85121000:"Services de pratique médicale",
   code_85121100:"Services prestés par les médecins généralistes",
   code_85121200:"Services prestés par les médecins spécialistes",
   code_85121210:"Services prestés par les gynécologues ou les obstétriciens",
   code_85121220:"Services prestés par les néphrologues ou services prestés par les neurologues",
   code_85121230:"Services prestés par les cardiologues ou services prestés par les pneumologues",
   code_85121231:"Services prestés par les cardiologues",
   code_85121232:"Services prestés par les pneumologues",
   code_85121240:"Services prestés par les ORL ou les audiologistes",
   code_85121250:"Services prestés par les gastro-entérologues et les gériatres",
   code_85121251:"Services prestés par les gastro-entérologues",
   code_85121252:"Services prestés par les gériatres",
   code_85121270:"Services prestés par les psychiatres ou les psychologues",
   code_85121271:"Services à domicile pour les personnes souffrant de perturbations psychologiques",
   code_85121280:"Services prestés par les ophthalmologues, les dermatologues et les orthopédistes",
   code_85121281:"Services prestés par les ophthalmologues",
   code_85121282:"Services prestés par les dermatologues",
   code_85121283:"Services prestés par les orthopédistes",
   code_85121290:"Services prestés par les pédiatres ou urologues",
   code_85121291:"Services prestés par les pédiatres",
   code_85121292:"Services prestés par les urologues",
   code_85121300:"Services prestés par les chirurgiens spécialistes",
   code_85130000:"Services de soins dentaires et services connexes",
   code_85131000:"Services de soins dentaires",
   code_85131100:"Services d'orthodontie",
   code_85131110:"Services de chirurgie orthodontique",
   code_85140000:"Services de santé divers",
   code_85141000:"Services prestés par le personnel médical",
   code_85141100:"Services prestés par les sages-femmes",
   code_85141200:"Services prestés par le personnel infirmier",
   code_85141210:"Services de soins médicaux à domicile",
   code_85141211:"Services de dialyse et de soins médicaux à domicile",
   code_85141220:"Services de conseil prestés par le personnel infirmier",
   code_85142000:"Services paramédicaux",
   code_85142100:"Services de physiothérapie",
   code_85142200:"Services d'homéopathie",
   code_85142300:"Services d'hygiène",
   code_85142400:"Livraison à domicile d'articles pour incontinents",
   code_85143000:"Services ambulanciers",
   code_85144000:"Services d'équipement de santé extrahospitalier",
   code_85144100:"Services de soins infirmiers de foyers",
   code_85145000:"Services prestés par les laboratoires médicaux",
   code_85146000:"Services prestés par les banques de sang",
   code_85146100:"Services prestés par les banques de sperme",
   code_85146200:"Services prestés par les banques d'organes",
   code_85147000:"Services de médecine du travail",
   code_85148000:"Services d'analyses médicales",
   code_85149000:"Services de pharmacie",
   code_85150000:"Services d'imagerie médicale",
   code_85160000:"Services prestés par les opticiens",
   code_85170000:"Services d'acupuncture et de chiropraxie",
   code_85171000:"Services d'acupuncture",
   code_85172000:"Services de chiropraxie",
   code_85200000:"Services vétérinaires",
   code_85210000:"Services d'animalerie",
   code_85300000:"Services d'action sociale et services connexes",
   code_85310000:"Services d'action sociale",
   code_85311000:"Services d'action sociale avec hébergement",
   code_85311100:"Services sociaux pour les personnes âgées",
   code_85311200:"Services sociaux pour les personnes handicapées",
   code_85311300:"Services sociaux pour les enfants et les adolescents",
   code_85312000:"Services sociaux sans hébergement",
   code_85312100:"Services de foyers de jour",
   code_85312110:"Services de crèches et garderies d'enfants",
   code_85312120:"Services de foyers de jour pour enfants et adolescents handicapés",
   code_85312200:"Livraison de provisions à domicile",
   code_85312300:"Services d'orientation et de conseil professionnel",
   code_85312310:"Services d'orientation",
   code_85312320:"Services de conseil professionnel",
   code_85312330:"Services de planning familial",
   code_85312400:"Services sociaux non prestés par les institutions résidentielles",
   code_85312500:"Services de réhabilitation",
   code_85312510:"Services de réhabilitation professionnelle",
   code_85320000:"Services sociaux",
   code_85321000:"Services sociaux administratifs",
   code_85322000:"Programme d'action communale",
   code_85323000:"Services médico-sociaux",
   code_90000000:"Services d'évacuation des eaux usées et d'élimination des déchets, services d'hygiénisation et services relatifs à l'environnement",
   code_90400000:"Services des eaux usées",
   code_90410000:"Services de collecte des eaux usées",
   code_90420000:"Services de traitement des eaux usées",
   code_90430000:"Services d'élimination des eaux usées",
   code_90440000:"Services de traitement pour puisards",
   code_90450000:"Services de traitement pour fosses septiques",
   code_90460000:"Services de vidange de puisards ou de fosses septiques",
   code_90470000:"Services de curage des égouts",
   code_90480000:"Service de gestion du réseau d'assainissement",
   code_90481000:"Exploitation d'une station de traitement des eaux d'égout",
   code_90490000:"Services d'inspection des égouts et services de conseil en matière de traitement des eaux usées",
   code_90491000:"Services d'inspection des égouts",
   code_90492000:"Services de conseil en traitement des eaux usées",
   code_90500000:"Services liés aux déchets et aux ordures",
   code_90510000:"Élimination et traitement des ordures",
   code_90511000:"Services de collecte des ordures",
   code_90511100:"Services de collecte de déchets solides urbains",
   code_90511200:"Services de collecte des ordures ménagères",
   code_90511300:"Services de collecte des déchets sauvages",
   code_90511400:"Services de collecte du papier",
   code_90512000:"Services de transport des ordures ménagères",
   code_90513000:"Services de traitement et d'élimination des ordures ménagères et des déchets non dangereux",
   code_90513100:"Services d'élimination des ordures ménagères",
   code_90513200:"Services d'élimination de déchets solides urbains",
   code_90513300:"Services d'incinération des ordures ménagères",
   code_90513400:"Services d'évacuation de cendres",
   code_90513500:"Traitement et évacuation de résidus liquides",
   code_90513600:"Services d'élimination des boues",
   code_90513700:"Services de transport des boues",
   code_90513800:"Services de traitement des boues",
   code_90513900:"Services d'évacuation des boues",
   code_90514000:"Services de recyclage des ordures ménagères",
   code_90520000:"Services relatifs aux déchets radioactifs, toxiques, médicaux et dangereux",
   code_90521000:"Services de traitement des déchets radioactifs",
   code_90521100:"Collecte de déchets radioactifs",
   code_90521200:"Services de stockage des déchets radioactifs",
   code_90521300:"Élimination de déchets radioactifs",
   code_90521400:"Transport de déchets radioactifs",
   code_90521410:"Transport de déchets radioactifs de faible activité",
   code_90521420:"Transport de déchets radioactifs d'activité intermédiaire",
   code_90521500:"Colis de déchets radioactifs",
   code_90521510:"Colis de déchets radioactifs de faible activité",
   code_90521520:"Colis de déchets radioactifs d'activité intermédiaire",
   code_90522000:"Services concernant les sols contaminés",
   code_90522100:"Enlèvement de sols contaminés",
   code_90522200:"Élimination de sols contaminés",
   code_90522300:"Services de traitement de sols contaminés",
   code_90522400:"Nettoyage et traitement du sol",
   code_90523000:"Services d'élimination de déchets toxiques, excepté déchets radioactifs et sols contaminés",
   code_90523100:"Services d'élimination d'armes et de munitions",
   code_90523200:"Services de neutralisation des bombes",
   code_90523300:"Services de dragage de mines",
   code_90524000:"Services relatifs aux déchets médicaux",
   code_90524100:"Services de collecte de déchets hospitaliers",
   code_90524200:"Services d'élimination de déchets hospitaliers",
   code_90524300:"Services d'enlèvement de déchets biologiques",
   code_90524400:"Services de collecte, de transport et d'élimination de déchets hospitaliers",
   code_90530000:"Exploitation d'une décharge",
   code_90531000:"Services de gestion de décharges contrôlées",
   code_90532000:"Services de gestion de terrils de charbon",
   code_90533000:"Services de gestion de décharges",
   code_90600000:"Services de propreté et d'hygiénisation en milieu urbain ou rural, et services connexes",
   code_90610000:"Services de voirie et services de balayage des rues",
   code_90611000:"Services de voirie",
   code_90612000:"Services de balayage des rues",
   code_90620000:"Services de déneigement",
   code_90630000:"Services de déverglaçage",
   code_90640000:"Services de nettoyage et de vidange des avaloirs",
   code_90641000:"Services de nettoyage des avaloirs",
   code_90642000:"Services de vidange des avaloirs",
   code_90650000:"Services de désamiantage",
   code_90660000:"Services d'enlèvement du plomb",
   code_90670000:"Services de désinfection et désinfestation en milieu rural ou urbain",
   code_90680000:"Services de nettoyage de plages",
   code_90690000:"Services d'enlèvement des graffitis",
   code_90700000:"Services relatifs à l'environnement",
   code_90710000:"Gestion environnementale",
   code_90711000:"Évaluation de l'impact sur l'environnement autre que pour la construction",
   code_90711100:"Évaluation des risques et dangers autre que pour la construction",
   code_90711200:"Normes environnementales autres que pour la construction",
   code_90711300:"Analyse des indicateurs environnementaux autres que pour la construction",
   code_90711400:"Services d'évaluation de l'impact sur l'environnement (EIA) autres que pour la construction",
   code_90711500:"Surveillance de l'environnement autre que pour la construction",
   code_90712000:"Planification environnementale",
   code_90712100:"Planification de la mise en valeur de l'environnement urbain",
   code_90712200:"Planification de la stratégie de conservation de la forêt",
   code_90712300:"Planification de la stratégie de conservation maritime",
   code_90712400:"Services de gestion des ressources naturelles et de planification de la stratégie de conservation",
   code_90712500:"Création ou planification d'institutions environnementales",
   code_90713000:"Services de conseils environnementaux",
   code_90713100:"Services de conseils en matière d'approvisionnement en eau et de gestion des eaux usées autres que pour la construction",
   code_90714000:"Contrôle de la conformité aux normes environnementales",
   code_90714100:"Systèmes d'information environnementale",
   code_90714200:"Services corporatifs de contrôle de la conformité aux normes environnementales",
   code_90714300:"Services sectoriels de contrôle de la conformité aux nornes environnementales",
   code_90714400:"Services de contrôle de la conformité aux normes environnementales pour activités spécifiques",
   code_90714500:"Services de contrôle de la qualité environnementale",
   code_90714600:"Services de contrôle de la sécurité environnementale",
   code_90715000:"Services de recherche de la pollution",
   code_90715100:"Services de recherche en matière de pollution par produits chimiques et par hydrocarbures",
   code_90715110:"Recherche sur un site d'usine à gaz",
   code_90715120:"Recherche sur un site d'usine à produits chimiques ou sur un site de déchets de raffinerie de pétrole",
   code_90715200:"Autres services de recherche en matière de pollution",
   code_90715210:"Recherche sur un site de dépôt d'hydrocarbures ou de terminal pétrolier",
   code_90715220:"Recherche sur un site industriel",
   code_90715230:"Recherche sur un site contenant des déchets industriels",
   code_90715240:"Recherche sur un site d'usine de traitement du bois",
   code_90715250:"Recherche sur un site d'installation de nettoyage chimique à sec",
   code_90715260:"Recherche sur un site de fonderie",
   code_90715270:"Recherche sur un site d'usine de recyclage",
   code_90715280:"Recherche sur un site d'usine de traitement des aliments",
   code_90720000:"Protection environnementale",
   code_90721000:"Services de sécurité environnementale",
   code_90721100:"Services de protection du paysage",
   code_90721200:"Service de protection de l'ozone",
   code_90721300:"Services de protection contre la contamination de l'alimentation humaine ou de l'alimentation animale",
   code_90721400:"Services de protection des ressources génétiques",
   code_90721500:"Services de protection contres les matières toxiques",
   code_90721600:"Services de radioprotection",
   code_90721700:"Services de protection des espèces menacées de disparition",
   code_90721800:"Services de protection contre les risques ou dangers naturels",
   code_90722000:"Réhabilitation environnementale",
   code_90722100:"Réhabilitation de site industriel",
   code_90722200:"Services de décontamination environnementale",
   code_90722300:"Services de mise en valeur des sols",
   code_90730000:"Recherche, surveillance et réhabilitation de la pollution",
   code_90731000:"Services liés à la pollution atmosphérique",
   code_90731100:"Gestion de la qualité de l'air",
   code_90731200:"Services de contrôle et de gestion de la pollution atmosphérique transfrontalière",
   code_90731210:"Achats de crédits d'émission de CO2",
   code_90731300:"Services de protection contre la pollution atmosphérique",
   code_90731400:"Services de surveillance ou de mesure de la pollution atmosphérique",
   code_90731500:"Services de détection de gaz toxiques",
   code_90731600:"Surveillance du méthane",
   code_90731700:"Services de surveillance du dioxyde de carbone",
   code_90731800:"Surveillance des particules atmosphériques",
   code_90731900:"Services de surveillance de l'appauvrissement en ozone",
   code_90732000:"Pollution des sols",
   code_90732100:"Services de protection contre la pollution des sols",
   code_90732200:"Services d'enlèvement des sols pollués",
   code_90732300:"Traitement et réhabilitation des sols pollués",
   code_90732400:"Services de conseil contre la pollution des sols",
   code_90732500:"Cartographie de la pollution des sols",
   code_90732600:"Mesure et surveillance de la pollution des sols",
   code_90732700:"Évaluation de la pollution par les engrais organiques",
   code_90732800:"Évaluation de la pollution par les pesticides",
   code_90732900:"Évaluation de la pollution par les nitrates et les phosphates",
   code_90732910:"Évaluation de la pollution par les nitrates",
   code_90732920:"Évaluation de la pollution par les phosphates",
   code_90733000:"Services liés à la pollution de l'eau",
   code_90733100:"Services de surveillance et de lutte contre la pollution des eaux de surface",
   code_90733200:"Services de réhabilitation de la pollution des eaux de surface",
   code_90733300:"Services de protection de la pollution des eaux de surface",
   code_90733400:"Services de traitement des eaux de surface",
   code_90733500:"Services de drainage de la pollution des eaux de surface",
   code_90733600:"Services de gestion ou de lutte contre la pollution transfrontalière des eaux",
   code_90733700:"Services de surveillance et de lutte contre la pollution de la nappe phréatique",
   code_90733800:"Services de drainage de la pollution de la nappe phréatique",
   code_90733900:"Traitement et réhabilitation de la pollution de la nappe phréatique",
   code_90740000:"Services de recherche, de surveillance et de réhabilitation des polluants",
   code_90741000:"Services liés à la pollution par hydrocarbures",
   code_90741100:"Services de surveillance de l'écoulement accidentel d'hydrocarbures",
   code_90741200:"Services de lutte contre les déversements accidentels d'hydrocarbures",
   code_90741300:"Services de réhabilitation de l'écoulement accidentel d'hydrocarbures",
   code_90742000:"Services liés à la pollution sonore",
   code_90742100:"Services de lutte contre le bruit",
   code_90742200:"Services de protection contre la pollution sonore",
   code_90742300:"Services de surveillance de la pollution sonore",
   code_90742400:"Services de conseil contre la pollution sonore",
   code_90743000:"Services liés à la pollution par des matières toxiques",
   code_90743100:"Services de surveillance des matières toxiques",
   code_90743200:"Services de réhabilitation des matières toxiques",
   code_90900000:"Services de nettoyage et d'hygiène",
   code_90910000:"Services de nettoyage",
   code_90911000:"Services de nettoyage de logements, de bâtiments et de vitres",
   code_90911100:"Services de nettoyage de logements",
   code_90911200:"Services de nettoyage de bâtiments",
   code_90911300:"Services de nettoyage de vitres",
   code_90912000:"Services de nettoyage par soufflage de structures tubolaires",
   code_90913000:"Services de nettoyage de cuves et de réservoirs",
   code_90913100:"Services de nettoyage de cuves",
   code_90913200:"Services de nettoyage de réservoirs",
   code_90914000:"Services de nettoyage de parkings",
   code_90915000:"Services de nettoyage de fourneaux et de cheminées",
   code_90916000:"Services de nettoyage de matériel téléphonique",
   code_90917000:"Services de nettoyage de matériel de transport",
   code_90918000:"Services de nettoyage de poubelles",
   code_90919000:"Services de nettoyage de matériel de bureau, de bureaux, des écoles",
   code_90919100:"Services de nettoyage de matériel de bureau",
   code_90919200:"Services de nettoyage de bureaux",
   code_90919300:"Services de nettoyage des écoles",
   code_90920000:"Services d'hygiénisation d'installations",
   code_90921000:"Services de désinfection et de désinfestation",
   code_90922000:"Services de lutte contre les parasites",
   code_90923000:"Services de dératisation",
   code_90924000:"Services de fumigation",
   code_92000000:"Services récréatifs, culturels et sportifs",
   code_92100000:"Services cinématographiques et services vidéo",
   code_92110000:"Services de production de films cinématographique et de vidéos et services connexes",
   code_92111000:"Services de production cinématographique et vidéo",
   code_92111100:"Production de films et de vidéos à usage pédagogique",
   code_92111200:"Production de films et de vidéos publicitaires, de propagande et d'information",
   code_92111210:"Production de films publicitaires",
   code_92111220:"Production de vidéos publicitaires",
   code_92111230:"Production de films de propagande",
   code_92111240:"Production de vidéos de propagande",
   code_92111250:"Production de films d'information",
   code_92111260:"Production de vidéos d'information",
   code_92111300:"Production de films et de vidéos de divertissement",
   code_92111310:"Production de films de divertissement",
   code_92111320:"Production de vidéos de divertissement",
   code_92112000:"Services liés à la production de films cinématographiques ou de vidéos",
   code_92120000:"Services de distribution de films cinématographiques et de vidéos",
   code_92121000:"Services de distribution de vidéos",
   code_92122000:"Services de distribution de films cinématographiques",
   code_92130000:"Services de projection de films cinématographiques",
   code_92140000:"Services de projection de vidéos",
   code_92200000:"Services de radio et de télévision",
   code_92210000:"Services de radio",
   code_92211000:"Services de production radiophonique",
   code_92213000:"Services de systèmes radiophoniques à petite échelle",
   code_92214000:"Services de studio ou de matériel radiophonique",
   code_92215000:"Services généraux de radiophonie mobile (GMRS)",
   code_92216000:"Services radio familiaux (FRS)",
   code_92217000:"Services généraux de radiophonie mobile/Services radio familiaux (GMRS/FRS)",
   code_92220000:"Services de télévision",
   code_92221000:"Services de production télévisuelle",
   code_92222000:"Services de télévision en circuit fermé",
   code_92224000:"Services de télévision numérique",
   code_92225000:"Services de télévision interactive",
   code_92225100:"Services de télédiffusion de films à la demande",
   code_92226000:"Services de téléprogrammation",
   code_92230000:"Services de radio et de télévision par câble",
   code_92231000:"Services bilatéraux internationaux et de lignes louées privées internationales",
   code_92232000:"Services de télévision par câble",
   code_92300000:"Services de divertissement",
   code_92310000:"Services de création et d'interprétation d'œuvres artistiques et littéraires",
   code_92311000:"Œuvres d'art",
   code_92312000:"Services artistiques",
   code_92312100:"Services de divertissement prestés par les metteurs en scène de théâtre, les chœurs, les ensembles musicaux et les orchestres",
   code_92312110:"Services de divertissement prestés par les metteurs en scène de théâtre",
   code_92312120:"Services de divertissement prestés par les chœurs",
   code_92312130:"Services de divertissement prestés par les ensembles musicaux",
   code_92312140:"Services de divertissement prestés par les orchestres",
   code_92312200:"Services prestés par les auteurs, les compositeurs, les sculpteurs et les artistes",
   code_92312210:"Services prestés par les auteurs",
   code_92312211:"Services d'agences de rédaction",
   code_92312212:"Services relatifs à la préparation de manuels de formation",
   code_92312213:"Services prestés par les rédacteurs techniques",
   code_92312220:"Services prestés par les compositeurs",
   code_92312230:"Services prestés par les sculpteurs",
   code_92312240:"Services prestés par les artistes",
   code_92312250:"Services prestés par les artistes indépendants",
   code_92312251:"Services prestés par les disk-jockeys",
   code_92320000:"Services d'exploitation de salles de spectacles",
   code_92330000:"Services de zones récréatives",
   code_92331000:"Services de fêtes foraines et de parcs d'attractions",
   code_92331100:"Services de fêtes foraines",
   code_92331200:"Services de parcs d'attractions",
   code_92331210:"Services d'animation pour enfants",
   code_92332000:"Services de plages",
   code_92340000:"Services relatifs à la danse et aux divertissements",
   code_92341000:"Services de cirques",
   code_92342000:"Services d'écoles de danse",
   code_92342100:"Services d'écoles de danse de salon",
   code_92342200:"Services d'écoles de danse de discothèque",
   code_92350000:"Services de jeux et de paris",
   code_92351000:"Services de jeux",
   code_92351100:"Services d'exploitation de la loterie",
   code_92351200:"Services d'exploitation de casinos",
   code_92352000:"Services de paris",
   code_92352100:"Services d'exploitation de machines pour les paris mutuels",
   code_92352200:"Services prestés par les bookmakers",
   code_92360000:"Services pyrotechniques",
   code_92370000:"Services prestés par les techniciens du son",
   code_92400000:"Services d'agences de presse",
   code_92500000:"Services de bibliothèques, archives, musées et autres services culturels",
   code_92510000:"Services de bibliothèques et archives",
   code_92511000:"Services de bibliothèques",
   code_92512000:"Services d'archives",
   code_92512100:"Services de destruction d'archives",
   code_92520000:"Services de musées et de préservation des sites historiques et des services connexes",
   code_92521000:"Services de musées",
   code_92521100:"Services d'exposition dans les musées",
   code_92521200:"Services de conservation des objets exposés et des spécimens",
   code_92521210:"Services de conservation des objets exposés",
   code_92521220:"Services de conservation des spécimens",
   code_92522000:"Services de conservation des sites et des monuments historiques",
   code_92522100:"Services de conservation des sites historiques",
   code_92522200:"Services de conservation des monuments historiques",
   code_92530000:"Services de jardins botaniques et zoologiques et des réserves naturelles",
   code_92531000:"Services de jardins botaniques",
   code_92532000:"Services de jardins zoologiques",
   code_92533000:"Services de réserves naturelles",
   code_92534000:"Services de préservation de la vie sauvage",
   code_92600000:"Services sportifs",
   code_92610000:"Services d'exploitation d'installations sportives",
   code_92620000:"Services liés au sport",
   code_92621000:"Services de promotion de manifestations sportives",
   code_92622000:"Services d'organisation de manifestations sportives",
   code_92700000:"Services de cybercafé",
   code_98000000:"Autres services communautaires, sociaux et personnels",
   code_98100000:"Services des organisations associatives",
   code_98110000:"Services fournis par des organisations commerciales, professionnelles et spécialisées",
   code_98111000:"Services fournis par des organisations commerciales",
   code_98112000:"Services prestés par les organisations professionnelles",
   code_98113000:"Services fournis par des organisations spécialisées",
   code_98113100:"Services de sûreté nucléaire",
   code_98120000:"Services prestés par les organisations syndicales",
   code_98130000:"Services divers prestés par les organisations associatives",
   code_98131000:"Services religieux",
   code_98132000:"Services prestés par les organisations politiques",
   code_98133000:"Services prestés par les organisations sociales",
   code_98133100:"Services d'appui relatifs au développement de l'esprit civique et aux équipements collectifs",
   code_98133110:"Services prestés par les associations de jeunes",
   code_98200000:"Services de conseil en matière d'égalité des chances",
   code_98300000:"Services divers",
   code_98310000:"Services de blanchisserie et de nettoyage à sec",
   code_98311000:"Services de ramassage de linge",
   code_98311100:"Services de gestion de blanchisserie",
   code_98311200:"Services d'exploitation de blanchisserie",
   code_98312000:"Services de nettoyage de textile",
   code_98312100:"Services d'imprégnation de textile",
   code_98313000:"Services de nettoyage de fourrures",
   code_98314000:"Services de coloration",
   code_98315000:"Services de repassage",
   code_98316000:"Services de teinture",
   code_98320000:"Services de coiffure et de soins de beauté",
   code_98321000:"Services de coiffure",
   code_98321100:"Services de coiffeurs pour hommes",
   code_98322000:"Services de soins de beauté",
   code_98322100:"Services de soins de beauté, de manucure et de pédicure",
   code_98322110:"Services de soins cosmétiques",
   code_98322120:"Services de manucure",
   code_98322130:"Services de pédicure",
   code_98322140:"Services de maquillage",
   code_98330000:"Services de soins corporels",
   code_98331000:"Services de bains turcs",
   code_98332000:"Services de stations thermales",
   code_98333000:"Services de massage",
   code_98334000:"Services de mieux-être",
   code_98336000:"Services d'entraînement, d'exercices ou d'aérobic",
   code_98340000:"Services d'hébergement et de bureau",
   code_98341000:"Services d'hébergement",
   code_98341100:"Services de gestion de l'hébergement",
   code_98341110:"Services ménagers",
   code_98341120:"Services de portier",
   code_98341130:"Services de conciergerie",
   code_98341140:"Services de gardien d'immeuble",
   code_98342000:"Services relatifs à l'environnement de travail",
   code_98350000:"Services relatifs aux équipements collectifs",
   code_98351000:"Services de gestion de parkings",
   code_98351100:"Services de parkings",
   code_98351110:"Services de mise en œuvre des règles de stationnement",
   code_98360000:"Services marins",
   code_98361000:"Services relatifs à l'océanologie",
   code_98362000:"Services de gestion portuaire",
   code_98362100:"Services d'appui de base maritimes",
   code_98363000:"Services de plongée",
   code_98370000:"Services funéraires et services connexes",
   code_98371000:"Services funéraires",
   code_98371100:"Services d'inhumation et de crémation",
   code_98371110:"Services d'inhumation",
   code_98371111:"Services d'entretien de cimetières",
   code_98371120:"Services de crémation",
   code_98371200:"Services de pompes funèbres",
   code_98380000:"Services de chenils",
   code_98390000:"Autres services",
   code_98391000:"Services de déclassement",
   code_98392000:"Services de déplacement",
   code_98393000:"Services de confection sur mesure",
   code_98394000:"Services de tapisserie",
   code_98395000:"Services de serrurerie",
   code_98396000:"Services d'accordage d'instruments",
   code_98500000:"Ménages privés employant du personnel",
   code_98510000:"Services d'employés commerciaux et d'ouvriers industriels",
   code_98511000:"Services d'employés commerciaux",
   code_98512000:"Services d'ouvriers industriels",
   code_98513000:"Services de main-d'œuvre pour les particuliers",
   code_98513100:"Service de personnel intérimaire pour les particuliers",
   code_98513200:"Services de personnel de bureau pour les particuliers",
   code_98513300:"Services de personnel temporaire pour les particuliers",
   code_98513310:"Services d'aide à domicile",
   code_98514000:"Services domestiques",
   code_98900000:"Services prestés par des organisations et des organismes extra-territoriaux",
   code_98910000:"Services spécifiques aux organisations et aux organismes internationaux",
   // code_00:"Other",
   code_AL:"Albania",
   code_AL0:"Shqipëria",
   code_AL01:"Veri",
   code_AL011:"Dibër",
   code_AL012:"Durrës",
   code_AL013:"Kukës",
   code_AL014:"Lezhë",
   code_AL015:"Shkodër",
   code_AL02:"Qender",
   code_AL021:"Elbasan",
   code_AL022:"Tiranë",
   code_AL03:"Jug",
   code_AL031:"Berat",
   code_AL032:"Fier",
   code_AL033:"Gjirokastër",
   code_AL034:"Korcë",
   code_AL035:"Vlorë",
   code_AT:"ÖSTERREICH",
   code_AT1:"OSTÖSTERREICH",
   code_AT11:"Burgenland",
   code_AT111:"Mittelburgenland",
   code_AT112:"Nordburgenland",
   code_AT113:"Südburgenland",
   code_AT12:"Niederösterreich",
   code_AT121:"Mostviertel-Eisenwurzen",
   code_AT122:"Niederösterreich-Süd",
   code_AT123:"Sankt Pölten",
   code_AT124:"Waldviertel",
   code_AT125:"Weinviertel",
   code_AT126:"Wiener Umland / Nordteil",
   code_AT127:"Wiener Umland / Südteil",
   code_AT13:"Wien",
   code_AT130:"Wien",
   code_AT2:"SÜDÖSTERREICH",
   code_AT21:"Kärnten",
   code_AT211:"Klagenfurt-Villach",
   code_AT212:"Oberkärnten",
   code_AT213:"Unterkärnten",
   code_AT22:"Steiermark",
   code_AT221:"Graz",
   code_AT222:"Liezen",
   code_AT223:"Östliche Obersteiermark",
   code_AT224:"Oststeiermark",
   code_AT225:"West- und Südsteiermark",
   code_AT226:"Westliche Obersteiermark",
   code_AT3:"WESTÖSTERREICH",
   code_AT31:"Oberösterreich",
   code_AT311:"Innviertel",
   code_AT312:"Linz-Wels",
   code_AT313:"Mühlviertel",
   code_AT314:"Steyr-Kirchdorf",
   code_AT315:"Traunviertel",
   code_AT32:"Salzburg",
   code_AT321:"Lungau",
   code_AT322:"Pinzgau-Pongau",
   code_AT323:"Salzburg und Umgebung",
   code_AT33:"Tirol",
   code_AT331:"Außerfern",
   code_AT332:"Innsbruck",
   code_AT333:"Osttirol",
   code_AT334:"Tiroler Oberland",
   code_AT335:"Tiroler Unterland",
   code_AT34:"Vorarlberg",
   code_AT341:"Bludenz-Bregenzer Wald",
   code_AT342:"Rheintal-Bodenseegebiet",
   code_ATZ:"EXTRA-REGIO NUTS 1",
   code_ATZZ:"Extra-Regio NUTS 2",
   code_ATZZZ:"Extra-Regio NUTS 3",
   code_BE:"BELGIQUE-BELGIË",
   code_BE1:"Région de Bruxelles-Capitale",
   code_BE10:"Région de Bruxelles-Capitale",
   code_BE100:"Arr. de Bruxelles-Capitale",
   code_BE2:"Vlaams Gewest",
   code_BE21:"Prov. Antwerpen",
   code_BE211:"Arr. Antwerpen",
   code_BE212:"Arr. Mechelen",
   code_BE213:"Arr. Turnhout",
   code_BE22:"Prov. Limburg (BE)",
   code_BE221:"Arr. Hasselt",
   code_BE222:"Arr. Maaseik",
   code_BE223:"Arr. Tongeren",
   code_BE23:"Prov. Oost-Vlaanderen",
   code_BE231:"Arr. Aalst",
   code_BE232:"Arr. Dendermonde",
   code_BE233:"Arr. Eeklo",
   code_BE234:"Arr. Gent",
   code_BE235:"Arr. Oudenaarde",
   code_BE236:"Arr. Sint-Niklaas",
   code_BE24:"Prov. Vlaams-Brabant",
   code_BE241:"Arr. Halle-Vilvoorde",
   code_BE242:"Arr. Leuven",
   code_BE25:"Prov. West-Vlaanderen",
   code_BE251:"Arr. Brugge",
   code_BE252:"Arr. Diksmuide",
   code_BE253:"Arr. Ieper",
   code_BE254:"Arr. Kortrijk",
   code_BE255:"Arr. Oostende",
   code_BE256:"Arr. Roeselare",
   code_BE257:"Arr. Tielt",
   code_BE258:"Arr. Veurne",
   code_BE3:"RÉGION WALLONNE",
   code_BE31:"Prov. Brabant Wallon",
   code_BE310:"Arr. Nivelles",
   code_BE32:"Prov. Hainaut",
   code_BE321:"Arr. Ath",
   code_BE322:"Arr. Charleroi",
   code_BE323:"Arr. Mons",
   code_BE324:"Arr. Mouscron",
   code_BE325:"Arr. Soignies",
   code_BE326:"Arr. Thuin",
   code_BE327:"Arr. Tournai",
   code_BE33:"Prov. Liège",
   code_BE331:"Arr. Huy",
   code_BE332:"Arr. Liège",
   code_BE334:"Arr. Waremme",
   code_BE335:"Arr. Verviers - communes francophones",
   code_BE336:"Bezirk Verviers - Deutschsprachige Gemeinschaft",
   code_BE34:"Prov. Luxembourg (BE)",
   code_BE341:"Arr. Arlon",
   code_BE342:"Arr. Bastogne",
   code_BE343:"Arr. Marche-en-Famenne",
   code_BE344:"Arr. Neufchâteau",
   code_BE345:"Arr. Virton",
   code_BE35:"Prov. Namur",
   code_BE351:"Arr. Dinant",
   code_BE352:"Arr. Namur",
   code_BE353:"Arr. Philippeville",
   code_BEZ:"EXTRA-REGIO NUTS 1",
   code_BEZZ:"Extra-Regio NUTS 2",
   code_BEZZZ:"Extra-Regio NUTS 3",
   code_BG:"БЪЛГАРИЯ",
   code_BG3:"СЕВЕРНА И ЮГОИЗТОЧНА БЪЛГАРИЯ",
   code_BG31:"Северозападен",
   code_BG311:"Видин",
   code_BG312:"Монтана",
   code_BG313:"Враца",
   code_BG314:"Плевен",
   code_BG315:"Ловеч",
   code_BG32:"Северен централен",
   code_BG321:"Велико Търново",
   code_BG322:"Габрово",
   code_BG323:"Русе",
   code_BG324:"Разград",
   code_BG325:"Силистра",
   code_BG33:"Североизточен",
   code_BG331:"Варна",
   code_BG332:"Добрич",
   code_BG333:"Шумен",
   code_BG334:"Търговище",
   code_BG34:"Югоизточен",
   code_BG341:"Бургас",
   code_BG342:"Сливен",
   code_BG343:"Ямбол",
   code_BG344:"Стара Загора",
   code_BG4:"ЮГОЗАПАДНА И ЮЖНА ЦЕНТРАЛНА БЪЛГАРИЯ",
   code_BG41:"Югозападен",
   code_BG411:"София (столица)",
   code_BG412:"София",
   code_BG413:"Благоевград",
   code_BG414:"Перник",
   code_BG415:"Кюстендил",
   code_BG42:"Южен централен",
   code_BG421:"Пловдив",
   code_BG422:"Хасково",
   code_BG423:"Пазарджик",
   code_BG424:"Смолян",
   code_BG425:"Кърджали",
   code_BGZ:"EXTRA-REGIO NUTS 1",
   code_BGZZ:"Extra-Regio NUTS 2",
   code_BGZZZ:"Extra-Regio NUTS 3",
   code_CH:"Switzerland",
   code_CH0:"Schweiz/Suisse/Svizzera",
   code_CH01:"Région lémanique",
   code_CH011:"Vaud",
   code_CH012:"Valais / Wallis",
   code_CH013:"Genève",
   code_CH02:"Espace Mittelland",
   code_CH021:"Bern / Berne",
   code_CH022:"Fribourg / Freiburg",
   code_CH023:"Solothurn",
   code_CH024:"Neuchâtel",
   code_CH025:"Jura",
   code_CH03:"Nordwestschweiz",
   code_CH031:"Basel-Stadt",
   code_CH032:"Basel-Landschaft",
   code_CH033:"Aargau",
   code_CH04:"Zürich",
   code_CH040:"Zürich",
   code_CH05:"Ostschweiz",
   code_CH051:"Glarus",
   code_CH052:"Schaffhausen",
   code_CH053:"Appenzell Ausserrhoden",
   code_CH054:"Appenzell Innerrhoden",
   code_CH055:"St. Gallen",
   code_CH056:"Graubünden / Grigioni / Grischun",
   code_CH057:"Thurgau",
   code_CH06:"Zentralschweiz",
   code_CH061:"Luzern",
   code_CH062:"Uri",
   code_CH063:"Schwyz",
   code_CH064:"Obwalden",
   code_CH065:"Nidwalden",
   code_CH066:"Zug",
   code_CH07:"Ticino",
   code_CH070:"Ticino",
   code_CY:"ΚΥΠΡΟΣ",
   code_CY0:"ΚΥΠΡΟΣ",
   code_CY00:"Κύπρος",
   code_CY000:"Κύπρος",
   code_CYZ:"EXTRA-REGIO NUTS 1",
   code_CYZZ:"Extra-Regio NUTS 2",
   code_CYZZZ:"Extra-Regio NUTS 3",
   code_CZ:"ČESKÁ REPUBLIKA",
   code_CZ0:"ČESKÁ REPUBLIKA",
   code_CZ01:"Praha",
   code_CZ010:"Hlavní město Praha",
   code_CZ02:"Střední Čechy",
   code_CZ020:"Středočeský kraj",
   code_CZ03:"Jihozápad",
   code_CZ031:"Jihočeský kraj",
   code_CZ032:"Plzeňský kraj",
   code_CZ04:"Severozápad",
   code_CZ041:"Karlovarský kraj",
   code_CZ042:"Ústecký kraj",
   code_CZ05:"Severovýchod",
   code_CZ051:"Liberecký kraj",
   code_CZ052:"Královéhradecký kraj",
   code_CZ053:"Pardubický kraj",
   code_CZ06:"Jihovýchod",
   code_CZ063:"Kraj Vysočina",
   code_CZ064:"Jihomoravský kraj",
   code_CZ07:"Střední Morava",
   code_CZ071:"Olomoucký kraj",
   code_CZ072:"Zlínský kraj",
   code_CZ08:"Moravskoslezsko",
   code_CZ080:"Moravskoslezský kraj",
   code_CZZ:"EXTRA-REGIO NUTS 1",
   code_CZZZ:"Extra-Regio NUTS 2",
   code_CZZZZ:"Extra-Regio NUTS 3",
   code_DE:"DEUTSCHLAND",
   code_DE1:"BADEN-WÜRTTEMBERG",
   code_DE11:"Stuttgart",
   code_DE111:"Stuttgart, Stadtkreis",
   code_DE112:"Böblingen",
   code_DE113:"Esslingen",
   code_DE114:"Göppingen",
   code_DE115:"Ludwigsburg",
   code_DE116:"Rems-Murr-Kreis",
   code_DE117:"Heilbronn, Stadtkreis",
   code_DE118:"Heilbronn, Landkreis",
   code_DE119:"Hohenlohekreis",
   code_DE11A:"Schwäbisch Hall",
   code_DE11B:"Main-Tauber-Kreis",
   code_DE11C:"Heidenheim",
   code_DE11D:"Ostalbkreis",
   code_DE12:"Karlsruhe",
   code_DE121:"Baden-Baden, Stadtkreis",
   code_DE122:"Karlsruhe, Stadtkreis",
   code_DE123:"Karlsruhe, Landkreis",
   code_DE124:"Rastatt",
   code_DE125:"Heidelberg, Stadtkreis",
   code_DE126:"Mannheim, Stadtkreis",
   code_DE127:"Neckar-Odenwald-Kreis",
   code_DE128:"Rhein-Neckar-Kreis",
   code_DE129:"Pforzheim, Stadtkreis",
   code_DE12A:"Calw",
   code_DE12B:"Enzkreis",
   code_DE12C:"Freudenstadt",
   code_DE13:"Freiburg",
   code_DE131:"Freiburg im Breisgau, Stadtkreis",
   code_DE132:"Breisgau-Hochschwarzwald",
   code_DE133:"Emmendingen",
   code_DE134:"Ortenaukreis",
   code_DE135:"Rottweil",
   code_DE136:"Schwarzwald-Baar-Kreis",
   code_DE137:"Tuttlingen",
   code_DE138:"Konstanz",
   code_DE139:"Lörrach",
   code_DE13A:"Waldshut",
   code_DE14:"Tübingen",
   code_DE141:"Reutlingen",
   code_DE142:"Tübingen, Landkreis",
   code_DE143:"Zollernalbkreis",
   code_DE144:"Ulm, Stadtkreis",
   code_DE145:"Alb-Donau-Kreis",
   code_DE146:"Biberach",
   code_DE147:"Bodenseekreis",
   code_DE148:"Ravensburg",
   code_DE149:"Sigmaringen",
   code_DE2:"BAYERN",
   code_DE21:"Oberbayern",
   code_DE211:"Ingolstadt, Kreisfreie Stadt",
   code_DE212:"München, Kreisfreie Stadt",
   code_DE213:"Rosenheim, Kreisfreie Stadt",
   code_DE214:"Altötting",
   code_DE215:"Berchtesgadener Land",
   code_DE216:"Bad Tölz-Wolfratshausen",
   code_DE217:"Dachau",
   code_DE218:"Ebersberg",
   code_DE219:"Eichstätt",
   code_DE21A:"Erding",
   code_DE21B:"Freising",
   code_DE21C:"Fürstenfeldbruck",
   code_DE21D:"Garmisch-Partenkirchen",
   code_DE21E:"Landsberg am Lech",
   code_DE21F:"Miesbach",
   code_DE21G:"Mühldorf a. Inn",
   code_DE21H:"München, Landkreis",
   code_DE21I:"Neuburg-Schrobenhausen",
   code_DE21J:"Pfaffenhofen a. d. Ilm",
   code_DE21K:"Rosenheim, Landkreis",
   code_DE21L:"Starnberg",
   code_DE21M:"Traunstein",
   code_DE21N:"Weilheim-Schongau",
   code_DE22:"Niederbayern",
   code_DE221:"Landshut, Kreisfreie Stadt",
   code_DE222:"Passau, Kreisfreie Stadt",
   code_DE223:"Straubing, Kreisfreie Stadt",
   code_DE224:"Deggendorf",
   code_DE225:"Freyung-Grafenau",
   code_DE226:"Kelheim",
   code_DE227:"Landshut, Landkreis",
   code_DE228:"Passau, Landkreis",
   code_DE229:"Regen",
   code_DE22A:"Rottal-Inn",
   code_DE22B:"Straubing-Bogen",
   code_DE22C:"Dingolfing-Landau",
   code_DE23:"Oberpfalz",
   code_DE231:"Amberg, Kreisfreie Stadt",
   code_DE232:"Regensburg, Kreisfreie Stadt",
   code_DE233:"Weiden i. d. Opf, Kreisfreie Stadt",
   code_DE234:"Amberg-Sulzbach",
   code_DE235:"Cham",
   code_DE236:"Neumarkt i. d. OPf.",
   code_DE237:"Neustadt a. d. Waldnaab",
   code_DE238:"Regensburg, Landkreis",
   code_DE239:"Schwandorf",
   code_DE23A:"Tirschenreuth",
   code_DE24:"Oberfranken",
   code_DE241:"Bamberg, Kreisfreie Stadt",
   code_DE242:"Bayreuth, Kreisfreie Stadt",
   code_DE243:"Coburg, Kreisfreie Stadt",
   code_DE244:"Hof, Kreisfreie Stadt",
   code_DE245:"Bamberg, Landkreis",
   code_DE246:"Bayreuth, Landkreis",
   code_DE247:"Coburg, Landkreis",
   code_DE248:"Forchheim",
   code_DE249:"Hof, Landkreis",
   code_DE24A:"Kronach",
   code_DE24B:"Kulmbach",
   code_DE24C:"Lichtenfels",
   code_DE24D:"Wunsiedel i. Fichtelgebirge",
   code_DE25:"Mittelfranken",
   code_DE251:"Ansbach, Kreisfreie Stadt",
   code_DE252:"Erlangen, Kreisfreie Stadt",
   code_DE253:"Fürth, Kreisfreie Stadt",
   code_DE254:"Nürnberg, Kreisfreie Stadt",
   code_DE255:"Schwabach, Kreisfreie Stadt",
   code_DE256:"Ansbach, Landkreis",
   code_DE257:"Erlangen-Höchstadt",
   code_DE258:"Fürth, Landkreis",
   code_DE259:"Nürnberger Land",
   code_DE25A:"Neustadt a. d. Aisch-Bad Windsheim",
   code_DE25B:"Roth",
   code_DE25C:"Weißenburg-Gunzenhausen",
   code_DE26:"Unterfranken",
   code_DE261:"Aschaffenburg, Kreisfreie Stadt",
   code_DE262:"Schweinfurt, Kreisfreie Stadt",
   code_DE263:"Würzburg, Kreisfreie Stadt",
   code_DE264:"Aschaffenburg, Landkreis",
   code_DE265:"Bad Kissingen",
   code_DE266:"Rhön-Grabfeld",
   code_DE267:"Haßberge",
   code_DE268:"Kitzingen",
   code_DE269:"Miltenberg",
   code_DE26A:"Main-Spessart",
   code_DE26B:"Schweinfurt, Landkreis",
   code_DE26C:"Würzburg, Landkreis",
   code_DE27:"Schwaben",
   code_DE271:"Augsburg, Kreisfreie Stadt",
   code_DE272:"Kaufbeuren, Kreisfreie Stadt",
   code_DE273:"Kempten (Allgäu), Kreisfreie Stadt",
   code_DE274:"Memmingen, Kreisfreie Stadt",
   code_DE275:"Aichach-Friedberg",
   code_DE276:"Augsburg, Landkreis",
   code_DE277:"Dillingen a.d. Donau",
   code_DE278:"Günzburg",
   code_DE279:"Neu-Ulm",
   code_DE27A:"Lindau (Bodensee)",
   code_DE27B:"Ostallgäu",
   code_DE27C:"Unterallgäu",
   code_DE27D:"Donau-Ries",
   code_DE27E:"Oberallgäu",
   code_DE3:"BERLIN",
   code_DE30:"Berlin",
   code_DE300:"Berlin",
   code_DE4:"BRANDENBURG",
   code_DE40:"Brandenburg",
   code_DE401:"Brandenburg an der Havel, Kreisfreie Stadt",
   code_DE402:"Cottbus, Kreisfreie Stadt",
   code_DE403:"Frankfurt (Oder), Kreisfreie Stadt",
   code_DE404:"Potsdam, Kreisfreie Stadt",
   code_DE405:"Barnim",
   code_DE406:"Dahme-Spreewald",
   code_DE407:"Elbe-Elster",
   code_DE408:"Havelland",
   code_DE409:"Märkisch-Oderland",
   code_DE40A:"Oberhavel",
   code_DE40B:"Oberspreewald-Lausitz",
   code_DE40C:"Oder-Spree",
   code_DE40D:"Ostprignitz-Ruppin",
   code_DE40E:"Potsdam-Mittelmark",
   code_DE40F:"Prignitz",
   code_DE40G:"Spree-Neiße",
   code_DE40H:"Teltow-Fläming",
   code_DE40I:"Uckermark",
   code_DE5:"BREMEN",
   code_DE50:"Bremen",
   code_DE501:"Bremen, Kreisfreie Stadt",
   code_DE502:"Bremerhaven, Kreisfreie Stadt",
   code_DE6:"HAMBURG",
   code_DE60:"Hamburg",
   code_DE600:"Hamburg",
   code_DE7:"HESSEN",
   code_DE71:"Darmstadt",
   code_DE711:"Darmstadt, Kreisfreie Stadt",
   code_DE712:"Frankfurt am Main, Kreisfreie Stadt",
   code_DE713:"Offenbach am Main, Kreisfreie Stadt",
   code_DE714:"Wiesbaden, Kreisfreie Stadt",
   code_DE715:"Bergstraße",
   code_DE716:"Darmstadt-Dieburg",
   code_DE717:"Groß-Gerau",
   code_DE718:"Hochtaunuskreis",
   code_DE719:"Main-Kinzig-Kreis",
   code_DE71A:"Main-Taunus-Kreis",
   code_DE71B:"Odenwaldkreis",
   code_DE71C:"Offenbach, Landkreis",
   code_DE71D:"Rheingau-Taunus-Kreis",
   code_DE71E:"Wetteraukreis",
   code_DE72:"Gießen",
   code_DE721:"Gießen, Landkreis",
   code_DE722:"Lahn-Dill-Kreis",
   code_DE723:"Limburg-Weilburg",
   code_DE724:"Marburg-Biedenkopf",
   code_DE725:"Vogelsbergkreis",
   code_DE73:"Kassel",
   code_DE731:"Kassel, Kreisfreie Stadt",
   code_DE732:"Fulda",
   code_DE733:"Hersfeld-Rotenburg",
   code_DE734:"Kassel, Landkreis",
   code_DE735:"Schwalm-Eder-Kreis",
   code_DE736:"Waldeck-Frankenberg",
   code_DE737:"Werra-Meißner-Kreis",
   code_DE8:"MECKLENBURG-VORPOMMERN",
   code_DE80:"Mecklenburg-Vorpommern",
   code_DE803:"Rostock, Kreisfreie Stadt",
   code_DE804:"Schwerin, Kreisfreie Stadt",
   code_DE80J:"Mecklenburgische Seenplatte",
   code_DE80K:"Landkreis Rostock",
   code_DE80L:"Vorpommern-Rügen",
   code_DE80M:"Nordwestmecklenburg",
   code_DE80N:"Vorpommern-Greifswald",
   code_DE80O:"Ludwigslust-Parchim",
   code_DE9:"NIEDERSACHSEN",
   code_DE91:"Braunschweig",
   code_DE911:"Braunschweig, Kreisfreie Stadt",
   code_DE912:"Salzgitter, Kreisfreie Stadt",
   code_DE913:"Wolfsburg, Kreisfreie Stadt",
   code_DE914:"Gifhorn",
   code_DE916:"Goslar",
   code_DE917:"Helmstedt",
   code_DE918:"Northeim",
   code_DE91A:"Peine",
   code_DE91B:"Wolfenbüttel",
   code_DE91C:"Göttingen",
   code_DE92:"Hannover",
   code_DE922:"Diepholz",
   code_DE923:"Hameln-Pyrmont",
   code_DE925:"Hildesheim",
   code_DE926:"Holzminden",
   code_DE927:"Nienburg (Weser)",
   code_DE928:"Schaumburg",
   code_DE929:"Region Hannover",
   code_DE93:"Lüneburg",
   code_DE931:"Celle",
   code_DE932:"Cuxhaven",
   code_DE933:"Harburg",
   code_DE934:"Lüchow-Dannenberg",
   code_DE935:"Lüneburg, Landkreis",
   code_DE936:"Osterholz",
   code_DE937:"Rotenburg (Wümme)",
   code_DE938:"Heidekreis",
   code_DE939:"Stade",
   code_DE93A:"Uelzen",
   code_DE93B:"Verden",
   code_DE94:"Weser-Ems",
   code_DE941:"Delmenhorst, Kreisfreie Stadt",
   code_DE942:"Emden, Kreisfreie Stadt",
   code_DE943:"Oldenburg (Oldenburg), Kreisfreie Stadt",
   code_DE944:"Osnabrück, Kreisfreie Stadt",
   code_DE945:"Wilhelmshaven, Kreisfreie Stadt",
   code_DE946:"Ammerland",
   code_DE947:"Aurich",
   code_DE948:"Cloppenburg",
   code_DE949:"Emsland",
   code_DE94A:"Friesland (DE)",
   code_DE94B:"Grafschaft Bentheim",
   code_DE94C:"Leer",
   code_DE94D:"Oldenburg, Landkreis",
   code_DE94E:"Osnabrück, Landkreis",
   code_DE94F:"Vechta",
   code_DE94G:"Wesermarsch",
   code_DE94H:"Wittmund",
   code_DEA:"NORDRHEIN-WESTFALEN",
   code_DEA1:"Düsseldorf",
   code_DEA11:"Düsseldorf, Kreisfreie Stadt",
   code_DEA12:"Duisburg, Kreisfreie Stadt",
   code_DEA13:"Essen, Kreisfreie Stadt",
   code_DEA14:"Krefeld, Kreisfreie Stadt",
   code_DEA15:"Mönchengladbach, Kreisfreie Stadt",
   code_DEA16:"Mülheim an der Ruhr, Kreisfreie Stadt",
   code_DEA17:"Oberhausen, Kreisfreie Stadt",
   code_DEA18:"Remscheid, Kreisfreie Stadt",
   code_DEA19:"Solingen, Kreisfreie Stadt",
   code_DEA1A:"Wuppertal, Kreisfreie Stadt",
   code_DEA1B:"Kleve",
   code_DEA1C:"Mettmann",
   code_DEA1D:"Rhein-Kreis Neuss",
   code_DEA1E:"Viersen",
   code_DEA1F:"Wesel",
   code_DEA2:"Köln",
   code_DEA22:"Bonn, Kreisfreie Stadt",
   code_DEA23:"Köln, Kreisfreie Stadt",
   code_DEA24:"Leverkusen, Kreisfreie Stadt",
   code_DEA26:"Düren",
   code_DEA27:"Rhein-Erft-Kreis",
   code_DEA28:"Euskirchen",
   code_DEA29:"Heinsberg",
   code_DEA2A:"Oberbergischer Kreis",
   code_DEA2B:"Rheinisch-Bergischer Kreis",
   code_DEA2C:"Rhein-Sieg-Kreis",
   code_DEA2D:"Städteregion Aachen",
   code_DEA3:"Münster",
   code_DEA31:"Bottrop, Kreisfreie Stadt",
   code_DEA32:"Gelsenkirchen, Kreisfreie Stadt",
   code_DEA33:"Münster, Kreisfreie Stadt",
   code_DEA34:"Borken",
   code_DEA35:"Coesfeld",
   code_DEA36:"Recklinghausen",
   code_DEA37:"Steinfurt",
   code_DEA38:"Warendorf",
   code_DEA4:"Detmold",
   code_DEA41:"Bielefeld, Kreisfreie Stadt",
   code_DEA42:"Gütersloh",
   code_DEA43:"Herford",
   code_DEA44:"Höxter",
   code_DEA45:"Lippe",
   code_DEA46:"Minden-Lübbecke",
   code_DEA47:"Paderborn",
   code_DEA5:"Arnsberg",
   code_DEA51:"Bochum, Kreisfreie Stadt",
   code_DEA52:"Dortmund, Kreisfreie Stadt",
   code_DEA53:"Hagen, Kreisfreie Stadt",
   code_DEA54:"Hamm, Kreisfreie Stadt",
   code_DEA55:"Herne, Kreisfreie Stadt",
   code_DEA56:"Ennepe-Ruhr-Kreis",
   code_DEA57:"Hochsauerlandkreis",
   code_DEA58:"Märkischer Kreis",
   code_DEA59:"Olpe",
   code_DEA5A:"Siegen-Wittgenstein",
   code_DEA5B:"Soest",
   code_DEA5C:"Unna",
   code_DEB:"RHEINLAND-PFALZ",
   code_DEB1:"Koblenz",
   code_DEB11:"Koblenz, Kreisfreie Stadt",
   code_DEB12:"Ahrweiler",
   code_DEB13:"Altenkirchen (Westerwald)",
   code_DEB14:"Bad Kreuznach",
   code_DEB15:"Birkenfeld",
   code_DEB17:"Mayen-Koblenz",
   code_DEB18:"Neuwied",
   code_DEB1A:"Rhein-Lahn-Kreis",
   code_DEB1B:"Westerwaldkreis",
   code_DEB1C:"Cochem-Zell",
   code_DEB1D:"Rhein-Hunsrück-Kreis",
   code_DEB2:"Trier",
   code_DEB21:"Trier, Kreisfreie Stadt",
   code_DEB22:"Bernkastel-Wittlich",
   code_DEB23:"Eifelkreis Bitburg-Prüm",
   code_DEB24:"Vulkaneifel",
   code_DEB25:"Trier-Saarburg",
   code_DEB3:"Rheinhessen-Pfalz",
   code_DEB31:"Frankenthal (Pfalz), Kreisfreie Stadt",
   code_DEB32:"Kaiserslautern, Kreisfreie Stadt",
   code_DEB33:"Landau in der Pfalz, Kreisfreie Stadt",
   code_DEB34:"Ludwigshafen am Rhein, Kreisfreie Stadt",
   code_DEB35:"Mainz, Kreisfreie Stadt",
   code_DEB36:"Neustadt an der Weinstraße, Kreisfreie Stadt",
   code_DEB37:"Pirmasens, Kreisfreie Stadt",
   code_DEB38:"Speyer, Kreisfreie Stadt",
   code_DEB39:"Worms, Kreisfreie Stadt",
   code_DEB3A:"Zweibrücken, Kreisfreie Stadt",
   code_DEB3B:"Alzey-Worms",
   code_DEB3C:"Bad Dürkheim",
   code_DEB3D:"Donnersbergkreis",
   code_DEB3E:"Germersheim",
   code_DEB3F:"Kaiserslautern, Landkreis",
   code_DEB3G:"Kusel",
   code_DEB3H:"Südliche Weinstraße",
   code_DEB3I:"Rhein-Pfalz-Kreis",
   code_DEB3J:"Mainz-Bingen",
   code_DEB3K:"Südwestpfalz",
   code_DEC:"SAARLAND",
   code_DEC0:"Saarland",
   code_DEC01:"Regionalverband Saarbrücken",
   code_DEC02:"Merzig-Wadern",
   code_DEC03:"Neunkirchen",
   code_DEC04:"Saarlouis",
   code_DEC05:"Saarpfalz-Kreis",
   code_DEC06:"St. Wendel",
   code_DED:"SACHSEN",
   code_DED2:"Dresden",
   code_DED21:"Dresden, Kreisfreie Stadt",
   code_DED2C:"Bautzen",
   code_DED2D:"Görlitz",
   code_DED2E:"Meißen",
   code_DED2F:"Sächsische Schweiz-Osterzgebirge",
   code_DED4:"Chemnitz",
   code_DED41:"Chemnitz, Kreisfreie Stadt",
   code_DED42:"Erzgebirgskreis",
   code_DED43:"Mittelsachsen",
   code_DED44:"Vogtlandkreis",
   code_DED45:"Zwickau",
   code_DED5:"Leipzig",
   code_DED51:"Leipzig, Kreisfreie Stadt",
   code_DED52:"Leipzig",
   code_DED53:"Nordsachsen",
   code_DEE:"SACHSEN-ANHALT",
   code_DEE0:"Sachsen-Anhalt",
   code_DEE01:"Dessau-Roßlau, Kreisfreie Stadt",
   code_DEE02:"Halle (Saale), Kreisfreie Stadt",
   code_DEE03:"Magdeburg, Kreisfreie Stadt",
   code_DEE04:"Altmarkkreis Salzwedel",
   code_DEE05:"Anhalt-Bitterfeld",
   code_DEE06:"Jerichower Land",
   code_DEE07:"Börde",
   code_DEE08:"Burgenlandkreis",
   code_DEE09:"Harz",
   code_DEE0A:"Mansfeld-Südharz",
   code_DEE0B:"Saalekreis",
   code_DEE0C:"Salzlandkreis",
   code_DEE0D:"Stendal",
   code_DEE0E:"Wittenberg",
   code_DEF:"SCHLESWIG-HOLSTEIN",
   code_DEF0:"Schleswig-Holstein",
   code_DEF01:"Flensburg, Kreisfreie Stadt",
   code_DEF02:"Kiel, Kreisfreie Stadt",
   code_DEF03:"Lübeck, Kreisfreie Stadt",
   code_DEF04:"Neumünster, Kreisfreie Stadt",
   code_DEF05:"Dithmarschen",
   code_DEF06:"Herzogtum Lauenburg",
   code_DEF07:"Nordfriesland",
   code_DEF08:"Ostholstein",
   code_DEF09:"Pinneberg",
   code_DEF0A:"Plön",
   code_DEF0B:"Rendsburg-Eckernförde",
   code_DEF0C:"Schleswig-Flensburg",
   code_DEF0D:"Segeberg",
   code_DEF0E:"Steinburg",
   code_DEF0F:"Stormarn",
   code_DEG:"THÜRINGEN",
   code_DEG0:"Thüringen",
   code_DEG01:"Erfurt, Kreisfreie Stadt",
   code_DEG02:"Gera, Kreisfreie Stadt",
   code_DEG03:"Jena, Kreisfreie Stadt",
   code_DEG04:"Suhl, Kreisfreie Stadt",
   code_DEG05:"Weimar, Kreisfreie Stadt",
   code_DEG06:"Eichsfeld",
   code_DEG07:"Nordhausen",
   code_DEG09:"Unstrut-Hainich-Kreis",
   code_DEG0A:"Kyffhäuserkreis",
   code_DEG0B:"Schmalkalden-Meiningen",
   code_DEG0C:"Gotha",
   code_DEG0D:"Sömmerda",
   code_DEG0E:"Hildburghausen",
   code_DEG0F:"Ilm-Kreis",
   code_DEG0G:"Weimarer Land",
   code_DEG0H:"Sonneberg",
   code_DEG0I:"Saalfeld-Rudolstadt",
   code_DEG0J:"Saale-Holzland-Kreis",
   code_DEG0K:"Saale-Orla-Kreis",
   code_DEG0L:"Greiz",
   code_DEG0M:"Altenburger Land",
   code_DEG0N:"Eisenach, Kreisfreie Stadt",
   code_DEG0P:"Wartburgkreis",
   code_DEZ:"EXTRA-REGIO NUTS 1",
   code_DEZZ:"Extra-Regio NUTS 2",
   code_DEZZZ:"Extra-Regio NUTS 3",
   code_DK:"DANMARK",
   code_DK0:"DANMARK",
   code_DK01:"Hovedstaden",
   code_DK011:"Byen København",
   code_DK012:"Københavns omegn",
   code_DK013:"Nordsjælland",
   code_DK014:"Bornholm",
   code_DK02:"Sjælland",
   code_DK021:"Østsjælland",
   code_DK022:"Vest- og Sydsjælland",
   code_DK03:"Syddanmark",
   code_DK031:"Fyn",
   code_DK032:"Sydjylland",
   code_DK04:"Midtjylland",
   code_DK041:"Vestjylland",
   code_DK042:"Østjylland",
   code_DK05:"Nordjylland",
   code_DK050:"Nordjylland",
   code_DKZ:"EXTRA-REGIO NUTS 1",
   code_DKZZ:"Extra-Regio NUTS 2",
   code_DKZZZ:"Extra-Regio NUTS 3",
   code_EE:"EESTI",
   code_EE0:"EESTI",
   code_EE00:"Eesti",
   code_EE001:"Põhja-Eesti",
   code_EE004:"Lääne-Eesti",
   code_EE006:"Kesk-Eesti",
   code_EE007:"Kirde-Eesti",
   code_EE008:"Lõuna-Eesti",
   code_EEZ:"EXTRA-REGIO NUTS 1",
   code_EEZZ:"Extra-Regio NUTS 2",
   code_EEZZZ:"Extra-Regio NUTS 3",
   code_EL:"ΕΛΛΑΔΑ",
   code_EL3:"ATTIKΗ",
   code_EL30:"Aττική",
   code_EL301:"Βόρειος Τομέας Αθηνών",
   code_EL302:"Δυτικός Τομέας Αθηνών",
   code_EL303:"Κεντρικός Τομέας Αθηνών",
   code_EL304:"Νότιος Τομέας Αθηνών",
   code_EL305:"Ανατολική Αττική",
   code_EL306:"Δυτική Αττική",
   code_EL307:"Πειραιάς, Νήσοι",
   code_EL4:"NΗΣΙΑ ΑΙΓΑΙΟΥ, KΡΗΤΗ",
   code_EL41:"Βόρειο Αιγαίο",
   code_EL411:"Λέσβος, Λήμνος",
   code_EL412:"Ικαρία, Σάμος",
   code_EL413:"Χίος",
   code_EL42:"Νότιο Αιγαίο",
   code_EL421:"Κάλυμνος, Κάρπαθος, Κως, Ρόδος",
   code_EL422:"Άνδρος, Θήρα, Κέα, Μήλος, Μύκονος, Νάξος, Πάρος, Σύρος, Τήνος",
   code_EL43:"Κρήτη",
   code_EL431:"Ηράκλειο",
   code_EL432:"Λασίθι",
   code_EL433:"Ρέθυμνο",
   code_EL434:"Χανιά",
   code_EL5:"ΒΟΡΕΙΑ ΕΛΛΑΔΑ",
   code_EL51:"Aνατολική Μακεδονία, Θράκη",
   code_EL511:"Έβρος",
   code_EL512:"Ξάνθη",
   code_EL513:"Ροδόπη",
   code_EL514:"Δράμα",
   code_EL515:"Θάσος, Καβάλα",
   code_EL52:"Κεντρική Μακεδονία",
   code_EL521:"Ημαθία",
   code_EL522:"Θεσσαλονίκη",
   code_EL523:"Κιλκίς",
   code_EL524:"Πέλλα",
   code_EL525:"Πιερία",
   code_EL526:"Σέρρες",
   code_EL527:"Χαλκιδική",
   code_EL53:"Δυτική Μακεδονία",
   code_EL531:"Γρεβενά, Κοζάνη",
   code_EL532:"Καστοριά",
   code_EL533:"Φλώρινα",
   code_EL54:"Ήπειρος",
   code_EL541:"Άρτα, Πρέβεζα",
   code_EL542:"Θεσπρωτία",
   code_EL543:"Ιωάννινα",
   code_EL6:"ΚΕΝΤΡΙΚΗ ΕΛΛΑΔΑ",
   code_EL61:"Θεσσαλία",
   code_EL611:"Καρδίτσα, Τρίκαλα",
   code_EL612:"Λάρισα",
   code_EL613:"Μαγνησία, Σποράδες",
   code_EL62:"Ιόνια Νησιά",
   code_EL621:"Ζάκυνθος",
   code_EL622:"Κέρκυρα",
   code_EL623:"Ιθάκη, Κεφαλληνία",
   code_EL624:"Λευκάδα",
   code_EL63:"Δυτική Ελλάδα",
   code_EL631:"Αιτωλοακαρνανία",
   code_EL632:"Αχαΐα",
   code_EL633:"Ηλεία",
   code_EL64:"Στερεά Ελλάδα",
   code_EL641:"Βοιωτία",
   code_EL642:"Εύβοια",
   code_EL643:"Ευρυτανία",
   code_EL644:"Φθιώτιδα",
   code_EL645:"Φωκίδα",
   code_EL65:"Πελοπόννησος",
   code_EL651:"Αργολίδα, Αρκαδία",
   code_EL652:"Κορινθία",
   code_EL653:"Λακωνία, Μεσσηνία",
   code_ELZ:"EXTRA-REGIO NUTS 1",
   code_ELZZ:"Extra-Regio NUTS 2",
   code_ELZZZ:"Extra-Regio NUTS 3",
   code_ES:"ESPAÑA",
   code_ES1:"NOROESTE",
   code_ES11:"Galicia",
   code_ES111:"A Coruña",
   code_ES112:"Lugo",
   code_ES113:"Ourense",
   code_ES114:"Pontevedra",
   code_ES12:"Principado de Asturias",
   code_ES120:"Asturias",
   code_ES13:"Cantabria",
   code_ES130:"Cantabria",
   code_ES2:"NORESTE",
   code_ES21:"País Vasco",
   code_ES211:"Araba / Álava",
   code_ES212:"Gipuzkoa",
   code_ES213:"Bizkaia",
   code_ES22:"Comunidad Foral de Navarra",
   code_ES220:"Navarra",
   code_ES23:"La Rioja",
   code_ES230:"La Rioja",
   code_ES24:"Aragón",
   code_ES241:"Huesca",
   code_ES242:"Teruel",
   code_ES243:"Zaragoza",
   code_ES3:"COMUNIDAD DE MADRID",
   code_ES30:"Comunidad de Madrid",
   code_ES300:"Madrid",
   code_ES4:"CENTRO (ES)",
   code_ES41:"Castilla y León",
   code_ES411:"Ávila",
   code_ES412:"Burgos",
   code_ES413:"León",
   code_ES414:"Palencia",
   code_ES415:"Salamanca",
   code_ES416:"Segovia",
   code_ES417:"Soria",
   code_ES418:"Valladolid",
   code_ES419:"Zamora",
   code_ES42:"Castilla-La Mancha",
   code_ES421:"Albacete",
   code_ES422:"Ciudad Real",
   code_ES423:"Cuenca",
   code_ES424:"Guadalajara",
   code_ES425:"Toledo",
   code_ES43:"Extremadura",
   code_ES431:"Badajoz",
   code_ES432:"Cáceres",
   code_ES5:"ESTE",
   code_ES51:"Cataluña",
   code_ES511:"Barcelona",
   code_ES512:"Girona",
   code_ES513:"Lleida",
   code_ES514:"Tarragona",
   code_ES52:"Comunidad Valenciana",
   code_ES521:"Alicante/Alacant",
   code_ES522:"Castellón/Castelló",
   code_ES523:"Valencia/València",
   code_ES53:"Illes Balears",
   code_ES531:"Eivissa y Formentera",
   code_ES532:"Mallorca",
   code_ES533:"Menorca",
   code_ES6:"SUR",
   code_ES61:"Andalucía",
   code_ES611:"Almería",
   code_ES612:"Cádiz",
   code_ES613:"Córdoba",
   code_ES614:"Granada",
   code_ES615:"Huelva",
   code_ES616:"Jaén",
   code_ES617:"Málaga",
   code_ES618:"Sevilla",
   code_ES62:"Región de Murcia",
   code_ES620:"Murcia",
   code_ES63:"Ciudad Autónoma de Ceuta",
   code_ES630:"Ceuta",
   code_ES64:"Ciudad Autónoma de Melilla",
   code_ES640:"Melilla",
   code_ES7:"CANARIAS",
   code_ES70:"Canarias",
   code_ES703:"El Hierro",
   code_ES704:"Fuerteventura",
   code_ES705:"Gran Canaria",
   code_ES706:"La Gomera",
   code_ES707:"La Palma",
   code_ES708:"Lanzarote",
   code_ES709:"Tenerife",
   code_ESZ:"EXTRA-REGIO NUTS 1",
   code_ESZZ:"Extra-Regio NUTS 2",
   code_ESZZZ:"Extra-Regio NUTS 3",
   code_FI:"SUOMI / FINLAND",
   code_FI1:"MANNER-SUOMI",
   code_FI19:"Länsi-Suomi",
   code_FI193:"Keski-Suomi",
   code_FI194:"Etelä-Pohjanmaa",
   code_FI195:"Pohjanmaa",
   code_FI196:"Satakunta",
   code_FI197:"Pirkanmaa",
   code_FI1B:"Helsinki-Uusimaa",
   code_FI1B1:"Helsinki-Uusimaa",
   code_FI1C:"Etelä-Suomi",
   code_FI1C1:"Varsinais-Suomi",
   code_FI1C2:"Kanta-Häme",
   code_FI1C3:"Päijät-Häme",
   code_FI1C4:"Kymenlaakso",
   code_FI1C5:"Etelä-Karjala",
   code_FI1D:"Pohjois- ja Itä-Suomi",
   code_FI1D1:"Etelä-Savo",
   code_FI1D2:"Pohjois-Savo",
   code_FI1D3:"Pohjois-Karjala",
   code_FI1D5:"Keski-Pohjanmaa",
   code_FI1D7:"Lappi",
   code_FI1D8:"Kainuu",
   code_FI1D9:"Pohjois-Pohjanmaa",
   code_FI2:"ÅLAND",
   code_FI20:"Åland",
   code_FI200:"Åland",
   code_FIZ:"EXTRA-REGIO NUTS 1",
   code_FIZZ:"Extra-Regio NUTS 2",
   code_FIZZZ:"Extra-Regio NUTS 3",
   code_FR:"FRANCE",
   code_FR1:"ILE-DE-FRANCE",
   code_FR10:"Ile-de-France",
   code_FR101:"Paris",
   code_FR102:"Seine-et-Marne",
   code_FR103:"Yvelines",
   code_FR104:"Essonne",
   code_FR105:"Hauts-de-Seine",
   code_FR106:"Seine-Saint-Denis",
   code_FR107:"Val-de-Marne",
   code_FR108:"Val-d’Oise",
   code_FRB:"CENTRE – VAL DE LOIRE",
   code_FRB0:"Centre – Val de Loire",
   code_FRB01:"Cher",
   code_FRB02:"Eure-et-Loir",
   code_FRB03:"Indre",
   code_FRB04:"Indre-et-Loire",
   code_FRB05:"Loir-et-Cher",
   code_FRB06:"Loiret",
   code_FRC:"BOURGOGNE-FRANCHE-COMTÉ",
   code_FRC1:"Bourgogne",
   code_FRC11:"Côte-d’Or",
   code_FRC12:"Nièvre",
   code_FRC13:"Saône-et-Loire",
   code_FRC14:"Yonne",
   code_FRC2:"Franche-Comté",
   code_FRC21:"Doubs",
   code_FRC22:"Jura",
   code_FRC23:"Haute-Saône",
   code_FRC24:"Territoire de Belfort",
   code_FRD:"NORMANDIE",
   code_FRD1:"Basse-Normandie",
   code_FRD11:"Calvados",
   code_FRD12:"Manche",
   code_FRD13:"Orne",
   code_FRD2:"Haute-Normandie",
   code_FRD21:"Eure",
   code_FRD22:"Seine-Maritime",
   code_FRE:"NORD-PAS DE CALAIS-PICARDIE",
   code_FRE1:"Nord-Pas de Calais",
   code_FRE11:"Nord",
   code_FRE12:"Pas-de-Calais",
   code_FRE2:"Picardie",
   code_FRE21:"Aisne",
   code_FRE22:"Oise",
   code_FRE23:"Somme",
   code_FRF:"ALSACE-CHAMPAGNE-ARDENNE-LORRAINE",
   code_FRF1:"Alsace",
   code_FRF11:"Bas-Rhin",
   code_FRF12:"Haut-Rhin",
   code_FRF2:"Champagne-Ardenne",
   code_FRF21:"Ardennes",
   code_FRF22:"Aube",
   code_FRF23:"Marne",
   code_FRF24:"Haute-Marne",
   code_FRF3:"Lorraine",
   code_FRF31:"Meurthe-et-Moselle",
   code_FRF32:"Meuse",
   code_FRF33:"Moselle",
   code_FRF34:"Vosges",
   code_FRG:"PAYS DE LA LOIRE",
   code_FRG0:"Pays de la Loire",
   code_FRG01:"Loire-Atlantique",
   code_FRG02:"Maine-et-Loire",
   code_FRG03:"Mayenne",
   code_FRG04:"Sarthe",
   code_FRG05:"Vendée",
   code_FRH:"BRETAGNE",
   code_FRH0:"Bretagne",
   code_FRH01:"Côtes-d’Armor",
   code_FRH02:"Finistère",
   code_FRH03:"Ille-et-Vilaine",
   code_FRH04:"Morbihan",
   code_FRI:"AQUITAINE-LIMOUSIN-POITOU-CHARENTES",
   code_FRI1:"Aquitaine",
   code_FRI11:"Dordogne",
   code_FRI12:"Gironde",
   code_FRI13:"Landes",
   code_FRI14:"Lot-et-Garonne",
   code_FRI15:"Pyrénées-Atlantiques",
   code_FRI2:"Limousin",
   code_FRI21:"Corrèze",
   code_FRI22:"Creuse",
   code_FRI23:"Haute-Vienne",
   code_FRI3:"Poitou - Charentes",
   code_FRI31:"Charente",
   code_FRI32:"Charente-Maritime",
   code_FRI33:"Deux-Sèvres",
   code_FRI34:"Vienne",
   code_FRJ:"LANGUEDOC-ROUSSILLON-MIDI-PYRÉNÉES",
   code_FRJ1:"Languedoc-Roussillon",
   code_FRJ11:"Aude",
   code_FRJ12:"Gard",
   code_FRJ13:"Hérault",
   code_FRJ14:"Lozère",
   code_FRJ15:"Pyrénées-Orientales",
   code_FRJ2:"Midi-Pyrénées",
   code_FRJ21:"Ariège",
   code_FRJ22:"Aveyron",
   code_FRJ23:"Haute-Garonne",
   code_FRJ24:"Gers",
   code_FRJ25:"Lot",
   code_FRJ26:"Hautes-Pyrénées",
   code_FRJ27:"Tarn",
   code_FRJ28:"Tarn-et-Garonne",
   code_FRK:"AUVERGNE-RHÔNE-ALPES",
   code_FRK1:"Auvergne",
   code_FRK11:"Allier",
   code_FRK12:"Cantal",
   code_FRK13:"Haute-Loire",
   code_FRK14:"Puy-de-Dôme",
   code_FRK2:"Rhône-Alpes",
   code_FRK21:"Ain",
   code_FRK22:"Ardèche",
   code_FRK23:"Drôme",
   code_FRK24:"Isère",
   code_FRK25:"Loire",
   code_FRK26:"Rhône",
   code_FRK27:"Savoie",
   code_FRK28:"Haute-Savoie",
   code_FRL:"PROVENCE-ALPES-CÔTE D’AZUR",
   code_FRL0:"Provence-Alpes-Côte d’Azur",
   code_FRL01:"Alpes-de-Haute-Provence",
   code_FRL02:"Hautes-Alpes",
   code_FRL03:"Alpes-Maritimes",
   code_FRL04:"Bouches-du-Rhône",
   code_FRL05:"Var",
   code_FRL06:"Vaucluse",
   code_FRM:"CORSE",
   code_FRM0:"Corse",
   code_FRM01:"Corse-du-Sud",
   code_FRM02:"Haute-Corse",
   code_FRY:"RUP FR – RÉGIONS ULTRAPÉRIPHÉRIQUES FRANÇAISES",
   code_FRY1:"Guadeloupe",
   code_FRY10:"Guadeloupe",
   code_FRY2:"Martinique",
   code_FRY20:"Martinique",
   code_FRY3:"Guyane",
   code_FRY30:"Guyane",
   code_FRY4:"La Réunion",
   code_FRY40:"La Réunion",
   code_FRY5:"Mayotte",
   code_FRY50:"Mayotte",
   code_FRZ:"EXTRA-REGIO NUTS 1",
   code_FRZZ:"Extra-Regio NUTS 2",
   code_FRZZZ:"Extra-Regio NUTS 3",
   code_HR:"HRVATSKA",
   code_HR0:"HRVATSKA",
   code_HR03:"Jadranska Hrvatska",
   code_HR031:"Primorsko-goranska županija",
   code_HR032:"Ličko-senjska županija",
   code_HR033:"Zadarska županija",
   code_HR034:"Šibensko-kninska županija",
   code_HR035:"Splitsko-dalmatinska županija",
   code_HR036:"Istarska županija",
   code_HR037:"Dubrovačko-neretvanska županija",
   code_HR04:"Kontinentalna Hrvatska",
   code_HR041:"Grad Zagreb",
   code_HR042:"Zagrebačka županija",
   code_HR043:"Krapinsko-zagorska županija",
   code_HR044:"Varaždinska županija",
   code_HR045:"Koprivničko-križevačka županija",
   code_HR046:"Međimurska županija",
   code_HR047:"Bjelovarsko-bilogorska županija",
   code_HR048:"Virovitičko-podravska županija",
   code_HR049:"Požeško-slavonska županija",
   code_HR04A:"Brodsko-posavska županija",
   code_HR04B:"Osječko-baranjska županija",
   code_HR04C:"Vukovarsko-srijemska županija",
   code_HR04D:"Karlovačka županija",
   code_HR04E:"Sisačko-moslavačka županija",
   code_HRZ:"EXTRA-REGIO NUTS 1",
   code_HRZZ:"Extra-Regio NUTS 2",
   code_HRZZZ:"Extra-Regio NUTS 3",
   code_HU:"MAGYARORSZÁG",
   code_HU1:"KÖZÉP-MAGYARORSZÁG",
   code_HU11:"Budapest",
   code_HU110:"Budapest",
   code_HU12:"Pest",
   code_HU120:"Pest",
   code_HU2:"DUNÁNTÚL",
   code_HU21:"Közép-Dunántúl",
   code_HU211:"Fejér",
   code_HU212:"Komárom-Esztergom",
   code_HU213:"Veszprém",
   code_HU22:"Nyugat-Dunántúl",
   code_HU221:"Győr-Moson-Sopron",
   code_HU222:"Vas",
   code_HU223:"Zala",
   code_HU23:"Dél-Dunántúl",
   code_HU231:"Baranya",
   code_HU232:"Somogy",
   code_HU233:"Tolna",
   code_HU3:"ALFÖLD ÉS ÉSZAK",
   code_HU31:"Észak-Magyarország",
   code_HU311:"Borsod-Abaúj-Zemplén",
   code_HU312:"Heves",
   code_HU313:"Nógrád",
   code_HU32:"Észak-Alföld",
   code_HU321:"Hajdú-Bihar",
   code_HU322:"Jász-Nagykun-Szolnok",
   code_HU323:"Szabolcs-Szatmár-Bereg",
   code_HU33:"Dél-Alföld",
   code_HU331:"Bács-Kiskun",
   code_HU332:"Békés",
   code_HU333:"Csongrád",
   code_HUZ:"EXTRA-REGIO NUTS 1",
   code_HUZZ:"Extra-Regio NUTS 2",
   code_HUZZZ:"Extra-Regio NUTS 3",
   code_IE:"IRELAND",
   code_IE0:"IRELAND",
   code_IE04:"Northern and Western",
   code_IE041:"Border",
   code_IE042:"West",
   code_IE05:"Southern",
   code_IE051:"Mid-West",
   code_IE052:"South-East",
   code_IE053:"South-West",
   code_IE06:"Eastern and Midland",
   code_IE061:"Dublin",
   code_IE062:"Mid-East",
   code_IE063:"Midland",
   code_IEZ:"EXTRA-REGIO NUTS 1",
   code_IEZZ:"Extra-Regio NUTS 2",
   code_IEZZZ:"Extra-Regio NUTS 3",
   code_IS:"Iceland",
   code_IS0:"Ísland",
   code_IS00:"Ísland",
   code_IS001:"Höfuðborgarsvæði",
   code_IS002:"Landsbyggð",
   code_IT:"ITALIA",
   code_ITC:"NORD-OVEST",
   code_ITC1:"Piemonte",
   code_ITC11:"Torino",
   code_ITC12:"Vercelli",
   code_ITC13:"Biella",
   code_ITC14:"Verbano-Cusio-Ossola",
   code_ITC15:"Novara",
   code_ITC16:"Cuneo",
   code_ITC17:"Asti",
   code_ITC18:"Alessandria",
   code_ITC2:"Valle d’Aosta / Vallée d’Aoste",
   code_ITC20:"Valle d’Aosta / Vallée d’Aoste",
   code_ITC3:"Liguria",
   code_ITC31:"Imperia",
   code_ITC32:"Savona",
   code_ITC33:"Genova",
   code_ITC34:"La Spezia",
   code_ITC4:"Lombardia",
   code_ITC41:"Varese",
   code_ITC42:"Como",
   code_ITC43:"Lecco",
   code_ITC44:"Sondrio",
   code_ITC46:"Bergamo",
   code_ITC47:"Brescia",
   code_ITC48:"Pavia",
   code_ITC49:"Lodi",
   code_ITC4A:"Cremona",
   code_ITC4B:"Mantova",
   code_ITC4C:"Milano",
   code_ITC4D:"Monza e della Brianza",
   code_ITF:"SUD",
   code_ITF1:"Abruzzo",
   code_ITF11:"L’Aquila",
   code_ITF12:"Teramo",
   code_ITF13:"Pescara",
   code_ITF14:"Chieti",
   code_ITF2:"Molise",
   code_ITF21:"Isernia",
   code_ITF22:"Campobasso",
   code_ITF3:"Campania",
   code_ITF31:"Caserta",
   code_ITF32:"Benevento",
   code_ITF33:"Napoli",
   code_ITF34:"Avellino",
   code_ITF35:"Salerno",
   code_ITF4:"Puglia",
   code_ITF43:"Taranto",
   code_ITF44:"Brindisi",
   code_ITF45:"Lecce",
   code_ITF46:"Foggia",
   code_ITF47:"Bari",
   code_ITF48:"Barletta-Andria-Trani",
   code_ITF5:"Basilicata",
   code_ITF51:"Potenza",
   code_ITF52:"Matera",
   code_ITF6:"Calabria",
   code_ITF61:"Cosenza",
   code_ITF62:"Crotone",
   code_ITF63:"Catanzaro",
   code_ITF64:"Vibo Valentia",
   code_ITF65:"Reggio di Calabria",
   code_ITG:"ISOLE",
   code_ITG1:"Sicilia",
   code_ITG11:"Trapani",
   code_ITG12:"Palermo",
   code_ITG13:"Messina",
   code_ITG14:"Agrigento",
   code_ITG15:"Caltanissetta",
   code_ITG16:"Enna",
   code_ITG17:"Catania",
   code_ITG18:"Ragusa",
   code_ITG19:"Siracusa",
   code_ITG2:"Sardegna",
   code_ITG25:"Sassari",
   code_ITG26:"Nuoro",
   code_ITG27:"Cagliari",
   code_ITG28:"Oristano",
   code_ITG29:"Olbia-Tempio",
   code_ITG2A:"Ogliastra",
   code_ITG2B:"Medio Campidano",
   code_ITG2C:"Carbonia-Iglesias",
   code_ITH:"NORD-EST",
   code_ITH1:"Provincia Autonoma di Bolzano / Bozen",
   code_ITH10:"Bolzano-Bozen",
   code_ITH2:"Provincia Autonoma di Trento",
   code_ITH20:"Trento",
   code_ITH3:"Veneto",
   code_ITH31:"Verona",
   code_ITH32:"Vicenza",
   code_ITH33:"Belluno",
   code_ITH34:"Treviso",
   code_ITH35:"Venezia",
   code_ITH36:"Padova",
   code_ITH37:"Rovigo",
   code_ITH4:"Friuli-Venezia Giulia",
   code_ITH41:"Pordenone",
   code_ITH42:"Udine",
   code_ITH43:"Gorizia",
   code_ITH44:"Trieste",
   code_ITH5:"Emilia-Romagna",
   code_ITH51:"Piacenza",
   code_ITH52:"Parma",
   code_ITH53:"Reggio nell’Emilia",
   code_ITH54:"Modena",
   code_ITH55:"Bologna",
   code_ITH56:"Ferrara",
   code_ITH57:"Ravenna",
   code_ITH58:"Forlì-Cesena",
   code_ITH59:"Rimini",
   code_ITI:"CENTRO (IT)",
   code_ITI1:"Toscana",
   code_ITI11:"Massa-Carrara",
   code_ITI12:"Lucca",
   code_ITI13:"Pistoia",
   code_ITI14:"Firenze",
   code_ITI15:"Prato",
   code_ITI16:"Livorno",
   code_ITI17:"Pisa",
   code_ITI18:"Arezzo",
   code_ITI19:"Siena",
   code_ITI1A:"Grosseto",
   code_ITI2:"Umbria",
   code_ITI21:"Perugia",
   code_ITI22:"Terni",
   code_ITI3:"Marche",
   code_ITI31:"Pesaro e Urbino",
   code_ITI32:"Ancona",
   code_ITI33:"Macerata",
   code_ITI34:"Ascoli Piceno",
   code_ITI35:"Fermo",
   code_ITI4:"Lazio",
   code_ITI41:"Viterbo",
   code_ITI42:"Rieti",
   code_ITI43:"Roma",
   code_ITI44:"Latina",
   code_ITI45:"Frosinone",
   code_ITZ:"EXTRA-REGIO NUTS 1",
   code_ITZZ:"Extra-Regio NUTS 2",
   code_ITZZZ:"Extra-Regio NUTS 3",
   code_LI:"Liechtenstein",
   code_LI0:"Liechtenstein",
   code_LI00:"Liechtenstein",
   code_LI000:"Liechtenstein",
   code_LT:"LIETUVA",
   code_LT0:"LIETUVA",
   code_LT01:"Sostinės regionas",
   code_LT011:"Vilniaus apskritis",
   code_LT02:"Vidurio ir vakarų Lietuvos regionas",
   code_LT021:"Alytaus apskritis",
   code_LT022:"Kauno apskritis",
   code_LT023:"Klaipėdos apskritis",
   code_LT024:"Marijampolės apskritis",
   code_LT025:"Panevėžio apskritis",
   code_LT026:"Šiaulių apskritis",
   code_LT027:"Tauragės apskritis",
   code_LT028:"Telšių apskritis",
   code_LT029:"Utenos apskritis",
   code_LTZ:"EXTRA-REGIO NUTS 1",
   code_LTZZ:"Extra-Regio NUTS 2",
   code_LTZZZ:"Extra-Regio NUTS 3",
   code_LU:"LUXEMBOURG",
   code_LU0:"LUXEMBOURG",
   code_LU00:"Luxembourg",
   code_LU000:"Luxembourg",
   code_LUZ:"EXTRA-REGIO NUTS 1",
   code_LUZZ:"Extra-Regio NUTS 2",
   code_LUZZZ:"Extra-Regio NUTS 3",
   code_LV:"LATVIJA",
   code_LV0:"LATVIJA",
   code_LV00:"Latvija",
   code_LV003:"Kurzeme",
   code_LV005:"Latgale",
   code_LV006:"Rīga",
   code_LV007:"Pierīga",
   code_LV008:"Vidzeme",
   code_LV009:"Zemgale",
   code_LVZ:"EXTRA-REGIO NUTS 1",
   code_LVZZ:"Extra-Regio NUTS 2",
   code_LVZZZ:"Extra-Regio NUTS 3",
   code_ME:"Montenegro",
   code_ME0:"Црна Гора",
   code_ME00:"Црна Гора",
   code_ME000:"Црна Гора",
   code_MK:"Macedonia",
   code_MK0:"Северна Македонија",
   code_MK00:"Северна Македонија",
   code_MK001:"Вардарски",
   code_MK002:"Источен",
   code_MK003:"Југозападен",
   code_MK004:"Југоисточен",
   code_MK005:"Пелагониски",
   code_MK006:"Полошки",
   code_MK007:"Североисточен",
   code_MK008:"Скопски",
   code_MT:"MALTA",
   code_MT0:"MALTA",
   code_MT00:"Malta",
   code_MT001:"Malta",
   code_MT002:"Gozo and Comino / Għawdex u Kemmuna",
   code_MTZ:"EXTRA-REGIO NUTS 1",
   code_MTZZ:"Extra-Regio NUTS 2",
   code_MTZZZ:"Extra-Regio NUTS 3",
   code_NL:"NEDERLAND",
   code_NL1:"NOORD-NEDERLAND",
   code_NL11:"Groningen",
   code_NL111:"Oost-Groningen",
   code_NL112:"Delfzijl en omgeving",
   code_NL113:"Overig Groningen",
   code_NL12:"Friesland (NL)",
   code_NL124:"Noord-Friesland",
   code_NL125:"Zuidwest-Friesland",
   code_NL126:"Zuidoost-Friesland",
   code_NL13:"Drenthe",
   code_NL131:"Noord-Drenthe",
   code_NL132:"Zuidoost-Drenthe",
   code_NL133:"Zuidwest-Drenthe",
   code_NL2:"OOST-NEDERLAND",
   code_NL21:"Overijssel",
   code_NL211:"Noord-Overijssel",
   code_NL212:"Zuidwest-Overijssel",
   code_NL213:"Twente",
   code_NL22:"Gelderland",
   code_NL221:"Veluwe",
   code_NL224:"Zuidwest-Gelderland",
   code_NL225:"Achterhoek",
   code_NL226:"Arnhem/Nijmegen",
   code_NL23:"Flevoland",
   code_NL230:"Flevoland",
   code_NL3:"WEST-NEDERLAND",
   code_NL31:"Utrecht",
   code_NL310:"Utrecht",
   code_NL32:"Noord-Holland",
   code_NL321:"Kop van Noord-Holland",
   code_NL323:"IJmond",
   code_NL324:"Agglomeratie Haarlem",
   code_NL325:"Zaanstreek",
   code_NL327:"Het Gooi en Vechtstreek",
   code_NL328:"Alkmaar en omgeving",
   code_NL329:"Groot-Amsterdam",
   code_NL33:"Zuid-Holland",
   code_NL332:"Agglomeratie ’s-Gravenhage",
   code_NL333:"Delft en Westland",
   code_NL337:"Agglomeratie Leiden en Bollenstreek",
   code_NL33A:"Zuidoost-Zuid-Holland",
   code_NL33B:"Oost-Zuid-Holland",
   code_NL33C:"Groot-Rijnmond",
   code_NL34:"Zeeland",
   code_NL341:"Zeeuwsch-Vlaanderen",
   code_NL342:"Overig Zeeland",
   code_NL4:"ZUID-NEDERLAND",
   code_NL41:"Noord-Brabant",
   code_NL411:"West-Noord-Brabant",
   code_NL412:"Midden-Noord-Brabant",
   code_NL413:"Noordoost-Noord-Brabant",
   code_NL414:"Zuidoost-Noord-Brabant",
   code_NL42:"Limburg (NL)",
   code_NL421:"Noord-Limburg",
   code_NL422:"Midden-Limburg",
   code_NL423:"Zuid-Limburg",
   code_NLZ:"EXTRA-REGIO NUTS 1",
   code_NLZZ:"Extra-Regio NUTS 2",
   code_NLZZZ:"Extra-Regio NUTS 3",
   code_NO:"Norway",
   code_NO0:"Norge",
   code_NO01:"Oslo og Akershus",
   code_NO011:"Oslo",
   code_NO012:"Akershus",
   code_NO02:"Innlandet",
   code_NO021:"Hedmark",
   code_NO022:"Oppland",
   code_NO03:"Sør-Østlandet",
   code_NO031:"Østfold",
   code_NO032:"Buskerud",
   code_NO033:"Vestfold",
   code_NO034:"Telemark",
   code_NO04:"Agder og Rogaland",
   code_NO041:"Aust-Agder",
   code_NO042:"Vest-Agder",
   code_NO043:"Rogaland",
   code_NO05:"Vestlandet",
   code_NO051:"Hordaland",
   code_NO052:"Sogn og Fjordane",
   code_NO053:"Møre og Romsdal",
   code_NO06:"Trøndelag",
   code_NO061:"Sør-Trøndelag",
   code_NO062:"Nord-Trøndelag",
   code_NO07:"Nord-Norge",
   code_NO071:"Nordland",
   code_NO072:"Troms",
   code_NO073:"Finnmark",
   code_PL:"POLSKA",
   code_PL2:"MAKROREGION POŁUDNIOWY",
   code_PL21:"Małopolskie",
   code_PL213:"Miasto Kraków",
   code_PL214:"Krakowski",
   code_PL217:"Tarnowski",
   code_PL218:"Nowosądecki",
   code_PL219:"Nowotarski",
   code_PL21A:"Oświęcimski",
   code_PL22:"Śląskie",
   code_PL224:"Częstochowski",
   code_PL225:"Bielski",
   code_PL227:"Rybnicki",
   code_PL228:"Bytomski",
   code_PL229:"Gliwicki",
   code_PL22A:"Katowicki",
   code_PL22B:"Sosnowiecki",
   code_PL22C:"Tyski",
   code_PL4:"MAKROREGION PÓŁNOCNO-ZACHODNI",
   code_PL41:"Wielkopolskie",
   code_PL411:"Pilski",
   code_PL414:"Koniński",
   code_PL415:"Miasto Poznań",
   code_PL416:"Kaliski",
   code_PL417:"Leszczyński",
   code_PL418:"Poznański",
   code_PL42:"Zachodniopomorskie",
   code_PL424:"Miasto Szczecin",
   code_PL426:"Koszaliński",
   code_PL427:"Szczecinecko-pyrzycki",
   code_PL428:"Szczeciński",
   code_PL43:"Lubuskie",
   code_PL431:"Gorzowski",
   code_PL432:"Zielonogórski",
   code_PL5:"MAKROREGION POŁUDNIOWO-ZACHODNI",
   code_PL51:"Dolnośląskie",
   code_PL514:"Miasto Wrocław",
   code_PL515:"Jeleniogórski",
   code_PL516:"Legnicko-głogowski",
   code_PL517:"Wałbrzyski",
   code_PL518:"Wrocławski",
   code_PL52:"Opolskie",
   code_PL523:"Nyski",
   code_PL524:"Opolski",
   code_PL6:"MAKROREGION PÓŁNOCNY",
   code_PL61:"Kujawsko-pomorskie",
   code_PL613:"Bydgosko-toruński",
   code_PL616:"Grudziądzki",
   code_PL617:"Inowrocławski",
   code_PL618:"Świecki",
   code_PL619:"Włocławski",
   code_PL62:"Warmińsko-mazurskie",
   code_PL621:"Elbląski",
   code_PL622:"Olsztyński",
   code_PL623:"Ełcki",
   code_PL63:"Pomorskie",
   code_PL633:"Trójmiejski",
   code_PL634:"Gdański",
   code_PL636:"Słupski",
   code_PL637:"Chojnicki",
   code_PL638:"Starogardzki",
   code_PL7:"MAKROREGION CENTRALNY",
   code_PL71:"Łódzkie",
   code_PL711:"Miasto Łódź",
   code_PL712:"Łódzki",
   code_PL713:"Piotrkowski",
   code_PL714:"Sieradzki",
   code_PL715:"Skierniewicki",
   code_PL72:"Świętokrzyskie",
   code_PL721:"Kielecki",
   code_PL722:"Sandomiersko-jędrzejowski",
   code_PL8:"MAKROREGION WSCHODNI",
   code_PL81:"Lubelskie",
   code_PL811:"Bialski",
   code_PL812:"Chełmsko-zamojski",
   code_PL814:"Lubelski",
   code_PL815:"Puławski",
   code_PL82:"Podkarpackie",
   code_PL821:"Krośnieński",
   code_PL822:"Przemyski",
   code_PL823:"Rzeszowski",
   code_PL824:"Tarnobrzeski",
   code_PL84:"Podlaskie",
   code_PL841:"Białostocki",
   code_PL842:"Łomżyński",
   code_PL843:"Suwalski",
   code_PL9:"MAKROREGION WOJEWÓDZTWO MAZOWIECKIE",
   code_PL91:"Warszawski stołeczny",
   code_PL911:"Miasto Warszawa",
   code_PL912:"Warszawski wschodni",
   code_PL913:"Warszawski zachodni",
   code_PL92:"Mazowiecki regionalny",
   code_PL921:"Radomski",
   code_PL922:"Ciechanowski",
   code_PL923:"Płocki",
   code_PL924:"Ostrołęcki",
   code_PL925:"Siedlecki",
   code_PL926:"Żyrardowski",
   code_PLZ:"EXTRA-REGIO NUTS 1",
   code_PLZZ:"Extra-Regio NUTS 2",
   code_PLZZZ:"Extra-Regio NUTS 3",
   code_PT:"PORTUGAL",
   code_PT1:"CONTINENTE",
   code_PT11:"Norte",
   code_PT111:"Alto Minho",
   code_PT112:"Cávado",
   code_PT119:"Ave",
   code_PT11A:"Área Metropolitana do Porto",
   code_PT11B:"Alto Tâmega",
   code_PT11C:"Tâmega e Sousa",
   code_PT11D:"Douro",
   code_PT11E:"Terras de Trás-os-Montes",
   code_PT15:"Algarve",
   code_PT150:"Algarve",
   code_PT16:"Centro (PT)",
   code_PT16B:"Oeste",
   code_PT16D:"Região de Aveiro",
   code_PT16E:"Região de Coimbra",
   code_PT16F:"Região de Leiria",
   code_PT16G:"Viseu Dão Lafões",
   code_PT16H:"Beira Baixa",
   code_PT16I:"Médio Tejo",
   code_PT16J:"Beiras e Serra da Estrela",
   code_PT17:"Área Metropolitana de Lisboa",
   code_PT170:"Área Metropolitana de Lisboa",
   code_PT18:"Alentejo",
   code_PT181:"Alentejo Litoral",
   code_PT184:"Baixo Alentejo",
   code_PT185:"Lezíria do Tejo",
   code_PT186:"Alto Alentejo",
   code_PT187:"Alentejo Central",
   code_PT2:"REGIÃO AUTÓNOMA DOS AÇORES",
   code_PT20:"Região Autónoma dos Açores",
   code_PT200:"Região Autónoma dos Açores",
   code_PT3:"REGIÃO AUTÓNOMA DA MADEIRA",
   code_PT30:"Região Autónoma da Madeira",
   code_PT300:"Região Autónoma da Madeira",
   code_PTZ:"EXTRA-REGIO NUTS 1",
   code_PTZZ:"Extra-Regio NUTS 2",
   code_PTZZZ:"Extra-Regio NUTS 3",
   code_RO:"ROMANIA",
   code_RO1:"MACROREGIUNEA UNU",
   code_RO11:"Nord-Vest",
   code_RO111:"Bihor",
   code_RO112:"Bistriţa-Năsăud",
   code_RO113:"Cluj",
   code_RO114:"Maramureş",
   code_RO115:"Satu Mare",
   code_RO116:"Sălaj",
   code_RO12:"Centru",
   code_RO121:"Alba",
   code_RO122:"Braşov",
   code_RO123:"Covasna",
   code_RO124:"Harghita",
   code_RO125:"Mureş",
   code_RO126:"Sibiu",
   code_RO2:"MACROREGIUNEA DOI",
   code_RO21:"Nord-Est",
   code_RO211:"Bacău",
   code_RO212:"Botoşani",
   code_RO213:"Iaşi",
   code_RO214:"Neamţ",
   code_RO215:"Suceava",
   code_RO216:"Vaslui",
   code_RO22:"Sud-Est",
   code_RO221:"Brăila",
   code_RO222:"Buzău",
   code_RO223:"Constanţa",
   code_RO224:"Galaţi",
   code_RO225:"Tulcea",
   code_RO226:"Vrancea",
   code_RO3:"MACROREGIUNEA TREI",
   code_RO31:"Sud-Muntenia",
   code_RO311:"Argeş",
   code_RO312:"Călăraşi",
   code_RO313:"Dâmboviţa",
   code_RO314:"Giurgiu",
   code_RO315:"Ialomiţa",
   code_RO316:"Prahova",
   code_RO317:"Teleorman",
   code_RO32:"Bucureşti-Ilfov",
   code_RO321:"Bucureşti",
   code_RO322:"Ilfov",
   code_RO4:"MACROREGIUNEA PATRU",
   code_RO41:"Sud-Vest Oltenia",
   code_RO411:"Dolj",
   code_RO412:"Gorj",
   code_RO413:"Mehedinţi",
   code_RO414:"Olt",
   code_RO415:"Vâlcea",
   code_RO42:"Vest",
   code_RO421:"Arad",
   code_RO422:"Caraş-Severin",
   code_RO423:"Hunedoara",
   code_RO424:"Timiş",
   code_ROZ:"EXTRA-REGIO NUTS 1",
   code_ROZZ:"Extra-Regio NUTS 2",
   code_ROZZZ:"Extra-Regio NUTS 3",
   code_RS:"Serbia",
   code_SE:"SVERIGE",
   code_SE1:"ÖSTRA SVERIGE",
   code_SE11:"Stockholm",
   code_SE110:"Stockholms län",
   code_SE12:"Östra Mellansverige",
   code_SE121:"Uppsala län",
   code_SE122:"Södermanlands län",
   code_SE123:"Östergötlands län",
   code_SE124:"Örebro län",
   code_SE125:"Västmanlands län",
   code_SE2:"SÖDRA SVERIGE",
   code_SE21:"Småland med öarna",
   code_SE211:"Jönköpings län",
   code_SE212:"Kronobergs län",
   code_SE213:"Kalmar län",
   code_SE214:"Gotlands län",
   code_SE22:"Sydsverige",
   code_SE221:"Blekinge län",
   code_SE224:"Skåne län",
   code_SE23:"Västsverige",
   code_SE231:"Hallands län",
   code_SE232:"Västra Götalands län",
   code_SE3:"NORRA SVERIGE",
   code_SE31:"Norra Mellansverige",
   code_SE311:"Värmlands län",
   code_SE312:"Dalarnas län",
   code_SE313:"Gävleborgs län",
   code_SE32:"Mellersta Norrland",
   code_SE321:"Västernorrlands län",
   code_SE322:"Jämtlands län",
   code_SE33:"Övre Norrland",
   code_SE331:"Västerbottens län",
   code_SE332:"Norrbottens län",
   code_SEZ:"EXTRA-REGIO NUTS 1",
   code_SEZZ:"Extra-Regio NUTS 2",
   code_SEZZZ:"Extra-Regio NUTS 3",
   code_SI:"Slovenia",
   code_SI0:"SLOVENIJA",
   code_SI03:"Vzhodna Slovenija",
   code_SI031:"Pomurska",
   code_SI032:"Podravska",
   code_SI033:"Koroška",
   code_SI034:"Savinjska",
   code_SI035:"Zasavska",
   code_SI036:"Posavska",
   code_SI037:"Jugovzhodna Slovenija",
   code_SI038:"Primorsko-notranjska",
   code_SI04:"Zahodna Slovenija",
   code_SI041:"Osrednjeslovenska",
   code_SI042:"Gorenjska",
   code_SI043:"Goriška",
   code_SI044:"Obalno-kraška",
   code_SIZ:"EXTRA-REGIO NUTS 1",
   code_SIZZ:"Extra-Regio NUTS 2",
   code_SIZZZ:"Extra-Regio NUTS 3",
   code_SK:"SLOVENSKO",
   code_SK0:"SLOVENSKO",
   code_SK01:"Bratislavský kraj",
   code_SK010:"Bratislavský kraj",
   code_SK02:"Západné Slovensko",
   code_SK021:"Trnavský kraj",
   code_SK022:"Trenčiansky kraj",
   code_SK023:"Nitriansky kraj",
   code_SK03:"Stredné Slovensko",
   code_SK031:"Žilinský kraj",
   code_SK032:"Banskobystrický kraj",
   code_SK04:"Východné Slovensko",
   code_SK041:"Prešovský kraj",
   code_SK042:"Košický kraj",
   code_SKZ:"EXTRA-REGIO NUTS 1",
   code_SKZZ:"Extra-Regio NUTS 2",
   code_SKZZZ:"Extra-Regio NUTS 3",
   code_TR:"Turkey",
   code_TR1:"İstanbul",
   code_TR10:"İstanbul",
   code_TR100:"İstanbul",
   code_TR2:"Batı Marmara",
   code_TR21:"Tekirdağ",
   code_TR211:"Tekirdağ",
   code_TR212:"Edirne",
   code_TR213:"Kırklareli",
   code_TR22:"Balıkesir",
   code_TR221:"Balıkesir",
   code_TR222:"Çanakkale",
   code_TR3:"Ege",
   code_TR31:"İzmir",
   code_TR310:"İzmir",
   code_TR32:"Aydın",
   code_TR321:"Aydın",
   code_TR322:"Denizli",
   code_TR323:"Muğla",
   code_TR33:"Manisa",
   code_TR331:"Manisa",
   code_TR332:"Afyonkarahisar",
   code_TR333:"Kütahya",
   code_TR334:"Uşak",
   code_TR4:"Doğu Marmara",
   code_TR41:"Bursa",
   code_TR411:"Bursa",
   code_TR412:"Eskişehir",
   code_TR413:"Bilecik",
   code_TR42:"Kocaeli",
   code_TR421:"Kocaeli",
   code_TR422:"Sakarya",
   code_TR423:"Düzce",
   code_TR424:"Bolu",
   code_TR425:"Yalova",
   code_TR5:"Batı Anadolu",
   code_TR51:"Ankara",
   code_TR510:"Ankara",
   code_TR52:"Konya",
   code_TR521:"Konya",
   code_TR522:"Karaman",
   code_TR6:"Akdeniz",
   code_TR61:"Antalya",
   code_TR611:"Antalya",
   code_TR612:"Isparta",
   code_TR613:"Burdur",
   code_TR62:"Adana",
   code_TR621:"Adana",
   code_TR622:"Mersin",
   code_TR63:"Hatay",
   code_TR631:"Hatay",
   code_TR632:"Kahramanmaraş",
   code_TR633:"Osmaniye",
   code_TR7:"Orta Anadolu",
   code_TR71:"Kırıkkale",
   code_TR711:"Kırıkkale",
   code_TR712:"Aksaray",
   code_TR713:"Niğde",
   code_TR714:"Nevşehir",
   code_TR715:"Kırşehir",
   code_TR72:"Kayseri",
   code_TR721:"Kayseri",
   code_TR722:"Sivas",
   code_TR723:"Yozgat",
   code_TR8:"Batı Karadeniz",
   code_TR81:"Zonguldak",
   code_TR811:"Zonguldak",
   code_TR812:"Karabük",
   code_TR813:"Bartın",
   code_TR82:"Kastamonu",
   code_TR821:"Kastamonu",
   code_TR822:"Çankırı",
   code_TR823:"Sinop",
   code_TR83:"Samsun",
   code_TR831:"Samsun",
   code_TR832:"Tokat",
   code_TR833:"Çorum",
   code_TR834:"Amasya",
   code_TR9:"Doğu Karadeniz",
   code_TR90:"Trabzon",
   code_TR901:"Trabzon",
   code_TR902:"Ordu",
   code_TR903:"Giresun",
   code_TR904:"Rize",
   code_TR905:"Artvin",
   code_TR906:"Gümüşhane",
   code_TRA:"Kuzeydoğu Anadolu",
   code_TRA1:"Erzurum",
   code_TRA11:"Erzurum",
   code_TRA12:"Erzincan",
   code_TRA13:"Bayburt",
   code_TRA2:"Ağrı",
   code_TRA21:"Ağrı",
   code_TRA22:"Kars",
   code_TRA23:"Iğdır",
   code_TRA24:"Ardahan",
   code_TRB:"Ortadoğu Anadolu",
   code_TRB1:"Malatya",
   code_TRB11:"Malatya",
   code_TRB12:"Elazığ",
   code_TRB13:"Bingöl",
   code_TRB14:"Tunceli",
   code_TRB2:"Van",
   code_TRB21:"Van",
   code_TRB22:"Muş",
   code_TRB23:"Bitlis",
   code_TRB24:"Hakkari",
   code_TRC:"Güneydoğu Anadolu",
   code_TRC1:"Gaziantep",
   code_TRC11:"Gaziantep",
   code_TRC12:"Adıyaman",
   code_TRC13:"Kilis",
   code_TRC2:"Şanlıurfa",
   code_TRC21:"Şanlıurfa",
   code_TRC22:"Diyarbakır",
   code_TRC3:"Mardin",
   code_TRC31:"Mardin",
   code_TRC32:"Batman",
   code_TRC33:"Şırnak",
   code_TRC34:"Siirt",
   code_UK:"United Kingdom",
   code_UKC:"NORTH EAST (ENGLAND)",
   code_UKC1:"Tees Valley and Durham",
   code_UKC11:"Hartlepool and Stockton-on-Tees",
   code_UKC12:"South Teesside",
   code_UKC13:"Darlington",
   code_UKC14:"Durham CC",
   code_UKC2:"Northumberland and Tyne and Wear",
   code_UKC21:"Northumberland",
   code_UKC22:"Tyneside",
   code_UKC23:"Sunderland",
   code_UKD:"NORTH WEST (ENGLAND)",
   code_UKD1:"Cumbria",
   code_UKD11:"West Cumbria",
   code_UKD12:"East Cumbria",
   code_UKD3:"Greater Manchester",
   code_UKD33:"Manchester",
   code_UKD34:"Greater Manchester South West",
   code_UKD35:"Greater Manchester South East",
   code_UKD36:"Greater Manchester North West",
   code_UKD37:"Greater Manchester North East",
   code_UKD4:"Lancashire",
   code_UKD41:"Blackburn with Darwen",
   code_UKD42:"Blackpool",
   code_UKD44:"Lancaster and Wyre",
   code_UKD45:"Mid Lancashire",
   code_UKD46:"East Lancashire",
   code_UKD47:"Chorley and West Lancashire",
   code_UKD6:"Cheshire",
   code_UKD61:"Warrington",
   code_UKD62:"Cheshire East",
   code_UKD63:"Cheshire West and Chester",
   code_UKD7:"Merseyside",
   code_UKD71:"East Merseyside",
   code_UKD72:"Liverpool",
   code_UKD73:"Sefton",
   code_UKD74:"Wirral",
   code_UKE:"YORKSHIRE AND THE HUMBER",
   code_UKE1:"East Yorkshire and Northern Lincolnshire",
   code_UKE11:"Kingston upon Hull, City of",
   code_UKE12:"East Riding of Yorkshire",
   code_UKE13:"North and North East Lincolnshire",
   code_UKE2:"North Yorkshire",
   code_UKE21:"York",
   code_UKE22:"North Yorkshire CC",
   code_UKE3:"South Yorkshire",
   code_UKE31:"Barnsley, Doncaster and Rotherham",
   code_UKE32:"Sheffield",
   code_UKE4:"West Yorkshire",
   code_UKE41:"Bradford",
   code_UKE42:"Leeds",
   code_UKE44:"Calderdale and Kirklees",
   code_UKE45:"Wakefield",
   code_UKF:"EAST MIDLANDS (ENGLAND)",
   code_UKF1:"Derbyshire and Nottinghamshire",
   code_UKF11:"Derby",
   code_UKF12:"East Derbyshire",
   code_UKF13:"South and West Derbyshire",
   code_UKF14:"Nottingham",
   code_UKF15:"North Nottinghamshire",
   code_UKF16:"South Nottinghamshire",
   code_UKF2:"Leicestershire, Rutland and Northamptonshire",
   code_UKF21:"Leicester",
   code_UKF22:"Leicestershire CC and Rutland",
   code_UKF24:"West Northamptonshire",
   code_UKF25:"North Northamptonshire",
   code_UKF3:"Lincolnshire",
   code_UKF30:"Lincolnshire",
   code_UKG:"WEST MIDLANDS (ENGLAND)",
   code_UKG1:"Herefordshire, Worcestershire and Warwickshire",
   code_UKG11:"Herefordshire, County of",
   code_UKG12:"Worcestershire",
   code_UKG13:"Warwickshire",
   code_UKG2:"Shropshire and Staffordshire",
   code_UKG21:"Telford and Wrekin",
   code_UKG22:"Shropshire CC",
   code_UKG23:"Stoke-on-Trent",
   code_UKG24:"Staffordshire CC",
   code_UKG3:"West Midlands",
   code_UKG31:"Birmingham",
   code_UKG32:"Solihull",
   code_UKG33:"Coventry",
   code_UKG36:"Dudley",
   code_UKG37:"Sandwell",
   code_UKG38:"Walsall",
   code_UKG39:"Wolverhampton",
   code_UKH:"EAST OF ENGLAND",
   code_UKH1:"East Anglia",
   code_UKH11:"Peterborough",
   code_UKH12:"Cambridgeshire CC",
   code_UKH14:"Suffolk",
   code_UKH15:"Norwich and East Norfolk",
   code_UKH16:"North and West Norfolk",
   code_UKH17:"Breckland and South Norfolk",
   code_UKH2:"Bedfordshire and Hertfordshire",
   code_UKH21:"Luton",
   code_UKH23:"Hertfordshire",
   code_UKH24:"Bedford",
   code_UKH25:"Central Bedfordshire",
   code_UKH3:"Essex",
   code_UKH31:"Southend-on-Sea",
   code_UKH32:"Thurrock",
   code_UKH34:"Essex Haven Gateway",
   code_UKH35:"West Essex",
   code_UKH36:"Heart of Essex",
   code_UKH37:"Essex Thames Gateway",
   code_UKI:"LONDON",
   code_UKI3:"Inner London – West",
   code_UKI31:"Camden and City of London",
   code_UKI32:"Westminster",
   code_UKI33:"Kensington & Chelsea and Hammersmith & Fulham",
   code_UKI34:"Wandsworth",
   code_UKI4:"Inner London – East",
   code_UKI41:"Hackney and Newham",
   code_UKI42:"Tower Hamlets",
   code_UKI43:"Haringey and Islington",
   code_UKI44:"Lewisham and Southwark",
   code_UKI45:"Lambeth",
   code_UKI5:"Outer London – East and North East",
   code_UKI51:"Bexley and Greenwich",
   code_UKI52:"Barking & Dagenham and Havering",
   code_UKI53:"Redbridge and Waltham Forest",
   code_UKI54:"Enfield",
   code_UKI6:"Outer London – South",
   code_UKI61:"Bromley",
   code_UKI62:"Croydon",
   code_UKI63:"Merton, Kingston upon Thames and Sutton",
   code_UKI7:"Outer London – West and North West",
   code_UKI71:"Barnet",
   code_UKI72:"Brent",
   code_UKI73:"Ealing",
   code_UKI74:"Harrow and Hillingdon",
   code_UKI75:"Hounslow and Richmond upon Thames",
   code_UKJ:"SOUTH EAST (ENGLAND)",
   code_UKJ1:"Berkshire, Buckinghamshire and Oxfordshire",
   code_UKJ11:"Berkshire",
   code_UKJ12:"Milton Keynes",
   code_UKJ13:"Buckinghamshire CC",
   code_UKJ14:"Oxfordshire",
   code_UKJ2:"Surrey, East and West Sussex",
   code_UKJ21:"Brighton and Hove",
   code_UKJ22:"East Sussex CC",
   code_UKJ25:"West Surrey",
   code_UKJ26:"East Surrey",
   code_UKJ27:"West Sussex (South West)",
   code_UKJ28:"West Sussex (North East)",
   code_UKJ3:"Hampshire and Isle of Wight",
   code_UKJ31:"Portsmouth",
   code_UKJ32:"Southampton",
   code_UKJ34:"Isle of Wight",
   code_UKJ35:"South Hampshire",
   code_UKJ36:"Central Hampshire",
   code_UKJ37:"North Hampshire",
   code_UKJ4:"Kent",
   code_UKJ41:"Medway",
   code_UKJ43:"Kent Thames Gateway",
   code_UKJ44:"East Kent",
   code_UKJ45:"Mid Kent",
   code_UKJ46:"West Kent",
   code_UKK:"SOUTH WEST (ENGLAND)",
   code_UKK1:"Gloucestershire, Wiltshire and Bristol/Bath area",
   code_UKK11:"Bristol, City of",
   code_UKK12:"Bath and North East Somerset, North Somerset and South Gloucestershire",
   code_UKK13:"Gloucestershire",
   code_UKK14:"Swindon",
   code_UKK15:"Wiltshire CC",
   code_UKK2:"Dorset and Somerset",
   code_UKK21:"Bournemouth and Poole",
   code_UKK22:"Dorset CC",
   code_UKK23:"Somerset",
   code_UKK3:"Cornwall and Isles of Scilly",
   code_UKK30:"Cornwall and Isles of Scilly",
   code_UKK4:"Devon",
   code_UKK41:"Plymouth",
   code_UKK42:"Torbay",
   code_UKK43:"Devon CC",
   code_UKL:"WALES",
   code_UKL1:"West Wales and The Valleys",
   code_UKL11:"Isle of Anglesey",
   code_UKL12:"Gwynedd",
   code_UKL13:"Conwy and Denbighshire",
   code_UKL14:"South West Wales",
   code_UKL15:"Central Valleys",
   code_UKL16:"Gwent Valleys",
   code_UKL17:"Bridgend and Neath Port Talbot",
   code_UKL18:"Swansea",
   code_UKL2:"East Wales",
   code_UKL21:"Monmouthshire and Newport",
   code_UKL22:"Cardiff and Vale of Glamorgan",
   code_UKL23:"Flintshire and Wrexham",
   code_UKL24:"Powys",
   code_UKM:"SCOTLAND",
   code_UKM5:"North Eastern Scotland",
   code_UKM50:"Aberdeen City and Aberdeenshire",
   code_UKM6:"Highlands and Islands",
   code_UKM61:"Caithness & Sutherland and Ross & Cromarty",
   code_UKM62:"Inverness & Nairn and Moray, Badenoch & Strathspey",
   code_UKM63:"Lochaber, Skye & Lochalsh, Arran & Cumbrae and Argyll & Bute",
   code_UKM64:"Na h-Eileanan Siar (Western Isles)",
   code_UKM65:"Orkney Islands",
   code_UKM66:"Shetland Islands",
   code_UKM7:"Eastern Scotland",
   code_UKM71:"Angus and Dundee City",
   code_UKM72:"Clackmannanshire and Fife",
   code_UKM73:"East Lothian and Midlothian",
   code_UKM75:"Edinburgh, City of",
   code_UKM76:"Falkirk",
   code_UKM77:"Perth & Kinross and Stirling",
   code_UKM78:"West Lothian",
   code_UKM8:"West Central Scotland",
   code_UKM81:"East Dunbartonshire, West Dunbartonshire and Helensburgh & Lomond",
   code_UKM82:"Glasgow City",
   code_UKM83:"Inverclyde, East Renfrewshire and Renfrewshire",
   code_UKM84:"North Lanarkshire",
   code_UKM9:"Southern Scotland",
   code_UKM91:"Scottish Borders",
   code_UKM92:"Dumfries & Galloway",
   code_UKM93:"East Ayrshire and North Ayrshire mainland",
   code_UKM94:"South Ayrshire",
   code_UKM95:"South Lanarkshire",
   code_UKN:"NORTHERN IRELAND",
   code_UKN0:"Northern Ireland",
   code_UKN06:"Belfast",
   code_UKN07:"Armagh City, Banbridge and Craigavon",
   code_UKN08:"Newry, Mourne and Down",
   code_UKN09:"Ards and North Down",
   code_UKN10:"Derry City and Strabane",
   code_UKN11:"Mid Ulster",
   code_UKN12:"Causeway Coast and Glens",
   code_UKN13:"Antrim and Newtownabbey",
   code_UKN14:"Lisburn and Castlereagh",
   code_UKN15:"Mid and East Antrim",
   code_UKN16:"Fermanagh and Omagh",
   code_UKZ:"EXTRA-REGIO NUTS 1",
   code_UKZZ:"Extra-Regio NUTS 2",
   code_UKZZZ:"Extra-Regio NUTS 3",
   code_GR:"ELLADA",
   code_AF:"Afghanistan",
   code_DZ:"Algeria",
   code_AS:"American Samoa",
   code_AD:"Andorra",
   code_AO:"Angola",
   code_AI:"Anguilla",
   code_AQ:"Antarctica",
   code_AG:"Antigua and Barbuda",
   code_AR:"Argentina",
   code_AM:"Armenia",
   code_AW:"Aruba",
   code_AU:"Australia",
   code_AZ:"Azerbaijan",
   code_BS:"Bahamas",
   code_BH:"Bahrain",
   code_BD:"Bangladesh",
   code_BB:"Barbados",
   code_BY:"Belarus",
   code_BZ:"Belize",
   code_BJ:"Benin",
   code_BM:"Bermuda",
   code_BT:"Bhutan",
   code_BO:"Bolivia",
   code_BA:"Bosnia and Herzegovina",
   code_BW:"Botswana",
   code_BV:"Bouvet Island",
   code_BR:"Brazil",
   code_IO:"British Indian Ocean Territory",
   code_BN:"Brunei Darussalam",
   code_BF:"Burkina Faso",
   code_BI:"Burundi",
   code_KH:"Cambodia",
   code_CM:"Cameroon",
   code_CA:"Canada",
   code_CV:"Cabo Verde",
   code_KY:"Cayman Islands",
   code_CF:"Central African Republic",
   code_TD:"Chad",
   code_CL:"Chile",
   code_CN:"China",
   code_CX:"Christmas Island",
   code_CO:"Colombia",
   code_KM:"Comoros",
   code_CG:"Congo",
   code_CD:"Congo",
   code_CK:"Cook Islands",
   code_CR:"Costa Rica",
   code_CI:"Côte d'Ivoire",
   code_CU:"Cuba",
   code_DJ:"Djibouti",
   code_DM:"Dominica",
   code_DO:"Dominican Republic",
   code_EC:"Ecuador",
   code_EG:"Egypt",
   code_EH:"Western Sahara",
   code_SV:"El Salvador",
   code_GQ:"Equatorial Guinea",
   code_ER:"Eritrea",
   code_ET:"Ethiopia",
   code_FK:"Falkland Islands (Malvinas)",
   code_FO:"Faroe Islands",
   code_FJ:"Fiji",
   code_GF:"French Guiana",
   code_PF:"French Polynesia",
   code_TF:"French Southern Territories",
   code_GA:"Gabon",
   code_GM:"Gambia",
   code_GE:"Georgia",
   code_GH:"Ghana",
   code_GI:"Gibraltar",
   code_GL:"Greenland",
   code_GD:"Grenada",
   code_GP:"Guadeloupe",
   code_GU:"Guam",
   code_GT:"Guatemala",
   code_GN:"Guinea",
   code_GW:"Guinea-Bissau",
   code_GY:"Guyana",
   code_HT:"Haiti",
   code_HN:"Honduras",
   code_HK:"Hong Kong",
   code_IN:"India",
   code_ID:"Indonesia",
   code_IR:"Iran",
   code_IQ:"Iraq",
   code_IL:"Israel",
   code_JM:"Jamaica",
   code_JP:"Japan",
   code_JO:"Jordan",
   code_KZ:"Kazakhstan",
   code_KE:"Kenya",
   code_KI:"Kiribati",
   code_KP:"Korea",
   code_KR:"Korea",
   code_KW:"Kuwait",
   code_KG:"Kyrgyzstan",
   code_LA:"Lao People's Democratic Republic",
   code_LB:"Lebanon",
   code_LS:"Lesotho",
   code_LR:"Liberia",
   code_LY:"Libya",
   code_MO:"Macao",
   code_MG:"Madagascar",
   code_MW:"Malawi",
   code_MY:"Malaysia",
   code_MV:"Maldives",
   code_ML:"Mali",
   code_MH:"Marshall Islands",
   code_MQ:"Martinique",
   code_MR:"Mauritania",
   code_MU:"Mauritius",
   code_YT:"Mayotte",
   code_MX:"Mexico",
   code_FM:"Micronesia",
   code_MD:"Moldova",
   code_MC:"Monaco",
   code_MN:"Mongolia",
   code_MS:"Montserrat",
   code_MA:"Morocco",
   code_MZ:"Mozambique",
   code_MM:"Myanmar",
   code_NA:"Namibia",
   code_NR:"Nauru",
   code_NP:"Nepal",
   code_AN:"Netherlands Antilles",
   code_NZ:"New Zealand",
   code_NI:"Nicaragua",
   code_NE:"Niger",
   code_NG:"Nigeria",
   code_NU:"Niue",
   code_NF:"Norfolk Island",
   code_MP:"Northern Mariana Islands",
   code_OM:"Oman",
   code_PK:"Pakistan",
   code_PW:"Palau",
   code_PS:"Palestine",
   code_PA:"Panama",
   code_PG:"Papua New Guinea",
   code_PY:"Paraguay",
   code_PE:"Peru",
   code_PH:"Philippines",
   code_PN:"Pitcairn",
   code_PR:"Puerto Rico",
   code_QA:"Qatar",
   code_RE:"Réunion",
   code_RU:"Russian Federation",
   code_RW:"Rwanda",
   code_SH:"Saint Helena",
   code_KN:"Saint Kitts and Nevis",
   code_LC:"Saint Lucia",
   code_PM:"Saint Pierre and Miquelon",
   code_VC:"Saint Vincent and the Grenadines",
   code_WS:"Samoa",
   code_SM:"San Marino",
   code_ST:"Sao Tome and Principe",
   code_SA:"Saudi Arabia",
   code_SN:"Senegal",
   code_CS:"Serbia and Montenegro",
   code_SC:"Seychelles",
   code_SL:"Sierra Leone",
   code_SG:"Singapore",
   code_SB:"Solomon Islands",
   code_SO:"Somalia",
   code_ZA:"South Africa",
   code_GS:"South Georgia and the South Sandwich Islands",
   code_LK:"Sri Lanka",
   code_SD:"Sudan",
   code_SR:"Suriname",
   code_SJ:"Svalbard and Jan Mayen",
   code_SZ:"Swaziland",
   code_SY:"Syrian Arab Republic",
   code_TW:"Taiwan",
   code_TJ:"Tajikistan",
   code_TZ:"Tanzania",
   code_TH:"Thailand",
   code_TL:"Timor-Leste",
   code_TG:"Togo",
   code_TK:"Tokelau",
   code_TO:"Tonga",
   code_TT:"Trinidad and Tobago",
   code_TN:"Tunisia",
   code_TM:"Turkmenistan",
   code_TC:"Turks and Caicos Islands",
   code_TV:"Tuvalu",
   code_UG:"Uganda",
   code_UA:"Ukraine",
   code_AE:"United Arab Emirates",
   code_GB:"United Kingdom of Great Britain and Northern Ireland",
   code_US:"United States of America",
   code_UM:"United States Minor Outlying Islands",
   code_UY:"Uruguay",
   code_UZ:"Uzbekistan",
   code_VE:"Venezuela",
   code_VU:"Vanuatu",
   code_VN:"Viet Nam",
   code_VG:"Virgin Islands",
   code_VI:"Virgin Islands",
   code_WF:"Wallis and Futuna",
   code_YE:"Yemen",
   code_ZW:"Zimbabwe",

   code_03000000:"Produits agricoles, de l'élevage, de la pêche, de la sylviculture et produits connexes",
   code_FR212:"Aube",
   code_DE421:"Brandenburg an der Havel, Kreisfreie Stadt",
   code_DE422:"Cottbus, Kreisfreie Stadt",
   code_DE411:"Frankfurt (Oder), Kreisfreie Stadt",
   code_DE423:"Potsdam, Kreisfreie Stadt",
   code_DE412:"Barnim",
   code_DE424:"Dahme-Spreewald",
   code_DE425:"Elbe-Elster",
   code_DE426:"Havelland",
   code_DE413:"Märkisch-Oderland",
   code_DE414:"Oberhavel",
   code_DE427:"Oberspreewald-Lausitz",
   code_DE415:"Oder-Spree",
   code_DE416:"Ostprignitz-Ruppin",
   code_DE428:"Potsdam-Mittelmark",
   code_DE417:"Prignitz",
   code_DE429:"Spree-Neiße",
   code_DE42A:"Teltow-Fläming",
   code_DE418:"Uckermark",
   code_DEB16:"Cochem-Zell",
   code_DEB19:"Rhein-Hunsrück-Kreis",
   code_DED1:"Chemnitz",
   code_DED11:"Chemnitz, Kreisfreie Stadt",
   code_DED3:"Leipzig",
   code_DED31:"Leipzig, Kreisfreie Stadt",
   code_GR3:"ATTIKI",
   code_GR30:"Attiki",
   code_GR4:"NISIA AIGAIOU, KRITI",
   code_GR41:"Voreio Aigaio",
   code_GR411:"Lesvos",
   code_GR412:"Samos",
   code_GR413:"Chios",
   code_GR42:"Notio Aigaio",
   code_GR421:"Dodekanisos",
   code_GR422:"Kyklades",
   code_GR43:"Kriti",
   code_GR431:"Irakleio",
   code_GR432:"Lasithi",
   code_GR433:"Rethymni",
   code_GR434:"Chania",
   code_GR1:"VOREIA ELLADA",
   code_GR11:"Anatoliki Makedonia, Thraki",
   code_GR111:"Evros",
   code_GR112:"Xanthi",
   code_GR113:"Rodopi",
   code_GR114:"Drama",
   code_GR115:"Kavala",
   code_GR12:"Kentriki Makedonia",
   code_GR121:"Imathia",
   code_GR122:"Thessaloniki",
   code_GR123:"Kilkis",
   code_GR124:"Pella",
   code_GR125:"Pieria",
   code_GR126:"Serres",
   code_GR127:"Chalkidiki",
   code_GR13:"Dytiki Makedonia",
   code_GR132:"Kastoria",
   code_GR134:"Florina",
   code_GR21:"Ipeiros",
   code_GR212:"Thesprotia",
   code_GR213:"Ioannina",
   code_GR2:"KENTRIKI ELLADA",
   code_GR14:"Thessalia",
   code_GR142:"Larisa",
   code_GR143:"Magnisia",
   code_GR22:"Ionia Nisia",
   code_GR221:"Zakynthos",
   code_GR222:"Kerkyra",
   code_GR223:"Kefallinia",
   code_GR224:"Lefkada",
   code_GR23:"Dytiki Ellada",
   code_GR231:"Aitoloakarnania",
   code_GR232:"Achaia",
   code_GR233:"Ileia",
   code_GR24:"Sterea Ellada",
   code_GR241:"Voiotia",
   code_GR242:"Evvoia",
   code_GR243:"Evrytania",
   code_GR244:"Fthiotida",
   code_GR245:"Fokida",
   code_GR25:"Peloponnisos",
   code_GR253:"Korinthia",
   code_GRZ:"EXTRA-REGIO",
   code_GRZZ:"Extra-Regio",
   code_GRZZZ:"Extra-Regio",
   code_FI183:"Varsinais-Suomi",
   code_FI184:"Kanta-Häme",
   code_FI185:"Päijät-Häme",
   code_FI186:"Kymenlaakso",
   code_FI187:"Etelä-Karjala",
   code_FI131:"Etelä-Savo",
   code_FI132:"Pohjois-Savo",
   code_FI133:"Pohjois-Karjala",
   code_FI1A1:"Keski-Pohjanmaa",
   code_FI1A3:"Lappi",
   code_FI134:"Kainuu",
   code_FI1A2:"Pohjois-Pohjanmaa",
   code_FR24:"Centre",
   code_FR241:"Cher",
   code_FR242:"Eure-et-Loir",
   code_FR243:"Indre",
   code_FR244:"Indre-et-Loire",
   code_FR245:"Loir-et-Cher",
   code_FR246:"Loiret",
   code_FR26:"Bourgogne",
   code_FR261:"Côte-d'Or",
   code_FR262:"Nièvre",
   code_FR263:"Saône-et-Loire",
   code_FR264:"Yonne",
   code_FR43:"Franche-Comté",
   code_FR431:"Doubs",
   code_FR432:"Jura",
   code_FR433:"Haute-Saône",
   code_FR434:"Territoire de Belfort",
   code_FR25:"Basse-Normandie",
   code_FR251:"Calvados",
   code_FR252:"Manche",
   code_FR253:"Orne",
   code_FR23:"Haute-Normandie",
   code_FR231:"Eure",
   code_FR232:"Seine-Maritime",
   code_FR3:"NORD - PAS-DE-CALAIS",
   code_FR30:"Nord - Pas-de-Calais",
   code_FR301:"Nord",
   code_FR302:"Pas-de-Calais",
   code_FR22:"Picardie",
   code_FR221:"Aisne",
   code_FR222:"Oise",
   code_FR223:"Somme",
   code_FR42:"Alsace",
   code_FR421:"Bas-Rhin",
   code_FR422:"Haut-Rhin",
   code_FR21:"Champagne-Ardenne",
   code_FR211:"Ardennes",
   code_FR213:"Marne",
   code_FR214:"Haute-Marne",
   code_FR41:"Lorraine",
   code_FR411:"Meurthe-et-Moselle",
   code_FR412:"Meuse",
   code_FR413:"Moselle",
   code_FR414:"Vosges",
   code_FR51:"Pays de la Loire",
   code_FR511:"Loire-Atlantique",
   code_FR512:"Maine-et-Loire",
   code_FR513:"Mayenne",
   code_FR514:"Sarthe",
   code_FR515:"Vendée",
   code_FR52:"Bretagne",
   code_FR521:"Côtes-d'Armor",
   code_FR522:"Finistère",
   code_FR523:"Ille-et-Vilaine",
   code_FR524:"Morbihan",
   code_FR61:"Aquitaine",
   code_FR611:"Dordogne",
   code_FR612:"Gironde",
   code_FR613:"Landes",
   code_FR614:"Lot-et-Garonne",
   code_FR615:"Pyrénées-Atlantiques",
   code_FR63:"Limousin",
   code_FR631:"Corrèze",
   code_FR632:"Creuse",
   code_FR633:"Haute-Vienne",
   code_FR53:"Poitou-Charentes",
   code_FR531:"Charente",
   code_FR532:"Charente-Maritime",
   code_FR533:"Deux-Sèvres",
   code_FR534:"Vienne",
   code_FR81:"Languedoc-Roussillon",
   code_FR811:"Aude",
   code_FR812:"Gard",
   code_FR813:"Hérault",
   code_FR814:"Lozère",
   code_FR815:"Pyrénées-Orientales",
   code_FR62:"Midi-Pyrénées",
   code_FR621:"Ariège",
   code_FR622:"Aveyron",
   code_FR623:"Haute-Garonne",
   code_FR624:"Gers",
   code_FR625:"Lot",
   code_FR626:"Hautes-Pyrénées",
   code_FR627:"Tarn",
   code_FR628:"Tarn-et-Garonne",
   code_FR72:"Auvergne",
   code_FR721:"Allier",
   code_FR722:"Cantal",
   code_FR723:"Haute-Loire",
   code_FR724:"Puy-de-Dôme",
   code_FR71:"Rhône-Alpes",
   code_FR711:"Ain",
   code_FR712:"Ardèche",
   code_FR713:"Drôme",
   code_FR714:"Isère",
   code_FR715:"Loire",
   code_FR716:"Rhône",
   code_FR717:"Savoie",
   code_FR718:"Haute-Savoie",
   code_FR82:"Provence-Alpes-Côte d'Azur",
   code_FR821:"Alpes-de-Haute-Provence",
   code_FR822:"Hautes-Alpes",
   code_FR823:"Alpes-Maritimes",
   code_FR824:"Bouches-du-Rhône",
   code_FR825:"Var",
   code_FR826:"Vaucluse",
   code_FR83:"Corse",
   code_FR831:"Corse-du-Sud",
   code_FR832:"Haute-Corse",
   code_FR91:"Guadeloupe",
   code_FR910:"Guadeloupe",
   code_FR92:"Martinique",
   code_FR920:"Martinique",
   code_FR93:"Guyane",
   code_FR930:"Guyane",
   code_FR94:"Réunion",
   code_FR940:"Réunion",
   code_HR011:"Grad Zagreb",
   code_HR012:"Zagrebačka županija",
   code_HR013:"Krapinsko-zagorska županija",
   code_HR014:"Varaždinska županija",
   code_HR015:"Koprivničko-križevačka županija",
   code_HR016:"Međimurska županija",
   code_HR021:"Bjelovarsko-bilogorska županija",
   code_HR022:"Virovitičko-podravska županija",
   code_HR023:"Požeško-slavonska županija",
   code_HR024:"Brodsko-posavska županija",
   code_HR025:"Osječko-baranjska županija",
   code_HR026:"Vukovarsko-srijemska županija",
   code_HR027:"Karlovačka županija",
   code_HR028:"Sisačko-moslavačka županija",
   code_HU101:"Budapest",
   code_HU102:"Pest",
   code_IE01:"Border, Midland and Western",
   code_IE011:"Border",
   code_IE013:"West",
   code_IE023:"Mid-West",
   code_IE024:"South-East (IRL)",
   code_IE025:"South-West (IRL)",
   code_IE021:"Dublin",
   code_IE022:"Mid-East",
   code_IE012:"Midland",
   code_ITC45:"Milano",
   code_ITF41:"Foggia",
   code_ITF42:"Bari",
   code_ITD:"NORD-EST",
   code_ITD1:"Provincia Autonoma Bolzano/Bozen",
   code_ITD10:"Bolzano-Bozen",
   code_ITD2:"Provincia Autonoma Trento",
   code_ITD20:"Trento",
   code_ITD3:"Veneto",
   code_ITD31:"Verona",
   code_ITD32:"Vicenza",
   code_ITD33:"Belluno",
   code_ITD34:"Treviso",
   code_ITD35:"Venezia",
   code_ITD36:"Padova",
   code_ITD37:"Rovigo",
   code_ITD4:"Friuli-Venezia Giulia",
   code_ITD41:"Pordenone",
   code_ITD42:"Udine",
   code_ITD43:"Gorizia",
   code_ITD44:"Trieste",
   code_ITD5:"Emilia-Romagna",
   code_ITD51:"Piacenza",
   code_ITD52:"Parma",
   code_ITD53:"Reggio nell'Emilia",
   code_ITD54:"Modena",
   code_ITD55:"Bologna",
   code_ITD56:"Ferrara",
   code_ITD57:"Ravenna",
   code_ITD58:"Forlì-Cesena",
   code_ITD59:"Rimini",
   code_ITE:"CENTRO (I)",
   code_ITE1:"Toscana",
   code_ITE11:"Massa-Carrara",
   code_ITE12:"Lucca",
   code_ITE13:"Pistoia",
   code_ITE14:"Firenze",
   code_ITE15:"Prato",
   code_ITE16:"Livorno",
   code_ITE17:"Pisa",
   code_ITE18:"Arezzo",
   code_ITE19:"Siena",
   code_ITE1A:"Grosseto",
   code_ITE2:"Umbria",
   code_ITE21:"Perugia",
   code_ITE22:"Terni",
   code_ITE3:"Marche",
   code_ITE31:"Pesaro e Urbino",
   code_ITE32:"Ancona",
   code_ITE33:"Macerata",
   code_ITE34:"Ascoli Piceno",
   code_ITE4:"Lazio",
   code_ITE41:"Viterbo",
   code_ITE42:"Rieti",
   code_ITE43:"Roma",
   code_ITE44:"Latina",
   code_ITE45:"Frosinone",
   code_LT00A:"Vilniaus apskritis",
   code_LT001:"Alytaus apskritis",
   code_LT002:"Kauno apskritis",
   code_LT003:"Klaipėdos apskritis",
   code_LT004:"Marijampolės apskritis",
   code_LT005:"Panevėžio apskritis",
   code_LT006:"Šiaulių apskritis",
   code_LT007:"Tauragės apskritis",
   code_LT008:"Telšių apskritis",
   code_LT009:"Utenos apskritis",
   code_NL121:"Noord-Friesland",
   code_NL122:"Zuidwest-Friesland",
   code_NL123:"Zuidoost-Friesland",
   code_NL322:"Alkmaar en omgeving",
   code_NL326:"Groot-Amsterdam",
   code_NL331:"Agglomeratie Leiden en Bollenstreek",
   code_NL336:"Zuidoost-Zuid-Holland",
   code_NL334:"Oost-Zuid-Holland",
   code_NL335:"Groot-Rijnmond",
   code_PL215:"Nowosądecki",
   code_PL216:"Oświęcimski",
   code_PL422:"Koszaliński",
   code_PL425:"Szczeciński",
   code_PL521:"Nyski",
   code_PL522:"Opolski",
   code_PL614:"Grudziądzki",
   code_PL615:"Włocławski",
   code_PL631:"Słupski",
   code_PL635:"Starogardzki",
   code_PL11:"Łódzkie",
   code_PL113:"Miasto Łódź",
   code_PL114:"Łódzki",
   code_PL115:"Piotrkowski",
   code_PL116:"Sieradzki",
   code_PL117:"Skierniewicki",
   code_PL33:"Świętokrzyskie",
   code_PL331:"Kielecki",
   code_PL332:"Sandomiersko-jędrzejowski",
   code_PL3:"REGION WSCHODNI",
   code_PL31:"Lubelskie",
   code_PL311:"Bialski",
   code_PL312:"Chełmsko-zamojski",
   code_PL314:"Lubelski",
   code_PL315:"Puławski",
   code_PL32:"Podkarpackie",
   code_PL323:"Krośnieński",
   code_PL324:"Przemyski",
   code_PL325:"Rzeszowski",
   code_PL326:"Tarnobrzeski",
   code_PL34:"Podlaskie",
   code_PL343:"Białostocki",
   code_PL344:"Łomżyński",
   code_PL345:"Suwalski",
   code_PL127:"Miasto Warszawa",
   code_PL129:"Warszawski-wschodni",
   code_PL12A:"Warszawski-zachodni",
   code_PL128:"Radomski",
   code_PT113:"Ave",
   code_PT114:"Grande Porto",
   code_PT115:"Tâmega",
   code_PT116:"Entre Douro e Vouga",
   code_PT117:"Douro",
   code_PT118:"Alto Trás-os-Montes",
   code_PT163:"Pinhal Litoral",
   code_PT165:"Dâo-Lafôes",
   code_PT182:"Alto Alentejo",
   code_PT183:"Alentejo Central",
   code_SI01:"Vzhodna Slovenija",
   code_SI011:"Pomurska",
   code_SI012:"Podravska",
   code_SI013:"Koroška",
   code_SI014:"Savinjska",
   code_SI015:"Zasavska",
   code_SI016:"Spodnjeposavska",
   code_SI017:"Jugovzhodna Slovenija",
   code_SI018:"Notranjsko-kraška",
   code_SI02:"Zahodna Slovenija",
   code_SI021:"Osrednjeslovenska",
   code_SI022:"Gorenjska",
   code_SI023:"Goriška",
   code_SI024:"Obalno-kraška",
   code_UKD2:"Cheshire",
   code_UKD5:"Merseyside",
   code_UKD51:"East Merseyside",
   code_UKD52:"Liverpool",
   code_UKD53:"Sefton",
   code_UKD54:"Wirral",
   code_UKM2:"Eastern Scotland",
   code_UKM21:"Angus and Dundee City",
   code_UKM22:"Clackmannanshire and Fife",
   code_UKM23:"East Lothian and Midlothian",
   code_UKM25:"Edinburgh, City of",
   code_UKM26:"Falkirk",
   code_UKM27:"Perth & Kinross and Stirling",
   code_UKM28:"West Lothian",
   code_UKM31:"East Dunbartonshire, West Dunbartonshire and Helensburgh & Lomond",
   code_UKM34:"Glasgow City",
   code_UKM35:"Inverclyde, East Renfrewshire and Renfrewshire",
   code_UKM36:"North Lanarkshire",
   code_UKM24:"Scottish Borders",
   code_UKM32:"Dumfries & Galloway",
   code_UKM33:"East Ayrshire and North Ayrshire mainland",
   code_UKM37:"South Ayrshire",
   code_UKM38:"South Lanarkshire",
   code_UKN01:"Belfast",
   code_FR2:"Bassin Parisien",
   code_FR4:"Est",
   code_42924000:"Pulvérisateurs",
   code_35811000:"Uniformes",
   code_34511000:"Navires de patrouille",
   code_44115300:"Volets",
   code_44613100:"Silos",
   code_35611000:"Avions de chasse, bombardiers et autres aéronefs",
   code_60110000:"Services de transport public",
   code_39250000:"Horloges, montres et réveils",
   code_30192120:"Stylos, crayons et porte-stylos",
   code_35612000:"Hélicoptères militaires et hélicoptères de recherche et de sauvetage",
   code_38527000:"Dosimètres",
   code_P:"Installations électriques",
   code_BE328:"Arr. Tournai-Mouscron",
   code_BE329:"Arr. La Louvière",
   code_BE32A:"Arr. Ath",
   code_BE32B:"Arr. Charleroi",
   code_BE32C:"Arr. Soignies",
   code_BE32D:"Arr. Thuin",
   code_EE009:"Kesk-Eesti",
   code_EE00A:"Kirde-Eesti",
   code_HR05:"Grad Zagreb",
   code_HR050:"Grad Zagreb",
   code_HR06:"Sjeverna Hrvatska",
   code_HR061:"Međimurska županija",
   code_HR062:"Varaždinska županija",
   code_HR063:"Koprivničko-križevačka županija",
   code_HR064:"Krapinsko-zagorska županija",
   code_HR065:"Zagrebačka županija",
   code_HR02:"Panonska Hrvatska",
   code_ITG2D:"Sassari",
   code_ITG2E:"Nuoro",
   code_ITG2F:"Cagliari",
   code_ITG2G:"Oristano",
   code_ITG2H:"Sud Sardegna",
   code_UKK24:"Bournemouth, Christchurch and Poole",
   code_UKK25:"Dorset",
   code_UKN0A:"Derry City and Strabane",
   code_UKN0B:"Mid Ulster",
   code_UKN0C:"Causeway Coast and Glens",
   code_UKN0D:"Antrim and Newtownabbey",
   code_UKN0E:"Lisburn and Castlereagh",
   code_UKN0F:"Mid and East Antrim",
   code_UKN0G:"Fermanagh and Omagh",
   code_NO020:"Innlandet",
   code_NO060:"Trøndelag",
   code_NO074:"Troms og Finnmark",
   code_NO08:"Oslo og Viken",
   code_NO081:"Oslo",
   code_NO082:"Viken",
   code_NO09:"Agder og Sør-Østlandet",
   code_NO091:"Vestfold og Telemark",
   code_NO092:"Agder",
   code_NO0A:"Vestlandet",
   code_NO0A1:"Rogaland",
   code_NO0A2:"Vestland",
   code_NO0A3:"Møre og Romsdal",
   code_DE41:"Brandenburg - Nordost",
   code_DE42:"Brandenburg - Südwest",
   code_DE801:"Greifswald, Kreisfreie Stadt",
   code_DE802:"Neubrandenburg, Kreisfreie Stadt",
   code_DE805:"Stralsund, Kreisfreie Stadt",
   code_DE806:"Wismar, Kreisfreie Stadt",
   code_DE807:"Bad Doberan",
   code_DE808:"Demmin",
   code_DE809:"Güstrow",
   code_DE80A:"Ludwigslust",
   code_DE80B:"Mecklenburg-Strelitz",
   code_DE80C:"Müritz",
   code_DE80D:"Nordvorpommern",
   code_DE80E:"Nordwestmecklenburg",
   code_DE80F:"Ostvorpommern",
   code_DE80G:"Parchim",
   code_DE80H:"Rügen",
   code_DE80I:"Uecker-Randow",
   code_DE915:"Göttingen",
   code_DE919:"Osterode am Harz",
   code_DEA21:"Aachen, Kreisfreie Stadt",
   code_DEA25:"Aachen, Kreis",
   code_DED12:"Plauen, Kreisfreie Stadt",
   code_DED13:"Zwickau, Kreisfreie Stadt",
   code_DED14:"Annaberg",
   code_DED15:"Chemnitzer Land",
   code_DED16:"Freiberg",
   code_DED17:"Vogtlandkreis",
   code_DED18:"Mittlerer Erzgebirgskreis",
   code_DED19:"Mittweida",
   code_DED1A:"Stollberg",
   code_DED1B:"Aue-Schwarzenberg",
   code_DED1C:"Zwickauer Land",
   code_DED22:"Görlitz, Kreisfreie Stadt",
   code_DED23:"Hoyerswerda, Kreisfreie Stadt",
   code_DED24:"Bautzen",
   code_DED25:"Meißen",
   code_DED26:"Niederschlesischer Oberlausitzkreis",
   code_DED27:"Riesa-Großenhain",
   code_DED28:"Löbau-Zittau",
   code_DED29:"Sächsische Schweiz",
   code_DED2A:"Weißeritzkreis",
   code_DED2B:"Kamenz",
   code_DED32:"Delitzsch",
   code_DED33:"Döbeln",
   code_DED34:"Leipziger Land",
   code_DED35:"Muldentalkreis",
   code_DED36:"Torgau-Oschatz",
   code_GR131:"Grevena",
   code_GR133:"Kozani",
   code_GR211:"Arta",
   code_GR214:"Preveza",
   code_GR141:"Karditsa",
   code_GR144:"Trikala",
   code_GR251:"Argolida",
   code_GR252:"Arkadia",
   code_GR254:"Lakonia",
   code_GR255:"Messinia",
   code_GR300:"Attiki",
   code_FI181:"Uusimaa",
   code_FI182:"Itä-Uusimaa",
   code_FI18:"Etelä-Suomi",
   code_FI13:"Itä-Suomi",
   code_FI1A:"Pohjois-Suomi",
   code_FR5:"OUEST",
   code_FR6:"SUD-OUEST",
   code_FR7:"CENTRE-EST",
   code_FR8:"MÉDITERRANÉE",
   code_FR9:"DÉPARTEMENTS D'OUTRE-MER",
   code_HR01:"Sjeverozapadna Hrvatska",
   code_HU10:"Közép-Magyarország",
   code_IE02:"Southern and Eastern",
   code_LT00:"Lietuva",
   code_PL1:"REGION CENTRALNY",
   code_PL12:"Mazowieckie",
   code_PL121:"Ciechanowsko-płocki",
   code_PL122:"Ostrołęcko-siedlecki",
   code_PL423:"Stargardzki",
   code_PT161:"Baixo Vouga",
   code_PT162:"Baixo Mondego",
   code_PT164:"Pinhal Interior Norte",
   code_PT166:"Pinhal Interior Sul",
   code_PT169:"Beira Interior Sul",
   code_PT16C:"Médio Tejo",
   code_PT167:"Serra da Estrela",
   code_PT168:"Beira Interior Norte",
   code_PT16A:"Cova da Beira",
   code_PT171:"Grande Lisboa",
   code_PT172:"Península de Setúbal",
   code_UKD31:"Greater Manchester South",
   code_UKD32:"Greater Manchester North",
   code_UKD43:"Lancashire CC",
   code_UKD21:"Halton and Warrington",
   code_UKD22:"Cheshire CC",
   code_UKE43:"Calderdale, Kirklees and Wakefield",
   code_UKF23:"Northamptonshire",
   code_UKG34:"Dudley and Sandwell",
   code_UKG35:"Walsall and Wolverhampton",
   code_UKH13:"Norfolk",
   code_UKH22:"Bedfordshire CC",
   code_UKH33:"Essex CC",
   code_UKI1:"Inner London",
   code_UKI11:"Inner London - West",
   code_UKI12:"Inner London - East",
   code_UKI2:"Outer London",
   code_UKI21:"Outer London - East and North East",
   code_UKI22:"Outer London - South",
   code_UKI23:"Outer London - West and North West",
   code_UKJ23:"Surrey",
   code_UKJ24:"West Sussex",
   code_UKJ33:"Hampshire CC",
   code_UKJ42:"Kent CC",
   code_UKM3:"South Western Scotland",
   code_UKN02:"Outer Belfast",
   code_UKN03:"East of Northern Ireland",
   code_UKN04:"North of Northern Ireland",
   code_UKN05:"West and South of Northern Ireland",
   code_1A:"Kosovo",
   code_ZM:"Zambia",
   code_AX:"Åland Islands",
   code_BL:"Saint Barthélemy",
   code_BQ:"Bonaire, Sint Eustatius and Saba",
   code_CC:"Cocos (Keeling) Islands",
   code_CW:"Curaçao",
   code_GG:"Guernsey",
   code_HM:"Heard Island and McDonald Islands",
   code_IM:"Isle of Man",
   code_JE:"Jersey",
   code_MF:"Saint Martin (French part)",
   code_NC:"New Caledonia",
   code_SS:"South Sudan",
   code_SX:"Sint Maarten (Dutch part)",
   code_VA:"Holy See",
   code_CP:"Clipperton Island",
   code_NO0B:"Svalbard og Jan Mayen",
   code_NO0B1:"Jan Mayen",
   code_NO0B2:"Svalbard",
   code_BE224:"Arr. Hasselt",
   code_BE225:"Arr. Maaseik",
   code_ISZ:"Extra-Regio NUTS 1",
   code_ISZZ:"Extra-Regio NUTS 2",
   code_ISZZZ:"Extra-Regio NUTS 3",
   code_LIZ:"Extra-Regio NUTS 1",
   code_LIZZ:"Extra-Regio NUTS 2",
   code_LIZZZ:"Extra-Regio NUTS 3",
   code_NOZ:"Extra-Regio NUTS 1",
   code_NOZZ:"Extra-Regio NUTS 2",
   code_NOZZZ:"Extra-Regio NUTS 3",
   code_CHZ:"Extra-Regio NUTS 1",
   code_CHZZ:"Extra-Regio NUTS 2",
   code_CHZZZ:"Extra-Regio NUTS 3",
   code_MEZ:"Extra-Regio NUTS 1",
   code_MEZZ:"Extra-Regio NUTS 2",
   code_MEZZZ:"Extra-Regio NUTS 3",
   code_MKZ:"Extra-Regio NUTS 1",
   code_MKZZ:"Extra-Regio NUTS 2",
   code_MKZZZ:"Extra-Regio NUTS 3",
   code_ALZ:"Extra-Regio NUTS 1",
   code_ALZZ:"Extra-Regio NUTS 2",
   code_ALZZZ:"Extra-Regio NUTS 3",
   code_RS1:"Србија - север ",
   code_RS11:"Београдски регион",
   code_RS110:"Београдска област",
   code_RS12:"Регион Војводине",
   code_RS121:"Западнобачка област",
   code_RS122:"Јужнобанатска област",
   code_RS123:"Јужнобачка област",
   code_RS124:"Севернобанатска област",
   code_RS125:"Севернобачка област",
   code_RS126:"Средњобанатска област",
   code_RS127:"Сремска област",
   code_RS2:"Србија - југ ",
   code_RS21:"Регион Шумадије и Западне Србије",
   code_RS211:"Златиборска област",
   code_RS212:"Колубарска област",
   code_RS213:"Мачванска област",
   code_RS214:"Моравичка област",
   code_RS215:"Поморавска област",
   code_RS216:"Расинска област",
   code_RS217:"Рашка област",
   code_RS218:"Шумадијска област",
   code_RS22:"Регион Јужне и Источне Србије",
   code_RS221:"Борска област",
   code_RS222:"Браничевска област",
   code_RS223:"Зајечарска област",
   code_RS224:"Јабланичка област",
   code_RS225:"Нишавска област",
   code_RS226:"Пиротска област",
   code_RS227:"Подунавска област",
   code_RS228:"Пчињска област",
   code_RS229:"Топличка област",
   code_RSZ:"Extra-Regio NUTS 1",
   code_RSZZ:"Extra-Regio NUTS 2",
   code_RSZZZ:"Extra-Regio NUTS 3",
   code_TRZ:"Extra-Regio NUTS 1",
   code_TRZZ:"Extra-Regio NUTS 2",
   code_TRZZZ:"Extra-Regio NUTS 3",
   accreditation_budget_class_1: "Classe 1: jusqu'à 135.000 EUR",
   accreditation_budget_class_2: "Classe 2: jusqu'à 275.000 EUR",
   accreditation_budget_class_3: "Classe 3: jusqu'à 500.000 EUR",
   accreditation_budget_class_4: "Classe 4: jusqu'à 900.000 EUR",
   accreditation_budget_class_5: "Classe 5: jusqu'à 1.810.000 EUR",
   accreditation_budget_class_6: "Classe 6: jusqu'à 3.225.000 EUR",
   accreditation_budget_class_7: "Classe 7: jusqu'à 5.330.000 EUR",
   accreditation_budget_class_8: "Classe 8: Plus de 5.330.000 EUR",
   accreditation_P1:"Installations électriques des bâtiments, y compris installations de groupes électrogènes, équipements de détection d’incendie et de vol, télétransmissions dans les bâtiments et leur périphérie et installations ou équipements de téléphonie mixte",
   accreditation_P2:"Installations électriques et électromécaniques d’ouvrages d’art ou industriels et installations électriques extérieures",
   accreditation_T6:"Equipements d’abattoirs",
   accreditation_D11:"Plafonnage, crépissage",
   accreditation_P3:"Installations de lignes aériennes de transport électrique",
   accreditation_D10:"Carrelages",
   accreditation_P4:"Installations électriques d’ouvrages portuaires",
   accreditation_D13:"Peinture",
   accreditation_L1:"Installations de tuyauteries",
   accreditation_D12:"Couvertures non métalliques et non asphaltiques",
   accreditation_L2:"Equipements de stations de pompage ou de turbinage",
   accreditation_D15:"Parquetage",
   accreditation_D14:"Vitrerie",
   accreditation_D17:"Chauffage central, installations thermiques",
   accreditation_H1:"Travaux de soudure de rails",
   accreditation_D16:"Installations sanitaires et installations de chauffage au gaz par appareils individuels",
   accreditation_H2:"Pose de caténaires",
   accreditation_D18:"Ventilation, chauffage à air chaud, conditionnement d’air",
   accreditation_D1:"Tous travaux de gros oeuvre et de mise sous toit de bâtiments",
   accreditation_D4:"Isolation acoustique ou thermique, cloisons légères, faux plafonds et faux planchers préfabriqués ou non",
   accreditation_D5:"Menuiserie générale, charpentes et escaliers en bois",
   accreditation_D6:"Marbrerie et taille de pierres",
   accreditation_D7:"Ferronnerie",
   accreditation_D8:"Couvertures de toiture asphaltiques ou similaires, travaux d’étanchéité",
   accreditation_D20:"Menuiserie métallique",
   accreditation_D22:"Couvertures métalliques de toiture et zinguerie",
   accreditation_D21:"Ravalement et remise en état de façades",
   accreditation_M1:"Equipements électroniques à fréquence industrielle ou élevée y compris équipements des stations d’alimentation",
   accreditation_D24:"Restauration de monuments",
   accreditation_D23:"Restauration par des artisans",
   accreditation_D25:"Revêtements de murs et de sols, autres que la marbrerie, le parquetage et les carrelages",
   accreditation_D29:"Chapes de sols et revêtements de sols industriels",
   accreditation_E1:"Egouts collecteurs",
   accreditation_E2:"Fondations profondes sur pieux, rideaux de palplanches, murs emboués",
   accreditation_E4:"Fonçages horizontaux d’éléments constitutifs d’ouvrages d’art",
   accreditation_A1:"Renflouage de bateaux et enlèvement d’épaves",
   accreditation_N1:"Ascenseurs, monte-charges, escaliers et trottoirs roulants",
   accreditation_N2:"Transports par gaines ou tubes d’objets, de documents ou de marchandises (pneumatique, mécanique…)",
   accreditation_F1:"Travaux de montage et de démontage (sans fournitures)",
   accreditation_F2:"Construction de charpentes métalliques",
   accreditation_F3:"Peinture industrielle",
   accreditation_B1:"Curage de cours d’eau",
   accreditation_S1:"Equipements de téléphonie et de télégraphie",
   accreditation_S2:"Equipements de télécommande, télécontrôle et télémesure",
   accreditation_S3:"Equipements de transmission de radio et de télévision, radar et antennes",
   accreditation_A:"Entreprises de dragage",
   accreditation_S4:"Equipements d’informatique et de régulation de processus",
   accreditation_B:"Entreprises de travaux hydrauliques",
   accreditation_C:"Entreprises générales de travaux routiers",
   accreditation_D:"Entreprises générales de bâtiments",
   accreditation_E:"Entreprises de génie civil",
   accreditation_F:"Entreprises de constructions métalliques",
   accreditation_K1:"Equipements d’ouvrages d’art ou de mécanique industrielle",
   accreditation_G:"Entreprises de terrassements",
   accreditation_K2:"Installations d’engins de manutention et de levage (grues, ponts roulants…)",
   accreditation_H:"Entreprises de voies ferrées",
   accreditation_K3:"Equipements oléomécaniques",
   accreditation_G1:"Travaux de forage, de sondage et d’injection",
   accreditation_K:"Entreprises d’équipements mécaniques",
   accreditation_G2:"Travaux de drainage",
   accreditation_L:"Entreprises d’installations d’équipements hydromécaniques",
   accreditation_G3:"Plantations",
   accreditation_M:"Entreprises d’installations d’équipements électroniques",
   accreditation_G4:"Revêtements spéciaux pour terrains de sport",
   accreditation_N:"Entreprise d’installations de transport dans les bâtiments",
   accreditation_C1:"Travaux d’égouts courants",
   accreditation_G5:"Travaux de démolition",
   accreditation_C2:"Distribution d’eau et pose de canalisations diverses",
   accreditation_C3:"Signalisation non-électrique des voies de communication, dispositifs de sécurité, clôtures et écrans de tout type, non électriques",
   accreditation_C5:"Revêtements hydrocarbonés et enduisages",
   accreditation_S:"Entreprises d’installation d’équipements de télétransmission et de gestion de données",
   accreditation_C6:"Pose en tranchées de câbles électriques d’énergie et de télécommunication, sans connexion",
   accreditation_C7:"Fonçages horizontaux de tuyaux pour câbles et canalisations",
   accreditation_U:"Installations pour traitement des immondices",
   accreditation_V:"Installations d’épuration d’eau",
   accreditation_T2:"Paratonnerres, antennes de réception",
   accreditation_T3:"Equipements frigorifiques",
   accreditation_T4:"Equipements de buanderies et de grandes cuisines",
   accreditation_P:"Installations électriques",



}