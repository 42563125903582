import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { RaiseUser } from "../../../../hooks/raiseUser";
import {
    SearchResponse,
    TenderSearchAggregation,
    updateSearchFilters
} from "../../../../hooks/slices/tenderSearchSlice";
import { Label, LabelCategory } from "../../../../types/label";
import { SearchResultsHeaderProps } from "./SearchResultsHeader";
import { SearchConfiguration } from "../../GenericSearchPage";
import { TenderSearchUtil } from "../../../../utils/savedsearches";
import {UserInteraction} from "../../../../hooks/slices/userSlice";

export const AggregationsInfoBox: React.FC<SearchResultsHeaderProps> = (props) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const userInStore = RaiseUser();
    let userInteractions: UserInteraction[] = userInStore.user?.userInteractions ? userInStore.user.userInteractions : [];
    const searchFilters = useAppSelector((state) => state.tenderSearch.searchFilters);
    const aggregations: TenderSearchAggregation[] | undefined = useAppSelector((state) => state.tenderSearch.aggregations);
    const response: SearchResponse | undefined = useAppSelector((state) => state.tenderSearch.searchResponse);
    const labelCategories: LabelCategory[] = userInStore.user && userInStore.user.labelCategories ? userInStore.user.labelCategories : [];
    if (aggregations === undefined && (response && response.totalHits < 10000)) {
        //if totalHits >= 10000 aggregations remain undefined
        return <AggregationsInfoBoxSkeleton {...props.searchConfiguration} />;
    }
    const allLabels: Label[] = [];
    for (const c of labelCategories) {
        for (const l of c.labels) {
            allLabels.push(l);
        }
    }
    let cueAggregations: TenderSearchAggregation[] = aggregations ? getCueAggregations(aggregations, 1) : [];
    const addCueToQueryFilter = (cueAggregation: TenderSearchAggregation) => {
        if (cueAggregation.cue && !searchFilters.query.includes(cueAggregation.cue)) {
            let query = TenderSearchUtil.getCueAsQuery(cueAggregation.cue);
            let newQuery = searchFilters.query === "" ? query : searchFilters.query + " AND " + query;
            dispatch(updateSearchFilters({query: newQuery, userInteractions: userInteractions}));
        }
    };
    let labelAggregations: TenderSearchAggregation[] = getLabelAggregations(aggregations ? aggregations : [], 1);
    const addLabelFilter = (labelAggregation: TenderSearchAggregation) => {
        let label = allLabels.filter(l => l.name === labelAggregation.label);
        if (label.length === 1) {
            let labelFilterIsAlreadyPresent = searchFilters.labelFilters && searchFilters.labelFilters.filter(l => l.name === label[0].name).length > 0;
            if (!labelFilterIsAlreadyPresent) {
                let newLabelFilters = searchFilters.labelFilters ? [...searchFilters.labelFilters, label[0]] : [label[0]];
                dispatch(updateSearchFilters({labelFilters: newLabelFilters, userInteractions: userInteractions}));
            }
        }
    };
    let showCueAggregations = props.searchConfiguration.info.showCueAggregations || searchFilters.searchSector === true;
    let showLabelAggregations = props.searchConfiguration.info.showLabelAggregations;
    let showAggregations = props.tenderSearchOutput.totalHits < 10000 && (showCueAggregations || showLabelAggregations);
    return <>
        {showAggregations &&
            <div className="first-card-details">
                {showCueAggregations &&
                    <p>
                        <span>{t("publications.searchTermsFound") + ": "}</span>
                        {cueAggregations.map((tsa, i) =>
                            <span key={i}
                                  onClick={() => addCueToQueryFilter(tsa)}>{tsa.cue + " (" + tsa.count + ")" + (i < cueAggregations.length - 1 ? ", " : "")}</span>)}
                        {cueAggregations.length === 0 && <span>{t("publications.noSearchTermsFound")}</span>}
                    </p>}
                {showLabelAggregations &&
                    <p>
                        <span>{t("settings.labels") + ": "}</span>
                        {labelAggregations.map((tsa, i) =>
                            <span key={i}
                                  onClick={() => addLabelFilter(tsa)}>{tsa.label + " (" + tsa.count + ")" + (i < labelAggregations.length - 1 ? ", " : "")}</span>)}
                        {labelAggregations.length === 0 && <span>{t("publications.noLabelsFound")}</span>}
                    </p>}
            </div>
        }

    </>;
};

function getCueAggregations(aggregations: TenderSearchAggregation[], minCount: number): TenderSearchAggregation[] {
    if (aggregations === undefined) return [];
    return aggregations
        .filter(a => a.cue !== null)
        .filter(a => a.count >= minCount)
        .sort((c1, c2) => c1.count > c2.count ? -1 : c2.count > c1.count ? 1 : 0);
}

function getLabelAggregations(aggregations: TenderSearchAggregation[], minCount: number): TenderSearchAggregation[] {
    if (aggregations === undefined) return [];
    else return aggregations
        .filter(a => a.label !== null)
        .filter(a => a.count >= minCount)
        .sort((c1, c2) => c1.count > c2.count ? -1 : c2.count > c1.count ? 1 : 0);
}


export const AggregationsInfoBoxSkeleton: React.FC<SearchConfiguration> = (props) => {
    const {t} = useTranslation();
    return <>
        {(props.info.showCueAggregations || props.info.showLabelAggregations) &&
            <div className="first-card-details">
                {props.info.showCueAggregations && <p>
                    <span>{t("publications.searchTermsFound") + ":"}</span><span>...</span>
                </p>}
                {props.info.showLabelAggregations && <p>
                    <span>{t("settings.labels") + ":"}</span><span>...</span>
                </p>}
            </div>}
    </>;
};