import React, {ReactNode} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {updateToken} from "../../hooks/slices/loginSlice";
import {LoginOrSignupHeader} from "../loginHeader";
import {Login} from "../../pages/login";
import {RaiseUser} from "../../hooks/raiseUser";
import {SignupDetails} from "../../pages/signup/details";
import {useLocation} from "react-router-dom";
import {LoginOrSignupLayout} from "../../pages";
import {Loader} from "../loader";
import {DateUtil} from "../../utils/date";
import {persistor} from "../../app/store";

/*
 * PageOrLogin serves the content in children if the user
 * already has a valid authorization token in the redux store.
 * If not, then the user will instead be shown a login page.
 */
export const PageOrLogin: React.FC<{}> = ({children}) => {
    const sessionToken = useAppSelector((state) => state.login.parsedToken);
    let localToken = localStorage.getItem("token");
    let tokenExpired = sessionToken && sessionToken.exp < DateUtil.today().unix();
    const dispatch = useAppDispatch();
    const userInStore = RaiseUser();
    const activityStatus = userInStore.user ? userInStore.user.activityStatus : undefined;
    if(userInStore.isLoading) return <Loader/>
    else if (userInStore.error || tokenExpired || (!sessionToken && !localToken)) {
        persistor.purge();
        return <LoginPage/>
    } else {
        if (!sessionToken && localToken) {
            dispatch(updateToken(localToken));
        }
        return <CheckActivityStatus activityStatus={activityStatus} children={children}/>
    }
}

const LoginPage: React.FC = () => {
    return (
        <div>
            <LoginOrSignupHeader showLanguagesDropdown={true}/>
            <Login/>
        </div>
    );
}

interface checkActivityStatusProps {
    activityStatus: string | undefined,
    children: ReactNode,
}


const CheckActivityStatus: React.FC<checkActivityStatusProps> = ({activityStatus, children}) => {
    const location = useLocation();
    if (activityStatus == null) return <Loader/>
    else if(activityStatus === "ACTIVE") return <>{children}</>;
    if (activityStatus === "UNFINISHED") {
        if (location.pathname.includes("signup-")) return <>{children}</>;
        else return <LoginOrSignupLayout showLanguagesDropdown={false}><SignupDetails/></LoginOrSignupLayout>;
    } else return <Login/>

}


