import { ResourceKey } from "i18next";

export const Intake: ResourceKey = {
	title_default: "Creëer een nieuw account",
	title_government: "Toegang tot de bestekkendatabank",
	trial_message_start: "Je krijgt een gratis proefperiode van ",
	trial_message_end_days: " dagen.",
	trial_message_end_months: " maanden.",
	trial_message_end_years: " jaren.",
	trial_plan_message_government: "Je kan volledig gratis en vrijblijvend gebruik maken van onze bestekkendatabank.",
	trial_message_accelerated: "Je krijgt toegang tot een gratis proefperiode van TenderWolf Professional. Volledig vrijblijvend.",
	change_plan_message_default: "Je kan op elk moment overstappen naar een ander plan. Zonder overstap word je na de proefperiode op het plan Free gezet.",
	change_plan_message_government: "De gratis toegang is beperkt tot één werknemer per organisatie. Organisaties die toegang willen voor meerdere werknemers dienen een betalend abonnement te nemen.",
	promotion_plan_message: "Dit is de snelste, meest uitgebreide en gebruiksvriendelijke bestekkendatabank op de Belgische markt.",
	first_name: "Voornaam",
	last_name: "Naam",
	email: "E-mailadres",
	enter_password: "Wachtwoord",
	current_password: "Huidig wachtwoord",
	confirm_password: "Herhaal wachtwoord",
	agree: "Ik ben akkoord met ",
	privacy_policy: "het privacy-beleid ",
	and: "en ",
	terms: "de gebruikersvoorwaarden",
	register: "Registreer",
	already_account: "Al geregistreerd?",
	email_already_in_use: "E-mailadres reeds in gebruik",
	fill_in_all_fields: "Vul alle velden in",
	passwords_dont_match: "Wachtwoorden komen niet overeen",
	password_is_invalid: "Ongeldig wachtwoord",
	valid_password_description: "Kies een wachtwoord dat minstens 8 tekens lang is, bestaat uit zowel kleine letters als hoofletters, en ook minstens één cijfer en één leesteken bevat.",
	login: "Log in",
	second_page_title: "Vul de verdere informatie in",
	company_name: "Organisatie",
	invoice_reference: "Referentie factuur",
	vat_number_default: "Ondernemingsnummer",
	vat_number_government: "Ondernemingsnummer (indien van toepassing)",
	country: "Land",
	website_default: "Website",
	website_government: "Website (optioneel)",
	tender_experience: "Heeft je bedrijf al meegedaan aan overheidsopdrachten?",
	next: "Volgende",
	skip: "Overslaan",
	yes: "Ja",
	no: "Nee",
	ok: "Ok",
	third_page_title: "Selecteer de landen waarin je geïnteresseerd bent",
	all_publications: "Alle overheidsopdrachten (incl. EU-instellingen)",
	european_publications_only: "Alle overheidsopdrachten boven de Europese drempels (incl. EU-instellingen)",
	fourth_page_title: "Geef ons enkele trefwoorden die de overheidsopdrachten beschrijven waarnaar je op zoek bent",
	company_description: "Beschrijf je activiteiten waarvoor je openbare aanbestedingen zoekt (optioneel)",
	hardstop_title: "Bedankt voor je inschrijving!",
	hardstop_text: "Onze tender search experts zijn op de hoogte van je aanvraag.\n"
		+ "Binnen 6-24 uur stellen wij je proefaccount van TenderWolf in.\n"
		+ "Bedankt voor je geduld.",
	invalid_email: "Ongeldig e-mailadres",
    close: "Sluiten",
	must_select_at_least_one_country: "Je moet ten minste één land selecteren",
	cant_select_more_than_six_countries: "Je kunt niet meer dan zes landen selecteren tijdens je proefperiode",
	address: "Adres",
	main_activity: "Hoofdactiviteit",
	starting_date: "Startdatum",
	enterprise_number: "Ondernemingsnummer",
	is_subcontractor: "Heb je interesse om overheidsopdrachten als onderaannemer uit te voeren?",
	is_looking_for_subcontractors: "Ben je soms op zoek naar onderaannemers voor bepaalde delen van een overheidscontract dat je gewonnen hebt?",
	is_looking_for_partners: "Sta je open voor of ben je soms op zoek naar partners voor het meedingen naar overheidsopdrachten?",
	is_interested_in_private_contracts: "Ben je geïnteresseerd in het uitvoeren van onderhandse opdrachten, en mogen we je bedrijf voorstellen aan ambtenaren?",
	complete_where_needed: "Vul aan waar nodig",
	curated_profiles_title: "In welke sectoren heb jij interesse?",
	we_found_this_info_about_you: "We hebben deze informatie over jou teruggevonden",
	answer_these_questions: "Beantwoord deze vragen",
	i_cant_find_my_sector: "Ik vind mijn sector niet terug",
	you_have_accreditations: "We hebben de volgende erkenningen teruggevonden voor je onderneming. Pas aan als je voor sommige erkenningen (categorie en klassen) geen overheidsopdrachten wil ontvangen.",
	select_regions: "Duid de regio's aan waar je opdrachten wil uitvoeren",
};