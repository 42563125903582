import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Client} from "../../types/client";
import {apiSlice} from "./apiSlice";
import {PURGE} from "redux-persist";
import {CurrentPlanState} from "./currentPlanSlice";

/*
  REDUX STORE stuff
*/

// export interface ClientState {
//     uuid?: string;
//     website?: string;
//     companyName?: string;
//     vatNumber?: string;
//     emailAddress?: string;
//     phoneNumber?: string;
//     streetName?: string;
//     streetNumber?: string;
//     zipCode?: string;
//     cityName?: string;
//     countryIso?: string;
//     automaticallySendInvoices?: boolean;
//     invoiceReference?: string;
//     invoicePaymentTerm?: number;
// }

export const initialState: Client = {};

export const clientSlice = createSlice({
    initialState,
    name: "client",
    reducers: {
        updateClient(state, action: PayloadAction<Client>) {
            if(action.payload.uuid !== undefined) state.uuid = action.payload.uuid;
            if(action.payload.clientType !== undefined) state.clientType = action.payload.clientType;
            if(action.payload.website !== undefined) state.website = action.payload.website;
            if(action.payload.companyName !== undefined) state.companyName = action.payload.companyName;
            if(action.payload.vatNumber !== undefined) state.vatNumber = action.payload.vatNumber;
            if(action.payload.emailAddress !== undefined) state.emailAddress = action.payload.emailAddress;
            if(action.payload.phoneNumber !== undefined) state.phoneNumber = action.payload.phoneNumber;
            if(action.payload.streetName !== undefined) state.streetName = action.payload.streetName;
            if(action.payload.streetNumber !== undefined) state.streetNumber = action.payload.streetNumber;
            if(action.payload.zipCode !== undefined) state.zipCode = action.payload.zipCode;
            if(action.payload.cityName !== undefined) state.cityName = action.payload.cityName;
            if(action.payload.countryIso !== undefined) state.countryIso = action.payload.countryIso;
            if(action.payload.automaticallySendInvoices !== undefined) state.automaticallySendInvoices = action.payload.automaticallySendInvoices;
            if(action.payload.invoiceReference !== undefined) state.invoiceReference = action.payload.invoiceReference;
            if(action.payload.invoicePaymentTerm !== undefined) state.invoicePaymentTerm = action.payload.invoicePaymentTerm;
        },
    }, extraReducers: (builder) => {
        builder.addCase(PURGE, (state) => {
            state.uuid = initialState.uuid;
            state.website = initialState.website;
            state.companyName = initialState.companyName;
            state.vatNumber = initialState.vatNumber;
            state.emailAddress = initialState.emailAddress;
            state.phoneNumber = initialState.phoneNumber;
            state.streetName = initialState.streetName;
            state.streetNumber = initialState.streetNumber;
            state.zipCode = initialState.zipCode;
            state.cityName = initialState.cityName;
            state.countryIso = initialState.countryIso;
            state.automaticallySendInvoices = initialState.automaticallySendInvoices;
            state.invoiceReference = initialState.invoiceReference;
            state.invoicePaymentTerm = initialState.invoicePaymentTerm;
        });
    }
});

export const {updateClient} = clientSlice.actions;

/*
  API connection stuff
*/



export interface GetClientParams {
    uuid: string;
}

const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        getClient: build.query<Client, GetClientParams>({
            query: (params: GetClientParams) => {
                return {
                    url: `/shaggy/client`,
                };
            },
        }),
        putClient: build.mutation<Client, Client>({
            query: (body: Client) => {
                return {
                    url: `shaggy/client`,
                    method: 'PUT',
                    body: body
                }
            }
        })
    }),
});

export const {useGetClientQuery, usePutClientMutation} = extendedApiSlice;
