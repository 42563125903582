import {FetchBaseQueryError, skipToken} from "@reduxjs/toolkit/query";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {SerializedError} from "@reduxjs/toolkit";

import {useEffect} from "react";
import {EmailPageState, GetEmailPageParams, updateEmailPage, useGetEmailPageQuery} from "./slices/userEmailPageSlice";


export interface EmailPageInStore {
    emailPage?: EmailPageState;
    isLoading: boolean;
    error: FetchBaseQueryError | SerializedError | undefined;
}

export function RaiseEmailPage(): EmailPageInStore {
    const dispatch = useAppDispatch();
    const authState = useAppSelector((state) => state.login);
    const emailAddressesInStore: EmailPageState = useAppSelector((state) => state.emailAddresses);
    let pageNeedsToBeFetched = emailAddressesInStore.email === undefined;
    let params: GetEmailPageParams | undefined = authState.userID ? {uuid: authState.userID} : undefined;
    const {data , isLoading, isSuccess, error, isError} = useGetEmailPageQuery(params ?? skipToken);
    useEffect(() => {
        //!(isLoading || isFetching) avoids masquerading issues (where the user is swapped)
        if (pageNeedsToBeFetched && isSuccess && data) {
            dispatch(updateEmailPage(data));
        }
    }, [pageNeedsToBeFetched, isSuccess, data, dispatch])

    return {
        emailPage: emailAddressesInStore,
        isLoading: isLoading,
        error: error
    }
}
