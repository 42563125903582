import {ResourceKey} from "i18next";

export const Languages: ResourceKey = {
    DE: "Allemand",
    FR: "Français",
    NL: "Néerlandais",
    EN: "Anglais",
    ALL: "Tous",

    EL : "Grec",
    ES : "Espagnol",
    ET : "Estonien",
    FI : "Finnois",
    GA : "Irlandais",
    HR : "Croate",
    HU : "Hongrois",
    IT : "Italien",
    LT : "Lituanien",
    LV : "Letton",
    MT : "Maltais",
    PL : "Polonais",
    PT : "Portugais",
    RO : "Roumain",
    SK : "Slovaque",
    SL : "Slovène",
    SV : "Suédois",

    BG : "Bulgare ",
    CS : "Tchèque",
    DA : "Danois",
};
