import React from 'react'
import Pages from '../../pages'

export default function Layout() {
  return (
    <div>
        <Pages/>
    </div>
  )
}
