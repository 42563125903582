import {ResourceKey} from "i18next";

export const SearchFilters: ResourceKey ={
	FIELD_GROUP_TITLE: "Title",
	FIELD_GROUP_DESCRIPTION: "Description",
	FIELD_GROUP_SPECIFICATIONS: "Specifications",
	FIELD_GROUP_ADDENDA: "search addenda: YES",
    FIELD_GROUP_AWARD_COMPANY_NAMES:"search winners: YES",
    FIELD_GROUP_BID_COMPANY_NAMES:"search participants: YES",
	PUBLICATION_DATE_EVERYTHING: "Everything",
	PUBLICATION_DATE_YESTERDAY: "Most recent",
	PUBLICATION_DATE_LAST_WEEK: "Last week",
	PUBLICATION_DATE_LAST_MONTH: "Last month",
	PUBLICATION_DATE_LAST_TWO_MONTHS: "Last two months",
	PUBLICATION_DATE_LAST_YEAR: "Last year",
	PUBLICATION_DATE_CUSTOM: "Custom",
	AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_EVERYTHING: "Everything",
	AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_YESTERDAY: "Most recent",
	AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_WEEK: "Last week",
	AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_MONTH: "Last month",
	AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_YEAR: "Last year",
	AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_CUSTOM: "Custom",
	DEADLINE_EVERYTHING: "Everything",
	DEADLINE_NOT_YET_EXPIRED: "Not yet expired",
	DEADLINE_IN_7_DAYS: "7 days",
	DEADLINE_IN_14_DAYS: "14 days",
	DEADLINE_IN_1_MONTH: "1 month",
	DEADLINE_IN_2_MONTHS: "2 months",
	DEADLINE_CUSTOM: "Custom",
    DEADLINE_THIS_YEAR:"", //not in use
    DEADLINE_PREVIOUS_YEAR:"", //not in use
    DEADLINE_TWO_YEARS_AGO:"", //not in use
    DEADLINE_MORE_THAN_TWO_YEARS_AGO:"", //not in use
	COUNTRY_BE: "Belgium",
	COUNTRY_NL: "Netherlands",
	COUNTRY_FR: "France",
	COUNTRY_LU: "Luxemburg",
	LANGUAGE_NL: "Dutch",
	LANGUAGE_FR: "French",
	LANGUAGE_DE: "German",
	LANGUAGE_EN: "English",
	HAS_PRIOR_INFORMATION_NOTICE: "Prior information notice",
	HAS_CONTRACT_NOTICE: "Contract notice",
	HAS_CONTRACT_AWARD_NOTICE: "Contract award notice",
	HAS_DESIGN_CONTEST: "Design contest",
	HAS_CORRIGENDA: "Corrigendum",
	HAS_AWARD_MODIFICATIONS: "Award modification",
	HAS_OPENING_REPORT: "Opening report",
	HAS_MUNICIPAL_DECISION: "Municipal council report",
	CONTRACT_TYPE_SERVICES: "Services",
	CONTRACT_TYPE_WORKS: "Works",
	CONTRACT_TYPE_SUPPLIES: "Supplies",
	CONTRACTING_AUTHORITY_TYPE_EU_INSTITUTION: "International organisation",
	CONTRACTING_AUTHORITY_TYPE_MINISTRY: "Ministry",
	CONTRACTING_AUTHORITY_TYPE_UTILITIES: "Utilities",
	CONTRACTING_AUTHORITY_TYPE_BODY_PUBLIC: "Body governed by public law",
	CONTRACTING_AUTHORITY_TYPE_RA: "Regional authority",
	CONTRACTING_AUTHORITY_TYPE_LA: "Local authority",
	CONTRACTING_AUTHORITY_TYPE_CGA: "Central government authority",
	CONTRACTING_AUTHORITY_TYPE_DEF_CONT: "Defence contractor",
	CONTRACTING_AUTHORITY_TYPE_PUB_UNDERT: "Public undertaking",
	CONTRACTING_AUTHORITY_TYPE_REGIONAL_AGENCY: "Regional agency",
	CONTRACTING_AUTHORITY_TYPE_NATIONAL_AGENCY: "National agency",
	CONTRACTING_AUTHORITY_TYPE_OTHER: "Other",
	PUBLICATION_RADIUS_EUROPEAN_UNION: "Yes",
	PUBLICATION_RADIUS_NATIONAL: "No",
	HAS_ADDENDA: "Yes",
	DOES_NOT_HAVE_ADDENDA: "No",
	PROCEDURE_TYPE_OPEN: "Open",
	PROCEDURE_TYPE_RESTRICTED: "Restricted",
	PROCEDURE_TYPE_COMPETITIVE_DIALOGUE: "Competitive dialogue",
	PROCEDURE_TYPE_INNOVATION_PARTNERSHIP: "Innovation partnership",
	PROCEDURE_TYPE_NEGOTIATED: "Negotiated",
	PROCEDURE_TYPE_OTHER: "Other",
};
