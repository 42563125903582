import React from "react";
import {useTranslation} from "react-i18next";
import {DateUtil} from "../../../../utils/date";
import {CloseIcon} from "../../../../components/icons";

interface DateFilterButtonProps {
    label: string;
    start: string | undefined;
    end: string | undefined;
    canRemove: boolean;
    removeDateFilters: () => void;
}

export const DateFilterButton: React.FC<DateFilterButtonProps> = ({label, start, end, canRemove, removeDateFilters}) => {
    const {t} = useTranslation();
    if (!start && !end) return <></>;
    let startString = start ? DateUtil.getDateMoment(start).format("DD-MM-YYYY") : "..."
    let endString = end ? DateUtil.getDateMoment(end).format("DD-MM-YYYY") : "..."
    let fullString = label + ": " + startString + " " + t("searchFilterTags.until") + " " + endString;
    if(!end && start && start === DateUtil.getDateString(DateUtil.today())) fullString = label + ": " + t("searchFilterTags.notYetExpired")
    return <>
        <button>
            <span>{fullString}</span>
            {canRemove && <span onClick={removeDateFilters} style={{cursor:"pointer"}} data-cy={"date-filter-close-button"}><CloseIcon/></span>}
        </button>
    </>
}