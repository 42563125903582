import React, {useState} from 'react'
import './forgot-password.scss';
import {useForgotPasswordMutation} from "../../hooks/slices/loginSlice";
import {useTranslation} from "react-i18next";
import {Form} from "../../components/form/Form";
import {Link} from "react-router-dom";

export const ForgotPassword: React.FC = () => {

    const [forgotPassword, isSuccess] = useForgotPasswordMutation();

    const onSubmit = (email: string) => {
        forgotPassword(email);
        if(isSuccess) setIsSent(true)
    };
    const [isSent, setIsSent] = useState(false);

    return (
        <>
            <ForgotPasswordForm onSubmit={onSubmit} isSent={isSent}/>
        </>
    );
};

interface ForgotPasswordFormProps {
    isSent: boolean;
    onSubmit: (email: string) => void;
}

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({isSent, onSubmit}) => {

    const {t} = useTranslation();

    const [email, setEmail] = useState("");
    const onEmailChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setEmail(e.currentTarget.value);
    };

    return (
        <div>
            <div className='layout-main-banner'>
                <div className='container'>
                    <div className='layout-top-alignment'>
                        <div className='forgot-password-box'>
                            <div className='page-title'>
                                <h1>{t("login.forgot_password")}</h1>
                                <p>{t("login.forgot_password_message")}</p>
                            </div>
                            <Form
                                onSubmit={() => {
                                    onSubmit(email);
                                }}
                            >
                                {isSent && <div className={"sent-message"}>
                                    {t("login.sent_message")}
                                </div>}
                                <div className='input'>
                                    <input onChange={onEmailChange} type="text" maxLength={255} placeholder={t("intake.email")}/>
                                </div>
                                <div className='request-to-login-button'>
                                    <div className='fill-button'>
                                        <button type={"submit"}>{t("login.send")}</button>
                                    </div>
                                </div>
                                <div className='back-to-login'>
                                    <Link to='/login'>{t("login.back_to_login")}</Link>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
