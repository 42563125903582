import React, { useEffect, useState } from "react";
import "./signup-accreditations.scss";
import { useAppDispatch } from "../../../app/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { showErrorPopup } from "../../../hooks/slices/snaccSlice";
import { usePostSignupAccreditationsMutation } from "../../../hooks/slices/signupSlice";
import { useTranslation } from "react-i18next";
import { Form } from "../../../components/form/Form";
import { Loader } from "../../../components/loader";
import { AccreditationsTree } from "../../savedSearchSettings/accreditations";
import {CodeBranch, CodeType, CodeWithClass} from "../../../types/code";
import { RegionCodeTree } from "../../savedSearchSettings/regionCodes";
import { RegionCodes } from "../../../consts/regionCodes";
import { CodeUtil } from "../../../utils/codes";
import { updateActivityStatus } from "../../../hooks/slices/userSlice";
import { useSignupErrorHandler } from "../SignupErrorHandler";
import { useNavigateToOpportunitiesIfSignupAlreadyFinished } from "../SignupFinishedHandler";

export const SignupAccreditations: React.FC = () => {
  useNavigateToOpportunitiesIfSignupAlreadyFinished();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [postSignupAccreditations, { isSuccess, isError, error }] = usePostSignupAccreditationsMutation();
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  let selectedAccreditations: string[] = searchParams.getAll("select");
  let relevantAccreditations: CodeWithClass[] = [];
  for (const s of selectedAccreditations) {
    let accreditation: CodeWithClass = CodeUtil.convertStringToCodeWithClass(s);
    relevantAccreditations.push(accreditation);
    CodeUtil.getCodeWithClassLowerClasses(accreditation).forEach((c) => relevantAccreditations.push(c));
  }
  relevantAccreditations = Array.from(new Set(relevantAccreditations));
  const onSubmit = (accreditations: CodeWithClass[], regionCodes: CodeBranch[]) => {
    setShowLoader(true);
    postSignupAccreditations({ accreditationCodes: accreditations, regionCodes: regionCodes });
  };
  const navigateOrShowPopup = useSignupErrorHandler();

  useEffect(() => {
    if (isSuccess) {
      setShowLoader(false);
      dispatch(updateActivityStatus({ activityStatus: "ACTIVE" }));
        if (searchParams.get("tenderUuid") != null) navigate("/tender/" + searchParams.get("tenderUuid"));
        else navigate("/opportunities?publication_date=last_week");
    } else if (isError && error) {
      setShowLoader(false);
      navigateOrShowPopup(error);
    }
  }, [isSuccess, isError, error]);
  return (
    <>
      {showLoader && <Loader />}
      <SignupAccreditationsForm relevantAccreditations={relevantAccreditations} onSubmit={onSubmit} />
    </>
  );
};

interface SignupAccreditationsFormProps {
    onSubmit: (accreditations: CodeWithClass[], regionCodes: CodeBranch[]) => void;
    relevantAccreditations: CodeWithClass[];
}

const SignupAccreditationsForm: React.FC<SignupAccreditationsFormProps> = ({relevantAccreditations, onSubmit}) => {
    const {t} = useTranslation();
    const regionCodesToShow: string[] = RegionCodes.filter(r => r.startsWith("BE"));
    const [selectedAccreditations, setSelectedAccreditations] = useState<CodeWithClass[]>(relevantAccreditations);
    const [selectedRegionCodes, setSelectedRegionCodes] = useState<CodeBranch[]>([{code: "BE", inclusive: true, type: CodeType.NUTS}]);
    const [showLoader, setShowLoader] = useState(false);
    const submit = () => {
       setShowLoader(true);
       onSubmit(selectedAccreditations, selectedRegionCodes);
    }
    return <div>
        {showLoader && <Loader />}
        <div className='layout-main-banner'>
                <Form onSubmit={submit}>
                    <div className='container'>
                        <div className='intake-accreditations'>
                                <h1>{t("intake.you_have_accreditations")}</h1>
                                <div className='intake-box'>
                                    <AccreditationsTree accreditationsToShow={relevantAccreditations}
                                                        selectedAccreditations={selectedAccreditations}
                                                        showAll={true}
                                                        setSelectedAccreditations={setSelectedAccreditations}/>
                                </div>
                                <h1>{t("intake.select_regions")}</h1>
                                <div className='intake-box'>
                                    <div className="region-all-content-alignment">
                                        <div className="radio-region-alignment">
                                            <RegionCodeTree
                                                regionCodesToShow={regionCodesToShow}
                                                selectedCodes={selectedRegionCodes}
                                                setSelectedCodes={setSelectedRegionCodes}
                                                setUnsavedChanges={() => {}}
                                                showAll={false}
                                            />
                                        </div>
                                    </div>

                                </div>
                            <div className='button-center-alignment'>
                                    <div className={(selectedRegionCodes.length > 0 && selectedAccreditations.length > 0) ? "fill-button" : "fill-button disabled"}>
                                        <button id="submit" type="submit" data-testid={"signup-accreditations-submit-button"}>{t("intake.next")}</button>
                                    </div>
                                </div>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
}



