import {useMeasure} from "@react-hookz/web";
import {useEffect, useState} from "react";
import {animated, useSpring} from "react-spring";
import {SearchIcon} from "../../components/icons";

export interface FormContainerProps {
    formName?: string;
}

export const FormContainer: React.FC<FormContainerProps> = ({formName, children}) => {
    return (
        <section>
            {formName ? <h3>{formName}</h3> : null}
            {children}
        </section>
    );
};

export interface FormProps {
    onSubmit?: React.FormEventHandler<HTMLFormElement>;
}

export const Form: React.FC<FormProps> = ({onSubmit, children}) => {
    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                if (onSubmit) {
                    onSubmit(e);
                }
            }}
        >
            {children}
        </form>
    );
};

export interface FormSubmitButtonProps {
    prompt: string;
}

export const FormSubmitButton: React.FC<FormSubmitButtonProps> = ({prompt}) => {
    return <button type="submit">{prompt}</button>;
};

export interface FormGroupProps {
    groupName?: string;
}

export const FormGroup: React.FC<FormGroupProps> = ({groupName, children}) => {
    return (
        <>
            {groupName ? <div>{groupName}</div> : null}
            {children}
        </>
    );
};

export interface FormGroupCollapsibleProps {
    groupName: string;
}

export const FormGroupCollapsible: React.FC<FormGroupCollapsibleProps> = ({groupName, children}) => {
    // use a fancy little spring effect when groupname is clicked
    // 1. measure the height of the section we're going to animate
    const [measurements, ref] = useMeasure<HTMLDivElement>();
    // 2. set some state re: whether the section is expanded or not
    const [expanded, setExpanded] = useState(false);
    // 3. use a spring to expand or contract the section
    const initialHeight = 0; // start closed
    const [{height}, setHeightSpring] = useSpring(() => ({height: initialHeight}));
    useEffect(() => {
        // if the spring is expanded, take up the height of the contained div element,
        // otherwise (if it's not expanded) just use the initial height defined above.
        setHeightSpring.start({height: expanded ? measurements?.height || initialHeight : initialHeight});
    }, [initialHeight, measurements, expanded, setHeightSpring]);
    // 4. create an on-click handler that toggles between expanded or not expanded
    const onClick: React.MouseEventHandler<HTMLDivElement> = () => {
        setExpanded(!expanded);
    };

    return (
        <div>
            <div onClick={onClick}>
                <i aria-hidden="true"/>
                {groupName}
            </div>
            <animated.div style={{height}}>
                <div ref={ref}>
                    {children}
                </div>
            </animated.div>
        </div>
    );
};

export const FormRow: React.FC<{}> = ({children}) => {
    return <div>{children}</div>;
};

interface TextInputProps {
    id: string;
    label: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    value?: string | number | readonly string[] | undefined;
    required?: boolean;
}

export const TextInput: React.FC<TextInputProps> = ({id, label, onChange, value, required = false}) => {
    return (
        <div>
            <label htmlFor={id}>
                {label}
                {required ? "*" : ""}
            </label>
            <input type="text" id={id} name={id} onChange={onChange}/>
        </div>
    );
};

export const TextInputGreyed: React.FC<TextInputProps> = ({id, label, onChange, value, required = false}) => {
    return (
        <div>
            <label htmlFor={id}>
                {label}
                {required ? "*" : ""}
            </label>
            <input
                type="text"
                id={id}
                name={id}
                value={value}
                onChange={onChange}
                disabled={true}
            />
        </div>
    );
};

export const PasswordInput: React.FC<TextInputProps> = ({id, label, onChange, value, required = false}) => {
    return (
        <div>
            <label htmlFor={id}>
                {label}
                {required ? "*" : ""}
            </label>
            <input type="password" id={id} name={id} onChange={onChange}/>
        </div>
    );
};

export const SearchBar: React.FC<TextInputProps> = ({id, label, onChange, value, required = false}) => {
    return (
        <div>
      <span>
        <SearchIcon/>
      </span>
            <input

                type="text"
                id={id}
                name={id}
                placeholder={label}
                onChange={onChange}
                value={value}
            />
        </div>
    );
};

export interface DatePickerProps {
    id: string;
    label: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    value?: string | number | readonly string[] | undefined;
    required?: boolean;
    disabled?: boolean;
}

export const DatePicker: React.FC<DatePickerProps> = ({id, label, onChange, value, disabled = false}) => {

    return (
        <div>
            <label htmlFor={id}>
                {label}
            </label>
            <input

                type="date"
                id={id}
                name={id}
                onChange={onChange}
                value={value}
                disabled={disabled}
            />
        </div>
    );
};

export const RadioRow: React.FC<{}> = ({children}) => {
    return <div>{children}</div>;
};

export interface RadioProps {
    id: string;
    label: string;
    name: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    value?: string | number | readonly string[] | undefined;
}

export const Radio: React.FC<RadioProps> = ({id, label, name, onChange, value}) => {
    return (
        <div>
            <input type="radio" id={id} name={name} onChange={onChange} value={value}></input>
            <label htmlFor={id}>
                {label}
            </label>
        </div>
    );
};
