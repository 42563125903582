import React, {useEffect, useState} from "react";
import "./signup-countries.scss";
import {useAppDispatch} from "../../../app/hooks";
import {useNavigate, useSearchParams} from "react-router-dom";
import {showCustomInfoPopup, showErrorPopup} from "../../../hooks/slices/snaccSlice";
import {usePostSignupCountriesMutation} from "../../../hooks/slices/signupSlice";
import {useTranslation} from "react-i18next";
import {CountriesGrid} from "./CountriesGrid";
import {useGetAllFeaturesQuery} from "../../../hooks/slices/currentPlanSlice";
import {ErrorPage} from "../../error";
import {Feature} from "../../../consts/features";
import {FeatureUtil} from "../../../utils/features";
import {Loader} from "../../../components/loader";
import {SearchParamUtil} from "../../../utils/searchparams";
import { useSignupErrorHandler } from "../SignupErrorHandler";
import { useNavigateToOpportunitiesIfSignupAlreadyFinished } from "../SignupFinishedHandler";

export const SignupCountries: React.FC = () => {
  useNavigateToOpportunitiesIfSignupAlreadyFinished();
  const dispatch = useAppDispatch();

  const [
    postSignupCountries,
    { isSuccess: postCountriesIsSuccess, isError: signupCountriesIsError, error: signupCountriesError },
  ] = usePostSignupCountriesMutation();
  const {
    data: allFeatures,
    isSuccess: featuresFetched,
    isLoading: featuresLoading,
    isError: fetchingFeaturesIsError,
    error: fetchingFeaturesError,
  } = useGetAllFeaturesQuery();
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const onSubmit = (countries: string[]) => {
    setShowLoader(true);
    postSignupCountries({ countries });
  };
  const navigateOrShowPopup = useSignupErrorHandler();

  useEffect(() => {
    if (postCountriesIsSuccess) {
      setShowLoader(false);
      navigate("/signup-curated-profiles" + SearchParamUtil.passOnSearchParams(searchParams));
    } else if (signupCountriesIsError && signupCountriesError) {
      setShowLoader(false);
      navigateOrShowPopup(signupCountriesError);
    }
  }, [postCountriesIsSuccess, signupCountriesIsError, signupCountriesError]);
  if (featuresLoading || !allFeatures || !allFeatures.features) return <></>;
  if (fetchingFeaturesIsError && fetchingFeaturesError) return <ErrorPage error={fetchingFeaturesError} />;
  return (
    <>
      {showLoader && <Loader />}
      <SignupCountriesForm
        allPublisherFeatures={allFeatures.features.filter((f) => FeatureUtil.isPublisherFeature(f))}
        onSubmit={onSubmit}
      />
    </>
  );
};

interface SignupCountriesFormProps {
    allPublisherFeatures: Feature[];
    onSubmit: (countries: string[]) => void;
}

const SignupCountriesForm: React.FC<SignupCountriesFormProps> = ({allPublisherFeatures, onSubmit}) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const [selectedPublishers, setSelectedPublishers] = useState<Feature[]>(allPublisherFeatures.filter(f => f.name === 'PUBLISHERS_BE'));
    function updatePublisherFeatures(publisherFeature: Feature, isSelected: boolean){
        if (isSelected) {
            if (selectedPublishers.length === 6) dispatch(showCustomInfoPopup(t("intake.cant_select_more_than_six_countries")));
            else setSelectedPublishers([...selectedPublishers, publisherFeature])
        }
        else {
            if (selectedPublishers.length === 1) dispatch(showCustomInfoPopup(t("intake.must_select_at_least_one_country")));
            else setSelectedPublishers(selectedPublishers.filter(f => f.name !== publisherFeature.name));
        }
    }
    const submit = () => {
        let countries: string[] = [];
        for(const p of selectedPublishers){
            let country = FeatureUtil.getCountryIfPublisherFeature(p);
            if(country) countries.push(country);
        }
        if (countries.length > 0) onSubmit(countries)
        else onSubmit(["BE"]);
    }
    return <div>
        <div className='layout-main-banner'>
                {/*<Form onSubmit={submit}>*/}
                    <div className='container'>
                        <div className='intake-third-alignment'>
                            <div className='intake-box'>
                                <CountriesGrid selectedPublisherFeatures={selectedPublishers}
                                               allPublisherFeatures={allPublisherFeatures}
                                               updatePublisherFeatures={updatePublisherFeatures}
                                               showPrices={false}
                                />
                            </div>
                            <div className='button-center-alignment'>
                                <div className={selectedPublishers.length > 0 ? "fill-button" : "fill-button disabled"}>
                                    <button id="submit" type="submit" onClick={submit} data-testid={"signup-countries-submit-button"}>{t("intake.next")}</button>
                                </div>
                                {/*<div className='outline-button'>*/}
                                {/*    <button>{t("intake.skip")}</button>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                {/*</Form>*/}
            </div>
        </div>
}



