import { ResourceKey } from "i18next";

export const ContractingAuthorityTypes: ResourceKey = {
    EU_INSTITUTION: "Institution/agence européenne ou organisation internationale",
    MINISTRY: "Ministère ou toute autre autorité nationale ou fédérale, y compris leurs subdivisions régionales ou locales",
    UTILITIES: "Secteurs spéciaux",
    BODY_PUBLIC: "Organisme de droit public",
    REGIONAL_AUTHORITY: "Autorité régionale ou locale",
    OTHER: "Autre",
    REGIONAL_AGENCY: "Agence/office régional(e) ou local(e)",
    NATIONAL_AGENCY: "Agence/office national(e) ou fédéral(e)",
    CONT_ENT: "Contracting Entity",
    NOT_CONT_ENT: "Not Contracting Entity",
    BODY_PL: "Organisme de droit public",
    CGA: "Autorité publique centrale",
    BODY_PL_CGA: "Organisme de droit public, contrôlé par une autorité publique centrale",
    PUB_UNDERT_RA: "Entreprise publique, contrôlée par une autorité régionale",
    PUB_UNDERT: "Entreprise publique",
    DEF_CONT: "Contractant du secteur de la défense",
    ORG_SUB: "Organisation qui passe un marché subventionné par un pouvoir adjudicateur",
    INT_ORG: "Organisation internationale",
    LA: "Autorité locale",
    GRP_P_AUT: "Groupement d’autorités publiques",
    ORG_SUB_LA: "Organisation qui passe un marché subventionné par une autorité locale",
    BODY_PL_LA: "Organisme de droit public, contrôlé par une autorité locale",
    EU_INS_BOD_AG: "Institution, organe ou agence de l’UE",
    RL_AUT: "Autorité régionale ou locale",
    RA: "Autorité régionale",
    ORG_SUB_RA: "Organisation qui passe un marché subventionné par une autorité régionale",
    PUB_UNDERT_CGA: "Entreprise publique, contrôlée par une autorité publique centrale",
    BODY_PL_RA: "Organisme de droit public, contrôlé par une autorité régionale",
    SPEC_RIGHTS_ENTITY: "Entité disposant de droits spéciaux ou exclusifs",
    EU_INT_ORG: "Institution/agence européenne ou organisation internationale",
    ORG_SUB_CGA: "Organisation qui passe un marché subventionné par une autorité publique centrale",
    PUB_UNDERT_LA: "Entreprise publique, contrôlée par une autorité locale",

};
