import React from "react";
import { SearchConfiguration } from "../../GenericSearchPage";
import { AwardsCategories } from "./AwardsCategories";
import { FilterResultsPanel } from "./SortResultsDropdown";
import { useTranslation } from "react-i18next";
import { SearchResultsHeaderProps } from "./SearchResultsHeader";

export const ResultsInfoBox: React.FC<SearchResultsHeaderProps> = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={"results-info-box"}>
        <div className="peripheral-buttons-align">
          {props.searchConfiguration.info.showResponseTime && (
            <p data-testid={"search-results-count"} title={props.tenderSearchOutput.totalHits === 10000 ? t("publications.noInfoRefineSearch") : ""}>
              {(props.tenderSearchOutput.totalHits === 10000 ? "10000+" : props.tenderSearchOutput.totalHits) +
                " " +
                t("publications.results")}
            </p>
          )}
        </div>
        <div className="central-buttons-align">
          {props.searchConfiguration.info.showTenderUuidFilterCategories && <AwardsCategories />}
        </div>
        <div className={props.opportunitiesLimit ? "disabled peripheral-buttons-align" : "peripheral-buttons-align"}>
          <FilterResultsPanel {...props} />
        </div>
      </div>
    </>
  );
};

export const ResultsInfoBoxSkeleton: React.FC<SearchConfiguration> = (props) => {
    return <>
        <div className={"results-info-box"}>
            <div>
                {props.info.showResponseTime &&
                    <p></p>
                }
            </div>
            <div className="central-buttons-align">
                {props.info.showTenderUuidFilterCategories && <AwardsCategories/>}
            </div>
            <div className={"peripheral-buttons-align"}>
                {/*{props.info.showSortBy && <FilterResultsPanel/>}*/}
            </div>
        </div>
    </>;
};