import React, { useState } from "react";
import "./navbar.scss";
import TenderWolfLogo from "../../assets/logo/black-logo-row.svg";
import { SkeletonSection } from "../../pages/tenderDetails/main/TenderDetailsView";
import { AccountIcon, CloseIcon, MobileMenuIcon } from "../icons";
import { ErrorPage } from "../../pages/error";
import { FeatureName } from "../../consts/features";
import { RaiseCurrentPlan } from "../../hooks/raiseCurrentPlan";
import { RaiseUser } from "../../hooks/raiseUser";
import { Link, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { FeatureUtil } from "../../utils/features";
import { useTranslation } from "react-i18next";
import { SettingTab } from "../../consts/settingTabs";

export default function Navbar() {
  const { t } = useTranslation();
  const userInStore = RaiseUser();
  const [mobileSearchSidebarOpen, setMobileSearchSidebarOpen] = useState(false);
  const [mobileAccountSidebarOpen, setMobileAccountSidebarOpen] = useState(false);
  const currentPlanInStore = RaiseCurrentPlan();
  let path = window.location.pathname;
  const masquerading = useAppSelector((state) => state.login.masquerading);
  const headerClass = masquerading ? "header-sticky masquerading" : "header-sticky";
  let features = currentPlanInStore.currentPlan?.features;
  if (currentPlanInStore.error) return <ErrorPage error={currentPlanInStore.error} />;
  else if (currentPlanInStore.isLoading || !features || !userInStore.user)
    return <SkeletonSection width={"95%"} height={2} />;
  else
    return (
      <>
        <div className={headerClass}>
          <div className="sub-header-alignment">
            <div className="container">
              <div className="all-content-alignment">
                <div className="menu">
                  <div className="menu-section">
                    {FeatureUtil.hasFeature(features, FeatureName.OPPORTUNITIES_TAB) && (
                      <Link
                        to="/opportunities"
                        className={path === "/opportunities" || path === "/" ? "selected" : ""}
                        data-testid={"navigation-opportunities"}
                      >
                        {t("navigation.opportunities")}
                      </Link>
                    )}
                    {FeatureUtil.hasFeature(features, FeatureName.LEADS_TAB) && (
                      <Link
                        to="/shortlist"
                        className={path === "/shortlist" ? "selected" : ""}
                        data-testid={"navigation-leads"}
                      >
                        {t("navigation.leads")}
                      </Link>
                    )}
                    {FeatureUtil.hasFeature(features, FeatureName.AWARDS_TAB) && (
                      <Link
                        to="/awards"
                        className={path === "/awards" ? "selected" : ""}
                        data-testid={"navigation-awards"}
                      >
                        {t("navigation.awards")}
                      </Link>
                    )}
                    {FeatureUtil.hasFeature(features, FeatureName.SEARCH_TAB) && (
                      <Link
                        to="/search"
                        className={path === "/search" ? "selected space-remove" : "space-remove"}
                        data-testid={"navigation.search"}
                      >
                        {t("navigation.search")}
                      </Link>
                    )}
                  </div>
                  <div className="menu-section">
                    <div className="logo">
                      <Link to="/">
                        <img src={TenderWolfLogo} alt="TenderWolfLogo" />
                      </Link>
                    </div>
                  </div>
                  <div className="menu-section">
                    <Link to="/account" className="profile">
                      <AccountIconWithHover
                        message={userInStore.user.firstName + (masquerading ? " (masquerading)" : "")}
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="mini-view-header-show">
                <div className="mini-view-header">
                  <div className="profile" onClick={() => setMobileAccountSidebarOpen(!mobileAccountSidebarOpen)}>
                    <div style={{ cursor: "pointer" }}>
                      <span>{userInStore.user.firstName}</span>
                    </div>
                  </div>
                  {/*<div className='tab-view-logo'>*/}
                  {/*    <div className='logo'>*/}
                  {/*        <Link to='/'>*/}
                  {/*            <img src={TenderWolfLogoIcon} alt="TenderWolfLogo"/>*/}
                  {/*        </Link>*/}
                  {/*    </div>*/}
                  {/*</div>*/}
                  <div className="tab-menu-icon" onClick={() => setMobileSearchSidebarOpen(!mobileSearchSidebarOpen)}>
                    <MobileMenuIcon />
                  </div>
                  {/*<div className='all-tab-alignment'>*/}
                  {/*    <Link to='/account'>*/}
                  {/*        */}
                  {/*    </Link>*/}
                  {/*    */}
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
        {mobileSearchSidebarOpen && <div className="mobile-sidebar-wrapper"></div>}
        <div
          className={
            mobileSearchSidebarOpen ? "mobile-sidebar mobile-sidebar-show" : "mobile-sidebar mobile-sidebar-hidden"
          }
        >
          <div className="mobile-header">
            <div>
              {/*<Link to='/'>*/}
              {/*    <img src={TenderWolfLogo} alt="TenderWolfLogo"/>*/}
              {/*</Link>*/}
            </div>
            <div onClick={() => setMobileSearchSidebarOpen(false)}>
              <CloseIcon />
            </div>
          </div>
          <div className="mobile-body">
            {FeatureUtil.hasFeature(features, FeatureName.OPPORTUNITIES_TAB) && (
              <Link
                to="/opportunities"
                className={path === "/opportunities" ? "selected" : ""}
                onClick={() => setMobileSearchSidebarOpen(false)}
              >
                {t("navigation.opportunities")}
              </Link>
            )}
            {FeatureUtil.hasFeature(features, FeatureName.LEADS_TAB) && (
              <Link
                to="/shortlist"
                className={path === "/shortlist" ? "selected" : ""}
                onClick={() => setMobileSearchSidebarOpen(false)}
              >
                {t("navigation.leads")}
              </Link>
            )}
            {FeatureUtil.hasFeature(features, FeatureName.AWARDS_TAB) && (
              <Link
                to="/awards"
                className={path === "/awards" ? "selected" : ""}
                onClick={() => setMobileSearchSidebarOpen(false)}
              >
                {t("navigation.awards")}
              </Link>
            )}
            {FeatureUtil.hasFeature(features, FeatureName.SEARCH_TAB) && (
              <Link
                to="/search"
                className={path === "/search" ? "selected space-remove" : "space-remove"}
                onClick={() => setMobileSearchSidebarOpen(false)}
              >
                {t("navigation.search")}
              </Link>
            )}
            {/*<Link to='/account' className={path === '/account' ? 'selected space-remove' : 'space-remove'}*/}
            {/*      onClick={() => setSidebarOpen(false)}>{t("navigation.account")}</Link>*/}
          </div>
        </div>
        {mobileAccountSidebarOpen && <div className="mobile-sidebar-wrapper"></div>}
        <div
          className={
            mobileAccountSidebarOpen ? "mobile-sidebar mobile-sidebar-show" : "mobile-sidebar mobile-sidebar-hidden"
          }
        >
          <div className="mobile-header">
            <div>
              {/*    <Link to='/'>*/}
              {/*        <img src={TenderWolfLogo} alt="TenderWolfLogo"/>*/}
              {/*    </Link>*/}
            </div>
            <div onClick={() => setMobileAccountSidebarOpen(false)}>
              <CloseIcon />
            </div>
          </div>
          <div className="mobile-body">
            <AccountLink
              tab={SettingTab.ACCOUNT}
              setMobileAccountSidebarOpen={setMobileAccountSidebarOpen}
            ></AccountLink>
            <AccountLink
              tab={SettingTab.COMPANY}
              setMobileAccountSidebarOpen={setMobileAccountSidebarOpen}
            ></AccountLink>
            <AccountLink
              tab={SettingTab.INVOICE}
              setMobileAccountSidebarOpen={setMobileAccountSidebarOpen}
            ></AccountLink>
            <AccountLink
              tab={SettingTab.LABELS}
              setMobileAccountSidebarOpen={setMobileAccountSidebarOpen}
            ></AccountLink>
            <AccountLink tab={SettingTab.EMAIL} setMobileAccountSidebarOpen={setMobileAccountSidebarOpen}></AccountLink>
            <AccountLink
              tab={SettingTab.PROFILES}
              setMobileAccountSidebarOpen={setMobileAccountSidebarOpen}
            ></AccountLink>
            <AccountLink
              tab={SettingTab.PASSWORD}
              setMobileAccountSidebarOpen={setMobileAccountSidebarOpen}
            ></AccountLink>
            <AccountLink
              tab={SettingTab.COUNTRIES}
              setMobileAccountSidebarOpen={setMobileAccountSidebarOpen}
            ></AccountLink>
            <AccountLink tab={SettingTab.PLAN} setMobileAccountSidebarOpen={setMobileAccountSidebarOpen}></AccountLink>
            <AccountLink
              tab={SettingTab.FEEDBACK}
              setMobileAccountSidebarOpen={setMobileAccountSidebarOpen}
            ></AccountLink>
            {userInStore.user && userInStore.user.isAdmin && (
              <AccountLink
                tab={SettingTab.MASQUERADE}
                setMobileAccountSidebarOpen={setMobileAccountSidebarOpen}
              ></AccountLink>
            )}
          </div>
        </div>
      </>
    );
}

interface AccountLinkProps {
  tab: SettingTab;
  setMobileAccountSidebarOpen: (v: boolean) => void;
}

const AccountLink: React.FC<AccountLinkProps> = ({ tab, setMobileAccountSidebarOpen }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const getIsSelected = () => {
    if (searchParams.get("tab") === tab) return true;
  };
  return (
    <Link
      to={"/account?tab=" + tab}
      className={getIsSelected() ? "selected space-remove" : "space-remove"}
      onClick={() => setMobileAccountSidebarOpen(false)}
    >
      {t("settings." + tab)}
    </Link>
  );
};

interface AccountWithHoverProps {
  message: string;
}

const AccountIconWithHover: React.FC<AccountWithHoverProps> = ({ message }) => {
  return (
    <div>
      <div className={"account-icon"}>
        <AccountIcon />
      </div>
      <div>{message}</div>
    </div>
  );
};
