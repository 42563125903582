import React from "react";
import "../results/searchResults.scss";
import { SearchFilter } from "../../../consts/SearchFilter";
import {
    GenericSearchPage,
    SearchConfiguration,
    SearchResultsConfiguration,
    SearchSidebarConfiguration
} from "../GenericSearchPage";
import { SearchFilterState } from "../../../hooks/slices/tenderSearchSlice";
import { ErrorPage } from "../../error";
import { SearchType } from "../../../consts/searchType";
import { Feature, FeatureName } from "../../../consts/features";
import { RaiseCurrentPlan } from "../../../hooks/raiseCurrentPlan";
import { DateToSortBy } from "../../../consts/dateToSortBy";
import { SearchFilterUtil } from "../../../utils/searchFilters";
import { FeatureUtil } from "../../../utils/features";
import { TenderCardConfiguration } from "../results/body/TenderCard";
import { useTranslation } from "react-i18next";
import { SortBy } from "../../../consts/SortBy";

export const ShortlistSearchPage: React.FC = () => {
    const {t} = useTranslation();
    let preSelectedFilters: SearchFilter[] = [];
    const features: Feature[] | undefined = RaiseCurrentPlan().currentPlan?.features;
    const hiddenFilters: SearchFilter[] = getHiddenFilters();
    const lockedFilters: SearchFilter[] = [...hiddenFilters];
    const tenderCardConfiguration: TenderCardConfiguration = {
        showProgress: true,
        showDescription: false,
        showCompanies: false,
        showCueHighlights: false,
        showQueryHighlights: false,
        showAddendumHighlights: true,
        showLatestPublicationTypeInUpperRightCorner: false,
        showPublicationDetails: false,
        showTags: true,
        showLeadsIcon: true,
        showFollowIcon: true,
        showDeleteOpportunityIcon: false,
    }
    const searchViewConfiguration: SearchResultsConfiguration = {
        showExportButton:true,
        showResponseTime:true,
        showFilterButtons: true,
        showLabelAggregations: true,
        showCueAggregations: false,
        hiddenFilters: hiddenFilters,
        lockedFilters: lockedFilters,
    }
    const initialSearchFilterState: SearchFilterState = {
        query:"", //this is overwritten if there is a previous input in the store
        page: 0,
        pageSize: 10,
        sortBy: SortBy.DATE,
        dateToSortBy: DateToSortBy.PUBLICATION_DATE_DESC,
        selectedFilters: preSelectedFilters,
        searchShortlist: true,
    }
    const searchSidebarConfiguration: SearchSidebarConfiguration = {
        queryType: SearchType.SHORTLIST,
        queryPlaceHolder: t('searchFilterTags.search'),
        showLabels: true,
        hiddenFilters: hiddenFilters,
        lockedFilters: lockedFilters,
    }
    const searchConfiguration: SearchConfiguration = {
        sidebar: searchSidebarConfiguration,
        info: searchViewConfiguration,
        tenderCard: tenderCardConfiguration,
    }
    if(features !== undefined && !FeatureUtil.hasFeature(features, FeatureName.LEADS_TAB)) {
        return <ErrorPage errorCode={403}></ErrorPage>
    } else return <GenericSearchPage
        initialSearchFilterState={initialSearchFilterState}
        searchConfiguration={searchConfiguration}/>
}

function getHiddenFilters(): SearchFilter[]{
    let hiddenFilters: SearchFilter[] = SearchFilterUtil.getFieldGroupFilters();
    SearchFilterUtil.getPublicationDateFilters().forEach(f => hiddenFilters.push(f))
    SearchFilterUtil.getAwardOrOpeningReportPublicationDateFilters().forEach(f => hiddenFilters.push(f))
    return hiddenFilters;
}




