import {SearchFilter} from "./SearchFilter";

export enum FilterGroup {

    OPPORTUNITY="OPPORTUNITY",
    AWARD="AWARD"


}

export namespace FilterGroupParser {

    export function findAll(): FilterGroup[]{
        return Object.values(FilterGroup);
    }

    export function fromString(s: string): FilterGroup{
        return (FilterGroup as any)[s.toUpperCase()];
    }

}
