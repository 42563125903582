import { ResourceKey } from "i18next";

export const ContractingAuthorityMainActivities: ResourceKey = {
    GENERAL_PUBLIC_SERVICES: "General public services",
    EDUCATION: "Education",
    RAILWAY_SERVICES: "Railway services",
    PRODUCTION_TRANSPORT_DISTRIBUTION_GAS_HEAT: "Production, transport and distribution of gas and heat",
    ELECTRICITY: "Electricity",
    HEALTH: "Health",
    PUBLIC_ORDER_AND_SAFETY: "Public order and safety",
    URBAN_RAILWAY_TRAMWAY_TROLLEYBUS_BUS_SERVICES: "Urban railway, tramway, trolleybus or bus services",
    AIRPORT_RELATED_ACTIVITIES: "Airport-related activities",
    WATER: "Water",
    POSTAL_SERVICES: "Postal services",
    DEFENCE: "Defence",
    RECREATION_CULTURE_AND_RELIGION: "Recreation, culture and religion",
    HOUSING_AND_COMMUNITY_AMENITIES: "Housing and community amenities",
    PORT_RELATED_ACTIVITIES: "Port-related activities",
    OTHER: "Other",
    SOCIAL_PROTECTION: "Social protection",
    EXPLORATION_EXTRACTION_GAS_OIL: "Extraction of gas and oil",
    ENVIRONMENT: "Environment",
    EXPLORATION_EXTRACTION_COAL_OTHER_SOLID_FUEL: "Exploration and extraction of coal and other solid fuels",
    URBAN_RAILWAY_OR_BUS_SERVICES: "Urban railway or bus services",
    ECONOMIC_AFFAIRS: "Economic affairs",
    RCR: "Recreation, culture and religion",
    HC_AM: "Housing and community amenities",
    PUB_OS: "Public order and safety",
    ECON_AFF: "Economic affairs",
    ENV_PRO: "Environmental protection",
    SOC_PRO: "Social protection",
    GEN_PUB: "General public services",
    POST: "Postal services",
    AIRPORT: "Airport-related activities",
    GAS_OIL: "Extraction of gas or oil",
    URTTB: "Urban railway, tramway, trolleybus or bus services",
    SOLID_FUEL: "Exploration or extraction of coal or other solid fuels",
    RAIL: "Railway services",
    PORT: "Port-related activities",
    GAS_HEAT: "Production, transport or distribution of gas or heat",





};
