import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

export interface NavigateProps {
    location: string;
    external?: boolean;
}

export const Navigate: React.FC<NavigateProps> = ({location, external}) => {
    let navigate = useNavigate();
    useEffect(() => {
        external ? window.location.assign(location) : navigate(location, {replace: true, state: {absolute: external}});
    }, [])
    return <></>
};