import { ResourceKey } from "i18next";

export const Preferences: ResourceKey = {
	basicDetails: "Détails de base",
	firstName: "prénom",
	emailAddress: "adresse e-mail",
	language: "langue",
	username: "nom d'utilisateur",
	password: "mot de passe",
	addressDetails: "Coordonnées",
	company: "entreprise",
	street: "rue",
	number: "numéro de maison",
	city: "ville",
	zip: "code postal",
	phone: "numéro de téléphone",
	update: "actualiser les paramètres",
	unsubscribe_successful: "Vous êtes désabonné",
	unsubscribe_error: "Utilisateur inconnu",
};
