import { ResourceKey } from "i18next";

export const Languages: ResourceKey = {
  DE:"Duits",
  FR:"Frans",
  NL:"Nederlands",
  EN:"Engels",
  ALL: "Alle",

  EL: "Grieks",
  ES: "Spaans",
  ET: "Ests",
  FI: "Fins",
  GA: "Iers",
  HR: "Kroatisch",
  HU: "Hongaars",
  IT: "Italiaans",
  LT: "Litouws",
  LV: "Lets",
  MT: "Maltees",
  PL: "Pools",
  PT: "Portugees",
  RO: "Roemeens",
  SK: "Slowaaks",
  SL: "Sloveens",
  SV: "Zweeds",

  BG: "Bulgaars",
  CS: "Tsjechisch",
  DA: "Deens",
};
