import { FetchBaseQueryError, skipToken } from "@reduxjs/toolkit/query";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { SerializedError } from "@reduxjs/toolkit";
import { GetClientParams, PlanDto, updateCurrentPlan, useGetCurrentPlanQuery } from "./slices/currentPlanSlice";
import { useEffect } from "react";

export interface CurrentPlanInStore {
  currentPlan?: PlanDto;
  isLoading: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
}

export function RaiseCurrentPlan(): CurrentPlanInStore {
    const dispatch = useAppDispatch();
    const clientUuid = useAppSelector((state) => state.user.clientUuid);
    let getClientParams: GetClientParams | undefined = clientUuid ? {uuid: clientUuid}:undefined;
    const currentPlan: PlanDto | undefined = useAppSelector((state) => state.currentPlan.currentPlan);
    let featuresNeedToBeFetched = currentPlan === undefined;
    let fetchFeaturesParams = featuresNeedToBeFetched ? getClientParams : undefined;
    const {data, isSuccess, isLoading, isError, error} = useGetCurrentPlanQuery(fetchFeaturesParams ?? skipToken);
    useEffect(() => {
        if (featuresNeedToBeFetched && isSuccess && data) {
            dispatch(updateCurrentPlan({currentPlan: data}));
        }
    }, [data, dispatch, featuresNeedToBeFetched, isSuccess]);

    return {
        currentPlan: currentPlan,
        isLoading: isLoading,
        error: error
    }
}
