import React from "react";
import { useAppSelector } from "../../../../app/hooks";
import { LabelTenderDto } from "../../../../hooks/slices/labelSlice";
import { LabelCategory } from "../../../../types/label";
import { TenderSearchHit } from "../../../../hooks/slices/tenderSearchSlice";
import { UserInteraction } from "../../../../hooks/slices/userSlice";
import { UserInteractionType } from "../../../../consts/UserInteractionType";
import { RaiseUser } from "../../../../hooks/raiseUser";
import { TenderCard, TenderCardConfiguration, TenderCardSkeleton } from "./TenderCard";

interface SearchResultsBodyProps {
  searchHits: TenderSearchHit[];
  languageIso: string;
  cardConfiguration: TenderCardConfiguration;
}

export const SearchResultsBody: React.FC<SearchResultsBodyProps> = (props) => {
    const authState = useAppSelector((state) => state.login);

    // let getUserParams: GetUserParams | undefined = undefined;
    const userInStore = RaiseUser();
    // const userTenderInteractions = useAppSelector((state) => state.tenderSearch.userTenderInteractions);
    let labels: LabelTenderDto[] | undefined = useAppSelector((state) => state.tenderSearch.responseLabels);
    const getLabels = (tenderUuid: string): LabelTenderDto[] => {
        if (labels !== undefined) return labels.filter(l => l.tenderUuid === tenderUuid);
        else return [];
    }
    const getLabelCategories = (): LabelCategory[] => {
        return (userInStore.user && userInStore.user.labelCategories !== undefined) ? userInStore.user.labelCategories : [];
    }

    if (authState.userID) {
        return <TenderCardsSectionWithData userUuid={authState.userID} searchHits={props.searchHits}
                                           languageIso={props.languageIso}
                                           cardConfiguration={props.cardConfiguration}
                                           userInteractions={userInStore.user?.userInteractions ?? []}
                                           getLabels={getLabels}
                                           getLabelCategories={getLabelCategories}
        />
    } else return <></>;
}

interface TenderCardsSectionWithDataProps {
    userUuid: string;
    searchHits: TenderSearchHit[];
    languageIso: string;
    cardConfiguration: TenderCardConfiguration;
    userInteractions: UserInteraction[];
    getLabels: (tenderUuid: string) => LabelTenderDto[];
    getLabelCategories: () => LabelCategory[];
}

const TenderCardsSectionWithData: React.FC<TenderCardsSectionWithDataProps> = (props) => {
    let leads = props.userInteractions.filter(i => i.interactionType === UserInteractionType.MAKE_LEAD).map(i => i.uuid)
    let isDeletedOpportunities = props.userInteractions.filter(i => i.interactionType === UserInteractionType.DELETE_OPPORTUNITY).map(i => i.uuid)
    return <>
        <div className='search-main-card-show'>
            {props.searchHits.map((hit, i) =>
                <TenderCard key={hit.tender.uuid}
                            tenderSearchHit={hit}
                            languageIso={props.languageIso}
                            configuration={props.cardConfiguration}
                            isLead={leads.includes(hit.tender.uuid)}
                            isDeletedOpportunity={isDeletedOpportunities.includes(hit.tender.uuid)}
                            getLabels={props.getLabels}
                            getLabelCategories={props.getLabelCategories}
                />
            )}
        </div>
    </>
}



export const SearchResultsBodySkeleton = () => {
    return <>
        <div className='search-main-card-show'>
            <TenderCardSkeleton/>
            <TenderCardSkeleton/>
            <TenderCardSkeleton/>
            <TenderCardSkeleton/>
        </div>
    </>
};



