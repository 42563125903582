import { ResourceKey } from "i18next";

export const Login: ResourceKey = {
	welcome_back: "Bienvenue !",
	welcome_back_message: "Veuillez remplir vos données de connexion",
	forgot_password: "Vous avez oublié votre mot de passe ?",
	forgot_password_message: "Entrez l'adresse e-mail que vous avez enregistrée auprès de TenderWolf.",
	send: "Envoyer",
	sent_message: "E-mail envoyé si l'adresse e-mail est enregistrée chez nous",
	reset_password: "Réinitialiser votre mot de passe",
	back_to_login: "Retour à la connexion",
	no_account: "Vous n'avez pas de compte ?",
	register: "Enregistrez",
	logIn: "Connexion",
	wrong_credentials: "Adresse e-mail ou mot de passe incorrect",
};