import React, {useState} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {SearchFilter} from "../../consts/SearchFilter";
import {updateSearchFilters} from "../../hooks/slices/tenderSearchSlice";

interface ToggleProps {
    label?: string;
    initialValue: boolean;
    toggle: () => boolean;
    frozen?: boolean;
    testId?: string;
}

export const Toggle: React.FC<ToggleProps> = (props) => {
    const [toggleValue, setToggleValue] = useState(props.initialValue);
    const changeValue = () => {
        if (!props.frozen) setToggleValue(props.toggle());
    }
    return <>
        <div className="checkbox-singleton-alignment">
            <div>
                <span>{props.label}</span>
            </div>
            <div>
                <label className="switch">
                    <input id="checkboxinp" type="checkbox"
                           checked={toggleValue}
                           onChange={changeValue}
                           data-testid={props.testId}
                    ></input>
                    <div className="slider round"></div>
                </label>
            </div>
        </div>
    </>

}