import React, {useState} from "react";
import "./unsubscribe.scss";
import { useTranslation } from "react-i18next";
import { QueryParameters } from "../tenderDetails/main/TenderDetailsView";
import { ErrorPage } from "../error";
import {usePostUnsubscribeMutation} from "../../hooks/slices/userEmailPageSlice";
import {Loader} from "../../components/loader";

export const UnsubscribePage: React.FC = () => {
  const { t } = useTranslation();
  let queryParameters = QueryParameters();
  let [queryHasBeenPerformed, setQueryHasBeenPerformed] = useState(false);
  let userUuid: string | undefined = queryParameters.uuid;
  const [postUnsubscribe, {isLoading, isSuccess, isError, error}] = usePostUnsubscribeMutation();
  if (userUuid == null) return <ErrorPage errorCode={404} />;
  else if(!queryHasBeenPerformed){
      postUnsubscribe({uuid: userUuid});
      setQueryHasBeenPerformed(true);
  }

    return (
      <div>
        <div className="layout-main-banner">
          <div className="container">
            <div className="layout-top-alignment">
              <div className="login-box">
                  {isLoading && <Loader></Loader>}
                  {isSuccess &&
                    <div className="page-title">
                        <h1>{t("preferences.unsubscribe_successful")}</h1>
                    </div>}
                  {isError &&
                      <div className="page-title">
                          <h1>{t("preferences.unsubscribe_error")}</h1>
                      </div>}

              </div>
            </div>
          </div>
        </div>
      </div>
    );
};
