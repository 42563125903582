import './App.css';
import '../styles/mixins/main.scss';
import Layout from '../components/layout';
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <>
     {/*<BrowserRouter>*/}
     <Layout />
    {/*</BrowserRouter>*/}
    </>
  );
}

export default App;
