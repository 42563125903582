import "./paginate.scss";
import { ArrowLeftDoubleIcon, ArrowRightDoubleIcon } from "../icons";

export interface PaginateProps {
  currentPage: number;
  totalHits: number;
  itemsPerPage: number;
  setPage: (page: number) => void;

  maxPages?: number;
}

export const Paginate: React.FC<PaginateProps> = (props) => {
  // zero indexed
  let totalPages = Math.ceil(props.totalHits / props.itemsPerPage);
  if(props.maxPages && totalPages >= props.maxPages) totalPages = props.maxPages;
  if(totalPages <= 1) return (<></>);
  else if (totalPages > 999) totalPages = 999; //pages of 10, max result size 10000

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  const pageMinusOne = props.currentPage - 1;
  const pageMinusTwo = props.currentPage - 2;
  const pagePlusOne = props.currentPage + 1;
  const pagePlusTwo = props.currentPage + 2;
  return (
      <>
    <div className="pagination-top-alignment">
      <div className="main-pagination-box">
        {pageMinusTwo >= 1 && <div onClick={() => {props.setPage(0); scrollToTop();}}><ArrowLeftDoubleIcon/></div>}
        {pageMinusTwo >= 0 && <div onClick={() => {props.setPage(pageMinusTwo); scrollToTop();}}>{pageMinusTwo+1}</div>}
        {pageMinusOne >= 0 && <div onClick={() => {props.setPage(pageMinusOne); scrollToTop();}}>{pageMinusOne+1}</div>}
        <div className={"current-page"}>{props.currentPage+1}</div>
        {pagePlusOne < totalPages && <div onClick={() => {props.setPage(pagePlusOne); scrollToTop();}}>{pagePlusOne+1}</div>}
        {pagePlusTwo < totalPages && <div onClick={() => {props.setPage(pagePlusTwo); scrollToTop();}}>{pagePlusTwo+1}</div>}
        {pagePlusTwo < totalPages && <div onClick={() => {props.setPage(totalPages-1); scrollToTop();}}><ArrowRightDoubleIcon/></div>}
      </div>
    </div>
  </>
  );
};

