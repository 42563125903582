export enum FieldGroup {
  TITLE = "TITLE ",
  DESCRIPTION = "DESCRIPTION",
  ADDENDA = "ADDENDA",
  SPECIFICATIONS = "SPECIFICATIONS",
  FULLCONTENT = "FULLCONTENT",
  AWARD_COMPANY_NAMES = "AWARD_COMPANY_NAMES",
  BID_COMPANY_NAMES = "BID_COMPANY_NAMES",
  CONTRACTING_AUTHORITY = "CONTRACTING_AUTHORITY",
}

export namespace FieldGroupParser {
  export function findAll(): FieldGroup[] {
    return Object.values(FieldGroup);
  }

  export function fromString(s: string): FieldGroup {
    return (FieldGroup as any)[s.toUpperCase()];
  }
}
