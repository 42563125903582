import {
    resetDeadlineFilter,
    resetPublicationDateFilter, resetSortByTenderUuidFilters,
    updateSearchFilters
} from "../../../../hooks/slices/tenderSearchSlice";
import { SearchFilter } from "../../../../consts/SearchFilter";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../app/hooks";
import { ResetIcon } from "../../../../components/icons";
import {UserInteraction} from "../../../../hooks/slices/userSlice";
import {RaiseUser} from "../../../../hooks/raiseUser";

interface ResetFiltersButtonProps {
    selectedFilters: SearchFilter[];
    hiddenOrLockedFilters: SearchFilter[];
}

export const ResetAllFiltersButton: React.FC<ResetFiltersButtonProps> = ({selectedFilters, hiddenOrLockedFilters}) => {
    let {t} = useTranslation();
    const dispatch = useAppDispatch();
    let userInteractions: UserInteraction[] = RaiseUser().user?.userInteractions ?? [];
    let newSelectedFilters = selectedFilters.filter(f => hiddenOrLockedFilters.includes(f));
    const resetAllFilters = () => {
        dispatch(resetDeadlineFilter());
        dispatch(resetPublicationDateFilter());
        dispatch(resetSortByTenderUuidFilters());
        dispatch(updateSearchFilters({
            query: "",
            selectedFilters: newSelectedFilters,
            regionCodes: [],
            subjectCodes: [],
            accreditations: [],
            userInteractions: userInteractions
        }));
    }
    return <>
        <span onClick={resetAllFilters} className={'reset-filters-icon'}
              title={t("searchFilterTags.RESET_FILTERS")}><ResetIcon/></span>
    </>
}