import {Country, CountryParser} from "../consts/countries";
import {RegionCodes} from "../consts/regionCodes";
import {TFunction} from "i18next";
import {Feature, FeatureName} from "../consts/features";
import {Language, LanguageParser} from "../consts/languages";
import {NL} from "../consts/lang/nl/resourceLanguage";
import {FR} from "../consts/lang/fr/resourceLanguage";
import {SearchFilter, SearchFilterParser} from "../consts/SearchFilter";
import {TenderSearch} from "../hooks/slices/tenderSearchSlice";

export namespace FeatureUtil {
  export function hasFeature(clientFeatures: Feature[] | undefined, featureName: FeatureName): boolean {
    if (clientFeatures !== undefined) {
      for (const feature of clientFeatures) {
        if (featureName === feature.name) return true;
      }
    }
    return false;
  }

  export function getFeatureLimit(clientFeatures: Feature[] | undefined, featureName: FeatureName): number | undefined {
    if (clientFeatures !== undefined) {
      for (const feature of clientFeatures) {
        if (featureName === feature.name) return feature.limit;
      }
    }
    return undefined;
  }

  export function isPublisherFeature(feature: Feature): boolean {
    return feature.name.startsWith("PUBLISHERS_");
  }

  export function getCountryIfPublisherFeature(feature: Feature): Country | undefined {
    if (isPublisherFeature(feature)) return CountryParser.fromString(feature.name.replace("PUBLISHERS_", ""));
    else return undefined;
  }

  export function getCountriesFromPublisherFeatures(features: Feature[]): Country[] {
    const countries: Country[] = [];
    for (const f of features) {
      let country = getCountryIfPublisherFeature(f);
      if (country !== undefined) countries.push(country);
    }
    return countries;
  }

  export function getRelevantRegionCodesFromFeatures(clientFeatures: Feature[] | undefined): string[] {
    let countries = getCountriesFromPublisherFeatures(clientFeatures ? clientFeatures : []);
    const isRelevantRegionCode = (nuts: string) => {
      for (const country of countries) {
        if (country === Country.GR && nuts.startsWith("EL")) return true;
        else if (country !== Country.GR && nuts.startsWith(country)) return true;
      }
      return false;
    };
    return RegionCodes.filter((c) => isRelevantRegionCode(c));
  }

  export function getRelevantLanguagesFromFeatures(clientFeatures: Feature[] | undefined): Language[] {
    let countries: Country[] = getCountriesFromPublisherFeatures(clientFeatures ? clientFeatures : []);
    let relevantLanguages: Language[] = [];
    for (const country of countries) {
      let relevantLanguagesForCountry: Language[] = LanguageParser.isos()
        .filter((iso) => isRelevantLanguage(iso, country))
        .map(LanguageParser.fromString);
      relevantLanguagesForCountry.forEach((l) => relevantLanguages.push(l));
    }
    return Array.from(new Set(relevantLanguages)).sort();
  }

  export function getRelevantLanguageFiltersFromFeatures(clientFeatures: Feature[] | undefined): SearchFilter[] {
    let languages = getRelevantLanguagesFromFeatures(clientFeatures);
    return languages.map((l) => SearchFilterParser.fromString("LANGUAGE_" + l));
  }

  export function isRelevantLanguage(languageIso: string, country: Country) {
    switch (country) {
      case Country.AT:
        return ["DE", "EN"].includes(languageIso);
      case Country.BE:
        return ["NL", "FR", "DE", "EN"].includes(languageIso);
      case Country.BG:
        return ["BG", "EN"].includes(languageIso);
      case Country.CH:
        return ["FR", "DE", "IT", "EN"].includes(languageIso);
      case Country.CY:
        return ["EL", "EN"].includes(languageIso);
      case Country.CZ:
        return ["CS", "EN"].includes(languageIso);
      case Country.DE:
        return ["DE", "EN"].includes(languageIso);
      case Country.DK:
        return ["DA", "EN"].includes(languageIso);
      case Country.EE:
        return ["ET", "EN"].includes(languageIso);
      case Country.ES:
        return ["ES", "EN"].includes(languageIso);
      case Country.FI:
        return ["FI", "EN"].includes(languageIso);
      case Country.FR:
        return ["FR", "EN"].includes(languageIso);
      case Country.GR:
        return ["EL", "EN"].includes(languageIso);
      case Country.HU:
        return ["HU", "EN"].includes(languageIso);
      case Country.HR:
        return ["HR", "EN"].includes(languageIso);
      case Country.IE:
        return ["GA", "EN"].includes(languageIso);
      case Country.IS:
        return languageIso === "EN"; //icelandic publications are published in English
      case Country.IT:
        return ["IT", "EN"].includes(languageIso);
      case Country.LV:
        return ["LV", "EN"].includes(languageIso);
      case Country.LT:
        return ["LT", "EN"].includes(languageIso);
      case Country.LU:
        return ["FR", "DE", "EN"].includes(languageIso);
      case Country.MT:
        return ["MT", "EN"].includes(languageIso);
      case Country.NL:
        return ["NL", "EN"].includes(languageIso);
      case Country.NO:
        return languageIso === "EN"; //norwegian publications are published in English
      case Country.PL:
        return ["PL", "EN"].includes(languageIso);
      case Country.PT:
        return ["PT", "EN"].includes(languageIso);
      case Country.RO:
        return ["RO", "EN"].includes(languageIso);
      case Country.SE:
        return ["SV", "EN"].includes(languageIso);
      case Country.SI:
        return ["SL", "EN"].includes(languageIso);
      case Country.SK:
        return ["SK", "EN"].includes(languageIso);
      case Country.UK:
        return languageIso === "EN";
      default:
        return languageIso.toUpperCase() === country.toUpperCase();
    }
  }

  export function getPriceString(feature: Feature, t: TFunction) {
    return feature.price ? " € " + feature.price.toString() + " / " + t("time.year") : "";
  }

  export function userHasExtraSavedSearchAvailable(
    currentSavedSearches: TenderSearch[] | undefined,
    features: Feature[] | undefined
  ): boolean {
    if (currentSavedSearches === undefined || features === undefined) return false;
    let savedSearchFeature = features.filter((f) => f.name === FeatureName.CUSTOM_USER_PROFILES);
    if (savedSearchFeature.length > 0) {
      if (savedSearchFeature[0].limit != null) return savedSearchFeature[0].limit - currentSavedSearches.length > 0;
      else return true; //meaning there is no limit to the amount of profiles the user can create
    }
    return false;
  }

  export function sortPublisherFeaturesByTranslation(publisherFeatures: Feature[], t: TFunction): Feature[] {
    return publisherFeatures.sort((a, b) =>
      t("countries." + FeatureUtil.getCountryIfPublisherFeature(a)).localeCompare(
        t("countries." + FeatureUtil.getCountryIfPublisherFeature(b))
      )
    );
  }
}