import React from "react";
import moment, {Moment} from "moment";
import {TenderSearchHit} from "../../../../hooks/slices/tenderSearchSlice";
import {useTranslation} from "react-i18next";
import {PublicationInformation, Tender} from "../../../../types/tender";
import {TFunction} from "i18next";
import {DateUtil} from "../../../../utils/date";
import {TenderUtil} from "../../../../utils/tenders";

import {useMediaQuery} from "react-responsive";


interface ProgressBarProps {
    tenderSearchHit: TenderSearchHit;
}

const maxProgressBarElements: number = 8;
export const ProgressBar: React.FC<ProgressBarProps> = ({tenderSearchHit}) => {
    let {t} = useTranslation();
    let progressBarElements: ProgressBarElement[] = getProgressBarElements(tenderSearchHit.tender, t);
    const isMobile = useMediaQuery({maxWidth: 600});
    if (isMobile && progressBarElements.length > 3) {
        //grab first and last two elements, if present
        let limitedProgressBarElements = [progressBarElements[0]];
        limitedProgressBarElements.push(progressBarElements[progressBarElements.length - 2]);
        limitedProgressBarElements.push(progressBarElements[progressBarElements.length - 1]);
        progressBarElements = limitedProgressBarElements;
    }
    return <>
        <div className="main-timeline-content-alignment">
            <div className={'line-background'}></div>
            <div className="events-box">
                {progressBarElements.map((p) => <>
                    <ProgressBarTransparentLine widthPercentage={p.lineProps.widthPercentage}/>
                    <ProgressBarCircle width={p.circleProps.width}
                                       isWhite={p.circleProps.isWhite}
                                       isDashed={p.circleProps.isDashed}
                                       topText={p.circleProps.topText}
                                       bottomText={p.circleProps.bottomText}
                    />
                </>)}
                <ProgressBarTransparentLine widthPercentage={5}/>

            </div>
        </div>
    </>
}

interface ProgressBarElement {
    circleProps: ProgressBarCircleProps;
    lineProps: ProgressBarLineProps;
}

function getProgressBarElements(tender: Tender, t: TFunction): ProgressBarElement[] {
    let elements: ProgressBarElement[] = [];
    let sortedPublications: TenderUtil.SortedPublicationCardProps[] = TenderUtil.getPublicationInformationsSorted(tender);
    sortedPublications = sortedPublications.slice(sortedPublications.length- maxProgressBarElements, sortedPublications.length);
    let today = DateUtil.today();
    let deadlineDateString = DateUtil.convertDateTimeStringToDateString(tender.deadline);
    let deadlineDate: Moment | undefined = deadlineDateString ? DateUtil.getDateMoment(deadlineDateString) : undefined;

    let tenderPublicationDate: Moment = DateUtil.getDateMoment(tender.publicationDate);
    let totalDays = getTotalDays(deadlineDate, tenderPublicationDate, sortedPublications);

    let sortedPublicationEvents: SortedPublicationEvent[] = sortedPublications
        .map(p => ({date: DateUtil.getDateMoment(p.publicationInformation.publicationDate), info: p.publicationInformation, priority: p.priority}));
    sortedPublicationEvents.push({date: today, isToday: true})
    if(deadlineDate) sortedPublicationEvents.push({date: deadlineDate, isDeadlineDate: true, priority: 1}); //priority makes sure deadline is before openingreport
    sortedPublicationEvents.sort(function (a, b) {
        if (a.date.isSame(b.date, 'day') && a.priority !== undefined && b.priority !== undefined) {
            // Priority is only important when dates are the same
            return a.priority > b.priority ? -1 : 1;
        } return a.date > b.date ? -1 : 1;
    }).reverse();

    let dateCounter: Moment | undefined = undefined;
    let eventPriorityCounter: number | undefined = undefined;
    // let tenderLastDay: Moment = getTenderLastDay(deadlineDate, tenderPublicationDate, sortedPublications);
    for (const event of sortedPublicationEvents) {
        let eventDate = event.date;
        let daysBetween = getDaysBetween(dateCounter, eventDate);
        let percentageOfTotalDays = getPercentageOfTotalDaysBetween(dateCounter, eventDate, totalDays);
        //ignore two events on same day if they have the same priority (esp rectifications)
        if (daysBetween === 0 && event.priority === eventPriorityCounter) continue;
        let newElement: ProgressBarElement | undefined = getProgressBarElement(event, percentageOfTotalDays, t);
        if (newElement) elements.push(newElement)
        else console.log("no new element for event: ", event)
        dateCounter = eventDate;
        eventPriorityCounter = event.priority;
    }

    return elements;
}

interface SortedPublicationEvent{
    date: Moment;
    //event type 1: publication
    info?: PublicationInformation;
    priority?: number;
    //event type 2: deadline
    isDeadlineDate?: boolean;
    //event type 3: today
    isToday?: boolean;
}

function getTotalDays(deadlineDate: Moment | undefined, tenderPublicationDate: Moment, sortedPublications: TenderUtil.SortedPublicationCardProps[]): number {
    let tenderLastDay = getTenderLastDay(deadlineDate, tenderPublicationDate, sortedPublications);
    if (tenderLastDay.isAfter(DateUtil.today())) return tenderLastDay.diff(tenderPublicationDate, 'days');
    else return DateUtil.today().diff(tenderPublicationDate, 'days');
}

function getTenderLastDay(deadlineDate: Moment | undefined, tenderPublicationDate: Moment, sortedPublications: TenderUtil.SortedPublicationCardProps[]): Moment {
    if (deadlineDate) return deadlineDate;
    else if (sortedPublications.length > 0) DateUtil.getDateMoment(sortedPublications[sortedPublications.length - 1].publicationInformation.publicationDate);
    return tenderPublicationDate;
}

function getDaysBetween(a: Moment | undefined, b: Moment | undefined): number | undefined {
    if (a && b) return b.diff(a, 'days');
    else return undefined;
}

function getPercentageOfTotalDaysBetween(a: Moment | undefined, b: Moment | undefined, totalDays: number | undefined): number {
    let daysBetween = getDaysBetween(a, b);
    if (daysBetween && totalDays) return (100 / totalDays * daysBetween);
    else return 5; //5% as default width
}

function getProgressBarElement(event: SortedPublicationEvent, percentageOfTotalDays: number, t: TFunction): ProgressBarElement | undefined{

    if (event.isToday) return todayProgressBarElement(percentageOfTotalDays, t);
    else if (event.isDeadlineDate) return deadlineProgressBarElement(t, event.date, percentageOfTotalDays);
    else if(event.info && (event.priority || event.priority === 0)) return publicationProgressBarElement(event.date, event.priority, t, percentageOfTotalDays);
}

function getDateString(date: Moment){
    return DateUtil.getDateStringWithFormat(date, "DD-MM-YYYY");
}
function publicationProgressBarElement(date: Moment, priority: number, t: TFunction, percentageOfTotalDays: number): ProgressBarElement {
    return {
        circleProps: {
            width: 28,
            topText: getDateString(date),
            bottomText: TenderUtil.translateSortedPublicationPriority(priority, t)
        },
        lineProps: {widthPercentage: percentageOfTotalDays}
    }
}

function todayProgressBarElement(percentageOfTotalDays: number, t: TFunction): ProgressBarElement {
    return {
        circleProps: {
            width: 20,
            isDashed: true,
            topText: getDateString(DateUtil.today()),
            bottomText: t("publications.today")
        },
        lineProps: {widthPercentage: percentageOfTotalDays}
    }
}

function deadlineProgressBarElement(t: TFunction, deadlineDate: Moment, percentageOfTotalDays: number): ProgressBarElement {
    return {
        circleProps: {
            width: 28,
            isWhite: deadlineDate.isAfter(DateUtil.today()),
            topText: getDateString(deadlineDate),
            bottomText: t("publications.deadlineTitle")
        },
        lineProps: {widthPercentage: percentageOfTotalDays}
    }
}

interface ProgressBarLineProps {
    widthPercentage: number;
}

const ProgressBarTransparentLine: React.FC<ProgressBarLineProps> = (props) => {
    return <>
        <div className="line-box" style={{width: props.widthPercentage + "%", height: '50px'}}></div>
    </>
}

interface ProgressBarCircleProps {
    width: number;
    isWhite?: boolean;
    isDashed?: boolean;
    topText: string;
    bottomText: string;
}

const ProgressBarCircle: React.FC<ProgressBarCircleProps> = (props) => {
    return <>
        <div className={"regular-circle-box"}>
            <p>{props.topText}</p>
            {props.isWhite && <ProgressBarWhiteCircle width={props.width}/>}
            {props.isDashed && <ProgressBarDashedCircle width={props.width}/>}
            {!props.isWhite && !props.isDashed && <ProgressBarRegularCircle width={props.width}/>}
            <p>{props.bottomText}</p>
        </div>
    </>
}

interface WidthProps {
    width: number;
}

const ProgressBarRegularCircle: React.FC<WidthProps> = (props) => {
    return <>
        <div className="center-alignment">
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"
                 style={{width: props.width}}>
                <path
                    d="M27.5 13.7255C27.5 21.0204 21.4652 26.951 14 26.951C6.53478 26.951 0.5 21.0204 0.5 13.7255C0.5 6.43054 6.53478 0.5 14 0.5C21.4652 0.5 27.5 6.43054 27.5 13.7255Z"
                    fill="white" stroke="#1EC3CB"></path>
                <ellipse cx="14" cy="13.7255" rx="11" ry="10.7843" fill="#1EC3CB"></ellipse>
            </svg>
        </div>
    </>
}

const ProgressBarDashedCircle: React.FC<WidthProps> = (props) => {
    return <>
        <div className="center-alignment">
            <svg className="svg-orange" width="28" height="28" viewBox="0 0 28 28" fill="none"
                 xmlns="http://www.w3.org/2000/svg" style={{width: props.width}}>
                <path strokeDasharray="4"
                      d="M27.5 13.7255C27.5 21.0204 21.4652 26.951 14 26.951C6.53478 26.951 0.5 21.0204 0.5 13.7255C0.5 6.43054 6.53478 0.5 14 0.5C21.4652 0.5 27.5 6.43054 27.5 13.7255Z"
                      fill="white" stroke="#ff8520"></path>
                <ellipse cx="14" cy="13.7255" rx="11" ry="10.7843" fill="#ff8520"></ellipse>
            </svg>
        </div>
    </>
}

const ProgressBarWhiteCircle: React.FC<WidthProps> = (props) => {
    return <>
        <div className="center-alignment">
            <svg className="svg-white" width="28" height="28" viewBox="0 0 28 28" fill="none"
                 xmlns="http://www.w3.org/2000/svg" style={{width: props.width}}>
                <path
                    d="M27.5 13.7255C27.5 21.0204 21.4652 26.951 14 26.951C6.53478 26.951 0.5 21.0204 0.5 13.7255C0.5 6.43054 6.53478 0.5 14 0.5C21.4652 0.5 27.5 6.43054 27.5 13.7255Z"
                    fill="white" stroke="#51565F"></path>
            </svg>
        </div>
    </>
}


//deprecated
interface ProgressBarOldProps {
    publicationDateString?: string;
    deadlineString?: string;
}

const ProgressBarOld: React.FC<ProgressBarOldProps> = ({publicationDateString, deadlineString}) => {

    if (publicationDateString === undefined || deadlineString === undefined || publicationDateString === null || deadlineString === null) return <></>

    let currentDay = DateUtil.today();

    let publicationDate = moment(publicationDateString);
    if (deadlineString.includes("T")) {
        deadlineString = deadlineString.split("T")[0]
    }
    let deadline = deadlineString ? moment(deadlineString) : null;

    return <>
        <div className="progress-visualization">
            {publicationDate &&
                <div>
                    <div className="icon-alignment-start">
                        <div className="range-round bg-green"></div>
                    </div>
                    <p><span>{DateUtil.getDateString(publicationDate)}</span></p>
                </div>
            }
            {deadline &&
                <div>
                    <div className="icon-alignment">
                        <div className="range-round bg-white"></div>
                    </div>
                    <p>
                        <span>{DateUtil.getDateString(deadline)}</span>
                    </p>
                </div>
            }
            {publicationDate && deadline && currentDay &&
                <div className={'red-round-alignment'}
                     style={{right: getProgressBarRedButtonAlignment(publicationDate, currentDay, deadline)}}
                     title={DateUtil.getDateString(currentDay)}>
                </div>}
        </div>
    </>
}

function getProgressBarRedButtonAlignment(publicationDate: Moment, currentDay: Moment, deadline: Moment) {
    let timeElapsed = currentDay.diff(publicationDate, 'days');
    let totalDays = deadline.diff(publicationDate, 'days');
    let percentage = 100 / totalDays * timeElapsed;
    if (percentage > 95) percentage = 95;
    if (percentage < 5) percentage = 5;
    return percentage + "%";
}