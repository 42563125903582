import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {apiSlice} from "./apiSlice";
import {Label, LabelCategory} from "../../types/label";

/*
  API connection stuff
*/

export interface UserLabelCategoriesState {
    labelCategories?: LabelCategory[];
}

export interface GetLabelCategoriesParams {
    userUuid: string;
}

export interface EditLabelCategoriesParams {
    userUuid: string;
    labelCategories: LabelCategory[];
}

export interface GetLabelsForSearchInput {
    tenderUuids: string[]
}

export interface LabelTenderDto {
    label: Label;
    tenderUuid: string;
}

export interface GetLabelsForSearchOutput {
    labelsAndTenders: LabelTenderDto[];
}

export interface AssignedLabelDto {
    label: Label;
    tenderUuid: string;
    newLabelCategory?: LabelCategory;
}


const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        getLabelsForSearch: build.mutation<GetLabelsForSearchOutput, GetLabelsForSearchInput>({
            query: (params: GetLabelsForSearchInput) => {
                return {
                    url: `/shaggy/labels/search`,
                    method: 'POST',
                    body: {tenderUuids: params.tenderUuids},
                };
            },
        }),
        editLabelCategories: build.mutation<UserLabelCategoriesState, EditLabelCategoriesParams>({
            query: (params: EditLabelCategoriesParams) => {
                return {
                    url: `/shaggy/user/labels`,
                    method: 'PUT',
                    body: {labelCategories: params.labelCategories},
                }
            }
        }),
        assignLabelToTender: build.mutation<AssignedLabelDto, LabelTenderDto>({
            query: (params: LabelTenderDto) => {
                return {
                    url: `/shaggy/labels/assign`,
                    method: 'POST',
                    body: params,
                }
            }
        }),
        unassignLabelFromTender: build.mutation<Label, LabelTenderDto>({
            query: (params: LabelTenderDto) => {
                return {
                    url: `/shaggy/labels/unassign`,
                    method: 'DELETE',
                    body: params,
                }
            }
        }),
    }),
});

export const {
    useGetLabelsForSearchMutation, useEditLabelCategoriesMutation, useAssignLabelToTenderMutation, useUnassignLabelFromTenderMutation} = extendedApiSlice;
