import {ResourceKey} from "i18next";

export const Features: ResourceKey = {
	update_mail_limited: "opportunities per week",
	update_mail_unlimited: "unlimited opportunities",
	update_mail_daily: "daily update",
	update_mail_weekly: "weekly update",
	country: "country",
	countries: "countries",
	up_to: "up to",
	unlimited_countries: "unlimited countries",
	curated_user_profile: "curated selection profile",
	curated_user_profiles: "curated selection profiles",
	curated_user_profiles_unlimited: "unlimited curated selection profiles",
	custom_user_profile: "custom selection profile",
	custom_user_profiles: "custom selection profiles",
	custom_user_profiles_unlimited: "unlimited custom selection profiles",
	advanced_search: "advanced search",
	unlimited_advanced_search: "unlimited advanced search",
	days_back: "days back",
	one_year_back: "one year back",
	labels: "labels",
	unlimited_labels: "unlimited labels",
	export_to_excel: "export to excel",
	search_addenda: "search in addenda",
}