import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useGetCompanyUuidQuery } from "../../hooks/slices/companySlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { showErrorPopup, updateSnacc } from "../../hooks/slices/snaccSlice";
import { t } from "i18next";
import { StarFillIcon, StarIcon } from "../../components/icons";
import {
  addCompanyPageInteraction,
  addUserInteraction,
  CompanyPageInteraction, removeCompanyPageInteraction,
  removeUserInteraction,
  useAddUserInteractionMutation,
  useRemoveUserInteractionMutation,
  UserInteraction,
  UserInteractionInput,
} from "../../hooks/slices/userSlice";
import { UserInteractionType } from "../../consts/UserInteractionType";
import { RaiseUser } from "../../hooks/raiseUser";
import {CompanyUtil} from "../../utils/company";

export const CompanyLinks: React.FC<{ companies: CompanyNameWithVat[] }> = ({ companies }) => {
  return (
    <p>
      {companies.map((c, i) => (
        <span key={i}>
          <CompanyLink c={c} /> {i < companies.length - 1 ? ", " : ""}
        </span>
      ))}
    </p>
  );
};

export const CompanyLink: React.FC<{c: CompanyNameWithVat}> = ({c}) => {
  //todo just call this navigate and follow, exception handling is obvious
  const dispatch = useAppDispatch();
  const userUuid = useAppSelector((state) => state.user.uuid);
  const [attemptToNavigate, setAttemptToNavigate] = useState<boolean>(false);
  const [attemptToFollow, setAttemptToFollow] = useState<boolean>(false);
  const userInteractions: UserInteraction[] = RaiseUser().user?.userInteractions ?? [];
  const companyPageInteractions: CompanyPageInteraction[] = RaiseUser().user?.companyPageInteractions ?? [];
  let kboVatNumber = CompanyUtil.findKboVatNumber(c.vat);
  const hasCompanyPage = (c: CompanyNameWithVat): boolean => {return CompanyUtil.findKboVatNumber(c.vat) != null;}
  const findCompanyPageUuid = (c: CompanyNameWithVat): string | undefined => {
    if (!hasCompanyPage(c)) return undefined;
    if (userInteractions.length === 0 || companyPageInteractions.length === 0) return undefined;
    else return companyPageInteractions.find(cp => cp.companyPageVatNumber === CompanyUtil.findKboVatNumber(c.vat))?.companyPageUuid;
  }
  const isFollowedCompany = (c: CompanyNameWithVat): boolean => {return findCompanyPageUuid(c) != null;}
  const [removeUserInteractionFromDb, {
    isError: removeUserInteractionsError,
    error: removeUserInteractionsErrorObject
  }] = useRemoveUserInteractionMutation();
  useEffect(() => {
     if (removeUserInteractionsError && removeUserInteractionsErrorObject) {
      dispatch(showErrorPopup(removeUserInteractionsErrorObject));
    }
  }, [removeUserInteractionsError, removeUserInteractionsErrorObject]);
  const unFollowCompany = () => {
    let companyPageUuid = findCompanyPageUuid(c);
    if (userUuid && companyPageUuid) {
      let interaction: UserInteraction = { uuid: companyPageUuid, interactionType: UserInteractionType.FOLLOW_COMPANY };
      let interactionInput: UserInteractionInput = { userUuid: userUuid, interaction: interaction };
      removeUserInteractionFromDb(interactionInput); //update DB
      dispatch(removeUserInteraction(interactionInput.interaction)); //update search state
      dispatch(removeCompanyPageInteraction({companyPageUuid: companyPageUuid})); //update search state
    }
  }

  return <>
    {(hasCompanyPage(c)) &&
        <span>
          <span className={"hover-highlight cursor-pointer"}><a onClick={() => setAttemptToNavigate(true)}>{c.name}</a></span>
          {isFollowedCompany(c) && c.showFollowButton && <span className={"hover-highlight cursor-pointer image-button"} onClick={() => unFollowCompany()}><StarFillIcon/></span>}
          {!isFollowedCompany(c) && c.showFollowButton && <span className={"hover-highlight cursor-pointer image-button"} onClick={() => setAttemptToFollow(true)}><StarIcon/></span>}
          {attemptToNavigate && kboVatNumber && <NavigateIfCompanyExists vat={kboVatNumber} close={() => setAttemptToNavigate(false)}/>}
          {attemptToFollow && kboVatNumber && userUuid != null && <FollowIfCompanyExists vat={kboVatNumber} name={c.name} userUuid={userUuid} close={() => setAttemptToFollow(false)}/>}
        </span>}
    {(!hasCompanyPage(c)) && <span>{c.name}</span>}
  </>

}

const NavigateIfCompanyExists: React.FC<{vat: string, close: () => void}> = ({vat, close}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data, isSuccess, error, isError } = useGetCompanyUuidQuery(vat ?? skipToken);
  useEffect(() => {
    if (isSuccess) {
      navigate('/company/' + data);
    } if (error && isError){
      dispatch(updateSnacc({
          message: t("companyPage.unknown_company") + " " + vat,
          severity: "error",
          open: true
        }
      )); close();
    }
  }, [data, isSuccess, error, isError]);
  return <></>
}

const FollowIfCompanyExists: React.FC<{vat: string, name: string, userUuid: string, close: () => void}> = ({vat, name, userUuid, close}) => {
  const dispatch = useAppDispatch();
  const { data: companyPageUuid, isSuccess: companyPageUuidsExists, error, isError } = useGetCompanyUuidQuery(vat ?? skipToken);
  const [addUserInteractionToDb, {
    isError: addUserInteractionsError,
    error: addUserInteractionsErrorObject
  }] = useAddUserInteractionMutation();
  useEffect(() => {
    if (companyPageUuidsExists && companyPageUuid != null) {
      let newInteraction: UserInteraction = { uuid: companyPageUuid, interactionType: UserInteractionType.FOLLOW_COMPANY };
      let interactionInput: UserInteractionInput = { userUuid: userUuid, interaction: newInteraction };
      addUserInteractionToDb(interactionInput); //update DB
      dispatch(addUserInteraction(interactionInput.interaction)); //update search state
      dispatch(addCompanyPageInteraction({companyPageUuid: companyPageUuid, companyPageVatNumber: vat, companyPageName: name})); //update search state
      close();
    } if (error && isError){
      dispatch(updateSnacc({
            message: t("companyPage.unknown_company") + " " + vat,
            severity: "error",
            open: true
          }
      ));
      close();
    } else if (addUserInteractionsError && addUserInteractionsErrorObject) {
      dispatch(showErrorPopup(addUserInteractionsErrorObject));
      close();
    }
  }, [companyPageUuid, companyPageUuidsExists, error, isError, addUserInteractionsError, addUserInteractionsErrorObject]);
  return <></>
}

export interface CompanyNameWithVat{
  name: string;
  vat?: string;
  showFollowButton?: boolean;
}