import {Country} from "../../../consts/countries";
import React, {useState} from "react";
import {FlagIcon} from "../../../components/flags/flags";
import {useTranslation} from "react-i18next";
import {Feature} from "../../../consts/features";
import {FeatureUtil} from "../../../utils/features";

interface CountriesGridProps{
    allPublisherFeatures: Feature[];
    selectedPublisherFeatures: Feature[];
    updatePublisherFeatures: (feature: Feature, isSelected: boolean) => void;
    showPrices: boolean;
}

export const CountriesGrid: React.FC<CountriesGridProps> = ({allPublisherFeatures, selectedPublisherFeatures, updatePublisherFeatures, showPrices}) => {

    const {t} = useTranslation();
    let sortedFeatures = FeatureUtil.sortPublisherFeaturesByTranslation(allPublisherFeatures, t);
    const isSelected = (feature: Feature): boolean => {
        return selectedPublisherFeatures.map(f => f.name).includes(feature.name);
    }
    return <>
        <div className='countries-grid'>
            <div className='countries-grid-items'>
                <h1>{t("intake.third_page_title")}</h1>
                <div className='countries-sub-grid'>
                    {
                        sortedFeatures.map((f) => {
                            return (
                                <CountryCard key={f.name} publisherFeature={f}
                                             isSelected={isSelected(f)}
                                             updatePublisherFeatures={updatePublisherFeatures}
                                             showPrices={showPrices}
                                />
                            )
                        })
                    }
                </div>

            </div>
        </div>
    </>

}

export interface CountryCardProps {
    publisherFeature: Feature;
    isSelected: boolean;
    updatePublisherFeatures: (feature: Feature, isSelected: boolean) => void;
    showPrices: boolean;
}

export const CountryCard: React.FC<CountryCardProps> = ({publisherFeature, isSelected, updatePublisherFeatures, showPrices}) => {

    const {t} = useTranslation();

    // const [isHighlighted, setIsHighlighted] = useState(isSelected);
    const country: Country | undefined = FeatureUtil.getCountryIfPublisherFeature(publisherFeature);
    const hasNationalPublications = country ? [Country.BE, Country.LU, Country.NL, Country.FR].includes(country) : false;

    function updateCountryCard(isSelected: boolean) {
        // setIsHighlighted(isSelected);
        updatePublisherFeatures(publisherFeature, isSelected);
    }

    let priceString = publisherFeature.price ? " € " + publisherFeature.price.toString() + " / " + t("time.year") : "";

    return (
        <div className={isSelected ? 'countries-sub-grid-items selected' : 'countries-sub-grid-items'}
             onClick={() => updateCountryCard(!isSelected)} data-cy={"country-card"}>
            <div className='box-title-alignment'>
                <div className={"flag-section"}>
                    <span><FlagIcon country={country}/></span>
                </div>
                <div className={'title-section'}>
                    <span>{t("countries." + country)}</span>
                    {showPrices && <span>{priceString}</span>}

                </div>
            </div>
            <p>
                {hasNationalPublications ? t("intake.all_publications") : t("intake.european_publications_only")}
            </p>
        </div>
    )

}