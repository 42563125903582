import { ResourceKey } from "i18next";

export const Languages: ResourceKey = {

  DE:"German",
  FR:"French",
  NL:"Dutch",
  EN:"English",
  ALL: "All",

  EL: "Greek",
  ES: "Spanish",
  ET: "Estonian",
  FI: "Finnish",
  GA: "Irish",
  HR: "Croatian",
  HU: "Hungarian",
  IT: "Italian",
  LT: "Lithuanian",
  LV: "Latvian",
  MT: "Maltese",
  PL: "Polish",
  PT: "Portuguese",
  RO: "Romanian",
  SK: "Slovak",
  SL: "Slovenian",
  SV: "Swedish",

  BG: "Bulgarian",
  CS: "Czech",
  DA: "Danish",
};
