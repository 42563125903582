import {CodeBranch, CodeType, CodeWithClass} from "../../../../types/code";
import React from "react";
import {useAppDispatch} from "../../../../app/hooks";
import {updateSearchFilters} from "../../../../hooks/slices/tenderSearchSlice";
import {useTranslation} from "react-i18next";
import {CodeUtil} from "../../../../utils/codes";
import {CloseIcon} from "../../../../components/icons";
import {UserInteraction} from "../../../../hooks/slices/userSlice";
import {RaiseUser} from "../../../../hooks/raiseUser";

export const CodeBranchFilterButtons: React.FC<{codes: CodeBranch[]}> = ({
                                                                 codes,
                                                             }) => {

    const dispatch = useAppDispatch();
    let userInteractions: UserInteraction[] = RaiseUser().user?.userInteractions ?? [];
    let codeType = codes.length > 0 ? codes[0].type : CodeType.CPV;
    const removeCodeFilter = (e: CodeBranch) => {
        if (codes.includes(e)) {
            let newSelectedElements = codes.filter(c => c !== e);
            if (codeType === CodeType.CPV) dispatch(updateSearchFilters({subjectCodes: newSelectedElements, userInteractions: userInteractions}));
            else if (codeType === CodeType.NUTS) dispatch(updateSearchFilters({regionCodes: newSelectedElements, userInteractions: userInteractions}));
        }
    }
    return <>
        {codes.map((code) => <CodeBranchFilterButton key={code.code + "-" + code.inclusive} code={code}
                                               removeCodeFilter={removeCodeFilter}/>)}
    </>
}

interface CodeBranchFilterButtonProps {
    code: CodeBranch;
    removeCodeFilter: (code: CodeBranch) => void;
}

export const CodeBranchFilterButton: React.FC<CodeBranchFilterButtonProps> = ({
                                                               code,
                                                               removeCodeFilter
                                                           }) => {
    let {t} = useTranslation();
    let fullTranslation = CodeUtil.getCodeWithTranslation(code, t) + (code.inclusive ? " (incl.)" : "");
    return <>
        <button>
            <span>{fullTranslation}</span>
            <span onClick={() => removeCodeFilter(code)} style={{cursor:"pointer"}}><CloseIcon/></span>
        </button>
    </>
}

export const CodeWithClassFilterButtons: React.FC<{codes: CodeWithClass[]}> = ({
                                                                             codes,
                                                                         }) => {

    const dispatch = useAppDispatch();
    let userInteractions: UserInteraction[] = RaiseUser().user?.userInteractions ?? [];
    let codeType = codes.length > 0 ? codes[0].type : CodeType.CPV;
    const removeCodeFilter = (e: CodeWithClass) => {
        if (codes.includes(e)) {
            if (codeType === CodeType.ACCREDITATION) dispatch(updateSearchFilters({accreditations: codes.filter(c => c !== e), userInteractions: userInteractions}));
        }
    }
    return <>
        {codes.map((code) => <CodeWithClassFilterButton key={code.code + "-" + code.budgetClass} code={code}
                                               removeCodeFilter={removeCodeFilter}/>)}
    </>
}

interface CodeWithClassFilterButtonProps {
    code: CodeWithClass;
    removeCodeFilter: (code: CodeWithClass) => void;
}

export const CodeWithClassFilterButton: React.FC<CodeWithClassFilterButtonProps> = ({
                                                                      code,
                                                                      removeCodeFilter
                                                                  }) => {
    let {t} = useTranslation();
    let fullTranslation = CodeUtil.convertCodeWithClassToString(code) + " " + CodeUtil.getCodeTranslation(code.code, CodeType.ACCREDITATION, t);
    return <>
        <button>
            <span>{fullTranslation}</span>
            <span onClick={() => removeCodeFilter(code)} style={{cursor:"pointer"}}><CloseIcon/></span>
        </button>
    </>
}