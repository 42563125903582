import { ResourceKey } from "i18next";

export const ContractingAuthorityTypes: ResourceKey = {
    EU_INSTITUTION: "Europese instelling/Europees agentschap of internationale organisatie",
    MINISTRY: "Ministerie of andere nationale of federale instantie, met inbegrip van regionale of plaatselijke onderverdelingen",
    UTILITIES: "Nutssectoren",
    BODY_PUBLIC: "Publiekrechtelijke instelling",
    REGIONAL_AUTHORITY: "Regionale of plaatselijke instantie",
    OTHER: "Andere",
    REGIONAL_AGENCY: "Regionaal of plaatselijk agentschap/bureau",
    NATIONAL_AGENCY: "Nationaal of federaal agentschap/bureau",
    CONT_ENT: "Contracting Entity",
    NOT_CONT_ENT: "Not Contracting Entity",
    BODY_PL: "Publiekrechtelijke instelling",
    CGA: "Centrale overheidsinstantie",
    BODY_PL_CGA: "Publiekrechtelijke instelling onder zeggenschap van een centrale overheidsinstantie",
    PUB_UNDERT_RA: "Overheidsonderneming onder zeggenschap van een regionale overheid",
    PUB_UNDERT: "Overheidsonderneming",
    DEF_CONT: "Contractant op defensiegebied",
    ORG_SUB: "Organisatie die een door een aanbestedende dienst gesubsidieerde opdracht gunt",
    INT_ORG: "Internationale organisatie",
    LA: "Lokale overheid",
    GRP_P_AUT: "Groep overheidsinstanties",
    ORG_SUB_LA: "Organisatie die een door een lokale overheid gesubsidieerde opdracht gunt",
    BODY_PL_LA: "Publiekrechtelijke instelling onder zeggenschap van een lokale overheid",
    EU_INS_BOD_AG: "EU-instelling, -orgaan of -agentschapp",
    RL_AUT: "Regionale of plaatselijke instantie",
    RA: "Regionale overheid",
    ORG_SUB_RA: "Organisatie die een door een regionale overheid gesubsidieerde opdracht gunt",
    PUB_UNDERT_CGA: "Overheidsonderneming onder zeggenschap van een centrale overheidsinstantie",
    BODY_PL_RA: "Publiekrechtelijke instelling onder zeggenschap van een regionale overheid",
    SPEC_RIGHTS_ENTITY: "Entiteit met bijzondere of uitsluitende rechten",
    EU_INT_ORG: "Europese instelling/Europees agentschap of internationale organisatie",
    ORG_SUB_CGA: "Organisatie die een door een centrale overheidsinstantie gesubsidieerde opdracht gunt",
    PUB_UNDERT_LA: "Overheidsonderneming onder zeggenschap van een lokale overheid",

};
