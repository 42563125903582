import {apiSlice} from "./apiSlice";

interface ShareMailParams{
    tenderUuid: string;
    message: string;
    languageIso: string;
    addressees: string[];
    isGroupEmail: boolean;
}

export interface GetShareMailsForTendersParams{
    tenderUuid: string;
}

export interface SharedTenderDto{
    previousAddresseesForTender: string[];
    allAddressees: string[];
}

const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        getShareMailsForTender: build.query<SharedTenderDto, GetShareMailsForTendersParams>({
            query: (body: GetShareMailsForTendersParams) => {
                return {
                    url: `/shaggy/share_mail/` + body.tenderUuid,
                    method: "GET"
                };
            },
        }),
        postShareMail: build.mutation<SharedTenderDto, ShareMailParams>({
            query: (body: ShareMailParams) => {
                return {
                    url: `/shaggy/share_mail`,
                    method: "POST",
                    body: body,
                };
            },
        }),
    })
});

export const {endpoints, useGetShareMailsForTenderQuery, usePostShareMailMutation} = extendedApiSlice;