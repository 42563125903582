import React, { useEffect, useState } from "react";
import "./keywords.scss";
import { useTranslation } from "react-i18next";
import { Language } from "../../../consts/languages";
import { PlusIcon, TrashIcon } from "../../../components/icons";
import { showCustomErrorPopup } from "../../../hooks/slices/snaccSlice";
import { useAppDispatch } from "../../../app/hooks";
import { FeatureUtil } from "../../../utils/features";
import { RaiseCurrentPlan } from "../../../hooks/raiseCurrentPlan";
import { SaveButton } from "../../../components/saveButton/SaveButton";
import { SavedSearchDetailsTabProps } from "../index";
import {TenderSearchUtil} from "../../../utils/savedsearches";

export const KeyWordsPage: React.FC<SavedSearchDetailsTabProps> = ({
  savedSearch,
  updateSavedSearch,
  setUnsavedChanges,
  userUuid,
  isLoading,
}) => {
  const dispatch = useAppDispatch();
  let { t } = useTranslation();
  const featuresInStore = RaiseCurrentPlan().currentPlan?.features;
  const relevantLanguages: Language[] = FeatureUtil.getRelevantLanguagesFromFeatures(featuresInStore).sort((a, b) =>
    t("languages." + a).localeCompare(t("languages." + b))
  );
  const [originalCues, setOriginalCues] = useState<string[]>([...TenderSearchUtil.getCues([savedSearch]), ""]); //the "" is the new cue input
  const [updatedCues, setUpdatedCues] = useState<string[]>(originalCues);
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>(
    savedSearch.languageIsos ? savedSearch.languageIsos : []
  );

  const languageIsSelected = (languageIso: string) => {
    return selectedLanguages.includes(languageIso);
  };
  const allRelevantLanguagesAreSelected = () => {
    //sort() both returns and sorts the original list otherwise ..
    return [...relevantLanguages].sort().join(",") === [...selectedLanguages].sort().join(",");
  };
  const removeCue = (index: number) => {
    let newCues = [...updatedCues];
    newCues.splice(index, 1);
    setOriginalCues(newCues);
    setUpdatedCues(newCues);
    setUnsavedChanges(true);
  };
  const updateCue = (index: number, value: string) => {
    let newCues: string[] = Array.from(updatedCues);
    newCues[index] = value;
    setUpdatedCues(newCues);
    setUnsavedChanges(true);
  };
  const countCue = (cue: string) => {
    let count = 0;
    let cueSlug = cue.trim().toLowerCase();
    for (const slug of updatedCues.map((c) => c.trim().toLowerCase())) {
      if (cueSlug === slug) count = count + 1;
    }
    return count;
  };
  const cueIsUnique = (cue: string): boolean => {
    if (cue.trim() !== "" && countCue(cue) > 1) return false; //ignores empty cues
    return true;
  };
  const cueContainsIllegalQuotes = (cue: string): boolean => {
    return /[‘’‛′“”„«»]/.test(cue);
  };
  const cueIsValid = (cue: string): boolean => {
    return cueIsUnique(cue) && !cueContainsIllegalQuotes(cue);
  };
  const persistCues = () => {
    let duplicateCuesPresent: boolean = false;
    let illegalQuotesInCuesPresent: boolean = false;
    for (const cue of updatedCues) {
      if (!cueIsUnique(cue)) {
        duplicateCuesPresent = true;
        break;
      }
    }
    for (const cue of updatedCues) {
      if (cueContainsIllegalQuotes(cue)) {
        illegalQuotesInCuesPresent = true;
        break;
      }
    }
    if (duplicateCuesPresent) {
      dispatch(showCustomErrorPopup(t("savedSearchSettings.duplicateKeywords")));
    } else if (illegalQuotesInCuesPresent) {
      dispatch(showCustomErrorPopup(t("savedSearchSettings.illegalQuotesInKeywords")));
    } else if (savedSearch.uuid) {
      updateSavedSearch({
        userUuid: userUuid,
        uuid: savedSearch.uuid,
        name: savedSearch.name,
        query: TenderSearchUtil.getCuesAsQuery(updatedCues.filter((c) => c !== "")),
        languageIsos: selectedLanguages,
      });
      //update state
      setOriginalCues(updatedCues);
      //communicate changes to save button
      setUnsavedChanges(false);
    }
  };

  const selectAllLanguages = () => {
    setSelectedLanguages(relevantLanguages);
    setUnsavedChanges(true);
  };
  const deselectAllLanguages = () => {
    setSelectedLanguages([]);
    setUnsavedChanges(true);
  };
  const toggleAllLanguages = () => {
    if (allRelevantLanguagesAreSelected()) deselectAllLanguages();
    else selectAllLanguages();
  };
  const selectLanguage = (languageIso: string) => {
    setSelectedLanguages([...selectedLanguages, languageIso]);
    setUnsavedChanges(true);
  };
  const deselectLanguage = (languageIso: string) => {
    setSelectedLanguages(selectedLanguages.filter((l) => l !== languageIso));
    setUnsavedChanges(true);
  };
  const toggleLanguage = (language: Language) => {
    if (languageIsSelected(language)) deselectLanguage(language);
    else selectLanguage(language);
  };
  const addCueInput = () => {
    setOriginalCues([...updatedCues, ""]);
    setUpdatedCues([...updatedCues, ""]);
  };
  return (
    <>
      <div>
        <div className="subtitle">
          <label>{t("detailsSideBar.languages")}</label>
        </div>
        <div className={"language-checkboxes"}>
          <div className={"checkbox-button-relative"}>
            <div key={"ALL"}>
              <input
                type="checkbox"
                id={"ALL"}
                name="checkBox"
                onChange={toggleAllLanguages}
                checked={allRelevantLanguagesAreSelected()}
              />
              <label htmlFor={"ALL"}>{t("languages.ALL")}</label>
            </div>
            {relevantLanguages.map((l) => (
              <>
                <div key={l.toUpperCase()}>
                  <input
                    type="checkbox"
                    id={l}
                    name="checkBox"
                    onChange={() => toggleLanguage(l)}
                    checked={languageIsSelected(l)}
                  />
                  <label htmlFor={l}>{t("languages." + l)}</label>
                </div>
              </>
            ))}
          </div>
        </div>
        <div className="subtitle">
          <label>{t("savedSearchSettings.keyWords")}</label>
        </div>
        <div className="keyword-section-alignment">
          {originalCues.map((c, i) => (
            <div className="grid" key={i + "-" + c}>
              <div className="grid-items">
                <CueInput
                  cue={c}
                  replaceCue={(v) => updateCue(i, v)}
                  removeCue={() => removeCue(i)}
                  isValid={(c) => cueIsValid(c)}
                />
              </div>
              {i === updatedCues.length - 1 && (
                <div className={"add-input-button"} onClick={addCueInput}>
                  <PlusIcon />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className={"fill-button"}>
        <SaveButton saving={isLoading} save={persistCues} />
      </div>
    </>
  );
};

interface CueProps {
    cue: string;
    replaceCue: (value: string) => void;
    removeCue: () => void;
    isValid: (cue: string) => boolean;
}

export const CueInput: React.FC<CueProps> = ({cue, replaceCue, removeCue, isValid}) => {

    let {t} = useTranslation();
    const [oldCue, setOldCue] = useState<string>(cue);
    const [localCue, setLocalCue] = useState<string>(cue);
    const [hasBeenPersisted, setHasBeenPersisted] = useState(cue !== "");

    const toggleLocalCueValue = (v: string) => {
        if (v !== localCue) {
            setLocalCue(v);
        }
    }
    useEffect(() => {
        const getData = setTimeout(() => {
            if (oldCue !== localCue) {
                replaceCue(localCue);
                // setHasBeenPersisted(true);
                // setOldCue(localCue);
            }
        }, 100)
        return () => clearTimeout(getData)
    }, [localCue])

    return <>
        {/*<div className='grid'>*/}
        {/*    <div className='grid-items'>*/}
        <div className={isValid(localCue) ? 'key-input valid' : 'key-input invalid'}>
            <input type="text" maxLength={255}
                   placeholder={t("savedSearchSettings.newKeyword")}
                   defaultValue={localCue}
                   onChange={(e) => {
                       toggleLocalCueValue(e.currentTarget.value);
                   }} data-cy={"user-profile-details-keyword-input"}
            />
            {hasBeenPersisted &&
                <div className='button-right-alignment' onClick={() => removeCue()}>
                    <TrashIcon/>
                </div>
            }
        </div>
        {/*</div>*/}
        {/*</div>*/}
    </>
}

