import React from "react";
import {CloseIcon} from "../../../../components/icons";

interface QueryFilterButtonProps {
    query: string;
    resetQuery: () => void;
}

export const QueryFilterButton: React.FC<QueryFilterButtonProps> = ({query, resetQuery}) => {
    if (query === "") return <></>
    return <>
        <button>
            <span>{query}</span>
            <span onClick={resetQuery} style={{cursor:"pointer"}}><CloseIcon/></span>
        </button>
    </>
}