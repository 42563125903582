import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import {updateSnacc} from "../hooks/slices/snaccSlice";

// NighteyesError is what an error looks like
// when it comes back from the nighteyes API.
export interface NighteyesError {
  timestamp: string;
  status: number;
  error: string;
  message?: string;
  path: string;
}

// APIError handles an error from Redux-wrapped API calls.
//todo recurrent error when navigating too fast: {"status":"FETCH_ERROR","error":"TypeError: NetworkError when attempting to fetch resource."}
export const APIError: (error: FetchBaseQueryError | SerializedError) => string = (
  error: FetchBaseQueryError | SerializedError
) => {
  let errorMessage = "Unknown error";
  if(typeof error === "undefined") return "Error: unknown";
  if ("data" in error && error.data) {
    try {
      // see if we've got an API error from nighteyes
      let nighteyesError = JSON.parse(JSON.stringify(error.data)) as NighteyesError;
      //this is because the login controller returns string
      if(nighteyesError.status === undefined) nighteyesError = JSON.parse(error.data as string) as NighteyesError;
      errorMessage = `${nighteyesError.status} - ${nighteyesError.error}`;
    } catch {
      // nope, we've got something that we can't understand, but it does have data in it so log it
      errorMessage = errorMessage + ` ${error.data}`;
    }
  }
  return errorMessage;
};

export const APIErrorStatus: (error: FetchBaseQueryError | SerializedError) => number = (
    error: FetchBaseQueryError | SerializedError
) => {
  if ("data" in error && error.data) {
    try {
      // see if we've got an API error from nighteyes
      let nighteyesError = JSON.parse(JSON.stringify(error.data)) as NighteyesError;
      //this is because the login controller returns string
      if(nighteyesError.status === undefined) nighteyesError = JSON.parse(error.data as string) as NighteyesError;
      return nighteyesError.status
    } catch {}
  }
  return -1;
};
