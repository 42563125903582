import React from "react";
import "./policies.scss";
import { useTranslation } from "react-i18next";
import {t} from "i18next";
import {useNavigate} from "react-router-dom";

export const GeneralTermsAndConditionsPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="conditions-page-box">
      <div className="conditions-box">
        <GeneralTermsAndConditionsWindow />
        <div className="fill-button center-bottom">
          <button onClick={() => navigate("/opportunities")}>{t("intake.close")}</button>
        </div>
      </div>
    </div>
  );
};

export const GeneralTermsAndConditionsWindow: React.FC = () => {
  return (
    <>
      <div className={"policy container"}>
        <GeneralTermsAndConditions></GeneralTermsAndConditions>
      </div>
    </>
  );
};

export const GeneralTermsAndConditions: React.FC = () => {
  const { i18n } = useTranslation();
  const lang = i18n.language; // something like en-US
  const languageIso = lang.split("-")[0].toLowerCase(); // something like en
  switch (languageIso) {
    case "en":
      return <GeneralTermsAndConditionsEN />;
    case "fr":
      return <GeneralTermsAndConditionsFR />;
    case "nl":
      return <GeneralTermsAndConditionsNL />;
    default:
      return <GeneralTermsAndConditionsEN />; // Default to English if language is not matched
  }
};

const GeneralTermsAndConditionsEN: React.FC = () => {
  return (
    <>
      <h4>1. GENERAL</h4>
      <p>
        TenderWolf offers its users an online platform with a personalised selection of publications related to public
        procurement and management tools for monitoring the publications.
        <br />
        TenderWolf is a service offered by DataWoods SRL, VAT BE 0539.826.972, RMP Ghent, Ghent division, with
        registered office at 9000 Ghent, Belgium, Tichelrei 82.
        <br />
        By using TenderWolf's services, including registering for a free trial period or subscribing to a free price
        plan, the user acknowledges having taken note of and accepting these general terms and conditions. In what
        follows, "Customer" refers to the natural or legal person who has subscribed to TenderWolf's free or paid
        services.
        <br />
        Notwithstanding any reference by the Customer to its own terms and conditions, these general terms and
        conditions of TenderWolf shall apply.
        <br />
        Deviations only apply when confirmed in writing by TenderWolf to the Customer. These general terms and
        conditions can be consulted at any time on TenderWolf's web application with the address app.tenderwolf.com
        (hereinafter the "web application").
        <br />
        The contractual relationship between TenderWolf and the Customer is governed by Belgian law. In case of
        disputes, only the courts of the district of Ghent, Ghent division have jurisdiction.
      </p>
      <h4>2. SUBSCRIPTIONS</h4>
      <p>
        When subscribing to a price plan, the Customer undertakes to pay a monthly or annual subscription fee in
        accordance with the price list published on the web application at the time of subscription or on the due date
        of his subscription period or as stated on his order form. All prices are expressed in euros and do not include
        VAT. Apart from any price increases in the price plans, the subscription prices are indexed annually - to
        compensate for inflation - on the basis of the general consumer price index and - to compensate for the increase
        in the number of tenders published - increased by a flat rate of 3%.
        <br />
        On the expiry date of the subscription period, the subscription is automatically renewed for a new period of the
        same duration, unless the Customer has changed or discontinued the subscription before the expiry date.
        <br />
        Customers can change price plans at any time via the web application. If the change implies a price increase, a
        new subscription period will be started and the pro rata remaining balance of the previous price plan will be
        deducted from the subscription fee payable for the new price plan. If the change involves a price reduction, the
        current subscription will continue until the expiry date. After the expiry date, the new lower price will then
        be applied.
        <br />
        The Customer can discontinue a price plan at any time via the web application. No further subscription fees will
        then be due on the expiry date of the subscription period. However, subscription fees already paid will remain
        permanently acquired and non-refundable.
      </p>
      <h4>3. INVOICE AND PAYMENTS</h4>
      <p>
        By subscribing to a price plan or by signing an order form, the Customer expressly agrees to the application of
        electronic invoicing by TenderWolf, unless expressly agreed otherwise.
        <br />
        Each invoice must be paid within 30 calendar days of the invoice date. Payments after this period shall by
        operation of law and without notice of default incur interest equal to 10%, as well as (also by operation of law
        and without notice of default) a fixed compensation of 10% on the invoice amount (with a minimum of € 25) as a
        penalty.
        <br />
        In case of non-timely payment of an invoice, TenderWolf may suspend access to the web application, in which case
        the Customer shall continue to owe the agreed subscription fee by operation of law and without notice of
        default.
        <br />
        Any complaint regarding an invoice must be notified in writing within 8 days of the invoice being sent failing
        which the invoice shall be deemed accepted.
      </p>
      <h4>4. LIABILITY</h4>
      <p>
        The tools and information offered by TenderWolf are tools for the Customer to select relevant public tenders.
        TenderWolf will make every effort to ensure that the information is complete and correct, that e-mails notifying
        the Customer of the publication of public contracts are sent in a timely manner and that the web application is
        permanently available.
        <br />
        However, TenderWolf cannot rule out that there may be a delay in sending e-mails or that the web application may
        be temporarily unavailable. In case of breakdown or interruption, TenderWolf will remedy this as soon as
        possible.
        <br />
        The Customer acknowledges explicitly and without any reservation that neither TenderWolf, nor any of its
        partners, are directly or indirectly responsible or liable for any direct or indirect damage, in the broadest
        sense of the word, resulting from any error or incompleteness of the information offered by TenderWolf, the use
        of the tools made available, the failure to meet the requirements, expectations or objectives of the Customer or
        the interruption of access to the web application.
        <br />
        In the performance of its services, TenderWolf gives an obligation of means. Its liability shall in all cases be
        limited to the amount of the last invoice.
      </p>
      <h4>5. CONFIDENTIALITY</h4>
      <p>
        All data entered by Customer when using TenderWolf (hereinafter "User Data"), will be treated as confidential by
        TenderWolf and protected and secured to the best of its ability by implementing appropriate technical and
        organisational measures. TenderWolf will immediately notify Customer of any threat to the security of the User
        Data.
        <br />
        The Customer retains all intellectual property rights in the User Data and grants TenderWolf a non-exclusive,
        sublicensable, transferable, worldwide and royalty-free licence and permission to use, copy, process, store,
        modify and otherwise exploit the User Data in any way to enable TenderWolf's services to the User, and
        generalised and anonymised User Data to improve TenderWolf's overall services. This licence and permission will
        continue after any termination of the service.
      </p>
      <h4>6. PERSONAL DATA</h4>
      <p>
        The Customer authorises TenderWolf to include the personal data provided by the Customer in an automated
        database. TenderWolf hereby acts in accordance with the Law of 30 July 2018 on the protection of natural persons
        with regard to the processing of personal data.
        <br />
        The personal data may be used by TenderWolf in the context of information or promotional campaigns related to
        TenderWolf's services.
        <br />
        Customers have the right to request access to their personal data, correct it, have it erased, restrict its
        processing, object to processing and the right to data portability. If the Customer no longer wishes to receive
        commercial information from TenderWolf, they must inform TenderWolf accordingly
      </p>
    </>
  );
};

const GeneralTermsAndConditionsFR: React.FC = () => {
  return (
    <>
      <h4>1. GÉNÉRALITÉS</h4>
      <p>
        TenderWolf offre à ses utilisateurs une plateforme en ligne avec une sélection personnalisée de publications
        relatives aux marchés publics et des outils de gestion pour le suivi des publications.
        <br />
        TenderWolf est un service proposé par DataWoods SRL, TVA BE 0539.826.972, RPR Gand, division Gand, dont le siège
        social est situé à 9000 Gand, Belgique, Tichelrei 82.
        <br />
        En utilisant les services de TenderWolf, y compris en s'inscrivant pour une période d'essai gratuite ou en
        souscrivant au plan tarifaire gratuit “ Free ", l'utilisateur reconnaît avoir pris connaissance et accepter les
        présentes conditions générales. Dans ce qui suit, le terme " Client " désigne la personne physique ou morale qui
        s'est inscrite aux services gratuits ou payants de TenderWolf.
        <br />
        Nonobstant toute référence par le Client à ses propres conditions générales, les présentes conditions générales
        de TenderWolf s'appliquent. Les dérogations ne s'appliquent que si elles sont confirmées par écrit par
        TenderWolf au Client. Les présentes conditions générales peuvent être consultées à tout moment sur l'application
        web de TenderWolf à l'adresse app.tenderwolf.com (ci-après “ l’application web “).
        <br />
        La relation contractuelle entre TenderWolf et le Client est régie par le droit belge. En cas de litige, seuls
        les tribunaux de l'arrondissement de Gand, division de Gand, sont compétents.
      </p>
      <h4>2. ABONNEMENTS</h4>
      <p>
        Lors de la souscription d'un plan tarifaire, le Client s'engage à payer un abonnement mensuel ou annuel
        conformément à la liste de prix publiée sur l’application web au moment de la souscription ou à la date
        d'expiration de sa période d'abonnement ou comme indiqué sur son bon de commande. Tous les prix sont exprimés en
        euros et s'entendent hors TVA. En dehors de toute augmentation de prix dans les plans tarifaires, les prix des
        abonnements sont indexés annuellement - pour compenser l'inflation - sur la base de l'indice général des prix à
        la consommation et - pour compenser l'augmentation du nombre d'appels d'offres publiés - augmentés d'un taux
        forfaitaire de 3 %.
        <br />
        À la date d'expiration de la période d'abonnement, l'abonnement est automatiquement renouvelé pour une nouvelle
        période de même durée, à moins que le Client n'ait modifié ou interrompu son abonnement avant la date
        d'expiration.
        <br />
        Les Clients peuvent changer de plan tarifaire à tout moment via l'application web. Si le changement implique une
        augmentation de prix, une nouvelle période d'abonnement commencera et le solde restant au prorata du plan
        tarifaire précédent sera déduit de la redevance d'abonnement à payer pour le nouveau plan tarifaire. Si la
        modification implique une réduction de prix, l'abonnement en cours se poursuivra jusqu'à la date d'expiration.
        Après la date d'expiration, le nouveau prix réduit sera appliqué.
        <br />
        Le Client peut à tout moment mettre fin à un plan tarifaire via l'application web. Aucun frais d'abonnement ne
        sera alors dû à la date d'expiration de la période d'abonnement. Toutefois, les frais d'abonnement déjà payés
        restent définitivement acquis et non remboursables.
      </p>
      <h4>3. FACTURES ET PAIEMENTS</h4>
      <p>
        En souscrivant à un plan tarifaire ou en signant un bon de commande, le Client accepte expressément
        l'application de la facturation électronique par TenderWolf, sauf convention contraire expresse.
        <br />
        Chaque facture doit être payée dans les 30 jours calendrier suivant la date de facturation. Les paiements
        effectués après ce délai donnent lieu, de plein droit et sans mise en demeure, à un intérêt de 10%, ainsi qu'à
        une indemnité forfaitaire de 10% sur le montant de la facture (avec un minimum de 25 euros) à titre de pénalité
        (également de plein droit et sans mise en demeure).
        <br />
        En cas de non-paiement d'une facture dans les délais, TenderWolf peut suspendre l'accès à l'application web,
        auquel cas le Client reste redevable, de plein droit et sans mise en demeure, de la redevance d'abonnement
        convenue.
        <br />
        Toute réclamation concernant une facture doit être notifiée par écrit dans les 8 jours suivant l'envoi de la
        facture, faute de quoi la facture sera réputée acceptée.
      </p>
      <h4>4. RESPONSABILITÉ</h4>
      <p>
        Les outils et les informations proposés par TenderWolf sont des outils permettant au Client de sélectionner des
        marchés publics pertinents. TenderWolf met tout en œuvre pour que les informations soient complètes et
        correctes, que les courriers électroniques informant le Client de la publication des marchés publics soient
        envoyés en temps utile et que l'application web soit disponible en permanence. Toutefois, TenderWolf ne peut
        exclure qu'il y ait un retard dans l'envoi des courriers électroniques ou que l'application web soit
        temporairement indisponible. En cas de panne ou d'interruption, TenderWolf y remédiera dans les meilleurs
        délais.
        <br />
        Le Client reconnaît expressément et sans réserve que ni TenderWolf, ni aucun de ses partenaires, ne sont
        directement ou indirectement responsables de tout dommage direct ou indirect, au sens le plus large du terme,
        résultant d'une erreur ou du caractère incomplet des informations proposées par TenderWolf, de l'utilisation des
        outils mis à disposition, de la non satisfaction des besoins, attentes ou objectifs du Client ou de
        l'interruption de l'accès à l'application web.
        <br />
        Dans le cadre de l'exécution de ses services, TenderWolf est soumis à une obligation de moyens. Sa
        responsabilité est en tout état de cause limitée au montant de la dernière facture.
      </p>
      <h4>5. CONFIDENTIALITÉ</h4>
      <p>
        Toutes les données saisies par le Client dans le cadre de l'utilisation de TenderWolf (ci-après les " Données
        utilisateur ") seront traitées de manière confidentielle par TenderWolf et protégées et sécurisées au mieux par
        la mise en œuvre de mesures techniques et organisationnelles appropriées. TenderWolf informera immédiatement le
        Client de toute menace pesant sur la sécurité des données de l'utilisateur. Le Client conserve tous les droits
        de propriété intellectuelle sur les Données utilisateurs et accorde à TenderWolf une licence non exclusive,
        sous- licenciable, transférable, mondiale et libre de redevance, ainsi que l'autorisation d'utiliser, de copier,
        de traiter, de stocker, de modifier et d'exploiter de toute autre manière les Données utilisateurs afin de
        permettre à TenderWolf de fournir ses services à l'utilisateur, ainsi que les Données utilisateurs généralisées
        et anonymisées afin d'améliorer l'ensemble des services proposés par TenderWolf. Cette licence et cette
        autorisation seront maintenues après la cessation du service.
      </p>
      <h4>6. DONNÉES PERSONNELLES</h4>
      <p>
        Le Client autorise TenderWolf à inclure les données personnelles fournies par le Client dans une base de données
        automatisée. Ce faisant, TenderWolf agit conformément à la Loi du 30 juillet 2018 relative à la protection des
        personnes physiques à l'égard des traitements de données à caractère personnel u règlement général sur la
        protection des données (RGPD) du 25 mai 2018. Les données personnelles peuvent être utilisées par TenderWolf
        dans le cadre de campagnes d'information ou de promotion liées aux services de TenderWolf.
        <br />
        Les Clients ont le droit de demander l'accès à leurs données personnelles, de les corriger, de les faire
        effacer, de restreindre leur traitement, de s'opposer au traitement et le droit à la portabilité des données. Si
        le Client ne souhaite plus recevoir d'informations commerciales de la part de TenderWolf, il doit en informer
        TenderWolf.
      </p>
    </>
  );
};

const GeneralTermsAndConditionsNL: React.FC = () => {
  return (
    <>
      <h4>1. ALGEMEEN</h4>
      <p>
        TenderWolf biedt zijn gebruikers een online platform met een gepersonaliseerde selectie publicaties met
        betrekking tot overheidsopdrachten en management tools voor de opvolging van de publicaties.
        <br />
        TenderWolf is een dienst die wordt aangeboden door DataWoods bv, BTW BE 0539.826.972, RPR Gent, afdeling Gent,
        met maatschappelijke zetel te 9000 Gent, België, Tichelrei 82.
        <br />
        Door het gebruik van de diensten van TenderWolf, inclusief inschrijving voor een gratis ‘trial’-periode of
        inschrijving op het gratis “Free” prijsplan, erkent de gebruiker kennis te hebben genomen van onderhavige
        algemene voorwaarden en deze te aanvaarden. In wat volgt wordt met de “Klant” de natuurlijke persoon of
        rechtspersoon bedoeld die zich ingeschreven heeft voor gratis of betalende diensten van TenderWolf.
        <br />
        Niettegenstaande elke verwijzing van de Klant naar zijn eigen voorwaarden, zijn onderhavige algemene voorwaarden
        van TenderWolf van toepassing. Afwijkingen gelden alleen wanneer deze schriftelijk door TenderWolf aan de Klant
        werden bevestigd.
        <br />
        Deze algemene voorwaarden zijn te allen tijde raadpleegbaar op de webapplicatie van TenderWolf met het adres
        app.tenderwolf.com (hierna de “webapplicatie”).
        <br />
        De contractuele relatie tussen TenderWolf en de Klant wordt beheerst door het Belgisch Recht. In geval van
        betwisting zijn enkel de rechtbanken van het arrondissement Gent, afdeling Gent bevoegd.
      </p>
      <h4>2. ABONNEMENTEN</h4>
      <p>
        Bij intekening op een prijsplan verbindt de Klant zich tot het betalen van een maandelijks of jaarlijks
        abonnementsgeld volgens de prijslijst die op het ogenblik van intekening of op de vervaldag van zijn
        abonnementsperiode op de webapplicatie gepubliceerd staat of zoals vermeld op zijn bestelbon.
        <br />
        Alle prijzen zijn uitgedrukt in euro en zijn exclusief btw. Los van eventuele prijsverhogingen van de
        prijsplannen worden de abonnementsprijzen jaarlijks - ter compensatie van de inflatie - geïndexeerd aan de hand
        van de algemene consumptieprijsindex en - ter compensatie van de verhoging van het aantal gepubliceerde
        aanbestedingen - verhoogd met een forfaitair percentage van 3%.
        <br />
        Op de vervaldag van de abonnementsperiode wordt het abonnement automatisch verlengd voor een nieuwe periode met
        dezelfde duur, tenzij de Klant het abonnement voor de vervaldag gewijzigd of stopgezet heeft.
        <br />
        Van prijsplan veranderen kan de Klant op elk ogenblik via de webapplicatie. Indien de verandering een
        prijsverhoging inhoudt, wordt een nieuwe abonnementsperiode opgestart en wordt het pro rata resterende saldo van
        het vorige prijsplan afgetrokken van het te betalen abonnementsgeld voor het nieuwe prijsplan. Indien de
        verandering een prijsverlaging inhoudt, loopt het huidige abonnement verder tot de vervaldag. Na de vervaldag
        wordt dan de nieuwe lagere prijs toegepast.
        <br />
        Een prijsplan stopzetten kan de Klant op elk ogenblik via de webapplicatie. Op de vervaldag van de
        abonnementsperiode zal dan geen abonnementsgeld meer verschuldigd zijn. De reeds betaalde abonnementsgelden
        zullen echter definitief verworven blijven en niet terugbetaald worden.
      </p>
      <h4>3. FACTUREN EN BETALINGEN</h4>
      <p>
        Door het intekenen op een prijsplan of door het ondertekenen van een bestelbon verklaart de Klant zich
        uitdrukkelijk akkoord met de toepassing van elektronische facturatie door TenderWolf, tenzij uitdrukkelijk
        anders overeengekomen.
        <br />
        Elke factuur dient betaald te worden binnen de 30 kalenderdagen na factuurdatum. Betalingen na deze termijn
        brengen van rechtswege en zonder ingebrekestelling interesten op gelijk aan 10%, alsmede (evenzeer van
        rechtswege en zonder ingebrekestelling) een forfaitaire vergoeding van 10% op het factuurbedrag (met een minimum
        van € 25) als schadebeding.
        <br />
        In geval van niet-tijdige betaling van een factuur kan TenderWolf de toegang tot de webapplicatie opschorten,
        waarbij de Klant van rechtswege en zonder ingebrekestelling het overeengekomen abonnementsgeld verschuldigd
        blijft.
        <br />
        Elke klacht in verband met een factuur dient schriftelijk te worden gemeld binnen de 8 dagen na de verzending
        van de factuur bij gebreke waaraan de factuur geacht wordt aanvaard te zijn
      </p>
      <h4>4. AANSPRAKELIJKHEID</h4>
      <p>
        De tools en informatie die aangeboden worden door TenderWolf zijn hulpmiddelen voor de Klant bij het selecteren
        van relevante overheidsopdrachten.
        <br />
        TenderWolf zal alles in het werk stellen opdat de informatie volledig en correct is, opdat de e-mails waarmee de
        Klant verwittigd wordt van de publicatie van overheidsopdrachten tijdig verstuurd worden en dat de webapplicatie
        permanent beschikbaar is.
        <br />
        TenderWolf kan echter niet uitsluiten dat er vertraging optreedt bij het verzenden van e-mails of dat de
        webapplicatie tijdelijk niet bereikbaar is. In geval van defect of onderbreking zal TenderWolf hier zo snel
        mogelijk aan verhelpen.
        <br />
        De Klant erkent uitdrukkelijk en zonder enige reserve dat noch TenderWolf, noch één van zijn partners, direct of
        indirect verantwoordelijk zijn of aansprakelijk kunnen gesteld worden voor enige rechtstreekse of
        onrechtstreekse schade in de breedste betekenis van het woord, die voortkomt uit om het even welke fout of
        onvolledigheid van de door TenderWolf aangeboden informatie, het gebruik van de ter beschikking gestelde tools,
        het niet tegemoetkomen aan de vereisten, verwachtingen of doelstellingen van de Klant of de onderbreking van de
        toegang tot de webapplicatie.
        <br />
        Bij de uitvoering van zijn dienstverlening levert TenderWolf een inspanningsverbintenis. Zijn aansprakelijkheid
        is alleszins beperkt tot het bedrag van de laatste factuur.
      </p>
      <h4>5. CONFIDENTIALITEIT</h4>
      <p>
        Alle data die worden ingebracht door de Klant bij het gebruik van TenderWolf (hierna “Gebruikersdata”), zullen
        door TenderWolf als confidentieel behandeld worden en naar best vermogen beschermd en beveiligd worden door het
        implementeren van gepaste technische en organisatorische maatregelen. TenderWolf zal de Klant onmiddellijk op de
        hoogte brengen van elke bedreiging voor de veiligheid van de Gebruikersdata. De Klant behoudt alle intellectuele
        eigendomsrechten op de Gebruikersdata en verleent TenderWolf een niet-exclusieve, sublicentieerbare,
        overdraagbare, wereldwijde en royaltyvrije licentie en toelating tot het gebruiken, kopiëren, verwerken,
        opslaan, wijzigen en op eender ander welke wijze exploiteren van de Gebruikersdata om de dienstverlening van
        TenderWolf aan de gebruiker mogelijk te maken, en van veralgemeende en geanonimiseerde Gebruikersdata om de
        algemene dienstverlening van TenderWolf te verbeteren. Deze licentie en toelating zullen doorlopen na de
        eventuele stopzetting van de dienstverlening
      </p>
      <h4>6. PERSOONSGEGEVENS</h4>
      <p>
        De Klant geeft TenderWolf de toestemming om de door de Klant verstrekte persoonsgegevens op te nemen in een
        geautomatiseerd gegevensbestand. TenderWolf handelt hierbij conform de Wet van 30 juli 2018 betreffende de
        bescherming van natuurlijke personen met betrekking tot de verwerking van persoonsgegevens. De persoonsgegevens
        kunnen door TenderWolf worden gebruikt in het kader van informatie- of promotiecampagnes die verband houden met
        de dienstverlening van TenderWolf.
        <br />
        De Klant heeft het recht om toegang te vragen tot zijn persoonsgegevens, deze te corrigeren, te laten wissen, de
        verwerking ervan te beperken, bezwaar te maken tegen de verwerking en het recht op gegevensoverdraagbaarheid.
        Indien de Klant geen commerciële informatie meer van TenderWolf wenst te ontvangen, dient hij TenderWolf hiervan
        op de hoogte te brengen.
      </p>
    </>
  );
};
