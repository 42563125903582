import { ResourceKey } from "i18next";

export const SavedSearchSettings: ResourceKey = {
	generalSettings: "General",
	settings: "Settings",
	keyWords: "Keywords",
	subjectCodes: "Subject codes (CPV)",
	regionCodes: "Region codes (NUTS)",
	accreditations: "Accreditations",
	formTypes: "Form types",
	save: "Save",
	accountSettings: "Account",
	newKeyword: "New keyword",
  duplicateKeywords: "Keywords contain duplicates",
  illegalQuotesInKeywords: "Keywords contain curly quotes, please replace them with straight quotes (', \")",
	addProfile: "Add profile",
	name: "Name of the profile",
  invalidProfileName: "Invalid profile name",
	defaultName: "Selection profile",
	confirmRemove: "Are you sure you want to delete this profile?",
	expertManaged: "This profile is managed for you by TenderWolf. If you need more flexibility, contact our Tender Search Expert.",
	noResults: "No results",


};
