import React from 'react'
import './error.scss';
import {NighteyesError} from "../../utils/error";
import {useTranslation} from "react-i18next";
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {SerializedError} from "@reduxjs/toolkit";

interface ErrorPageProps{
    error?: FetchBaseQueryError | SerializedError;
    errorCode?: number;
}

export const ErrorPage: React.FC<ErrorPageProps> = ({error, errorCode}) => {

    const {t} = useTranslation();
    let code = errorCode ? errorCode : getErrorCode(error);

    return (
        <div>
            <div className='layout-main-banner'>
                <div className='container'>
                    <div className='layout-top-alignment'>
                        <div className='login-box'>
                            <div className='page-title'>
                                <h1>{t("error.oopsSomethingWentWrong")}</h1>
                                <p>{t("error.error_" + code)}</p>
                            </div>

                            {/*<div className='have-a-account'>*/}
                            {/*    <p>{t("login.no_account")} <a href="/signup-start">{t("login.register")}</a></p>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function getErrorCode(error: FetchBaseQueryError | SerializedError | undefined): number{
    if (error && "data" in error && error.data) {
        try {
            // see if we've got an API error from nighteyes
            let nighteyesError = JSON.parse(JSON.stringify(error.data)) as NighteyesError;
            //this is because the login controller returns string
            if(nighteyesError.status === undefined) nighteyesError = JSON.parse(error.data as string) as NighteyesError;
            if(nighteyesError.status) return nighteyesError.status;
        } catch {
            //do nothing
        }
    } return 400;
}
