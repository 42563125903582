import {FetchBaseQueryError, skipToken} from "@reduxjs/toolkit/query";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {SerializedError} from "@reduxjs/toolkit";

import {useEffect} from "react";
import {GetClientParams, updateClient, useGetClientQuery} from "./slices/clientSlice";
import {Client} from "../types/client";


export interface ClientInStore {
    client?: Client;
    isLoading: boolean;
    error: FetchBaseQueryError | SerializedError | undefined;
}

export function RaiseClient(): ClientInStore {
    const dispatch = useAppDispatch();
    const authState = useAppSelector((state) => state.login);
    const clientInStore: Client = useAppSelector((state) => state.company);
    let companyNeedsToBeFetched = clientInStore.uuid === undefined;
    let params: GetClientParams | undefined = (authState.userID && companyNeedsToBeFetched) ? {uuid:authState.userID} : undefined;
    const {data, isSuccess, isLoading, error, isError} = useGetClientQuery(params ?? skipToken);
    useEffect(() => {
        //!(isLoading || isFetching) avoids masquerading issues (where the user is swapped)
        if (companyNeedsToBeFetched && isSuccess && data) {
            dispatch(updateClient(data));
        }
    }, [companyNeedsToBeFetched, isSuccess, data, dispatch])


    return {
        client: clientInStore,
        isLoading: isLoading,
        error: error
    }
}
