import { Label } from "../../../../types/label";
import React from "react";
import { CloseIcon } from "../../../../components/icons";
import { useTranslation } from "react-i18next";
import { updateSearchFilters } from "../../../../hooks/slices/tenderSearchSlice";
import { useAppDispatch } from "../../../../app/hooks";
import {UserInteraction} from "../../../../hooks/slices/userSlice";
import {RaiseUser} from "../../../../hooks/raiseUser";

export const LabelFilterButtons: React.FC<{ labelFilters: Label[] | undefined }> = ({ labelFilters }) => {
  const dispatch = useAppDispatch();
    let userInteractions: UserInteraction[] = RaiseUser().user?.userInteractions ?? [];
    const removeLabelFilter = (labelDto: Label) => {
    let newLabelFilters = labelFilters ? labelFilters.filter((l) => l.name !== labelDto.name) : undefined;
    dispatch(updateSearchFilters({ labelFilters: newLabelFilters, userInteractions: userInteractions }));
  };
  if (labelFilters === undefined) return <></>;
  return (
    <>
      {labelFilters.map((labelFilter, i) => (
        <LabelFilterButton key={i} labelFilter={labelFilter} removeLabelFilter={removeLabelFilter} />
      ))}
    </>
  );
};

interface LabelFilterButtonProps {
    labelFilter: Label;
    removeLabelFilter: (labelDto: Label) => void;
}

export const LabelFilterButton: React.FC<LabelFilterButtonProps> = ({
                                                                 labelFilter,
                                                                 removeLabelFilter
                                                             }) => {
    const {t} = useTranslation();
    return <>
        <button>
            <span>{t("searchFilterTags.label") + ": " + labelFilter.name}</span>
            <span onClick={() => removeLabelFilter(labelFilter)} style={{cursor:"pointer"}}><CloseIcon/></span>
        </button>
    </>
}