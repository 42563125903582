import {ResourceKey} from "i18next";

export const Error: ResourceKey = {
	oopsSomethingWentWrong: "Oups, quelque chose s'est mal passé!",
	error_400: "Vous avez envoyé une mauvaise requête.",
	error_401: "Vous n'êtes pas autorisé à voir cette page.",
	error_403: "Vous n'êtes pas autorisé à voir cette page.",
	error_404: "La page que vous recherchez n'existe pas.",
	error_500: "Nous avons eu des problèmes techniques lors de votre dernière opération.",
	error_503: "Tous nos serveurs sont occupés en ce moment.",
};