import React, { useState } from "react";
import "./generalSettings.scss";
import { useTranslation } from "react-i18next";
import { RaiseCurrentPlan } from "../../../hooks/raiseCurrentPlan";
import { FeatureUtil } from "../../../utils/features";
import { FeatureName } from "../../../consts/features";
import { SaveButton } from "../../../components/saveButton/SaveButton";
import { FieldGroup } from "../../../consts/FieldGroup";
import { SavedSearchDetailsTabProps } from "../index";

export const GeneralSettingsPage: React.FC<SavedSearchDetailsTabProps> = ({
  userUuid,
  savedSearch,
  updateSavedSearch,
  setUnsavedChanges,
  isLoading,
}) => {
  const { t } = useTranslation();
  const features = RaiseCurrentPlan().currentPlan?.features;
  const [profileName, setProfileName] = useState(savedSearch.name ? savedSearch.name : "");
  const [fieldGroupsToSearch, setFieldGroupsToSearch] = useState(
    savedSearch.fieldGroups ? savedSearch.fieldGroups : []
  );
  const userCanToggleSearchAddenda = FeatureUtil.hasFeature(features, FeatureName.USER_PROFILES_SEARCH_ADDENDA);
  const searchAddenda = fieldGroupsToSearch.includes(FieldGroup.ADDENDA);
  const changeProfileName: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setProfileName(e.target.value);
    setUnsavedChanges(true);
  };
  const toggleSearchAddenda = () => {
    if (savedSearch.uuid && userCanToggleSearchAddenda) {
      if (searchAddenda) {
        setFieldGroupsToSearch(fieldGroupsToSearch.filter((f) => f !== FieldGroup.ADDENDA));
      } else {
        setFieldGroupsToSearch([...fieldGroupsToSearch, FieldGroup.ADDENDA]);
      }
      setUnsavedChanges(true);
    }
  };
  const updateGeneralSettings = () => {
    if (savedSearch.uuid) {
      updateSavedSearch({ userUuid: userUuid, name: profileName, fieldGroups: fieldGroupsToSearch });
      setUnsavedChanges(false);
    }
  };

  return (
    <>
      <div>
        <div className="general-settings-section">
          <div className="profile-input">
            <label>{t("settings.profile_name_placeholder")}</label>
            <div className="input">
              <input
                maxLength={255}
                type="text"
                value={profileName}
                placeholder={t("settings.name_this_profile")}
                onChange={changeProfileName}
                data-testid={"profile-name-input"}
              />
            </div>
          </div>
          <div className="matching-rules">
            <h1>{t("savedSearchSettings.settings")}</h1>
            <div className="switch-text-alignment">
              <div>
                <label className="switch">
                  <input
                    id="checkboxinp"
                    type="checkbox"
                    checked={searchAddenda}
                    onChange={toggleSearchAddenda}
                    data-testid={"search-addenda-checkbox"}
                  />
                  <div className="slider round"></div>
                </label>
              </div>
              <div>
                <span>{t("publications.searchAddenda")}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
      <div></div>
      <div></div>
      <div className={"fill-button"}>
        <SaveButton saving={isLoading} save={updateGeneralSettings} />
      </div>
    </>
  );
};
