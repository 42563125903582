export enum Country {
    AD="AD",
    AE="AE",
    AF="AF",
    AG="AG",
    AI="AI",
    AL="AL",
    AM="AM",
    AO="AO",
    AQ="AQ",
    AR="AR",
    AS="AS",
    AT="AT",
    AU="AU",
    AW="AW",
    AZ="AZ",
    BA="BA",
    BB="BB",
    BD="BD",
    BE="BE",
    BF="BF",
    BG="BG",
    BH="BH",
    BI="BI",
    BJ="BJ",
    BM="BM",
    BO="BO",
    BQ="BQ",
    BR="BR",
    BS="BS",
    BT="BT",
    BV="BV",
    BW="BW",
    BY="BY",
    BZ="BZ",
    CA="CA",
    CF="CF",
    CG="CG",
    CH="CH",
    CI="CI",
    CK="CK",
    CL="CL",
    CM="CM",
    CN="CN",
    CO="CO",
    CP="CP",
    CR="CR",
    CU="CU",
    CV="CV",
    CW="CW",
    CX="CX",
    CY="CY",
    CZ="CZ",
    DE="DE",
    DJ="DJ",
    DK="DK",
    DM="DM",
    DO="DO",
    DZ="DZ",
    EC="EC",
    EE="EE",
    EG="EG",
    EH="EH",
    ER="ER",
    ES="ES",
    ET="ET",
    EU="EU",
    FI="FI",
    FJ="FJ",
    FK="FK",
    FM="FM",
    FO="FO",
    FR="FR",
    GA="GA",
    GB="GB",
    GD="GD",
    GE="GE",
    GF="GF",
    GH="GH",
    GI="GI",
    GL="GL",
    GM="GM",
    GN="GN",
    GP="GP",
    GQ="GQ",
    GR="GR",
    GS="GS",
    GT="GT",
    GU="GU",
    GW="GW",
    GY="GY",
    HK="HK",
    HN="HN",
    HR="HR",
    HT="HT",
    HU="HU",
    ID="ID",
    IE="IE",
    IL="IL",
    IN="IN",
    IO="IO",
    IQ="IQ",
    IR="IR",
    IS="IS",
    IT="IT",
    JM="JM",
    JO="JO",
    JP="JP",
    KE="KE",
    KG="KG",
    KH="KH",
    KI="KI",
    KM="KM",
    KN="KN",
    KP="KP",
    KR="KR",
    KW="KW",
    KY="KY",
    KZ="KZ",
    LA="LA",
    LB="LB",
    LC="LC",
    LI="LI",
    LK="LK",
    LR="LR",
    LS="LS",
    LT="LT",
    LU="LU",
    LV="LV",
    LY="LY",
    MA="MA",
    MC="MC",
    MD="MD",
    MF="MF",
    MG="MG",
    MH="MH",
    MK="MK",
    ML="ML",
    MM="MM",
    MN="MN",
    MO="MO",
    MP="MP",
    MQ="MQ",
    MR="MR",
    MS="MS",
    MT="MT",
    MU="MU",
    MV="MV",
    MW="MW",
    MX="MX",
    MY="MY",
    MZ="MZ",
    NA="NA",
    NE="NE",
    NF="NF",
    NG="NG",
    NI="NI",
    NL="NL",
    NO="NO",
    NP="NP",
    NR="NR",
    NU="NU",
    NZ="NZ",
    OM="OM",
    PA="PA",
    PE="PE",
    PF="PF",
    PG="PG",
    PH="PH",
    PK="PK",
    PL="PL",
    PN="PN",
    PR="PR",
    PS="PS",
    PT="PT",
    PW="PW",
    PY="PY",
    QA="QA",
    RE="RE",
    RO="RO",
    RU="RU",
    RW="RW",
    SA="SA",
    SB="SB",
    SC="SC",
    SD="SD",
    SE="SE",
    SG="SG",
    SH="SH",
    SI="SI",
    SJ="SJ",
    SK="SK",
    SL="SL",
    SM="SM",
    SN="SN",
    SO="SO",
    SR="SR",
    ST="ST",
    SV="SV",
    SX="SX",
    SY="SY",
    TC="TC",
    TD="TD",
    TF="TF",
    TG="TG",
    TH="TH",
    TJ="TJ",
    TK="TK",
    TL="TL",
    TM="TM",
    TN="TN",
    TO="TO",
    TR="TR",
    TT="TT",
    TV="TV",
    TW="TW",
    TZ="TZ",
    UA="UA",
    UG="UG",
    UK="UK",
    UM="UM",
    US="US",
    UY="UY",
    UZ="UZ",
    VC="VC",
    VE="VE",
    VG="VG",
    VI="VI",
    VN="VN",
    VU="VU",
    WF="WF",
    WS="WS",
    YE="YE",
    YT="YT",
    ZA="ZA",
    ZW="ZW",
}

export namespace CountryParser {
    export function toString(country: Country): string {
        return Country[country];
    }

    export function fromString(country: string): Country {
        return (Country as any)[country];
    }

    export function getCountriesForSale(): Country[]{
            return [
                Country.AT,
                Country.BE,
                Country.BG,
                Country.CH,
                Country.CY,
                Country.CZ,
                Country.DE,
                Country.DK,
                Country.EE,
                Country.ES,
                Country.FI,
                Country.FR,
                Country.GR,
                Country.HR,
                Country.HU,
                Country.IE,
                Country.IS,
                Country.IT,
                Country.LV,
                Country.LT,
                Country.LU,
                Country.MT,
                Country.NL,
                Country.NO,
                Country.PL,
                Country.PT,
                Country.RO,
                Country.SE,
                Country.SI,
                Country.SK,
                Country.UK,
            ];
    }
}