import { RaiseUser } from "../../hooks/raiseUser";
import { useNavigate } from "react-router-dom";

export const useNavigateToOpportunitiesIfSignupAlreadyFinished = () => {
  let userInStore = RaiseUser();
  let activityStatus = userInStore.user ? userInStore.user.activityStatus : undefined;
  const navigate = useNavigate();
  if (activityStatus === "ACTIVE") {
      navigate("/opportunities");
  }
};
