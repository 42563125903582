import {FetchArgs} from "@reduxjs/toolkit/dist/query";
import {apiSlice} from "../../hooks/slices/apiSlice";

/*
  REDUX STORE stuff
*/

/*
  API connection stuff
*/


export interface ExportInput{
    userUuid: string;
    tenderUuids: string[];
    query: string;
    filters: string[];
    reproduceSearchUrlPath: string;
}

export interface ExportOutput{
    downloadUrl: string;
}


/**
 * getTenderSearch and build.query constrains 'useGetTenderSearchQuery'
 * build.mutation returns [] whereas build.query returns {}
 */

const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        getExport: build.mutation<ExportOutput, ExportInput>({
            query: (body: ExportInput) => {
                let args: FetchArgs =  {
                    url: `/shaggy/export`,
                    method: "POST",
                    body: body,
                };
                return args;
            },
        }),
    }),
});

export const { endpoints, useGetExportMutation} = extendedApiSlice;
