import { RaiseUser } from "../hooks/raiseUser";
import { PlanDto, PlanTemplateDto } from "../hooks/slices/currentPlanSlice";
import BasicPlanImage from "../assets/images/plan-basic.png";
import FreePlanImage from "../assets/images/plan-free.png";
import StandardPlanImage from "../assets/images/plan-standard.png";
import ProfessionalPlanImage from "../assets/images/plan-professional.png";
import {Duration} from "../types/tender";

export namespace PlanUtil {

    export function getPlanTemplateName(planTemplateDto: PlanTemplateDto): string {
        let user = RaiseUser();
        switch (user.user?.locale) {
            case "nl":
                return planTemplateDto.dutchName;
            case "fr":
                return planTemplateDto.frenchName;
            default:
                return planTemplateDto.englishName;
        }
    }

    export function getNextPlanPrice(currentPlan: PlanDto, currentPlanTemplateDto: PlanTemplateDto | undefined, nextPlanTemplateDto: PlanTemplateDto | undefined): number {
        let nextPlanPrice: number
        if (nextPlanTemplateDto) {
            nextPlanPrice = nextPlanTemplateDto.price;
        } else if (currentPlanTemplateDto) {
            nextPlanPrice = currentPlanTemplateDto.price;
        } else {
            nextPlanPrice = currentPlan.price;
        }
        return nextPlanPrice;
    }

    export function getNextPlanName(currentPlan: PlanDto, currentPlanTemplateDto: PlanTemplateDto | undefined, nextPlanTemplateDto: PlanTemplateDto | undefined): string {
        let nextPlanName: string
        if (nextPlanTemplateDto) {
            nextPlanName = PlanUtil.getPlanTemplateName(nextPlanTemplateDto);
        } else if (currentPlanTemplateDto) {
            nextPlanName = PlanUtil.getPlanTemplateName(currentPlanTemplateDto);
        } else {
            nextPlanName = currentPlan.name;
        }
        return nextPlanName;
    }

    export function findPlanTemplate(id: number, availablePlanTemplates: PlanTemplateDto[]): PlanTemplateDto | undefined {
        if (id) {
            let filterPlanTemplates = availablePlanTemplates.filter((apt: PlanTemplateDto) => apt.id === id);
            if (filterPlanTemplates.length > 0) {
                return filterPlanTemplates[0];
            }
        }
    }

    export function getPlanImage(planTemplateDto: PlanTemplateDto | undefined) {
        if (planTemplateDto === undefined) {
            return BasicPlanImage;
        } else if (planTemplateDto.type === "FREE") {
            return FreePlanImage;
        } else {
            switch (planTemplateDto.englishName) {
                case "Basic":
                case "Basic Monthly":
                    return BasicPlanImage;
                case "Standard":
                case "Standard Monthly":
                    return StandardPlanImage;
                case "Professional":
                case "Professional Monthly":
                    return ProfessionalPlanImage;
                default:
                    return BasicPlanImage;//TODO: other image?
            }
        }
    }

    export function getPlanDuration(planName: string, planTemplates: PlanTemplateDto[]): Duration {
        let matchingPlans: PlanTemplateDto[] = planTemplates.filter(t => t.englishName.toLowerCase() === planName.toLowerCase());
        if (matchingPlans.length > 0) {
            return {type: matchingPlans[0].duration.type, amount: matchingPlans[0].duration.number};
        } return {type: "DAY", amount: 30}
    }

}