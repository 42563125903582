import {CodeType} from "../types/code";

export const RegionCodes: string[] = [
    // '00',
    '1A',
    'AD',
    'AE',
    'AF',
    'AG',
    'AI',
    'AL',
    'AL0',
    'AL01',
    'AL011',
    'AL012',
    'AL013',
    'AL014',
    'AL015',
    'AL02',
    'AL021',
    'AL022',
    'AL03',
    'AL031',
    'AL032',
    'AL033',
    'AL034',
    'AL035',
    'ALZ',
    'ALZZ',
    'ALZZZ',
    'AM',
    'AN',
    'AO',
    'AQ',
    'AR',
    'AS',
    'AT',
    'AT1',
    'AT11',
    'AT111',
    'AT112',
    'AT113',
    'AT12',
    'AT121',
    'AT122',
    'AT123',
    'AT124',
    'AT125',
    'AT126',
    'AT127',
    'AT13',
    'AT130',
    'AT2',
    'AT21',
    'AT211',
    'AT212',
    'AT213',
    'AT22',
    'AT221',
    'AT222',
    'AT223',
    'AT224',
    'AT225',
    'AT226',
    'AT3',
    'AT31',
    'AT311',
    'AT312',
    'AT313',
    'AT314',
    'AT315',
    'AT32',
    'AT321',
    'AT322',
    'AT323',
    'AT33',
    'AT331',
    'AT332',
    'AT333',
    'AT334',
    'AT335',
    'AT34',
    'AT341',
    'AT342',
    'ATZ',
    'ATZZ',
    'ATZZZ',
    'AU',
    'AW',
    'AX',
    'AZ',
    'BA',
    'BB',
    'BD',
    'BE',
    'BE1',
    'BE10',
    'BE100',
    'BE2',
    'BE21',
    'BE211',
    'BE212',
    'BE213',
    'BE22',
    'BE221',
    'BE222',
    'BE223',
    'BE224',
    'BE225',
    'BE23',
    'BE231',
    'BE232',
    'BE233',
    'BE234',
    'BE235',
    'BE236',
    'BE24',
    'BE241',
    'BE242',
    'BE25',
    'BE251',
    'BE252',
    'BE253',
    'BE254',
    'BE255',
    'BE256',
    'BE257',
    'BE258',
    'BE3',
    'BE31',
    'BE310',
    'BE32',
    'BE321',
    'BE322',
    'BE323',
    'BE324',
    'BE325',
    'BE326',
    'BE327',
    'BE328',
    'BE329',
    'BE32A',
    'BE32B',
    'BE32C',
    'BE32D',
    'BE33',
    'BE331',
    'BE332',
    'BE334',
    'BE335',
    'BE336',
    'BE34',
    'BE341',
    'BE342',
    'BE343',
    'BE344',
    'BE345',
    'BE35',
    'BE351',
    'BE352',
    'BE353',
    'BEZ',
    'BEZZ',
    'BEZZZ',
    'BF',
    'BG',
    'BG3',
    'BG31',
    'BG311',
    'BG312',
    'BG313',
    'BG314',
    'BG315',
    'BG32',
    'BG321',
    'BG322',
    'BG323',
    'BG324',
    'BG325',
    'BG33',
    'BG331',
    'BG332',
    'BG333',
    'BG334',
    'BG34',
    'BG341',
    'BG342',
    'BG343',
    'BG344',
    'BG4',
    'BG41',
    'BG411',
    'BG412',
    'BG413',
    'BG414',
    'BG415',
    'BG42',
    'BG421',
    'BG422',
    'BG423',
    'BG424',
    'BG425',
    'BGZ',
    'BGZZ',
    'BGZZZ',
    'BH',
    'BI',
    'BJ',
    'BL',
    'BM',
    'BN',
    'BO',
    'BQ',
    'BR',
    'BS',
    'BT',
    'BV',
    'BW',
    'BY',
    'BZ',
    'CA',
    'CC',
    'CD',
    'CF',
    'CG',
    'CH',
    'CH0',
    'CH01',
    'CH011',
    'CH012',
    'CH013',
    'CH02',
    'CH021',
    'CH022',
    'CH023',
    'CH024',
    'CH025',
    'CH03',
    'CH031',
    'CH032',
    'CH033',
    'CH04',
    'CH040',
    'CH05',
    'CH051',
    'CH052',
    'CH053',
    'CH054',
    'CH055',
    'CH056',
    'CH057',
    'CH06',
    'CH061',
    'CH062',
    'CH063',
    'CH064',
    'CH065',
    'CH066',
    'CH07',
    'CH070',
    'CHZ',
    'CHZZ',
    'CHZZZ',
    'CI',
    'CK',
    'CL',
    'CM',
    'CN',
    'CO',
    'CP',
    'CR',
    'CS',
    'CU',
    'CV',
    'CW',
    'CX',
    'CY',
    'CY0',
    'CY00',
    'CY000',
    'CYZ',
    'CYZZ',
    'CYZZZ',
    'CZ',
    'CZ0',
    'CZ01',
    'CZ010',
    'CZ02',
    'CZ020',
    'CZ03',
    'CZ031',
    'CZ032',
    'CZ04',
    'CZ041',
    'CZ042',
    'CZ05',
    'CZ051',
    'CZ052',
    'CZ053',
    'CZ06',
    'CZ063',
    'CZ064',
    'CZ07',
    'CZ071',
    'CZ072',
    'CZ08',
    'CZ080',
    'CZZ',
    'CZZZ',
    'CZZZZ',
    'DE',
    'DE1',
    'DE11',
    'DE111',
    'DE112',
    'DE113',
    'DE114',
    'DE115',
    'DE116',
    'DE117',
    'DE118',
    'DE119',
    'DE11A',
    'DE11B',
    'DE11C',
    'DE11D',
    'DE12',
    'DE121',
    'DE122',
    'DE123',
    'DE124',
    'DE125',
    'DE126',
    'DE127',
    'DE128',
    'DE129',
    'DE12A',
    'DE12B',
    'DE12C',
    'DE13',
    'DE131',
    'DE132',
    'DE133',
    'DE134',
    'DE135',
    'DE136',
    'DE137',
    'DE138',
    'DE139',
    'DE13A',
    'DE14',
    'DE141',
    'DE142',
    'DE143',
    'DE144',
    'DE145',
    'DE146',
    'DE147',
    'DE148',
    'DE149',
    'DE2',
    'DE21',
    'DE211',
    'DE212',
    'DE213',
    'DE214',
    'DE215',
    'DE216',
    'DE217',
    'DE218',
    'DE219',
    'DE21A',
    'DE21B',
    'DE21C',
    'DE21D',
    'DE21E',
    'DE21F',
    'DE21G',
    'DE21H',
    'DE21I',
    'DE21J',
    'DE21K',
    'DE21L',
    'DE21M',
    'DE21N',
    'DE22',
    'DE221',
    'DE222',
    'DE223',
    'DE224',
    'DE225',
    'DE226',
    'DE227',
    'DE228',
    'DE229',
    'DE22A',
    'DE22B',
    'DE22C',
    'DE23',
    'DE231',
    'DE232',
    'DE233',
    'DE234',
    'DE235',
    'DE236',
    'DE237',
    'DE238',
    'DE239',
    'DE23A',
    'DE24',
    'DE241',
    'DE242',
    'DE243',
    'DE244',
    'DE245',
    'DE246',
    'DE247',
    'DE248',
    'DE249',
    'DE24A',
    'DE24B',
    'DE24C',
    'DE24D',
    'DE25',
    'DE251',
    'DE252',
    'DE253',
    'DE254',
    'DE255',
    'DE256',
    'DE257',
    'DE258',
    'DE259',
    'DE25A',
    'DE25B',
    'DE25C',
    'DE26',
    'DE261',
    'DE262',
    'DE263',
    'DE264',
    'DE265',
    'DE266',
    'DE267',
    'DE268',
    'DE269',
    'DE26A',
    'DE26B',
    'DE26C',
    'DE27',
    'DE271',
    'DE272',
    'DE273',
    'DE274',
    'DE275',
    'DE276',
    'DE277',
    'DE278',
    'DE279',
    'DE27A',
    'DE27B',
    'DE27C',
    'DE27D',
    'DE27E',
    'DE3',
    'DE30',
    'DE300',
    'DE4',
    'DE40',
    'DE401',
    'DE402',
    'DE403',
    'DE404',
    'DE405',
    'DE406',
    'DE407',
    'DE408',
    'DE409',
    'DE40A',
    'DE40B',
    'DE40C',
    'DE40D',
    'DE40E',
    'DE40F',
    'DE40G',
    'DE40H',
    'DE40I',
    'DE41',
    'DE411',
    'DE412',
    'DE413',
    'DE414',
    'DE415',
    'DE416',
    'DE417',
    'DE418',
    'DE42',
    'DE421',
    'DE422',
    'DE423',
    'DE424',
    'DE425',
    'DE426',
    'DE427',
    'DE428',
    'DE429',
    'DE42A',
    'DE5',
    'DE50',
    'DE501',
    'DE502',
    'DE6',
    'DE60',
    'DE600',
    'DE7',
    'DE71',
    'DE711',
    'DE712',
    'DE713',
    'DE714',
    'DE715',
    'DE716',
    'DE717',
    'DE718',
    'DE719',
    'DE71A',
    'DE71B',
    'DE71C',
    'DE71D',
    'DE71E',
    'DE72',
    'DE721',
    'DE722',
    'DE723',
    'DE724',
    'DE725',
    'DE73',
    'DE731',
    'DE732',
    'DE733',
    'DE734',
    'DE735',
    'DE736',
    'DE737',
    'DE8',
    'DE80',
    'DE801',
    'DE802',
    'DE803',
    'DE804',
    'DE805',
    'DE806',
    'DE807',
    'DE808',
    'DE809',
    'DE80A',
    'DE80B',
    'DE80C',
    'DE80D',
    'DE80E',
    'DE80F',
    'DE80G',
    'DE80H',
    'DE80I',
    'DE80J',
    'DE80K',
    'DE80L',
    'DE80M',
    'DE80N',
    'DE80O',
    'DE9',
    'DE91',
    'DE911',
    'DE912',
    'DE913',
    'DE914',
    'DE915',
    'DE916',
    'DE917',
    'DE918',
    'DE919',
    'DE91A',
    'DE91B',
    'DE91C',
    'DE92',
    'DE922',
    'DE923',
    'DE925',
    'DE926',
    'DE927',
    'DE928',
    'DE929',
    'DE93',
    'DE931',
    'DE932',
    'DE933',
    'DE934',
    'DE935',
    'DE936',
    'DE937',
    'DE938',
    'DE939',
    'DE93A',
    'DE93B',
    'DE94',
    'DE941',
    'DE942',
    'DE943',
    'DE944',
    'DE945',
    'DE946',
    'DE947',
    'DE948',
    'DE949',
    'DE94A',
    'DE94B',
    'DE94C',
    'DE94D',
    'DE94E',
    'DE94F',
    'DE94G',
    'DE94H',
    'DEA',
    'DEA1',
    'DEA11',
    'DEA12',
    'DEA13',
    'DEA14',
    'DEA15',
    'DEA16',
    'DEA17',
    'DEA18',
    'DEA19',
    'DEA1A',
    'DEA1B',
    'DEA1C',
    'DEA1D',
    'DEA1E',
    'DEA1F',
    'DEA2',
    'DEA21',
    'DEA22',
    'DEA23',
    'DEA24',
    'DEA25',
    'DEA26',
    'DEA27',
    'DEA28',
    'DEA29',
    'DEA2A',
    'DEA2B',
    'DEA2C',
    'DEA2D',
    'DEA3',
    'DEA31',
    'DEA32',
    'DEA33',
    'DEA34',
    'DEA35',
    'DEA36',
    'DEA37',
    'DEA38',
    'DEA4',
    'DEA41',
    'DEA42',
    'DEA43',
    'DEA44',
    'DEA45',
    'DEA46',
    'DEA47',
    'DEA5',
    'DEA51',
    'DEA52',
    'DEA53',
    'DEA54',
    'DEA55',
    'DEA56',
    'DEA57',
    'DEA58',
    'DEA59',
    'DEA5A',
    'DEA5B',
    'DEA5C',
    'DEB',
    'DEB1',
    'DEB11',
    'DEB12',
    'DEB13',
    'DEB14',
    'DEB15',
    'DEB16',
    'DEB17',
    'DEB18',
    'DEB19',
    'DEB1A',
    'DEB1B',
    'DEB1C',
    'DEB1D',
    'DEB2',
    'DEB21',
    'DEB22',
    'DEB23',
    'DEB24',
    'DEB25',
    'DEB3',
    'DEB31',
    'DEB32',
    'DEB33',
    'DEB34',
    'DEB35',
    'DEB36',
    'DEB37',
    'DEB38',
    'DEB39',
    'DEB3A',
    'DEB3B',
    'DEB3C',
    'DEB3D',
    'DEB3E',
    'DEB3F',
    'DEB3G',
    'DEB3H',
    'DEB3I',
    'DEB3J',
    'DEB3K',
    'DEC',
    'DEC0',
    'DEC01',
    'DEC02',
    'DEC03',
    'DEC04',
    'DEC05',
    'DEC06',
    'DED',
    'DED1',
    'DED11',
    'DED12',
    'DED13',
    'DED14',
    'DED15',
    'DED16',
    'DED17',
    'DED18',
    'DED19',
    'DED1A',
    'DED1B',
    'DED1C',
    'DED2',
    'DED21',
    'DED22',
    'DED23',
    'DED24',
    'DED25',
    'DED26',
    'DED27',
    'DED28',
    'DED29',
    'DED2A',
    'DED2B',
    'DED2C',
    'DED2D',
    'DED2E',
    'DED2F',
    'DED3',
    'DED31',
    'DED32',
    'DED33',
    'DED34',
    'DED35',
    'DED36',
    'DED4',
    'DED41',
    'DED42',
    'DED43',
    'DED44',
    'DED45',
    'DED5',
    'DED51',
    'DED52',
    'DED53',
    'DEE',
    'DEE0',
    'DEE01',
    'DEE02',
    'DEE03',
    'DEE04',
    'DEE05',
    'DEE06',
    'DEE07',
    'DEE08',
    'DEE09',
    'DEE0A',
    'DEE0B',
    'DEE0C',
    'DEE0D',
    'DEE0E',
    'DEF',
    'DEF0',
    'DEF01',
    'DEF02',
    'DEF03',
    'DEF04',
    'DEF05',
    'DEF06',
    'DEF07',
    'DEF08',
    'DEF09',
    'DEF0A',
    'DEF0B',
    'DEF0C',
    'DEF0D',
    'DEF0E',
    'DEF0F',
    'DEG',
    'DEG0',
    'DEG01',
    'DEG02',
    'DEG03',
    'DEG04',
    'DEG05',
    'DEG06',
    'DEG07',
    'DEG09',
    'DEG0A',
    'DEG0B',
    'DEG0C',
    'DEG0D',
    'DEG0E',
    'DEG0F',
    'DEG0G',
    'DEG0H',
    'DEG0I',
    'DEG0J',
    'DEG0K',
    'DEG0L',
    'DEG0M',
    'DEG0N',
    'DEG0P',
    'DEZ',
    'DEZZ',
    'DEZZZ',
    'DJ',
    'DK',
    'DK0',
    'DK01',
    'DK011',
    'DK012',
    'DK013',
    'DK014',
    'DK02',
    'DK021',
    'DK022',
    'DK03',
    'DK031',
    'DK032',
    'DK04',
    'DK041',
    'DK042',
    'DK05',
    'DK050',
    'DKZ',
    'DKZZ',
    'DKZZZ',
    'DM',
    'DO',
    'DZ',
    'EC',
    'EE',
    'EE0',
    'EE00',
    'EE001',
    'EE004',
    'EE006',
    'EE007',
    'EE008',
    'EE009',
    'EE00A',
    'EEZ',
    'EEZZ',
    'EEZZZ',
    'EG',
    'EH',
    'EL',
    'EL3',
    'EL30',
    'EL301',
    'EL302',
    'EL303',
    'EL304',
    'EL305',
    'EL306',
    'EL307',
    'EL4',
    'EL41',
    'EL411',
    'EL412',
    'EL413',
    'EL42',
    'EL421',
    'EL422',
    'EL43',
    'EL431',
    'EL432',
    'EL433',
    'EL434',
    'EL5',
    'EL51',
    'EL511',
    'EL512',
    'EL513',
    'EL514',
    'EL515',
    'EL52',
    'EL521',
    'EL522',
    'EL523',
    'EL524',
    'EL525',
    'EL526',
    'EL527',
    'EL53',
    'EL531',
    'EL532',
    'EL533',
    'EL54',
    'EL541',
    'EL542',
    'EL543',
    'EL6',
    'EL61',
    'EL611',
    'EL612',
    'EL613',
    'EL62',
    'EL621',
    'EL622',
    'EL623',
    'EL624',
    'EL63',
    'EL631',
    'EL632',
    'EL633',
    'EL64',
    'EL641',
    'EL642',
    'EL643',
    'EL644',
    'EL645',
    'EL65',
    'EL651',
    'EL652',
    'EL653',
    'ELZ',
    'ELZZ',
    'ELZZZ',
    'ER',
    'ES',
    'ES1',
    'ES11',
    'ES111',
    'ES112',
    'ES113',
    'ES114',
    'ES12',
    'ES120',
    'ES13',
    'ES130',
    'ES2',
    'ES21',
    'ES211',
    'ES212',
    'ES213',
    'ES22',
    'ES220',
    'ES23',
    'ES230',
    'ES24',
    'ES241',
    'ES242',
    'ES243',
    'ES3',
    'ES30',
    'ES300',
    'ES4',
    'ES41',
    'ES411',
    'ES412',
    'ES413',
    'ES414',
    'ES415',
    'ES416',
    'ES417',
    'ES418',
    'ES419',
    'ES42',
    'ES421',
    'ES422',
    'ES423',
    'ES424',
    'ES425',
    'ES43',
    'ES431',
    'ES432',
    'ES5',
    'ES51',
    'ES511',
    'ES512',
    'ES513',
    'ES514',
    'ES52',
    'ES521',
    'ES522',
    'ES523',
    'ES53',
    'ES531',
    'ES532',
    'ES533',
    'ES6',
    'ES61',
    'ES611',
    'ES612',
    'ES613',
    'ES614',
    'ES615',
    'ES616',
    'ES617',
    'ES618',
    'ES62',
    'ES620',
    'ES63',
    'ES630',
    'ES64',
    'ES640',
    'ES7',
    'ES70',
    'ES703',
    'ES704',
    'ES705',
    'ES706',
    'ES707',
    'ES708',
    'ES709',
    'ESZ',
    'ESZZ',
    'ESZZZ',
    'ET',
    'FI',
    'FI1',
    'FI13',
    'FI131',
    'FI132',
    'FI133',
    'FI134',
    'FI18',
    'FI181',
    'FI182',
    'FI183',
    'FI184',
    'FI185',
    'FI186',
    'FI187',
    'FI19',
    'FI193',
    'FI194',
    'FI195',
    'FI196',
    'FI197',
    'FI1A',
    'FI1A1',
    'FI1A2',
    'FI1A3',
    'FI1B',
    'FI1B1',
    'FI1C',
    'FI1C1',
    'FI1C2',
    'FI1C3',
    'FI1C4',
    'FI1C5',
    'FI1D',
    'FI1D1',
    'FI1D2',
    'FI1D3',
    'FI1D5',
    'FI1D7',
    'FI1D8',
    'FI1D9',
    'FI2',
    'FI20',
    'FI200',
    'FIZ',
    'FIZZ',
    'FIZZZ',
    'FJ',
    'FK',
    'FM',
    'FO',
    'FR',
    'FR1',
    'FR10',
    'FR101',
    'FR102',
    'FR103',
    'FR104',
    'FR105',
    'FR106',
    'FR107',
    'FR108',
    'FR2',
    'FR21',
    'FR211',
    'FR212',
    'FR213',
    'FR214',
    'FR22',
    'FR221',
    'FR222',
    'FR223',
    'FR23',
    'FR231',
    'FR232',
    'FR24',
    'FR241',
    'FR242',
    'FR243',
    'FR244',
    'FR245',
    'FR246',
    'FR25',
    'FR251',
    'FR252',
    'FR253',
    'FR26',
    'FR261',
    'FR262',
    'FR263',
    'FR264',
    'FR3',
    'FR30',
    'FR301',
    'FR302',
    'FR4',
    'FR41',
    'FR411',
    'FR412',
    'FR413',
    'FR414',
    'FR42',
    'FR421',
    'FR422',
    'FR43',
    'FR431',
    'FR432',
    'FR433',
    'FR434',
    'FR5',
    'FR51',
    'FR511',
    'FR512',
    'FR513',
    'FR514',
    'FR515',
    'FR52',
    'FR521',
    'FR522',
    'FR523',
    'FR524',
    'FR53',
    'FR531',
    'FR532',
    'FR533',
    'FR534',
    'FR6',
    'FR61',
    'FR611',
    'FR612',
    'FR613',
    'FR614',
    'FR615',
    'FR62',
    'FR621',
    'FR622',
    'FR623',
    'FR624',
    'FR625',
    'FR626',
    'FR627',
    'FR628',
    'FR63',
    'FR631',
    'FR632',
    'FR633',
    'FR7',
    'FR71',
    'FR711',
    'FR712',
    'FR713',
    'FR714',
    'FR715',
    'FR716',
    'FR717',
    'FR718',
    'FR72',
    'FR721',
    'FR722',
    'FR723',
    'FR724',
    'FR8',
    'FR81',
    'FR811',
    'FR812',
    'FR813',
    'FR814',
    'FR815',
    'FR82',
    'FR821',
    'FR822',
    'FR823',
    'FR824',
    'FR825',
    'FR826',
    'FR83',
    'FR831',
    'FR832',
    'FR9',
    'FR91',
    'FR910',
    'FR92',
    'FR920',
    'FR93',
    'FR930',
    'FR94',
    'FR940',
    'FRB',
    'FRB0',
    'FRB01',
    'FRB02',
    'FRB03',
    'FRB04',
    'FRB05',
    'FRB06',
    'FRC',
    'FRC1',
    'FRC11',
    'FRC12',
    'FRC13',
    'FRC14',
    'FRC2',
    'FRC21',
    'FRC22',
    'FRC23',
    'FRC24',
    'FRD',
    'FRD1',
    'FRD11',
    'FRD12',
    'FRD13',
    'FRD2',
    'FRD21',
    'FRD22',
    'FRE',
    'FRE1',
    'FRE11',
    'FRE12',
    'FRE2',
    'FRE21',
    'FRE22',
    'FRE23',
    'FRF',
    'FRF1',
    'FRF11',
    'FRF12',
    'FRF2',
    'FRF21',
    'FRF22',
    'FRF23',
    'FRF24',
    'FRF3',
    'FRF31',
    'FRF32',
    'FRF33',
    'FRF34',
    'FRG',
    'FRG0',
    'FRG01',
    'FRG02',
    'FRG03',
    'FRG04',
    'FRG05',
    'FRH',
    'FRH0',
    'FRH01',
    'FRH02',
    'FRH03',
    'FRH04',
    'FRI',
    'FRI1',
    'FRI11',
    'FRI12',
    'FRI13',
    'FRI14',
    'FRI15',
    'FRI2',
    'FRI21',
    'FRI22',
    'FRI23',
    'FRI3',
    'FRI31',
    'FRI32',
    'FRI33',
    'FRI34',
    'FRJ',
    'FRJ1',
    'FRJ11',
    'FRJ12',
    'FRJ13',
    'FRJ14',
    'FRJ15',
    'FRJ2',
    'FRJ21',
    'FRJ22',
    'FRJ23',
    'FRJ24',
    'FRJ25',
    'FRJ26',
    'FRJ27',
    'FRJ28',
    'FRK',
    'FRK1',
    'FRK11',
    'FRK12',
    'FRK13',
    'FRK14',
    'FRK2',
    'FRK21',
    'FRK22',
    'FRK23',
    'FRK24',
    'FRK25',
    'FRK26',
    'FRK27',
    'FRK28',
    'FRL',
    'FRL0',
    'FRL01',
    'FRL02',
    'FRL03',
    'FRL04',
    'FRL05',
    'FRL06',
    'FRM',
    'FRM0',
    'FRM01',
    'FRM02',
    'FRY',
    'FRY1',
    'FRY10',
    'FRY2',
    'FRY20',
    'FRY3',
    'FRY30',
    'FRY4',
    'FRY40',
    'FRY5',
    'FRY50',
    'FRZ',
    'FRZZ',
    'FRZZZ',
    'GA',
    'GB',
    'GD',
    'GE',
    'GF',
    'GG',
    'GH',
    'GI',
    'GL',
    'GM',
    'GN',
    'GP',
    'GQ',
    'GR',
    'GR1',
    'GR11',
    'GR111',
    'GR112',
    'GR113',
    'GR114',
    'GR115',
    'GR12',
    'GR121',
    'GR122',
    'GR123',
    'GR124',
    'GR125',
    'GR126',
    'GR127',
    'GR13',
    'GR131',
    'GR132',
    'GR133',
    'GR134',
    'GR14',
    'GR141',
    'GR142',
    'GR143',
    'GR144',
    'GR2',
    'GR21',
    'GR211',
    'GR212',
    'GR213',
    'GR214',
    'GR22',
    'GR221',
    'GR222',
    'GR223',
    'GR224',
    'GR23',
    'GR231',
    'GR232',
    'GR233',
    'GR24',
    'GR241',
    'GR242',
    'GR243',
    'GR244',
    'GR245',
    'GR25',
    'GR251',
    'GR252',
    'GR253',
    'GR254',
    'GR255',
    'GR3',
    'GR30',
    'GR300',
    'GR4',
    'GR41',
    'GR411',
    'GR412',
    'GR413',
    'GR42',
    'GR421',
    'GR422',
    'GR43',
    'GR431',
    'GR432',
    'GR433',
    'GR434',
    'GRZ',
    'GRZZ',
    'GRZZZ',
    'GS',
    'GT',
    'GU',
    'GW',
    'GY',
    'HK',
    'HM',
    'HN',
    'HR',
    'HR0',
    'HR01',
    'HR011',
    'HR012',
    'HR013',
    'HR014',
    'HR015',
    'HR016',
    'HR02',
    'HR021',
    'HR022',
    'HR023',
    'HR024',
    'HR025',
    'HR026',
    'HR027',
    'HR028',
    'HR03',
    'HR031',
    'HR032',
    'HR033',
    'HR034',
    'HR035',
    'HR036',
    'HR037',
    'HR04',
    'HR041',
    'HR042',
    'HR043',
    'HR044',
    'HR045',
    'HR046',
    'HR047',
    'HR048',
    'HR049',
    'HR04A',
    'HR04B',
    'HR04C',
    'HR04D',
    'HR04E',
    'HR05',
    'HR050',
    'HR06',
    'HR061',
    'HR062',
    'HR063',
    'HR064',
    'HR065',
    'HRZ',
    'HRZZ',
    'HRZZZ',
    'HT',
    'HU',
    'HU1',
    'HU10',
    'HU101',
    'HU102',
    'HU11',
    'HU110',
    'HU12',
    'HU120',
    'HU2',
    'HU21',
    'HU211',
    'HU212',
    'HU213',
    'HU22',
    'HU221',
    'HU222',
    'HU223',
    'HU23',
    'HU231',
    'HU232',
    'HU233',
    'HU3',
    'HU31',
    'HU311',
    'HU312',
    'HU313',
    'HU32',
    'HU321',
    'HU322',
    'HU323',
    'HU33',
    'HU331',
    'HU332',
    'HU333',
    'HUZ',
    'HUZZ',
    'HUZZZ',
    'ID',
    'IE',
    'IE0',
    'IE01',
    'IE011',
    'IE012',
    'IE013',
    'IE02',
    'IE021',
    'IE022',
    'IE023',
    'IE024',
    'IE025',
    'IE04',
    'IE041',
    'IE042',
    'IE05',
    'IE051',
    'IE052',
    'IE053',
    'IE06',
    'IE061',
    'IE062',
    'IE063',
    'IEZ',
    'IEZZ',
    'IEZZZ',
    'IL',
    'IM',
    'IN',
    'IO',
    'IQ',
    'IR',
    'IS',
    'IS0',
    'IS00',
    'IS001',
    'IS002',
    'ISZ',
    'ISZZ',
    'ISZZZ',
    'IT',
    'ITC',
    'ITC1',
    'ITC11',
    'ITC12',
    'ITC13',
    'ITC14',
    'ITC15',
    'ITC16',
    'ITC17',
    'ITC18',
    'ITC2',
    'ITC20',
    'ITC3',
    'ITC31',
    'ITC32',
    'ITC33',
    'ITC34',
    'ITC4',
    'ITC41',
    'ITC42',
    'ITC43',
    'ITC44',
    'ITC45',
    'ITC46',
    'ITC47',
    'ITC48',
    'ITC49',
    'ITC4A',
    'ITC4B',
    'ITC4C',
    'ITC4D',
    'ITD',
    'ITD1',
    'ITD10',
    'ITD2',
    'ITD20',
    'ITD3',
    'ITD31',
    'ITD32',
    'ITD33',
    'ITD34',
    'ITD35',
    'ITD36',
    'ITD37',
    'ITD4',
    'ITD41',
    'ITD42',
    'ITD43',
    'ITD44',
    'ITD5',
    'ITD51',
    'ITD52',
    'ITD53',
    'ITD54',
    'ITD55',
    'ITD56',
    'ITD57',
    'ITD58',
    'ITD59',
    'ITE',
    'ITE1',
    'ITE11',
    'ITE12',
    'ITE13',
    'ITE14',
    'ITE15',
    'ITE16',
    'ITE17',
    'ITE18',
    'ITE19',
    'ITE1A',
    'ITE2',
    'ITE21',
    'ITE22',
    'ITE3',
    'ITE31',
    'ITE32',
    'ITE33',
    'ITE34',
    'ITE4',
    'ITE41',
    'ITE42',
    'ITE43',
    'ITE44',
    'ITE45',
    'ITF',
    'ITF1',
    'ITF11',
    'ITF12',
    'ITF13',
    'ITF14',
    'ITF2',
    'ITF21',
    'ITF22',
    'ITF3',
    'ITF31',
    'ITF32',
    'ITF33',
    'ITF34',
    'ITF35',
    'ITF4',
    'ITF41',
    'ITF42',
    'ITF43',
    'ITF44',
    'ITF45',
    'ITF46',
    'ITF47',
    'ITF48',
    'ITF5',
    'ITF51',
    'ITF52',
    'ITF6',
    'ITF61',
    'ITF62',
    'ITF63',
    'ITF64',
    'ITF65',
    'ITG',
    'ITG1',
    'ITG11',
    'ITG12',
    'ITG13',
    'ITG14',
    'ITG15',
    'ITG16',
    'ITG17',
    'ITG18',
    'ITG19',
    'ITG2',
    'ITG25',
    'ITG26',
    'ITG27',
    'ITG28',
    'ITG29',
    'ITG2A',
    'ITG2B',
    'ITG2C',
    'ITG2D',
    'ITG2E',
    'ITG2F',
    'ITG2G',
    'ITG2H',
    'ITH',
    'ITH1',
    'ITH10',
    'ITH2',
    'ITH20',
    'ITH3',
    'ITH31',
    'ITH32',
    'ITH33',
    'ITH34',
    'ITH35',
    'ITH36',
    'ITH37',
    'ITH4',
    'ITH41',
    'ITH42',
    'ITH43',
    'ITH44',
    'ITH5',
    'ITH51',
    'ITH52',
    'ITH53',
    'ITH54',
    'ITH55',
    'ITH56',
    'ITH57',
    'ITH58',
    'ITH59',
    'ITI',
    'ITI1',
    'ITI11',
    'ITI12',
    'ITI13',
    'ITI14',
    'ITI15',
    'ITI16',
    'ITI17',
    'ITI18',
    'ITI19',
    'ITI1A',
    'ITI2',
    'ITI21',
    'ITI22',
    'ITI3',
    'ITI31',
    'ITI32',
    'ITI33',
    'ITI34',
    'ITI35',
    'ITI4',
    'ITI41',
    'ITI42',
    'ITI43',
    'ITI44',
    'ITI45',
    'ITZ',
    'ITZZ',
    'ITZZZ',
    'JE',
    'JM',
    'JO',
    'JP',
    'KE',
    'KG',
    'KH',
    'KI',
    'KM',
    'KN',
    'KP',
    'KR',
    'KW',
    'KY',
    'KZ',
    'LA',
    'LB',
    'LC',
    'LI',
    'LI0',
    'LI00',
    'LI000',
    'LIZ',
    'LIZZ',
    'LIZZZ',
    'LK',
    'LR',
    'LS',
    'LT',
    'LT0',
    'LT00',
    'LT001',
    'LT002',
    'LT003',
    'LT004',
    'LT005',
    'LT006',
    'LT007',
    'LT008',
    'LT009',
    'LT00A',
    'LT01',
    'LT011',
    'LT02',
    'LT021',
    'LT022',
    'LT023',
    'LT024',
    'LT025',
    'LT026',
    'LT027',
    'LT028',
    'LT029',
    'LTZ',
    'LTZZ',
    'LTZZZ',
    'LU',
    'LU0',
    'LU00',
    'LU000',
    'LUZ',
    'LUZZ',
    'LUZZZ',
    'LV',
    'LV0',
    'LV00',
    'LV003',
    'LV005',
    'LV006',
    'LV007',
    'LV008',
    'LV009',
    'LVZ',
    'LVZZ',
    'LVZZZ',
    'LY',
    'MA',
    'MC',
    'MD',
    'ME',
    'ME0',
    'ME00',
    'ME000',
    'MEZ',
    'MEZZ',
    'MEZZZ',
    'MF',
    'MG',
    'MH',
    'MK',
    'MK0',
    'MK00',
    'MK001',
    'MK002',
    'MK003',
    'MK004',
    'MK005',
    'MK006',
    'MK007',
    'MK008',
    'MKZ',
    'MKZZ',
    'MKZZZ',
    'ML',
    'MM',
    'MN',
    'MO',
    'MP',
    'MQ',
    'MR',
    'MS',
    'MT',
    'MT0',
    'MT00',
    'MT001',
    'MT002',
    'MTZ',
    'MTZZ',
    'MTZZZ',
    'MU',
    'MV',
    'MW',
    'MX',
    'MY',
    'MZ',
    'NA',
    'NC',
    'NE',
    'NF',
    'NG',
    'NI',
    'NL',
    'NL1',
    'NL11',
    'NL111',
    'NL112',
    'NL113',
    'NL12',
    'NL121',
    'NL122',
    'NL123',
    'NL124',
    'NL125',
    'NL126',
    'NL13',
    'NL131',
    'NL132',
    'NL133',
    'NL2',
    'NL21',
    'NL211',
    'NL212',
    'NL213',
    'NL22',
    'NL221',
    'NL224',
    'NL225',
    'NL226',
    'NL23',
    'NL230',
    'NL3',
    'NL31',
    'NL310',
    'NL32',
    'NL321',
    'NL322',
    'NL323',
    'NL324',
    'NL325',
    'NL326',
    'NL327',
    'NL328',
    'NL329',
    'NL33',
    'NL331',
    'NL332',
    'NL333',
    'NL334',
    'NL335',
    'NL336',
    'NL337',
    'NL33A',
    'NL33B',
    'NL33C',
    'NL34',
    'NL341',
    'NL342',
    'NL4',
    'NL41',
    'NL411',
    'NL412',
    'NL413',
    'NL414',
    'NL42',
    'NL421',
    'NL422',
    'NL423',
    'NLZ',
    'NLZZ',
    'NLZZZ',
    'NO',
    'NO0',
    'NO01',
    'NO011',
    'NO012',
    'NO02',
    'NO020',
    'NO021',
    'NO022',
    'NO03',
    'NO031',
    'NO032',
    'NO033',
    'NO034',
    'NO04',
    'NO041',
    'NO042',
    'NO043',
    'NO05',
    'NO051',
    'NO052',
    'NO053',
    'NO06',
    'NO060',
    'NO061',
    'NO062',
    'NO07',
    'NO071',
    'NO072',
    'NO073',
    'NO074',
    'NO08',
    'NO081',
    'NO082',
    'NO09',
    'NO091',
    'NO092',
    'NO0A',
    'NO0A1',
    'NO0A2',
    'NO0A3',
    'NO0B',
    'NO0B1',
    'NO0B2',
    'NOZ',
    'NOZZ',
    'NOZZZ',
    'NP',
    'NR',
    'NU',
    'NZ',
    'OM',
    'PA',
    'PE',
    'PF',
    'PG',
    'PH',
    'PK',
    'PL',
    'PL1',
    'PL11',
    'PL113',
    'PL114',
    'PL115',
    'PL116',
    'PL117',
    'PL12',
    'PL121',
    'PL122',
    'PL127',
    'PL128',
    'PL129',
    'PL12A',
    'PL2',
    'PL21',
    'PL213',
    'PL214',
    'PL215',
    'PL216',
    'PL217',
    'PL218',
    'PL219',
    'PL21A',
    'PL22',
    'PL224',
    'PL225',
    'PL227',
    'PL228',
    'PL229',
    'PL22A',
    'PL22B',
    'PL22C',
    'PL3',
    'PL31',
    'PL311',
    'PL312',
    'PL314',
    'PL315',
    'PL32',
    'PL323',
    'PL324',
    'PL325',
    'PL326',
    'PL33',
    'PL331',
    'PL332',
    'PL34',
    'PL343',
    'PL344',
    'PL345',
    'PL4',
    'PL41',
    'PL411',
    'PL414',
    'PL415',
    'PL416',
    'PL417',
    'PL418',
    'PL42',
    'PL422',
    'PL423',
    'PL424',
    'PL425',
    'PL426',
    'PL427',
    'PL428',
    'PL43',
    'PL431',
    'PL432',
    'PL5',
    'PL51',
    'PL514',
    'PL515',
    'PL516',
    'PL517',
    'PL518',
    'PL52',
    'PL521',
    'PL522',
    'PL523',
    'PL524',
    'PL6',
    'PL61',
    'PL613',
    'PL614',
    'PL615',
    'PL616',
    'PL617',
    'PL618',
    'PL619',
    'PL62',
    'PL621',
    'PL622',
    'PL623',
    'PL63',
    'PL631',
    'PL633',
    'PL634',
    'PL635',
    'PL636',
    'PL637',
    'PL638',
    'PL7',
    'PL71',
    'PL711',
    'PL712',
    'PL713',
    'PL714',
    'PL715',
    'PL72',
    'PL721',
    'PL722',
    'PL8',
    'PL81',
    'PL811',
    'PL812',
    'PL814',
    'PL815',
    'PL82',
    'PL821',
    'PL822',
    'PL823',
    'PL824',
    'PL84',
    'PL841',
    'PL842',
    'PL843',
    'PL9',
    'PL91',
    'PL911',
    'PL912',
    'PL913',
    'PL92',
    'PL921',
    'PL922',
    'PL923',
    'PL924',
    'PL925',
    'PL926',
    'PLZ',
    'PLZZ',
    'PLZZZ',
    'PM',
    'PN',
    'PR',
    'PS',
    'PT',
    'PT1',
    'PT11',
    'PT111',
    'PT112',
    'PT113',
    'PT114',
    'PT115',
    'PT116',
    'PT117',
    'PT118',
    'PT119',
    'PT11A',
    'PT11B',
    'PT11C',
    'PT11D',
    'PT11E',
    'PT15',
    'PT150',
    'PT16',
    'PT161',
    'PT162',
    'PT163',
    'PT164',
    'PT165',
    'PT166',
    'PT167',
    'PT168',
    'PT169',
    'PT16A',
    'PT16B',
    'PT16C',
    'PT16D',
    'PT16E',
    'PT16F',
    'PT16G',
    'PT16H',
    'PT16I',
    'PT16J',
    'PT17',
    'PT170',
    'PT171',
    'PT172',
    'PT18',
    'PT181',
    'PT182',
    'PT183',
    'PT184',
    'PT185',
    'PT186',
    'PT187',
    'PT2',
    'PT20',
    'PT200',
    'PT3',
    'PT30',
    'PT300',
    'PTZ',
    'PTZZ',
    'PTZZZ',
    'PW',
    'PY',
    'QA',
    'RE',
    'RO',
    'RO1',
    'RO11',
    'RO111',
    'RO112',
    'RO113',
    'RO114',
    'RO115',
    'RO116',
    'RO12',
    'RO121',
    'RO122',
    'RO123',
    'RO124',
    'RO125',
    'RO126',
    'RO2',
    'RO21',
    'RO211',
    'RO212',
    'RO213',
    'RO214',
    'RO215',
    'RO216',
    'RO22',
    'RO221',
    'RO222',
    'RO223',
    'RO224',
    'RO225',
    'RO226',
    'RO3',
    'RO31',
    'RO311',
    'RO312',
    'RO313',
    'RO314',
    'RO315',
    'RO316',
    'RO317',
    'RO32',
    'RO321',
    'RO322',
    'RO4',
    'RO41',
    'RO411',
    'RO412',
    'RO413',
    'RO414',
    'RO415',
    'RO42',
    'RO421',
    'RO422',
    'RO423',
    'RO424',
    'ROZ',
    'ROZZ',
    'ROZZZ',
    'RS',
    'RS1',
    'RS11',
    'RS110',
    'RS12',
    'RS121',
    'RS122',
    'RS123',
    'RS124',
    'RS125',
    'RS126',
    'RS127',
    'RS2',
    'RS21',
    'RS211',
    'RS212',
    'RS213',
    'RS214',
    'RS215',
    'RS216',
    'RS217',
    'RS218',
    'RS22',
    'RS221',
    'RS222',
    'RS223',
    'RS224',
    'RS225',
    'RS226',
    'RS227',
    'RS228',
    'RS229',
    'RSZ',
    'RSZZ',
    'RSZZZ',
    'RU',
    'RW',
    'SA',
    'SB',
    'SC',
    'SD',
    'SE',
    'SE1',
    'SE11',
    'SE110',
    'SE12',
    'SE121',
    'SE122',
    'SE123',
    'SE124',
    'SE125',
    'SE2',
    'SE21',
    'SE211',
    'SE212',
    'SE213',
    'SE214',
    'SE22',
    'SE221',
    'SE224',
    'SE23',
    'SE231',
    'SE232',
    'SE3',
    'SE31',
    'SE311',
    'SE312',
    'SE313',
    'SE32',
    'SE321',
    'SE322',
    'SE33',
    'SE331',
    'SE332',
    'SEZ',
    'SEZZ',
    'SEZZZ',
    'SG',
    'SH',
    'SI',
    'SI0',
    'SI01',
    'SI011',
    'SI012',
    'SI013',
    'SI014',
    'SI015',
    'SI016',
    'SI017',
    'SI018',
    'SI02',
    'SI021',
    'SI022',
    'SI023',
    'SI024',
    'SI03',
    'SI031',
    'SI032',
    'SI033',
    'SI034',
    'SI035',
    'SI036',
    'SI037',
    'SI038',
    'SI04',
    'SI041',
    'SI042',
    'SI043',
    'SI044',
    'SIZ',
    'SIZZ',
    'SIZZZ',
    'SJ',
    'SK',
    'SK0',
    'SK01',
    'SK010',
    'SK02',
    'SK021',
    'SK022',
    'SK023',
    'SK03',
    'SK031',
    'SK032',
    'SK04',
    'SK041',
    'SK042',
    'SKZ',
    'SKZZ',
    'SKZZZ',
    'SL',
    'SM',
    'SN',
    'SO',
    'SR',
    'SS',
    'ST',
    'SV',
    'SX',
    'SY',
    'SZ',
    'TC',
    'TD',
    'TF',
    'TG',
    'TH',
    'TJ',
    'TK',
    'TL',
    'TM',
    'TN',
    'TO',
    'TR',
    'TR1',
    'TR10',
    'TR100',
    'TR2',
    'TR21',
    'TR211',
    'TR212',
    'TR213',
    'TR22',
    'TR221',
    'TR222',
    'TR3',
    'TR31',
    'TR310',
    'TR32',
    'TR321',
    'TR322',
    'TR323',
    'TR33',
    'TR331',
    'TR332',
    'TR333',
    'TR334',
    'TR4',
    'TR41',
    'TR411',
    'TR412',
    'TR413',
    'TR42',
    'TR421',
    'TR422',
    'TR423',
    'TR424',
    'TR425',
    'TR5',
    'TR51',
    'TR510',
    'TR52',
    'TR521',
    'TR522',
    'TR6',
    'TR61',
    'TR611',
    'TR612',
    'TR613',
    'TR62',
    'TR621',
    'TR622',
    'TR63',
    'TR631',
    'TR632',
    'TR633',
    'TR7',
    'TR71',
    'TR711',
    'TR712',
    'TR713',
    'TR714',
    'TR715',
    'TR72',
    'TR721',
    'TR722',
    'TR723',
    'TR8',
    'TR81',
    'TR811',
    'TR812',
    'TR813',
    'TR82',
    'TR821',
    'TR822',
    'TR823',
    'TR83',
    'TR831',
    'TR832',
    'TR833',
    'TR834',
    'TR9',
    'TR90',
    'TR901',
    'TR902',
    'TR903',
    'TR904',
    'TR905',
    'TR906',
    'TRA',
    'TRA1',
    'TRA11',
    'TRA12',
    'TRA13',
    'TRA2',
    'TRA21',
    'TRA22',
    'TRA23',
    'TRA24',
    'TRB',
    'TRB1',
    'TRB11',
    'TRB12',
    'TRB13',
    'TRB14',
    'TRB2',
    'TRB21',
    'TRB22',
    'TRB23',
    'TRB24',
    'TRC',
    'TRC1',
    'TRC11',
    'TRC12',
    'TRC13',
    'TRC2',
    'TRC21',
    'TRC22',
    'TRC3',
    'TRC31',
    'TRC32',
    'TRC33',
    'TRC34',
    'TRZ',
    'TRZZ',
    'TRZZZ',
    'TT',
    'TV',
    'TW',
    'TZ',
    'UA',
    'UG',
    'UK',
    'UKC',
    'UKC1',
    'UKC11',
    'UKC12',
    'UKC13',
    'UKC14',
    'UKC2',
    'UKC21',
    'UKC22',
    'UKC23',
    'UKD',
    'UKD1',
    'UKD11',
    'UKD12',
    'UKD2',
    'UKD21',
    'UKD22',
    'UKD3',
    'UKD31',
    'UKD32',
    'UKD33',
    'UKD34',
    'UKD35',
    'UKD36',
    'UKD37',
    'UKD4',
    'UKD41',
    'UKD42',
    'UKD43',
    'UKD44',
    'UKD45',
    'UKD46',
    'UKD47',
    'UKD5',
    'UKD51',
    'UKD52',
    'UKD53',
    'UKD54',
    'UKD6',
    'UKD61',
    'UKD62',
    'UKD63',
    'UKD7',
    'UKD71',
    'UKD72',
    'UKD73',
    'UKD74',
    'UKE',
    'UKE1',
    'UKE11',
    'UKE12',
    'UKE13',
    'UKE2',
    'UKE21',
    'UKE22',
    'UKE3',
    'UKE31',
    'UKE32',
    'UKE4',
    'UKE41',
    'UKE42',
    'UKE43',
    'UKE44',
    'UKE45',
    'UKF',
    'UKF1',
    'UKF11',
    'UKF12',
    'UKF13',
    'UKF14',
    'UKF15',
    'UKF16',
    'UKF2',
    'UKF21',
    'UKF22',
    'UKF23',
    'UKF24',
    'UKF25',
    'UKF3',
    'UKF30',
    'UKG',
    'UKG1',
    'UKG11',
    'UKG12',
    'UKG13',
    'UKG2',
    'UKG21',
    'UKG22',
    'UKG23',
    'UKG24',
    'UKG3',
    'UKG31',
    'UKG32',
    'UKG33',
    'UKG34',
    'UKG35',
    'UKG36',
    'UKG37',
    'UKG38',
    'UKG39',
    'UKH',
    'UKH1',
    'UKH11',
    'UKH12',
    'UKH13',
    'UKH14',
    'UKH15',
    'UKH16',
    'UKH17',
    'UKH2',
    'UKH21',
    'UKH22',
    'UKH23',
    'UKH24',
    'UKH25',
    'UKH3',
    'UKH31',
    'UKH32',
    'UKH33',
    'UKH34',
    'UKH35',
    'UKH36',
    'UKH37',
    'UKI',
    'UKI1',
    'UKI11',
    'UKI12',
    'UKI2',
    'UKI21',
    'UKI22',
    'UKI23',
    'UKI3',
    'UKI31',
    'UKI32',
    'UKI33',
    'UKI34',
    'UKI4',
    'UKI41',
    'UKI42',
    'UKI43',
    'UKI44',
    'UKI45',
    'UKI5',
    'UKI51',
    'UKI52',
    'UKI53',
    'UKI54',
    'UKI6',
    'UKI61',
    'UKI62',
    'UKI63',
    'UKI7',
    'UKI71',
    'UKI72',
    'UKI73',
    'UKI74',
    'UKI75',
    'UKJ',
    'UKJ1',
    'UKJ11',
    'UKJ12',
    'UKJ13',
    'UKJ14',
    'UKJ2',
    'UKJ21',
    'UKJ22',
    'UKJ23',
    'UKJ24',
    'UKJ25',
    'UKJ26',
    'UKJ27',
    'UKJ28',
    'UKJ3',
    'UKJ31',
    'UKJ32',
    'UKJ33',
    'UKJ34',
    'UKJ35',
    'UKJ36',
    'UKJ37',
    'UKJ4',
    'UKJ41',
    'UKJ42',
    'UKJ43',
    'UKJ44',
    'UKJ45',
    'UKJ46',
    'UKK',
    'UKK1',
    'UKK11',
    'UKK12',
    'UKK13',
    'UKK14',
    'UKK15',
    'UKK2',
    'UKK21',
    'UKK22',
    'UKK23',
    'UKK24',
    'UKK25',
    'UKK3',
    'UKK30',
    'UKK4',
    'UKK41',
    'UKK42',
    'UKK43',
    'UKL',
    'UKL1',
    'UKL11',
    'UKL12',
    'UKL13',
    'UKL14',
    'UKL15',
    'UKL16',
    'UKL17',
    'UKL18',
    'UKL2',
    'UKL21',
    'UKL22',
    'UKL23',
    'UKL24',
    'UKM',
    'UKM2',
    'UKM21',
    'UKM22',
    'UKM23',
    'UKM24',
    'UKM25',
    'UKM26',
    'UKM27',
    'UKM28',
    'UKM3',
    'UKM31',
    'UKM32',
    'UKM33',
    'UKM34',
    'UKM35',
    'UKM36',
    'UKM37',
    'UKM38',
    'UKM5',
    'UKM50',
    'UKM6',
    'UKM61',
    'UKM62',
    'UKM63',
    'UKM64',
    'UKM65',
    'UKM66',
    'UKM7',
    'UKM71',
    'UKM72',
    'UKM73',
    'UKM75',
    'UKM76',
    'UKM77',
    'UKM78',
    'UKM8',
    'UKM81',
    'UKM82',
    'UKM83',
    'UKM84',
    'UKM9',
    'UKM91',
    'UKM92',
    'UKM93',
    'UKM94',
    'UKM95',
    'UKN',
    'UKN0',
    'UKN01',
    'UKN02',
    'UKN03',
    'UKN04',
    'UKN05',
    'UKN06',
    'UKN07',
    'UKN08',
    'UKN09',
    'UKN0A',
    'UKN0B',
    'UKN0C',
    'UKN0D',
    'UKN0E',
    'UKN0F',
    'UKN0G',
    'UKN10',
    'UKN11',
    'UKN12',
    'UKN13',
    'UKN14',
    'UKN15',
    'UKN16',
    'UKZ',
    'UKZZ',
    'UKZZZ',
    'UM',
    'US',
    'UY',
    'UZ',
    'VA',
    'VC',
    'VE',
    'VG',
    'VI',
    'VN',
    'VU',
    'WF',
    'WS',
    'YE',
    'YT',
    'ZA',
    'ZM',
    'ZW',

];