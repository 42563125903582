import {apiSlice} from "./apiSlice";

export interface PutPasswordParams {
    uuid: string,
    body: PutPasswordBody;
}

export interface PutPasswordBody {
    oldPassword: string;
    newPassword: string;
    newPasswordRepeated: string;
}

const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        putPassword: build.mutation<void, PutPasswordParams>({
            query: (params: PutPasswordParams) => {
                return {
                    url: `shaggy/user/password`,
                    method: 'PUT',
                    body: params.body
                }
            }
        }),
    }),
});

export const {usePutPasswordMutation} = extendedApiSlice;