import { ResourceKey } from "i18next";

export const ContractingAuthorityMainActivities: ResourceKey = {
    GENERAL_PUBLIC_SERVICES: "Algemene overheidsdiensten",
    EDUCATION: "Onderwijs",
    RAILWAY_SERVICES: "Vervoersdiensten per spoor",
    PRODUCTION_TRANSPORT_DISTRIBUTION_GAS_HEAT: "Productie, vervoer en distributie van gas en warmte",
    ELECTRICITY: "Elektriciteit",
    HEALTH: "Gezondheid",
    PUBLIC_ORDER_AND_SAFETY: "Openbare orde en veiligheid",
    URBAN_RAILWAY_TRAMWAY_TROLLEYBUS_BUS_SERVICES: "Stadsspoorweg-, tram-, trolleybus- of busdiensten",
    AIRPORT_RELATED_ACTIVITIES: "Luchthavenactiviteiten",
    WATER: "Water",
    POSTAL_SERVICES: "Postdiensten",
    DEFENCE: "Defensie",
    RECREATION_CULTURE_AND_RELIGION: "Recreatie, cultuur en godsdienst",
    HOUSING_AND_COMMUNITY_AMENITIES: "Huisvesting en gemeenschappelijke voorzieningen",
    PORT_RELATED_ACTIVITIES: "Havenactiviteiten",
    OTHER: "Andere",
    SOCIAL_PROTECTION: "Sociale bescherming",
    EXPLORATION_EXTRACTION_GAS_OIL: "Winning van gas en aardolie",
    ENVIRONMENT: "Milieu",
    EXPLORATION_EXTRACTION_COAL_OTHER_SOLID_FUEL: "Opsporing en winning van steenkool en andere vaste brandstoffen",
    URBAN_RAILWAY_OR_BUS_SERVICES: "Stadsspoorweg- of busdiensten",
    ECONOMIC_AFFAIRS: "Economische activiteiten",
    RCR: "Recreatie, cultuur en godsdienst",
    HC_AM: "Huisvesting en gemeenschappelijke voorzieningen",
    PUB_OS: "Openbare orde en veiligheid",
    ECON_AFF: "Economische zaken",
    ENV_PRO: "Milieubescherming",
    SOC_PRO: "Sociale bescherming",
    GEN_PUB: "Algemene overheidsdiensten",
    POST: "Postdiensten",
    AIRPORT: "Luchthavenactiviteiten",
    GAS_OIL: "Aardolie- en aardgaswinning",
    URTTB: "Stadsspoorweg-, tram-, trolleybus- of busdiensten",
    SOLID_FUEL: "Exploratie of winning van steenkool of andere vaste brandstoffen",
    RAIL: "Spoorwegdiensten",
    PORT: "Havenactiviteiten",
    GAS_HEAT: "Productie, transport of distributie van gas of warmte",


};
