import React, { useEffect, useState } from "react";
import "./signup-keywords.scss";
import { useAppDispatch } from "../../../app/hooks";
import { useNavigate } from "react-router-dom";
import { showErrorPopup } from "../../../hooks/slices/snaccSlice";
import { useTranslation } from "react-i18next";
import { Form } from "../../../components/form/Form";
import { usePostSignupKeywordsMutation } from "../../../hooks/slices/signupSlice";
import { updateActivityStatus } from "../../../hooks/slices/userSlice";
import {Loader} from "../../../components/loader";
import {RaiseClient} from "../../../hooks/raiseClient";
import { useSignupErrorHandler } from "../SignupErrorHandler";
import { useNavigateToOpportunitiesIfSignupAlreadyFinished } from "../SignupFinishedHandler";

export const SignupKeywords: React.FC = () => {
    useNavigateToOpportunitiesIfSignupAlreadyFinished();
    const dispatch = useAppDispatch();
    const clientInStore = RaiseClient();
    const [showLoader, setShowLoader] = useState(false);
    const [postSignupKeywords, {isSuccess, isError, error}] = usePostSignupKeywordsMutation();
    const navigate = useNavigate();
    const onSubmit = (cues: string[], activityDescription: string) => {
        setShowLoader(true);
        cues = cues.filter(kw => kw !== null).filter(kw => kw !== "");
        postSignupKeywords({cues, activityDescription});
    };
    const navigateOrShowPopup = useSignupErrorHandler();

    useEffect(() => {
        if (isSuccess) {
            setShowLoader(false);
            dispatch(updateActivityStatus({activityStatus: "PENDING"}));
            navigate("/signup-pending");
        } else if (isError && error) {
            setShowLoader(false);
            navigateOrShowPopup(error);
        }
    }, [isSuccess, isError, error, dispatch]);
//const [showLoader, setShowLoader] = useState(false);
// {showLoader && <Loader/>}
    return <>
            {showLoader && <Loader/>}
            <SignupKeywordsForm onSubmit={onSubmit}/>
        </>
};

interface SignupKeywordsFormProps {
    onSubmit: (cues: string[], activityDescription: string) => void;
}

const SignupKeywordsForm: React.FC<SignupKeywordsFormProps> = ({onSubmit}) => {

    const {t} = useTranslation();

    const [cues, setCues] = useState<string[]>([]);
    const [activityDescription, setActivityDescription] = useState("");
    // const [infoAdded, setInfoAdded] = useState(false);
    let infoAdded = (): boolean => {
        if (activityDescription.trim() !== "") return true;
        if (cues.filter(c => c != null && c.trim() !== "").length > 0) return true;
        return false;
    }
    const onActivityDescriptionChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {

        setActivityDescription(e.currentTarget.value);
    };
    const onCuesChange = (index: number, value: string) => {
        const updatedCues = [...cues];
        updatedCues[index] = value;
        setCues(updatedCues);
    }

    return (
        <div>
            <div className='layout-main-banner'>
                <div className='container'>
                    <div className='intake-keywords-section'>
                        <Form onSubmit={() => {
                            onSubmit(cues, activityDescription)
                        }}>
                            <h1>{t("intake.fourth_page_title")}</h1>
                            <div className='button-alignment'>
                                {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((i) => {
                                    return (
                                        <div className='input' key={i}>
                                            <input type="text" maxLength={255}
                                                   onChange={(e) => onCuesChange(i, e.currentTarget.value)}/>
                                        </div>
                                    )
                                })}
                            </div>
                            <h1>{t("intake.company_description")}</h1>
                            <div className='input'>
                                <textarea onChange={onActivityDescriptionChange}></textarea>
                            </div>
                            <div className='button-alignment-footer'>
                                <div className={infoAdded() ? "fill-button" : "fill-button disabled"}>
                                    <button id="submit" type="submit">{t("intake.next")}</button>
                                </div>
                                <div className='outline-button'>
                                    <button id="skip" type="submit">{t("intake.skip")}</button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}
