import {Country, CountryParser} from "../../consts/countries";
import {Tender} from "../../types/tender";
import React from "react";

interface CountryProps{
    country: Country | undefined;
}

export const FlagIcon: React.FC<CountryProps> = ({country}) => {
  if(country) return <img src={process.env.PUBLIC_URL + "/assets/flags/"+country.toLowerCase()+".svg"} alt={country.toLowerCase()}></img>;
  else return <></>
};

interface FlagsForTenderProps {
    tender: Tender;
}

export const FlagsForTender: React.FC<FlagsForTenderProps> = ({tender}) => {
  let countries: Country[] = [];

    if (tender.publicationRadius === "EUROPEAN_UNION" || hasPublisher(tender,"TED")) countries.push(Country.EU);
    if (hasPublisher(tender,"ENOT")) countries.push(Country.BE);
    if (hasPublisher(tender,"PMP")) countries.push(Country.LU);
    if (hasPublisher(tender,"BOAMP")) countries.push(Country.FR);
    if (hasPublisher(tender,"TENDERNED_API")) countries.push(Country.NL);

  if (
      tender.contractingAuthority != null &&
      tender.contractingAuthority.address != null &&
      tender.contractingAuthority.address.countryIso != null
  ) {
    let otherCountry: Country = CountryParser.fromString(tender.contractingAuthority.address.countryIso);
    if (!countries.includes(otherCountry)) countries.push(otherCountry);
  }
  if (countries.length === 0) {
    countries.push(Country.EU)
  }
  return <>
    <span>
       {countries.map(c => <FlagIcon key={c} country={c}/>)}
    </span>
  </>
};

function hasPublisher(tender: Tender, publisher: string){
  if(tender.publishers === null) {
    return false;
  }
  for(const publisherName of tender.publishers){
    if (publisherName.toUpperCase().startsWith(publisher)) return true;
  } return false;
}
