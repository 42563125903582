import { apiSlice } from "./apiSlice";

export interface TenderUuidResponse {
  uuid: string;
}

export interface GetTenderUuidParams {
  publicationSnid: string;
}

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getTenderUuid: build.query<TenderUuidResponse, GetTenderUuidParams>({
      query: (getTenderUuidParams: GetTenderUuidParams) => {
        return {
          url: `/shaggy/publications/` + getTenderUuidParams.publicationSnid + `/tender`,
        };
      },
    }),
  }),
});

export const { useGetTenderUuidQuery } = extendedApiSlice;