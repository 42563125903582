import React, { useEffect, useState } from "react";
import "./signup-survey.scss";
import { useAppDispatch } from "../../../app/hooks";
import {useNavigate, useSearchParams} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { showErrorPopup } from "../../../hooks/slices/snaccSlice";
import { SignupSurveyPostParams, usePostSignupSurveyMutation } from "../../../hooks/slices/signupSlice";
import { RaiseClient } from "../../../hooks/raiseClient";
import { CodeUtil } from "../../../utils/codes";
import { QueryParameters } from "../../tenderDetails/main/TenderDetailsView";
import { CompanyDto, CompanyPageDto, useGetCompanyPageQuery } from "../../../hooks/slices/companySlice";
import { skipToken } from "@reduxjs/toolkit/query";
import {Loader} from "../../../components/loader";
import {
  CompanyDetailsContentItem,
  CompanyDetailsSection,
  CompanyPageCard,
  getCompanyActivities,
  getCompanyDescriptionRows,
} from "../../company";
import { SimpleAnswer } from "../../../types/util";
import {SearchParamUtil} from "../../../utils/searchparams";
import { useNavigateToOpportunitiesIfSignupAlreadyFinished } from "../SignupFinishedHandler";
import { useSignupErrorHandler } from "../SignupErrorHandler";
import {MultilingualFieldUtil} from "../../../utils/multilingualfield";

export const SignupSurvey: React.FC = () => {
  useNavigateToOpportunitiesIfSignupAlreadyFinished();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const clientInStore = RaiseClient();
  const [postSignupSurvey, { data: response, isSuccess, isError, error }] = usePostSignupSurveyMutation();
  const [showLoader, setShowLoader] = useState(false);
  //FETCH COMPANY INFO
  let queryParameters = QueryParameters();
  let [searchParams] = useSearchParams();
  let companyUuid: string = queryParameters.uuid;
  const { data: companyInfo, isLoading: companyInfoIsLoading } = useGetCompanyPageQuery(
    { uuid: companyUuid } ?? skipToken
  );
  const onSubmit = (params: SignupSurveyPostParams) => {
    setShowLoader(true);
    postSignupSurvey(params);
  };
  const navigateOrShowPopup = useSignupErrorHandler();

  useEffect(() => {
    if (isSuccess && response) {
      //Construction users are sent to a different fourth signup page which allows them to choose accreditations
      if (response.accreditationCodes.length > 0) {
          response.accreditationCodes.forEach((a) => SearchParamUtil.appendToSearchParams(searchParams,"select", CodeUtil.convertCodeWithClassToString(a)));
          navigate("/signup-accreditations" + SearchParamUtil.passOnSearchParams(searchParams));
      } else navigate("/signup-curated-profiles" + SearchParamUtil.passOnSearchParams(searchParams));
    } else if (isError && error) {
      setShowLoader(false);
      navigateOrShowPopup(error);
    }
  }, [response, isSuccess, isError, error, dispatch]);

  if (clientInStore.isLoading || clientInStore.client === undefined || companyInfoIsLoading || companyInfo === undefined) return <Loader></Loader>;
  else
    return (
      <>
        {showLoader && <Loader />}
        <SurveyForm companyInfo={companyInfo} onSubmit={onSubmit} />
      </>
    );
};

interface SurveyFormProps {
  companyInfo: CompanyPageDto;
  onSubmit: (params: SignupSurveyPostParams) => void;
}

const SurveyForm: React.FC<SurveyFormProps> = ({ companyInfo, onSubmit }) => {
    let [showingSurveyInfo, setShowingSurveyInfo] = useState(true);
    if(showingSurveyInfo) return <SurveyInfoForm companyInfo={companyInfo} changePage={() => setShowingSurveyInfo(false)}/>
    else return <SurveyQuestionsForm companyInfo={companyInfo} onSubmit={onSubmit}/>
}

const SurveyInfoForm: React.FC<{companyInfo: CompanyPageDto; changePage: () => void}> = ({ companyInfo, changePage }) => {
  const { t } = useTranslation();
  return (
      <div>
        <div className="layout-main-banner">
          <div className="container">
            <div className="intake-second-section" style={{minWidth: '100%'}}>
              <h1>{t("intake.we_found_this_info_about_you")}</h1>
              <div className="company-section-alignment">
                <div className="company-info-section-alignment">
                  <CompanyInfoCard
                      company={companyInfo.company}
                  /><CompanyRecentActivityCard {...companyInfo} />

                </div>
              </div>
              <div className="button-alignment-footer">
                <div className={"fill-button"}>
                  <button onClick={changePage} data-testid={"signup-survey-show-questions-button"}>
                    {t("intake.next")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};
const SurveyQuestionsForm: React.FC<SurveyFormProps> = ({ companyInfo, onSubmit }) => {
  const { t } = useTranslation();
  const [isSubcontractor, setIsSubcontractor] = useState<SimpleAnswer>(SimpleAnswer.UNKNOWN);
  const [isLookingForSubcontractors, setLookingForSubcontractors] = useState<SimpleAnswer>(SimpleAnswer.UNKNOWN);
  const [isLookingForPartners, setLookingForPartners] = useState<SimpleAnswer>(SimpleAnswer.UNKNOWN);
  const [isInterestedInPrivateContracts, setInterestedInPrivateContracts] = useState<SimpleAnswer>(SimpleAnswer.UNKNOWN);
  let showNextButton =
    isSubcontractor !== SimpleAnswer.UNKNOWN &&
    isLookingForSubcontractors !== SimpleAnswer.UNKNOWN &&
    isLookingForPartners !== SimpleAnswer.UNKNOWN &&
    isInterestedInPrivateContracts !== SimpleAnswer.UNKNOWN;
  return (
    <div>
      <div className="layout-main-banner">
        <div className="container">
          <div className="intake-second-section" style={{minWidth: '100%'}}>
            <h1>{t("intake.answer_these_questions")}</h1>
              <div className="company-section-alignment">
                <div className="company-info-section-alignment">
                  <SurveyQuestionsCard setIsSubcontractor={setIsSubcontractor}
                                       setLookingForSubcontractors={setLookingForSubcontractors}
                                       setLookingForPartners={setLookingForPartners}
                                       setInterestedInPrivateContracts={setInterestedInPrivateContracts}/>
                </div>
              </div>
              <div className="button-alignment-footer">
                <div className={showNextButton ? "fill-button" : "fill-button disabled"}>
                  <button onClick={() => onSubmit(
                        {
                            isSubcontractor: isSubcontractor === SimpleAnswer.YES,
                            isLookingForSubcontractors: isLookingForSubcontractors === SimpleAnswer.YES,
                            isLookingForPartners: isLookingForPartners === SimpleAnswer.YES,
                            isInterestedInPrivateContracts: isInterestedInPrivateContracts === SimpleAnswer.YES,
                        }
                  )} data-testid={"signup-survey-submit-button"}>
                    {t("intake.next")}
                  </button>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};


const CompanyInfoCard: React.FC<{company: CompanyDto}> = ({company}) => {
  let { t } = useTranslation();
  let description: CompanyDetailsContentItem[] = getCompanyDescriptionRows(company);
  const combinedActivities: CompanyDetailsContentItem[] = getCompanyActivities(company);
  return (
      <>
        <CompanyPageCard
            title={t("companyPage.company_information_section_title")}
            children={
              <>
                <CompanyDetailsSection title={company.name} content={description} />
                {combinedActivities && combinedActivities.length > 0 && (
                    <>
                      <CompanyDetailsSection
                          title={t("companyPage.main_activities_section_title")}
                          content={combinedActivities}
                          key={t("companyPage.main_activities_section_title") + " - " + company.uuid}
                      />
                    </>
                )}
              </>
            }
        />
      </>
  );
};

interface SurveyQuestionsProps {
    setIsSubcontractor: (toggle: SimpleAnswer) => void;
    setLookingForSubcontractors: (toggle: SimpleAnswer) => void;
    setLookingForPartners: (toggle: SimpleAnswer) => void;
    setInterestedInPrivateContracts: (toggle: SimpleAnswer) => void;
}

const SurveyQuestionsCard: React.FC<SurveyQuestionsProps> = ({setIsSubcontractor, setLookingForSubcontractors, setLookingForPartners, setInterestedInPrivateContracts}) => {
    let { t } = useTranslation();
    return (
        <>
            <CompanyPageCard
                title={"Strategische positionering"}
                children={
                <>
                  <CompanySurveyQuestions
                      setIsSubcontractor={setIsSubcontractor}
                      setLookingForSubcontractors={setLookingForSubcontractors}
                      setLookingForPartners={setLookingForPartners}
                      setInterestedInPrivateContracts={setInterestedInPrivateContracts}
                  />
                </>
                }
            />
        </>
    );
}



const CompanyRecentActivityCard: React.FC<CompanyPageDto> = ({ tendersRecentlyWon, tendersRecentlyParticipatedIn }) => {
  let { t } = useTranslation();
    let recentTenders = [...tendersRecentlyWon, ...tendersRecentlyParticipatedIn]
    if(recentTenders.length === 0) return <></>
  let recentTenderContentItems: CompanyDetailsContentItem[] = recentTenders.map((c, i) => ({
    value: (i + 1) + ". " + MultilingualFieldUtil.translate(c.title, "NL") + " (" + (c.type === "BID" ? t("companyPage.bid") : t("companyPage.award")) + ")"
  }));
  return (
      <>
        <CompanyPageCard
            title={t("companyPage.recent_activity_section_title")}
            children={
              <>
                <CompanyDetailsSection content={recentTenderContentItems} scroll={true} />
              </>
            }
        />
      </>
  );
};

const CompanySurveyQuestions: React.FC<SurveyQuestionsProps> = ({setIsSubcontractor, setLookingForSubcontractors, setLookingForPartners, setInterestedInPrivateContracts}) => {
  const {t} = useTranslation();
  return (
  <div className={"company-info-body-section"}>
      <RadioToggle label={t("intake.is_subcontractor")} confirm={() => setIsSubcontractor(SimpleAnswer.YES)} deny={() => setIsSubcontractor(SimpleAnswer.NO)} testId={"survey-first-question"}/>
      <RadioToggle label={t("intake.is_looking_for_subcontractors")} confirm={() => setLookingForSubcontractors(SimpleAnswer.YES)} deny={() => setLookingForSubcontractors(SimpleAnswer.NO)} testId={"survey-second-question"}/>
      <RadioToggle label={t("intake.is_looking_for_partners")} confirm={() => setLookingForPartners(SimpleAnswer.YES)} deny={() => setLookingForPartners(SimpleAnswer.NO)} testId={"survey-third-question"}/>
      <RadioToggle label={t("intake.is_interested_in_private_contracts")} confirm={() => setInterestedInPrivateContracts(SimpleAnswer.YES)} deny={() => setInterestedInPrivateContracts(SimpleAnswer.NO)} testId={"survey-fourth-question"}/>
  </div>
  )
}

interface RadioToggleProps {
    label: string
   confirm: () => void;
   deny: () => void;
   testId: string;
}

const RadioToggle: React.FC<RadioToggleProps> = ({label, confirm, deny, testId}) => {
  const {t} = useTranslation();
  return <>
    <div className={"radio-toggle-container"}>
      <div>
        <p>{label}</p>
      </div>
      <div>
        <label>
          <input type="radio" name={label} onClick={confirm} data-testid={testId + "-yes"}/>
          <span>{t("intake.yes")}</span>
        </label>
        <label>
          <input type="radio" name={label} onClick={deny} data-testid={testId + "-no"}/>
          <span>{t("intake.no")}</span>
        </label>
      </div>
    </div>

  </>
}
