import React, {useEffect, useState} from 'react'

import './change-password.scss';
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {PutPasswordBody, PutPasswordParams, usePutPasswordMutation} from "../../../hooks/slices/userPasswordSlice";
import {useTranslation} from "react-i18next";
import {showCustomErrorPopup, showErrorPopup} from "../../../hooks/slices/snaccSlice";
import {Form} from "../../../components/form/Form";
import {CheckWhiteIcon} from "../../../components/icons";
import {SaveButton} from "../../../components/saveButton/SaveButton";
import {PasswordUtil} from "../../../utils/password";

export const ChangePassword: React.FC = () => {

    const dispatch = useAppDispatch();

    const userState = useAppSelector((state) => state.user);
    const [putPassword, {isLoading, isSuccess, isError, error}] = usePutPasswordMutation();
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [currentPasswordCorrect, setCurrentPasswordCorrect] = useState(true);
    const onSubmit = (oldPassword: string, newPassword: string, newPasswordRepeated: string) => {
        if (newPassword !== newPasswordRepeated) {
            setPasswordMatch(false);
        } else {
            setPasswordMatch(true);
            if (userState.uuid) {
                let putPasswordBody: PutPasswordBody = {
                    oldPassword,
                    newPassword,
                    newPasswordRepeated
                }
                let putPasswordParams: PutPasswordParams = {
                    uuid: userState.uuid,
                    body: putPasswordBody,
                };
                putPassword(putPasswordParams);
            }
        }
    };

    useEffect(() => {
        if (isError && error) {
            dispatch(showErrorPopup(error));
            setCurrentPasswordCorrect(false);
        } else {
            setCurrentPasswordCorrect(true);
        }
    }, [isError, error]);
    return (
        <ChangePasswordForm onSubmit={onSubmit} passwordMatch={passwordMatch} currentPasswordCorrect={currentPasswordCorrect} changesMade={isLoading}/>
    );

}


interface ChangePasswordFormProps {
    onSubmit: (oldPassword: string, newPassword: string, newPasswordRepeated: string) => void;
    passwordMatch: boolean;
    currentPasswordCorrect: boolean;
    changesMade: boolean;
}

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({onSubmit, passwordMatch,currentPasswordCorrect, changesMade}) => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const [oldPassword, setOldPassword] = useState("");
    const onOldPasswordChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setOldPassword(e.currentTarget.value);
    };

    const [newPassword, setNewPassword] = useState("");
    const onNewPasswordChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setNewPassword(e.currentTarget.value);
    };

    const [newPasswordRepeated, setNewPasswordRepeated] = useState("");
    const onNewPasswordChangeRepeated: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setNewPasswordRepeated(e.currentTarget.value);
    };

    const validateAndSubmit = () => {
        if (PasswordUtil.passwordIsInvalid(newPassword)) dispatch(showCustomErrorPopup(t("intake.valid_password_description")));
        else if(oldPassword !== "" && newPassword !== "" && newPasswordRepeated !== ""){
            onSubmit(oldPassword, newPassword, newPasswordRepeated);
            setOldPassword("");
            setNewPassword("");
            setNewPasswordRepeated("");
        }
    }

    return (
        <div>
                <div className='change-password-section-alignment'>
                    <div className='page-alignment'>
                        <div>
                            <h1>Change Password</h1>
                        </div>
                    </div>
                    <div className='change-password-box'>
                        <div className='input'>
                            <input type="password" maxLength={255} placeholder={t("settings.current_password")} onChange={onOldPasswordChange}
                                   value={oldPassword} className={currentPasswordCorrect ? "" : "error"}/>
                        </div>
                        <div className='input'>
                            <input type="password" maxLength={255} placeholder={t("settings.new_password")} onChange={onNewPasswordChange}
                                   value={newPassword} className={passwordMatch ? "" : "error"}/>
                        </div>
                        <div className='input'>
                            <input type="password" maxLength={255} placeholder={t("settings.confirm_new_password")} onChange={onNewPasswordChangeRepeated}
                                   value={newPasswordRepeated} className={passwordMatch ? "" : "error"}/>
                        </div>
                        <SaveButton saving={changesMade} save={validateAndSubmit}/>
                    </div>
                </div>
        </div>
    )
}
