import React from "react";

import AccountSvg from "../../assets/icons/account.svg";
import AccountBlueSvg from "../../assets/icons/account-blue.svg";
import CloseSvg from "../../assets/icons/close.svg";
import MobileMenuSvg from "../../assets/icons/mobile-hamburger.svg";
import StarBlueSvg from "../../assets/icons/star-blue.svg";
import StarSvg from "../../assets/icons/star.svg";
import StarFillSvg from "../../assets/icons/star-fill.svg";
import SearchSvg from "../../assets/icons/search.svg";
import SearchBlueSvg from "../../assets/icons/search-blue.svg";
import GearSvg from "../../assets/icons/gear.svg";
import TrashcanSvg from "../../assets/icons/trashcan.svg";
import TrashcanBlueSvg from "../../assets/icons/trashcan-blue.svg";
import TrashcanBlueFillSvg from "../../assets/icons/trashcan-blue-fill.svg";
import MinusSvg from "../../assets/icons/minus.svg";
import PlusSvg from "../../assets/icons/plus.svg";
import PlusAltSvg from "../../assets/icons/plus-alt.svg";
import BesixForceSvg from "../../assets/icons/besixforce.svg";
import ResetSvg from "../../assets/icons/reset.svg";
import CheckmarkSvg from "../../assets/icons/check.svg";
import CheckmarkWhiteSvg from "../../assets/icons/check-white.svg";
import NotificationBellSvg from "../../assets/icons/notification.svg";
import PlusCircledSvg from "../../assets/icons/plus-circled.svg";
import PublicationDateSvg from "../../assets/icons/publication-date.svg";
import PublicationDateBlueSvg from "../../assets/icons/publication-date-blue.svg";
import DeadlineSvg from "../../assets/icons/deadline.svg";
import DeadlineBlueSvg from "../../assets/icons/deadline-blue.svg";
import EuroCircledSvg from "../../assets/icons/euro-circled.svg";
import EuroCircledBlueSvg from "../../assets/icons/euro-circled-blue.svg";
import StarBlueFillSvg from "../../assets/icons/star-blue-fill.svg";
import LabelSvg from "../../assets/icons/label.svg";
import LabelBlueSvg from "../../assets/icons/label-blue.svg";
import ShareSvg from "../../assets/icons/share.svg";
import ShareBlueSvg from "../../assets/icons/share-blue.svg";
import TranslateSvg from "../../assets/icons/translate.svg";
import TranslateBlueSvg from "../../assets/icons/translate-blue.svg";
import DownloadSvg from "../../assets/icons/download.svg";
import DownloadBlueSvg from "../../assets/icons/download-blue.svg";
import ExportSvg from '../../assets/icons/export.svg';
import ExportXLSSvg from '../../assets/icons/export-xls.svg';
import LinkedInSvg from '../../assets/icons/social-linkedin.svg';
import LocationSvg from '../../assets/icons/location.svg';
import LocationWhiteSvg from '../../assets/icons/location-white.svg';
import LocationBlueSvg from '../../assets/icons/location-blue.svg';
import EmailSvg from '../../assets/icons/email.svg';
import EmailWhiteSvg from '../../assets/icons/email-white.svg';
import PhoneSvg from '../../assets/icons/phone.svg';
import PhoneWhiteSvg from '../../assets/icons/phone-white.svg';
import SubjectCodeSvg from '../../assets/icons/subject-code.svg';
import SubjectCodeBlueSvg from '../../assets/icons/subject-code-blue.svg';
import ArrowDownSvg from "../../assets/icons/arrow-down.svg";
import ArrowUpSvg from "../../assets/icons/arrow-up.svg";
import ArrowLeftSvg from "../../assets/icons/arrow-left.svg";
import ArrowRightSvg from "../../assets/icons/arrow-right.svg";
import ArrowLeftDoubleSvg from "../../assets/icons/arrow-left-double.svg";
import ArrowRightDoubleSvg from "../../assets/icons/arrow-right-double.svg";
import ArrowRightAltSvg from "../../assets/icons/arrow-right-alt.svg";
import SettingsSvg from "../../assets/icons/settings.svg";
import CraneBlueSvg from "../../assets/icons/crane-blue.svg";

export const CloseIcon: React.FC = () => {return <img src={CloseSvg} alt={"Close"}></img>}
export const CraneBlueIcon: React.FC = () => {return <img src={CraneBlueSvg} alt={"Crane"}></img>}
export const MobileMenuIcon: React.FC = () => {return <img src={MobileMenuSvg} alt="Menu"/>}
export const StarIcon: React.FC = () => {return <img src={StarSvg} alt="Follow"/>}
export const StarFillIcon: React.FC = () => {return <img src={StarFillSvg} alt="Follow"/>}
export const StarBlueIcon: React.FC = () => {return <img src={StarBlueSvg} alt="Follow"/>}
export const StarBlueFillIcon: React.FC = () => {return <img src={StarBlueFillSvg} alt="Follow"/>}
export const SearchIcon: React.FC = () => {return <img src={SearchSvg} alt="Search"/>}
export const SearchBlueIcon: React.FC = () => {return <img src={SearchBlueSvg} alt="Search"/>}
export const GearIcon: React.FC = () => {return <img src={GearSvg} alt="Settings"/>}
export const TrashIcon: React.FC = () => {return <img src={TrashcanSvg} alt="Remove"/>}
export const TrashBlueIcon: React.FC = () => {return <img src={TrashcanBlueSvg} alt="Remove"/>}
export const TrashBlueFillIcon: React.FC = () => {return <img src={TrashcanBlueFillSvg} alt="Remove"/>}
export const MinusIcon: React.FC = () => {return <img src={MinusSvg} alt="Remove"/>}
export const PlusIcon: React.FC = () => {return <img src={PlusSvg} alt="Add"/>}
export const PlusAltIcon: React.FC = () => {return <img src={PlusAltSvg} alt="Add"/>}
export const BesixForceIcon: React.FC = () => {return <img src={BesixForceSvg} alt="BesixForce"/>}
export const ResetIcon: React.FC = () => {return <img src={ResetSvg} alt="Reset"/>}
export const CheckIcon: React.FC = () => {return <img src={CheckmarkSvg} alt="Checkmark"/>}
export const CheckWhiteIcon: React.FC = () => {return <img src={CheckmarkWhiteSvg} alt="Checkmark"/>}
export const NotificationBellIcon: React.FC = () => {return <img src={NotificationBellSvg} alt="Notification bell"/>}
export const PlusCircledIcon: React.FC = () => {return <img src={PlusCircledSvg} alt="Add"/>}
export const PublicationDateIcon: React.FC = () => {return <img src={PublicationDateSvg} alt="Publication date"/>}
export const PublicationDateBlueIcon: React.FC = () => {return <img src={PublicationDateBlueSvg} alt="Publication date"/>}
export const DeadlineIcon: React.FC = () => {return <img src={DeadlineSvg} alt="Deadline"/>}
export const DeadlineBlueIcon: React.FC = () => {return <img src={DeadlineBlueSvg} alt="Deadline"/>}
export const EuroCircledIcon: React.FC = () => {return <img src={EuroCircledSvg} alt="Amount"/>}
export const EuroCircledBlueIcon: React.FC = () => {return <img src={EuroCircledBlueSvg} alt="Amount"/>}
export const RegionCodeIcon: React.FC = () => {return <img src={LocationSvg} alt="Region code"/>}
export const RegionCodeBlueIcon: React.FC = () => {return <img src={LocationBlueSvg} alt="Region code"/>}
export const SubjectCodeIcon: React.FC = () => {return <img src={SubjectCodeSvg} alt="Subject code"/>}
export const SubjectCodeBlueIcon: React.FC = () => {return <img src={SubjectCodeBlueSvg} alt="Subject code"/>}
export const RemoveIcon: React.FC = () => {return <img src={TrashcanSvg} alt="Remove"/>}
export const UnremoveIcon: React.FC = () => {return <img src={TrashcanSvg} alt="Remove"/>}
export const FollowIcon: React.FC = () => {return <img src={LocationSvg} alt="Follow"/>}
export const SimulateMatchIcon: React.FC = () => {return <img src={LabelBlueSvg} alt="Simulate match"/>}
export const LabelIcon: React.FC = () => {return <img src={LabelSvg} alt="Label"/>}
export const LabelBlueIcon: React.FC = () => {return <img src={LabelBlueSvg} alt="Label"/>}
export const ShareIcon: React.FC = () => {return <img src={ShareSvg} alt="Share"/>}
export const ShareBlueIcon: React.FC = () => {return <img src={ShareBlueSvg} alt="Share"/>}
export const TranslateIcon: React.FC = () => {return <img src={TranslateSvg} alt="Translate"/>}
export const TranslateBlueIcon: React.FC = () => {return <img src={TranslateBlueSvg} alt="Translate"/>}
export const DownloadIcon: React.FC = () => {return <img src={DownloadSvg} alt="Download"/>}
export const DownloadBlueIcon: React.FC = () => {return <img src={DownloadBlueSvg} alt="Download"/>}
export const ExportIcon: React.FC = () => {return <img src={ExportSvg} alt="Export"/>}
export const ExportXLSIcon: React.FC = () => {return <img src={ExportXLSSvg} alt="Export"/>}
export const LinkedInIcon: React.FC = () => {return <img src={LinkedInSvg} alt="LinkedIn"/>}
export const LocationIcon: React.FC = () => {return <img src={LocationSvg} alt="Location"/>}
export const LocationWhiteIcon: React.FC = () => {return <img src={LocationWhiteSvg} alt="Location"/>}
export const PhoneIcon: React.FC = () => {return <img src={PhoneSvg} alt="Phone"/>}
export const PhoneWhiteIcon: React.FC = () => {return <img src={PhoneWhiteSvg} alt="Phone"/>}
export const EmailIcon: React.FC = () => {return <img src={EmailSvg} alt="Email"/>}
export const EmailWhiteIcon: React.FC = () => {return <img src={EmailWhiteSvg} alt="Email"/>}
export const AccountIcon: React.FC = () => {return <img src={AccountSvg} alt="Account settings"/>}
export const AccountBlueIcon: React.FC = () => {return <img src={AccountBlueSvg} alt="Account settings"/>}
export const ArrowDownIcon: React.FC = () => {return <img src={ArrowDownSvg} alt="Down"/>}
export const ArrowUpIcon: React.FC = () => {return <img src={ArrowUpSvg} alt="Up"/>}
export const ArrowLeftIcon: React.FC = () => {return <img src={ArrowLeftSvg} alt="Left"/>}
export const ArrowRightIcon: React.FC = () => {return <img src={ArrowRightSvg} alt="Right"/>}
export const ArrowLeftDoubleIcon: React.FC = () => {return <img src={ArrowLeftDoubleSvg} alt="Left"/>}
export const ArrowRightDoubleIcon: React.FC = () => {return <img src={ArrowRightDoubleSvg} alt="Right"/>}
export const ArrowRightAltIcon: React.FC = () => {return <img src={ArrowRightAltSvg} alt="Right"/>}
export const SettingsIcon: React.FC = () => {return <img src={SettingsSvg} alt="Settings"/>}
